import React, { useEffect,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;

export default function NotRequiredDocuments(props: any) {
  const {language,setLanguage}=useContext(Context);
  return (
    <>
      <div className="pointofcon-information-update-wrap">
        <h4>{Vendor_profileUpdation?.Not_Mandatory?.[language]}</h4>
        <hr /> 
        <Row>
          <Col md={4} sm={6}>
            <FormGroup>
              <Label>{Vendor_profileUpdation?.ISO_Certificate?.[language]}</Label>
              <div className="image-input">
                {props?.list > 0 ? (
                  <InputGroup>
                    <input
                      id="iso_certificate"
                      name="iso_certificate"
                      className="form-control"
                      accept=".pdf"
                      value={"ISO Certificate.pdf"}
                      disabled={props?.datas?.iso_certificate ? true : false}
                    ></input>
                    <InputGroupText
                      className="input-group-text bg-white border-start-0"
                      id="newpass"
                    >
                      <a
                        href={"#"}
                        onClick={() => {
                          props?.documentdownload(
                            props?.datas?.iso_certificate
                          );
                        }}
                      >
                        {" "}
                        <i className="bi bi-box-arrow-down"></i>
                      </a>
                    </InputGroupText>
                  </InputGroup>
                ) : (
                  <>
                    <input
                      type="file"
                      ref={props?.isoRef}
                      id="iso_certificate"
                      name="iso_certificate"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={props?.list > 0 ? true : false}
                    ></input>
                    <Label
                      className="image-button"
                      for="iso_certificate"
                      id="iso_certificate"
                    >
                      {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    {props?.iso == true ? (
                      <span
                        className="upload-text"
                        style={{
                          marginRight: "0px",
                          zIndex: "45",
                          cursor: "pointer",
                        }}
                        onClick={() => props?.handleFileremove("iso")}
                      >
                        &#x2715;
                      </span>
                    ) : null}
                  </>
                )}
              </div>
              <ErrorMessage name="iso_certificat" component={TextError} />
            </FormGroup>
          </Col>

          <Col md={4} sm={6}>
            <FormGroup>
              <Label>{Vendor_profileUpdation?.MAF_Certificate?.[language]}</Label>
              <div className="image-input">
                {props?.list > 0 ? (
                  <InputGroup>
                    <input
                      id="maf_certificate"
                      name="maf_certificate"
                      className="form-control"
                      accept=".pdf"
                      value={"MAF Certificate.pdf"}
                      disabled={props?.datas?.maf_certificate ? true : false}
                    ></input>

                    <InputGroupText
                      className="input-group-text bg-white border-start-0"
                      id="newpass"
                    >
                      <a
                        href={"#"}
                        onClick={() => {
                          props?.documentdownload(
                            props?.datas?.maf_certificate
                          );
                        }}
                      >
                        {" "}
                        <i className="bi bi-box-arrow-down"></i>
                      </a>
                    </InputGroupText>
                  </InputGroup>
                ) : (
                  <>
                    <input
                      type="file"
                      ref={props?.mafRef}
                      id="maf_certificate"
                      name="maf_certificate"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={props?.list > 0 ? true : false}
                    ></input>
                    <Label
                      className="image-button"
                      for="maf_certificate"
                      id="maf_certificate"
                    >
                      {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    {props?.maf == true ? (
                      <span
                        className="upload-text"
                        style={{
                          marginRight: "50px",
                          zIndex: "45",
                          cursor: "pointer",
                        }}
                        onClick={() => props?.handleFileremove("maf")}
                      >
                        &#x2715;
                      </span>
                    ) : null}
                  </>
                )}
              </div>
              <ErrorMessage name="maf_certificate" component={TextError} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
