import Empanelment from "../../../Pages/Admin/Empanelment/Index";
import ActiveVendorsTab from "../../../Pages/Admin/Empanelment/Tabs/ActiveVendorsTab";
import EmpanelmentTab from "../../../Pages/Admin/Empanelment/Tabs/EmpanelmentTab";
import InactiveVendorsTab from "../../../Pages/Admin/Empanelment/Tabs/InactiveVendorsTab";
import SelfVendorsTab from "../../../Pages/Admin/Empanelment/Tabs/SelfVendorsTab";
import Overview from "../../../Pages/Admin/Overview/Overview";
import ProductList from "../../../Pages/Admin/Products/ProductList";
import RateContract from "../../../Pages/Admin/RateContract/Tabs/RateContract";
import RateContractLayout from "../../../Pages/Admin/RateContract/RateContractLayout";
import UploadRate from "../../../Pages/Admin/RateContract/Tabs/UploadRate";
import UploadRates from "../../../Pages/Admin/AddTendor/Tabs/UploadRate";
import OrderLayout from "../../../Pages/Admin/Orders/OrderLayout";
import ApprovedOrders from "../../../Pages/Admin/Orders/Tabs/ApprovedOrders";
import CancelledOrders from "../../../Pages/Admin/Orders/Tabs/CancelledOrders";
import ConfirmedOrders from "../../../Pages/Admin/Orders/Tabs/ConfirmedOrders";
import PendingOrders from "../../../Pages/Admin/Orders/Tabs/PendingOrders";
import ProcessedOrders from "../../../Pages/Admin/Orders/Tabs/ProcessedOrders";
import ReceivedOrders from "../../../Pages/Admin/Orders/Tabs/ReceivedOrders";
import ClosedTickets from "../../../Pages/Admin/Services/Tickets/Tabs/ClosedTickets";
import OpenTickets from "../../../Pages/Admin/Services/Tickets/Tabs/OpenTickets";
import PendingTickets from "../../../Pages/Admin/Services/Tickets/Tabs/PendingTickets";
import TicketLayout from "../../../Pages/Admin/Services/Tickets/TicketLayout";
import Amc from "../../../Pages/Admin/Services/AMC/Amc";
import DeliveredLayout from "../../../Pages/Admin/Delivered/DeliveredLayout";
import CancelOrders from "../../../Pages/Admin/Delivered/Tabs/CancelledOrders";
import DeliveredOrders from "../../../Pages/Admin/Delivered/Tabs/DeliveredOrders";
import PaymentLayout from "../../../Pages/Admin/Payment/PayamentLayout";
import CancelledPayments from "../../../Pages/Admin/Payment/Tabs/CancelledPayments";
import PaymentReceived from "../../../Pages/Admin/Payment/Tabs/PaymentReceived";
import PaymentToVendors from "../../../Pages/Admin/Payment/Tabs/PaymentToVendors";
import AddrVerification from "../../../Pages/Admin/Verifications/Tabs/AddrVerifications";
import VerificationLayout from "../../../Pages/Admin/Verifications/VerificationsLayout";
import AccountVerification from "../../../Pages/Admin/Verifications/Tabs/AccountVerification";
import DeptVerification from "./../../../Pages/Admin/Verifications/Tabs/DeptVerification";
import Settings from "./../../../Pages/Admin/Settings/Settings";
import Notifications from "../../../Pages/Admin/Notifications/NotificationLists";
import Specifications from "../../../Pages/Admin/RateContract/ProductDetails/Specifications";
import ProductComparison from "../../../Pages/Admin/RateContract/ProductDetails/ProductComparison";
import RateContractLayouts from "../../../Pages/Admin/AddTendor/RateContractLayout";
import VerifyVendor from "../../../Pages/Admin/Empanelment/Tabs/VerifyVendor";
import VendorVerificationLayout from "../../../Pages/Admin/Empanelment/VendorVerificationProcess/VerificationsLayout";
import BasicDetails from "../../../Pages/Admin/Empanelment/VendorVerificationProcess/Tabs/BasicDetails";
import AdvancedDetails from "./../../../Pages/Admin/Empanelment/VendorVerificationProcess/Tabs/AdvancedDetails";
import DocumentVerify from "../../../Pages/Admin/Empanelment/VendorVerificationProcess/Tabs/DocumentVerify";
import RateAssigned from "../../../Pages/Admin/RateContract/Tabs/RateAssigned";
import PMUProfileSettings from "../../../Pages/Admin/Settings/Tabs/ProfileSettings";
import DraftVerification from "../../../Pages/Admin/Verifications/Tabs/DraftVerification";
import CoustmeOrders from "../../../Pages/Admin/Orders/CoustmeOrders";
import QAVendorIndex from "../../../Pages/Admin/Services/QAVendor/QAVendorLayout";
import ExpiredVendorsTab from "../../../Pages/Admin/Empanelment/Tabs/ExpiredVendorsTab";
import ToExpireVendorsTab from "../../../Pages/Admin/Empanelment/Tabs/ToExpireVendorsTab";
import DraftedOrdersTab from "../../../Pages/Admin/Orders/Tabs/DraftedOrdersTab";
import Reports from "../../../Pages/Admin/Reports/Reports";
import DepartmentsList from "../../../Pages/Admin/DepartmentsList/DepartmentList";
import DepartmentListLayout from "../../../Pages/Admin/DepartmentsList/DepartmentListLayout/DepartmentListLayout";
import Departments from "../../../Pages/Admin/DepartmentsList/Departments";
import TDSList from "../../../Pages/Admin/TDS/TDSList";
import TDSTable from "../../../Pages/Admin/TDS/TDSTable";
import TDSToVendor from "../../../Pages/Admin/TDS/TDSToVendor";
import AccountsList from "../../../Pages/Admin/AccountsList/AccountsList";
import VendorDraftVerification from "../../../Pages/Admin/Verifications/Tabs/VendorDraftVerification";

const routes = [
  {
    key: "1",
    path: "/dashboard",
    Element: <Overview />,
    name: "Dashboard",
  },
  {
    key: "3",
    path: "/products",
    Element: <ProductList />,
    name: "Product",
  },
  //=========== Empanelment =========
  {
    key: "4",
    path: "/empanelments",
    Element: (
      <Empanelment>
        <EmpanelmentTab />
      </Empanelment>
    ),
    name: "Empanelment",
  },
  {
    key: "5",
    path: "/self-registered-vendors",
    Element: (
      <Empanelment>
        <SelfVendorsTab />
      </Empanelment>
    ),
    name: "Self Registered Vendors",
  },
  {
    key: "6",
    path: "/active-vendors",
    Element: (
      <Empanelment>
        <ActiveVendorsTab />
      </Empanelment>
    ),
    name: "Active Vendors",
  },
  {
    key: "7",
    path: "/inactive-vendor",
    Element: (
      <Empanelment>
        <InactiveVendorsTab />
      </Empanelment>
    ),
    name: "Inactive Vendors",
  },
  {
    key: "33",
    path: "/verify-vendor",
    Element: (
      <Empanelment>
        <VerifyVendor />
      </Empanelment>
    ),
    name: "Verify Vendor",
  },
  {
    key: "33",
    path: "/expired-vendor",
    Element: (
      <Empanelment>
        <ExpiredVendorsTab />
      </Empanelment>
    ),
    name: "Verify Vendor",
  },
  {
    key: "33",
    path: "/tobe-expired-vendor",
    Element: (
      <Empanelment>
        <ToExpireVendorsTab />
      </Empanelment>
    ),
    name: "Verify Vendor",
  },
  //=========== Rate Contract==========
  {
    key: "8",
    path: "/rate",
    Element: (
      <RateContractLayout>
        <UploadRate />
      </RateContractLayout>
    ),
    name: "Upload Rate",
  },
  {
    key: "9",
    path: "/rate-contract",
    Element: <RateContract />,
    name: "Product",
  },
  //============ Order===============
  {
    key: "10",
    path: "/received-orders",
    Element: (
      <OrderLayout>
        <ReceivedOrders />
      </OrderLayout>
    ),
    name: "Recieved Orders",
  },
  {
    key: "11",
    path: "/processed-orders",
    Element: (
      <OrderLayout>
        <ProcessedOrders />
      </OrderLayout>
    ),
    name: "Processed Order",
  },
  {
    key: "12",
    path: "/confirmed-orders",
    Element: (
      <OrderLayout>
        <ConfirmedOrders />
      </OrderLayout>
    ),
    name: "Confirmed Order",
  },
  {
    key: "13",
    path: "/rejected-orders",
    Element: (
      <OrderLayout>
        <PendingOrders />
      </OrderLayout>
    ),
    name: "Pending Order",
  },
  {
    key: "14",
    path: "/cancelled-orders",
    Element: (
      <OrderLayout>
        <CancelledOrders />
      </OrderLayout>
    ),
    name: "Cancelled Order",
  },
  {
    key: "15",
    path: "/approved-orders",
    Element: (
      <OrderLayout>
        <ApprovedOrders />
      </OrderLayout>
    ),
    name: "Approved Orders",
  },
  {
    key: "15",
    path: "/drafted-orders",
    Element: (
      <OrderLayout>
        <DraftedOrdersTab />
      </OrderLayout>
    ),
    name: "Approved Orders",
  },
  //============ Services ===============

  // ------ Tickets ---------
  {
    key: "16",
    path: "/open-tickets",
    Element: (
      <TicketLayout>
        <OpenTickets />
      </TicketLayout>
    ),
    name: "Open Tickets",
  },
  {
    key: "17",
    path: "/closed-tickets",
    Element: (
      <TicketLayout>
        <ClosedTickets />
      </TicketLayout>
    ),
    name: "Closed Tickets",
  },
  {
    key: "18",
    path: "/pending-tickets",
    Element: (
      <TicketLayout>
        <PendingTickets />
      </TicketLayout>
    ),
    name: "Pending Tickets",
  },
  //------- Amc ----------
  {
    key: "19",
    path: "/amc",
    Element: <Amc />,
    name: "Amc",
  },

  // qa-vendor 
  {
    key: "32",
    path: "/qa-vendor",
    Element: <QAVendorIndex />,
    name: "Amc",
  },
  //============ Delivered ===============
  {
    key: "20",
    path: "/delivered-orders",
    Element: (
      <DeliveredLayout>
        <DeliveredOrders />
      </DeliveredLayout>
    ),
    name: "Delivered Orders",
  },
  {
    key: "200",
    path: "/tds-list",
    Element: (
      <TDSList>
        <TDSTable />
      </TDSList>
    ),
    name: "TDS",
  },
  {
    key: "105",
    path: "/tds-to-vendor-list",
    Element: (
      <TDSList>
        <TDSToVendor />
      </TDSList>
    ),
    name: "TDS",
  },
  {
    key: "21",
    path: "/orders-cancelled",
    Element: (

      <CancelOrders />

    ),
    name: "Cancelled Orders",
  },
  //============ Payment ===============

  {
    key: "22",
    path: "/payment-received",
    Element: (
      <PaymentLayout>
        <PaymentReceived />
      </PaymentLayout>
    ),
    name: "Payment Recieved",
  },
  {
    key: "23",
    path: "/payment-to-vendors",
    Element: (
      <PaymentLayout>
        <PaymentToVendors />
      </PaymentLayout>
    ),
    name: "Payment To Vendors",
  },
  {
    key: "24",
    path: "/cancelled-payment",
    Element: (
      <PaymentLayout>
        <CancelledPayments />
      </PaymentLayout>
    ),
    name: "Cancelled Payment",
  },
  {
    key: "25",
    path: "/addr-verifications",
    Element: (
      <VerificationLayout>
        <AddrVerification />
      </VerificationLayout>
    ),
    name: "Address Verifications",
  },
  {
    key: "26",
    path: "/account-verifications",
    Element: (
      <VerificationLayout>
        <AccountVerification />
      </VerificationLayout>
    ),
    name: "Account Verifications",
  },
  {
    key: "27",
    path: "/department-verifications",
    Element: (
      <VerificationLayout>
        <DeptVerification />
      </VerificationLayout>
    ),
    name: "Account Verifications",
  },
  {
    key: "27",
    path: "/draft-verifications",
    Element: (
      <VerificationLayout>
        <DraftVerification />
      </VerificationLayout>
    ),
    name: "Account Verifications",
  },

  {
    key: "28",
    path: "/profile-settings",
    Element: (
      <Settings>
        <PMUProfileSettings />
      </Settings>
    ),
    name: "Account Verifications",
  },
  {
    key: "29",
    path: "/notifications",
    Element: <Notifications />,
    name: "Notifications",
  },
  {
    key: "30",
    path: "/specifications",
    Element: <Specifications />,
    name: "Specifications",
  },
  {
    key: "31",
    path: "/product-comparison",
    Element: <ProductComparison />,
    name: "Product Comparison",
  },
  {
    key: "32",
    path: "/add-tendor",
    Element: (
      <RateContractLayouts>
        <UploadRates />
      </RateContractLayouts>
    ),
    name: "Product Comparison",
  },
  {
    key: "35",
    path: "/basic-details-vendor",
    Element: (
      <VendorVerificationLayout>
        <BasicDetails />
      </VendorVerificationLayout>
    ),
    name: "Basic Details",
  },
  {
    key: "35",
    path: "/advanced-details-vendor",
    Element: (
      <VendorVerificationLayout>
        <AdvancedDetails />
      </VendorVerificationLayout>
    ),
    name: "Advanced Details",
  },
  {
    key: "35",
    path: "/doc-verification",
    Element: (
      <VendorVerificationLayout>
        <DocumentVerify />
      </VendorVerificationLayout>
    ),
    name: "Document verification",
  },
  {
    key: "36",
    path: "/rate-contract-assigned",
    Element: <RateAssigned />,
    name: "Product",
  },
  {
    key: "38",
    path: "/custom-orders",
    Element: <CoustmeOrders />,
    name: "Product",
  },
  {
    key: "39",
    path: "/reports",
    Element: <Reports />,
    name: "Reports",
  },
  {
    key: "40",
    path: "/departments",
    Element:
      <DepartmentListLayout>
        <Departments />
      </DepartmentListLayout>,
    name: "Departments",
  },
  {
    key: "40",
    path: "/department/list",
    Element:
      <DepartmentListLayout>
        <DepartmentsList />
      </DepartmentListLayout>,
    name: "Departments",
  },
  {
    key: "41",
    path: "/accounts",
    Element: <AccountsList />,
    name: "Accounts",
  },
  {
    key: "42",
    path: "/vendor-verifications",
    Element: <VerificationLayout> <VendorDraftVerification />  </VerificationLayout>,
    name: "Vendor-Verifications",
  }
];

export default routes;
