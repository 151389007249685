import React, { useState } from 'react'
import VendorHeader from '../Components/VendorHeader'
import VendorSidebar from '../Components/VendorSiderbar'
import { Col } from 'reactstrap'


const VendorLayout = ({ children }: any) => {
    const [openSideVendorbar, setOpenVendorSidebar] = useState<any>(false);  //mobile view
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true); // web view

    

    return (
        <>

            <VendorSidebar
                setOpenVendorSidebar={setOpenVendorSidebar}
                openSideVendorbar={openSideVendorbar}
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
            />

            
            {/* right side section which includes body and header  */}
            <Col md={sidebarIsOpen ? '10' : '12'} className={sidebarIsOpen ? 'offset-md-2 minimise-content' : 'expand-content'}>
                <VendorHeader setOpenVendorSidebar={setOpenVendorSidebar} openSideVendorbar={openSideVendorbar} />
                <div className="dashboard-outer" id="content"> <main>{children}</main>
                </div>
            </Col>

        </>
    )
}

export default VendorLayout