import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../../../Components/Context/Context";
import { Admin_orders } from "../../../../Library/LanguageConversion/Admin_orders";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Collapse,
  Label,
  Spinner,
  Table
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import { toast } from "react-toastify";
import constants from "../../../../Library/Constants/Constants";
import Loader from "../../../../Components/Loader/Loader";
import { useLocation } from "react-router-dom";
import TextError from "../../../../Components/Errors/TextError";
import { ErrorMessage, Formik } from "formik";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { API_URL } from "../../../../Library/ApiSettings";
import AssignQAVendorModal from "../AssignQAVendorModal/AssignQAVendorModal";
import { sumByKey } from "../../../../Library/Constants/helpers";

const OrderView = (props: any) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<any>({});
  const [QAVIsOpen, setQAVIsOpen] = useState<boolean>(false);
  const { language, setLanguage } = useContext(Context);

  useEffect(() => {
    if (!QAVIsOpen) props?.getSingleOrder(props?.order_view?.id)
  }, [QAVIsOpen])

  function truncateText(text: any) {
    return text?.replace(/<br>/g, ", ");
  }
  // const toggle = () => setIsOpen(!isOpen);
  const toggle = (id: any) => {
    setIsOpen((prevState: { [x: string]: any }) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  let user_id: any = props?.order_view?.user_id;




  //=================================================== verify Work Order =========================================================
  const verifyWorkOrder = (status: any) => {
    api
      .post(
        `/order/workorderstatus`,
        {
          doc_id: props?.work_id,
          status: status,
          p_id: props?.order_view?.id,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Status updated Successfully");
          props?.getDownload(user_id, props?.order_view?.id);
        }

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // assign QA Vendor (Order view Modal -> Allocate QA Vendor) - submit handler 
  const QAVendorSubmitHandler = (qa_vendor_id: number) => {
    api
      .post(
        `/vendor/qa_allocations`,
        {
          invoice_id: props?.order_view?.id,
          qa_vendor_id: qa_vendor_id,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(response?.message || "QA Vendor Assigned Successfully!");
          setQAVIsOpen(false)
        }
        return response;
      })
      .catch((err) => {
        toast.error(err?.message)
      });
  }

  const QAVendorDocValidate = (status: 2 | 3) => {
    // 2->approve 
    // 3-> reject
    api
      .post(
        `/order/qa_doc_approve_reject`,
        {
          status: status,
          p_id: props?.order_view?.id
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(response?.message || "Document Approved");
          setQAVIsOpen(false)
        }
        return response;
      })
      .catch((err) => {
        toast.error(err?.message)
      });
  }

  const getDownload = (id: any, pf_id: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(
      `${API_URL}/order/workorderview?user_id=${id}&pf_id=${pf_id}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Work Order.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        props?.getSingleOrder();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  //================================================================ view =========================================================

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.modal}
        setOpen={() => {
          props?.toggle()
          props?.getSingleOrder()
        }}
        header='Order Details'
      >

        {props?.loading ? (
          <div style={{ marginLeft: "350px" }}>
            <Loader />
          </div>
        ) : (
          <div className="feeback-outer-wrap">
            <div className="feedback-main-heading"></div>
            <div className="feedback-wrap">
              <div className="feedback-details ">

                {/* ==================================== order tracker ==========================================               */}
                {props?.cancel == 7 ? (
                  <div className="progress-tracker mt-4">
                    <div className="progress-tracker-wrap">
                      <Row className="align-items-center">
                        <h5 className="sub-heading"> {Admin_orders?.OrderTrackingStatus[language]}</h5>
                        <Col md={12}>
                          <div className="tracker-status-details-wrap">
                            <ul className="main-list">
                              {location?.pathname == "/cancelled-orders" ||
                                location?.pathname == "/orders-cancelled" ? (
                                <>
                                  <li>
                                    <div className="flex-outer">

                                      <div className="flex-wrap">
                                        <div className="circle Received"></div>
                                        <p className="track-status-text">
                                          {Admin_orders?.Received[language]}
                                        </p>
                                      </div>
                                      <div className="date">
                                        <p className="track-status-text">
                                          {moment(
                                            props?.order_view?.created_at
                                          ).format("LLL")}
                                        </p>
                                      </div>

                                    </div>
                                  </li>
                                  <li>
                                    <div className="flex-outer">


                                      <div className="flex-wrap">
                                        <div className="circle Cancelled"></div>
                                        <p className="track-status-text red-text">
                                          {Admin_orders?.Cancelled[language]}
                                        </p>
                                      </div>
                                      <div className="date">
                                        <p className="track-status-text red-text">
                                          {moment(
                                            props?.order_view?.updated_at
                                          ).format("LLL")}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                  {/* <li>
                                  <div className="flex-outer">
                                    <div className="flex-wrap">
                                      <div className="emptycircle"></div>
                                      <p className="track-status-text">
                                        Out for Delivery
                                      </p>
                                    </div>
                                    <div className="date">
                                      <p className="track-status-text">
                                        Pending
                                      </p>
                                    </div>
                                  </div>
                                </li> */}

                                  {/* <li>
                                  <div className="flex-outer">
                                    <div className="flex-wrap">
                                      <div className="emptycircle"></div>
                                      <p className="track-status-text">
                                        Delivered
                                      </p>
                                    </div>
                                    <div className="date">
                                      <p className="track-status-text">
                                        Pending
                                      </p>
                                    </div>
                                  </div>
                                </li> */}
                                </>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                        </Col>

                      </Row>
                    </div>
                  </div>
                ) : null}

                {props?.order_view?.pf_details?.length !== 0 ? (
                  <>
                    {props?.order_view?.pf_details?.map(
                      (item: any, i: any) => {
                        console.log(props?.order_view?.pf_details);

                        return (
                          <React.Fragment key={i}>
                            <Table className="table-hover table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <b> {Admin_orders?.OrderID[language]}</b>{" "}
                                  </td>
                                  <td> {props?.order_view?.pro_forma_no} </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b> {Admin_orders?.TendorID[language]} </b>{" "}
                                  </td>
                                  <td>{item?.products?.product_rate?.[0]?.tender_id}</td>
                                </tr>
                                <tr>
                                  <td>
                                    {" "}
                                    <b> {Admin_orders?.ProductName[language]}</b>{" "}
                                  </td>
                                  <td>{item?.products?.name}</td>
                                </tr>
                                <tr
                                  onClick={() => toggle(i)}
                                  className="collapse-btn"
                                >
                                  <td>
                                    <b> {Admin_orders?.ProductCode[language]}</b>{" "}
                                  </td>
                                  <td>
                                    <span className="code">
                                      {item?.products?.product_code}{" "}
                                    </span>{" "}
                                  </td>
                                </tr>
                                <Collapse
                                  isOpen={isOpen[i]}
                                  tag={"tr"}
                                  key={i}
                                >
                                  <td colSpan={2}>
                                    <Table className="table-bordered">
                                      <tbody>
                                        <tr>
                                          <td>
                                            {" "}
                                            <b>{Admin_orders?.HSNCode[language]}</b>{" "}
                                          </td>
                                          <td>{item?.products?.hsn_code}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            {" "}
                                            <b>{Admin_orders?.Quantity[language]}</b>
                                          </td>
                                          <td> {item?.quantity}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>{Admin_orders?.UnitPrice[language]}</b>
                                          </td>
                                          <td>₹ {item?.unit_price}</td>
                                        </tr>
                                        {/* this row shows in desktop (Specs)*/}
                                        <tr className="large-screen-view">
                                          <td>
                                            <b>{Admin_orders?.ProductSpecifications[language]}</b>
                                          </td>
                                          <td>
                                            <p className="specs">
                                              <small>
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: truncateText(
                                                      item?.products?.features
                                                    ),
                                                  }}
                                                />
                                              </small>
                                              {/* <small><span><b>Processor:</b></span>i5</small> */}
                                            </p>
                                          </td>
                                        </tr>
                                        {/* this row shows in mobile (Specs)*/}
                                        <tr className="mobile-view">
                                          <td colSpan={2} className="text-center">
                                            <b>{Admin_orders?.ProductSpecifications[language]}</b>
                                          </td>
                                        </tr>
                                        <tr className="mobile-view">
                                          <td colSpan={2}>
                                            <p className="specs centered">
                                              <small>
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: truncateText(
                                                      item?.products?.features
                                                    ),
                                                  }}
                                                />
                                              </small>
                                              {/* <small><span><b>Processor:</b></span>i5</small> */}
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>{Admin_orders?.Department[language]}</b>
                                          </td>
                                          <td>
                                            {
                                              props?.order_view?.user
                                                ?.full_name
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>{Admin_orders?.ShipmentAddress[language]}</b>
                                          </td>
                                          <td>
                                            {Admin_orders?.BuildingNo[language]}:{" "}
                                            {
                                              props?.order_view
                                                ?.pf_address?.[0]?.building_no
                                            }
                                            ,
                                            {
                                              props?.order_view
                                                ?.pf_address?.[0]?.city
                                            }
                                            ,
                                            {
                                              props?.order_view
                                                ?.pf_address?.[0]?.district
                                            }
                                            ,{" "}
                                            {
                                              props?.order_view
                                                ?.pf_address?.[0]?.state
                                            }
                                            ,
                                            {
                                              props?.order_view
                                                ?.pf_address?.[0]?.pincode
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </td>
                                </Collapse>
                                {props?.item?.pf_address?.[0]?.state == "Karnataka" ?
                                  <>
                                    <tr>
                                      <td>
                                        <b>{Admin_orders?.SGST[language]}</b>{" "}
                                      </td>
                                      <td>₹ {item?.sgst}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>{Admin_orders?.CGST[language]}</b>{" "}
                                      </td>
                                      <td>₹ {item?.cgst}</td>
                                    </tr>
                                  </>
                                  :
                                  <>
                                    <tr>
                                      <td>
                                        <b>{Admin_orders?.IGST[language]}</b>{" "}
                                      </td>
                                      <td>₹ {item?.igst}</td>
                                    </tr>
                                  </>
                                }
                                <tr>
                                  <td>
                                    <b>{Admin_orders?.TotalGST[language]}</b>
                                  </td>
                                  <td> ₹ {item?.total_gst}</td>
                                </tr>

                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={2}>
                                    <div className="order-view-bottom">
                                      <h4>
                                        <small className="date">
                                          <span className="date-span-botton">
                                            {moment(props?.order_view?.updated_at).format("LLL")}
                                          </span>
                                        </small>
                                      </h4>
                                      <h4 className="text-end">
                                        <span>
                                          {Admin_orders?.GrandTotal[language]}:{" "}
                                          <strong>₹{item?.total_amount}</strong>
                                        </span>
                                      </h4>
                                    </div>
                                  </td>
                                </tr>
                              </tfoot>
                            </Table>
                            {/* <h4>
                            <strong>Total Amount :</strong> {item?.total_amount}
                          </h4> */}
                          </React.Fragment>
                        );
                      }
                    )}

                    {/* TDS Deductions  */}
                    <Table className="table-hover table-bordered">
                      <tbody>
                          {props?.order_view?.dep_deduction?.map((it: any, i: number) => {
                          return (
                            <tr >
                              <td><b>{it?.item}:</b></td>
                              <td>
                                <i className="bi bi-currency-rupee"></i>
                                {it?.amount}
                              </td>
                            </tr>
                          )
                        })}

                        <tr>
                          <td><b>Total Deductions</b></td>
                          <td>
                            <i className="bi bi-currency-rupee"></i>
                            {(sumByKey(props?.order_view?.dep_deduction, 'amount'))?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td><b>Total Amount</b></td>
                          <td>
                            <i className="bi bi-currency-rupee"></i>
                            {props?.order_view?.total_amount?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>{Admin_orders?.Payable[language]} {" "}</b>
                          </td>
                          <td className="green-text">
                              <strong>₹{(props?.order_view?.total_amount - (sumByKey(props?.order_view?.dep_deduction, 'amount')))?.toFixed(2)}</strong>
                          </td>
                        </tr>
                      </tbody>                    
                    </Table>
                    
                  </>
                ) : (
                  <h4>
                    <i>
                      <b className="text-danger">{Admin_orders?.NoDataFound[language]}</b>
                    </i>
                  </h4>
                )}
                <h3>
                  {props?.order_view?.order?.length !== 0 ? (
                    <strong>{Admin_orders?.VendorDetails[language]}</strong>
                  ) : null}
                </h3>
                <div className="container">
                  <div className="row">
                    {props?.order_view?.order?.length !== 0 ? (
                      <>
                        {props?.order_view?.order?.map(
                          (item: any, i: number) => {
                            return (
                              <Col md="6" className="vendor-outer-wrap" key={i}>
                                <div className="vendor-detail-wrap">
                                  <React.Fragment key={i}>
                                    <h4>
                                      <b>{Admin_orders?.VendorName[language]}:</b>{" "}
                                      {item?.cmpny_detail?.name}
                                    </h4>
                                    <h4>
                                      <b>{Admin_orders?.VendorEmail[language]}:</b>{" "}
                                      {item?.cmpny_detail?.email}
                                    </h4>
                                    <h4>
                                      <b>{Admin_orders?.RegistrationID[language]}:</b>{" "}
                                      {item?.cmpny_detail?.registration_id}
                                    </h4>
                                    <h4>
                                      <b>{Admin_orders?.ProductName[language]}</b>{" "}
                                      {
                                        item?.order_details?.product_details
                                          ?.name
                                      }
                                    </h4>
                                    <h4>
                                      <b>{Admin_orders?.AllocatedQuantity[language]}</b>{" "}
                                      {item?.order_details?.quantity}
                                    </h4>
                                    {/* <h4>
                                    <b>Point Contact Number:</b>{" "}
                                    {item?.vendor_detail?.point_contact_number}
                                  </h4> */}
                                  </React.Fragment>
                                </div>
                              </Col>
                            );
                          }
                        )}
                      </>
                    ) : null}
                    {props?.order_view?.order?.length !== 0 ? <hr /> : null}
                  </div>
                </div>
              </div>

              {props?.work_order == 1 && props?.url != "" && (
                <div className="flex-between">

                  {(props.order_view?.order_status == '18' || props.order_view?.order_status == '19') &&
                    <>
                      <div className="agree-checkbox-wrap">
                        <i className="bi bi-check-circle text-success" />
                        <Label >{Admin_orders?.OrderApprovedByQAVendor[language]}</Label>

                      </div>
                      <div className="flex-wrap">
                        {
                          props?.order_view?.qa_doc?.map((it: any, i: number) => {
                            return (
                              <div key={i} >
                                <a href={it?.url} target="_blank" rel="noreferrer" className="rounded-border">
                                  <span className="mx-2">{Admin_orders?.UploadedDocument[language]}{i + 1}</span>
                                  <span><i className="bi bi-download"></i></span>
                                </a>
                              </div>
                            )
                          })
                        }
                        {
                          props?.order_view?.order_status == 19 ?
                            <span className="text-center">{Admin_orders?.OrderDocumentsApproved[language]}</span>
                            :
                            <>
                              <div className="flex-100">
                                <Button color="primary" onClick={() => QAVendorDocValidate(2)}>Approve Documents</Button>
                                <Button color="danger" onClick={() => QAVendorDocValidate(3)} >Reject Documents</Button>
                              </div>
                            </>
                        }
                      </div>
                    </>
                  }
                  {
                    // the button will be enabled if the department is inside of bangalore with total amount above 2500000 or any order above 500000 
                    ((props?.order_view?.pf_address?.[0]?.district?.toUpperCase() == 'BANGALORE' &&
                      props?.order_view?.total_amount > 2500000) ||
                      props?.order_view?.total_amount > 5000000) && props.order_view?.order_status !== '18' && props.order_view?.order_status !== '19' ?
                      <div className="assign-btn-outer ms-auto">
                        <Button
                          color="success"
                          disabled={props.order_view?.qa_user_id || props?.order_view?.qa_vendor}
                          onClick={() => setQAVIsOpen(true)}
                        >
                          {props?.order_view?.order_status == '1' ? // just placed order
                            "Assign to Third Party Inspector" :
                            props?.order_view?.order_status == '16' ? // Order is assigned to QA Vendor
                              "Assigned to Third Party Inspector" :
                              props?.order_view?.order_status == '17' ? 'Rejected By Third Party Inspector' // order Rejected By QA Vendor
                                : ''}

                        </Button>
                      </div>
                      :
                      <>
                        <h3 className="green-text">{Admin_orders?.WorkOrder[language]}</h3>
                        {props?.verify_status ==
                          constants?.WORK_ORDER_VERIFY.APPROVE ?
                          (<>
                            <div className="agree-checkbox-wrap">
                              <Input
                                id="checkbox2"
                                type="checkbox"
                                checked={true}
                                disabled={true}
                              />{" "}
                              <Label className="text-success">{Admin_orders?.Approved[language]}</Label>
                            </div>
                          </>)
                          :
                          (
                            <>
                              {!(props?.verify_status ==
                                constants?.WORK_ORDER_VERIFY.REJECT) &&
                                <div className="agree-checkbox-wrap">
                                  <Button
                                    color="warning"
                                    onClick={() => {
                                      verifyWorkOrder(2);
                                    }}
                                    checked={false}
                                  >{Admin_orders?.ApproveWorkOrder[language]}</Button>
                                </div>}

                              {props?.verify_status ==
                                constants?.WORK_ORDER_VERIFY.REJECT ? (
                                <>
                                  <div className="agree-checkbox-wrap">
                                    <i className="icons icons-close grey"></i>
                                    <span className="text-danger">{Admin_orders?.Rejected[language]}</span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="agree-checkbox-wrap">
                                    <Button
                                      color="danger"
                                      onClick={() => {
                                        verifyWorkOrder(3);
                                      }}
                                    >{Admin_orders?.RejectWorkOrder[language]}</Button>
                                  </div>
                                </>
                              )}
                            </>
                          )
                        }
                      </>
                  }

                  {((props?.order_view?.pf_address?.[0]?.district?.toUpperCase() == 'BANGALORE' &&
                    props?.order_view?.total_amount > 2500000) ||
                    props?.order_view?.total_amount > 5000000) ? null :


                    <div className="download-btn work-order-download">
                      {!loading ? (
                        <>
                          {props?.url?.map((it: any, i: number) => {

                            return (
                              <React.Fragment key={i}>
                                {it?.status !== 3 && <a
                                  href={it?.file}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="rounded-tag warning"
                                >
                                  <div className="" title="Download Work Order">
                                    <Button
                                      color="outline"
                                      className="download-button"

                                    // onClick={() => getDownload(it?.user_id, props?.order_view.pf_details[0].pro_forma_invoice_id)}
                                    >
                                      <div className="work-order-inner doc-wrap">
                                        <div className="d-flex">
                                          <i className="bi bi-download me-2"></i>
                                          <i className="fa-sharp fa-regular fa-file"></i>
                                        </div>
                                        {/* <Button className="bi bi-x-circle close-btn"></Button> */}
                                      </div>
                                    </Button>
                                    {/* Download Work Order Doc {i + 1} */}

                                    {/* <i className="icons icons-close"></i> */}
                                  </div>
                                </a>}
                              </React.Fragment>
                            )
                          })}

                        </>
                      ) : (
                        <Button color="outline" className="download-button">
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        </Button>
                      )}
                    </div>}


                </div>
              )}
              {props?.url == "" && (
                <span className="text-danger">{Admin_orders?.NoWorkOrderUploaded[language]}</span>
              )}
            </div>
          </div>
        )}

        {
          props?.order_view?.payment_details?.refund_transaction_id == null && (<>
            <Col>
              {props?.cancel == 7 && (<>

                <Col md={12}>

                  <div className="transfer-account-details">
                    <Formik
                      initialValues={props?.initialValues}
                      validationSchema={props?.PaymentValidationSchema}
                      onSubmit={(values, actions) => {
                        props?.handleSubmit(values, actions);
                      }}
                    >
                      {({
                        isSubmitting,
                        values,
                        handleSubmit,
                        handleChange,
                        touched,
                        errors,
                        setFieldValue
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit}>


                            <div className="details-inner">
                              <Col lg={4} md={12} sm={12}
                                className="pe-2">
                                <div className="upload-wrap">
                                  <FormGroup className="form-group">
                                    <Label>
                                      {Admin_orders?.UploadChequeChellan[language]}{" "}
                                    </Label>
                                    <div className="modified-imginput">
                                      <Input
                                        type="file"
                                        name="document"
                                        accept=".pdf,.jpg,.jpeg,.png"
                                        title=""
                                        id="upload-chellan"
                                        multiple
                                        onChange={(e: any) =>
                                          props?.handleImagechange(e.target.files[0])
                                        }
                                        className={`form-control ${touched.document && errors.document
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                      />
                                      <Label className="image-button" for="upload-chellan" id="upload-cellan"> &nbsp; &nbsp; Choose Files &nbsp; &nbsp;</Label>
                                    </div>
                                    <ErrorMessage name="document" component={TextError} />
                                  </FormGroup>
                                </div>
                              </Col>
                              <Col lg={4} md={6} sm={12}
                                className="pe-2"
                              >
                                <div className="trans-num">
                                  <FormGroup>
                                    <Label> {Admin_orders?.TransactionNumber[language]}</Label>
                                    <Input
                                      type="text"
                                      value={values?.transaction}
                                      name="transaction"
                                      onChange={handleChange}
                                      className={`form-control ${touched.transaction && errors.transaction
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    />
                                    <ErrorMessage
                                      name="transaction"
                                      component={TextError}
                                    />
                                  </FormGroup>
                                </div>

                              </Col>
                              <Col lg={4} md={6} sm={12}>
                                <div className="paid-amnt">
                                  <FormGroup>
                                    <Label>{Admin_orders?.PaidAmount[language]}</Label>
                                    <Input
                                      type="number"
                                      value={values?.pay_amount}
                                      name="pay_amount"
                                      onChange={handleChange}
                                      className={`form-control ${touched.pay_amount && errors.pay_amount
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    //min="1"
                                    // onKeyDown={(e) => { e.preventDefault() }}
                                    />
                                    <ErrorMessage
                                      name="pay_amount"
                                      component={TextError}
                                    />
                                  </FormGroup>
                                </div>
                              </Col>
                            </div>

                            <div className="transfer-btn">
                              <Button
                                color="primary"
                                className={""}
                                type="submit"
                              //disabled={props?.pay}
                              >
                                {Admin_orders?.TransferMoneytoDepartment[language]}
                              </Button>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </Col>

              </>)}
            </Col></>)
        }

        {/* <ModalFooter> */}
        {/* <div className="order-view-bottom">
            <h4>
              <small className="date">
                <span className="date-span-botton">
                  {moment(props?.order_view?.updated_at).format("LLL")}
                </span>
              </small>
            </h4>
            <h4 className="text-end">
              Grand Total : <strong>₹ {props?.order_view?.total_amount}</strong>
            </h4>
          </div> */}
        {/* </ModalFooter> */}
      </ModalContainer>

      <ModalContainer
        open={QAVIsOpen}
        setOpen={setQAVIsOpen}
        header="Assign to QA Vendor"
      >
        <AssignQAVendorModal
          QAVendorSubmitHandler={QAVendorSubmitHandler}
        />
      </ModalContainer>
    </React.Fragment>
  );
};

export default OrderView;
