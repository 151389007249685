import React, { ReactNode } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../../Components/Context/Context";
import { Admin_Departlist} from "../../../../Library/LanguageConversion/Admin_Departlist";
import { useContext } from "react";


interface propType {
    children: ReactNode
}

const DepartmentListLayout = ({ children }: propType) => {
    const { pathname } = useLocation()
    const { language, setLanguage } = useContext(Context);
    return (
        <React.Fragment>
            <div className="right-content-wrap">
                <div className="breadcrumb-tabs-outer-wrap">
                    {/* tabs */}
                    <div className="custom-tabs">
                        <NavLink
                            to="/departments"
                        >
                             {Admin_Departlist?.Departments[language]}
                        </NavLink>

                        <NavLink
                            to="/department/list"
                        >
                            {Admin_Departlist?.DepartmentAccounts[language]}
                        </NavLink>

                    </div>
                    {/* breadcrumb */}
                    <List className=" breadcrumb">
                        <li>
                            <Link
                                className="active"
                                to="/department/dashboard"
                            >
                                 {Admin_Departlist?.Dashboard[language]}
                            </Link>
                        </li>
                        &nbsp; / &nbsp;
                        <li>
                            <b>
                                {
                                    pathname == "/department/list" ? Admin_Departlist?.DepartmentAccounts[language] :
                                        pathname == "/departments" ? Admin_Departlist?.Departments[language] : 'Departments'
                                }
                            </b>
                        </li>
                    </List>
                </div>
                <div className="custom-tab-content">
                    <div className="table-outer-wrap">
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DepartmentListLayout;
