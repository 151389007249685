// import { ErrorMessage, Field, FieldArray, Formik } from "formik";
// import React, { BaseSyntheticEvent, ChangeEvent, useState } from "react";
// import { Link } from "react-router-dom";
import React, { useEffect, useState,useContext } from "react";
import ImageGallery from "react-image-gallery";

import {

  Col,

  FormGroup,

  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ModalContainer from "../../../Components/Modal/ModalContainer";
// import Select from "react-select";
// import TextError from "../../../Components/Errors/TextError"; 
import { Context } from "../../../Components/Context/Context";
import{Admin_Products} from "../../../Library/LanguageConversion/Admin_Products";
interface ProductProps {
  isShowing: boolean;
  setIsShowing: Function;
  category: any;
  setFormData: any;
  spec_data: any;
  FormData: any;
}

const ViewProductModal = (props: ProductProps) => {
  const convertedString = props?.FormData?.features;
  const [specData, setSpecData] = useState<any>([])
  const [specShowAll, setSpecShowAll] = useState<boolean>(false)



  useEffect(() => {
    setSpecData(props?.FormData?.specifications?.filter((spec: any) => spec?.value?.length !== 0 && spec?.key?.length !== 0))
  }, [props?.FormData?.specifications])


  const images = props?.FormData?.images?.map((item: any) => {
    return {
      original: item?.path,
      thumbnail: item?.path,
    };
  });
  const { language, setLanguage } = useContext(Context);

  // spec show all / show less handler 
  const specShowHandler = () => {
    setSpecShowAll(!specShowAll)
  }
  return (
    <>
      <ModalContainer
        open={props?.isShowing}
        setOpen={props?.setIsShowing}
        header='View Products'
      >
        <div className="product-specifications-wrap mb-5">
          <Row className='gy-4'>
            <Col sm={12} md={12} lg={6} xl={6}>
              <Row className="gy-3 gx-3 ">
                <Col md={12}>
                  {/* image gallery */}
                  {images && (
                    <ImageGallery
                      items={images}
                      showNav={false}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      lazyLoad
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>

              <div className="specifications-content-wrap">
                <div className="main-specifications-wrap">

                  <h2 className="main-heading">
                    {" "}
                    {props?.FormData?.name}
                  </h2>
                  <hr />
                  <table className="table table-hover table-bordered table-results-product-outer">
                    <tbody>
                      <tr>
                        <td><h4 className="heading">{Admin_Products?.ProductCode[language]}</h4>{" "}</td>
                        <td> {props?.FormData?.product_code}</td>

                      </tr>
                      <tr>
                        <td> <h4 className="heading">{Admin_Products?.HSNCode[language]} </h4></td>
                        <td> {props?.FormData?.hsn_code}</td>

                      </tr>
                      <tr>
                        <td> <h4 className="heading">{Admin_Products?.Category[language]}</h4>{" "}</td>
                        <td>  {props?.FormData?.categories?.name}</td>

                      </tr>

                      {/* <tr>
                            <td>   <h4 className="heading">Product Description :</h4></td>
                            <td>  <div
                            className="prod-spec"
                            dangerouslySetInnerHTML={{
                              __html: convertedString,
                            }}
                          ></div></td>
                         
                          </tr> */}
                      {specData?.map((item: any, i: number) => {
                        return (
                          <React.Fragment key={item.id}>
                            {(!specShowAll ? i < 5 : true) && <>
                              {item?.value !== "" ? (
                                <tr>
                                  <td> <h4 className="heading">{item?.key}</h4></td>
                                  <td>{item?.value}</td>
                                </tr>
                              ) : null}
                            </>}
                          </React.Fragment>
                        );
                      })}

                      {/* show all / show less  */}
                      {specData?.length > 5 && <tr onClick={specShowHandler}>
                        <th colSpan={2} className='text-center show-more-less-text'><span>{specShowAll ? 'Show Less' : 'Show All'}</span></th>
                      </tr>}
                    </tbody>
                  </table>
                </div>

                {/* <div className="address-specifications-wrap mb-4s">
                <h4 className="heading ">Specifications : </h4>
                  {props?.FormData?.specifications?.length !== 0 ? (
                    <>
                      {props?.FormData?.specifications?.map((item: any) => {
                        return (
                          <>
                            {item?.value !== "" ? (
                              <>
                                <Col md={4} sm={6}>
                                  <FormGroup className="list-item">
                                    <b>{item?.key}</b> - {item?.value} &nbsp;
                                  </FormGroup>
                                </Col>
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <span>No Specification Found</span>
                  )}
                  </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </ModalContainer>
    </>
  );
}
export default ViewProductModal;