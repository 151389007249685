import React, { useEffect, useContext } from "react";
import { useState } from "react";
import {
    Button,
    Table,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    Badge,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../Library/CustomApi";
import constants from "../../../Library/Constants/Constants";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/Loader";
import { Formik } from "formik";
import * as Yup from "yup";
import AdvancedReDocUpload from "./AdvancedReuploadModal";
import AddressModal from "../profileUpdation/AddressAddModal";
import { pincodeValidation } from "../profileUpdation/UploadValidation";
import axios from "axios";
import { API_URL } from "../../../Library/ApiSettings";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

const AdvaDetailsValidationSchema = Yup.object().shape({
    reupload_name: Yup.string().required("Field is required").nullable(),
    //  reupload_date: Yup.date().required("Field is required").nullable(),
});

export default function AdvancedDetails() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("")
    const [advanced_details, setAdvancedDetails] = useState<any>([]);
    const [vendor_data, setVendorData] = useState<any>([]);
    const [document, setDocument] = useState<any>([]);
    const [com_add, setComAdd] = useState<any>([]);
    const [com_reg, setRegAdd] = useState<any>([]);
    const [verify_status, setVerifystatus] = useState<any>([]);
    const [doc_data, setDocData] = useState<any>("");
    const [turn_data, setTurnData] = useState<any>([]);
    const [comm_data, setCommData] = useState([]);
    const [emd, setEmd] = useState<any>([]);
    const { language, setLanguage } = useContext(Context);
    const [isShowing, setIsShowing] = useState(false)
    const [isShowings, setIsShowings] = useState(false)
    const [comment, setComment] = useState({
        comm_1: "",
        comm_2: "",
        comm_3: "",
        comm_4: "",
        comm_5: "",
        comm_6: "",
        comm_7: "",
        comm_8: "",
        comm_9: "",
        comm_10: "",
        comm_11: "",
        comm_12: "",
        comm_13: "",
        comm_14: "",
        turn_comm_1: "",
        turn_comm_2: "",
        turn_comm_3: "",
        emd_comm: "",
    });
    const [pincodeValues, setpincodeValues] = useState<any>({
        city: "",
        district: "",
        state: "",
        country: "",
        building_number: "",
        building_address: "",
        pincode: "",
        landmark: "",
        address: "",
    });
    const location = useLocation();
    const id = localStorage.getItem("vendor_data");
    const vendor_id = location?.state?.id || location?.state?.data || id;
    const initialValues = {
        reupload_name: "",
        // reupload_date: null
    }
    useEffect(() => {
        getAdvancedDetails();
    }, []);

    const handleComment = (e: any) => {
        setComment({
            ...comment,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = (values: any, actions: any) => {
       
        const formData = new FormData();
        formData.append("reupload_name", values?.reupload_name);
        formData.append("type", type);
        const body = formData
        axios({
            method: "post",
            url: `${API_URL}/text_reupload`,
            data: body,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            },
        })
            .then(function (response) {
                if (response?.status == 200) {
                    toast.success("Document Uploaded Successfuly");

                    setIsShowing(false)
                    getAdvancedDetails()
                    actions.resetForm();
                    actions.setSubmitting(false);
                    return response
                }
            })

            .catch((err) => {
                console.log(err);
            });
    }
    const handleSubmits = (values: any, actions: any) => {

       
        const company_register_address: any = {
            type: "5",
            building_no: values?.building_number,
            pincode: values?.pincode,
            district: values?.district,
            longtitude: null,
            latitude: null,
            landmark: values?.landmark,
            city: values?.city,
            state: values?.state,
            country: values?.country,
            building_number: values?.building_number,
            building_address: values?.building_address,
        };
        const company_communication_address: any = {
            type: "6",
            building_no: values?.building_number,
            pincode: values?.pincode,
            district: values?.district,
            longtitude: null,
            latitude: null,
            landmark: values?.landmark,
            city: values?.city,
            state: values?.state,
            country: values?.country,
            building_number: values?.building_number,
            building_address: values?.building_address,
        };
        api
            .post(
                `/text_reupload`,
                {
                    type: type,
                    reupload_name: company_register_address || company_communication_address
                },
                true
            )
            .then(async function ([success, response]) {
                if (response?.status == "success") {
                    toast.success("Uploaded Successfuly");

                    setIsShowings(false)
                    getAdvancedDetails()
                    actions.resetForm();
                    actions.setSubmitting(false);
                    return response
                }
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }
    const getAdvancedDetails = () => {
        setLoading(true);
        api
            .post(
                `/get_vendor_data`,
                {
                    user_id: localStorage?.getItem("USER_ID"),
                },
                true
            )
            .then(async function ([success, response]) {
                setAdvancedDetails(
                    response?.vendor_details?.advanced_registration_details || []
                );
                setRegAdd(response?.vendor_details?.COMPANYREGISTERED || []);
                setComAdd(response?.vendor_details?.COMPANYCOMMUNICATION || []);
                setVendorData(response?.vendor_details?.company_details || []);
                setDocument(response?.vendor_details?.document || []);
                setTurnData(response?.vendor_details?.turnover || []);
                setEmd(response?.vendor_details?.emd || []);
                setComment({
                    ...comment,
                    comm_1:
                        response?.vendor_details?.advanced_registration_details[0]
                            ?.gst_number_verified_comment,
                    comm_2:
                        response?.vendor_details?.advanced_registration_details[0]
                            ?.pan_number_verified_comment,
                    comm_3:
                        response?.vendor_details?.advanced_registration_details[0]
                            ?.vendor_type_verified_comment,
                    comm_4:
                        response?.vendor_details?.advanced_registration_details[0]
                            ?.gst_class_verified_comment,
                    comm_5:
                        response?.vendor_details?.advanced_registration_details[0]
                            .company_mse_verified_comment,
                    comm_6:
                        response?.vendor_details?.company_details[0]
                            ?.company_name_verified_comment,
                    comm_7:
                        response?.vendor_details?.company_details[0]
                            ?.company_email_verified_comment,
                    comm_8:
                        response?.vendor_details?.company_details[0]
                            ?.company_contact_verified_comment,
                    comm_9:
                        response?.vendor_details?.company_details[0]
                            ?.company_incorp_verified_comment,
                    comm_10:
                        response?.vendor_details?.COMPANYREGISTERED
                            ?.company_address_proof_verified_comment,
                    comm_11:
                        response?.vendor_details?.COMPANYCOMMUNICATION
                            ?.company_communication_address_verified_comment,
                    comm_12:
                        response?.vendor_details?.advanced_registration_details[0]
                            ?.contact_person_verified_comment,
                    comm_13:
                        response?.vendor_details?.advanced_registration_details[0]
                            ?.contact_number_verified_comment,
                    comm_14:
                        response?.vendor_details?.advanced_registration_details[0]
                            ?.email_verified_comment,
                    turn_comm_1:
                        response?.vendor_details?.turnover[1]?.verify_data?.comment,
                    turn_comm_2:
                        response?.vendor_details?.turnover[2]?.verify_data?.comment,
                    turn_comm_3:
                        response?.vendor_details?.turnover[3]?.verify_data?.comment,
                    emd_comm: response?.vendor_details?.emd?.emd_verified_comment,
                });
                
                setLoading(false);
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const verifyData = (id: any, status: any, type: any, comment_value: any) => {
        api
            .post(
                `/doc_verification`,
                {
                    user_id: vendor_id,
                    type: type,
                    doc_status: status,
                    comment: comment_value,
                },
                true
            )
            .then(async function ([success, response, status]) {
                if (status == 200) {
                    toast.success("Verified Successfully");
                }
                getAdvancedDetails();
                setLoading(false);

                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const docFilter = (type: any) => {
        setType(type)
        document?.filter((item: any) => {
            if (item.type == type) {
                setDocData(item?.url);

            }
        });
    };
    return (
        <React.Fragment>
            {loading == true ? (
               <div className="loading-overlay"> <Loader /> </div>
            ) : (
                <div className="table-outer-wrap">
                    <div className="table-wrap responsive-table advanced-reupload-vendor">
                        <Table className="w-100 table-striped">
                            <tbody>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.gst_number[language]}</td>
                                    <td><span className="mobile-heading">{VendorRegLang?.gst_number[language]}:</span>{advanced_details[0]?.gst_number}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.action[language]}:</span>
                                        <div className="action-flex-btn">
                                            <a
                                                href={doc_data}
                                                target="_blank"
                                                rel="noreferrer"
                                                onClick={() => docFilter(constants?.ADVANCED_VERIFY?.GST)}
                                                title="View"
                                            >
                                                <i className="bi bi-eye"></i>
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {advanced_details[0]?.gst_number_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowing(true);
                                                            docFilter(constants.ADVANCED_VERIFY.GST)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {advanced_details[0]?.gst_number_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : advanced_details[0]?.gst_number_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : advanced_details[0]?.gst_number_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.pan_number[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.pan_number[language]}:</span>
                                        {advanced_details[0]?.pan_number}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.action[language]}:</span>
                                        <div className="action-flex-btn">
                                            <a
                                                href={doc_data}
                                                target="_blank"
                                                rel="noreferrer"
                                                onClick={() => docFilter(constants?.ADVANCED_VERIFY?.PAN)}
                                                title="View"
                                            >
                                                <i className="bi bi-eye"></i>
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            advanced_details[0]?.pan_number_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        setIsShowing(true);
                                                        docFilter(constants?.ADVANCED_VERIFY?.PAN)
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )
                                        }
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {advanced_details[0]?.pan_number_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : advanced_details[0]?.pan_number_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : advanced_details[0]?.pan_number_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.vendor_type[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.vendor_type[language]}:</span>
                                        {advanced_details[0]?.vendor_type ==
                                            constants.VENDOR_TYPE.MANUFATURE ? (
                                            <span>{VendorRegLang?.manufacturer[language]}</span>
                                        ) : advanced_details[0]?.vendor_type ==
                                            constants.VENDOR_TYPE.DISTRIBUTER ? (
                                            <span>{VendorRegLang?.distributor[language]}</span>
                                        ) : advanced_details[0]?.vendor_type ==
                                            constants.VENDOR_TYPE.IMPORTER ? (
                                            <span>{VendorRegLang?.importer[language]}</span>
                                        ) : advanced_details[0]?.vendor_type ==
                                            constants.VENDOR_TYPE.MARKETER ? (
                                            <span>{VendorRegLang?.marketer[language]}</span>
                                        ) : null}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            advanced_details[0]?.vendor_type_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (<div
                                                className="one"
                                                onClick={() => {
                                                    {
                                                        setIsShowing(true);
                                                        docFilter(constants?.ADVANCED_VERIFY?.VENDORTYPE)
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {" "}<i className="fa-solid fa-upload"></i>
                                            </div>)
                                        }
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {advanced_details[0]?.vendor_type_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : advanced_details[0]?.vendor_type_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : advanced_details[0]?.vendor_type_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.gst_class_name[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.gst_class_name[language]}:</span>
                                        {advanced_details[0]?.gst_class ==
                                            constants?.GSTCLASS?.REGISTERED ? (
                                            <span>{VendorRegLang?.registered[language]}</span>
                                        ) : advanced_details[0]?.gst_class ==
                                            constants?.GSTCLASS?.NOTREGISTERED ? (
                                            <span>{VendorRegLang?.not_registered[language]}</span>
                                        ) : advanced_details[0]?.gst_class ==
                                            constants?.GSTCLASS?.COMPOUNDINGSCHEME ? (
                                            <span>{VendorRegLang?.componding_scheme[language]}</span>
                                        ) : advanced_details[0]?.gst_class ==
                                            constants?.GSTCLASS?.MARKETER ? (
                                            <span>{VendorRegLang?.marketer[language]}</span>
                                        ) : advanced_details[0]?.gst_class ==
                                            constants?.GSTCLASS?.PSUGOVT ? (
                                            <span>{VendorRegLang?.psu_gov_org[language]}</span>
                                        ) : null}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            advanced_details[0]?.gst_class_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (<div
                                                className="one"
                                                onClick={() => {
                                                    {
                                                        setIsShowing(true);
                                                        docFilter(constants?.ADVANCED_VERIFY?.GSTCLASSNAME)
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {" "}<i className="fa-solid fa-upload"></i>

                                            </div>)
                                        }
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {advanced_details[0]?.gst_class_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : advanced_details[0]?.gst_class_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : advanced_details[0]?.gst_class_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table className="w-100 table-striped">
                            <tbody>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.company_name[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.company_name[language]}:</span>
                                        {vendor_data[0]?.name}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            vendor_data[0]?.company_name_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowing(true);
                                                            docFilter(constants?.ADVANCED_VERIFY?.COMPANYNAME)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )
                                        }
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {vendor_data[0]?.company_name_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : vendor_data[0]?.company_name_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : vendor_data[0]?.company_name_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.company_email[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.company_email[language]}:</span>
                                        {vendor_data[0]?.email}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            vendor_data[0]?.company_email_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowing(true);
                                                            docFilter(constants?.ADVANCED_VERIFY?.COMPANYEMAIL)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>

                                                </div>
                                            )
                                        }
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {vendor_data[0]?.company_email_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : vendor_data[0]?.company_email_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : vendor_data[0]?.company_email_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.contact_number[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.contact_number[language]}:</span>
                                        {vendor_data[0]?.phone}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            vendor_data[0]?.company_contact_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowing(true);
                                                            docFilter(constants?.ADVANCED_VERIFY?.COMPANYCONTACTNUMBER)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )
                                        }
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {vendor_data[0]?.company_contact_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : vendor_data[0]?.company_contact_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : vendor_data[0]?.company_contact_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.company_inc_date[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.company_inc_date[language]}:</span>
                                        {advanced_details[0]?.incorporation_date
                                            ? moment(advanced_details[0]?.incorporation_date).format(
                                                "DD-MM-YYYY"
                                            )
                                            : null}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.action[language]}:</span>
                                        <div className="action-flex-btn">
                                            <a
                                                href={doc_data}
                                                target="_blank"
                                                rel="noreferrer"
                                                title="View"
                                                onClick={() =>
                                                    docFilter(constants.ADVANCED_VERIFY.INCORP)
                                                }
                                            >
                                                <i className="bi bi-eye"></i>
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            advanced_details[0]?.company_contact_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowing(true);
                                                            docFilter(constants?.ADVANCED_VERIFY?.INCORP)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {vendor_data[0]?.company_incorp_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : vendor_data[0]?.company_incorp_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : vendor_data[0]?.company_incorp_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.company_reg_add[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.company_reg_add[language]}:</span>
                                        <span className="table-data">
                                            {" "}
                                            {com_reg?.city},{""}
                                            {com_reg?.district}, {com_reg?.state}, {com_reg?.landmark},
                                            {""}
                                            {com_reg?.country}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.action[language]}:</span>
                                        <div className="action-flex-btn">
                                            <a
                                                href={doc_data}
                                                target="_blank"
                                                rel="noreferrer"
                                                title="View"
                                                onClick={() =>
                                                    docFilter(constants.ADVANCED_VERIFY.AADRESS_PROOF)
                                                }
                                            >
                                                <i className="bi bi-eye"></i>
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            com_reg?.company_address_proof_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowings(true);
                                                            docFilter(constants.ADVANCED_VERIFY.AADRESS_PROOF)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {com_reg?.company_address_proof_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : com_reg?.company_address_proof_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : com_reg?.company_address_proof_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.company_comm_add[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.company_comm_add[language]}:</span>
                                        <span className="table-data">
                                            {com_add?.city},{""}
                                            {com_add?.district}, {com_add?.state}, {com_add?.landmark},
                                            {""}
                                            {com_add?.country}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            com_add?.company_communication_address_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowings(true);
                                                            docFilter(constants.ADVANCED_VERIFY.COMPANYCOMMUNICATIONADDRESS)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )}
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {com_add?.company_communication_address_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : com_add?.company_communication_address_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : com_add?.company_communication_address_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {/* 

                    {emd.length !== 0 ? (
                        <Table className="w-100">
                            <tbody>
                                <tr>
                                    <td>EMD</td>
                                    <td>{emd?.amount}</td>
                                    <td>
                                        <div className="action-flex-btn">
                                            {doc_data && (
                                                <a
                                                    href={doc_data}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() =>
                                                        docFilter(constants.ADVANCED_VERIFY.EMD)
                                                    }
                                                >
                                                    <i className="bi bi-eye text-warning"></i>
                                                </a>
                                            )}


                                        </div>
                                    </td>
                                    <td>

                                    </td>
                                    <td>
                                        {emd?.emd_verified == constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">Approved</Badge>
                                        ) : emd?.emd_verified == constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">Rejected</Badge>
                                        ) : emd?.emd_verified == constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">Reuploaded</Badge>
                                        ) : (
                                            <Badge color="warning">Pending</Badge>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    ) : (
                        <Table className="w-100">
                            <tbody>
                                <tr>
                                    <td>No EMD Added</td>
                                </tr>
                            </tbody>
                        </Table>
                    )} */}
                        <Table className="w-100 table-striped">
                            <tbody>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.contact_person_name[language]}</td>
                                    <td><span className="mobile-heading">{VendorRegLang?.contact_person_name[language]}:</span>
                                        {advanced_details[0]?.point_contact_name}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            advanced_details[0]?.contact_person_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowing(true);
                                                            docFilter(constants.ADVANCED_VERIFY.CONTACTPERSONNAME)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>

                                                </div>
                                            )}
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {advanced_details[0]?.contact_person_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : advanced_details[0]?.contact_person_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : advanced_details[0]?.contact_person_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}

                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.contact_number[language]}</td>
                                    <td><span className="mobile-heading">{VendorRegLang?.contact_number[language]}:</span>
                                        {advanced_details[0]?.point_contact_number}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            advanced_details[0]?.contact_number_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowing(true);
                                                            docFilter(constants.ADVANCED_VERIFY.CONTACTNUMBER)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )}
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {advanced_details[0]?.contact_number_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : advanced_details[0]?.contact_number_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : advanced_details[0]?.contact_number_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="large-screen-view">{VendorRegLang?.email_id[language]}</td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.email_id[language]}:</span>
                                        {advanced_details[0]?.point_email_id}
                                    </td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.verification[language]}:</span>
                                        {
                                            advanced_details[0]?.email_verified ==
                                            constants.BASIC_VERIFY.REJECTED && (
                                                <div
                                                    className="one"
                                                    onClick={() => {
                                                        {
                                                            setIsShowing(true);
                                                            docFilter(constants.ADVANCED_VERIFY.EMAILID)
                                                        }
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {" "}<i className="fa-solid fa-upload"></i>
                                                </div>
                                            )}
                                    </td>
                                    <td className="large-screen-view"></td>
                                    <td>
                                        <span className="mobile-heading">{VendorRegLang?.status[language]}:</span>
                                        {advanced_details[0]?.email_verified ==
                                            constants.BASIC_VERIFY.APPROVED ? (
                                            <Badge color="success">{VendorRegLang?.approved[language]}</Badge>
                                        ) : advanced_details[0]?.email_verified ==
                                            constants.BASIC_VERIFY.REJECTED ? (
                                            <Badge color="danger">{VendorRegLang?.rejected[language]}</Badge>
                                        ) : advanced_details[0]?.email_verified ==
                                            constants.BASIC_VERIFY.REUPLOAD ? (
                                            <Badge color="warning">{VendorRegLang?.reuploaded[language]}</Badge>
                                        ) : (
                                            <Badge color="warning">{VendorRegLang?.pending[language]}</Badge>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={AdvaDetailsValidationSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    errors,
                    touched,
                    isSubmitting,
                    handleReset,
                    setFieldValue,
                }) => {
                    return (
                        <AdvancedReDocUpload
                            isShowing={isShowing}
                            setIsShowing={setIsShowing}
                            handleSubmit={handleSubmit}
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            handleReset={handleReset}
                            type={type}
                        />
                    );
                }}
            </Formik>
            <Formik
                initialValues={pincodeValues}
                enableReinitialize
                validationSchema={pincodeValidation}
                onSubmit={(values, actions) => {
                    handleSubmits(values, actions);
                }}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    errors,
                    touched,
                    isSubmitting,
                    handleReset,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched
                }) => {
                    {
                       
                    }
                    return (
                        <AddressModal
                            isShowing={isShowings}
                            setIsShowing={setIsShowings}
                            handleSubmit={handleSubmit}
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            setFieldError={setFieldError}
                            setFieldTouched={setFieldTouched}
                            touched={touched}
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            handleReset={handleReset}
                        />
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}