export const Admin_services:any = {

    //Admin---Services---AMC

    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    Services: {
        en: 'Services',
        ka: 'ಸೇವೆಗಳು'
    },
    AMC: {
        en: 'AMC',
        ka: 'ಎಎಂಸಿ'
    },
    SlNo: {
        en: 'Sl No',
        ka: 'ಕ್ರ.ಸಂ'
    },
    TenderID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    Invoice: {
        en: 'Invoice',
        ka: 'ಚಲಾನೆ'
    },
    Department: {
        en: 'Department',
        ka: 'ಇಲಾಖೆ'
    },
    Product: {
        en: 'Product',
        ka: 'ಉತ್ಪನ್ನ'
    },
    gst_class_name: {
        en: 'GST Class Name',
        ka: 'ಜಿಎಸ್ಟಿ ವರ್ಗದ ಹೆಸರು'
    },
    Type: {
        en: 'Type',
        ka: 'ಪ್ರಕಾ'
    },
    ValidFrom: {
        en: 'Valid From',
        ka: 'ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ'
    },
    ValidTo: {
        en: 'Valid To',
        ka: 'ಗೆ ಮಾನ್ಯವಾಗಿದೆ'
    },
    InvoiceId: {
        en: 'Invoice Id',
        ka: 'ಸರಕುಪಟ್ಟಿ ಐಡಿ'
    },
    Service: {
        en: 'Service',
        ka: 'ಸೇವೆ'
    },
    Hardware: {
        en: 'Hardware',
        ka: 'ಹಾರ್ಡ್ವೇರ್'
    },
    Onsite: {
        en: 'Onsite',
        ka: 'ಪ್ರದೇಶದಲ್ಲಿ'
    },
    SelectGSTclass: {
        en: 'Select GST class',
        ka: 'ಜಿಎಸ್ಟಿ ವರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
    },
    Showing: {
        en: 'Showing',
        ka: 'ತೋರಿಸುವ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ನ'
    },
    entries: {
        en: 'entries',
        ka: 'ಪ್ರವೇಶಗಳು'
    },

    // Admin--Services--QAVendor--AddQAVendorLayout

    ThirdPartyInspection: {
        en: 'Third Party Inspection',
        ka: 'ಮೂರನೇ ಪಕ್ಷದ ಪರೀಕ್ಷೆ'
    },
    AddThirdPartyInspector: {
        en: 'Add Third Party Inspector',
        ka: 'ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಇನ್ಸ್‌ಪೆಕ್ಟರ್ ಅನ್ನು ಸೇರಿಸಿ'
    },
    Name: {
        en: 'Name',
        ka: 'ಹೆಸರು'
    },
    EmailID: {
        en: 'Email ID',
        ka: 'ಇಮೇಲ್ ಐಡಿ'
    },
    ContactNumber: {
        en: 'Contact Number',
        ka: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ'
    },
    RegistrationNumber: {
        en: 'Registration ID',
        ka: 'ನೋಂದಣಿ ಐಡಿ'
    },
    VendorType: {
        en: 'Vendor Type',
        ka: 'ವೆಂಡರ್ ಪ್ರಕಾರ'
    },
    VendorName: {
        en: 'Vendor Name',
        ka: 'ವೆಂದರ್ ಹೆಸರು'
    },
    CreatedDate: {
        en: 'Created Date',
        ka: 'ರಚಿಸಿದ ದಿನಾಂಕ'
    },
    Status: {
        en: 'Status',
        ka: 'ಸ್ಥಿತಿ'
    },
    Categoryofvendors: {
        en: 'Category of vendors',
        ka: 'ವೆಂಡರ್ಸ್ ವರ್ಗ'
    },
    RegistrationDate: {
        en: 'Registration Date',
        ka: 'ನೋಂದಣಿ ದಿನಾಂಕ'
    },
    SentInvitation: {
        en: 'Sent Invitation',
        ka: 'ಆಹ್ವಾನವನ್ನು ಕಳುಹಿಸಲಾಗಿದೆ'
    },
    Invited: {
        en: 'Invited',
        ka: 'ಆಹ್ವಾನಿಸಲಾಗಿದೆ'
    },
    Uploaded: {
        en: 'Uploaded',
        ka: 'ಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿದೆ'
    },
    UnderVerification: {
        en: 'Under Verification',
        ka: 'ಪರಿಶೀಲನೆಯ ಕೆಳಗೆ'
    },
    Verified: {
        en: 'Verified',
        ka: 'ಪರಿಶೀಲಿಸಲಾಗಿದೆ'
    },
    Accepted: {
        en: 'Accepted',
        ka: 'ಸ್ವೀಕರಿಸಲಾಗಿದೆ'
    },
    Pending: {
        en: 'Pending',
        ka: 'ಬಾಕಿ'
    },
    Inactive: {
        en: 'Inactive',
        ka: 'ನಿಷ್ಕ್ರಿಯ'
    },



    // Admin--Services--QAVendor--AddQAVendorModal--AddQAVendorModal

    GSTClass: {
        en: 'GST Class',
        ka: 'ಜಿಎಸ್ಟಿ ತರಗತಿ'
    },
    
    PanNumber: {
        en: 'Pan Number',
        ka: 'ಪ್ಯಾನ್ ಸಂಖ್ಯೆ'
    },
    GSTNumber: {
        en: 'GST Number',
        ka: 'ಜಿಎಸ್ಟಿ ಸಂಖ್ಯೆ'
    },
    CompanyName: {
        en: 'Company Name',
        ka: 'ಕಂಪನಿಯ ಹೆಸರು'
    },
    CompanyEmail: {
        en: 'Company Email',
        ka: 'ಕಂಪನಿ ಇಮೇಲ್'
    },
    CompanyType: {
        en: 'Company Type',
        ka: 'ಕಂಪನಿ ಪ್ರಕಾರ'
    },
    SelectCompanyType: {
        en: 'Select Company Type',
        ka: 'ಸಂಸ್ಥೆಯ ಪ್ರಕಾರ ಆಯ್ಕೆಮಾಡಿ'
    },
    CompanyIncorporationDate: {
        en: 'Company Incorporation Date',
        ka: 'ಕಂಪನಿಯ ಸಂಯೋಜನೆಯ ದಿನಾಂಕ'
    },
    EmpanelmentDate: {
        en: 'Empanelment Date',
        ka: 'ಇಮ್‌ಪ್ಯಾನಲ್ಮೆಂಟ್ ದಿನಾಂಕ'
    },
    EmpanelmentExpiryDate: {
        en: 'Empanelment Expiry Date',
        ka: 'ಎಂಪನೆಲ್‌ಮೆಂಟ್ ಮುಕ್ತಾಯ ದಿನಾಂಕ'
    },
    CompanyRegistrationNumber: {
        en: 'Company Registration Number',
        ka: 'ಕಂಪನಿ ನೋಂದಣಿ ಸಂಖ್ಯೆ'
    },
    ContactPersonName: {
        en: 'Contact Person Name',
        ka: 'ಸಂಪರ್ಕ ವ್ಯಕ್ತಿಯ ಹೆಸರು'
    },
    ContactPersonNumber: {
        en: 'Contact Person Number',
        ka: 'ಸಂಪರ್ಕ ವ್ಯಕ್ತಿ ಸಂಖ್ಯೆ'
    },

     // Admin--Services--Tickets--TicketLayout

    OpenTickets: {
        en: 'Open Tickets',
        ka: 'ತೆರವಲು ಟಿಕೆಟ್ಟುಗಳು'
    },
    PendingTickets: {
        en: 'Pending Tickets',
        ka: 'ಬಾಕಿಯಲ್ಲಿರುವ ಟಿಕೆಟ್ಗಳು'
    },
    ClosedTickets: {
        en: 'Closed Tickets',
        ka: 'ಮುಚ್ಚಿದ ಟಿಕೆಟ್‌ಗಳು'
    },

     // Admin--Services--Tickets--Tabs--ClosedTickets

     SelectPriority: {
        en: 'Select Priority',
        ka: 'ಆದ್ಯತೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    SelectType: {
        en: 'Select Type',
        ka: 'ಆಯ್ಕೆ ಪ್ರಕಾರ'
    },
    Reset: {
        en: 'Reset',
        ka: 'ಮರುಹೊಂದಿಸಿ'
    },
    Requestby: {
        en: 'Request by',
        ka: 'ಕೇಳಿಕೆ ಮೂಲಕ'
    },
    Products: {
        en: 'Products',
        ka: 'ಉತ್ಪನ್ನಗಳು'
    },
    Priority: {
        en: 'Priority',
        ka: 'ಪ್ರಾಥಮಿಕತೆ'
    },
    Date: {
        en: 'Date',
        ka: 'ದಿನಾಂಕ'
    },
    low: {
        en: 'low',
        ka: 'ಕಡಿಮೆ'
    },
    Medium: {
        en: 'Medium',
        ka: 'ಮಧ್ಯಮ'
    },
    High: {
        en: 'High',
        ka: 'ಹೆಚ್ಚು'
    },
    Add_Vendor: {
        en: 'Add Vendor',
        ka: 'ವೆಂಡರ್ ಸೇರಿಸು'
    },

  
}