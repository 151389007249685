export const LoginViewRegLang:any = {
    // Login View
    keonics_info: {
        en: "KEONICS focuses on setting up world-class infrastructure for the electronics and IT related industries in the state",
        ka: "ಕಿಯೋನಿಕ್ಸ್ ರಾಜ್ಯದ ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಮತ್ತು ಐಟಿ ಸಂಬಂಧಿತ ಕ್ಷೇತ್ರಗಳಿಗೆ ವಿಶ್ವ ಶ್ರೇಷ್ಠ ಅಂತರ್ಜಾಲ ಅಂಗಡಿ ರಚಿಸುವುದರ ಮೇಲೆ ಹೆಚ್ಚಿನ ಗಮನ ಹರಿಸುತ್ತದೆ"
    },
    login: {
        en: "Login",
        ka: "ಲಾಗಿನ್ ಮಾಡಿ"
    },
    credentials_info: {
        en: "Enter your credentials to access the system",
        ka: "ವ್ಯವಸ್ಥೆಗೆ ಪ್ರವೇಶಕ್ಕೆ ನಿಮ್ಮ ಪರಿಚಯಗಳನ್ನು ನಮೂದಿಸಿ"
    },
    email_or_phone_number: {
        en: "Email or Phone Number",
        ka: "ಇಮೇಲ್ ಅಥವಾ ಫೋನ್ ಸಂಖ್ಯೆ"
    },
    password: {
        en: "Password",
        ka: "ಗುಪ್ತಪದ"
    },
    forgot_your_password: {
        en: "Forgot your password?",
        ka: "ನಿಮ್ಮ ಗುಪ್ತಪದವನ್ನು ಮರೆತಿರುವಿರಾ?"
    },
    dont_you_have_an_account: {
        en: "Don't you have an account?",
        ka: "ನಿಮ್ಮ ಬಳಿ ಖಾತೆ ಇಲ್ಲವೇ?"
    },
    dept_sign_up: {
        en: "Department Sign Up",
        ka: "ಇಲಾಖೆ ಸೈನ್ ಅಪ್"
    },
    vendor_sign_up: {
        en: "Vendor Sign Up",
        ka: "ಮಾರಾಟಗಾರರ ಸೈನ್ ಅಪ್"
    },
    sign_in: {
        en: "Sign in",
        ka: "ಸೈನ್ ಇನ್"
    },
    user_signed_in: {
        en: "User Signed in",
        ka: "ಬಳಕೆದಾರರು ಸೈನ್ ಇನ್ ಮಾಡಿದ್ದಾರೆ"
    },
    credentials_not_match: {
        en: "Credentials not match!",
        ka: "ರುಜುವಾತುಗಳು ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ!"
    },
    something_went_wrong: {
        en: "Something went wrong",
        ka: "ಏನೋ ತಪ್ಪಾಗಿದೆ"
    },
    // Vendor Login View
    registration_id: {
        en: "Registration ID",
        ka: "ನೋಂದಣಿ ID"
    },
    // Login Validation
    password_is_required: {
        en: "Password is required",
        ka: "ಗುಪ್ತಪದದ ಅಗತ್ಯವಿದೆ"
    }, 
    password_min_limit: {
        en: "Password must be at least 8 characters",
        ka: "ಪಾಸ್ವರ್ಡ್ ಕನಿಷ್ಠ 8 ಅಕ್ಷರಗಳಾಗಿರಬೇಕು"
    },
    password_max_limit: {
        en: "Password must not exceed 40 characters",
        ka: "ಪಾಸ್ವರ್ಡ್ 40 ಅಕ್ಷರಗಳನ್ನು ಮೀರಬಾರದು"
    },
    email_or_phone_req: {
        en: "Email / Phone is required",
        ka: "ಇಮೇಲ್ / ಫೋನ್ ಅಗತ್ಯವಿದೆ"
    },

    // Forgot Password
    unexpected_error: {
        en: "Unexpected Error Occured",
        ka: "ಅನಿರೀಕ್ಷಿತ ದೋಷ ಸಂಭವಿಸಿದೆ"    
    },
    forgot_password: {
        en: "Forgot Password",
        ka: "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ"    
    },
    email_info: {
        en: "Please enter the email address you had used to register",
        ka: "ದಯವಿಟ್ಟು ನೀವು ನೋಂದಾಯಿಸಲು ಬಳಸಿದ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ"    
    },
    email: {
        en: "Email ID",
        ka: "ಇಮೇಲ್ ಐಡಿ"    
    },
    enter_email: {
        en: "Enter Email ID",
        ka: "ಇಮೇಲ್ ಐಡಿ ನಮೂದಿಸಿ"    
    },
    send_link: {
        en: "Send Link",
        ka: "ಲಿಂಕ್ ಕಳುಹಿಸಿ"    
    },
    save: {
        en: "Save",
        ka: "ಉಳಿಸಿ"  
    },
    password_mismatch: {
        en: "Password & Re-type password is mismatch",
        ka: "ಪಾಸ್‌ವರ್ಡ್ ಮತ್ತು ಮರು-ಟೈಪ್ ಪಾಸ್‌ವರ್ಡ್ ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ"  
    },
    set_new_password: {
        en: "Set New Password",
        ka: "ಹೊಸ ಪಾಸ್ವರ್ಡ್ ಹೊಂದಿಸಿ"  
    },
    create_secure_password: {
        en: "Please create a secure password",
        ka: "ದಯವಿಟ್ಟು ಸುರಕ್ಷಿತ ಪಾಸ್‌ವರ್ಡ್ ರಚಿಸಿ"  
    },
    new_password: {
        en: "New Password",
        ka: "ಹೊಸ ಪಾಸ್ವರ್ಡ್"  
    },
    re_type_password: {
        en: "Re-type Password",
        ka: "ಗುಪ್ತಪದವನ್ನು ಮತ್ತೆ ನಮೂದಿಸಿ"  
    },
    update_password_successfully: {
        en: "Password Updated Successfully",
        ka: "ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ"
    },

    // Vendor Set New Password
    email_send_successfully: {
        en: "Email send successfully",
        ka: "ಇಮೇಲ್ ಕಳುಹಿಸಲು ಯಶಸ್ವಿಯಾಗಿ"
    },
} 