import React, { useContext } from "react";
import { convertISODateToDateTime } from '../../../../../../../Library/Constants/helpers'
import { Context } from "../../../../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../../../../Library/LanguageConversion/Department_Products";

const CustomOrderModalView = ({ selectedOrder }: any) => {
    const { language, setLanguage } = useContext(Context);
    return (
        <>
            <table className="table table-hover table-bordered ">

                <thead>
                    <tr className='sticky'>
                        <th className='col-lg-4'>{DepartmentProducts?.Product[language]}</th>
                        <th className='col-lg-8'><span className="text-success">
                            {selectedOrder?.product_name}
                        </span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{DepartmentProducts?.OrderID[language]}</td>
                        <td>{selectedOrder?.custom_order_id}</td>
                    </tr>
                    {selectedOrder?.ref_links && <tr>
                        <td>{DepartmentProducts?.Reference[language]}</td>
                        <td>
                            <a href={selectedOrder?.ref_links} target="_blank" rel="noreferrer" >
                                {selectedOrder?.ref_links}
                            </a>
                        </td>
                    </tr>}
                    <tr>
                        <td>{DepartmentProducts?.Registered_Date[language]}</td>
                        <td>{convertISODateToDateTime(selectedOrder?.created_at)}</td>
                    </tr>
                    <tr >
                        <td colSpan={2}>{DepartmentProducts?.Specifications[language]}</td>
                    </tr>
                    {
                        selectedOrder?.custom_order_product_spec?.map((itm: any, i: number) => {
                            return (
                                <React.Fragment key={i}>
                                    {itm?.spec_value && <tr >
                                        <td>{itm?.spec_key}</td>
                                        <td>{itm?.spec_value}</td>
                                    </tr>}
                                </React.Fragment>
                            )
                        })
                    }

                    <tr>
                        <td>{DepartmentProducts?.Comments[language]}</td>
                        <td>{selectedOrder?.comments}</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default CustomOrderModalView