import {useContext} from 'react';
import {
  List
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { Context } from '../../../Components/Context/Context';
import { Department_drafts } from '../../../Library/LanguageConversion/Department_drafts';


export default function PaymentDrafts(props: any) {
  const{language,setLanguage}=useContext(Context)

  return (
    <>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink
              to="/department/draft"
            >
               {Department_drafts?.Drafts[language]}
            </NavLink>
            <NavLink
              to="/department/workorder"
            >
              {Department_drafts?.Work_Order[language]}
            </NavLink>
           
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link
                className="active"
                to="/department/dashboard"
              >
                {Department_drafts?.Dashboard[language]}

              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b> {Department_drafts?.Drafts[language]}</b>
            </li>
          </List>
        </div>
        {
          props?.children
        }
      </div>
    </>
  );
}
