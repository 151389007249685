import React,{useContext} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button, List } from "reactstrap";
import { Admin_addtendor } from "../../../Library/LanguageConversion/Admin_addtendor";
import { Context } from "../../../Components/Context/Context";

export default function RateContractLayouts(props: any) {
  const { pathname } = useLocation();
  const {language,setLanguage} = useContext(Context)
  
  return (
    <React.Fragment>
      {/* inner contents starts here */}
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumbs */}
          <List className="breadcrumb">
            <li>
              <Link to="/dashboard" className="active">
              {Admin_addtendor?.Dashboard?.[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>{Admin_addtendor?.Tender?.[language]}</b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
