import React, { useEffect, useContext } from "react";
import { useState, useMemo } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  List,
} from "reactstrap";

import { Link, useLocation } from "react-router-dom";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import { API_URL } from "../../../Library/ApiSettings";
import PaymentModal from "./PaymentModal";
import ProductDetailsModal from "../PurchaseOrder/productDetailsModal";
import PaymentPendingModal from "./PaymentPendingModal";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

export default function PaymentLists(props: any) {
  const [loading, setLoading] = useState(false);
  const [received_order, setReceivedOrder] = useState([]);
  const [isShowings, setIsShowings] = useState(false);
  const [getProductdet, setgetProductdet] = useState<any>();
  const [data, setData] = useState("");
  const [datas, setDatas] = useState("");
  const [isShowing, setIsShowing] = useState(false);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [order_view, setOrderView] = useState([]);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [values, setValues] = useState<any>();
  const { language, setLanguage } = useContext(Context);
  const [value, setValue] = useState({
    id: null,
  });

  useEffect(() => {
    getReceivedOrder(currentPage);
  }, [currentPage]);
  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getReceivedOrder(1);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  // ========================================================== Get Recieved Order ==========================================================

  const getReceivedOrder = (page: any) => {
    setLoading(true);
    api
      .get(
        `/order/get-confirmed-orders?status=6&page=${currentPage}&search_value=${search}&check=6`,
        true
      )
      .then(async function ([success, response]) {
        setReceivedOrder(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ================================================================ View ==================================================================
  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-order-details?id=${order_id}&search=${search}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message);
        setIsShowing(true);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const removeTags = (str: any) => {
    const regex = /(<([^>]+)>)/gi;
    // const result = props?.data?.description.replace(regex, '');
    if (str === null || str === "") return false;
    else str = str?.toString();
    //    let all= str?.replace(/(<<([^>]+)>>)/g, ' ');
    let all = str?.replace(/(<br>)/g, " ,");
    let alls = all?.replace(/(<([^>]+)>)/g, "");
    let val = alls.split(",");
    var vals: any[] = [];
    val.map((items: any, index: any) => {
      var vall = val[index].split(":");

      vals.push([{ key: vall[0], val: vall[1] }]);
    });
    setValues(vals);

    return vals;
  };

  const handleSubmit = (values: any, actions: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(
      `${API_URL}/order/purchase-order-download/${value?.id}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "workorder.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setIsShowing(false);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  return (
    <>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap"></div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            <div className="search-widget-outer">
              <Form role="search">
                <InputGroup className="search-group">
                  <InputGroupText title="Search" className="search-icon" id="search">
                    <i
                      className="fa-sharp fa-solid fa-magnifying-glass"
                    />
                  </InputGroupText>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Product Name"
                    aria-label="Username"
                    aria-describedby="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyPress={(event) => {
                      var key = event.keyCode || event.which;
                      if (key === 13) {
                        event.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
              </Form>
            </div>
            <div className="table-wrap responsive-table">
              <Table className="w-100 table-striped">
                <thead>
                  <tr>
                    <th>{VendorRegLang?.sl_no[language]}</th>
                    <th>{VendorRegLang?.products[language]}</th>
                    <th>{VendorRegLang?.quantity[language]}</th>
                    <th>{VendorRegLang?.order_by[language]}</th>
                    <th>{VendorRegLang?.price[language]}</th>
                    <th>{VendorRegLang?.action[language]}</th>
                  </tr>
                </thead>
                <tbody>
                  {received_order?.length !== 0 ? (
                    <>
                      {received_order?.map((item: any, index: any) => {
                        return (
                          // item?.payment_detail == null ?

                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <span className="mobile-heading">{VendorRegLang?.sl_no[language]}:</span>
                                <span className="table-data">{index + 1}</span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {VendorRegLang?.products[language]}:
                                </span>
                                <span className="table-data">
                                  <Link
                                    to="#"
                                    className="blue-text"
                                    title="View Product"
                                    onClick={() => {
                                      setIsShowings(true);
                                      setgetProductdet(
                                        item?.order_details?.features
                                      );
                                      removeTags(item?.order_details?.features);
                                    }}
                                  >
                                    {item?.order_details?.name}
                                  </Link>

                                  {/* <p
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                item?.order_details?.features,


                                                                            }}
                                                                            ></p> */}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {VendorRegLang?.quantity[language]}:
                                </span>
                                <span className="table-data">
                                  {item?.order_details?.quantity}
                                </span>
                              </td>
                              {/* <td>
                                <span className="mobile-heading">
                                  Transcation Id:
                                </span>
                                <span className="table-data">
                                  {item?.payment_detail?.transaction_id}
                                </span>
                              </td> */}
                              <td>
                                <span className="mobile-heading">
                                  {VendorRegLang?.order_by[language]}:
                                </span>
                                <span className="table-data">
                                  {item?.pf_invoice?.user_data?.email}
                                  <p>{item?.pf_invoice?.user_data?.phone}</p>
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">{VendorRegLang?.price[language]}:</span>
                                <span className="table-data">
                                  ₹{item?.total_amount}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">{VendorRegLang?.action[language]}:</span>
                                <span className="table-data primary-text">
                                  <Link
                                    to="#"
                                    className="primary-text"
                                    onClick={() => {
                                      setIsShowing(true);
                                      // getSingleOrder(item?.id);
                                      setValue({
                                        ...value,
                                        id: item?.id,
                                      });
                                      setData(item);
                                      setDatas(item?.order_details?.features);
                                      removeTags(item?.order_details?.features);
                                    }}
                                  >
                                    <i
                                      title="View"
                                      className="bi bi-eye lg"
                                    ></i>
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          </React.Fragment>
                        //   :
                        //   <tr>
                        //   <td
                        //     className="text-center px-2 py-5 empty-text"
                        //     colSpan={7}
                        //   >
                        //     {loading ? (
                        //       <div className="d-flex justify-content-center">
                        //         <Loader />
                        //       </div>
                        //     ) : (
                        //       `${VendorRegLang?.no_data_found[language]}`                      
                        //     )}
                        //   </td>
                        // </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td
                          className="text-center px-2 py-5 empty-text"
                          colSpan={7}
                        >
                          {loading ? (
                            <div className="d-flex justify-content-center">
                              <Loader />
                            </div>
                          ) : (
                            `${VendorRegLang?.no_data_found[language]}`                      
                            )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            {pageData && (
              <div className="table-footer-wrap">
                <small>
                  {VendorRegLang?.showing[language]} {pageData?.from != null ? pageData?.from : 0} {VendorRegLang?.to[language]}{" "}
                  {pageData?.to != null ? pageData?.to : 0} {VendorRegLang?.of[language]}{" "}
                  {pageData?.total != null ? pageData?.total : 0} {VendorRegLang?.entries[language]}
                </small>
                <div className="pagination">
                  <CustomPagination
                    totalItems={pageData?.total}
                    itemsPerPage={pageData?.per_page}
                    activePage={pageData?.current_page}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isShowing == true ? (
        <PaymentPendingModal
          isShowing={isShowing}
          setIsShowing={setIsShowing}
          order_view={order_view}
          handleSubmit={handleSubmit}
          data={data}
          datas={values}
        />
      ) : null}

      <ProductDetailsModal
        isShowing={isShowings}
        setIsShowings={setIsShowings}
        values={values}
      />
    </>
  );
}