import { Row, Col } from "reactstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { SetStateAction, useEffect, useState, useContext } from "react";
import Paypal from "../../../Components/PaymentGateway";
import ChequeModal from "./ChequeModal";
import { Formik } from "formik";
import BankAccountModal from "./BankAccountModal";
import {
  chellanValidation,
  AccountValidation,
  ChequeValidation,
} from "./ChellanValidation";
import RightSection from "../../Department/Products/PaymentMethodComponents/RightSection";
import PaymentGateWay from "./PaymentGateWay";
import { api } from "../../../Library/CustomApi";
import axios from "axios";
import { API_URL } from "../../../Library/ApiSettings";
import { toast } from "react-toastify";
import ChellanModal from "./ChellanModal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PaymentTypesSelector from "../../Department/Products/PaymentMethodComponents/PaymentTypesSelector";
import constants from "../../../Library/Constants/Constants";
import PaymentItems from "./PaymentItems";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";
import AccountDetails from "../../Department/Products/PaymentMethodComponents/AccountDetails";

export default function Chellon() {
  const { language, setLanguage } = useContext(Context);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState<any>(0);
  const [isShowing, setIsShowing] = useState(false);
  const [amount, setAmount] = useState(state);
  const [isShowings, setIsShowings] = useState(false);
  const [isShowingChellan, setIsShowingChellan] = useState(false);
  const today: any = new Date().toJSON().slice(0, 10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState({});
  const [method_name, setMethodName] = useState("");
  const [method, setMethod] = useState<any>("");
  const [ifsc, setIfsc] = useState({
    ifsc: "",
  });
  const [succes_data, setSuccesData] = useState(false);
  const [data, setData] = useState([]);

  const initialValues_Cheque = {
    bank_name: "",
    account_holder_name: "",
    branch_name: "",
    ifsc_code: "",
    account_number: "",
    cheque_number: "",
    amount_data: "",
    file: "",
    account_name: "",
    
  };
  const initialValues_account = {
    bank_name: "",
    account_holder_name: "",
    branch_name: "",
    account_name: "",
    ifsc_code: "",
    account_number: "",
    amount_data: "",
    file: "",
    transaction_number:"",
    payment_date: ""

  };
  const initialValues_chellan = {
    // receipt_number: "",
    // file: "",
    // bsr_code: "",
    // payment_site: "",
    // payment_date: "",
    // chellan_number: "",
    // amount_data: "",

    bank_name: "",
    account_holder_name: "",
    branch_name: "",
    ifsc_code: "",
    account_number: "",
    chellan_number: "",
    amount_data: "",
    file: "",
    payment_date: "",

  };
  function paychange(statues: any) {
    return setIsOpen(true), setSelectedMethod(statues);
  }

  const get_Type_payment_method = (type: number) => {
    if (type === constants?.PAYMENT_TYPE?.BANK) {
      setMethod("1")
      setIsShowing(true);
      setMethodName("Bank Transfer Method");

    }
    if (type === constants?.PAYMENT_TYPE?.NETBANK) {
    }
    if (type === constants?.PAYMENT_TYPE?.CHEQUE) {
      setMethod("2")
      setIsShowings(true);
      setMethodName("Cheque Method");
    }
    if (type === constants?.PAYMENT_TYPE?.RTGS) {
      setMethod("6")
      setIsShowing(true);
      setMethodName("RTGS Method");
    }
    if (type === constants?.PAYMENT_TYPE?.NEFT) {
      setMethod("5")
      setIsShowing(true);
      setMethodName("NEFT Method");
    }
    if (type === constants?.PAYMENT_TYPE?.CHELLAN) {
      setMethod("3")
      setIsShowingChellan(true);
    }
  };
  //send bank Save
  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions);
  };
  const sendData = (values: any, actions: any) => {
    var dates: any = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;

    let type: any = 1;
    const formData = new FormData();
    formData.append("type", method);
    formData.append("order_id", "2");
    formData.append("file", values?.file);
    formData.append("ifsc_code", values?.ifsc_code);
    formData.append("account_name", values?.account_name);
    formData.append("bank_name", values?.bank_name);
    formData.append("branch_name", values?.branch_name);
    formData.append("account_holder_name", values?.account_holder_name);
    formData.append("account_number", values?.account_number);
    formData.append("transaction_id", values?.transaction_number);
    formData.append("amount", state?.amount);
    // formData.append("account_name", values?.account_name);
    formData.append("payment_date", dat);
    formData.append("payment_type", selectedMethod);
    formData.append("status", "1");
    formData.append("currency", "INR");
    formData.append("category", state?.category);


    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/account_add`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success(`${VendorRegLang?.payment_details_uploaded[language]}`); 
          setSuccesData(true);
          getData();
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/emd-details");
          setMethod("")

        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  //send cheque
  const handleSubmits = (values: any, actions: any) => {
    sendDatas(values, actions);
  };
  const sendDatas = (values: any, actions: any) => {
    var dates: any = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;

    let type: any = 2;
    const formData = new FormData();
    formData.append("type", method);
    formData.append("order_id", "2");
    formData.append("file", values?.file);
    formData.append("ifsc_code", values?.ifsc_code);
    formData.append("bank_name", values?.bank_name);
    formData.append("branch_name", values?.branch_name);
    formData.append("account_holder_name", values?.account_holder_name);
    formData.append("amount", state?.amount);
    formData.append("account_number", values?.account_number);
    formData.append("cheque_number", values?.cheque_number);
    formData.append("payment_date", dat);
    formData.append("payment_type", selectedMethod);
    formData.append("status", "1");
    formData.append("currency", "INR");
    formData.append("category", state?.category);


    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/account_add`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success(`${VendorRegLang?.cheque_uploaded_successfully[language]}`);
          setIsShowings(false);
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/emd-details");
          setMethod("")

        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  //send chellan

  const handleSubmitCheque = (values: any, actions: any) => {
    sendDataChellan(values, actions);
  };
  const sendDataChellan = (values: any, actions: any) => {
    var dates: any = new Date(values?.payment_date).toLocaleDateString(
      "en-GB",
      {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }
    );
    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;
    let type: any = 3;
    const formData = new FormData();
    formData.append("type", method);
    formData.append("order_id", "2");
    formData.append("file", values?.file);
    formData.append("ifsc_code", values?.ifsc_code);
    formData.append("bank_name", values?.bank_name);
    formData.append("branch_name", values?.branch_name);
    formData.append("account_holder_name", values?.account_holder_name);
    formData.append("account_number", values?.account_number);
    // formData.append("payment_site", values?.payment_site);
    formData.append("file", values?.file);
    formData.append("payment_date", dat);
    // formData.append("bsr_code", values?.bsr_code);
    // formData.append("transaction_id", values?.receipt_number);
    formData.append("amount", state?.amount);
    formData.append("challan_no", values?.chellan_number);
    formData.append("payment_type", selectedMethod);
    formData.append("status", "1");
    formData.append("currency", "INR");
    formData.append("category", state?.category);



    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/account_add`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success(`${VendorRegLang?.challan_uploaded_successfully[language]}`);
          setIsShowingChellan(false);
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/emd-details");
          setMethod("")
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  useEffect(() => {
    if (selectedMethod == constants?.PAYMENT_TYPE?.BANK) {
      getData();
    }
  }, [selectedMethod]);

  const getData = () => {
    api
      .get(`/account_list?type=1`, true)
      .then(async function ([success, response]) {
        setData(response?.data || []);
        setSuccesData(true);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <React.Fragment>
        {/* inner contents starts here */}
        <div className="right-content-wrap">
          <div className="proforma-checkout-wrap">
            <div className="custom-tabs">
              <NavLink to="/emd-details">
                <i className="fa-solid fa-arrow-left"></i> {VendorRegLang?.nrla_details[language]}
              </NavLink>
            </div>
            <Row className="gy-4">
              <Col lg={6}>
                <div className="choose-payment-outer-wrap">
                  <Col md={8}>
                    <div className="choose-payment-wrap">
                      <h5>{VendorRegLang?.choose_payment[language]}:</h5>
                      <div className="choose-list-wrap">
                        <PaymentItems
                          selectedMethod={selectedMethod}
                          setSelectedMethod={setSelectedMethod}
                          get_Type_payment_method={get_Type_payment_method}
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>
              <Col lg={6}>
                <div className="checkout-slip-wrap">
                  <div className="cart-total-left-wrap">
                    <div className="cart-total-left-inner">
                      <strong>{VendorRegLang?.date_of_payment[language]}</strong>
                      <p>{today}</p>
                    </div>
                    <div className="cart-total-left-inner">
                      <p className="mb-0">{VendorRegLang?.total[language]}:</p>
                      <h2 className="primary-text">₹{state?.amount}</h2>
                    </div>
                    <AccountDetails />
                    {selectedMethod === constants?.PAYMENT_TYPE?.BANK ? (
                      <PaymentGateWay
                        paymentMethod="Direct Bank Transfer"
                        id={state?.id}
                        datas={data}
                      />
                    ) : null}
                    {selectedMethod === constants?.PAYMENT_TYPE?.RTGS ? (
                      <PaymentGateWay
                        paymentMethod="RTGS"
                        id={state?.id}
                        datas={data}
                      />
                    ) : null}
                    {selectedMethod === constants?.PAYMENT_TYPE?.NEFT ? (
                      <PaymentGateWay
                        paymentMethod="NEFT"
                        id={state?.id}
                        datas={data}
                      />
                    ) : null}
                    {selectedMethod === constants?.PAYMENT_TYPE?.CHEQUE ? (
                      <PaymentGateWay
                        paymentMethod="Cheque"
                        id={state?.id}
                        datas={data}
                      />
                    ) : null}
                    {selectedMethod === constants?.PAYMENT_TYPE?.CHELLAN ? (
                      <PaymentGateWay
                        paymentMethod="Demand Draft"
                        id={state?.id}
                        datas={data}
                      />
                    ) : null}
                    {selectedMethod === constants?.PAYMENT_TYPE?.NETBANK ? (
                      <Paypal
                        amount={state?.amount}
                        purchaseType="challon"
                        order_id={state?.category}
                      />
                    ) : null}
                    <hr />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Formik
          initialValues={initialValues_account}
          validationSchema={AccountValidation}
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
            setFieldTouched
          }) => {
            return (
              <>
                <BankAccountModal
                  isShowing={isShowing}
                  setIsShowing={setIsShowing}
                  handleSubmit={handleSubmit}
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  setValue={setValue}
                  value={value}
                  handleReset={handleReset}
                  amount={amount?.amount}
                  heading={method_name}
                  setMethod = {setMethod}
                  setFieldTouched={setFieldTouched}


                />
              </>
            );
          }}
        </Formik>
        <Formik
          initialValues={initialValues_Cheque}
          validationSchema={ChequeValidation}
          onSubmit={(values, actions) => {
            handleSubmits(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
            setFieldTouched
          }) => {
            return (
              <ChequeModal
                isShowing={isShowings}
                setIsShowing={setIsShowings}
                handleSubmit={handleSubmit}
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                setValue={setValue}
                value={value}
                handleReset={handleReset}
                amount={state?.amount}
                setMethod = {setMethod}
                setFieldTouched={setFieldTouched}


              />
            );
          }}
        </Formik>
        <Formik
          initialValues={initialValues_chellan}
          validationSchema={chellanValidation}
          onSubmit={(values, actions) => {
            handleSubmitCheque(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
            setFieldTouched
          }) => {
            {
              console.log(errors, "ashddsg");
            }
            return (
              <ChellanModal
                isShowing={isShowingChellan}
                setIsShowing={setIsShowingChellan}
                handleSubmit={handleSubmit}
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                setValue={setValue}
                value={value}
                handleReset={handleReset}
                amount={state?.amount}
                setMethod = {setMethod}
                setFieldTouched={setFieldTouched}
              />
            );
          }}
        </Formik>
      </React.Fragment>
    </>
  );
}