export const Department_DeliveryAddress:any = {
    // Department/DeliveryAddress/AddressLayout.tsx
    DeliveryAddress: {
        en: 'Delivery Address',
        ka: 'ವಿತರಣಾ ವಿಳಾಸ'
    },
    VerificationPending: {
        en: 'Verification Pending',
        ka: 'ಪರಿಶೀಲನೆ ಬಾಕಿ'
    },
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ '
    },
    // Department/DeliveryAddress/Tabs/DeliveryAddress.tsx
    AddAddress: {
        en: 'Add Address',
        ka: 'ವಿಳಾಸವನ್ನು ಸೇರಿಸಿ'
    },
    AddNewAddress: {
        en: 'Add New Address',
        ka: 'ಹೊಸ ವಿಳಾಸವನ್ನು ಸೇರಿಸಿ'
    },
    BuildingNo: {
        en: 'Building No',
        ka: 'ಕಟ್ಟಡ ಸಂಖ್ಯೆ'
    },
    NoAddressFound: {
        en: 'No Address Found',
        ka: 'ಯಾವುದೇ ವಿಳಾಸ ಕಂಡು ಬರಲಿಲ್ಲ'
    },
    BuildingOfficeModuleNo: {
        en: 'Building No/Office No/Module No',
        ka: 'ಕಟ್ಟಡ ಸಂಖ್ಯೆ/ಕಚೇರಿ ಸಂಖ್ಯೆ/ಮೋಡ್ಯೂಲ್ ಸಂಖ್ಯೆ'
    },
    PinCode: {
        en: 'Pin Code',
        ka: 'ಪಿನ್ಕೋಡ್'
    },
    City: {
        en: 'City',
        ka: 'ನಗರ'
    },
    SelectCity: {
        en: 'Select City',
        ka: 'ನಗರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
    },
    Landmark: {
        en: 'Landmark',
        ka: 'ಗುರುತು ಸ್ಥಳ'
    },
    District: {
        en: 'District',
        ka: 'ಜಿಲ್ಲೆ'
    },
    State: {
        en: 'State',
        ka: 'ರಾಜ್ಯ'
    },
    Country: {
        en: 'Country',
        ka: 'ದೇಶ"'
    },
    EmailID: {
        en: 'E-mail ID',
        ka: 'ಇಮೇಲ್ ಐಡಿ'
    },
    PhoneNo: {
        en: 'Phone No',
        ka: 'ಫೋನ್ ಸಂಖ್ಯೆ'
    },
    AlternatePhoneNo: {
        en: 'Alternate Phone No',
        ka: 'ಪರ್ಯಾಯ ಫೋನ್ ಸಂಖ್ಯೆ'
    },
    WhatsappNo: {
        en: 'Whatsapp No',
        ka: 'ವಾಟ್ಸ್‌ಆ್ಯಪ್ ಸಂಖ್ಯೆ'
    },
    AddressType: {
        en: 'Address Type',
        ka: 'ವಿಳಾಸ ಪ್ರಕಾರ'
    },
    SelectAddressType: {
        en: 'Select Address Type',
        ka: 'ವಿಳಾಸ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
    },
    Submit: {
        en: 'Submit',
        ka: 'ಸಲ್ಲಿಸು'
    },

    // Department/DeliveryAddress/Tabs/VerificationPending.tsx
    ForPending: {
        en: 'For Pending',
        ka: 'ಸಲ್ಲಿಸು'
    },
    NoAddressesFound: {
        en: 'No Addresses Found',
        ka: 'ಯಾವುದೇ ವಿಳಾಸಗಳು ಕಂಡು ಬಂದಿಲ್ಲ'
    },
}

