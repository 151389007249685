import { Link, NavLink } from "react-router-dom";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import React, { useEffect, useState,useContext } from "react";
import ServiceRequestModal from "../ServiceRequestModal/ServiceRequestModal";
import Feedback from "../Feedback/Feedback";
import { api } from "../../../../Library/CustomApi";
import { Row, Col } from "reactstrap";
import ReactImageGallery from "react-image-gallery";
import Loader from "../../../../Components/Loader/Loader";
import { ticket_priority } from "../../../../Library/Constants/MapConstants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import { Department_Orders } from "../../../../Library/LanguageConversion/Department_Orders";
import { Context } from "../../../../Components/Context/Context";
const ProductSpecModal = ({ allData, viewFeedback }: any) => {

    const [open2, setOpen2] = useState<boolean>(false) // for service req modal
    const [selectedTab, setSelectedtab] = useState<number>(0)
    const [productData, setProductData] = useState<any>()
    const [specData, setSpecData] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [specShowAll, setSpecShowAll] = useState<boolean>(false)
    const [tickets, setTickets] = useState<any>([])
    const [pageData, setPageData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number | string>(1)
    const {language,setLanguage}=useContext(Context);


    useEffect(() => {
        if (allData?.order_details?.product_id) {
            getProductDetails()
        }
    }, [allData])

    useEffect(() => {
        getTickets()
    }, [currentPage, open2])

    // gets product details 
    const getProductDetails = () => {
        setIsLoading(true);
        api
            .get(`/product/products/${allData?.order_details?.product_id}`, true)
            .then(async function ([success, response]) {
                setProductData(response?.data || []);
                setSpecData(response?.data?.specifications?.filter((spec: any) => spec?.value?.length !== 0 && spec?.key?.length !== 0))
                setIsLoading(false);
                return response;
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    // gets tickets raised 
    const getTickets = () => {
        api
            .get(`/service-maintenance?product_id=${allData?.order_details?.product_id}&page=${currentPage}`, true)
            .then(async function ([success, response]) {
                console.log(response);
                setTickets(response?.data || []);
                setPageData(response?.meta)
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // image gallery 
    const images = productData?.images.map((item: any) => {
        return {
            original: item?.path,
            thumbnail: item?.path,
        };
    });

    // spec show all / show less handler 
    const specShowHandler = () => {
        setSpecShowAll(!specShowAll)
    }

    return (
        <>
            {isLoading && <div className="text-center pt-5">
                <Loader />
            </div>}
            {!isLoading && productData?.length !== 0 && <div className="product-specifications-wrap mb-5 modal-popup-fix">
                <Row>
                    <Col md={5} className="mt-4">
                            <div className="main-image-wrap-outer">
                                {/* image gallery */}
                                {images &&
                                    <ReactImageGallery
                                        items={images}
                                        showNav={false}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        lazyLoad
                                    />}
                            </div>
                    </Col>
                    <Col md={7}>
                        <div className="specifications-content-wrap mt-3">
                         <Row>
                            <Col md={12}>
                            <div className="main-specifications-wrap">
                                <h2 className="main-heading">{Department_Orders?.Features[language]}</h2>
                                <hr />
                                <table className="table table-hover table-bordered table-results-product-outer">
                                    <tbody>
                                        {
                                            specData?.map((spec: any, i: number) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        {(!specShowAll ? i < 5 : true) && <>
                                                            {spec?.key?.length !== 0 && spec?.value?.length !== 0 &&
                                                                <tr>
                                                                    <th scope="row">{spec.key}</th>
                                                                    <td>{spec.value}</td>
                                                                </tr>}
                                                        </>}
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        <tr>
                                            <th scope="row">
                                            {Department_Orders?.Quantity[language]}
                                            </th>
                                            <td>
                                                {allData?.order_details?.quantity}
                                            </td>
                                        </tr>
                                        {specData?.length > 5 && <tr onClick={specShowHandler}>
                                            <th colSpan={2} className='text-center show-more-less-text'><span>{specShowAll ? 'Show Less' : 'Show All'}</span></th>
                                        </tr>}
                                    </tbody>
                                </table>

                            </div>
                            </Col>
                            <Col md={12}>
                            <div className="address-specifications-wrap">
                                <h6 className="sub-heading">{Department_Orders?.Billedto[language]}:</h6>
                                <div className='address-outer'>
                                    <div className="address-inner">
                                        <small>
                                        {Department_Orders?.BuildingNo[language]}:{allData?.pf_invoice?.pf_address[0]?.building_no} <br />
                                            {allData?.pf_invoice?.pf_address[0]?.city}<br />
                                            {allData?.pf_invoice?.pf_address[0]?.district}, &nbsp;
                                            {allData?.pf_invoice?.pf_address[0]?.state}<br />
                                            {Department_Orders?.Pincode[language]}:{allData?.pf_invoice?.pf_address[0]?.pincode}<br />
                                        </small>
                                    </div>
                                </div>
                            </div></Col>
                         </Row>
                        </div>
                    </Col>
                </Row>
                {viewFeedback && <Row>
                    <Col md={12}>
                        <div className="feed-back-wrap mt-4">
                            {/* tabs */}
                            <div className="custom-tabs">
                                <Link
                                    className={selectedTab == 0 ? 'active' : ''}
                                    onClick={() => setSelectedtab(0)}
                                    to="#"
                                >
                                    {Department_Orders?.Feedback[language]}
                                </Link>
                                <Link to=""
                                    className={selectedTab == 1 ? 'active' : ''}
                                    onClick={() => setSelectedtab(1)}
                                >
                                   {Department_Orders?.RaiseaComplaint[language]}
                                </Link>
                            </div>

                            {/* feedback  */}
                            {selectedTab === 0 && <Feedback allData={allData} vendorId={allData?.vendor_id} />}

                            {/* tickets listing  */}
                            {selectedTab === 1 &&
                                <div>
                                    <div className="add-complaint-btn-wrap">
                                        <Link to=''title="Add Complaint/Service Request" onClick={() => setOpen2(true)}><span className="danger-text">{Department_Orders?.AddComplaint[language]}</span>&nbsp; /{Department_Orders?.ServiceRequest[language]}</Link>
                                    </div>
                                    <>
                                        <table className="table table-hover table-bordered ">
                                            <thead>
                                            <tr>
                                                    <th>{Department_Orders?.SlNo[language]}</th>
                                                    <th>{Department_Orders?.IssueType[language]}</th>
                                                    <th>{Department_Orders?.RequestedDate[language]}</th>
                                                    <th>{Department_Orders?.Priority[language]}</th>
                                                    <th>{Department_Orders?.Status[language]}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tickets?.length !== 0 ?
                                                        tickets?.map((itm: any, i: number) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{pageData?.from + i}</td>
                                                                        <td>{itm?.type === 1 ? "Maintenance Request" : "Service request"}</td>
                                                                        <td>{itm?.request_date}</td>
                                                                        <td>{ticket_priority?.map((tp: any) => itm?.priority == tp.value && tp.name)}</td>
                                                                        <td>{itm?.resolve_date == null ? "Requested" : "Resolved"}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={5} className="text-center">{Department_Orders?.NoTicketsFound[language]}</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        {pageData && tickets?.length > 0 && < div className="table-footer-wrap">
                                            <small>{Department_Orders?.Showing[language]}{pageData.from}{Department_Orders?.to[language]}{pageData.to} {Department_Orders?.of[language]} {pageData?.total}{Department_Orders?.entries[language]}</small>
                                            <div className="pagination-outer">
                                                <CustomPagination
                                                    totalItems={pageData?.total}
                                                    itemsPerPage={pageData?.per_page}
                                                    activePage={pageData?.current_page}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            </div>
                                        </div>}
                                    </>
                                </div>}
                        </div>
                    </Col>
                </Row>}
                <ModalContainer header="Raise a Complaint" open={open2} setOpen={setOpen2} >
                    <ServiceRequestModal allData={allData} setOpen={setOpen2} vendorId={allData?.vendor_id} />
                </ModalContainer>
            </div>}
        </>
    )
}

export default ProductSpecModal