import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Table,
} from "reactstrap";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import constants from "../../../Library/Constants/Constants";
import { getUnique } from "../../../Library/Constants/helpers";
import { api } from "../../../Library/CustomApi";
import OrderView2 from "../Orders/Modal/OrderView2";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import TDSPaymentModal from "../../Department/TDS/Modal/TDSPaymentModal";
import UploadTdsToVendor from "./Modal/UploadTdsToVendor";
import { Admin_tds } from "../../../Library/LanguageConversion/Admin_Tds";
import { Context } from "../../../Components/Context/Context";
export default function TDSToVendo() {
  const {language,setLanguage} = useContext(Context)
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  const [confirmed_order, setConfirmedOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any>();
  const [search, setSearch] = useState("");
  const [order_view, setOrderView] = useState([]);
  const [payIsOpen, setPayIsOpen] = useState<boolean>(false) // for feedback modal
  const [TDSDataIndex, setTDSDataIndex] = useState<number | null>(null)

  const [order_id, setOrderId] = useState()
  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getConfirmedOrder();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);
  const getConfirmedOrder = () => {
    setLoading(true);
    api
      .get(
        `/order/get-confirmed-orders?page=${currentPage}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setConfirmedOrder(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-order-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message?.[0]);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  function limitText(text: any, limit: any) {
    if (text?.length > limit) {
      return text.substring(0, limit) + "...";
    } else {
      return text;
    }
  }
  // Graph data
  const graphData = (id: any) => {
    api
      .post(`/order/get_order_timeline`, {
        order_id: id
      }, true)
      .then(async function ([success, response]) {
        // setPaymentData(response?.message?.[0] || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const vendorApprovalDocument = (id: any) => {
    console.log(id, "ashdashdsdhdsjh")
    if (id == 1) {
      Swal.fire({
        title: "Are you sure?" + "\n" + "Do you want to Approve document?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          sendApproveData(id);
        }
      });
    }
    else {
      Swal.fire({
        title: "Are you sure?" + "\n" + "Do you want to Reject document?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          sendApproveData(id);
        }
      });
    }

  }
  const sendApproveData = (id: any) => {
    api
      .post(`/order/approve_reject_emd_stmt`, {
        status: id,
        order_id: order_id,


      }, true)
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          toast.success(response?.message)
          setModal(false)
          getConfirmedOrder();
        }
        else {

        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText title="Product Name/Order ID" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder="Product Name/Order ID"
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_tds?.SlNo?.[language]}</th>
              <th>{Admin_tds?.OrderID?.[language]}</th>
              <th>{Admin_tds?.OrderedDate?.[language]}</th>
              {/* <th>Tender ID</th> */}
              <th >{Admin_tds?.OrderDetails?.[language]}</th>
              <th>{Admin_tds?.VendorName?.[language]}</th>
              <th>{Admin_tds?.ShippingAddress?.[language]}</th>
              <th>{Admin_tds?.Quantity?.[language]}</th>
              <th>{Admin_tds?.Department?.[language]}</th>
              <th>{Admin_tds?.Action?.[language]}</th>
            </tr>
          </thead>
          <tbody>
            {confirmed_order?.length !== 0 ? (
              <>
                {confirmed_order?.map((item: any, i: any) => {
                  let count = 0;
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_tds?.SlNo?.[language]}:</span>
                          <span className="table-data">
                            {pageData?.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_tds?.OrderID?.[language]}:</span>
                          <Link
                            to="#"
                            className="primary-text"
                            title="View Order"
                            onClick={() => {
                              toggle();
                              getSingleOrder(item?.id);
                              graphData(item?.id)
                              setOrderId(item?.id)
                            }}
                          >
                            {item?.vendor_po_no}
                          </Link>
                        </td>

                        <td>
                          <span className="mobile-heading">{Admin_tds?.OrderedDate?.[language]}:</span>
                          <span className="table-data">
                            {moment(item?.pf_invoice?.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_tds?.OrderDetails?.[language]}:</span>
                          <span className="table-data">
                            <p
                            >
                              <span className="product-title" title={item?.order_details?.name}>
                                {item?.order_details?.name
                                  ? limitText(item?.order_details?.name, 15)
                                  : null}
                              </span>
                              <br /> {Admin_tds?.Unitprice?.[language]}:
                              <i className="bi bi-currency-rupee"></i>
                              {item?.pf_invoice?.pf_details[0]?.price} <br />
                              {Admin_tds?.Quantity?.[language]}: {item?.order_details?.quantity} <br />
                              {Admin_tds?.Total?.[language]}: <i className="bi bi-currency-rupee"></i>
                              {item?.pf_invoice?.total_amount}
                            </p>

                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_tds?.VendorName?.[language]}:</span>
                          <span className="table-data">
                            {item?.cmpny_detail?.name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                          {Admin_tds?.ShippingAddress?.[language]}:
                          </span>
                          <span className="table-data d-block">
                            <p>
                            {Admin_tds?.BuildingNo?.[language]}:
                              {item?.pf_invoice?.pf_address?.[0]?.building_no}
                            </p>
                            <p>{item?.pf_invoice?.pf_address?.[0]?.city}</p>
                            <p>
                              {item?.pf_invoice?.pf_address?.[0]?.district},
                              {item?.pf_invoice?.pf_address?.[0]?.state}
                            </p>

                            <p>
                            {Admin_tds?.Pincode?.[language]}:
                              {item?.pf_invoice?.pf_address?.[0]?.pincode}
                            </p>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_tds?.Quantity?.[language]}:</span>
                          <span className="table-data">
                            {item?.order_details?.quantity}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_tds?.Department?.[language]}:</span>
                          <span className="table-data">
                            {item?.pf_invoice?.user?.full_name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_tds?.Actions?.[language]}:</span>
                          <span className="table-data">
                            <Button
                              onClick={() => {
                                setTDSDataIndex(i)
                                setPayIsOpen(true)
                              }}
                            >{Admin_tds?.UploadTDS?.[language]}</Button>
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={8}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Order Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && confirmed_order?.length !== 0 && (
        <div className="table-footer-wrap">
          <small>
            Showing {pageData.from} to {pageData.to} of {pageData.total} entries
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      {/* <OrderView2
        order_view={order_view}
        modal={modal}
        toggle={toggle}
        loading={loading}
        graph_data={graph_data}
      // confirmed={1}
      // vendorApprovalDocument={vendorApprovalDocument} 
      /> */}

      {/* payment modal  */}
      <div>
        <ModalContainer
          open={payIsOpen}
          setOpen={setPayIsOpen}
          header='Pay Now'
        >
          <UploadTdsToVendor
            TDSDataIndex={TDSDataIndex}
            TDSData={confirmed_order}
            setPayIsOpen={setPayIsOpen}
            getTDSList={getConfirmedOrder}
          />
        </ModalContainer>
      </div>
    </>
  );
}
