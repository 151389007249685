import { useState } from 'react';
import { Col } from 'reactstrap';
import ManagerAccountHeader from './Components/ManagerAccountHeader';
import ManagerSidebar from './Components/ManagerSidebar';


const ManagerAccountLayout = ({ children }: any) => {

    const [openSidebar, setOpenSidebar] = useState(false); // mobile view
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true); // web view

    

    return (
        <>
            {/*  sidebar  */}
            <ManagerSidebar
                setOpenSidebar={setOpenSidebar}
                openSidebar={openSidebar}
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
            />

            {/* right side section which includes body and header  */}
            <Col
                md={sidebarIsOpen ? '10' : '12'}
                className={sidebarIsOpen ?
                    'offset-md-2 minimise-content'
                    : 'expand-content'}
            >
                <ManagerAccountHeader
                    setOpenSidebar={setOpenSidebar}
                    openSidebar={openSidebar}
                />

                <div
                    className="dashboard-outer"
                    id="content"
                >
                    <main>{children}</main>
                    
                </div>
            </Col>
        </>
    )
}

export default ManagerAccountLayout