import React, { useEffect } from "react";
import { useState, useContext } from "react";
import {
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText
} from "reactstrap";
import { api } from "../../../../../Library/CustomApi";
import Loader from "../../../../../Components/Loader/Loader";
import CustomPagination from "../../../../../Components/CustomPagination/Index";
import { ticket_priority } from "../../../../../Library/Constants/MapConstants";
import { Department_Services } from "../../../../../Library/LanguageConversion/Department_Services";
import { Context } from "../../../../../Components/Context/Context";


export default function DeptOpenTickets() {
  const [isLoading, setIsLoading] = useState(true);
  const [openTicket, setOpenTickets] = useState([]);
  const [pageData, setPageData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number | string>(1)
  const [searchVal, setSearchVal] = useState<string>("")

  useEffect(() => {
    getOpenTickets()
  }, [, currentPage, searchVal]);

  const getOpenTickets = () => {
    setIsLoading(true);
    api
      .get(`/service-maintenance?status=1&page=${currentPage}&search_keyword=${searchVal}`, true)
      .then(async function ([success, response]) {
        setOpenTickets(response?.data || []);
        setPageData(response?.meta)
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const { language, setLanguage } = useContext(Context);
  return (
    <React.Fragment>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText title="Product Name" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder="Product Name"
              aria-label="Username"
              aria-describedby="search"
              onChange={(e: any) => {
                setCurrentPage(1)
                setSearchVal(e.target.value)
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Department_Services?.Sl_No[language]}</th>
              <th>{Department_Services?.Product[language]}</th>
              <th>{Department_Services?.Priority[language]}</th>
              <th>{Department_Services?.Issue_Type[language]}  </th>
              <th>{Department_Services?.Issued_Date[language]}  </th>
              {/* <th>Time</th> */}
              <th>{Department_Services?.Status[language]}</th>
            </tr>
          </thead>
          <tbody>
            {
              !isLoading ?
                openTicket.length !== 0 ?
                  <>
                    {openTicket?.map((obj: any, i: number) => {
                      return (
                        <React.Fragment key={i}>
                          <tr>
                            <td><span className="mobile-heading">{Department_Services?.Sl_No[language]} :</span><span className="table-data">{pageData?.from + i}</span></td>
                            <td><span className="mobile-heading">{Department_Services?.Product[language]}:</span><span className="table-data"><strong>{obj?.serviceproduct?.name}</strong></span></td>
                            <td><span className="mobile-heading">{Department_Services?.Priority[language]}:</span><span className="table-data">{ticket_priority.map((tp: any) => obj?.priority == tp.value && tp.name)} </span></td>
                            <td><span className="mobile-heading">{Department_Services?.Issue_Type[language]}  :</span><span className="table-data">{obj?.type === 1 ? "Maintenance Request" : "Service request"}</span></td>
                            <td><span className="mobile-heading">{Department_Services?.Issued_Date[language]} :</span><span className="table-data">{obj?.request_date}</span></td>
                            {/* <td><span className="mobile-heading">Time:</span><span className="table-data">{obj?.time_taken}</span></td> */}
                            <td>
                              <span className="mobile-heading">{Department_Services?.Status[language]} :</span>
                              <span className="table-data">
                                <span className="uploaded-status">
                                  {obj?.resolve_date == null ? "Requested" : "Resolved"}
                                </span>
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </>
                  :
                  <tr>
                    <td colSpan={7} className="text-center py-5 empty-text">
                      <div className="d-flex justify-content-center">
                        <p>{Department_Services?.No_Tickets_Found[language]}  </p>
                      </div>
                    </td>
                  </tr>
                :
                <>
                  <tr>
                    <td colSpan={7} className="text-center py-5 empty-text">
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    </td>
                  </tr>
                </>
            }
          </tbody>
        </Table>
        {pageData && openTicket?.length > 0 && < div className="table-footer-wrap">
          <small>Showing {pageData?.from}to {pageData?.to} of {pageData?.total} entries</small>
          <div className="pagination-outer">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData?.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>}
      </div>
    </React.Fragment>
  );
}