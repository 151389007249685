export const Department_drafts:any ={

    // department---Drafts----draftstab---
    
    Order_ID:{
        en:"Order ID",
        ka:"ಆರ್ಡರ್ ಐಡಿ"
    },
    Quantity:{
        en:"Quantity",
        ka:"ಪ್ರಮಾಣ"
    },
    Price:{
        en:"Price",
        ka:"ಬೆಲೆ"
    },
    Uploaded:{
        en:' Uploaded',
        ka:'ಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿದೆ' 
    },
    
    Approved:{
        en:' Approved',
        ka:'ಅನುಮೋದಿಸಲಾಗಿದೆ' 
    },
    No_Drafts_Found : {
        en:'No Drafts Found',
        ka:'ಯಾವುದೇ ಡ್ರಾಫ್ಟ್‌ಗಳು ಕಂಡುಬಂದಿಲ್ಲ' 
    },
    Showing:{
        en:' Showing',
        ka:'ತೋರಿಸಲಾಗುತ್ತಿದೆ' 
    },
    to:{
        en:'to',
        ka:'ಗೆ' 
    },
    of:{
        en:'of',
        ka:'ನ' 
    },
    entries:{
        en:'entries',
        ka:'ಪ್ರವೇಶಗಳು' 
    },
    // department---draft----workordertab---workorderreupload modal-----
    
    
    Upload_Work_Order:{
        en:'Upload Work Order',
        ka:'ಕೆಲಸದ ಆದೇಶವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ' 
    },
    Choose_Documents:{
        en:'Choose Documents',
        ka:'ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ಆದೇಶ.' 
    },
    
    Upload_transaction_details:{
        en:'Upload Transaction Details',
        ka:'ವಹಿವಾಟಿನ ವಿವರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ' 
    },
    
    // department---draft----workordertab---workordertab.tsx
    Download_Work_Order:{
        en:'Download Work Order',
        ka:'ಕಾರ್ಯ ಆದೇಶವನ್ನು ಡೌನ್ಲೋಡ್ ಮಾಡಿ' 
    },
    Reupload_Work_Order:{
        en:'Reupload Work Order',
        ka:'ಕೆಲಸದ ಆದೇಶವನ್ನು ಮರುಅಪ್ಲೋಡ್ ಮಾಡಿ' 
    },
    No_Work_Order_Found:{
        en:'No Work Order Found',
        ka:'ಯಾವುದೇ ಕೆಲಸದ ಆದೇಶ ಕಂಡುಬಂದಿಲ್ಲ' 
    },
    Meeting_Proceedings:{
        en:'Meeting Proceedings',
        ka:'ಸಭೆಯ ಪ್ರಕ್ರಿಯೆಗಳು' 
    },
    Internal_Letters:{
        en:'Internal Letters',
        ka:'ಆಂತರಿಕ ಪತ್ರಗಳು' 
    },
    Reupload_Meeting_Internal:{
        en:'Reupload Work Order / Meeting Proceedings/ Internal Letters ',
        ka:'ಕೆಲಸದ ಆದೇಶವನ್ನು ಮರುಅಪ್ಲೋಡ್ ಮಾಡಿ / ಸಭೆಯ ಪ್ರಕ್ರಿಯೆಗಳು / ಆಂತರಿಕ ಪತ್ರಗಳು' 
    },
    

    // --------------department---draft----paymentDrafts---------
    Drafts:{
        en:'Drafts',
        ka:'ಕರಡುಗಳು' 
    },
    Work_Order:{
        en:' Work Order',
        ka:'ಕೆಲಸದ ಆದೇಶ' 
    },
    Dashboard:{
        en:'Dashboard',
        ka:'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್' 
    },
    // --------------department---draft----uploadchallan validation
    
    Payment_type_is_required:{
        en:'Payment type is required',
        ka:'ಪಾವತಿ ಪ್ರಕಾರದ ಅಗತ್ಯವಿದೆ' 
    },
    Payment_type:{
        en:'Payment type',
        ka:'ಪಾವತಿ ವಿಧಾನ' 
    },
    IFSC_code_is_required:{
        en:'IFSC code is required',
        ka:'ಐಎಫ್‌ಎಸ್‌ಸಿ ಕೋಡ್ ಅಗತ್ಯವಿದೆ'
    },
    Bank_name_is_required:{
        en:'Bank name is required',
        ka:'ಬ್ಯಾಂಕ್ ಹೆಸರು ಅಗತ್ಯವಿದೆ' 
    },
    Account_number_must_be_number:{
        en:'Account number must be number',
        ka:'ಖಾತೆ ಸಂಖ್ಯೆ ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು' 
    },
    Account_number_is_required:{
        en:'Account number is required',
        ka:'ಖಾತೆ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ'
    
        } ,
    Branch_name_is_required:{
            en:'Branch name is required',
            ka:'ಶಾಖೆಯ ಹೆಸರು ಅಗತ್ಯವಿದೆ'
        
            },
     Account_holder_name_is_required:{
                en:'Account holder name is required',
                ka:'ಖಾತೆದಾರರ ಹೆಸರು ಅಗತ್ಯವಿದೆ'
            
                } ,
                //
    Amount_must_be_number:{
                    en:'Amount must be number',
                    ka:'ಮೊತ್ತವು ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು'
                },
    
    Amount_is_required:{
        en:'Amount is required',
        ka:'ಮೊತ್ತದ ಅಗತ್ಯವಿದೆ'
        },
    Transaction_number_is_required:{
        en:'Transaction number is required',
        ka:'ವಹಿವಾಟಿನ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ'
            },
    Cheque_number_is_required:{
        en:'Cheque number is required',
        ka:'ಚೆಕ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ'
        },
    Cheque_date_is_required:{
         en:'Cheque date is required',
         ka:'ಚೆಕ್ ದಿನಾಂಕದ ಅಗತ್ಯವಿದೆ'
         },
    Cheque_date:{
        en:'Cheque date',
        ka:'ಚೆಕ್ ದಿನಾಂಕ'
        }, 
    Receipt_number_is_required:{
            en:'Receipt number is required',
            ka:'ರಶೀದಿ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ'
            },
    File_is_required:{
                en:'File is required',
                ka:'ಫೈಲ್ ಅಗತ್ಯವಿದೆ'
                },
     Cheque_number:{
        en:'Cheque number',
        ka:'ಚೆಕ್ ಸಂಖ್ಯೆ'
     } ,    
    Select_Payment_type:{
        en:'select Payment type',
        ka:'ಪಾವತಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ' 
    },
    Invalid_amount:{
        en:'invalid amount',
        ka:'ಅಮಾನ್ಯ ಮೊತ್ತ' 
    },     
    Direct_Bank_Transfer:{
        en:'Direct Bank Transfer',
        ka:'ನೇರ ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆ' 
    },
    Cheque:{
        en:'Cheque',
        ka:'ಚೆಕ್'
        },
     Demand_draft:{
            en:'Demand_draft',
            ka:'ಬೇಡಿಕೆ ಕರಡು'
            },
     NEFT:{
         en:'NEFT',
         ka:'ಎನ್ ಇ ಎಫ್ ಟಿ'
         },
     RTGS:{
        en:'RTGS',
        ka:'ಆರ್ ಟಿ ಜಿ ಎಸ್'
     },
     IFSC:{
        en:'IFSC',
        ka:'ಐಎಫ್ಎಸ್ಸಿ'
     },
     
     Amount:{
        en:'Amount',
        ka:'ಮೊತ್ತ'
     },
    
     Bank_name:{
        en:'Bank Name',
        ka:'ಬ್ಯಾಂಕ್ ಹೆಸರು'
     },
     Account_Number:{
        en:'Account Number',
        ka:'ಖಾತೆ ಸಂಖ್ಯೆ'
     },
     Branch_name:{
        en:'Branch Name',
        ka:'ಶಾಖೆಯ ಹೆಸರು'
     },
    
       Account_Holder_Name:{
        en:'Account Holder Name',
        ka:'ಖಾತೆದಾರನ ಹೆಸರು'
     },
     Transaction_Number:{
        en:'Transaction Number',
        ka:'ವ್ಯಾಪಾರ ಸಂಖ್ಯೆ'
     },
     Receipt_Number:{
        en:'Receipt Number',
        ka:'ರಶೀದಿ  ಸಂಖ್ಯೆ'
     },
     Select_Documents:{
        en:'Select Documents',
        ka:'ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ'
     },
    
    
     Submit:{
        en:'Submit',
        ka:'ಸಲ್ಲಿಸಿ'
     }
    
    
    
    }