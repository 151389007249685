import React from "react";
import { useState, useMemo, useEffect } from "react";
import { useContext } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import AddTenderModal from "../Modal/AddTenderModal";
import { Formik } from "formik";
import TenderValidationSchema from "../Validation/TenderValidation";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import moment from "moment";
import DatePicker from 'react-date-picker'
import { Context } from "../../../../Components/Context/Context";
import { Admin_addtendor } from "../../../../Library/LanguageConversion/Admin_addtendor";

function UploadRates(props: any) {
  const navigate = useNavigate();
  const [upload_rate, setUploadRate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowing, setIsShowing] = useState(false);
  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any>();
  const [from, setFrom] = useState("");
  const [date, setDate] = useState<any>({
    validfrom: "",
    validto: "",
  });
  const [scrolling, setScrolling] = useState<boolean>(false)

  useEffect(() => {
    getCategory();
  }, []);
  const clearAll = () => {
    setDate({
      ...date,
      validfrom: "",
      validto: "",
    });
    setSearch("");
    getUploadRate();
  };

  useEffect(() => {
    getUploadRate();
  }, [currentPage]);

  //========================================================= Search ==================================================================
  const handleSearch = (event: any) => {
    setCurrentPage(1)
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getUploadRate();
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search, date?.validfrom, date?.validto]);

  // ======================================================== Get Upload Rate ========================================================

  const getUploadRate = () => {
    let v_from: any = moment(date?.validfrom).format("YYYY-MM-DD");
    let v_to: any = moment(date?.validto).format("YYYY-MM-DD");

    if (v_from !== "Invalid date" && v_to !== "Invalid date") {
      setIsLoading(true);
      api
        .get(
          `/tender?search_keyword=${search}&page=${currentPage}&valid_from=${v_from}&valid_to=${v_to}`,
          true
        )
        .then(async function ([success, response]) {
          setUploadRate(response?.data || []);
          setFrom(response?.meta?.from);
          setPageData(response?.meta);
          setIsLoading(false);
          return response;
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      api
        .get(`/tender?search_keyword=${search}&page=${currentPage}`, true)
        .then(async function ([success, response]) {
          setUploadRate(response?.data || []);
          setFrom(response?.meta?.from);
          setPageData(response?.meta);
          setIsLoading(false);
          return response;
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  // ========================================================== Get Category ==========================================================

  const getCategory = () => {
    api
      .get(`/product/categories`, true)
      .then(async function ([success, response]) {
        let datas: any = [];
        response?.data?.map((elem: any) => {
          datas.push({ value: elem?.id, label: elem?.name });
        });
        setCategory(datas || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ======================================================== Add Tender  ========================================================
  const handleSubmit = (values: any, actions: any) => {
    TenderAdd(values, actions);
  };

  const TenderAdd = (values: any, actions: any) => {
    let cat: any = [];
    values?.category.map((item: any) => {
      cat.push(item?.value);
    });
    api
      .post(
        `/tender`,
        {
          tender_id: values?.tender_Id,
          tender_date: moment(values?.tender_date).format("YYYY-MM-DD"),
          version: values?.version,
          valid_from: moment(values?.valid_from).format("YYYY-MM-DD"),
          valid_to: moment(values?.valid_to).format("YYYY-MM-DD"),
          cat_id: cat,
          approved_by: values?.approved_by,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Tender Created Successfully");
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
        }
        getUploadRate();
        return response;
      })
      .catch((err) => {
        if (err?.response?.status == 422) {
          actions.setFieldError("tender_Id", err?.response?.data?.message);
          toast.error(err?.response?.data?.message);
          actions.setSubmitting(false);
        }
        if (err?.response?.status == 500) {
          actions.setSubmitting(false);
        }
        console.log(err);
      });
  };

  // ============================================== Navigate to Rate contract  ======================================================

  const getUpdateStatus = (id: any) => {
    api
      .post(
        `/tender_active`,
        {
          tender_id: id,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(response?.message);
          getUploadRate();
        }
        return response;
      })
      .catch((err) => {
        if (err?.response?.status == 500) {
          toast.error(err?.response?.data?.message);
        }
        console.log(err);
      });
  };

  const handleScroll = () => {
    setScrolling(true)
    setTimeout(() => setScrolling(false), 800);
  }
  const { language, setLanguage } = useContext(Context);
  // =========================================================== View ================================================================
  return (
    <>
      <Row className="gy-4 gx-5 add-tendor-content-wrap-outer">
        <div className="search-widget-outer">
          <Form role="search" className="widget-inner">
            <div className="valid-from">
              <Label>{Admin_addtendor?.ValidFrom?.[language]}</Label>
              <DatePicker
                name="validfrom"
                className="form-control"
                onChange={(e: any) => {
                  setCurrentPage(1)
                  setDate({
                    ...date,
                    validfrom: e,
                  });
                }}
                format={"dd-MM-y"}
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                value={date?.validfrom} />
            </div>
            <div className="valid-to">
              <Label>{Admin_addtendor?.ValidTo?.[language]}</Label>
              <DatePicker
                name="validto"
                className="form-control"
                onChange={(e: any) => {
                  setCurrentPage(1)
                  setDate({
                    ...date,
                    validto: e,
                  });
                }}
                format={"dd-MM-y"}
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                minDate={date?.validfrom}
                disabled={date?.validfrom ? false : true}
                value={date?.validto} />
            </div>
            <div className="search-btn-wrap">
              <InputGroup className="search-group">
                <InputGroupText title="Search"className="search-icon" id="search">
                  <i className="fa-sharp fa-solid fa-magnifying-glass" />
                </InputGroupText>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Tender ID/Category"
                  aria-label="Username"
                  aria-describedby="search"
                  value={search}
                  onChange={handleSearch}
                  onKeyPress={(event) => {
                    var key = event.keyCode || event.which;
                    if (key === 13) {
                      event.preventDefault();
                    }
                  }}
                />
              </InputGroup>
              {/* <Button type="button" color="secondary" onClick={clearAll} title="Reset All">
                <i className="icons icons-reset-white"></i>
              </Button> */}
              <div className="add-button">
                <Link to="" onClick={() => setIsShowing(true)}>
                  <Button className="add-vendor primary-btn" color="primary">
                    <span className="large-screen-view"> <i className="bi bi-plus" />
                    {Admin_addtendor?.AddTender?.[language]}</span>
                    <span className="mobile-view"><i className="bi bi-plus-lg"></i></span>
                  </Button>
                </Link>
              </div>
            </div>
          </Form>
        </div>

        <div className="reset-all mt-0">
          {(date.validfrom !== '' || date.validto !== '') &&
            <i title="Reset All"><a href="#" onClick={clearAll}>{Admin_addtendor?.Reset?.[language]}</a></i>}
        </div>

        <div className="table-wrap responsive-table mt-0" onScroll={handleScroll}>
          <Table className="w-100 table-striped">
            <thead>
              <tr>
                <th className=""> {Admin_addtendor?.SlNo?.[language]}</th>
                <th className="">{Admin_addtendor?.TenderID?.[language]}</th>
                <th className="">{Admin_addtendor?.Categories?.[language]}</th>
                <th className="">{Admin_addtendor?.Version?.[language]}</th>
                {/* <th className="">Status</th> */}
                <th className="">{Admin_addtendor?.ValidFrom?.[language]} </th>
                <th className=""> {Admin_addtendor?.ValidTo?.[language]}</th>
                <th className="">{Admin_addtendor?.Action?.[language]}</th>
              </tr>
            </thead>
            <tbody>
              {upload_rate?.length !== 0 ? (
                <>
                  {upload_rate?.map((item: any, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        <tr className={`${item?.status !== constants.TENDER.APPROVED ? "inactive-row " : ''}`}>
                          <td>
                            <span className="mobile-heading">{Admin_addtendor?.SlNo?.[language]}:</span>
                            <span className="table-data">
                              {pageData?.from + i}
                            </span>
                          </td>
                          <td className="">
                            <span className="mobile-heading">{Admin_addtendor?.TenderID?.[language]}:</span>
                            <span className="table-data">
                              {item?.tender_id}
                            </span>
                          </td>
                          <td className="">
                            <span className="mobile-heading">
                            {Admin_addtendor?.Categories?.[language]}:
                            </span>
                            <span className="table-data">
                              {item?.tendor_category?.length !== 0 ? (
                                <>
                                  {item?.tendor_category.map(
                                    (items: any, i: number) => {
                                      return (
                                        <React.Fragment key={i}>
                                          {i ? ", " : ""}

                                          {items?.name}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <label>---</label>
                              )}
                            </span>
                          </td>
                          <td className="">
                            <span className="mobile-heading">{Admin_addtendor?.Version?.[language]}:</span>
                            <span className="table-data">
                              {item?.version}
                            </span>
                          </td>
                          {/* <td className="">
                            <span className="mobile-heading">Status:</span>
                            <span className="table-data">
                              {item?.status == constants.TENDER.APPROVED ? (
                                <span className="text-success">Active</span>
                              ) : (
                                <span className="text-danger">Inactive</span>
                              )}
                            </span>
                          </td> */}
                          <td className="">
                            <span className="mobile-heading">
                            {Admin_addtendor?.ValidFrom?.[language]}:
                            </span>
                            <span className="table-data">
                              {moment(item?.valid_from).format("DD-MM-YYYY")}
                            </span>
                          </td>
                          <td className="">
                            <span className="mobile-heading"> {Admin_addtendor?.ValidTo?.[language]}:</span>
                            <span className="table-data">
                              {moment(item?.valid_to).format("DD-MM-YYYY")}
                            </span>
                          </td>
                          <td
                            className={`${scrolling ? 'scroll-shadow' : 'no-shadow'}`}
                          >
                            <span className="mobile-heading">{Admin_addtendor?.Action?.[language]}:</span>
                            <span className="action-flex-btn toggle-btn">
                              {item?.status == constants.TENDER.APPROVED ? (
                                <Link
                                  to=""
                                  title="Click to Deactivate"
                                  aria-disabled={true}
                                  onClick={() => getUpdateStatus(item?.id)}
                                >
                                  <i className="fas fa-toggle-on text-success lg"></i>
                                </Link>
                              ) : (
                                <Link
                                  to=""
                                  title="Click to Activate"
                                  onClick={() => getUpdateStatus(item?.id)}
                                >
                                  <i className="fas fa-toggle-off text-danger lg"></i>
                                </Link>
                              )}
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td
                    className="text-center px-2 py-5 empty-text"
                    colSpan={8}
                  >
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    ) : (
                      "No Tender Found"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {pageData && upload_rate?.length !== 0 && (
          <div className="table-footer-wrap">
            <small>
            {Admin_addtendor?.Showing?.[language]} {pageData.from} {Admin_addtendor?.to?.[language]} {pageData.to} {Admin_addtendor?.of?.[language]} {pageData.total}{" "}
            {Admin_addtendor?.entries?.[language]}
            </small>
            <div className="pagination">
              <CustomPagination
                totalItems={pageData?.total}
                itemsPerPage={pageData?.per_page}
                activePage={pageData.current_page}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )}
      </Row>
      <Formik
        initialValues={{
          tender_Id: "",
          tender_date: "",
          approved_by: "",
          category: [],
          valid_from: "",
          valid_to: "",
          version: "",
        }}
        validationSchema={TenderValidationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <AddTenderModal
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleReset={handleReset}
              category={category}
              setFieldValue={setFieldValue}
            />
          );
        }}
      </Formik>
    </>
  );
}

export default UploadRates;