import { Formik } from "formik";
import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Admin_Products } from "../../../Library/LanguageConversion/Admin_Products";
import { Context } from "../../../Components/Context/Context";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  List,
  Table,
} from "reactstrap";
import Loader from "../../../Components/Loader/Loader";
import { API_URL } from "../../../Library/ApiSettings";
import { api } from "../../../Library/CustomApi";
import UploadProductModal from "./UploadProductModal";
import ViewProductModal from "./ViewProductModal";
import {
  ProductValidationSchema,
  catinitialValidation,
} from "./UploadProductValidation";
import axios from "axios";
import constants from "../../../Library/Constants/Constants";
import Swal from "sweetalert2";
import Specifications from "./../RateContract/ProductDetails/Specifications";
import CustomPagination from "../../../Components/CustomPagination/Index";
import { convertFieldName } from "../../../Library/Constants/helpers";

export default function ProductList(props: any) {
  const [isShowing, setIsShowing] = useState(false);
  const [isShowings, setIsShowings] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [product_data, setProductData] = useState([]);
  const [category, setCategory] = useState<any>();
  const [selectedFile, setSelectedFile] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState<object | undefined>();
  const [cat_value, setCatValue] = useState<any>();
  const [spec_data, setSpecData] = useState([]);
  const [pageData, setPageData] = useState<any>();
  const [show_add, setShowadd] = useState(false);
  const [from, setFrom] = useState("");
  const [cat, setCat] = useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getProductList();
  }, [currentPage]);

  // =========================================================== Search ===============================================================

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getProductList();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  // ======================================================== Get Product Edit ========================================================

  const getProductEdit = (id: number) => {
    api
      .get(`/product/products/${id}`, true)
      .then(async function ([success, response]) {
        setFormData(response?.data || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ========================================================== Get Category ==========================================================

  const getCategory = () => {
    api
      .get(`/product/categories`, true)
      .then(async function ([success, response]) {
        const arr: { value: number; label: string }[] = [];
        response?.data?.forEach((elem: any) => {
          arr.push({ value: elem.id, label: elem.name });
        });
        setCategory(arr);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ====================================================== Get Specifications =======================================================
  const initialValues = {
    product_name: "",
    product_description: "",
    product_code: "",
    hsn_code: "",
    category: "",
    file: [],
    specifications: [],
    spec_details: "",
    images: [],
  };

  // ======================================================== Get Product List ========================================================
  const getProductList = () => {
    setIsLoading(true);
    api
      .get(
        `/product/products-list?search_key=name&search_value=${search}&sort_key=id&sort_value=DESC&page=${currentPage}`,
        true
      )
      .then(async function ([success, response]) {
        setProductData(response?.data || []);
        setPageData(response?.meta);
        setFrom(response?.meta?.from);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // ======================================================= Update Status=================================================================
  const getUpdateStatus = (id: number, status: number) => {
    api
      .patch(
        `/product/products/${id}`,
        {
          status: status,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Status Updated Successfully");
        getProductList();
        return response;
      })
      .catch((err) => {
        if (err?.response?.status == 500) {
          toast.error(err?.response?.data?.message);
        }
        console.log(err);
      });
  };

  // =========================================================== Delete Product ===========================================================

  function deleteProduct(id: number) {
    Swal.fire({
      title: "Are you sure you want to delete this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  }
  const handleSubmitCat = (values: any, actions: any) => {};

  const handleDelete = async (id: number) => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      };
      const res = await axios.delete(`${API_URL}/product/products/${id}`, {
        headers,
      });
      toast.success("Product Deleted Successfully");
      getProductList();
    } catch (error) {
      console.log(error);
    }
  };

  // ======================================================== Get Product List ========================================================
  const [close_all, setCloseAll] = useState(false);
  const handleSubmit = (values: any, actions: any) => {
    uploadProduct(values, actions);
  };
  const uploadProduct = (values: any, actions: any) => {
    setIsUploading(true);
    const formData = new FormData();
    var data: any = new Array();
    values?.spec_details?.map((item: any) => {
      data?.push({
        spec_meta_id: item?.id,
        key: item?.name,
        value: item?.spec_details,
        unit: "null",
      });
    });
    var spec_value: any = new Array();
    values?.specifications?.map((item: any) => {
      spec_value?.push({
        name: item?.name,
        value: item?.value,
        parent: item?.parent || "0",
      });
    });
    formData.append("spec_all", JSON.stringify(spec_value));
    formData.append("spec_data", JSON.stringify(data));
    values?.file?.forEach((file: any) => formData.append("file[]", file));
    formData.append("cat_id", values?.category);
    formData.append("name", values?.product_name);
    formData.append("features", values?.product_description);
    formData.append("hsn_code", values?.hsn_code);
    formData.append("product_code", values?.product_code);
    formData.append("sort_order", "1");
    formData.append("status", "0");
    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/product/products`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          setIsUploading(false);
          toast.success("Product Created Successfuly");
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
        }
        setCloseAll(true);
        getProductList();
      })
      .catch(function (response) {
        setIsUploading(false);
        if (response.response.status == 422) {
          toast.error(response.response.data.message);
          actions.setFieldError("product_code", response.response.data.message);
          actions.setSubmitting(false);
        }
        if (response?.response?.status == 500) {
          toast.error(response.response.data.message);
          actions.setSubmitting(false);
        }
        if (response?.response?.status == 402) {
          toast.error(response.response.data.message);
          actions.setSubmitting(false);
        }
        console.log(response);
      });
  };

  function truncateText(text: any) {
    return text.replace(/<br>/g, ", ");
  }
  const { language, setLanguage } = useContext(Context);
  //================================================================= View ============================================================
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <NavLink className="active" to="/dashboard">
                {Admin_Products?.Dashboard[language]} 
              </NavLink>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>{Admin_Products?.Products[language]}</b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            <div className="search-widget-outer">
              <Form role="search" className="search end between">
                <InputGroup className="search-group">
                  <InputGroupText
                    title="Search"
                    className="search-icon"
                    id="search"
                  >
                    <i className="fa-sharp fa-solid fa-magnifying-glass" />
                  </InputGroupText>
                  <Input
                    type="text"
                    placeholder={Admin_Products?.ProductName[language]}
                    aria-label="Username"
                    aria-describedby="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyPress={(event) => {
                      var key = event.keyCode || event.which;
                      if (key === 13) {
                        event.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
                {/* Mobile Upload Products Button */}
                <div className="add-button mobile-view">
                  <Link to="#">
                    <Button
                      color="primary"
                      className="bi bi-upload"
                      style={{ minWidth: "auto", cursor: "pointer" }}
                      onClick={() => {
                        setIsShowing(true);
                        setShowadd(true);
                      }}
                    ></Button>
                  </Link>
                </div>
              </Form>
              {/* Destop Upload Product Button */}
              <div className="add-button large-screen-view">
                <Link to="#">
                  <Button
                    color="primary"
                    style={{ minWidth: "auto", cursor: "pointer" }}
                    onClick={() => {
                      setIsShowing(true);
                      setShowadd(true);
                    }}
                  >
                    <i className="bi bi-plus" />
                    {Admin_Products?.UploadProducts[language]}
                  </Button>
                </Link>
              </div>
            </div>
            <div className="table-wrap responsive-table">
              <Table className="product-list-table table-striped">
                <thead>
                  <tr>
                    <th className="sl-list-product">
                      {Admin_Products?.SlNo[language]}
                    </th>
                    <th className="product-list-product product-image-pmu">
                      {Admin_Products?.ProductImage[language]}
                    </th>
                    <th className="product-list-product">
                      {Admin_Products?.ProductName[language]}
                    </th>
                    <th>{Admin_Products?.Status[language]}</th>
                    <th>{Admin_Products?.Action[language]}</th>
                  </tr>
                </thead>
                <tbody>
                  {product_data?.length !== 0 ? (
                    <>
                      {product_data?.map((item: any, key) => {
                        return (
                          <React.Fragment key={item.id}>
                            <tr
                              className={`${
                                item?.status == constants?.PRODUCT.INACTIVE
                                  ? "inactive-row "
                                  : ""
                              }`}
                            >
                              <td>
                                <span className="mobile-heading">
                                  {Admin_Products?.SlNo[language]}:
                                </span>
                                <span className="table-data">{from + key}</span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {Admin_Products?.ProductImage[language]} :
                                </span>
                                <div className="prod-image-pmu">
                                  <img src={item?.images?.[0]?.path} />
                                </div>
                              </td>

                              <td>
                                <span className="mobile-heading">
                                  {Admin_Products?.ProductName[language]} :
                                </span>
                                <span className="table-data">
                                  <div>
                                    <div className="product-name-orders">
                                      {convertFieldName(item?.name)}{" "}
                                    </div>{" "}
                                    {""}
                                    {
                                      Admin_Products?.ProductCode[language]
                                    } :{" "}
                                    <span className="">
                                      {item?.product_code}
                                    </span>
                                    <br />
                                    <span className="text-secondary">
                                      {Admin_Products?.Category[language]} :{" "}
                                      <span className="">
                                        {item?.categories?.name}
                                      </span>
                                    </span>
                                  </div>
                                </span>
                              </td>

                              <td>
                                <span className="mobile-heading">
                                  {Admin_Products?.Status[language]}:
                                </span>
                                <span className="table-data">
                                  {item?.status == constants.PRODUCT.ACTIVE ? (
                                    <span className="approved-status active">
                                      {Admin_Products?.Active[language]}
                                    </span>
                                  ) : (
                                    <span className="inactive-status inactive">
                                      {Admin_Products?.Inactive[language]}
                                    </span>
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {Admin_Products?.Action[language]}:
                                </span>
                                <div className="action-flex-btn">
                                  <Link
                                    to=""
                                    title="View Product Details"
                                    onClick={() => {
                                      setIsShowings(true);
                                      getProductEdit(item?.id);
                                      setShowadd(false);
                                    }}
                                  >
                                    <i className="bi bi-eye lg"></i>
                                  </Link>
                                  {item?.status ==
                                  constants.PRODUCT.INACTIVE ? (
                                    <Link
                                      to=""
                                      title="Click to Activate"
                                      onClick={() =>
                                        getUpdateStatus(item?.id, 1)
                                      }
                                    >
                                      <i className="fas fa-toggle-off text-danger lg"></i>
                                    </Link>
                                  ) : (
                                    <Link
                                      to=""
                                      title="Click to Deactivate"
                                      onClick={() =>
                                        getUpdateStatus(item?.id, 0)
                                      }
                                    >
                                      <i className="fas fa-toggle-on text-success lg"></i>
                                    </Link>
                                  )}
                                  {item?.status ==
                                  constants.PRODUCT.INACTIVE ? (
                                    <Link
                                      to=""
                                      title="Delete"
                                      onClick={() => deleteProduct(item?.id)}
                                    >
                                      <i className="bi bi-trash3 text-danger lg bg-transparent"></i>
                                    </Link>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td
                          className="text-center px-2 py-5 empty-text"
                          colSpan={7}
                        >
                          {isLoading ? (
                            <div className="loading-overlay">
                              <Loader />
                            </div>
                          ) : (
                            "No Product Found"
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            {pageData && (
              <div className="table-footer-wrap">
                <small>
                  {Admin_Products?.Showing[language]}
                  {pageData.from} {Admin_Products?.to[language]} {pageData.to}{" "}
                  {Admin_Products?.of[language]} {pageData.total}{" "}
                  {Admin_Products?.entries[language]}
                </small>
                <div className="pagination">
                  <CustomPagination
                    totalItems={pageData?.total}
                    itemsPerPage={pageData?.per_page}
                    activePage={pageData?.current_page}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={ProductValidationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <>
              <UploadProductModal
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                handleSubmit={handleSubmit}
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                handleReset={handleReset}
                category={category}
                setFieldValue={setFieldValue}
                setSelectedFile={setSelectedFile}
                setFormData={setFormData}
                selectedFile={selectedFile}
                spec_data={spec_data}
                show_add={show_add}
                setShowadd={setShowadd}
                setSpecData={setSpecData}
                cat={cat}
                setCat={setCat}
                setCatValue={setCatValue}
                v={1}
                close_all={close_all}
                getCategory={getCategory}
                isUploading={isUploading}
              />

              <ViewProductModal
                isShowing={isShowings}
                setIsShowing={setIsShowings}
                category={category}
                setFormData={setFormData}
                spec_data={spec_data}
                FormData={formData}
              />
            </>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}
