export const Department_TDS:any={
    // TDS/Modal/TDSPaymentModal
    Upload_TDS_Details: {
        en: 'Upload TDS Details',
        ka: 'TDS ವಿವರಗಳನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ'
    },
    Choose_Documents: {
        en: 'Choose Documents',
        ka: 'ಡಾಕ್ಯುಮೆಂಟ್ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ' 
    },
    OrderID_ProductName: {
        en: ' Order ID / Product Name ',
        ka: 'ಆದೇಶ ಐಡಿ /ಉತ್ಪನ್ನದ ಹೆಸರು '
    },
    // TDS/TDSList
    Sl_No: {
        en: 'Sl No',
        ka: 'ಕ್ರಮ ಸಂಖ್ಯೆ'
    },
    Order_ID: {
        en: 'Order ID',
        ka: 'ಆದೇಶ ಐಡಿ'
    },
    Tender_ID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    Order_Details: {
        en: 'Order Details',
        ka: 'ಆದೇಶ ವಿವರಗಳು'
    },
    Shipping_Address: {
        en: 'Shipping Address',
        ka: 'ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ'
    },
    Date: {
        en: 'Date',
        ka: 'ದಿನಾಂಕ'
    },
    Quantity: {
        en: 'Quantity',
        ka: 'ಪ್ರಮಾಣ'
    },
    Action: {
        en: 'Action',
        ka: 'ಕ್ರಿಯೆ'
    },
    Product: {
        en: 'Product',
        ka: 'ಉತ್ಪನ್ನ'
    },
    More_than_one_order: {
        en: 'More than one order',
        ka: 'ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ಆದೇಶಗಳು'
    },
    Click_to_see_the_Order_list: {
        en: 'Click to see the Order list',
        ka: 'ಆದೇಶ ಪಟ್ಟಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ'
    },
    Unit_price: {
        en: 'Unit price',
        ka: 'ಯೂನಿಟ್ ಬೆಲೆ'
    },
    Total: {
        en: 'Total',
        ka: 'ಒಟ್ಟು'
    },
    Including_GST: {
        en: 'Including GST',
        ka: 'GST ಸೇರಿಸಿದ್ದು'
    },
     TDS: {
        en: ' TDS',
        ka: 'ಟಿಡಿಎಸ್'
    },
    Building_no: {
        en: 'Building no',
        ka: 'ಕಟ್ಟಡ ಸಂಖ್ಯೆ'
    },
    Pay_TDS: {
        en: 'Pay TDS',
        ka: 'TDS ಪಾವತಿ ಮಾಡಿ'
    },
    Uploaded: {
        en: 'Uploaded',
        ka: 'ಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿದೆ'
    },
    Approved: {
        en: 'Approved',
        ka: 'ಅನುಮೋದಿಸಲಾಗಿದೆ'
    },
     No_Orders_Found: {
        en: ' No Orders Found',
        ka: 'ಯಾವುದೇ ಆದೇಶಗಳು ಸಿಗಲಿಲ್ಲ'
    },
    Showing: {
        en: 'Showing',
        ka: 'ತೋರಿಸುತ್ತಿದೆ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ಯಲ್ಲಿ'
    },
    entries: {
        en: 'entries',
        ka: 'ಪ್ರವೇಶಗಳು'
    },

    // TDS/TDSListingLayout
    
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ '
    },
    Upload_form_No_16A: {
        en: 'Upload form No 16A',
        ka: 'ಫಾರ್ಮ್ 16A ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ'
    }
}