import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const ProfileValidation = Yup.object().shape({
    fullname: Yup.string().required("Name is required"),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10,'Phone number must be atleast 10 numbers').max(10,'Phone number must be 10 numbers').required('Phone number is required'),
    email: Yup.string().email("Invalid Email").required("Email is required"),
});
export const ProfilePasswordValidation = Yup.object().shape({
    otp: Yup.string().required('Enter OTP to update password').min(6),
    password: Yup.string().required('Password is required').min(8),
    password_confirmation: Yup.string().required('Confirm password').oneOf([Yup.ref('password'), null], "Passwords doesn't match")
});