import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
} from "reactstrap";
import { api } from "../../../Library/CustomApi";
import Toast from "reactstrap";
import { toast } from "react-toastify";
import { order_status } from "../../../Library/Constants/MapConstants";
import TextError from "../../../Components/Errors/TextError";
import { ErrorMessage } from "formik";
import { API_URL } from "../../../Library/ApiSettings";
import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { convertISODateToDateTime } from "../../../Library/Constants/helpers";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

const PaymentPendingModal = (props: any) => {
  const [invoice, setInvoice] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(true);
  const { language, setLanguage } = useContext(Context);
  const payment = props?.data;

  const generateinvoice = () => {
    api
      .post(
        `/order/invoice`,
        { vendorOrderId: props?.order_view[0]?.vendor_po_no },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Invoice Generated Successfully");
        // props?.toggle()
        setInvoice(true);

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const downloadinvoice = () => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      // body: JSON.stringify({
      //     orderId: props?.order_view[0]?.vendor_po_no
      //})
    };

    fetch(
      `${API_URL}/order/invoice-download?orderId=${props?.order_view[0]?.vendor_po_no}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Invoice.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        props?.setIsShowing(false);
        setDisabled(true);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={props?.setIsShowing}
        header="Payment Details"
      >
        <div className="feeback-outer-wrap">
          <div className="feedback-wrap">
            <Form className="form-wrap">
              <Container>
                <Row>
                  <Col>
                    <div className="feedback-outer-section">
                      <div className="feedback-outer">
                        <div className="feedback-inner thankyou-text">
                          <h5>{VendorRegLang?.thanks_for_your_order[language]}</h5>
                        </div>
                        {/* <div className="receipt-outer">
                          <p className="lead">Receipt</p>
                          <p className="small">
                            Transaction Number :{" "}
                            <span>
                              {payment?.payment_detail?.transaction_id}
                            </span>
                          </p>
                        </div> */}
                        <Row className="gy-4  product-payment-wrap">
                          <Col lg="5">
                            <div className="product-thumb-xxl">
                              <img
                                src={props?.data?.order_details?.product_details?.product_image?.[0]?.path}
                                className="img-fluid"
                                alt="Phone"
                              />
                            </div>
                          </Col>
                          <Col lg="7">
                          <div className="product-info">
                              <h2 className="main-heading">
                                {props?.data?.order_details?.name}
                              </h2>
                              <hr />
                              <div>
                                {VendorRegLang?.price[language]}:{" "}
                                <span className="green-text">
                                  ₹{props?.data?.total_amount}
                                </span>
                              </div>
                            </div>
                            <table className="table table-hover table-bordered">
                              {/* {
                                                            props?.data.map((val: any, index: any) => {
                                                                return (
                                                                    <> */}
                              <tbody>
                                {/* <tr>
                                  <td>
                                    <h4>Product Name </h4>
                                  </td>
                                  <td>{props?.data?.order_details.name}</td>
                                </tr> */}
                                <tr>
                                  <td>
                                    <h4>{VendorRegLang?.product_code[language]}</h4>
                                  </td>
                                  <td>
                                    {props?.data?.order_details.product_code}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <h4>{VendorRegLang?.hsn_code[language]}</h4>
                                  </td>
                                  <td>{props?.data?.order_details.hsn_code}</td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <h4 className="spec-head center">
                                      {VendorRegLang?.product_description[language]}{" "}
                                      {show ? (
                                        <i
                                          className="fa-solid fa-chevron-down"
                                          onClick={() => setShow(false)}
                                        ></i>
                                      ) : (
                                        <>
                                          <i
                                            className="fa-solid fa-chevron-up"
                                            onClick={() => setShow(true)}
                                          ></i>
                                        </>
                                      )}
                                    </h4>
                                  </td>
                                </tr>
                                {show && (
                                  <>
                                    {" "}
                                    <tr>
                                      <td colSpan={2}>
                                        <div className="prod-spec-outer-wrap">
                                          {props?.datas.map(
                                            (val: any, index: any) => {
                                              return (
                                                <React.Fragment key={index}>
                                                  <span className="product-spec-table-wrap">
                                                    <span className="spec-head green-text">
                                                      {val[0].key}
                                                    </span>{" "}
                                                    {" :"}{" "}
                                                    <span className="spec-content">
                                                      {val[0].val}
                                                    </span>
                                                    <hr />
                                                  </span>
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                              {/* </>
                                                                )
                                                            })
                                                        } */}
                            </table>
                            {/* <div className="links">
                              <div className="">
                                <Link to="#">
                                  <p>Track Order</p>
                                </Link>
                              </div>
                              <div className="text-warning">
                                <p>Out For Delivery</p>
                              </div>
                              <div className="text-success">
                                <p>Delivered</p>
                              </div>
                            </div> */}
                          </Col>
                        </Row>
                        <hr />
                        <div className="order-outer">
                          <h5 className="lead green-text">{VendorRegLang?.order_details[language]}</h5>
                         
                        </div>
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td>{VendorRegLang?.invoice_number[language]}</td>
                              <td> {props?.data?.pf_invoice?.pro_forma_no}</td>
                            </tr>
                            <tr>
                              <td>{VendorRegLang?.invoice_date[language]}</td>
                              <td>
                                {" "}
                                {convertISODateToDateTime(
                                  props?.data?.pf_invoice?.created_at
                                )}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>Recepits Voucher</td>
                              <td>
                                {" "}
                                {props?.data?.payment_detail?.transaction_id}
                              </td>
                            </tr> */}
                          </tbody>
                          <tbody>
                            <tr>
                              <td>{VendorRegLang?.total[language]}:&nbsp;</td>
                              <td> ₹ {props?.data?.total_amount}.00</td>
                            </tr>
                            <tr>
                              <td>{VendorRegLang?.gst[language]} 18%:&nbsp;</td>
                              <td>
                                {props?.data.pf_invoice.pf_details[0].total_gst}
                                .00
                              </td>
                            </tr>
                            <tr>
                              <td>{VendorRegLang?.delivery_charges[language]}&nbsp;</td>
                              <td><span className="text-success">{VendorRegLang?.free[language]}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default PaymentPendingModal;