import { useState, useContext } from "react";
import {
    Collapse,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Col, List
} from "reactstrap";

import { Link, matchPath } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Context } from "../../../../Components/Context/Context";

interface proptype {
    setOpenSidebar: Function,
    openSidebar: boolean,
    setSidebarIsOpen: Function,
    sidebarIsOpen: boolean
}

function ManagerSidebar({ openSidebar, setOpenSidebar, sidebarIsOpen, setSidebarIsOpen }: proptype) {
    const location_details = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [isOrdersOpen, setIsOrdersOpen] = useState(false);
    const { theme, toggleTheme, resetFilterHandler } = useContext(Context);

    return (
        <>
            {/* desktop-sidebar */}
            <div
                className={`${sidebarIsOpen ? 'col-md-2' : 'minimized-sidebar-wrap'} desktop-sidebar  `}
            >
                <Col md={12} className="logo-outer">
                    <div className="logo-inner-wrap">
                        <a className="header-logo" href="#">
                            {sidebarIsOpen ? <img
                                src="/images/logo-white.png"
                                className="d-block"
                                alt="icon"
                            /> : <img src="../images/mob-logo.png"></img>}
                        </a>
                        <div className="minimise-sidebar">
                            <span onClick={() => setSidebarIsOpen(!sidebarIsOpen)}><i className={`bi bi-arrow-${sidebarIsOpen ? 'left' : 'right'}-circle`}></i></span>
                            {/* <p onClick={()=>toggleTheme()}>{theme=='theme-light'?'Dark':'Light'}</p> */}
                        </div>
                    </div>
                </Col>
                <div className="sidebar-wrap dept-sidebar">
                    <List className="nav">
                        <li className={location_details?.pathname === "/store/landing"
                            ? "nav-item active"
                            : "nav-item"
                        }>
                            <span className="curve-before">
                                <span className="curve-inner"></span>
                            </span>
                            <Link
                                to="/store/landing"
                                title="Store"
                                className="nav-link"
                            >
                                <i className="icons icons-dashboard" />
                                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>Manager</span>
                            </Link>
                            <span className="curve-after">
                                <span className="curve-inner"></span>
                            </span>
                        </li>

                    </List>

                </div>
            </div>
            {/*mobile sidebar */}

            <Offcanvas
                isOpen={openSidebar}
                scrollable
                className="mobile-sidebar"
            >
                <OffcanvasHeader toggle={() => setOpenSidebar(false)}>
                    <div className="offcanvas-header">
                        <a className="navbar-brand" href="">
                            <img src="/images/logo-white-ktipp.png" alt="icon" />
                        </a>
                    </div>
                </OffcanvasHeader>

                <OffcanvasBody>
                    <div className="offcanvas-body">
                        <div className="sidebar-wrap">
                            <List className="nav">
                                <li className={location_details?.pathname === "/store/landing"
                                    ? "nav-item active"
                                    : "nav-item"
                                }>
                                    <span className="curve-before">
                                        <span className="curve-inner"></span>
                                    </span>
                                    <Link
                                        to="/store/landing"
                                        title="Dashboard"
                                        className="nav-link"
                                        onClick={() => setOpenSidebar(false)}
                                    >
                                        <i className="icons icons-dashboard" />
                                        Store
                                    </Link>
                                    <span className="curve-after">
                                        <span className="curve-inner"></span>
                                    </span>
                                </li>
                            </List>
                        </div>
                    </div>
                </OffcanvasBody>
            </Offcanvas>

        </>
    );
}
export default ManagerSidebar;
