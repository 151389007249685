import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import axios from "axios";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { convertISODateToDateTime } from "../../../../Library/Constants/helpers";
import moment from "moment";
import constants from "../../../../Library/Constants/Constants";
import { Context } from "../../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../../Library/LanguageConversion/Vendor_profileUpdation";
export default function StartupDocumentModal(props: any) {
  const { language, setLanguage } = useContext(Context);
  return (
    <React.Fragment>
      <ModalContainer
        open={props?.show}
        setOpen={() => {
          props?.setShow(false);
        }}
        header="Document View"
      >
        <div className="feeback-outer-wrap">
          <div className="feedback-wrap">
            <div className="feedback-details ">
              <hr />
              {props?.data?.type?.id == constants.DOCUMENTS.OEM && (
                <>
                  <Col>
                    Oem in company type:{" "}
                    {props?.data?.meta?.oem_in_company_type}
                  </Col>
                  <hr />
                  <Col>{Vendor_profileUpdation?.OEM_Valid_From[language]}:{props?.data?.meta?.oem_valid_from}</Col>
                  <hr />
                  <Col>{Vendor_profileUpdation?.OEM_Valid_To[language]}:{props?.data?.meta?.oem_valid_to}</Col>
                  <hr />
                </>
              )}

              {props?.data?.type?.id ==
                constants.DOCUMENTS.NO_CASE_DOCUMENT && (
                  <>
                    <Col>
                      Start up company udyamdate
                      {moment(
                        props?.data?.meta?.start_up_company_udyam_date
                      ).format("DD-MM-YYYY")}
                    </Col>
                    <hr />

                    <Col>
                      start up company udyam district industries center:
                      {
                        props?.data?.meta
                          ?.start_up_company_udyam_district_industries_center
                      }
                    </Col>
                    <hr />
                    <Col>
                      Start up company udyam major activity:
                      {props?.data?.meta?.start_up_company_udyam_major_activity}
                    </Col>
                    <hr />
                    <Col>
                      Start up company udyam msme dfo:
                      {props?.data?.meta?.start_up_company_udyam_msme_df}
                    </Col>
                    <hr />
                    <Col>
                      start up company udyam name of enterprises:
                      {
                        props?.data?.meta
                          ?.start_up_company_udyam_name_of_enterprises
                      }
                    </Col>
                    <hr />
                    <Col>
                      Start up company udyam registration number:
                      {
                        props?.data?.meta
                          ?.start_up_company_udyam_registration_number
                      }
                    </Col>
                    <hr />
                    <Col>
                      Start up company udyam name of enterprises:
                      {
                        props?.data?.meta
                          ?.start_up_company_udyam_name_of_enterprises
                      }
                    </Col>
                    <hr />
                    <Col>
                      Start up company udyam social category of enterprises:
                      {
                        props?.data?.meta
                          ?.start_up_company_udyam_social_category_of_enterprises
                      }
                    </Col>
                    <hr />
                    <Col>
                      Start up company udyam type of enterprises:
                      {
                        props?.data?.meta
                          ?.start_up_company_udyam_type_of_enterprises
                      }
                    </Col>
                    <hr />
                    <Col>
                      Start up company udyam type office address:
                      {
                        props?.data?.meta
                          ?.start_up_company_udyam_type_office_address
                      }
                    </Col>
                  </>
                )}
              {props?.data?.type?.id ==
                constants.DOCUMENTS.UDYAM_DFO && (
                  <>
                    <Col>
                      Udyam Date :
                      {
                        props?.data?.meta ?.start_up_company_udyam_date
                      }
                    </Col>
                    <hr />
                    <Col>
                    {Vendor_profileUpdation?.District_Industries[language]}:
                      {props?.data?.meta?.start_up_company_udyam_district_industries_center}
                    </Col>
                    <hr />
                    <Col>
                    {Vendor_profileUpdation?.Major_Activity[language]}:
                      {props?.data?.meta?.start_up_company_udyam_major_activity}
                    </Col>
                    <hr />
                    <Col>
                      MSME DF:
                      {props?.data?.meta?.start_up_company_udyam_msme_df}
                    </Col>
                    <hr />
                    <Col>
                      Name of Enterprises:
                      {props?.data?.meta?.start_up_company_udyam_name_of_enterprises}
                    </Col>
                    <hr />
                    <Col>
                      Registration Number :
                      {props?.data?.meta?.start_up_company_udyam_registration_number}
                    </Col>
                    <hr />

                    <Col>
                      Category of Enterprises  :
                      {props?.data?.meta?.start_up_company_udyam_social_category_of_enterprises}
                    </Col>
                    <hr />

                    <Col>
                    {Vendor_profileUpdation?.Type_Of_Enterprises[language]}:
                      {props?.data?.meta?.start_up_company_udyam_type_of_enterprises}
                    </Col>
                    <hr />

                    <Col>
                    {Vendor_profileUpdation?.Office_Address[language]}:
                      {props?.data?.meta?.start_up_company_udyam_type_office_address}
                    </Col>
                    <hr />


                  </>
                )}


                    {/* Attorny */}
              {props?.data?.type?.id == constants.DOCUMENTS.POWER_ATO && (
                <>
                  <Col>
                  {Vendor_profileUpdation?.Company_Name[language]}:{" "}
                    {moment(props?.data?.meta?.start_up_company_power_attorney_company_name).format(
                      "DD-MM-YYYY"
                    )}
                  </Col>
                  <hr />
                  <Col>
                    Director Name: {props?.data?.meta?.start_up_company_power_attorney_director_name}
                  </Col>
                  <hr />
                </>
              )}


              {props?.data?.type?.id == constants.DOCUMENTS.HR_ANY_OFFICE && (
                <>
                  <Col>
                  {Vendor_profileUpdation?.Name[language]} :{" "}
                    {props?.data?.meta?.any_other_company_name}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.Email[language]} : {" "}
                    {props?.data?.meta?.any_other_company_email}
                  </Col>
                  <hr />

                  <Col>
                  {Vendor_profileUpdation?.City[language]} : {" "}
                    {props?.data?.meta?.any_other_company_city}
                  </Col>
                  <hr />

                  <Col>
                  {Vendor_profileUpdation?.District[language]} : {" "}
                    {props?.data?.meta?.any_other_company_district}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.State[language]} : {" "}
                    {props?.data?.meta?.any_other_company_state}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.Pincode[language]} : {" "}
                    {props?.data?.meta?.any_other_company_pincode}
                  </Col>
                  <hr />

                </>
              )}

              {/* startup certificate */}

              {props?.data?.type?.id == 35 && (
                <>
                  <Col>{Vendor_profileUpdation?.Company_Name[language]}: {props?.data?.meta?.start_up_company_name}</Col>
                  <hr />
                  <Col>{Vendor_profileUpdation?.Company_Number[language]}: {props?.data?.meta?.start_up_company_number}</Col>
                  <hr />
                  <Col>{Vendor_profileUpdation?.Company_incorporation[language]}: {props?.data?.meta?.start_up_company_incoparation_date}</Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.Company_Address[language]}: {props?.data?.meta?.start_up_company_address}
                  </Col>
                  <hr />
                </>
              )}

              {/* Block certificate */}

              {props?.data?.type?.id == 36 && (
                <>
                  <Col>Block List: {props?.data?.meta?.start_up_company_under_black_list}</Col>
                  <hr />

                </>
              )}

             

              {props?.data?.type?.id == constants.DOCUMENTS.HR_LAND && (
                <>
                  <Col>Land extent: {props?.data?.meta?.land_extent}</Col>
                  <hr />
                  <Col>Land survey no: {props?.data?.meta?.land_survey_no}</Col>
                  <hr />
                  <Col>Land value : {props?.data?.meta?.land_value}</Col>
                  <hr />
                  <Col>
                    Land village col : {props?.data?.meta?.land_village_col}
                  </Col>
                  <hr />
                </>
              )}



              {props?.data?.type?.id ==
                constants.DOCUMENTS.IT_TURN_OVER_ONE && (
                  <>
                    <Col>
                      First IT turn over Amount:{" "}
                      {props?.data?.meta?.f_it_turn_over_amount}
                    </Col>
                    <hr />
                    <Col>
                      First IT turn over Year:{" "}
                      {props?.data?.meta?.f_it_turn_over_year}
                    </Col>
                    <hr />
                    <Col>
                      First IT turn over CA Email:{" "}
                      {props?.data?.meta?.it_turn_over_ca_email}
                    </Col>
                    <hr />
                    <Col>
                      First IT turn over CA Name:{" "}
                      {props?.data?.meta?.it_turn_over_ca_name}
                    </Col>
                    <hr />
                    <Col>
                      {" "}
                      First IT turn over CA Phone:{" "}
                      {props?.data?.meta?.it_turn_over_ca_phone}
                    </Col>
                  </>
                )}
              {props?.data?.type?.id ==
                constants.DOCUMENTS.IT_TURN_OVER_TWO && (
                  <>
                    <Col>
                      Second IT turn over Amount:{" "}
                      {props?.data?.meta?.s_it_turn_over_amount}
                    </Col>
                    <hr />
                    <Col>
                      IT turn over Year: {props?.data?.meta?.s_it_turn_over_year}
                    </Col>
                    <hr />
                    <Col>
                      IT turn over CA Email:{" "}
                      {props?.data?.meta?.it_turn_over_ca_email}
                    </Col>
                    <hr />
                    <Col>
                      IT turn over CA Name:{" "}
                      {props?.data?.meta?.it_turn_over_ca_name}
                    </Col>
                    <hr />
                    <Col>
                      {" "}
                      IT turn over CA Phone:{" "}
                      {props?.data?.meta?.it_turn_over_ca_phone}
                    </Col>
                  </>
                )}
            </div>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
}
