import React, { useEffect, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap";
import { useState, useMemo } from "react";
import { api } from "../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Formik, Form, ErrorMessage } from "formik";
import { BasicDetailsValidationSchema } from "./UploadValidation";
import TextError from "../../../Components/Errors/TextError";
import { convertISODateToDateTime } from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
import Loader from "../../../Components/Loader/Loader";
export default function VendorRegister() {
  const { language, setLanguage } = useContext(Context);
  const token = localStorage?.getItem("AUTH_TOKEN");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    user_id: "",
    departments: "",
    reg_id: "",
    joined_at: "",
  });
  const initialValues = {
    name: "" || data?.name,
    email: "" || data?.email,
    phone: "" || data?.phone,
    departments: "" || data?.departments,
    user_id: "",
    profile_status: 1,
    type: "basic",
    reg_id: "" || data?.reg_id,
    joined_at: "" || convertISODateToDateTime(data?.joined_at),
  };
  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = () => {
    setLoading(true);
    api
      .post(`/get_vendor_profile`, {}, true)
      .then(async function ([success, response]) {
        let c = response?.vendor_details?.company_details[0];
        let a = response?.vendor_details?.advanced_registration_details[0];
        setData({
          ...data,
          name: c?.name,
          phone: c?.phone,
          email: c?.email,
          user_id: c?.user_id,
          departments: a?.departments,
          reg_id: c?.registration_id,
          joined_at: c?.created_at,
        });
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions);
  };
  const sendData = (values: any, actions: any) => {
    api
      .patch(
        `/vendors/${data?.user_id}`,
        {
          ...values,
        },
        true
      )
      .then(async function ([success, response, status]: any) {
        toast.success("Updated successfully");
        //actions.isSubmitting(false);
        navigate("/advanced-registration");
        return response;
      })
      .catch((err) => {
        //toast.success("updated successfully");
        //   actions.isSubmitting(false);
        //   navigate("/basic-details-view");
        // if (err?.response?.data?.message) {
        //   actions.isSubmitting(false);
        // } else {
        //   toast.success("updated successfully");
        //   actions.isSubmitting(false);
        //   navigate("/basic-details-view");
        // }
        // if (err.response.status == 500) {
        //   actions.isSubmitting(false);
        // }
      });
  };
  return (
    <>
      <div className="login-content">
        <Formik
          validationSchema={BasicDetailsValidationSchema}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({ errors, values, isSubmitting, handleChange, touched }) => (
            <>
              <Form className="Reg-wrap">
                {loading ? (
                  <div className="loading-overlay">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                              {Vendor_profileUpdation?.Full_Name?.[language]}
                            </Label>
                            <Input
                              name="name"
                              type="text"
                              value={values?.name}
                              className={`form-control ${
                                touched.name && errors.name ? "is-invalid" : ""
                              }`}
                              onChange={handleChange}
                              disabled
                            />
                            <ErrorMessage name="name" component={TextError} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                              {Vendor_profileUpdation?.Phone_Number?.[language]}
                            </Label>
                            <Input
                              name="phone"
                              type="text"
                              value={values?.phone}
                              className={`form-control ${
                                touched.phone && errors.phone
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                              disabled
                            />
                            <ErrorMessage name="phone" component={TextError} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                              {
                                Vendor_profileUpdation
                                  ?.company_registration_number?.[language]
                              }
                            </Label>
                            <Input
                              name="reg_id"
                              type="text"
                              value={values?.reg_id}
                              className={`form-control ${
                                touched.reg_id && errors.reg_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                              disabled
                            />
                            <ErrorMessage name="reg_id" component={TextError} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>Joined at</Label>
                            <Input
                              name="joined_at"
                              type="text"
                              value={values?.joined_at}
                              className={`form-control ${
                                touched.joined_at && errors.joined_at
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                              disabled
                            />
                            <ErrorMessage
                              name="joined_at"
                              component={TextError}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>Select Department</Label>
                        <Input
                          type="select"
                          label="Department"
                          name="departments"
                          value={values?.departments}
                          onChange={handleChange}
                        >
                          <option value="">Select </option>
                          {departments?.map((item: any) => {
                            return (
                              <>
                                <option value={item?.value}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </Input>
                        <ErrorMessage
                          name="departments"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col> */}
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                              {Vendor_profileUpdation?.Email?.[language]}
                            </Label>
                            <InputGroup>
                              <Input
                                name="email"
                                type="text"
                                value={values?.email}
                                className={`form-control ${
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onChange={handleChange}
                                disabled
                              />
                              {token == null && (
                                <>
                                  <InputGroupText>
                                    <i className="bi bi-arrow-right-square-fill primary-text"></i>
                                  </InputGroupText>
                                </>
                              )}{" "}
                            </InputGroup>
                            <ErrorMessage name="email" component={TextError} />
                          </div>
                        </FormGroup>
                      </Col>
                      {token == null && (
                        <>
                          <Col sm={12}>
                            <div className="breadcrumb-tabs-outer-wrap">
                              <div className="agree-checkbox-wrap">
                                <Input type="checkbox"></Input>
                                <Label>
                                  {
                                    Vendor_profileUpdation
                                      ?.Send_Notifications?.[language]
                                  }
                                </Label>
                              </div>
                              <Link to="#" className="primary-text">
                                {Vendor_profileUpdation?.Resend_OTP?.[language]}
                              </Link>
                            </div>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>
                                  {
                                    Vendor_profileUpdation?.Enter_OTP?.[
                                      language
                                    ]
                                  }
                                </Label>
                                <Input type="password"></Input>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>
                                  {
                                    Vendor_profileUpdation?.Confirm_Password?.[
                                      language
                                    ]
                                  }
                                </Label>
                                <Input type="password"></Input>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>
                                  {
                                    Vendor_profileUpdation?.Confirm_Password?.[
                                      language
                                    ]
                                  }
                                </Label>
                                <InputGroup>
                                  <Input type="password" />
                                  <InputGroupText>
                                    <i className="bi bi-eye"></i>
                                  </InputGroupText>
                                </InputGroup>
                              </div>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      {token == null && (
                        <>
                          <Col sm={12}>
                            <h6 className="mb-4">
                              {Vendor_profileUpdation?.Already_have?.[language]}
                              <Link
                                to="/login"
                                className="primary-text text-decoration-none"
                              >
                                {Vendor_profileUpdation?.Login?.[language]}
                              </Link>
                            </h6>
                          </Col>
                          <Col sm={12}>
                            <Button
                              class="btn btn-primary"
                              color="primary"
                              type="submit"
                            >
                              {Vendor_profileUpdation?.Register?.[language]}
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}
