import React, { createContext, useEffect, useState } from 'react';
import { api } from '../../Library/CustomApi';

interface ContextType {
    itemCount: number;
    setItemCount: (value: number) => void;
    updateCartCount: () => void;
    rate_id: any;
    setRateId: any;

    // products-dept 
    searchVal: any;
    setSearchVal: any;
    catFilterVal: any;
    setCatFilterVal: any;
    specSelectedVal: any;
    setSpecSelectedVal: any;
    resetFilterHandler: any;

    // theme
    theme: any;
    setTheme: any;
    toggleTheme: any;

    // Language switch 
    language: any;
    setLanguage: Function;
}

const initialItemCount: ContextType = {
    itemCount: 0,
    rate_id: 0,
    setRateId: () => { },
    setItemCount: () => { },
    updateCartCount: () => { },

    // products-dept 
    searchVal: '',
    setSearchVal: () => { },
    catFilterVal: [],
    setCatFilterVal: () => { },
    specSelectedVal: [],
    setSpecSelectedVal: () => { },
    resetFilterHandler: () => { },

    // theme 
    theme: '',
    setTheme: () => { },
    toggleTheme: () => { },
    language: '',
    setLanguage: () => { },
};

export const Context = createContext<ContextType>(initialItemCount);

export const ContextProvider = ({ children }: any) => {
    const [itemCount, setItemCount] = useState<any>(10);
    const [cartItems, setCartItems] = useState<any>([]);
    const [rate_id, setRateId] = useState<any>(0);

    // products-dept 
    const [searchVal, setSearchVal] = useState<string>("")
    const [catFilterVal, setCatFilterVal] = useState<number[]>([])
    const [specSelectedVal, setSpecSelectedVal] = useState<string[]>([])

    // theme 
    const [theme, setTheme] = useState<'theme-dark' | 'theme-light'>('theme-light')

    // language 
    const [language, setLang] = useState<string>(sessionStorage.getItem('language') || 'en');

    const setLanguage = (selectedLanguage: string) => {
        setLang(selectedLanguage);
        sessionStorage.setItem('language', selectedLanguage);
    }

    useEffect(() => {
        let count = 0
        if (cartItems) {
            // to display count of individual product as cart icon count
            // cartItems?.forEach((el: any) => {
            //     count = count + el?.quantity
            // });

            // to display cart icon count as products count
            count = cartItems?.length
        }
        setItemCount(count);
    }, [cartItems])

    // theme toggler
    const toggleTheme = () => {
        setTheme(theme == 'theme-dark' ? 'theme-light' : 'theme-dark')
    }

    const updateCartCount = () => {
        api
            .get(`/product/cart`, true)
            .then(async function ([success, response]) {
                setCartItems(response?.data)
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // reset button for filter 
    const resetFilterHandler = () => {
        setSearchVal('')
        setCatFilterVal([])
        setSpecSelectedVal([])
    }

    const ContextValue = {
        itemCount,
        setItemCount,
        updateCartCount,
        rate_id,
        setRateId,
        searchVal,
        setSearchVal,
        catFilterVal,
        setCatFilterVal,
        specSelectedVal,
        setSpecSelectedVal,
        resetFilterHandler,

        // theme 
        theme,
        setTheme,
        toggleTheme,

        // language
        language,
        setLanguage,
    };

    return (
        <Context.Provider value={ContextValue}>
            {children}
        </Context.Provider>
    );
};