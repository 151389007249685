import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../../Components/Context/Context';
import { Link } from 'react-router-dom';
import { Button, Form, Input, InputGroup, InputGroupText, List, Table } from 'reactstrap';
import { vendor_types } from '../../../../Library/Constants/MapConstants';
import { api } from '../../../../Library/CustomApi';
import constants from '../../../../Library/Constants/Constants';
import Loader from '../../../../Components/Loader/Loader';
import CustomPagination from '../../../../Components/CustomPagination/Index';
import ModalContainer from '../../../../Components/Modal/ModalContainer';
import AddQAVendorModal from './AddQAVendorModal.tsx/AddQAVendorModal';
import { Formik } from 'formik';
import InviteVendor from '../../Empanelment/Modals/InviteVendor/InviteVendor';
import { toast } from 'react-toastify';
import { Admin_services } from '../../../../Library/LanguageConversion/Admin_Services';
export default function QAVendorIndex() {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageData, setPageData] = useState<any>();
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showAddVendor, setShowAddVendor] = useState<boolean>(false);
    const [showInviteVendor, setShowInviteVendor] = useState(false); // toggles invite vendor modal
    const [get_vendor, setGetVendor] = useState({});
    const [get_vendors, setGetVendors] = useState({});
    const { language, setLanguage } = useContext(Context);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search !== null || search === "") {
                getTableData(currentPage);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [search, currentPage]);
    // Get Vendor List 
    const getTableData = (currentPage: any) => {
        setIsLoading(true);
        api
            .get(
                `/qa_vendors?page=${currentPage}&search_keyword=${search}`,
                true
            )
            .then(async function ([success, response]) {
                setTableData(response?.data || []);
                setPageData(response?.meta || []);
                setIsLoading(false);
                return response;
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    // search handler 
    const handleSearch = (event: any) => {
        setCurrentPage(1);
        setSearch(event.target.value);
    };

    // handles invite vender modal submit 
    const handleSend = (values: any, actions: any, get_vendor: any) => {
        sendInvite(values, actions, get_vendor);
    };

    const sendInvite = (values: any, actions: any, get_vendor: any) => {
        api
            .post(
                `/invite_vendor`,
                {
                    email: get_vendor?.company?.email,
                    cmpny_name: get_vendor?.company?.name,
                    user_id: get_vendor?.user?.id,
                },
                true
            )
            .then(async function ([success, response]) {
                toast.success("Invitation Send Successfully");
                actions.setSubmitting(false);
                setShowInviteVendor(false);
                getTableData(1);
            })
            .catch((err) => {
                console.log(err);
                actions.setSubmitting(false);
                if (err?.response?.status == 422) {
                    toast.error(err?.response?.data?.message);
                }
            });
    };

    return (
        <div className='right-content-wrap'>
            <div className="breadcrumb-tabs-outer-wrap">
                {/* breadcrumb */}
                <List className="breadcrumb">
                    <li>
                        <Link className="active" to="/dashboard">
                            {Admin_services?.Dashboard?.[language]}
                        </Link>
                    </li>
                    &nbsp; / &nbsp;
                    <li>
                        {/* <Link className="active" to="/amc"> */}
                        <Link className="active" to="">

                            <b>{Admin_services?.Services?.[language]}</b>
                        </Link>
                    </li>
                    {/* &nbsp; / &nbsp; */}
                    <li>
                        <b>{Admin_services?.ThirdPartyInspection?.[language]}</b>
                    </li>
                </List>
            </div>
            <div className="custom-tab-content">
                <div className="table-outer-wrap">
                    <div className="search-widget-outer">
                        <Form role="search" className="search">
                            <InputGroup className="search-group">
                                <InputGroupText title='Vendor Name' className="search-icon" id="search">
                                    <i className="fa-sharp fa-solid fa-magnifying-glass" />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={Admin_services?.VendorName[language]}
                                    aria-label="Username"
                                    aria-describedby="search"
                                    value={search}
                                    onChange={handleSearch}
                                    onKeyPress={(event) => {
                                        var key = event.keyCode || event.which;
                                        if (key === 13) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </InputGroup>
                            {/* Mobile add button */}
                            <div className="add-button mobile-view">
                                <Link onClick={() => setShowAddVendor(true)} to="">
                                    <Button
                                        color="primary"
                                        style={{ minWidth: "auto", cursor: "pointer" }}
                                        className="bi bi-plus-lg"
                                    >
                                    </Button>
                                </Link>
                            </div>
                        </Form>
                        {/* Large screen add button */}
                        <div className="add-button large-screen-view">
                            <Link onClick={() => setShowAddVendor(true)} to="">
                                <Button
                                    color="primary"
                                    style={{ minWidth: "auto", cursor: "pointer" }}
                                >
                                    <i className="bi bi-plus" />
                                    {Admin_services?.AddThirdPartyInspector[language]}
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className="table-wrap responsive-table">
                        <Table className="w-100  table-striped">
                            <thead>
                                <tr>
                                    <th>{Admin_services?.SlNo?.[language]}</th>
                                    <th>{Admin_services?.VendorName?.[language]}</th>
                                    <th>{Admin_services?.EmailID?.[language]}</th>
                                    <th>{Admin_services?.ContactNumber?.[language]}</th>
                                    <th>{Admin_services?.RegistrationNumber?.[language]}</th>
                                    <th>{Admin_services?.VendorType?.[language]}</th>
                                    <th>{Admin_services?.CreatedDate?.[language]}</th>
                                    <th>{Admin_services?.Status?.[language]}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {tableData?.length !== 0 ? (
                                    tableData?.map((item: any, i: number) => {
                                        return (
                                            <React.Fragment key={item.id}>
                                                <tr>
                                                    <td>
                                                        <span className="mobile-heading">{Admin_services?.SlNo?.[language]}:</span>
                                                        <span className="table-data">{pageData?.from + i}</span>
                                                    </td>
                                                    <td>
                                                        <span className="mobile-heading">{Admin_services?.VendorName?.[language]}:</span>
                                                        <span className="table-data">
                                                            {item?.company?.name}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="mobile-heading">{Admin_services?.EmailID?.[language]}:</span>
                                                        <span className="table-data">{item.user?.email}</span>
                                                    </td>
                                                    <td>
                                                        <span className="mobile-heading">{Admin_services?.ContactNumber?.[language]}:</span>
                                                        <span className="table-data">{item.user?.phone}</span>
                                                    </td>
                                                    <td>
                                                        <span className="mobile-heading">{Admin_services?.RegistrationNumber?.[language]}:</span>
                                                        <span className="table-data">
                                                            {item?.company?.registration_id}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="mobile-heading">
                                                            {Admin_services?.Categoryofvendors?.[language]}:
                                                        </span>
                                                        <span className="table-data">
                                                            {
                                                                vendor_types?.map((obj: any, i) => item.vendor_type == obj.value && obj?.name)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="mobile-heading">
                                                            {Admin_services?.CreatedDate?.[language]}:
                                                        </span>
                                                        <span className="table-data">
                                                            {" "}
                                                            {item.created_at
                                                                ? moment(item.created_at).format("DD-MM-YYYY")
                                                                : null}{" "}
                                                        </span>
                                                    </td>

                                                    {item?.status == constants.VENDOR_STAUS.REGISTERED ? (
                                                        <td >
                                                            <span className="mobile-heading">{Admin_services?.Status?.[language]}:</span>
                                                            <span className="table-data">
                                                                {item?.user?.invite_status ==
                                                                    constants.INVITE_STATUS.INVITE_NOT_SEND && (
                                                                        <Link
                                                                            className="green-text no-wrap"
                                                                            onClick={() => {
                                                                                setShowInviteVendor(true);
                                                                                setGetVendor(item);
                                                                                let initialValues = {
                                                                                    vendor_name: item?.company?.name,
                                                                                    company_name: item?.company?.email,
                                                                                    tender_id: item?.company_name,
                                                                                    mobile_number: item?.company_name,
                                                                                    email_id: item?.company_name,
                                                                                    product: item?.company_name,
                                                                                };
                                                                                setGetVendors(initialValues);
                                                                            }}
                                                                            to=""
                                                                        >
                                                                            <i className="bi bi-send"></i> {Admin_services?.SentInvitation?.[language]}
                                                                        </Link>
                                                                    )}

                                                                {item?.user?.invite_status ==
                                                                    constants.INVITE_STATUS.INVITE_SEND && (
                                                                        <span className=" invited-status">
                                                                            <i className="bi bi-envelope-open"></i> {Admin_services?.Invite?.[language]}
                                                                        </span>
                                                                    )}
                                                                {item?.user?.invite_status ==
                                                                    constants.INVITE_STATUS.DOCUMENTS_UPLOADED && (
                                                                        <span className="uploaded-status">
                                                                            {" "}
                                                                            <i className="bi bi-file-earmark-arrow-up"></i>{" "}
                                                                            <span>{Admin_services?.Uploaded?.[language]}</span>
                                                                        </span>
                                                                    )}
                                                                {item?.user?.invite_status ==
                                                                    constants.INVITE_STATUS
                                                                        .DOCUMENT_UNDER_VERIFICATION && (
                                                                        <span className=" info-status">
                                                                            {" "}
                                                                            <i className="bi bi-file-earmark-check"></i>{" "}
                                                                            <span>{Admin_services?.UnderVerification?.[language]}</span>
                                                                        </span>
                                                                    )}
                                                                {item?.user?.invite_status ==
                                                                    constants.INVITE_STATUS.DOCUMENT_VERIFIED && (
                                                                        <span color="approved-status">
                                                                            {" "}
                                                                            <i className="bi bi-file-earmark-check"></i>{" "}
                                                                            <span>{Admin_services?.Verified?.[language]}</span>
                                                                        </span>
                                                                    )}
                                                                {item?.user?.invite_status ==
                                                                    constants.INVITE_STATUS.ACCEPTED && (
                                                                        <span className="approved-status">
                                                                            {" "}
                                                                            <i className="bi bi-envelope-open"></i>{" "}
                                                                            <span>{Admin_services?.Accepted?.[language]}</span>
                                                                        </span>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : item?.status === constants?.VENDOR_STAUS.PENDING ? (
                                                        <td className="warning">
                                                            <span className="mobile-heading">{Admin_services?.Status?.[language]}:</span>
                                                            <span className="table-data pending-status">
                                                                <i className="bi bi-clock"></i>{" "}{Admin_services?.Pending?.[language]}
                                                            </span>
                                                        </td>
                                                    ) : item?.status === constants?.VENDOR_STAUS.VERIFIED ? (
                                                        <td className="success">
                                                            <span className="mobile-heading">{Admin_services?.Status?.[language]}:</span>
                                                            <span className="table-data verified-status">
                                                                <i className="bi bi-patch-check"></i>{" "}{Admin_services?.Verified?.[language]}
                                                            </span>
                                                        </td>
                                                    ) : item?.status === 2 ? (
                                                        <td className="danger">
                                                            <span className="mobile-heading">{Admin_services?.Status?.[language]}:</span>
                                                            <span className="table-data inactive-status">
                                                                <i className="bi bi-exclamation-circle"></i>{" "}{Admin_services?.Inactive?.[language]}
                                                            </span>
                                                        </td>
                                                    ) : null}
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })
                                ) : (
                                    // section to display loading and error messages
                                    <tr>
                                        <td colSpan={8} className="text-center px-2 py-5 empty-text">
                                            {isLoading ? <div className="d-flex justify-content-center">
                                                <Loader />
                                            </div>
                                                : "No Vendors Found"}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    {tableData?.length !== 0 && pageData && (
                        <div className="table-footer-wrap">
                            <small>
                            {Admin_services?.Showing?.[language]} {pageData?.from} {Admin_services?.to?.[language]} {pageData?.to} {Admin_services?.of?.[language]} {pageData?.total} {Admin_services?.entries?.[language]}
                            </small>
                            <div className="pagination">
                                <CustomPagination
                                    totalItems={pageData?.total}
                                    itemsPerPage={pageData?.per_page}
                                    activePage={pageData?.current_page}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <ModalContainer
                    open={showAddVendor}
                    setOpen={setShowAddVendor}
                    header='Add QA-Vendor'
                >

                    <AddQAVendorModal
                        getTableData={getTableData}
                        setShowAddVendor={setShowAddVendor}
                    />
                </ModalContainer>

                {/* invite vendor modal  */}
                <Formik
                    initialValues={get_vendors}
                    // validationSchema={inviteVendorSchema}
                    onSubmit={(values, actions) => {
                        console.log(values, "fgsdfdsg");
                        handleSend(values, actions, get_vendor);
                    }}
                >
                    {({
                        handleSubmit,
                        values,
                        handleChange,
                        errors,
                        touched,
                        resetForm,
                        isSubmitting,
                    }) => {
                        return (
                            <InviteVendor
                                showInviteVendor={showInviteVendor}
                                setShowInviteVendor={setShowInviteVendor}
                                handleSubmit={handleSubmit}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                resetForm={resetForm}
                                get_vendor={get_vendor}
                                handleSend={handleSend}
                                isSubmitting={isSubmitting}
                            />
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}
