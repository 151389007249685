import React, { useEffect, useContext } from "react";
import { useState, useMemo } from "react";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import constants from "../../../Library/Constants/Constants";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import OrderViews from "./ViewModal";
import { Formik } from "formik";
import { orderValidation } from "./OrderValidation";
import { toast } from "react-toastify";
import { getOrderStatus } from "../../../Library/Constants/helpers";
import ChallanModal from "./DeliveryChallanModal";
import axios from "axios";
import { API_URL } from "../../../Library/ApiSettings";
import { challanValidation } from "./OrderValidation";
import {
  order_status_three,
  order_status_two,
} from "../../../Library/Constants/MapConstants";
import ProductDetailsModal from "./productDetailsModal";
import OutForDelivaryOrderModal from "./OrderModal/OutForDeliveryModal";

export default function OutofDeliveryOrders(props: any) {
  const [loading, setLoading] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [received_order, setReceivedOrder] = useState([]);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [order_view, setOrderView] = useState([]);
  const [orderid, setorderid] = useState("");
  const [search, setSearch] = useState("");
  const [isshowing, setIsshowing] = useState(false);
  const [orderIds, setOrderIds] = useState("");
  const [values, setValues] = useState<any>();
  const [getProductdet, setgetProductdet] = useState<any>();
  const [isShowings, setIsShowings] = useState(false);
  const [latitudes, setLatitudes] = useState<any>([]);
  const [longitudes, setLongitudes] = useState<any>([]);
  const { language, setLanguage } = useContext(Context);
  const navigate=useNavigate()
  const [location, setLocation] = useState<any>([
    {
      images: "",
      latitude: "",
      longitude: "",
    },
  ]);

  const [value, setValue] = useState({
    orderid: "",
    proforma_id: "",
    product_id: "",
    quantity: "",
  });

  const challanValues = {
    challan: "",
    order_id: "",
  };

  const toggle = () => setModal(!modal);

  const initialValues = {
    order_status: "",
    challan: "",
    wou: "",
    invoice: "",
    location: [],
    order_id: "",
  };

  useEffect(() => {
    getReceivedOrder();
  }, [currentPage]);
  // ========================================================== Get Recieved Order ==========================================================
  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getReceivedOrder(1);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const getReceivedOrder = (page = 1) => {
    setLoading(true);
    api
      .get(
        `/order/orders?status=9&page=${currentPage}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setReceivedOrder(response?.data || []);
        setPageData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ================================================================ View ==================================================================

  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-order-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleSubmit = (values: any, actions: any) => {
    const formData = new FormData();

    formData.append("workcompletion", values?.wou);
    location.slice(1).forEach((location: any, index: any) => {
      formData.append(
        `images[]`,
        location?.images ? location?.images : location
      );
      formData.append(`latitude[]`, location?.latitude);
      formData.append(`longitude[]`, location?.longitude);
    });
    formData.append("order_id", values?.order_id);
    formData.append("status", values?.order_status);
    formData.append("proforma_id", value?.proforma_id);

    const body = formData;

    axios({
      method: "post",
      url: `${API_URL}/order/delivery-documents-upload`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success("Document Uploaded Successfuly");
          actions.resetForm();
          actions.setSubmitting(false);
          getReceivedOrder();
          setIsShowing(false);
          setLatitudes("");
          setLongitudes("");
          setLocation([{ images: "", latitude: "", longitude: "" }]);
          navigate("/delivered-vendor")
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitss = (values: any, actions: any) => {
    const formData = new FormData();

    formData.append("images", values?.images);
    formData.append("order_id", values?.order_id);
    formData.append("status", values?.order_status);
    formData.append("lattitude", latitudes);
    formData.append("longitude", longitudes);

    const body = formData;

    axios({
      method: "post",
      url: `${API_URL}/order/delivery-documents-upload`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success("Chellan Uploaded Successfuly");
          setIsshowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
          getReceivedOrder();
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendData = (values: any, actions: any) => {
    api
      .post(
        `/order/delivery-documents-upload`,
        {
          order_id: value?.orderid,
          status: values?.order_satus,
          proforma_id: value?.proforma_id,
          product_id: value?.product_id,
          quantity: value?.quantity,
        },
        true
      )
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          toast.success("Order Status Updated!");
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
          getReceivedOrder();
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeTags = (str: any) => {
    const regex = /(<([^>]+)>)/gi;
    // const result = props?.data?.description.replace(regex, '');
    if (str === null || str === "") return false;
    else str = str?.toString();
    //    let all= str?.replace(/(<<([^>]+)>>)/g, ' ');
    let all = str?.replace(/(<br>)/g, " ,");
    let alls = all?.replace(/(<([^>]+)>)/g, "");
    let val = alls.split(",");
    var vals: any[] = [];
    val.map((items: any, index: any) => {
      var vall = val[index].split(":");
      vals.push([{ key: vall[0], val: vall[1] }]);
    });
    setValues(vals);
    return vals;
  };

  return (
    <React.Fragment>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText
              title="Search here"
              className="search-icon"
              id="search"
            >
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={VendorRegLang?.OrderID_ProductName[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100">
          <thead>
            <tr>
              <th>{VendorRegLang?.sl_no?.[language]}</th>
              <th>{VendorRegLang?.order_id?.[language]}</th>
              <th>{VendorRegLang?.out_for_delivery_date?.[language]}</th>
              <th>{VendorRegLang?.products?.[language]}</th>
              <th>{VendorRegLang?.quantity?.[language]}</th>
              {/* <th>{VendorRegLang?.status?.[language]}</th> */}
              <th>{VendorRegLang?.price?.[language]}</th>
              <th>{VendorRegLang?.action?.[language]}</th>
            </tr>
          </thead>
          <tbody>
            {received_order?.length !== 0 ? (
              <>
                {received_order?.map((item: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.sl_no?.[language]}:
                          </span>
                          <span className="table-data">
                            {pageData?.from + index}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.order_id?.[language]}:
                          </span>
                          <span className="table-data">
                            {item?.vendor_po_no}
                          </span>
                        </td>

                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.out_for_delivery_date?.[language]}:
                          </span>
                          <span className="table-data">
                            {item?.updated_at.toString().slice(0, 10)}
                          </span>
                        </td>

                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.products?.[language]}:
                          </span>
                          <span className="table-data">
                            <Link
                              to="#"
                              className="blue-text"
                              title="View Product"
                              onClick={() => {
                                setIsShowings(true);
                                setgetProductdet(item?.order_details?.features);
                                removeTags(item?.order_details?.features);
                              }}
                            >
                              {item?.order_details?.name}
                            </Link>

                            {/* <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item?.order_details?.features,


                                  }}
                                ></p> */}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.quantity?.[language]}:
                          </span>
                          <span className="table-data d-block ">
                            {item?.order_details?.quantity}
                          </span>
                        </td>
                        {/* <td>
                          <span className="mobile-heading">{VendorRegLang?.status?.[language]}:</span>
                          <span className="table-data">
                            {getOrderStatus(item?.status)}
                          </span>
                        </td> */}

                        {/* <td>
                          <span className="table-data">
                            {item?.pf_invoice?.user_data?.email}<p>
                              {item?.pf_invoice?.user_data?.phone}</p></span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.price?.[language]}:
                          </span>
                          <span className="table-data">
                            {/* ₹{(((item?.order_details?.cgst * item?.order_details?.quantity) + (item?.order_details?.sgst * item?.order_details?.quantity ) + (item?.order_details?.amount * item?.order_details?.quantity)) - ((((item?.order_details?.cgst * item?.order_details?.quantity) + (item?.order_details?.sgst * item?.order_details?.quantity))) + (item?.order_details?.amount * item?.order_details?.quantity) )* 5/100 )} */}
                            ₹
                            {(
                              item?.order_details?.cgst *
                                item?.order_details?.quantity +
                              item?.order_details?.sgst *
                                item?.order_details?.quantity +
                              item?.order_details?.amount *
                                item?.order_details?.quantity
                            )?.toFixed(2)}
                          </span>
                        </td>
                        {/* <td>
                        <span className="mobile-heading">Chellan:</span>
                          <span className="table-data">
                          <Link
                            to="#"
                            className=""
                            onClick={() => {
                              setIsshowing(true);
                              setOrderIds(item?.id)

                            }}
                          >
                            <Button className="btn btn-primary">{" "}<i className="fa-solid fa-upload"></i></Button>

                          </Link>
                          </span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.action?.[language]}:
                          </span>
                          <span className="table-data">
                            <Link
                              to="#"
                              className=""
                              onClick={() => {
                                setIsShowing(true);
                                getSingleOrder(item?.id);
                                setOrderIds(item?.id);
                                setValue({
                                  ...value,
                                  orderid: item?.id,
                                  proforma_id: item?.proforma_id,
                                  product_id: item?.order_details?.id,
                                  quantity: item?.order_details?.quantity,
                                });
                              }}
                            >
                              <i className="icons icons-view-more"></i>
                            </Link>
                          </span>
                        </td>
                      </tr>
                      </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>
                <tr>
                  <td className="text-center px-2 py-5 empty-text" colSpan={8}>
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    ) : (
                      `${VendorRegLang?.no_data_found?.[language]}`
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && (
        <div className="table-footer-wrap">
          <small>
            {VendorRegLang?.showing?.[language]}{" "}
            {pageData?.from != null ? pageData?.from : 0}{" "}
            {VendorRegLang?.to?.[language]}{" "}
            {pageData?.to != null ? pageData?.to : 0}{" "}
            {VendorRegLang?.of?.[language]}{" "}
            {pageData?.total != null ? pageData?.total : 0}{" "}
            {VendorRegLang?.entries?.[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData?.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={orderValidation}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
          setFieldTouched,
        }) => {
          {
            // console.log(errors, "ASfskjh");
          }
          return (
            <OutForDelivaryOrderModal
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              handleReset={handleReset}
              order_view={order_view}
              outOf_del={1}
              order_status={order_status_three}
              challanValues={challanValues}
              challanValidation={challanValidation}
              handleSubmitss={handleSubmitss}
              orderIds={orderIds}
              setFieldTouched={setFieldTouched}
              stat={9}
              setLatitudess={setLatitudes}
              setLongitudess={setLongitudes}
              latitudes={latitudes}
              longitudes={longitudes}
              setLocation={setLocation}
              location={location}
              value={value}
            />
          );
        }}
      </Formik>

      <Formik
        initialValues={challanValues}
        validationSchema={challanValidation}
        onSubmit={(values, actions) => {
          handleSubmitss(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          {
            // console.log(values, "ashddsgiuiooj");
          }
          return (
            <ChallanModal
              isShowing={isshowing}
              setIsShowing={setIsshowing}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              orderIds={orderIds}
              touched={touched}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              handleReset={handleReset}
              setValue={undefined}
              value={undefined}
            />
          );
        }}
      </Formik>
      <ProductDetailsModal
        isShowing={isShowings}
        setIsShowings={setIsShowings}
        values={values}
      />
    </React.Fragment>
  );
}
