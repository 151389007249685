import VendorDashboard from "../../../Pages/Vendor/Dashboard/VendorDashboard";
import AdvancedRegister from "../../../Pages/Vendor/profileUpdation/AdvancedReg";
import VendorRegister from "../../../Pages/Vendor/profileUpdation/BasicDetails";
import DocUpload from "../../../Pages/Vendor/profileUpdation/DocUpload";
import EmdDetails from "../../../Pages/Vendor/profileUpdation/EmdDetails";
import EmplanmentDetails from "../../../Pages/Vendor/profileUpdation/EmplanmentDetails";
// import VendorTabs from "../../../Pages/Vendor/profileupdationtab/tabs";
import CancelledOrders from "../../../Pages/Vendor/PurchaseOrder/CancelledOrder";
import ProcessedOrders from "../../../Pages/Vendor/PurchaseOrder/ProcessedOrder";
import ReceivedOrders from "../../../Pages/Vendor/PurchaseOrder/ReceivedOrder";
import OrderLayoutVendor from "../../../Pages/Vendor/PurchaseOrder/LayoutOrder";
import FeedbackSummary from "../../../Pages/Vendor/FeedbackSummary/FeedbackSummary";
import ScheduleMaintenanceLayout from "../../../Pages/Vendor/ScheduleMaintenance/ScheduleMaintenanceTab";
import VendorScheduleCompleted from "../../../Pages/Vendor/ScheduleMaintenance/Completed";
import VendorSchedulePending from "../../../Pages/Vendor/ScheduleMaintenance/Pending";
import VendorSettingsLayout from "../../../Pages/Vendor/Settings/SettingsLayout";
import VendorProfileSettings from "../../../Pages/Vendor/Settings/ProfileSettings";
import VendorDocuments from "../../../Pages/Vendor/Settings/DocumentsSettings";
import VendorAccountDetails from "../../../Pages/Vendor/Settings/AccountDetiles";
import AmcVendor from "../../../Pages/Vendor/Services/AmcVendor";
import TicketsVendor from "../../../Pages/Vendor/Services/TicketsVendor";
import DeliveredOrders from "../../../Pages/Vendor/DeliveredOrder/DeliverdOrders";
import ProfileLayoutVendor from "../../../Pages/Vendor/profileUpdation/Profiletab";
import TicketLayout from "../../../Pages/Admin/Services/Tickets/TicketLayout";
import OpenTicketsVendor from "../../../Pages/Vendor/Services/OpenTickets";
import ClosedTicketsVendor from "../../../Pages/Vendor/Services/ClosedTickets";
import PendingTicketsVendor from "../../../Pages/Vendor/Services/PendingTicktes";
import SeviceLayoutVendor from "../../../Pages/Vendor/Services/ServicesTab";
import Chellon from "../../../Pages/Vendor/payments/Chellan";
import SecurePayment from "../../../Pages/Vendor/payments/SecurePayment";
import OutofDeliveryOrders from "../../../Pages/Vendor/PurchaseOrder/OutofDelivery";
import VendorAdvancedDocumment from "../../../Pages/Vendor/Settings/AdvancedReupload";
import PaymentLists from "../../../Pages/Vendor/PaymentList/PaymentList";
import PaymentLayout from "../../../Pages/Vendor/PaymentList/PaymentLayout";
import PaymentPendingLists from "../../../Pages/Vendor/PaymentList/PaymentPending";
import RejectedOrders from "../../../Pages/Vendor/PurchaseOrder/RejectedOrder";
import SelfVendorRegisterBasic from "../../../Pages/Vendor/VendorSignUp/BasicDetalisSelfRegister";
import LayoutSelfRegisterVendor from "../../../Pages/Vendor/VendorSignUp/TabSection";
import AdvancedRegisterSelfVendor from "../../../Pages/Vendor/VendorSignUp/AdvancedRegSelfRegisterVendor";
import DocUploadSelfRegisterVendor from "../../../Pages/Vendor/VendorSignUp/DocumentUploadSelfRegister";
import EmdDetailsSelfRegisterVendor from "../../../Pages/Vendor/VendorSignUp/EmdPayment";
import StartUpdocuments from "../../../Pages/Vendor/profileUpdation/StartUpDouments";

const Vendorroutes = [
  {
    key: "1",
    path: "/document-upload",
    Element: (
      <ProfileLayoutVendor>
        <DocUpload />
      </ProfileLayoutVendor>
    ),
    name: "Document Upload",
  },
  // path to vendor documents upload page

  {
    key: "2",
    path: "/basic-details-view",
    Element: (
      <ProfileLayoutVendor>
        {" "}
        <VendorRegister />
      </ProfileLayoutVendor>
    ),
    name: "Document Upload",
  },
  // path to vendor basic details page after reset password

  {
    key: "3",
    path: "/advanced-registration",
    Element: (
      <ProfileLayoutVendor>
        {" "}
        <AdvancedRegister />
      </ProfileLayoutVendor>
    ),
    name: "Document Upload",
  },
  // path to vendor advanced registration page

  {
    key: "4",
    path: "/emd-details",
    Element: (
      <ProfileLayoutVendor>
        {" "}
        <EmdDetails />
      </ProfileLayoutVendor>
    ),
    name: "Document Upload",
  },
  // path to vendor EmdDetails page

  {
    key: "5",
    path: "/empanelment-details",
    Element: (
      <ProfileLayoutVendor>
        {" "}
        <EmplanmentDetails />
      </ProfileLayoutVendor>
    ),
    name: "Document Upload",
  },
  // path to vendor EmplanmentDetails page

  {
    key: "6",
    path: "/overview-vendor",
    Element: <VendorDashboard />,
    name: "Dashboard",
  },
  // path to vendor dashboard page

  {
    key: "7",
    path: "/received-order-vendor",
    Element: (
      <OrderLayoutVendor>
        <ReceivedOrders />
      </OrderLayoutVendor>
    ),
    name: "Dashboard",
  },
  // path to vendor received orders page comes under purchase orders

  {
    key: "8",
    path: "/cancelled-order-vendor",
    Element: (
      <OrderLayoutVendor>
        <CancelledOrders />
      </OrderLayoutVendor>
    ),
    name: "Dashboard",
  },
  // path to vendor cancelled orders page comes under purchase orders

  {
    key: "9",
    path: "/processed-order-vendor",
    Element: (
      <OrderLayoutVendor>
        <ProcessedOrders />
      </OrderLayoutVendor>
    ),
    name: "Dashboard",
  },
  // path to vendor processing orders page comes under purchase orders

  {
    key: "10",
    path: "/services-vendor",
    Element: <VendorDashboard />,
    name: "Dashboard",
  },
  // path to vendor EmplanmentDetails page

  {
    key: "11",
    path: "/amc-vendor",
    Element: <AmcVendor />,
    name: "Dashboard",
  },
  // path to vendor Amc page

  {
    key: "12",
    path: "/tickets-vendor",
    Element: <TicketsVendor />,
    name: "Dashboard",
  },

  {
    key: "13",
    path: "/delivered-vendor",
    Element: <DeliveredOrders />,
    name: "Dashboard",
  },
  // path to vendor deliveried orders page

  {
    key: "14",
    path: "/schedule-maintenance-vendor-completed",
    Element: (
      <ScheduleMaintenanceLayout>
        <VendorScheduleCompleted />
      </ScheduleMaintenanceLayout>
    ),
    name: "Dashboard",
  },
  // path to vendor completed schedule maintenance page comes under schedule maintenance

  {
    key: "15",
    path: "/schedule-maintenance-vendor-pending",
    Element: (
      <ScheduleMaintenanceLayout>
        <VendorSchedulePending />
      </ScheduleMaintenanceLayout>
    ),
    name: "Dashboard",
  },
  // path to vendor pending schedule maintenance page comes under schedule maintenance

  {
    key: "16",
    path: "/feedback-summary-vendor",
    Element: <FeedbackSummary />,
    name: "Dashboard",
  },
  // path to vendor feedback page

  {
    key: "17",
    path: "/vendor-profile-settings",
    Element: (
      <VendorSettingsLayout>
        <VendorProfileSettings />
      </VendorSettingsLayout>
    ),
    name: "Dashboard",
  },
  // path to vendor profile update page comes under settings

  {
    key: "18",
    path: "/vendor-documents",
    Element: (
      <VendorSettingsLayout>
        <VendorDocuments />
      </VendorSettingsLayout>
    ),
    name: "Dashboard",
  },
  // path to vendor documents list page

  {
    key: "18",
    path: "/vendor-documents-view",
    Element: <VendorDocuments />,
    name: "Dashboard",
  },
  // path to vendor documents list page comes under settings

  {
    key: "19",
    path: "/vendor-account-details",
    Element: (
      <VendorSettingsLayout>
        <VendorAccountDetails />
      </VendorSettingsLayout>
    ),
    name: "Dashboard",
  },
  // path to vendor acccount add and list page comes under settings

  {
    key: "20",
    path: "/vendor-open-tickets",
    Element: (
      <SeviceLayoutVendor>
        <OpenTicketsVendor />
      </SeviceLayoutVendor>
    ),
    name: "Dashboard",
  },
  // path to vendor open tickets page

  {
    key: "21",
    path: "/vendor-closed-tickets",
    Element: (
      <SeviceLayoutVendor>
        <ClosedTicketsVendor />
      </SeviceLayoutVendor>
    ),
    name: "Dashboard",
  },
  // path to vendor closed ticket page

  {
    key: "22",
    path: "/chellon",
    Element: <Chellon />,
    name: "Chellon",
  },
  // path to vendor challan upload page

  {
    key: "23",
    path: "/vendor-pending-tickets",
    Element: (
      <SeviceLayoutVendor>
        <PendingTicketsVendor />
      </SeviceLayoutVendor>
    ),
    name: "Dashboard",
  },
  // path to vendor pending tickets page

  {
    key: "24",
    path: "/outofdelivery",
    Element: (
      <OrderLayoutVendor>
        {" "}
        <OutofDeliveryOrders />
      </OrderLayoutVendor>
    ),
    name: "Dashboard",
  },
  // path to vendor out for delivery page

  {
    key: "25",
    path: "/vendor-advanced-reupload",
    Element: (
      <VendorSettingsLayout>
        <VendorAdvancedDocumment />
      </VendorSettingsLayout>
    ),
    name: "Dashboard",
  },
  // path to vendor all rejected details reupload page comes under settings

  {
    key: "26",
    path: "/payment-vendor",
    Element: (
      <PaymentLayout>
        <PaymentLists />
      </PaymentLayout>
    ),
    name: "Dashboard",
  },
  // path to vendor payment success list page

  {
    key: "27",
    path: "/payment-received-vendor",
    Element: (
      <PaymentLayout>
        <PaymentPendingLists />
      </PaymentLayout>
    ),
    name: "Dashboard",
  },
  // path to vendor payment pending list page
  {
    key: "28",
    path: "/rejected-order-vendor",
    Element: (
      <OrderLayoutVendor>
        <RejectedOrders />
      </OrderLayoutVendor>
    ),
    name: "Dashboard",
  },

  {
    key: "29",
    path: "/secure-payment",
    Element: <SecurePayment />,
    name: "SecurePayment",
  },

  {
    key: "30",
    path: "/self-register-vendor-basic-det",
    Element: (
     <LayoutSelfRegisterVendor>
      <SelfVendorRegisterBasic/>
     </LayoutSelfRegisterVendor>
    ),
    name: "SecurePayments",
  },

  {
    key: "31",
    path: "/self-register-vendor-advanced-det",
    Element: (
     <LayoutSelfRegisterVendor>
      <AdvancedRegisterSelfVendor/>
     </LayoutSelfRegisterVendor>
    ),
    name: "SecurePayment",
  },
  {
    key: "32",
    path: "/self-register-vendor-document-upload",
    Element: (
     <LayoutSelfRegisterVendor>
      <DocUploadSelfRegisterVendor/>
     </LayoutSelfRegisterVendor>
    ),
    name: "SecurePayment",
  },
  {
    key: "33",
    path: "/self-register-vendor-emd",
    Element: (
     <LayoutSelfRegisterVendor>
      <EmdDetailsSelfRegisterVendor/>
     </LayoutSelfRegisterVendor>
    ),
    name: "SecurePayment",
  },
  {
    key: "34",
    path: "/start-up-document-upload",
    Element: (
      <ProfileLayoutVendor>
      {" "}
      <StartUpdocuments />
    </ProfileLayoutVendor>
    ),
    name: "SecurePayment",
  },

];

export default Vendorroutes;

