import React, { useEffect, useContext } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { api } from "../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { Input, Button, FormGroup, Label, InputGroupText, Row, Col, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { ForgotValidationSchema } from "./ForgotValidation";
import { toast } from "react-toastify";
import TextError from "../../Components/Errors/TextError";
import { LoginViewRegLang } from "../../Library/LanguageConversion/LoginViewLang";
import { Context } from "../../Components/Context/Context";

function ForgotView() {
  const navigate = useNavigate();
  const { language, setLanguage } = useContext(Context);
  const handleSubmit = (values: any, actions: any) => {
    sendLoginData(values, actions);
  };

  const sendLoginData = (values: any, actions: any) => {
    api
      .post(
        "/forgot_password",
        {
          email: values?.email_phone,
          mode: "no-cors",
        },
        false
      )
      .then(async function ([success, response, status]: any) {
        if (!response?.success) {
          toast.error(response?.msg)
          actions?.setSubmitting(false)
          return
        }
        if (response?.success) {
          toast.success(response?.msg)
        }
        navigate("/login");
        actions?.setSubmitting(false)
        return response;
      })
      .catch((err) => {
        toast.error(`${LoginViewRegLang?.unexpected_error[language]}`)
        actions?.setSubmitting(false)
      });
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="login-outer">
          <Row>
            {/* left side */}
            <Col lg={5} md={6} sm={12} className="ps-0 login-desktop-column">
              {/* login image wrap */}
              <div className="login-img-wrap flex-column">
                <img
                  src="../images/logo-white1.png"
                  alt="login-background"
                  className="px-5"
                />
                <p className="text-white text-center px-5 w-75">
                  {LoginViewRegLang?.keonics_info[language]}
                </p>
              </div>
            </Col>
            {/* Right side */}
            <Col lg={5} md={5} sm={12} className="m-auto">
              {/* login wrap */}

              <Col sm={12} className="ps-0 login-mobile-column">
                <div className="logo-mobile mb-4">
                  <img
                    src="../images/logo-white1.png"
                    alt="login-background"
                    className="px-2"
                  />
                </div>
              </Col>
              <div className="login-wrap">
                <h1>{LoginViewRegLang?.forgot_password[language]}</h1>
                <p>
                  {LoginViewRegLang?.email_info[language]}
                </p>
                <div className="login-content">
                  <Formik
                    validationSchema={ForgotValidationSchema}
                    initialValues={{ email_phone: "" }}
                    onSubmit={(values, actions) => {
                      handleSubmit(values, actions);
                    }}
                  >
                    {({
                      errors, 
                      values,
                      isSubmitting,
                      handleChange,
                      touched,
                    }) => (
                      <>
                        <Form>
                          <FormGroup>
                            <div className="custom-input">
                              <Label>{LoginViewRegLang?.email[language]} </Label>
                              <Input
                                name="email_phone"
                                type="text"
                                placeholder={`${LoginViewRegLang?.enter_email[language]}`}
                                value={values?.email_phone}
                                className={`form-control ${touched.email_phone && errors.email_phone
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="email_phone"
                                component={TextError}
                              />
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <div className="login-button pt-2 my-4">
                              <Button
                                title="Send Link"
                                color="primary"
                                className={"px-5"}
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ?
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  /> :
                                  `${LoginViewRegLang?.send_link[language]}`
                                }
                              </Button>
                            </div>
                          </FormGroup>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default ForgotView;