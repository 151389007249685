import { useState, useEffect, useContext } from 'react'
import { InputGroup, InputGroupText, Input, Table, Form, Button } from 'reactstrap';
import { api } from '../../../../Library/CustomApi';
import Loader from '../../../../Components/Loader/Loader';
import ModalContainer from '../../../../Components/Modal/ModalContainer';
import CompletePayment from '../CompletePayment/CompletePayment';
import OrderViewModal from '../OrderViewModal/OrderViewModal';
import CustomPagination from '../../../../Components/CustomPagination/Index';
import { Context } from '../../../../Components/Context/Context';
import { Department_Orders } from '../../../../Library/LanguageConversion/Department_Orders';
import React from 'react';
const PaymentPendingOrders = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [payPendingOrders, setPayPendingOrders] = useState<any>([])
    const [searchVal, setSearchVal] = useState<string>('')
    const [pageData, setPageData] = useState<any>([])
    const [orderId, setOrderId] = useState<any>()
    const [singleOrderData, setSingleOrderData] = useState<any>([])
    const [singlePayData, setSinglePayData] = useState<any>([])
    const [payIsOpen, setPayIsOpen] = useState<boolean>(false) // for feedback modal
    const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false) // for feedback modal
    const [orderData, setOrderData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number | string>(1)
    const [selectedAddress, setSelectedAddress] = useState<any>([])
    const { language, setLanguage } = useContext(Context);
    useEffect(() => {
        getPayPendingOrders()
    }, [currentPage, searchVal])

    // fetch payment pending orders 
    const getPayPendingOrders = () => {
        setIsLoading(true);
        api
            .get(`/order/pf-invoice?is_pending=1&sort_value=DESC&search_value=${searchVal}&page=${currentPage}`, true)
            .then(async function ([success, response]) {
                setPayPendingOrders(response?.data || []);
                setPageData(response?.meta)
                setIsLoading(false);
                return response;
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    // handles order view modal 
    const handleOrderView = (obj: any) => {
        setSingleOrderData(obj.order_details)
        setOrderData(obj)
        setSelectedAddress(obj?.shipping_address[0])
        setOrderModalOpen(true)
    }
    return (
        <>
            <div className="search-widget-outer">
                <Form className="d-flex" role="search">
                    <InputGroup className="search-group">
                        <InputGroupText title="Order ID/Product Name" className="search-icon" id="search">
                            <i className="fa-sharp fa-solid fa-magnifying-glass" />
                        </InputGroupText>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder="Order ID"
                            aria-label="Username"
                            aria-describedby="search"
                            onChange={(e: any) => setSearchVal(e.target.value)}
                        />
                    </InputGroup>
                </Form>
            </div>
            <div className="table-wrap responsive-table">
                <Table className="w-100 table-striped">
                    <thead>
                        <tr>
                            <th>{Department_Orders?.SlNo[language]}</th>
                            <th>{Department_Orders?.Order_ID[language]}</th>
                            <th>{Department_Orders?.Total[language]}</th>
                            <th>{Department_Orders?.Paid[language]}</th>
                            <th>{Department_Orders?.Balance[language]}</th>
                            <th>{Department_Orders?.Action[language]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading ?
                            <>
                                {payPendingOrders?.length !== 0 ? payPendingOrders?.map((obj: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td><span className="mobile-heading">{Department_Orders?.SlNo[language]}:</span>
                                                <span className="table-data">{i + 1}</span>
                                            </td>
                                            <td onClick={() => handleOrderView(obj)}><span className="mobile-heading">{Department_Orders?.Order_ID[language]}:</span><span className="table-data blue-text" >{obj?.order_id}</span>
                                            
                                            </td>
                                            <td><span className="mobile-heading">{Department_Orders?.Total[language]}:</span>
                                                <span className="table-data green-text">
                                                    <i className="bi bi-currency-rupee"></i>{obj?.grand_amount}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="mobile-heading">{Department_Orders?.Paid[language]}:</span>
                                                <span className="table-data text-success">
                                                    <i className="bi bi-currency-rupee"></i>{obj?.last_paid_amount}
                                                </span>
                                            </td>
                                            <td><span className="mobile-heading">{Department_Orders?.Balance[language]}:</span>
                                                <span className="table-data text-warning">
                                                    <i className="bi bi-currency-rupee"></i>{obj?.payment_details?.balance_amt}
                                                </span>
                                            </td>
                                            <td><span className="mobile-heading">{Department_Orders?.Action[language]}:</span>
                                                <span className="table-data">
                                                    {obj?.payment_details?.is_pending == '1' &&
                                                        <Button
                                                            onClick={() => {
                                                                setOrderId(obj?.draft?.id)
                                                                setSingleOrderData(obj?.order_details)
                                                                setSinglePayData(obj?.payment_details)
                                                                setPayIsOpen(true)
                                                            }}
                                                            className="btn btn-primary px-3 "
                                                            
                                                        >
                                                           {Department_Orders?.Pay_Now[language]}
                                                        </Button>}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                                    :
                                    <tr>
                                        <td colSpan={6} className='empty-text text-center'>{Department_Orders?.NoOrdersFound[language]}</td>
                                    </tr>
                                }
                            </>
                            :
                            <tr>
                                <td colSpan={6} className='text-center empty-text'>
                                        <Loader />
                                </td>
                            </tr>
                        }
                    </tbody >
                </Table >
                {pageData && payPendingOrders?.length > 0 && <div className="table-footer-wrap">
                    <small>{Department_Orders?.Showing[language]} {pageData.from} to {pageData.to} of {pageData?.total} {Department_Orders?.entries[language]}</small>
                    <div className="pagination-outer">
                        <CustomPagination
                            totalItems={pageData?.total}
                            itemsPerPage={pageData?.per_page}
                            activePage={pageData?.current_page}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>}
            </div >
            {/* order view modal  */}
            <div>
                <ModalContainer
                    open={orderModalOpen}
                    setOpen={setOrderModalOpen}
                    header='Order Details'
                >
                    <OrderViewModal
                        singleOrderData={singleOrderData}
                        orderData={orderData}
                        orderViewLoading={false}
                        address={selectedAddress}
                        hideTracker
                    />
                </ModalContainer>
            </div>
            {/* payment modal  */}
            <div>
                <ModalContainer
                    open={payIsOpen}
                    setOpen={setPayIsOpen}
                    header='Pay Now'
                >
                    <CompletePayment
                        singleOrderData={singleOrderData || []}
                        singlePayData={singlePayData}
                        orderId={orderId}
                    />
                </ModalContainer>
            </div>
        </>
    )
}

export default PaymentPendingOrders