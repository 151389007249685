import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const AddAddressValidation = Yup.object().shape({
    building_no: Yup.string().required("Building no is required"),
    pincode: Yup.string().length(6,'Pincode must be exactly 6 numbers').matches(/^[0-9]{6}/).required("Pincode is required"),
    district: Yup.string().required("District is required"),
    city: Yup.string().typeError('City is required').required("City is required"),
    address_type: Yup.number().required("Address type is required"),
    landmark: Yup.string(),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, 'Phone number must be atleast 10 numbers').max(10, 'Phone number must be 10 numbers').required('Phone number is required'),
    alt_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, 'Phone number must be atleast 10 numbers').max(10, 'Phone number must be 10 numbers').required('Phone number is required'),
    whatsapp_no: Yup.string().matches(phoneRegExp, 'Whatsapp number is not valid').min(10, 'Whatsapp number must be atleast 10 numbers').max(10, 'Phone number must be 10 numbers').required('Phone number is required')
});