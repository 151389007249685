import * as Yup from "yup";
const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm


export const customOrderValidation = Yup.object().shape({
    product_name: Yup.string().required("Product name is required"),
    quantity: Yup.number().required("Quantity is required").typeError('Quantity must be a number').positive('Invalid Quantity'),
    ref_links: Yup.string().matches(re, 'URL is not valid'),

    cat_id: Yup.string().required("Category is required"),
    comments: Yup.string().required("Comments is required"),

    // spec_details: Yup.array().of(
    //     Yup.object().shape({
    //         spec_details: Yup.string().required("Field is required"),
    //     })
    // ),

    // comments: '',
    // category: '',
    // specifications: '',
    // cat_id: '',
    // spec_details: [],
    // spec_all: [],
});