import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const ComplaintValidationSchema = Yup.object().shape({
  // department_name: Yup.string().required("Department name is required"),
  contact_no:Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).required('Phone number is required'),
  service_type: Yup.string().required("Service type is required"),
  issue_type: Yup.string().required("Issue type is required"),
  // vendor_id: Yup.string().required("Order Id is required"),
  // file:Yup.string().required('Image is required')
  // product_name: Yup.string().required("Product name is required"),
  // brand: Yup.string().required("Brand name is required"),
  // warranty_month: Yup.number().required("Warranty is required"),
});