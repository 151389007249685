import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useContext } from "react";
import { ErrorMessage } from "formik";
import TextError from "../../../../../Components/Errors/TextError";
import ModalContainer from "../../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../../Components/Context/Context";
import { admin_empanelment } from "../../../../../Library/LanguageConversion/admin_empanelment";

interface propType {
  showInviteVendor: boolean;
  setShowInviteVendor: Function;
  handleSubmit: any;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  resetForm: Function;
  get_vendor: any;
  handleSend: Function;
  isSubmitting: any;
}

const InviteVendor = (props: propType) => {
  const { language, setLanguage } = useContext(Context);
  return (
    <>
      <ModalContainer
        open={props.showInviteVendor}
        setOpen={props.setShowInviteVendor}
        header={admin_empanelment?.Invite_Vendor?.[language]}
        >
        <div className="add-vendor-outer">
          <div className="vendor-details text-center">
          </div>
          <Form className="form-wrap">
            <Row>
              <Col md={6} sm={6}>
                <FormGroup>
                  <label>{admin_empanelment?.Vendor_Name?.[language]}</label>
                  <Input
                    type="text"
                    label="GST Class"
                    name="vendor_name"
                    value={props?.get_vendor?.company?.name}
                    //onChange={props.handleChange}
                    disabled={true}
                  />
                  <ErrorMessage name="vendor_name" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup>
                  <label>{admin_empanelment?.Company_Name?.[language]}</label>
                  <Input
                    type="text"
                    label="GST Class"
                    name="company_name"
                    value={props?.get_vendor?.company?.name}
                    //onChange={props.handleChange}
                    disabled={true}
                  />
                  <ErrorMessage name="company_name" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup>
                  <label>{admin_empanelment?.Mobile_Number?.[language]}</label>
                  <Input
                    type="text"
                    label="Mobile Number"
                    name="mobile_number"
                    value={props?.get_vendor?.user?.phone}
                    // onChange={props.handleChange}
                    disabled={true}
                  />
                  <ErrorMessage name="mobile_number" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup>
                  <label>{admin_empanelment?.Email_ID?.[language]}</label>
                  <Input
                    type="text"
                    label="Email ID"
                    name="email_id"
                    value={props?.get_vendor?.company?.email}
                    // onChange={props.handleChange}
                    disabled={true}
                  />
                  <ErrorMessage name="email_id" component={TextError} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className={"text-end"}>
                  <Button
                    type="submit"
                    color="primary"
                    className="px-5"
                    onClick={props?.handleSubmit}
                    disabled={props?.isSubmitting}
                  >
                    {props?.isSubmitting ? (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    ) : (
                      `${admin_empanelment?.Invite?.[language]}`
                    )}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </>
  );
};

export default InviteVendor;
