import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import { ErrorMessage } from "formik";
import moment from "moment";
// import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Label,

} from "reactstrap";
import TextError from "../../../Components/Errors/TextError";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";


export default function AddressModal(props: any) {

  const [address, setAddress] = useState<any>({
    city: "",
    state: "",
    country: "",
    district: "",
    add: "",
  });

  const [data, setData] = useState([]);
  const fetchLocation = (pinCode: any) => {
    if (pinCode.length > 5) {
      axios
        .get(`https://api.postalpincode.in/pincode/${pinCode}`)
        .then((response: any) => {
          let v = response?.data[0]?.PostOffice;
          let arr: any = [];

          v?.map((item: any, i: any) => {
            arr?.push({
              value: i,
              label: item?.Name,
              city: item?.Block,
              state: item?.State,
              // landmark: item?.Region,
              country: item?.Country,
              district: item?.District,
              pincode: item?.Pincode,
            });
            setAddress({
              ...address,
              city: item?.Block,
              state: item?.State,
              country: item?.Country,
              district: item?.District,
              add: item?.Name,
            });
          });
          setData(arr || []);

          props?.setFieldValue("city", arr[0]?.city);
          props?.setFieldValue("pincode", arr[0]?.pincode);
          props?.setFieldValue("country", arr[0]?.country);
          props?.setFieldValue("district", arr[0]?.district);
          props?.setFieldValue("address", arr[0]?.label);
          props?.setFieldValue("state", arr[0]?.state);
          props?.setFieldValue(
            "sc_st_entrepreneur",
            props?.data?.sc_st_entrepreneur
          );
          props?.setFieldValue(
            "woman_entrepreneur",
            props?.data?.woman_entrepreneur
          );
          props?.setFieldValue("company_mse", props?.values?.company_mse);
          props?.setFieldValue("valid_upto", props?.data?.valid_upto);
          props?.setFieldValue("designation", props?.data?.designation);
          props?.setFieldValue(
            "sc_st_entrepreneur",
            props?.data?.sc_st_entrepreneur
          );
          props?.setFieldValue("designation", props?.data?.designation);

          props?.setFieldTouched("city", false, false);
          props?.setFieldTouched("country", false, false);
          props?.setFieldTouched("district", false, false);
          props?.setFieldTouched("address", false, false);
          props?.setFieldTouched("state", false, false);
        })
        .catch((error) => {
          toast.error("Please Enter a Valid Pincode");
        });
    }
  };

  useEffect(() => {}, [address]);
  const { language, setLanguage } = useContext(Context);
    return (<>
        <React.Fragment>
            <ModalContainer
                open={props?.isShowing}
                setOpen={() => {
                    props?.setIsShowing(false);
                    // props?.handleReset();
                }}
                header='Add Company Registered Address'
            >
                <div className="add-address-wrap">
                    <div className="feeback-outer-wrap">

                        <div className="feedback-wrap">
                            <div className="feedback-details ">
                                <Form className="form-wrap">
                                    <Row>
                                        <Col md={6} className="mt-3">
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.Building_Number?.[language]}  <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>
                                                    <Input
                                                        type="text"
                                                        name="building_number"
                                                        value={props?.values?.building_number }
                                                        onChange={props.handleChange}
                                                        className={`form-control ${props?.touched.building_number &&
                                                            props?.errors?.building_number
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    ></Input>
                                                    <ErrorMessage
                                                        name="building_number"
                                                        component={TextError}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.Building_Address?.[language]} <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>
                                                    <Input
                                                        type="text"
                                                        name="building_address"
                                                        value={props?.values?.building_address}
                                                        onChange={props?.handleChange}
                                                        className={`form-control ${props?.touched.building_address &&
                                                            props?.errors?.building_address
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    ></Input>
                                                    <ErrorMessage
                                                        name="building_address"
                                                        component={TextError}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>

                                        <Col md={6} className="mt-3">
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.Landmark?.[language]}  <i
                                                        // className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>
                                                    <Input
                                                        type="text"
                                                        name="landmark"
                                                        onChange={props?.handleChange}
                                                        value={props?.values?.landmark}
                                                        className={`form-control ${props?.touched?.landmark &&
                                                            props?.errors?.landmark
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    ></Input>



                                                    <ErrorMessage
                                                        name="landmark"
                                                        component={TextError}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        
                                        <Col md={6} className="mt-3">
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.Pincode?.[language]}   <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>
                                                    <Input
                                                        type="tel"
                                                        name="pincode"
                                                        onChange={(e) => {
                                                            props?.handleChange(e);
                                                            fetchLocation(e.target.value);
                                                        }}
                                                        value={props?.values.pincode}
                                                        className={`form-control ${props?.touched.pincode &&
                                                            props?.errors?.pincode
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    ></Input>
                                                    <ErrorMessage
                                                        name="pincode"
                                                        component={TextError}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.Select_Location?.[language]}  <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>

                                                    <Input
                                                        type="select"
                                                        name="address"
                                                        value={props?.values.address || address?.add}
                                                        onChange={props?.handleChange}
                                                        className={`form-control ${props?.touched.address &&
                                                            props?.errors?.address
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    //disabled
                                                    >
                                                        <option value="">{Vendor_profileUpdation?.Select?.[language]} </option>
                                                        {data?.map((item: any) => {
                                                            return (
                                                                <>
                                                                    <option value={item?.label}>
                                                                        {item?.label}
                                                                    </option>
                                                                </>
                                                            );
                                                        })}
                                                    </Input>
                                                    <ErrorMessage name="address" component={TextError} />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        
                                        <Col md={6} className="mt-3">
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.City?.[language]}  <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>
                                                    <Input
                                                        type="text"
                                                        name="city"
                                                        onChange={props?.handleChange}
                                                        value={props?.values?.city}
                                                        className={`form-control ${props?.touched?.city &&
                                                            props?.errors?.city
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    ></Input>



                                                    <ErrorMessage
                                                        name="city"
                                                        component={TextError}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <div className="custom-input ">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.District?.[language]}  <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>
                                                    <Input
                                                        type="text"
                                                        name="district"
                                                        value={props?.values?.district}
                                                        onChange={props?.handleChange}
                                                        className={`form-control ${props?.touched?.district &&
                                                            props?.errors?.district
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    ></Input>
                                                    <ErrorMessage
                                                        name="district"
                                                        component={TextError}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.State?.[language]} <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>
                                                    <Input
                                                        type="text"
                                                        name="state"
                                                        value={props?.values?.state || address?.state}
                                                        onChange={props?.handleChange}
                                                        className={`form-control ${props?.touched?.state &&
                                                            props?.errors?.state
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    ></Input>
                                                    <ErrorMessage
                                                        name="state"
                                                        component={TextError}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Vendor_profileUpdation?.Country?.[language]} <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i></Label>
                                                    <Input
                                                        type="text"
                                                        name="country"
                                                        value={props?.values?.country || address?.country}
                                                        onChange={props?.handleChange}
                                                        className={`form-control ${props?.touched?.country &&
                                                            props?.errors?.country
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                    ></Input>
                                                    <ErrorMessage
                                                        name="country"
                                                        component={TextError}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>

                                        <Col sm={12} className="text-end">
                                            <Button
                                                class="btn btn-primary mb-5 mt-5"
                                                color="primary"
                                                type="submit"
                                                onClick={props?.handleSubmit}
                                            >
                                               {Vendor_profileUpdation?.Submit?.[language]}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>

                            </div>

                        </div>

                    </div>

                </div>
            </ModalContainer>
        </React.Fragment>

    </>)

}
