import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Table,
  FormGroup,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Formik, Form, ErrorMessage } from "formik";
import { AccountValidation } from "./SettingsValidation";
import TextError from "../../../Components/Errors/TextError";
import { api } from "../../../Library/CustomApi";
import { bank_list } from "../../../Library/Constants/BankList";
import { toast } from "react-toastify";
import CustomPagination from "../../../Components/CustomPagination/Index";
import { remove_first_leters } from "../../../Library/Constants/helpers";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";
import Loader from "../../../Components/Loader/Loader";

export default function VendorAccountDetails() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any>();
  const [password, setpassword] = useState<any>("password");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const { language, setLanguage } = useContext(Context);
  const [eye, seteye] = useState(true);
  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };
  const [ifsc_code, setIFSC] = useState("");
  const [value, setValue] = useState({
    retype_account_number: "",
    bank_name: "",
    branch_name: "",
    bank_address: "",
    MICR: "",
    city: "",
  });
  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions);
  };
  const sendData = (values: any, actions: any) => {
    if (values?.retype_account_number !== values?.account_number) {
      //toast.error("Account number and Retpe account number is not matched")
      setErr("Account number and Retype account number is not matched");
    } else {
      console.log("send");
      api
        .post(
          `/bank/accounts`,
          {
            bank_name: values?.bank_name,
            account_holder_name: values?.account_holder_name,
            account_number: values?.account_number,
            branch: values?.branch_name,
            ifsc_code: values?.ifsc_code,
            verified: 1,
            status: 1,
          },
          true
        )
        .then(async function ([success, response]) {
          if (response?.status == "success") {
            toast.success(response?.message);
            actions.setSubmitting(false);
            getData(currentPage);
            values.bank_name = "";
            values.account_holder_name = "";
            values.account_number = "";
            values.branch_name = "";
            values.ifsc_code = "";
            values.bank_address = "";
            values.MICR = "";
            values.city = "";
            values.retype_account_number = "";
          }

          return response;
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          console.log(err);
        });
    }
  };
  const account_security = (e: any) => {
    let reg = /.{1,5}/;
    let str = e.target.value;
    setValue({
      ...value,
      retype_account_number: str.replace(reg, (m: any) => "X".repeat(m.length)),
    });
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);
  const getData = (page: Number) => {
    setLoading(true);
    api
      .get(`/bank/accounts?page=${page}`, true)
      .then(async function ([success, response]) {
        setData(response?.data);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getBank();
  }, [ifsc_code]);
  const getBank = () => {
    let ifsc_code_data = ifsc_code;
    if (ifsc_code_data?.length == 11) {
      api
        .post(`/getifscdetails`, { ifsc_code: ifsc_code }, true)
        .then(async function ([success, response]) {
          setValue({
            ...value,
            branch_name: response?.data?.BRANCH,
            bank_name: response?.data?.BANK,
            bank_address: response?.data?.ADDRESS,
            city: response?.data?.CITY,
            MICR: response?.data?.MICR,
          });

          return response;
        })
        .catch((err) => {
          toast.error("Invalid IFSC Code");
          console.log(err);
        });
    }
  };

  const initialValues = {
    account_holder_name: "",
    bank_name: "" || value?.bank_name,
    branch_name: "" || value?.branch_name,
    ifsc_code: "" || ifsc_code,
    account_number: "",
    retype_account_number: "",
    bank_address: "" || value?.bank_address,
    city: "" || value?.city,
    MICR: "" || value?.MICR,
  };
  return (
    <React.Fragment>
      <div className="custom-tab-content">
        <div className="table-outer-wrap ">
          <div className="outer-wrap">
            {loading ? (
              <div className="loading-overlay">
                <Loader />
              </div>
            ) : (
              <>
                <Row className="gy-4">
                  <Col md={8}>
                    <div className="account-details-wrap">
                      <h3>Account Details</h3>
                      <div className="table-wrap responsive-table">
                        <Table className="table-striped">
                          <thead>
                            <tr>
                              <th>{VendorRegLang?.sl_no[language]}</th>
                              <th>{VendorRegLang?.account_number[language]}</th>
                              <th>{VendorRegLang?.bank[language]}</th>
                              <th>{VendorRegLang?.branch[language]}</th>
                              <th>{VendorRegLang?.action[language]}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.length !== 0 ? (
                              data?.map((item: any, i: number) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <span className="mobile-heading">
                                          {VendorRegLang?.sl_no[language]}:
                                        </span>
                                        <span className="table-data">
                                          {i + 1}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mobile-heading">
                                          {
                                            VendorRegLang?.account_number[
                                              language
                                            ]
                                          }
                                          :
                                        </span>
                                        <span className="table-data">
                                          {remove_first_leters(
                                            item?.account_number
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mobile-heading">
                                          {VendorRegLang?.bank[language]}:
                                        </span>
                                        <span className="table-data">
                                          {item?.bank_name != null
                                            ? item?.bank_name
                                            : "-"}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mobile-heading">
                                          {VendorRegLang?.branch[language]}:
                                        </span>
                                        <span className="table-data">
                                          {item?.branch}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mobile-heading">
                                          {VendorRegLang?.action[language]}:
                                        </span>
                                        <Link to="" className="sec">
                                          {
                                            VendorRegLang?.set_as_primary[
                                              language
                                            ]
                                          }
                                        </Link>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <tr>
                                  <td
                                    colSpan={5}
                                    className="text-center empty-text"
                                  >
                                    <span>
                                      {
                                        VendorRegLang?.no_account_details_found[
                                          language
                                        ]
                                      }
                                    </span>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </Table>
                      </div>
                      {pageData && data?.length !== 0 && (
                        <div className="table-footer-wrap">
                          <small>
                            {VendorRegLang?.showing[language]} {pageData.from}{" "}
                            {VendorRegLang?.to[language]} {pageData.to}{" "}
                            {VendorRegLang?.of[language]} {pageData.total}{" "}
                            {VendorRegLang?.entries[language]}
                          </small>
                          <div className="pagination">
                            <CustomPagination
                              totalItems={pageData?.total}
                              itemsPerPage={pageData?.per_page}
                              activePage={pageData.current_page}
                              setCurrentPage={setCurrentPage}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={4} className="account">
                    <div className="bank-account-form-wrap">
                      <h3>{VendorRegLang?.add_new_bank_account[language]}</h3>
                      <Formik
                        validationSchema={AccountValidation}
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={(values, actions) => {
                          handleSubmit(values, actions);
                        }}
                      >
                        {({
                          handleSubmit,
                          errors,
                          values,
                          isSubmitting,
                          handleChange,
                          touched,
                        }) => (
                          <>
                            <Form onSubmit={handleSubmit}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.ifsc_code[language]}
                                  </Label>
                                  <Input
                                    name="ifsc_code"
                                    type="text"
                                    value={values?.ifsc_code}
                                    className={`form-control ${
                                      touched.ifsc_code && errors.ifsc_code
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setIFSC(e.target.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="ifsc_code"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <FormGroup>
                                  <div className="custom-input">
                                    <Label for="exampleEmail">
                                      {VendorRegLang?.bank_name[language]}
                                    </Label>
                                    <Input
                                      name="bank_name"
                                      type="text"
                                      value={values?.bank_name}
                                      className={`form-control ${
                                        touched.bank_name && errors.bank_name
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      name="bank_name"
                                      component={TextError}
                                    />
                                  </div>
                                </FormGroup>
                              </FormGroup>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.branch[language]}
                                  </Label>
                                  <Input
                                    name="branch_name"
                                    type="text"
                                    value={values?.branch_name}
                                    className={`form-control ${
                                      touched.branch_name && errors.branch_name
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="branch_name"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.bank_address[language]}
                                  </Label>
                                  <Input
                                    name="bank_address"
                                    type="textarea"
                                    value={values?.bank_address}
                                    className={`form-control ${
                                      touched.bank_address &&
                                      errors.bank_address
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                    disabled
                                  />
                                  <ErrorMessage
                                    name="bank_address"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.city[language]}
                                  </Label>
                                  <Input
                                    name="city"
                                    type="text"
                                    value={values?.city}
                                    className={`form-control ${
                                      touched.city && errors.city
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                    disabled
                                  />
                                  <ErrorMessage
                                    name="city"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.micr[language]}
                                  </Label>
                                  <Input
                                    name="MICR"
                                    type="text"
                                    value={values?.MICR}
                                    className={`form-control ${
                                      touched.MICR && errors.MICR
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                    disabled
                                  />
                                  <ErrorMessage
                                    name="MICR"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label for="exampleEmail">
                                    {
                                      VendorRegLang?.account_holder_name[
                                        language
                                      ]
                                    }
                                  </Label>
                                  <Input
                                    name="account_holder_name"
                                    type="text"
                                    value={values?.account_holder_name}
                                    className={`form-control ${
                                      touched?.account_holder_name &&
                                      errors?.account_holder_name
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="account_holder_name"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.account_number[language]}
                                  </Label>
                                  <Input
                                    name="account_number"
                                    type="number"
                                    value={values?.account_number}
                                    className={`form-control ${
                                      touched?.account_number &&
                                      errors?.account_number
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setErr("");
                                    }}
                                  />
                                  <ErrorMessage
                                    name="account_number"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.retype_acc_num[language]}
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      name="retype_account_number"
                                      type={"number"}
                                      value={values?.retype_account_number}
                                      className={`form-control ${
                                        touched?.retype_account_number &&
                                        errors?.retype_account_number
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setErr("");
                                      }}
                                    />
                                    <ErrorMessage
                                      name="retype_account_number"
                                      component={TextError}
                                    />
                                    <div className="error-outer mt-5">
                                      <div className="errors">{err}</div>
                                    </div>
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" />{" "}
                                  <span className="mx-2 mt-5 details">
                                    {
                                      VendorRegLang
                                        ?.confirm_entered_details_are_coorect[
                                        language
                                      ]
                                    }
                                  </span>
                                </Label>
                              </FormGroup>
                              <div className="text-end">
                                <Button
                                  color="primary"
                                  className="btn btn-primary mt-3"
                                  type="submit"
                                >
                                  {VendorRegLang?.add_account[language]}
                                </Button>
                              </div>{" "}
                            </Form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
