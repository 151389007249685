import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { api } from '../../Library/CustomApi'
import { PAYMENT_MODES } from '../../Library/Constants/MapConstants'

declare global {
    interface Window {
        paypal: any
    }
}
interface propType {
    amount: number,
    purchaseType: string,
    order_id: number,
    selectedMode?: number
}

const Paypal = ({ amount, purchaseType, order_id, selectedMode }: propType) => {
    const paypal = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const location = useLocation();
    const [paymentMode, setPaymentMode] = useState<any>(selectedMode)

    useEffect(() => {
        if (amount) {
            paypalHandler()
        }
    }, [])

    const paypalHandler = () => {
        window.paypal.Buttons({
            createOrder: (data: any, actions: any, err: any) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            description: "",
                            amount: {
                                currency_code: "CAD",
                                value: amount
                            }
                        }
                    ]
                })
            },
            onApprove: async (data: any, actions: any) => {
                const order = await actions.order.capture();
                if (purchaseType === 'PRODUCT') {

                    let DATA =
                    {
                        payment_mode: paymentMode,
                        transaction_id: order.id,
                        order_id: order_id,
                        amount: order.purchase_units[0].amount.value,
                        currency: order.purchase_units[0].amount.currency_code,
                        status: order.status,
                        method: 'paypal',
                        bank: 'bank',
                    }
                    api
                        .post(
                            `/payment/payments`,
                            {
                                ...DATA
                            },
                            true
                        )
                        .then(async function ([success, response, status]) {
                            if (status == 200) {
                                toast.success("Payment Successful");
                                navigate(location.pathname, {});
                                navigate('/department/placed-orders')
                            }
                            return response;
                        })
                        .catch((err) => {
                            if (err?.response?.status == 422) {
                                toast.error(err?.response?.data?.message);
                            }
                            console.log(err);
                        });
                }

                else if (purchaseType == 'challon') {
                    let resData =
                    {
                        transaction_id: order.id,
                        amount: order.purchase_units[0].amount.value,
                        currency: order.purchase_units[0].amount.currency_code,
                        status: order.status,
                        method: 'paypal',
                        bank: 'bank',
                        category: order_id,
                        date: order.create_time,

                    }
                    api
                        .post(
                            `/vendor/emd`,
                            {
                                ...resData
                            },
                            true
                        )
                        .then(async function ([success, response, status]) {
                            if (status == 200) {
                                navigate('/emd-details')
                                toast.success("Payment Successful");

                            }
                            return response;
                        })
                        .catch((err) => {
                            if (err?.response?.status == 422) {
                                toast.error(err?.response?.data?.message);
                            }
                            console.log(err);
                        });
                }
            },
            onError: (err: any) => {
                console.log(err);
            }
        }).render(paypal.current)
    }
    return (
        <div ref={paypal}></div>
    )
}

export default Paypal