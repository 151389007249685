export const Admin_orders:any = { //admin/orders/assignQAvendor
    SelectQAVendor: {
        en: 'Select QA Vendor',
        ka: 'ಕ್ವಾಲಿಟಿ ಆಸ್ವಾದನಾ ವ್ಯಾಪಾರಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },

    Assign: {
        en: 'Assign',
        ka: 'ಹಂಚಿಕೊಳ್ಳು'
    },

//admin/orders/modal/orderview

OrderTrackingStatus: {
        en: 'Order Tracking Status',
        ka: 'ಆದೇಶ ಟ್ರ್ಯಾಕಿಂಗ್ ಸ್ಥಿತಿ'
    },

    Cancelled: {
        en: 'Cancelled',
        ka: 'ರದ್ದುಗೊಂಡಿದೆ'
    },

    Received: {
        en: 'Received',
        ka: 'ಸ್ವೀಕರಿಸಲಾಗಿದೆ'
    },
    Dashboard:{
        en:'Dashboard',
        ka:'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
    },
    OrderID: {
        en: 'Order ID',
        ka: 'ಆದೇಶ ಐಡಿ'
    },
    
    TendorID: {
        en: 'Tendor ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },

    ProductName: {
        en: 'Product Name',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    ProductNameOrder: {
        en: 'Product Name/Order ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ/ಆದೇಶ ಐಡಿ'
    },
   

    ProductCode: {
        en: 'Product Code',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },

    HSNCode: {
        en: 'HSN Code',
        ka: 'ಎಚ್‌ಎಸ್‌ಎನ್ ಕೋಡ್'
    },

    
    Quantity: {
        en: 'Quantity',
        ka: 'ಪ್ರಮಾಣ'
    },
    AllocatetoVendors:{
        en: 'Allocate to Vendors',
        ka: 'ಮಾರಾಟಗಾರರಿಗೆ ಹಂಚಿಕೆ ಮಾಡಿ'
    },
    UnitPrice: {
        en: 'Unit Price',
        ka: 'ಯೂನಿಟ್ ಬೆಲೆ'
    },

    ProductSpecifications: {
        en: 'Product Specifications',
        ka: 'ಉತ್ಪನ್ನದ ವಿಶೇಷಣಗಳು'
    },

    Department: {
        en: 'Department',
        ka: 'ಇಲಾಖೆ'
    },

    ShipmentAddress: {
        en: 'Shipment Address',
        ka: 'ಶಿಪ್ಮೆಂಟ್ ವಿಳಾಸ'
    },

    BuildingNo: {
        en: 'Building No',
        ka: 'ಕಟ್ಟಡ ಸಂಖ್ಯೆ'
    },

    SGST: {
        en: 'SGST',
        ka: 'ಎಸ್ಜಿಎಸ್ಟಿ'
    },

    CGST: {
        en: 'CGST',
        ka: 'ಸಿಜಿಎಸ್ಟಿ'
    },

    IGST: {
        en: 'IGST',
        ka: 'ಐಜಿಎಸ್ಟಿ'
    },

    TotalGST: {
        en: 'Total GST',
        ka: 'ಮೊತ್ತದ ಜಿಎಸ್‌ಟಿ'
    },

    TDSonGST: {
        en: 'TDS on GST',
        ka: 'ಜಿಎಸ್‌ಟಿ ಮೇಲೆ ಟಿಡಿಎಸ್'
    },

    GrandTotal: {
        en: 'Grand Total',
        ka: 'ಮೊತ್ತದ ಮೊತ್ತ'
    },

    Tax: {
        en: 'Tax',
        ka: 'ತೆರಿಗೆ'
    },

    SubTotal: {
        en: 'Sub Total',
        ka: 'ಉಪ ಮೊತ್ತ'
    },

    ChallanNo: {
        en: 'Challan No',
        ka: 'ಚಲನ್ ನಂ'
    },

    DeliveryChallan: {
        en: 'Delivery Challan',
        ka: 'ಡೆಲಿವರಿ ಚಲನ್'
    },

    Payable: {
        en: 'Payable',
        ka: 'ಪಾವತಿಯಾಗಬೇಕಾದ'
    },

    NoDataFound: {
        en: 'No Data Found',
        ka: 'ಮಾಹಿತಿ ಕಂಡುಬಂದಿಲ್ಲ'
    },

    VendorDetails: {
        en: 'Vendor Details',
        ka: 'ವೆಂಡರ್ ವಿವರಗಳು'
    },

    Manager: {
        en: 'Manager',
        ka: 'ಮ್ಯಾನೇಜರ್'
    },

    Accountant: {
        en: 'Accountant',
        ka: 'ಲೆಕ್ಕಪರಿಶೋಧಕ'
    },

    Store: {
        en: 'Store',
        ka: 'ಅಂಗಡಿ'
    },

    DownloadICRR: {
        en: 'Download ICRR',
        ka: 'ICRR ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ'
    }, 

    GenerateICRR: {
        en: 'Generate ICRR',
        ka: 'ICRR ಅನ್ನು ರಚಿಸಿ'
    },

    ApprovalStatus: {
        en: 'Approval Status',
        ka: 'ಅನುಮೋದನೆ ಸ್ಥಿತಿ'
    },

    VendorName: {
        en: 'Vendor Name',
        ka: 'ವೆಂಡರ್ ಹೆಸರು'
    },
    VendorNameOrder: {
        en: 'Vendor Name/Order ID',
        ka: 'ವೆಂಡರ್ ಹೆಸರು/ಆದೇಶ ಐಡಿ'
    },
   

    VendorEmail: {
        en: 'Vendor Email',
        ka: 'ವೆಂಡರ್ ಇಮೇಲ್'
    },

    RegistrationID: {
        en: 'Registration ID',
        ka: 'ನೋಂದಣಿ ಐಡಿ'
    },


    AllocatedQuantity: {
        en: 'Allocated Quantity',
        ka: 'ಹಂಚಿಕೆ ಪ್ರಮಾಣ'
    },

    OrderApprovedByQAVendor: {
        en: 'Order Approved By QA Vendor',
        ka: 'ಕ್ಯೂ.ಏ ವ್ಯಾಪಾರಿ ದ್ವಾರಾ ಆದೇಶ ಅನುಮೋದಿತ'
    },

    UploadedDocument: {
        en: 'Uploaded Document',
        ka: 'ದಾಖಲೆಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ'
    },

    OrderDocumentsApproved: {
        en: 'Order Documents Approved',
        ka: 'ಆದೇಶ ದಾಖಲೆಗಳು ಅನುಮೋದಿತವಾಗಿವೆ'
    },

    WorkOrder: {
        en: 'Work Order',
        ka: 'ಕೆಲಸದ ಆದೇಶ'
    },

    Approved: {
        en: 'Approved',
        ka: 'ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
    },

    ApproveWorkOrder: {
        en: 'Approve Work Order',
        ka: 'ಕಾರ್ಯ ಆದೇಶವನ್ನು ಅನುಮೋದಿಸಿ'
    },

    Rejected: {
        en: 'Rejected',
        ka: 'ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
    },

    RejectWorkOrder: {
        en: 'Reject Work Order',
        ka: 'ಕಾರ್ಯ ಆದೇಶವನ್ನು ತಿರಸ್ಕರಿಸಿ'
    },
    
    NoWorkOrderUploaded: {
        en: 'No Work Order Uploaded',
        ka: 'ಯಾವುದೇ ಕಾರ್ಯ ಆದೇಶ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿಲ್ಲ'
    },

    UploadChequeChellan: {
        en: 'Upload Cheque/Chellan',
        ka: 'ಚೆಕ್ / ಚೆಲ್ಲಾನ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ'
    },

    TransactionNumber: {
        en: 'Transaction Number',
        ka: 'ವ್ಯಾಪಾರ ಸಂಖ್ಯೆ'
    },

    PaidAmount: {
        en: 'Paid Amount',
        ka: 'ಪಾವತಿಸಿದ '
    },

    TransferMoneytoDepartment: {
        en: 'Transfer Money to Department',
        ka: 'ಇಲಾಖೆಗೆ ಹಣವನ್ನು ವರ್ಗಾಯಿಸಿ'
    },
//admin/orders/modal/orderview2
   


    MOUCertificate: {
        en: 'MOU Agreement',
        ka: 'MOU ಪ್ರಮಾಣಪತ್ರ'
    },

    PerformanceGuaranteePayment: {
        en: 'Performance Guarantee Payment',
        ka: 'ಪ್ರದರ್ಶನ ಗ್ಯಾರಂಟಿ ಪಾವತಿ'
    },

    AccountHolderName: {
        en: 'Account Holder Name',
        ka: 'ಖಾತೆದಾರನ ಹೆಸರು'
    },

    BankName: {
        en: 'Bank Name',
        ka: 'ಬ್ಯಾಂಕ್ ಹೆಸರು'
    },

    BranchName: {
        en: 'Branch Name',
        ka: 'ಶಾಖೆಯ ಹೆಸರು'
    },

    Amount: {
        en: 'Amount',
        ka: 'ಮೊತ್ತ'
    },

    InvoiceDate: {
        en: 'Invoice Date',
        ka: 'ಸರಕುಪಟ್ಟಿ ದಿನಾಂಕ'
    },

    DeliveryAddress: {
        en: 'Delivery Address',
        ka: 'ವಿತರಣಾ ವಿಳಾಸ'
    },

    FromAddress: {
        en: 'From Address',
        ka: 'ವಿಳಾಸದಿಂದ'
    },

    TaxInvoice: {
        en: 'Tax Invoice',
        ka: 'ತೆರಿಗೆ ಸರಕುಪಟ್ಟಿ'
    },

    InvoiceNo: {
        en: 'Invoice No',
        ka: 'ಸರಕುಪಟ್ಟಿ ಸಂಖ್ಯೆ'
    },

    NotUploaded:{
        en:'Not Uploaded',
        ka:'ಅಪ್‌ಲೋಡ್ ಆಗಿಲ್ಲ'
    },
    DocumentUploaded: {
        en: 'Document Uploaded',
        ka: 'ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ:'
    },
    Uploaded:{
        en:'Uploaded',
        ka:'ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ'
    },
    View: {
         en: 'Download',
         ka:'ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ'
    },
    Approve: {
        en: 'Approve',
        ka: 'ಅನುಮೋದಿತ'
    },

    Reject: {
        en: 'Reject',
        ka: 'ತಿರಸ್ಕರಿಸು'
    },

    DocumentApproval: {
        en: 'Document Approval',
        ka: 'ದಾಖಲೆ ಅನುಮೋದನೆ'
    },

    Documents: {
        en: 'Documents',
        ka: 'ದಾಖಲೆಗಳು'
    },

    DateofPayment : {
        en: 'Date of Payment ',
        ka: 'ಪಾವತಿಯ ದಿನಾಂಕ'
    },
    Showing:{
        en: 'Showing',
        ka: 'ತೋರಿಸಲಾಗುತ್ತಿದೆ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ನ'
    },
    entries:{
        en: 'entries',
        ka:'ನಮೂದುಗಳು'
    },
    
    Paymenttype: {
        en: 'Payment type',
        ka: 'ಪಾವತಿ ಪ್ರಕಾರ'
    },
    NoDocumentsarefound:{
        en: 'No Documents found',
        ka: 'ಯಾವುದೇ ದಾಖಲೆಗಳು ಕಂಡುಬಂದಿಲ್ಲ'
    },
    Morethanoneorder:{
       en: 'More than one order',
       ka: 'ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ಆದೇಶ'
    },
Status: {
        en: 'Status',
        ka: 'ಸ್ಥಿತಿ'
    },

    Action: {
        en: 'Action',
        ka: 'ಕ್ರಿಯೆ'
    },
//admin/orders/modal/orderview3
Waiting: {
        en: 'Waiting',
        ka: 'ಕಾಯುತ್ತಿರುವುದು'
    },
//admin/orders/modal/vendorallocation

TotalOrderAllocated: {
        en: 'Total Order Allocated',
        ka: 'ಒಟ್ಟು ಆದೇಶ ನಿಗದಿಪಡಿಸಲಾಗಿದೆ'
    },

    TotalAmount: {
        en: 'Total Amount',
        ka: 'ಒಟ್ಟು ಮೊತ್ತ'
    },
    TotalQuantity: {
        en: 'Total Quantity',
        ka: 'ಒಟ್ಟು ಪರಿಮಾಣ'
    },
    Enter: {
        en: 'Enter',
        ka: 'ನಮೂದಿಸಿ'
    },
    Total:{
        en: 'Total',
        ka:'ಒಟ್ಟು'
    },
    //admin/orders/Tabs/approvedorders


    OrderDetails: {
        en: 'Order Details',
        ka: 'ಆದೇಶ ವಿವರಗಳು'
    },

    SlNo:{
        en: 'Sl No',
        ka: 'ಎಸ್ಎಲ್ ನಂ'
    },

    OrderedDate: {
        en: 'Ordered Date',
        ka: 'ಆರ್ಡರ್ ದಿನಾಂಕ'
    },
    ShippingAddress:{
        en: 'Shipping Address',
        ka:'ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ'
    },

   //admin/orders/Tabs/cancelledorders
    
    Refunded: {
        en: 'Refunded',
        ka: 'ಪರಿಹಾರವಾಗಿದೆ'
    },

    Unitprice: {
        en: 'Unit price',
        ka: 'ಘಟಕ ಬೆಲೆ'
    },

  

    ClicktoseetheOrderlist: {
        en: 'Click to see the Order list',
        ka: 'ಆದೇಶ ಪಟ್ಟಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ'
    },

//admin/orders/Tabs/confirmedorders
Pincode: {
        en: 'Pincode:',
        ka: 'ಪಿನ್ ಕೋಡ್'
    },
//admin/orders/Tabs/Processedorders
Processing: {
        en: 'Processing',
        ka: 'ಪ್ರಕ್ರಿಯೆ ನಡೆಸುತ್ತಿದೆ'
    },

    OutforDelivery: {
        en: ' Out for Delivery',
        ka: 'ಬಹಿರಂಗ ವಹಿವಾಟದ ಮೂಲಕ'
    },
//admin/orders/Tabs/receivedorders
PaymentStatus: {
        en: 'Payment Status',
        ka: 'ಪಾವತಿ ಸ್ಥಿತಿ'
    },

    TDS: {
        en: 'TDS',
        ka: 'ಟಿಡಿಎಸ್'
    },

    PayableAmount: {
        en: 'Payable Amount',
        ka: 'ಪಾವತಿ ಮೊತ್ತ'
    },

//admin/orders/coustmeorders
Category: {
        en: 'Category',
        ka: 'ವರ್ಗ'
    },
    AllocationHistory: {
        en: 'Allocation History',
        ka: 'ಹಂಚಿಕೆ ಇತಿಹಾಸ'
    },
    CustomOrderID: {
        en: 'Custom Order ID',
        ka: 'ಅನುಕೂಲಿತ ಆದೇಶ ಐಡಿ'
    },

    VendorAllocation: {
        en: 'Vendor Allocation',
        ka: 'ವೆಂಡರ್ ನಿಯತಿ'
    },
    OrderStatus:{
        en: 'Order Status',
        ka: 'ಆದೇಶ ಸ್ಥಿತಿ'
    },

    SendRequest: {
        en: 'Send Request',
        ka: 'ನೇಮಕಾತಿಯನ್ನು ಕಳುಹಿಸಿ'
    },

    Customorders: {
        en: 'Custom orders',
        ka: 'ಕಸ್ಟಮ್ ಆದೇಶಗಳು'
    },
//admin/orders/coustmeordersmodal

DateofRequest: {
        en: 'Date of Request',
        ka: 'ವಿನಂತಿಯ ದಿನಾಂಕ'
    },

    Specifications: {
        en: 'Specifications',
        ka: 'ವಿವರಣೆ'
    },

    Comments: {
        en: 'Comments',
        ka: 'ಟಿಪ್ಪಣಿಗಳು'
    },

    AlternateSuggestions: {
        en: ' Alternate Suggestions',
        ka: 'ಬದಲಾವಣೆಗಳ ಸೂಚನೆಗಳು'
    },

    Product: {
        en: ' Product',
        ka: 'ಉತ್ಪನ್ನಗಳು'
    },

    Link: {
        en: 'Link',
        ka: 'ಲಿಂಕ್'
    },
//admin/orders/orderlayout
Processed: {
        en: 'Processed',
        ka: 'ಪ್ರಕ್ರಿಯಾಗೊಳಿಸಲಾಗಿದೆ'
    },

    Drafted: {
        en: 'Drafted',
        ka: 'ಹಚ್ಚಲಾಗಿದೆ'
    },

    Latest: {
        en: 'Latest',
        ka: 'ಇತ್ತೀಚಿನ'
    },

    Confirmed: {
        en: 'Confirmed',
        ka: 'ದೃ‌ಢೀ‌ಕರಿಸಲಾಗಿದೆ'
    },
    Confirmed_orders: {
        en: 'Confirmed Orders',
        ka: 'ದೃ‌ಢೀ‌ಕರಿಸಲಾದ ಆದೇಶಗಳು'
    },
    Approved_orders: {
        en: 'Approved Orders',
        ka: 'ಅನುಮೋದಿತ ಆರ್ಡರ್ಗಳು'
    },
    Processed_Orders: {
        en: 'Processed Orders',
        ka: 'ಪ್ರಕ್ರಿಯಾಜೀತ ಆದೇಶಗಳು'
    },
    Cancelled_Orders: {
        en: 'Cancelled Orders',
        ka: 'ರದ್ದುಪಡಿಸಿದ ಆದೇಶಗಳು'
    },
    Rejected_Orders: {
        en: 'Rejected Orders',
        ka: 'ತಿರಸ್ಕರಿಸಿದ  ಆದೇಶಗಳು'
    },

    Pending: {
        en: 'Pending',
        ka: 'ಬಾಕಿಯಿರುವುದು'
    },
    search_here: {
        en:"Search here",
        ka:"ಇಲ್ಲಿ ಹುಡುಕಿ"
    },
    Latest_order: {
        en:"Latest Order",
        ka:"ಇತ್ತೀಚಿನ ಆರ್ಡರ್ಗಳು"
    },
    ProductName_order: {
        en:"Product Name/Order ID",
        ka:"ಟೆಂಡರ್ ಐಡಿ/ಆದೇಶ ಐಡಿ"
    },
   

}