export const Admin_accountlist: any = {
    // ----------admin------------accountlist-------
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    AddAccount: {
        en: 'Add account',
        ka: 'ಖಾತೆಯನ್ನು ಸೇರಿಸಿ'
    },
    Account: {
        en: 'Account',
        ka: 'ಖಾತೆ'
    },
    SlNo: {
        en: 'Sl No',
        ka: 'ಕ್ರಮ ಸಂಖ್ಯೆ'
    },
    Name: {
        en: 'Name',
        ka: 'ಹೆಸರು'
    },
    Phone: {
        en: 'Phone',
        ka: 'ದೂರವಾಣಿ'
    },
    Mail_Id: {
        en: 'E-Mail Id',
        ka: 'ಇ-ಮೇಲ್ ಐಡಿ'
    },
    AccountType: {
        en: 'Account Type',
        ka: 'ಖಾತೆಯ ಪ್ರಕಾರ'
    },
    Status: {
        en: 'Status',
        ka: 'ಸ್ಥಿತಿ'
    },
    Email: {
        en: 'Email',
        ka: 'ಇಮೇಲ್'
    },
    Active: {
        en: 'Active',
        ka: 'ಸಕ್ರಿಯ'
    },
    Inactive: {
        en: 'Inactive',
        ka: 'ಇನಿಷ್ಕ್ರಿಯಮೇಲ್'
    },
    SelectAccountType: {
        en: 'Select Account Type',
        ka: 'ಖಾತೆಯ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },

    Showing: {
        en: 'Showing',
        ka: 'ತೋರಿಸುವ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ನ'
    },
    entries: {
        en: 'entries',
        ka: 'ಪ್ರವೇಶಗಳು'
    },
    Store: {
        en: 'Store',
        ka: 'ಅಂಗಡಿ'
    },
    Manager: {
        en: 'Manager',
        ka: 'ಮ್ಯಾನೇಜರ್'
    },
    Accountant: {
        en: 'Accountant',
        ka: 'ಲೆಕ್ಕಪರಿಶೋಧಕ'
    },
    Submit: {
        en: 'Submit',
        ka: 'ಲೆಕ್ಕಪರಿಶೋಧಕ'
    },

}
