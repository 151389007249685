import React, { useEffect, useState, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardBody, Input, Progress, Spinner } from 'reactstrap'
import { api } from "../../../Library/CustomApi";
import TicketsChart from "../VendorCharts/TicketsChart";
import RadialChart from "../VendorCharts/RadialChart";
import { convertISODateToDateTime } from "../../../Library/Constants/helpers";
import Loader from "../../../Components/Loader/Loader";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";


export default function VendorDashboard() {
  const [day_click, setDayClick] = useState(false)

  const [data, setData] = useState([{
    id: 1,
    day: "Sun",
    date: "23",
    is_click: true
  },
  {
    id: 1,
    day: "Mon",
    date: "24",
    is_click: false
  }, {
    id: 1,
    day: "Tue",
    date: "25"
    ,
    is_click: false
  }, {
    id: 1,
    day: "Wed",
    date: "26"
    ,
    is_click: false
  }, {
    id: 1,
    day: "Thu",
    date: "27",
    is_click: false
  }, {
    id: 1,
    day: "Fri",
    date: "28",
    is_click: false
  }, {
    id: 1,
    day: "Sun",
    date: "29",
    is_click: false
  }, {
    id: 1,
    day: "Sat",
    date: "30",
    is_click: false
  },])
  const [dashboard, setDashboard] = useState({
    approved_orders: "",
    approved_orders_percentage: "",
    cancelled_orders: "",
    cancelled_orders_percentage: "",
    delivered_orders: "",
    delivered_orders_percentage: "",
    penalties: "",
    pending_orders: "",
    pending_orders_percentage: "",
    pending_payment: "",
    pending_ticket: "",
    todays_order_percentage: "",
    todays_sales_percentage: "",
    total_orders: "",
    total_payment: "",
    total_sales: "",
    pending_payment_percentage: ""


  })

  const [orders, setOrders] = useState([])
      //language
      const { language, setLanguage } = useContext(Context);

  const [ordersLoading, setOrdersLoading] = useState<boolean>(true)
  const [orderGraphFilter, setOrderGraphFilter] = useState(1)
  const [orders_graph, setOrdersGraph] = useState([])
  const [orders_graphs, setOrdersGraphs] = useState<any>([])
  const [orders_graphs_filter_values, setorders_graphs_filter_values] = useState<any>("")
  const [orders_graphs_index, setorders_graphs_index] = useState<any>(0)
  const [TicketFilter, setTicketFilter] = useState(1)
  const [TicketValues, setTicketValues] = useState([])
 

  const DayClick = (index: number) => {
    var new_data = [...data];
    new_data[index].is_click = !new_data[index]?.is_click;
    setData(new_data);
  }
  useEffect(() => {
    getData(orderGraphFilter)
    orgerGraph(orderGraphFilter)
    ticketGraph(TicketFilter)

  }, [orderGraphFilter, orders_graphs_index, TicketFilter])


  const orgerGraph = (params: number) => {
    api
      .post(`/vendor/order_graph?data`, { data: params }, true)
      .then(async function ([success, response]) {
        setOrdersGraphs(response)
        // setorders_graphs_filter_values(response?.data?.[0])
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const setorders_graphs_index_fun = (val: number) => {
    setorders_graphs_filter_values(orders_graphs?.data?.[val]?.count)
    setorders_graphs_index(val)

  }

  const OrderGraphonChange = (e: any) => {
    setorders_graphs_index_fun(0)
    setOrderGraphFilter(e.target.value)
    orgerGraph(e.target.value)
    setorders_graphs_index(0)

  }
  const TicketonChange = (e: any) => {
    setTicketFilter(e.target.value)
    ticketGraph(e.target.value)
  }

  const ticketGraph = (params: any) => {
    api
      .post(`/dashboard/ticket_graph_list`, { data: params }, true)
      .then(async function ([success, response]) {
        setTicketValues(response)
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const getData = (params: any) => {
    api
      .post(`/vendor/order_count`, { data: params }, true)
      .then(async function ([success, response]) {
        setDashboard({
          ...dashboard,
          approved_orders: response?.data[0]?.approved_orders,
          approved_orders_percentage: response?.data[0]?.approved_orders_percentage,
          cancelled_orders: response?.data[0]?.cancelled_orders,
          cancelled_orders_percentage: response?.data[0]?.cancelled_orders_percentage,
          delivered_orders: response?.data[0]?.delivered_orders,
          delivered_orders_percentage: response?.data[0]?.delivered_orders_percentage,
          penalties: response?.data[0]?.penalties,
          pending_orders: response?.data[0]?.pending_orders,
          pending_orders_percentage: response?.data[0]?.pending_orders_percentage,
          pending_payment: response?.data[0]?.pending_payment,
          pending_payment_percentage: response?.data[0]?.pending_payment_percentage,
          pending_ticket: response?.data[0]?.pending_ticket,
          todays_order_percentage: response?.data[0]?.todays_order_percentage,
          todays_sales_percentage: response?.data[0]?.todays_sales_percentage,
          total_orders: response?.data[0]?.total_orders,
          total_payment: response?.data[0]?.total_payment,
          total_sales: response?.data[0]?.total_sales,
        })
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    setOrdersLoading(true)
    api
      .post(`/vendor/recent_order_list`, {}, true)
      .then(async function ([success, response]) {
        setOrders(response?.data)
        setOrdersLoading(false)
        return response;
      })
      .catch((err) => {
        setOrdersLoading(false)
        console.log(err);
      });

  };


  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="vendor-dashboard">
          <Row className="gy-3 gx-3">
            <Col lg={6}>
              <Row className="gy-3 gx-3">
                <Col md={12}> 
                  <Card className="dashboard-card-wrap">
                    <CardHeader className="mb-0">
                      {/* large screen-heading */}
                      <h6 className="heading large-screen-view">
                        {orders_graphs.completed_orders}                       
                        {VendorRegLang?.orders_completed_out_of[language]}
                        {orders_graphs.total_orders}
                      </h6>
                      {/* mobile screen heading */}
                      <h6 className="heading mobile-view">
                        {VendorRegLang?.completed_orders[language]}
                      </h6>
                      {/* <Input type="select" name="" className="form-select w-auto" onChange={OrderGraphonChange}>
                        <option value='1' selected>
                          {VendorRegLang?.this_week[language]}
                        </option>
                        <option value='2'>
                          {VendorRegLang?.this_month[language]}
                        </option>
                        <option value='3'>
                          {VendorRegLang?.this_year[language]}
                        </option>
                      </Input> */}
                    <Input type="select" name="" className="form-select w-auto" onChange={OrderGraphonChange} value={orderGraphFilter}>
                    <option value='1'>
                      {VendorRegLang?.this_week[language]}
                    </option>
                    <option value='2'>
                      {VendorRegLang?.this_month[language]}
                    </option>
                    <option value='3'>
                      {VendorRegLang?.this_year[language]}
                    </option>
                    </Input>

                    </CardHeader>
                    <CardBody>
                      <Progress
                        value={orders_graphs_filter_values}
                        className="animated-gradient"
                      />
                      <div className="completed-order-count-wrap mobile-view">
                        <p><span className="completed">{orders_graphs.completed_orders}</span> {VendorRegLang?.orders_completed_out_of[language]} <span className="total">{orders_graphs.total_orders}</span></p>
                      </div>
                      {/* <p className="date">23 December,&nbsp;<span>Sunday</span></p> */}
                      <div className="calendar-flex">
                        {orders_graphs?.data?.map((item: any, index: number) => {
                          return (<React.Fragment key={index}>
                            <div key={index} className="calendar-inner-wrap" onClick={() => setorders_graphs_index_fun(index)} style={{ cursor: "pointer" }}>
                              {/* <p>{item?.day}</p> */}
                              <small className={index != orders_graphs_index ? "" : "active"}>{item?.name}</small>
                            </div></React.Fragment>)
                        })}

                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="count-card-wrap vendor-cardbody-outer">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.total_orders[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.total_orders.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.total_orders}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.total_orders.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.total_orders}%<span className="ms-1"><img src={`${dashboard?.total_orders.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.total_sales[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.total_sales.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.total_sales}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.total_sales.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.total_sales}%<span className="ms-1"><img src={`${dashboard?.total_sales.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.total_payments[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.total_payment.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.total_payment}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.total_payment.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.total_payment}%<span className="ms-1"><img src={`${dashboard?.total_payment.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.pending_payments[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.pending_payment.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.pending_payment}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.pending_payment_percentage.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.pending_payment_percentage}%<span className="ms-1"><img src={`${dashboard?.pending_payment_percentage.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.pending_orders[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.pending_orders.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.pending_orders}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.pending_orders_percentage.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.pending_orders_percentage}%<span className="ms-1"><img src={`${dashboard?.pending_orders_percentage.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                
                <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.approved_orders[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.approved_orders.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.approved_orders}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.approved_orders_percentage.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.approved_orders_percentage}%<span className="ms-1"><img src={`${dashboard?.approved_orders_percentage.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.delivered_orders[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.delivered_orders.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.delivered_orders}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.delivered_orders_percentage.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.delivered_orders_percentage}%<span className="ms-1"><img src={`${dashboard?.delivered_orders_percentage.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                
                <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.cancelled_orders[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.cancelled_orders}</h5>
                        <small
                          className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.cancelled_orders_percentage}%<span className="ms-1"><img src={`${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.pending_tickets[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.pending_ticket.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.pending_ticket}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.pending_ticket.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.pending_ticket}%<span className="ms-1"><img src={`${dashboard?.pending_ticket.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="count-card-wrap">
                    <CardBody>
                      <h6 className="heading">{VendorRegLang?.penalties[language]}</h6>
                      <div className="count-flex">
                        <h5 className={`count ${dashboard?.penalties.toString().startsWith("-") ? "text-danger" : "primart-text"}`}>{dashboard?.penalties}</h5>
                        <small className={`count-growth ${dashboard?.cancelled_orders_percentage.toString().startsWith("-") ? "text-danger" : "primary-text"}`}>
                          <>{dashboard?.penalties.toString().startsWith("-") ? () => "-" : () => "+"}</>
                          {dashboard?.penalties}%<span className="ms-1"><img src={`${dashboard?.penalties.toString().startsWith("-") ? "images/grow-down.svg" : "images/grow-up.svg"}`} alt="img" />
                          </span>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </Col>
            <Col lg={6}>
              <Row className="gy-3 gx-3 sticky-up">
                <Col md={12}>
                  <Card className="dashboard-card-wrap recent-orders">
                    <CardHeader>
                      <h6 className="heading">{VendorRegLang?.recent_orders[language]}</h6>
                    </CardHeader>
                    <CardBody className="recent-orders-content-wrap-outer">
                      <div className="recent-orders-content-wrap">

                        {ordersLoading ?
                          <div className="text-center">
                            <Loader />
                          </div>
                          :
                          orders?.length !== 0 ?
                            orders?.map((item: any, index: number) => {
                              return (
                                <React.Fragment key={index}>
                                  <div key={index} className="recent-orders-item-flex">
                                    <div className="left-side">
                                      <div className="icon-wrap">
                                        <i className="icons icons-cart-rounded xxl"></i>
                                      </div>
                                      <div className="item-details">
                                        <h6>{item?.product_details?.name} <br />
                                          <small>{
                                            convertISODateToDateTime(item?.created_at)
                                          }<span>{item?.quantity} Pieces</span></small></h6>
                                      </div>
                                    </div>
                                    <div className="right-side">
                                      <h6 className="price-range">₹{item?.total_amount}</h6>
                                    </div>
                                  </div>
                                </React.Fragment>)
                            })
                            :
                            <p>
                              {VendorRegLang?.no_recent_orders[language]}
                            </p>}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card className="dashboard-card-wrap">
                    <CardHeader>
                      <h6 className="heading">{VendorRegLang?.tickets[language]}</h6>
                      {/* <Input type='select' name="" className="form-select w-auto" onChange={TicketonChange} >
                        <option value={1} selected>
                          {VendorRegLang?.this_week[language]}
                        </option>
                        <option value={2} >
                          {VendorRegLang?.this_month[language]}
                        </option>
                        <option value={3} >
                          {VendorRegLang?.this_year[language]}
                        </option>
                      </Input> */}
                      <Input type='select' name="" className="form-select w-auto" onChange={TicketonChange} value={TicketFilter}>
                      <option value={1}>
                        {VendorRegLang?.this_week[language]}
                      </option>
                      <option value={2}>
                        {VendorRegLang?.this_month[language]}
                      </option>
                      <option value={3}>
                        {VendorRegLang?.this_year[language]}
                      </option>
                      </Input>

                    </CardHeader>
                    <CardBody>
                      <div className="growth-graph-wrap">
                        <RadialChart data={TicketValues} />
                        {/* <TicketsChart data={TicketValues} /> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div >
      </React.Fragment>
  );
}