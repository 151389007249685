import { toast } from "react-toastify";
import constants from "./Constants";
function getOrderStyle(id: string) {
  return parseInt(id) === constants.DOCUMETSWAY.APPROVED ? (
    <span className="approved-status">Approved</span>
  ) : parseInt(id) === constants.DOCUMETSWAY.REJECTED ? (
    <span className="rejected-status">Rejected</span>
  ) : parseInt(id) === constants.DOCUMETSWAY.REUPLOAD ? (
    <span className="info-status">Reuploaded</span>
  ) : parseInt(id) === constants.DOCUMETSWAY.PENDING ? (
    <span className="warning-status">Pending</span>
  ) : null;
}

function getOrderStatus(id: string) {
  return parseInt(id) === constants.ORDERS?.CONFIRMED ? (
    <span className="approved-status">Accepted</span>
  ) : parseInt(id) === constants.ORDERS.REJECTED ? (
    <span className="rejected-status ">Rejected</span>
  ) : parseInt(id) === constants.ORDERS.DELIVERED ? (
    <span className="approved-status">Delivered</span>
  ) : parseInt(id) === constants.ORDERS.PENDING ? (
    <span className="warning-status">Pending</span>
  ) : parseInt(id) === constants.ORDERS.OUTOFDELIVEREY ? (
    <span className="info-status">Out for Delivery</span>
  ) : parseInt(id) === constants.ORDERS.CANCELLED ? (
    <span className="rejected-status">Cancelled</span>
  ) : parseInt(id) === constants.ORDERS.PROCESSED ? (
    <span className="info-status">Processing</span>
  ) : parseInt(id) === constants.ORDERS.RECEIVED ? (
    <span>New Order</span>
  ) : parseInt(id) === constants.ORDERS.COMPLETED ? (
    <span className="new-status">Completed</span>
  ) : parseInt(id) === constants.ORDERS.DELIVEREDANDINSTALLED ? (
    <span className="info-status">Delivered and Installed</span>
  ) : null;
}
function PaymentStatus(id: string) {
  return parseInt(id) === constants.DEPT_PAYMENT_MODES?.FULL_PAYMENT ? (
    <span className="approved-status">Full Payment</span>
  ) : parseInt(id) === constants.DEPT_PAYMENT_MODES.PARTIAL_PAYMENT ? (
    <span title="View Payment Status" className="warning-status">
      Partially Paid
    </span>
  ) : parseInt(id) === constants.DEPT_PAYMENT_MODES.PAY_LATER ? (
    <span title="View Payment Status" className="rejected-status">
      Pay Later
    </span>
  ) : (
    <span className="danger-status">Net Banking</span>
  );
}
function CoustmeOrderStatus(id: string) {
  return parseInt(id) === constants.COUSTME_ORDERS?.NEW_ORDER ? (
    <span className="warning-status">New Suggestion</span>
  ) : parseInt(id) === constants.COUSTME_ORDERS.REQUEST_ACCEPT ? (
    <span className="text-success">Suggestion Accepted</span>
  ) : parseInt(id) === constants.COUSTME_ORDERS.REQUEST_REJECT ? (
    <span className="text-danger">Suggestion Rejected</span>
  ) : parseInt(id) === constants.COUSTME_ORDERS.REQUEST_SEND ? (
    <span className="info-status">Suggestion Sent</span>
  ) : (
    <span className="warning-status"></span>
  );
}
function DocumentTYpe(id: string) {
  return parseInt(id) == constants.DOCUMENTS.PAN ? (
    <span>Pan</span>
  ) : parseInt(id) == constants.DOCUMENTS.GST ? (
    <span>GST</span>
  ) : parseInt(id) == constants.DOCUMENTS.AADAR ? (
    <span>Aadhar Proof</span>
  ) : parseInt(id) == constants.DOCUMENTS.AADRESS_PROOF ? (
    <span>Address Proof</span>
  ) : parseInt(id) == constants.DOCUMENTS.INCORP ? (
    <span>Company Incorporation Certificate</span>
  ) : parseInt(id) == constants.DOCUMENTS.MSE ? (
    <span>MSE</span>
  ) : parseInt(id) == constants.DOCUMENTS.ISO ? (
    <span>ISO</span>
  ) : parseInt(id) == constants.DOCUMENTS.OEM ? (
    <span>OEM</span>
  ) : parseInt(id) == constants.DOCUMENTS.TURN_OVER1 ? (
    <span>Turn Over 1</span>
  ) : parseInt(id) == constants.DOCUMENTS.TURN_OVER2 ? (
    <span>Turn Over 2</span>
  ) : parseInt(id) == constants.DOCUMENTS.TURN_OVER3 ? (
    <span>Turn Over 3</span>
  ) : parseInt(id) == constants.DOCUMENTS.DD ? (
    <span>DD</span>
  ) : parseInt(id) == constants.DOCUMENTS.EMPANEL ? (
    <span>Empanel</span>
  ) : parseInt(id) == constants.DOCUMENTS.WORKORDER ? (
    <span>Work Order</span>
  ) : parseInt(id) == constants.DOCUMENTS.HR_ANY_OFFICE ? (
    <span>Any other office</span>
  ) : parseInt(id) == constants.DOCUMENTS.HR_LAND ? (
    <span>Office Details</span>
  ) : parseInt(id) == constants.DOCUMENTS.HR_MANPOWER ? (
    <span>Man power</span>
  ) : null;
}
function DocumentTYpeUpload(id: string) {
  return parseInt(id) == constants.DOCUMENTS.PAN
    ? "PAN"
    : parseInt(id) == constants.DOCUMENTS.GST
      ? " GST"
      : parseInt(id) == constants.DOCUMENTS.AADAR
        ? "Aadhar Proof"
        : parseInt(id) == constants.DOCUMENTS.AADRESS_PROOF
          ? "Address Proof"
          : parseInt(id) == constants.DOCUMENTS.INCORP
            ? "Company Incorporation Certificate"
            : parseInt(id) == constants.DOCUMENTS.MSE
              ? "MSME"
              : parseInt(id) == constants.DOCUMENTS.ISO
                ? "ISO"
                : parseInt(id) == constants.DOCUMENTS.OEM
                  ? "OEM"
                  : parseInt(id) == constants.DOCUMENTS.TURN_OVER1
                    ? "Turn Over 1"
                    : parseInt(id) == constants.DOCUMENTS.TURN_OVER2
                      ? "Turn Over 2"
                      : parseInt(id) == constants.DOCUMENTS.TURN_OVER3
                        ? "Turn Over 3"
                        : parseInt(id) == constants.DOCUMENTS.DD
                          ? "DD"
                          : parseInt(id) == constants.DOCUMENTS.EMPANEL
                            ? "Empanel"
                            : parseInt(id) == constants.DOCUMENTS.WORKORDER
                              ? "Work Order"
                              : parseInt(id) == constants.DOCUMENTS.HR_ANY_OFFICE
                                ? "Any other office Proof"
                                : parseInt(id) == constants.DOCUMENTS.HR_LAND
                                  ? "Office Details (Land)"
                                  : parseInt(id) == constants.DOCUMENTS.HR_MANPOWER
                                    ? "Man power"
                                    : parseInt(id) == constants.DOCUMENTS.IT_TURN_OVER_ONE
                                      ? "IT Turn over One"
                                      : parseInt(id) == constants.DOCUMENTS.IT_TURN_OVER_TWO
                                        ? "IT Turn Over Two"
                                        : parseInt(id) == 35
                                          ? "Startup Document"
                                          : parseInt(id) == 37
                                            ? "Clearance Certificate"
                                            : parseInt(id) == 36
                                              ? "No Black List"
                                              : parseInt(id) == 39
                                                ? "Udyam DFO"
                                                : parseInt(id) == 38
                                                  ? "Power of Attorney"
                                                  : null;
}
function VendorType(id: string) {
  return parseInt(id) == constants.VENDOR_TYPE?.MANUFATURE
    ? "Manufacture"
    : parseInt(id) == constants.VENDOR_TYPE?.DISTRIBUTER
      ? "Distributor"
      : parseInt(id) == constants.VENDOR_TYPE?.IMPORTER
        ? "Importer"
        : parseInt(id) == constants.VENDOR_TYPE?.MARKETER
          ? "Marketer"
          : parseInt(id) == constants?.VENDOR_TYPE?.PROPRITERSHIP
            ? "Proprietorship"
            : parseInt(id) == constants.VENDOR_TYPE?.PARTNERSHIP
              ? "Patnership"
              : parseInt(id) == constants.VENDOR_TYPE?.PVTLTD
                ? "PVTLTD"
                : parseInt(id) == constants.VENDOR_TYPE?.LLP
                  ? "LLP"
                  : parseInt(id) == constants.VENDOR_TYPE?.OEMS
                    ? "OEMS"
                    : parseInt(id) == constants.VENDOR_TYPE?.STARTUP
                      ? "Start Up"
                      : parseInt(id) == constants.VENDOR_TYPE?.QAVENDOR
                        ? "QA Vendor"
                        : null;
}
const capitalizeFirst = (str: any) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function convertISODateToDate(iso_date: any) {
  var date = new Date(iso_date);
  let months = {
    month_names: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    month_names_short: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };
  return (
    months.month_names_short[date.getMonth()] +
    " " +
    date.getDate() +
    "," +
    date.getFullYear()
  );
}
function validateDatetotime(iso_date: any) {
  var date = new Date(iso_date);
  var hours = date.getHours();
  var minutes: any = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
}
function convertISODateToDateTime(iso_date_time: any) {
  return (
    convertISODateToDate(iso_date_time) +
    " " +
    validateDatetotime(iso_date_time)
  );
}

function getUnique(arr: any, index: any) {
  const unique = arr
    .map((e: any) => e[index])

    // store the keys of the unique objects
    .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e: any) => arr[e])
    .map((e: any) => arr[e]);

  return unique;
}

function convertFieldName(str: any) {
  var splitStr = str?.replaceAll("_", " ").toLowerCase()?.split(" ");

  for (var i = 0; i < splitStr?.length; i++) {
    splitStr[i] = splitStr[i]?.charAt(0) + splitStr[i]?.substring(1);
  }
  var text = splitStr?.join(" ");
  return text[0]?.toUpperCase() + text?.slice(1);
}
function remove_first_leters(str: any) {
  let str_ = str.replace(/.(?=.{7})/g, "x");
  return str_;
}
function truncateText(text: any) {
  return text?.replace(/<br>/g, ", ");
}
const preventNegativeValues = (e: any) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const regex = /^[0-9]*$/; // regex pattern to match only numeric input
  if (!regex.test(keyValue)) {
    e.preventDefault();
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  }
};

function turn_over_mark_caluction(amount: number) {
  let two_cr: number = 20000000;
  let three_cr: number = 30000000;
  let four_cr: number = 40000000;
  let four_point_cr: number = 45000000;
  let secv_cr: number = 70000000;
  let ten_cr: number = 100000000;
  let ten_point_cr: number = 105000000;
  let twlv_cr: number = 120000000;
  let fr_cr: number = 140000000;

  let one_a: any = ten_point_cr < amount && twlv_cr >= amount;
  let two_a: any = twlv_cr < amount && fr_cr > amount;
  let three_a: any = fr_cr <= amount;

  let one_b: any = four_point_cr < amount && secv_cr >= amount;
  let two_b: any = secv_cr < amount && ten_cr >= amount;
  let three_b: any = ten_cr < amount && ten_point_cr >= amount;

  let one_c: any = two_cr < amount && three_cr >= amount;
  let two_c: any = three_cr < amount && four_cr >= amount;
  let three_c: any = four_cr < amount && four_point_cr >= amount;
  let zero_: any = two_cr > amount;

  switch (
  one_a ||
  two_a ||
  three_a ||
  one_b ||
  two_b ||
  three_b ||
  one_c ||
  three_c ||
  two_c ||
  zero_
  ) {
    case one_a:
      return ["A", "20"];

    case two_a:
      return ["A", "25"];

    case three_a:
      return ["A", "30"];

    case one_b:
      return ["B", "20"];

    case two_b:
      return ["B", "25"];

    case three_b:
      return ["B", "30"];

    case one_c:
      return ["C", "20"];

    case two_c:
      return ["C", "25"];

    case three_c:
      return ["C", "30"];
    case zero_:
      return ["Nil", "0"];
  }
}

function turn_over_mark_caluction_(amount: number) {
  let two_cr: number = 20000000;
  let three_cr: number = 30000000;
  let four_cr: number = 40000000;
  let four_point_cr: number = 45000000;
  let secv_cr: number = 70000000;
  let ten_cr: number = 100000000;
  let ten_point_cr: number = 105000000;
  let twlv_cr: number = 120000000;
  let fr_cr: number = 140000000;

  let one_a: any = ten_point_cr < amount && twlv_cr >= amount;
  let two_a: any = twlv_cr < amount && fr_cr > amount;
  let three_a: any = fr_cr <= amount;

  let one_b: any = four_point_cr < amount && secv_cr >= amount;
  let two_b: any = secv_cr < amount && ten_cr >= amount;
  let three_b: any = ten_cr < amount && ten_point_cr >= amount;

  let one_c: any = two_cr < amount && three_cr >= amount;
  let two_c: any = three_cr < amount && four_cr >= amount;
  let three_c: any = four_cr < amount && four_point_cr >= amount;
  let zero_: any = two_cr > amount;

  switch (
  one_a ||
  two_a ||
  three_a ||
  one_b ||
  two_b ||
  three_b ||
  one_c ||
  three_c ||
  two_c ||
  zero_
  ) {
    case one_a:
      return 20;

    case two_a:
      return 25;

    case three_a:
      return 30;

    case one_b:
      return 20;

    case two_b:
      return 25;

    case three_b:
      return 30;

    case one_c:
      return 20;

    case two_c:
      return 25;

    case three_c:
      return 30;

    case zero_:
      return 0;
  }
}
function it_turn_mark_caluction(amount: number) {
  let five_cr: number = 50000000;
  let five_point_cr: number = 55000000;
  let six_cr: number = 60000000;
  let seven_cr: number = 70000000;
  let two_cr: number = 20000000;
  let two_point_cr: number = 25000000;
  let one_cr: number = 10000000;
  let one_pont_cr: number = 15000000;
  let four_cr: number = 40000000;

  let one_a: any = five_point_cr < amount && six_cr >= amount;
  let two_a: any = six_cr < amount && seven_cr >= amount;
  let three_a: any = seven_cr < amount;

  let one_b: any = two_point_cr < amount && four_cr >= amount;
  let two_b: any = four_cr < amount && five_cr >= amount;
  let three_b: any = five_cr < amount && five_point_cr >= amount;

  let one_c: any = one_cr < amount && one_pont_cr >= amount;
  let two_c: any = one_pont_cr < amount && two_cr >= amount;
  let three_c: any = two_cr < amount && two_point_cr >= amount;
  // let zer0:any=
  let zero_: any = one_cr > amount;
  switch (
  one_a ||
  two_a ||
  three_a ||
  one_b ||
  two_b ||
  three_b ||
  one_c ||
  three_c ||
  two_c ||
  zero_
  ) {
    case one_a:
      return ["A", "20"];

    case two_a:
      return ["A", "25"];

    case three_a:
      return ["A", "30"];

    case one_b:
      return ["B", "20"];

    case two_b:
      return ["B", "25"];

    case three_b:
      return ["B", "30"];

    case one_c:
      return ["C", "20"];

    case two_c:
      return ["C", "25"];

    case three_c:
      return ["C", "30"];

    case zero_:
      return ["Nil", "0"];
  }
}
function it_turn_mark_caluction_(amount: number) {
  let five_cr: number = 50000000;
  let five_point_cr: number = 55000000;
  let six_cr: number = 60000000;
  let seven_cr: number = 70000000;
  let two_cr: number = 20000000;
  let two_point_cr: number = 25000000;
  let one_cr: number = 10000000;
  let one_pont_cr: number = 15000000;
  let four_cr: number = 40000000;

  let one_a: any = five_point_cr < amount && six_cr >= amount;
  let two_a: any = six_cr < amount && seven_cr >= amount;
  let three_a: any = seven_cr < amount;

  let one_b: any = two_point_cr < amount && four_cr >= amount;
  let two_b: any = four_cr < amount && five_cr >= amount;
  let three_b: any = five_cr < amount && five_point_cr >= amount;

  let one_c: any = one_cr < amount && one_pont_cr >= amount;
  let two_c: any = one_pont_cr < amount && two_cr >= amount;
  let three_c: any = two_cr < amount && two_point_cr >= amount;
  // let zer0:any=
  let zero_: any = one_cr > amount;
  switch (
  one_a ||
  two_a ||
  three_a ||
  one_b ||
  two_b ||
  three_b ||
  one_c ||
  three_c ||
  two_c ||
  zero_
  ) {
    case one_a:
      return 20;

    case two_a:
      return 25;

    case three_a:
      return 30;

    case one_b:
      return 20;

    case two_b:
      return 25;

    case three_b:
      return 30;

    case one_c:
      return 20;

    case two_c:
      return 25;

    case three_c:
      return 30;

    case zero_:
      return 0;
  }
}
function file_remove(file_name: any, ref_value: any, c_name: any) {
  if (c_name == file_name) {
    if (ref_value.current) {
      return (ref_value.current.value = "");
    }
  }
}

// finds sum of a particulat key in an array of objects 
function sumByKey(arr: any, key: any) {
  let sum = 0;
  arr?.forEach((item: any) => {
    sum += Number(item[key]);
  });
  return sum;
}
function mergeCommonValues(arr1: any, arr2: any) {
  let mergedArray = [];

  for (let item1 of arr1) {
    for (let item2 of arr2) {
      if (item1.type === item2.type) {
        // Merge the common value into a new object
        let mergedItem = { ...item1, ...item2 };
        mergedArray.push(mergedItem);
        break; // Stop the inner loop as we have found a match
      }
    }
  }

  return mergedArray;
}

// used to select work order files- accepts only pdf files 
const handleWorkOrderFileChange = (event: any, setFile: Function, files: any) => {
  let selectedFiles = [...event?.target?.files]

  // filters pdf files 
  let filteredArray: any = selectedFiles?.filter((it: any, i: number) => it?.name?.endsWith('.pdf'))
  setFile([...files, ...filteredArray]);

  // shows error when non pdf file is selected 
  if (selectedFiles?.length !== filteredArray?.length) toast?.error('Only PDF is accepeted', { toastId: 'pdf-error' })
};

export {
  getOrderStyle,
  handleWorkOrderFileChange,
  sumByKey,
  getOrderStatus,
  capitalizeFirst,
  convertISODateToDateTime,
  DocumentTYpe,
  getUnique,
  PaymentStatus,
  CoustmeOrderStatus,
  convertFieldName,
  remove_first_leters,
  truncateText,
  preventNegativeValues,
  turn_over_mark_caluction,
  it_turn_mark_caluction,
  DocumentTYpeUpload,
  turn_over_mark_caluction_,
  it_turn_mark_caluction_,
  file_remove,
  VendorType,
  mergeCommonValues
};
