import React from "react";
import { useState, useEffect,useContext } from "react";
import { Context } from "../../../../Components/Context/Context";
import {Admin_orders} from "../../../../Library/LanguageConversion/Admin_orders";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import OrderView2 from "../Modal/OrderView2";
import moment from "moment";

export default function ProcessedOrders() {
  const { language, setLanguage } = useContext(Context);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  const [processed_order, setProcessedOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any>();
  const [search, setSearch] = useState("");
  const [order_view, setOrderView] = useState([]);

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getProcessedOrder();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);
  const getProcessedOrder = () => {
    setLoading(true);
    api
      .get(
        `/order/get-confirmed-orders?status=${constants.ORDERS.PROCESSED}&page=${currentPage}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setProcessedOrder(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
      
  };

  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-order-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message?.[0]);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  function limitText(text: any, limit: any) {
    if (text?.length > limit) {
      return text?.substring(0, limit) + "...";
    } else {
      return text;
    }
  }

  return (
    <>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText title="Product Name/Order ID" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={Admin_orders?.ProductName_order?.[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_orders?.SlNo[language]}</th>
              <th>{Admin_orders?.OrderID[language]}</th>
              <th>{Admin_orders?.OrderedDate[language]}</th>
              {/* <th>Tender ID</th> */}
              <th>{Admin_orders?.OrderDetails[language]}</th>
              <th>{Admin_orders?.VendorName[language]}</th>
              <th>{Admin_orders?.ShippingAddress[language]}</th>
              <th>{Admin_orders?.Quantity[language]}</th>
              <th>{Admin_orders?.Department[language]}</th>
              <th>{Admin_orders?.Status[language]}</th>
            </tr>
          </thead>
          <tbody>
            {processed_order.length !== 0 ? (
              <>
                {processed_order?.map((item: any, i: any) => {
                  let count = 0;
                  return (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.SlNo[language]}:</span>
                          <span className="table-data">
                            {pageData?.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.OrderID[language]}:</span>
                          <Link
                            title="View Order"
                            to="#"
                            className="primary-text"
                            onClick={() => {
                              toggle();
                              getSingleOrder(item?.id);
                            }}
                          >
                            {item?.vendor_po_no}
                          </Link>
                        </td>
                        {/* <td>
                          <span className="mobile-heading">Tender ID:</span>
                          <span className="table-data">
                            {item?.pf_invoice?.pf_details[0]?.tender_id}
                          </span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">{Admin_orders?.OrderedDate[language]}:</span>
                          <span className="table-data">
                            {moment(item?.pf_invoice?.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.OrderDetails[language]}:</span>
                          <div>
                            <span className="product-title" title={item?.order_details?.name}>
                              {item?.order_details?.name
                                ? limitText(item?.order_details?.name, 15)
                                : null}
                            </span>
                            <br></br>
                            <span>
                            {Admin_orders?.Unitprice[language]}:{" "}
                              <span>
                                <i className="bi bi-currency-rupee"></i>{" "}
                                {item?.pf_invoice?.pf_details[0]?.price}
                              </span>
                            </span>
                            <br></br>
                            <span>
                            {Admin_orders?.Quantity[language]}:{" "}
                              <span>{item?.order_details?.quantity}</span>
                            </span>
                            <br></br>
                            <span>
                            {Admin_orders?.Total[language]}:{" "}
                              <span>
                                <i className="bi bi-currency-rupee"></i>
                                {item?.pf_invoice?.total_amount}
                              </span>
                            </span>
                          </div>
                          <br></br>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.VendorName[language]}:</span>
                          <span className="table-data">
                            {item?.cmpny_detail?.name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                          {Admin_orders?.ShippingAddress[language]}:
                          </span>
                          <span className="table-data d-block">
                            <p>
                            {Admin_orders?.BuildingNo[language]}:
                              {item?.pf_invoice?.pf_address?.[0]?.building_no}
                            </p>
                            <p>{item?.pf_invoice?.pf_address?.[0]?.city}</p>
                            <p>
                              {item?.pf_invoice?.pf_address?.[0]?.district},
                              {item?.pf_invoice?.pf_address?.[0]?.state}
                            </p>

                            <p>
                             {Admin_orders?.Pincode[language]}:
                              {item?.pf_invoice?.pf_address?.[0]?.pincode}
                            </p>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.Quantity[language]}:</span>
                          <span className="table-data">
                            {item?.order_details?.quantity}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.Department[language]}:</span>
                          <span className="table-data">
                            {item?.pf_invoice?.user?.full_name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">Status:</span>
                          <span className="table-data">
                            {item?.status == constants.ORDERS.OUTOFDELIVEREY ? (
                              <span className="approved-status">
                                {Admin_orders?.OutforDelivery[language]}
                              </span>
                            ) : (
                              <span className="uploaded-status">{Admin_orders?.Processing[language]}</span>
                            )}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={9}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Order Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && processed_order.length !== 0 && (
        <div className="table-footer-wrap">
          <small>
          {Admin_orders?.Showing[language]} {pageData.from} to {pageData.to} of {pageData.total} {Admin_orders?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      <OrderView2
        order_view={order_view}
        modal={modal}
        toggle={toggle}
        loading={loading} />
    </>
  );
}
