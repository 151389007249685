import * as Yup from "yup";
// const phoneRegExp = /^(?:\d{10}|\w+@\w+\.\w{2,3})$/;
const LoginValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(40, "Password must not exceed 40 characters"),
  email_phone: Yup.string()
    .required('Email / Phone is required')
    .test('email_or_phone', 'Email / Phone is invalid', (value) => {
      return validateEmail(value) || validatePhone(parseInt(value ?? '0'));
    }),
});
const validateEmail = (email: string | undefined) => {
  return Yup.string().email().isValidSync(email)
};

const validatePhone = (phone: number | undefined) => {
  return Yup.number().integer().positive().test(
    (phone:any) => {
      return (phone && phone.toString().length >= 10 && phone.toString().length <= 10) ? true : false;
    }
  ).isValidSync(phone);
};


const VendorLoginValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(40, "Password must not exceed 40 characters"),

  registarion_id: Yup.string()
    .required("Registarion Id is required")

});
export { LoginValidationSchema, VendorLoginValidationSchema };
