import { PieChart, Pie, Cell, Label } from "recharts";

interface ProgressProps {
  percentage: any;
 
}

const Progress: React.FC<ProgressProps> = ({ percentage }) => {
  const data = [
    { name: "Progress", value: percentage },
    { name: "Remaining", value: 100 - percentage },
  ];

  const colors = ["#169fdb ", "#fff"];
  return (
    <PieChart width={100} height={100}>
      <Pie
        data={data}
        dataKey="value"
        startAngle={90}
        endAngle={-270}
        innerRadius={35}
        outerRadius={40}
        paddingAngle={0}
        isAnimationActive={false}
      >
        {data?.map((entry, index) => (
          <Cell key={entry?.name} fill={colors[index]}  stroke={"#e6e6e6"}/>
        ))}
        <Label
          value={`${percentage}`}
          position="center"
          style={{ fontSize: "1rem" }}
        />
      </Pie>
    </PieChart>
  );
};

export default Progress;
