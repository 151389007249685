import React, { useEffect, useContext } from "react";
import { useState } from "react";
import {
  Button,
  Table,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Collapse,
  Badge,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../../Library/CustomApi";
import Loader from "../../../../../Components/Loader/Loader";
import constants from "../../../../../Library/Constants/Constants";
import { toast } from "react-toastify";
import { API_URL } from "../../../../../Library/ApiSettings";
import {
  DocumentTYpeUpload,
  it_turn_mark_caluction_,
  turn_over_mark_caluction,
  turn_over_mark_caluction_,
} from "../../../../../Library/Constants/helpers";
import { Context } from "../../../../../Components/Context/Context";
import { admin_empanelment } from "../../../../../Library/LanguageConversion/admin_empanelment";

export default function DocumentVerify(args: any) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const [id, setId] = useState<any>("");
  useEffect(() => {
    setId(localStorage.getItem("vendor_data"));
  }, [id]);
  const vendor_id: any = location?.state?.id || location?.state?.data || id;
  const [documents, setDocument] = useState<any>([]);
  const [start_up, setStartUp] = useState<any>([]);
  useEffect(() => {
    getDocuments(vendor_id);
  }, [vendor_id]);
  const { language, setLanguage } = useContext(Context);
  const getDocuments = (vendor_id: any) => {
    setLoading(true);
    api
      .post(
        `/get_vendor_data`,
        {
          user_id: vendor_id,
        },
        true
      )
      .then(async function ([success, response]) {
        let data = response?.vendor_details?.document?.filter((item: any) => {
          return item?.type?.id !== 28;
        });
        setDocument(data || []);
        setStartUp(
          response?.vendor_details?.advanced_registration_details[0]
            ?.vendor_type
        );
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const [comment, setComment] = useState({
    comment: "",
  });
  const handleComment = (e: any, i: any) => {
    const { name, value } = e.target;

    setComment({
      ...comment,
      [`comment` + [i]]: value,
    });
  };

  const verifyData = (id: any, status: any, type: any, i: any) => {
    api
      .post(
        `/doc_verification`,
        {
          user_id: vendor_id,
          type: type,
          doc_status: status,
          comment: comment?.comment[i],
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Verified Successfully");
          getDocuments(vendor_id);
        }

        setLoading(false);

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const download_documents = async (id: any) => {
    // documents/1602/download

    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(`${API_URL}/documents/${id}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.innerHTML = "This is a new div element.";
        a.href = url;
        a.download = "Document.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  const [mark, setMArk] = useState({
    mark: 0,
  });
  return (
    <React.Fragment>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{admin_empanelment?.sl_no[language]}</th>
              <th>{admin_empanelment?.Document_Type[language]}</th>
              {start_up == 10 ? (
                <>
                  <th></th>
                </>
              ) : (
                <>
                  <th>{admin_empanelment?.Mark[language]}</th>
                </>
              )}

              <th>{admin_empanelment?.Action[language]}</th>
              <th>{admin_empanelment?.Status[language]}</th>
            </tr>
          </thead>
          <tbody>
            {documents?.length !== 0 ? (
              <>
                {documents?.map((item: any, i: any) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td>
                          <span className="mobile-heading">
                            {admin_empanelment?.sl_no[language]}:
                          </span>
                          <span className="table-data">{i + 1}</span>
                        </td>
                        <td>{DocumentTYpeUpload(item?.type?.id)}</td>
                        <td>
                          {start_up !== 10 && (
                            <>
                              {item?.type?.id ==
                                constants?.DOCUMENTS?.HR_ANY_OFFICE &&
                                item?.meta?.any_other_company_state == "Karnataka"
                                ? "10"
                                : item?.type?.id ==
                                  constants?.DOCUMENTS?.HR_LAND
                                  ? "5"
                                  : item?.type?.id ==
                                    constants?.DOCUMENTS?.WORKORDER
                                    ? "5"
                                    : item?.type?.id ===
                                      constants?.DOCUMENTS?.TURN_OVER1
                                      ? turn_over_mark_caluction_(
                                        Number(
                                          item?.meta?.f_financial_year_amount
                                        ) +
                                        Number(
                                          item?.meta?.s_financial_year_amount
                                        ) +
                                        Number(
                                          item?.meta?.t_financial_year_amount
                                        )
                                      )
                                      // : item?.type?.id ===
                                      //   constants?.DOCUMENTS?.TURN_OVER2
                                      //   ? turn_over_mark_caluction_(
                                      //     Number(
                                      //       item?.meta?.f_financial_year_amount
                                      //     ) +
                                      //     Number(
                                      //       item?.meta?.s_financial_year_amount
                                      //     ) +
                                      //     Number(
                                      //       item?.meta?.t_financial_year_amount
                                      //     )
                                      //   )
                                      //   : item?.type?.id ===
                                      //     constants?.DOCUMENTS?.TURN_OVER3
                                      //     ? turn_over_mark_caluction_(
                                      //       Number(
                                      //         item?.meta?.f_financial_year_amount
                                      //       ) +
                                      //       Number(
                                      //         item?.meta?.s_financial_year_amount
                                      //       ) +
                                      //       Number(
                                      //         item?.meta?.t_financial_year_amount
                                      //       )
                                      //     )
                                          : item?.type?.id ==
                                            constants?.DOCUMENTS?.HR_MANPOWER
                                            ? "10"
                                            : item?.type?.id == constants?.DOCUMENTS?.INCORP
                                              ? "5"
                                              : item?.type?.id == constants?.DOCUMENTS?.OEM
                                                ? "5"
                                                : item?.type?.id ==
                                                  constants?.DOCUMENTS?.IT_TURN_OVER_ONE
                                                  ? it_turn_mark_caluction_(
                                                    Number(item?.meta?.f_it_turn_over_amount) +
                                                    Number(
                                                      item?.meta?.s_it_turn_over_amount
                                                    ) +
                                                    Number(item?.meta?.t_it_turn_over_amount)
                                                  )
                                                  : null}
                            </>
                          )}
                        </td>{" "}
                        <td>
                          <div className="action-flex-btn download-icon">
                            <a
                              // href="#"
                              // target="_blank"
                              onClick={() => {
                                download_documents(item?.id);
                              }}
                              rel="noreferrer"
                              className="rounded-tag"
                            >
                              <i
                                title="Download & View"
                                className="icons bi bi-download lg"
                              ></i>
                            </a>
                            {item?.status?.id ==
                              constants.BASIC_VERIFY.APPROVED ? (
                              <span title="Approved">
                                <Input
                                  type="checkbox"
                                  disabled={true}
                                  checked={true}
                                ></Input>
                              </span>
                            ) : item?.status?.id ==
                              constants.BASIC_VERIFY.REJECTED ? (
                              <span title="Approve">
                                <Input
                                  type="checkbox"
                                  disabled={true}
                                  checked={false}
                                ></Input>
                              </span>
                            ) : item?.status?.id ==
                              constants.BASIC_VERIFY.REUPLOAD ? (
                              <span title="Approve">
                                <Input
                                  type="checkbox"
                                  onClick={() => {
                                    verifyData(vendor_id, 2, item?.type?.id, i);
                                  }}
                                ></Input>
                              </span>
                            ) : (
                              <span title="Approve">
                                <Input
                                  type="checkbox"
                                  onClick={() => {
                                    verifyData(vendor_id, 2, item?.type?.id, i);
                                  }}
                                ></Input>
                              </span>
                            )}
                            {item?.status?.id ==
                              constants.BASIC_VERIFY.REJECTED ? (
                              <>
                                <a className="refuse-btn active">
                                  <i
                                    title="Rejected"
                                    className="icons icons-reject-white"
                                  ></i>
                                </a>
                              </>
                            ) : item?.status?.id ==
                              constants.BASIC_VERIFY.REUPLOAD ? (
                              <Link
                                to="#"
                                className="refuse-btn"
                                onClick={() => {
                                  verifyData(vendor_id, 3, item?.type?.id, i);
                                }}
                              >
                                <i className="icons icons-reject"></i>
                              </Link>
                            ) : (
                              <>
                                <Link
                                  to="#"
                                  className="refuse-btn"
                                  onClick={() => {
                                    verifyData(vendor_id, 3, item?.type?.id, i);
                                  }}
                                >
                                  <i className="icons icons-reject"></i>
                                </Link>
                              </>
                            )}
                            {/* <Input
                              type="textarea"
                              rows="1"
                              name="comment"
                              //disabled={true}
                              placeholder="Enter Comment"
                              className="comment-text"
                              onChange={(e) => handleComment(e, i)}
                              value={comment?.comment[i]}
                            ></Input> */}
                          </div>
                        </td>
                        <td>
                          {item?.status?.name == "APPROVED" ? (
                            <Badge color="success">
                              {admin_empanelment?.Approved[language]}
                            </Badge>
                          ) : item.status?.name == "REJECTED" ? (
                            <Badge color="danger">
                              {admin_empanelment?.Rejected[language]}
                            </Badge>
                          ) : item.status?.name == "REUPLOAD" ? (
                            <Badge color="info">
                              {admin_empanelment?.Reuploaded[language]}
                            </Badge>
                          ) : (
                            <Badge color="warning">
                              {admin_empanelment?.pending[language]}
                            </Badge>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={7} className="text-center p-5">
                  {loading ? (
                    <div className="text-center py-5">
                      {" "}
                      <Loader />{" "}
                    </div>
                  ) : (
                    "No data added"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
}
