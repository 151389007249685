import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import React, { useContext } from "react";
import { ErrorMessage } from "formik";
import TextError from "../../../../../Components/Errors/TextError";
import constants from "../../../../../Library/Constants/Constants";
import ModalContainer from "../../../../../Components/Modal/ModalContainer";
import { Context } from '../../../../../Components/Context/Context';
import { admin_empanelment } from "../../../../../Library/LanguageConversion/admin_empanelment";

interface propType {
  showVerifyVendor: boolean;
  setShowVerifyVendor: Function;
  handleSubmit: any;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  resetForm: Function;
  get_vendor: any;
  setStatus: any;
}

const VerifyVendorModal = (props: propType) => {
  const { language, setLanguage } = useContext(Context);
  console.log(props?.get_vendor, "get_vendor")
  return (
    <ModalContainer
      open={props.showVerifyVendor}
      setOpen={props.setShowVerifyVendor}
      header='Verify Vendor'
    >
      <div className="add-vendor-outer">
        <div className="vendor-details text-center mb-4">
          <div className="circle-icon mb-2">
            <img
              src="images/Addvendorimg.svg"
              alt="img"
              className="d-block mx-auto"
            />
          </div>
          {/* <h5 className="text-center">Vendor 1</h5>
            <p className="mb-0">
              Lorem Ipsum is simply dummy text of the printing{" "}
            </p>
            <p className="mb-0">12/12/2022-22/01/2023</p> */}
        </div>
        <Form className="form-wrap" onSubmit={props.handleSubmit}>
          <Row>
            {/* <Col sm={6}>
                <FormGroup>
                  <Input
                    name="vendor"
                    type="select"
                    value={props.get_vendor.vendor}
                    onChange={props.handleChange}
                    disabled={true}
                  >
                    <option>{props?.get_vendor?.vendor_type == constants.VENDOR_TYPE.MANUFATURE
                          ? "Manufacturer"
                          : props?.get_vendor?.vendor_type ==
                            constants.VENDOR_TYPE.DISTRIBUTER
                          ? "Distributor"
                          : props?.get_vendor?.vendor_type == constants.VENDOR_TYPE.IMPORTER
                          ? "Importer"
                          : props?.get_vendor?.vendor_type == constants.VENDOR_TYPE.MARKETER
                          ? "Marketer"
                          : null}</option>
                    
                  </Input>
                  <ErrorMessage name="vendor" component={TextError} />
                </FormGroup>
              </Col> */}
            {/* <Col sm={6}>
                <FormGroup>
                  <Input
                    id=""
                    name="gst_class"
                    type="select"
                    value={props.values.gst_class}
                    onChange={props.handleChange}
                    disabled={true}
                  >
                    <option disabled>GST Class</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                  <ErrorMessage name="gst_class" component={TextError} />
                </FormGroup>
              </Col> */}

            <Col md={6} sm={6}>
              <FormGroup>
                <Label for="">{admin_empanelment?.Pan_Number?.[language]}</Label>
                <Input
                  name="pan_number"
                  type="text"
                  value={props?.get_vendor?.pan_number}
                  onChange={props.handleChange}
                  className="form-control"
                  disabled={true}
                />
                <ErrorMessage name="pan_number" component={TextError} />
              </FormGroup>
            </Col>
            <Col md={6} sm={6}>
              <FormGroup>
                <Label for="">{admin_empanelment?.GST_Number[language]}</Label>
                <Input
                  name="gst_number"
                  type="text"
                  value={props?.get_vendor?.gst_number}
                  onChange={props.handleChange}
                  className="form-control"
                  disabled={true}
                />
                <ErrorMessage name="gst_number" component={TextError} />
              </FormGroup>
            </Col>
            <Col md={6} sm={6}>
              <FormGroup>
                <Label for="">{admin_empanelment?.Company_Name?.[language]}</Label>
                <Input
                  name="company_name"
                  type="text"
                  value={props?.get_vendor?.company_name}
                  onChange={props.handleChange}
                  className="form-control"
                  disabled={true}
                />
                <ErrorMessage name="company_name" component={TextError} />
              </FormGroup>
            </Col>
            <Col md={6} sm={6}>
              <FormGroup>
                <Label>{admin_empanelment?.Company_Email?.[language]}</Label>
                <Input
                  name="company_email"
                  type="email"
                  value={props?.get_vendor?.company_email}
                  onChange={props.handleChange}
                  className="form-control"
                  disabled={true}
                />
                <ErrorMessage name="company_email" component={TextError} />
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <FormGroup>
                <Label for="">{admin_empanelment?.Company_Incorporation_Date?.[language]}</Label>
                <Input
                  name="company_incorporate_date"
                  type="text"
                  value={props?.get_vendor?.pan_number}
                  onChange={props.handleChange}
                  className="form-control"
                  disabled={true}
                />
                <ErrorMessage
                  name="company_incorporate_date"
                  component={TextError}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <FormGroup>
                <Label for="">{admin_empanelment?.Contact_Person_Name?.[language]}</Label>
                <Input
                  name="person_name"
                  type="text"
                  value={props?.get_vendor?.company_name}
                  onChange={props.handleChange}
                  className="form-control"
                  disabled={true}
                />
                <ErrorMessage name="person_name" component={TextError} />
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <FormGroup>
                <Label for="">{admin_empanelment?.Contact_Person_Number?.[language]}</Label>
                <InputGroup>
                  <InputGroupText className="bg-transparent">
                    +91
                  </InputGroupText>
                  <Input
                    name="contact_number"
                    type="text"
                    value={props?.get_vendor?.user?.phone}
                    onChange={props.handleChange}
                    className="border-start-0"
                    disabled={true}
                  />
                </InputGroup>
                <ErrorMessage name="contact_number" component={TextError} />
              </FormGroup>
            </Col>
            <Row>
              {/* <Col md={12}>
                  <FormGroup className={"d-flex align-items-center gap-2 mb-5"}>
                    <Input type="checkbox" />
                    <Label check>
                      Lorem Ipsum is simply dummy text of the printing
                    </Label>
                  </FormGroup>
                </Col> */}
              <Row>
                <Col md={6}>
                  <FormGroup className={"text-center"}>
                    <Button
                      color="primary"
                      className={"px-5"}
                      onClick={() => {
                        props?.setStatus(1);
                        props?.handleSubmit();
                      }}
                    >
                     {admin_empanelment?.verify?.[language]}
                    </Button>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className={"text-center"}>
                    <Button
                      color="danger"
                      className={"px-5"}
                      onClick={() => {
                        props?.setStatus(2);
                        props?.handleSubmit();
                      }}
                    >
                      {admin_empanelment?.reject?.[language]}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Row>
          </Row>
        </Form>
      </div>
    </ModalContainer>
  );
};

export default VerifyVendorModal;
