import React, { useContext } from "react";
import { useState, useMemo, useEffect } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import moment from "moment";
import DatePicker from 'react-date-picker'
import { Context } from "../../../../Components/Context/Context";
import { Admin_RateContract } from "../../../../Library/LanguageConversion/Admin_RateContract";

function UploadRate(props: any) {
  const { language, setLanguage } = useContext(Context)
  const navigate = useNavigate();
  const { rate_id, setRateId } = useContext(Context);
  const [upload_rate, setUploadRate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowing, setIsShowing] = useState(false);
  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any>();
  const [reset, setReset] = useState(false);
  const [scrolling, setScrolling] = useState<boolean>(false)

  const [from, setFrom] = useState("");
  const [date, setDate] = useState<any>({
    validfrom: "",
    validto: "",
  });

  const handleDate = (e: any, field_name: any = null) => {
    setDate({
      ...date,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
  };
  //=======================reset==============================
  // const handleInputChange = (e) => {
  //   setSearch(e.target.value);
  // };
  //============================================================ clear filter =====================================================

  const clearAll = (e: any) => {
    setDate({
      ...date,
      validfrom: "",
      validto: "",
    });
    setReset(true);
    setSearch("");
  };
  //   const clearAll = (e: any) => {
  //   e.preventDefault(); // Prevent the default link behavior

  //   // Reset the state values
  //   setDate({
  //     validfrom: "",
  //     validto: "",
  //   });
  //   setSearch("");
  // };



  //============================================================ function call =====================================================

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getUploadRate();
  }, [currentPage, date]);

  //========================================================= Search ==================================================================
  const handleSearch = (event: any) => {
    setCurrentPage(1)
    setSearch(event.target.value);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getUploadRate();
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // ======================================================== Get Upload Rate ========================================================

  const getUploadRate = () => {
    let v_from: any = moment(date?.validfrom).format("YYYY-MM-DD") || "";
    let v_to: any = moment(date?.validto).format("YYYY-MM-DD");

    if (v_from !== "Invalid date" && v_to !== "Invalid date") {
      setIsLoading(true);
      api
        .get(
          `/tender?search_keyword=${search}&page=${currentPage}&valid_from=${v_from}&valid_to=${v_to}`,
          true
        )
        .then(async function ([success, response]) {
          setUploadRate(response?.data || []);
          setFrom(response?.meta?.from);
          setPageData(response?.meta);
          setIsLoading(false);
          return response;
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      api
        .get(`/tender?search_keyword=${search}&page=${currentPage}`, true)
        .then(async function ([success, response]) {
          setUploadRate(response?.data || []);
          setFrom(response?.meta?.from);
          setPageData(response?.meta);
          setIsLoading(false);
          return response;
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  // ========================================================== Get Category ==========================================================

  const getCategory = () => {
    api
      .get(`/product/categories`, true)
      .then(async function ([success, response]) {
        let datas: any = [];
        response?.data?.map((elem: any) => {
          datas.push({ value: elem?.id, label: elem?.name });
        });
        setCategory(datas || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ============================================== Navigate to Rate contract  ======================================================

  const go_url = (tender_id: string) => {
    localStorage.setItem("Tend_id", tender_id);
    navigate("/rate-contract", {
      state: {
        id: tender_id,
        tend: tender_id,
      },
    });
  };

  const handleScroll = () => {
    setScrolling(true)
    setTimeout(() => setScrolling(false), 800);
  }

  // =========================================================== View ================================================================
  return (
    <>
      <Row className="gy-4 gx-5 add-tendor-content-wrap-outer">
        <Col lg={12}>
          <div className="search-widget-outer">
            <Form role="search" className="widget-inner">
              <div className="valid-from">
                <Label>{Admin_RateContract?.Valid_From[language]}</Label>
                <DatePicker
                  name="validfrom"
                  className="form-control"
                  onChange={(e: any) => {
                    setCurrentPage(1)
                    setDate({
                      ...date,
                      validfrom: e,
                    });
                  }}
                  format={"dd-MM-y"}
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  value={date?.validfrom} />
              </div>
              <div className="valid-to">
                <Label>{Admin_RateContract?.Valid_To[language]}</Label>

                <DatePicker
                  name="validto"
                  className="form-control"
                  onChange={(e: any) => {
                    setCurrentPage(1)
                    setDate({
                      ...date,
                      validto: e,
                    });
                  }}
                  format={"dd-MM-y"}
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  value={date?.validto}
                  disabled={date?.validfrom ? false : true}
                  minDate={date?.validfrom}
                />
              </div>

              <div className="search-btn-wrap">
                <InputGroup className="search-group">
                  <InputGroupText title="Search" className="search-icon" id="search">
                    <i className="fa-sharp fa-solid fa-magnifying-glass" />
                  </InputGroupText>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder= {Admin_RateContract?.TenderIDCategory[language]}
                    aria-label="Username"
                    aria-describedby="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyPress={(event) => {
                      var key = event.keyCode || event.which;
                      if (key === 13) {
                        event.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
              </div>
            </Form>
          </div>
          <div className="reset-all">
            {(date.validfrom !== '' || date.validto !== '') &&
              <i title="Reset All"><a href="#" onClick={clearAll}>{Admin_RateContract?.Reset[language]}</a></i>}
          </div>

          <div className="table-wrap responsive-table" onScroll={handleScroll}>
            <Table className="w-100 table-striped">
              <thead> 
                <tr>
                  <th className="">{Admin_RateContract?.Sl_No[language]}</th>
                  <th className="">{Admin_RateContract?.Tender_ID[language]}</th>
                  <th className="">{Admin_RateContract?.Categories[language]}</th>
                  <th className="">{Admin_RateContract?.Version[language]}</th>
                  <th className="">{Admin_RateContract?.Status[language]}</th>
                  <th className="">{Admin_RateContract?.Valid_From[language]}</th>
                  <th className="">{Admin_RateContract?.Valid_To[language]}</th>
                  <th className="">{Admin_RateContract?.Action[language]}</th>
                </tr>
              </thead>
              <tbody>
                {upload_rate?.length !== 0 ? (
                  <>
                    {upload_rate?.map((item: any, i: number) => {
                      return (
                        <React.Fragment key={i}>
                          <tr className={`${item?.status == constants?.TENDER.APPROVED ? " " : 'inactive-row'}`} >
                            <td className="">
                              <span className="mobile-heading">{Admin_RateContract?.Sl_No[language]}:</span>
                              <span className="table-data">{from + i}</span>
                            </td>
                            <td className="">
                              <span className="mobile-heading">{Admin_RateContract?.Tender_ID[language]}:</span>
                              <span className="table-data">
                                {item?.tender_id}
                              </span>
                            </td>
                            <td className="">
                              <span className="mobile-heading">
                                {Admin_RateContract?.Categories[language]}:
                              </span>
                              <span className="table-data">
                                {item?.tendor_category?.length !== 0 ? (
                                  <>
                                    {item?.tendor_category.map(
                                      (items: any, i: number) => {
                                        return (
                                          <React.Fragment key={i}>
                                            {i ? ", " : ""}

                                            {items?.name}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <label>---</label>
                                )}
                              </span>
                            </td>
                            <td className="">
                              <span className="mobile-heading">{Admin_RateContract?.Version[language]}:</span>
                              <span className="table-data">
                                {item?.version}
                              </span>
                            </td>
                            <td className="">
                              <span className="mobile-heading">{Admin_RateContract?.Status[language]}:</span>
                              <span className="table-data">
                                {item?.status == constants.TENDER.APPROVED ? (
                                  <span className="approved-status">{Admin_RateContract?.Active[language]}</span>
                                ) : (
                                  <span className="inactive-status">{Admin_RateContract?.Inactive[language]}</span>
                                )}
                              </span>
                            </td>
                            <td className="">
                              <span className="mobile-heading">
                                {Admin_RateContract?.Valid_From[language]}:
                              </span>
                              <span className="table-data">
                                {moment(item?.valid_from).format("DD-MM-YYYY")}
                              </span>
                            </td>
                            <td className="">
                              <span className="mobile-heading">{Admin_RateContract?.Valid_To[language]}:</span>
                              <span className="table-data">
                                {moment(item?.valid_to).format("DD-MM-YYYY")}
                              </span>
                            </td>
                            <td
                              className={`${scrolling ? 'scroll-shadow' : 'no-shadow'}`}
                              onClick={() => {
                                go_url(item?.id);
                                setRateId(item?.id)
                              }}
                            >
                              <span className="mobile-heading">{Admin_RateContract?.Action[language]}:</span>
                              <span className="table-data">
                                <Link to="#"
                                  title="View Rate Contract Details">
                                  <i className="bi bi-eye lg"></i>
                                </Link>
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td
                      className="text-center px-2 py-5 empty-text"
                      colSpan={8}
                    >
                      {isLoading ? (
                        <div className="d-flex justify-content-center">
                          <Loader />
                        </div>
                      ) : (
                        Admin_RateContract?.No_Tender_Found[language]
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {pageData && upload_rate?.length !== 0 && (
            <div className="table-footer-wrap">
              <small>
                Showing {pageData.from} to {pageData.to} of {pageData.total}{" "}
                entries
              </small>
              <div className="pagination">
                <CustomPagination
                  totalItems={pageData?.total}
                  itemsPerPage={pageData?.per_page}
                  activePage={pageData.current_page}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default UploadRate;