import * as Yup from "yup";

const AccountValidation = Yup.object().shape({
  bank_name: Yup.string().required("Field is required").nullable(),
  account_holder_name: Yup.string().required("Field is required").nullable(),
  branch_name: Yup.string().required("Field is required").nullable(),
  // account_name: Yup.string().required("Field is required"),
  ifsc_code: Yup.string().required("Field is required").nullable(),
  account_number: Yup.number().required("Field is required").nullable(),
  retype_account_number: Yup.number().required("Field is required").nullable(),
  //cheque_number: Yup.number().required("Field is required").nullable(),
});

export { AccountValidation }