export const Vendor_profileUpdation: any = {
  Pan_Card: {
    en: "Pan Card ",
    ka: "ಪ್ಯಾನ್ ಕಾರ್ಡ್",
  },

  Choose_image: {
    en: "Choose image",
    ka: "ಚಿತ್ರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  },

  Make_sure: {
    en: "Make sure entered details are correct",
    ka: "ನಮೂದಾಗಿ ನೀಡಿದ ವಿವರಗಳು ಸರಿಯಾಗಿವೆಯೆಂದು ಖಚಿತಪಡಿಸಿ",
  },

  Submit: {
    en: "Submit",
    ka: "ಸಲ್ಲಿಸಿ",
  },
  Document_Type: {
    en: "Document Type",
    ka: "ದಾಖಲೆಯ ಪ್ರಕಾರ",
  },
  Document: {
    en: "Document",
    ka: "ಡಾಕ್ಯುಮೆಂಟ್",
  },
  View: {
    en: "View",
    ka: "ವೀಕ್ಷಿಸು",
  },
  Status: {
    en: "Status",
    ka: "ಸ್ಥಿತಿ",
  },
  Action: {
    en: "Action",
    ka: "ಕ್ರಿಯೆ",
  },
  Approved: {
    en: "Approved",
    ka: "ಅನುಮೋದಿಸಲಾಗಿದೆ",
  },
  Rejected: {
    en: "Rejected",
    ka: "ತಿರಸ್ಕರಿಸಿದ",
  },
  Reuploaded: {
    en: "Reuploaded",
    ka: "ಮರುಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
  },
  Uploaded: {
    en: "Uploaded",
    ka: "ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
  },
  Name: {
    en: "Name",
    ka: "ಹೆಸರು",
  },
  // AddressAddModel

  Building_Number: {
    en: "Building Number",
    ka: "ಕಟ್ಟಡ ಸಂಖ್ಯೆ",
  },

  Landmark: {
    en: "Landmark",
    ka: "ಸೂಚಕ ಸ್ಥಳ",
  },

  Pincode: {
    en: "Pincode",
    ka: "ಪಿನ್‌ಕೋಡ್",
  },

  Select: {
    en: "Select",
    ka: "ಆಯ್ಕೆ ಮಾಡಿ",
  },

  City: {
    en: "City",
    ka: "ನಗರ",
  },

  District: {
    en: "District",
    ka: "ಜಿಲ್ಲೆ",
  },

  State: {
    en: "State",
    ka: "ರಾಜ್ಯ",
  },

  Country: {
    en: "Country",
    ka: "ದೇಶ",
  },

  // ProfileUpdation-AdvancedReg
  Pan_Number: {
    en: "Pan Number",
    ka: "ಪ್ಯಾನ್ ಸಂಖ್ಯೆ",
  },
  GST_Number: {
    en: "GST Number",
    ka: "ಜಿಎಸ್‌ಟಿ ಸಂಖ್ಯೆ",
  },
  Vendor_Type: {
    en: "Vendor Type",
    ka: "ವೇಂಡರ್ ಪ್ರಕಾರ",
  },
  GST_CLass: {
    en: "GST_CLass",
    ka: "ಜಿಎಸ್ಟಿ_ಕ್ಲಾಸ್",
  },

  Is_your_company: {
    en: "Is your company MSME(Yes/No)",
    ka: "ನಿಮ್ಮ ಕಂಪನಿ ಎಂಎಸ್ಎಂಈ ಆಗಿದೆಯೇ? (ಹೌದು / ಇಲ್ಲ)",
  },

  MSME_Valid_upto: {
    en: "MSME Valid  Fom",
    ka: " ಎಂಎಸ್ಎಂಈ ಮುಗಿದ ವರೆಗೆ",
  },

  UDYAM: {
    en: "UDYAM (Yes / No)",
    ka: "ಉದ್ಯಮ್ (ಹೌದು / ಇಲ್ಲ)",
  },

  Are_you_SC_ST: {
    en: "Are you SC/ST entrepreneur (Yes / No)",
    ka: "ನೀವು ಶೇಡ್ಯೂಲ್ಡ್ ಕ್ಯಾಸ್ಟ್/ಶೇಡ್ಯೂಲ್ಡ್ ಜನಜಾತಿ ಉದ್ಯಮಿಯಾಗಿದ್ದೀರಿಯೇ (ಹೌದು/ಇಲ್ಲ)",
  },
  Are_you_woman: {
    en: "Are you woman entrepreneur (Yes/No)",
    ka: "ನೀವು ಒಬ್ಬ ಮಹಿಳೆ ಉದ್ಯಮಿಯಾಗಿದ್ದೀರಿಯೇ (ಹೌದು/ಇಲ್ಲ)",
  },

  Company_information: {
    en: "Company information",
    ka: "ಕಂಪನಿ ಮಾಹಿತಿ",
  },

  Company_Name: {
    en: "Company Name",
    ka: "ಕಂಪನಿಯ ಹೆಸರು",
  },

  Company_Email: {
    en: "Company Email",
    ka: "ಕಂಪನಿ ಇಮೇಲ್",
  },

  Contact_Number: {
    en: "Contact Number",
    ka: "ಸಂಪರ್ಕ ಸಂಖ್ಯೆ",
  },
  Company_Number: {
    en: "Company Number",
    ka: "ಸಂಪರ್ಕ ಸಂಖ್ಯೆ",
  },

  Company_incorporation: {
    en: "Company incorporation date",
    ka: "ಕಂಪನಿ ಸೇರ್ಪಡೆ ದಿನಾಂಕ ",
  },

  Company_Registered: {
    en: "Company Registered Address",
    ka: "ಕಂಪನಿಯ ನೋಂದಣಿಯ ವಿಳಾಸ",
  },

  Add: {
    en: "Add",
    ka: "ಸೇರಿಸಿ",
  },

  Company_Communication: {
    en: "Company Communication Address",
    ka: "ಕಂಪನಿ ಸಂಪರ್ಕ ವಿಳಾಸ ",
  },

  Point_of_Contact: {
    en: "Point of Contact Information",
    ka: "ಸಂಪರ್ಕ ಬಿಂದು ಮಾಹಿತಿ",
  },

  Contact_Name: {
    en: "Contact Name",
    ka: "ಸಂಪರ್ಕ ಹೆಸರು",
  },

  Contact_Email_ID: {
    en: "Contact Email ID",
    ka: "ಸಂಪರ್ಕ ಇಮೇಲ್ ಐಡಿ",
  },

  Designation: {
    en: "Designation",
    ka: "ಹುದ್ದೆ",
  },

  // AnyOtherCompanyDocument

  Any_Other_Office: {
    en: "Any Other Office Proof",
    ka: "ಬೇರೆ ಯಾವುದೇ ಕಚೇರಿ ಪುರವಠಿ",
  },

  // BasicDetails
  Full_Name: {
    en: "Full Name",
    ka: "ಪೂರ್ಣ ಹೆಸರು",
  },

  Phone_Number: {
    en: "Phone Number",
    ka: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
  },

  Email: {
    en: "Email",
    ka: "ಇಮೇಲ್",
  },

  Send_Notifications: {
    en: "Send Notifications on email",
    ka: "ಇಮೇಲ್‌ನಲ್ಲಿ ಅಧಿಸೂಚನೆಗಳನ್ನು ಕಳುಹಿಸಿ ",
  },

  Resend_OTP: {
    en: "Resend OTP",
    ka: "ಮರುಕಳಿಸಿ",
  },

  Enter_OTP: {
    en: "Enter OTP",
    ka: "ನಮೂದಿಸಿ",
  },

  Confirm_Password: {
    en: "Confirm Password",
    ka: "ಪಾಸ್‌ವರ್ಡ್‌ನನ್ನು ದೃಢೀಕರಿಸಿ",
  },

  Already_have: {
    en: "Already have an account ?",
    ka: "ನೀವು ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರಾ?",
  },

  Login: {
    en: "Login",
    ka: "ಲಾಗಿನ್",
  },

  Register: {
    en: "Register",
    ka: "ನೋಂದಾಯಿಸು",
  },

  Black_List: {
    en: "Black List",
    ka: "",
  },

  BlackList_Documents: {
    en: "BlackList Documents",
    ka: "ಕಪಟಿ ಪಟ್ಟಿ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳು",
  },

  Choose_Documents: {
    en: "Choose Documents",
    ka: "ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  },

  No_Case_Pending: {
    en: "No Case Pending Declaration Letter",
    ka: "ಯಾವುದೇ ಕೇಸು ಬಾಕಿ ಇಲ್ಲದ ಘೋಷಣೆ ಪತ್ರ",
  },

  // DocUpload
  Please_be_advised: {
    en: "Please be advised that providing false or fraudulent Information is strictly prohibited and may result in severe consequences. It is crucial to ensure that all details you provide are accurate, truthful, and reliable. Engaging in any form of fraudulent activity can lead to legal actions, penalties, and potential criminal charges. Additionally, providing misleading information can negatively impact not only your own reputation but also the trust and integrity of the systems and organizations involved. Please exercise caution and ethical responsibility when sharing any information.",
    ka: "ದಯವಿಟ್ಟು ಗಮನಿಸಿ: ತಪ್ಪು ಅಥವಾ ಕಪಟ ಮಾಹಿತಿ ಒದಗಿಸುವುದು ಕಡೆಗಣಿಸಲ್ಪಡುವಂಥ ಪರಿಣಾಮಗಳನ್ನು ತರುವಂಥ ಗಮನಿಸಬೇಕಾದ ಅಂಶ. ನೀಡುವ ಎಲ್ಲಾ ವಿವರಗಳೂ ನಿಜವಾಗಿಯೂ ನಿಷ್ಪ್ರಯೋಜಕವಾಗಿ ಮತ್ತು ವಿಶ್ವಾಸಾರ್ಹವಾಗಿರುವುದನ್ನು ಖಚಿತಪಡಿಸುವುದು ಮುಖ್ಯ. ಕಪಟಗೊಳಿಸುವ ಯಾವುದೇ ರೀತಿಯ ಚಟುವಟಿಕೆ ಕಾನೂನುಗಳ ಕ್ರಮ, ದಂಡನೆಗಳ ಮತ್ತು ಸಂಭವಿಸಬಹುದಾದ ನ್ಯಾಯಿಕ ಕ್ರಮಗಳಿಗೆ ಕಾರಣವಾಗಬಹುದು. ಹೆಚ್ಚಿನವರಿಗೆ ಮಾರುಕಟ್ಟೆಗಳ ಪ್ರಮಾಣವೇ ಇಲ್ಲದಿದ್ದರೂ, ಭ್ರಾಂತಿಯುಳ್ಳ ಮಾಹಿತಿ ನೀಡುವುದು ನಿಮ್ಮ ನೆಲವನ್ನು ಮಾರುವುದಾಗಿದೆ. ಇದು ನೀವೇನೇ ನಿಮ್ಮ ಪ್ರತ್ಯುತ್ಪನ್ನತೆಗೂ ಹೊಂದಿದ್ದು ಅಲ್ಲದೆ, ಸಂಗಡಿಗರ ಮತ್ತು ಸಂಸ್ಥೆಗಳ ವಿಶ್ವಾಸ ಮತ್ತು ಪ್ರಮಾಣ ವ್ಯವಸ್ಥೆಗೂ ಹಾನಿಯನ್ನು ತರಬಹುದು. ಯಾವುದೇ ಮಾಹಿತಿಯನ್ನು ಹಂಚುವಾಗ ಜಾಗರೂಕತೆ ಮತ್ತು ನೈತಿಕ ಮುಂದುವರಿಕೆಯನ್ನು ಪಾಲಿಸಿಕೊಳ್ಳುವುದು ಅತ್ಯಂತ ಮುಖ್ಯವಾಗಿದೆ",
  },

  Make_sure_entered: {
    en: "Make sure entered details are correct",
    ka: " ನಮೂದಿಸಿದ ವಿವರಗಳು ಸರಿಯಾಗಿವೆಯೆಂದು ಖಚಿತಪಡಿಸಿ ",
  },

  //emdDetails////////////////////////////////////////////////////////

  For_A_Category: {
    en: "For A Category",
    ka: " ವರ್ಗ A ಗೆ",
  },

  Above_20_Crores_NRLA: {
    en: "Above 20 Crores — NRLA",
    ka: "20 ಕೋಟಿಗಿಂತ ಮೇಲೆ — NRLA",
  },

  Above_20_Crores: {
    en: "Above 20 Crores",
    ka: " 20 ಕೋಟಿಗಿಂತ ಮೇಲೆ",
  },

  Transaction_ID: {
    en: "Transaction ID",
    ka: "ಲಂಬಿತ ಗ್ರಹಿಕೆ ID",
  },

  Pay: {
    en: "Pay",
    ka: "ಪಾವತಿ",
  },

  History_details: {
    en: "History details",
    ka: "ಇತಿಹಾಸ ವಿವರಗಳು",
  },

  ten_20_Crores: {
    en: "10-20 Crores",
    ka: "10-20 ಕೋಟಿಗಳವರೆಗೆ",
  },

  upto_10_Crores: {
    en: "upto 10 Crores",
    ka: "10 ಕೋಟಿಗಿಂತ ಕೆಳಗೆ",
  },

  Upto_10_Crores_NRLA: {
    en: "Upto 10 Crores — NRLA",
    ka: "10 ಕೋಟಿಗಿಂತ ಕೆಳಗೆ — NRLA",
  },

  ten_20_Crores_NRLA: {
    en: "10-20 Crores — NRLA",
    ka: "10-20 ಕೋಟಿಗಳವರೆಗೆ — NRLA",
  },

  For_C_Category: {
    en: "For C Category",
    ka: " ವರ್ಗ C ಗೆ",
  },

  For_B_Category: {
    en: "For B Category",
    ka: " ವರ್ಗ B ಗೆ",
  },
  for:{
    en:"For",
    ka:"ಫಾರ್"
  },
  Category:{
    en: "Category",
    ka: "ವರ್ಗ"
},
Start_Date:{
  en: "Start Date",
  ka: " ಪ್ರಾರಂಭ ದಿನಾಂಕ"
},
  
  //empanelment documents
  Empanel_Documents: {
    en: "Empanel Documents",
    ka: "ಇಂಪಾನಲ್ ಕಾಗದಗಳು",
  },

  Empanel_Certificate: {
    en: "Empanel Certificate",
    ka: "ಇಂಪಾನಲ್ ಪ್ರಮಾಣಪತ್ರ",
  },

  Empanelment_Date: {
    en: "Empanelment Date",
    ka: "ಇಂಪಾನಲ್ ದಿನಾಂಕ",
  },

  Empanelment_Expiry_Date: {
    en: "Empanelment Expiry Date",
    ka: "ಇಂಪಾನಲ್ ಅವಧಿ ಮುಗಿಯುವ ದಿನಾಂಕ",
  },
  //emplanment details

  Sl_No: {
    en: "Sl No",
    ka: "ಕ್ರಮಸಂಖ್ಯೆ",
  },

  Emp_category: {
    en: "Emp category",
    ka: "ನೌಕರಿ ವರ್ಗ",
  },

  Valid_from: {
    en: "Valid from",
    ka: "ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ",
  },

  Valid_upto: {
    en: "Valid upto",
    ka: "ಗೆ ಮಾನ್ಯವಾಗಿದೆ",
  },

  No_Data_Found: {
    en: " No Data Found",
    ka: "ಯಾವುದೇ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ",
  },

  Manpower_Documents: {
    en: "Manpower Documents",
    ka: " ಮನಶ್ಶಕ್ತಿ ಕಾಗದಗಳು",
  },

  Date_of_in_Document: {
    en: "Date of in Document",
    ka: "ದಾಖಲೆಯ ದಿನಾಂಕ",
  },

  No_of_Employees_Manpower: {
    en: " No of Employees(Manpower)",
    ka: "ನೌಕರರ ಸಂಖ್ಯೆ(ಮನಶ್ಶಕ್ತಿ)",
  },

  You_are_in_Category: {
    en: "You are in Category",
    ka: "ನೀವು ವರ್ಗದಲ್ಲಿದ್ದೀರಿ",
  },

  Extent: {
    en: "Extent",
    ka: "ವ್ಯಾಪ್ತಿ",
  },

  Value: {
    en: "Value",
    ka: "ಮೌಲ್ಯ",
  },

  Mark: {
    en: "Mark",
    ka: "ಗುರುತಿಸು",
  },

  //IT Turnover
  IT_Turnover: {
    en: "IT Turnover",
    ka: "ಐಟಿ ವಹಿವಾಟು",
  },

  First_IT_Year: {
    en: "First IT Year",
    ka: "ಮೊದಲನೇ ಐಟಿ ವರ್ಷ",
  },

  First_IT_Year_Amount: {
    en: "First IT Year Amount",
    ka: "ಮೊದಲನೇ ಐಟಿ ವರ್ಷದ ಮೊತ್ತ",
  },
  First_IT_Year_document: {
    en: "First IT Year document",
    ka: "ಮೊದಲ ಐಟಿ ವರ್ಷದ ದಾಖಲೆ"
  },

  Second_IT_Year: {
    en: "Second IT Year",
    ka: "ಎರಡನೇ ಐಟಿ ವರ್ಷ",
  },

  Second_IT_Year_Documents: {
    en: " Second IT Year Documents",
    ka: "ಎರಡನೇ ಐಟಿ ವರ್ಷದ ಕಾಗದಗಳು",
  },

  Second_IT_Year_Amount: {
    en: "Second IT Year Amount",
    ka: "ಎರಡನೇ ಐಟಿ ವರ್ಷದ ಮೊತ್ತ",
  },

  Approved_by_Chartered_Accountant_CA: {
    en: "Approved by Chartered Accountant(CA)",
    ka: "ಚಾರ್ಟರ್ಡ್ ಅಕೌಂಟಂಟ್ (ಸಿಏ)ಅನುಮೋದಿಸಲಾಗಿದೆ ",
  },

  CA_Email: {
    en: " CA Email",
    ka: "ಸಿಏ ಇಮೇಲ್",
  },

  CA_Phone_Number: {
    en: "CA Phone Number",
    ka: "ಸಿಏ ಫೋನ್ ಸಂಖ್ಯೆ",
  },
  CA_Phone: {
    en: "CA Phone",
    ka: "ಸಿಏ ಫೋನ್",
  },

  Catagory: {
    en: "Category:",
    ka: "ವರ್ಗ",
  },
  //workorderdocument
  Work_Order_Details: {
    en: "Work Order Details",
    ka: "ಕಾರ್ಯ ಆದೇಶ ವಿವರಗಳು",
  },

  Work_Order_Certificate: {
    en: "Work Order Certificate",
    ka: "ಕಾರ್ಯ ಆದೇಶ ಪ್ರಮಾಣಪತ್ರ",
  },

  No_of_Projects_Done: {
    en: "No of Projects Done",
    ka: "ನಿರ್ವಹಿಸಲಾದ ಪ್ರಾಜೆಕ್ಟ್ಗಳ ಸಂಖ್ಯೆ",
  },

  // LandDocuments

  HR_Documents: {
    en: "HR Documents",
    ka: "ಎಚ್ಆರ್ ಡಾಕ್ಯುಮೆಂಟ್ಗಳು",
  },

  Land_Document: {
    en: "Land Document",
    ka: "ಭೂಮಿ ಡಾಕ್ಯುಮೆಂಟ್ ",
  },

  Village_Col: {
    en: "Village/Col",
    ka: "ಗ್ರಾಮ/ಕಾಲೊನಿ",
  },

  Survey_No: {
    en: "Survey No",
    ka: "",
  },

  // MSMEDocuments

  Power_Of_Attorney: {
    en: "Power Of Attorney / Board Resolution",
    ka: "ಪವರ್ ಆಫ್ ಅಟಾರ್ನಿ / ಮಂಡಲ ನಿರ್ಣಯ",
  },

  MSME_Certificate: {
    en: "MSME Certificate",
    ka: "ಎಂಎಸ್ಎಂಈ ಪ್ರಮಾಣಪತ್ರ",
  },

  Udyam_Registration: {
    en: "Udyam Registration number",
    ka: "ಉದ್ಯಮ ನೋಂದಣಿ ಸಂಖ್ಯೆ",
  },

  Name_Of_Enterprises: {
    en: "Name Of Enterprises",
    ka: "ಉದ್ಯಮದ ಹೆಸರು",
  },

  Type_Of_Enterprises: {
    en: "Type Of Enterprises",
    ka: "ಉದ್ಯಮಗಳ ವಿಧ",
  },

  Major_Activity: {
    en: "Major Activity",
    ka: "ಪ್ರಮುಖ ಕ್ರಿಯೆ",
  },

  Social_Category_Of_Enterprises: {
    en: "Social Category Of Enterprises",
    ka: "ಉದ್ಯಮಗಳ ಸಾಮಾಜಿಕ ವರ್ಗ",
  },

  Office_Address: {
    en: "Office Address",
    ka: "ಕಚೇರಿ ವಿಳಾಸ",
  },

  Date_Of_UDYAM: {
    en: "Date Of UDYAM Registration",
    ka: "ಉದ್ಯಮ ನೋಂದಣಿಯ ದಿನಾಂಕ",
  },

  District_Industries: {
    en: "District Industries Center",
    ka: "ಜಿಲ್ಲಾ ಉದ್ಯಮ ಕೇಂದ್ರ",
  },

  MSME_DFO: {
    en: "MSME - DFO",
    ka: "ಎಂಎಸ್ಎಂಈ - ಜಿವಿಎಫ್ಒ",
  },

  // NotRequiredDocuments
  Not_Mandatory: {
    en: "Not Mandatory",
    ka: " ಅಗತ್ಯವಿಲ್ಲ",
  },

  ISO_Certificate: {
    en: "ISO Certificate",
    ka: "ಐ ಎಸ್ ಒ  ಪ್ರಮಾಣಪತ್ರ",
  },

  MAF_Certificate: {
    en: "MAF Certificate",
    ka: "ಎಂ ಏ ಎಫ್ ಪ್ರಮಾಣಪತ್ರ",
  },

  // OEMDocuments

  OEM_Details: {
    en: "OEM Details",
    ka: "ಒ ಇ ಎಂ ವಿವರಗಳು",
  },

  OEM_Certificate: {
    en: "OEM Certificate",
    ka: "ಒ ಇ ಎಂ ಪ್ರಮಾಣಪತ್ರ",
  },

  Comapny_Type_in_OEM: {
    en: "Company Type in OEM Certificate",
    ka: "ಒ ಇ ಎಂ ಪ್ರಮಾಣಪತ್ರದಲ್ಲಿ ಕಂಪನಿ ಪ್ರಕಾರ",
  },

  OEM_Valid_From: {
    en: "OEM Valid From",
    ka: "ಒ ಇ ಎಂ ಪ್ರಾರಂಭವಾಗುವ ದಿನಾಂಕ",
  },

  OEM_Valid_To: {
    en: "OEM Valid To",
    ka: "ಒ ಇ ಎಂ ಮುಗಿಯುವ ದಿನಾಂಕ",
  },

  Power_Of_Attorney_Board: {
    en: "Power Of Attorney / Board Resolution Document",
    ka: "ಆಡಳಿತ ಹಕ್ಕು / ಮಂಡಲ ನಿರ್ಣಯ ಡಾಕ್ಯುಮೆಂಟ್",
  },

  Company_Address: {
    en: "Company Address",
    ka: "ಕಂಪನಿ ವಿಳಾಸ ",
  },

  // Profiletab

  Basic_Details: {
    en: "Basic Details",
    ka: "ಮೌಲ್ಯಮಾಪನ ವಿವರಗಳು",
  },

  Advanced_Registration: {
    en: "Advanced Registration",
    ka: "ಮುನ್ನೆಲೆ ನೋಂದಾಯಿಸುವುದು",
  },

  Document_Upload: {
    en: "Document Upload",
    ka: "ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್‌ಲೋಡ್",
  },

  Non_Refundable_Loyalty: {
    en: "Non-Refundable Loyalty Amount",
    ka: "ಪರಿತ್ಯಾಗ ಮಾಡಲಾಗದ ನಿಷ್ಕರ್ಷಣ ಮೊತ್ತ",
  },

  // RequiredDocuments

  Mandatory_Documents: {
    en: "Mandatory Documents",
    ka: "ಅಗತ್ಯ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳು",
  },

  Address_Proof: {
    en: "Address Proof",
    ka: "ವಿಳಾಸ ಪುರವಠಿ",
  },

  Company_incorporation_certificate: {
    en: "Company incorporation certificate",
    ka: "ಕಂಪನಿ ಸೇರ್ಪಡೆ ಪ್ರಮಾಣಪತ್ರ",
  },

  GST_Registration: {
    en: "GST Registration",
    ka: "ಜಿಎಸ್‌ಟಿ ನೋಂದಣಿ",
  },

  Udyam_Certificate: {
    en: "Udyam Certificate",
    ka: "ಉದ್ಯಮ್ ಪ್ರಮಾಣಪತ್ರ",
  },

  // SameAddressModel

  Same_As_Registered: {
    en: "Same As Registered Address",
    ka: "ನೋಂದಾಯಿತ ವಿಳಾಸದಂತೆಯೇ",
  },

  Building_Address: {
    en: "Building  Address",
    ka: "ಕಟ್ಟಡ ವಿಳಾಸ ",
  },

  Select_Location: {
    en: "Select Location",
    ka: "ಸ್ಥಳವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  },

  // StartUpCertificates

  Startup_Certificate: {
    en: "Startup Certificate",
    ka: "ಸ್ಟಾರ್ಟಪ್ ಪ್ರಮಾಣಪತ್ರ",
  },

  StartUp_Documents: {
    en: "StartUp Documents",
    ka: "ಸ್ಟಾರ್ಟಪ್ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳು ",
  },

  Startup_No: {
    en: "Startup/No",
    ka: "ಸ್ಟಾರ್ಟಪ್/ಇಲ್ಲ",
  },

  // TrunOverCertificate

  Company_Turnover: {
    en: "Company Turnover",
    ka: "ಕಂಪನಿಯ ತಿರಿಗಿ ಹೋದ ಹಣಕ್ಕೆ ಕಂಪನಿ ತರಿಸುವ ಮೊತ್ತ",
  },

  First_Financial_year: {
    en: "First Financial year",
    ka: "ಮೊದಲ ಆರ್ಥಿಕ ವರ್ಷ",
  },

  First_Financial_year_Documents: {
    en: "First Financial year Documents",
    ka: " ಮೊದಲ ಆರ್ಥಿಕ ವರ್ಷದ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳು",
  },

  First_Financial_year_Amount: {
    en: "First Financial year Amount",
    ka: "ಮೊದಲ ಆರ್ಥಿಕ ವರ್ಷದ ಮೊತ್ತ",
  },

  Second_Financial_year: {
    en: "Second Financial year",
    ka: "ಎರಡನೇ ಆರ್ಥಿಕ ವರ್ಷ",
  },

  Second_Financial_year_Documents: {
    en: "Second Financial year Documents",
    ka: "ಎರಡನೇ ಆರ್ಥಿಕ ವರ್ಷದ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳು",
  },

  Second_Financial_year_Amount: {
    en: "Second Financial year Amount",
    ka: "ಎರಡನೇ ಆರ್ಥಿಕ ವರ್ಷದ ಮೊತ್ತ",
  },

  Third_Financial_year: {
    en: "Third Financial year",
    ka: "ಮೂರನೇ ಆರ್ಥಿಕ ವರ್ಷ",
  },

  Third_Financial_year_Documents: {
    en: "Third Financial year Documents",
    ka: "ಮೂರನೇ ಆರ್ಥಿಕ ವರ್ಷದ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳು",
  },

  Third_Financial_year_Amount: {
    en: "Third Financial year Amount",
    ka: "ಮೂರನೇ ಆರ್ಥಿಕ ವರ್ಷದ ಮೊತ್ತ",
  },

  Approved_by_Chartered: {
    en: "Approved by Chartered accountant(CA)",
    ka: "ಚಾರ್ಟರ್ಡ್ ಅಕೌಂಟೆಂಟ್ ಅನುಮೋದಿತ",
  },

  CA_Name: {
    en: "CA Name",
    ka: " ಸಿಎ ಹೆಸರು",
  },
  company_registration_number: {
    en: "Company Registration number",
    ka: "ಕಂಪನಿ ನೋಂದಣಿ ಸಂಖ್ಯೆ",
  },
  Amount:{
    en: "Amount",
    ka: "ಮೊತ್ತ"
  },
  Payment_Date:{
    en: " Payment Date",
    ka: " ಪಾವತಿ ದಿನಾಂಕ"
  },
  Bank_Name:{
    en: " Bank Name",
    ka: " ಬ್ಯಾಂಕ್ ಹೆಸರು"
  },
  Account_Holder_Name:{
    en: "Account Holder Name",
    ka: " ಖಾತೆದಾರನ ಹೆಸರು"
  },
  Any_Other_Company: {
    en: "Any Other Company",
    ka: "ಯಾವುದೇ ಇತರ ಕಂಪನಿ"
  },
  Total_Area: {
    en: "Total Area",
    ka: "ಒಟ್ಟು ಪ್ರದೇಶ"
  },
  Address: {
    en: "Address",
    ka: "ವಿಳಾಸ"
  },
  Logout:{
    en: "Logout",
    ka: "ಲಾಗೌಟ್ ಆಗಿದ್ದೇವೆ"
  },
  No_of_Technical_Employees:{
    en: "No of Technical Employees(Manpower)",
    ka: "No of Technical Employees(Manpower)"
  },
  Date_in_document: {
    en: "Date in Document",
    ka: "ದಾಖಲೆಯಲ್ಲಿ ದಿನಾಂಕ"
},
};
