export const Admin_Reports:any={
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
    },
    Reports: {
        en: 'Reports',
        ka: 'ವರದಿಗಳು'
    },
    
    
    // Reports/ReportSection
    From: {
        en: 'From',
        ka: 'ಯಿಂದ'
    },
    Export: {
        en: 'Export',
        ka: 'ಎಗ್ಜಾರ್ಟ್'
    },

    Reset: {
        en: 'Reset',
        ka: 'ಮರುಹೊಂದಿಸಿ'
    },
}