import React from 'react'
import { Link } from 'react-router-dom';

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";

const FaqEng = () => {
  return (
    <><div>
   
    {/* hero-banner */}
    <section>
      <div className="container-fluid">
        <div className="faq-banner-outer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="faq-banner-text-wrap ">
                  <h1>  
                    FAQ
                  </h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to={"/home"}>Home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">FAQ</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></section>
    {/* Faq */}
    <section className="my-5">
      <div className="faq-wrap">
        <div className="container">
          <div className="accordion-content-wrap">
          <UncontrolledAccordion id="faq">
              {/*Accordion Item 1*/}
              <AccordionItem>
              <AccordionHeader targetId="1" className="">
                    What is a Rate Contract System ( RC) ?
                  </AccordionHeader>
                  <AccordionBody accordionId='1'>
                    In Keonics, the Rate Contract (RC) system is a procurement method used to procure goods and services at pre-negotiated prices from empanelled vendors. The RC system is designed to streamline the procurement process and ensure consistency in pricing and quality across different projects. Under the RC system, Keonics empanels vendors who meet certain eligibility criteria and agrees to supply goods or services at a predetermined rate for a fixed period of time. The RC is typically awarded through a competitive bidding process that evaluates vendors based on factors such as technical capabilities, financial stability, and quality standards. Once the vendor is empanelled under the RC system, they can supply goods or services at the predetermined rate without the need for a separate tendering process. This reduces the time and effort required for procurement and allows Keonics to quickly obtain goods or services from pre-qualified vendors. The RC system is commonly used for procuring items such as hardware, software, consumables, and services such as maintenance and support. Keonics periodically reviews the RC rates to ensure they are competitive and reflects the prevailing market conditions. Overall, the RC system helps Keonics to streamline procurement and ensures that goods and services are procured at competitive rates from pre-qualified vendors, while providing vendors with a reliable source of business.
                    </AccordionBody>
              </AccordionItem>
              {/*Accordion Item 2*/}
              <AccordionItem>
              <AccordionHeader targetId="2" className="">
                    What is the vendor empanelment procedure for Keonics ? 
                </AccordionHeader>
                  <AccordionBody accordionId='2'>
                    The vendor empanelment procedure for Keonics (Karnataka State Electronics Development Corporation Limited) may involve the following steps: <br /> <br />
                    <ul>
                      <li>1. <b>Registration:</b> The vendor needs to register in the Keonics vendor portal by providing basic details such as company name, contact person, email ID, phone number, and address.</li>
                      <li>2. <b>Empanelment Application:</b> The vendor needs to fill and submit the empanelment application form along with the required documents such as PAN card, GST registration, bank account details, and other relevant certificates.</li>
                      <li>3. <b>Document Verification:</b> Keonics will verify the documents submitted by the vendor to ensure their authenticity and eligibility.</li>
                      <li>4. <b>Technical Evaluation:</b> Keonics will evaluate the vendor's technical capabilities, experience, and quality standards to determine their suitability for empanelment.</li>
                      <li>5. <b>Financial Evaluation:</b> Keonics may conduct a financial evaluation of the vendor to assess their financial stability and capacity to handle projects.</li>
                      <li>6. <b>Approval:</b> If the vendor meets all the empanelment criteria and requirements, Keonics will approve their application and issue an empanelment letter.</li>
                      <li>7. <b>Empanelment Agreement:</b> The vendor needs to sign an empanelment agreement with Keonics that outlines the terms and conditions of the empanelment.</li>
                      <li>8. <b>Participation in Tenders:</b> Once empanelled, the vendor can participate in Keonics tenders and projects as a registered vendor.</li>
                    </ul>
                    It is important to note that the empanelment process may vary based on the specific requirements and policies of Keonics. Therefore, it is recommended to check the vendor empanelment guidelines in the Keonics website or contact their procurement department for further details.
                  </AccordionBody>
              </AccordionItem>
              {/*Accordion Item 3*/}
              <AccordionItem>
                <AccordionHeader targetId='3'>
                    What are the documents required for empanelment in Keonics ?
                    </AccordionHeader>
                <AccordionBody accordionId='3'>
                    <ol>
                      <li>a.	Company incorporation certificate</li> 
                      <li>b.	PAN </li>
                      <li>c.	GST registration </li>
                      <li>d.	IT Reference for 3 years </li>
                      <li>e.  Company balance sheet for 3 years</li> 
                      <li>f.	Turn over certificate </li>
                      <li>g.	ISO </li> 
                      <li>h.	OEM/MAF </li>
                      <li>i.	Address Proof </li> 
                      <li>j.	MSME </li>
                    </ol>               
                    </AccordionBody>
              </AccordionItem>
              {/*Accordion Item 4*/}
              <AccordionItem>
                <AccordionHeader targetId='4'>
                    How can a vendor empanel with Keonics ?
                    </AccordionHeader>
                    <AccordionBody accordionId='4'>
                    Keonics, or Karnataka State Electronics Development Corporation Limited, is a government-owned company in India that provides IT services and solutions. If you are a vendor looking to empanel with Keonics, you can follow these steps:
                    <ol>
                      <li>1.	Visit the Keonics website and look for the "Vendor Empanelment" section.
                      </li><li>2.	Read the vendor empanelment policy carefully to understand the eligibility criteria, evaluation process, and other requirements.
                      </li><li>3.	Register on the Keonics vendor portal and create a profile by providing your company details, contact information, and other necessary documents.
                      </li><li>4.	Submit the empanelment application form along with the required documents such as PAN card, GST registration, bank account details, and other relevant certificates.
                      </li><li>5.	Once the application is submitted, Keonics will evaluate your application based on the eligibility criteria and other factors such as experience, technical capabilities, financial stability, and quality standards.
                      </li><li>6.	If your application is approved, you will receive an empanelment letter from Keonics, which will include the terms and conditions of the empanelment agreement.
                      </li><li>7.	Once you have signed the empanelment agreement, you will be eligible to participate in Keonics tenders and projects as a registered vendor.
                      </li></ol>
                    It is important to note that the empanelment process may vary depending on the specific requirements and policies of Keonics. Therefore, it is recommended to check the vendor empanelment guidelines on the Keonics website or contact their procurement department for further details.
                  </AccordionBody>
              </AccordionItem>
              {/*Accordion Item 5*/}
              <AccordionItem>
                <AccordionHeader targetId='5'>
                    How much is the Non Refundable Amount for the vendor registration ?
                    </AccordionHeader>
                    <AccordionBody accordionId='5'>
                    Category A, Rupees 1,77,000/-( Inclusive of GST) , Turn over &gt;20 Cr ,<br />
                    Category B, Rupees 88,500/-(Inclusive of GST), Turn over &gt;10 Cr &amp;&amp; Turn over &lt;20 Cr <br />
                    Category C, Rupees 59000/- ( Inclusive of GST) , Turn over &lt;10 Cr
                    </AccordionBody>
              </AccordionItem>
              {/*Accordion Item 6*/}
              {/* <AccordionItem>
              <AccordionHeader targetId='6'>
                    What is GST ?
              </AccordionHeader>
              <AccordionBody accordionId='6'>
                    GST stands for Goods and Services Tax, which is a value-added tax levied on the supply of goods and services in India. GST was introduced in India on July 1, 2017, as a comprehensive indirect tax that replaced several central and state taxes such as excise duty, service tax, and value-added tax (VAT). <br /><br /> Under the GST regime, all goods and services are classified into different tax slabs based on their nature, and a fixed percentage of tax is levied on the value of the goods or services at each stage of the supply chain. GST is a destination-based tax, which means that the tax is levied on the final consumption of goods or services, regardless of their origin. <br /><br />GST has several benefits, including the simplification of the indirect tax system, the elimination of multiple taxes, and the reduction of tax evasion and corruption. It also promotes ease of doing business by providing a uniform tax structure across India, which makes it easier for businesses to operate across different states.<br /><br />In India, GST is administered by the Goods and Services Tax Council, which is a body comprising representatives from the central and state governments. The Council is responsible for setting the tax rates, making changes to the GST law, and resolving any disputes related to GST.
              </AccordionBody>
              </AccordionItem> */}
              {/*Accordion Item 7*/}
              {/* <AccordionItem>
              <AccordionHeader targetId='7'>
                    What is TDS ? 
              </AccordionHeader>
              <AccordionBody accordionId='7'>
                    TDS stands for Tax Deducted at Source, which is a mechanism for collecting income tax in India at the time of payment itself. TDS is applicable to various types of payments such as salaries, professional fees, rent, and payments to contractors and freelancers. <br />In the case of TDS, the payer is required to deduct a certain percentage of the payment as tax and remit it to the government on behalf of the payee. The payee can claim credit for the TDS deducted while filing their income tax returns. <br />The rates for TDS on services and goods depend on various factors such as the nature of the transaction, the type of payment, and the category of the payee. Here are some of the commonly applicable rates for TDS in India: <br /> <br />
                    <b>TDS on services:</b>
                    <ul>
                      <li>●	For professional or technical services: 10% (if the payee is an individual or a Hindu Undivided Family) or 2% (if the payee is a company or a partnership firm)</li>
                      <li>● For royalty or fees for technical services: 10% (if the payee is an individual or a Hindu Undivided Family) or 2% (if the payee is a company or a partnership firm)</li>
                      <li>● For commission or brokerage: 5% (if the payee is an individual or a Hindu Undivided Family) or 2% (if the payee is a company or a partnership firm)</li>
                    </ul>
                    <b>TDS on goods:</b>
                    <ul>
                      <li>●	For contracts and sub-contracts: 1% (if the payee is an individual or a Hindu Undivided Family) or 2% (if the payee is a company or a partnership firm)</li>
                      <li>●	For rent of land, building or furniture: 10% (if the rent exceeds Rs. 2,40,000 per annum)</li>
                      <li>●	For payments to contractors and sub-contractors: 1% (for individual or Hindu Undivided Family) or 2% (for company or partnership firm)</li>
                      <li>●	For the payments to the vendors who raised sales bill for Electronics ITem then TDs is zero percentage </li>
                    </ul>
                    It is important to note that these rates may change from time to time, and the payer should verify the latest rates with the Income Tax Department before making any payments
                    </AccordionBody>
              </AccordionItem> */}
              {/*Accordion Item 8*/}
              {/* <AccordionItem>
              <AccordionHeader targetId='8'>
                    What is the  TDS on GST in Keonics ?
                    </AccordionHeader>
                  <AccordionBody accordionId='8'>
                    As per the TDS guidelines for GST payments in Keonics, the applicable TDS rate is 2% of the total payment amount. However, if the payment is for an intra-state transaction, the TDS will be split into 1% CGST and 1% SGST. On the other hand, if the payment is for an inter-state transaction, the TDS will be 2% IGST.
                    <br /><br />
                    Therefore, if you are making a payment to a vendor for goods or services in Keonics, and the payment is subject to GST, the TDS rate will be 2% of the total payment amount. If the transaction is intra-state, the TDS will be split into 1% CGST and 1% SGST. If the transaction is inter-state, the TDS will be 2% IGST.
                    <br /><br />
                    It is important to note that the TDS should be calculated on the total payment amount, which includes the GST component. The TDS amount should be deposited with the government within the due date specified by the Income Tax Department, failing which, you may be liable to pay interest and penalties.
                    </AccordionBody>
              </AccordionItem> */}
              {/*Accordion Item 9*/}
              <AccordionItem>
              <AccordionHeader targetId='9'>
                    What are the advantages of Keonics Rate Contract System ?
                  </AccordionHeader>

                  <AccordionBody accordionId='9'>
                    The Keonics Rate Contract System offers several advantages for both buyers and vendors. Here are some of the key benefits: <br /><br />
                    <ol>
                      <li>1.	Transparent and Competitive Pricing: The Rate Contract System ensures that the prices for goods and services are pre-negotiated and fixed for a specific period of time. This helps in ensuring that the prices are competitive and transparent, and buyers can be assured of getting the best value for their money.</li>
                      <li>2.	Time and Cost Savings: The Rate Contract System saves time and costs for both buyers and vendors. Buyers do not have to go through the time-consuming process of procurement, negotiation, and finalization of prices with each vendor separately. Vendors, on the other hand, can save on the costs of marketing, advertising, and bidding for each procurement opportunity.</li>
                      <li>3.	Quality Assurance: The Rate Contract System helps in ensuring that the goods and services procured meet the required quality standards. Vendors are required to comply with the terms and conditions of the contract, and any breach of contract can result in penalties and blacklisting.</li>
                      <li>4.	Easy Access to Vendors: The Rate Contract System provides a centralized database of vendors who have been empanelled by Keonics. This makes it easy for buyers to access a wide range of vendors for their procurement needs.</li>
                      <li>5.	Reduced Risk of Fraud and Corruption: The Rate Contract System reduces the risk of fraud and corruption by ensuring that the procurement process is transparent and fair. The vendors are selected based on their credentials and capabilities, and the procurement process is governed by strict rules and regulations.</li>
                    </ol>
                    In summary, the Keonics Rate Contract System offers a range of benefits for buyers and vendors, including transparency, cost savings, quality assurance, easy access to vendors, and reduced risk.
                    </AccordionBody>
              </AccordionItem>
              {/*Accordion Item 10*/}
              <AccordionItem>
              <AccordionHeader targetId='10'>
                    What is the duration of the rate contract ? 
                </AccordionHeader>
                
                <AccordionBody accordionId='10'>
                    180 Days ( 6 Months ) 
              </AccordionBody>
              </AccordionItem>
              {/*Accordion Item 11*/}
              <AccordionItem>
              <AccordionHeader targetId='11'>
                    Would rate changes for 180 days ? 
                </AccordionHeader>
                <AccordionBody accordionId='11'>
                    Not usually, It changes  if the prices and inflammation goes beyond a particular point which is analyzed and decided by the Management of Keonics
                </AccordionBody>
              </AccordionItem>
              {/*Accordion Item 12*/}
              <AccordionItem>
              <AccordionHeader targetId='12'>
                    How can the department benefit from the rate contract system implemented by Keonics ? 
              </AccordionHeader>
              <AccordionBody accordionId='12'>
                    The Rate Contract System implemented by Keonics can bring several benefits to a department. Here are some of the key advantages:<br /><br />
                    <ol>
                      <li>1.	<b>Cost Savings:</b> By using a rate contract system, a department can get products and services at a pre-negotiated price, which can help to reduce costs. This is because the rates offered through the rate contract system are generally lower than the market rates.</li>
                      <li>2.	<b>Time-Saving:</b> With a rate contract system in place, departments do not need to spend time negotiating prices for each purchase. This can save a lot of time and effort and allows the department to focus on other important tasks.</li>
                      <li>3.	<b>Quality Assurance:</b> Keonics is a reputable organization that ensures the quality of products and services offered through the rate contract system. This can help departments to ensure that they are getting high-quality products and services at a reasonable price.</li>
                      <li>4.	<b>Transparency:</b> The rate contract system offers a transparent procurement process, where all terms and conditions are clearly defined. This can help departments to avoid any misunderstandings or disputes with suppliers.</li>
                      <li>5.	<b>Standardization:</b> The rate contract system ensures that all products and services meet certain standards, which can help to ensure consistency in quality across the department.</li>
                    </ol>
                    Overall, the rate contract system implemented by Keonics can bring several benefits to a department, including cost savings, time-saving, quality assurance, transparency, and standardization.
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </div>
        </div>
      </div>
    </section>
  </div></>
  )
}

export default FaqEng