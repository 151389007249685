export const DepartDash:any = {
    //Admin\TDS\Modal\UploadTdsToVendor
    DashboardAvailability: {
        en: 'The dashboard will be available once the products are allocated to your tender',
        ka: 'ನಿಮ್ಮ ಟೆಂಡರ್‌ಗೆ ಉದ್ಯಮಗಳು ಹಂಚಿಕೆಗೆ ಹೊಂದಿಕೊಳ್ಳಲಾದ ಮೇಲೆ ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಲಭ್ಯವಾಗುತ್ತದೆ'
    },

    DiscoveraHugeCollectionof:{
        en:'Discover a Huge Collection of ',
        ka:'ದೊಡ್ಡ ಸಂಗ್ರಹವನ್ನು ಕಂಡುಹಿಡಿಯಿರಿ'
    },

    BuyTheLatest:{
        en:'Buy The Latest',
        ka:'ಹೊಸತನ್ನು ಖರೀದಿಸಿ'
    },

    DiscoveraHugeCollectionOf:{
        en:'Discover a Huge Collection Of ',
        ka:'ದೊಡ್ಡ ಸಂಗ್ರಹವನ್ನು ಕಂಡುಹಿಡಿಯಿರಿ'
    },

    AtGreatPrices:{
        en:'At Great Prices. Shop Online Today!',
        ka:'ಅದ್ಭುತ ಬೆಲೆಗಳಲ್ಲಿ. ಇಂದು ಆನ್ಲೈನ್ ಶಾಪಿಂಗ್ ಮಾಡಿ!'
    },

    
    ShopNow:{
        en:'Shop Now',
        ka:'ಈಗ ಖರೀದಿಸಿ'
    },

    ViewMore:{
        en:'View More',
        ka:'ಹೆಚ್ಚಿನದನ್ನು ವೀಕ್ಷಿಸು'
    },

    
    Products: {
        en: ' Products',
        ka: 'ಉತ್ಪನ್ನಗಳು'
    },

    ThisWeek:{
        en:'This Week',
        ka:'ಈ ವಾರ'
    },

    ThisMonth:{
        en:'This Month',
        ka:'ಈ ತಿಂಗಳು'
    },

    ThisYear:{
        en:'This Year',
        ka:'ಈ ವರ್ಷ'
    },

    LatestProducts:{
        en:'Latest Products',
        ka:'ಇತ್ತೀಚಿನ ಉತ್ಪನ್ನಗಳು'
    },
    
    Show:{
        en:'Show',
        Ka:'ತೋರಿಸು'
    }
}  