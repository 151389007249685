export const admin_empanelment :any = {
    
    //admin----empanelment----modals----addvendor----add_vendor_modal
    Add_a_Vendor: {
        en: 'Add a Vendor',
        ka: 'ವೇಂಡರ್ ಸೇರಿಸಿ'
    },
    Vendor_type:{
        en:' Vendor Type',
        ka:'ವೇಂಡರ್ ಪ್ರಕಾರ'
    },
    Select_Vendor_Type:{
        en:'Select Vendor Type',
        ka:'ವೇಂಡರ್ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
    },
    GST_Class_Name: {
        en: 'GST Class Name',
        ka: 'GST ವರ್ಗದ ಹೆಸರು'
    },
    Startup_Certificate:{
        en:'Startup Certificate',
        ka:'ಸ್ಟಾರ್ಟಅಪ್ ಪ್ರಮಾಣಪತ್ರ'
    },
    Choose_Documents:{
        en:'Choose Documents',
        ka:'ಪತ್ರಿಕೆಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
    },
    GST_Class:{
        en:'GST Class',
        ka:'ಜಿಎಸ್ಟಿ ವರ್ಗ'
    },
    Select_GST_Class:{
        en:'Select GST Class',
        ka:'ಜಿಎಸ್ಟಿ ವರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ' 
    },

    Pan_Number : {
        en:'Pan Number',
        ka:'ಪ್ಯಾನ್ ಸಂಖ್ಯೆ' 
    },
    GST_Number: {
        en:'GST Number',
        ka:'ಜಿಎಸ್ಟಿ ಸಂಖ್ಯೆ' 
    },
    Company_Name :{
        en:'Company Name',
        ka:'ಕಂಪನಿಯ ಹೆಸರು' 
    },
    Company_Email:{
        en:'Company Email',
        ka:'ಕಂಪನಿ ಇಮೇಲ್' 
    },
    Company_Type:{
        en:' Company Type',
        ka:'ಕಂಪನಿ ಪ್ರಕಾರ' 
    },
   select_Company_Type:{
        en:'Select  Company Type',
        ka:'ಕಂಪನಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ' 
    },
    Company_Incorporation_Date:{
        en:'Company Incorporation Date',
        ka:'ಕಂಪನಿ ಸೇರುವ ದಿನಾಂಕ'
    },
    
    Empanelment_Date:{
        en:'Empanelment Date',
        ka:'ಎಂಪ್ಯಾನೆಲ್ಮೆಂಟ್ ದಿನಾಂಕ'
    },
    Empanelment_Expiry_Date:{
        en:'Empanelment Expiry Date',
        ka:'ಎಂಪ್ಯಾನೆಲ್ಮೆಂಟ್ ಮುಗಿಯುವ ದಿನಾಂಕ'
    },
    Company_Registration_Number:{
        en:'Company Registration Number',
        ka:'ಕಂಪನಿ ನೋಂದಣಿ ಸಂಖ್ಯೆ'
    },
    Contact_Person_Name:{
        en:' Contact Person Name',
        ka:'ಸಂಪರ್ಕ ವ್ಯಕ್ತಿಯ ಹೆಸರು'
    },
    Contact_Person_Number :{
        en:' Contact Person Number',
        ka:'ಸಂಪರ್ಕ ವ್ಯಕ್ತಿಯ ಸಂಖ್ಯೆ' 
    },

     //admin----empanelment----modals----invite_vendor

     Vendor_Name :{
        en:'Vendor Name',
        ka:'ವೇಂಡರ್ ಹೆಸರು' 
    },
    // //Company_Name :{
    //     en:'Company Name',
    //     ka:'ಕಂಪನಿಯ ಹೆಸರು' 
    // },
    Mobile_Number :{
        en:'Mobile Number',
        ka:'ಮೊಬೈಲ್ ಸಂಖ್ಯೆ' 
    },
    Email_ID :{
        en:'Email ID',
        ka:'ಇಮೇಲ್ ಐಡಿ' 
    },

    // admin---- empanelment----modal----verifyvendormodal
    Vendor_1 :{
        en:'Vendor 1',
        ka:'ವೇಂಡರ್ 1' 
    },
    // admin---- empanelment----tabs----Active_vendor_tab
    sl_no :{
        en:'Sl No',
        ka:'ಕ್ರ. ಸಂ' 
    },
    // Vendor_Name :{
    //     en:'Vendor Name',
    //     ka:'ವೇಂಡರ್ ಹೆಸರು' 
    // },
    Registration_Number:{
        en:'Vendor ID',
        ka:'ನೋಂದಣಿ ಸಂಖ್ಯೆ'
    },
    Verified_Date:{
        en:'Verified Date',
        ka:'ಪರಿಶೀಲಿತ ದಿನಾಂಕ'
    },
    Registration_Date:{
        en:'Registration Date',
        ka:'ನೋಂದಣಿ ದಿನಾಂಕ'
    },
    Click_to_Deactivate:{
        en:'Click to Deactivate',
        ka:'ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ' 
    },
    Click_to_activate:{
        en:'Click to Activate',
        ka:'ಸಕ್ರಿಯಗೊಳಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ' 
    },
    No_Vendors_Found:{
        en:'No Vendors Found',
        ka:'ಯಾವುದೇ ವೇಂಡರ್ ಕಂಡುಬಂದಿಲ್ಲ' 
    },
    Contact_Number: {
        en: ' Contact Number ',
        ka: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ'
    },
    Action: {
        en: ' Action',
        ka: 'ಕ್ರಿಯೆ'
    },
    Registration_Id: {
        en: 'Registration ID',
        ka: 'ನೋಂದಣಿ ಐಡಿ'
    },
    Company_Communication_Address: {
        en: 'Company Communication Address',
        ka: 'ಕಂಪನಿಯ ಸಂವಹನ ವಿಳಾಸ'
    },
    Is_Your: {
        en: 'Is Your Company MSME(Yes/No)',
        ka: 'ನಿಮ್ಮ ಕಂಪನಿ MSME (ಹೌದು/ಇಲ್ಲ)'
    },

    // admin---- empanelment----tabs----empanelmenttab
    Vendor_Id: {
        en: 'Vendor Id',
        ka: ' ಮಾರಾಟಗಾರರ ಐಡಿ'
    },

    Add_vendor:{
        en:'Add  vendor',
        ka:'ವೇಂಡರ್ ಸೇರಿಸಿ' 
    },
    Created_Date:{
        en:'Created Date',
        ka:'ರಚಿಸಲಾದ ದಿನಾಂಕ' 
    },
    Status:{
        en:'Status',
        ka:'ಸ್ಥಿತಿ' 
    },
    Category_of_vendors:{
        en:'Category of Vendors',
        ka:'ವೇಂಡರ್ಗಳ ವರ್ಗವನ್ನು' 
    },
    Sent_Invitation:{
        en:' Sent Invitation',
        ka:'ಆಹ್ವಾನವನ್ನು ಕಳುಹಿಸಲಾಗಿದೆ' 
    },
    Invited:{
        en:' Invited',
        ka:'ಆಹ್ವಾನಿಸಲಾಗಿದೆ' 
    },
    Uploaded:{
        en:' Uploaded',
        ka:'ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ' 
    },

    Under_Verification:{
        en:'Under Verification',
        ka:'ಪರಿಶೀಲನೆಗೆ ಒಳಗಾಗಿದೆ' 
    },
    Verified:{
        en:'Verified',
        ka:'ಪರಿಶೀಲಿಸಲಾಗಿದೆ' 
    },
    
    Accepted:{
        en:'Accepted',
        ka:'ಒಪ್ಪಿಗೆ ಪಡೆದಿದೆ' 
    },
    Pending:{
        en:'Pending',
        ka:'ಬಾಕಿಯಿದೆ' 
    },
    Inactive:{
        en:'Inactive',
        ka:'ನಿಷ್ಕ್ರಿಯ' 
    },
    Showing:{
        en:'Showing',
        ka:'ತೋರಿಸಲಾಗುತ್ತಿದೆ' 
    },
    to:{
        en:'to',
        ka:'ಗೆ' 
    },
    of:{
        en:'of',
        ka:'ನ' 
    },
    entries:{
        en:'entries',
        ka:'ಪ್ರವೇಶಗಳು' 
    },


    //admin----empanelment----tabs----expiredvendorstab

    Inactivation_Date:{
        en:'Inactivation Date',
        ka:'ನಿಷ್ಕ್ರಿಯೆ ದಿನಾಂಕ' 
    },


    //admin----empanelment----tabs----selfvendorstab
    
    View_and_Verify:{
        en:' View and Verify',
        ka:'ವೀಕ್ಷಿಸಿ ಮತ್ತು ಪರಿಶೀಲಿಸಿ' 
    },


    //admin----empanelment----vendor verification process ----tabs---advanced details

    Approved:{
        en:' Approved',
        ka:'ಅನುಮೋದಿಸಲಾಗಿದೆ' 
    },
    
    Approve:{
        en:' Approve',
        ka:'ಅನುಮೋದಿಸಿ' 
    },
    reject:{
        en:'reject',
        ka:'ತಿರಸ್ಕರಿಸಿ' 
    },
   comment:{
        en:'Comment',
        ka:'ಕಾಮೆಂಟ್ಗಳು' 
    },
    Enter_comment:{
        en:'Enter Comment',
        ka:'ಕಾಮೆಂಟ್ ನಮೂದಿಸಿ' 
    },
    Rejected:{
        en:'Rejected',
        ka:'ನಿರಾಕರಿಸಲಾಗಿದೆ' 
    },
    Reuploaded:{
        en:'Reuploaded',
        ka:'ಮರೆಮಾಡಿದ್ದನ್ನು ಮರುಹೊಂದಿಸಲಾಗಿದೆ' 
    },
    No_MSME_Added:{
        en:'No MSME Added',
        ka:'ಯಾವುದೇ ಎಂಎಸ್ಎಂಇ ಸೇರಿಸಲಾಗಿಲ್ಲ' 
    },
    
    pending:{
        en:'Pending',
        ka:'ಬಾಕಿಯಿದೆ' 
    },
    Turn_Over_1:{
        en:'Turn Over',
        ka:'ಹಾದಿಯುದ್ಧ' 
    },
    year:{
        en:'Year',
        ka:'ವರ್ಷ' 
    },
    
    Non_refundable_Loyalty_Amount:{
        en:'Non-refundable Loyalty Amount ',
        ka:'ಮರಳಿಸಲಾಗದ ನಿಷ್ಪ್ರತಿಷ್ಠೆ ಮೊತ್ತ' 
    },
    //admin----empanelment----vendor verification process ----tabs---document verify 
    
    Document_Type:{
        en:'Document Type',
        ka:'ದಾಖಲೆಯ ಪ್ರಕಾರ' 
    },

    
    Document:{
        en:'Document',
        ka:'ದಾಖಲೆ' 
    },

    
    MAF_Document_not_uploaded:{
        en:'MAF Document not Uploaded',
        ka:'ಎಂಎಎಫ್ ದಾಖಲೆ ಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿಲ್ಲ' 
    },
    ISO_Document_not_uploaded:{
        en:'ISO Document not Uploaded',
        ka:'ಐಎಸ್ಒ ದಾಖಲೆ ಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿಲ್ಲ' 
    },
    OEM_Document_not_uploaded:{
        en:'OEM Document not Uploaded',
        ka:'ಓಇಎಂ ದಾಖಲೆ ಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿಲ್ಲ' 
    },


    Address_Proof:{
        en:'Address Proof',
        ka:'ವಿಳಾಸ ಪುರಾವೆ' 
    },
    MSE:{
        en:'MSE',
        ka:'ಎಂಎಸ್ಇ' 
    },
    DD:{
        en:'DD',
        ka:'ಡಿ.ಡಿ' 
    },
    Company_Registered_Address: {
        en: 'Company Registered Address ',
        ka: 'ಕಂಪನಿ ನೋಂದಾಯಿತ ವಿಳಾಸ'
    },

    Company_Incorporation_Certificate:{
        en:'Company Incorporation Certificate',
        ka:'ಕಂಪನಿ ಸೇರುವ ಪ್ರಮಾಣಪತ್ರ' 
    },
    Empanel:{
        en:'Empanel',
        ka:'ಏಮ್‌ಪ್ಯಾನೆಲ್ ಮಾಡು.' 
    },
    Udyam:{
        en:'Udyam',
        ka:'ಉದ್ಯಮ' 
    },
    No_data_added:{
        en:'No data added',
        ka:'ಯಾವುದೇ ಡೇಟಾ ಸೇರಿಸಲಾಗಿಲ್ಲ' 
    },



    //admin----empanelment----vendor verification process ----tabs---verification layout----

    Basic_details:{
        en:'Basic Details',
        ka:'ಮೂಲ ವಿವರಗಳು' 
    },
    Advanced_details:{
        en:'Advanced Details',
        ka:'ಉನ್ನತ ವಿವರಗಳು' 
    },
    Documents:{
        en:'Documents',
        ka:'ದಾಖಲೆಗಳು' 
    },
    Verify_Vendor:{
        en:'Verify Vendor',
        ka:'ವೇಂಡರ್ ಪರಿಶೀಲಿಸಿ' 
    },
    verify: {
        en: 'Verify',
        ka: 'ಪರಿಶೀಲಿಸಿ'
    },
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
    },

     //admin----empanelment----index---------
     Latest_Registered_Vendors :{
        en:'Latest Registered Vendors ',
        ka:'ಇತ್ತಿಹಾಸದ ಕೈಗಾರಿಕೆ ಪತ್ರ ಪಡೆದ ವೇಂಡರ್ಗಳು"' 
    },
    Registered: {
        en: 'Registered',
        ka: 'ನೋಂದಾಯಿಸಲಾಗಿದೆ'
    },
    Not_Registered: {
        en: 'Not Registered',
        ka: 'ನೋಂದಣಿಯಾಗಿಲ್ಲ'
    },
    Componding_Scheme: {
        en: 'Componding Scheme',
        ka: 'ಸಂಯೋಜನೆ ಯೋಜನೆ'
    },
    Marketer: {
        en: 'Marketer',
        ka: 'ವ್ಯಾಪಾರೋದ್ಯಮಿ'
    },
    Mark: {
        en: 'Mark',
        ka: 'ಅಂಕಗಳು'
    },
    PSU_Organization: {
        en: 'PSU Organization',
        ka: 'PSU ಸಂಸ್ಥೆ'
    },

    Vendor_Verification:{
        en:'Vendor Verification',
        ka:'ವೇಂಡರ್ ಪರಿಶೀಲನೆ' 
    },
    Empanelled_Vendors:{
        en:'Empanelled Vendors',
        ka:'ಏಮ್‌ಪ್ಯಾನೆಲ್ಡ್ ವೇಂಡರ್ಗಳು' 
    },
    Inactive_Vendors:{
        en:'Inactive Vendors',
        ka:'ನಿಷ್ಕ್ರಿಯ ವೇಂಡರ್ಗಳು' 
    },
    Expired_Vendors:{
        en:'Expired Vendors',
        ka:'ಮುಗಿದ ವೇಂಡರ್ಗಳು' 
    },
    Vendors_Going_To_Expire:{
        en:'Vendors Going To Expire',
        ka:'ಮುಗಿಯುವ ವೇಂಡರ್ಗಳು' 
    },
    Invite_Vendor: {
        en: 'Invite Vendor',
        ka: 'ಮಾರಾಟಗಾರರನ್ನು ಆಹ್ವಾನಿಸಿ'
    },
    Invite: {
        en: 'Invite',
        ka: 'ಆಹ್ವಾನಿಸಿ'
    }, 
}