import React, { useContext } from 'react'
import { Context } from './Components/Context/Context';

const ThemeWrap = ({ children }: any) => {
    const { theme } = useContext(Context);
    return (
        <div className={theme}>
            {children}
        </div>
    )
}

export default ThemeWrap