import axios from "axios";
import { API_URL } from "../../Library/ApiSettings";
import { toast } from "react-toastify";

// gets report data 
const getExportData = async (download_url: string, NAME: string, fromDate: string, toDate: string, setIsLoading: Function) => {

    setIsLoading(true)
    axios({
        method: "get",
        url: `${API_URL}${download_url}?from_date=${fromDate}&to_date=${toDate}`,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        },
    })
        .then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${NAME} ${fromDate !== '' ? `from ${fromDate}` : ''}  ${toDate !== '' ? `to ${toDate}` : ''} .csv`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setIsLoading(false)
        }
        ).catch(function (response) {
            toast.error('Download Error')
            setIsLoading(false)
        })
};

export default getExportData