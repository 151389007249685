import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import OrderViewModal from "../OrderViewModal/OrderViewModal";
import { Link } from "react-router-dom";
import { PAYMENT_MODES } from "../../../../Library/Constants/MapConstants";
import constants from "../../../../Library/Constants/Constants";
import { Context } from "../../../../Components/Context/Context";
import { Department_Orders } from "../../../../Library/LanguageConversion/Department_Orders";
export default function DeptCancelledOrders() {
  const [loading, setLoading] = useState(false);
  const [cancelled_order, setCancelledOrder] = useState([]);
  const [pageData, setPageData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number | string>(1)
  const [searchVal, setSearchVal] = useState<string>("")
  const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false) // for feedback modal
  const [singleOrderData, setSingleOrderData] = useState<any>([])
  const [selectedAddress, setSelectedAddress] = useState<any>([])
  const { language, setLanguage } = useContext(Context);
  useEffect(() => {
    getCancelledOrder();
  }, [currentPage, searchVal]);

  // Fetches all cancelled orders  
  const getCancelledOrder = () => {
    setLoading(true);
    api
      .get(`/order/pf-invoice?status=7&page=${currentPage}&search_value=${searchVal}`, true)
      .then(async function ([success, response]) {
        setCancelledOrder(response.data || []);
        setPageData(response?.meta)
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // handles order view modal 
  const handleOrderView = (obj: any) => {
    setSingleOrderData(obj.order_details)
    setSelectedAddress(obj?.shipping_address[0])
    setOrderModalOpen(true)
  }

  return (
    <>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText title="Order ID/Product Name" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder="Order ID/Product Name"
              aria-label="Username"
              aria-describedby="search"
              onChange={(e: any) => {
                setCurrentPage(1)
                setSearchVal(e.target.value)
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th> {Department_Orders?.SlNo[language]}</th>
              <th> {Department_Orders?.OrderID[language]}</th>
              <th>{Department_Orders?.TenderID[language]}</th>
              <th>{Department_Orders?.OrderDetails[language]}</th>
              <th>{Department_Orders?.ShippingAddress[language]}</th>
              <th>{Department_Orders?.Quantity[language]}</th>
              <th>{Department_Orders?.Payment[language]}</th>
            </tr>
          </thead>
          <tbody>
            {!loading ?
              <>
                {cancelled_order?.length !== 0 ? (
                  <>
                    {cancelled_order?.map((item: any, i: number) => {
                      let count = 0
                      return (
                        <React.Fragment key={i}>
                          <tr className="order-relative">
                            <td><span className="mobile-heading"> {Department_Orders?.SlNo[language]}:</span><span className="table-data">{pageData?.from + i}</span></td>
                            <td title="View Order"><span className="mobile-heading">{Department_Orders?.OrderID[language]}:</span><span className="table-data"><Link to="" className="blue-text" onClick={() => handleOrderView(item)}>{item?.order_id}</Link></span></td>
                            <td><span className="mobile-heading">{Department_Orders?.TenderID[language]}:</span><span className="table-data">{item?.order_details[0].tender_id}</span></td>
                            <td>
                              <span className="mobile-heading">{Department_Orders?.OrderDetails[language]}:</span>
                              <span className="table-data">
                                {/* Multiple order list */}
                                {item?.order_details?.length > 1 ? (
                                  <>
                                    <UncontrolledAccordion defaultOpen="0" className="order-list-accordion">
                                      <AccordionItem>
                                        <AccordionHeader targetId="1" className="">
                                          {Department_Orders?.MoreThanOneOrder[language]}. <span>{Department_Orders?.On_[language]}.</span>
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1" className="">
                                          <AccordionHeader targetId="1" className="close-btn">
                                            <span className="close-btn-inner" title="Close">
                                              <i className="bi bi-x-lg"></i>
                                            </span>
                                          </AccordionHeader>
                                          <div className="order-list-outer-wrap">
                                            {
                                              item?.order_details?.map((obj: any, x: number) => {
                                                count = count + obj.quantity
                                                return (
                                                  <React.Fragment key={x}>
                                                    <div className="product-details">
                                                      <p className="product-title"><span>{obj?.name}</span></p>
                                                      <p className="sub-details"> {Department_Orders?.Unitprice[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.price * 0.05) + obj?.price} </span></p>
                                                      <p className="sub-details">{Department_Orders?.Quantity[language]}: <span>{obj?.quantity}</span> </p>
                                                      <p className="sub-details">{Department_Orders?.Total[language]}: <span><i className="bi bi-currency-rupee"></i>{obj?.total_amount} ({Department_Orders?.IncludingGST[language]})</span></p>
                                                    </div>
                                                    {/* {item?.order_details?.length !== x + 1 && <hr />} */}
                                                  </React.Fragment>
                                                )
                                              })
                                            }
                                          </div>
                                        </AccordionBody>
                                      </AccordionItem>
                                    </UncontrolledAccordion>
                                  </>

                                ) : null}

                                {/* Single order */}
                                {item?.order_details?.length == 1 ? (
                                  <>
                                    {
                                      item?.order_details?.map((obj: any, x: number) => {
                                        count = count + obj.quantity
                                        return (
                                          <React.Fragment key={x}>
                                            <div className="product-details">
                                              <p className="product-title"><span>{obj?.name}</span></p>
                                              <p className="sub-details"> {Department_Orders?.Unitprice[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.price * 0.05) + obj?.price} </span></p>
                                              <p className="sub-details">{Department_Orders?.Quantity[language]}: <span>{obj?.quantity}</span> </p>
                                              <p className="sub-details">{Department_Orders?.Total[language]}: <span><i className="bi bi-currency-rupee"></i>{obj?.total_amount} ({Department_Orders?.IncludingGST[language]})</span></p>
                                            </div>
                                          </React.Fragment>
                                        )
                                      })
                                    }
                                  </>
                                ) : null}
                              </span>

                            </td>
                            <td><span className="mobile-heading">{Department_Orders?.Shipping_Address[language]}:</span>
                              <span className="table-data">
                                Building no:{item?.shipping_address[0].building_no} <br />
                                {item?.shipping_address[0].city} <br />
                                {item?.shipping_address[0].district} <br />
                                {item?.shipping_address[0].pincode} <br />
                                {item?.shipping_address[0].email} <br />
                                {item?.shipping_address[0].phone} <br />
                              </span>
                            </td>
                            <td><span className="mobile-heading">{Department_Orders?.Quantity[language]}:</span><span className="table-data">{count}</span></td>
                            <td>
                              <span className="mobile-heading">{Department_Orders?.Payments[language]}:</span>
                              <div className="payment-detail-wrap blue-text">
                                <span className="status">{(PAYMENT_MODES?.find((obj: any) => {
                                  return obj?.value == item?.payment_mode || obj?.value == item?.payment_details?.payment_mode
                                }))?.name}</span>
                                <div className="tooltip-outer-wrap">
                                  <i className="bi bi-info-circle tooltip-eye"></i>
                                  <span className="tooltip-text">
                                    {item?.grand_amount && <div>
                                      <small>{Department_Orders?.Total[language]}: <span className="green-text">₹{item?.grand_amount}</span></small>
                                    </div>}

                                    {(item?.payment_mode == constants?.DEPT_PAYMENT_MODES?.FULL_PAYMENT || item?.payment_mode == constants?.DEPT_PAYMENT_MODES?.PARTIAL_PAYMENT) ?
                                      <>
                                        {item?.last_paid_amount && <div>
                                          <small>{Department_Orders?.YouPaid[language]}: <span className="green-text">₹{item?.last_paid_amount}</span></small>
                                        </div>}
                                        <div>
                                          {item?.payment_details?.refund_transaction_id == null ?
                                            <span className="text-danger">{Department_Orders?.RefundinProgress[language]}</span>
                                            :
                                            <span className="text-success">{Department_Orders?.Refunded[language]}</span>}
                                        </div>
                                      </>
                                      :
                                      (item?.payment_mode == constants?.DEPT_PAYMENT_MODES?.FULL_PAYMENT) && <small>{Department_Orders?.NoRefundNeeded[language]}</small>
                                    }
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </>
                )
                  : (
                    <tr>
                      <td colSpan={7} className="text-center p-5 empty-text">
                        {Department_Orders?.NoOrdersFound[language]}
                      </td>
                    </tr>
                  )}
              </>
              :
              <tr>
                <td colSpan={7} className="text-center p-5 empty-text">
                  <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </Table>
        {pageData && cancelled_order.length > 0 && <div className="table-footer-wrap">
          <small>{Department_Orders?.Showing[language]}{pageData.from}{Department_Orders?.to[language]}{pageData.to} {Department_Orders?.of[language]} {pageData?.total}{Department_Orders?.entries[language]}</small>
          <div className="pagination-outer">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData?.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>}
      </div>

      {/* order view modal  */}
      <div>
        <ModalContainer open={orderModalOpen} setOpen={setOrderModalOpen} header='Order Details'>
          <OrderViewModal singleOrderData={singleOrderData} orderViewLoading={false} address={selectedAddress} />
        </ModalContainer>
      </div>
    </>
  );
}