export const Admin_Products:any = {
    //Admin\Products\Productlist
    Dashboard: {
        en: ' Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },

    Products: {
        en: ' Products',
        ka: 'ಉತ್ಪನ್ನಗಳು'
    },

    UploadProducts: {
        en: ' Upload Products',
        ka: 'ಉತ್ಪನ್ನಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ'
    },

    ProductImage: {
        en: ' Product Image',
        ka: 'ಉತ್ಪನ್ನ ಚಿತ್ರ'
    },
    UploadProductImage: {
        en: 'Upload Product Image',
        ka: 'ಉತ್ಪನ್ನಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಚಿತ್ರ'
    },

    ProductName: {
        en: ' Product Name',
        ka: 'ಉತ್ಪನ್ನ ಹೆಸರು'
    },
    Upload_the_Image: {
        en: ' Upload the Image',
        ka: 'ಚಿತ್ರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ'
    },

    Status:{
        en:'Status',
        ka:'ಸ್ಥಿತಿ'
    },

    Action:{
        en:'Action',
        ka:'ಕ್ರಿಯೆ'
    },

    SlNo: {
        en: 'Sl No',
        ka: 'ಕ್ರ.ಸಂ'
    },
    ProductCode: {
        en: 'Product Code',
        ka: 'ಉತ್ಪನ್ನ ಕೋಡ್'
    },
    Category : {
        en: 'Category',
        ka: 'ವರ್ಗ'
    },

    Active : {
        en: 'Active',
        ka: 'ಸಕ್ರಿಯ'
    },

    Inactive : {
        en: 'Inactive',
        ka: 'ನಿಷ್ಕ್ರಿಯ'
    },
    
    Showing:{
        en:'Showing',
        ka:'ಪ್ರದರ್ಶಿಸುವುದು'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ನ'
    },
     entries:{
        en:'entries',
        ka:'ಪ್ರವೇಶಗಳು'
    },
  //Admin\Products\UploadProductsModal

     HSNCode:{
      en:'HSN Code',
      ka:'ಎಚ್‌ಎಸ್‌ಎನ್ ಕೋಡ್'

    },

     Close:{
       en:'Close',
       ka:'ಹೆಚ್ಚುಕೊಂಡ'
    },

     Remove:{
       en:'Remove',
       ka:'ತೆಗೆದುಹಾಕಿ'
    },

      AddSpecification:{
         en:'Add Specification',
         ka:'ವಿವರಣೆಯನ್ನು ಸೇರಿಸು'
    },
      
     ProductDescription:{
        en:'Product Description',
        ka:'ಉತ್ಪಾದನ ವಿವರಣೆ'
   },
   Add_Category: {
    en: 'Add Category',
    ka: 'ವರ್ಗವನ್ನು ಸೇರಿಸಿ'
    },
    Add: {
        en: 'Add',
        ka: 'ಸೇರಿಸಿ'
    },
    Select_Category: {
        en: 'Select Category',
        ka: 'ವರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
    },
    Select_Parent: {
        en: 'Select Parent',
        ka: 'ಪೋಷಕರನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },



}
