import React, { useEffect, useRef,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import moment from "moment";
import { file_remove } from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;

export default function EmpanelmentDocuments(props: any) {
  const {language,setLanguage}=useContext(Context);
  const EmpanelRef = useRef<HTMLInputElement>(null);
  function minExpirtDate() {
    const date = new Date(props?.values?.empanelment_date);

    return props?.values?.empanelment_date
      ? date.setDate(date.getDate() + 1)
      : new Date() || new Date();
  }

  return (
    <>
      <div className="company-information-update-wrap">
        <h4>{Vendor_profileUpdation?.Empanel_Documents?.[language]}</h4>
        <hr />
        <Row>
          <Col md={4} sm={6}>
            <FormGroup className="file">
              <Label>
              {Vendor_profileUpdation?.Empanel_Certificate?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {" "}
                <Input
                  innerRef={EmpanelRef}
                  type="file"
                  id="empanel_certificate"
                  name="empanel_certificate"
                  accept=".pdf"
                  className="form-control"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                  disabled={props?.list > 0 ? true : false}
                ></Input>
                <Label
                  className="image-button"
                  for="empanel_certificate"
                  id="empanel_certificate"
                >
                  {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.empanel_certificate && (
                  <>
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "50px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove(
                          "empanel_certificate",
                          EmpanelRef,
                          "empanel_certificate"
                        );
                        props?.setFieldValue("empanel_certificate", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>
              <ErrorMessage name="empanel_certificate" component={TextError} />
            </FormGroup>
          </Col>

          <Col md={4} sm={6}>
            <FormGroup className="form-group date">
              <Label for="">
              {Vendor_profileUpdation?.Empanelment_Date?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              {props?.list <= 0 ? (
                <DatePicker
                  onChange={(e: any) => {
                    props?.setFieldValue("expiry_date", "");
                    props?.setFieldValue("empanelment_date", e);
                  }}
                  format={"dd-MM-y"}
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  value={props?.values?.empanelment_date}
                  name="empanelment_date"
                  maxDate={new Date()}
                />
              ) : (
                <Input
                  type="text"
                  name="empanelment_date"
                  value={props?.values?.empanelment_date}
                  disabled
                ></Input>
              )}

              <ErrorMessage name="empanelment_date" component={TextError} />
            </FormGroup>
          </Col>

          <Col md={4} sm={6}>
            <FormGroup className="form-group date">
              <Label for="">
              {Vendor_profileUpdation?.Empanelment_Expiry_Date?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              {props?.list <= 0 ? (
                <DatePicker
                  onChange={(e: any) => {
                    props?.setFieldValue("expiry_date", e);
                  }}
                  format={"dd-MM-y"}
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  value={props?.values?.expiry_date}
                  name="expiry_date"
                  minDate={new Date(minExpirtDate())}
                />
              ) : (
                <Input
                  type="text"
                  name="expiry_date"
                  value={props?.values?.expiry_date}
                  disabled
                ></Input>
              )}

              <ErrorMessage name="expiry_date" component={TextError} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
