import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from '../../../Components/Context/Context';
import { Department_DeliveryAddress } from '../../../Library/LanguageConversion/Department_DeliveryAddress';
import DeptDeliveryAddress from './Tabs/DeliveryAddress';
import DeptVerificationPending from './Tabs/VerificationPending';
import { api } from '../../../Library/CustomApi';
import { toast } from 'react-toastify';

interface propType {
  setOpen?: Function,
  getAddress?: Function
}

export default function AddressChange(props: propType) {

  const [tab, setTab] = useState(1)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [addressData, setAddressData] = useState([]);
  const [pendingAddress, setPendingAddress] = useState([]);
  const { language, setLanguage } = useContext(Context);

  useEffect(() => {
    getAddress()
  }, [])

  // get all addresses 
  const getAddress = () => {
    setIsLoading(true)
    api
      .get(`/address`, true)
      .then(async function ([success, response]) {
        setAddressData(response?.data?.filter((obj: any) => obj.status == '1' || obj.status == '2'));
        setPendingAddress(response?.data?.filter((obj: any) => obj.status == '0'));
        setIsLoading(false)
        return response;
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      });
  };

  // address selection handler 
  const selectAddress = (id: number) => {
    api
      .patch(
        `/addressVerify/${id}`,
        {
          status: 2,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Address Selected");
        getAddress();
        props?.setOpen && props?.setOpen(false)
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      {/* inner contents starts here */}
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <Link to='' className={tab === 1 ? "active" : ""} onClick={() => setTab(1)}>
            {Department_DeliveryAddress?.DeliveryAddress[language]}  
            </Link>
            <Link to='' className={tab === 2 ? "active" : ""} onClick={() => setTab(2)}>
            {Department_DeliveryAddress?.VerificationPending[language]} 
            </Link>
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/department/dashboard">
              {Department_DeliveryAddress?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li><b>
              {
                tab === 1 ? "Delivery Address" :
                  tab === 2 ? "Verification Pending" : null
              }
            </b></li>
          </List>
        </div>
        <div className="address-verification-outer">
          {tab === 1 ?

            // Approved tickets 
            <DeptDeliveryAddress addressData={addressData} isLoading={isLoading}
              getAddress={getAddress}
              selectAddress={selectAddress}
              setOpen={props.setOpen}
              setTab={setTab}
            /> :

            // verification pending addresses 
            <DeptVerificationPending addressData={pendingAddress} />
          }
        </div>
      </div>
    </>
  );
}
