import React, { useState,useContext } from "react";
import { Col, Row } from "reactstrap";
import { Context } from "../../../../Components/Context/Context";
import { Department_DeliveryAddress } from "../../../../Library/LanguageConversion/Department_DeliveryAddress";

interface propType {
  addressData: any
}
export default function DeptVerificationPending(props: propType) {
  const { language, setLanguage } = useContext(Context);
  return (
    <>
      <Row className="gy-4">
        <Col md={12}>
          {
            props?.addressData?.length !== 0 ? props?.addressData?.map((obj: any, i: number) => {
              return (
                <React.Fragment key={i}>
                  {obj?.status == 0 && <label htmlFor={String(i)} className="label-wrap">
                    <div className="address-verify-inner">
                      <Row>
                        <Col md={8}>
                          <div className="add-outer gap-4 ">
                            <div className="add-inner">
                              <div className="icon">
                                <i className="icons icons-address-rounded xxl"></i>
                              </div>
                              <div className="address-wrap">
                              <h5> 
                                <small>{obj?.building_no}</small>
                                <br />
                                <small>
                                  {obj?.landmark}  
                                </small>
                                <small>
                                  {obj?.city}
                                </small>
                                <br />
                                <small>
                                  {obj?.district}
                                </small>
                                <br />
                                <small>
                                  {obj?.country}
                                </small>
                                <br />
                                <small>
                                  {obj?.pincode}
                                </small>
                              </h5>
                            </div>
                            </div>
                            {/* <div className="icon">
                              <img src="/images/addressicon.svg" alt="icon" />
                            </div> */}
                            
                            
                          </div>
                        </Col>
                        <Col md={4}>
                        <div className="address-checkbox float-end">
                              <h4 className="text-warning">{Department_DeliveryAddress?.ForPending[language]}</h4>
                            </div>
                        </Col>
                        
                      </Row>
                    </div>
                  </label>}
                </React.Fragment>
              )
            })
              :
              <>
                <div className="d-flex justify-content-center">
                  <p> {Department_DeliveryAddress?.NoAddressFound[language]} </p>
                </div>
              </>
          }
        </Col>
      </Row>
    </>
  );
}