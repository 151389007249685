import { ErrorMessage, Formik } from "formik";
import {
  Button, Form,
  FormGroup,
  Input, Row,
  Label,
  Col
} from "reactstrap";
import { ComplaintValidationSchema } from "./ComplaintValidationSchema";
import TextError from "../../../../Components/Errors/TextError";
import { toast } from "react-toastify";
import React, { useState ,useContext} from "react";
import axios from "axios";
import { API_URL } from "../../../../Library/ApiSettings";
import { ticket_priority } from "../../../../Library/Constants/MapConstants";
import { Department_Orders } from "../../../../Library/LanguageConversion/Department_Orders";
import { Context } from "../../../../Components/Context/Context";
interface propType {
  allData: any,
  setOpen: Function,
  vendorId: any,
}

const ServiceRequestModal = (props: propType) => {
  const initialValues = {
    describe: "",
    contact_no: "",
    file: "",
    service_type: "",
    issue_type: ""
  };

  const {language,setLanguage}=useContext(Context);
  const [images, setImages] = useState<string[]>([]); // blob
  const handleImagechange = (e: any) => {
    const myMemoObj = URL.createObjectURL(e.target.files[0]);
    setImages([myMemoObj]);
  }

  // submit handler 
  const handleServiceReqSubmit = (values: any, actions: any) => {
    const formData = new FormData();
    formData.append("type", values.service_type,)
    formData.append("product_id", props?.allData?.order_details?.product_id)
    formData.append("vendor_id", props?.allData?.vendor_id)
    formData.append("issue_type", values.issue_type)
    formData.append("contact_no", values.contact_no)
    formData.append("describe", values.describe)
    formData.append("file[]", values.file)
    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/service-maintenance`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success("Service request sent Successfully!");
          props.setOpen(false)
        }
      })
      .catch(function (response) {
        toast.error('Unexpected error occured')
      });
  }

  return (
    <>
      <div className="add-address-wrap">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={ComplaintValidationSchema}
          onSubmit={(values, actions) => {
            handleServiceReqSubmit(values, actions)
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            handleReset,
            setFieldValue,
            touched,
            errors
          }) => {

            return (
              <>
                <Form onSubmit={handleSubmit} className="form-wrap">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="form-group">
                        <Label className="">
                           {Department_Orders?.UploadProductImage[language]}{" "}
                          {/* <i
                            className="fa fa-asterisk required-field"
                            color="text-danger"
                            aria-hidden="true"
                          ></i> */}
                        </Label>
                        <div className="image-input">
                          <Input
                            type="file"
                            name="file"
                            id="file"
                            accept="image/*"
                            // disabled={props?.show_add == true ? false : true}
                            title="Select image of product"
                            className="px-5"
                            onChange={(e: any) => {
                              handleImagechange(e)
                              setFieldValue('file', e.target.files[0])
                            }}
                          > </Input>
                          <Label className="image-button px-3" for="file" id="file">
                          {Department_Orders?.ChooseDocuments[language]}
                        </Label>
                          <ErrorMessage name="file" component={TextError} />
                        </div>
                        <ErrorMessage name="file" component={TextError} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <div className="custom-input">
                        <FormGroup className="form-group">
                          <Label>{Department_Orders?.SelectType[language]} <i
                            className="fa fa-asterisk required-field"
                            color="text-danger"
                            aria-hidden="true"
                          ></i></Label>
                          <Input type="select" onChange={handleChange} name="service_type" className={`${touched.service_type && errors.service_type ? "is-invalid" : ""}`}>
                            <option value="">{Department_Orders?.SelectRequestType[language]}</option>
                            <option value="1">{Department_Orders?.MaintenanceRequest[language]}</option>
                            <option value="2">{Department_Orders?.ServiceRequest[language]}</option>
                          </Input>
                          <ErrorMessage name="service_type" component={TextError} />
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="custom-input">
                        <FormGroup className="form-group">
                          <Label>{Department_Orders?.Priority[language]}<i
                            className="fa fa-asterisk required-field"
                            color="text-danger"
                            aria-hidden="true"
                          ></i></Label>
                          <Input type="select" onChange={handleChange} name="issue_type" className={`${touched.issue_type && errors.issue_type ? "is-invalid" : ""}`}>
                            <option value="">{Department_Orders?.SelectIssueType[language]}</option>
                            {
                              ticket_priority?.map((ob: any, i: number) => {
                                return (
                                  <React.Fragment key={i}>
                                    <option value={ob?.value}>{ob?.name}</option>
                                  </React.Fragment>
                                )
                              })
                            }
                          </Input>
                          <ErrorMessage name="issue_type" component={TextError} />
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="custom-input">
                        <FormGroup className="form-group">
                          <Label>{Department_Orders?.ContactNumber[language]}<i
                            className="fa fa-asterisk required-field"
                            color="text-danger"
                            aria-hidden="true"
                          ></i></Label>
                          <Input type="tel" onChange={handleChange} name="contact_no" className={`${touched.contact_no && errors.contact_no ? "is-invalid" : ""}`} />
                          <ErrorMessage name="contact_no" component={TextError} />
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="custom-input">
                        <FormGroup className="form-group">
                          <Label>{Department_Orders?.Describe_Issue[language]}</Label>
                          <Input type="textarea" onChange={handleChange} name="describe" className={`${touched.describe && errors.describe ? "is-invalid" : ""}`} />
                          <ErrorMessage name="describe" component={TextError} />
                        </FormGroup>
                      </div>
                    </Col>
                    
                      <FormGroup className="footer-sticky">
                        <div className="text-end">
                        <Button type="submit" color="primary">
                        {Department_Orders?.Submit[language]}
                        </Button>
                        </div>
                      </FormGroup>
                    
                  </Row>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ServiceRequestModal;