import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Table
} from "reactstrap";
import { Context } from "../../../../Components/Context/Context";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import Loader from "../../../../Components/Loader/Loader";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { API_URL } from "../../../../Library/ApiSettings";
import constants from "../../../../Library/Constants/Constants";
import {
  gst_class,
  vendor_types
} from "../../../../Library/Constants/MapConstants";
import { VendorType } from "../../../../Library/Constants/helpers";
import { api } from "../../../../Library/CustomApi";
import { admin_empanelment } from "../../../../Library/LanguageConversion/admin_empanelment";
import AddVendorModal from "../Modals/AddVendor/AddVendorModal";
import EditVendorDetails from "../Modals/EditVendorModal/EditVendorDetails";
import { EditVendorValidationSchema } from "../Modals/EditVendorModal/EditVendorValidationSchema";
import { addVendorSchema } from "../Modals/Formik Schemas";
import InviteVendor from "../Modals/InviteVendor/InviteVendor";

export default function EmpanelmentTab(props: any) {

  const { language } = useContext(Context);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [get_vendor, setGetVendor] = useState({});
  const [get_vendors, setGetVendors] = useState({});
  const [showAddVendor, setShowAddVendor] = useState(false); // toggles add vendor modal
  const [showInviteVendor, setShowInviteVendor] = useState(false); // toggles invite vendor modal
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [files, setFile] = useState<any>(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>([]);

  useEffect(() => {
    getProductsList();
  }, []);

  // =========================================================== Search ===============================================================

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getTableData(currentPage);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);

  //==================================================== Get Vendor List ==========================================

  const getTableData = (currentPage: any) => {
    setIsLoading(true);
    api
      .get(
        // `/vendors?status=1&page=${currentPage}&search_keyword=${search}`,
        `/vendors?page=${currentPage}&search_keyword=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setTableData(response?.data || []);
        setPageData(response?.meta || []);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  //==================================================== Get Product ================================================

  const getProductsList = () => {
    api
      .get(`/product/products`, true)
      .then(async function ([success, response]) {
        setProductList(response?.data || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //==================================================== Add Vendor ==============================================
  const handleSubmit = (values: any, actions: any) => {
    const formData = new FormData();
    formData.append("phone", values?.phone);
    formData.append("email", values?.email);
    formData.append("pan_number", values?.pan_number);
    formData.append("gst_number", values?.gst_number);
    formData.append("gst_class", values?.gst_class);
    formData.append("mse_status", "0");
    formData.append("full_name", values?.full_name);
    formData.append("registration_id", values?.registration_id);
    formData.append(
      "start_date",
      moment(values?.comp_inc_date).format("YYYY-MM-DD")
    );
    formData.append(
      "end_date",
      moment(values?.comp_inc_date).format("YYYY-MM-DD")
    );
    formData.append("status", "1");
    formData.append("vendor_type", values?.vendor_type);
    formData.append("name", values?.company_name);
    formData.append("type", 'vendor');
    formData.append("company_type", values?.company_type);
    formData.append(
      "empanelment_date",
      moment(values?.empanelment_date).format("YYYY-MM-DD")
    );
    formData.append(
      "expiry_date",
      moment(values?.expiry_date).format("YYYY-MM-DD")
    );
    if (files) formData.append("startup_certificate[]", files);

    axios({
      method: "post",
      url: `${API_URL}/register`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success("Vendor Added Successfully");
          setShowAddVendor(false);
          actions.resetForm();
          actions.setSubmitting(false);
          getTableData(1);
        }
      })
      .catch((err) => {
        if (err?.response?.status == 422) {
          toast.error(err?.response?.data?.message, {
            toastId: "509",
          });
          actions.setSubmitting(false);
          if (err?.response?.data?.errors?.email)
            actions?.setFieldError("email", err?.response?.data?.errors?.email);
          if (err?.response?.data?.errors?.gst_number)
            actions?.setFieldError(
              "gst_number",
              err?.response?.data?.errors?.gst_number
            );
          if (err?.response?.data?.errors?.pan_number)
            actions?.setFieldError(
              "pan_number",
              err?.response?.data?.errors?.pan_number
            );
          if (err?.response?.data?.errors?.phone)
            actions?.setFieldError("phone", err?.response?.data?.errors?.phone);
          if (err?.response?.data?.errors?.registration_id)
            actions?.setFieldError(
              "registration_id",
              err?.response?.data?.errors?.registration_id
            );
        }
        if (err?.response?.status == 500) {
          toast.error(err?.response?.data?.message, {
            toastId: "509",
          });
          actions.setSubmitting(false);
        }
      });
  };

  //========================================================= View ================================================
  const handleSend = (values: any, actions: any, get_vendor: any) => {
    sendInvite(values, actions, get_vendor);
  };
  const sendInvite = (values: any, actions: any, get_vendor: any) => {
    api
      .post(
        `/invite_vendor`,
        {
          email: get_vendor?.company?.email,
          cmpny_name: get_vendor?.company?.name,
          user_id: get_vendor?.user?.id,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Invitation Send Successfully");
        actions.setSubmitting(false);
        setShowInviteVendor(false);
        getTableData(1);
      })
      .catch((err) => {
        console.log(err);
        actions.setSubmitting(false);
        if (err?.response?.status == 422) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  const editHandler = (item: any) => {
    setEditData(item)
    setOpenEdit(true)
  }

  const editVendorHandler = (values: any, actions: any) => {
    api
      .post(
        `/order/user_edit_update`,
        {
          ...values
        },
        true
      )
      .then(async function ([success, response, status]) {
        actions?.setSubmitting(false)
        console.log(response);
        if (response?.error) {
          toast?.error(response?.message)
        }
        if (response?.success) {
          toast?.success('Success')
          getTableData(currentPage)
          setOpenEdit(false)
        }

        return response;
      })
      .catch((err) => {
        actions?.setSubmitting(false)

        if (err?.response?.status == 500) {
          toast.error(err?.response?.data?.message || 'Error');
        }
        console.log(err);
      });
  };


  return (
    <>
      <div className="search-widget-outer">
        <Form role="search" className="search">
          <InputGroup className="search-group">
            <InputGroupText
              title="Search"
              className="search-icon"
              id="search"
            >
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={admin_empanelment?. Vendor_Name[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
          {/* Mobile add button */}
          <div className="add-button mobile-view">
            <Link onClick={() => setShowAddVendor(true)} to="">
              <Button
                color="primary"
                style={{ minWidth: "auto", cursor: "pointer" }}
                className="bi bi-plus-lg"
                title="Add Vendor"
              ></Button>
            </Link>
          </div>
        </Form>
        {/* Large screen add button */}
        <div className="add-button large-screen-view">
          <Link onClick={() => setShowAddVendor(true)} to="">
            <Button
              color="primary"
              style={{ minWidth: "auto", cursor: "pointer" }}
            >
              <i className="bi bi-plus" />
              {admin_empanelment?.Add_vendor?.[language]}
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th> {admin_empanelment?.sl_no?.[language]}</th>
              <th> {admin_empanelment?.Vendor_Name?.[language]}</th>
              <th>{admin_empanelment?.Email_ID?.[language]}</th>
              <th>{admin_empanelment?.Contact_Person_Number?.[language]}</th>
              <th> {admin_empanelment?.Registration_Id?.[language]}</th>
              <th>{admin_empanelment?.Vendor_type?.[language]}</th>
              <th>{admin_empanelment?.Created_Date?.[language]}</th>
              <th> {admin_empanelment?.Status?.[language]}</th>
              <th> {admin_empanelment?.Action?.[language]}</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.length !== 0 ? (
              tableData?.map((item: any, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <tr
                      className={`${item?.status === 2 ? "inactive-row " : ""}`}
                    >
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.sl_no?.[language]}:
                        </span>
                        <span className="table-data">{pageData?.from + i}</span>
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Vendor_Name?.[language]}:
                        </span>
                        <span className="table-data">
                          {item?.company?.name}
                        </span>
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Email_ID?.[language]}:
                        </span>
                        <span className="table-data">{item.user?.email}</span>
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Contact_Number?.[language]}:
                        </span>
                        <span className="table-data">{item.user?.phone}</span>
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Registration_Id?.[language]}:
                        </span>
                        <span className="table-data">
                          {item?.company?.registration_id}
                        </span>
                      </td>

                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Vendor_type?.[language]}:
                        </span>
                        <span className="table-data">
                          {VendorType(item?.vendor_type)}
                        </span>
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Registration_Date?.[language]}:
                        </span>
                        <span className="table-data">
                          {" "}
                          {item.created_at
                            ? moment(item.created_at).format("DD-MM-YYYY")
                            : null}{" "}
                        </span>
                      </td>

                      {item?.status == constants.VENDOR_STAUS.REGISTERED ? (
                        <td>
                          <span className="mobile-heading">
                            {admin_empanelment?.Status?.[language]}:
                          </span>
                          <span className="table-data empanel-tab-icon">
                            {item?.user?.invite_status ==
                              constants.INVITE_STATUS.INVITE_NOT_SEND && (
                                <Link
                                  className="green-text no-wrap"
                                  onClick={() => {
                                    setShowInviteVendor(true);
                                    setGetVendor(item);
                                    let initialValues = {
                                      vendor_name: item?.company?.name,
                                      company_name: item?.company?.email,
                                      tender_id: item?.company_name,
                                      mobile_number: item?.company_name,
                                      email_id: item?.company_name,
                                      product: item?.company_name,
                                    };
                                    setGetVendors(initialValues);
                                  }}
                                  to=""
                                >
                                  <i className="bi bi-send"></i>
                                  {admin_empanelment?.Sent_Invitation?.[language]}
                                </Link>
                              )}

                            {item?.user?.invite_status ==
                              constants.INVITE_STATUS.INVITE_SEND && (
                                <span className=" invited-status">
                                  <i className="bi bi-envelope-open"></i>
                                  {admin_empanelment?.Invited?.[language]}
                                </span>
                              )}
                            {item?.user?.invite_status ==
                              constants.INVITE_STATUS.DOCUMENTS_UPLOADED && (
                                <span className="uploaded-status">
                                  {" "}
                                  <i className="bi bi-file-earmark-arrow-up"></i>{" "}
                                  <span>
                                    {admin_empanelment?.Uploaded?.[language]}
                                  </span>
                                </span>
                              )}
                            {item?.user?.invite_status ==
                              constants.INVITE_STATUS
                                .DOCUMENT_UNDER_VERIFICATION && (
                                <span className="info-status">
                                  {" "}
                                  <i className="bi bi-file-earmark-check"></i>{" "}
                                  <span>
                                    {
                                      admin_empanelment?.Under_Verification?.[language]
                                    }
                                  </span>
                                </span>
                              )}
                            {item?.user?.invite_status ==
                              constants.INVITE_STATUS.DOCUMENT_VERIFIED && (
                                <span className="approved-status">
                                  {" "}
                                  <i className="bi bi-file-earmark-check"></i>{" "}
                                  <span>
                                    {admin_empanelment?.Verified?.[language]}
                                  </span>
                                </span>
                              )}
                            {item?.user?.invite_status ==
                              constants.INVITE_STATUS.ACCEPTED && (
                                <span className="approved-status">
                                  {" "}
                                  <i className="bi bi-envelope-open"></i>{" "}
                                  <span>
                                    {admin_empanelment?.Accepted?.[language]}
                                  </span>
                                </span>
                              )}
                          </span>
                        </td>
                      ) : item?.status === constants?.VENDOR_STAUS.PENDING ? (
                        <td className="warning empanel-tab-icon">
                          <span className="mobile-heading">
                            {admin_empanelment?.Status?.[language]}:
                          </span>
                          <span className="table-data pending-status">
                            <i className="bi bi-clock"></i>{" "}
                            {admin_empanelment?.Pending?.[language]}
                          </span>
                        </td>
                      ) : item?.status === constants?.VENDOR_STAUS.VERIFIED ? (
                        <td className="green empanel-tab-icon">
                          <span className="mobile-heading">
                            {admin_empanelment?.Status?.[language]}:
                          </span>
                          <span className="table-data approved-status">
                            <i className="bi bi-patch-check"></i>{" "}
                            {admin_empanelment?.Verified?.[language]}
                          </span>
                        </td>
                      ) : item?.status === 2 ? (
                        <td className="danger empanel-tab-icon">
                          <span className="mobile-heading">
                            {admin_empanelment?.Status?.[language]}:
                          </span>
                          <span className="table-data inactive-status">
                            <i className="bi bi-exclamation-circle"></i>{" "}
                            {admin_empanelment?.Inactive?.[language]}
                          </span>
                        </td>
                      ) : null}
                      <td>
                        {/* Edit vendor details  */}
                        {/* Need edit option only before uploading vendor docs  */}
                        {(
                          (item?.user?.invite_status !==
                            constants.INVITE_STATUS.DOCUMENTS_UPLOADED) &&
                          (item?.user?.invite_status !==
                            constants.INVITE_STATUS.DOCUMENT_UNDER_VERIFICATION) &&
                          (item?.user?.invite_status !==
                            constants.INVITE_STATUS.DOCUMENT_VERIFIED)
                        ) && <span title="Edit Vendor Details" className="pointer" onClick={() => editHandler(item)}><i className="icons icons-view-more"></i></span>
                        }
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              // section to display loading and error messages
              <tr>
                <td colSpan={8} className="text-center px-2 py-5 empty-text">
                  {isLoading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Vendors Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {tableData?.length !== 0 && pageData && (
        <div className="table-footer-wrap">
          <small>
          {admin_empanelment?.Showing[language]}{pageData?.from} {admin_empanelment?.to[language]} {pageData?.to} {admin_empanelment?.of[language]} {pageData?.total}{" "}
          {admin_empanelment?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      {/* Add vendor modal*/}
      <Formik
        initialValues={{
          email: "",
          vendor_type: "",
          gst_class: "",
          pan_number: "",
          gst_number: "",
          company_name: "",
          comp_inc_date: "",
          full_name: "",
          phone: "",
          registration_id: "",
          company_type: "",
          empanelment_date: "",
          expiry_date: "",
        }}
        validationSchema={addVendorSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          resetForm,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <AddVendorModal
              showAddVendor={showAddVendor}
              setShowAddVendor={setShowAddVendor}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              resetForm={resetForm}
              productList={productList}
              gst_class={gst_class}
              vendor_types_data={vendor_types}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              files={files}
              setFile={setFile}
            />
          );
        }}
      </Formik>

      {/* invite vendor modal  */}
      <Formik
        initialValues={get_vendors}
        // validationSchema={inviteVendorSchema}
        onSubmit={(values, actions) => {
          handleSend(values, actions, get_vendor);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          resetForm,
          isSubmitting,
        }) => {
          return (
            <InviteVendor
              showInviteVendor={showInviteVendor}
              setShowInviteVendor={setShowInviteVendor}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              resetForm={resetForm}
              get_vendor={get_vendor}
              handleSend={handleSend}
              isSubmitting={isSubmitting}
            />
          );
        }}
      </Formik>

      {/* Edit vendor details  */}
      <ModalContainer
        open={openEdit}
        setOpen={setOpenEdit}
        header="Edit Vendor Details"
      >
        <Formik
          initialValues={{
            phone: editData?.user?.phone || '',
            email: editData?.user?.email || '',
            user_id: editData?.user?.id
          }}
          enableReinitialize
          validationSchema={EditVendorValidationSchema}
          onSubmit={(values, actions) => {
            // submit handler 
            editVendorHandler(values, actions)

          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            handleReset,
            initialValues,
          }) => {


            return (
              <>
                <EditVendorDetails
                  values={values}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  editData={editData}
                  initialValues={initialValues}
                />
              </>
            )
          }}
        </Formik>

      </ModalContainer>
    </>
  );
}
