import React, { useEffect, useState, useRef,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import {
  file_remove,
  turn_over_mark_caluction,
} from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;
export default function FinancialTurnover(props: any) {
  const {language,setLanguage}=useContext(Context);
  const firstRef = useRef<HTMLInputElement>(null);
  const secondRef = useRef<HTMLInputElement>(null);
  const thirdRef = useRef<HTMLInputElement>(null);
  const preventNegativeValues = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/; // regex pattern to match only numeric input
    if (!regex.test(keyValue)) {
      e.preventDefault();
      ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  };
  const [turn_mark, setTurnMark] = useState({
    cat: "",
    mark: "",
  });
  useEffect(() => {
    let first_yr_amount = props?.values?.Ffinancial_year_amount
      ? props?.values?.Ffinancial_year_amount
      : 0;
    let second_yr_amount = props?.values?.Sfinancial_year_amount
      ? props?.values?.Sfinancial_year_amount
      : 0;
    let third_yr_amount = props?.values?.Tfinancial_year_amount
      ? props?.values?.Tfinancial_year_amount
      : 0;

    let total = first_yr_amount + second_yr_amount + third_yr_amount;

    let mark_turn_over: any = turn_over_mark_caluction(total);

    setTurnMark({
      ...turn_mark,
      cat: mark_turn_over?.[0],
      mark: mark_turn_over?.[1],
    });
    props?.setFieldValue("mark_turn", total == 0 ? 0 : mark_turn_over?.[1]);
    props?.setFieldValue("turn_cat", total == 0 ? "" : mark_turn_over?.[0]);
  }, [
    props?.values?.Ffinancial_year_amount,
    props?.values?.Sfinancial_year_amount,
    props?.values?.Tfinancial_year_amount,
  ]);

  return (
    <>
      <div className="company-information-update-wrap">
        <h4>{Vendor_profileUpdation?.Company_Turnover?.[language]}</h4>
        <hr />
        <Row>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.First_Financial_year?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                <DatePicker
                  value={props?.values?.Ffinancial_year}
                  onChange={(e: any) => {
                    props?.setFieldValue("Ffinancial_year", e);
                    props?.setFieldValue("Sfinancial_year", "");
                  }}
                  name="Ffinancial_year"
                  format="dd-MM-y"
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  maxDate={new Date()}
                  disabled={props?.list > 0 ? true : false}
                />
                {/* ) : (
                  <Input
                    type="text"
                    name="Ffinancial_year"
                    value={`(Apr-01-${
                      props?.datas?.Ffinancial_year
                    }) - (Mar-31-${
                      Number(props?.datas?.Ffinancial_year) + 1
                    }) `}
                    disabled={props?.values?.Ffinancial_year ? true : false}
                  ></Input>
                )} */}

                <ErrorMessage name="Ffinancial_year" component={TextError} />
              </div>
            </FormGroup>
          </Col>
          <Col md={4} sm={4}>
            <FormGroup>
              <Label>
              {Vendor_profileUpdation?.First_Financial_year_Documents?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.datas?.FFfinancial_year ? (
                  <InputGroup>
                    <Input
                      id="FFfinancial_year"
                      name="FFfinancial_year"
                      className="form-control"
                      accept=".pdf"
                      value={"First Financial year Documents.pdf"}
                      disabled={props?.datas?.FFfinancial_year ? true : false}
                    ></Input>
                    <InputGroupText
                      className="input-group-text bg-white border-start-0"
                      id="newpass"
                    >
                      <a
                        href={"#"}
                        onClick={() => {
                          props?.documentdownload(
                            props?.datas?.work_order_certificate
                          );
                        }}
                      >
                        {" "}
                        <i className="bi bi-box-arrow-down"></i>
                      </a>
                    </InputGroupText>
                  </InputGroup>
                ) : (
                  <>
                    <Input
                      innerRef={firstRef}
                      type="file"
                      id="FFfinancial_year"
                      name="FFfinancial_year"
                      //className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={
                        !props?.values?.Ffinancial_year
                          ? true
                          : props?.list > 0
                          ? true
                          : false
                      }
                    ></Input>
                    <Label
                      className="image-button"
                      for="FFfinancial_year"
                      id="FFfinancial_year"
                    >
                     {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    {props?.values?.FFfinancial_year && (
                      <span
                        className="upload-text"
                        style={{
                          marginRight: "5px",
                          zIndex: "45",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          file_remove(
                            "FFfinancial_year",
                            firstRef,
                            "FFfinancial_year"
                          );
                          props?.setFieldValue("FFfinancial_year", "");
                        }}
                      >
                        &#x2715;
                      </span>
                    )}
                  </>
                )}
              </div>
              <ErrorMessage name="FFfinancial_year" component={TextError} />
            </FormGroup>
          </Col>
          <Col md={4} sm={4}>
            <div className="custom-input">
              <Label>
              {Vendor_profileUpdation?.First_Financial_year_Amount?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              {props?.list <= 0 ? (
                <Input
                  type="number"
                  name="Ffinancial_year_amount"
                  onChange={props?.handleChange}
                  value={props?.values?.Ffinancial_year_amount}
                  min="1"
                  placeholder="Amount"
                  disabled={
                    !props?.values?.Ffinancial_year
                      ? true
                      : props?.list > 0
                      ? true
                      : false
                  }
                ></Input>
              ) : (
                <Input
                  type="number"
                  name="Ffinancial_year_amount"
                  onChange={props?.handleChange}
                  value={props?.values?.Ffinancial_year_amount}
                  min="1"
                  placeholder="Amount"
                  disabled
                ></Input>
              )}
              <ErrorMessage
                name="Ffinancial_year_amount"
                component={TextError}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.Second_Financial_year?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                <br />
                {props?.list <= 0 ? (
                  <DatePicker
                    value={props?.values?.Sfinancial_year}
                    onChange={(e: any) => {
                      props?.setFieldValue("Sfinancial_year", e);
                      props?.setFieldValue("Tfinancial_year", "");
                    }}
                    name="Sfinancial_year"
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    className={`form-control ${
                      props?.touched.Sfinancial_year &&
                      props?.errors.Sfinancial_year
                        ? "is-invalid"
                        : ""
                    }`}
                    maxDate={
                      props?.list <= 0
                        ? props?.values?.Ffinancial_year
                          ? new Date(
                              `${props?.values?.Ffinancial_year?.getFullYear()}`?.toString()
                            )
                          : new Date()
                        : new Date()
                    }
                    disabled={
                      props?.list <= 0
                        ? props?.values?.Ffinancial_year
                          ? false
                          : true
                        : true
                    }
                  />
                ) : (
                  <Input
                    type="text"
                    name="Sfinancial_year"
                    value={`(Apr-01-${
                      props?.datas?.Sfinancial_year
                    }) - (Mar-31-${
                      Number(props?.datas?.Sfinancial_year) + 1
                    }) `}
                    disabled={props?.values?.Sfinancial_year ? true : false}
                  ></Input>
                )}

                <ErrorMessage name="Sfinancial_year" component={TextError} />
              </div>
            </FormGroup>
          </Col>
          <Col md={4} sm={4}>
            <FormGroup>
              <Label>
              {Vendor_profileUpdation?.Second_Financial_year_Documents?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.datas?.SFfinancial_year ? (
                  <InputGroup>
                    <Input
                      id="SFfinancial_year"
                      name="SFfinancial_year"
                      className="form-control"
                      accept=".pdf"
                      value={"Second Financial year Documents"}
                      disabled={props?.datas?.SFfinancial_year ? true : false}
                    ></Input>
                    <InputGroupText
                      className="input-group-text bg-white border-start-0"
                      id="newpass"
                    >
                      <a
                        href={"#"}
                        onClick={() => {
                          props?.documentdownload(
                            props?.datas?.SFfinancial_year
                          );
                        }}
                      >
                        {" "}
                        <i className="bi bi-box-arrow-down"></i>
                      </a>
                    </InputGroupText>
                  </InputGroup>
                ) : (
                  <>
                    {" "}
                    <Input
                      type="file"
                      id="SFfinancial_year"
                      name="SFfinancial_year"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={
                        !props?.values?.Sfinancial_year
                          ? true
                          : props?.list > 0
                          ? true
                          : false
                      }
                    ></Input>
                    <Label
                      className="image-button"
                      for="SFfinancial_year"
                      id="SFfinancial_year"
                    >
                      {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    {props?.values?.SFfinancial_year && (
                      <>
                        <span
                          className="upload-text"
                          style={{
                            marginRight: "5px",
                            zIndex: "45",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            file_remove(
                              "SFfinancial_year",
                              secondRef,
                              "SFfinancial_year"
                            );
                            props?.setFieldValue("SFfinancial_year", "");
                          }}
                        >
                          &#x2715;
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              <ErrorMessage name="SFfinancial_year" component={TextError} />
            </FormGroup>
          </Col>
          <Col sm={4} md={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.Second_Financial_year_Amount?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                {props?.list <= 0 ? (
                  <Input
                    type="number"
                    name="Sfinancial_year_amount"
                    className="form-control"
                    min="1"
                    onChange={props?.handleChange}
                    placeholder="Amount"
                    value={props?.values?.Sfinancial_year_amount}
                    disabled={
                      !props?.values?.Sfinancial_year
                        ? true
                        : props?.list > 0
                        ? true
                        : false
                    }
                  ></Input>
                ) : (
                  <Input
                    type="number"
                    name="Sfinancial_year_amount"
                    className="form-control"
                    min="1"
                    onChange={props?.handleChange}
                    placeholder="Amount"
                    value={props?.values?.Sfinancial_year_amount}
                    disabled
                  ></Input>
                )}

                <ErrorMessage
                  name="Sfinancial_year_amount"
                  component={TextError}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.Third_Financial_year?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                <br />
                {props?.list <= 0 ? (
                  <DatePicker
                    value={props?.values?.Tfinancial_year}
                    onChange={(e: any) => {
                      props?.setFieldValue("Tfinancial_year", e);
                    }}
                    name="Tfinancial_year"
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    className={`form-control ${
                      props?.touched.Tfinancial_year &&
                      props?.errors.Tfinancial_year
                        ? "is-invalid"
                        : ""
                    }`}
                    // maxDate={props?.list <= 0 ? props?.values?.Sfinancial_year ? new Date(`${(props?.values?.Sfinancial_year?.getMonth() + 1).toString().padStart(2, "0")}-${props?.values?.Sfinancial_year?.getDate().toString().padStart(2, "0")}-${props?.values?.Sfinancial_year?.getFullYear() - 1}`?.toString()) : new Date() : new Date()}
                    maxDate={
                      props?.list <= 0
                        ? props?.values?.Sfinancial_year
                          ? new Date(
                              `${props?.values?.Sfinancial_year?.getFullYear()}`?.toString()
                            )
                          : new Date()
                        : new Date()
                    }
                    disabled={
                      props?.list <= 0
                        ? props?.values?.Sfinancial_year
                          ? false
                          : true
                        : true
                    }
                  />
                ) : (
                  <Input
                    type="text"
                    name="Tfinancial_year"
                    className="form-control"
                    value={`(Apr-01-${
                      props?.datas?.Tfinancial_year
                    }) - (Mar-31-${
                      Number(props?.datas?.Tfinancial_year) + 1
                    }) `}
                    disabled={props?.values?.Tfinancial_year ? true : false}
                  ></Input>
                )}
                <ErrorMessage name="Tfinancial_year" component={TextError} />
              </div>
            </FormGroup>
          </Col>

          <Col md={4} sm={4}>
            <FormGroup>
              <Label>
                {Vendor_profileUpdation?.Third_Financial_year_Documents?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.datas?.TFfinancial_year ? (
                  <InputGroup>
                    <Input
                      id="Tfinancial_year"
                      name="TFfinancial_year"
                      className="form-control"
                      accept=".pdf"
                      disabled={props?.datas?.TFfinancial_year ? true : false}
                      value={"Third Financial year Documents.pdf"}
                    ></Input>
                    <InputGroupText
                      className="input-group-text bg-white border-start-0"
                      id="newpass"
                    >
                      <a
                        href={"#"}
                        onClick={() => {
                          props?.documentdownload(
                            props?.datas?.TFfinancial_year
                          );
                        }}
                      >
                        {" "}
                        <i className="bi bi-box-arrow-down"></i>
                      </a>
                    </InputGroupText>
                  </InputGroup>
                ) : (
                  <>
                    <Input
                      type="file"
                      id="Tfinancial_year"
                      name="TFfinancial_year"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={
                        !props?.values?.Tfinancial_year
                          ? true
                          : props?.list > 0
                          ? true
                          : false
                      }
                    ></Input>
                    <Label
                      className="image-button"
                      for="Tfinancial_year"
                      id="Tfinancial_year"
                    >
                      {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    {props?.values?.Tfinancial_year && (
                      <>
                        <span
                          className="upload-text"
                          style={{
                            marginRight: "5px",
                            zIndex: "45",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            file_remove(
                              "Tfinancial_year",
                              thirdRef,
                              "Tfinancial_year"
                            );
                            props?.setFieldValue("Tfinancial_year", "");
                          }}
                        >
                          &#x2715;
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              <ErrorMessage name="TFfinancial_year" component={TextError} />
            </FormGroup>
          </Col>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.Third_Financial_year_Amount?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                {props?.list <= 0 ? (
                  <Input
                    type="number"
                    name="Tfinancial_year_amount"
                    onChange={props?.handleChange}
                    min="1"
                    className="form-control"
                    placeholder="Amount"
                    disabled={
                      !props?.values?.Tfinancial_year
                        ? true
                        : props?.list > 0
                        ? true
                        : false
                    }
                    value={props?.values?.Tfinancial_year_amount}
                  ></Input>
                ) : (
                  <Input
                    type="number"
                    name="Tfinancial_year_amount"
                    onChange={props?.handleChange}
                    min="1"
                    className="form-control"
                    placeholder="Amount"
                    disabled
                    value={props?.values?.Tfinancial_year_amount}
                  ></Input>
                )}
                <ErrorMessage
                  name="Tfinancial_year_amount"
                  component={TextError}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <div>{Vendor_profileUpdation?.Approved_by_Chartered?.[language]}</div>
        <Row>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.CA_Name?.[language]}{" "}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="financial_turn_over_ca_name"
                    type="text"
                    value={props?.values?.financial_turn_over_ca_name}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="financial_turn_over_ca_name"
                    type="text"
                    value={props?.values?.financial_turn_over_ca_name}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="financial_turn_over_ca_name"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.CA_Email?.[language]}{" "}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="financial_turn_over_ca_email"
                    type="email"
                    value={props?.values?.financial_turn_over_ca_email}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="financial_turn_over_ca_email"
                    type="email"
                    value={props?.values?.financial_turn_over_ca_email}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="financial_turn_over_ca_email"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.CA_Phone_Number?.[language]}{" "}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="financial_turn_over_ca_phone"
                    type="tel"
                    onKeyPress={preventNegativeValues}
                    value={props?.values?.financial_turn_over_ca_phone}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="financial_turn_over_ca_phone"
                    type="tel"
                    onKeyPress={preventNegativeValues}
                    value={props?.values?.financial_turn_over_ca_phone}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="financial_turn_over_ca_phone"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={12} className="mt-5">
            {" "}
            {props?.values?.Ffinancial_year_amount &&
            props?.values?.Sfinancial_year_amount &&
            props?.values?.Tfinancial_year_amount ? (
              <span className="text-success mark-end">
                <div>{Vendor_profileUpdation?.Category?.[language]}:{turn_mark?.cat}</div>
                <div>{Vendor_profileUpdation?.Mark?.[language]}:{turn_mark?.mark}</div>
              </span>
            ) : null}
          </Col>
        </Row>
      </div>
    </>
  );
}
