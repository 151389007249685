import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface DataPoint {
  name: any;
  value: any;
}

interface Props {
  data: DataPoint[];
}

const AreaChartData: React.FC<Props> = ({ data }) => {
  return (
    <ResponsiveContainer height={190}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="Orders" stroke="#1a1167" fill="#1a1167" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartData;
