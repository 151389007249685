import * as Yup from "yup";

//

const CommonValidation = Yup.object().shape({
  pan: Yup.string().required("Field is required"),
});

const FinancialValidationOne = Yup.object().shape({
  financial_turn_over_ca_name: Yup.string().required("Field is required"),
  financial_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  financial_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
    .min(10, "Phone number must be at least 10 digits"),
  Ffinancial_year_amount: Yup.string().required("Field is required"),
  FFfinancial_year: Yup.string().required("Field is required"),
  Ffinancial_year: Yup.mixed().required("Field is required"),
});
const FinancialValidationTwo = Yup.object().shape({
  financial_turn_over_ca_name: Yup.string().required("Field is required"),
  financial_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  financial_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits"),
  Sfinancial_year_amount: Yup.string().required("Field is required"),
  Sfinancial_year: Yup.string().required("Field is required"),
  SFfinancial_year: Yup.mixed().required("Field is required"),
});
const FinancialValidationThree = Yup.object().shape({
  financial_turn_over_ca_name: Yup.string().required("Field is required"),
  financial_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  financial_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
    .min(10, "Phone number must be at least 10 digits"),
  Tfinancial_year_amount: Yup.string().required("Field is required"),
  Tfinancial_year: Yup.string().required("Field is required"),
  TFfinancial_year: Yup.mixed().required("Field is required"),
});
const OEMValiadtion = Yup.object().shape({
  oem_certificate: Yup.string().required("Field is required"),
  oem_in_company_type: Yup.string().required("Field is required"),
  oem_in_company_valid_from: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  oem_in_company_valid_to: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
});

const EmpanelValiadtion = Yup.object().shape({
  empanelment_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  expiry_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  empanel_certificate: Yup.string().required("Field is required"),
});

const WorkOrderValidation = Yup.object().shape({
  work_order_certificate: Yup.string().required("Field is required"),
  work_order_no_of_projects: Yup.number().required("Field is required"),
});

const ManpowerValidation = Yup.object().shape({
  manpower_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  manpower_number: Yup.number().required("Field is required"),
  manpower_documents: Yup.string().required("Field is required"),
});

const LandValidation = Yup.object().shape({
  land_document: Yup.string().required("Field is required"),
  land_village_col: Yup.string().required("Field is required"),
  land_extent: Yup.string().required("Field is required"),
  land_value: Yup.number().required("Field is required"),
  land_survey_no: Yup.string().required("Field is required"),
});
const AnyOtherValidation = Yup.object().shape({
  any_other_company_pincode: Yup.number().required("Field is required"),
  any_other_company_documents: Yup.string().required("Field is required"),
  any_other_company_name: Yup.string().required("Field is required"),
  any_other_company_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
});

const ITValidationOne = Yup.object().shape({
  it_turn_over_first_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  it_turn_over_first_document: Yup.string().required("Field is required"),

  it_turn_over_first_amount: Yup.number().required("Field is required"),

  it_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required")
    .nullable(),
  it_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")

    .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
    .min(10, "Phone number must be at least 10 digits"),
  it_turn_over_ca_name: Yup.string().required("Field is required"),
});
const ITValidationTwo = Yup.object().shape({
  it_turn_over_second_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  it_turn_over_second_document: Yup.string().required("Field is required"),

  it_turn_over_second_amount: Yup.number().required("Field is required"),

  it_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required")
    .nullable(),
  it_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")

    .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
    .min(10, "Phone number must be at least 10 digits"),
  it_turn_over_ca_name: Yup.string().required("Field is required"),
});

export {
  CommonValidation,
  FinancialValidationThree,
  FinancialValidationTwo,
  FinancialValidationOne,
  OEMValiadtion,
  EmpanelValiadtion,
  WorkOrderValidation,
  ManpowerValidation,
  LandValidation,
  AnyOtherValidation,
  ITValidationOne,
  ITValidationTwo
};
