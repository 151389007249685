import React, { useState,useContext} from 'react'
import DatePicker from 'react-date-picker'
import { Button, Label, Spinner } from 'reactstrap'
import getExportData from '../../../Components/CsvExport/CsvExport'
import { Context } from "../../../Components/Context/Context";
import { Admin_Reports} from "../../../Library/LanguageConversion/Admin_Reports"
import { Link } from 'react-router-dom'


interface propType {
    NAME: string,
    URL: string,
    HideButton?: boolean
}
const ReportSection = ({ NAME, URL, HideButton }: propType) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>(null)
    const [fromDateFilter, setFromDateFilter] = useState<string>('')
    const [toDateFilter, setToDateFilter] = useState<string>('')
    const minToDate = fromDate ? new Date(fromDate) : undefined;

    const fromDateHandler = (date: Date | null) => {
        setFromDate(date);
        setToDate(null)
        setToDateFilter('')
        const formattedDate = date ? date.toISOString().split("T")[0] : "";
        setFromDateFilter(formattedDate)
    }

    const toDateHandler = (date: Date | null) => {
        setToDate(date);
        const formattedDate = date ? date.toISOString().split("T")[0] : "";
        setToDateFilter(formattedDate)
    }

    const clearFilterHandler = () => {
        setFromDate(null)
        setFromDateFilter('')
        setToDate(null)
        setToDateFilter('')
    }

    const { language, setLanguage } = useContext(Context);
    return (
        <div className="item-flex">
            <p className="label" title={NAME}>{NAME}</p>
            {!HideButton && <>
                <div className="date-group">
                    <Label>{Admin_Reports?.From[language]}:</Label>
                    <DatePicker
                        name="validfrom"
                        className="form-control"
                        format={"dd-MM-y"}
                        dayPlaceholder={"dd"}
                        monthPlaceholder={"mm"}
                        yearPlaceholder={"yyyy"}
                        value={fromDate}
                        onChange={(e: any) => fromDateHandler(e)}
                    />
                </div>
            </>}


            {!HideButton && <>
                <div className="date-group">
                    <Label>To:</Label>
                    <DatePicker
                        name="validfrom"
                        className="form-control"
                        format={"dd-MM-y"}
                        dayPlaceholder={"dd"}
                        monthPlaceholder={"mm"}
                        yearPlaceholder={"yyyy"}
                        value={toDate}
                        onChange={(e: any) => toDateHandler(e)}
                        minDate={minToDate}
                    />
                </div>

                <span className="report-reset">
                    {(fromDate || toDate) &&
                        <i  
                            onClick={clearFilterHandler}
                            title='Reset Date Filter'
                        ><Link to="">{Admin_Reports?.Reset[language]}</Link></i>
                    }
                    {/* empty date field */}
                    {(!fromDate && !toDate) &&
                        <i  
                            onClick={clearFilterHandler}
                            title='Reset Date Filter '
                        ><Link to="" className='disabled'>{Admin_Reports?.Reset[language]}</Link></i>
                    }
                </span>

            </>}

            <div className="export-btn-wrap">
                {/*BUtton Tooltip section */}
                <div className="export-tooltip">
                    <small>{Admin_Reports?.Export[language]} {NAME}</small>
                </div>
                <Button
                    onClick={() => getExportData(URL, NAME, fromDateFilter, toDateFilter, setIsLoading)}
                    color="primary" className="export-btn"
                    disabled={isLoading}
                >
                    {isLoading ?
                        <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                        />
                        :
                        <span>
                            <i className="bi bi-box-arrow-up-right" />{"  "}
                            {Admin_Reports?.Export[language]}
                        </span>
                    }
                </Button>
            </div>
        </div>)
}

export default ReportSection