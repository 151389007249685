import React, { useState, useEffect,useContext } from 'react'
import { Button, Form, Input, InputGroup, InputGroupText, Table } from 'reactstrap'
import ModalContainer from '../../../Components/Modal/ModalContainer'
import AddDepartmentType from './Modal/AddDepartmentType'
import { api } from '../../../Library/CustomApi'
import CustomPagination from '../../../Components/CustomPagination/Index'
import Loader from '../../../Components/Loader/Loader'
import { Context } from '../../../Components/Context/Context';
import { Admin_Departlist } from '../../../Library/LanguageConversion/Admin_Departlist'

const Departments = () => {

    const [open, setOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [search, setSearch] = useState<string>('')
    const [deptData, setDeptData] = useState<any>([])
    const [pageData, setPageData] = useState<any>([])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search !== null || search === "") {
                getDepartmentsList()
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [currentPage, search])

    const getDepartmentsList = () => {
        setIsLoading(true)
        api
            .get(`/get_dept_type?page=${currentPage}&search_value=${search}`, true)
            .then(async function ([success, response]) {
                setDeptData(response?.data)
                setPageData(response?.meta)
                setIsLoading(false)
                return response;
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            });
    };
const{ language,setLanguage } = useContext(Context);

    return (
        <>
            <div className="right-content-wrap">
                        {/* Search */}
                        <div className="search-widget-outer">
                            <Form role="search">
                                <InputGroup className="search-group">
                                    <InputGroupText title='Department/Name' className="search-icon" id="search">
                                        <i className="fa-sharp fa-solid fa-magnifying-glass" />
                                    </InputGroupText>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder={Admin_Departlist?.Department_Name?.[language]}
                                        aria-label="Username"
                                        aria-describedby="search"
                                        onChange={(e: any) => {
                                            setCurrentPage(1)
                                            setSearch(e.target.value)
                                        }} 
                                    />
                                </InputGroup>
                                <div className="add-button">
                                    <Button onClick={() => setOpen(true)} className=" primary-btn" color="primary">
                                        <span className="large-screen-view"> <i className="bi bi-plus" />
                                        {Admin_Departlist?.AddDepartment?.[language]}</span>
                                        <span className="mobile-view"><i className="bi bi-plus-lg"></i></span>
                                    </Button>
                                </div>
                            </Form>                
                        </div>
                        {/* Table list */}
                        <div className="table-wrap responsive-table">
                            <Table className="w-100 table-striped"> 
                                <thead>
                                    <tr>
                                        <th>{Admin_Departlist?.SlNo[language]}</th>
                                        <th>{Admin_Departlist?.Name[language]}</th>
                                        <th>{Admin_Departlist?.Status[language]}</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (deptData?.length !== 0 && !isLoading) ?
                                            deptData?.map((it: any, i: number) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <span className="mobile-heading">{Admin_Departlist?.SlNo[language]}:</span>
                                                            <span className="table-data">
                                                                {pageData?.from + i}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-heading">{Admin_Departlist?.Name[language]}:</span>
                                                            <span className="table-data">
                                                                {it?.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-heading">{Admin_Departlist?.Status[language]}:</span>
                                                            <span className="table-data">
                                                                {it?.status == '1' ? <span className='approved-status'>{Admin_Departlist?.Active?.[language]}</span> : 
                                                                <span className='rejected-status'>{Admin_Departlist?.Inactive?.[language]}</span> }
                                                            </span>
                                                        </td>
                                                        {/* <td>
                                                        <span className="mobile-heading">Action:</span>
                                                        <span className="table-data">

                                                        </span>
                                                    </td> */}
                                                    </tr>
                                                )
                                            })
                                            :
                                            isLoading ?
                                                <tr className='text-center'>
                                                    <td colSpan={3}>
                                                        <Loader />
                                                    </td>
                                                </tr> :
                                                <tr className='text-center'>
                                                    <td colSpan={3}>
                                                    {Admin_Departlist?.NoDepartmentFound[language]}
                                                    </td>
                                                </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>

                        {pageData && deptData.length > 0 && <div className="table-footer-wrap">
                            <small> {Admin_Departlist?.Showing[language]} {pageData.from}{Admin_Departlist?.to[language]} {pageData.to}{Admin_Departlist?.of[language]} {pageData?.total} {Admin_Departlist?.entries[language]}</small>
                            <div className="pagination-outer">
                                <CustomPagination
                                    totalItems={pageData?.total}
                                    itemsPerPage={pageData?.per_page}
                                    activePage={pageData?.current_page}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        </div>}
            </div>

            <ModalContainer
                open={open}
                setOpen={setOpen}
                header='Add Department'
            >
                <AddDepartmentType
                    setOpen={setOpen}
                    getDepartmentsList={getDepartmentsList}
                />
            </ModalContainer>
        </>
    )
}

export default Departments