import { useContext } from 'react'
import FooterEnglish from './EnglishComponents/FooterEnglish';
import FooterKanada from './KanadaComponents/FooterKanada';
import { Context } from '../../Components/Context/Context';

const Footer = () => {
    const { language } = useContext(Context);

    return (
        language == "en" ? <FooterEnglish/> : <FooterKanada/>
    )
}

export default Footer;