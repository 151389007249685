import moment from "moment";
import React, { useEffect, useState, useRef,useContext } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Collapse,
  Table,
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import Toast from "reactstrap";
import { toast } from "react-toastify";

import TextError from "../../../../Components/Errors/TextError";
import { ErrorMessage } from "formik";
import { API_URL } from "../../../../Library/ApiSettings";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { Formik } from "formik";
import EXIF from "exif-js";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { EmdCalculation, PGCalculation } from "./../EmdCalculation";
import { Value } from "sass";
import axios from "axios";
import TrackOrder from "../TrackOders";
import CommonOrderView from "../CommonOrderView";
import DatePicker from "react-date-picker";
import { preventNegativeValues } from "../../../../Library/Constants/helpers";
import { VendorRegLang } from "../../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../../Components/Context/Context";

// const {language,setLanguage}=useContext(Context)
const OutForDelivaryOrderModal = (props: any) => {
  const {language,setLanguage} = useContext(Context)

  const order_data = props?.order_view?.[0];
  const [images_, setImages] = useState([]);
  const [file_name, setFileName] = useState("");
  const ref = useRef<any>();
  const [data, setData] = useState([]);
  const handleFilechange = (e: any, values: any, field: string) => {
    const myMemoObj = URL.createObjectURL(e.target.files[0]);

    if (field == "wou") {
      props?.setFieldValue("wou", e.target.files[0]);
      props?.setFieldTouched("wou", false, false);
    } else {
      if (e.target.files.length <= 1) {
        var datas = EXIF.getData(e.target.files[0], function () {
          EXIF.getAllTags(e.target.files[0]);
          var latDegree = e?.target?.files[0]?.exifdata?.GPSLatitude;
          var lonDegree = e?.target?.files[0]?.exifdata?.GPSLongitude;

          if (latDegree == "" || latDegree == null || latDegree == undefined) {
            toast.error("Please Upload Image With Location Details");
          }

          var latitudes = latDegree[0]?.numerator
            ? [
                latDegree[0]?.numerator,
                latDegree[1]?.numerator,
                latDegree[2]?.numerator / latDegree[2]?.denominator,
              ]
            : [latDegree[0], latDegree[1], latDegree[2]];
          var longitudes = lonDegree[0]?.numerator
            ? [
                lonDegree[0]?.numerator,
                lonDegree[1]?.numerator,
                lonDegree[2]?.numerator / lonDegree[2]?.denominator,
              ]
            : [lonDegree[0], lonDegree[1], lonDegree[2]];
          const latitude =
            latitudes[0] + latitudes[1] / 60 + latitudes[2] / 3600;
          const longitude =
            longitudes[0] + longitudes[1] / 60 + longitudes[2] / 3600;

          if (latDegree == "" || latDegree == null || latDegree == undefined) {
            toast.error("Please Upload Image With Location Details");
            props?.setLatitudess("");
            props?.setLongitudess("");
          } else {
            props?.setLocation([
              ...props?.location,
              {
                images: e?.target?.files[0],
                latitude: latitude,
                longitude: longitude,
              },
            ]);
            props?.setFieldTouched("location", false, false);
            var imgval = document.getElementById("img") as HTMLInputElement;
            imgval.value = "";
          }
        });
      } else {
        const selectedFiles = e.target.files;
        var filesArray: any = Array.from(selectedFiles);
        setImages(filesArray);
        filesArray?.forEach((item: any, index: any) => {
          EXIF.getData(item, async function () {
            EXIF.getAllTags(item);
            var latDegree = item?.exifdata?.GPSLatitude;
            var lonDegree = item?.exifdata?.GPSLongitude;

            if (!latDegree) {
              toast.error("Please Upload Image With Location Details");
            } else {
              var latitudes = latDegree[0]?.numerator
                ? [
                    latDegree[0]?.numerator,
                    latDegree[1]?.numerator,
                    latDegree[2]?.numerator / latDegree[2]?.denominator,
                  ]
                : [latDegree[0], latDegree[1], latDegree[2]];
              var longitudes = lonDegree[0]?.numerator
                ? [
                    lonDegree[0]?.numerator,
                    lonDegree[1]?.numerator,
                    lonDegree[2]?.numerator / lonDegree[2]?.denominator,
                  ]
                : [lonDegree[0], lonDegree[1], lonDegree[2]];
              item.latitude =
                latitudes[0] + latitudes[1] / 60 + latitudes[2] / 3600;
              item.longitude =
                longitudes[0] + longitudes[1] / 60 + longitudes[2] / 3600;

              props?.setLocation([
                ...props?.location,
                ...filesArray.filter((item: any) => item?.latitude),
              ]);
              props?.setFieldTouched("location", false, false);
              var imgval = document.getElementById("img") as HTMLInputElement;
              imgval.value = "";
              return;
            }
          });
        });
      }
    }

    props?.setFieldValue("order_id", props?.orderIds);
  };
  const removeImg = async (index: any) => {
    props?.location?.splice(index, 1);
    await props?.setLocation([...props?.location]);
  };
  const downloadinvoice = () => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      // body: JSON.stringify({
      //     orderId: props?.order_view[0]?.vendor_po_no
      //})
    };
    fetch(
      `${API_URL}/order/invoice-download?orderId=${props?.order_view[0]?.vendor_po_no}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Work Order.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        props?.setIsShowing(false);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  const work_order_download = () => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(
      `${API_URL}/order/purchase-order-download-department/${props?.value?.orderid}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "workorder.doc";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        // setIsShowing(false);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
          setImages([]);
        }}
        header="Order View"
      >
        <div className="feeback-outer-wrap">
          <div className="feedback-main-heading">
            <TrackOrder order_view={order_data} />

            <h2>{VendorRegLang?.OrderDetails?.[language]}</h2>
          </div>
          <div className="feedback-wrap">
            <div className="feedback-details">
              <Form className="form-wrap">
                <CommonOrderView order_data={order_data} />
                <div className="Order-status">
                  <h3>{VendorRegLang?.WorkCompletionDetails?.[language]}</h3>
                </div>

                <div className="button-flex mt-4">
                  <Col md={12} sm={12}>
                    <Button
                      type="button"
                      className="btn btn-primary px-5 w-100"
                      onClick={() => work_order_download()}
                    >
                      {VendorRegLang?.Work_Completion_download?.[language]}
                      <i className="fa-solid fa-download ms-1"></i>
                    </Button>
                  </Col>
                </div>

                <div className="Order-status">
                  <FormGroup>
                    <Label>
                    {VendorRegLang?.Upload_work_completion?.[language]}
                      <i
                        className="fa fa-asterisk required-field"
                        color="text-danger"
                        aria-hidden="true"
                      ></i>
                    </Label>
                    <Col md-8 lg-8>
                      <div className="image-input px-3">
                        <Input
                          ref={ref}
                          type="file"
                          name="wou"
                          accept=".pdf"
                          //disabled={props?.show_add == true ? false : true}
                          title=""
                          multiple
                          onChange={(e: any) =>
                            handleFilechange(e, e.target.files[0], "wou")
                          }
                          id="img"
                          className={`form-control ${
                            props?.touched.wou && props?.errors.wou
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <Label className="image-button" for="wou" id="img">
                        {VendorRegLang?.ChooseDocuments?.[language]}
                        </Label>
                        {/* <span className="upload-text">Upload the Challan(s){file_name}</span> */}
                      </div>
                    </Col>
                    <ErrorMessage name="wou" component={TextError} />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                    {VendorRegLang?.UploadtheProductsimage?.[language]}{" "}
                      <i
                        className="fa fa-asterisk required-field"
                        color="text-danger"
                        aria-hidden="true"
                      ></i>
                    </Label>
                    <Col md-8 lg-8>
                      <div className="image-input px-3">
                        <Input
                          ref={ref}
                          type="file"
                          name="location"
                          accept="file_extension|.gif, .jpg, .png"
                          //disabled={props?.show_add == true ? false : true}
                          title=""
                          multiple
                          onChange={(e: any) =>
                            handleFilechange(e, e.target.files[0], "images")
                          }
                          id="img"
                          className={`form-control ${
                            props?.touched.location && props?.errors.location
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <Label className="image-button" for="location" id="img">
                        {VendorRegLang?.ChooseDocuments?.[language]}
                        </Label>
                        {/* <span className="upload-text">Upload the Challan(s){file_name}</span> */}
                      </div>
                    </Col>
                    <ErrorMessage name="location" component={TextError} />
                  </FormGroup>
                  <div>
                    <Row>
                      {props?.location.length > 1
                        ? props?.location?.map((val: any, ind: any) => {
                            //console.log(val,"vuffhfufjfu")
                            return val?.latitude != "" || null || undefined ? (
                              <>
                                <Col sm={3} md={3} className="m-3 ">
                                  <div className="custom-input">
                                    <span
                                      onClick={() => removeImg(ind)}
                                      style={{
                                        color: "red",
                                        position: "absolute",
                                        left: "0px",
                                        fontWeight: "bold",
                                        padding: "7px",
                                        border: "2px solid red",
                                        borderRadius: "100%",
                                        lineHeight: "1rem",
                                        margin: "5px",
                                      }}
                                    >
                                      X
                                    </span>
                                    <img
                                      src={
                                        val?.images
                                          ? URL.createObjectURL(val?.images)
                                          : URL.createObjectURL(val)
                                      }
                                      style={{
                                        height: "200px",
                                        width: "200px",
                                      }}
                                    />
                                    <p> {VendorRegLang?.Latitude?.[language]} : {val?.latitude}</p>
                                    <p> {VendorRegLang?.Longitude?.[language]} : {val?.longitude}</p>
                                  </div>
                                  <hr />
                                </Col>
                              </>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                    </Row>

                    <div className="Order-status">
                      <Label>
                      {VendorRegLang?.OrderStatus?.[language]}  {" "}
                        <i
                          className="fa fa-asterisk required-field"
                          color="text-danger"
                          aria-hidden="true"
                        ></i>
                      </Label>

                      <Input
                        type="select"
                        name="order_status"
                        value={props?.values?.order_status}
                        data={data}
                        onChange={props?.handleChange}
                        className={`form-control  mt-2 ${
                          props?.touched.order_status &&
                          props?.errors.order_status
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <option value="">{VendorRegLang?.SelectOrderStatus?.[language]}</option>
                        {props?.order_status?.map((item: any, i: number) => {
                          return (
                            <React.Fragment key={item?.value}>
                              <option key={i} value={item?.value}>
                                {" "}
                                {item?.name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </Input>

                      <ErrorMessage name="order_status" component={TextError} />
                      {props?.values?.order_status == 10 ? (
                        <>
                          <Input
                            name="rejection_reasons"
                            type="text"
                            value={props?.values?.rejection_reasons}
                            className={`form-control mt-4 ${
                              props?.touched.rejection_reasons &&
                              props?.errors.rejection_reasons
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={props?.handleChange}
                            placeholder="please enter reject reasons"
                          />
                          <ErrorMessage
                            name="rejection_reasons"
                            component={TextError}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <Row className="text-end">
                    <div></div>
                    <div className="vendor-order-buttons">
                      <div className="button-flex mt-4">
                        <Button
                          color="success"
                          className="btn"
                          onClick={() => downloadinvoice()}
                        >
                          {VendorRegLang?.DownloadWorkOrder?.[language]} 
                        </Button>
                      </div>
                      <div className="button-flex-outer">
                        {props?.stat == 7 ? (
                          <div className="button-flex">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={props?.handleSubmit}
                              className={"px-5"}
                            >
                              {VendorRegLang?.Save?.[language]}
                            </Button>
                          </div>
                        ) : (
                          <div className="button-flex mt-4">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={props?.handleSubmit}
                              className={"px-5"}
                              disabled={
                                props?.stat == 9
                                  ? props?.location?.length == 1
                                  : false
                              }
                            >
                               {VendorRegLang?.Save?.[language]}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default OutForDelivaryOrderModal;
