import React, { useEffect ,useContext} from "react";
import { Context } from "../../../Components/Context/Context";
import { Vendor_Services } from "../../../Library/LanguageConversion/Vendor_Services";
import { useState, useMemo } from "react";
import {
    Button,
    Table,
    Form,
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import constants from "../../../Library/Constants/Constants";
import { api } from "../../../Library/CustomApi";

export default function TicketsVendor(props: any) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [received_order, setReceivedOrder] = useState([]);
    const [isShowing, setIsShowing] = useState(false);
    const { language, setLanguage } = useContext(Context);
    const handleSubmit = (values: any, actions: any) => { };
    useEffect(() => {
        getReceivedOrder();
    }, []);
    // ========================================================== Get Recieved Order ==========================================================

    const getReceivedOrder = () => {
        setLoading(true);
        api
            .get(`/service-maintenance`, true)
            .then(async function ([success, response]) {
                setReceivedOrder(response?.data || []);
                setLoading(false);
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };
    // ================================================================ View ==================================================================

    return (
        <>
            <div className="custom-tab-content">
                <div className="table-outer-wrap">
                    <div className="search-widget-outer">
                        <Form role="search">
                            <InputGroup className="search-group">
                                <InputGroupText className="search-icon" id="search">
                                    <i title="Search here" className="fa-sharp fa-solid fa-magnifying-glass" />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here"
                                    aria-label="Username"
                                    aria-describedby="search"
                                />
                            </InputGroup>
                        </Form>
                    </div>
                    <div className="table-wrap responsive-table">
                        <Table className="w-100">
                            <thead>
                                <tr>
                                    <th> {Vendor_Services?.Order_ID[language]}</th>
                                    <th>  {Vendor_Services?.Tender_ID[language]}</th>
                                    <th> {Vendor_Services?.Order_Details[language]}</th>
                                    <th> {Vendor_Services?.Shipping_Address[language]}</th>
                                    <th> {Vendor_Services?.Quantity[language]}</th>
                                    <th> {Vendor_Services?.Department[language]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span className="mobile-heading">{Vendor_Services?.Order_ID[language]}:</span><span className="table-data">124512</span></td>
                                    <td><span className="mobile-heading">{Vendor_Services?.Tender_ID[language]}:</span><span className="table-data">253659</span></td>
                                    <td><span className="mobile-heading">{Vendor_Services?.Order_Details[language]}:</span><span className="table-data">
                                        Dell 23.8 inch Full HD VA Panel 3-Side Near Edgeless with TUV
                                        Eye Care Monitor
                                        </span>
                                    </td>
                                    <td><span className="mobile-heading">{Vendor_Services?.Shipping_Address[language]}:</span><span className="table-data">Bannerghatta Main Rd, Bengaluru, Karnataka 560029</span></td>
                                    <td><span className="mobile-heading">{Vendor_Services?.Quantity[language]}:</span><span className="table-data">200</span></td>
                                    <td>
                                        <Link
                                            to=""
                                            onClick={() => setIsShowing(true)}
                                            className="text-decoration-none"
                                        >
                                            {Vendor_Services?.Water_Authority[language]}
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="table-footer-wrap">
                        <small>{Vendor_Services?.showing[language]} 1 {Vendor_Services?.to[language]} 10 {Vendor_Services?.of[language]} 57 {Vendor_Services?.entries[language]}</small>
                        <div className="pagination ">
                            <Button tag="a" href="#" >
                                <i className="bi bi-chevron-left"></i>
                            </Button>
                            <Button
                                tag="a"
                                href="#"

                                className="active"
                            >
                                1
                            </Button>
                            <Button tag="a" href="#" >
                                2
                            </Button>
                            <Button tag="a" href="#" >
                                3
                            </Button>
                            <Button tag="a" href="#" >
                                4
                            </Button>
                            <Button tag="a" href="#" >
                                5
                            </Button>
                            <Button tag="a" href="#" >
                                6
                            </Button>
                            <Button tag="a" href="#" >
                                <i className="bi bi-chevron-right"></i>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
