import { Row, Col, Button, Spinner, AccordionItem, AccordionHeader, AccordionBody, UncontrolledAccordion } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState,useContext } from 'react';
import Paypal from '../../../Components/PaymentGateway';
import RightSection from './PaymentMethodComponents/RightSection';
import PaymentMode from './Tabs/PaymentMode';
import { toast } from 'react-toastify';
import constants from '../../../Library/Constants/Constants';
import ViewOrderCard from './ViewOrderCard';
import axios from 'axios';
import { API_URL } from '../../../Library/ApiSettings';
import { PMU_BANK_DETAILS } from '../../../Library/Constants/MapConstants';
import AccountDetails from './PaymentMethodComponents/AccountDetails';
import PaymentTypesSelector from './PaymentMethodComponents/PaymentTypesSelector';
import { DepartmentProducts } from '../../../Library/LanguageConversion/Department_Products';
import { Context } from '../../../Components/Context/Context';
export default function ProformaCheckOut() {
  const { state } = useLocation();
  const navigate = useNavigate()
  const [selectedMethod, setSelectedMethod] = useState<number>(0)
  const [id, setId] = useState<number>(0)
  const [amount, setAmount] = useState<number>(0)
  const [proformaData, setProformaData] = useState<any>([])
  const [order_id, setOrder_id] = useState<number>(0)
  const [partialAmount, setPartialAmount] = useState<number>(0)
  const [selectedMode, setSelectedMode] = useState<number>(0)//  partial payment-PP,full payment-FP,pay later- PL
  const [proformaDownloading, setProformaDownloading] = useState(false)
  const [creditDownloading, setCreditDownloading] = useState(false)
  const { language, setLanguage } = useContext(Context);


  // date 
  var today: any = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + '/' + mm + '/' + yyyy;

  useEffect(() => {
    setSelectedMethod(0)
    setAmount((state?.proformaData?.grand_amount - proformaData?.sum_dep_deduction) || 0)
    setOrder_id(state?.proformaData?.order_id)
    setId(state?.proformaData?.id)
    setProformaData(state?.proformaData)

    if ((!state?.proformaData?.order_id) || (!state?.proformaData?.grand_amount) || (!state?.id)) {
      toast.error('Unexpected Error Occured', {
        toastId: "error123"
      })
      navigate('/department/products/1')
    }
  }, [partialAmount, selectedMode])

  // proforma download handler 
  const proformaDownloadHandler = (status: 1 | 2) => {
    // status->1  => proforma 
    // status->2  => credit bill 
    let DownloadURL: string = ''
    status == 1 ? DownloadURL = 'proforma-download' : DownloadURL = 'credit-proforma-download'

    status == 1 ? setProformaDownloading(true) : setCreditDownloading(true)
    axios({
      url: `${API_URL}/order/${DownloadURL}/${state?.id}`,
      method: 'GET',
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN")
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${status == 1 ? 'Proforma Invoice' : 'Credit Bill'}-${proformaData?.order_id}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      setCreditDownloading(false)
      setProformaDownloading(false)
    }).catch(
      function (error) {
        console.log(error);
        setCreditDownloading(false)
        setProformaDownloading(false)
      }
    )
  }

  return (
    <>
      {/* inner contents starts here */}
      <div className="right-content-wrap">
        <div className="proforma-checkout-wrap">

          <>
            <div className="proforma-top-btn-wrap">
              {/* <Button color='outline' className='btn performa-download-btn' onClick={() => proformaDownloadHandler(1)} >
                  {proformaDownloading ?
                    <Spinner
                      as="span"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    /> : <>
                      <i className="bi bi-download me-2"></i>Download Proforma Invoice
                    </>
                  }
                </Button> */}
              <Button color="primary" outline className='btn download-credit-btn' onClick={() => proformaDownloadHandler(2)}>
                {creditDownloading ?
                  <Spinner
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                  /> : <>
                    <i className="bi bi-download me-2"></i>{DepartmentProducts?.DownloadCreditBill[language]}
                  </>
                }
              </Button>
            </div>
          </>

          {true && <Row className='gy-4'>
            <div className='choose-payment-outer'>
              <h5><span className='money-icon-wrap'><img src="../../images/rupee-icon.svg" alt="" /></span>Transaction Details:</h5>
              {/* order details  */}
              <ViewOrderCard
                proformaData={proformaData}
              />
              {/* payment mode selection  */}
              <PaymentMode
                selectedMode={selectedMode}
                order_id={order_id}
                setSelectedMode={setSelectedMode}
                partialAmount={partialAmount}
                amount={partialAmount}
                setPartialAmount={setPartialAmount}
                totalAmount={proformaData?.grand_amount || 0}
              />
            </div>

            <Col lg={7}>
              <div className="choose-payment-outer-wrap">
                <Col md={12}>
                  <div className="choose-payment-wrap">

                    {(selectedMode == constants?.DEPT_PAYMENT_MODES?.FULL_PAYMENT
                      || partialAmount > 0 && amount >= partialAmount) &&
                      <PaymentTypesSelector
                        selectedMethod={selectedMethod}
                        setSelectedMethod={setSelectedMethod}
                      />}
                  </div>
                </Col>
              </div>
            </Col>
            <Col lg={5}>
              <div className="checkout-slip-wrap">
                <div className="cart-total-left-wrap">

                  {/* <h6>{paymentMethod}</h6> */}
                  <AccountDetails />   {/* component to show account details of the PMU */}

                  <div className="cart-total-left-inner mt-4">
                    <p>{DepartmentProducts?.Date_of_Order?.[language]}:</p>
                    <p className='date'>{today && today}</p>
                  </div>
                  {amount > 0 && <div className="cart-total-left-inner">
                    <p className='mb-0'>{DepartmentProducts?.TotalAmount?.[language]}:</p>
                    <h2 className='primary-text'>₹ {amount.toFixed(2)} </h2>
                  </div>}
                  {partialAmount ? <div className="cart-total-left-inner">
                    <p className='mb-0'>{DepartmentProducts?.Paying?.[language]}:</p>
                    <h2 className='primary-text'>₹ {partialAmount} </h2>
                  </div> : null}
                </div>
                {selectedMethod !== constants?.PAYMENT_TYPE?.NETBANK ?
                  <RightSection
                    paymentMethod=">"
                    payment_type={selectedMethod}
                    id={id}
                    selectedMode={selectedMode}
                    amount={selectedMode == constants?.DEPT_PAYMENT_MODES?.PARTIAL_PAYMENT ? partialAmount : amount}
                  /> :
                  <div>
                    {(amount) ?
                      <Paypal
                        amount={selectedMode == constants?.DEPT_PAYMENT_MODES?.PARTIAL_PAYMENT ? partialAmount : amount}
                        order_id={order_id}
                        purchaseType={'PRODUCT'}
                        selectedMode={selectedMode}
                      />
                      :
                      <>
                        {navigate('/department/proforma-invoice')}
                      </>}
                  </div>}
              </div>
            </Col>
          </Row>}
        </div >
      </div >

    </>
  )
};
