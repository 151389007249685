import React, { useEffect,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { useState, useMemo, useRef } from "react";
import { api } from "../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import { API_URL } from "../../../Library/ApiSettings";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
import axios from "axios";
import {
  UploadValidationSchema,
  UploadValidationWithoutMSESchema,
} from "./UploadValidation";
import {
  UploadValidationWithoutUdyamSchema,
  UploadValidationWithoutUdeyamMSESchema,
} from "./UploadValidation";
import OEMDocumentsSection from "./OEMDocumntes";
import HrDocumentsSection from "./HrDocuments";
import ITTurnover from "./ITTurnover";
import FinancialTurnover from "./TurnOverCertificate";
import EmpanelmentDocuments from "./EmpanelDocuments";
import NotRequiredDocuments from "./NotRequiredDouments";
import RequiredDocuments from "./RequiredDocuments";
import moment from "moment";
import WorkOrderSection from "./WorkOrderDocument";
import AnyOthercomapnyDocuments from "./AnyOtherCompanyDocument";
import NormalVendorView from "./DocumentsView/NormalVendorView";
import AllValiadation from "./AllValidationShow";
import Loader from "../../../Components/Loader/Loader";

export default function DocUpload() {
  const isoRef = useRef<HTMLInputElement>(null);
  const oemRef = useRef<HTMLInputElement>(null);
  const mafRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [iso, setiso] = useState<any>(false);
  const [maf, setmaf] = useState<any>(false);
  const [oem, setoem] = useState<any>(false);
  const [msme, setmsme] = useState<any>(false);
  const [empanel_certificate, setempanel_certificate] = useState<any>(false);
  const [list, setlists] = useState<any>("");
  const [dat, setDates] = useState<any>();
  const [vendorUdyam, setVendorUdyam] = useState("");
  const [vendormse, setVendorMSE] = useState("");
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const { language, setLanguage } = useContext(Context);
  const [datas, setData] = useState({
    pan: "",
    incorporation_certificate: "",
    address_proof: "",
    mse_certificate: "",
    gst_certificate: "",
    FFfinancial_year: "",
    SFfinancial_year: "",
    TFfinancial_year: "",
    file: [],
    Ffinancial_year: "",
    Sfinancial_year: "",
    Tfinancial_year: "",
    Ffinancial_year_amount: "",
    Sfinancial_year_amount: "",
    Tfinancial_year_amount: "",
    iso_certificate: "",
    oem_certificate: "",
    maf_certificate: "",
    empanel_certificate: "",
    udyam: "",
    oem_valid_from: "",
    profile_status: 3,
    empanelment_date: "",
    expiry_date: "",
    it_turn_over_first_year: "",
    it_turn_over_second_year: "",

    it_turn_over_first_document: "",
    it_turn_over_second_document: "",

    it_turn_over_second_amount: "",
    it_turn_over_first_amount: "",

    it_turn_over_ca_name: "",
    it_turn_over_ca_email: "",
    it_turn_over_ca_phone: "",
    financial_turn_over_ca_name: "",
    financial_turn_over_ca_email: "",
    financial_turn_over_ca_phone: "",
    manpower_documents: "",
    manpower_number: "",
    technical_manpower_number: "",
    nontechnical_manpower_number: "",
    manpower_date: "",
    any_other_company_documents: "",
    any_other_company_name: "",
    any_other_company_email: "",
    land_document: "",
    land_village_col: "",
    land_extent: "",
    land_value: "",
    land_survey_no: "",
    oem_in_company_type: "",
    oem_in_company_valid_from: "",
    oem_in_company_valid_to: "",
    work_order_certificate: "",
    inside_karnataka_documents: "",
    work_order_no_of_projects: "",
    any_other_company_pincode: "",
    any_other_company_state: "",
    any_other_company_address: "",
    mark_turn: "",
    mark_it_turn: "",
    turn_cat: "",
    any_other_company_district: "",
    any_other_company_city: "",
    manpower_mark: "",
  });
  const [mark, setMark] = useState({
    incorporation_certificate_mark: 0,
    work_order_document_mark: 0,
    turn_over_mark: 0,
    it_turn_over_mark: 0,
    land_mark: 0,
    manpower_mark: 0,
    oem_mark: 0,
    any_other: 0,
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // Save the scroll position when navigating away
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    // Restore the scroll position when navigating back
    window.scrollTo(0, scrollPosition);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    getDocumnets();
    getVendor();
  }, []);

  function subtractYears(date: any, years: any) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getVendor = () => {
    api
      .post(`/get_vendor_profile`, {}, true)
      .then(async function ([success, response]) {
        setVendorUdyam(
          response?.vendor_details?.advanced_registration_details?.[0]?.udyam
        );
        setVendorMSE(
          response?.vendor_details?.advanced_registration_details?.[0]
            ?.company_mse
        );
        // setData({
        //   ...datas,
        //   empanelment_date: moment(
        //     response?.vendor_details?.advanced_registration_details[0]
        //       .empanelment_date
        //   ).format("DD-MM-YYYY"),
        //   expiry_date: moment(
        //     response?.vendor_details?.advanced_registration_details[0]
        //       .expiry_date
        //   ).format("DD-MM-YYYY"),
        // });
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumnets = () => {
    setLoading(true);

    api
      .get(`/documents`, true)
      .then(async function ([success, response]) {
        setData({
          ...datas,
          pan: response?.data?.[0]?.id,
          incorporation_certificate: response?.data?.[1]?.id,
          address_proof: response?.data?.[2]?.id,
          gst_certificate: response?.data?.[3]?.id,
          empanel_certificate: response?.data?.[4]?.id,
          oem_certificate: response?.data?.[1]?.id,
          work_order_certificate: response?.data?.[6]?.id,
          FFfinancial_year: response?.data?.[7]?.id,
          SFfinancial_year: response?.data?.[8]?.id,
          TFfinancial_year: response?.data?.[9]?.id,
          manpower_documents: response?.data?.[10]?.id,
          land_document: response?.data?.[11]?.id,
          any_other_company_documents: response?.data?.[12]?.id,
          it_turn_over_first_document: response?.data?.[13]?.id,
          it_turn_over_second_document: response?.data?.[14]?.id,
          iso_certificate: response?.data?.[15]?.id,
          maf_certificate: response?.data?.[16]?.id,
          mse_certificate: response?.data?.[17]?.id,
          udyam: response?.data?.[18]?.id,
          empanelment_date: response?.data?.[11]?.meta?.empanelment_date,
          expiry_date: response?.data?.[11]?.meta?.empanelment_expiry_date,
          oem_in_company_type: response?.data?.[1]?.meta?.oem_in_company_type,
          oem_in_company_valid_from: response?.data?.[1]?.meta?.oem_valid_from,
          oem_in_company_valid_to: response?.data?.[1]?.meta?.oem_valid_to,
          work_order_no_of_projects:
            response?.data?.[13]?.meta?.work_order_no_of_projects,
          Ffinancial_year_amount:
            response?.data?.[7]?.meta?.f_financial_year_amount,
          Sfinancial_year_amount:
            response?.data?.[8]?.meta?.s_financial_year_amount,
          Tfinancial_year_amount:
            response?.data?.[9]?.meta?.t_financial_year_amount,
          Ffinancial_year: response?.data?.[7]?.meta?.f_financial_year
            ? moment(response?.data?.[7]?.meta?.f_financial_year).format("YYYY")
            : "",
          Sfinancial_year: response?.data?.[8]?.meta?.s_financial_year
            ? moment(response?.data?.[8]?.meta?.s_financial_year).format("YYYY")
            : "",
          Tfinancial_year: response?.data?.[9]?.meta?.t_financial_year
            ? moment(response?.data?.[9]?.meta?.t_financial_year).format("YYYY")
            : "",
          financial_turn_over_ca_email:
            response?.data?.[7]?.meta?.financial_turn_over_ca_email,
          financial_turn_over_ca_name:
            response?.data?.[7]?.meta?.financial_turn_over_ca_name,
          financial_turn_over_ca_phone:
            response?.data?.[7]?.meta?.financial_turn_over_ca_phone,
          manpower_date: response?.data?.[14]?.meta?.manpower_date
            ? moment(response?.data?.[14]?.meta?.manpower_date).format(
                "DD-MM-YYYY"
              )
            : "",
          manpower_number: response?.data?.[14]?.meta?.manpower_number,
          land_extent: response?.data?.[15]?.meta?.land_extent,
          land_survey_no: response?.data?.[15]?.meta?.land_survey_no,
          land_value: response?.data?.[15]?.meta?.land_value,
          land_village_col: response?.data?.[15]?.meta?.land_village_col,
          any_other_company_district:
            response?.data?.[16]?.meta?.any_other_company_district,
          any_other_company_city:
            response?.data?.[16]?.meta?.any_other_company_city,
          any_other_company_email:
            response?.data?.[16]?.meta?.any_other_company_email,
          any_other_company_pincode:
            response?.data?.[16]?.meta?.any_other_company_pincode,
          any_other_company_name:
            response?.data?.[16]?.meta?.any_other_company_name,
          any_other_company_state:
            response?.data?.[16]?.meta?.any_other_company_state,
          it_turn_over_first_year: response?.data?.[13]?.meta
            ?.f_t_turn_over_year
            ? moment(response?.data?.[13]?.meta?.f_t_turn_over_year).format(
                "DD-MM-YYYY"
              )
            : "",

          it_turn_over_second_year:
            response?.data?.[18]?.meta?.s_t_turn_over_year,
          it_turn_over_first_amount:
            response?.data?.[17]?.meta?.f_it_turn_over_amount,
          it_turn_over_second_amount:
            response?.data?.[18]?.meta?.s_it_turn_over_amount,
          it_turn_over_ca_email:
            response?.data?.[17]?.meta?.it_turn_over_ca_email,
          it_turn_over_ca_name:
            response?.data?.[17]?.meta?.it_turn_over_ca_name,
          it_turn_over_ca_phone:
            response?.data?.[17]?.meta?.it_turn_over_ca_phone,

          profile_status: 3,
        });

        setlists(response?.data?.length > 0 ? 1 : 0);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const initialValues = {
    pan: "",
    incorporation_certificate: "",
    address_proof: "",
    mse_certificate: "",
    gst_certificate: "",
    FFfinancial_year: "",
    SFfinancial_year: "",
    TFfinancial_year: "",
    file: [],
    Ffinancial_year: "",
    Sfinancial_year: "",
    Tfinancial_year: "",
    iso_certificate: "",
    Ffinancial_year_amount: "",
    Sfinancial_year_amount: "",
    Tfinancial_year_amount: "",
    oem_certificate: "",
    maf_certificate: "",
    empanel_certificate: "",
    udyam: "",
    profile_status: 3,
    address: "false",
    empanelment_date: "",
    expiry_date: "",
    it_turn_over_first_year: "",
    it_turn_over_second_year: "",

    it_turn_over_first_document: "",
    it_turn_over_second_document: "",

    it_turn_over_second_amount: "",
    it_turn_over_first_amount: "",
    financial_turn_over_ca_name: "",
    financial_turn_over_ca_email: "",
    financial_turn_over_ca_phone: "",
    it_turn_over_ca_name: "",
    it_turn_over_ca_email: "",
    it_turn_over_ca_phone: "",
    manpower_documents: "",
    manpower_number: "",
    manpower_date: "",
    any_other_company_documents: "",
    any_other_company_name: "",
    any_other_company_email: "",
    land_document: "",
    land_village_col: "",
    land_extent: "",
    land_value: "",
    land_survey_no: "",
    oem_in_company_type: "",
    oem_in_company_valid_from: "",
    oem_in_company_valid_to: "",
    work_order_no_of_projects: "",
    work_order_certificate: "",
    inside_karnataka_documents: "",
    any_other_company_pincode: "",
    any_other_company_state: "",
    any_other_company_district: "",
    any_other_company_address: "",
    any_other_company_city: "",
    mark_turn: "",
    mark_it_turn: "",
    turn_cat: "",
    technical_manpower_number: "",
    nontechnical_manpower_number: "",
    manpower_mark: "",
  };

  const handleSubmit = (values: any, actions: any) => {
    console.log(mark,values?.land_survey_no,values?.manpower_mark,values?.mark_it_turn,values?.mark_turn,"dbfue")
    const total_mark =
      Number(mark?.incorporation_certificate_mark) +
      Number(mark?.work_order_document_mark) +
      Number(values?.mark_turn) +
      Number(mark?.oem_mark) +
      Number(values?.mark_it_turn) +
      Number(
        values?.any_other_company_state == "Karnataka" ? mark?.any_other : 0
      ) +
      Number(values?.manpower_mark) +
      Number(values?.land_survey_no ? 5 : 0);

    console.log(total_mark, "total_mark");
    if (total_mark < 70) {
      toast.error(
        `You scored ${total_mark}/100.But you haven't attained the benchmark(70/100)`
      );
    } else {
      toast.success(`Congratulations 👍,You scored ${total_mark}/100`);
      uploadDocument(values, actions);
    }
  };

  const currentYear = new Date().getFullYear(); // 2020
  const previousYear = currentYear - 1;
  const nest_yr = previousYear - 1;

  const uploadDocument = (values: any, actions: any) => {
    const formData = new FormData();

    // documets
    // if (values?.pan?.type !== "application/pdf") {
    //   actions?.setFieldError("pan", "Only Accept pdf file");
    // }
    formData.append("pan", values?.pan);
    formData.append("oem_certificate", values?.oem_certificate);
    formData.append(
      "maf_certificate",
      values?.maf_certificate ? values?.maf_certificate : ""
    );
    formData.append("address_proof", values?.address_proof);
    formData.append(
      "incorporation_certificate",
      values?.incorporation_certificate
    );
    {
    }
    formData.append(
      "mse_certificate",
      values?.mse_certificate ? values?.mse_certificate : ""
    );
    formData.append("gst_certificate", values?.gst_certificate);
    formData.append("f_financial_year_doc", values?.FFfinancial_year);
    formData.append("s_financial_year_doc", values?.SFfinancial_year);
    formData.append("t_financial_year_doc", values?.TFfinancial_year);
    formData.append("f_t_turn_over_year", values?.it_turn_over_first_year);
    formData.append("s_t_turn_over_year", values?.it_turn_over_second_year);
    formData.append(
      "iso_certificate",
      values?.iso_certificate ? values?.iso_certificate : ""
    );
    formData.append(
      "udyam",
      values?.mse_certificate ? values?.mse_certificate : ""
    );
    formData.append(
      "f_it_turn_over_document",
      values?.it_turn_over_first_document
    );
    formData.append(
      "s_it_turn_over_document",
      values?.it_turn_over_second_document
    );
    formData.append("empanel_certificate", values?.empanel_certificate);
    formData.append(
      "any_other_company_document",
      values?.any_other_company_documents
    );
    formData.append("land_document", values?.land_document);
    formData.append("work_order_certificate", values?.work_order_certificate);
    formData.append("manpower_document", values?.manpower_documents);

    formData.append(
      "empanelment_date",
      moment(values?.empanelment_date).format("YYYY-MM-DD")
    );
    formData.append(
      "empanelment_expiry_date",
      moment(values?.expiry_date).format("YYYY-MM-DD")
    );

    formData.append(
      "financial_turn_over_ca_email",
      values?.financial_turn_over_ca_email
    );
    formData.append(
      "financial_turn_over_ca_name",
      values?.financial_turn_over_ca_name
    );
    formData.append(
      "financial_turn_over_ca_phone",
      values?.financial_turn_over_ca_phone
    );

    formData.append("it_turn_over_ca_email", values?.it_turn_over_ca_email);
    formData.append("it_turn_over_ca_name", values?.it_turn_over_ca_name);
    formData.append("it_turn_over_ca_phone", values?.it_turn_over_ca_phone);

    formData.append(
      "technical_manpower_number",
      values?.technical_manpower_number
    );
    formData.append(
      "nontechnical_manpower_number",
      values?.nontechnical_manpower_number
    );
    formData.append("manpower_number", values?.manpower_number);
    formData.append(
      "manpower_date",
      moment(values?.manpower_date).format("YYYY-MM-DD")
    );

    formData.append("land_extent", values?.land_extent);
    formData.append("land_village_col", values?.land_village_col);
    formData.append("land_value", values?.land_value);
    formData.append("land_survey_no", values?.land_survey_no);

    formData.append("oem_in_company_type", values?.oem_in_company_type);
    formData.append(
      "oem_valid_from",
      moment(values?.oem_in_company_valid_from).format("YYYY-MM-DD")
    );
    formData.append(
      "oem_valid_to",
      moment(values?.oem_in_company_valid_to).format("YYYY-MM-DD")
    );

    formData.append(
      "work_order_no_of_projects",
      values?.work_order_no_of_projects
    );

    formData.append(
      "any_other_company_pincode",
      values?.any_other_company_pincode
    );
    formData.append("any_other_company_state", values?.any_other_company_state);
    formData.append(
      "any_other_company_district",
      values?.any_other_company_district
    );
    formData.append("any_other_company_city", values?.any_other_company_city);
    formData.append("any_other_company_name", values?.any_other_company_name);
    formData.append("any_other_company_email", values?.any_other_company_email);

    formData.append("f_financial_year", values?.Ffinancial_year);
    formData.append("s_financial_year", values?.Sfinancial_year);
    formData.append("t_financial_year", values?.Tfinancial_year);

    formData.append("f_financial_year_amount", values?.Ffinancial_year_amount);
    formData.append("s_financial_year_amount", values?.Sfinancial_year_amount);
    formData.append("t_financial_year_amount", values?.Tfinancial_year_amount);

    formData.append(
      "s_it_turn_over_amount",
      values?.it_turn_over_second_amount
    );
    formData.append("f_it_turn_over_amount", values?.it_turn_over_first_amount);

    formData.append("f_it_turn_over_year", values?.it_turn_over_first_year);
    formData.append("s_it_turn_over_year", values?.it_turn_over_second_year);

    if (values?.iso_certificate) {
      formData.append("is_iso", "1");
    }
    if (values?.oem_certificate) {
      formData.append("is_oem", "1");
    }
    if (values?.maf_certificate) {
      formData.append("is_adhar", "1");
    }
    if (values?.mse_certificate) {
      formData.append("is_msme", "1");
    }
    const body = formData;

    axios({
      method: "post",
      url: `${API_URL}/documents`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success("Documents are uploaded successfuly");
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/emd-details", {
            state: {
              cat:
                values?.turn_cat == "A"
                  ? localStorage.setItem("CAT", "A")
                  : values?.turn_cat == "B"
                  ? localStorage.setItem("CAT", "B")
                  : values?.turn_cat == "C"
                  ? localStorage.setItem("CAT", "C")
                  : "",
            },
          });

          localStorage.setItem("emds", "true");
        }
      })
      .catch(function (response) {
        toast.error("Something Went Wrong, Please Check Datas");
        console.log(response);
      });
  };
  // const formatDate = (date:any) => {
  //   if (!date) return "";
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const form:any = `${day}-${month}-${year-1}`;
  //   setDates(form);
  //   console.log(dat,"difhiueifej")
  // };

  const documentdownload = (id: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(`${API_URL}/documents/${id}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Memorandum Of Understanding.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  const [val, setVa] = useState(0);
  return (
    <>
      <div className="login-content">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={
             vendormse == "yes"
              ? UploadValidationSchema
              :  vendormse == "no"
              ? UploadValidationWithoutUdeyamMSESchema
              :  vendormse == "no"
              ? UploadValidationWithoutMSESchema
              : vendormse == "yes"
              ? UploadValidationWithoutUdyamSchema
              : UploadValidationSchema
          }
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            const handleFileChange = (e: any, values: any) => {
              const myMemoObj = URL.createObjectURL(e.target.files[0]);
              setValue([...value, myMemoObj]);
              if (e.target.name == "pan") {
                setFieldValue("pan", e.target.files[0]);
              }
              if (e.target.name == "incorporation_certificate") {
                setFieldValue("incorporation_certificate", e.target.files[0]);
                setMark({
                  ...mark,
                  incorporation_certificate_mark: 5,
                });
              }
              if (e.target.name == "address_proof") {
                setFieldValue("address_proof", e.target.files[0]);
              }
              if (e.target.name == "mse_certificate") {
                setFieldValue("mse_certificate", e.target.files[0]);
                setmsme(true);
              }
              if (e.target.name == "gst_certificate") {
                setFieldValue("gst_certificate", e.target.files[0]);
              }
              if (e.target.name == "FFfinancial_year") {
                setFieldValue("FFfinancial_year", e.target.files[0]);
              }
              if (e.target.name == "SFfinancial_year") {
                setFieldValue("SFfinancial_year", e.target.files[0]);
              }

              if (e.target.name == "TFfinancial_year") {
                setFieldValue("TFfinancial_year", e.target.files[0]);
              }
              if (e.target.name == "Ffinancial_year_amount") {
                setFieldValue("Ffinancial_year_amount", e.target.files[0]);
              }
              if (e.target.name == "Sfinancial_year_amount") {
                setFieldValue("Sfinancial_year_amount", e.target.files[0]);
              }
              if (e.target.name == "Tfinancial_year_amount") {
                setFieldValue("Tfinancial_year_amount", e.target.files[0]);
              }
              if (e.target.name == "iso_certificate") {
                setFieldValue("iso_certificate", e.target.files[0]);
                setiso(true);
              }
              if (e.target.name == "TFfinancial_year") {
                setFieldValue("TFfinancial_year", e.target.files[0]);
              }
              if (e.target.name == "maf_certificate") {
                setFieldValue("maf_certificate", e.target.files[0]);
                setmaf(true);
              }
              if (e.target.name == "oem_certificate") {
                setFieldValue("oem_certificate", e.target.files[0]);
                setMark({
                  ...mark,
                  oem_mark: 5,
                });
                setoem(true);
              }
              if (e.target.name == "empanel_certificate") {
                setFieldValue("empanel_certificate", e.target.files[0]);
                setempanel_certificate(true);
              }
              if (e.target.name == "udyam") {
                setFieldValue("udyam", e.target.files[0]);
              }

              if (e.target.name == "it_turn_over_first_document") {
                setFieldValue("it_turn_over_first_document", e.target.files[0]);
              }
              if (e.target.name == "it_turn_over_second_document") {
                setFieldValue(
                  "it_turn_over_second_document",
                  e.target.files[0]
                );
              }
              if (e.target.name == "it_turn_over_third_document") {
                setFieldValue("it_turn_over_third_document", e.target.files[0]);
              }

              if (e.target.name == "land_document") {
                setFieldValue("land_document", e.target.files[0]);
                setMark({
                  ...mark,
                  land_mark: 5,
                });
              }
              if (e.target.name == "manpower_documents") {
                setFieldValue("manpower_documents", e.target.files[0]);
                // setMark({
                //   ...mark,
                //   manpower_mark: 10,
                // });
              }
              if (e.target.name == "any_other_company_documents") {
                setFieldValue("any_other_company_documents", e.target.files[0]);
                setMark({
                  ...mark,
                  any_other: 10,
                });
              }
              if (e.target.name == "work_order_certificate") {
                setFieldValue("work_order_certificate", e.target.files[0]);
                setFieldValue(
                  "any_other_company_state",
                  values?.any_other_company_state
                );
                setMark({
                  ...mark,
                  work_order_document_mark: 5,
                });
              }
            };

            const handleFileremove = (val: any) => {
              if (val == "iso") {
                setFieldValue("iso_certificate", "");
                setiso(false);
                if (isoRef && isoRef.current) {
                  isoRef.current.value = "";
                }
              }

              if (val == "maf") {
                setFieldValue("maf_certificate", "");
                setmaf(false);
                if (mafRef && mafRef.current) {
                  mafRef.current.value = "";
                }
              }

              if (val == "oem") {
                setFieldValue("oem_certificate", "");
                setoem(false);
                if (oemRef && oemRef.current) {
                  oemRef.current.value = "";
                }
              }
            };
            
            return (
             
              <Form onSubmit={handleSubmit} className="Reg-wrap">
                {!datas?.pan && (
                  <span className="text-danger">
                    * {Vendor_profileUpdation?.Please_be_advised?.[language]}
                  </span>
                )}

                <hr />
                {loading ? (
                  <div className="loading-overlay">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <AllValiadation error={errors} val={val} />
                    {datas?.pan ? (
                      <NormalVendorView />
                    ) : (
                      <>
                        <RequiredDocuments
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          vendormse={vendormse}
                          vendorUdyam={vendorUdyam}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          documentdownload={documentdownload}
                        />
                        <OEMDocumentsSection
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          documentdownload={documentdownload}
                        />
                        <WorkOrderSection
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          documentdownload={documentdownload}
                        />

                        <FinancialTurnover
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          documentdownload={documentdownload}
                        />
                        <HrDocumentsSection
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          man_={
                            values?.turn_cat == "A"
                              ? 50
                              : values?.turn_cat == "B"
                              ? 25
                              : values?.turn_cat == "C"
                              ? 15
                              : 0
                          }
                          touched={touched}
                          errors={errors}
                          documentdownload={documentdownload}
                        />

                        <AnyOthercomapnyDocuments
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          documentdownload={documentdownload}
                        />
                        <ITTurnover
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          documentdownload={documentdownload}
                        />
                        <EmpanelmentDocuments
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          documentdownload={documentdownload}
                        />

                        <NotRequiredDocuments
                          datas={datas}
                          list={list}
                          oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          handleFileremove={handleFileremove}
                          maf={maf}
                          mafRef={mafRef}
                          isoRef={isoRef}
                          iso={iso}
                          documentdownload={documentdownload}
                        />

                        <Row>
                          <Col sm={12}>
                            <div className="agree-checkbox">
                              <FormGroup>
                                <div className="agree-checkbox-wrap">
                                  <Input
                                    name="address"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={values?.address}
                                    onChange={handleChange}
                                    disabled={list > 0 ? true : false}
                                    style={{
                                      backgroundColor: `${
                                        list > 0 ? "#b6b4b4" : "white"
                                      }`,
                                    }}
                                  ></Input>
                                  <Label>
                                  {Vendor_profileUpdation?.Make_sure?.[language]}
                                  </Label>
                                </div>
                                <ErrorMessage
                                  name="address"
                                  component={TextError}
                                />
                              </FormGroup>
                            </div>
                          </Col>

                          <Col sm={12} className="text-end">
                            <Button
                              className="btn btn-primary mb-5"
                              color="primary"
                              type="submit"
                              disabled={list > 0 ? true : false}
                              onClick={() => {
                                window.scrollTo(0, 0);
                                setVa(1);
                              }}
                            >
                              {Vendor_profileUpdation?.Submit?.[language]}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
        {isVisible && (
          <>
            <button
              onClick={() => scrollToTop()}
              className="btn btn-dark button_scroll_ "
            >
              <i className="fa-solid fa-arrow-up"></i>
            </button>
          </>
        )}
      </div>
    </>
  );
}
function setFieldValue(arg0: string, arg1: any[]) {
  throw new Error("Function not implemented.");
}
