import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../../Components/Context/Context";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
} from "reactstrap";
import { clearData } from "../../../../Library/KeyStorage";
import CurrentTab from "../../CurrentTab";

interface propType {
  openAdminSidebar: boolean,
  setAdminSidebar: Function
}
function Header({ setAdminSidebar, openAdminSidebar }: propType) {
  let newDate = new Date();
  let date = moment(newDate).format("DD MMMM YYYY");
  let time = moment(newDate).format("hh:mm A");
  let user_name = localStorage.getItem("USER_NAME");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { pathname } = useLocation();

  const { language, setLanguage } = useContext(Context);


  const Log_out = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  const logout = async () => {
    clearData();
    navigate("/login");
    // setAuthStatus(false);
  };

  return (
    <>
      {/*top-header  header */}
      <header className="top-bar">
        <div className="top-bar-full-wrap">
          <Row>
            <Col md={12}>
              <div className="header-outer-wrap">
                <div className="page-name-date-wrap">
                  {/* mobile sidebar toggle-btn */}
                  <Button
                    color="link"
                    className="menu-mobile text-decoration-none p-0"
                    onClick={() =>
                      setAdminSidebar(!openAdminSidebar)
                    }
                  >
                    <i className="fa fa-bars"></i>
                  </Button>

                  {/* mobile sidebar toggle-btn ends */}
                  <div className="page-heading">
                    {/* mobile-logo */}
                    <div className="mob-logo-wrap">
                      <img src="../../images/mob-logo.png" alt="logo" />
                    </div>
                    {/* shows current tab name  */}
                    <CurrentTab pathname={pathname} />
                  </div>
                  {/* desktop header date  */}
                  <div className="date-and-time">
                    <small className="mb-0">
                      <span className="date">{date}</span>
                      &nbsp;&nbsp;|&nbsp;&nbsp;
                      <span className="time">{time} GMT</span>
                    </small>
                  </div>
                  {/* desktop header date ends*/}
                </div>
                <div className="search-top-bar-outer">
                  {/* Language switcher */}
                  <div className="sidebar-language-btn-group">
                    <div className="lang-tab-inner">
                      <span className="en-tab" onClick={() => setLanguage('en')}>English</span>
                      <div className="divider"></div>
                      <span className="ka-tab" onClick={() => setLanguage('ka')}>ಕನ್ನಡ</span>
                    </div>
                  </div>

                  {/* <div className="cart-container"> */}
                  {/* notification collapse section */}
                  {/* <Link
                      to="/department/cart"
                      className="cart text-decoration-none"
                    >
                      <i className="bi bi-cart text-dark"></i>
                    </Link> */}
                  {/* </div> */}
                  {/* notification-bell btn */}
                  <div className="notification-container">
                    <Link
                      to="/notifications"
                      className="notification-bell"
                      title="Notifications"
                    >
                      <i className="bi bi-bell"></i>
                    </Link>
                    {/* <div className="notification-page">
                      <p>Notification Content</p>
                    </div> */}
                  </div>

                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    // direction={direction}
                    className="account-drop"
                  >
                    <DropdownToggle className="" color="link">
                      <div className="account-info">
                        <div className="avatar-img-wrap">
                          <img src="/images/avatar.svg" alt="img" />
                        </div>
                        <small>{user_name}</small>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="top-50">
                      <DropdownItem
                        title="Log Out"
                        to="#"
                        onClick={() => {
                          Log_out();
                        }}
                      >
                        <i className="bi bi-box-arrow-in-left me-3"></i>
                        Log Out
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </header>
    </>
  );
}

export default Header;
