import React, { useEffect, useContext } from "react";
import { useState } from "react";
import {
  Button,
  List,
  Table,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  InputGroup,
  InputGroupText,
  FormGroup,
  Label,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import constants from "../../../../Library/Constants/Constants";
import moment from "moment";
import PaymentForVendors from "./../Modal/PaymentToVendors";
import * as Yup from "yup";
import { toast } from "react-toastify";
import OrderView2 from "../../Orders/Modal/OrderView2";
import {
  getOrderStatus,
  getUnique,
} from "../../../../Library/Constants/helpers";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { tr } from "date-fns/locale";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import { Admin_Delivered } from "../../../../Library/LanguageConversion/Admin_Delivered";
import { Context } from "../../../../Components/Context/Context";
export default function DeliveredOrders() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);

  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [delivered_order, setDeliveredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState<any>();
  const [pro_data, setProData] = useState<any>([]);
  const [user_data, setUserData] = useState<any>([]);
  const [payment_data, setPaymentData] = useState<any>([]);
  const [graph_data, setGraphData] = useState<any>([]);
  const [order_view, setOrderView] = useState([]);
  const [order_id, setOrderId] = useState<any>([]);
  const [id, setId] = useState<string>("");
  const [doc_data, setDocData] = useState<any>([]);
  const { language, setLanguage } = useContext(Context)
  // const[order_id,setOrderId]=useState("")
  const maxStars: number = 5;
  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getDeliveredOrders();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);

  const getDeliveredOrders = () => {
    setLoading(true);
    api
      .get(
        `/order/get-confirmed-orders?status=${constants.ORDERS.DELIVERED}&page=${currentPage}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setDeliveredOrders(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getFeedback = (p_id: any, u_id: any, o_id: any) => {
    setLoading1(true);
    api
      .get(
        `/product/orderFeedback?product_id=${p_id}&user_id=${u_id}&order_id=${o_id}`,
        true
      )
      .then(async function ([success, response]) {
        setProData(response?.data?.feedback || []);
        setUserData(response?.data?.user || []);
        setLoading1(false);
        return response;
      })
      .catch((err) => {
        setLoading1(false);
        console.log(err);
      });
  };

  const getPaymentData = (id: any) => {
    api
      .get(`/order/payment-to-vendors?order_id=${id}`, true)
      .then(async function ([success, response]) {
        setPaymentData(response?.message?.[0] || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Graph data
  const graphData = (id: any) => {
    api
      .post(
        `/order/get_order_timeline`,
        {
          order_id: id,
        },
        true
      )
      .then(async function ([success, response]) {
        // setPaymentData(response?.message?.[0] || []);
        setGraphData(getUnique(response?.message, "status") || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function limitText(text: any, limit: any) {
    if (text?.length > limit) {
      return text.substring(0, limit) + "...";
    } else {
      return text;
    }
  }

  //====================================================== get order view details ======================================================

  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-order-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message?.[0]);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  //====================================================== get Work order completion ===================================================
  const getDocument = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get_delivered_documents?order_id=${order_id}`, true)
      .then(async function ([success, response]) {
        setDocData(response?.message || []);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //====================================================== Verify Work order completion ===================================================
  const verifyDocument = (id?: number, sts?: any) => {
    api
      .get(
        `/order/accept_reject_deliver_docs?id=${id}&order_id=${order_id}&status=${sts}`,
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Document Verified Successfully");
        }
        getDocument(order_id);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //=============================================== First Letter auto captitalize ===================================================

  const capitalize = (str: any) => {
    return str?.charAt(0).toUpperCase() + str.slice(1);
  };

  //========================================================= View ==================================================================
  return (
    <React.Fragment>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText
              title="Vendor Name/Order ID"
              className="search-icon"
              id="search"
            >
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={Admin_Delivered?.ProductNameOrder?.[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_Delivered?.SlNo[language]}</th>
              <th>{Admin_Delivered?.OrderID[language]}</th>
              <th>{Admin_Delivered?.Department[language]}</th>
              <th>{Admin_Delivered?.VendorName[language]}</th>
              {/* <th>Tender ID</th> */}
              <th>{Admin_Delivered?.OrderDetails[language]}</th>
              {/* <th>Shipping Address</th> */}
              <th className="text-center">{Admin_Delivered?.Quantity[language]}</th>
              <th>{Admin_Delivered?.Price[language]}</th>
              <th>{Admin_Delivered?.OrderStatus[language]}</th>
              {/* <th>Payment Status</th> */}
              {/* <th>Action</th> */}
              <th>{Admin_Delivered?.Feedback[language]}</th>
            </tr>
          </thead>
          <tbody>
            {delivered_order?.length !== 0 ? (
              <>
                 {delivered_order?.map((item: any, i: any) => {
                    let count = 0;
                  return (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.SlNo[language]}:</span>
                          <span className="table-data">
                            {pageData?.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.OrderID[language]}:</span>
                          <span className="table-data">
                            <Link
                              to="#"
                              title="View Order Details"
                              onClick={() => {
                                toggle();
                                getSingleOrder(item?.id);
                                graphData(item?.id);
                              }}
                              className="primary-text"
                            >
                              {item?.vendor_po_no}
                            </Link>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {Admin_Delivered?.DepartmentName[language]}:
                          </span>
                          <span className="table-data">
                            {item?.pf_invoice?.user?.full_name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.VendorName[language]}:</span>
                          <span className="table-data">
                            {item?.cmpny_detail?.name}
                          </span>
                        </td>
                        {/* <td>
                          <span className="mobile-heading">Tender Id:</span>
                          <span className="table-data">
                            {item?.pf_invoice?.pf_details[0]?.tender_id}
                          </span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.OrderDetails[language]}:</span>

                          <span
                            className="table-data"
                            title={item?.order_details?.name}
                          >
                            <strong>
                              {limitText(item?.order_details?.name, 15)}
                            </strong>
                            <br /> Unit price:
                            <i className="bi bi-currency-rupee"></i>
                            {item?.pf_invoice?.pf_details[0]?.price}
                          </span>
                        </td>
                        {/* <td>
                          <span className="mobile-heading">
                            Shipping Address:
                          </span>
                          <span className="table-data">
                            {item?.pf_invoice?.pf_address?.[0]?.city},
                            {item?.pf_invoice?.pf_address?.[0]?.district},
                            {item?.pf_invoice?.pf_address?.[0]?.state},
                            {item?.pf_invoice?.pf_address?.[0]?.pincode}
                          </span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.Quantity[language]}:</span>
                          <span className="table-data lg-centered">
                            {" "}
                            {item?.order_details?.quantity}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.Price[language]}:</span>
                          <span>
                            <strong>₹ {item?.total_amount}</strong>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.OrderStatus[language]}:</span>
                          <span>
                            <strong>{getOrderStatus(item?.status)}</strong>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.Action[language]}:</span>
                          <div className="action-flex-btn feedback-box">
                            {item?.get_order_feedback != null && (
                              <>
                                <span
                                  className="align-center success"
                                  title="Feedback"
                                  onClick={() => {
                                    getFeedback(
                                      item?.order_details?.product_id,
                                      item?.pf_invoice?.user_data?.id,
                                      item?.id
                                    );
                                    toggle1();
                                  }}
                                >
                                  <i className="icons icons-feedback-orange xl"></i>
                                </span>
                              </>
                            )}
                            <span
                              className="success"
                              title="Document Verification"
                              onClick={() => {
                                getDocument(item?.id);
                                setOrderId(item?.id);
                                toggle3();
                              }}
                            >
                              <i className="icons icons-verifications-primary xl"></i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={9}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Delivered Order Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && delivered_order?.length !== 0 && (
        <div className="table-footer-wrap">
          <small>
          {Admin_Delivered?.Showing?.[language]} {pageData.from} {Admin_Delivered?.to?.[language]} {pageData.to} {Admin_Delivered?.of?.[language]} {pageData.total} {Admin_Delivered?.entries?.[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}

      {/* Feedback Modal */}
      <div>
        <ModalContainer
          open={modal1}
          setOpen={toggle1}
          header="Feedback & Review From Users"
        >
          <div className="feeback-outer-wrap">
            <div className="feedback-main-heading"></div>
            <div className="feedback-wrap">
              <div className="feedback-details ">
                {pro_data?.length !== 0 ? (
                  <>
                    {pro_data?.map((item: any, i: any) => {
                      return (
                        <React.Fragment key={i}>
                          {/* <h6 className="feedback-title"> */}
                          <div>
                            <h5 className="feedback-title">
                              <b>{capitalize(user_data?.full_name)}</b>
                            </h5>

                            <hr />

                            <table>
                              <tbody>
                                <tr>
                                  <td><strong>{Admin_Delivered?.ProductName[language]}:</strong></td>
                                  <td>{item?.name}</td>
                                </tr>
                                <tr>
                                  <td><strong>{Admin_Delivered?.Message[language]}:</strong></td>
                                  <td>{" "}
                                    {item?.message ? (
                                      item?.message
                                    ) : (
                                      <span className="text-danger">
                                        {Admin_Delivered?.NoMessageAdded[language]}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>


                            <small className="date">
                              {Admin_Delivered?.Updatedat[language]}{" "}
                              <span className="date-span">
                                {moment(item?.updated_at).format("LLLL")}
                              </span>
                            </small>
                            <div className="rating-wrap">
                              <div className="rating-stars">
                                {Array.from({ length: maxStars }, (_, i) => (
                                  <span key={i}>
                                    {item.star > i ? (
                                      <i className="bi bi-star-fill"></i>
                                    ) : (
                                      <i className="bi bi-star"></i>
                                    )}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <hr />
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {loading1 ? (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    ) : (
                      <h4 className="text-center">
                        <i>
                          <b className="text-danger">{Admin_Delivered?.NoFeedbackFound[language]}</b>
                        </i>
                      </h4>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </ModalContainer>
      </div>
      <OrderView2
        order_view={order_view}
        modal={modal}
        toggle={toggle}
        loading={loading}
        graph_data={graph_data}
        deliverd={1}
      />

      {/* ============================================= Document Approval Popup ======================================================*/}
      <div>
        <ModalContainer
          open={modal3}
          setOpen={toggle3}
          header={Admin_Delivered?.document_verification?.[language]}
        >
          {loading ? (
            <div className="modal-loader">
              <Loader />
            </div>
          ) : (
            <div className="table-outer-wrap doc-approval-outer-wrap">
              <div className="table-wrap responsive-table ">
                {/* <h5 className="sub-heading">Document Approval</h5>
                <Table className="w-100 doc-approval-wrap table-striped">
                  <thead>
                    <tr>
                      <th> Documents</th>
                      <th> View</th>
                      <th> Action/Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {doc_data?.length != 0 ? (
                      <>
                        {doc_data?.map((item: any) => {
                          let invoice = item?.type == 1 ? "Challan" : null;
                          let workorder = item?.type == 2 ? "WorkOrder" : null;
                          let delivery = item?.type == 3 ? "Invoice" : null;

                          let Completion =
                            item?.type == 5 ? "Work Completion" : null;
                          return (
                            <>
                              {item?.type !== 4 && (
                                <tr>
                                  <td>
                                    <div className="input-wrap doc-wrap">
                                      <div className="input-group">
                                        {item?.type !== 4 && (
                                          <>
                                            <Input
                                              disabled
                                              type="text"
                                              class="form-control"
                                              value={`${
                                                workorder ||
                                                delivery ||
                                                invoice ||
                                                Completion
                                              }`}
                                            />
                                            <div className="checkbox-image-circle">
                                              <i className="bi bi-check-circle-fill"></i>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="mobile-heading">
                                      Action:
                                    </span>

                                    <div className="action-flex-btn">
                                      {item?.type !== 4 && (
                                        <>
                                          <a
                                            href={item?.docs || item?.wo_doc}
                                            target="_blank"
                                            className="rounded-tag"
                                          >
                                            <i
                                              title="View"
                                              className="icons bi bi-eye lg"
                                            ></i>
                                          </a>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="mobile-heading">
                                      Status:
                                    </span>
                                    <div className="action-flex-btn">
                                      {item?.type !== 4 && (
                                        <>
                                          {item?.status == 2 ? (
                                            <span className="text-success">
                                              Approved
                                            </span>
                                          ) : item?.status == 3 ? (
                                            <span className="text-danger">
                                              Rejected
                                            </span>
                                          ) : (
                                            <>
                                              <a
                                                onClick={() => {
                                                  verifyDocument(item?.id, 2);
                                                }}
                                                target="_blank"
                                                className="rounded-tag bg-success"
                                              >
                                                <i className="icons icons-accept-white"></i>
                                              </a>

                                              <a
                                                onClick={() => {
                                                  verifyDocument(item?.id, 3);
                                                }}
                                                target="_blank"
                                                className="rounded-tag bg-danger"
                                              >
                                                <i className="bi bi-x-circle text-white"></i>
                                              </a>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <div className="input-wrap doc-wrap text-center">
                            <span>No Document Found</span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table> */}
              </div>
              {doc_data?.length !== 0 ? (
                <>
                  <div className="delivery-details-wrap">
                    <h5 className="sub-heading">{Admin_Delivered?.DeliveryDetails[language]}</h5>

                    {doc_data?.map((item: any) => {
                      return (
                        <>
                          {item?.getimages?.map((item: any) => {
                            return (
                              <>
                                <Row className="delivery-inner-wrap gy-4">
                                  <Col md={6}>
                                    <div className="delivery-image">
                                      <img src={item?.image} alt="image" />
                                    </div>
                                  </Col>
                                  {/* <Col md={6}>
                                    <div className="location-wrap form-wrap">
                                      <Row className="gy-4">
                                        <Col md={12}>
                                          <div className="latitude-wrap form-group">
                                            <Label>Latitude</Label>
                                            <Input
                                              type="text"
                                              value={item?.latitude}
                                              disabled
                                            />
                                          </div>
                                        </Col>
                                        <Col md={12}>
                                          <div className="longitude-wrap form-group">
                                            <Label>Longitude</Label>
                                            <Input
                                              type="text"
                                              value={item?.longitude}
                                              disabled
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col> */}

                                  <Col md={6}>
                                    <div className="latitude-wrap form-group">
                                      <MapContainer
                                        style={{
                                          height: "30vh",
                                          width: "100%",
                                        }}
                                        center={[
                                          item?.latitude,
                                          item?.longitude,
                                        ]}
                                        dragging={false}
                                        zoom={13}
                                        doubleClickZoom={true}
                                        scrollWheelZoom={true}
                                        zoomControl={true}
                                      >
                                        <TileLayer
                                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Marker
                                          position={[
                                            item?.latitude,
                                            item?.longitude,
                                          ]}
                                          icon={
                                            new Icon({
                                              iconUrl: "images/placeholder.png",
                                              iconSize: [41, 41],
                                              iconAnchor: [12, 41],
                                            })
                                          }
                                        >
                                          <Popup>{Admin_Delivered?.Location[language]}</Popup>
                                        </Marker>
                                      </MapContainer>
                                    </div>
                                  </Col>
                                </Row>

                                <hr className="dashed" />
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                    {/* <td className="mt-2">
                      <span><b>Status : </b></span>
                      {doc_data?.[2]?.status ==
                        constants?.DELIVERED_DOC.APPROVED ? (
                        <span className="badge bg-success">
                          Approved
                        </span>
                      ) : doc_data?.[2]?.status ==
                        constants?.DELIVERED_DOC.REJECTED ? (
                        <span className="badge bg-danger">
                          Rejected
                        </span>
                      ) : (
                        <span className="badge bg-info">Pending</span>
                      )}
                    </td> */}
                  </div>
                  {/* <div className="feedback-approval">
                <h5 className="sub-heading">Feedback Approval</h5>
                <div className="agree-checkbox-wrap">
                  <Input type="checkbox" />
                  <Label>Approve all Feedbacks</Label>
                </div>
              </div> */}
                  {/* <hr /> */}
                  {/* <div className="btn-wrap footer-sticky">
                    <div className="btn-inner-wrap">
                      {doc_data?.[0]?.status ==
                        constants?.DELIVERED_DOC.APPROVED ? (
                        <Button color="primary" disabled={true}>
                          Approved
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => {
                            verifyDocument(2);
                          }}
                        >
                          Approve all
                        </Button>
                      )}
                      {doc_data?.[0]?.status ==
                        constants?.DELIVERED_DOC.REJECTED ? (
                        <Button color="danger" disabled={true}>
                          Rejected
                        </Button>
                      ) : (
                        <Button
                          color="danger"
                          onClick={() => {
                            verifyDocument(3);
                          }}
                        >
                          Reject all
                        </Button>
                      )}
                    </div>
                  </div> */}
                </>
              ) : null}
            </div>
          )}
        </ModalContainer>
      </div>
    </React.Fragment>
  );
}
