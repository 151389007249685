import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { List, Table } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import ImageGallery from "react-image-gallery";
import moment from "moment";
import { Context } from "../../../../Components/Context/Context";
import { Admin_RateContract } from "../../../../Library/LanguageConversion/Admin_RateContract";

export default function Specifications() {
  const {language, setLanguage} = useContext(Context)
  const [product_info, setProductInfo] = useState<any>([]);
  const [tend_data, setTendData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [specData, setSpecData] = useState<any>()
  const [specShowAll, setSpecShowAll] = useState<boolean>(false)
  const location = useLocation();
  const id = location?.state?.id;
  const tend_id = location?.state?.tender_id;
  useEffect(() => {
    getProductData(id);
  }, []);

  useEffect(() => {
    getProductVendors();
  }, [tend_id]);

  //===================================================== Get Product Data ========================================

  const getProductData = (id: number) => {
    setLoading(true);
    api
      .get(`/product/products/${id}`, true)
      .then(async function ([success, response]) {
        setProductInfo(response?.data);
        setSpecData(response?.data?.specifications?.filter((spec: any) => spec?.value?.length !== 0 && spec?.key?.length !== 0))
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  //===================================================== Get Product Data ========================================

  const getProductVendors = () => {
    setLoading(true);
    api
      .get(`/productvendors?tender_id=${tend_id}&product_id=${id}`, true)
      .then(async function ([success, response]) {
        setTendData(response?.message || []);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //==================================================== Image Gallery ===============================================

  const images = product_info?.images?.map((item: any) => {
    return {
      original: item?.path,
      thumbnail: item?.path,
    };
  });
  //======================================================= View ==================================================

  // spec show all / show less handler 
  const specShowHandler = () => {
    setSpecShowAll(!specShowAll)
  }

  return (
    <>
      {/* inner contents starts here */}
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <Link className="active" style={{ pointerEvents: "none" }} to="">
              {Admin_RateContract?.Product_Details[language]}
            </Link>
          </div>
          {/* breadcrumb */}
          <List className=" breadcrumb">
            <li>
              <Link
                className="active "
                to="/dashboard"
                style={{ minWidth: "auto" }}
              >
                {Admin_RateContract?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <Link className="active" to="/rate">
                {Admin_RateContract?.Upload_Rate[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                <NavLink
                  className="active "
                  to="/rate-contract"
                  state={{ new_data: tend_id }}
                >
                  {Admin_RateContract?.Rate_Contract[language]}
                </NavLink>
              </b>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>{Admin_RateContract?.Specifications[language]}</b>
            </li>
          </List>
        </div>
        {loading ? (
          <>
            <div className='text-center py-5'>
              {" "}
              <Loader />
            </div>
          </>
        ) : (
          <>
            <div className="specifications-wrap mb-5">
              <div className="row">
                <div className="col-md-6">
                  <div className="row gy-3 gx-3">
                    <div className="col-md-12">
                      <div className="spec-main-img">
                        {/* <img
                          src={
                            product_info?.images?.[0]?.path
                              ? product_info?.images?.[0]?.path
                              : "https://www.altaraza.ph/wp-content/themes/altaraza/assets/images/temp.jpg"
                          }
                          alt="img"
                          className="w-100"
                        /> */}

                        {/* image gallery */}
                        {product_info?.images?.length !== 0 ? (
                          <>
                            {images && (
                              <ImageGallery
                                items={images}
                                showNav={false}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                lazyLoad
                              />
                            )}
                          </>
                        ) : (
                          <img
                            src="https://www.altaraza.ph/wp-content/themes/altaraza/assets/images/temp.jpg"

                            alt="img"
                            className="empty-spec-img"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="specifications-content-wrap">
                    <div className="main-specifications-wrap">
                      <h2 className="main-heading">{product_info?.name}</h2>
                      <hr />
                    </div>

                    <table className="table table-hover table-bordered table-results-product-outer">
                      <tbody>
                        {specData?.map((item: any, i: number) => {
                          return (
                            <>
                              {(!specShowAll ? i < 4 : true) && <>
                                {item?.value !== "" ? (
                                  <tr>
                                    <td>{item?.key}</td>
                                    <td>{item?.value}</td>
                                  </tr>
                                ) : null}
                              </>}
                            </>
                          );
                        })}
                        <tr>
                          <td> <small className={"d-block"}>
                            {Admin_RateContract?.HSN_Code[language]}
                          </small></td>
                          <td><span>{product_info?.hsn_code}</span></td>
                        </tr>
                        <tr>
                          <td> <small className={"d-block"}>
                            {Admin_RateContract?.Product_code[language]}
                          </small></td>
                          <td> <span>{product_info?.product_code}</span></td>
                        </tr>
                        <tr>
                          <td> <small className={"d-block"}>
                            {Admin_RateContract?.Category[language]}
                          </small></td>
                          <td><span>{product_info?.categories?.name}</span></td>
                        </tr>

                        {product_info?.manufacturers?.length !== 0 ? (
                          <small className={"d-block"}>
                            {product_info?.manufacturers?.length > 1
                              ? "Manufacturers:"
                              : "Manufacturer:"}
                            &nbsp;
                            <span>
                              {product_info?.manufacturers?.map(
                                (obj: any, i: number) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <span>{obj.name}</span>
                                      {product_info?.manufacturers?.length > 1 &&
                                        product_info?.manufacturers?.length !=
                                        i + 1 &&
                                        ","}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </span>
                          </small>
                        ) : null}
                        {product_info?.rate?.length !== 0 ? (
                          <tr>
                            <td>{Admin_RateContract?.Price[language]}:</td>
                            <td><span className="green-text">
                              ₹
                              {product_info?.rate
                                ? product_info?.rate[0]?.price
                                : null}
                            </span></td>
                          </tr>
                        ) : null}

                        {/* show all / show less  */}
                        {specData?.length > 4 && <tr onClick={specShowHandler}>
                          <th colSpan={2} className='text-center show-more-less-text'><span>{specShowAll ? 'Show Less' : 'Show All'}</span></th>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="spec-table-outer-wrap">
              {/* tabs */}
              <div className="spec-table-tabs">
                <h6 className=""> {Admin_RateContract?.View_Vendors[language]}</h6>

                {/* <Link
              to="/product-comparison"
              className=" primary-bg  text-decoration-none"
            >
              Product Comparison
            </Link> */}
              </div>
              <div className="table-wrap responsive-table">
                <Table className="w-100 table-striped">
                  <thead>
                    <tr>
                      <th>{Admin_RateContract?.Sl_No[language]}</th>
                      <th className="">{Admin_RateContract?.Vendors[language]}</th>
                      <th className="">{Admin_RateContract?.Vendor_Name[language]}</th>
                      <th className="">{Admin_RateContract?.Contract_Expiry[language]}</th>
                      <th className="">{Admin_RateContract?.Registration_ID[language]}</th>
                      <th className="">{Admin_RateContract?.Contact_Number[language]}</th>
                      <th className="">{Admin_RateContract?.Email_ID[language]}</th>
                      <th className="">
                        {Admin_RateContract?.Price[language]}(<i className="fa fa-inr"></i>)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tend_data?.length !== 0 ? (
                      <>
                        {tend_data.map((item: any, i: number) => {
                          return (
                            <React.Fragment key={item?.id}>
                              <tr>
                              <td className="">
                                  <span className="mobile-heading">{Admin_RateContract?.Sl_No[language]}:</span>
                                  <span className="table-data">{i + 1}</span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">{Admin_RateContract?.Lows[language]}:</span>
                                  <span className="table-data">
                                    {item?.type == 1 ? (
                                      <span>{Admin_RateContract?.Low_1[language]}</span>
                                    ) : item?.type == 2 ? (
                                      <span>{Admin_RateContract?.Low_2[language]}</span>
                                    ) : item?.type == 3 ? (
                                      <span>{Admin_RateContract?.Low_3[language]}</span>
                                    ) : null}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Vendor_Name[language]}
                                  </span>
                                  <span className="table-data">
                                    {item?.name}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Contract_Expiry[language]}:
                                  </span>
                                  <span className="table-data">
                                    {moment(item?.to_date).format("DD-MM-YYYY")}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Registration_ID[language]}:
                                  </span>
                                  <span className="table-data">
                                    {item?.registration_id}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Contact_Number[language]}:
                                  </span>
                                  <span className="table-data">
                                    {item?.phone}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Email_ID[language]}:
                                  </span>
                                  <span className="table-data">
                                    {item?.email}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">{Admin_RateContract?.Price[language]}:</span>
                                  <span className="table-data">
                                    ₹ {item?.price}
                                  </span>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td
                          className="text-center px-2 py-5 empty-text"
                          colSpan={7}
                        >
                          {Admin_RateContract?.Not_Assigned[language]}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
