import React, { useEffect,useRef, useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import { file_remove, preventNegativeValues } from "../../../Library/Constants/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;

export default function AnyOthercomapnyDocuments(props: any) {
  const {language,setLanguage}=useContext(Context);
  const anyRef = useRef<HTMLInputElement>(null);
  const fetchLocation = (pinCode: any) => {
    if (pinCode.length > 5) {
      axios
        .get(`https://api.postalpincode.in/pincode/${pinCode}`)
        .then((response: any) => {
          let v = response?.data[0]?.PostOffice?.[0];
          props?.setFieldValue("any_other_company_district", v?.District);
          props?.setFieldValue("any_other_company_state", v?.State);
          props?.setFieldValue("any_other_company_city", v?.Name);
        })
        .catch((error) => {
          toast.error("Please Enter a Valid Pincode");
        });
    }
  };
  return (
    <> 
      <div className="company-information-update-wrap">
        <h4>{Vendor_profileUpdation?.Any_Other_Company?.[language]}</h4>
        <hr />
        <Row>
          <Col md={4} sm={6}>
            <FormGroup>
              <Label>
                Any Other Office Proof{" "}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.list > 0 ? (
                  <InputGroup>
                    <input
                      id="any_other_company_documents"
                      name="any_other_company_documents"
                      className="form-control"
                      accept=".pdf"
                      value="Any Other Office Proof.pdf"
                      disabled={
                        props?.datas?.any_other_company_documents ? true : false
                      }
                    ></input>
                    <InputGroupText
                      className="input-group-text bg-white border-start-0"
                      id="newpass"
                    >
                      <a
                        href={"#"}
                        onClick={() => {
                          props?.documentdownload(
                            props?.datas?.any_other_company_documents
                          );
                        }}
                      >
                        {" "}
                        <i className="bi bi-box-arrow-down"></i>
                      </a>
                    </InputGroupText>
                  </InputGroup>
                ) : (
                  <>
                    <Input
                      type="file"
                      id="any_other_company_documents"
                      name="any_other_company_documents"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                     innerRef={anyRef}
                    ></Input>
                    <Label
                      className="image-button"
                      for="any_other_company_documents"
                      id="any_other_company_documents"
                    >
                      {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    {props?.values?.any_other_company_documents && (
                  <>
                    {" "}
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "5px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove("any_other_company_documents", anyRef, "any_other_company_documents");
                        props?.setFieldValue("any_other_company_documents", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
                  </>
                )}
              </div>
              <ErrorMessage
                name="any_other_company_documents"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Company_Name [language]}{" "}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="any_other_company_name"
                    type="text"
                    value={props?.values?.any_other_company_name}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="any_other_company_name"
                    type="text"
                    value={props?.values?.any_other_company_name}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="any_other_company_name"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Company_Email?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="any_other_company_email"
                    type="text"
                    value={props?.values?.any_other_company_email}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="any_other_company_email"
                    type="text"
                    value={props?.values?.any_other_company_email}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="any_other_company_email"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Company_Address?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="any_other_company_address"
                    type="textarea"
                    value={props?.values?.any_other_company_address}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="any_other_company_address"
                    type="text"
                    value={props?.values?.any_other_company_address}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="any_other_company_address"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.Pincode?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="any_other_company_pincode"
                    type="text"
                    value={props?.values?.any_other_company_pincode}
                    onChange={(e) => {
                      props?.handleChange(e);
                      fetchLocation(e.target.value);
                    }}
                  />
                ) : (
                  <Input
                    name="any_other_company_pincode"
                    type="text"
                    value={props?.values?.any_other_company_pincode}
                    onChange={(e) => {
                      props?.handleChange(e);
                      fetchLocation(e.target.value);
                    }}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="any_other_company_pincode"
                component={TextError}
              />
            </FormGroup>
          </Col>

          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>{Vendor_profileUpdation?.City?.[language]}</Label>
                {props?.list <= 0 ? (
                  <Input
                    name="any_other_company_city"
                    type="text"
                    value={props?.values?.any_other_company_city}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="any_other_company_city"
                    type="text"
                    value={props?.values?.any_other_company_city}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              {/* <ErrorMessage name="village_land" component={TextError} /> */}
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>{Vendor_profileUpdation?.District?.[language]}</Label>
                {props?.list <= 0 ? (
                  <Input
                    name="any_other_company_district"
                    type="text"
                    value={props?.values?.any_other_company_district}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="any_other_company_district"
                    type="text"
                    value={props?.values?.any_other_company_district}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              {/* <ErrorMessage name="village_land" component={TextError} /> */}
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>{Vendor_profileUpdation?.State?.[language]}</Label>
                {props?.list <= 0 ? (
                  <Input
                    name="any_other_company_state"
                    type="text"
                    value={props?.values?.any_other_company_state}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="any_other_company_state"
                    type="text"
                    value={props?.values?.any_other_company_state}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              {/* <ErrorMessage name="village_land" component={TextError} /> */}
            </FormGroup>
          </Col>
          <Col sm={12} className="mt-2">
            {" "}
            {props?.start_up1 !== 1 && (
              <>
                {props?.values?.any_other_company_state == "Karnataka" &&
                props?.values?.any_other_company_documents ? (
                  <>
                    <span className="text-success mark-end">{Vendor_profileUpdation?.Mark?.[language]}:10</span>
                  </>
                ) : null}
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
