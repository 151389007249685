import React from 'react'
import { Link } from 'react-router-dom';
import FooterEng from '../../Components/Footer';



const AboutUsEng = () => {
  const data = [
    { name: 'Laptops', students: 400 },
    { name: 'Technical scripter', students: 700 },
    { name: 'Geek-i-knack', students: 200 },
    { name: 'Geek-o-mania', students: 1000 }
];
  return (
    <>
      <div>
        <main>
          {/*Hero Section*/}
          <section>
            <div className="about-container-fluid">
              <div className="about-banner-outer">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="about-banner-text-wrap">
                        <h1>
                          About Us
                        </h1>
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/home"}>Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></section>
          {/* content */}
          <section className="about-us-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-12 mt-4 py-5">
                  {/* <canvas id="myChart" /> */}
                  <img className='chart' src={require("../../images/englishpiechart.jpg")} />
                </div>
                <div className="content-inner col-lg-8 col-md-12">
                  <p className="sentence">Keonics is a leading provider of innovative technology solutions, serving the Indian government and public sector organizations. With over three decades of experience in the industry, Keonics has a proven track record of delivering high-quality, cost-effective solutions that meet the unique needs of its clients.</p>
                  <p className="sentence">In an effort to streamline procurement processes and improve transparency, Keonics is launching a new project to develop a public procurement system. This platform will bring together key stakeholders including government departments, the Keonics project management team, and respective departments who want to procure through Keonics.</p>
                </div>
              </div>
            </div>
          </section>
          {/* content-para */}
          <section className="about-us-content-para">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 animate__animated animate__fadeInUp">
                  <p className="sentence">The system will provide a centralized, online portal for all procurement activities, enabling government agencies to efficiently manage the procurement process from start to finish. The platform will also allow for easy collaboration between stakeholders, as well as real-time tracking and reporting of procurement activities. With a focus on user-friendliness and accessibility, the system will ensure that all parties involved in the procurement process have access to the information they need to make informed decisions.</p>
                  <p className="sentence">Security is a top priority for Keonics, and the new procurement system will include robust security features to protect sensitive information and ensure compliance with government regulations.</p>
                  <p className="sentence">As a leading provider of technology solutions to the public sector, Keonics is committed to delivering value to its clients and contributing to the success of the Indian government. With the new government public procurement system, Keonics is poised to revolutionize the way procurement is done in the government and improve the overall efficiency of procurement processes.</p>
                  <p className="sentence">Keonics is a leading provider of innovative technology solutions for the Indian government and public sector organizations. With over three decades of experience in the industry, Keonics has a proven track record of delivering high-quality, cost-effective solutions that meet the unique needs of its clients.</p>
                </div>
              </div>
            </div>
          </section>
          {/* objectives */}
          <section className="about-us-objectives">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h3>Objectives:</h3>
                </div>
                <div className="col-lg-12 col-md-12">
                  <ul className="animate__animated animate__fadeInUp">
                    <li><p>To provide better e-governance and increased transparency in procurement processes.</p></li>
                    <li><p>To enable efficient and effective social audit processes.</p></li>
                    <li><p>To increase the speed of procurement and reduce manual work.</p></li>
                    <li><p>To improve the standard of procurement and ensure compliance with government regulations.</p></li>
                    <li><p>To provide on-call support to departments and vendors.</p></li>
                    <li><p>To foster good relationships with departments and vendors, promoting collaboration and trust.</p></li>
                    <li><p>To leverage innovative technology to enhance procurement processes and improve overall efficiency.</p></li>
                    <li><p>To reduce procurement costs and time, providing departments with access to a wider pool of vendors and products.</p></li>
                    <li><p>To promote social accountability and responsibility in procurement processes.</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/* why-keonics */}
          <section className="about-us-why-keonics">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h2>Why should you select Keonics</h2>
                </div>
                <div className="col-lg-12 col-md-12">
                  <p className="sentence animate__animated animate__fadeInUp">To create a world-class public procurement system that enables efficient, transparent and socially accountable governance in Karnataka through the use of innovative technology.</p>
                </div>
              </div>
            </div>
          </section>
          {/* info */}
          <section className="about-us-info">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  <img src={require("../../images/Rectangle 4604.png")} alt="image" className="img-fluid" />
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="info-content animate__animated animate__fadeInUp">
                    <h3>Purchase order management</h3>
                    <p className="sentence">Automated purchase order management, allowing Keonics to efficiently manage procurement processes and reduce manual work.</p>
                  </div>
                  <div className="info-content animate__animated animate__fadeInUp">
                    <h3>Reporting and analytics</h3>
                    <p className="sentence">Real-time reporting and analytics capabilities to provide insights into procurement activities and improve decision making.</p>
                  </div>
                  <div className="info-content animate__animated animate__fadeInUp">
                    <h3>Delivery management</h3>
                    <p className="sentence">Real-time tracking and reporting of product deliveries, ensuring departments receive their products in a timely and efficient manner.</p>
                  </div>
                  <div className="info-content animate__animated animate__fadeInUp">
                    <h3>Compliance with regulations</h3>
                    <p className="sentence">Robust security features to ensure compliance with government regulations and protect sensitive information.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div></>
  )
}

export default AboutUsEng