import React, { useContext, useEffect} from "react";
import { useState } from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledAccordion,
  AccordionBody,
  AccordionItem,
  AccordionHeader
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import moment from "moment";
import { Admin_Payment } from "../../../../Library/LanguageConversion/Admin_Payment";
import { Context } from "../../../../Components/Context/Context";

export default function CancelledPayments() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cancelled_payment, setCancelledPayments] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const {language,setLanguage} = useContext(Context)
  const [pageData, setPageData] = useState<any>({
    total: "",
    per_page: "",
    currentPage: "",
    from: "",
    to: "",
  });

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getCancelledPayments();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);

  const getCancelledPayments = () => {
    setLoading(true);
    api
      .get(
        `/payments?status=${constants.PAYMENTSTATUS.CANCLED}&search_keyword=${search}&page=${currentPage}`,
        true
      )
      .then(async function ([success, response]) {
        setCancelledPayments(response?.data?.data || []);
        setPageData({
          ...pageData,
          total: response?.data?.total,
          per_page: response?.data?.per_page,
          currentPage: response?.data?.current_page,
          from: response?.data?.from,
          to: response?.data?.to,
        });
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
      
  };
  return (
    <React.Fragment>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText title="Search here" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={Admin_Payment?.Search_here[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_Payment?.SlNo[language]}</th>
              <th>{Admin_Payment?.Date?.[language]}</th>
              <th>{Admin_Payment?.TransactionID?.[language]}</th>
              <th>{Admin_Payment?.OrderDetails?.[language]}</th>
              <th>{Admin_Payment?.Department?.[language]}</th>
              <th>{Admin_Payment?.Quantity?.[language]}</th>
              <th>{Admin_Payment?.Price?.[language]}</th>
            </tr>
          </thead>
          <tbody>
            {cancelled_payment?.length !== 0 ? (
              <>
                {cancelled_payment?.map((item: any) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.SlNo[language]}:</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.Date?.[language]}:</span>
                          <span className="table-data">
                            {" "}
                            {moment(item?.created_at).format("DD-MM-YYYY")}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                          {Admin_Payment?.TransactionID?.[language]}:
                          </span>
                          <span className="table-data">
                            {" "}
                            {item?.transaction_id}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.OrderDetails?.[language]}:</span>
                          <span className="table-data">
                            {item?.invoicedata.length > 1 ? (
                              <>
                            <UncontrolledAccordion defaultOpen="0" className="order-list-accordion">
                            <AccordionItem>
                            <AccordionHeader targetId="1" className="">
                            {Admin_Payment?.More_than_one_order?.[language]}. <span>{Admin_Payment?.Click_to_see_the_Orderlist?.[language]}.</span>
                            </AccordionHeader>
                            <AccordionBody accordionId="1" className="">
                                {item?.invoicedata?.invoicedetails.map(
                                  (item: any) => {
                                    return (
                                      <>
                                        Dell<br></br>
                                        {Admin_Payment?.Unit_Price?.[language]}:
                                        <i className="bi bi-currency-rupee"></i>
                                        {item?.unit_price}
                                        <br></br>
                                        {Admin_Payment?.Quantity?.[language]}:{item?.quantity}
                                        <br></br>
                                        {Admin_Payment?.Total_Price?.[language]}:{" "}
                                        <i className="bi bi-currency-rupee"></i>
                                        {item?.total_amount}
                                        {/* {item?.invoicedata?.invoicedetails
                                          .length > 1 ? (
                                          <hr />
                                        ) : null} */}
                                      </>
                                    );
                                  }
                                )}
                                </AccordionBody>
                                </AccordionItem>
                                </UncontrolledAccordion>
                              </>
                            ) : null}

                            {item?.invoicedata.length == 1 ? (
                              <>
                                {item?.invoicedata?.invoicedetails.map(
                                  (item: any) => {
                                    return (
                                      <>
                                        Dell<br></br>
                                        {Admin_Payment?.Unit_Price?.[language]}:
                                        <i className="bi bi-currency-rupee"></i>
                                        {item?.unit_price}
                                        <br></br>
                                        {Admin_Payment?.Quantity?.[language]}:{item?.quantity}
                                        <br></br>
                                        {Admin_Payment?.Total_Price?.[language]}:{" "}
                                        <i className="bi bi-currency-rupee"></i>
                                        {item?.total_amount}
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.Department?.[language]}:</span>
                          <span className="table-data">
                            {item?.department[0]?.name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.Quantity?.[language]}:</span>
                          <span className="table-data">
                            {item?.invoicedata?.invoicedetails[0]?.quantity}
                          </span>
                        </td>
                        <td className="">
                          <span className="mobile-heading">{Admin_Payment?.Total_Price?.[language]}:</span>
                          <span className="table-data text-danger">
                            ₹{item?.amount}
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={6}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Cancelled Payments found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {cancelled_payment.length !== 0 && pageData && (
        <div className="table-footer-wrap">
          <small>
            Showing {pageData.from} to {pageData.to} of {pageData.total} entries
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
