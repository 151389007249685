import React, { useEffect, useRef, useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import { file_remove } from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;

export default function RequiredDocuments(props: any) {
  const { language, setLanguage } = useContext(Context);
  const panRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const gstRef = useRef<HTMLInputElement>(null);
  const cincoRef = useRef<HTMLInputElement>(null);
  const msme_udyamRef = useRef<HTMLInputElement>(null);


  return (
    <>
      <div className="pointofcon-information-update-wrap">
        <h4>{Vendor_profileUpdation?.Mandatory_Documents?.[language]}</h4>
        <hr />
        <Row>
          <Col md={4} sm={6}>
            <FormGroup className="file">
              <Label>
                {Vendor_profileUpdation?.Pan_Card?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                <Input
                  innerRef={panRef}
                  type="file"
                  id="pan"
                  name="pan"
                  className="form-control"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                  accept=".pdf"
                  disabled={props?.list > 0 ? true : false}
                ></Input>
                <Label className="image-button" for="pan" id="pan">
                  {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.pan && (
                  <>
                    {" "}
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "5px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove("pan", panRef, "pan");
                        props?.setFieldValue("pan", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>

              <ErrorMessage name="pan" component={TextError} />
            </FormGroup>
          </Col>

          <Col md={4} sm={6}>
            <FormGroup className="file">
              <Label>
                {Vendor_profileUpdation?.Address_Proof?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                <Input
                  innerRef={addressRef}
                  type="file"
                  id="address_proof"
                  name="address_proof"
                  className="form-control"
                  accept=".pdf"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                ></Input>
                <Label
                  className="image-button"
                  for="address_proof"
                  id="address_proof"
                >
                  {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.address_proof && (
                  <>
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "5px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove(
                          "address_proof",
                          addressRef,
                          "address_proof"
                        );
                        props?.setFieldValue("address_proof", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>
              <ErrorMessage name="address_proof" component={TextError} />
            </FormGroup>
          </Col>

          {props?.vendormse == "yes" ? (
            <Col md={4} sm={6}>
              <FormGroup className="file">
                <Label>
                  MSME/Udyam Certificate
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                <div className="image-input">
                  <Input
                    type="file"
                    id="mse_certificate"
                    name="mse_certificate"
                    accept=".pdf"
                    className="form-control"
                    onChange={(e: any) =>
                      props?.handleFileChange(e, e.target.files[0])
                    }
                    innerRef={msme_udyamRef}
                  ></Input>
                  <Label
                    className="image-button"
                    for="mse_certificate"
                    id="mse_certificate"
                  >
                    {Vendor_profileUpdation?.Choose_Documents?.[language]}
                  </Label>

                  {props?.values?.mse_certificate && (
                    <>
                      <span
                        className="upload-text"
                        style={{
                          marginRight: "5px",
                          zIndex: "45",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          file_remove(
                            "mse_certificate",
                            msme_udyamRef,
                            "mse_certificate"
                          );
                          props?.setFieldValue("mse_certificate", "");
                        }}
                      >
                        &#x2715;
                      </span>
                    </>
                  )}
                </div>
                <ErrorMessage name="mse_certificate" component={TextError} />
              </FormGroup>
            </Col>
          ) : null}

          <Col md={4} sm={6}>
            <FormGroup className="file">
              <Label>
                {Vendor_profileUpdation?.GST_Registration?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                <Input
                  type="file"
                  id="gst_certificate"
                  name="gst_certificate"
                  accept=".pdf"
                  className="form-control"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                  innerRef={gstRef}
                ></Input>
                <Label
                  className="image-button"
                  for="gst_certificate"
                  id="gst_certificate"
                >
                  {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.gst_certificate && (
                  <>
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "5px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove(
                          "gst_certificate",
                          gstRef,
                          "gst_certificate"
                        );
                        props?.setFieldValue("gst_certificate", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>
              <ErrorMessage name="gst_certificate" component={TextError} />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup className="file">
              <Label>
                Company Incorporation /Registration Certificate
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                <Input
                  innerRef={cincoRef}
                  type="file"
                  id="incorporation_certificate"
                  name="incorporation_certificate"
                  className="form-control"
                  accept=".pdf"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                ></Input>
                <Label
                  className="image-button"
                  for="incorporation_certificate"
                  id="incorporation_certificate"
                >
                  {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.incorporation_certificate && (
                  <>
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "5px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove(
                          "incorporation_certificate",
                          cincoRef,
                          "incorporation_certificate"
                        );
                        props?.setFieldValue("incorporation_certificate", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>
              <ErrorMessage
                name="incorporation_certificate"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={12}>
            {props?.start_up !== 1 && (
              <>
                {props?.values?.incorporation_certificate && (
                  <>
                    <span className="text-success mark-end">{Vendor_profileUpdation?.Mark?.[language]}:5</span>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
