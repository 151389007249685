import React from "react";
import { useState, useEffect,useContext } from "react";
import { Context } from "../../../../Components/Context/Context";
import {Admin_orders} from "../../../../Library/LanguageConversion/Admin_orders";
import {
    Button,
    Table,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    UncontrolledAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import OrderView from "../Modal/OrderView";
import moment from "moment";
import { getOrderStatus, getUnique } from "../../../../Library/Constants/helpers";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../../../Library/ApiSettings";


const ValidationSchema = Yup.object().shape({
    // document: Yup.string()
    //   .required("Field is required"),
    transaction: Yup.string()
        .required("Field is required")
    //.matches(/^ [A - Z0 - 9]{ 11 } (? !.* [.: _ -]{ 2 })[A - Z0 - 9.: _ -]{ 0, 30 } [A - Z0 - 9]$1/, 'Invalid transaction number format')
    ,
    pay_amount: Yup.number()
        .required("Field is required"),

})
export default function DraftedOrdersTab() {
    const { language, setLanguage } = useContext(Context);
    const navigate = useNavigate();
    const [err, seterr] = useState({
        pay_amount: ""
    })
    const [pay, setPay] = useState(false)
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [loading, setLoading] = useState(true);
    const [cancelled_order, setCancelledOrder] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageData, setPageData] = useState<any>();
    const [search, setSearch] = useState("");
    const [order_view, setOrderView] = useState([]);
    const [graph_data, setGraphData] = useState<any>([]);
    const [total, setTotal] = useState()
    const [image, setImage] = useState({
        img: ""
    })
    const [order_id, setOrderId] = useState("")
    const handleSearch = (event: any) => {
        setSearch(event.target.value);
    };

    const initialValues = {
        document: null,
        transaction: "",
        pay_amount: ""
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search !== null || search === "") {
                getCancelledOrder();
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [search, currentPage]);

    const getCancelledOrder = () => {
        setLoading(true);
        api
            .get(
                `/order/pf-invoice?status=${constants?.ORDERS?.DRAFT}&page=${currentPage}&search_value=${search}`,
                true
            )
            .then(async function ([success, response]) {
                setCancelledOrder(response?.data || []);
                setPageData(response?.meta);
                setLoading(false);
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const getSingleOrder = (order_id: any) => {
        setLoading(true);
        api
            .get(`/order/get-proforma-details?id=${order_id}`, true)
            .then(async function ([success, response]) {
                setLoading(false);
                setOrderView(response?.message?.[0]);
                setTotal(response?.message?.[0]?.total_amount)
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    function limitText(text: any, limit: any) {
        if (text?.length > limit) {
            return text?.substring(0, limit) + "...";
        } else {
            return text;
        }
    }

    return (
        <>
            <div className="search-widget-outer">
                <Form role="search">
                    <InputGroup className="search-group">
                        <InputGroupText title="Product Name/Order ID" className="search-icon" id="search">
                            <i className="fa-sharp fa-solid fa-magnifying-glass" />
                        </InputGroupText>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder={Admin_orders?.ProductName_order?.[language]}
                            aria-label="Username"
                            aria-describedby="search"
                            value={search}
                            onChange={handleSearch}
                            onKeyPress={(event) => {
                                var key = event.keyCode || event.which;
                                if (key === 13) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </InputGroup>
                </Form>
            </div>
            <div className="table-wrap responsive-table">
                <Table className="w-100 table-striped">
                    <thead>
                        <tr>
                            <th>{Admin_orders?.SlNo[language]}</th>
                            <th>{Admin_orders?.OrderID[language]}</th>
                            <th>{Admin_orders?.OrderedDate[language]}</th>
                            <th>{Admin_orders?.OrderDetails[language]}</th>
                            <th>{Admin_orders?.ShippingAddress[language]}</th>
                            <th>{Admin_orders?.Quantity[language]}</th>
                            <th>{Admin_orders?.Department[language]}</th>
                            <th>{Admin_orders?.Status[language]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cancelled_order?.length !== 0 ? (
                            <>
                                {cancelled_order?.map((item: any, i: any) => {
                                    let count = 0;

                                    return (
                                        <tr key={i}>
                                            <td>
                                                <span className="mobile-heading">{Admin_orders?.SlNo[language]}:</span>
                                                <span className="table-data">
                                                    {pageData?.from + i}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="mobile-heading">{Admin_orders?.OrderID[language]}:</span>
                                                <Link
                                                    title="View Order"
                                                    to="#"
                                                    className="primary-text"
                                                    onClick={() => {
                                                        toggle();
                                                        getSingleOrder(item?.id);
                                                        setOrderId(item?.order_id)
                                                    }}
                                                >
                                                    {item?.order_id}
                                                </Link>
                                            </td>

                                            <td>
                                                <span className="mobile-heading">{Admin_orders?.OrderedDate[language]}:</span>
                                                <span className="table-data">
                                                    {moment(item?.created_at).format("DD-MM-YYYY")}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="mobile-heading">{Admin_orders?.OrderDetails[language]}:</span>
                                                <span className="table-data">
                                                    {/* Multiple order list */}
                                                    {item?.order_details?.length > 1 ? (
                                                        <>
                                                            <UncontrolledAccordion defaultOpen="0" className="order-list-accordion">
                                                                <AccordionItem>
                                                                    <AccordionHeader targetId="1" className="">
                                                                    {Admin_orders?.Morethanoneorder[language]}. <span>{Admin_orders?.ClicktoseetheOrderlist[language]}.</span>
                                                                    </AccordionHeader>
                                                                    <AccordionBody accordionId="1" className="">
                                                                    <AccordionHeader targetId="1" className="close-btn">
                                                                        <span className="close-btn-inner" title="Close">
                                                                        <i className="bi bi-x-lg"></i>
                                                                        </span>
                                                                    </AccordionHeader>
                                                                        <div className="order-list-outer-wrap">
                                                                            {item?.order_details?.map(
                                                                                (obj: any, x: number) => {
                                                                                    count = count + obj.quantity;
                                                                                    return (
                                                                                        <React.Fragment key={x}>
                                                                                            <div className="product-details">
                                                                                                <h6
                                                                                                    className="product-title"
                                                                                                    title={obj?.name}
                                                                                                >
                                                                                                    {limitText(obj?.name, 15)}
                                                                                                </h6>
                                                                                                <p className="sub-details">
                                                                                                {Admin_orders?.Unitprice[language]}:{" "}
                                                                                                    <span>
                                                                                                        <i className="bi bi-currency-rupee"></i>
                                                                                                        {obj?.price}
                                                                                                    </span>
                                                                                                </p>
                                                                                                <p className="sub-details">
                                                                                                {Admin_orders?.Quantity[language]}:{" "}
                                                                                                    <span>{obj?.quantity}</span>
                                                                                                </p>
                                                                                                <p className="sub-details">
                                                                                                {Admin_orders?.Total[language]}:{" "}
                                                                                                    <span>
                                                                                                        <i className="bi bi-currency-rupee"></i>
                                                                                                        {obj?.total_amount}
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>

                                                                                            {/* {item?.order_details.length > 1 ? (
                                          <hr />
                                        ) : null} */}
                                                                                        </React.Fragment>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    </AccordionBody>
                                                                </AccordionItem>
                                                            </UncontrolledAccordion>
                                                        </>

                                                    ) : null}

                                                    {/* Single order */}
                                                    {item?.order_details?.length == 1 ? (
                                                        <>

                                                            {item?.order_details?.map(
                                                                (obj: any, x: number) => {
                                                                    count = count + obj.quantity;
                                                                    return (
                                                                        <React.Fragment key={x}>
                                                                            <div className="product-details">
                                                                                <h6
                                                                                    className="product-title"
                                                                                    title={obj?.name}
                                                                                >
                                                                                    {limitText(obj?.name, 15)}
                                                                                </h6>
                                                                                <p className="sub-details">
                                                                                {Admin_orders?.Unitprice[language]}:{" "}
                                                                                    <span>
                                                                                        <i className="bi bi-currency-rupee"></i>
                                                                                        {obj?.price}
                                                                                    </span>
                                                                                </p>
                                                                                <p className="sub-details">
                                                                                {Admin_orders?.Quantity[language]}:{" "}
                                                                                    <span>{obj?.quantity}</span>
                                                                                </p>
                                                                                <p className="sub-details">
                                                                                {Admin_orders?.Total[language]}:{" "}
                                                                                    <span>
                                                                                        <i className="bi bi-currency-rupee"></i>
                                                                                        {obj?.total_amount}
                                                                                    </span>
                                                                                </p>
                                                                            </div>

                                                                            {item?.order_details.length > 1 ? (
                                                                                <hr />
                                                                            ) : null}
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            )}
                                                        </>

                                                    ) : null}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="mobile-heading">
                                                {Admin_orders?.ShippingAddress[language]}:
                                                </span>
                                                <span className="table-data d-block">
                                                    <p>
                                                    {Admin_orders?.BuildingNo[language]}:
                                                        {item?.shipping_address[0]?.building_no}
                                                    </p>
                                                    <p>{item?.shipping_address[0]?.city}</p>
                                                    <p>
                                                        {" "}
                                                        {item?.shipping_address[0]?.district},{" "}
                                                        {item?.shipping_address[0]?.state}
                                                    </p>

                                                    <p>{Admin_orders?.Pincode[language]}:{item?.shipping_address[0]?.pincode}</p>
                                                </span>
                                            </td>
                                            <td>
                                                <span className="mobile-heading"> {Admin_orders?.Quantity[language]}:</span>
                                                <span className="table-data">{count}</span>
                                            </td>
                                            <td>
                                                <span className="mobile-heading">{Admin_orders?.Department[language]}:</span>
                                                <span className="table-data">
                                                    {item?.user?.full_name}
                                                </span>
                                            </td>
                                            <td>
                                                {(item?.draft == null || item?.draft?.status == 3 || item?.draft?.status == 0) ?
                                                    <span className="text-danger">{Admin_orders?.NotUploaded[language]}</span>
                                                    :
                                                    item?.draft?.status == 1 ?
                                                        <>
                                                            <span className="text-success">{Admin_orders?.Uploaded[language]}</span>
                                                            <i className="fa fa-check-circle text-success ms-1"></i>
                                                        </>
                                                        :
                                                        item?.draft?.status == 2 && <span className="text-Success">{Admin_orders?.Approved[language]}</span>
                                                }
                                            </td>
                                        </tr>
                                    );
                                })}
                            </>
                        ) : (
                            <tr>
                                <td className="text-center px-2 py-5 empty-text" colSpan={8}>
                                    {loading ? (
                                        <div className="d-flex justify-content-center">
                                            <Loader />
                                        </div>
                                    ) : (
                                        "No Order Found"
                                    )}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {pageData && cancelled_order?.length !== 0 && (
                <div className="table-footer-wrap">
                    <small>
                    {Admin_orders?.Showing[language]}{pageData.from} to {pageData.to} of {pageData.total} {Admin_orders?.entries[language]}
                    </small>
                    <div className="pagination">
                        <CustomPagination
                            totalItems={pageData?.total}
                            itemsPerPage={pageData?.per_page}
                            activePage={pageData.current_page}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
            <OrderView
                order_view={order_view}
                modal={modal}
                toggle={toggle}
                loading={loading}
                getSingleOrder={getSingleOrder}
            />
        </>
    );
}
