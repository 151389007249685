import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import { api } from "../../Library/CustomApi";
import { useNavigate, useLocation } from "react-router-dom";
import { LoginViewRegLang } from "../../Library/LanguageConversion/LoginViewLang";
import { Context } from "../../Components/Context/Context";
import {
  Input,
  Button,
  FormGroup,
  Label,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner
} from "reactstrap";
import { NewPasswordValidationSchema } from "./ForgotValidation";
import { toast } from "react-toastify";
import TextError from "../../Components/Errors/TextError";

function SetNewPassword() {
  const { language, setLanguage } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setpassword] = useState<any>("password");
  const [password_, setpassword_] = useState<any>("password");
  const [eye, seteye] = useState(true);
  const [eye_, seteye_] = useState(true);

  const [error, setErr] = useState("");
  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };
  const Eye1 = () => {
    if (password_ == "password") {
      setpassword_("text");
      seteye_(false);
    } else {
      setpassword_("password");
      seteye_(true);
    }
  };

  const handleSubmit = (values: any, actions: any) => {
    sendLoginData(values, actions);
  };
  const tokensplit = location.pathname.split("=");

  const sendLoginData = (values: any, actions: any) => {
    if (values?.retype_password !== values?.password) {
      setErr(`${LoginViewRegLang?.password_mismatch[language]}`);
      actions.setSubmitting(false);
    } else {
      api
        .post(
          "/reset-password",
          {
            token: tokensplit[1],
            password: values?.password,
            password_confirmation: values?.retype_password,
          },
          false
        )
        .then(async function ([success, response, status]: any) {
          if (status == 200) {
            toast.success(`${LoginViewRegLang?.update_password_successfully[language]}`);
            let token = response?.message?.token;
            let user_name = response?.message?.name;
            let user_id = response?.message?.advanced_registration_details?.[0]?.user_id;
            let role_id = response?.message?.role;
            let profile_status = 5;
            localStorage?.setItem("AUTH_TOKEN", token);
            localStorage?.setItem("USER_NAME", user_name);
            localStorage?.setItem("USER_ID", user_id);
            localStorage?.setItem("ROLE_ID", role_id);
            if (role_id == 5) navigate('/qa-vendor/orders')
            if (role_id == 4) {
              navigate("/basic-details-view")
            }
            else {
              navigate("/dashboard");
            }
          }
          actions.setSubmitting(false);
          return response;
        })
        .catch((err) => {
          actions.setSubmitting(false);
          toast.error(err?.response?.data?.message)
        });
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="login-outer">
          <Row>
            {/* left side */}
            <Col lg={5} md={6} sm={12} className="ps-0 login-desktop-column">
              {/* login image wrap */}
              <div className="login-img-wrap flex-column">
                <img
                  src="../images/logo-white1.png"
                  alt="login-background"
                  className="px-5"
                />
                <p className="text-white text-center px-5 w-75">
                  {LoginViewRegLang?.keonics_info[language]}
                </p>
              </div>
            </Col>
            {/* Right side */}
            <Col lg={5} md={5} sm={12} className="m-auto">
              {/* login wrap */}

              <Col sm={12} className="ps-0 login-mobile-column">
                <div className="logo-mobile mb-4">
                  <img
                    src="../images/logo-white1.png"
                    alt="login-background"
                    className="px-2"
                  />
                </div>
              </Col>
              <div className="login-wrap">
                <h1>{LoginViewRegLang?.set_new_password[language]}</h1>
                <p>{LoginViewRegLang?.create_secure_password[language]}</p>
                <div className="login-content">
                  <Formik
                    validationSchema={NewPasswordValidationSchema}
                    initialValues={{
                      retype_password: "",
                      password: "",
                    }}
                    onSubmit={(values, actions) => {
                      handleSubmit(values, actions);
                    }}
                  >
                    {({
                      errors,
                      values,
                      isSubmitting,
                      handleChange,
                      touched,
                    }) => (
                      <>
                        <Form>
                          <FormGroup>
                            <div className="custom-input">
                              <Label>{LoginViewRegLang?.new_password[language]}</Label>
                              <InputGroup>
                                <Input
                                  name="password"
                                  type={password_}
                                  value={values?.password}
                                  className={`form-control ${touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  onChange={(e: any) => { handleChange(e); setErr("") }}
                                />

                              </InputGroup>
                              <ErrorMessage
                                name="password"
                                component={TextError}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="custom-input">
                              <Label>{LoginViewRegLang?.re_type_password[language]}</Label>
                              <InputGroup>
                                <Input
                                  name="retype_password"
                                  type={password}
                                  value={values?.retype_password}
                                  className={`form-control ${touched.retype_password &&
                                    errors.retype_password
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  onChange={(e: any) => { handleChange(e); setErr("") }}
                                />
                                <InputGroupText
                                  className="input-group-text bg-white border-start-0"
                                  id="newpass"
                                >
                                  <i
                                    onClick={Eye}
                                    className={`bi ${eye ? "bi-eye-slash" : "bi-eye"
                                      }`}
                                  />
                                </InputGroupText>
                              </InputGroup>
                              <ErrorMessage
                                name="retype_password"
                                component={TextError}
                              />
                              <div className="error-outer mt-5">
                                <div className="errors">{error}</div>
                              </div>

                            </div>
                          </FormGroup>

                          <FormGroup>
                            <div className="login-button pt-2 my-4">
                              <Button
                                color="primary"
                                className={"px-5"}
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ?
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  /> :
                                  `${LoginViewRegLang?.save[language]}`
                                }
                              </Button>

                            </div>
                          </FormGroup>
                          <div className="language-btn-group">
                            <Link to="" className="" onClick={() => setLanguage('en')}>English</Link>
                            <div className="divider"></div>
                            <Link to="" onClick={() => setLanguage('ka')}>ಕನ್ನಡ</Link>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
}
export default SetNewPassword;