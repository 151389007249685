import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Table,
} from "reactstrap";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import { api } from "../../../../Library/CustomApi";
import { vendor_types } from "../../../../Library/Constants/MapConstants";
import { Context } from "../../../../Components/Context/Context";
import { admin_empanelment } from "../../../../Library/LanguageConversion/admin_empanelment";
import { VendorType } from "../../../../Library/Constants/helpers";


const InactiveVendorsTab = () => {
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const { language, setLanguage } = useContext(Context);
  // =========================================================== Search ===============================================================

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getTableData();
      }
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);

  //===================================================================================================================================

  const getTableData = () => {
    setIsLoading(true);
    api
      .get(
        `/vendors?status=4&search_keyword=${search}&page=${currentPage}`,
        true
      )
      .then(async function ([success, response]) {
        setTableData(response?.data || []);
        setPageData(response?.meta || []);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getUpdateStatus = (id: number, status: number) => {
    api
      .post(
        `/vendor/vendorstatus`,
        {
          vendor_id: id,
          status: status,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(response?.message);
          getTableData();
        }

        return response;
      })
      .catch((err) => {
        if (err?.response?.status == 500) {
          toast.error(err?.response?.data?.message);
        }
        console.log(err);
      });
  };
  return (
    <>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText title="Search" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={admin_empanelment?. Vendor_Name[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>

      <div className="table-wrap responsive-table">
        <Table className="w-100  table-striped">
          <thead>
            <tr>
            <th>{admin_empanelment?.sl_no?.[language]}</th>
              <th>{admin_empanelment?.Vendor_Name?.[language]}</th>
              <th>{admin_empanelment?.Email_ID?.[language]}</th>
              <th>{admin_empanelment?.Contact_Person_Number?.[language]}</th>
              <th> {admin_empanelment?.Registration_Id?.[language]}</th>
              <th>{admin_empanelment?.Vendor_type?.[language]}</th>
              <th>{admin_empanelment?.Inactivation_Date?.[language]}</th>
              {/* <th>Status</th> */}
              <th>{admin_empanelment?.Action?.[language]}</th>
            </tr>
          </thead>

          <tbody>
            {tableData?.length !== 0 ? (
              tableData?.map((item: any, i: number) => {

                return (
                  <React.Fragment key={i}>
                    <tr className={`${item?.status !== constants.VENDOR_STAUS.VERIFIED ? "inactive-row " : ''}`}>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.sl_no?.[language]}:</span>
                        <span className="table-data">
                          {pageData?.from + i}
                        </span>
                      </td>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Vendor_Name?.[language]}:</span>
                        <span className="table-data">
                          {item?.company?.name}
                        </span>
                      </td>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Email_ID?.[language]}:</span>
                        <span className="table-data">{item.user.email}</span>
                      </td>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Contact_Number?.[language]}:</span>
                        <span className="table-data">{item.user.phone}</span>
                      </td>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Registration_Id?.[language]}:</span>
                        <span className="table-data">
                          {item?.company?.registration_id}
                        </span>
                      </td>

                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Vendor_type?.[language]}:</span>
                        <span className="table-data">
                          {/* filters type of vendor from constants  */}
                          {/* {vendor_types?.filter((itm: any) => item.vendor_type == itm?.value)?.[0]?.name} */}
                          {VendorType(item?.vendor_type)}
                        </span>
                      </td>
                      <td>
                        <span className="mobile-heading">
                        {admin_empanelment?.Registration_Date?.[language]}:
                        </span>
                        <span className="table-data">
                          {item.created_at
                            ? moment(item.created_at).format("DD-MM-YYYY")
                            : null}
                        </span>
                      </td>
                      {/* <td>
                        {item?.status == 2 ? (
                          <span className="text-danger">Inactive</span>
                        ) : null}
                      </td> */}
                      <td>
                        <span className="mobile-heading">
                        {admin_empanelment?.Action?.[language]}:
                        </span>
                        <span className="action-flex-btn toggle-btn">
                          {item?.status == constants.VENDOR_STAUS.VERIFIED ? (
                            <Link
                              to=""
                              title="Click to Deactivate"
                              onClick={() => getUpdateStatus(item?.id, 2)}
                            >
                              <i className="fas fa-toggle-on text-success lg"></i>
                            </Link>
                          ) : (
                            <Link
                              to=""
                              title="Click to Activate"
                              onClick={() => getUpdateStatus(item?.id, 4)}
                            >
                              <i className="fas fa-toggle-off text-danger lg"></i>
                            </Link>
                          )}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              // section to display loading and error messages
              <tr>
                <td colSpan={8} className="text-center px-2 py-5 empty-text">
                  {isLoading ? <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                    : "No Vendors Found"}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {tableData?.length !== 0 && pageData && (
        <div className="table-footer-wrap">
          <small>
          {admin_empanelment?.Showing[language]} {pageData.from} {admin_empanelment?.to[language]} {pageData.to} {admin_empanelment?.of[language]} {pageData.total} {admin_empanelment?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InactiveVendorsTab;
