import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Context } from "../../../../Components/Context/Context";
import { Admin_Verifications } from "../../../../Library/LanguageConversion/Admin_Verifications";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  ButtonGroup,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import { toast } from "react-toastify";
import CustomPagination from "../../../../Components/CustomPagination/Index";


export default function DeptVerification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dept, setDept] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<any>("");
  const { language, setLanguage } = useContext(Context);

  useEffect(() => {
    getDepartment();
  }, [currentPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getDepartment();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filter]);

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };
  //===================================================== get Department ==================================================

  const getDepartment = () => {
    setLoading(true);
    api
      .get(
        `/departments?page=${currentPage}&search_value=${search}&status=${filter}`,
        true
      )
      .then(async function ([success, response]) {
        setDept(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //====================================================== update status =================================================

  const VerifyDept = (id: number, status: number) => {
    api
      .patch(
        `/departmentVerify/${id}`,
        {
          status: status,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Department Verified Successfully");
        }
        getDepartment();
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //============================================================== View ==================================================
  return (
    <React.Fragment>
      <div className="search-widget-outer verif-outer">
        <Form role="search">
          <ButtonGroup>
            <Button
              className={`${
                filter === "" ? "status-tabs active" : "status-tabs"
              }`}
              onClick={() => {
                setFilter("");
                setCurrentPage(1);
              }}
            >
              {Admin_Verifications?.All[language]}
            </Button>
            <Button
              className={`${
                filter === "0" ? "status-tabs active" : "status-tabs"
              }`}
              onClick={() => {
                setCurrentPage(1);
                setFilter("0");
              }}
            >
              {Admin_Verifications?.Pending[language]}
            </Button>
            <Button
              className={`${
                filter === "1" ? "status-tabs active" : "status-tabs"
              }`}
              onClick={() => {
                setCurrentPage(1);
                setFilter("1");
              }}
            >
              {Admin_Verifications?.Approved[language]}
            </Button> 
          </ButtonGroup>
          <InputGroup className="search-group">
            <InputGroupText title="User Name" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={Admin_Verifications?.UserName[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_Verifications?.SlNo[language]}</th>
              <th>{Admin_Verifications?.UserName[language]}</th>
              <th>{Admin_Verifications?.Email[language]}</th>
              <th>{Admin_Verifications?.PhoneNumber[language]}</th>
              <th>{Admin_Verifications?.Action[language]}</th>
              <th>{Admin_Verifications?.Status[language]}</th>
            </tr>
          </thead>
          <tbody>
          {dept?.length !== 0 ? (
              <>
                {dept?.map((item: any, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.SlNo[language]}:</span>
                          <span className="table-data">{pageData.from + i}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.UserName[language]}:</span>
                          <span className="table-data">{item?.name}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Email[language]}:</span>
                          <span className="table-data">{item?.user_details?.email}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.PhoneNumber[language]}:</span>
                          <span className="table-data">{item?.user_details?.phone}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Action[language]}:</span>
                          <div className="action-flex-btn">
                            {item?.status == constants?.DEPARTMENT?.VERIFY ? (
                              <Input
                                type="checkbox"
                                checked={false}
                                onClick={() => VerifyDept(item?.id, 1)}
                              ></Input>
                            ) : (
                              <Input type="checkbox" disabled={true} checked={true}></Input>
                            )}
                          </div>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Status[language]}:</span>
                          {item?.status == constants.DEPARTMENT?.VERIFY ? (
                            <span className="pending-status">{Admin_Verifications?.PendingVerification[language]}</span>
                          ) : (
                            <span className="approved-status">{Admin_Verifications?.Verified[language]}</span>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={6}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Department Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && (
        <div className="table-footer-wrap">
          <small>
          {Admin_Verifications?.Showing[language]} {pageData.from} {Admin_Verifications?.to[language]} {pageData.to} {Admin_Verifications?.of[language]} {pageData.total} {Admin_Verifications?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}