import { ErrorMessage, Formik } from 'formik';
import React, { useState,useContext } from 'react'
import { addVendorSchema } from './AddQAVendorSchema';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../../../../Library/ApiSettings';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, Row, Spinner } from 'reactstrap';
import { company_type_list, gst_class } from '../../../../../Library/Constants/MapConstants';
import TextError from '../../../../../Components/Errors/TextError';
import DatePicker from 'react-date-picker';
import constants from '../../../../../Library/Constants/Constants';
import { Admin_services } from '../../../../../Library/LanguageConversion/Admin_Services';
import { Context } from '../../../../../Components/Context/Context';
interface propType {
    getTableData: Function,
    setShowAddVendor: Function,
}
const AddQAVendorModal = ({ getTableData, setShowAddVendor }: propType) => {
    const [files, setFile] = useState<any>(null);

    const preventNegativeValues = (e: any) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[0-9]*$/; // regex pattern to match only numeric input
        if (!regex.test(keyValue)) {
            e.preventDefault();
            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
        }
    }
    const {language,setLanguage}=useContext(Context);
    const handleSubmit = (values: any, actions: any) => {
        const formData = new FormData();
        formData.append('vendor_type', constants?.VENDOR_TYPE.QAVENDOR?.toString())
        formData.append('phone', values?.phone)
        formData.append('email', values?.email)
        formData.append('pan_number', values?.pan_number)
        formData.append('gst_number', values?.gst_number)
        formData.append('gst_class', values?.gst_class)
        formData.append('mse_status', '0')
        formData.append('full_name', values?.full_name)
        formData.append('registration_id', values?.registration_id)
        formData.append('start_date', moment(values?.comp_inc_date).format("YYYY-MM-DD"))
        formData.append('end_date', moment(values?.comp_inc_date).format("YYYY-MM-DD"))
        formData.append('status', '1')
        formData.append('name', values?.company_name)
        formData.append('company_type', values?.company_type)
        formData.append('empanelment_date', moment(values?.empanelment_date).format("YYYY-MM-DD"))
        formData.append('expiry_date', moment(values?.expiry_date).format("YYYY-MM-DD"))

        axios({
            method: "post",
            url: `${API_URL}/qa_register`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            },
        })
            .then(function (response) {
                if (response?.status == 200) {
                    toast.success("Vendor Added Successfully");
                    setShowAddVendor(false);
                    actions.resetForm();
                    actions.setSubmitting(false);
                    getTableData(1);
                }
            })
            .catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error(err?.response?.data?.message, {
                        toastId: "509",
                    });
                    actions.setSubmitting(false);
                    if (err?.response?.data?.errors?.email)
                        actions?.setFieldError("email", err?.response?.data?.errors?.email);
                    if (err?.response?.data?.errors?.gst_number)
                        actions?.setFieldError(
                            "gst_number",
                            err?.response?.data?.errors?.gst_number
                        );
                    if (err?.response?.data?.errors?.pan_number)
                        actions?.setFieldError(
                            "pan_number",
                            err?.response?.data?.errors?.pan_number
                        );
                    if (err?.response?.data?.errors?.phone)
                        actions?.setFieldError("phone", err?.response?.data?.errors?.phone);
                    if (err?.response?.data?.errors?.registration_id)
                        actions?.setFieldError(
                            "registration_id",
                            err?.response?.data?.errors?.registration_id
                        );
                }
                if (err?.response?.status == 500) {
                    toast.error(err?.response?.data?.message, {
                        toastId: "509",
                    });
                    actions.setSubmitting(false);
                }
            });
    }

    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                    // vendor_type: "",
                    gst_class: "",
                    pan_number: "",
                    gst_number: "",
                    company_name: "",
                    comp_inc_date: "",
                    full_name: "",
                    phone: "",
                    registration_id: "",
                    company_type: "",
                    empanelment_date: "",
                    expiry_date: "",
                }}
                validationSchema={addVendorSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    errors,
                    touched,
                    resetForm,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                }) => {

                    return (
                        <>
                            <div className="add-vendor-outer">
                                <div className="vendor-details text-center">
                                    {/* <div className="circle-icon mb-2">
                <img
                  src="images/Addvendorimg.svg"
                  alt="img"
                  className="d-block mx-auto"
                />
              </div> */}
                                    {/* <h5 className="text-center">Vendor 1</h5> */}
                                    {/* <p className="mb-0">
                Lorem Ipsum is simply dummy text of the printing{" "}
              </p>
              <p className="mb-0">12/12/2022-22/01/2023</p> */}
                                </div>
                                <Form className="form-wrap" onSubmit={handleSubmit}>
                                    <Row>

                                        <Col sm={6}>
                                            <FormGroup className="form-group">
                                                <Label className="large-screen-view">
                                                {Admin_services?.gst_class_name[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="select"
                                                    label="GST Class"
                                                    name="gst_class"
                                                    className={`form-control ${touched.gst_class && errors.gst_class
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    value={values.gst_class}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">{Admin_services?.SelectGSTclass?.[language]}</option>
                                                    {gst_class?.map((item: any) => {
                                                        return (
                                                            <React.Fragment key={item?.value}>
                                                                <option value={item?.value}>{item?.name}</option>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Input>
                                                <ErrorMessage name="gst_class" component={TextError} />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6} sm={6}>
                                            <FormGroup className="form-group">
                                                <Label>
                                                {Admin_services?.PanNumber[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="pan_number"
                                                    maxLength={10}
                                                    placeholder=""
                                                    className={`form-control ${touched.pan_number && errors.pan_number
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    value={values.pan_number}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="pan_number" component={TextError} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={6}>
                                            <FormGroup className="form-group">
                                                <Label for="">
                                                {Admin_services?.GSTNumber[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    maxLength={15}
                                                    className={`form-control ${touched.gst_number && errors.gst_number
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    name="gst_number"
                                                    value={values.gst_number}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="gst_number" component={TextError} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={6}>
                                            <FormGroup className="form-group">
                                                <Label for="">
                                                {Admin_services?.CompanyName[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="company_name"
                                                    placeholder=""
                                                    className={`form-control ${touched.company_name &&
                                                        errors.company_name
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    value={values.company_name}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="company_name" component={TextError} />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6} sm={6}>
                                            <FormGroup className="form-group">
                                                <Label for="">
                                                {Admin_services?.CompanyEmail[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="email"
                                                    maxLength={35}
                                                    placeholder=""
                                                    className={`form-control ${touched.email && errors.email
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="email" component={TextError} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup className="form-group">
                                                <Label className="large-screen-view">
                                                {Admin_services?.CompanyType[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="select"
                                                    label="Company type"
                                                    name="company_type"
                                                    value={values.company_type}
                                                    className={`form-control ${touched.company_type && errors.company_type
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">{Admin_services?.SelectCompanyType[language]}</option>
                                                    {company_type_list?.map((item: any) => {
                                                        return (
                                                            <React.Fragment key={item?.value}>
                                                                <option value={item?.value}>{item?.name}</option>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Input>
                                                <ErrorMessage name="company_type" component={TextError} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup className="form-group date">
                                                <Label for="">
                                                {Admin_services?.CompanyIncorporationDate[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <DatePicker onChange={(e: any) => {
                                                    setFieldValue("comp_inc_date", e);
                                                }}
                                                    format={"dd-MM-y"}
                                                    dayPlaceholder={"dd"}
                                                    monthPlaceholder={"mm"}
                                                    yearPlaceholder={"yyyy"}
                                                    value={values?.comp_inc_date}
                                                    name="comp_inc_date"
                                                    calendarClassName={`form-control ${touched.comp_inc_date &&
                                                        errors.comp_inc_date
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    maxDate={new Date()} />
                                                <ErrorMessage name="comp_inc_date" component={TextError} />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup className="form-group date">
                                                <Label for="">
                                                {Admin_services?.EmpanelmentDate[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <DatePicker onChange={(e: any) => {
                                                    setFieldValue("empanelment_date", e); 
                                                }}
                                                    format={"dd-MM-y"}
                                                    dayPlaceholder={"dd"}
                                                    monthPlaceholder={"mm"}
                                                    yearPlaceholder={"yyyy"}
                                                    value={values?.empanelment_date}
                                                    name="empanelment_date"
                                                    calendarClassName={`form-control ${touched.empanelment_date &&
                                                        errors.empanelment_date
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    maxDate={new Date()} />
                                                <ErrorMessage name="empanelment_date" component={TextError} />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup className="form-group date">
                                                <Label for="">
                                                {Admin_services?.EmpanelmentExpiryDate[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <DatePicker onChange={(e: any) => {
                                                    setFieldValue("expiry_date", e);
                                                }}
                                                    format={"dd-MM-y"}
                                                    dayPlaceholder={"dd"}
                                                    monthPlaceholder={"mm"}
                                                    yearPlaceholder={"yyyy"}
                                                    value={values?.expiry_date}
                                                    name="empanelment_date"
                                                    calendarClassName={`form-control ${touched.expiry_date &&
                                                        errors.expiry_date
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                />
                                                <ErrorMessage name="expiry_date" component={TextError} />
                                            </FormGroup>
                                        </Col>

                                        <Col md={4} sm={6} lg={6}>
                                            <FormGroup className="form-group">
                                                <Label for="">
                                                {Admin_services?.CompanyRegistrationNumber[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        type="text"
                                                        name="registration_id"
                                                        placeholder=""
                                                        className={`form-control ${touched.registration_id &&
                                                            errors.registration_id
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        value={values.registration_id}
                                                        onChange={handleChange}
                                                    />
                                                </InputGroup>
                                                <div>
                                                    <ErrorMessage
                                                        name="registration_id"
                                                        component={TextError}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>

                                        <Col md={4} sm={6} lg={6}>
                                            <FormGroup className="form-group">
                                                <Label for="">
                                                {Admin_services?.ContactPersonName[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="full_name"
                                                    placeholder=""
                                                    className={`form-control ${touched.full_name && errors.full_name
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    value={values.full_name}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage name="full_name" component={TextError} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4} sm={6} lg={6}>
                                            <FormGroup className="form-group">
                                                <Label for="">
                                                {Admin_services?.ContactPersonNumber[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                        type="text"
                                                        name="phone"
                                                        onKeyPress={preventNegativeValues}
                                                        className={`form-control ${touched.phone && errors.phone
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        maxLength={11}
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                    />
                                                </InputGroup>
                                                <div>
                                                    <ErrorMessage name="phone" component={TextError} />
                                                </div>
                                            </FormGroup>
                                        </Col>

                                        {/* <Col md={12}>
                    <FormGroup
                      className={"d-flex align-items-center gap-2 mb-5"}
                    >
                      <Input type="checkbox" id="agree" />{" "}
                      <Label check htmlFor="agree">
                        Lorem Ipsum is simply dummy text of the printing
                      </Label>
                    </FormGroup> 
                  </Col> */}
                                        <div className="footer-sticky">
                                            <FormGroup className={"text-end"}>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className={"px-5"}
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? (
                                                        <Spinner
                                                            as="span"
                                                            variant="light"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            animation="border"
                                                        />
                                                    ) : (
                                                        Admin_services?.Add_Vendor[language]
                                                    )}
                                                </Button>
                                            </FormGroup>
                                        </div>
                                    </Row>
                                </Form>
                            </div>
                        </>
                    );
                }}
            </Formik>
        </>
    )
}

export default AddQAVendorModal