import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import axios from "axios";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { convertISODateToDateTime } from "../../../../Library/Constants/helpers";
import moment from "moment";
import constants from "../../../../Library/Constants/Constants";
import { Context } from "../../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../../Library/LanguageConversion/Vendor_profileUpdation";
export default function ViewDocumentModal(props: any) {
  const { language, setLanguage } = useContext(Context);
  return (
    <React.Fragment>
      <ModalContainer
        open={props?.show}
        setOpen={() => {
          props?.setShow(false);
        }}
        header="Document View"
      >
        <div className="feeback-outer-wrap">
          <div className="feedback-wrap">
            <div className="feedback-details ">
              <hr />

              {/* OEM */}
              {props?.data?.type?.id == constants.DOCUMENTS.OEM && (
                <>
                  <Col>
                    OEM in Company Type :{" "}
                    {props?.data?.meta?.oem_in_company_type}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.OEM_Valid_From?.[language]}: {" "}
                    {props?.data?.meta?.oem_valid_from}
                  </Col>
                  <hr />

                  <Col>
                  {Vendor_profileUpdation?.OEM_Valid_To?.[language]} : {" "}
                    {props?.data?.meta?.oem_valid_to}
                  </Col>
                  <hr />

                </>
              )}

              {/* Any other office */}
              {props?.data?.type?.id == constants.DOCUMENTS.HR_ANY_OFFICE && (
                <>
                  <Col>
                  {Vendor_profileUpdation?.Name?.[language]} :{" "}
                    {props?.data?.meta?.any_other_company_name}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.Email?.[language]} : {" "}
                    {props?.data?.meta?.any_other_company_email}
                  </Col>
                  <hr />

                  <Col>
                  {Vendor_profileUpdation?.City?.[language]} : {" "}
                    {props?.data?.meta?.any_other_company_city}
                  </Col>
                  <hr />

                  <Col>
                  {Vendor_profileUpdation?.District?.[language]} : {" "}
                    {props?.data?.meta?.any_other_company_district}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.State?.[language]} : {" "}
                    {props?.data?.meta?.any_other_company_state}
                  </Col>
                  <hr />
                  <Col>
                    {Vendor_profileUpdation?.Pincode?.[language]} : {" "}
                    {props?.data?.meta?.any_other_company_pincode}
                  </Col>
                  <hr />

                </>
              )}

              {props?.data?.type?.id == constants.DOCUMENTS.TURN_OVER1 && (
                <>
                  <Col>
                    Financial year :{" "}
                    {moment(props?.data?.meta?.f_financial_year).format(
                      "DD-MM-YYYY"
                    )}
                  </Col>
                  <hr />
                  <Col>
                    Financial year amount : ₹
                    {props?.data?.meta?.f_financial_year_amount}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Email?.[language]}: {props?.data?.meta?.financial_turn_over_ca_email}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Name?.[language]}: {props?.data?.meta?.financial_turn_over_ca_name}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Phone?.[language]} : {props?.data?.meta?.financial_turn_over_ca_phone}
                  </Col>
                </>
              )}
              {props?.data?.type?.id == constants.DOCUMENTS.TURN_OVER2 && (
                <>
                  <Col>
                    Financial year :{" "}
                    {moment(props?.data?.meta?.s_financial_year).format(
                      "DD-MM-YYYY"
                    )}
                  </Col>
                  <hr />
                  <Col>
                    Financial year amount : ₹
                    {props?.data?.meta?.s_financial_year_amount}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Email?.[language]}: {props?.data?.meta?.financial_turn_over_ca_email}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Name?.[language]} : {props?.data?.meta?.financial_turn_over_ca_name}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Phone?.[language]} : {props?.data?.meta?.financial_turn_over_ca_phone}
                  </Col>
                </>
              )}
              {props?.data?.type?.id == constants.DOCUMENTS.TURN_OVER3 && (
                <>
                  <Col>
                    Financial year :{" "}
                    {moment(props?.data?.meta?.t_financial_year).format(
                      "DD-MM-YYYY"
                    )}
                  </Col>
                  <hr />
                  <Col>
                    Financial year amount : ₹
                    {props?.data?.meta?.t_financial_year_amount}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Email?.[language]}: {props?.data?.meta?.financial_turn_over_ca_email}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Name?.[language]} : {props?.data?.meta?.financial_turn_over_ca_name}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.CA_Phone?.[language]} : {props?.data?.meta?.financial_turn_over_ca_phone}
                  </Col>
                </>
              )}

              {props?.data?.type?.id == constants.DOCUMENTS.EMPANEL && (
                <>
                  <Col>
                  {Vendor_profileUpdation?.Empanelment_Date?.[language]} :{" "}
                    {moment(props?.data?.meta?.f_financial_year).format(
                      "DD-MM-YYYY"
                    )}
                  </Col>
                  <hr />
                  <Col>
                  {Vendor_profileUpdation?.Empanelment_Expiry_Date?.[language]}:{" "}
                    {moment(props?.data?.meta?.f_financial_year).format(
                      "DD-MM-YYYY"
                    )}
                  </Col>
                  <hr />
                </>
              )}
              {props?.data?.type?.id == constants.DOCUMENTS.HR_MANPOWER && (
                <>
                  <Col>
                    Manpower Date :{" "}
                    {moment(props?.data?.meta?.manpower_date).format(
                      "DD-MM-YYYY"
                    )}
                  </Col>
                  <hr />

                  <Col>
                    Technical Manpower Number: {props?.data?.meta?.technical_manpower_number}
                  </Col>
                  <hr />

                  <Col>
                    Non-Technical Manpower Number: {props?.data?.meta?.nontechnical_manpower_number}
                  </Col>
                  <hr />
                  <Col>
                    Total Manpower Number: {props?.data?.meta?.manpower_number}
                  </Col>
                  <hr />
                </>
              )}
              {props?.data?.type?.id == constants.DOCUMENTS.HR_LAND && (
                <>
                  <Col>Land extent: {props?.data?.meta?.land_extent}</Col>
                  <hr />
                  <Col>Land survey no: {props?.data?.meta?.land_survey_no}</Col>
                  <hr />
                  <Col>Land value : {props?.data?.meta?.land_value}</Col>
                  <hr />
                  <Col>
                    Land village col : {props?.data?.meta?.land_village_col}
                  </Col>
                  <hr />
                </>
              )}

              {props?.data?.type?.id ==
                constants.DOCUMENTS.IT_TURN_OVER_ONE && (
                  <>
                    <Col>
                      First IT turn over Amount:{" "}
                      {props?.data?.meta?.f_it_turn_over_amount}
                    </Col>
                    <hr />
                    <Col>
                      First IT turn over Year:{" "}
                      {props?.data?.meta?.f_it_turn_over_year}
                    </Col>
                    <hr />
                    <Col>
                      First IT turn over CA Email:{" "}
                      {props?.data?.meta?.it_turn_over_ca_email}
                    </Col>
                    <hr />
                    <Col>
                      First IT turn over CA Name:{" "}
                      {props?.data?.meta?.it_turn_over_ca_name}
                    </Col>
                    <hr />
                    <Col>
                      {" "}
                      First IT turn over CA Phone:{" "}
                      {props?.data?.meta?.it_turn_over_ca_phone}
                    </Col>
                  </>
                )}
              {props?.data?.type?.id ==
                constants.DOCUMENTS.IT_TURN_OVER_TWO && (
                  <>
                    <Col>
                      Second IT turn over Amount:{" "}
                      {props?.data?.meta?.s_it_turn_over_amount}
                    </Col>
                    <hr />
                    <Col>
                      IT turn over Year: {props?.data?.meta?.s_it_turn_over_year}
                    </Col>
                    <hr />
                    <Col>
                      IT turn over CA Email:{" "}
                      {props?.data?.meta?.it_turn_over_ca_email}
                    </Col>
                    <hr />
                    <Col>
                      IT turn over CA Name:{" "}
                      {props?.data?.meta?.it_turn_over_ca_name}
                    </Col>
                    <hr />
                    <Col>
                      {" "}
                      IT turn over CA Phone:{" "}
                      {props?.data?.meta?.it_turn_over_ca_phone}
                    </Col>
                  </>
                )}
            </div>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
}
