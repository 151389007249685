import React, {useContext} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../Library/LanguageConversion/Department_Products";

export default function CartLayout(props: any) {
  const { pathname } = useLocation()
  const { language, setLanguage } = useContext(Context);

  return (
    <>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          <div className="custom-tabs">
            <NavLink to="/department/cart" >
            {DepartmentProducts?.Cart[language]}
            </NavLink>
            <NavLink to="/department/cart-custom-orders">
            {DepartmentProducts?.Custom_Order[language]}
            </NavLink>
          </div>

          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
              {DepartmentProducts?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {
                  pathname === "/department/cart" ? "Cart" :
                    pathname === "/department/cart/custom-orders" ? "Custom Orders" : "Cart"
                }
              </b>
            </li>
          </List>
        </div>

        {props.children}

      </div>
    </>
  );
}