import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRouteProps {
    loginStatus: boolean;
}

const QAVendorRoutes = (loginStatus: PrivateRouteProps) => {
    const [QAVendor, setQAVendor] = useState(true);
    useEffect(() => {
        if (localStorage.getItem("ROLE_ID") == "5") {
            setQAVendor(true);
        } else {
            setQAVendor(false);
        }
    }, [loginStatus]);
    const location = useLocation();

    return QAVendor ? (<Outlet />) :
        (
            <Navigate to="/login" replace state={{ from: location }} />
        );

    // this function is belongs to access vendor page only if vendor login else lead to login page
};

export default QAVendorRoutes;