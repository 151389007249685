export const Department_Services: any = {
    // Services/AMC/DeptAmc
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
    },
    AMC: {
        en: 'AMC',
        ka: 'ಏಎಂಸಿ'
    },
    Sl_No: {
        en: 'Sl No',
        ka: 'ಎಸ್ಎಲ್ ನಂ'
    },
    Contracts: {
        en: 'Contracts',
        ka: 'ಒಪ್ಪಂದಗಳು'
    },
    Invoice_ID: {
        en: 'Invoice ID',
        ka: 'ಸರಕುಪಟ್ಟಿ ID'
    },
    Valid_From: {
        en: 'Valid From',
        ka: 'ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ'
    },
    Valid_To: {
        en: 'Valid To',
        ka: 'ಗೆ ಮಾನ್ಯವಾಗಿದೆ'
    },
    Email_ID: {
        en: 'Email ID',
        ka: 'ಇಮೇಲ್ ಐಡಿ'
    },
    
    No_AMC_Found: {
        en: 'No AMC Found',
        ka: 'ಯಾವುದೇ ಏಎಂಸಿ ಕಂಡುಬರಲಿಲ್ಲ'
    },
    Showing: {
        en: 'Showing',
        ka: 'ತೋರಿಸಲಾಗುತ್ತಿದೆ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ಯಲ್ಲಿ'
    },
    entries: {
        en: 'entries',
        ka: 'ಪ್ರವೇಶಗಳು'
    },

    //Services/Tickets/Tabs/OpenTickets


    Product: {
        en: 'Product',
        ka: 'ಉತ್ಪನ್ನಗಳು'
    },
    Priority: {
        en: 'Priority',
        ka: 'ಪ್ರಾಥಮಿಕತೆ'
    },
    Issue_Type: {
        en: 'Issue Type',
        ka: 'ಸಮಸ್ಯೆಯ ಪ್ರಕಾರ'
    },
    Issued_Date: {
        en: 'Issued Date',
        ka: 'ನೀಡಿದ ದಿನಾಂಕ'
    },
    Status: {
        en: 'Status',
        ka: 'ಸ್ಥಿತಿ'
    },
    No_Tickets_Found: {
        en: 'No Tickets Found',
        ka: 'ಯಾವುದೇ ಟಿಕೆಟ್‌ಗಳು ಕಂಡುಬಂದಿಲ್ಲ'
    },

    //Services/Tabs/ResolvedTickets 

    time: {
        en: 'time',
        ka: 'ಸಮಯ'
    },
    // Services/DeptTicketLayout

    Open_Tickets: {
        en: 'Open Tickets',
        ka: 'ತೆರೆದ ಟಿಕೆಟ್‌ಗಳು'
    },
    Pending_Tickets: {
        en: 'Pending Tickets',
        ka: 'ಬಾಕಿಯಿರುವ ಟಿಕೆಟ್‌ಗಳು'
    },
    Resolved_Tickets: {
        en: 'Resolved Tickets',
        ka: 'ಪರಿಹಾರಗೊಂಡ ಟಿಕೆಟ್‌ಗಳು'
    },
    Services: {
        en: 'Services',
        ka: 'ಸೇವೆಗಳು'
    },
    Full_Name: {
        en: 'Full Name',
        ka: 'ಪೂರ್ಣ ಹೆಸರು'
    },
    Phone_Number: {
        en:'Phone Number',
        ka:'ಫೋನ್ ಸಂಖ್ಯೆ'
    },
    Password: {
        en: '',
        ka:'ಪಾಸ್ವರ್ಡ್'
    },
    Email: {
        en:'Email',
        ka:'ಇಮೇಲ್'
    },
    Update:{
        en:'Update',
        ka:'ನವೀಕರಿಸು'
    },
    Enter_Password:{
        en:'Enter Password',
        ka:'ಗುಪ್ತಪದ ನಮೂದಿಸಿ'
    },
    Confirm_Password:{
        en:'Confirm Password',
        ka:'ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ'
    },
    Enter_OTP:{
        en:'Enter OTP',
        ka:'ಒ.ಟಿ.ಪಿ ನಮೂದಿಸಿ'
    },
    Verified:{
        en:'Verified',
        ka:'ಪರಿಶೀಲಿಸಲಾಗಿದೆ'
    },
    Verify_link:{
        en:'Verify link',
        ka:'ಲಿಂಕ್ ಪರಿಶೀಲಿಸಿ'
    }
}
