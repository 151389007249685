import { ErrorMessage } from "formik";
import React, { useState, useContext } from "react";
import {
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    Form
} from "reactstrap";
import TextError from "../../../Components/Errors/TextError";
import { PaymentStatus } from "../../../Library/Constants/helpers";
import constants from "../../../Library/Constants/Constants";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { Admin_Payment } from "../../../Library/LanguageConversion/Admin_Payment";
import { Context } from "../../../Components/Context/Context";


const PaymentCheckModal = (props: any) => {
    const {language,setLanguage} = useContext(Context)
    const total_amount = props?.payment_data?.grand_amount
    const dispay_paid = props?.payment_data?.last_paid_amount ? props?.payment_data?.last_paid_amount : props?.payment_data?.payment_details?.amount
    const remian_amount = props?.payment_data?.payment_details?.balance_amt ? props?.payment_data?.payment_details?.balance_amt : 0
    let payment_mode = props?.payment_data?.payment_details?.payment_mode ? props?.payment_data?.payment_details?.payment_mode : props?.payment_data?.payment_mode
    const [err, setErr] = useState("")
    const [v, setV] = useState(0)
    const check_payment_ = () => {
        let v = total_amount - dispay_paid
        if (remian_amount == v) {
            setErr("it's correct amount is paid")
            props?.setCheck(true)
        }
        else {
            setErr("Not correct amount is paid")
            props?.setCheck(true)
        }
        setV(v)
        props?.setFieldValue("reamin_amount", v)
    }

    return (
        <>
            <ModalContainer
                open={props?.isShowing}
                setOpen={() => {
                    props.setIsShowing(false);
                    props.resetForm();
                    setErr("")
                    setV(0)
                    props?.setCheck(false)
                }}
                header='Payment Request1'
            >
                <div className="add-vendor-outer">
                    <div className="feedback-wrap">
                        <div className="feedback-details ">
                            <table className="table table-hover table-bordered ">
                                <tbody>
                                    <tr>
                                        <td>
                                            {" "}
                                            <b>{Admin_Payment?.Payment_Mode?.[language]}</b>{" "}
                                        </td>
                                        <td>
                                            {PaymentStatus(props?.payment_data?.payment_details?.payment_mode ?
                                                props?.payment_data?.payment_details?.payment_mode :
                                                props?.payment_data?.payment_mode
                                            )}</td>

                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <b>{Admin_Payment?.Department?.[language]}</b>{" "}
                                        </td>
                                        <td> {props?.payment_data?.user?.full_name},
                                            ({props?.payment_data?.user?.phone})</td>

                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <b>{Admin_Payment?.TotalAmount?.[language]}</b>{" "}
                                        </td>
                                        <td> ₹{total_amount}/-</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <b>{Admin_Payment?.ChequeDBTNBChellan?.[language]}</b>{" "}
                                        </td>
                                        <td> <a href="https://www.shutterstock.com/image-vector/blank-cheque-template-260nw-231773500.jpg"
                                            target="_blank" className="primary-text">{Admin_Payment?.View?.[language]}</a></td>

                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <b>{Admin_Payment?.TotalPaidAmount?.[language]}</b>{" "}
                                        </td>
                                        <td> ₹{dispay_paid || 0}/-</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <b>{Admin_Payment?.RemainingAmountToPay?.[language]}</b>{" "}
                                        </td>
                                        <td>
                                            {/* ₹{remian_amount || 0}/- */}
                                            {/* {
                                                    (props?.payment_data?.payment_details?.payment_mode ==
                                                        constants.DEPT_PAYMENT_MODES.PAY_LATER) ||
                                                        (props?.payment_data?.payment_mode ==
                                                            constants.DEPT_PAYMENT_MODES.PAY_LATER)
                                                        ?
                                                        "₹" + { total_amount } + "/-" : null

                                                } */}
                                            {
                                                payment_mode ==
                                                    constants.DEPT_PAYMENT_MODES.PAY_LATER ?
                                                    "₹" + total_amount + "/-" : null
                                            }
                                            {
                                                payment_mode ==
                                                    constants.DEPT_PAYMENT_MODES.PARTIAL_PAYMENT ?
                                                    "₹" + remian_amount || 0 + "/-" : null
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="amount-button">
                        {
                            props?.check == false && (<>   <Button
                                color="primary"
                                onClick={() => check_payment_()}>
                                {" "}
                                <b>{Admin_Payment?.CheckAmountisCorrect?.[language]}</b>{" "}
                            </Button></>)
                        }
                    </div>
                    {
                        props?.check == true && (<> <Form className="form-wrap">
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup>
                                        <Label for="">
                                        {Admin_Payment?.Comments?.[language]}
                                            <i
                                                className="fa fa-asterisk required-field"
                                                color="text-danger"
                                                aria-hidden="true"
                                            ></i>
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="comments"
                                            className={`form-control ${props?.touched.comments && props?.errors.comments
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            value={props.values.comments}
                                            onChange={props.handleChange}
                                        />
                                        <ErrorMessage name="comments" component={TextError} />
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup>
                                        <Label for="">
                                        {Admin_Payment?.RemainingAmount?.[language]}
                                            <i
                                                className="fa fa-asterisk required-field"
                                                color="text-danger"
                                                aria-hidden="true"
                                            ></i>
                                        </Label>
                                        <Input
                                            type="number"
                                            name="reamin_amount"
                                            disabled
                                            placeholder=""
                                            className={`form-control ${props?.touched.reamin_amount && props?.errors.reamin_amount
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            value={payment_mode ==
                                                constants.DEPT_PAYMENT_MODES.PAY_LATER ? total_amount : payment_mode ==
                                                    constants.DEPT_PAYMENT_MODES.PARTIAL_PAYMENT ? remian_amount : 0}
                                            onChange={props.handleChange}
                                        />
                                        <ErrorMessage name="reamin_amount" component={TextError} />
                                    </FormGroup>
                                </Col>
                                <div className="footer-sticky mt-5">
                                    <FormGroup className={"text-end"}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className={"px-5"}
                                            onClick={props.handleSubmit}
                                        >
                                            {Admin_Payment?.RequesttoRemainingpayment?.[language]}
                                        </Button>
                                    </FormGroup>
                                </div>
                            </Row>
                        </Form></>)
                    }
                </div>
            </ModalContainer>
        </>
    );
};
export default PaymentCheckModal;