import React, { useState, useContext } from 'react'
import { Col, Collapse, Row } from 'reactstrap'
import Loader from '../../../../Components/Loader/Loader'
import { useLocation } from 'react-router-dom'
import { convertISODateToDateTime, sumByKey } from '../../../../Library/Constants/helpers'
import { Department_Orders } from '../../../../Library/LanguageConversion/Department_Orders'
import { Context } from '../../../../Components/Context/Context';
interface propType {
  singleOrderData: any,
  orderViewLoading: boolean,
  address?: any,
  orderData?: any,
  hideTracker?: boolean,
}
const OrderViewModal = ({ singleOrderData, orderViewLoading, address, orderData, hideTracker = false }: propType) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const location = useLocation()
  const { language } = useContext(Context)

  return (
    <>
      {orderViewLoading ?
        <div className='text-center pt-5'>
          <Loader />
        </div>
        :
        <>

          <div className='feeback-outer-wrap'>
            {singleOrderData?.map((obj: any, i: number) => {
              return (
                <React.Fragment key={i}>
                  {/* timeline of order  */}
                  {(i < 1 && !hideTracker) &&
                    <div className="progress-tracker">
                      <div className="progress-tracker-wrap">
                        <Row className="align-items-center">
                          <h5 className="sub-heading">{Department_Orders?.OrderTrackingStatus[language]}</h5>
                          <Col md={12}>
                            <div className="tracker-status-details-wrap">
                              <ul className='main-list'>
                                {location?.pathname == "/department/orders" ?

                                  <>
                                    {/* Delivered orders  */}
                                    <li>
                                      <div className="flex-outer">
                                        <div className="flex-wrap">
                                          <div className="circle Placed"></div>
                                          <p className="track-status-text">{Department_Orders?.OrderPlaced[language]}</p>
                                        </div>
                                        <div className="date">
                                          <p className="track-status-text">{Department_Orders?.Completed[language]}</p>
                                          {obj?.order_details?.created_at && <p>{convertISODateToDateTime(obj?.order_details?.created_at)}</p>}
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="flex-outer">
                                        <div className="flex-wrap">
                                          <div className={!obj?.updated_at ? 'emptycircle Processed' : 'circle Processed'}></div>
                                          <p className="track-status-text">{Department_Orders?.Processed[language]}</p>
                                        </div>
                                        <div className="date">
                                          <p className="track-status-text">{Department_Orders?.Completed[language]}</p>
                                          {obj?.updated_at && <p>{convertISODateToDateTime(obj?.updated_at) || 'Pending'}</p>}
                                        </div>

                                      </div>
                                    </li>
                                    <li>
                                      <div className="flex-outer">
                                        <div className="flex-wrap">
                                          <div className={!obj?.delivery_date ? 'emptycircle Delivered' : 'circle Delivered'}></div>
                                          <p className="track-status-text">{Department_Orders?.Delivered[language]}</p>
                                        </div>
                                        <div className="date">
                                          <p className="track-status-text">{Department_Orders?.Completed[language]}</p>
                                          {obj?.delivery_date && <p className="track-status-text">{convertISODateToDateTime(obj?.delivery_date) || 'Pending'}</p>}
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                  :
                                  // placed orders 
                                  location?.pathname == "/department/placed-orders" ?
                                    <>
                                      <li>
                                        <div className="flex-outer">
                                          <div className="flex-wrap">
                                            <div className="circle Placed"></div>
                                            <p className="track-status-text">{Department_Orders?.OrderPlaced[language]}</p>
                                          </div>
                                          <div className="date">
                                            <p className="track-status-text">{Department_Orders?.Completed[language]}</p>
                                            {obj?.created_at && <p>{convertISODateToDateTime(obj?.created_at)}</p>}
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="flex-outer">
                                          <div className="flex-wrap">
                                            <div className="emptycircle Processed"></div>
                                            <p className="track-status-text">{Department_Orders?.Processing[language]}</p>
                                          </div>
                                          <div className="date">
                                            <p className="track-status-text">{Department_Orders?.Pending[language]}</p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="flex-outer">
                                          <div className="flex-wrap">
                                            <div className={!obj?.delivery_date ? 'emptycircle Delivered' : 'circle Delivered'}></div>
                                            <p className="track-status-text">{Department_Orders?.Delivered[language]}</p>
                                          </div>
                                          <div className="date">
                                            <p className="track-status-text">{obj?.delivery_date || 'Pending'}</p>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                    :
                                    // processing orders 
                                    location?.pathname == "/department/processing-orders" ?
                                      <>
                                        <li>
                                          <div className="flex-outer">
                                            <div className="flex-wrap">
                                              <div className="circle Placed"></div>
                                              <p className="track-status-text">{Department_Orders?.OrderPlaced[language]}</p>
                                            </div>
                                            <div className="date">
                                              <p className="track-status-text">{Department_Orders?.Completed[language]}</p>
                                              {obj?.created_at && <p>{convertISODateToDateTime(obj?.created_at)}</p>}
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="flex-outer">
                                            <div className="flex-wrap">
                                              <div className="circle Processed"></div>
                                              <p className="track-status-text">{Department_Orders?.Processing[language]}</p>
                                            </div>
                                            <div className="date">
                                              <p className="track-status-text">In Progress</p>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="flex-outer">
                                            <div className="flex-wrap">
                                              <div className='emptycircle Delivered'></div>
                                              <p className="track-status-text">{Department_Orders?.Delivered[language]}</p>
                                            </div>
                                            <div className="date">
                                              <p className="track-status-text">{'Pending'}</p>
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                      :

                                      // canceled orders 
                                      location?.pathname == "/department/cancelled-orders" &&
                                      <>
                                        <li>
                                          <div className="flex-outer">
                                            <div className="flex-wrap">
                                              <div className="circle Placed"></div>
                                              <p className="track-status-text">{Department_Orders?.OrderPlaced[language]}</p>
                                            </div>
                                            <div className="date">
                                              <p className="track-status-text">{Department_Orders?.Completed[language]}</p>
                                              {obj?.created_at && <p className="track-status-text">{convertISODateToDateTime(obj?.created_at)}</p>}
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="flex-outer">
                                            <div className="flex-wrap">
                                              <div className="circle Cancelled"></div>
                                              <p className="track-status-text">{Department_Orders?.OrderCancelled[language]}</p>
                                            </div>
                                            <div className="date">
                                              <p className="track-status-text"></p>
                                              {obj?.updated_at && <p className="track-status-text">{convertISODateToDateTime(obj?.updated_at)}</p>}
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="flex-outer">
                                            <div className="flex-wrap">
                                              <div className="emptycircle Processed"></div>
                                              <p className="track-status-text ">{Department_Orders?.Processed[language]}</p>
                                            </div>
                                            <div className="date">
                                              <p className="track-status-text"><i className="bi bi-x-circle"></i></p>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="flex-outer">
                                            <div className="flex-wrap">
                                              <div className={!obj?.delivery_date ? 'emptycircle Delivered' : 'circle Delivered'}></div>
                                              <p className="track-status-text">{Department_Orders?.Delivered[language]}</p>
                                            </div>
                                            <div className="date">
                                              <p className="track-status-text"><i className="bi bi-x-circle"></i></p>
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                }
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>}
                </React.Fragment>
              )
            })}
            {(orderData?.grand_gst && orderData?.grand_amount) && <div className='price-flex-between'>
              <p>{Department_Orders?.TotalGST[language]}: <span><i className="bi bi-currency-rupee"></i> {orderData?.grand_gst}</span></p>
              <p>{Department_Orders?.PayableAmount?.[language]}: <span><i className="bi bi-currency-rupee"></i> {(orderData?.grand_amount - orderData?.tds_on_gst).toFixed(2)}</span></p>
            </div>}

            {singleOrderData?.map((obj: any, i: number) => {

              return (
                <React.Fragment key={i}>
                  <div className="">
                    <div className="feedback-wrap order-table-wrap">
                      <h5 className="sub-heading">Product {Department_Orders?.PayableAmount?.[language]} {i + 1}</h5>
                      <div className="feedback-details ">
                        <table className="table table-hover table-bordered ">
                          <tbody>
                            {obj?.vendor_po_no && <tr>
                              <td>
                                <b>{Department_Orders?.Order_ID[language]}</b>{" "}
                              </td>
                              <td> {obj?.vendor_po_no} </td>
                            </tr>}
                            <tr>
                              <td>
                                <b>{Department_Orders?.ProductName[language]}</b>{" "}
                              </td>
                              <td>
                                <strong>{
                                  obj?.order_details?.product_details
                                    ?.name || obj?.name
                                }</strong>

                              </td>
                            </tr>
                            <tr onClick={() => setIsOpen(!isOpen)} className="collapse-btn">
                              <td>
                                <b>{Department_Orders?.ProductCode[language]}</b>{" "}
                              </td>
                              <td>
                                {
                                  obj?.order_details?.product_details
                                    ?.product_code || obj?.product_code
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>{Department_Orders?.Quantity[language]}</b>
                              </td>
                              <td>
                                {obj?.order_details?.quantity || obj?.quantity}
                              </td>
                            </tr>
                            <Collapse isOpen={isOpen} tag={"tr"}>
                              <td colSpan={2}>
                                <table className="table  table-bordered ">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b>{Department_Orders?.HSNCode[language]}</b>{" "}
                                      </td>
                                      <td>
                                        {
                                          obj?.order_details
                                            ?.product_details?.hsn_code || obj?.hsn_code
                                        }
                                      </td>
                                    </tr>
                                    {/* Specs in Desktop */}
                                    <tr className="large-screen-view">
                                      <td>
                                        <b>{Department_Orders?.ProductSpecifications[language]}</b>
                                      </td>
                                      <td>
                                        <p className="specs">
                                          {obj?.order_details
                                            ?.product_details?.features || obj?.features ? (
                                            <small className='w-100'>
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    obj?.order_details
                                                      ?.product_details?.features || obj?.features
                                                  ,
                                                }}
                                              />
                                            </small>
                                          ) : null}
                                        </p>
                                      </td>
                                    </tr >
                                    {/* Specs in mobile */}
                                    <tr className="mobile-view">
                                      <td colSpan={2} className='text-center'>
                                        <b>{Department_Orders?.ProductSpecifications[language]}</b>
                                      </td>
                                    </tr>
                                    <tr className="mobile-view">
                                      <td colSpan={2}>
                                        <p className="specs centered">
                                          {obj?.order_details
                                            ?.product_details?.features || obj?.features ? (
                                            <small>
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    obj?.order_details
                                                      ?.product_details?.features || obj?.features
                                                  ,
                                                }}
                                              />
                                            </small>
                                          ) : null}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>{Department_Orders?.Shipment_Address[language]}</b>
                                      </td>
                                      <td>
                                        {Department_Orders?.Building_no[language]}:
                                        {
                                          obj?.pf_invoice?.pf_address?.[0]
                                            ?.building_no || address?.building_no
                                        }
                                        ,{'  '}
                                        {
                                          obj?.pf_invoice?.pf_address?.[0]
                                            ?.city || address?.city
                                        }
                                        ,{'  '}
                                        {
                                          obj?.pf_invoice?.pf_address?.[0]
                                            ?.district || address?.district
                                        }
                                        ,{'  '}
                                        {
                                          obj?.pf_invoice?.pf_address?.[0]
                                            ?.state || address?.state
                                        }
                                        ,{'  '}
                                        {
                                          obj?.pf_invoice?.pf_address?.[0]
                                            ?.pincode || address?.pincode
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </Collapse>
                            {obj?.cmpny_detail?.name && <tr>
                              <td><b>{Department_Orders?.Vendor[language]}</b></td>
                              <td>{obj?.cmpny_detail?.name}</td>
                            </tr>}
                            <tr>
                              <td>
                                <b>{Department_Orders?.UnitPrice[language]}</b>
                              </td>
                              <td>
                                ₹
                                {
                                  obj?.order_details
                                    ?.product_details?.product_rate?.[0]?.price || obj?.unit_price
                                }
                              </td>
                            </tr>

                            {obj?.shipping_address?.[0]?.this.state == "Karnataka" ?

                              <>
                                <tr>
                                  <td>
                                    <b>{Department_Orders?.SGST[language]}</b>
                                  </td>
                                  <td>₹ {obj?.sgst}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>{Department_Orders?.CGST[language]}</b>
                                  </td>
                                  <td>₹ {obj?.cgst}</td>
                                </tr>
                              </>
                              :
                              <>
                                <tr>
                                  <td>
                                    <b>{Department_Orders?.IGST?.[language]}</b>
                                  </td>
                                  <td>₹ {obj?.igst}</td>
                                </tr>
                              </>

                            }


                            <tr>
                              <td>
                                <b>{Department_Orders?.TotalGST[language]}</b>
                              </td>
                              <td>₹ {obj?.total_gst}</td>
                            </tr>
                            {/* <tr>
                              <td>
                                <b>{Department_Orders?.TDS_Amount[language]}</b>
                              </td>
                              <td>₹ {obj?.tds_on_gst}</td>
                            </tr> */}
                            {/* TDS Deductions  */}
                            {orderData?.dep_deduction?.map((it: any, i: number) => {
                              return (
                                <tr >
                                  <td><b>{it?.item}:</b></td>
                                  <td>
                                    <i className="bi bi-currency-rupee"></i>
                                    {it?.amount}
                                  </td>
                                </tr>
                              )
                            })}
                            {orderData?.sum_dep_deduction && <tr>
                              <td><b>Total Deductions</b></td>
                              <td>
                                <i className="bi bi-currency-rupee"></i>
                                {orderData?.sum_dep_deduction}
                              </td>
                            </tr>}
                            <tr>
                              <td>
                                <b>{Department_Orders?.TotalAmount[language]}</b>
                              </td>
                              <td>₹ {obj?.total_amount.toFixed(2)}</td>
                            </tr>
                            {orderData?.sum_dep_deduction && <tr>
                              <td>
                                <b>{Department_Orders?.PayableAmount?.[language]}</b>
                              </td>
                              <td>₹ {(obj?.total_amount - orderData?.sum_dep_deduction).toFixed(2)}</td>
                            </tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div>

                    {/* list delivery challan  */}
                    {obj?.get_delivered_docs?.map((itm: any, i: number) => {

                      return (
                        <React.Fragment key={i}>
                          {itm?.type == 1 &&
                            <div className="input-wrap doc-wrap">
                              <div className="delivery-chellan">
                                <h6>{Department_Orders?.Delivery_Chellan[language]}</h6>
                                {/* <Input
                                  disabled
                                  type="text"
                                  className="form-control"
                                  value="Delivery-challan"
                                /> */}
                                <div className="icon">
                                  {/* <i className="bi bi-check-circle-fill"></i> */}
                                  <a
                                    href={itm?.docs}
                                    target="_blank"
                                    className="rounded-tag" rel="noreferrer"
                                  >
                                    <i title="View" className="icons bi bi-eye lg"></i>
                                  </a>
                                </div>
                              </div>
                            </div>}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </React.Fragment >
              )
            })}
          </div>
        </>
      }
      {/* <ModalFooter>
        <div className="order-view-bottom">
          <h4 className="text-end text-dark w-100">
            Grand Total : <strong>₹ {singleOrderData?.total_amount}</strong>
          </h4>
        </div>
      </ModalFooter> */}
    </>
  )
}

export default OrderViewModal