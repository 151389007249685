import React from "react";
import {useContext} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../Components/Context/Context";
import { Admin_orders } from "../../../Library/LanguageConversion/Admin_orders";

export default function OrderLayout(props: any) {
  const { pathname } = useLocation();
  const { language, setLanguage } = useContext(Context);
  return ( 
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap md-tabs">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/received-orders">{Admin_orders?.Latest[language]}</NavLink>
            <NavLink to="/approved-orders">{Admin_orders?.Approved[language]}</NavLink>
            <NavLink to="/rejected-orders">{Admin_orders?.Rejected[language]}</NavLink>
            <NavLink to="/confirmed-orders">{Admin_orders?.Confirmed[language]}</NavLink>
            <NavLink to="/processed-orders">{Admin_orders?.Processed[language]}</NavLink>
            {/* <NavLink to="/pending-orders">Pending Orders</NavLink> */}
            <NavLink to="/cancelled-orders">{Admin_orders?.Cancelled[language]}</NavLink>
            <NavLink to="/drafted-orders">{Admin_orders?.Drafted[language]}</NavLink>
          </div>
          {/* breadcrumb */}
          <List className=" breadcrumb"> 
            <li>
              <Link className="active" to="/dashboard">
              {Admin_orders?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {pathname == "/received-orders"
                  ? Admin_orders?.Latest_order?.[language]
                  : pathname == "/confirmed-orders"
                  ? Admin_orders?.Confirmed_orders?.[language]
                  : pathname == "/approved-orders"
                  ? Admin_orders?.Approved_orders?.[language]
                  : pathname == "/processed-orders"
                  ? Admin_orders?.Processed_Orders?.[language]
                  : pathname == "/cancelled-orders"
                  ? Admin_orders?.Cancelled_Orders?.[language]
                  : pathname == "/rejected-orders"
                  ? Admin_orders?.Rejected_Orders?.[language]
                  : pathname == "/drafted-orders"
                  ? Admin_orders?.Drafted_Orders?.[language]
                  : null}
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
