import * as Yup from "yup";

const Transaction_id = /^[A-Fa-f0-9]{64}$/;
const chellanValidation = Yup.object().shape({
    file: Yup.string().required("File is required"),
    chellan_number:Yup.number().required("Field is required").nullable(),
    bank_name: Yup.string().required("Field is required").nullable(),
    account_holder_name: Yup.string().required("Field is required").nullable(),
    branch_name: Yup.string().required("Field is required").nullable(),
    ifsc_code: Yup.string().required("Field is required").nullable(),
    account_number: Yup.number().required("Field is required").nullable(),

    // receipt_number: Yup.string().required("Field is required").nullable(),
    // amount_data: Yup.number().required("Field is required").nullable(),
    // bsr_code:Yup.string().required("Field is required").nullable(),
    // payment_date:Yup.string().required("Field is required").nullable(),
    // payment_site:Yup.string().required("Field is required").nullable(),

});
const AccountValidation = Yup.object().shape({
    bank_name: Yup.string().required("Field is required").nullable(),
    account_holder_name: Yup.string().required("Field is required").nullable(),
    branch_name: Yup.string().required("Field is required").nullable(),
    // account_name: Yup.string().required("Field is required").nullable(),
    ifsc_code: Yup.string().required("Field is required").nullable(),
    // account_number: Yup.string().required("Field is required").matches(Transaction_id, "Invalid Transaction Id").nullable(),
    // amount_data: Yup.number().required("Field is required").nullable(),
    account_number: Yup.number().required("Field is required").nullable(),
    file: Yup.string().required("File is required"),
    transaction_number:Yup.string().required("Field is required").nullable()
});
const ChequeValidation = Yup.object().shape({
    file: Yup.string()

        .required("File is required"),
    bank_name: Yup.string().required("Field is required"),
    account_holder_name: Yup.string().required("Field is required"),
    branch_name: Yup.string().required("Field is required"),
    // account_name: Yup.string().required("Field is required"),
    ifsc_code: Yup.string().required("Field is required"),
    account_number: Yup.number().required("Field is required"),
    cheque_number: Yup.number().required("Field is required").nullable(),
    // amount_data: Yup.number().required("Field is required").nullable(),
});

const SecurechellanValidation = Yup.object().shape({
    file: Yup.string()
        .required("File is required"),
    receipt_number: Yup.string().required("Field is required").nullable(),
    // amount_data: Yup.number().required("Field is required").nullable(),
    chellan_number:Yup.number().required("Field is required").nullable(),
   // cheque_number:Yup.number().required("Field is required").nullable(),
    bsr_code:Yup.string().required("Field is required").nullable(),
    payment_date:Yup.string().required("Field is required").nullable(),
    payment_site:Yup.string().required("Field is required").nullable(),
});
const SecureAccountValidation = Yup.object().shape({

    bank_name: Yup.string().required("Field is required").nullable(),
    account_holder_name: Yup.string().required("Field is required").nullable(),
    branch_name: Yup.string().required("Field is required").nullable(),
    // account_name: Yup.string().required("Field is required").nullable(),
    ifsc_code: Yup.string().required("Field is required").nullable(),
    // account_number: Yup.string().required("Field is required").matches(Transaction_id, "Invalid Transaction Id").nullable(),
    // amount_data: Yup.number().required("Field is required").nullable(),
    account_number: Yup.string().required("Field is required").nullable(),
    file: Yup.string().required("File is required"),

});
const SecureChequeValidation = Yup.object().shape({

    file: Yup.string()

        .required("File is required"),
    bank_name: Yup.string().required("Field is required"),
    account_holder_name: Yup.string().required("Field is required"),
    branch_name: Yup.string().required("Field is required"),
    // account_name: Yup.string().required("Field is required"),
    ifsc_code: Yup.string().required("Field is required"),
    account_number: Yup.number().required("Field is required"),
    cheque_number: Yup.number().required("Field is required").nullable(),
    // amount_data: Yup.number().required("Field is required").nullable(),

});

export {
    chellanValidation,
    AccountValidation,
    ChequeValidation,
    SecurechellanValidation,
    SecureAccountValidation,
    SecureChequeValidation

};