import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Collapse,
  Table,
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import Toast from "reactstrap";
import { toast } from "react-toastify";

import TextError from "../../../../Components/Errors/TextError";
import { ErrorMessage } from "formik";
import { API_URL } from "../../../../Library/ApiSettings";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Formik } from "formik";
import EXIF from "exif-js";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { EmdCalculation, PGCalculation } from "./../EmdCalculation";
import { Value } from "sass";
import axios from "axios";
import TrackOrder from "../TrackOders";

const ReceivedOrderModal = (props: any) => {
  const [isOpen, setIsOpen] = useState<any>(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const order_data = props?.order_view?.[0];
  // console.log(order_data?.mou_status, "order_data?.mou_status ");
  const [show_upload, setUpload] = useState(false);
  const [mou_image, setImage] = useState();
  const [show_data, setShowData] = useState(false);
  const MouChange = (e: any) => {
    setImage(e);
  };
  const [error, setErr] = useState({
    mou_err: "",
    file_type: "",
  });
  var amo =
    order_data?.order_details?.cgst * order_data?.order_details?.quantity +
    order_data?.order_details?.sgst * order_data?.order_details?.quantity +
    order_data?.order_details?.amount * order_data?.order_details?.quantity;
  const toggle = () => setIsOpen(!isOpen);

  function truncateText(text: any) {
    return text?.replace(/<br>/g, ", ");
  }

  const downloadmou = () => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    };
    fetch(
      `${API_URL}/order/mou-document-download?orderId=${props?.order_id}`,
      //   `${API_URL}/order/mou-document-download`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Memorandum Of Understanding.doc";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setUpload(true);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  const uploadmou = () => {
    if (!mou_image) {
      setErr({
        ...error,
        mou_err: "Field is required",
      });
    } else {
      //  if()
      const formData = new FormData();
      formData.append("file", mou_image);
      formData.append("orderId", props?.order_view[0]?.id);
      formData.append("mou_status", "0");

      const body = formData;
      axios({
        method: "post",
        url: `${API_URL}/order/mou-document-upload`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        },
      })
        .then(function (response) {
          if (response?.status == 200) {
            toast.success("MOU Agreement are uploaded successfuly");
            props?.getSingleOrder(props?.order_id);
            // setPayButton(true);
            // setShowData(true);
          }
        })
        .catch(function (response) {
          toast.error("Something Went Wrong, Please Check Datas");
          console.log(response);
        });
    }
  };
  const downloadinvoice = () => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      // body: JSON.stringify({
      //     orderId: props?.order_view[0]?.vendor_po_no
      //})
    };
    fetch(
      `${API_URL}/order/invoice-download?orderId=${props?.order_view[0]?.vendor_po_no}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Work Order.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        props?.setIsShowing(false);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
          setUpload(false);
        }}
        header="Order View"
      >
        <div className="feeback-outer-wrap">
          <div className="feedback-main-heading">
            <TrackOrder order_view={order_data} />

            <h2>Order Details</h2>
          </div>
          <div className="feedback-wrap">
            <div className="feedback-details">
              <Form className="form-wrap">
                {props?.order_view?.[0] !== 0 ? (
                  <>
                    <React.Fragment>
                      <Table className="table-hover table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <b>Order ID</b>{" "}
                            </td>
                            <td> {order_data?.vendor_po_no} </td>
                          </tr>

                          <tr> 
                            <td>
                              {" "}
                              <b>Product Name</b>{" "}
                            </td>
                            <td>
                              {order_data?.order_details?.product_details?.name}
                            </td>
                          </tr>
                          <tr onClick={() => toggle()} className="collapse-btn">
                            <td>
                              <b>Product Code</b>{" "}
                            </td>
                            <td>
                              <span className="code">
                                {
                                  order_data?.order_details?.product_details
                                    ?.product_code
                                }{" "}
                              </span>{" "}
                            </td>
                          </tr>
                          <Collapse isOpen={isOpen} tag={"tr"}>
                            <td colSpan={2}>
                              <Table className=" table-bordered ">
                                <tbody>
                                  <tr>
                                    <td>
                                      {" "}
                                      <b>HSN Code</b>{" "}
                                    </td>
                                    <td>
                                      {
                                        order_data?.order_details
                                          ?.product_details?.hsn_code
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <b>Quantity</b>
                                    </td>
                                    <td>
                                      {" "}
                                      {order_data?.order_details?.quantity}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Unit Price</b>
                                    </td>
                                    <td>
                                      ₹ {order_data?.order_details?.unit_price}
                                    </td>
                                  </tr>

                                  <tr className="large-screen-view">
                                    <td>
                                      <b>Product Specifications</b>
                                    </td>
                                    <td>
                                      <p className="specs">
                                        <small>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: truncateText(
                                                order_data?.order_details
                                                  ?.product_details?.features
                                              ),
                                            }}
                                          />
                                        </small>
                                      </p>
                                    </td>
                                  </tr>
                                  {/* this row shows in mobile (Specs)*/}
                                  <tr className="mobile-view">
                                    <td colSpan={2} className="text-center">
                                      <b>Product Specifications</b>
                                    </td>
                                  </tr>
                                  <tr className="mobile-view">
                                    <td colSpan={2}>
                                      <p className="specs centered">
                                        <small>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: truncateText(
                                                order_data?.order_details
                                                  ?.product_details?.features
                                              ),
                                            }}
                                          />
                                        </small>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                          </Collapse>
                          {order_data?.address?.[0]?.state == "Karnataka" ? (
                            <>
                              <tr>
                                <td>
                                  <b>SGST</b>{" "}
                                </td>
                                <td>₹ {order_data?.order_details?.sgst}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>CGST</b>{" "}
                                </td>
                                <td>₹ {order_data?.order_details?.cgst}</td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                <td>
                                  <b>IGST</b>{" "}
                                </td>
                                <td>₹ {order_data?.order_details?.igst}</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2}>
                              <div className="order-view-bottom">
                                <h4>
                                  <small className="date">
                                    <span className="date-span-botton">
                                      {moment(
                                        props?.order_view?.updated_at
                                      ).format("LLL")}
                                    </span>
                                  </small>
                                </h4>
                                <h4 className="text-end">
                                  Grand Total :{" "}
                                  <strong>
                                    ₹
                                    {order_data?.order_details?.cgst *
                                      order_data?.order_details?.quantity +
                                      order_data?.order_details?.sgst *
                                        order_data?.order_details?.quantity +
                                      order_data?.order_details?.amount *
                                        order_data?.order_details?.quantity}
                                  </strong>
                                </h4>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </React.Fragment>
                  </>
                ) : (
                  <h4>
                    <i>
                      <b>After Allocation only you can view order</b>
                    </i>
                  </h4>
                )}

                {order_data?.mou_status == "2" ||
                order_data?.mou_status == null ? (
                  <div className="vendor-order-buttons">
                    <div className="button-flex">
                      <Button
                        color="success"
                        outline
                        onClick={downloadmou}
                      >
                        <i className="icons bi bi-download lg"></i>MOU Agreement
                      </Button>
                    </div>
                  </div>
                  
                ) : null}

                {order_data?.mou_status == "0" && (
                  <div className="">
                    <span className="text-success text-center">
                      Waiting approvel of the MOU Agreement Verfication
                    </span>
                  </div>
                )}

                {order_data?.mou_status == "0" ? null : (
                  <>
                    {" "}
                    {show_upload == true ? (
                      <Form>
                        <FormGroup className="file">
                          <Label>
                            MOU Agreement
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>

                          <div className="file-inner"> 
                            <Col lg="8" sm="12">
                              <div className="image-input">
                                <Input
                                  type="file"
                                  id="mou"
                                  name="mou"
                                  onChange={(e: any) => {
                                    MouChange(e.target.files[0]);
                                    setErr({
                                      ...error,
                                      mou_err: "",
                                    });
                                  }}
                                  accept=".pdf"
                                  // value={mou_image}
                                ></Input>
                                <Label
                                  className="image-button"
                                  for="mou"
                                  id="mou"
                                >
                                  Choose Documents
                                </Label>
                              </div>
                            </Col>

                            <Col lg="4">
                              <div className="">
                                <Button
                                  outline
                                  //type="submit"
                                  color="success"
                                  onClick={() => uploadmou()}
                                >
                                  <i className="icons bi bi-upload lg"></i> MOU
                                  Agreement
                                </Button>
                              </div>
                              <div className="text-danger">
                                {error?.mou_err}
                              </div>
                            </Col>
                          </div>
                        </FormGroup>
                      </Form>
                    ) : null}
                  </>
                )}

                {order_data?.mou_status == "1" && (
                  <>
                    <div className="flex-between price-flex margin">
                      <p>
                        {" "}
                        EMD Amount :{" "}
                        <span className="green-text">
                          {" "}
                          ₹
                          {EmdCalculation({
                            amount: amo,
                            udyam: order_data?.vendor_details?.udyam,
                          })?.toFixed(2)}
                        </span>
                        {props?.emd?.status == 1 ? (
                          <span className="text-success">(Approved)</span>
                        ) : order_data?.vendor_details?.udyam == "yes" ? (
                          <span className="text-success">(Approved)</span>
                        ) : props?.emd?.status == 2 ? (
                          <span className="text-danger">(Rejected)</span>
                        ) : props?.emd?.status == 0 ? (
                          <span className="text-danger">
                            (Verification In Progress)
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>
                        {" "}
                        Performance Guarentee Amount :{" "}
                        <span className="green-text">
                          {" "}
                          ₹
                          {PGCalculation({
                            amount: amo,
                            udyam: order_data?.vendor_details?.udyam,
                          })?.toFixed(2)}
                        </span>
                        {props?.pg?.pgstatus == 1 ? (
                          <span className="text-success">(Approved)</span>
                        ) : props?.pg?.pgstatus == 2 ? (
                          <span className="text-danger">(Rejected)</span>
                        ) : props?.pg?.pgstatus == 0 ? (
                          <span className="text-danger">
                            (Verification in Progress)
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <div className="button-flex">
                        {order_data?.vendor_details?.udyam == "yes" ? null : (
                          <>
                            {" "}
                            <Button
                              className="btn btn-primary"
                              color="primary"
                              // type="submit"
                              onClick={() => {
                                navigate("/secure-payment", {
                                  state: {
                                    amount: EmdCalculation({
                                      amount: amo,
                                      udyam: order_data?.vendor_details?.udyam,
                                    }),
                                    payfor: "EMD",
                                    order_id: props?.order_view[0]?.id,
                                    proforma_id:
                                      props?.order_view[0]?.proforma_id,
                                  },
                                });
                              }}
                              style={{
                                display: `${
                                  order_data?.vendor_details?.udyam == "yes"
                                    ? "none"
                                    : "block"
                                }`,
                              }}
                              disabled={
                                props?.emd?.status == 1
                                  ? true
                                  : props?.emd?.status == 0
                                  ? true
                                  : false
                              }
                            >
                              {order_data?.vendor_details?.udyam == "yes"
                                ? "Approved"
                                : props?.emd?.status == 0
                                ? "Paid EMD"
                                : props?.emd?.status == 1
                                ? "Approved"
                                : props?.emd?.status == 2
                                ? "Pay Again"
                                : "Pay EMD"}
                            </Button>
                          </>
                        )}

                        <Button
                          className="btn"
                          color="success"
                          //type="submit"
                          onClick={() => {
                            navigate("/secure-payment", {
                              state: {
                                amount: PGCalculation({
                                  amount: amo,
                                  udyam: order_data?.vendor_details?.udyam,
                                }),
                                payfor: "PG",
                                order_id: props?.order_view[0]?.id,
                                proforma_id: props?.order_view[0]?.proforma_id,
                                type: "Pay Performance Guarentee",
                              },
                            });
                          }}
                          disabled={
                            props?.pg?.pgstatus == 0
                              ? true
                              : props?.pg?.pgstatus == 1
                              ? true
                              : false
                          }
                        >
                          {props?.pg?.pgstatus == 0
                            ? "Paid PG"
                            : props?.pg?.pgstatus == 1
                            ? "Approved"
                            : props?.pg?.pgstatus == 2
                            ? "Pay Again"
                            : "Pay PG"}
                        </Button>
                      </div>
                    </div>
                  </>
                )}

                {props?.pg?.pgstatus == undefined &&
                props?.emd?.status == undefined ? (
                  <>
                    <div className="Order-status mt-3">
                      <Label>
                        Order Status{" "}
                        <i
                          className="fa fa-asterisk required-field"
                          color="text-danger"
                          aria-hidden="true"
                        ></i>
                      </Label>
                      <Input
                        type="select"
                        name="order_status"
                        value={props?.values?.order_satus}
                        data={data}
                        onChange={props?.handleChange}
                        className={`form-control  mt-2 ${
                          props?.touched.order_status &&
                          props?.errors.order_status
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <option value="">Select Order Status</option>

                        <>
                          <option
                            value={props?.order_status?.[1]?.value}
                            style={{ color: "red" }}
                          >
                            {" "}
                            {props?.order_status?.[1]?.name}
                          </option>
                        </>
                      </Input>
                      <ErrorMessage name="order_status" component={TextError} />
                      {props?.values?.order_status == 10 ? (
                        <>
                          <Input
                            name="rejection_reasons"
                            type="text"
                            value={props?.values?.rejection_reasons}
                            className={`form-control mt-4 ${
                              props?.touched.rejection_reasons &&
                              props?.errors.rejection_reasons
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={props?.handleChange}
                            placeholder="please enter reject reasons"
                          />
                          <ErrorMessage
                            name="rejection_reasons"
                            component={TextError}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <Row className="text-end">
                      <div></div>
                      <div className="vendor-order-buttons">
                        <div className="button-flex mt-4">
                          <Button
                            color="success"
                            className="btn"
                            onClick={downloadinvoice}
                          >
                            Download Work Order
                          </Button>

                          {/* <Button onClick={generateinvoice} color="outline">View Work Order</Button> */}
                        </div>
                        <div className="button-flex-outer">
                          {props?.stat == 7 ? (
                            <div className="button-flex">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={props?.handleSubmit}
                                className={"px-5"}
                                // disabled={order_data?.vendor_details?.udyam ? false : order_data?.vendor_to_pmu_payment?.status == 1 ? false : true}
                              >
                                Save
                              </Button>
                            </div>
                          ) : (
                            <div className="button-flex mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={props?.handleSubmit}
                                className={"px-5"}
                                disabled={
                                  props?.stat == 9
                                    ? props?.location?.length == 1
                                    : false
                                }
                              >
                                Save
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Row>
                  </>
                ) : null}

                {props?.pg?.pgstatus == 1 &&
                props?.emd?.status == 1 &&
                order_data?.vendor_details?.udyam !== "yes" ? (
                  <>
                    <div className="Order-status">
                      <Label>
                        Order Status{" "}
                        <i
                          className="fa fa-asterisk required-field"
                          color="text-danger"
                          aria-hidden="true"
                        ></i>
                      </Label>
                      {props?.emd?.status !== 0 ? (
                        <Input
                          type="select"
                          name="order_status"
                          value={props?.values?.order_satus}
                          data={data}
                          onChange={props?.handleChange}
                          className={`form-control  mt-2 ${
                            props?.touched.order_status &&
                            props?.errors.order_status
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Order Status</option>

                          {order_data?.vendor_details?.udyam == "yes" ? (
                            <>
                              <option
                                value={props?.order_status?.[0]?.value}
                                style={{ color: "green" }}
                              >
                                {" "}
                                {props?.order_status?.[0]?.name}
                              </option>
                              <option
                                value={props?.order_status?.[1]?.value}
                                style={{ color: "red" }}
                              >
                                {" "}
                                {props?.order_status?.[1]?.name}
                              </option>
                            </>
                          ) : props?.emd?.status == 1 ? (
                            <>
                              <option
                                value={props?.order_status?.[0]?.value}
                                style={{ color: "green" }}
                              >
                                {" "}
                                {props?.order_status?.[0]?.name}
                              </option>
                            </>
                          ) : (
                            <>
                              <option
                                value={props?.order_status?.[1]?.value}
                                style={{ color: "red" }}
                              >
                                {" "}
                                {props?.order_status?.[1]?.name}
                              </option>
                            </>
                          )}
                        </Input>
                      ) : (
                        <Input
                          type="select"
                          name="order_status"
                          value={props?.values?.order_status}
                          data={data}
                          onChange={props?.handleChange}
                          className={`form-control  mt-2 ${
                            props?.touched.order_status &&
                            props?.errors.order_status
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Order Status</option>
                          {props?.order_status?.map((item: any, i: number) => {
                            return (
                              <React.Fragment key={item?.value}>
                                <option key={i} value={item?.value}>
                                  {" "}
                                  {item?.name}
                                </option>
                              </React.Fragment>
                            );
                          })}
                        </Input>
                      )}
                      <ErrorMessage name="order_status" component={TextError} />
                      {props?.values?.order_status == 10 ? (
                        <>
                          <Input
                            name="rejection_reasons"
                            type="text"
                            value={props?.values?.rejection_reasons}
                            className={`form-control ${
                              props?.touched.rejection_reasons &&
                              props?.errors.rejection_reasons
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={props?.handleChange}
                            placeholder="please enter reject reasons"
                          />
                          <ErrorMessage
                            name="rejection_reasons"
                            component={TextError}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <Row className="text-end">
                      <div></div>
                      <div className="vendor-order-buttons">
                        <div className="button-flex mt-4">
                          <Button
                            color="success"
                            className="btn"
                            onClick={downloadinvoice}
                          >
                            Download Work Order
                          </Button>

                          {/* <Button onClick={generateinvoice} color="outline">View Work Order</Button> */}
                        </div>
                        <div className="button-flex-outer">
                          {props?.stat == 7 ? (
                            <div className="button-flex">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={props?.handleSubmit}
                                className={"px-5"}
                                // disabled={order_data?.vendor_details?.udyam ? false : order_data?.vendor_to_pmu_payment?.status == 1 ? false : true}
                              >
                                Save
                              </Button>
                            </div>
                          ) : (
                            <div className="button-flex mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={props?.handleSubmit}
                                className={"px-5"}
                                disabled={
                                  props?.stat == 9
                                    ? props?.location?.length == 1
                                    : false
                                }
                              >
                                Save
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Row>
                  </>
                ) : null}
                {props?.pg?.pgstatus == 0 &&
                props?.emd?.status == 0 &&
                order_data?.vendor_details?.udyam !== "yes" ? (
                  <>
                    <div className="Order-status">
                      <Label>
                        Order Status{" "}
                        <i
                          className="fa fa-asterisk required-field"
                          color="text-danger"
                          aria-hidden="true"
                        ></i>
                      </Label>
                      {props?.emd?.status == 0 ? (
                        <Input
                          type="select"
                          name="order_status"
                          value={props?.values?.order_satus}
                          data={data}
                          onChange={props?.handleChange}
                          className={`form-control  mt-2 ${
                            props?.touched.order_status &&
                            props?.errors.order_status
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Order Status</option>

                          {order_data?.vendor_details?.udyam == "yes" ? (
                            <>
                              <option
                                value={props?.order_status?.[0]?.value}
                                style={{ color: "green" }}
                              >
                                {" "}
                                {props?.order_status?.[0]?.name}
                              </option>
                              <option
                                value={props?.order_status?.[1]?.value}
                                style={{ color: "red" }}
                              >
                                {" "}
                                {props?.order_status?.[1]?.name}
                              </option>
                            </>
                          ) : props?.emd?.status == 1 ? (
                            <>
                              <option
                                value={props?.order_status?.[0]?.value}
                                style={{ color: "green" }}
                              >
                                {" "}
                                {props?.order_status?.[0]?.name}
                              </option>
                            </>
                          ) : (
                            <>
                              <option
                                value={props?.order_status?.[1]?.value}
                                style={{ color: "red" }}
                              >
                                {" "}
                                {props?.order_status?.[1]?.name}
                              </option>
                            </>
                          )}
                        </Input>
                      ) : (
                        <Input
                          type="select"
                          name="order_status"
                          value={props?.values?.order_status}
                          data={data}
                          onChange={props?.handleChange}
                          className={`form-control  mt-2 ${
                            props?.touched.order_status &&
                            props?.errors.order_status
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Order Status</option>
                          {props?.order_status?.map((item: any, i: number) => {
                            return (
                              <React.Fragment key={item?.value}>
                                <option key={i} value={item?.value}>
                                  {" "}
                                  {item?.name}
                                </option>
                              </React.Fragment>
                            );
                          })}
                        </Input>
                      )}
                      <ErrorMessage name="order_status" component={TextError} />
                      {props?.values?.order_status == 10 ? (
                        <>
                          <Input
                            name="rejection_reasons"
                            type="text"
                            value={props?.values?.rejection_reasons}
                            className={`form-control ${
                              props?.touched.rejection_reasons &&
                              props?.errors.rejection_reasons
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={props?.handleChange}
                            placeholder="please enter reject reasons"
                          />
                          <ErrorMessage
                            name="rejection_reasons"
                            component={TextError}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <Row className="text-end">
                      <div></div>
                      <div className="vendor-order-buttons">
                        <div className="button-flex mt-4">
                          <Button
                            color="success"
                            className="btn"
                            onClick={downloadinvoice}
                          >
                            Download Work Order
                          </Button>

                          {/* <Button onClick={generateinvoice} color="outline">View Work Order</Button> */}
                        </div>
                        <div className="button-flex-outer">
                          {props?.stat == 7 ? (
                            <div className="button-flex">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={props?.handleSubmit}
                                className={"px-5"}
                                // disabled={order_data?.vendor_details?.udyam ? false : order_data?.vendor_to_pmu_payment?.status == 1 ? false : true}
                              >
                                Save
                              </Button>
                            </div>
                          ) : (
                            <div className="button-flex mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={props?.handleSubmit}
                                className={"px-5"}
                                disabled={
                                  props?.stat == 9
                                    ? props?.location?.length == 1
                                    : false
                                }
                              >
                                Save
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Row>
                  </>
                ) : null}
                {props?.pg?.pgstatus == 1 &&
                order_data?.vendor_details?.udyam == "yes" ? (
                  <>
                    <div className="Order-status">
                      <Label>
                        Order Status{" "}
                        <i
                          className="fa fa-asterisk required-field"
                          color="text-danger"
                          aria-hidden="true"
                        ></i>
                      </Label>
                      {props?.emd?.status !== 0 ? (
                        <Input
                          type="select"
                          name="order_status"
                          value={props?.values?.order_satus}
                          data={data}
                          onChange={props?.handleChange}
                          className={`form-control  mt-2 ${
                            props?.touched.order_status &&
                            props?.errors.order_status
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Order Status</option>

                          {order_data?.vendor_details?.udyam == "yes" ? (
                            <>
                              <option
                                value={props?.order_status?.[0]?.value}
                                style={{ color: "green" }}
                              >
                                {" "}
                                {props?.order_status?.[0]?.name}
                              </option>
                              <option
                                value={props?.order_status?.[1]?.value}
                                style={{ color: "red" }}
                              >
                                {" "}
                                {props?.order_status?.[1]?.name}
                              </option>
                            </>
                          ) : order_data?.vendor_to_pmu_payment?.status == 1 ? (
                            <>
                              <option
                                value={props?.order_status?.[0]?.value}
                                style={{ color: "green" }}
                              >
                                {" "}
                                {props?.order_status?.[0]?.name}
                              </option>
                            </>
                          ) : (
                            <>
                              <option
                                value={props?.order_status?.[1]?.value}
                                style={{ color: "red" }}
                              >
                                {" "}
                                {props?.order_status?.[1]?.name}
                              </option>
                            </>
                          )}
                        </Input>
                      ) : (
                        <Input
                          type="select"
                          name="order_status"
                          value={props?.values?.order_status}
                          data={data}
                          onChange={props?.handleChange}
                          className={`form-control  mt-2 ${
                            props?.touched.order_status &&
                            props?.errors.order_status
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Order Status</option>
                          {props?.order_status?.map((item: any, i: number) => {
                            return (
                              <React.Fragment key={item?.value}>
                                <option key={i} value={item?.value}>
                                  {" "}
                                  {item?.name}
                                </option>
                              </React.Fragment>
                            );
                          })}
                        </Input>
                      )}
                      <ErrorMessage name="order_status" component={TextError} />
                      {props?.values?.order_status == 10 ? (
                        <>
                          <Input
                            name="rejection_reasons"
                            type="text"
                            value={props?.values?.rejection_reasons}
                            className={`form-control ${
                              props?.touched.rejection_reasons &&
                              props?.errors.rejection_reasons
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={props?.handleChange}
                            placeholder="please enter reject reasons"
                          />
                          <ErrorMessage
                            name="rejection_reasons"
                            component={TextError}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <Row className="text-end">
                      <div></div>
                      <div className="vendor-order-buttons">
                        <div className="button-flex mt-4">
                          <Button
                            color="success"
                            className="btn"
                            onClick={downloadinvoice}
                          >
                            Download Work Order
                          </Button>

                          {/* <Button onClick={generateinvoice} color="outline">View Work Order</Button> */}
                        </div>
                        <div className="button-flex-outer">
                          {props?.stat == 7 ? (
                            <div className="button-flex">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={props?.handleSubmit}
                                className={"px-5"}
                                // disabled={order_data?.vendor_details?.udyam ? false : order_data?.vendor_to_pmu_payment?.status == 1 ? false : true}
                              >
                                Save
                              </Button>
                            </div>
                          ) : (
                            <div className="button-flex mt-4">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={props?.handleSubmit}
                                className={"px-5"}
                                disabled={
                                  props?.stat == 9
                                    ? props?.location?.length == 1
                                    : false
                                }
                              >
                                Save
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Row>
                  </>
                ) : null}
              </Form>
            </div>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default ReceivedOrderModal;
