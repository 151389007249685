import * as Yup from "yup";
const today = new Date();
const VendorAllocationValidationSchema = Yup.object().shape({
  low_1_quantity: Yup.number()
    .typeError("Low 1 quantity must be a number")
    .required("Low 1 quantity is required"),
  low_2_quantity: Yup.number().when("low_1_quantity", {
    is: (val: string) => val && val.length > 0,
    then: Yup.number()
      .typeError("Low 2 quantity must be a number")
      .required("Low 2 quantity is required"),
    otherwise: Yup.number().typeError("Low 2 quantity must be a number"),
  }),
  low_3_quantity: Yup.number().when("low_2_quantity", {
    is: (val: string) => val && val.length > 0,
    then: Yup.number()
      .typeError("Low 3 quantity must be a number")
      .required("Low 3 quantity is required"),
    otherwise: Yup.number().typeError("Low 3 quantity must be a number"),
  }),

  // low_2_quantity: Yup.number().required("Low 1 quantity is required"),
  // low_3_quantity: Yup.number().required("Low 1 quantity is required"),
});
const RequestamountValidationSchema = Yup.object().shape({
  comments: Yup.string().required("Comments is required"),
  reamin_amount: Yup.number().required("Reamin amount is required"),

});
export { VendorAllocationValidationSchema, RequestamountValidationSchema };
