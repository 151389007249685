export const Vendor_DocumentView:any = {
    Sl_No: {
        en: "Sl No ",
        ka: "ಎಸ್ಎಲ್ ನಂ"
    },
    Document_Type: {
        en: "Document Type",
        ka: "ಡಾಕ್ಯುಮೆಂಟ್ ಪ್ರಕಾರ"
    },
    Document: {
        en: "Document",
        ka: "ಡಾಕ್ಯುಮೆಂಟ್"
    },
    Status: {
        en: "Status",
        ka: "ಸ್ಥಿತಿ"
    },
    Documents: {
        en: "Documents",
        ka: "ದಾಖಲೆಗಳು"
    },
    Action: {
        en: "Action",
        ka: "ಕ್ರಿಯೆ"
    },
    Approved: {
        en: "Approved",
        ka: "ಅನುಮೋದಿಸಲಾಗಿದೆ"
    },
    Rejected: {
        en: "Rejected",
        ka: "ತಿರಸ್ಕರಿಸಿದ"
    },
    Reuploaded: {
        en: "Reuploaded",
        ka: "ಮರುಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿದೆ"
    },
    Uploaded: {
        en: "Uploaded",
        ka: "ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ"
    },
    View: {
        en: "View",
        ka: "ನೋಟದೆ"
    },
    Oem_in_company_type: {
        en: "Oem in company type",
        ka: "ಕಂಪನಿ ಪ್ರಕಾರದಲ್ಲಿ ಓ ಇ ಎಂ"
    },
    Oem_valid_from: {
        en: "Oem valid from",
        ka: "ಓ ಇ ಎಂ ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ"
    },
    Oem_valid_to: {
        en: "Oem valid to",
        ka: "ಓ ಇ ಎಂ ಮಾನ್ಯವಾಗಿದೆ"
    },
    Start_up_company_udyamdate: {
        en: "Start up company udyamdate",
        ka: "ಉದ್ಯಮ ದಿನಾಂಕವನ್ನು ಪ್ರಾರಂಭಿಸಿ"
    },
    start_up_company_udyam_district_industries_center: {
        en: "start up company udyam district industries center",
        ka: "ಉದ್ಯಮ ಜಿಲ್ಲಾ ಕೈಗಾರಿಕೆಗಳ ಕೇಂದ್ರವನ್ನು ಪ್ರಾರಂಭಿಸಿ"
    },
    Start_up_company_udyam_major_activity: {
        en: "Start up company udyam major activity",
        ka: "ಉದ್ಯಮದ ಪ್ರಮುಖ ಚಟುವಟಿಕೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿ"
    },
    Start_up_company_udyam_msme_dfo: {
        en: "Start up company udyam msme dfo",
        ka: "ಉದ್ಯಮ msme dfo ಕಂಪನಿಯನ್ನು ಪ್ರಾರಂಭಿಸಿ"
    },
    start_up_company_udyam_name_of_enterprises: {
        en: "start up company udyam name of enterprises",
        ka: "ಉದ್ದಿಮೆಗಳ ಕಂಪನಿಯ ಹೆಸರು ಉದ್ಯಮವನ್ನು ಪ್ರಾರಂಭಿಸಿ"
    },
    Start_up_company_udyam_registration_number: {
        en: "Start up company udyam registration number",
        ka: "ಸ್ಟಾರ್ಟ್ ಅಪ್ ಕಂಪನಿ ಉದ್ಯಮ ನೋಂದಣಿ ಸಂಖ್ಯೆ"
    },
    Start_up_company_udyam_social_category_of_enterprises: {
        en: "Start up company udyam registration number",
        ka: "ಉದ್ಯಮಗಳ ಸಾಮಾಜಿಕ ವರ್ಗದ ಉದ್ಯಮಗಳನ್ನು ಪ್ರಾರಂಭಿಸಿ"
    },
    Start_up_company_udyam_type_of_enterprises: {
        en: "Start up company udyam type of enterprises",
        ka: "ಉದ್ಯಮ ಉದ್ಯಮ ಮಾದರಿಯ ಉದ್ಯಮಗಳನ್ನು ಪ್ರಾರಂಭಿಸಿ"
    },
    Start_up_company_udyam_type_office_address: {
        en: "Start up company udyam type office address",
        ka: "ಉದ್ಯಮದ ಪ್ರಕಾರದ ಕಚೇರಿ ವಿಳಾಸವನ್ನು ಪ್ರಾರಂಭಿಸಿ"
    },
    Start_up_company_power_attorney_company_name: {
        en: "Start up company power attorney company name",
        ka: "ಕಂಪನಿಯ ಪವರ್ ಅಟಾರ್ನಿ ಕಂಪನಿಯ ಹೆಸರು ಪ್ರಾರಂಭಿಸಿ"
    },
    Start_up_company_power_attorney_director_name: {
        en: "Start up company power attorney director name",
        ka: "ಕಂಪನಿಯ ಪವರ್ ಅಟಾರ್ನಿ ಕಂಪನಿಯ ಹೆಸರು ಪ್ರಾರಂಭಿಸಿ"
    },
    Manpower_Date: {
        en: "Manpower Date",
        ka: "ಮಾನವಶಕ್ತಿ ದಿನಾಂಕ"
    },
    Manpower_Number: {
        en: "Manpower Number",
        ka: "ಮಾನವಶಕ್ತಿ ಸಂಖ್ಯೆ"
    },
    Land_extent: {
        en: "Land extent",
        ka: "ಭೂಮಿಯ ವಿಸ್ತಾರ"
    },
    Land_survey_no: {
        en: "Land survey no",
        ka: "ಜಮೀನಿನ ಸರ್ವೆ ನಂ"
    },
    Land_value: {
        en: "Land value",
        ka: "ಭೂಮಿಯ ಮೌಲ್ಯ"
    },
    Land_village_col: {
        en: "Land village col",
        ka: "ಜಮೀನು ಗ್ರಾಮ ಕಲಂ"
    },
    First_IT_turn_over_Amount: {
        en: "First IT turn over Amount",
        ka: "ಮೊತ್ತದ ಮೇಲೆ ಮೊದಲ IT ಟರ್ನ್"
    },
    First_IT_turn_over_Year: {
        en: "First IT turn over Year",
        ka: "ವರ್ಷದ ಮೊದಲ ಐಟಿ ತಿರುವು"
    },
    First_IT_turn_over_CA_Email: {
        en: "First IT turn over CA Email",
        ka: "ಮೊದಲ IT ಟರ್ನ್ ಓವರ್ CA ಇಮೇಲ್"
    },
    First_IT_turn_over_CA_Name: {
        en: "First IT turn over CA Name",
        ka: "ಮೊದಲ IT ಟರ್ನ್ ಓವರ್ ಸಿಎ ಹೆಸರು"
    },
    First_IT_turn_over_CA_Phone: {
        en: " First IT turn over CA Phone",
        ka: "ಮೊದಲ IT ಟರ್ನ್ CA ಫೋನ್"
    },
    Second_IT_turn_over_Amount: {
        en: " Second IT turn over Amount",
        ka: "ಮೊದಲ IT ಟರ್ನ್ CA ಫೋನ್"
    },
    IT_turn_over_Year: {
        en: " IT turn over Year",
        ka: "ವರ್ಷಕ್ಕೆ ಐಟಿ ತಿರುವು"
    },
    IT_turn_over_CA_Email: {
        en: " IT turn over Year",
        ka: "ಐಟಿ ಟರ್ನ್ ಓವರ್ ಸಿಎ ಇಮೇಲ್"
    },
    IT_turn_over_CA_Name: {
        en: " IT turn over Year",
        ka: "ಸಿಎ ಹೆಸರನ್ನು ಐಟಿ ತಿರುಗಿಸಿ"
    },
    IT_turn_over_CA_Phone: {
        en: "  IT turn over CA Phone",
        ka: "ಐಟಿ ಸಿಎ ಫೋನ್ ಅನ್ನು ತಿರುಗಿಸಿ"
    },
    Financial_year: {
        en: "  Financial year",
        ka: "ಹಣಕಾಸು ವರ್ಷ"
    },
    CA_Email: {
        en: "CA Email",
        ka: "ಸಿ ಎ ಇಮೇಲ್"
    },
    CA_Name: {
        en: "CA Name",
        ka: "ಸಿ ಎ ಹೆಸರು"
    },
    CA_Phone: {
        en: "CA Phone",
        ka: "ಸಿ ಎ ಫೋನ್"
    },
    Financial_year_amount: {
        en: "Financial year amount",
        ka: "ಹಣಕಾಸು ವರ್ಷದ ಮೊತ್ತ"
    },
    Empanelment_Date: {
        en: "Empanelment Date",
        ka: "ಎಂಪನೆಲ್ಮೆಂಟ್ ದಿನಾಂಕ"
    },
    Empanelment_Expiry_Date: {
        en: "Empanelment Expiry Date",
        ka: "ಎಂಪನೆಲ್‌ಮೆಂಟ್ ಮುಕ್ತಾಯ ದಿನಾಂಕ"
    },
}