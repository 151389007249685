import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { useContext } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import TextError from "../../../../Components/Errors/TextError";
import Select from "react-select";
import { version } from "../../../../Library/Constants/MapConstants";
import DatePicker from 'react-date-picker'
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../Components/Context/Context";
import { Admin_addtendor } from "../../../../Library/LanguageConversion/Admin_addtendor";

interface TenderProps {
  isShowing: boolean;
  setIsShowing: Function;
  handleSubmit: any;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  isSubmitting: any;
  handleReset: any;
  category: any;
  setFieldValue: any;
}
const AddTenderModal = (props: TenderProps) => {
  let start_date: any = `${new Date().toLocaleString("default", {
    year: "numeric",
  })}-${new Date().toLocaleString("default", {
    month: "2-digit",
  })}-${new Date().toLocaleString("default", { day: "2-digit" })}`;
  const [dateformat, setDateformat] = useState<any>([]);
  const { language, setLanguage } = useContext(Context);

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header={Admin_addtendor?.AddTender[language]}
      >
        <div className="add-vendor-outer">

          <Form className="form-wrap">
            <Row className="align-items-end">
              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label>
                  {Admin_addtendor?.TenderID[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    name="tender_Id"
                    label="Tender ID"
                    type="text"
                    value={props?.values?.tender_Id}
                    onChange={(e: any) => {
                      const capitalizedInput =
                        e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1);
                      props?.setFieldValue("tender_Id", capitalizedInput);
                    }}
                    className={`form-control ${props?.touched.tender_Id && props?.errors.tender_Id
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  <ErrorMessage name="tender_Id" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label>
                  {Admin_addtendor?.TenderDate[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <DatePicker onChange={(e: any) => {
                    props?.setFieldValue("tender_date", e);
                  }}
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={props?.values?.tender_date}
                    name="tender_date"
                    calendarClassName={`form-control ${props?.touched.tender_date &&
                      props?.errors.tender_date
                      ? "is-invalid"
                      : ""
                      }`}
                    maxDate={new Date()} />


                  <ErrorMessage name="tender_date" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label>
                  {Admin_addtendor?.Category[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>

                  <Select
                    name="category"
                    options={props?.category}
                    value={props?.values?.category}
                    onChange={(category) =>
                      props?.setFieldValue("category", category)
                    }
                    className={` ${props?.touched.category && props?.errors.category
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching Category"}
                    noOptionsMessage={() => "Category appears here"}
                    isSearchable={true}
                    isMulti={true}
                    isClearable
                  />

                  <ErrorMessage name="category" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label className="large-screen-view">
                  {Admin_addtendor?.Version[language]}{" "}
                    {/* <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i> */}
                  </Label>
                  <Input
                    type="select"
                    name="version"
                    value={props?.values?.version}
                    data={version}
                    onChange={props?.handleChange}
                    className={`form-control ${props?.touched.version && props?.errors.version
                      ? "is-invalid"
                      : ""
                      }`}
                  >
                    <option value="">{Admin_addtendor?.SelectVersion[language]}</option>;
                    {version?.map((item: any, i: number) => {
                      return (
                        <React.Fragment key={item?.value}>
                          <option key={i} value={item?.value}>
                            {" "}
                            {item?.name}
                          </option>
                        </React.Fragment>
                      );
                    })}
                  </Input>
                  <ErrorMessage name="version" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label>
                  {Admin_addtendor?.ValidFrom[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <DatePicker onChange={(e: any) => {
                    props?.setFieldValue("valid_to", '');
                    props?.setFieldValue("valid_from", e);
                    setDateformat(new Date(e))
                  }}
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={props?.values?.valid_from}
                    name="valid_from"
                    calendarClassName={`form-control ${props?.touched.valid_from &&
                      props?.errors.valid_from
                      ? "is-invalid"
                      : ""
                      }`}
                    minDate={new Date()}
                  />
                  <ErrorMessage name="valid_from" component={TextError} />
                </FormGroup>
              </Col>

              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label>
                  {Admin_addtendor?.ValidTo[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>


                  <DatePicker onChange={(e: any) => {
                    props?.setFieldValue("valid_to", e);

                  }}
                    calendarAriaLabel={
                      props?.values?.valid_from
                        ? ""
                        : "Please select valid from"
                    }
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={props?.values?.valid_to}
                    name="valid_to"
                    calendarClassName={`form-control ${props?.touched.valid_to &&
                      props?.errors.valid_to
                      ? "is-invalid"
                      : ""
                      }`}
                    minDate={props?.values?.valid_from}
                    disabled={props?.values?.valid_from ? false : true}
                  />


                  <ErrorMessage name="valid_to" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label>
                    {Admin_addtendor?.Approvedby[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    name="approved_by"
                    label="Approved by"
                    type="text"
                    value={props?.values?.approved_by}
                    onChange={props?.handleChange}
                    className={`form-control ${props?.touched.approved_by && props?.errors.approved_by
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  <ErrorMessage name="approved_by" component={TextError} />
                </FormGroup>
              </Col>
              {/* <Col md={12}>
                    <FormGroup
                      className={"d-flex align-items-center gap-2 mb-5"}
                    >
                      <Input type="checkbox" />{" "}
                      <Label check>
                        Lorem Ipsum is simply dummy text of the printing
                      </Label>
                    </FormGroup>
                  </Col> */}
              <div className="footer-sticky">
                <FormGroup className={"text-end"}>
                  <Button
                    color="primary"
                    onClick={props?.handleSubmit}
                    className={"px-5"}
                    disabled={props?.isSubmitting}
                  >
                    {Admin_addtendor?.AddTender[language]}
                  </Button>
                </FormGroup>
              </div>
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </React.Fragment >
  );
};
export default AddTenderModal;
