

export const Admin_Departlist:any = {
    //Admin\Departmentlist\Departmentlistlayout
    Departments: {
        en: 'Departments',
        ka: 'ಇಲಾಖೆಗಳು'
    },

    DepartmentAccounts: {
        en: 'Department Accounts',
        ka: 'ವಿಭಾಗ ಖಾತೆಗಳು'
    },
    Dashboard: {
        en: ' Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },

    //Admin\Departmentlist\modal\AddDepartmentType

    DepartmentName: {
        en: 'Department Name',
        ka: 'ವಿಭಾಗ ಹೆಸರು'
    },
    Department_Name: {
        en: 'Department/Name',
        ka: 'ವಿಭಾಗ/ಹೆಸರು'
    },

     
    Submit: {
        en: 'Submit',
        ka: 'ಸಲ್ಲಿಸು'
    },

    //ADmin\Departmentlist\modal\AddDepartmentValidationSchema

    //Admin\Departmentlist\modal\Departmentlist
      
    SlNo: {
        en: 'Sl No',
        ka: 'ಕ್ರ.ಸಂ'
    },

    Name:{
        en: 'Name',
        ka: 'ಹೆಸರು'
    },

    Department:{
        en: 'Department',
        ka: 'ಇಲಾಖೆ'
    },

    EmailID:{
        en:'Email ID',
        ka:'ಇಮೇಲ್ ಐಡಿ'
    },
    
    PhoneNumber:{
        en:'Phone Number',
        ka:'ಫೋನ್ ಸಂಖ್ಯೆ'
    },

    NoDepartmentAccountsfound:{
        en:'No Department Accounts found',
        ka:'ಯಾವುದೇ ವಿಭಾಗ ಖಾತೆಗಳು ಕಂಡುಬರಲಿಲ್ಲ'
    },

    Showing:{
        en:'Showing',
        ka:'ಪ್ರದರ್ಶಿಸುವುದು'
    },
    
    to:{
        en:'to',
        ka:'ಗೆ'
    },
    of:{
        en:'of',
        ka:'ನ'
    },
    entries:{
        en:'entries',
        ka:'ನಮೂದುಗಳು'
    },
    Active: {
        en: 'Active',
        ka: 'ಸಕ್ರಿಯ'
    },
    Inactive: {
        en: 'Inactive',
        ka: 'ನಿಷ್ಕ್ರಿಯ'
    },



    //Admin\Departmentlist\Departments

    Status:{
        en:'Status',
        ka:'ಸ್ಥಿತಿ'
    },

    NoDepartmentFound:{
        en:' No Departments Found',
        ka:'ಯಾವ ವಿಭಾಗವೂ ಕಂಡುಬಂದಿಲ್ಲ'
    },
    AddDepartment: {
        en: 'Add Department',
        ka: 'ಇಲಾಖೆಯನ್ನು ಸೇರಿಸಿ'
    },


}
