import React, { useState, useContext } from "react";
import {
  Col,
  Row,
  Input,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import Loader from "../../../../Components/Loader/Loader";
import { ErrorMessage, Formik } from "formik";
import { Context } from "../../../../Components/Context/Context";
import { Department_DeliveryAddress } from "../../../../Library/LanguageConversion/Department_DeliveryAddress";
import { AddAddressValidation } from "./AddAddressValidationYup/AddAddressValidationYup";
import TextError from "../../../../Components/Errors/TextError";
import { toast } from "react-toastify";
import { api } from "../../../../Library/CustomApi";
import axios from "axios";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { ADDRESS_TYPE } from "../../../../Library/Constants/MapConstants";

interface propType {
  addressData: any;
  isLoading: boolean;
  getAddress: Function;
  selectAddress: Function;
  setOpen?: any;
  setTab: Function;
}

export default function DeptDeliveryAddress(props: propType) {
  const { language, setLanguage } = useContext(Context);
  const [modal, setModal] = useState(false); // for add address modal
  const [isOpen, setIsOpen] = useState<boolean>(false); // for dropdown in add address modal
  const [cities, setCities] = useState<any>([]);

  // for auto-filling location details based on postal code n
  const fetchLocation = (
    pinCode: string,
    setFieldValue: Function,
    setFieldError: Function,
    setFieldTouched: Function
  ) => {
    if (pinCode?.length < 6) {
      setFieldValue("city", []);
      setCities([]);
      setFieldValue("district", "");
      setFieldValue("state", "");
      setFieldValue("country", "");
    }
    if (pinCode?.length > 5) {
      axios
        .get(`https://api.postalpincode.in/pincode/${pinCode}`)
        .then((response) => {
          setCities(response.data[0].PostOffice);
          setFieldValue("district", response.data[0].PostOffice[0].District);
          setFieldValue("state", response.data[0].PostOffice[0].State);
          setFieldValue("country", response.data[0].PostOffice[0].Country);

          setFieldTouched("district", false, false);
          setFieldTouched("state", false, false);
          setFieldTouched("country", false, false);
        })
        .catch((error) => {
          setFieldError("pincode", "Invalid pincode");
          toast.error("Please enter a valid Pincode", {
            toastId: "pincodeerror1",
          });
        });
    }
  };

  // formik initial values
  const initialValues = {
    building_no: "",
    pincode: "",
    district: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    address_type: "",
    email: "",
    phone: "",
    alt_phone: "",
    whatsapp_no: "",
  };

  // submit address
  const HandleAddAddress = (values: any, actions: any) => {
    api
      .post(
        `/address`,
        {
          building_no: values.building_no,
          pincode: values.pincode,
          district: values.district,
          landmark: values.landmark,
          city: values.city,
          state: values.state,
          country: values.country,
          type: values.address_type,
          email: values.email,
          phone: values.phone,
          alt_phone: values.alt_phone,
          whatsapp_no: values.whatsapp_no,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Address added Successfully!");
          props.getAddress();
          props.setTab(2);
          setModal(false);
        }
        return response;
      })
      .catch((err) => {
        if (err?.response?.status == 422) {
          if (err?.response?.data?.errors?.email) {
            actions.setFieldError("email", err?.response?.data?.errors?.email);
          }
          toast.error(err?.response?.data?.message);
          actions.setSubmitting(false);
        }
        actions.setSubmitting(false);
      });
  };

  // handles address selection
  const selectHandler = (id: number) => {
    props.selectAddress(id);
    props.setOpen && props.setOpen(false);
  };

  return (
    <>
      <Row className="gy-4">
        <Col md={12} sm={12}>
          <div className="address-filter">
            <Button
              color="primary"
              onClick={() => setModal(!modal)}
            >
              <i className="bi bi-plus"></i>{Department_DeliveryAddress?.AddAddress[language]}
            </Button>
          </div>
        </Col>

        <Col md={12}>
          {!props.isLoading ? (
            <>
              {props?.addressData?.length !== 0 ? (
                props?.addressData?.map((obj: any, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <label htmlFor={String(i)} className="label-wrap">
                        <div key={i} className="address-verify-inner">
                          <Row>
                            <Col md={12}>
                              <div className="add-outer gap-4">
                                <div className="add-inner">
                                  <div className="icon">
                                    <i className="icons icons-address-rounded xxl"></i>
                                  </div>

                                  <div className="address-wrap">
                                    <h5>
                                      <small>
                                        {
                                          Department_DeliveryAddress
                                            ?.BuildingNo[language]
                                        }{" "}
                                        : {obj?.building_no}
                                      </small>
                                      <br />
                                      <small>{obj?.landmark}</small>
                                      <small>{obj?.city}</small>
                                      <br />
                                      <small>{obj?.district}</small>
                                      <br />
                                      <small>{obj?.country}</small>
                                      <br />
                                      <small>{obj?.pincode}</small>
                                    </h5>
                                  </div>
                                </div>

                                <div className="address-checkbox">
                                  <Input
                                    type="radio"
                                    name="address"
                                    checked={obj?.status == 2}
                                    id={String(i)}
                                    onChange={() => selectHandler(obj.id)}
                                  ></Input>
                                </div>
                              </div>
                            </Col>
                            {/* <Col md={4}>
                              <div className="address-checkbox float-end">
                                <Input type="radio" name="address" checked={obj?.status == 2} id={String(i)} onChange={() => selectHandler(obj.id)}></Input>
                              </div>
                            </Col> */}
                          </Row>
                        </div>
                      </label>
                    </React.Fragment>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  <p>
                    {" "}
                    {Department_DeliveryAddress?.NoAddressesFound[language]}
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            </>
          )}
        </Col>
      </Row>

      {/* Add Address Modal */}
      <div>
        <ModalContainer
          open={modal}
          setOpen={setModal}
          header= {Department_DeliveryAddress?.AddNewAddress[language]}
        >
          <div className="add-address-wrap">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={AddAddressValidation}
              onSubmit={(values, actions) => {
                HandleAddAddress(values, actions);
              }}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                errors,
                touched,
                isSubmitting,
                handleReset,
                setFieldValue,
                setFieldError,
                setFieldTouched,
              }) => {
                console.log(errors);

                return (
                  <>
                    <Form onSubmit={handleSubmit} className="form-wrap">
                      <Row>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {
                                  Department_DeliveryAddress
                                    ?.BuildingOfficeModuleNo[language]
                                }
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="text"
                                name="building_no"
                                onChange={handleChange}
                                className={`${
                                  touched.building_no && errors.building_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="building_no"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {Department_DeliveryAddress?.PinCode[language]}
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="tel"
                                name="pincode"
                                onChange={(e) => {
                                  setFieldTouched("pincode");
                                  handleChange(e);
                                  fetchLocation(
                                    e.target.value,
                                    setFieldValue,
                                    setFieldError,
                                    setFieldTouched
                                  );
                                }}
                                className={`${
                                  touched.pincode && errors.pincode
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="pincode"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label className="large-screen-view">
                                {Department_DeliveryAddress?.City[language]}
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                title="Select City"
                                type="select"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                disabled={cities?.length == 0}
                                className={`${
                                  touched.city && errors.city
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value="">
                                  {
                                    Department_DeliveryAddress?.SelectCity[
                                      language
                                    ]
                                  }{" "}
                                </option>
                                {cities?.map((item: any, i: number) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <option value={item?.Name}>
                                        {item?.Name}
                                      </option>
                                    </React.Fragment>
                                  );
                                })}
                              </Input>
                              <ErrorMessage name="city" component={TextError} />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {Department_DeliveryAddress?.Landmark[language]}
                                {/* <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i> */}
                              </Label>
                              <Input
                                type="text"
                                name="landmark"
                                onChange={handleChange}
                                className={`${
                                  touched.landmark && errors.landmark
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="landmark"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {Department_DeliveryAddress?.District[language]}
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="text"
                                name="district"
                                value={values.district}
                                onChange={handleChange}
                                className={`${
                                  touched.district && errors.district
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="district"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {Department_DeliveryAddress?.State[language]}
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="text"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                className={`${
                                  touched.state && errors.state
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="state"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {Department_DeliveryAddress?.Country[language]}
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="text"
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                className={`${
                                  touched.country && errors.country
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="country"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {Department_DeliveryAddress?.EmailID[language]}
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="text"
                                name="email"
                                onChange={handleChange}
                                className={`${
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="email"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {Department_DeliveryAddress?.PhoneNo[language]}
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="text"
                                name="phone"
                                onChange={handleChange}
                                className={`${
                                  touched.phone && errors.phone
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="phone"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {
                                  Department_DeliveryAddress?.AlternatePhoneNo[
                                    language
                                  ]
                                }
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="text"
                                name="alt_phone"
                                onChange={handleChange}
                                className={`${
                                  touched.alt_phone && errors.alt_phone
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="alt_phone"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label>
                                {
                                  Department_DeliveryAddress?.WhatsappNo[
                                    language
                                  ]
                                }
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                type="text"
                                name="whatsapp_no"
                                onChange={handleChange}
                                className={`${
                                  touched.whatsapp_no && errors.whatsapp_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="whatsapp_no"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="custom-input">
                            <FormGroup className="form-group">
                              <Label className="large-screen-view">
                                {
                                  Department_DeliveryAddress?.AddressType[
                                    language
                                  ]
                                }
                                <i
                                  className="fa fa-asterisk required-field"
                                  color="text-danger"
                                  aria-hidden="true"
                                ></i>
                              </Label>
                              <Input
                                title="Select Address Type"
                                type="select"
                                name="address_type"
                                value={values.address_type}
                                onChange={handleChange}
                                className={`${
                                  touched.address_type && errors.address_type
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value="">
                                  {
                                    Department_DeliveryAddress
                                      ?.SelectAddressType[language]
                                  }
                                </option>
                                {ADDRESS_TYPE?.map((it: any, i: number) => (
                                  <option key={i} value={it?.VALUE}>
                                    {it?.TYPE}
                                  </option>
                                ))}
                              </Input>
                              <ErrorMessage
                                name="address_type"
                                component={TextError}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col className="footer-sticky">
                          <FormGroup className="text-end">
                            <Button
                              type="submit"
                              disabled={isSubmitting}
                              color="primary"
                            >
                              {Department_DeliveryAddress?.Submit[language]}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </ModalContainer>
      </div>
    </>
  );
}
