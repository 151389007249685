import { ErrorMessage, Formik } from 'formik';
import { AddAccountValidationSchema } from './AddAccountValidationSchema';
import { Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import TextError from '../../../Components/Errors/TextError';
import { api } from '../../../Library/CustomApi';
import { toast } from 'react-toastify';
import { useState,useContext } from 'react';
import { Admin_accountlist } from '../../../Library/LanguageConversion/Admin_accountlist';
import { Context } from '../../../Components/Context/Context';

interface propType {
    setOpenModal: Function,
    getAccounts:Function
}

const AddAccountModal = ({ setOpenModal, getAccounts }: propType) => {
    const { language, setLanguage } = useContext(Context);

    // formik initial values 
    const initialValues = {
        name: '',
        phone: '',
        email: '',
        role_id: '',
    };

    const AddAccountHandler = (values: any, actions: any) => {

        api
            .post(
                `/other_register`,
                {
                    phone: values?.phone,
                    email: values?.email,
                    full_name: values?.name,
                    role_id: values?.role_id
                },
                true
            )
            .then(async function ([success, response, status]) {
                if (status == 200) {
                    toast?.success('Account Created and invitation sent through Email')
                    getAccounts()
                    setOpenModal(false)
                    actions.setSubmitting(false);
                }
                return response;
            })
            .catch((err) => {
                if (err?.response?.status == 422) {
                    if (err?.response?.data?.errors?.length !== 0) {
                        actions.setFieldError('email', err?.response?.data?.errors?.email);
                        actions.setFieldError('phone', err?.response?.data?.errors?.phone);
                    }
                    toast.error('Error');
                    actions.setSubmitting(false);
                }
                actions.setSubmitting(false);
            });
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={AddAccountValidationSchema}
                onSubmit={(values, actions) => {
                    //submit
                    AddAccountHandler(values, actions)
                }}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    errors,
                    touched,
                    isSubmitting,
                    handleReset,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched,
                    setSubmitting
                }) => {

                    return (
                        <Form onSubmit={handleSubmit} className="form-wrap">
                            <Row>
                                <Col md={4}>
                                    <div className="custom-input">
                                        <FormGroup className="form-group">
                                            <Label>{Admin_accountlist?.Name[language]}
                                                <i
                                                    className="fa fa-asterisk required-field"
                                                    color="text-danger"
                                                    aria-hidden="true"
                                                ></i>
                                            </Label>
                                            <Input type="text" name="name" onChange={handleChange} className={`${touched.name && errors.name ? "is-invalid" : ""}`} />
                                            <ErrorMessage name="name" component={TextError} />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="custom-input">
                                        <FormGroup className="form-group">
                                            <Label>{Admin_accountlist?.Phone[language]}
                                                <i
                                                    className="fa fa-asterisk required-field"
                                                    color="text-danger"
                                                    aria-hidden="true"
                                                ></i>
                                            </Label>
                                            <Input type="text" name="phone" onChange={handleChange} className={`${touched.phone && errors.phone ? "is-invalid" : ""}`} />
                                            <ErrorMessage name="phone" component={TextError} />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="custom-input">
                                        <FormGroup className="form-group">
                                            <Label>{Admin_accountlist?.Email[language]}
                                                <i
                                                    className="fa fa-asterisk required-field"
                                                    color="text-danger"
                                                    aria-hidden="true"
                                                ></i>
                                            </Label>
                                            <Input type="text" name="email" onChange={handleChange} className={`${touched.email && errors.email ? "is-invalid" : ""}`} />
                                            <ErrorMessage name="email" component={TextError} />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="custom-input">
                                        <FormGroup className="form-group">
                                            <Label>{Admin_accountlist?.AccountType[language]}
                                                <i
                                                    className="fa fa-asterisk required-field"
                                                    color="text-danger"
                                                    aria-hidden="true"
                                                ></i>
                                            </Label>
                                            <Input
                                                title="Account Type"
                                                type="select"
                                                name="role_id"
                                                // value={}
                                                onChange={handleChange}
                                                className={`${touched.role_id && errors.role_id ? "is-invalid" : ""}`}
                                            >
                                                <option value="">{Admin_accountlist?.SelectAccountType[language]}</option>
                                                <option value="6">{Admin_accountlist?.Store[language]}</option>
                                                <option value="7">{Admin_accountlist?.Manager[language]} </option>
                                                <option value="8">{Admin_accountlist?.Accountant[language]}</option>
                                            </Input>
                                            <ErrorMessage name="role_id" component={TextError} />
                                        </FormGroup>
                                    </div>
                                </Col>

                                <Col md={12} className="footer-sticky">
                                    <FormGroup className="text-end">
                                        <Button
                                            type="submit"
                                            title="Submit"
                                            disabled={isSubmitting}
                                            color="primary"
                                        >
                                            {isSubmitting ? (
                                                <Spinner
                                                    as="span"
                                                    variant="light"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    animation="border"
                                                />)
                                                :
                                            Admin_accountlist?.Submit[language]}
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )
                }
                }
            </Formik>

        </>
    )
}

export default AddAccountModal