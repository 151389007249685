import React, { useEffect, useState } from "react";
import ModalContainer from "../../../../../Components/Modal/ModalContainer";
import constants from "../../../../../Library/Constants/Constants";
import moment from "moment";

const VendorDetailsModal = (props: any) => {

    console.log(props?.values, "jggrgijr")
    return (
        <React.Fragment>
            <ModalContainer
                open={props?.isShowing}
                setOpen={() => props?.setIsShowings(false)}
                header="Vendor Payment Details">
                <div className="feeback-outer-wrap pt-5">
                    <div className="feedback-main-heading">
                        <div className="feedback-wrap">
                            <table className="table table-hover table-bordered">
                                <tbody>
                                    {props?.values?.type == constants?.PAYMENT_TYPE?.BANK || props?.values?.type == constants?.PAYMENT_TYPE?.NEFT || props?.values?.type == constants?.PAYMENT_TYPE?.RTGS ?
                                        <>
                                            <tr>
                                                <td>
                                                    <h4>Account Holder Name</h4>
                                                </td>
                                                <td>{props?.values?.account_holder_name}</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h4>Bank Name</h4>
                                                </td>
                                                <td>{props?.values?.bank_name}</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h4>Account Number</h4>
                                                </td>
                                                <td>{props?.values?.account_number ? props?.values?.account_number : "-"}</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h4>Transaction Number</h4>
                                                </td>
                                                <td>{props?.values?.transaction_id ? props?.values?.transaction_id : "-"}</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h4>Payment Type </h4>
                                                </td>
                                                <td>{props?.values?.type == constants?.PAYMENT_TYPE?.BANK ? "BANK" : props?.values?.type == constants?.PAYMENT_TYPE?.NEFT ? "NEFT" : "RTGS"}</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h4>Branch</h4>
                                                </td>
                                                <td>{props?.values?.branch_name}</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h4>IFSC</h4>
                                                </td>
                                                <td>{props?.values?.ifsc_code}</td>
                                            </tr>


                                            <tr>
                                                <td>
                                                    <h4>Amount</h4>
                                                </td>
                                                <td>₹{props?.values?.amount}</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <h4>Date</h4>
                                                </td>
                                                <td>{moment(props?.values?.payment_date).format('DD-MM-YYYY')}</td>
                                            </tr>
                                        </>

                                        :

                                        props?.values?.type == constants?.PAYMENT_TYPE?.CHELLAN ?
                                            <>

                                                <tr>
                                                    <td>
                                                        <h4>Payment Type </h4>
                                                    </td>
                                                    <td>Demand Draft</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h4>Account Holder Name</h4>
                                                    </td>
                                                    <td>{props?.values?.account_holder_name}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h4>Bank Name</h4>
                                                    </td>
                                                    <td>{props?.values?.bank_name}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h4>Account Number</h4>
                                                    </td>
                                                    <td>{props?.values?.account_number ? props?.values?.account_number : "-"}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h4>Branch</h4>
                                                    </td>
                                                    <td>{props?.values?.branch_name}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h4>IFSC</h4>
                                                    </td>
                                                    <td>{props?.values?.ifsc_code}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h4>DD Number</h4>
                                                    </td>
                                                    <td>{props?.values?.challan_no}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h4>Amount</h4>
                                                    </td>
                                                    <td>₹{props?.values?.amount}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h4>Date</h4>
                                                    </td>
                                                    <td>{moment(props?.values?.payment_date).format('DD-MM-YYYY')}</td>
                                                </tr>
                                            </>
                                            :
                                            props?.values?.type == constants?.PAYMENT_TYPE?.CHEQUE ?

                                                <>
                                                    <tr>
                                                        <td>
                                                            <h4>Account Holder Name</h4>
                                                        </td>
                                                        <td>{props?.values?.account_holder_name}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <h4>Bank Name</h4>
                                                        </td>
                                                        <td>{props?.values?.bank_name}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <h4>Account Number</h4>
                                                        </td>
                                                        <td>{props?.values?.account_number ? props?.values?.account_number : "-"}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <h4>Payment Type </h4>
                                                        </td>
                                                        <td>CHEQUE</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <h4>Cheque Number</h4>
                                                        </td>
                                                        <td>{props?.values?.cheque_number}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <h4>Branch</h4>
                                                        </td>
                                                        <td>{props?.values?.branch_name}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <h4>IFSC</h4>
                                                        </td>
                                                        <td>{props?.values?.ifsc_code}</td>
                                                    </tr>


                                                    <tr>
                                                        <td>
                                                            <h4>Amount</h4>
                                                        </td>
                                                        <td>₹{props?.values?.amount}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <h4>Date</h4>
                                                        </td>
                                                        <td>{moment(props?.values?.payment_date).format('DD-MM-YYYY')}</td>
                                                    </tr>
                                                </>
                                                :
                                                null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </React.Fragment>
    );
};

export default VendorDetailsModal;
