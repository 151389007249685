import React, { useContext, useEffect } from "react";
import { useState } from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  FormGroup,
  Label,
  Row,
  Col,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Badge,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import moment from "moment";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import * as Yup from "yup";
import { toast } from "react-toastify";
import PaymentForVendors from "../../Delivered/Modal/PaymentToVendors";
import DatePicker from 'react-date-picker'
import { API_URL } from "../../../../Library/ApiSettings";
import OrderView2 from "../../Orders/Modal/OrderView2";
import { getUnique } from "../../../../Library/Constants/helpers";
import { Admin_Payment } from "../../../../Library/LanguageConversion/Admin_Payment";
import { Context } from "../../../../Components/Context/Context";
export default function PaymentToVendors() {
  const navigate = useNavigate();
  const {language,setLanguage} = useContext(Context)
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [payment_to_vendors, setPaymentToVendors] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [payment_data, setPaymentData] = useState<any>([]);
  const [modal1, setModal1] = useState(false);
  const [order_id, setOrderId] = useState<any>([]);
  const toggle1 = () => setModal1(!modal1);
  const [filter, setFilter] = useState<any>("");
  const [exportvalue, setExportValue] = useState("");
  const [order_view, setOrderView] = useState([]);
  const [graph_data, setGraphData] = useState<any>([]);
  const [pageData, setPageData] = useState<any>({
    total: "",
    per_page: "",
    currentPage: "",
    from: "",
    to: "",
  });
  const [date, setDate] = useState<any>({
    validfrom: "",
    validto: "",
  });
  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getPaymentToVendors();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage, filter, date?.validfrom, date?.validto]);

  const initialValues = {
    transaction: "",
    tds_deduction: 0,
  };

  const PaymentValidationSchema = Yup.object().shape({
    transaction: Yup.string()
      // .matches(/^\d{4}\d{4}\d{4}\d{4}$/, 'Invalid transaction number format')
      .required("Transaction number is required"),
  });

  const onSubmit = (values: any, actions: any) => {

    if (values?.tds_deduction !== 0) {
      api
        .post(
          `/order/store-vendor-payments`,
          {
            transaction_id: values?.transaction,
            amount: values?.tds_deduction,
            order_id: order_id,
          },
          true
        )
        .then(async function ([success, response, status]) {
          if (status == 200) {
            toast.success("Payment Successfull");
            actions.setSubmitting(false);
            toggle1();
            getPaymentToVendors();
          }
          return response;
        })
        .catch((err) => {
          actions.setSubmitting(false);
          console.log(err);
        });
    }
    else {
      toast?.error('Error Occured')
    }
  };

  const getPaymentToVendors = () => {
    let v_from: any = moment(date?.validfrom).format("YYYY-MM-DD");
    let v_to: any = moment(date?.validto).format("YYYY-MM-DD");
    if (v_from !== "Invalid date" && v_to !== "Invalid date") {
      setLoading(true);
      api
        .get(
          `/order/payment_after_doc_verify?status=${constants.ORDERS.DELIVERED}&page=${currentPage}&search_value=${search}&check=${filter}&valid_from=${v_from}&valid_to=${v_to}`,
          true
        )
        .then(async function ([success, response]) {
          setPaymentToVendors(response?.data || []);
          setPageData(response?.meta);
          setLoading(false);
          return response;
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(true);
      api
        .get(
          `/order/payment_after_doc_verify?status=${constants.ORDERS.DELIVERED}&page=${currentPage}&search_value=${search}&check=${filter}`,
          true
        )
        .then(async function ([success, response]) {
          setPaymentToVendors(response?.data || []);
          setPageData(response?.meta);
          setLoading(false);
          return response;
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  // const exportdata = () => {
  //   let v_from: any = moment(date?.validfrom).format("YYYY-MM-DD");
  //   let v_to: any = moment(date?.validto).format("YYYY-MM-DD");
  //   if (v_from !== "Invalid date" && v_to !== "Invalid date") {
  //   api
  //     .get(`/payment_export?start_date=${v_from}&end_date=${v_to}&search_keywords=${search}`, true)

  //       .then(response => response.blob())
  //       .then(blob => {
  //           const url = URL.createObjectURL(blob);
  //           const a = document.createElement('a');
  //           a.href = url;
  //           a.download = 'Invoice.pdf';
  //           document.body.appendChild(a);
  //           a.click();
  //           document.body.removeChild(a);
  //           URL.revokeObjectURL(url);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   }
  //   else{
  //     api
  //     .get(`/payment_export?search_keywords=${search}`, true)
  //     .then(async function ([success, response]) {
  //       return response;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   }
  // };
  const exportdata = () => {
    let v_from: any = moment(date?.validfrom).format("YYYY-MM-DD");
    let v_to: any = moment(date?.validto).format("YYYY-MM-DD");
    if (v_from !== "Invalid date" && v_to !== "Invalid date") {
      const requestOptions: any = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        },
      };
      fetch(
        `${API_URL}/payment_export?start_date=${v_from}&end_date=${v_to}&search_keywords=${search}&check=${filter}`,
        requestOptions
      )
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "PaymentData.xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        })
        .catch((e) => {
          console.log(e, ">>>");
        });
    } else {
      const requestOptions: any = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        },
      };
      fetch(
        `${API_URL}/payment_export?search_keywords=${search}&check=${filter}&export=${exportvalue || "1"
        }`,
        requestOptions
      )
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "PaymentData.xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        })
        .catch((e) => {
          console.log(e, ">>>");
        });
    }
  };

  const getPaymentData = (id: any) => {
    api
      .get(`/order/payment-to-vendors?order_id=${id}`, true)
      .then(async function ([success, response]) {
        setPaymentData(response?.message?.[0] || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-order-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message?.[0]);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const clearAll = () => {
    setDate({
      ...date,
      validfrom: "",
      validto: "",
    });
    setFilter("");
    setSearch("");
    getPaymentToVendors();
  };
  // Graph data
  const graphData = (id: any) => {
    api
      .post(`/order/get_order_timeline`, {
        order_id: id
      }, true)
      .then(async function ([success, response]) {

        setGraphData(getUnique(response?.message, "status") || [])
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Row className="gy-4">
        <Col lg={12}>
          <Form role="search" className="form-search-outer">
            <Row className="align-items-end gy-4">
              <Col lg="3" md="6" xs="6">
                <div className="valid-from">
                  <Label>{Admin_Payment?.ValidFrom?.[language]}</Label>

                  <DatePicker onChange={(e: any) => {
                    setCurrentPage(1);
                    setDate({
                      ...date,
                      validfrom: e,
                    });
                  }}
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={date?.validfrom}
                    name="validfrom"
                    className={"form-control"}

                  />
                </div>
              </Col>

              <Col lg="3" md="6" xs="6">
                <div className="valid-to">
                  <Label>{Admin_Payment?.ValidTo?.[language]}</Label>

                  <DatePicker
                    onChange={(e: any) => {
                      setCurrentPage(1);
                      setDate({
                        ...date,
                        validto: e,
                      });
                    }}
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    minDate={date?.validfrom}
                    disabled={date?.validfrom ? false : true}
                    value={date?.validto}
                    name="validfrom"
                    className={"form-control"}
                  //maxDate={new Date()} 
                  />
                </div>
              </Col>

              <Col lg="4" md="8" xs="8">
                <div className="form-selection">
                  <Input
                    type="select"
                    name="exportvalue"
                    value={exportvalue}
                    onChange={(e) => {
                      setExportValue(e.target.value);
                    }}
                    className="form-select"
                  >
                    <option value="1">{Admin_Payment?.ThisWeek?.[language]}</option>
                    <option value="2">{Admin_Payment?.ThisMonth?.[language]}</option>
                    <option value="3">{Admin_Payment?.ThisYear?.[language]}</option>
                  </Input>
                </div>
              </Col>

              <Col lg="2" md="4" xs="4">
                <div className="dwnld-reset">

                  {payment_to_vendors?.length !== 0 ? (
                    <div className="export-icon">
                      <Button
                        color="primary"
                        title="Download Payment Data"
                        onClick={() => {
                          exportdata();
                        }}
                      >
                        <i className="fa-solid fa-download"></i>
                      </Button>
                    </div>
                  ) : <div className="export-icon disabled">
                    <Button
                      color="primary"
                      className="disabled"
                      disabled
                    >
                      <i className="fa-solid fa-download"></i>
                    </Button>
                  </div>
                  }

                  <div className="search-btn-wrap">
                    <div className="reset-all">
                      <i title="Reset All"><a href="#" onClick={clearAll}>{Admin_Payment?.Reset?.[language]}</a></i>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col lg="8" md="6" xs="12">
              <div className="buttons-section-outer">
                <ButtonGroup className="buttons-payment-filter">
                  <Button
                    className={`${filter === ""
                      ? " pending-button-filter status-tabs btn btn-secondary active"
                      : "pending-button-filter status-tabs btn btn-secondary"
                      }`}
                    onClick={() => {
                      setCurrentPage(1);
                      setFilter("");
                    }}
                  >
                    {Admin_Payment?.All?.[language]}
                  </Button>
                  <Button
                    className={`${filter === "6"
                      ? " pending-button-filter status-tabs btn btn-secondary active"
                      : "pending-button-filter status-tabs btn btn-secondary"
                      }`}
                    onClick={() => {
                      setCurrentPage(1);
                      setFilter("6");
                    }}
                  >
                    {Admin_Payment?.Pending?.[language]}
                  </Button>
                  <Button
                    className={`${filter === "11"
                      ? "ompleted-button-filter status-tabs btn btn-secondary active"
                      : "completed-button-filter status-tabs btn btn-secondary"
                      }`}
                    onClick={() => {
                      setCurrentPage(1);
                      setFilter("11");
                    }}
                  >
                    {Admin_Payment?.Completed?.[language]}
                  </Button>
                </ButtonGroup>
              </div>
            </Col>

            <Col lg="4" md="6" xs="12">
              <div className="search-section">
                <InputGroup className="search-group">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Vendor Name/Order ID"
                    aria-label="Username"
                    aria-describedby="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyPress={(event) => {
                      var key = event.keyCode || event.which;
                      if (key === 13) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <InputGroupText title="Vendor Name/Order ID" className="search-icon" id="search">
                    <i className="fa-sharp fa-solid fa-magnifying-glass" />
                  </InputGroupText>
                </InputGroup>
              </div>
            </Col>
          </Row>

          <div className="table-wrap responsive-table">
            <Table className="w-100 table-striped">
              <thead>
                <tr>
                  <th>{Admin_Payment?.SlNo?.[language]}</th>
                  <th>{Admin_Payment?.OrderedDate?.[language]}</th>
                  <th>{Admin_Payment?.VendorName?.[language]}</th>
                  <th>{Admin_Payment?.OrderId?.[language]}</th>
                  <th>{Admin_Payment?.TenderID?.[language]}</th>
                  <th>{Admin_Payment?.Quantity?.[language]}</th>
                  <th>{Admin_Payment?.Price?.[language]}</th>
                  <th>{Admin_Payment?.Action?.[language]}</th>
                </tr>
              </thead>
              <tbody>
                {payment_to_vendors?.length !== 0 ? (
                  <>
                    {payment_to_vendors?.map((item: any, i: any) => {
                      return (
                        <React.Fragment key={i}>
                          <tr>
                            <td>
                              <span className="mobile-heading">{Admin_Payment?.SlNo?.[language]}:</span>
                              <span className="table-data">
                                {pageData?.from + i}
                              </span>
                            </td>
                            <td>
                              <span className="mobile-heading">
                              {Admin_Payment?.OrderedDate?.[language]}:
                              </span>
                              <span className="table-data">
                                {moment(item?.updated_at).format("DD-MM-YYYY")}
                              </span>
                            </td>

                            <td>
                              <span className="mobile-heading">
                              {Admin_Payment?.VendorName?.[language]}:
                              </span>
                              <span className="table-data">
                                {item?.cmpny_detail?.name}
                              </span>
                            </td>

                            <td>
                              <span className="mobile-heading">{Admin_Payment?.OrderId?.[language]}:</span>

                              <span className="table-data">
                                <Link
                                  to="#"
                                  className="primary-text"
                                  title="View Order Details"
                                  onClick={() => {
                                    toggle();
                                    getSingleOrder(item?.id);
                                    graphData(item?.id)
                                  }}
                                >
                                  {item?.vendor_po_no}
                                </Link>
                              </span>
                            </td>
                            <td>
                              <span className="mobile-heading">{Admin_Payment?.TenderID?.[language]}:</span>
                              <span className="table-data">
                                {item?.pf_invoice?.pf_details[0]?.tender_id}
                              </span>
                            </td>

                            <td>
                              <span className="mobile-heading">{Admin_Payment?.Quantity?.[language]}:</span>
                              <span className="table-data">
                                {" "}
                                {item?.order_details?.quantity}
                              </span>
                            </td>
                            <td>
                              <span className="mobile-heading">{Admin_Payment?.Price?.[language]}:</span>
                              <span className="table-data">
                                ₹{item?.total_amount}
                              </span>
                            </td>
                            <td className="payment-action">
                              <span className="mobile-heading">{Admin_Payment?.Action?.[language]}:</span>
                              <span className="table-data">
                                {item?.payment_detail?.status == "COMPLETED" ? (
                                  <span className="success-text">
                                    Payment Completed
                                  </span>
                                ) : (
                                  // <Button
                                  //   color="primary"
                                  //   className="rounded-pill"
                                  //   onClick={() => {
                                  //     setOrderId(item?.id);
                                  //     getPaymentData(item?.id);
                                  //     toggle1();
                                  //   }}
                                  // >
                                  //   Payment To Vendors
                                  // </Button>
                                  <Link to=""
                                    title="Payment to Vendors"
                                    className=""
                                    onClick={() => {
                                      setOrderId(item?.id);
                                      getPaymentData(item?.id);
                                      toggle1();
                                    }}>
                                    <Button color="warning">{Admin_Payment?.Payment_to_Vendors?.[language]}</Button>
                                  </Link>
                                )}
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td
                      className="text-center px-2 py-5 empty-text"
                      colSpan={8}
                    >
                      {loading ? (
                        <div className="d-flex justify-content-center">
                          <Loader />
                        </div>
                      ) : (
                        "No Payment found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <PaymentForVendors
            modal1={modal1}
            toggle1={toggle1}
            initialValues={initialValues}
            onSubmit={onSubmit}
            payment_data={payment_data}
            PaymentValidationSchema={PaymentValidationSchema}
            getPaymentData={getPaymentData}
          />

          {payment_to_vendors.length !== 0 && pageData && (
            <div className="table-footer-wrap">
              <small>
              {Admin_Payment?.Showing?.[language]} {pageData.from} {Admin_Payment?.to?.[language]} {pageData.to} {Admin_Payment?.of?.[language]} {pageData.total}{" "}
              {Admin_Payment?.entries?.[language]}
              </small>
              <div className="pagination">
                <CustomPagination
                  totalItems={pageData?.total}
                  itemsPerPage={pageData?.per_page}
                  activePage={pageData.currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
          <OrderView2
            order_view={order_view}
            modal={modal} toggle={toggle}
            loading={loading} graph_data={graph_data} />
        </Col>
      </Row>
    </React.Fragment>
  );
}
