import React, { useContext , useEffect} from 'react'
import TCEnglish from './EnglishComponents/TCEnglish';
import TCKanada from './KanadaComponents/TCKanada';
import { Context } from '../../Components/Context/Context';
import { useLocation } from 'react-router-dom';


export default function TC() {

    const location = useLocation();

    useEffect(() => {
        console.log(location?.pathname); 
        if (location?.pathname === "/terms-condition") {
          window.scrollTo({ top: 0 });
        }
      }, []);
    
    // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
    const { language, setLanguage } = useContext(Context);

    return (
        language == "en" ? <TCEnglish /> : <TCKanada />
    )
}
