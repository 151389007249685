export const CurrentTabLang:any = {
    dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    vendor_registration:{
        en:"Latest Registered Vendors", 
        ka:"ಇತ್ತೀಚಿನ ನೋಂದಾಯಿತ ಮಾರಾಟಗಾರರು"
    },
    products:{
        en:"Products",
        ka:"ಉತ್ಪನ್ನಗಳು"
    },
    tender:{
        en:"Tender",
        ka:"ಟೆಂಡರ್"
        },
    rate_contract:{
        en:"Rate Contract",
        ka:"ದರ ಒಪ್ಪಂದ"
    },
    orders:{
        en:"Orders",
        ka:"ಆರ್ಡರ್ಗಳು"
    },
    regular_orders:{
        en:"Regular Orders",
        ka:"ನಿಯಮಿತ ಆರ್ಡರ್ಗಳು"
    },
    custom_orders:{
        en:"Custom Orders",
        ka:"ಅನುಕೂಲಿತ ಆದೇಶಗಳು"
    },
    delivered:{
        en:"Delivered",
        ka:"ಬಂದಿದೆ"
    },
    tds:{
        en:"TDS",
        ka:"ಟಿಡಿಎಸ್"
    },
    payments:{
        en:"Payments",
        ka:"ಪಾವತಿಗಳು"
    },
    departments:{
        en:"Departments",
        ka:"ಇಲಾಖೆಗಳು"
    },
    services:{
        en:"Services",
        ka:"ಸೇವೆಗಳು"
    },
    tickets:{
        en:"Tickets",
        ka:"ಟಿಕೆಟ್ಗಳು"
    },
    amc:{
        en:"AMC",
        ka:"ಟಿಕೆಟ್ಗಳು"
    },
    third_party_inspection:{
        en:"Third Party Inspection",
        ka:"ಮೂರನೇ ವ್ಯಕ್ತಿಯ ತಪಾಸಣೆ"
    },
    verification:{
        en:"Verification",
        ka:"ಪರಿಶೀಲನೆ"
    },
    settings:{
        en:"settings",
        ka:"ಸಂಯೋಜನೆಗಳು"
    },
    reports:{
        en:"Reports",
        ka:"ವರದಿಗಳು"
    },
    overview:{
        en:"Overview",
        ka:"ಅವಲೋಕನ"
    },
    uploaded_rate:{
        en:"Uploaded Rate",
        ka:"ಅಪ್ಲೋಡ್ ಮಾಡಲಾದ ದರ"
    },
    rate_contract_assigned:{
        en:"Rate Contract Assigned",
        ka:"ದರ ಒಪ್ಪಂದವನ್ನು ನಿಗದಿಪಡಿಸಲಾಗಿದೆ"
    },
    self_registered_vendors:{
        en:"Self Registered Vendors",
        ka:"ಸ್ವಯಂ ನೋಂದಾಯಿತ ವೇಂದರ್ಸ್"
    },
    active_vendors:{
        en:"Empanelled Vendors",
        ka:"ಎಂಪನೆಲ್ಡ್ ಮಾರಾಟಗಾರರು"
    },
    inactive_vendors:{
        en:"Inactive Vendors",
        ka:"ನಿಷ್ಕ್ರಿಯ ವೇಂದರ್ಸ್"
    },
    verify_vendors:{
        en:"Vendor Verification",
        ka:"ಮಾರಾಟಗಾರರ ಪರಿಶೀಲನೆ"
    },
    expired_vendors:{
        en:"Expired Vendors",
        ka:"ಅವಧಿ ಮುಗಿದಿರುವ ವೆಂಡರ್ಸ್"
    },
    vendors_going_to_expire:{
        en:"Expiring Vendors",
        ka:"ಅವಧಿ ಮುಗಿಯುತ್ತಿರುವ ಮಾರಾಟಗಾರರ"
    },
    basic_details:{
        en:"Basic Details",
        ka:"ಮೂಲ ವಿವರಗಳು"
    },
    advanced_details:{
        en:"Advanced Details",
        ka:"ಸುಧಾರಿತ ವಿವರಗಳು"
    },
    document_verification:{
        en:"Document Verification",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್ ಪರಿಶೀಲನೆ"
    },
    open_tickets:{
        en:"Open Tickets",
        ka:"ತೆರೆದ ಟಿಕೆಟ್ಟುಗಳು"
    },
    closed_tickets:{
        en:"Closed Tickets",
        ka:"ಮುಚ್ಚಲಾದ ಟಿಕೆಟ್ಟುಗಳು"
    },
    pending_tickets:{
        en:"Closed Tickets",
        ka:"ಬಾಕಿಯಿರುವ ಟಿಕೆಟ್ಗಳು"
    },
    delivered_orders:{
        en:"Delivered Orders",
        ka:"ಬಾಕಿಯಿರುವ ಟಿಕೆಟ್ಗಳು"
    },
    cancelled_orders:{
        en:"Cancelled Orders",
        ka:"ಬಾಕಿಯಿರುವ ಟಿಕೆಟ್ಗಳು"
    },
    payment_received:{
        en:"Payment Received",
        ka:"ಪಾವತಿಯನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ"
    },
    payment_to_vendors:{
        en:"Payment to Vendors",
        ka:"ಮಾರಾಟಗಾರರಿಗೆ ಪಾವತಿ"
    },
    cancelled_payments:{
        en:"Cancelled Payments",
        ka:"ರದ್ದಾದ ಪಾವತಿಗಳು"
    },
    addr_verification:{
        en:"Address Verification",
        ka:"ವಿಳಾಸ ಪರಿಶೀಲನೆ"
    },
    dept_verification:{
        en:"Department Verification",
        ka:"ಇಲಾಖೆ ಪರಿಶೀಲನೆ"
    },
    draft_verification:{
        en:"Draft Verification",
        ka:"ಡ್ರಾಫ್ಟ್ ಪರಿಶೀಲನೆ"
    },
    account_verification:{
        en:"Account Verification",
        ka:"ಖಾತೆ ಪರಿಶೀಲನೆ"
    },
    notifications:{
        en:"Notifications",
        ka:"ಖಾತೆ ಪರಿಶೀಲನೆ"
    },
    specifications:{
        en:"Specifications",
        ka:"ವಿಶೇಷಣಗಳು"
    },
    cart:{
        en:"Cart",
        ka:"ಕಾರ್ಟ್‌"
    },
    draft_workorder:{
        en:"Draft / Work Order",
        ka:"ಕರಡು / ಕೆಲಸದ  ಆದೇಶ‌"
    },
    delivery_address:{
        en:"Delivery Address",
        ka:"ವಿತರಣಾ ವಿಳಾಸ‌"
    },
    profile:{
        en:"Profile",
        ka:"ಪ್ರೊಫೈಲ್"
    },
    profile_settings:{
        en:"Profile Settings",
        ka:"ಪ್ರೊಫೈಲ್ ಸೆಟ್ಟಿಂಗ್ಗಳು"
    },
    purchase_order:{
        en:"Purchase Order",
        ka:"ಖರೀದಿ ಆದೇಶ"
    },
    schedule_maintenance:{
        en:"Schedule Maintenance",
        ka:"ವೇಳಾಪಟ್ಟಿ ನಿರ್ವಹಣೆ"
    },
    feedback_summary:{
        en:"Feedback & Summary",
        ka:"ಪ್ರತಿಕ್ರಿಯೆ ಮತ್ತು ಸಾರಾಂಶ"
    },
    received_orders:{
        en:"Received Orders",
        ka:"ಪ್ಪಡೆದ ಆದೇಶಗಳು"
    },
    processed_orders:{
        en:"Processed Orders",
        ka:"ಪ್ರಕ್ರಿಯಾಜೀತ ಆದೇಶಗಳು"
    },
    outfordelivery_orders:{
        en:"Out For Delivery Orders",
        ka:"ವಿತರಣೆಗಾಗಿ ಹೊರಗಡೆಯಲ್ಲಿರುವ ಆದೇಶಗಳು"
    },
    payment_pending:{
        en:"Payment Pending",
        ka:"ಪಾವತಿ ಬಾಕಿ ಇದೆ"
    },
    documents:{
        en:"Documents",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್ಗಳು"
    },
    vendor_documents:{
        en:"Vendor Documents",
        ka:"ಮಾರಾಟಗಾರರ ದಾಖಲೆಗಳು"
    },
    account_details:{
        en:"Account Details",
        ka:"ಖಾತೆ ವಿವರಗಳು"
    },
    document_upload:{
        en:"Document Upload",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್ಲೋಡ್"
    },
    advanced_registration:{
        en:"Advanced Registration",
        ka:"ಸುಧಾರಿತ ನೋಂದಣಿ"
    },
    rejected_orders:{
        en:"Rejected Orders",
        ka:"ತಿರಸ್ಕರಿಸಿದ  ಆದೇಶಗಳು"
    },
    start_up_documents:{
        en:"Document Upload",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್ಲೋಡ್"
    },
    no_refund:{
        en:"Non-Refundable Loyalty Amount",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್ಲೋಡ್"
    },
    Confirmed_Orders: {
        en: 'Confirmed Orders',
        ka: 'ದೃ‌ಢೀ‌ಕರಿಸಲಾದ ಆದೇಶಗಳು'
    },
    Approved_Orders: {
        en: 'Approved Orders',
        ka: 'ಅನುಮೋದಿತ ಆರ್ಡರ್ಗಳು'
    },
    Processed_Orders: {
        en: 'Processed Orders',
        ka: 'ಸಂಸ್ಕರಿಸಿದ ಆದೇಶಗಳು'
    },
    Cancelled_Orders: {
        en: 'Cancelled Orders',
        ka: 'ರದ್ದುಪಡಿಸಿದ ಆದೇಶಗಳು'
    },
    Rejected_Orders: {
        en: 'Rejected Orders',
        ka: 'ತಿರಸ್ಕರಿಸಿದ  ಆದೇಶಗಳು'
    },
    Drafted_Orders: {
        en: 'Drafted Orders',
        ka: 'ಕರಡು ಆದೇಶಗಳು'
    }
}
