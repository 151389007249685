import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../Components/Context/Context";
import { Row, Col, Card, CardHeader, CardBody, Button, Input } from 'reactstrap';
import { api } from "../../../Library/CustomApi";
import Loader from "../../../Components/Loader/Loader";
import RadialChart from "../../Vendor/VendorCharts/RadialChart";
import { DeptRegLang } from "../../../Library/LanguageConversion/DepartmentLang";
import { DepartDash } from "../../../Library/LanguageConversion/Department_Dash";

export default function DeptUserDashboard() {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [latestProductsShow, setLatestProductsShow] = useState<boolean>(false)
  const [productsShow, setProductsShow] = useState<boolean>(false)
  const [productdata, setProductData] = useState<any>([])
  const [count, setCount] = useState(0);
  const [TicketFilter, setTicketFilter] = useState(1)
  const [TicketValues, setTicketValues] = useState([])

  const { language } = useContext(Context);

  useEffect(() => {
    getProductList()
    ticketGraph(TicketFilter)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount(count => count < productdata?.length - 1 ? count + 1 : 0);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [productdata]);

  // products data fetching 
  const getProductList = () => {
    setIsLoading(true);
    api
      .post(
        `/dashboard/recent_orders`,
        {},
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          // setProductData(response)
          setProductData(response.filter((obj: any) => obj.product_rate.length !== 0))
          setIsLoading(false)
        }
        return response;
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err);
      });
  }

  const TicketonChange = (e: any) => {
    setTicketFilter(e.target.value)
    ticketGraph(e.target.value)
  }

  const ticketGraph = (params: any) => {
    api
      .post(`/dashboard/ticket_graph_list`, { data: params }, true)
      .then(async function ([success, response]) {
        setTicketValues(response)
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <div className="right-content-wrap">
        <div className="dept-dashboard">
          {productdata?.length == 0 && !isLoading &&
            <div className="text-center mt-5">
              <p>{DepartDash?.DashboardAvailability[language]} </p>
            </div>}
          {isLoading && <div className="d-flex justify-content-center pt-5">
            <Loader />
          </div>}
          {productdata?.length !== 0 && !isLoading && <Row className="gx-3 gy-3">
            <Col md={12}>
              <Card className="top-banner-dashboard-wrap-outer">
                <CardBody>
                  <Row className="gy-4">
                    <Col md={8}>
                      <div className="top-banner-left">
                        <h2>{DeptRegLang?.top_banner_head[language]}<span className="product-name">{productdata.length !== 0 && productdata[count]?.name}</span></h2>
                        <small> {DepartDash?.BuyTheLatest[language]} {productdata.length !== 0 && productdata[count]?.name}. {DepartDash?.DiscoveraHugeCollectionOf[language]} {productdata.length !== 0 && productdata[count]?.name} {DepartDash?.AtGreatPrices[language]} </small>
                        <div className="top-banner-btn-wrap">
                          {productdata[count]?.id && <Link to={`/department/products-view/${productdata[count].id}`} >
                            <Button className=" btn btn-primary" color="primary">{DepartDash?.ShopNow[language]}</Button>
                          </Link>}
                          <Link to='/department/products'><Button className=" btn btn-outline" color="outline">{DepartDash?.ViewMore[language]}</Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="top-banner-right">
                        <div className="banner-image-wrap">
                          {productdata[count]?.product_image && productdata[count]?.product_image.length !== 0 &&
                            <img src={productdata[count]?.product_image.length !== 0 ? productdata[count]?.product_image
                            [0].path : ''} alt="img" />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={7}>
              <Card className="product-cards-sticky">
                <CardHeader>
                  <h5>{DeptRegLang?.products[language]}</h5>
                </CardHeader>
                <CardBody className="card-dashboard-products">
                  <div className="product-list-dashboard-outer">
                    <Row className="gx-2 gy-3">
                      {productdata?.length !== 0 &&
                        productdata?.map((obj: any, key: number) => {
                          return (
                            <React.Fragment key={key}>
                              {(!productsShow ? key < 8 : true) && <>
                                <Col md={4} key={key}>
                                  <Card className="product-inner-wrap" title="View Product">
                                    <Link to={`/department/products-view/${obj?.id}`}>
                                      <CardBody>
                                        <div className="product-dashboard-thumb">
                                          {obj?.product_image.length !== 0 && <img src={obj?.product_image?.length !== 0 ? obj?.product_image[0]?.path : ''} alt="" />}
                                        </div>
                                        <h6>{obj?.name}</h6>
                                      </CardBody>
                                    </Link>
                                  </Card>
                                </Col>
                              </>}
                            </React.Fragment>
                          )
                        })}
                      {productdata?.length > 8 && <Col md={4}>
                        <Card className="product-inner-wrap">
                          <Link to='' onClick={() => setProductsShow(!productsShow)}>
                            <CardBody>
                              <div className="product-dashboard-thumb">
                                {productsShow ? <img src="../images/icon-less.svg" alt="icon" className="show-less" /> : <img src="../images/icon-more.svg" alt="icon" className="show-more" />}
                              </div>
                              <h6>{DepartDash?.Show[language]} {productsShow ? 'Less' : 'All'}</h6>
                            </CardBody>
                          </Link>
                        </Card>
                      </Col>}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* tickets chart  */}
            <Col md={12} lg={5}>
              <Card className="dashboard-card-wrap ">
                <CardHeader>
                  <h6 className="heading">{DeptRegLang?.tickets[language]}</h6>
                  <Input type='select' name="" className="form-select w-auto" onChange={TicketonChange} defaultValue={1} >
                    <option value={1} >
                      {DepartDash?.ThisWeek[language]}
                    </option>
                    <option value={2} >
                      {DepartDash?.ThisMonth[language]}
                    </option>
                    <option value={3} >
                      {DepartDash?.ThisYear[language]}
                    </option>
                  </Input>
                </CardHeader>
                <CardBody>
                  <div className="growth-graph-wrap">
                    <RadialChart data={TicketValues} />
                    {/* <TicketsChart data={TicketValues} /> */}
                  </div>
                </CardBody>
              </Card>

              {/* latest products */}
              <Card className="latest-products-card">
                <CardHeader>
                  <h5>{DeptRegLang?.latest_products[language]}</h5>
                </CardHeader>
                <CardBody className="card-dashboard-products">
                  <div className="list-item-wrap-outer">
                    {productdata?.length !== 0 &&
                      productdata?.map((obj: any, key: number) => {
                        return (
                          <Fragment key={key}>
                            {(!latestProductsShow ? key < 6 : true) && <>
                              <Link title="View Product" className="list-item" to={`/department/products-view/${obj?.id}`}>
                                <div className="list-item-left">
                                  <div className="img-thumb">
                                    <img
                                      src={obj?.product_image?.length !== 0 ? obj?.product_image?.[0]?.path : ''}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div className="list-item-details">
                                    <h6>{obj?.name}</h6>
                                    <small dangerouslySetInnerHTML={{
                                      __html: obj?.features, 
                                    }}></small>
                                  </div>
                                </div>
                                <div className="list-item-right">
                                  <h5 className="price">
                                    {/* ₹{obj?.product_rate?.length !== 0 && obj?.product_rate?.[0]?.price} */}
                                    ₹{obj?.product_rate?.length !== 0 && (obj?.product_rate?.[0]?.price + (obj?.product_rate?.[0]?.price * 0.05))?.toFixed(2)}
                                    {/* {(prod?.rate[0]?.price + (prod?.rate[0]?.price * 0.05))?.toFixed(2)} */}
                                  </h5>
                                </div>
                              </Link>
                            </>}
                          </Fragment>
                        )
                      })
                    }
                    {productdata?.length > 6 && <Link className="show-all-button btn btn-block" to='' onClick={() => setLatestProductsShow(!latestProductsShow)}>
                      {DepartDash?.Show[language]} {latestProductsShow ? 'Less' : 'All'}
                    </Link>}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>}
        </div>
      </div>
    </>
  );
}
