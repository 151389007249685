import React, { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../Components/Context/Context";
import { DepartmentSettings } from "../../../Library/LanguageConversion/Department_Settings";

function DeptSettingsLayout(props:any) {
  const {language,setLanguage}=useContext(Context);

  const { pathname } = useLocation()
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <NavLink className='active'  to="/department/dashboard">
              {DepartmentSettings?.Dashboard?.[language]}
              </NavLink>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {
                  pathname == "/department/settings" ? "Profile Settings" :null
                }
              </b>
            </li>
          </List>
        </div>
     
            {props.children}
         
      </div>
    </React.Fragment>
  );
}
export default DeptSettingsLayout;

