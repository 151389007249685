import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRouteProps {
  loginStatus: boolean;
}

const VendorRoutes = (loginStatus: PrivateRouteProps) => {
  const [pmu, setPmu] = useState(true);
  
  useEffect(() => {
    if (localStorage.getItem("ROLE_ID") == "4") {
      setPmu(true);
    } else {
      setPmu(false);
    }
  }, [loginStatus]);
  const location = useLocation();

  return pmu ? (<Outlet />) :
    (
      <Navigate to="/login" replace state={{ from: location }} />
    );

    // this function is belongs to access vendor page only if vendor login else lead to login page
};

export default VendorRoutes;