import DepartmentLayout from "../Layouts/Department/DepartmentLayout/DepartmentLayout";
import AdminLayout from "../Layouts/Admin/AdminLayout/AdminLayout";
import VendorLayout from "../Layouts/Vendor/VendorLayout/VendorLayout";
import QAVendorLayout from "../Layouts/QAVendor/QAVendorLayout/QAVendorLayout";
import StoreLayout from "../Layouts/StoreAccountLayout/StoreAccountLayout";
import ManagerAccountLayout from "../Layouts/ManagerAccountLayout/ManagerAccountLayout";
import AccountantAccoutLayout from "../Layouts/AccountantAccountLayout/AccountantAccoutLayout";

interface PropType {
  children: React.ReactNode;
}

const Layout = (props: PropType) => {


  return (
    <>
      <div className="row m-0">
        {localStorage.getItem("ROLE_ID") == "2" ? (<AdminLayout>{props?.children}</AdminLayout>) : null}
        {localStorage.getItem("ROLE_ID") == "3" ? (<DepartmentLayout>{props?.children}</DepartmentLayout>) : null}
        {localStorage.getItem("ROLE_ID") == "4" ? (<VendorLayout>{props?.children}</VendorLayout>) : null}
        {localStorage.getItem("ROLE_ID") == "5" ? (<QAVendorLayout>{props?.children}</QAVendorLayout>) : null}

        {localStorage.getItem("ROLE_ID") == "6" ? (<StoreLayout>{props?.children}</StoreLayout>) : null}
        {localStorage.getItem("ROLE_ID") == "7" ? (<ManagerAccountLayout>{props?.children}</ManagerAccountLayout>) : null}
        {localStorage.getItem("ROLE_ID") == "8" ? (<AccountantAccoutLayout>{props?.children}</AccountantAccoutLayout>) : null}
      </div>
    </>
  );
};
export default Layout;