import { useState, useEffect } from 'react'
import { ErrorMessage } from 'formik';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import TextError from '../../../../../Components/Errors/TextError';

const EditVendorDetails = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleReset,
    editData,
    initialValues
}: any) => {
    const [edited, setEdited] = useState<boolean>(false)

    useEffect(() => {
        // to check values changed or not 
        if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
            setEdited(true)
        }
    }, [values])

    return (
        <>
            <div className="add-vendor-outer">
                <div className="vendor-details text-center">
                </div>
                <form className="form-wrap" onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6} sm={6}>
                            <FormGroup>
                                <label>Phone No</label>
                                <Input
                                    type="number"
                                    label="Phone No"
                                    name="phone"
                                    value={values?.phone}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                />
                                <ErrorMessage name="phone" component={TextError} />
                            </FormGroup>
                        </Col>

                        <Col md={6} sm={6}>
                            <FormGroup>
                                <label>Email ID</label>
                                <Input
                                    type="text"
                                    label="Email ID"
                                    name="email"
                                    value={values?.email}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage name="email" component={TextError} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className={"text-end"}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="px-5"
                                    disabled={!edited}
                                >
                                    Submit
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        </>
    )
}

export default EditVendorDetails