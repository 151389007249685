import React, { useState, useContext, useEffect } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";
import CustomPagination from "../../Components/CustomPagination/Index";
import {
  List,
  Input,
  Form,
  InputGroup,
  InputGroupText,
  Table,
  Button
} from 'reactstrap'
import { api } from '../../Library/CustomApi';
import constants from '../../Library/Constants/Constants';
import Loader from '../../Components/Loader/Loader';
import { Admin_orders } from '../../Library/LanguageConversion/Admin_orders';
import { Context } from '../../Components/Context/Context';
import moment from 'moment';


const StoreLanding = () => {
  const { language } = useContext(Context);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState<boolean>(true)
  const [pageData, setPageData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [orders, setOrders] = useState<any>([])

  useEffect(() => {
    getConfirmedOrder()
  }, [])

  // gets orders for approval 
  const getConfirmedOrder = () => {
    setLoading(true);
    api
      .get(
        `/order/get_icrr?status=${constants.ORDERS.CONFIRMED}&page=${currentPage}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setOrders(response?.data || []);
        setPageData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  function limitText(text: any, limit: any) {
    if (text?.length > limit) {
      return text.substring(0, limit) + "...";
    } else {
      return text;
    }
  }

  // const approve ICCR 
  const approveOrder = (status: 1 | 2, id: number) => {
    api
      .post(
        `/order/icrr_update`,
        {
          manager_status: status,
          id: id
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          console.log(response);
        }
        getConfirmedOrder()
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="right-content-wrap">
      <div className="breadcrumb-tabs-outer-wrap">
        <div className="custom-tabs">
          <NavLink to="">
            ICRR
          </NavLink>
          {/* <NavLink to="">
              Tab2
            </NavLink> */}
        </div>
        <List className="breadcrumb">
          <li>
            <Link className="active" to="/dashboard">Dashboard</Link>
          </li>
          &nbsp; / &nbsp;
          <li>
            <b>{pathname == "/store/landing" ? "Landing" : "Landing"}</b>
          </li>
        </List>
      </div>
      <div className="custom-tab-content">
        <div className="table-outer-wrap">
          <div className="search-widget-outer">
            <Form role="search">
              <InputGroup title="Search here" className="search-group">
                <InputGroupText className="search-icon" id="search">
                  <i className="fa-sharp fa-solid fa-magnifying-glass" />
                </InputGroupText>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  aria-label="Username"
                  aria-describedby="search"
                />
              </InputGroup>
              <div className="add-button">
                <Link title="Add" to="">
                  <Button className="add-vendor primary-btn" color="primary">
                    {/* Desktp add button */}
                    <span className="large-screen-view">
                      <i className="bi bi-plus"></i>
                      Add Button
                    </span>
                    {/* mobile add button */}
                    <span className="mobile-view">
                      <i className="bi bi-plus-lg">
                      </i>
                    </span>
                  </Button>
                </Link>
              </div>
            </Form>
          </div>

          <div className="table-wrap responsive-table">
            <Table className="w-100 table-striped">
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Order Id</th>
                  <th>Document</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders?.length !== 0 ?
                  orders?.map((it: any, i: number) => {

                    return (
                      <tr className="order-relative">
                        <td>
                          <span className="mobile-heading">
                            Sl No:
                          </span>
                          <span className="table-data">
                            {pageData?.from + i || i + 1}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            Order Id:
                          </span>
                          <span className="table-data">
                            {it?.invoice_number}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            Document:
                          </span>
                          <span className="table-data">
                            <a href={it?.icrr_doc} target='_blank'>View</a>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            Actions:
                          </span>
                          <span className="table-data">
                            {
                              ((!it?.manager_status) || it?.manager_status == 0) ?
                                <Button onClick={() => approveOrder(1, it?.id)}>Approve From Manager</Button>
                                :
                                it?.manager_status == 1 ? <span>Approved</span>
                                  : null}
                          </span>
                        </td>
                      </tr>
                    )
                  })
                  : (
                    <tr>
                      <td className="text-center px-2 py-5 empty-text" colSpan={8}>
                        {loading ? (
                          <div className="d-flex justify-content-center">
                            <Loader />
                          </div>
                        ) : (
                          "No Orders Found"
                        )}
                      </td>
                    </tr>
                  )}
              </tbody>
            </Table>
          </div>

          {pageData && orders?.length != 0 && (
            <div className="table-footer-wrap">
              <small>
                {Admin_orders?.Showing[language]} {pageData.from} to {pageData.to}{" "}
                of {pageData.total} {Admin_orders?.entries[language]}
              </small>
              <div className="pagination">
                <CustomPagination
                  totalItems={pageData?.total}
                  itemsPerPage={pageData?.per_page}
                  activePage={pageData.current_page}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StoreLanding