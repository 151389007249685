import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const SignupValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).required('Phone number must be atleast 10 digits'),
    department: Yup.string().required("Department name is required"),

    otp:Yup.string().required('OTP verification required').length(6,'OTP Must be exactly 6 digits'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password1: Yup.string().required('Password is required').min(8,'Password must be at least 8 characters'),
    password2: Yup.string().required('Confirm password').oneOf([Yup.ref('password1'), null], "Password doesn't match")
});