import React from 'react'
import { Link } from 'react-router-dom'

export default function PPEnglish() {
    return (
        <div>
            <section>
                <div className="privacy-container-fluid">
                    <div className="privacy-banner-outer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="privacy-banner-text-wrap">
                                        <h1>
                                            Privacy Policy
                                        </h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to={"/home"}>Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container py-5">
                <div className="privacy-wrap">
                    <div className="WordSection1">

                        <p className="MsoNormal" ><span lang="EN">This Privacy Policy 
                        explains how [Keonics] collects, uses, and protects personal information
                        obtained from users of our <Link target="_blank" to="https://ebuy.keonics.in/">https://ebuy.keonics.in/</Link>&nbsp; At KEONICS, we offer
                        a range of services to meet the diverse needs of our clients. Our services
                        include IT training, networking solutions, software development, web solutions,
                        data entry, data warehousing, and e-procurement solutions. We provide
                        comprehensive IT training through our centers, Yuva.Com Centers, and Franchisee
                        Centers, focusing on strengthening the IT community and catering to market
                        requirements. Our well-trained and experienced manpower delivers computer
                        networking and network maintenance solutions across Karnataka, ensuring
                        efficient operations and security. With our in-house expertise, we develop
                        application software and have successfully executed numerous projects in this
                        domain. Our dedicated Web solutions division creates, hosts, and maintains
                        websites, including those for government departments. Additionally, we handle
                        large-scale data entry and data warehousing projects for various organizations.
                        Through our e-procurement solution, including e-tendering and works management
                        software, we bring automation and enhanced transparency to goods procurement processes
                        for government departments. We are committed to protecting the privacy of our
                        users, and this privacy policy outlines how we collect, use, store, and protect
                        personal information in accordance with applicable privacy laws and
                        regulations. By using the Service, you agree to the terms of this Policy.</span></p>

                        <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>

                        <h4><span lang="EN">1. Information
                        We Collect</span></h4>
                        <p className="MsoNormal" ><span lang="EN">1.1. Personal
                        Information: We may collect personal information such as your name, email&nbsp;&nbsp;
                        address, contact information, and any other information you voluntarily provide
                        to us.</span></p>

                        <p className="MsoNormal" ><span lang="EN">1.2. Usage Data: We
                        may automatically collect certain information about your interaction with the
                        Service, including your IP address, browser type, device information, pages
                        visited, and referring URL.</span></p>

                        <h4><span lang="EN">2. Use of
                        Information</span></h4>

                        <p className="MsoNormal" ><span lang="EN">2.1. We use the
                        collected information to provide and improve our Service, communicate with you,
                        respond to inquiries, personalize your experience, and send you promotional
                        materials, subject to your preferences.</span></p>

                        <p className="MsoNormal" ><span lang="EN">2.2. We may share
                        your information with third-party service providers who assist us in operating
                        our Service, conducting our business, or providing services to you. These
                        service providers are obligated to protect your information and use it only for
                        the purposes we specify.</span></p>

                        <h4><span lang="EN">3. Cookies and
                        Tracking Technologies</span></h4>
                        <p className="MsoNormal" ><span lang="EN">3.1. We use cookies
                        and similar tracking technologies to enhance your experience, analyze usage
                        patterns, and collect information about how you interact with the Service. You
                        can modify your browser settings to refuse cookies or alert you when cookies
                        are being sent, but this may impact your ability to use certain features of the
                        Service.</span></p>

                        <h4><span lang="EN">4. Data
                        Retention</span></h4>
                        <p className="MsoNormal" ><span lang="EN">4.1. We retain
                        personal information for as long as necessary to fulfill the purposes outlined
                        in this Policy unless a longer retention period is required or permitted by
                        law. We will securely delete or anonymize personal information when it is no
                        longer needed.</span></p>

                        <h4><span lang="EN">5. Data Security</span></h4>
                        <p className="MsoNormal" ><span lang="EN">5.1. We implement
                        reasonable security measures to protect your personal information from
                        unauthorized access, disclosure, alteration, or destruction. However, no method
                        of transmission over the internet or electronic storage is completely secure,
                        and we cannot guarantee absolute security.</span></p>

                        <h4><span lang="EN">6. Children's
                        Privacy</span></h4>
                        <p className="MsoNormal" ><span lang="EN">6.1. Our Service is
                        not directed to individuals under the age of 13. We do not knowingly collect
                        personal information from children under 13. If you believe we have
                        inadvertently collected information from a child under 13, please contact us,
                        and we will promptly delete it.</span></p>

                        <h4><span lang="EN">7. Your Rights</span></h4>                        
                        <p className="MsoNormal" ><span lang="EN">7.1. You have the
                        right to access, modify, or delete your personal information held by us. You
                        may also have the right to restrict or object to certain processing activities.
                        Please contact us to exercise these rights.</span></p>

                        <h4><span lang="EN">8. Changes to
                        this Policy</span></h4>
                        <p className="MsoNormal" ><span lang="EN">8.1. We reserve the
                        right to update or modify this Policy at any time. We will notify you of any
                        material changes by posting the revised Policy on our website or through other
                        reasonable means. Your continued use of the Service after the changes indicates
                        your acceptance of the updated Policy.</span></p>

                        <h4><span lang="EN">9. Contact Us</span></h4>
                        <p className="MsoNormal" ><span lang="EN">9.1. If you have
                        any questions or concerns about this Policy or our privacy practices, please
                        contact us at <Link to="" style={{pointerEvents:'none' , textDecoration:'none'}}>info@keonics.com</Link>.</span></p>
                    </div>
                 </div>
                </div>
            </section>
        </div>
    )
}