import React from 'react'
import { Dna } from 'react-loader-spinner'

function Loader() {
  return (
    <Dna
    visible={true}
    height="80"
    width="80"
    ariaLabel="dna-loading"
    wrapperStyle={{}}
    wrapperClass="dna-wrapper"
  />
  )
}

export default Loader