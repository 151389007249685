import axios from "axios";
import { ErrorMessage } from "formik";
import moment from "moment";
import React, { useEffect, useState,useContext } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
} from "reactstrap";
import TextError from "../../../Components/Errors/TextError";
import { Value } from "sass";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";

export default function SameAddressModal(props: any) {
  const { language, setLanguage } = useContext(Context);
  const [data, setData] = useState([]);
  const [check, setCheck] = useState(false);
  const [pincode, setPincode] = useState("");
  const getCheck = (checked: any) => {
    setCheck(checked);

    if (checked == true) {
      props.values.landmark = props?.all?.landmark;
      setPincode(props?.all?.pincode);
      fetchLocation(props?.all?.pincode);
      if (!props?.all?.pincode) {
        props?.setIsShowing(false);
        toast.info("Please Make Sure Company Registered Address Added?");
        setCheck(false);
      } else {
        props?.setFieldValue("building_number", props?.all?.building_number);
        props?.setFieldValue("building_address", props?.all?.building_address);
      }
      props?.setFieldTouched("city", false, false);
      props?.setFieldTouched("check", false, false);
      props?.setFieldTouched("country", false, false);
      props?.setFieldTouched("district", false, false);
      props?.setFieldTouched("address", false, false);
      props?.setFieldTouched("state", false, false);
      props?.setFieldTouched("pincode", false, false);
      props?.setFieldTouched("landmark", false, false);
      props?.setFieldTouched("building_address", false, false);
      props?.setFieldTouched("building_number", false, false);
      localStorage?.setItem("SAME", "true");
    } else {
      setData([]);
      props?.setFieldValue("address", "");
      props?.setFieldTouched("check", "");
      props?.setFieldValue("city", "");
      props?.setFieldValue("pincode", "");
      props?.setFieldValue("landmark", "");
      props?.setFieldValue("country", "");
      props?.setFieldValue("district", "");
      props?.setFieldValue("state", "");
      props?.setFieldValue("building_number", "");
      props?.setFieldValue("building_address", "");
      localStorage?.removeItem("SAME");
    }

    props?.setFieldValue("company_mse", props?.values?.company_mse);
    props?.setFieldValue("valid_upto", props?.data?.valid_upto);
    props?.setFieldValue("designation", props?.data?.designation);
    props?.setFieldValue("sc_st_entrepreneur", props?.data?.sc_st_entrepreneur);
    props?.setFieldValue("woman_entrepreneur", props?.data?.woman_entrepreneur);
  };

  const fetchLocation = (pinCode: any) => {
    console.log("ahcgash", pinCode);

    axios
      .get(`https://api.postalpincode.in/pincode/${pinCode || pincode}`)
      .then((response: any) => {
        let v = response?.data[0]?.PostOffice;
        let arr: any = [];
        v?.map((item: any, i: any) => {
          arr?.push({
            value: i,
            label: item?.Name,
            city: item?.Block,
            state: item?.State,
            // landmark: item?.Region,
            country: item?.Country,
            district: item?.District,
            pincode: item?.Pincode,
          });
        });
        setData(arr || []);

        props?.setFieldValue("city", arr[0]?.city);
        props?.setFieldValue("pincode", arr[0]?.pincode);
        // props?.setFieldValue("landmark", arr[0]?.landmark)
        props?.setFieldValue("country", arr[0]?.country);
        props?.setFieldValue("district", arr[0]?.district);
        props?.setFieldValue("address", arr[0]?.label);
        props?.setFieldValue("state", arr[0]?.state);
        props?.setFieldValue("check", true);
        props?.setFieldTouched("city", false, false);
        props?.setFieldTouched("country", false, false);
        props?.setFieldTouched("district", false, false);
        props?.setFieldTouched("address", false, false);
        props?.setFieldTouched("state", false, false);
      })

      .catch((error) => {
        props?.setFieldValue("pincode", "");
        toast.error("Please enter a valid Pincode");
      });
  };
  return (
    <>
      <React.Fragment>
        <ModalContainer
          open={props?.isShowing}
          setOpen={() => {
            props?.setIsShowing(false);
            // props?.handleReset();
          }}
          header="Add Company Registered Address"
        >
          <div className="add-address-wrap">
            <div className="feeback-outer-wrap">
              <div className="feedback-wrap">
                <div className="feedback-details ">
                  <Form className="form-wrap">
                    <Row>
                      <Col md={12}>
                        <div className="custom-input">
                          <FormGroup>
                            <Label> {Vendor_profileUpdation?.Same_As_Registered?.[language]} </Label>
                            {check == true ? (
                              <Input
                                type="checkbox"
                                name="same"
                                value={props?.values?.same}
                                onClick={(e: any) => {
                                  getCheck(e.target.checked);
                                }}
                                checked={true}
                              ></Input>
                            ) : (
                              <Input
                                type="checkbox"
                                name="same"
                                value={props?.values?.same}
                                onClick={(e: any) => {
                                  getCheck(e.target.checked);
                                }}
                              ></Input>
                            )}
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={6} className="mt-3">
                        <div className="custom-input">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.Building_Number?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Input
                              type="text"
                              name="building_number"
                              value={props?.values?.building_number}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.building_number &&
                                props?.errors?.building_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check}
                            ></Input>
                            <ErrorMessage
                              name="building_number"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={6} className="mt-3">
                        <div className="custom-input">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.Building_Address?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Input
                              type="text"
                              name="building_address"
                              value={props?.values?.building_address}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.building_address &&
                                props?.errors?.building_address
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check}
                            ></Input>
                            <ErrorMessage
                              name="building_address"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>

                      <Col md={6} className="mt-3">
                        <div className="custom-input">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.Landmark?.[language]}{" "}
                              <i
                                // className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Input
                              type="text"
                              name="landmark"
                              onChange={props?.handleChange}
                              value={props?.values?.landmark}
                              className={`form-control ${
                                props?.touched.landmark &&
                                props?.errors?.landmark
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check}
                            ></Input>

                            <ErrorMessage
                              name="landmark"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>

                      <Col md={6} className="mt-3">
                        <div className="custom-input">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.Pincode?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Input
                              type="tel"
                              name="pincode"
                              onChange={(e) => {
                                props?.handleChange(e);
                                fetchLocation(e.target.value);
                              }}
                              value={props?.values.pincode}
                              className={`form-control ${
                                props?.touched.pincode && props?.errors?.pincode
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check}
                            ></Input>
                            <ErrorMessage
                              name="pincode"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={6} className="mt-3">
                        <div className="custom-input">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.Select_Location?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>

                            <Input
                              type="select"
                              name="address"
                              value={props?.values.address}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.address && props?.errors?.address
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check}
                              //disabled
                            >
                              <option value="">{Vendor_profileUpdation?.Select?.[language]}</option>
                              {data?.map((item: any) => {
                                return (
                                  <>
                                    <option value={item?.label}>
                                      {item?.label}
                                    </option>
                                  </>
                                );
                              })}
                            </Input>
                            <ErrorMessage
                              name="address"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>

                      <Col md={6} className="mt-3">
                        <div className="custom-input">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.City?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Input
                              type="text"
                              name="city"
                              onChange={props?.handleChange}
                              value={props?.values.city}
                              className={`form-control ${
                                props?.touched.city && props?.errors?.city
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check}
                            ></Input>

                            <ErrorMessage name="city" component={TextError} />
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={6} className="mt-3">
                        <div className="custom-input ">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.District?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Input
                              type="text"
                              name="district"
                              value={props?.values.district}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.district &&
                                props?.errors?.district
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check}
                            ></Input>
                            <ErrorMessage
                              name="district"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={6} className="mt-3">
                        <div className="custom-input">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.State?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Input
                              type="text"
                              name="state"
                              value={props?.values.state}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.state && props?.errors?.state
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check == true ? true : false}
                            ></Input>
                            <ErrorMessage name="state" component={TextError} />
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={6} className="mt-3">
                        <div className="custom-input">
                          <FormGroup>
                            <Label>
                            {Vendor_profileUpdation?.Country?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Input
                              type="text"
                              name="country"
                              value={props?.values.country}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.country && props?.errors?.country
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={check}
                            ></Input>
                            <ErrorMessage
                              name="country"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>

                      <Col sm={12} className="text-end">
                        <Button
                          class="btn btn-primary mb-5 mt-5"
                          color="primary"
                          type="submit"
                          onClick={props?.handleSubmit}
                        >
                          {Vendor_profileUpdation?.Submit?.[language]}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </ModalContainer>
      </React.Fragment>
    </>
  );
}
