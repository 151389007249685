export const Vendor_Services:any={
    // Vendor/Services/ServicesTab
    Open_Tickets: {
        en: 'Open Tickets',
        ka: 'ಟಿಕೆಟ್ ತೆರೆಯಿರಿ'
    },
    Pending_Tickets: {
        en: 'Pending Tickets',
        ka: 'ಬಾಕಿ ಇರುವ ಟಿಕೆಟ್‌ಗಳು'
    },
    Closed_Tickets: {
        en: 'Closed Tickets',
        ka: 'ಮುಚ್ಚಿದ ಟಿಕೆಟ್‌ಗಳು'
    },
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
    },
    // Vendor/Services/TicketModal
    Ticket_Status: {
        en: 'Ticket Status',
        ka: 'ಟಿಕೆಟ್ ಸ್ಥಿತಿ'
    },
    Select_Order_Status: {
        en: 'Select Order Status',
        ka: 'ಆರ್ಡರ್ ಸ್ಥಿತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    Resolved_Date: {
        en: 'Resolved Date',
        ka: 'ಪರಿಹರಿಸಿದ ದಿನಾಂಕ'
    },
    Resolve_start_Date : {
        en: 'Resolve start Date ',
        ka: 'ಪ್ರಾರಂಭ ದಿನಾಂಕವನ್ನು ಪರಿಹರಿಸಿ'
    },
    Resolve_end_Date: {
        en: 'Resolve end Date',
        ka: 'ಅಂತಿಮ ದಿನಾಂಕವನ್ನು ಪರಿಹರಿಸಿ'
    },
    How_many: {
        en: 'How many days taken?',
        ka: 'ಎಷ್ಟು ದಿನ ತೆಗೆದುಕೊಂಡಿತು?'
    },
    comments: {
        en:"Comments",
        ka:"ಟಿಪ್ಪಣಿಗಳು"
    },

    // Vendor/Services/TicketVendor
    Order_ID: {
        en: 'Order ID',
        ka: 'ಆರ್ಡರ್ ಐಡಿ'
    },
    Tender_ID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    Order_Details: {
        en: 'Order Details',
        ka: 'ಆದೇಶದ ವಿವರಗಳು'
    },
    Shipping_Address: {
        en: 'Shipping Address',
        ka: 'ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ'
    },
    Quantity: {
        en: 'Quantity',
        ka: 'ಪ್ರಮಾಣ'
    },
    Department: {
        en: 'Department',
        ka: 'ಇಲಾಖೆ'
    },
   
    Water_Authority: {
        en: 'Water Authority',
        ka: 'ಜಲ ಪ್ರಾಧಿಕಾರ'
    },
    showing: {
        en:"Showing",
        ka:"ತೋರಿಸಲಾಗುತ್ತಿದೆ"
    },
    to: {
        en:"to",
        ka:"ಗೆ"
    },
    of: {
        en:"of",
        ka:"ನ"
    },
    entries: {
        en:"entries",
        ka:"ನಮೂದುಗಳು"
    },
    


}