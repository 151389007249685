import React, { useEffect,useContext } from "react";
import { useState } from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledAccordion,
  AccordionBody,
  AccordionItem,
  AccordionHeader
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import moment from "moment";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import OrderView from "../../Orders/Modal/OrderView";
import { Admin_Payment } from "../../../../Library/LanguageConversion/Admin_Payment";
import { Context } from "../../../../Components/Context/Context";
export default function PaymentReceived() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [payment_recieved, setPaymentReceived] = useState([]);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [order_view, setOrderView] = useState([]);
  const [pageData, setPageData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number | string>(1)
  const {language,setLanguage} = useContext(Context)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getPaymentReceived();
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);

  const handleSearch = (event: any) => {
    setCurrentPage(1)
    setSearch(event.target.value);
  };

  const getPaymentReceived = () => {
    setLoading(true);
    api
      .get(
        `/order/pf-invoice?status=${constants.ORDERS.RECEIVED}&search_value=${search}&page=${currentPage}`,
        true
      )
      .then(async function ([success, response]) {
        setPaymentReceived(response?.data || []);
        setPageData(response?.meta);
        // setPageData({
        //   ...pageData,
        //   total: response?.data?.total,
        //   per_page: response?.data?.per_page,
        //   currentPage: response?.data?.current_page,
        //   from: response?.data?.from,
        //   to: response?.data?.to,
        // });
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ============================================================ get Single Order View =====================================================
  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-proforma-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message?.[0]);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };


  function limitText(text: any, limit: any) {
    if (text?.length > limit) {
      return text.substring(0, limit) + "...";
    } else {
      return text;
    }
  }


  return (
    <React.Fragment>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText title="Product Name/Order ID" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={Admin_Payment?.ProductName_order?.[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
               <th>{Admin_Payment?.SlNo?.[language]}</th>
              <th>{Admin_Payment?.OrderedDate?.[language]}</th>
              <th>{Admin_Payment?.OrderId?.[language]}</th>
              <th>{Admin_Payment?.OrderDetails?.[language]}</th>
              <th>{Admin_Payment?.TenderID?.[language]}</th>
              <th>{Admin_Payment?.Quantity?.[language]}</th>
              <th>{Admin_Payment?.Price?.[language]}</th>
              <th>{Admin_Payment?.Department?.[language]}</th>
            </tr>
          </thead>
          <tbody>
            {payment_recieved?.length !== 0 ? (
              <>
                {payment_recieved?.map((item: any, i: any) => {
                  let count = 0;
                  return (
                    <React.Fragment key={i}>
                      <tr className="order-relative">
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.SlNo?.[language]}:</span>
                          <span className="table-data">
                            {pageData?.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.OrderedDate?.[language]}:</span>
                          <span className="table-data">
                            {moment(item?.created_at).format("DD-MM-YYYY")}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.OrderId?.[language]}:</span>
                          <span className="table-data">
                            <Link
                              to="#"
                              className="primary-text"
                              title="View Order Details"
                              onClick={() => {
                                toggle();
                                getSingleOrder(item?.id);
                              }}
                            >
                              {item?.order_id}
                            </Link>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.OrderDetails?.[language]}:</span>
                          <span className="table-data">
                            {item?.order_details?.length > 1 ? (
                              <>
                                <UncontrolledAccordion defaultOpen="0" className="order-list-accordion">
                                  <AccordionItem>
                                    <AccordionHeader targetId="1" className="">
                                    {Admin_Payment?.More_than_one_order?.[language]}. <span>{Admin_Payment?.Click_to_see_the_Orderlist?.[language]}.</span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1" className="">
                                      <AccordionHeader targetId="1" className="close-btn">
                                        <span className="close-btn-inner" title="Close">
                                          <i className="bi bi-x-lg"></i>
                                        </span>
                                      </AccordionHeader>
                                      <div className="order-list-outer-wrap">
                                        {item?.order_details?.map((obj: any, x: number) => {
                                          count = count + obj.quantity;
                                          return (
                                            <React.Fragment key={x}>
                                              <div className="product-details" title={obj?.name}>
                                                <strong>{limitText(obj?.name, 15)}</strong>
                                                <p className="sub-details">
                                                 {Admin_Payment?.Unit_Price?.[language]}:{" "}
                                                  <span>
                                                    <i className="bi bi-currency-rupee"></i>
                                                    {obj?.price}
                                                  </span>
                                                </p>
                                                <p className="sub-details">
                                                {Admin_Payment?.Quantity?.[language]}: <span>{obj?.quantity}</span>
                                                </p>
                                              </div>
                                            </React.Fragment>
                                          );
                                        })}
                                      </div>
                                    </AccordionBody>
                                  </AccordionItem>
                                </UncontrolledAccordion>
                              </>
                            ) : null}

                            {item?.order_details?.length == 1 ? (
                              <>
                                {item?.order_details?.map((obj: any, x: number) => {
                                  count = count + obj.quantity;
                                  return (
                                    <React.Fragment key={x}>
                                      <div className="product-details" title={obj?.name}>
                                        <strong>{limitText(obj?.name, 15)}</strong>
                                        <p className="sub-details">
                                        {Admin_Payment?.Unit_Price?.[language]}:{" "}
                                          <span>
                                            <i className="bi bi-currency-rupee"></i>
                                            {obj?.price}
                                          </span>
                                        </p>
                                        <p className="sub-details">
                                        {Admin_Payment?.Quantity?.[language]}: <span>{obj?.quantity}</span>
                                        </p>
                                      </div>

                                      {item?.order_details.length > 1 ? (
                                        <hr />
                                      ) : null}
                                    </React.Fragment>
                                  );
                                })}
                              </>
                            ) : null}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.TenderID?.[language]}:</span>
                          <span className="table-data">
                            {item?.order_details[0]?.tender_id}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.Quantity?.[language]}:</span>
                          <span className="table-data">{count}</span>
                        </td>
                        <td className="">
                          <span className="mobile-heading">{Admin_Payment?.Price?.[language]}:</span>
                          <span className="table-data primary-text">
                            ₹{item?.grand_amount}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Payment?.Department?.[language]}:</span>
                          <span className="table-data">
                            {item?.user?.full_name}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={8}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Payment found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <OrderView
        order_view={order_view}
        modal={modal}
        toggle={toggle}
        getSingleOrder={getSingleOrder}
        getReceivedOrder={getPaymentReceived}
      />

      {pageData && payment_recieved.length > 0 && <div className="table-footer-wrap">
        <small>{Admin_Payment?.Showing?.[language]} {pageData.from} {Admin_Payment?.to?.[language]} {pageData.to} {Admin_Payment?.of?.[language]} {pageData.total}{" "}
              {Admin_Payment?.entries?.[language]}</small>
        <div className="pagination-outer">
          <CustomPagination
            totalItems={pageData?.total}
            itemsPerPage={pageData?.per_page}
            activePage={pageData?.current_page}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>}
    </React.Fragment>
  );
}
