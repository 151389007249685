import React, { useContext, useEffect, useState } from "react";
import {
  Button, Input,
  Table,
  Row,
  Col,
  Spinner
} from "reactstrap";
import { api } from "../../../../../Library/CustomApi";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../../Components/Loader/Loader";
import { Context } from "../../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../../Library/LanguageConversion/Department_Products";
import CartItemTile from "../CartItemTile";

export default function DeptCart(args: any) {

  const { updateCartCount } = useContext(Context);
  const [cartData, setCartData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [sum, setSum] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const { language } = useContext(Context);
  const navigate = useNavigate()
  useEffect(() => {
    getCartList()
    updateCartCount()
  }, [])

  useEffect(() => {
    let sum = 0
    let count = 0
    if (cartData) {
      cartData?.forEach((el: any) => {
        let amount: number = el?.ratecontracts?.price + (el?.ratecontracts?.price * 0.05)
        let tgst: number = amount * (el?.Product?.producthsn?.[0]?.igst / 100)
        let totalAmount: number = amount + tgst;
        sum = sum + totalAmount * el?.quantity
        count = count + el?.quantity
        // sum = sum + (el.ratecontracts.price * el?.quantity)
        // count = count + el?.quantity
      });
    }
    setSum(sum);
    setCount(count);
  }, [cartData])

  // cart data fetching 
  const getCartList = () => {
    api
      .get(`/product/cart`, true)
      .then(async function ([success, response]) {
        setCartData(response?.data || []);
        setIsLoading(false)
        return response;
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)

      });
  };

  // removes all items from cart 
  const clearCartHandler = () => {
    setIsLoading(true)
    api
      .get(`/order/clear-cart`, true)
      .then(async function ([success, response]) {
        getCartList()
        updateCartCount()
        return response;
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err);
      });
  }

  // remove item from cart 
  const removeCart = (id: number) => {
    api
      .post(`/product/remove_from_cart`, { cart_ids: [id] }, true,)
      .then(async function ([success, response]) {
        toast.success("Item removed")
        getCartList()
        updateCartCount()
      })
      .catch((err) => {
        console.log(err)
        toast.error("Unexpected error occured")
      });
  }

  // proforma invoice 
  const generateProformaHandler = () => {

    let productId: number[] = []
    let quantity: number[] = []

    cartData.forEach((element: any) => {
      productId.push(element?.Product?.id)
      quantity.push(element?.quantity)
    });

    api
      .post(`/order/pf-invoice`,
        {
          product: productId,
          quantity: quantity
        }
        , true,)
      .then(async function ([success, response]) {
        toast.success("Commercial Proposal Generated")
        navigate('/department/proforma-invoice', { state: { id: response?.data?.id } })
      })
      .catch((err) => {
        console.log(err)
        toast.error("Unexpected error occured")
      });
  }

  return (
    <>
      <div className="cart-content">
        {isLoading ?
          <div className="text-center py-5">
            <Loader />
          </div>
          :
          <>
            {!(cartData?.length > 0) ?
              <div className="text-center">
                <Link to='/department/products' className="text-decoration-none text-secondary" > {DepartmentProducts?.Add_Item_To_Cart[language]} </Link>
              </div>
              :
              <div>
                <div className="table-outer-wrap">
                  <div className="cart-table table-wrap responsive-table">
                    <div className="overflow-auto">
                      <div className="clear-cart-btn">
                        <Button
                          onClick={clearCartHandler}
                          color="danger"
                          outline>
                          {DepartmentProducts?.Clear_Cart[language]}
                        </Button>
                      </div>
                      <Table className="table-striped">
                        <tbody>
                          {cartData?.map((item: any, i: number) => {

                            let amount: number = item?.ratecontracts?.price + (item?.ratecontracts?.price * 0.05)

                            let tgst: number = amount * (item?.Product?.producthsn?.[0]?.igst / 100)
                            let totalAmount: number = amount + tgst;

                            return (
                              // Items card 
                              <CartItemTile
                                item={item}
                                removeCart={removeCart}
                                key={item?.id}
                                getCartList={getCartList}
                              />
                            )
                          })
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="cart-total-outer">
                  <h2 className="heading">{DepartmentProducts?.Cart_Total[language]}</h2>
                  <hr />
                  <Row className="align-items-end">
                    <Col md={12}>
                      <div className="cart-total-left-wrap">
                        <div className="flex-between">
                          <p>{DepartmentProducts?.Total_items[language]}: {count} {count > 1 ? 'Items' : 'item'}</p>
                          <p>{DepartmentProducts?.TotalAmount[language]} : <span className="primary-text">
                            <i className="bi bi-currency-rupee"></i>{sum?.toFixed(2)} <small> ( {DepartmentProducts?.GST_Included[language]} )
                            </small></span></p>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="generate-proforma-btn">
                        <Link to="">
                          <Button className="btn btn-primary" onClick={generateProformaHandler}>{DepartmentProducts?.Generate_Commercial_Proposal[language]}</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            }
          </>}
      </div >
    </>
  );
}
