import React from 'react'
import HeaderEng from './Header';
import Footer from './Footer';

const Layout = (props:any ) => {
    return (
        <div className='website-wrap'>
            <>
                <HeaderEng />
                {props?.children }
                <Footer/>
            </>

        </div>
    )
}

export default Layout;
