export const VendorRegLang:any = {
    //Vendor Sidebar
    profile_settings:{
        en:"Profile Settings",
        ka:"ಪ್ರೊಫೈಲ್ ಸೆಟ್ಟಿಂಗ್‌ಗಳು"
    },
    dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    purchase_order: {
        en: 'Purchase Order',
        ka: 'ಖರೀದಿ ಆದೇಶ'
    },
    
    delivered_date:{
        en:"Delivered Date",
        ka: 'ವಿತರಿಸಿದ ದಿನಾಂಕ'
    },

    OrderID_ProductName: {
        en: ' Order ID / Product Name ',
        ka: 'ಆದೇಶ ಐಡಿ /ಉತ್ಪನ್ನದ ಹೆಸರು '
    },
    Invoice_ProductName: {
        en: ' Invoice No / Product Name ',
        ka: 'Invoice No / Product Name '
    },
    feedback_and_summary: {
        en: 'Feedback & Summary',
        ka: 'ಪ್ರತಿಕ್ರಿಯೆ ಮತ್ತು ಸಾರಾಂಶ'
    },
    payment_lists: {
        en: 'Payment Lists',
        ka: 'ಪ್ರತಿಕ್ರಿಯೆ ಮತ್ತು ಸಾರಾಂಶ'
    },
    services: {
        en: 'Services',
        ka: 'ಸೇವೆಗಳು'
    },
    tickets: {
        en:"Tickets",
        ka:"ಟಿಕೆಟ್ಸರ್"
    },
    amc: {
        en:"AMC",
        ka:"ಏಎಂಸಿ"
    },
    schedule_maintenance: {
        en:"Schedule Maintenance",
        ka:"ವೇಳಾಪಟ್ಟಿ ನಿರ್ವಹಣೆ"
    },
    documents: {
        en:"Documents",
        ka:"ದಾಖಲೆಗಳು"
    },
    settings: {
        en:"Settings",
        ka:"ಸಂಯೋಜನೆಗಳು"
    },
    completed: {
        en:"completed",
        ka:"ಪೂರ್ಣಗೊಂಡಿದೆ"
    },
    delivered: {
        en:"Delivered",
        ka:"ವಿತರಿಸಲಾಯಿತು"
    },

    //Vendor Dashboard
    orders_completed_out_of: {
        en:" Orders Completed Out of ",
        ka:" ಆದೇಶಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಲಾಗಿದೆ "
    },
    completed_orders: {
        en:"Completed Orders",
        ka:"ಪೂರ್ಣಗೊಂಡ ಆದೇಶಗಳು"
    },
    
    this_week: {
        en:"This Week",
        ka:"ಈ ವಾರ"
    },
    this_month: {
        en:"This Month",
        ka:"ಈ ತಿಂಗಳು"
    },
    this_year: {
        en:"This Year",
        ka:"ಈ ವರ್ಷ"
    },
    total_orders: {
        en:"Total Orders",
        ka:"ಒಟ್ಟು ಆದೇಶಗಳು"
    },
    total_sales: {
        en:"Total Sales",
        ka:"ಒಟ್ಟು ಮಾರಾಟ"
    },
    approved_orders: {
        en:"Approved Orders",
        ka:"ಅನುಮೋದಿತ ಆದೇಶಗಳು"
    },
    delivered_orders: {
        en:"Delivered Orders",
        ka:"ಆದೇಶಗಳನ್ನು ವಿತರಿಸಲಾಗಿದೆ"
    },
    pending_orders: {
        en:"Pending Orders",
        ka:"ಬಾಕಿ ಉಳಿದ ಆದೇಶಗಳು"
    },
    cancelled_orders: {
        en:"Cancelled Orders",
        ka:"ರದ್ದುಗೊಳಿಸಲಾದ ಆದೇಶಗಳು"
    },
    total_payments: {
        en:"Total Payments",
        ka:"ಒಟ್ಟು ಪಾವತಿಗಳು"
    },
    pending_payments: {
        en:"Pending Payments",
        ka:"ಬಾಕಿ ಉಳಿದ ಪಾವತಿಗಳು"
    },
    pending_tickets: {
        en:"Pending Tickets",
        ka:"ಬಾಕಿ ಉಳಿದ ಟಿಕೆಟ್ಗಳು"
    },
    penalties: {
        en:"Penalties",
        ka:"ದಂಡಗಳು"
    },
    recent_orders: {
        en:"Recent Orders",
        ka:"ಇತ್ತಿಹಾಸದ ಆದೇಶಗಳು"
    },
    maintenance: {
        en:"Maintenance",
        ka:"ನಿರ್ವಹಣೆ"
    },
    enquiry: {
        en:"Enquiry",
        ka:"ಪ್ರಶ್ನೆ"
    },
    no_recent_orders: {
        en:"No Recent Orders",
        ka:"ಇತ್ತೀಚಿನ ಆದೇಶಗಳಿಲ್ಲ"
    },

    //Vendor - Purchase Order - Received Orders
    search_here: {
        en:"Search here",
        ka:"ಇಲ್ಲಿ ಹುಡುಕಿ"
    },
    sl_no: {
        en:"Sl No",
        ka:"ಕ್ರಮ ಸಂಖ್ಯೆ"
    },
    order_id: {
        en:"Order ID",
        ka:"ಆದೇಶ ಐಡಿ"
    },
    order_allocated_date: {
        en:"Order Allocated Date",
        ka:"ಆದೇಶ ಹಂಚಿಕೆ ದಿನಾಂಕ"
    },
    products: {
        en:"Products",
        ka:"ಉತ್ಪನ್ನಗಳು"
    },
    product_name: {
        en:"product_name",
        ka:"ಉತ್ಪನ್ನದ ಹೆಸರು"
    },
    quantity: {
        en:"Quantity",
        ka:"ಪ್ರಮಾಣ"
    },
    status: {
        en:"Status",
        ka:"ಸ್ಥಿತಿ"
    },
    price: {
        en:"Price",
        ka:"ಬೆಲೆ"
    },
    action: {
        en:"Action",
        ka:"ಕ್ರಿಯೆ"
    },
    new_order: {
        en:"New Order",
        ka:"ಹೊಸ ಆದೇಶ"
    },
    no_data_found: {
        en:"No Data Found",
        ka:"ಮಾಹಿತಿ ಸಿಗಲಿಲ್ಲ"
    },
    showing: {
        en:"Showing",
        ka:"ತೋರಿಸಲಾಗುತ್ತಿದೆ"
    },
    to: {
        en:"to",
        ka:"ಗೆ"
    },
    of: {
        en:"of",
        ka:"ನ"
    },
    entries: {
        en:"entries",
        ka:"ನಮೂದುಗಳು"
    },

    // Processed Orders
    invoice_no: {
        en:"Invoice No",
        ka:"ಚಲಾನಿ ಸಂಖ್ಯೆ"
    },
    processed_date: {
        en:"Processed Date",
        ka:"ಪ್ರಕ್ರಿಯಿಸಲಾದ ದಿನಾಂಕ"
    },
    // Rejected Orders
    rejected_date: {
        en:"Rejected Date",
        ka:"ಪ್ರಕ್ರಿಯಿಸಲಾದ ದಿನಾಂಕ"
    },
    rejection_reason: {
        en:"Rejection Reason",
        ka:"ತಿರಸ್ಕರಣ ಕಾರಣ"
    },
    // Out For Delivery Orders
    out_for_delivery_date: {
        en:"Out For Delivery Date",
        ka:"ವಿತರಣೆಗಾಗಿ ಹೊರಗಡೆಯಲ್ಲಿರುವ ದಿನಾಂಕ"
    },
    // Cancelled Orders
    cancelled_date: {
        en:"Cancelled Date",
        ka:"ರದ್ದಾದ ದಿನಾಂಕ"
    },
    Cancelled_By: {
        en:"Cancelled By", //new
        ka:"ಮೂಲಕ ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ"
    },
    cancelled: {
        en:"Cancelled",
        ka:"ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ"
    },
    // Vendor - Delivered
    order_by: {
        en:"Order by",
        ka:"ಮೂಲಕ ಆದೇಶ"
    },
    // Vendor - Feedback and Summary
    water_authority: {
        en:"Water Authority",
        ka:"ಜಲ ಪ್ರಾಧಿಕಾರ"
    },
    vendor: {
        en:"Vendor",
        ka:"ಮಾರಾಟಗಾರ"
    },
    // Vendor - Payment Lists
    transaction_id: {
        en:"Transaction ID",
        ka:"ವಹಿವಾಟು ಐಡಿ"
    },
    // Vendor - Payment Lists - PaymentLayout
    payment_pending: {
        en:"Payment Pending",
        ka:"ಪಾವತಿ ಬಾಕಿ ಇದೆ"
    },
    payment_received: {
        en:"Payment Received",
        ka:"ಪಾವತಿಯನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ"
    },

    // Vendor - Payment Lists - PaymentModal
    invoice_generated_successfully: {
        en:"Invoice Generated Successfully",
        ka:"ಸರಕುಪಟ್ಟಿ ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ"
    },
    thanks_for_your_order: {
        en:"Thanks for your Order!",
        ka:"ನಿಮ್ಮ ಆದೇಶಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು!"
    },
    receipt: {
        en:"Receipt",
        ka:"ರಶೀದಿ"
    },
    transaction_number: {
        en:"Transaction Number",
        ka:"ವ್ಯಾಪಾರ ಸಂಖ್ಯೆ"
    },

    product_description: {
        en:"Product Description",
        ka:"ಉತ್ಪನ್ನ ವಿವರಣೆ"
    },
    order_details: {
        en:"Order Details",
        ka:"ಆದೇಶದ ವಿವರಗಳು"
    },
    invoice_number: {
        en:"Invoice Number",
        ka:"ಸರಕುಪಟ್ಟಿ ಸಂಖ್ಯೆ"
    },
    invoice_date: {
        en:"Invoice Date",
        ka:"ಸರಕುಪಟ್ಟಿ ದಿನಾಂಕ"
    },
    receipts_voucher: {
        en:"Receipt Voucher",
        ka:"ರಶೀದಿ ಚೀಟಿ"
    },
    total: {
        en:"Total",
        ka:"ಒಟ್ಟು"
    },
    delivery_charges: {
        en:"Delivery Charges",
        ka:"ವಿತರಣಾ ಶುಲ್ಕಗಳು"
    },
    free: {
        en:"Free",
        ka:"ಉಚಿತ"
    },
    track_order: {
        en:"Track Order",
        ka:""
    },
    out_for_delivery: {
        en:"Out For Delivery",
        ka:"ವಿತರಣೆಗೆ ಹೊರಗಿದೆ"
    },
    discount: {
        en:"Discount",
        ka:"ರಿಯಾಯಿತಿ"
    },

    // Vendor - Services - Open Tickets
    request_by: {
        en:"Request by",
        ka:"ಮೂಲಕ ವಿನಂತಿಸಿ"
    },
    type: {
        en:"Type",
        ka:"ಮಾದರಿ"
    }, 
    priority: {
        en:"Priority",
        ka:"ಪ್ರಾಥಮಿಕತೆ"
    },
    date: {
        en:"Date",
        ka:"ದಿನಾಂಕ"
    },
    product_code: {
        en:"Product Code",
        ka:"ಉತ್ಪನ್ನ ಕೋಡ್"
    },
    hsn_code: {
        en:"HSN Code",
        ka:"ಎಚ್ಎಸ್ಎನ್ ಕೋಡ್"
    },
    // Services - AMC
    contract: {
        en:"Contract",
        ka:"ಒಪ್ಪಂದ"
    },
    invoice_id: {
        en:"Invoice ID",
        ka:"ಇಂವೋಯಿಸ್"
    },
    valid_from: {
        en:"Valid From",
        ka:"ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ"
    },
    valid_to: {
        en:"Valid To",
        ka:"ಗೆ ಮಾನ್ಯವಾಗಿದೆ"
    },
    email_id: {
        en:"Email ID",
        ka:"ಇಮೇಲ್ ಐಡಿ"
    },
    // Vendor - Schedule Maintenance
    issue_type: {
        en:"Issue Type",
        ka:"ಸಮಸ್ಯೆಯ ಪ್ರಕಾರ"
    },
    resolved_date: {
        en:"Resolved Date",
        ka:"ಪರಿಹರಿಸಿದ ದಿನಾಂಕ"
    },
    time: {
        en:"Time",
        ka:"ಸಮಯ"
    },
    // Vendor - Profile Settings
    full_name: {
        en:"Full Name",
        ka:"ಪೂರ್ಣ ಹೆಸರು"
    },
    id_number: {
        en:"ID Number",
        ka:"ಐಡಿ ಸಂಖ್ಯೆ"
    },
    mobile_number: {
        en:"Mobile Number",
        ka:"ಮೊಬೈಲ್ ನಂಬರ"
    },
    company_details: {
        en:"Company Details",
        ka:"ಕಂಪನಿಯ ವಿವರಗಳು"
    },
    submit: {
        en:"Submit",
        ka:"ಸಲ್ಲಿಸಿ"
    },

    //Vendor - Documents
    view_document: {
        en:"View Document",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್ ವೀಕ್ಷಿಸು"
    },
    comments: {
        en:"Comments",
        ka:"ಟಿಪ್ಪಣಿಗಳು"
    },
    reupload: {
        en:"Reupload",
        ka:"ಮರುಹಾಕು"
    },
    pan_card: {
        en:"PAN Card",
        ka:"ಪ್ಯಾನ್ ಕಾರ್ಡ್"
    },
    oem: {
        en:"OEM",
        ka:"ಓಈಎಂ"
    },
    maf: {
        en:"MAF",
        ka:"ಎಂಎಫ್"
    },
    address_proof: {
        en:"Address Proof",
        ka:"ವಿಳಾಸ ಪುರಾವೆ"
    },
    incoporation: {
        en:"Incoporation",
        ka:"ಸೇರುವು"
    },
    msme: {
        en:"MSME",
        ka:"ಎಂಎಸ್ಎಂಇ"
    },
    gst: {
        en:"GST",
        ka:"ಜಿಎಸ್ಟಿ"
    },
    iso: {
        en:"ISO",
        ka:"ಐಎಸ್ಒ"
    },
    challan: { 
        en:"Challan",
        ka:"ಚಲನ್"
    },
    cheque: {
        en:"Cheque",
        ka:"ಪರಿಶೀಲಿಸಿ"
    },
    turn_over_one: {
        en:"Turn Over 1",
        ka:"ವಹಿವಾಟು 1"
    },
    turn_over_two: {
        en:"Turn Over 2",
        ka:"ವಹಿವಾಟು 2"
    },
    turn_over_three: {
        en:"Turn Over 3",
        ka:"ವಹಿವಾಟು 3"
    },
    empanel: {
        en:"Empanel",
        ka:"ಎಂಪನೆಲ್"
    },
    udyam: {
        en:"Udyam",
        ka:"ಉದ್ಯಮ"
    },
    demand_draft: {
        en:"Demand Draft",
        ka:"ಬೇಡಿಕೆ ಕರಡು"
    },
    no_comment: {
        en:"No Comment",
        ka:"ಏನನ್ನೂ ಹೇಳುವುದಿಲ್ಲ"
    },
    no_documents_found: {
        en:"No Documents Found",
        ka:"ಯಾವುದೇ ದಾಖಲೆಗಳು ಕಂಡುಬಂದಿಲ್ಲ"
    },
    // Vendor - Advanced Reupload
    gst_number: {
        en:"GST Number",
        ka:"GST ಸಂಖ್ಯೆ"
    },
    verification: {
        en:"Verification",
        ka:"ಪರಿಶೀಲನೆ"
    },
    approved: {
        en:"Approved",
        ka:"ಅನುಮೋದಿಸಲಾಗಿದೆ"
    },
    order_tracking_status: {
        en:"order_tracking_status",
        ka:"ಆದೇಶ ಟ್ರ್ಯಾಕಿಂಗ್ ಸ್ಥಿತಿ"
    },
    received: {
        en:"received",
        ka:"ಸ್ವೀಕರಿಸಿದರು"
    },
    rejected: {
        en:"Rejected",
        ka:"ತಿರಸ್ಕರಿಸಲಾಗಿದೆ"
    },
    reuploaded: {
        en:"Reuploaded",
        ka:"ಮರುಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿದೆ"
    },
    pending: {
        en:"Pending",
        ka:"ಬಾಕಿಯಿದೆ"
    },
    processed: {
        en:"processed",
        ka:"ಸಂಸ್ಕರಿಸಿದ"
    },
    pan_number: {
        en:"PAN Number",
        ka:"ಪ್ಯಾನ್ ಸಂಖ್ಯೆ"
    },
    vendor_type: {
        en:"Vendor Type",
        ka:"ಮಾರಾಟಗಾರರ ಪ್ರಕಾರ"
    },
    manufacturer: {
        en:"Manufacturer",
        ka:"ನಿರ್ಮಾತೃ"
    },

    
    distributor: {
        en:"Distributor",
        ka:"ವಿತರಕ"
    },
    importer: {
        en:"Importer",
        ka:"ಆಮದುದಾರ"
    },
    marketer: {
        en:"Marketer",
        ka:"ಮಾರ್ಕೆಟರ್"
    },
    gst_class_name: {
        en:"GST Class Name",
        ka:"GST ವರ್ಗದ ಹೆಸರು"
    },
    registered: {
        en:"Registered",
        ka:"ನೋಂದಾಯಿಸಲಾಗಿದೆ"
    },
    not_registered: {
        en:"Not Registered",
        ka:"ನೋಂದಣಿಯಾಗಿಲ್ಲ"
    },
    componding_scheme: {
        en:"Componding Scheme",
        ka:"ಸಂಯೋಜನೆ ಯೋಜನೆ"
    },
    psu_gov_org: {
        en:"PSU/Government Organization",
        ka:"PSU/ಸರ್ಕಾರಿ ಸಂಸ್ಥೆ"
    },
    company_name: {
        en:"Company Name",
        ka:"ಸಂಸ್ಥೆಯ ಹೆಸರು"
    },
    company_email: {
        en:"Company Email",
        ka:"ಕಂಪನಿ ಇಮೇಲ್"
    },
    contact_number: {
        en:"Contact Number",
        ka:"ಸಂಪರ್ಕ ಸಂಖ್ಯೆ"
    },
    company_inc_date: {
        en:"Company Incorporation Date",
        ka:"ಕಂಪನಿಯ ಸಂಯೋಜನೆಯ ದಿನಾಂಕ"
    },
    company_reg_add: {
        en:"Company Registered Address",
        ka:"ಕಂಪನಿ ನೋಂದಾಯಿತ ವಿಳಾಸ"
    },
    company_comm_add: {
        en:"Company Communication Address",
        ka:"ಕಂಪನಿಯ ಸಂವಹನ ವಿಳಾಸ"
    },
    contact_person_name: {
        en:"Contact Person Name",
        ka:"ಸಂಪರ್ಕ ವ್ಯಕ್ತಿಯ ಹೆಸರು"
    },

    // Vendor - AccountDetails
    account_number: {
        en:"Account No",
        ka:"ಖಾತೆ ಸಂಖ್ಯೆ"
    },
    bank: {
        en:"Bank",
        ka:"ಬ್ಯಾಂಕ್"
    },
    branch: {
        en:"Branch",
        ka:"ಶಾಖೆ"
    },
    set_as_primary: {
        en:"Set as Primary",
        ka:"ಪ್ರಾಥಮಿಕವಾಗಿ ಹೊಂದಿಸಲಾಗಿದೆ"
    },
    no_account_details_found: {
        en:"No Account Details Found",
        ka:"ಯಾವುದೇ ಖಾತೆ ವಿವರಗಳು ಕಂಡುಬಂದಿಲ್ಲ"
    },
    add_new_bank_account: {
        en:"Add New Bank Account",
        ka:"ಹೊಸ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಸೇರಿಸಿ"
    },
    bank_name: {
        en:"Bank Name",
        ka:"ಬ್ಯಾಂಕ್ ಹೆಸರು"
    },
    bank_address: {
        en:"Bank Address",
        ka:"ಬ್ಯಾಂಕ್ ವಿಳಾಸ"
    },
    city: {
        en:"City",
        ka:"ನಗರ"
    },
    micr: {
        en:"MICR",
        ka:"MICR"
    },
    account_holder_name: {
        en:"Account Holder Name",
        ka:"ಖಾತೆದಾರನ ಹೆಸರು"
    },
    retype_acc_num: {
        en:"Retype Account Number",
        ka:"ಖಾತೆ ಸಂಖ್ಯೆಯನ್ನು ಮತ್ತೆ ಟೈಪ್ ಮಾಡಿ"
    },
    confirm_entered_details_are_coorect: {
        en:"Confirm Entered Details are Correct",
        ka:"ನಮೂದಿಸಿದ ವಿವರಗಳು ಸರಿಯಾಗಿವೆಯೇ ಎಂದು ಖಚಿತಪಡಿಸಿ"
    },
    add_account: {
        en:"Add Account",
        ka:"ಖಾತೆಯನ್ನು ಸೇರಿಸು"
    },
    PayPG:{
        en:'Pay PG',
        ka:'ಪಿಜಿ ಪಾವತಿಸಿ'
    },
    
    
    // Vendor - Payments - BankAccountModal 
    upload_the_documents: {
        en:"Upload the Documents",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
    },
    ifsc_code: {
        en:"IFSC Code",
        ka:"IFSC ಕೋಡ್"
    },
    branch_name: {
        en:"Branch Name",
        ka:"ಶಾಖೆಯ ಹೆಸರು"
    },
    amount: {
        en:"Amount",
        ka:"ಮೊತ್ತ"
    },
    payment_date: {
        en:"Payment Date",
        ka:"ಪಾವತಿ ದಿನಾಂಕ"
    },
    account_name: {
        en:"Account Type",
        ka:"ಖಾತೆಯ ಪ್ರಕಾರ"
    },

    // Vendor - Payments - Challan 
    nrla_details: {
        en:"NRLA Details",
        ka:"NRLA ವಿವರಗಳು"
    },
    choose_payment: {
        en:"Choose Payment",
        ka:"ಪಾವತಿ ಆಯ್ಕೆಮಾಡಿ"
    },
    date_of_payment: {
        en:"Date of Payment",
        ka:"ಪಾವತಿಯ ದಿನಾಂಕ"
    },
    challan_uploaded_successfully: {
        en:"Challan Uploaded Successfully",
        ka:"ಚಲನ್ ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ"
    },
    cheque_uploaded_successfully: {
        en:"Cheque Uploaded Successfully",
        ka:"ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ ಎಂದು ಪರಿಶೀಲಿಸಿ"
    },
    receipt_number: {
        en:"Receipt Number",
        ka:"ರಶೀದಿ ಸಂಖ್ಯೆ"
    },
    payment_site: {
        en:"Payment Site",
        ka:"ಪಾವತಿ ಸೈಟ್"
    },
    bsr_code: {
        en:"BSR Code",
        ka:"ಬಿಎಸ್ಆರ್ ಕೋಡ್"
    },
    challan_number: {
        en:"Challan Number",
        ka:"ಚಲನ್ ಸಂಖ್ಯೆ"
    },

    demand_draft_number: {
        en:"Demand Draft Number",
        ka:"ಬೇಡಿಕೆ ಕರಡು ಸಂಖ್ಯೆ"
    },

    cheque_number: {
        en:"Cheque Number ",
        ka:"ಸಂಖ್ಯೆಯನ್ನು ಪರಿಶೀಲಿಸಿ"
    },
    payment_details_uploaded: {
        en:"Payment Details Uploaded",
        ka:"ಪಾವತಿ ವಿವರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ"
    },
    invalid_ifsc_code: {
        en:"Invalid IFSC Code",
        ka:"ಅಮಾನ್ಯ IFSC ಕೋಡ್"
    },
    direct_bank_transfer: {
        en:"Direct Bank Transfer",
        ka:"ನೇರ ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆ"
    },
    rtgs: {
        en:"RTGS",
        ka:"RTGS"
    },
    neft: {
        en:"NEFT",
        ka:"NEFT"
    },
    net_banking: {
        en:"NetBanking",
        ka:"ನೆಟ್ ಬ್ಯಾಂಕಿಂಗ್"
    },

    // Vendor - Profile Updation Tab
    basic_details: {
        en:"Basic Details",
        ka:"ನೆಟ್ ಬ್ಯಾಂಕಿಂಗ್"
    },
    doc_upload: {
        en:"Document Upload",
        ka:""
    },
    adv_reg: {
        en:"Advanced Registration",
        ka:""
    },
    emd_details: {
        en:"EMD Details",
        ka:"ನೆಟ್ ಬ್ಯಾಂಕಿಂಗ್"
    },
    empanel_details: {
        en:"Empanelment Details",
        ka:"ನೆಟ್ ಬ್ಯಾಂಕಿಂಗ್"
    },


    // Vendor - Purchaseorder-ordermodal-deliverymodal
    UnitPrice: {
        en:"Unit Price",
        ka:"ಯೂನಿಟ್ ಬೆಲೆ"
    },
    download: {
        en:"Download",
        ka:"ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ"
    },
    TotalAmount: {
        en:"Total Amount",
        ka:"ಒಟ್ಟು ಮೊತ್ತ"
    },
    GenarateICCR:{
        en: ' Genarate ICCR',
        ka: 'ICCR ಅನ್ನು ರಚಿಸಿ'
    },
    IGST: {
        en:"IGST",
        ka:"ಐಜಿಎಸ್ಟಿ"
    },

    SGST: {
        en:"SGST",
        ka:"ಎಸ್ಜಿಎಸ್ಟಿ"
    },

    CGST: {
        en:"CGST",
        ka:"ಸಿಜಿಎಸ್ಟಿ"
    },

    DeliveryDocuments: {
        en:"Delivery Documents",
        ka:"ವಿತರಣಾ ದಾಖಲೆಗಳು"
    },
    WorkCompletionCertificate:{
        en: 'Work Completion Certificate',
        ka: 'ಕೆಲಸ ಪೂರ್ಣಗೊಳಿಸಿದ ಪ್ರಮಾಣಪತ್ರ'
    },
    WorkCompletionCertificateDownload: {
        en:"Work Completion Certificate Download",
        ka:"ಕೆಲಸ ಪೂರ್ಣಗೊಳಿಸಿದ ಪ್ರಮಾಣಪತ್ರ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ"
    },

    DeliveryDetails: {
        en:"Delivery Details",
        ka:"ವಿತರಣಾ ವಿವರಗಳು"
    },

    Location: {
        en:"Location",
        ka:"ಸ್ಥಳ"
    },

    ShippingAddress: {
        en:"Shipping Address",
        ka:"ಶಿಪ್ಮೆಂಟ್ ವಿಳಾಸ"
    },

    Address: {
        en:"Address",
        ka:"ವಿಳಾಸ"
    },

    Name: {
        en:"Name",
        ka:"ಹೆಸರು"
    },

    PhoneNumber: {
        en:"Phone Number",
        ka:"ಫೋನ್ ಸಂಖ್ಯೆ"
    },

    // Vendor - Purchaseorder-ordermodal-outfordeliverymodal

    OrderID:{
        en: 'Order ID',
        ka: 'ಆರ್ಡರ್ ಐಡಿ'
    },
    OrderDetails: {
        en:"Order Details",
        ka:"ಆದೇಶ ವಿವರಗಳು"
    },

    WorkCompletionDetails: {
        en:"Work Completion Details",
        ka:"ಕೆಲಸ ಮುಗಿಯುವ ವಿವರಗಳು"
    },

    UploadtheProductsimage: {
        en:"Upload the Product(s) image",
        ka:"ಉತ್ಪನ್ನದ ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
    },

    ChooseDocuments: {
        en:"Choose Documents",
        ka:"ಕೆಲಸ ಮುಗಿಯುವ ವಿವರಗಳು"
    },

    Latitude: {
        en:"Latitude",
        ka:"ಅಕ್ಷಾಂಶ"
    },

    Longitude: {
        en:"Longitude",
        ka:"ರೇಖಾಂಶ"
    },

    OrderStatus: {
        en:"Order Status",
        ka:"ಆದೇಶ ಸ್ಥಿತಿ"
    },

    SelectOrderStatus: {
        en:"Select Order Status",
        ka:"ಆದೇಶದ ಸ್ಥಿತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ"
    },

    Save: {
        en:" Save",
        ka:"ಉಳಿಸಿ"
    },
    // Vendor - Purchaseorder-ordermodal-processordermodal

    TaxInvoiceDetails: {
        en:"Tax Invoice Details",
        ka:"ಕಾರ್ಯ ಆದೇಶವನ್ನು ಡೌನ್ಲೋಡ್ ಮಾಡಿ"
    },

    TaxInvoiceRejectedpleasegenerateagain: {
        en:"Tax Invoice Rejected,please generate again",
        ka:"ತೆರಿಗೆ ಚಲಾನೆ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ, ಮತ್ತೆ ರಚಿಸಿದ್ದುಕೊಳ್ಳಿ"
    },

    TaxInvoiceUndertheVerification: {
        en:"Tax Invoice Under the Verification",
        ka:"ಪರಿಶೀಲನೆಯ ಅಡಿಗಲ್ಲಿನಲ್ಲಿರುವ ತೆರಿಗೆ ಚಲಾವಣೆ"
    },

    InvoiceNumber: {
        en:"Invoice Number",
        ka:"ಚಲಾನೆ ಸಂಖ್ಯೆ"
    },

    InvoiceDate: {
        en:"Invoice Date",
        ka:"ಚಲಾನೆ ದಿನಾಂಕ"
    },

    BillingAddress: {
        en:"Billing Address",
        ka:"ಬಿಲ್ಲಿಂಗ್ ವಿಳಾಸ"
    },
 
    PlaceofSupply: {
        en:" Place of Supply",
        ka:"ಸರಬರಾಜು ಸ್ಥಳ"
    },

    TaxInvoiceVerifed: {
        en:"Tax Invoice Verifed",
        ka:"ತೆರಿಗೆ ಚಲಾನೆ ಪರಿಶೀಲಿಸಲಾಗಿದೆ"
    },

    DeliveryChellanDetails: {
        en:"Delivery Chellan Details",
        ka:"ವಿತರಣಾ ಚೆಲ್ಲಾನ್ ವಿವರಗಳು"
    },
    Work_Completion_download: {
        en:"Download Work Completion for Department and Keonics",
        ka:"ಇಲಾಖೆ ಮತ್ತು ಕಿಯೋನಿಕ್ಸ್‌ಗಾಗಿ ಕೆಲಸ ಪೂರ್ಣಗೊಳಿಸುವಿಕೆಯನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ"
    },

    DeliverychellanRejectedpleasegenerateagain: {
        en:"Delivery chellan Rejected ,please generate again",
        ka:"ವಿತರಣಾ ಚೆಲ್ಲಾನ್ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ, ಮತ್ತೆ ರಚಿಸಿದ್ದುಕೊಳ್ಳಿ"
    },

    DeliverychellanUndertheVerification: {
        en:"Delivery chellan Under the Verification",
        ka:"ಚೆಲ್ಲಾನ್ ಪರಿಶೀಲನೆಗೆ ಒಳಗಾಗಿದೆ"
    },

    GenerateDeliveryChellan: {
        en:"Generate Delivery Chellan",
        ka:"ವಿತರಣಾ ಚೆಲ್ಲಾನ್ ರಚಿಸಿ"
    },

    ChellanNumber: {
        en:"Chellan Number",
        ka:"ಚಾಲನೆ ಸಂಖ್ಯೆ"
    },

    ChellanDate: {
        en:"Chellan Date",
        ka:"ಚೆಲ್ಲಾನ್ ದಿನಾಂಕ"
    },

    SubTotal: {
        en:"Sub Total",
        ka:"ಉಪ ಮೊತ್ತ"
    },

    Tax: {
        en:"Tax",
        ka:"ತೆರಿಗೆ"
    },

    GrandTotal: {
        en:"Grand Total",
        ka:"ಒಟ್ಟು ಮೊತ್ತ"
    },

    DownloadWorkOrder: {
        en:"Download Work Order",
        ka:"ಕಾರ್ಯ ಆದೇಶವನ್ನು ಡೌನ್ಲೋಡ್ ಮಾಡಿ"
    },
    // Vendor - Purchaseorder-ordermodal-receivedordermodal

    ProductName: {
        en:"Product Name",
        ka:"ಉತ್ಪನ್ನದ ಹೆಸರು"
    },
    HSNCode:{
        en: 'HSN Code',
        ka: 'HSN ಕೋಡ್'
    },
    ProductSpecifications: {
        en:"Product Specifications",
        ka:"ಉತ್ಪನ್ನದ ವಿಶೇಷಣಗಳು"
    },


    AfterAllocationonlyyoucanvieworder: {
        en:"After Allocation only you can view order",
        ka:"ನಿಯತಗೊಳಿಸಿದ ನಂತರ ಮಾತ್ರ ಆದೇಶವನ್ನು ವೀಕ್ಷಿಸಬಹುದು"
    },

    DownloadMOU: {
        en:"Download MOU",
        ka:"ಎಂಓಯು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ"
    },

    WaitingapproveloftheMOUVerfication: {
        en:" Waiting approvel of the MOU Verfication",
        ka:"ಎಂಓಯು ಪರಿಶೀಲನೆಯ ಅಪ್ಪಾರ್ವಲ್‌ನ ನಿರೀಕ್ಷೆಯಲ್ಲಿ ನಿಲ್ಲುತ್ತಿದೆ"
    },

    MOUCertificate: {
        en:"MOU Certificate",
        ka:"ಎಂಓಯು ಪ್ರಮಾಣಪತ್ರ"
    },

    UploadMOU: {
        en:"Upload MOU",
        ka:"ಎಂಓಯು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
    },

    Certificate: {
        en:"Certificate",
        ka:"ಪ್ರಮಾಣಪತ್ರ"
    },

    EMDAmount: {
        en:" EMD Amount",
        ka:"ಇಎಂಡಿ ಮೊತ್ತ"
    },

    PerformanceGuarenteeAmount: {
        en:"Performance Guarentee Amount",
        ka:"ಪ್ರದರ್ಶನ ನಿಖರತೆ ಮೊತ್ತ"
    },

    VerificationinProgress: {
        en:"Verification in Progress",
        ka:"ಪರಿಶೀಲನೆ ನಡೆಯುತ್ತಿದೆ"
    },

    // Vendor - Purchaseorder-cancelledorder

    OrderAllocatedDate: {
        en:">Order Allocated Date",
        ka:"ಆದೇಶ ನಿಯತಗೊಂಡ ದಿನಾಂಕ"
    },
// Vendor - Purchaseorder-commonorderview

// Vendor - Purchaseorder-Deliverychallanmodal
UploadtheDocuments: {
        en:"Upload the Document(s)",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
    },

    UploadChellan: {
        en:"Upload Chellan",
        ka:"ಚೆಲ್ಲಾನ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
    },
    UploadtheDeliveryChallan:{
        en: ' Upload the Delivery Challan',
        ka: 'ಡೆಲಿವರಿ ಚಲನ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ'
    },
    UploadtheTaxInvoice:{
        en: 'Upload the Tax Invoice',
        ka: 'ತೆರಿಗೆ ಸರಕುಪಟ್ಟಿ ಅಪ್ಲೋಡ್ ಮಾಡಿ'
    },
// Vendor - Purchaseorder-layoutorders
Received: {
        en:"Received",
        ka:"ಪಡೆದಿದೆ"
    },

    Rejected: {
        en:"Rejected",
        ka:"ತಿರಸ್ಕರಿಸಲಾಗಿದೆ"
    },

    Processed: {
        en:"Processed",
        ka:"ಪ್ರಕ್ರಿಯಾಗೊಂಡಿದೆ"
    },

    OutForDelivery: {
        en:"Out For Delivery",
        ka:"ವಿತರಣೆಗೆ ಹೊರಡಿಸಲಾಗಿದೆ"
    },
    OrderTrackingStatus:{
        en: 'Order Tracking Status',
        ka: 'ಆರ್ಡರ್ ಟ್ರ್ಯಾಕಿಂಗ್ ಸ್ಥಿತಿ'
    },
    PurchaseOrders: {
        en:"Purchase Orders",
        ka:"ಖರೀದಿ ಆದೇಶಗಳು"
    },
// Vendor - Purchaseorder-orderviewmodal
    Cancel: {
        en:"Cancel",
        ka:"ರದ್ದು ಮಾಡು"
    }, 

    View: {
        en:"View",
        ka:"ವೀಕ್ಷಿಸು"
    },

    Pending: {
        en:"Pending",
        ka:"ಬಾಕಿಯಿದೆ"
    },

    Approved: {
        en:" Approved",
        ka:"ಅನುಮೋದಿತ"
    },

    ReUploadStatus: {
        en:" ReUpload Status",
        ka:"ಮತ್ತೆ ಅಪ್‌ಲೋಡ್ ಸ್ಥಿತಿ"
    },
    
    Upload_work_completion: {
        en:"Upload the Work Completion",
        ka:"ಕೆಲಸ ಪೂರ್ಣಗೊಳಿಸುವಿಕೆಯನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
    },

    Reuploaded: {
        en:" Reuploaded",
        ka:"ಮತ್ತೆ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ"
    },

    Reupload: {
        en:"Reupload",
        ka:"ಮರೆಮಾಡಿದ ದಾಖಲೆಯನ್ನು ಮತ್ತೆ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
    },

    NoDocumentFound: {
        en:"No Document Found",
        ka:"ಯಾವುದೇ ಆವಶ್ಯಕ ದಾಖಲೆ ಸಿಗಲಿಲ್ಲ"
    },
    add_third_party_inspector: {
        en:"Add Third Party Inspector",
        ka:"ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಇನ್ಸ್‌ಪೆಕ್ಟರ್ ಅನ್ನು ಸೇರಿಸಿ"
    },
    vendor_name: {
        en:"Vendor Name",
        ka:"ಮಾರಾಟಗಾರರ ಹೆಸರು"
    },
    registration_number: {
        en:"Registration Number",
        ka:"ಮಾರಾಟಗಾರರ ಹೆಸರು"  
    },
    created_date: {
        en:"Created Date",
        ka:"ರಚಿಸಿದ ದಿನಾಂಕ"  
    },
    sent_invitaton: {
        en:"Sent Invitation",
        ka:"ಆಹ್ವಾನ ಕಳುಹಿಸಲಾಗಿದೆ"  
    },
    invited: {
        en:"Invited",
        ka:"ಆಹ್ವಾನಿಸಲಾಗಿದೆದೆ"  
    },
    under_verification: {
        en:"Under Verification",
        ka:"ಪರಿಶೀಲನೆ ಅಡಿಯಲ್ಲಿ"  
    },
    verified: {
        en:"Verified",
        ka:"ಪರಿಶೀಲಿಸಲಾಗಿದೆ"  
    },
    accepted: {
        en:"Accepted",
        ka:"ಸ್ವೀಕರಿಸಲಾಗಿದೆ"  
    },
    select_gst_class: {
        en:"Select GST Class",
        ka:"GST ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"  
    },
    company_type: {
        en:"Company Type",
        ka:"ಕಂಪನಿ ಪ್ರಕಾರ"  
    },
    select_company_type: {
        en:"Select Company Type",
        ka:"ಕಂಪನಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"  
    },
    company_registration_number: {
        en:"Company Registration Number",
        ka:"ಕಂಪನಿ ನೋಂದಣಿ ಸಂಖ್ಯೆ"  
    },
    contact_person_number: {
        en:"Contact Person Number",
        ka:"ಸಂಪರ್ಕ ವ್ಯಕ್ತಿ ಸಂಖ್ಯೆ"  
    },
    empanelment_date: {
        en:"Empanelment Date",
        ka:"ಎಂಪನೆಲ್ಮೆಂಟ್ ದಿನಾಂಕ"  
    },
    empanelment_expiry_date: {
        en:"Empanelment Expiry Date",
        ka:"ಎಂಪನೆಲ್‌ಮೆಂಟ್ ಮುಕ್ತಾಯ ದಿನಾಂಕ"  
    },
    BlackList_Documents: {
        en: "BlackList Documents",
        ka: "ಕಪ್ಪುಪಟ್ಟಿ ದಾಖಲೆಗಳು",
      },
    No_Case_Pending: {
        en: "No Case Pending Declaration Letter",
        ka: "ಯಾವುದೇ ಪ್ರಕರಣ ಬಾಕಿ ಉಳಿದಿಲ್ಲ ಘೋಷಣೆ ಪತ್ರ",
      },
}
