import React from "react";
import { useState, useEffect, useContext } from "react";
import { Context } from "../../../Components/Context/Context";
import { Admin_orders } from "../../../Library/LanguageConversion/Admin_orders";
import {
    Button,
    Table,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    List,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../Library/CustomApi";
import Loader from "../../../Components/Loader/Loader";
import constants from "../../../Library/Constants/Constants";
import CustomPagination from "../../../Components/CustomPagination/Index";
import OrderView from "./Modal/OrderView";
import moment from "moment";
import { Formik } from "formik";
import { ProductValidationSchema } from "../Products/UploadProductValidation";
import CoustmeOrdersModal from "./CoustmeOrdersModal";
import { orderValiadtionFirstSchema, orderValiadtionSecondSchema } from "./CommonValidation";
import { CoustmeOrderStatus } from "../../../Library/Constants/helpers";
import { toast } from "react-toastify";

export default function CoustmeOrders() {
    const { language, setLanguage } = useContext(Context);
    const [isShowing, setIsShowing] = useState(false);
    const [suggestion, setSuggestion] = useState(false)
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [loading, setLoading] = useState(true);
    const [approved_order, setApprovedOrder] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageData, setPageData] = useState<any>();
    const [search, setSearch] = useState("");
    const [order_view, setOrderView] = useState([]);
    const [lodingProd, setLoadingPro] = useState(false)
    const [order_view_data, setOrderViewData] = useState([]);
    const [category, setCategory] = useState<any>();
    const [product, setProduct] = useState<any>();
    const [order_id, setOrderId] = useState<any>();
    const [order_views, setOrderview] = useState(true);
    const initialValues = {
        product: "",
        category: "",
        comments: ""

    }
    const handleSearch = (event: any) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        getCatgory()
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search !== null || search === "") {
                getApprovedOrder();
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [search, currentPage]);

    const getApprovedOrder = () => {
        setLoading(true);
        api
            .get(
                `/order/custom-order-list?status=${constants.ORDERS.APPROVED}&&page=${currentPage}&search_value=${search}`,
                true
            )
            .then(async function ([success, response]) {
                setApprovedOrder(response?.data || []);
                setPageData(response?.meta);
                setLoading(false);
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };
    const getCatgory = () => {
        api
            .get(`/product/categories`, true)
            .then(async function ([success, response]) {
                const arr: { value: number; label: string }[] = [];
                response?.data?.forEach((elem: any) => {
                    arr.push({ value: elem.id, label: elem.name });
                });
                setCategory(arr);
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getProduct = (id: number) => {
        setLoadingPro(true)
        api
            .get(`/product/product-list?category=[${id}]`, true)
            .then(async function ([success, response]) {
                const arr: { value: number; label: string }[] = [];
                response?.data?.forEach((elem: any) => {
                    arr.push({ value: elem.id, label: elem.name });
                });
                setProduct(arr);
                setLoadingPro(false)
                return response;
            })
            .catch((err) => {
                setLoadingPro(false)
                console.log(err);
            });
    };




    const getSingleOrder = (order_id: any) => {
        setLoading(true);
        api
            .get(`/order/get-proforma-details?id=${order_id}`, true)
            .then(async function ([success, response]) {
                setLoading(false);
                setOrderView(response?.message?.[0]);
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    function limitText(text: any, limit: any) {
        if (text?.length > limit) {
            return text.substring(0, limit) + "...";
        } else {
            return text;
        }
    }

    const handleSubmit = (values: any, actions: any) => {
        if (values?.product?.length !== 0) {
            let product_arry_: any = []
            values?.product?.map((item: any) => {
                product_arry_?.push(item?.value)
            })
            api
                .post(`/product/suggest-request`, {
                    product_ids: product_arry_,
                    custom_order_id: order_id,
                    comment: values?.comments,
                    suggestion_status: product_arry_?.length !== 0 ? constants?.COUSTME_ORDERS?.REQUEST_SEND :
                        constants?.COUSTME_ORDERS?.REQUEST_ACCEPT

                }, true)
                .then(async function ([success, response]) {
                    if (response?.status == "success") {
                        toast.success(response?.message)
                        setIsShowing(false)
                        actions.resetForm();
                        actions.setSubmitting(false);
                        getApprovedOrder()


                    }

                    return response;
                })
                .catch((err) => {

                    console.log(err);
                });

        }
        else {

            api
                .post(`/product/suggest-request`, {
                    product_ids: [],
                    custom_order_id: order_id,
                    comment: values?.comments,
                    suggestion_status:
                        constants?.COUSTME_ORDERS?.REQUEST_ACCEPT

                }, true)
                .then(async function ([success, response]) {
                    if (response?.status == "success") {
                        toast.success(response?.message)
                        setIsShowing(false)
                        actions.resetForm();
                        actions.setSubmitting(false);


                    }

                    return response;
                })
                .catch((err) => {

                    console.log(err);
                });
        }

    }



    return (
        <>
            <div className="right-content-wrap">
                <div className="breadcrumb-tabs-outer-wrap">
                    {/* breadcrumb */}
                    <List className="breadcrumb">
                        <li>
                            <Link className="active" to="/dashboard">
                                {Admin_orders?.Dashboard[language]}
                            </Link>
                        </li>
                        &nbsp; / &nbsp;

                        <li>
                            <b>{Admin_orders?.Customorders[language]}</b>
                        </li>
                    </List>
                </div>
                <div className="custom-tab-content">
                    <div className="table-outer-wrap">
                        <div className="search-widget-outer">

                            <Form className="d-flex" role="search">
                                <InputGroup className="search-group">
                                    <InputGroupText title="Product Name/Order ID" className="search-icon" id="search">
                                        <i className="fa-sharp fa-solid fa-magnifying-glass" />
                                    </InputGroupText>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder={Admin_orders?.ProductNameOrder[language]}
                                        aria-label="Username"
                                        aria-describedby="search"
                                        value={search}
                                        onChange={handleSearch}
                                        onKeyPress={(event) => {
                                            var key = event.keyCode || event.which;
                                            if (key === 13) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </Form>
                        </div>
                        <div className="table-wrap responsive-table" id="orders_table">
                            <Table className="w-100 table-striped">
                                <thead>
                                    <tr>
                                        <th>{Admin_orders?.SlNo[language]}</th>
                                        <th>{Admin_orders?.CustomOrderID[language]}</th>
                                        <th>{Admin_orders?.Product[language]} </th>
                                        <th>{Admin_orders?.Category[language]}</th>
                                        <th>{Admin_orders?.Department[language]}</th>
                                        <th>Product Allocation</th>
                                        <th>{Admin_orders?.OrderStatus[language]}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {approved_order?.length !== 0 ? (
                                        <>
                                            {approved_order?.map((item: any, i: any) => {
                                                let count = 0;
                                                return (
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>
                                                                <span className="mobile-heading">{Admin_orders?.OrderID[language]}:</span>
                                                                <Link
                                                                    to="#"
                                                                    className="primary-text"
                                                                    title="View Order"
                                                                    onClick={() => {
                                                                        setIsShowing(true)
                                                                        setOrderViewData(item)
                                                                        setOrderId(item?.id)
                                                                        setOrderview(true)
                                                                        //getSingleOrder(item?.id)
                                                                    }}

                                                                >
                                                                    {item?.custom_order_id}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <span className="mobile-heading">
                                                                    {Admin_orders?.ProductName[language]}:
                                                                </span>
                                                                <span className="table-data"><strong>{item?.product_name}</strong></span>

                                                            </td>
                                                            <td>
                                                                <span className="mobile-heading">
                                                                    {Admin_orders?.Category[language]}:
                                                                </span>
                                                                <span className="table-data">{item?.category}</span>

                                                            </td>
                                                            <td>
                                                                <span className="mobile-heading">
                                                                    {Admin_orders?.Department[language]}:
                                                                </span>
                                                                <span className="table-data">{item?.department?.name || '-'}</span>

                                                            </td>
                                                            <td>
                                                                <span className="mobile-heading">
                                                                    Product Allocation:
                                                                </span>
                                                                <span className="table-data">
                                                                    {
                                                                        item?.status == constants?.COUSTME_ORDERS?.NEW_ORDER ? (
                                                                            <Link
                                                                                to=""
                                                                                title="Send Request"
                                                                                onClick={() => {
                                                                                    setIsShowing(true)
                                                                                    setOrderViewData(item)
                                                                                    setOrderId(item?.id)
                                                                                    //getSingleOrder(item?.id)
                                                                                }}
                                                                            >
                                                                                {Admin_orders?.SendRequest[language]}
                                                                            </Link>
                                                                        )

                                                                            :
                                                                            <p>-</p>}
                                                                </span>

                                                            </td>
                                                            <td>
                                                                <span className="mobile-heading">
                                                                    {Admin_orders?.Status[language]}:
                                                                </span>
                                                                <span className="table-data">{CoustmeOrderStatus(item?.suggestion_status)}</span>

                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }
                                            )
                                            }
                                        </>) : <tr>
                                        <td className="text-center px-2 py-5 empty-text" colSpan={7}>
                                            {loading ? (
                                                <div className="d-flex justify-content-center">
                                                    <Loader />
                                                </div>
                                            ) : (
                                                "No Order Found"
                                            )}
                                        </td>
                                    </tr>}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            {pageData && approved_order.length !== 0 && (
                <div className="table-footer-wrap">
                    <small>
                        {Admin_orders?.Showing[language]} {pageData.from} to {pageData.to} of {pageData.total} {Admin_orders?.entries[language]}
                    </small>
                    <div className="pagination">
                        <CustomPagination
                            totalItems={pageData?.total}
                            itemsPerPage={pageData?.per_page}
                            activePage={pageData.current_page}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            )}

            <Formik
                initialValues={initialValues}
                validationSchema={suggestion == true ? orderValiadtionFirstSchema : orderValiadtionSecondSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    errors,
                    touched,
                    isSubmitting,
                    handleReset,
                    setFieldValue,
                    resetForm
                }) => {
                    return (
                        <>
                            <CoustmeOrdersModal
                                isShowing={isShowing}
                                setIsShowing={setIsShowing}
                                handleSubmit={handleSubmit}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                isSubmitting={isSubmitting}
                                handleReset={handleReset}
                                resetForm={resetForm}
                                setSuggestion={setSuggestion}
                                suggestion={suggestion}
                                order_view_data={order_view_data}
                                category={category}
                                getProduct={getProduct}
                                setFieldValue={setFieldValue}
                                product={product}
                                loadingPro={lodingProd}
                                order_views={order_views}

                            />

                        </>
                    );
                }}
            </Formik>

        </>
    );
}
