import React,{useContext} from 'react'
import { Link } from 'react-router-dom'
import { List } from 'reactstrap'
import { Context } from '../../../Components/Context/Context'
import { Department_TDS } from '../../../Library/LanguageConversion/Department_TDS'
const TDSListingLayout = ({ children }: any) => {
  const{language,setLanguage}=useContext(Context)
  return (
    <>
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/department/dashboard">
              {Department_TDS?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li><b>{Department_TDS?.TDS[language]}</b></li>
          </List>
        </div>
        {
          children
        }
      </div>
    </>
  )
}

export default TDSListingLayout