import * as Yup from "yup";

export const addVendorSchema = Yup.object().shape({
    gst_class: Yup.string(),
    pan_number: Yup.string()
        .min(10, "PAN Number should be atleast 10 characters")
        .max(10, "PAN Number should not be more than 10 characters"),
    gst_number: Yup.string()
        .min(15, "GST Number should be atleast 15 characters")
        .max(15, "GST Number should not be more than 15 characters"),
    company_name: Yup.string().required("Company name is required"),
    email: Yup.string()
        .email("Invalid email").required('Email is required'),
    full_name: Yup.string(),
    // phone: Yup.string().required("Contact person number is required").min(10).max(10),
    phone: Yup.string().required('Phone number is required')
        // .matches(/^\d{10}$/, "Phone number must be 10 digits"),
        .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
        .min(10, "Phone number must be at least 10 digits"),
    // comp_inc_date: Yup.date().required("Date required"),
    registration_id: Yup.string(),
    company_type: Yup.string(),
    comp_inc_date: Yup.date()
        .transform(function (value, originalValue) {
            if (this.isType(value)) {
                return value;
            }
            // const result = parent(originalValue, "dd.MM.yyyy", new Date());
            // return result;
        })
        .typeError("please enter a valid date"),
    expiry_date: Yup.date(),
    empanelment_date: Yup.date(),
    // startup_certificate:Yup.string().required('Startup Certificate is required')
});