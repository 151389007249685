import React, { useContext, useEffect, useState } from 'react'
import { DepartmentProducts } from '../../../../Library/LanguageConversion/Department_Products'
import { Context } from '../../../../Components/Context/Context';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Spinner } from 'reactstrap';
import { api } from '../../../../Library/CustomApi';
import { toast } from 'react-toastify';

interface propType {
    item: any,
    removeCart: Function,
    getCartList: Function
}
const CartItemTile = ({ item, removeCart, getCartList }: propType) => {
    const { language } = useContext(Context);
    const navigate = useNavigate()
    const [quantity, setQuantity] = useState<number>(item?.quantity || 0)
    const [totalAmount, setTotalAmount] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)


    useEffect(() => {
        let amount: number = item?.ratecontracts?.price + (item?.ratecontracts?.price * 0.05)
        let tgst: number = amount * (item?.Product?.producthsn?.[0]?.igst / 100)
        setTotalAmount(amount + tgst)
    }, [item])

    useEffect(() => {
        if (item?.quantity !== quantity) {
            setLoading(true)
            const timeout = setTimeout(() => {

                if (quantity == 0) {
                    setQuantity(1)
                    setLoading(false)
                }
                else {
                    handleCount(item.id, quantity)
                    console.log('Correct')
                }

            }, 1000);
            return () => clearTimeout(timeout)
        }
    }, [quantity])

    // increment/decrement quantity of item 
    const handleCount = (id: number, val: number) => {
        api
            .patch(
                `/product/cart/${id}`,
                {
                    quantity: val
                },
                true
            )
            .then(async function ([success, response]) {
                getCartList()
                setLoading(false)
                return response;
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
                toast.error('Failed to update quantity')
            });
    }


    return (
        <div>
            <tr>
                <td>
                    <div className="cart-product-details" onClick={() => {
                        navigate(`/department/products-view/${item?.Product?.id}`)
                    }}>

                        <div className="prod-image">
                            <img src={item?.Product.product_image.length > 0 ? item?.Product.product_image[0].path : "/images/product1.svg"} alt="icon" />
                        </div>

                        <div className="address-wrap">
                            <h6 className="title">
                                {item?.Product?.name}
                            </h6>
                            <small dangerouslySetInnerHTML={{
                                __html: item?.Product?.features,
                            }}>
                            </small>
                        </div>
                    </div>
                </td>

                <td>
                    <p className='quantity-select'><span className='quantity-select-inner'>
                        <Button disabled={quantity <= 1} onClick={() => quantity >= 1 && setQuantity(quantity - 1)}>-</Button>

                        <div className="input-cart-count-wrap">
                            <Input
                                type='text'
                                className="text-center"
                                value={quantity}
                                onChange={(e) => {
                                    Number(e.target.value) > 0 ? setQuantity(parseInt(e.target.value)) : setQuantity(0);
                                }}
                            />
                        </div>

                        <Button onClick={() => setQuantity(quantity + 1)}>+</Button>
                    </span>
                    </p>
                </td>
                <td className="cart-price green-text no-wrap">
                    {loading ?
                        <div className='text-center'>
                            <Spinner
                                as="span"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                            />
                        </div>
                        :
                        <>
                            <i className="bi bi-currency-rupee"></i>
                            {loading ? '' : (totalAmount * item?.quantity)?.toFixed(2)}
                            <small> ({DepartmentProducts?.GST[language]} {item?.Product?.producthsn?.[0]?.igst}% {DepartmentProducts?.Included[language]}  )</small>
                        </>}
                </td>
                <td onClick={() => removeCart(item?.id)} title="Remove From Cart"><i className="icons icons-bin"></i></td>
            </tr>
        </div>
    )
}

export default CartItemTile