import React, { useEffect, useRef,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import {
  file_remove,
  preventNegativeValues,
} from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;
export default function WorkOrderSection(props: any) {
  const {language,setLanguage}=useContext(Context);
  const workRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <div className="company-information-update-wrap">
        <h4>{Vendor_profileUpdation?.Work_Order_Details?.[language]}</h4>
        <hr />
        <Row>
          <Col md={4} sm={6}>
            <FormGroup>
              <Label>
              {Vendor_profileUpdation?.Work_Order_Certificate?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                <Input
                  type="file"
                  innerRef={workRef}
                  id="work_order_certificate"
                  name="work_order_certificate"
                  className="form-control"
                  accept=".pdf"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                ></Input>
                <Label
                  className="image-button"
                  for="work_order_certificate"
                  id="work_order_certificate"
                >
                 {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.work_order_certificate && (
                  <>
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "50px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove(
                          "work_order_certificate",
                          workRef,
                          "work_order_certificate"
                        );
                        props?.setFieldValue("work_order_certificate", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>
              <ErrorMessage
                name="work_order_certificate"
                component={TextError}
              />
            </FormGroup>
          </Col>{" "}
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.No_of_Projects_Done?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="work_order_no_of_projects"
                    type="number"
                    value={props?.values?.work_order_no_of_projects}
                    onChange={props?.handleChange}
                    min={1}
                    onKeyPress={preventNegativeValues}
                  />
                ) : (
                  <Input
                    name="work_order_no_of_projects"
                    type="number"
                    value={props?.values?.work_order_no_of_projects}
                    onChange={props?.handleChange}
                    min={1}
                    onKeyPress={preventNegativeValues}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="work_order_no_of_projects"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={12}>
            {props?.values?.work_order_no_of_projects &&
              props?.values?.work_order_certificate && (
                <>
                  <FormGroup>
                    <div className="custom-input">
                      <span className="mark-end text-success">
                      {Vendor_profileUpdation?.Mark?.[language]}:5
                      </span>
                    </div>
                  </FormGroup>
                </>
              )}
          </Col>
        </Row>
      </div>
    </>
  );
}
