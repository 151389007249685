export const DepartmentProducts:any = {

  //Department/Products/ProductsView

  Dashboard: {
      en: 'Dashboard',
      ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
  },
  Products: {
      en: 'Products',
      ka: 'ಉತ್ಪನ್ನಗಳು'
  },
  Product: {
      en: 'Product',
      ka: 'ಉತ್ಪಾದನೆ'
  },
  Price: {
      en: 'Price',
      ka: 'ಬೆಲೆ'
  },
  High_to_low : {
      en: 'High to low',
      ka: 'ಹೆಚ್ಚುಮಟ್ಟದಿಂದ ಕಡಮೆಯದು'
  },
 Low_to_High: {
      en: 'Low to high',
      ka: 'ಕಡಿಮೆಯಿಂದ ಹೆಚ್ಚು'
  },
  GST: {
      en: 'GST',
      ka: 'ಜಿಎಸ್ಟಿ'
  },
  Availability: {
      en: 'Availability',
      ka: 'ಲಭ್ಯತೆ'
  },
  Instock: {
      en: 'Instock',
      ka: 'ಸ್ಟಾಕ್‌ನಲ್ಲಿ ಇದೆ'
  },
  Quantity: {
      en: 'Quantity',
      ka: 'ಪ್ರಮಾಣ'
  },
  AddCart: {
      en: 'Add Cart',
      ka: 'ಕಾರ್ಟ್‌ಗೆ ಸೇರಿಸಿ'
  },
  DeliveryAddress: {
      en: 'Delivery Address',
      ka: 'ವಿತರಣಾ ವಿಳಾಸ'
  },
  // checked
  Select_an_address_to_continue: {
      en: 'Select an address to continue',
      ka: 'ಮುಂದುವರಿಯಲು ವಿಳಾಸವನ್ನು ಆಯ್ಕೆಮಾಡಿ'
  },

  //Department/Products/ProfomaCheckOut

  DownloadCreditBill: {
      en: 'Download Credit Bill',
      ka: 'ಕ್ರೆಡಿಟ್ ಬಿಲ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ'
  },
  SelectPaymentMode: {
      en: 'Select Payment Mode',
      ka: 'ಪಾವತಿ ಮೋಡ್ ಆಯ್ಕೆಮಾಡಿ'
  },
  Date_of_Order: {
      en: 'Date of Order',
      ka: 'ಆದೇಶದ ದಿನಾಂಕ'
  },
  TotalAmount: {
      en: 'Total Amount',
      ka: 'ಒಟ್ಟು ಮೊತ್ತ'
  },
  Total: {
    en: 'Total ',
    ka: 'ಒಟ್ಟು'
},
  Paying: {
      en: 'Paying',
      ka: 'ಪಾವತಿಸುತ್ತಿದೆ'
  },

  //Department/Products/ProfomaInvoice

  Commercial_Proposal: {
      en: 'Commercial Proposal',
      ka: 'ವಾಣಿಜ್ಯ ಪ್ರಸ್ತಾಪ'
  },
  InvoiceNo: {
      en: 'Invoice No.',
      ka: 'ಚಲಾನಿ ಸಂಖ್ಯೆ'
  },
  ValidFrom: {
      en: 'Valid From',
      ka: 'ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ'
  },
  ValidTo: {
      en: 'Valid To',
      ka: 'ಗೆ ಮಾನ್ಯವಾಗಿದೆ'
  },
  Download_Commercial_Proposal: {
      en: 'Download Commercial Proposal',
      ka: 'ವಾಣಿಜ್ಯಿಕ ಪ್ರಸ್ತಾವವನ್ನು ಡೌನ್ಲೋಡ್ ಮಾಡಿ'
  },
  SlNo: {
      en: 'Sl No',
      ka: 'ಕ್ರಮಸಂಖ್ಯೆ'
  },
  Product_Name: {
      en: 'Product Name',
      ka: 'ಉತ್ಪನ್ನ ಹೆಸರು'
  },
  Features: {
      en: 'Features',
      ka: 'ವೈಶಿಷ್ಟ್ಯಗಳು'
  },
  UnitPrice: {
      en: 'Unit Price',
      ka: 'ಯೂನಿಟ್ ಬೆಲೆ'
  },
  TotalPrice: {
      en: 'Total Price',
      ka: 'ಒಟ್ಟು ಬೆಲೆ'
  },
  SubTotal: {
      en: 'SubTotal',
      ka: 'ಉಪ ಮೊತ್ತ'
  },
  TDS_on_GST_at_Amount: {
      en: 'TDS on GST at 2% Amount',
      ka: 'ಜಿಎಸ್ಟಿ ಮೇಲೆ 2% ತಲುಪುಹಣಿ ವಸ್ತುವಿನ ಮೇಲೆ ತಲುಪುಹಣಿ'
  },
  Payable_Amount: {
      en: 'Payable Amount',
      ka: 'ಪಾವತಿಸಬೇಕಾದ ಮೊತ್ತ'
  },
  Cancel: {
      en: 'Cancel',
      ka: 'ರದ್ದು ಮಾಡಿ'
  },
  Upload_Work_Order: {
      en: 'Upload Work Order',
      ka: 'ಕೆಲಸದ ಆದೇಶವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ'
  },
  Confirm_Order: {
      en: 'Confirm Order',
      ka: 'ಆದೇಶವನ್ನು ದೃಢೀಕರಿಸಿ'
  },
  
  //Department/Products/UploadWorkOrderModel

  Choose_Documents: {
      en: 'Choose Documents',
      ka: 'ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ'
  },

  //Department/Products/ViewOrderCard

  OrderID: {
      en: 'Order ID',
      ka: 'ಆರ್ಡರ್ ಐಡಿ'
  },
  Total_Unit_Price: {
      en: 'Total Unit Price',
      ka: 'ಒಟ್ಟು ಘಟಕ ಬೆಲೆ'
  },
  Total_GST: {
      en: 'Total GST',
      ka: 'ಒಟ್ಟು ಜಿಎಸ್‌ಟಿ'
  },
  TDS_on_GST_Amount: {
      en: 'TDS on GST Amount',
      ka: 'GST ಮೊತ್ತದ ಮೇಲೆ TDS'
  },
  Total_Payable_Amount: {
      en: 'Total Payable Amount',
      ka: 'ಪಾವತಿಸಬೇಕಾದ ಒಟ್ಟು ಮೊತ್ತ'
  },
 
  //Department/Products/Cart/CartLayout

  Cart: {
      en: 'Cart',
      ka: 'ಕಾರ್ಟ್'
  },
  Custom_Order: {
      en: 'Custom Order',
      ka: 'ವೈಯಕ್ತಿಕ ಆದೇಶ'
  },

  //Department/Products/Cart/Tabs/AddCustomOrder

  ProductName: {
      en: 'Product Name',
      ka: 'ಉತ್ಪನ್ನದ ಹೆಸರು'
  },
  ReferenceLink: {
      en: 'Reference Link',
      ka: 'ಉಲ್ಲೇಖ ಲಿಂಕ್'
  },
  Comments: {
      en: 'Comments',
      ka: 'ಟಿಪ್ಪಣಿಗಳು'
  },
  Category: {
      en: 'Category',
      ka: 'ವರ್ಗ'
  },
  Select_Category: {
      en: 'Select Category',
      ka: 'ವರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
  },
  Select_Parent: {
      en: 'Select Parent',
      ka: 'ಪೋಷಕರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
  },
  Remove: {
      en: 'Remove',
      ka: 'ತೆಗೆದುಹಾಕಿ'
  },
  Filter: {
    en: 'Filter',
    ka: 'ವಿತರಣೆ'
},
  Add_Specification: {
      en: 'Add Specification',
      ka: 'ವಿಶೇಷಣೆಯನ್ನು ಸೇರಿಸಿ'
  },
  Add_Custom_Order: {
      en: 'Add Custom Order',
      ka: 'ಕಸ್ಟಮ್ ಆದೇಶವನ್ನು ಸೇರಿಸಿ'
  },

  //Department/Products/Cart/Tabs/Cart

  Add_Item_To_Cart: {
      en: 'Add Item To Cart',
      ka: 'ಕಾರ್ಟ್‌ಗೆ ಐಟಂ ಸೇರಿಸಿ'
  },
  Clear_Cart: {
      en: 'Clear Cart',
      ka: 'ಕಾರ್ಟ್ ತೆರವುಗೊಳಿಸಿ'
  },
  Included: {
      en: 'Included',
      ka: 'ಒಳಗೊಂಡಿತ್ತು'
  },
  Cart_Total: {
      en: 'Cart Total',
      ka: 'ಕಾರ್ಟ್ ಒಟ್ಟು'
  },
  Total_items: {
      en: 'Total items',
      ka: 'ಒಟ್ಟು ವಸ್ತುಗಳು'
  },
  GST_Included: {
      en: 'GST Included',
      ka: 'GST ಒಳಗೊಂಡಿದೆ'
  },
  Generate_Commercial_Proposal: {
      en: 'Generate Commercial Proposal',
      ka: 'ವಾಣಿಜ್ಯ ಪ್ರಸ್ತಾಪವನ್ನು ರಚಿಸಿ'
  },

  //Department/Products/Cart/Tabs/CustomOrder


  Date_of_Request : {
      en: 'Date of Request',
      ka: 'ವಿನಂತಿಯ ದಿನಾಂಕ'
  },
  Alternative_Suggestion : {
      en: 'Alternative Suggestion',
      ka: 'ಪರ್ಯಾಯ ಸಲಹೆ'
  },
  No_Suggestions : {
      en: 'No Suggestions',
      ka: 'ಯಾವುದೇ ಸಲಹೆಗಳಿಲ್ಲ'
  },
  Suggestions : {
    en: 'Suggestions',
    ka: 'ಸಲಹೆಗಳು'
},
  View_Suggestion : {
      en: 'View Suggestion',
      ka: 'ಸೂಚನೆಯನ್ನು ವೀಕ್ಷಿಸಿ'
  },
  Accepted : {
      en: 'Accepted',
      ka: 'ಸ್ವೀಕೃತ'
  },
  Rejected : {
      en: 'Rejected',
      ka: 'ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
  },
  Showing: {
      en: 'Showing',
      ka: 'ತೋರಿಸುವ'
  },
  to: {
      en: 'to',
      ka: 'ಗೆ'
  },
  of: {
      en: 'of',
      ka: 'ನ'
  },
  entries: {
      en: 'entries',
      ka: 'ಪ್ರವೇಶಗಳು'
  },

  //Department/Products/Cart/Tabs/CustomOrderModalView


  Reference : {
      en: 'Reference',
      ka: 'ಉಲ್ಲೇಖ'
  },
  Registered_Date : {
      en: 'Registered Date',
      ka: 'ನೋಂದಾಯಿತ ದಿನಾಂಕ'
  },
  Specifications : {
      en: 'Specifications',
      ka: 'ವಿಶೇಷಣಗಳು'
  },

   //Department/Products/Cart/Tabs/CustomOrder/CustomOrder


   No_Orders_Found : {
      en: 'No Orders Found',
      ka: 'ಯಾವುದೇ ಆದೇಶಗಳು ಕಂಡುಬಂದಿಲ್ಲ'
  },

  //Department/Products/Cart/Tabs/CustomOrder/CustomOrderContainers


  Custom_Orders : {
      en: 'Custom Orders',
      ka: 'ಕಸ್ಟಮ್ ಆದೇಶಗಳು'
  },

  //Department/Products/Cart/Tabs/CustomOrderModals/ProductSuggestionModal

  Accept_Suggestion : {
      en: 'Accept Suggestion',
      ka: 'ಸಲಹೆಯನ್ನು ಸ್ವೀಕರಿಸಿ'
  },
  Reject_Suggestion : {
      en: 'Reject Suggestion',
      ka: 'ಸಲಹೆಯನ್ನು ತಿರಸ್ಕರಿಸಿ'
  },

  //Department/Products/PaymentMethod/AccountDetails

  Bank_Details : {
      en: 'Bank Details',
      ka: 'ಬ್ಯಾಂಕ್ ವಿವರಗಳು'
  },
  Name_of_Account : {
      en: 'Name of Account',
      ka: 'ಖಾತೆಯ ಹೆಸರು'
  },
  Name_of_Bank : {
      en: 'Name of Bank',
      ka: 'ಬ್ಯಾಂಕ್ ಹೆಸರು'
  },
  BranchName : {
      en: 'Branch Name',
      ka: 'ಶಾಖೆಯ ಹೆಸರು'
  },
  AccountNo : {
      en: 'Account No',
      ka: 'ಖಾತೆ ಸಂಖ್ಯೆ'
  },
  IFSC : {
      en: 'IFSC',
      ka: 'ಐಎಫ್ಎಸ್ಸಿ'
  },
  MICRCode : {
      en: 'MICR Code',
      ka: 'ಎಂಐಸಿಆರ್ ಕೋಡ್'
  },
  Recipient_Code : {
      en: 'Recipient Code',
      ka: 'ಸ್ವೀಕರಿಸುವವರ ಕೋಡ್'
  },
  GST_No : {
      en: 'GST No',
      ka: 'ಜಿಎಸ್ಟಿ ಸಂಖ್ಯೆ'
  },
  Khajane_Recipient_ID : {
      en: 'Khajane-2 Recipient ID',
      ka: 'ಖಜಾನೆ-2 ಸ್ವೀಕರಿಸುವವರ ID'
  },

  //Department/Products/PaymentMethod/PaymentTypeSelector

  Direct_Bank_Transfer : {
      en: 'Direct Bank Transfer',
      ka: 'ಬ್ಯಾಂಕ್ ನೇರ ಬದಲಾವಣೆ'
  },
  NetBanking : {
      en: 'Net Banking',
      ka: 'ನೆಟ್ ಬ್ಯಾಂಕಿಂಗ್'
  },
  Cheque : {
      en: 'Cheque',
      ka: 'ಪರಿಶೀಲಿಸಿ'
  },
  Demand_Draft : {
      en: 'Demand Draft',
      ka: 'ಬೇಡಿಕೆ ಕರಡು'
  },
  RTGS : {
      en: 'RTGS',
      ka: 'ಆರ್ಟಿಜಿಎಸ್'
  },
  NEFT : {
      en: 'NEFT',
      ka: 'ಎನ್‌ಈಎಫ್‌ಟಿ'
  },

  //Department/Products/PaymentMethod/RightSection

  Confirm_entered_details_correct : {
      en: 'Confirm entered details are correct',
      ka: 'ನಮೂದಿಸಿದ ವಿವರಗಳು ಸರಿಯಾಗಿವೆಯೇ ಎಂದು ಖಚಿತಪಡಿಸಿ'
  },
  Confirm_Payment : {
      en: 'Confirm Payment',
      ka: 'ಪಾವತಿಯನ್ನು ದೃಢೀಕರಿಸಿ'
  },
  Note : {
      en: 'Note',
      ka: 'ಸೂಚನೆ'
  },
  Please_upload_drafts : {
      en: 'Please upload the challan from drafts',
      ka: 'ದಯವಿಟ್ಟು ಡ್ರಾಫ್ಟ್‌ಗಳಿಂದ ಚಲನ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ'
  },

  //Department/Products/Tabs/PaymentMode

  Enter_Amount : {
      en: 'Enter Amount',
      ka: 'ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ'
  },
  Confirm_Amount : {
      en: 'Confirm Amount',
      ka: 'ಮೊತ್ತವನ್ನು ದೃಢೀಕರಿಸಿ'
  },
  Amount_exceeded_limit : {
      en: 'Amount exceeded limit',
      ka: 'ಮೊತ್ತವು ಮಿತಿಯನ್ನು ಮೀರಿದೆ'
  },
  Confirm_Pay_Later : {
      en: 'Confirm Pay Later',
      ka: 'ನಂತರ ಪಾವತಿಯನ್ನು ದೃಢೀಕರಿಸಿ'
  },
  Item : {
    en: 'Item',
    ka: 'ಐಟಂ'
},
Rate : {
  en: 'Rate',
  ka: 'ದರ'
},
Amount : {
  en: 'Amount',
  ka: 'ಮೊತ್ತ'
},
Action : {
  en: 'Action',
  ka: 'ಕ್ರಿಯೆ'
},
Commercial_No : {
  en: 'Commercial No',
  ka: 'ವಾಣಿಜ್ಯ ಸಂಖ್ಯೆ'
},
Deductions : {
  en: 'Deductions',
  ka: 'ಕಡಿತಗಳು'
},
Add_Deduction : {
  en: 'Add Deduction',
  ka: 'ಕಡಿತವನ್ನು ಸೇರಿಸಿ'
},
No_Deductions_Selected : {
  en: ' No Deductions Selected',
  ka: 'ಯಾವುದೇ ಕಡಿತಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲಾಗಿಲ್ಲ'
},
NoDeduction: {
  en: '  No Deductions Saved for this invoice',
  ka: 'ಈ ಇನ್‌ವಾಯ್ಸ್‌ಗೆ ಯಾವುದೇ ಕಡಿತಗಳನ್ನು ಉಳಿಸಲಾಗಿಲ್ಲ'
},
}
