import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Admin_Products } from "../../../Library/LanguageConversion/Admin_Products";
import { Context } from "../../../Components/Context/Context";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  InputGroup,
  InputGroupText,
  Spinner
} from "reactstrap";
import TextError from "../../../Components/Errors/TextError";
import { api } from "../../../Library/CustomApi";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import ModalContainer from "../../../Components/Modal/ModalContainer";


interface ProductProps {
  isUploading: boolean,
  isShowing: boolean;
  setIsShowing: Function;
  handleSubmit: any;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  isSubmitting: any;
  handleReset: any;
  category: any;
  setFieldValue: any;
  setSelectedFile: any;
  setFormData: any;
  selectedFile: any;
  spec_data: any;
  show_add: any;
  setShowadd: any;
  cat: any;
  setSpecData: any;
  setCat: any;
  setCatValue: any;
  v: number;
  close_all: boolean;
  getCategory: Function
}
interface Specification {
  name: string;
  value: string;
  parent: string;
}
interface SpecId {
  product_id: string;
  spec_meta_id: string;
  key: string;
  value: string;
}

const UploadProductModal = (props: ProductProps) => {
  const [specifications, setSpecifications] = useState<Specification[]>([]);
  const [value, setValue] = useState({
    category_name: ""
  })
  
  const [cat_show, setCatShow] = useState(false)
  const [Specvalue, setSpecValue] = useState<SpecId[]>([]);
 
  const [images, setImages] = useState<string[]>([]);
  const [spec_data, setSpecData] = useState([]);
  const [data, setData] = useState<any>([]);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const handleImagechange = (e: any, values: any) => {
    setImages([...images, ...e?.target?.files])
    props?.setFieldValue("file", [...props?.values?.file, ...e.target.files]);
  };

  
  useEffect(() => {
    
    const allData = [...data,...specifications].flat()
    const d: any = JSON.stringify(allData);
    const jsonStr = d;
    const jsonArray = JSON.parse(jsonStr);
    const formattedArraynew = jsonArray
      .map((item: any) => `<b>${item.name}</b> : ${item.value}<br>`)
      .join(" ");
      props?.setFieldValue("product_description", formattedArraynew);
  },[specifications])
  

  useEffect(() => {
    props.setFieldValue("spec_details", spec_data);
  }, [spec_data]);

  useEffect(() => {
    setSpecData([]);
    setImages([]);
    setSpecifications([])
  }, [props?.close_all, props?.isShowing]);
  const [both, setBoth] = useState([])
  const [both1, setBoth1] = useState([])
  const [both2, setBoth2] = useState([])
  const handleSpecificationChange = (
    index: number,
    field: keyof Specification,
    value: string
  ) => {
    const newSpecifications: Specification[] = [...specifications];
    newSpecifications[index][field] = value;
    setSpecifications(newSpecifications);

    // const datas: any = [];

    // specifications?.map((elem: any) => {

    //   if (elem.value !== "") {
    //     datas.push({ name: elem.name, value: elem.value });
    //   }
    // });
    // data.push(...datas);

    const allData = [...data,...specifications].flat()
    const d: any = JSON.stringify(allData);
    const jsonStr = d;
    const jsonArray = JSON.parse(jsonStr);
    const formattedArraynew = jsonArray
      .map((item: any) => `<b>${item.name}</b> : ${item.value}<br>`)
      .join(" ");

    props?.setFieldValue("specifications", specifications);
    props?.setFieldValue("product_description", formattedArraynew);
  };

  //==================================================== Add Specification ==========================================
  const handleValueChange = (
    index: number,
    field: keyof SpecId,
    values: string,
    e: any
  ) => {
    
    const specData: SpecId[] = [...Specvalue];
    const { name, value } = e.target;
    const list: any = [...spec_data];
    list[index][`spec_details`] = value;
    const data: any = [];
    list.map((elem: any) => {
      if (elem.spec_details !== "") {
        data.push({ name: elem.name, value: elem.spec_details });
      }
    });
    setData(data);
    const d: any = JSON.stringify(data);
    const jsonStr = d;
    const jsonArray = JSON.parse(jsonStr);
    const formattedArray = jsonArray
      .map((item: any) => `<b>${item.name}</b> : ${item.value}<br>`)
      .join(" ");
    props?.setFieldValue("product_description", formattedArray);
    props?.setFieldValue("spec_details", list);
  };


  const handleRemove = (index: number) => {
   
    console.log(index,'index value')
    
    // const allData = [...data, ...specifications].flat()
    // console.log(allData, 'all data comes')


    // const d: any = JSON.stringify(allData);
    // const jsonStr = d;
    // const jsonArray = JSON.parse(jsonStr);
    // const formattedArraynew = jsonArray
    //   .map((item: any) => `<b>${item.name}</b> : ${item.value}<br>`)
    //   .join(" ");

    console.log(specifications, 'specification one two')
    // console.log(specifications.filter((_,i)=>i !== index) , 'console specification')
    const filteredSpec = specifications.filter((_, i) => i !== index);
    
    setSpecifications(filteredSpec);
    props?.setFieldValue("specifications", filteredSpec);
    


  };

  //===================================================================================================================
  const getSpecification = (id: any) => {
    api
      .get(`/product/collectmetaspecifications/${id}`, true)
      .then(async function ([success, response]) {
        response?.map((item: any) => {
          item.spec_details = "";
        });
        setSpecData(response || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //==================================================== Image delete =============================================

  const handleDeletes = (img: any, indexs: number) => {
    props?.setFieldValue(
      "file",
      props?.values.file.filter((_: any, index: any) => index !== indexs)
    );
    const s1 = images?.filter((key, index) => index !== indexs);
    setImages(s1);
  };

  //================================================== editor =====================================================
  const handleEditorChange = (content: any, editor: any) => {
    props?.setFieldValue("product_description", content);
  };

  const [err, setErr] = useState({
    category_name: ""
  })
  const handleChangeCat = (e: any) => {
    setValue({
      ...value,
      category_name: e.target.value
    })
    setErr({
      ...err,
      category_name: ""
    })
  }

  const handleSubmitCat = () => {
    sendCatData()
  }

  const sendCatData = () => {
    api
      .post(`/product/categories`, {
        name: value?.category_name
      }, true)
      .then(async function ([success, response]) {
        if (response?.errors) {

        }

        else {
          toast.success("Category created")
          setValue({
            ...value,
            category_name: ""
          })
          props?.getCategory()
          setCatShow(false)

        }
        return response;
      })
      .catch((err) => {
        console.log(err);
        setErr({
          ...err,
          category_name: err?.response?.data?.message
        })
        //toast.error(err?.response?.data?.message ? "Category name is required" : err?.response?.data?.message)
      });
  };
  //==================================================== view =====================================================
  const re = (e: any) => {
    var char = e.which || e.keyCode;
    if (char < 65 || char > 122 || (char > 90 && char < 97)) {
      e.preventDefault();
    }
  }
  const { language, setLanguage } = useContext(Context);
  return (

    <React.Fragment>

      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
          props?.setFormData();
          props?.setSpecData([]);
          props?.setCat();
          setSpecData([]);
          setImages([]);
          setCatShow(false)
          setErr({
            ...err,
            category_name: ""
          })
        }}
        header={props?.show_add ? "Upload Products" : "View Products"}
      >
        <div className="upload-prod-outer">
          <Form className="form-wrap">
            <Row>
              <Col md={12}>
                <FormGroup className="form-group">
                  <Label className="large-screen-view">
                  {Admin_Products?.UploadProductImage[language]}{" "}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <div className="image-upload-input">
                    <Input
                      
                      type="file"
                      name="file"
                      accept="image/*"
                      disabled={props?.show_add == true ? false : true}
                      title="Upload the Image"
                      multiple
                      onChange={(e: any) =>
                        handleImagechange(e, e.target.files[0])
                      }
                      className={`form-control ${props?.touched.file && props?.errors.file
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <span className="upload-text">{Admin_Products?.Upload_the_Image[language]}(s)</span>
                  </div>
                  <ErrorMessage name="file" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={12}>
                <div className="display-upload-image-wrap">
                  {images?.length > 0 &&
                    images.map((item: any, index, key) => {
                     
                      return (
                        
                        <React.Fragment key={index}>
                        
                          <div className="display-image" key={index}>
                            
                            <i
                              className="fa-solid fa-xmark "
                              onClick={() => handleDeletes(item, index)}
                              title="Remove photo"
                            ></i>
                            <img src={URL.createObjectURL(item)} alt="" className="img-fluid " />
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup className="form-group" >
                  <Label>
                  {Admin_Products?.ProductName[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    name="product_name"
                    type="text"
                    placeholder=""
                    value={props?.values?.product_name}
                    onChange={props?.handleChange}
                    disabled={props?.show_add == true ? false : true}
                    className={`form-control ${props?.touched.product_name &&
                      props?.errors.product_name
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  <ErrorMessage name="product_name" component={TextError} />
                </FormGroup>
              </Col>

              <Col md={4} sm={6}>
                <FormGroup className="form-group" >
                  <Label>
                  {Admin_Products?.ProductCode[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    name="product_code"
                    type="text"
                    value={props?.values?.product_code}
                    onChange={props?.handleChange}
                    disabled={props?.show_add == true ? false : true}
                    className={`form-control ${props?.touched.product_code &&
                      props?.errors.product_code
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  <ErrorMessage name="product_code" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup className="form-group" >
                  <Label>
                  {Admin_Products?.HSNCode[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    name="hsn_code"
                    type="text"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()

                    }
                    value={props?.values?.hsn_code}
                    disabled={props?.show_add == true ? false : true}
                    onChange={props?.handleChange}
                    className={`form-control ${props?.touched.hsn_code && props?.errors.hsn_code
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  <ErrorMessage name="hsn_code" component={TextError} />
                </FormGroup>
              </Col>

              <Col lg={12}>
              </Col>
              {!props?.values?.category && (<>  {

                cat_show == true ?
                  <div className="text-end mb-3">
                    {/* <Link to=""
                        className="red-text"
                        onClick={() => {
                          setCatShow(false);
                          setValue({
                            ...value,
                            category_name: ""

                          })
                          setErr({
                            ...err,
                            category_name: ""
                          })
                        }}><i className="bi bi-x-circle text-danger"></i> Close</Link> */}
                  </div> :
                  <div className="text-end mb-3">
                    <Link to=""
                      title="Click to Add Category"
                      className="blue-text"
                      onClick={() => setCatShow(true)}><i className="bi bi-plus-lg"></i>&nbsp;{Admin_Products?.Add_Category[language]}</Link>
                  </div>
              }
                {
                  cat_show == true && (<>   
                  <Col md={12} sm={12}>
                    <FormGroup>
                      <div>
                        <Label>
                        {Admin_Products?.Category[language]}{" "}
                          <i
                            className="fa fa-asterisk fa-1 required-field"
                            color="text-danger"
                            aria-hidden="true"
                          ></i>
                        </Label>
                      </div>
                      
                      {/* <InputGroup>
                        <Input
                          type="text"
                          name="category_name"
                          value={value?.category_name}
                          className={`form-control ${err?.category_name
                            ? "is-invalid"
                            : ""
                            }`}
                          onChange={(e: any) => handleChangeCat(e)}></Input>

                        <Button title="Click to Add Category" type="button" className="add-category-button" onClick={handleSubmitCat}>{Admin_Products?.Add[language]}</Button>

                        <Link to=""
                          className="red-text"
                          onClick={() => {
                            setCatShow(false);
                            setValue({
                              ...value,
                              category_name: ""

                            })
                            setErr({
                              ...err,
                              category_name: ""
                            })
                          }}>
                            <i className="bi bi-x-circle text-danger">{Admin_Products?.Close[language]}</i>
                        </Link>
                      </InputGroup> */}

                      <div className="add-button-test">
                      <InputGroup>
                        <Input
                          type="text"
                          name="category_name"
                          value={value?.category_name}
                          className={`form-control ${err?.category_name
                            ? "is-invalid"
                            : ""
                            }`}
                          onChange={(e: any) => handleChangeCat(e)}></Input>

                        <Button type="button" className="add-btn-test" onClick={handleSubmitCat}>{Admin_Products?.Add[language]}</Button>

                        <Link to=""
                          className=""
                          onClick={() => {
                            setCatShow(false);
                            setValue({
                              ...value,
                              category_name: ""

                            })
                            setErr({
                              ...err,
                              category_name: ""
                            })
                          }}>
                            <span className="close-icon">&times;</span>
                        </Link>
                      </InputGroup>
                      </div>

                      <ErrorMessage name="category_name" component={TextError} />
                      <span className="text-danger">{err?.category_name}</span>
                    </FormGroup>
                  </Col></>)
                }</>)}

              <Col md={12} sm={12}>
                <FormGroup className="form-group" >
                  <Label className="large-screen-view">
                  {Admin_Products?.Category[language]}{" "}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="select"
                    name="category"
                    value={props?.values?.category}
                    disabled={props?.show_add == true ? false : true}
                    data={props?.category}
                    onChange={(e) => {
                      getSpecification(e.target.value);
                      props?.setFieldValue("product_description", "");
                      setSpecData([]);
                      props?.setFieldValue("category", e.target.value);
                    }}
                    className={`form-control ${props?.touched.category && props?.errors.category
                      ? "is-invalid"
                      : ""
                      }`}
                  >
                    <option value="">{Admin_Products?.Select_Category[language]}</option>;
                    {props?.category?.map((item: any, i: number) => {
                      
                      return (
                        <React.Fragment key={i}>
                          <option key={i} value={item?.value}>
                            {" "}
                            {item?.label}
                          </option>
                        </React.Fragment>
                      );
                    })}
                  </Input>

                  <ErrorMessage name="category" component={TextError} />
                </FormGroup>
              </Col>

              {/* validation of new spc  start*/}
              <FieldArray
                name="spec_details"
                render={(arrayHelpers:any) => {
                  
                  return spec_data && spec_data.length > 0
                    ? spec_data.map((item: any, index: any) => (
                      
                      <Col md={6} sm={6} key={index}>
                        <div >
                          <FormGroup className="form-group" >
                            <Label>{item?.name} </Label>
                            <Input
                              name={`spec_details.${index}.spec_details`}
                              type="text"
                              value={item?.value}
                              disabled={
                                props?.show_add == true ? false : true
                              }
                              min="0"
                              onChange={(e) => {
                                handleValueChange(
                                  index,
                                  item?.name,
                                  e.target.value,
                                  e
                                );
                              }}
                              className={`form-control ${props?.touched?.spec_details?.[index]
                                ?.spec_details &&
                                props?.errors.spec_details?.[index]
                                  ?.spec_details
                                ? "is-invalid"
                                : ""
                                }`}
                            />
                            <ErrorMessage
                              name={`spec_details.${index}.spec_details`}
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>
                    ))
                    : null;
                }}

              />
              <div className=" add-spec-outer-wrap">
                {
                  props?.values?.category && (
                    <>
                      <FieldArray name="specifications">
                        {(arrayHelpers:any) => (
                          <div className="items-outer p-0">
                            {specifications.map((spec, index) => {
                              
                              return (
                             
                                <div key={index} className="item">
                                  <Row className="gy-4">
                                    <div className="mobile-view">
                                      <i className="bi bi-x-circle remove-btn text-danger"
                                        onClick={() => handleRemove(index)} title="Remove"></i>
                                    </div>
                                    <Col lg={6} md={3} sm={6}>
                                      <Input
                                        name={`specifications.${index}.name`}
                                        type="text"
                                        placeholder="Specification name"
                                        onChange={(e) => {
                                          handleSpecificationChange(
                                            index,
                                            "name",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col lg={6} md={3} sm={6}>
                                      <Input
                                        name={`specifications.${index}.value`}
                                        type="text"
                                        placeholder="Specification value"
                                        onChange={(e) => {
                                          handleSpecificationChange(
                                            index,
                                            "value",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col lg={6} md={3} sm={6}>
                                      <Input
                                        type="select"
                                        name={`props?.values.spec.${index}.type`}
                                        data={props?.spec_data}
                                        onChange={(e) => {
                                          handleSpecificationChange(
                                            index,
                                            "parent",
                                            e.target.value
                                          );
                                        }}
                                        className={`form-control ${props?.touched.version &&
                                          props?.errors.version
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                      >
                                        <option value="">{Admin_Products?.Select_Parent[language]}</option>;
                                        {spec_data?.map((item: any, i: number) => {
                                          {console.log("4",item?.id)}
                                          return (
                                            <React.Fragment key={item?.id}>
                                           
                                              <option key={item?.id} value={item?.id}>
                                                {" "}

                                                {item?.name}
                                              </option>
                                            </React.Fragment>
                                            
                                          );
                                        })}
                                      </Input>
                                      <ErrorMessage
                                        name={`props?.values.spec.${index}.type`}
                                        component={TextError}
                                      />
                                    </Col>

                                    <Col lg={6} md={3} sm={6} className="large-screen-view" >
                                      <Button
                                        type="button"
                                        color="danger"
                                        className={"col-12 px-3"}
                                        onClick={() => handleRemove(index)}
                                      >
                                        <i className="fa fa-times"></i>&nbsp; {Admin_Products?.Remove[language]}
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              )
                            })}

                            <FormGroup className="text-end my-3">
                              <Link to="" title="Add Specification" className="blue-text" onClick={() => {
                                const newField = {
                                  name: "",
                                  value: "",
                                  parent: "",
                                };
                                const newSpecifications = [
                                  ...specifications,
                                  newField,
                                ];
                                setSpecifications(newSpecifications);
                                arrayHelpers.push(newField);
                              }}>
                                <i className="bi bi-plus-lg"></i>&nbsp;{Admin_Products?.AddSpecification[language]}</Link>
                            </FormGroup>
                          </div>
                        )}
                      </FieldArray>
                    </>)}
              </div>
              {/* end */}
              <Col md={12}>
                <FormGroup>
                  <Label>
                  {Admin_Products?.ProductDescription[language]}{" "}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                 
                  <Editor
                    apiKey="4uglneyo7vlhlcq286kp5smo685svv51llytjx7u0wrx1kzz"
                    value={props?.values?.product_description}
                    textareaName="product_description"
                    init={{
                      height: 300,
                      //plugins: "table",
                      toolbar:
                        "undo redo | bold italic | underline | alignleft aligncenter alignright | bullist numlist table",
                    }}
                    // className={`form-control ${
                    //   props?.touched.product_description &&
                    //   props?.errors.product_description
                    //     ? "is-invalid"
                    //     : ""
                    // }`}
                    onEditorChange={handleEditorChange}
                  />
                  <ErrorMessage
                    name="product_description"
                    component={TextError}
                  />
                </FormGroup>
              </Col>

              {props?.show_add == true ? (
                <Row className="mx-0 footer-sticky text-end">

                  <FormGroup>
                    <Button
                      type="submit"
                      color="primary"
                      onClick={props?.handleSubmit}
                      className={"px-5"}
                    //  disabled={props?.isSubmitting}
                    >
                      {
                        props?.isUploading ?
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                          :
                          Admin_Products?.UploadProducts[language]
                      }
                    </Button>
                  </FormGroup>
                </Row>
              ) : null}
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </React.Fragment >
  );
};

export default UploadProductModal;