import React from "react";
import { useState,useContext } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  Button,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import { useEffect } from "react";
import { ErrorMessage, Formik } from "formik";
import TextError from "../../../../Components/Errors/TextError";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Tooltip } from "recharts";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../Components/Context/Context";
import { Admin_Settings } from "../../../../Library/LanguageConversion/Admin_Setting";
export default function PMUProfileSettings(args: any) {
  // modal
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [password, setpassword] = useState<any>("password");
  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);
  const [isLoading, setIsLoading] = useState(false);
  const [eye, seteye] = useState(true);
  const [profile, setProfile] = useState<any>([]);
  const [disabled, setDisabled] = useState(true);
  const [start, setStart] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [isSend, setSend] = useState(false);
  const [loadotp, setLoadOtp] = useState(false);
  const { language, setLanguage } = useContext(Context);

  useEffect(() => {
    getProfileLData();
  }, []);

  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };
  const getProfileLData = () => {
    setIsLoading(true);
    api
      .get(`/profile`, true)
      .then(async function ([success, response]) {
        setProfile(response?.[0]);

        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleSubmit = (values: any, actions: any) => {
    updateProfile(values, actions);
  };

  const updateProfile = (values: any, actions: any) => {
    api
      .patch(
        `/profile/1`,
        {
          email: values?.email,
          phone: values?.phone_number,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Profile updated successfully");
          actions.setSubmitting(false);
        }
        return response;
      })
      .catch((err) => {
        actions.setSubmitting(false);
        setIsLoading(false);
        console.log(err);
      });
  };

  const Passwordschema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords doesn't match")
      .required("Confirm Password is required"),
    otp: Yup.string().required("OTP is required"),
  });

  const SendOtp = () => {
    setLoadOtp(true);
    setSend(true);
    api
      .post(`/password_otp`, {}, true)
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(response?.message);
          setLoadOtp(false);
        }
        return response;
      })
      .catch((err) => {
        setLoadOtp(false);
        console.log(err);
      });
  };

  const handleUpdateSubmit = (values: any, actions: any) => {
    api
      .post(
        `/change-password`,
        {
          password: values?.password,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(response?.message);
          toggle();
        }
        return response;
      })
      .catch((err) => {
        actions.setSubmitting(false);
        console.log(err);
      });
  };

  const VerifyOtp = (values: any) => {
    setStart(true);
    api
      .post(
        `/check-password-otp`,
        {
          otp: values?.otp,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(response?.message);
          setDisabled(false);
          setStart(false);
          setVerified(true);
        }
        return response;
      })
      .catch((err) => {
        if (err.response?.status == 401) {
          toast.error(err?.response?.data?.message);
          setStart(false);
        }
        setVerified(false);
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className="profile-tab-content">
        <div className="table-outer-wrap ">
          <div className="main-wrap">
            <Formik
              // validationSchema={LoginValidationSchema}
              initialValues={{
                first_name: "" || profile?.email,
                email: "" || profile?.email,
                phone_number: "" || profile?.phone,
                password: "" || "admin@0r!sys",
              }}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                handleSubmit(values, actions);
              }}
            >
              {({
                errors,
                values,
                isSubmitting,
                handleChange,
                touched,
                handleSubmit,
              }) => (
                <>
                  <Form>
                    <Row>
                      {/* <Col sm={6}> */}
                      {/* <FormGroup>
                          <div className="custom-input">
                            <Label>Full Name</Label>
                            <Input
                              name="first_name"
                              type="text"
                              value={values?.first_name}
                              className={`form-control ${
                                touched.first_name && errors.first_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                            ></Input>
                            <ErrorMessage
                              name="first_name"
                              component={TextError}
                            />
                          </div>
                        </FormGroup> */}
                      {/* /  </Col> */}
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>{Admin_Settings?.Phone_Number[language]}</Label>
                            <Input
                              name="phone_number"
                              type="number"
                              disabled={true}
                              value={values?.phone_number}
                              className={`form-control ${touched.phone_number && errors.phone_number
                                ? "is-invalid"
                                : ""
                                }`}
                              onChange={handleChange}
                            ></Input>
                            <ErrorMessage
                              name="phone_number"
                              component={TextError}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>{Admin_Settings?.Password[language]}</Label>
                            <InputGroup>
                              <Input
                                name="password"
                                type="password"
                                value={values?.password}
                                className={`form-control ${touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={handleChange}
                                disabled
                              />
                              <ErrorMessage
                                name="password"
                                component={TextError}
                              />
                              <Button title="Change Password" color="primary" onClick={toggle}>
                                <i className="bi bi-pencil"></i>
                              </Button>
                            </InputGroup>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>{Admin_Settings?.Email[language]}</Label>
                            <InputGroup>
                              <Input
                                name="email"
                                type="email"
                                disabled={true}
                                value={values?.email}
                                className={`form-control ${touched.email && errors.email
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="email"
                                component={TextError}
                              />
                              {/* <Button color="primary" onClick={toggle2}>
                                <i
                                  // className="bi bi-exclamation-circle"
                                  onClick={Eye}
                                  className={`bi ${
                                    eye ? "bi bi-eye" : "bi-eye-slash"
                                  }`}
                                ></i>
                              </Button> */}
                            </InputGroup>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <div className="action-flex-btn">
                          {/* <Button
                            className="btn btn-primary"
                            color="primary"
                            type="submit"
                            onClick={() => handleSubmit()}
                            disabled={isSubmitting}
                          >
                            Update
                          </Button> */}
                          {/* <Button
                            className="btn btn-primary"
                            color="outline"
                            type="submit"
                          >
                            Cancel
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {/*Change Password Modal */}
      <div>
        <ModalContainer
          open={modal}
          setOpen={toggle}
          header='Change Password'
        >
          <div className="change-pass-wrap">
            <div className="change-password-form">
              <Formik
                validationSchema={Passwordschema}
                initialValues={{
                  password: "",
                  confirm_password: "",
                  otp: "",
                }}
                onSubmit={(values, actions) => {
                  handleUpdateSubmit(values, actions);
                }}
              >
                {({
                  errors,
                  values,
                  isSubmitting,
                  handleChange,
                  touched,
                  handleSubmit,
                  setFieldTouched,
                  isValid,
                }) => (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <div className="custom-input">
                              <Label>{Admin_Settings?.Enter_Password[language]}</Label>
                              <InputGroup>
                                <Input
                                  name="password"
                                  type="password"
                                  value={values?.password}
                                  className={`form-control ${touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  onChange={(e) => {
                                    setFieldTouched("password");
                                    handleChange(e);
                                  }}
                                />
                                {/* <InputGroupText>
                                    <i className="bi bi-eye primary-text"></i>
                                  </InputGroupText> */}
                              </InputGroup>
                              <ErrorMessage
                                name="password"
                                component={TextError}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <div className="custom-input">
                              <Label>{Admin_Settings?.Confirm_Password[language]}</Label>
                              <InputGroup>
                                <Input
                                  name="confirm_password"
                                  type={password}
                                  aria-describedby="newpass"
                                  value={values?.confirm_password}
                                  className={`form-control ${touched.confirm_password &&
                                    errors.confirm_password
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  onChange={(e) => {
                                    setFieldTouched("confirm_password");
                                    handleChange(e);
                                  }}
                                />
                                <InputGroupText id="newpass">
                                  <i
                                    // className="bi bi-eye primary-text"
                                    onClick={Eye}
                                    className={`bi ${eye
                                      ? "bi bi-eye primary-text"
                                      : "bi-eye-slash"
                                      }`}
                                  ></i>
                                </InputGroupText>
                              </InputGroup>
                              <ErrorMessage
                                name="confirm_password"
                                component={TextError}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          {!loadotp ? (
                            <Link
                              to="#"
                              className="text-decoration-none otp-link"
                              onClick={() =>
                                values?.password &&
                                values?.confirm_password &&
                                SendOtp()
                              }
                            >
                              {values?.password &&
                                values?.confirm_password &&
                                !errors.confirm_password &&
                                !errors.password ? (
                                <>
                                  <small>
                                    {isSend ? "Resend" : "Send"} OTP
                                  </small>{" "}
                                </>
                              ) : null}
                            </Link>
                          ) : (
                            <Spinner
                              as="span"
                              variant="light"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                            />
                          )}
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <div className="custom-input">
                              <Label>{Admin_Settings?.Enter_OTP[language]}</Label>
                              <InputGroup>
                                <Input
                                  name="otp"
                                  type="number"
                                  value={values?.otp}
                                  className={`form-control ${touched.otp && errors.otp
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  onChange={handleChange}
                                ></Input>
                                <ErrorMessage
                                  name="otp"
                                  component={TextError}
                                />
                                {!start ? (
                                  <InputGroupText
                                    style={{ cursor: "pointer" }}
                                    title="Verify the otp here"
                                    onClick={() =>
                                      values?.otp && VerifyOtp(values)
                                    }
                                  >
                                    <i
                                      className="fa fa-arrow-right"
                                      aria-hidden="true"
                                    ></i>
                                  </InputGroupText>
                                ) : start ? (
                                  <span className="otp-scanner">
                                    <Spinner
                                      as="span"
                                      variant="light"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      animation="border"
                                    />
                                  </span>
                                ) : null}
                              </InputGroup>
                              {/* {values?.otp ? (
                                  start == true ? (
                                    <span className="otp-scanner">
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </span>
                                  ) : (
                                    <span className="otp-scanner">
                                      <i className="fa fa-check-circle text-success"></i>
                                    </span>
                                  )
                                ) : null} */}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <div className="action-flex-btn justify-content-end">
                            <Button
                              color="primary"
                              type="submit"
                              className=""
                              disabled={disabled == true ? true : false}
                            >
                             {Admin_Settings?.Update[language]}
                            </Button>
                            {/* <Button
                                color="outline"
                                disabled={values?.otp ? false : true}
                                onClick={() => VerifyOtp(values)}
                              >
                                Verify
                              </Button> */}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </ModalContainer>
      </div>

      {/*Email Verification Modal */}
      <div>
        <ModalContainer
          open={modal2}
          setOpen={toggle2}
          header='Email Verification'
        >
          <div className="email-verify-wrap">
            <div className="heading">
              <h5 className="primary-text">
              {Admin_Settings?.A1[language]}
              </h5>
            </div>
            <div className="forgot-password-form">
              <small>
              {Admin_Settings?.A2[language]}
              </small>
            </div>
          </div>
        </ModalContainer>
      </div>
    </React.Fragment >
  );
}
