import React, { useContext, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Button, Col, Input, List, Row, Spinner } from "reactstrap";
import { Context } from '../../../Components/Context/Context';
import Loader from '../../../Components/Loader/Loader';
import ModalContainer from '../../../Components/Modal/ModalContainer';
import { api } from "../../../Library/CustomApi";
import AddressChange from '../DeliveryAddress/AddressLayout';

import { DepartmentProducts } from '../../../Library/LanguageConversion/Department_Products';


export default function DeptProductsView() {
  const { updateCartCount } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>()
  const [isAddressLoading, setAddressIsLoading] = useState<boolean>()
  const [addIsLoading, setAddIsLoading] = useState<boolean>()
  const [productData, setProductData] = useState<any>()
  const [specData, setSpecData] = useState<any>()
  const [itemCount, setItemCount] = useState<number>(1)
  const [open, setOpen] = useState<boolean>(false)
  const { pathname } = useLocation();
  const [addressData, setAddressData] = useState([]);
  const [addressError, setAddressError] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<any>([])
  const [specShowAll, setSpecShowAll] = useState<boolean>(false)
  const navigate = useNavigate()
  const { language } = useContext(Context);

  const productId = useParams().id;

  // image gallery 
  const images = productData?.images.map((item: any) => {
    return {
      original: item.path,
      thumbnail: item.path,
    };
  });

  useEffect(() => {
    productId ? getProductData() : navigate('/department/products') // to redirect if no id in state
    getAddress()
  }, [])

  useEffect(() => {
    if (!open) {
      getAddress()
    }
  }, [open])

  useEffect(() => {
    setSelectedAddress(addressData?.filter((obj: any) => {
      return obj?.status == 2
    }))
  }, [addressData])

  const getAddress = () => {
    setAddressIsLoading(true)
    api
      .get(`/address`, true)
      .then(async function ([success, response]) {
        setAddressData(response?.data || []);
        setAddressIsLoading(false)
        return response;
      })
      .catch((err) => {
        setAddressIsLoading(false)
        console.log(err);
      });
  };

  // products data fetching 
  const getProductData = () => {
    setIsLoading(true);
    api
      .get(`/product/products/${productId}`, true)
      .then(async function ([success, response]) {
        setProductData(response?.data || []);
        setSpecData(response?.data?.specifications?.filter((spec: any) => spec?.value?.length !== 0 && spec?.key?.length !== 0))
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        navigate('/department/products')
      });
  };

  const handleAddCart = () => {
    setAddIsLoading(true)
    const values: object = {
      product_id: productData?.id,
      quantity: itemCount,
    }

    api
      .post(`/product/cart`, values, true,)
      .then(async function ([success, response]) {
        toast.success("Item Added", {
          toastId: 'addCart',
          position: 'bottom-right'
        })
        updateCartCount()
        setAddIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Unexpected Error Occured", {
          toastId: 'errorCart'
        })
        setAddIsLoading(false)
      });
  }

  // spec show all / show less handler 
  const specShowHandler = () => {
    setSpecShowAll(!specShowAll)
  }

  return (
    <>
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumbs */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/department/dashboard">
              {DepartmentProducts?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <Link className="active" to="/department/products">
              {DepartmentProducts?.Products[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li><b>{DepartmentProducts?.Product[language]}</b></li>
          </List>
        </div>
        {isLoading ?
          <div className='text-center py-5'>
            <Loader />
          </div>
          :
          <div className="product-specifications-wrap mb-5">
            <Row className='gy-4'>
              <Col sm={12} md={12} lg={5} xl={4}>
                <Row className="gy-3 gx-3 ">
                  <Col md={12}>
                    {/* image gallery */}
                    {images &&
                      <ImageGallery
                        items={images}
                        showNav={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        lazyLoad
                      />}
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12} lg={7} xl={8}>
                <div className="specifications-content-wrap">
                  <div className="main-specifications-wrap">
                    <h2 className="main-heading">{productData?.name}</h2>
                    <hr />
                    <div>{DepartmentProducts?.Price[language]}:<span className='green-text h3'>
                      <i className="bi bi-currency-rupee"></i>
                      {(productData?.rate[0]?.price + (productData?.rate[0]?.price * 0.05))?.toFixed(2)}
                    </span>
                      <small>&nbsp; (+ {DepartmentProducts?.GST[language]}
                        <i className="bi bi-currency-rupee"></i>
                        {((productData?.rate[0]?.price + (productData?.rate[0]?.price * 0.05)) * (productData?.producthsn?.[0]?.igst / 100)).toFixed(2)})</small>
                    </div>
                    <table className="table table-hover table-bordered table-results-product-outer">

                      <thead>
                        <tr className='sticky'>
                          <th className='col-lg-4'> {DepartmentProducts?.Availability[language]}:</th>
                          <th className='col-lg-8'><span className="text-success">
                            <i className="bi bi-check2 mx-2" />
                            {DepartmentProducts?.Instock[language]}
                          </span></th>
                        </tr>
                      </thead>
                      <tbody>

                        {/* specifications of product  */}
                        {
                          specData?.map((spec: any, i: number) => {
                            return (
                              <React.Fragment key={i}>
                                {(!specShowAll ? i < 9 : true) && <>
                                  {spec?.key?.length !== 0 && spec?.value?.length !== 0 &&
                                    <tr>
                                      <th scope="row">{spec.key}:</th>
                                      <td>{spec.value}</td>
                                    </tr>}
                                </>}
                              </React.Fragment>
                            )
                          })
                        }
                        {productData?.manufacturers?.length !== 0 && <tr>
                          <th scope="row">{productData?.manufacturers?.length > 1 ? "Manufacturers:" : "Manufacturer:"}</th>
                          <td>{
                            productData?.manufacturers?.map((obj: any, i: number) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{obj.name}</span>
                                  {
                                    productData?.manufacturers?.length > 1 && productData?.manufacturers?.length !== i + 1 && ","
                                  }
                                </React.Fragment>
                              )
                            })
                          }</td>
                        </tr>}

                        {/* show all / show less  */}
                        {specData?.length > 9 && <tr onClick={specShowHandler}>
                          <th colSpan={2} className='text-center show-more-less-text'><span>{specShowAll ? 'Show Less' : 'Show All'}</span></th>
                        </tr>}

                      </tbody>
                    </table>
                  </div>
                  <div className="address-specifications-wrap mb-4">
                    <p className="quantity-select">
                      <strong>{DepartmentProducts?.Quantity[language]}:</strong>
                      <span className="quantity-select-inner">
                        <Button onClick={() => { itemCount > 1 ? setItemCount(itemCount - 1) : setItemCount(0) }} title='Reduce Quantity'>-</Button>
                        <Input type="text" title='Quantity' onChange={(e) => Number(e.target.value) > 0 ? setItemCount(parseInt(e.target.value)) : setItemCount(0)} value={itemCount} />
                        <Button onClick={() => setItemCount(itemCount + 1)} title='Increase Quantity'>+</Button>
                      </span>
                    </p>


                    <div className="add-cart">
                      <Button className="btn btn-primary" disabled={addIsLoading || itemCount < 1} color="primary" onClick={selectedAddress?.length ? handleAddCart : () => {
                        setAddressError(true)
                      }}>
                        {addIsLoading ?
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                          :
                          <>
                            <i className="bi bi-cart2">&nbsp;</i>{DepartmentProducts?.AddCart[language]}
                          </>}
                      </Button>
                    </div>
                    <div className="address-outer">
                      <h6 className="sub-heading">{DepartmentProducts?.DeliveryAddress[language]}:</h6>
                      <div className='address-holder'>
                        <div className="address-inner">

                          {selectedAddress.length !== 0 ?
                            <>
                              <Input type="radio" checked onChange={() => null} title='Send to default address'></Input>

                              {selectedAddress?.map((obj: any, i: number) => {
                                return (
                                  <React.Fragment key={i}>
                                    <span className='ms-3 address-fix-space'>
                                      {`Building No: ${obj.building_no}`}
                                      <br />
                                      {`${obj.city},${obj.landmark}, ${obj.district}`}<br />{` Pin: ${obj.pincode}`}<br />
                                      {`${obj.state}, ${obj.country}`}
                                    </span>
                                  </React.Fragment>
                                )
                              })}
                            </>
                            :
                            <>
                              <div>
                                {addressError && !isAddressLoading && <small className='text-danger' onClick={() => setOpen(true)}>{DepartmentProducts?.Select_an_address_to_continue[language]}</small>}
                              </div>
                              {isAddressLoading && <div className='text-center w-100'><Loader /></div>}
                            </>
                          }

                        </div>
                      </div>
                      <div className="change-address-btn ms-2" title='Change Address'>
                        <Link to="" className="green-text" onClick={() => setOpen(true)}>{selectedAddress?.length > 0 ? "Change Address" : "Select Address"}</Link>
                      </div>
                    </div>
                  </div>

                  <ModalContainer open={open} setOpen={setOpen} header="Select Address" >
                    <AddressChange setOpen={setOpen} getAddress={getAddress} />
                  </ModalContainer>

                </div>
              </Col>
            </Row>
          </div>}
      </div>
    </>
  );
}
