import React, { useState, useContext } from "react";
import { List } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Context } from "../../../../Components/Context/Context";
import { Admin_RateContract } from "../../../../Library/LanguageConversion/Admin_RateContract";
export default function ProductComparison() {
  const {language, setLanguage} = useContext(Context)
  return (
    <>
      {/* inner contents starts here */}
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="d-flex align-items-baseline justify-content-between mb-4 flex-wrap gap-4">
          {/* tabs */}
          <div className="heading-2 ">
            <h5 className=''>{Admin_RateContract?.Product_Details[language]}</h5>
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li><Link className="active p-0 text-black text-decoration-none " to='' style={{ minWidth: 'auto' }}>Dashboard</Link></li>&nbsp; / &nbsp;
            <li><Link className="active p-0 text-black text-decoration-none" to='' style={{ minWidth: 'auto' }}>Contract Rate</Link></li>&nbsp; / &nbsp;
            <li><b>{Admin_RateContract?.Upload_Rate_Contract[language]}</b></li>
          </List>
        </div>
        <div className="specifications-wrap mb-5">
          <div className="row">
            <div className="col-md-3">
              <div className="row gy-3 gx-3">
                <div className="col-md-12">
                  <div className="spec-main-img">
                    <img src="images/pcspec.svg" alt="img" className='w-100' />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="spec-sub-img border p-2">
                    <img src="images/spec-sub.svg" alt="img" className='w-100' />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="spec-sub-img border p-2">
                    <img src="images/spec-sub.svg" alt="img" className='w-100' />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="spec-sub-img border p-2">
                    <img src="images/spec-sub.svg" alt="img" className='w-100' />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="specifications-content-wrap">
                <div className="main-specifications-wrap">
                  <h2 className='main-heading'>{Admin_RateContract?.Dell_Laptop[language]}</h2>
                  <p>{Admin_RateContract?.Dell1[language]}</p>
                  <small className={'d-block'}>{Admin_RateContract?.Panel_Type[language]}:&nbsp;<span>{Admin_RateContract?.VA_Panel[language]}</span></small>
                  <small className={'d-block'}>{Admin_RateContract?.Screen_Resolution_Type[language]}:&nbsp;<span>{Admin_RateContract?.Full_HD[language]}</span></small>
                  <small className={'d-block'}>{Admin_RateContract?.Response_Time[language]}&nbsp;|&nbsp;{Admin_RateContract?.Refresh_Rate[language]}</small>
                </div>
                <div className="general-specifications-wrap my-4">
                  <h6 className="sub-heading">{Admin_RateContract?.General_Specifications[language]}</h6>
                  <small className={'d-block'}>{Admin_RateContract?.Model_Name[language]}:&nbsp;<span>D24-20</span></small>
                  <small className={'d-block'}>{Admin_RateContract?.Color[language]}:&nbsp;<span>{Admin_RateContract?.Raven_Black[language]}</span></small>
                  <small className={'d-block'}>{Admin_RateContract?.Display[language]}:&nbsp;<span>60.45 cm (23.8 inch) LCD Display</span></small>
                  <small className={'d-block'}>{Admin_RateContract?.Color[language]}:&nbsp;<span>{Admin_RateContract?.Raven_Black[language]}</span></small>
                  <small className={'d-block'}>{Admin_RateContract?.Panel_Type[language]}:&nbsp;<span>{Admin_RateContract?.VA_Panel[language]}</span></small>
                  <small className={'d-block'}>3D:&nbsp;<span>{Admin_RateContract?.Screen_Resolution_Type[language]}</span></small>
                  <small className={'d-block'}>{Admin_RateContract?.Sales_Package[language]}:&nbsp;<span>{Admin_RateContract?.packages[language]}</span></small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spec-table-outer-wrap">
          {/* tabs */}
          <div className="spec-table-tabs d-flex align-items-center gap-4 flex-wrap ">
            <Link to="/specifications"
              className="  primary-bg  text-decoration-none"
            >
              {Admin_RateContract?.View_Vendors[language]}
            </Link>
            <Link
              to='/product-comparison'
              className="active  primary-bg  text-decoration-none"
            >
              {Admin_RateContract?.Product_Comparison[language]}
            </Link>
          </div>
          <div className="comparison-wrap border">
            <div className="row mx-0">
              <div className="col-md-4  px-md-0 px-lg-0 px-xl-0 px-xxl-0">
                <div className="comparsion-head">
                  <h5 className='text-center my-4'>{Admin_RateContract?.Keonics[language]}</h5>
                </div>
                <div className="comparsion-content">
                  <div className="comparison-thumb border">
                    <img src="images/thumb.svg" alt="img" className='d-block mx-auto w-100' />
                  </div>
                  <div className="main-specifications-wrap px-4">
                    <h2 className='main-heading'>{Admin_RateContract?.Dell_Laptop[language]}</h2>
                    <p>{Admin_RateContract?.Dell1[language]}</p>
                    <small className={'d-block'}>{Admin_RateContract?.Panel_Type[language]}:&nbsp;<span>{Admin_RateContract?.VA_Panel[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Screen_Resolution_Type[language]}:&nbsp;<span>{Admin_RateContract?.Full_HD[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Response_Time[language]}&nbsp;|&nbsp;{Admin_RateContract?.Refresh_Rate[language]}</small>
                  </div>
                  <div className="general-specifications-wrap my-4 px-4">
                    <h6 className="sub-heading">{Admin_RateContract?.General_Specifications[language]}</h6>
                    <small className={'d-block'}>{Admin_RateContract?.Model_Name[language]}:&nbsp;<span>D24-20</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Color[language]}:&nbsp;<span>{Admin_RateContract?.Raven_Black[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Display[language]}:&nbsp;<span>60.45 cm (23.8 inch) {Admin_RateContract?.LCD_Display[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Color[language]}:&nbsp;<span>{Admin_RateContract?.Raven_Black[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Panel_Type[language]}:&nbsp;<span>{Admin_RateContract?.VA_Panel[language]}</span></small>
                    <small className={'d-block'}>3D:&nbsp;<span>{Admin_RateContract?.Resolution_Type[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Sales_Package[language]}:&nbsp;<span>{Admin_RateContract?.packages[language]}</span></small>
                  </div>
                  <div className="price-section px-4 mb-4">
                    <small className='d-inline-block border px-4 py-2 rounded-2'>{Admin_RateContract?.Price[language]}:&nbsp;<span>32,365</span></small>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-md-0 px-lg-0 px-xl-0 px-xxl-0">
                <div className="comparsion-head">
                  <h5 className='text-center my-4'>{Admin_RateContract?.Amazon[language]}</h5>
                </div>
                <div className="comparsion-content">
                  <div className="comparison-thumb border">
                    <img src="images/thumb.svg" alt="img" className='d-block mx-auto w-100' />
                  </div>
                  <div className="main-specifications-wrap px-4">
                    <h2 className='main-heading'>{Admin_RateContract?.Dell_Laptop[language]}</h2>
                    <p>{Admin_RateContract?.Dell1[language]}</p>
                    <small className={'d-block'}>{Admin_RateContract?.Panel_Type[language]}:&nbsp;<span>{Admin_RateContract?.VA_Panel[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Screen_Resolution_Type[language]}:&nbsp;<span>{Admin_RateContract?.Full_HD[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Response_Time[language]}&nbsp;|&nbsp;{Admin_RateContract?.Refresh_Rate[language]}</small>
                  </div>
                  <div className="general-specifications-wrap my-4 px-4">
                    <h6 className="sub-heading">{Admin_RateContract?.General_Specifications[language]}</h6>
                    <small className={'d-block'}>{Admin_RateContract?.Model_Name[language]}:&nbsp;<span>D24-20</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Color[language]}:&nbsp;<span>{Admin_RateContract?.Raven_Black[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Display[language]}:&nbsp;<span>60.45 cm (23.8 inch) {Admin_RateContract?.LCD_Display[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Color[language]}:&nbsp;<span>{Admin_RateContract?.Raven_Black[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Panel_Type[language]}:&nbsp;<span>{Admin_RateContract?.VA_Panel[language]}</span></small>
                    <small className={'d-block'}>3D:&nbsp;<span>{Admin_RateContract?.Resolution_Type[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Sales_Package[language]}:&nbsp;<span>{Admin_RateContract?.packages[language]}</span></small>
                  </div>
                  <div className="price-section px-4 mb-4">
                    <small className='d-inline-block border px-4 py-2 rounded-2'>{Admin_RateContract?.Price[language]}:&nbsp;<span>32,365</span></small>
                  </div>
                </div>
              </div>
              <div className="col-md-4  px-md-0 px-lg-0 px-xl-0 px-xxl-0">
                <div className="comparsion-head">
                  <h5 className='text-center my-4'>{Admin_RateContract?.Flipkart[language]}</h5>
                </div>
                <div className="comparsion-content">
                  <div className="comparison-thumb border">
                    <img src="images/thumb.svg" alt="img" className='d-block mx-auto w-100' />
                  </div>
                  <div className="main-specifications-wrap px-4">
                    <h2 className='main-heading'>{Admin_RateContract?.Dell_Laptop[language]}</h2>
                    <p>{Admin_RateContract?.Dell1[language]}</p>
                    <small className={'d-block'}>{Admin_RateContract?.Panel_Type[language]}:&nbsp;<span>{Admin_RateContract?.VA_Panel[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Screen_Resolution_Type[language]}:&nbsp;<span>{Admin_RateContract?.Full_HD[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Response_Time[language]}&nbsp;|&nbsp;{Admin_RateContract?.Refresh_Rate[language]}</small>
                  </div>
                  <div className="general-specifications-wrap my-4 px-4">
                    <h6 className="sub-heading">{Admin_RateContract?.General_Specifications[language]}</h6>
                    <small className={'d-block'}>{Admin_RateContract?.Model_Name[language]}:&nbsp;<span>D24-20</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Color[language]}:&nbsp;<span>{Admin_RateContract?.Raven_Black[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Display[language]}:&nbsp;<span>60.45 cm (23.8 inch) {Admin_RateContract?.LCD_Display[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Color[language]}:&nbsp;<span>{Admin_RateContract?.Raven_Black[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Panel_Type[language]}:&nbsp;<span>{Admin_RateContract?.VA_Panel[language]}</span></small>
                    <small className={'d-block'}>3D:&nbsp;<span>{Admin_RateContract?.Resolution_Type[language]}</span></small>
                    <small className={'d-block'}>{Admin_RateContract?.Sales_Package[language]}:&nbsp;<span>{Admin_RateContract?.packages[language]}</span></small>
                  </div>
                  <div className="price-section px-4 mb-4">
                    <small className='d-inline-block border px-4 py-2 rounded-2'>{Admin_RateContract?.Price[language]}:&nbsp;<span>32,365</span></small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
};
