import React, { useEffect,useState,useContext } from "react";
import { ErrorMessage } from "formik";

import { Context } from "../../../../Components/Context/Context";
import { toast } from "react-toastify";
import {Admin_orders} from "../../../../Library/LanguageConversion/Admin_orders";
import {
  Form,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import TextError from "../../../../Components/Errors/TextError";
import { api } from "../../../../Library/CustomApi";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
interface VendorProps {
  isShowing: boolean;
  setIsShowing: Function;
  handleSubmit: any;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  isSubmitting: any;
  handleReset: any;
  handleBlur: any;
  pro_data: any;
  order_data: any;
  ship_data: any;
  length: any;
  vendor_details: any;
  history: any;
}

const VendorAllocationModal = (props: VendorProps) => {
  const truncateText = (text: any) => {
    return text.replace(/<br>/g, ", ");
  };

  
  const { language, setLanguage } = useContext(Context);
  const [disabled, setdisabled] = useState(true);

  useEffect(() => {
    setdisabled(true);
    const delayDebounceFn = setTimeout(() => {
      if (props?.values?.low_1_quantity !== "" && props?.length == 1) {
        let data: any = [];
        data.push(props?.values?.low_1_quantity);
        AllocationData(data);
      }
      if (
        props?.values?.low_1_quantity !== "" &&
        props?.values?.low_2_quantity !== "" &&
        props?.length === 2
      ) {
        let data: any = [];
        data.push(props?.values?.low_1_quantity, props?.values?.low_2_quantity);

        AllocationData(data);
      }
      if (
        props?.values?.low_1_quantity !== "" &&
        props?.values?.low_2_quantity !== "" &&
        props?.values?.low_3_quantity !== "" &&
        props?.length === 3
      ) {
        let data: any = [];
        data.push(
          props?.values?.low_1_quantity,
          props?.values?.low_2_quantity,
          props?.values?.low_3_quantity
        );
        AllocationData(data);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    props?.values?.low_1_quantity,
    props?.values?.low_2_quantity,
    props?.values?.low_3_quantity,
  ]);

  const AllocationData = (data: any) => {
    setdisabled(true);
    api
      .post(
        `/vendor/check-allocation`,
        {
          allocate_data: data,
          order_id: props?.order_data?.pro_forma_invoice_id,
          product_id: props?.order_data?.product_id,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          // toast.success(response?.message);
          setdisabled(false);
        }
        return response;
      })
      .catch((err) => {
        setdisabled(true);
        if (err?.response?.status == 500) {
          toast.error(err?.response?.data?.message);
          setdisabled(true);
        }
        console.log(err);
      });
  };
  const preventNegativeValues = (e: any) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false)
          props?.handleReset()
        }}
        header='Vendor Allocation'
      >
        <div className="vendor-allocation-wrap ">
          <Row>
            {/* <Col md={6} className="leftside-col">
                <div className="left-side-wrap">
                  <div className="allocation-main-details">
                    <h1> {props?.order_data?.name || ""}</h1>

                    <small className="d-block">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: props?.order_data?.features,
                        }}
                      ></p>
                    </small>
                  </div>
                  <div className="allocation-sub-details">
                    <small>
                      Total:&nbsp;
                      <span> {props?.order_data?.quantity || ""}</span>
                    </small>
                    <small>
                      Department:&nbsp;
                      <span>{props?.ship_data?.email || ""}</span>
                    </small>

                    <small>
                      Order Id:&nbsp;
                      <span>{props?.pro_data?.order_id || ""}</span>
                    </small>
                    <small>
                      Tender Id:&nbsp;
                      <span>{props.pro_data?.tender_id || ""}</span>
                    </small>
                  </div>
                  <div className="allocation-address">
                    <div className="allocation-address-flex">
                      <i className="bi bi-geo-alt-fill"></i>
                      <h6>
                        Shipping Address
                        <br />
                        <small>
                          {props.ship_data?.city || ""},
                          {props.ship_data?.district || ""},
                          {props.ship_data?.state || ""},
                          {props.ship_data?.pincode || ""}
                        </small>
                      </h6>
                    </div>
                  </div>
                </div>
              </Col> */}
            <Col md={5}>
              <div className="history-list-outer">
                <h2 className="main-heading">{Admin_orders?.AllocationHistory[language]}</h2>
                <div className="history-inner-wrap">
                  <UncontrolledAccordion defaultOpen="0">
                    {props?.length == 1 ? (
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          {props?.vendor_details?.[0]?.name}
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <div className="item">
                            <div className="content">
                              <p className="flex-between">
                              {Admin_orders?.TotalOrderAllocated[language]}:{" "}
                                <span>
                                  {props?.history?.[0]?.order_count}
                                </span>
                              </p>
                            </div>
                            <hr />
                            <div className="item-footer-wrap">
                              <div className="bid-price">
                                <h6 className="flex-between">
                                {Admin_orders?.TotalAmount[language]}:
                                  <span>
                                    ₹ {props?.history?.[0]?.total_amount}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    ) : null}
                    {props?.length == 2 ? (
                      <>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            {props?.vendor_details?.[0]?.name}
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <div className="item">
                              <div className="content">
                                <p className="flex-between">
                                {Admin_orders?.TotalOrderAllocated[language]}:{" "}
                                  <span>
                                    {props?.history?.[0]?.order_count}
                                  </span>
                                </p>
                              </div>
                              <hr />
                              <div className="item-footer-wrap">
                                <div className="bid-price">
                                  <h6 className="flex-between">
                                  {Admin_orders?.TotalAmount[language]}:
                                    <span>
                                      ₹ {props?.history?.[0]?.total_amount}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionHeader targetId="2">
                            {props?.vendor_details?.[1]?.name}
                          </AccordionHeader>
                          <AccordionBody accordionId="2">
                            <div className="item">
                              <div className="content">
                                <p className="flex-between">
                                {Admin_orders?.TotalOrderAllocated[language]}:{" "}
                                  <span>
                                    {props?.history?.[1]?.order_count}
                                  </span>
                                </p>
                              </div>
                              <hr />
                              <div className="item-footer-wrap">
                                <div className="bid-price">
                                  <h6 className="flex-between">
                                  {Admin_orders?.TotalAmount[language]}:
                                    <span>
                                      ₹{props?.history?.[1]?.total_amount}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </>
                    ) : null}
                    {props?.length == 3 ? (
                      <>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            {props?.vendor_details?.[0]?.name}
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <div className="item">
                              <div className="content">
                                <p className="flex-between">
                                {Admin_orders?.TotalOrderAllocated[language]}:{" "}
                                  <span>
                                    {props?.history?.[0]?.order_count}
                                  </span>
                                </p>
                              </div>
                              <hr />
                              <div className="item-footer-wrap">
                                <div className="bid-price">
                                  <h6 className="flex-between">
                                  {Admin_orders?.TotalAmount[language]}:
                                    <span>
                                      ₹ {props?.history?.[0]?.total_amount}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionHeader targetId="2">
                            {props?.vendor_details?.[1]?.name}
                          </AccordionHeader>
                          <AccordionBody accordionId="2">
                            <div className="item">
                              <div className="content">
                                <p className="flex-between">
                                {Admin_orders?.TotalOrderAllocated[language]}:{" "}
                                  <span>
                                    {props?.history?.[1]?.order_count}
                                  </span>
                                </p>
                              </div>
                              <hr />
                              <div className="item-footer-wrap">
                                <div className="bid-price">
                                  <h6 className="flex-between">
                                  {Admin_orders?.TotalAmount[language]}:
                                    <span>
                                      ₹{props?.history?.[1]?.total_amount}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionHeader targetId="3">
                            {props?.vendor_details?.[2]?.name}
                          </AccordionHeader>
                          <AccordionBody accordionId="3">
                            <div className="item">
                              <div className="content">
                                <p className="flex-between">
                                {Admin_orders?.TotalOrderAllocated[language]}:{" "}
                                  <span>
                                    {props?.history?.[2]?.order_count}
                                  </span>
                                </p>
                              </div>
                              <hr />
                              <div className="item-footer-wrap">
                                <div className="bid-price">
                                  <h6 className="flex-between">
                                  {Admin_orders?.TotalAmount[language]}:
                                    <span>
                                      ₹ {props?.history?.[2]?.total_amount}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </>
                    ) : null}
                  </UncontrolledAccordion>
                </div>
              </div>
            </Col>
            <Col md={7} className="rightside-col">
              <div className="allocation-main-details">
                <h2>
                {Admin_orders?.TotalQuantity[language]}:&nbsp;
                  <span> {props?.order_data?.quantity || ""}</span>
                </h2>
              </div>
              <div className="right-side-outer">
                <div className="allocation-form-wrap">
                  <Form onSubmit={props?.handleSubmit}>
                    {props?.length == 1 || props?.length == 2 || props?.length == 3 ? (
                      <FormGroup>
                        <Label>
                        {Admin_orders?.Enter[language]} {props?.vendor_details?.[0]?.name} {Admin_orders?.Quantity[language]}
                        </Label>
                        <Input
                          name="low_1_quantity"
                          type="number"
                          min={0}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                          value={props?.values?.low_1_quantity}
                          onChange={props?.handleChange}
                          onKeyPress={preventNegativeValues}
                          onBlur={props?.handleBlur}
                          className={`form-control ${props?.touched.low_1_quantity &&
                            props?.errors.low_1_quantity
                            ? "is-invalid"
                            : ""
                            }`}
                        />
                        <ErrorMessage
                          name="low_1_quantity"
                          component={TextError}
                        />
                      </FormGroup>
                    ) : null}
                    {props?.length == 2 ||
                      (props?.length == 3 && props?.length != 1) ? (
                      <FormGroup>
                        <Label>
                        {Admin_orders?.Enter[language]}  {props?.vendor_details?.[1]?.name} {Admin_orders?.Quantity[language]}
                        </Label>
                        <Input
                          name="low_2_quantity"
                          min={0}
                          type="number"
                          value={props?.values?.low_2_quantity}
                          onBlur={props?.handleBlur}
                          onKeyPress={preventNegativeValues}
                          onChange={props?.handleChange}
                          disabled={
                            props?.length == 2 || props?.length == 3
                              ? false
                              : true
                          }
                          className={`form-control ${props?.touched.low_2_quantity &&
                            props?.errors.low_2_quantity
                            ? "is-invalid"
                            : ""
                            }`}
                        />
                        <ErrorMessage
                          name="low_2_quantity"
                          component={TextError}
                        />
                      </FormGroup>
                    ) : null}
                    {props?.length == 3 && props?.length != 2 && props?.length != 1 ? (
                      <FormGroup>
                        <Label>
                        {Admin_orders?.Enter[language]}  {props?.vendor_details?.[2]?.name} {Admin_orders?.Quantity[language]}
                        </Label>
                        <Input
                          name="low_3_quantity"
                          min={0}
                          type="number"
                          value={props?.values?.low_3_quantity}
                          onBlur={props?.handleBlur}
                          onKeyPress={preventNegativeValues}
                          onChange={props?.handleChange}
                          disabled={props?.length == 3 ? false : true}
                          className={`form-control ${props?.touched.low_3_quantity &&
                            props?.errors.low_3_quantity
                            ? "is-invalid"
                            : ""
                            }`}
                        />
                        <ErrorMessage
                          name="low_3_quantity"
                          component={TextError}
                        />
                      </FormGroup>
                    ) : null}
                    <FormGroup className={"agree-checkbox-wrap"}></FormGroup>
                    <FormGroup className="text-end">
                      {!disabled ? (
                        <Button
                          type="submit"
                          color="primary"
                          className={"px-5"}
                          disabled={props?.isSubmitting}
                        >
                          {Admin_orders?.AllocatetoVendors[language]}
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          color="primary"
                          className={"px-5"}
                          disabled={true}
                        >
                         {Admin_orders?.AllocatetoVendors[language]}
                        </Button>
                      )}
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default VendorAllocationModal;
