import React, { useEffect,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import { preventNegativeValues } from "../../../Library/Constants/helpers";
import { sc_st_entrepreneur } from "../../../Library/Constants/MapConstants";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;
export default function BlackLsitDocuments(props: any) {
  const { language, setLanguage } = useContext(Context);
  return (
    <>
      <div className="company-information-update-wrap">
        <h4>{Vendor_profileUpdation?.BlackList_Documents?.[language]}</h4>
        <hr />

        <Row>
          <Col md={3} sm={3}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  No Declaration Black List
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                <Input
                  type="select"
                  name="start_up_company_under_black_list"
                  value={props?.values?.start_up_company_under_black_list}
                  onChange={(e) => {
                    props?.handleChange(e);
                    props?.setValue({
                      ...props?.value,
                      start_up_company_under_black_list: e.target.value,
                    });
                  }}
                >
                  <option value="">{Vendor_profileUpdation?.Select?.[language]}</option>
                  {sc_st_entrepreneur?.map((item: any) => {
                    return (
                      <>
                        <option value={item?.value}>{item?.name}</option>
                      </>
                    );
                  })}
                </Input>
                <ErrorMessage
                  name="start_up_company_under_black_list"
                  component={TextError}
                />
              </div>
            </FormGroup>
          </Col>

          <Col>
            {props?.values?.start_up_company_under_black_list == 1 && (
              <>
                <Col md={4} sm={6}>
                  <FormGroup>
                    <Label>
                    {Vendor_profileUpdation?.BlackList_Documents?.[language]}{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <div className="image-input">
                      {props?.list <= 0 ? (
                        <>
                          {" "}
                          <input
                            type="file"
                            ref={props?.oemRef}
                            id="start_up_company_under_black_list_document"
                            name="start_up_company_under_black_list_document"
                            className="form-control"
                            accept=".pdf"
                            onChange={(e: any) =>
                              props?.handleFileChange(e, e.target.files[0])
                            }
                            disabled={props?.list > 0 ? true : false}
                          ></input>
                          <Label
                            className="image-button"
                            for="start_up_company_under_black_list_document"
                            id="start_up_company_under_black_list_document"
                          >
                            {Vendor_profileUpdation?.Choose_Documents?.[language]}
                          </Label>
                        </>
                      ) : (
                        <>
                          <InputGroup>
                            <input
                              id="start_up_company_under_black_list_document"
                              name="start_up_company_under_black_list_document"
                              className="form-control"
                              accept=".pdf"
                              value={
                                props?.datas
                                  ?.start_up_company_under_black_list_document
                              }
                              disabled={
                                props?.datas
                                  ?.start_up_company_under_black_list_document
                                  ? true
                                  : false
                              }
                            ></input>
                            <InputGroupText
                              className="input-group-text bg-white border-start-0"
                              id="newpass"
                            >
                              <a
                                href={
                                  `${DOCUMENT_URL}` +
                                  props?.datas
                                    ?.start_up_company_under_black_list_document
                                }
                                download
                                target="_blank"
                              >
                                {" "}
                                <i className="bi bi-box-arrow-down"></i>
                              </a>
                            </InputGroupText>
                          </InputGroup>
                        </>
                      )}
                    </div>
                  </FormGroup>
                  <ErrorMessage
                name="start_up_company_under_black_list_document"
                component={TextError}
              />
                </Col>
              </>
            )}
          </Col>
        </Row>
        <Row>
       
                <Col md={4} sm={6}>
                  <FormGroup>
                    <Label>
                    {Vendor_profileUpdation?.No_Case_Pending?.[language]}{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <div className="image-input">
                      {props?.list <= 0 ? (
                        <>
                          {" "}
                          <input
                            type="file"
                            ref={props?.oemRef}
                            id="start_up_company_no_case_certificate"
                            name="start_up_company_no_case_certificate"
                            className="form-control"
                            accept=".pdf"
                            onChange={(e: any) =>
                              props?.handleFileChange(e, e.target.files[0])
                            }
                            disabled={props?.list > 0 ? true : false}
                          ></input>
                          <Label
                            className="image-button"
                            for="start_up_company_no_case_certificate"
                            id="start_up_company_no_case_certificate"
                          >
                            {Vendor_profileUpdation?.Choose_Documents?.[language]}
                          </Label>
                        </>
                      ) : (
                        <>
                          <InputGroup>
                            <input
                              id="start_up_company_no_case_certificate"
                              name="start_up_company_no_case_certificate"
                              className="form-control"
                              accept=".pdf"
                              value={
                                props?.datas
                                  ?.start_up_company_no_case_certificate
                              }
                              disabled={
                                props?.datas
                                  ?.start_up_company_no_case_certificate
                                  ? true
                                  : false
                              }
                            ></input>
                            <InputGroupText
                              className="input-group-text bg-white border-start-0"
                              id="newpass"
                            >
                              <a
                                href={
                                  `${DOCUMENT_URL}` +
                                  props?.datas
                                    ?.start_up_company_no_case_certificate
                                }
                                download
                                target="_blank"
                              >
                                {" "}
                                <i className="bi bi-box-arrow-down"></i>
                              </a>
                            </InputGroupText>
                          </InputGroup>
                        </>
                      )}
                    </div>
                  </FormGroup>
                  <ErrorMessage
                name="start_up_company_no_case_certificate"
                component={TextError}
              />
          </Col>
        </Row>
      </div>
    </>
  );
}
