import { ErrorMessage, Form } from "formik"
import React, { useState, useContext } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, Input, Button } from "reactstrap"
import TextError from "../../../../Components/Errors/TextError"
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../Components/Context/Context";
import { Admin_RateContract } from "../../../../Library/LanguageConversion/Admin_RateContract";
const Manufacture = (props: any) => {
    const {language, setLanguage}= useContext(Context)
    return (
        <React.Fragment>
            <ModalContainer
                open={props?.open}
                setOpen={() => {
                    props?.setOpen(false);
                    props?.handleReset();
                }}
                header='Add Manufacture & Model'
            >
                <div className="add-vendor-outer">
                    <div className="vendor-details">
                        {/* <div className="circle-icon mb-2">
                                <img
                                    src="images/manufacture.svg"
                                    alt="img"
                                    className="d-block mx-auto"
                                />
                            </div> */}

                        <Form className="form-wrap">
                            <Row>
                                <Col md={6} sm={6}>
                                    <FormGroup>
                                        <Label> {Admin_RateContract?.Make[language]}
                                            <i
                                                className="fa fa-asterisk fa-1 required-field"
                                                color="text-danger"
                                                aria-hidden="true"
                                            ></i>
                                        </Label>
                                        <Input
                                            type="text"
                                            name="make_new"
                                            value={props?.values?.make_new}
                                            onChange={props?.handleChange}
                                            className={`form-control ${props?.touched.make_new &&
                                                props?.errors.make_new
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <ErrorMessage name="make_new" component={TextError} />
                                    </FormGroup>

                                </Col>

                                <Col md={6} sm={6}>
                                    <FormGroup>
                                        <Label> {Admin_RateContract?.Model[language]}
                                            <i
                                                className="fa fa-asterisk fa-1 required-field"
                                                color="text-danger"
                                                aria-hidden="true"
                                            ></i>
                                        </Label>
                                        <Input
                                            type="text"
                                            name="model_new"
                                            value={props?.values?.model_new}
                                            onChange={props?.handleChange}
                                            className={`form-control ${props?.touched.model_new &&
                                                props?.errors.model_new
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <ErrorMessage name="model_new" component={TextError} />
                                    </FormGroup>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup className={"text-end"}>
                                        <Button
                                            color="primary"
                                            className={"px-5"}
                                            onClick={props?.handleSubmit}
                                            disabled={props?.isSubmitting}
                                        >
                                            {Admin_RateContract?.Add[language]}
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </ModalContainer>
        </React.Fragment>
    )
}

export default Manufacture;
