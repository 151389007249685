import React, { useEffect, useContext } from "react";
import { Context } from "../../../../Components/Context/Context";
import Loader from "../../../../Components/Loader/Loader";
import { API_URL } from "../../../../Library/ApiSettings";
import { useState } from "react";
import { Button, Table, Badge } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import { toast } from "react-toastify";
import axios from "axios";
import ViewDocumentModal from "./ViewDocumetsModal";
import constants from "../../../../Library/Constants/Constants";
import { DocumentTYpeUpload } from "../../../../Library/Constants/helpers";
import { Vendor_profileUpdation } from "../../../../Library/LanguageConversion/Vendor_profileUpdation";
export default function NormalVendorView(props: any) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { language, setLanguage } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState<any>("");
  useEffect(() => {
    setId(localStorage?.getItem("USER_ID"));
  }, [id]);
  const vendor_id: any = id;
  const [documents, setDocument] = useState<any>([]);
  const [data, setData] = useState();
  useEffect(() => {
    getDocuments(vendor_id);
  }, [vendor_id]);
  const getDocuments = (vendor_id: any) => {
    setLoading(true);
    api
      .get(`/documents`, true)
      .then(async function ([success, response]) {
        setDocument(response?.data || []);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const download_documents = async (id: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        responseType: "blob",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(`${API_URL}/documents/${id}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Document.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  return (
    <>
      <div className="company-information-update-wrap">
        <div className="table-wrap responsive-table">
          <Table className="w-100 table-striped">
            <thead>
              <tr>
                <th>{Vendor_profileUpdation?.Sl_No?.[language]}</th>
                <th>{Vendor_profileUpdation?.Document_Type?.[language]}</th>
                <th>{Vendor_profileUpdation?.Document?.[language]}</th>
                <th>{Vendor_profileUpdation?.View?.[language]}</th>
                <th>{Vendor_profileUpdation?.Status?.[language]}</th>
              </tr>
            </thead>
            {loading ? (
              <div className="loading-overlay">
                <Loader />
              </div>
            ) : (
              <>
                {" "}
                <tbody>
                  {documents
                    ?.filter((it: any) => {
                      return it?.type?.id != 28;
                    })
                    .map((item: any, i: any) => {
                      return (
                        <React.Fragment key={i}>
                          <tr>
                            <td>
                              <span className="mobile-heading">
                                {Vendor_profileUpdation?.Sl_No?.[language]}:
                              </span>
                              <span className="table-data">{i + 1}</span>
                            </td>
                            <td>
                              <span className="mobile-heading">
                                {Vendor_profileUpdation?.Document?.[language]}:
                              </span>
                              {DocumentTYpeUpload(item?.type?.id)}
                            </td>

                            <td>
                              <span className="mobile-heading">
                                {Vendor_profileUpdation?.Action?.[language]}:
                              </span>
                              <div className="action-flex-btn">
                                <a
                                  onClick={() => {
                                    download_documents(item?.id);
                                  }}
                                  rel="noreferrer"
                                  className="rounded-tag"
                                >
                                  <i
                                    title="View"
                                    className="icons bi bi-download lg"
                                  ></i>
                                </a>
                              </div>
                            </td>

                            <td>
                              <span className="mobile-heading">
                                {Vendor_profileUpdation?.View?.[language]}:
                              </span>
                              {(item?.type.id ==
                                constants?.DOCUMENTS?.TURN_OVER1 ||
                                item?.type.id ==
                                  constants?.DOCUMENTS?.TURN_OVER2 ||
                                item?.type.id ==
                                  constants?.DOCUMENTS?.TURN_OVER3 ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.IT_TURN_OVER_ONE ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.IT_TURN_OVER_TWO ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.HR_ANY_OFFICE ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.HR_MANPOWER ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.HR_LAND ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.IT_TURN_OVER_ONE ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.IT_TURN_OVER_ONE ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.EMPANEL ||
                                item?.type?.id ==
                                  constants?.DOCUMENTS?.OEM) && (
                                // ||
                                // item?.type?.id == constants?.DOCUMENTS?.MSE
                                <>
                                  <i
                                    title="View"
                                    className="icons pointer bi bi-eye lg"
                                    onClick={() => {
                                      if (
                                        item?.type.id ==
                                          constants?.DOCUMENTS?.TURN_OVER1 ||
                                        item?.type.id ==
                                          constants?.DOCUMENTS?.TURN_OVER2 ||
                                        item?.type.id ==
                                          constants?.DOCUMENTS?.TURN_OVER3 ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS
                                            ?.IT_TURN_OVER_ONE ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS
                                            ?.IT_TURN_OVER_TWO ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS?.HR_ANY_OFFICE ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS?.HR_MANPOWER ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS?.HR_LAND ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS
                                            ?.IT_TURN_OVER_ONE ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS
                                            ?.IT_TURN_OVER_ONE ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS?.EMPANEL ||
                                        item?.type?.id ==
                                          constants?.DOCUMENTS?.OEM
                                        //   ||
                                        // item?.type?.id ==
                                        //   constants?.DOCUMENTS?.MSE
                                      ) {
                                        setShow(true);
                                      }
                                      setData(item);
                                    }}
                                  ></i>
                                </>
                              )}
                            </td>
                            <td>
                              <span className="mobile-heading">
                                {Vendor_profileUpdation?.Status?.[language]}
                              </span>
                              {item?.status?.name == "APPROVED" ? (
                                <Badge color="success">
                                  {Vendor_profileUpdation?.Approved?.[language]}
                                </Badge>
                              ) : item.status?.name == "REJECTED" ? (
                                <Badge color="danger">
                                  {Vendor_profileUpdation?.Rejected?.[language]}
                                </Badge>
                              ) : item.status?.name == "REUPLOAD" ? (
                                <Badge color="info">
                                  {
                                    Vendor_profileUpdation?.Reuploaded?.[
                                      language
                                    ]
                                  }
                                </Badge>
                              ) : (
                                <Badge color="success">
                                  {Vendor_profileUpdation?.Uploaded?.[language]}
                                </Badge>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </>
            )}
          </Table>
        </div>
      </div>
      {show && (
        <>
          <ViewDocumentModal setShow={setShow} show={show} data={data} />
        </>
      )}
    </>
  );
}
