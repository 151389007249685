import React, { useState,useContext } from 'react'
import { Col, Collapse, Row } from 'reactstrap'
import RightSection from '../../Products/PaymentMethodComponents/RightSection'
import Paypal from '../../../../Components/PaymentGateway'
import constants from '../../../../Library/Constants/Constants'
import { toast } from 'react-toastify'
import AccountDetails from '../../Products/PaymentMethodComponents/AccountDetails'
import PaymentTypesSelector from '../../Products/PaymentMethodComponents/PaymentTypesSelector'
import { PAYMENT_TYPES } from '../../../../Library/Constants/MapConstants'
import { Context } from '../../../../Components/Context/Context'
import { Department_Orders } from '../../../../Library/LanguageConversion/Department_Orders'

interface propType {
    singleOrderData: any,
    singlePayData: any,
    orderId?: any
}

const CompletePayment = ({ singleOrderData, singlePayData, orderId }: propType) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedMethod, setSelectedMethod] = useState<number>(0)
    const {language,setLanguage}=useContext(Context);


    return (
        <>
            <div className='feeback-outer-wrap'>
                {singleOrderData?.map((obj: any, i: number) => {
                    return (
                        <React.Fragment key={i}>
                            <div className="">
                                <div className="feedback-wrap order-table-wrap">
                                    <h5 className="sub-heading">{Department_Orders?.Product[language]} {i + 1}</h5>
                                    <div className="feedback-details ">
                                        <table className="table table-hover table-bordered ">
                                            <tbody>
                                                {obj?.vendor_po_no && <tr>
                                                    <td><b>{Department_Orders?.Order_ID[language]}</b>{" "}</td>
                                                    <td> {obj?.vendor_po_no} </td>
                                                </tr>}
                                                <tr>
                                                    <td><b> {Department_Orders?.ProductName[language]}</b>{" "}</td>
                                                    <td>
                                                        <strong>{obj?.order_details?.product_details?.name || obj?.name}</strong>
                                                    </td>
                                                </tr>
                                                <tr onClick={() => setIsOpen(!isOpen)} className="collapse-btn">
                                                    <td><b>{Department_Orders?.ProductCode[language]}</b>{" "}</td>
                                                    <td>{obj?.order_details?.product_details?.product_code || obj?.product_code}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{Department_Orders?.Quantity[language]}</b></td>
                                                    <td>{obj?.order_details?.quantity || obj?.quantity}</td>
                                                </tr>
                                                <Collapse isOpen={isOpen} tag={"tr"}>
                                                    <td colSpan={2}>
                                                        <table className="table  table-bordered ">
                                                            <tbody>
                                                                <tr>
                                                                    <td><b>{Department_Orders?.HSNCode[language]}</b>{" "}</td>
                                                                    <td>{obj?.order_details?.product_details?.hsn_code || obj?.hsn_code}</td>
                                                                </tr>
                                                                {/* Specs in Desktop */}
                                                                <tr className="large-screen-view">
                                                                    <td><b>{Department_Orders?.ProductSpecifications[language]}</b></td>
                                                                    <td>
                                                                        <p className="specs">
                                                                            {obj?.order_details
                                                                                ?.product_details?.features || obj?.features ? (
                                                                                <small>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html:
                                                                                                obj?.order_details
                                                                                                    ?.product_details?.features || obj?.features
                                                                                            ,
                                                                                        }}
                                                                                    />
                                                                                </small>
                                                                            ) : null}
                                                                        </p>
                                                                    </td>
                                                                </tr >
                                                                {/* Specs in mobile */}
                                                                <tr className="mobile-view">
                                                                    <td colSpan={2} className='text-center'>
                                                                        <b>{Department_Orders?.ProductSpecifications[language]}</b>
                                                                    </td>
                                                                </tr>
                                                                <tr className="mobile-view">
                                                                    <td colSpan={2}>
                                                                        <p className="specs centered">
                                                                            {obj?.order_details
                                                                                ?.product_details?.features || obj?.features ? (
                                                                                <small>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html:
                                                                                                obj?.order_details
                                                                                                    ?.product_details?.features || obj?.features
                                                                                            ,
                                                                                        }}
                                                                                    />
                                                                                </small>
                                                                            ) : null}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </Collapse>
                                                {obj?.cmpny_detail?.name && <tr>
                                                    <td><b>{Department_Orders?.Vendor[language]}</b></td>
                                                    <td>{obj?.cmpny_detail?.name}</td>
                                                </tr>}
                                                <tr>
                                                    <td>
                                                        <b>{Department_Orders?.UnitPrice[language]}</b>
                                                    </td>
                                                    <td>
                                                        ₹
                                                        {obj?.order_details?.product_details?.product_rate?.[0]?.price || obj?.unit_price}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <b>{Department_Orders?.TotalGST[language]}</b>
                                                    </td>
                                                    <td>₹ {obj?.total_gst}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>{Department_Orders?.TotalAmount[language]}</b>
                                                    </td>
                                                    <td>₹ {obj?.total_amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>{Department_Orders?.TotalPaidAmount[language]}</b>
                                                    </td>
                                                    <td>₹ {singlePayData?.amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>{Department_Orders?.BalanceAmounttoPay[language]}</b>
                                                    </td>
                                                    <td>₹ {singlePayData?.balance_amt}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment >
                    )
                })}
            </div>

            {/* Payment handling section  */}
            <Row className="proforma-checkout-wrap  gy-4" >
                <h5 className="sub-heading">{Department_Orders?.Payment[language]}
                    {/* we can change the payment details from constants  */}
                    {selectedMethod ?<>{ '  '}{Department_Orders?.Via[language]} {'  '}
                        {PAYMENT_TYPES?.filter((it: any) => it?.value == selectedMethod)?.[0]?.name}
                    </> : ''}
                </h5>
                <Col lg={6}>
                    <div className="choose-payment-outer-wrap">
                        <Col md={12}>
                            <div className="choose-payment-wrap">
                                <PaymentTypesSelector
                                    selectedMethod={selectedMethod}
                                    setSelectedMethod={setSelectedMethod}
                                />
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col lg={6}>
                    <div>
                        <div className="cart-total-left-inner">
                            <p className='mb-0'>{Department_Orders?.Paying[language]}:</p>
                            <h2 className='primary-text'>₹ {singlePayData?.balance_amt} </h2>
                        </div>
                        <AccountDetails />   {/* component to show account details of the PMU */}

                        {selectedMethod !== constants?.PAYMENT_TYPE?.NETBANK ?
                            <RightSection
                                payment_type={selectedMethod}
                                id={singleOrderData?.[0]?.pro_forma_invoice_id}
                                selectedMode={constants?.DEPT_PAYMENT_MODES?.FULL_PAYMENT}
                                amount={singlePayData?.balance_amt}
                                order_status={20}
                                orderId={orderId}
                            />
                            :
                            <div>
                                {
                                    (singlePayData?.balance_amt) ?
                                        <div>
                                            <Paypal
                                                amount={singlePayData?.balance_amt}
                                                order_id={singleOrderData?.[0].pro_forma_invoice_id}
                                                purchaseType={'PRODUCT'}
                                                selectedMode={constants?.DEPT_PAYMENT_MODES?.FULL_PAYMENT}
                                            />
                                        </div>
                                        :
                                        toast.error('Unexpected Error Occured')
                                }
                            </div>}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default CompletePayment