import React, { useEffect,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useState, useMemo, useRef } from "react";
import { api } from "../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; 
import { Formik, ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import { API_URL } from "../../../Library/ApiSettings";
import axios from "axios";
import {
  UploadValidationWithoutMSESchema,
  UploadValidationSchema,
  UploadValidationWithoutUdeyamMSESchema,
  UploadValidationWithoutUdyamSchema,
} from "../profileUpdation/UploadValidation";
import DatePicker from "react-date-picker";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { Context } from "../../../Components/Context/Context";
import { VendorSignup } from "../../../Library/LanguageConversion/Vendor_SignUp";


const DOCUMENT_URL = DOCS_URL;
export default function DocUploadSelfRegisterVendor() {
  const isoRef = useRef<HTMLInputElement>(null);
  const oemRef = useRef<HTMLInputElement>(null);
  const mafRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<any>([]);
  const [iso, setiso] = useState<any>(false);
  const [maf, setmaf] = useState<any>(false);
  const [oem, setoem] = useState<any>(false);
  const [empanel_certificate, setempanel_certificate] = useState<any>(false);
  const [list, setlists] = useState<any>("");
  const [dat, setDates] = useState<any>();
  const [vendorUdyam, setVendorUdyam] = useState("");
  const [vendormse, setVendorMSE] = useState("");
  const navigate = useNavigate();
  const { language, setLanguage } = useContext(Context);
  const [datas, setData] = useState({
    pan: "",
    incorporation_certificate: "",
    address_proof: "",
    mse_certificate: "",
    gst_certificate: "",
    FFfinancial_year: "",
    SFfinancial_year: "",
    TFfinancial_year: "",
    file: [],
    Ffinancial_year: "",
    Sfinancial_year: "",
    Tfinancial_year: "",
    Ffinancial_year_amount: "",
    Sfinancial_year_amount: "",
    Tfinancial_year_amount: "",
    iso_certificate: "",
    oem_certificate: "",
    maf_certificate: "",
    empanel_certificate: "",
    udyam: "",

    profile_status: 3,
  });
  useEffect(() => {
    getDocumnets();
    getVendor();
  }, []);

  function subtractYears(date: any, years: any) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getVendor = () => {
    api
      .post(`/get_vendor_profile`, {}, true)
      .then(async function ([success, response]) {
        setVendorUdyam(
          response?.vendor_details?.advanced_registration_details?.[0]?.udyam
        );
        setVendorMSE(
          response?.vendor_details?.advanced_registration_details?.[0]
            ?.company_mse
        );
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumnets = () => {
    api
      .post(`/get_documents`, {}, true)
      .then(async function ([success, response]) {
        setData({
          ...datas,
          pan: response?.data?.documents?.[0]?.url?.split("/")?.[5],
          incorporation_certificate:
            response?.data?.documents?.[4]?.url?.split("/")?.[5],
          address_proof: response?.data?.documents?.[3]?.url?.split("/")?.[5],
          mse_certificate:
            response?.data?.documents?.[1]?.is_msme == 1
              ? response?.data?.documents?.[5]?.url?.split("/")?.[5]
              : "",
          gst_certificate: response?.data?.documents?.[6]?.url?.split("/")?.[5],
          iso_certificate:
            response?.data?.documents?.[10]?.is_iso == 1
              ? response?.data?.documents?.[10]?.url?.split("/")?.[5]
              : "",
          oem_certificate:
            response?.data?.documents?.[1]?.is_oem == 1
              ? response?.data?.documents?.[1]?.url?.split("/")?.[5]
              : "",
          maf_certificate:
            response?.data?.documents?.[2]?.is_adhar == 1
              ? response?.data?.documents?.[2]?.url?.split("/")?.[5]
              : "",
          file: [],
          Ffinancial_year: response?.data?.finance?.[0]?.year_from,
          Sfinancial_year: response?.data?.finance?.[1]?.year_from,
          Tfinancial_year: response?.data?.finance?.[2]?.year_from,
          Ffinancial_year_amount: response?.data?.finance?.[0]?.amount,
          Sfinancial_year_amount: response?.data?.finance?.[1]?.amount,
          Tfinancial_year_amount: response?.data?.finance?.[2]?.amount,
          FFfinancial_year: response?.data?.finance?.[0]?.url?.split("/")?.[4],
          SFfinancial_year: response?.data?.finance?.[1]?.url?.split("/")?.[4],
          TFfinancial_year: response?.data?.finance?.[2]?.url?.split("/")?.[4],
          empanel_certificate:
            response?.data?.documents?.[11]?.url?.split("/")?.[5],
          udyam: response?.data?.documents?.[12]?.url?.split("/")?.[5],
          profile_status: 3,
        });
        setlists(response?.data?.documents?.length > 0 ? 1 : 0);

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initialValues: any = {
    pan: "" || datas?.pan,
    incorporation_certificate: "" || datas?.incorporation_certificate,
    address_proof: "" || datas?.address_proof,
    mse_certificate: "" || datas?.mse_certificate,
    gst_certificate: "" || datas?.gst_certificate,
    FFfinancial_year: "" || datas?.FFfinancial_year,
    SFfinancial_year: "" || datas?.SFfinancial_year,
    TFfinancial_year: "" || datas?.TFfinancial_year,
    file: [],
    Ffinancial_year: "" || datas?.Ffinancial_year,
    Sfinancial_year: "" || datas?.Sfinancial_year,
    Tfinancial_year: "" || datas?.Tfinancial_year,
    iso_certificate: "" || datas?.iso_certificate,
    Ffinancial_year_amount: "" || datas?.Ffinancial_year_amount,
    Sfinancial_year_amount: "" || datas?.Sfinancial_year_amount,
    Tfinancial_year_amount: "" || datas?.Tfinancial_year_amount,
    oem_certificate: "" || datas?.oem_certificate,
    maf_certificate: "" || datas?.maf_certificate,
    empanel_certificate: "" || datas?.empanel_certificate,
    udyam: "" || datas?.udyam,
    profile_status: 3,
    address: "false",
  };
  const handleSubmit = (values: any, actions: any) => {
    uploadProduct(values, actions);
  };

  const currentYear = new Date().getFullYear(); // 2020
  const previousYear = currentYear - 1;
  const nest_yr = previousYear - 1;

  const uploadProduct = (values: any, actions: any) => {
    const formData = new FormData();

    let arr: any = [];

    arr = [
      values?.pan,
      !values?.oem_certificate ? values?.pan : values?.oem_certificate,
      !values?.maf_certificate ? values?.pan : values?.maf_certificate,
      values?.address_proof,
      values?.incorporation_certificate,
      !values?.mse_certificate ? values?.pan : values?.mse_certificate,
      values?.gst_certificate,
      values?.FFfinancial_year,
      values?.SFfinancial_year,
      values?.TFfinancial_year,
      !values?.iso_certificate ? values?.pan : values?.iso_certificate,
      values?.empanel_certificate,
      values?.udyam,
    ];

    if (values?.iso_certificate) {
      formData.append("is_iso", "1");
    }
    if (values?.oem_certificate) {
      formData.append("is_oem", "1");
    }
    if (values?.maf_certificate) {
      formData.append("is_adhar", "1");
    }
    if (values?.mse_certificate) {
      formData.append("is_msme", "1");
    }
    let arr_amount: any = [];

    arr_amount = [
      values?.Ffinancial_year_amount,
      values?.Sfinancial_year_amount,
      values?.Tfinancial_year_amount,
    ];
    let arr_year: any = [];

    arr_year = [
      values?.Ffinancial_year.toString().slice(11, 15),
      values?.Sfinancial_year.toString().slice(11, 15),
      values?.Tfinancial_year.toString().slice(11, 15),
    ];
    arr.forEach((file: any, i: number) => {
      formData.append("file[]", file);
    });
    //formData.append("file[]", arr);
    arr_amount.forEach((amount: any, i: number) => {
      formData.append("amount[]", amount);
    });
    arr_year.forEach((year: any, i: number) => {
      formData.append("year[]", year);
    });
    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/documents`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success("Documents are uploaded successfuly");
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/emd-details", { state: true });
          localStorage.setItem("emds", "true");

          // navigate("/overview-vendor", { replace: true });
        }
      })
      .catch(function (response) {
        toast.error("Something Went Wrong, Please Check Datas");
        console.log(response);
      });
  };

  // const formatDate = (date:any) => {
  //   if (!date) return "";
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const form:any = `${day}-${month}-${year-1}`;
  //   setDates(form);
  //   console.log(dat,"difhiueifej")
  // };

  return (
    <>
      <div className="login-content">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={
            vendorUdyam == "yes" && vendormse == "yes"
              ? UploadValidationSchema
              : vendorUdyam == "no" && vendormse == "no"
              ? UploadValidationWithoutUdeyamMSESchema
              : vendorUdyam == "yes" && vendormse == "no"
              ? UploadValidationWithoutMSESchema
              : vendorUdyam == "no" && vendormse == "yes"
              ? UploadValidationWithoutUdyamSchema
              : UploadValidationSchema
          }
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            const handleFilechange = (e: any, values: any) => {

              const myMemoObj = URL.createObjectURL(e.target.files[0]);
              setValue([...value, myMemoObj]);
              if (e.target.name == "pan") {
                setFieldValue("pan", e.target.files[0]);
              }
              if (e.target.name == "incorporation_certificate") {
                setFieldValue("incorporation_certificate", e.target.files[0]);
              }
              if (e.target.name == "address_proof") {
                setFieldValue("address_proof", e.target.files[0]);
              }
              if (e.target.name == "mse_certificate") {
                setFieldValue("mse_certificate", e.target.files[0]);
              }
              if (e.target.name == "gst_certificate") {
                setFieldValue("gst_certificate", e.target.files[0]);
              }
              if (e.target.name == "FFfinancial_year") {
                setFieldValue("FFfinancial_year", e.target.files[0]);
              }
              if (e.target.name == "SFfinancial_year") {
                setFieldValue("SFfinancial_year", e.target.files[0]);
              }

              if (e.target.name == "TFfinancial_year") {
                setFieldValue("TFfinancial_year", e.target.files[0]);
              }
              if (e.target.name == "Ffinancial_year_amount") {
                setFieldValue("Ffinancial_year_amount", e.target.files[0]);
              }
              if (e.target.name == "Sfinancial_year_amount") {
                setFieldValue("Sfinancial_year_amount", e.target.files[0]);
              }
              if (e.target.name == "Tfinancial_year_amount") {
                setFieldValue("Tfinancial_year_amount", e.target.files[0]);
              }
              if (e.target.name == "iso_certificate") {
                setFieldValue("iso_certificate", e.target.files[0]);
                setiso(true);
              }
              if (e.target.name == "TFfinancial_year") {
                setFieldValue("TFfinancial_year", e.target.files[0]);
              }
              if (e.target.name == "maf_certificate") {
                setFieldValue("maf_certificate", e.target.files[0]);
                setmaf(true);
              }
              if (e.target.name == "oem_certificate") {
                setFieldValue("oem_certificate", e.target.files[0]);
                setoem(true);
              }
              if (e.target.name == "empanel_certificate") {
                setFieldValue("empanel_certificate", e.target.files[0]);
                setempanel_certificate(true);
              }
              if (e.target.name == "udyam") {
                setFieldValue("udyam", e.target.files[0]);
              }
            };

            const handleFileremove = (val: any) => {
              if (val == "iso") {
                setFieldValue("iso_certificate", "");
                setiso(false);
                if (isoRef && isoRef.current) {
                  isoRef.current.value = "";
                }
              }

              if (val == "maf") {
                setFieldValue("maf_certificate", "");
                setmaf(false);
                if (mafRef && mafRef.current) {
                  mafRef.current.value = "";
                }
              }

              if (val == "oem") {
                setFieldValue("oem_certificate", "");
                setoem(false);
                if (oemRef && oemRef.current) {
                  oemRef.current.value = "";
                }
              }
            };

            return (
              <Form onSubmit={handleSubmit} className="Reg-wrap">
                <Row>
                  <Col md={4} sm={6}>
                    <FormGroup className="file">
                      <Label>
                      {VendorSignup?.pancard[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        {datas?.pan ? (
                          <>
                            <InputGroup>
                              <Input
                                id="pan"
                                name="pan"
                                value={datas?.pan}
                                disabled={list > 0 ? true : false}
                              ></Input>
                              <InputGroupText
                                className="input-group-text bg-white border-start-0"
                                id="newpass"
                              >
                                <a
                                  href={`${DOCUMENT_URL}` + datas?.pan}
                                  download
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="bi bi-box-arrow-down"></i>
                                </a>
                              </InputGroupText>
                            </InputGroup>
                          </>
                        ) : (
                          <>
                            <Input
                              type="file"
                              id="pan"
                              name="pan"
                              onChange={(e: any) =>
                                handleFilechange(e, e.target.files[0])
                              }
                              accept=".pdf"
                              disabled={list > 0 ? true : false}
                            ></Input>
                            <Label className="image-button" for="pan" id="pan">
                            {VendorSignup?.Choose_Documents[language]}
                            </Label>
                          </>
                        )}
                      </div>

                      <ErrorMessage name="pan" component={TextError} />
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={6}>
                    <FormGroup className="file">
                      <Label>
                      {VendorSignup?.company_incorporation_certificate[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        {datas?.incorporation_certificate ? (
                          <InputGroup>
                            <Input
                              id="incorporation_certificate"
                              name="incorporation_certificate"
                              className="form-control"
                              accept=".pdf"
                              value={datas?.incorporation_certificate}
                              disabled={list > 0 ? true : false}
                            ></Input>
                            <InputGroupText
                              className="input-group-text bg-white border-start-0"
                              id="newpass"
                            >
                              <a
                                href={
                                  `${DOCUMENT_URL}` +
                                  datas?.incorporation_certificate
                                }
                                download
                                target="_blank"
                              >
                                {" "}
                                <i className="bi bi-box-arrow-down"></i>
                              </a>
                            </InputGroupText>
                          </InputGroup>
                        ) : (
                          <>
                            {" "}
                            <Input
                              type="file"
                              id="incorporation_certificate"
                              name="incorporation_certificate"
                              className="form-control"
                              accept=".pdf"
                              onChange={(e: any) =>
                                handleFilechange(e, e.target.files[0])
                              }
                              disabled={list > 0 ? true : false}
                            ></Input>
                            <Label
                              className="image-button"
                              for="incorporation_certificate"
                              id="incorporation_certificate"
                            >
                              {VendorSignup?.Choose_Documents[language]}
                            </Label>
                          </>
                        )}
                      </div>
                      <ErrorMessage
                        name="incorporation_certificate"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={6}>
                    <FormGroup className="file">
                      <Label>
                      {VendorSignup?.address_proof[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        {datas?.address_proof ? (
                          <InputGroup>
                            <Input
                              id="address_proof"
                              name="address_proof"
                              className="form-control"
                              accept=".pdf"
                              value={datas?.address_proof}
                              disabled={list > 0 ? true : false}
                            ></Input>
                            <InputGroupText
                              className="input-group-text bg-white border-start-0"
                              id="newpass"
                            >
                              <a
                                href={`${DOCUMENT_URL}` + datas?.address_proof}
                                download
                                target="_blank"
                              >
                                {" "}
                                <i className="bi bi-box-arrow-down"></i>
                              </a>
                            </InputGroupText>
                          </InputGroup>
                        ) : (
                          <>
                            {" "}
                            <Input
                              type="file"
                              id="address_proof"
                              name="address_proof"
                              className="form-control"
                              accept=".pdf"
                              onChange={(e: any) =>
                                handleFilechange(e, e.target.files[0])
                              }
                              disabled={list > 0 ? true : false}
                            ></Input>
                            <Label
                              className="image-button"
                              for="address_proof"
                              id="address_proof"
                            >
                              {VendorSignup?.Choose_Documents[language]}
                            </Label>
                          </>
                        )}
                      </div>
                      <ErrorMessage
                        name="address_proof"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  {vendormse == "yes" ? (
                    <Col md={4} sm={6}>
                      <FormGroup className="file">
                        <Label>
                        {VendorSignup?.MSME_Certificate[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <div className="image-input">
                          {datas?.mse_certificate ? (
                            <InputGroup>
                              <Input
                                id="mse_certificate"
                                name="mse_certificate"
                                accept=".pdf"
                                value={datas?.mse_certificate}
                                disabled={list > 0 ? true : false}
                              ></Input>
                              <InputGroupText
                                className="input-group-text bg-white border-start-0"
                                id="newpass"
                              >
                                <a
                                  href={
                                    `${DOCUMENT_URL}` + datas?.mse_certificate
                                  }
                                  download
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="bi bi-box-arrow-down"></i>
                                </a>
                              </InputGroupText>
                            </InputGroup>
                          ) : (
                            <>
                              <Input
                                type="file"
                                id="mse_certificate"
                                name="mse_certificate"
                                accept=".pdf"
                                className="form-control"
                                onChange={(e: any) =>
                                  handleFilechange(e, e.target.files[0])
                                }
                                disabled={list > 0 ? true : false}
                              ></Input>
                              <Label
                                className="image-button"
                                for="mse_certificate"
                                id="mse_certificate"
                              >
                                {VendorSignup?.Choose_Documents[language]}
                              </Label>
                            </>
                          )}
                        </div>
                        <ErrorMessage
                          name="mse_certificate"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                  ) : null}

                  <Col md={4} sm={6}>
                    <FormGroup className="file">
                      <Label>
                      {VendorSignup?.GST_Registration[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        {datas?.gst_certificate ? (
                          <>
                            {" "}
                            <InputGroup>
                              <Input
                                id="gst_certificate"
                                name="gst_certificate"
                                accept=".pdf"
                                className="form-control"
                                value={datas?.gst_certificate}
                                disabled={list > 0 ? true : false}
                              ></Input>
                              <InputGroupText
                                className="input-group-text bg-white border-start-0"
                                id="newpass"
                              >
                                <a
                                  href={
                                    `${DOCUMENT_URL}` + datas?.gst_certificate
                                  }
                                  download
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="bi bi-box-arrow-down"></i>
                                </a>
                              </InputGroupText>
                            </InputGroup>
                          </>
                        ) : (
                          <>
                            {" "}
                            <Input
                              type="file"
                              id="gst_certificate"
                              name="gst_certificate"
                              accept=".pdf"
                              className="form-control"
                              onChange={(e: any) =>
                                handleFilechange(e, e.target.files[0])
                              }
                              disabled={list > 0 ? true : false}
                            ></Input>
                            <Label
                              className="image-button"
                              for="gst_certificate"
                              id="gst_certificate"
                            >
                              {VendorSignup?.Choose_Documents[language]}
                            </Label>
                          </>
                        )}
                      </div>
                      <ErrorMessage
                        name="gst_certificate"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={4} sm={6}>
                    <FormGroup className="file">
                      <Label>
                      {VendorSignup?.Empanel_Certificate[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        {datas?.empanel_certificate ? (
                          <>
                            {" "}
                            <InputGroup>
                              <Input
                                id="empanel_certificate"
                                name="empanel_certificate"
                                accept=".pdf"
                                className="form-control"
                                value={datas?.empanel_certificate}
                                disabled={list > 0 ? true : false}
                              ></Input>
                              <InputGroupText
                                className="input-group-text bg-white border-start-0"
                                id="newpass"
                              >
                                <a
                                  href={
                                    `${DOCUMENT_URL}` +
                                    datas?.empanel_certificate
                                  }
                                  download
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="bi bi-box-arrow-down"></i>
                                </a>
                              </InputGroupText>
                            </InputGroup>
                          </>
                        ) : (
                          <>
                            {" "}
                            <Input
                              type="file"
                              id="empanel_certificate"
                              name="empanel_certificate"
                              accept=".pdf"
                              className="form-control"
                              onChange={(e: any) =>
                                handleFilechange(e, e.target.files[0])
                              }
                              disabled={list > 0 ? true : false}
                            ></Input>
                            <Label
                              className="image-button"
                              for="empanel_certificate"
                              id="empanel_certificate"
                            >
                              {VendorSignup?.Choose_Documents[language]}
                            </Label>
                          </>
                        )}
                      </div>
                      <ErrorMessage
                        name="empanel_certificate"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>

                  {vendorUdyam == "yes" ? (
                    <Col md={4} sm={6}>
                      <FormGroup className="file">
                        <Label>
                        {VendorSignup?.Udyam_Certificate[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <div className="image-input">
                          {datas?.empanel_certificate ? (
                            <>
                              {" "}
                              <InputGroup>
                                <Input
                                  id="udyam_certificate"
                                  name="udyam"
                                  accept=".pdf"
                                  className="form-control"
                                  value={datas?.udyam}
                                  disabled={list > 0 ? true : false}
                                ></Input>
                                <InputGroupText
                                  className="input-group-text bg-white border-start-0"
                                  id="newpass"
                                >
                                  <a
                                    href={`${DOCUMENT_URL}` + datas?.udyam}
                                    download
                                    target="_blank"
                                  >
                                    {" "}
                                    <i className="bi bi-box-arrow-down"></i>
                                  </a>
                                </InputGroupText>
                              </InputGroup>
                            </>
                          ) : (
                            <>
                              {" "}
                              <Input
                                type="file"
                                id="udyam"
                                name="udyam"
                                accept=".pdf"
                                className="form-control"
                                onChange={(e: any) =>
                                  handleFilechange(e, e.target.files[0])
                                }
                                disabled={list > 0 ? true : false}
                              ></Input>
                              <Label
                                className="image-button"
                                for="udyam"
                                id="udyam"
                              >
                                {VendorSignup?.Choose_Documents[language]}
                              </Label>
                            </>
                          )}
                        </div>
                        <ErrorMessage name="udyam" component={TextError} />
                      </FormGroup>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                <div className="company-information-update-wrap">
                  <h4>{VendorSignup?.Company_Turnover[language]}</h4>
                  <Row>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                          {VendorSignup?.First_Financial_year[language]}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>

                          {list <= 0 ? (
                            <DatePicker
                              value={values?.Ffinancial_year}
                              onChange={(e: any) => {
                                setFieldValue("Ffinancial_year", e);
                                setFieldValue("Sfinancial_year", "");
                              }}
                              name="Ffinancial_year"
                              format="dd-MM-y"
                              dayPlaceholder={"dd"}
                              monthPlaceholder={"mm"}
                              yearPlaceholder={"yyyy"}
                              className={` ${
                                touched.Ffinancial_year &&
                                errors.Ffinancial_year
                                  ? "is-invalid"
                                  : ""
                              }`}
                              maxDate={new Date()}
                              disabled={list > 0 ? true : false}
                            />
                          ) : (
                            <Input
                              type="text"
                              name="Ffinancial_year"
                              value={`(Apr-01-${
                                datas?.Ffinancial_year
                              }) - (Mar-31-${
                                Number(datas?.Ffinancial_year) + 1
                              }) `}
                              disabled={list > 0 ? true : false}
                            ></Input>
                          )}

                          <ErrorMessage
                            name="Ffinancial_year"
                            component={TextError}
                          />
                        </div>
                        <br />
                        <div className="custom-input">
                          <Label>
                          {VendorSignup?.First_Financial_year_amount[language]}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <Input
                            type="number"
                            name="Ffinancial_year_amount"
                            onChange={handleChange}
                            className={`form-control ${
                              touched.Ffinancial_year_amount &&
                              errors.Ffinancial_year_amount
                                ? "is-invalid"
                                : ""
                            }`}
                            value={values?.Ffinancial_year_amount}
                            min="1"
                            placeholder="Amount"
                            disabled={
                              !values?.Ffinancial_year
                                ? true
                                : list > 0
                                ? true
                                : false
                            }
                          ></Input>
                          <ErrorMessage
                            name="Ffinancial_year_amount"
                            component={TextError}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6} className="align-self-end">
                      <FormGroup>
                        <Label>
                        {VendorSignup?.First_Financial_year_document[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <div className="image-input">
                          {datas?.FFfinancial_year ? (
                            <InputGroup>
                              <Input
                                id="FFfinancial_year"
                                name="FFfinancial_year"
                                className="form-control"
                                accept=".pdf"
                                value={datas?.FFfinancial_year}
                                disabled={list > 0 ? true : false}
                              ></Input>
                              <InputGroupText
                                className="input-group-text bg-white border-start-0"
                                id="newpass"
                              >
                                <a
                                  href={
                                    `${DOCUMENT_URL}` + datas?.FFfinancial_year
                                  }
                                  download
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="bi bi-box-arrow-down"></i>
                                </a>
                              </InputGroupText>
                            </InputGroup>
                          ) : (
                            <>
                              <Input
                                type="file"
                                id="FFfinancial_year"
                                name="FFfinancial_year"
                                className="form-control"
                                accept=".pdf"
                                onChange={(e: any) =>
                                  handleFilechange(e, e.target.files[0])
                                }
                                disabled={
                                  !values?.Ffinancial_year
                                    ? true
                                    : list > 0
                                    ? true
                                    : false
                                }
                              ></Input>
                              <Label
                                className="image-button"
                                for="FFfinancial_year"
                                id="FFfinancial_year"
                              >
                                {VendorSignup?.Choose_Documents[language]}
                              </Label>
                            </>
                          )}
                        </div>
                        <ErrorMessage
                          name="FFfinancial_year"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                          {VendorSignup?.GST_Registration[language]}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <br />
                          {list <= 0 ? (
                            <DatePicker
                              value={values?.Sfinancial_year}
                              onChange={(e: any) => {
                                setFieldValue("Sfinancial_year", e);
                                setFieldValue("Tfinancial_year", "");
                              }}
                              name="Sfinancial_year"
                              format={"dd-MM-y"}
                              dayPlaceholder={"dd"}
                              monthPlaceholder={"mm"}
                              yearPlaceholder={"yyyy"}
                              className={`form-control ${
                                touched.Sfinancial_year &&
                                errors.Sfinancial_year
                                  ? "is-invalid"
                                  : ""
                              }`}
                              // maxDate={list <= 0 ? values?.Ffinancial_year ? new Date(`${(values?.Ffinancial_year?.getMonth() + 1).toString().padStart(2, "0")}-${values?.Ffinancial_year?.getDate().toString().padStart(2, "0")}-${values?.Ffinancial_year?.getFullYear() - 1}`?.toString()) : new Date() : new Date()}
                              maxDate={
                                list <= 0
                                  ? values?.Ffinancial_year
                                    ? new Date(
                                        `${values?.Ffinancial_year?.getFullYear()}`?.toString()
                                      )
                                    : new Date()
                                  : new Date()
                              }
                              disabled={
                                list <= 0
                                  ? values?.Ffinancial_year
                                    ? false
                                    : true
                                  : true
                              }
                            />
                          ) : (
                            <Input
                              type="text"
                              name="Sfinancial_year"
                              value={`(Apr-01-${
                                datas?.Sfinancial_year
                              }) - (Mar-31-${
                                Number(datas?.Sfinancial_year) + 1
                              }) `}
                              disabled={
                                list <= 0
                                  ? values?.Ffinancial_year
                                    ? false
                                    : true
                                  : true
                              }
                            ></Input>
                          )}
                          <ErrorMessage
                            name="Sfinancial_year"
                            component={TextError}
                          />
                        </div>
                        <br />
                        <div className="custom-input">
                          <Label>
                          {VendorSignup?.Second_Financial_year[language]}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>

                          <Input
                            type="number"
                            name="Sfinancial_year_amount"
                            className="form-control"
                            min="1"
                            onChange={handleChange}
                            placeholder="Amount"
                            value={values?.Sfinancial_year_amount}
                            disabled={
                              !values?.Sfinancial_year
                                ? true
                                : list > 0
                                ? true
                                : false
                            }
                          ></Input>

                          <ErrorMessage
                            name="Sfinancial_year_amount"
                            component={TextError}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6} className="align-self-end">
                      <FormGroup>
                        <Label>
                        {VendorSignup?.Second_Financial_year_Documents[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <div className="image-input">
                          {datas?.SFfinancial_year ? (
                            <InputGroup>
                              <Input
                                id="SFfinancial_year"
                                name="SFfinancial_year"
                                className="form-control"
                                accept=".pdf"
                                value={datas?.SFfinancial_year}
                                disabled={list > 0 ? true : false}
                              ></Input>
                              <InputGroupText
                                className="input-group-text bg-white border-start-0"
                                id="newpass"
                              >
                                <a
                                  href={
                                    `${DOCUMENT_URL}` + datas?.SFfinancial_year
                                  }
                                  download
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="bi bi-box-arrow-down"></i>
                                </a>
                              </InputGroupText>
                            </InputGroup>
                          ) : (
                            <>
                              {" "}
                              <Input
                                type="file"
                                id="SFfinancial_year"
                                name="SFfinancial_year"
                                className="form-control"
                                accept=".pdf"
                                onChange={(e: any) =>
                                  handleFilechange(e, e.target.files[0])
                                }
                                disabled={
                                  !values?.Sfinancial_year
                                    ? true
                                    : list > 0
                                    ? true
                                    : false
                                }
                              ></Input>
                              <Label
                                className="image-button"
                                for="SFfinancial_year"
                                id="SFfinancial_year"
                              >
                                {VendorSignup?.Choose_Documents[language]}
                              </Label>
                            </>
                          )}
                        </div>
                        <ErrorMessage
                          name="SFfinancial_year"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                          {VendorSignup?.Third_Financial_year[language]}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <br />
                          {list <= 0 ? (
                            <DatePicker
                              value={values?.Tfinancial_year}
                              onChange={(e: any) => {
                                setFieldValue("Tfinancial_year", e);
                              }}
                              name="Tfinancial_year"
                              format={"dd-MM-y"}
                              dayPlaceholder={"dd"}
                              monthPlaceholder={"mm"}
                              yearPlaceholder={"yyyy"}
                              className={`form-control ${
                                touched.Tfinancial_year &&
                                errors.Tfinancial_year
                                  ? "is-invalid"
                                  : ""
                              }`}
                              // maxDate={list <= 0 ? values?.Sfinancial_year ? new Date(`${(values?.Sfinancial_year?.getMonth() + 1).toString().padStart(2, "0")}-${values?.Sfinancial_year?.getDate().toString().padStart(2, "0")}-${values?.Sfinancial_year?.getFullYear() - 1}`?.toString()) : new Date() : new Date()}
                              maxDate={
                                list <= 0
                                  ? values?.Sfinancial_year
                                    ? new Date(
                                        `${values?.Sfinancial_year?.getFullYear()}`?.toString()
                                      )
                                    : new Date()
                                  : new Date()
                              }
                              disabled={
                                list <= 0
                                  ? values?.Sfinancial_year
                                    ? false
                                    : true
                                  : true
                              }
                            />
                          ) : (
                            <Input
                              type="text"
                              name="Tfinancial_year"
                              className="form-control"
                              disabled={
                                list <= 0
                                  ? values?.Sfinancial_year
                                    ? false
                                    : true
                                  : true
                              }
                              value={`(Apr-01-${
                                datas?.Tfinancial_year
                              }) - (Mar-31-${
                                Number(datas?.Tfinancial_year) + 1
                              }) `}
                            ></Input>
                          )}
                          <ErrorMessage
                            name="Tfinancial_year"
                            component={TextError}
                          />
                        </div>
                        <br />
                        <div className="custom-input">
                          <Label>
                          {VendorSignup?.Third_Financial_year_amount[language]}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>

                          <Input
                            type="number"
                            name="Tfinancial_year_amount"
                            onChange={handleChange}
                            min="1"
                            className="form-control"
                            placeholder="Amount"
                            disabled={
                              !values?.Tfinancial_year
                                ? true
                                : list > 0
                                ? true
                                : false
                            }
                            value={values?.Tfinancial_year_amount}
                          ></Input>
                          <ErrorMessage
                            name="Tfinancial_year_amount"
                            component={TextError}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6} className="align-self-end">
                      <FormGroup>
                        <Label>
                        {VendorSignup?.Third_Financial_year_document[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <div className="image-input">
                          {datas?.TFfinancial_year ? (
                            <InputGroup>
                              <Input
                                id="Tfinancial_year"
                                name="TFfinancial_year"
                                className="form-control"
                                accept=".pdf"
                                disabled={list > 0 ? true : false}
                                value={datas?.TFfinancial_year}
                              ></Input>
                              <InputGroupText
                                className="input-group-text bg-white border-start-0"
                                id="newpass"
                              >
                                <a
                                  href={
                                    `${DOCUMENT_URL}` + datas?.TFfinancial_year
                                  }
                                  download
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="bi bi-box-arrow-down"></i>
                                </a>
                              </InputGroupText>
                            </InputGroup>
                          ) : (
                            <>
                              <Input
                                type="file"
                                id="Tfinancial_year"
                                name="TFfinancial_year"
                                className="form-control"
                                accept=".pdf"
                                onChange={(e: any) =>
                                  handleFilechange(e, e.target.files[0])
                                }
                                disabled={
                                  !values?.Tfinancial_year
                                    ? true
                                    : list > 0
                                    ? true
                                    : false
                                }
                              ></Input>
                              <Label
                                className="image-button"
                                for="Tfinancial_year"
                                id="Tfinancial_year"
                              >
                                 {VendorSignup?.Choose_Documents[language]}
                              </Label>
                            </>
                          )}
                        </div>
                        <ErrorMessage
                          name="TFfinancial_year"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Row>
                  <div className="pointofcon-information-update-wrap">
                    <h4> {VendorSignup?.Not_Mandatory[language]}</h4>
                    <Row>
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <Label> {VendorSignup?.ISO_Certificate[language]}</Label>
                          <div className="image-input">
                            {datas?.iso_certificate ? (
                              <InputGroup>
                                <input
                                  id="iso_certificate"
                                  name="iso_certificate"
                                  className="form-control"
                                  accept=".pdf"
                                  value={datas?.iso_certificate}
                                  disabled={list > 0 ? true : false}
                                ></input>
                                <InputGroupText
                                  className="input-group-text bg-white border-start-0"
                                  id="newpass"
                                >
                                  <a
                                    href={
                                      `${DOCUMENT_URL}` + datas?.iso_certificate
                                    }
                                    download
                                    target="_blank"
                                  >
                                    {" "}
                                    <i className="bi bi-box-arrow-down"></i>
                                  </a>
                                </InputGroupText>
                              </InputGroup>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  ref={isoRef}
                                  id="iso_certificate"
                                  name="iso_certificate"
                                  className="form-control"
                                  accept=".pdf"
                                  onChange={(e: any) =>
                                    handleFilechange(e, e.target.files[0])
                                  }
                                  disabled={list > 0 ? true : false}
                                ></input>
                                <Label
                                  className="image-button"
                                  for="iso_certificate"
                                  id="iso_certificate"
                                >
                                  {VendorSignup?.Choose_Documents[language]}
                                </Label>
                                {iso == true ? (
                                  <span
                                    className="upload-text"
                                    style={{
                                      marginRight: "0px",
                                      zIndex: "45",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleFileremove("iso")}
                                  >
                                    &#x2715;
                                  </span>
                                ) : null}
                              </>
                            )}
                          </div>
                          <ErrorMessage
                            name="iso_certificat"
                            component={TextError}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <Label>{VendorSignup?.OEM_Certificate[language]}</Label>
                          <div className="image-input">
                            {datas?.oem_certificate ? (
                              <InputGroup>
                                <input
                                  id="oem_certificate"
                                  name="oem_certificate"
                                  className="form-control"
                                  accept=".pdf"
                                  value={datas?.oem_certificate}
                                  disabled={list > 0 ? true : false}
                                ></input>
                                <InputGroupText
                                  className="input-group-text bg-white border-start-0"
                                  id="newpass"
                                >
                                  <a
                                    href={
                                      `${DOCUMENT_URL}` + datas?.oem_certificate
                                    }
                                    download
                                    target="_blank"
                                  >
                                    {" "}
                                    <i className="bi bi-box-arrow-down"></i>
                                  </a>
                                </InputGroupText>
                              </InputGroup>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  ref={oemRef}
                                  id="oem_certificate"
                                  name="oem_certificate"
                                  className="form-control"
                                  accept=".pdf"
                                  onChange={(e: any) =>
                                    handleFilechange(e, e.target.files[0])
                                  }
                                  disabled={list > 0 ? true : false}
                                ></input>
                                <Label
                                  className="image-button"
                                  for="oem_certificate"
                                  id="oem_certificate"
                                >
                                  {VendorSignup?.Choose_Documents[language]}
                                </Label>
                                {oem == true ? (
                                  <span
                                    className="upload-text"
                                    style={{
                                      marginRight: "0px",
                                      zIndex: "45",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleFileremove("oem")}
                                  >
                                    &#x2715;
                                  </span>
                                ) : null}
                              </>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <Label>{VendorSignup?.MAF_Certificate[language]}</Label>
                          <div className="image-input">
                            {datas?.maf_certificate ? (
                              <InputGroup>
                                <input
                                  id="maf_certificate"
                                  name="maf_certificate"
                                  className="form-control"
                                  accept=".pdf"
                                  value={datas?.maf_certificate}
                                  disabled={list > 0 ? true : false}
                                ></input>

                                {/* <Label className="image-button" for="maf_certificate" id="maf_certificate">Choose Documents</Label>
                                {maf == true ? <span className="upload-text" style={{ marginRight: "50px", zIndex: "45", cursor: "pointer" }}
                                  onClick={() => handleFileremove("maf")}>&#x2715;</span> : null} */}

                                <InputGroupText
                                  className="input-group-text bg-white border-start-0"
                                  id="newpass"
                                >
                                  <a
                                    href={
                                      `${DOCUMENT_URL}` + datas?.maf_certificate
                                    }
                                    download
                                    target="_blank"
                                  >
                                    {" "}
                                    <i className="bi bi-box-arrow-down"></i>
                                  </a>
                                </InputGroupText>
                              </InputGroup>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  ref={mafRef}
                                  id="maf_certificate"
                                  name="maf_certificate"
                                  className="form-control"
                                  accept=".pdf"
                                  onChange={(e: any) =>
                                    handleFilechange(e, e.target.files[0])
                                  }
                                  disabled={list > 0 ? true : false}
                                ></input>
                                <Label
                                  className="image-button"
                                  for="maf_certificate"
                                  id="maf_certificate"
                                >
                                 {VendorSignup?.Choose_Documents[language]}
                                </Label>
                                {maf == true ? (
                                  <span
                                    className="upload-text"
                                    style={{
                                      marginRight: "50px",
                                      zIndex: "45",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleFileremove("maf")}
                                  >
                                    &#x2715;
                                  </span>
                                ) : null}
                              </>
                            )}
                          </div>
                          <ErrorMessage
                            name="maf_certificate"
                            component={TextError}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="agree-checkbox">
                      <FormGroup>
                        <div className="agree-checkbox-wrap">
                          <Input
                            name="address"
                            type="checkbox"
                            className="form-check-input"
                            value={values?.address}
                            onChange={handleChange}
                            disabled={list > 0 ? true : false}
                            style={{
                              backgroundColor: `${
                                list > 0 ? "#b6b4b4" : "white"
                              }`,
                            }}
                          ></Input>
                          <Label>{VendorSignup?.Make_sure_entered_details_are_correct[language]}</Label>
                        </div>
                        <ErrorMessage name="address" component={TextError} />
                      </FormGroup>
                    </div>
                  </Col>
                  <Col sm={12} className="text-end">
                    <Button
                      className="btn btn-primary mb-5"
                      color="primary"
                      type="submit"
                      disabled={list > 0 ? true : false}
                    >
                      {VendorSignup?.submit[language]}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
function setFieldValue(arg0: string, arg1: any[]) {
  throw new Error("Function not implemented.");
}
