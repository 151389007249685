export const Vendor_Settings_ReuploadModal:any={
    // Vendor/Settings/ReuploadModal/EmpanelReupload...//
   Any_Other_Office_Proof: {
        en: 'Any Other Office Proof',
        ka: 'ಯಾವುದೇ ಇತರ ಕಚೇರಿ ಪುರಾವೆ'
    },
    Company_Name: {
        en: 'Company Name',
        ka: 'ಸಂಸ್ಥೆಯ ಹೆಸರು'
    },
    Pincode: {
        en: 'Pincode',
        ka: 'ಪಿನ್‌ಕೋಡ್'
    },
    City: {
        en: 'City',
        ka: 'ನಗರ'
    },
   
    District: {
        en: 'District',
        ka: 'ಜಿಲ್ಲೆ'
    },
    State: {
        en: 'State',
        ka: 'ರಾಜ್ಯ'
    },
   
  

    Empanel_Documents: {
        en: 'Empanel Documents',
        ka: 'ಎಂಪನೆಲ್ ಡಾಕ್ಯುಮೆಂಟ್ಸ್'
    },
    Empanel_Certificate: {
        en: 'Empanel Certificate',
        ka: 'ಎಂಪ್ಯಾನೆಲ್ ಪ್ರಮಾಣಪತ್ರ'
    },
    Choose_Documents: {
        en: 'Choose Documents',
        ka: 'ದಾಖಲೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    Empanelment_Date: {
        en: 'Empanelment Date',
        ka: 'ಎಂಪನೆಲ್ಮೆಂಟ್ ದಿನಾಂಕ'
    },
    Empanelment_Expiry_Date: {
        en: 'Empanelment Expiry Date',
        ka: 'ಈಮ್‌ಪ್ಯಾನೆಲ್ಮೆಂಟ್ ಮುಗಿವುದಾಗುವ ದಿನಾಂಕ'
    },
    Submit: {
        en: 'Submit',
        ka: 'ಸಲ್ಲಿಸಿ'
    },
    // Vendor/Settings/ReuploadModal/IITurnoverReupload...//
    IT_Turnover: {
        en: 'IT Turnover',
        ka: 'ಐಟಿ ಪರಿವರ್ತನೆ'
    },
    First_IT_Year: {
        en: 'First IT Year',
        ka: 'ಮೊದಲ ಐಟಿ ವರ್ಷ'
    },
    First_IT_Year_Amount: {
        en: 'First IT Year Amount',
        ka: 'ಮೊದಲ ಐಟಿ ವರ್ಷ ಮೊತ್ತ'
    },

    First_IT_Year_Documents: {
        en: 'First IT Year Documents',
        ka: 'ಮೊದಲ ವರ್ಷದ ದಾಖಲೆಗಳು'
    },
    Second_IT_Year: {
        en: 'Second IT Year',
        ka: 'ಎರಡನೇ ಐಟಿ ವರ್ಷ'
    },
    Second_IT_Year_Documents: {
        en: 'Second IT Year Documents',
        ka: 'ಎರಡನೇ ಐಟಿ ವರ್ಷದ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳು'
    },
     Second_IT_Year_Amount: {
        en: 'Second IT Year Amount',
        ka: 'ದ್ವಿತೀಯ ಐಟಿ ವರ್ಷ ಮೊತ್ತ'
    },
    Approved_by_Chartered_Accountant: {
        en: 'Approved by Chartered Accountant',
        ka: 'ಚಾರ್ಟರ್ಡ್ ಅಕೌಂಟಂಟ್ ದ್ವಾರಾ ಅನುಮೋದಿತ'
    },
    CA: {
        en: 'CA',
        ka: 'ಸಿಎ'
    },
    CA_Name: {
        en: 'CA Name',
        ka: 'ಸಿಎ ಹೆಸರು '
    },
    CA_Email: {
        en: 'CA Email',
        ka: 'ಸಿಎ ಇಮೇಲ್ '
    },
    CA_Phone_Number: {
        en: 'CA Phone Number',
        ka: 'ಸಿಎ ದೂರವಾಣಿ ಸಂಖ್ಯೆ '
    },
    Catagory: {
        en: 'Catagory',
        ka: 'ವರ್ಗ'
    },
    Mark: {
        en: 'Mark',
        ka: 'ಮಾರ್ಕ್'
    },

    // Vendor/Settings/ReuploadModal/LandReupload...//

    Land_Document: {
        en: 'Land Document',
        ka: 'ಭೂಮಿ ಡಾಕ್ಯುಮೆಂಟ್'
    },
    Village: {
        en: 'Village',
        ka: 'ಗ್ರಾಮ '
    },
    Col: {
        en: 'Col',
        ka: 'ಕಲಂ '
    },
    Extent: {
        en: 'Extent',
        ka: 'ವಿಸ್ತರಣೆ '
    },
    Value: {
        en: 'Value',
        ka: 'ಮೌಲ್ಯ '
    },
    Survey_No: {
        en: 'Survey No',
        ka: 'ಸರ್ವೇ ಸಂಖ್ಯೆ'
    },
    Mark_10: {
        en: 'Mark 10',
        ka: 'ಮಾರ್ಕ್ ೧೦'
    },
    // Vendor/Settings/ReuploadModal/manpowerReupload...//
    HR_Documents: {
        en: 'HR Documents',
        ka: 'ಮಾನವ ಸಂಪನ್ಮೂಲ ದಾಖಲೆಗಳು'
    },
    Date_of_in_Document: {
        en: 'Date of in Document',
        ka: 'ದಸ್ತಾವೇಜಿನಲ್ಲಿ ದಿನಾಂಕ'
    },

    No_of_Employees: {
        en: 'No of Employees',
        ka: 'ನೌಕರರ ಸಂಖ್ಯೆ'
    },


    Manpower: {
        en: 'Manpower',
        ka: 'ಮಾನವಶಕ್ತಿ'
    },
    Manpower_Documents: {
        en: 'Manpower Documents',
        ka: 'ಮಾನವಶಕ್ತಿ ದಾಖಲೆಗಳು'
    },
    You_are_in_Category : {
        en: 'You are in Category ',
        ka: 'ನೀವು ವರ್ಗದಲ್ಲಿದ್ದೀರಿ'
    },
    Must_need_a_Minimum_of : {
        en: 'Must_need_a_Minimum_of ',
        ka: 'ಕಡಿಮೆಯಾಗಿದ್ದರೆ ಅಗತ್ಯವಿದೆ'
    },
    Employees: {
        en: 'Employees',
        ka: 'ನೌಕರರು'
    },
     // Vendor/Settings/ReuploadModal/OEMReupload...//

     OEM: {
        en: 'OEM',
        ka: 'ಮೂಲ ಉಪಕರಣ ನಿರ್ಮಾತಾ'
    },
    OEM_Certificate: {
        en: 'OEM Certificate',
        ka: 'ಮೂಲ ಉಪಕರಣ ಪ್ರಮಾಣಪತ್ರ'
    },
    Comapny_Type_in_OEM_Certificate: {
        en: 'Comapny Type in OEM Certificate',
        ka: 'ಕಂಪನಿಯ ಪ್ರಕಾರ'
    },
    OEM_Valid_From: {
        en: 'OEM Valid From',
        ka: 'ಮೂಲ ಉಪಕರಣ ನಿರ್ಮಾತಾ ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ'
    },
    OEM_Valid_To: {
        en: 'OEM Valid To',
        ka: ' ಮೂಲ ಉಪಕರಣ ನಿರ್ಮಾತಾ ಗೆ ಮಾನ್ಯವಾಗಿದೆ'
    },
    // Vendor/Settings/ReuploadModal/TurnOverReuploadModal...//

    Company_Turnover: {
        en: 'Company Turnover',
        ka: 'ಕಂಪನಿಯ ತಿರಸ್ಕಾರ'
    },
    First_Financial_year: {
        en: 'First Financial year',
        ka: 'ಮೊದಲ ಆರ್ಥಿಕ ವರ್ಷ'
    },
    First_Financial_year_Documents: {
        en: 'First Financial year Documents',
        ka: 'ಮೊದಲ ಆರ್ಥಿಕ ವರ್ಷದ ದಸ್ತಾವೇಜುಗಳು'
    },
    First_Financial_year_Amount: {
        en: 'First Financial year Amount',
        ka: 'ಮೊದಲ ಆರ್ಥಿಕ ವರ್ಷದ ಮೊತ್ತ'
    },
    Second_Financial_year: {
        en: 'Second Financial year',
        ka: 'ಎರಡನೇ ಆರ್ಥಿಕ ವರ್ಷ'
    },
    Second_Financial_year_Documents: {
        en: 'Second Financial year Documents',
        ka: 'ಎರಡನೇ ಆರ್ಥಿಕ ವರ್ಷದ ದಸ್ತಾವೇಜುಗಳು'
    },
    Second_Financial_year_Amount: {
        en: 'Second Financial year Amount',
        ka: 'ಎರಡನೇ ಆರ್ಥಿಕ ವರ್ಷದ ಮೊತ್ತ'
    },
    Third_Financial_year: {
        en: 'Third Financial year',
        ka: 'ಮೂರನೇ ಆರ್ಥಿಕ ವರ್ಷ'
    },
    Third_Financial_year_Documents: {
        en: 'Third Financial year Documents',
        ka: 'ಮೂರನೇ ಆರ್ಥಿಕ ವರ್ಷದ ದಸ್ತಾವೇಜುಗಳು'
    },
    Third_Financial_year_Amount: {
        en: 'Third Financial year Amount',
        ka: 'ಮೂರನೇ ಆರ್ಥಿಕ ವರ್ಷದ ಮೊತ್ತ'
    },
    // Vendor/Settings/ReuploadModal/WorkOrderReuploadl...//
    Work_Order_Details: {
        en: 'Work Order Details',
        ka: 'ಕಾರ್ಯ ಆದೇಶ ವಿವರಗಳು'
    },
    Work_Order_Certificate: {
        en: 'Work Order Certificate',
        ka: 'ಕಾರ್ಯ ಆದೇಶ ಪ್ರಮಾಣಪತ್ರ'
    },
    No_of_Projects_Done: {
        en: 'No of Projects Done',
        ka: 'ಮುಗಿದ ಪ್ರಾಜೆಕ್ಟ್ಗಳ ಸಂಖ್ಯೆ'
    },
    // No_of_Projects_Done: {
    //     en: 'No of Projects Done',
    //     ka: 'ಮುಗಿದ ಪ್ರಾಜೆಕ್ಟ್ಗಳ ಸಂಖ್ಯೆ'
    // },
    Mark_5: {
        en: 'Mark:5',
        ka: 'ಅಂಕ ಐದು'
    },
     // Vendor/Settings/ReuploadModal/AccountDetiles...//

     Account_Details: {
        en: 'Account Details',
        ka: 'ಖಾತೆ ವಿವರಗಳು'
    },
    IFSC_Code: {
        en: 'IFSC Code',
        ka: 'ಭಾರತೀಯ ಆರ್ಥಿಕ ವ್ಯವಸ್ಥೆ ಕೋಡ್ ಕೋಡ್'
    },
    
     // Vendor/Settings/ReuploadModal/AdvancedReuploadModal...//

     Vendor_Type: {
        en: 'Vendor Type',
        ka: 'ವೆಂಡರ್ ಪ್ರಕಾರ'
    },
    Select: {
        en: 'Select',
        ka: 'ಆಯ್ಕೆ ಮಾಡಿ'
    },
    GST_CLass: {
        en: 'GST CLass',
        ka: 'ವಸ್ತು ಮತ್ತು ಸೇವೆ ತೆರಿಗೆ ವರ್ಗ'
    },
    Company_Email: {
        en: 'Company Email',
        ka: 'ಕಂಪನಿ ಇಮೇಲ್'
    },
    Company_Number: {
        en: 'Company Number',
        ka: 'ಕಂಪನಿ ಸಂಖ್ಯೆ'
    },
    Company_Incorporation_Date: {
        en: 'Company Incorporation Date',
        ka: 'ಕಂಪನಿ ಸೇರ್ಪಡೆ ದಿನಾಂಕ'
    },
    // Vendor/Settings/Profilevalidation/...//

    
    Field_is_required: {
        en: 'Field is required',
        ka: 'ಕ್ಷೇತ್ರ ಅಗತ್ಯವಿದೆ'
    },
    // Vendor/Settings/ReuploadModal/...//

    Upload_document: {
        en: 'Upload document',
        ka: 'ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್ಲೋಡ್ ಮಾಡಿ'
    },
    Amount: {
        en: 'Amount',
        ka: 'ಮೊತ್ತ'
    },
    Year: {
        en: 'Year',
        ka: 'ವರ್ಷ'
    },
     // Vendor/Settings/SettingsLayout...//
    Profile_Settings: {
        en: 'Profile Settings',
        ka: 'ಪ್ರೊಫೈಲ್ ಸೆಟ್ಟಿಂಗ್ಗಳು'
    },
    Documents: {
        en: 'Documents',
        ka: 'ದಾಖಲೆಗಳು'
    },

    Vendor_Advanced_Reupload: {
        en: 'Vendor Advanced Reuploads',
        ka: 'ಮಾರಾಟಗಾರರ ಸುಧಾರಿತ ಮರುಅಪ್‌ಲೋಡ್‌ಗಳು'
    },
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
    },

    OEM_details: {
        en: 'OEM details',
        ka: 'ಒಇಎಂ ವಿವರಗಳು'
    },
   
}