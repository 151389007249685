import React, { useEffect, useState,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";

import DatePicker from "react-date-picker";

import { DOCS_URL } from "../../../../Library/ApiSettings";
import {
  preventNegativeValues,
  turn_over_mark_caluction,
} from "../../../../Library/Constants/helpers";
import TextError from "../../../../Components/Errors/TextError";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../Components/Context/Context";
import { Vendor_Settings_ReuploadModal } from "../../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";


const DOCUMENT_URL = DOCS_URL;
export default function FinancialTurnoverModal(props: any) {
  const {language,setLanguage}=useContext(Context)
  const currentYear = new Date().getFullYear(); // 2020
  const previousYear = currentYear - 1;
  const nest_yr = previousYear - 1;
  const handleFileChange = (e: any, values?: any) => {
    props?.type == 7
      ? props?.setFieldValue("FFfinancial_year", e.target.files[0])
      : props?.type == 8
      ? props?.setFieldValue("SFfinancial_year", e.target.files[0])
      : props?.setFieldValue("TFfinancial_year", e.target.files[0]);
  };

  return (
    <>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Document Reupload"
      >
        <div className="company-information-update-wrap">
          <h4>{Vendor_Settings_ReuploadModal?.Company_Turnover?.[language]}</h4>
          <hr />
          <Form className="form-wrap" onSubmit={props?.handleSubmit}>
            {props?.type == 7 && (
              <>
                <Row>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.First_Financial_year?.[language]}  
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <DatePicker
                          value={props?.values?.Ffinancial_year}
                          onChange={(e: any) => {
                            props?.setFieldValue("Ffinancial_year", e);
                            props?.setFieldValue("Sfinancial_year", "");
                          }}
                          name="Ffinancial_year"
                          format="dd-MM-y"
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          maxDate={new Date()}
                          disabled={props?.list > 0 ? true : false}
                        />

                        <ErrorMessage
                          name="Ffinancial_year"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <Label>
                      {Vendor_Settings_ReuploadModal?.First_Financial_year_Documents?.[language]}  
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        <Input
                          type="file"
                          id="FFfinancial_year"
                          name="FFfinancial_year"
                          className="form-control"
                          accept=".pdf"
                          onChange={(e: any) =>
                            handleFileChange(e, e.target.files[0])
                          }
                          disabled={
                            !props?.values?.Ffinancial_year
                              ? true
                              : props?.list > 0
                              ? true
                              : false
                          }
                        ></Input>
                        <Label
                          className="image-button"
                          for="FFfinancial_year"
                          id="FFfinancial_year"
                        >
                          {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]} 
                        </Label>
                      </div>
                      <ErrorMessage
                        name="FFfinancial_year"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.First_Financial_year_Amount?.[language]}  
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <Input
                          type="number"
                          name="Ffinancial_year_amount"
                          onChange={props?.handleChange}
                          value={props?.values?.Ffinancial_year_amount}
                          min="1"
                          placeholder="Amount"
                        ></Input>

                        <ErrorMessage
                          name="Ffinancial_year_amount"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            {props?.type == 8 && (
              <>
                {" "}
                <Row>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Second_Financial_year?.[language]} 
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <br />

                        <DatePicker
                          value={props?.values?.Sfinancial_year}
                          onChange={(e: any) => {
                            props?.setFieldValue("Sfinancial_year", e);
                            props?.setFieldValue("Tfinancial_year", "");
                          }}
                          name="Sfinancial_year"
                          format={"dd-MM-y"}
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          className={`form-control ${
                            props?.touched.Sfinancial_year &&
                            props?.errors.Sfinancial_year
                              ? "is-invalid"
                              : ""
                          }`}
                          maxDate={
                            props?.list <= 0
                              ? props?.values?.Ffinancial_year
                                ? new Date(
                                    `${props?.values?.Ffinancial_year?.getFullYear()}`?.toString()
                                  )
                                : new Date()
                              : new Date()
                          }
                         
                        />

                        <ErrorMessage
                          name="Sfinancial_year"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <Label>
                      {Vendor_Settings_ReuploadModal?.Second_Financial_year_Documents?.[language]}  
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        <Input
                          type="file"
                          id="SFfinancial_year"
                          name="SFfinancial_year"
                          className="form-control"
                          accept=".pdf"
                          onChange={(e: any) =>
                            handleFileChange(e, e.target.files[0])
                          }
                          disabled={
                            !props?.values?.Sfinancial_year
                              ? true
                              : props?.list > 0
                              ? true
                              : false
                          }
                        ></Input>
                        <Label
                          className="image-button"
                          for="SFfinancial_year"
                          id="SFfinancial_year"
                        >
                         {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]} 
                        </Label>
                      </div>
                      <ErrorMessage
                        name="SFfinancial_year"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4} md={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Second_Financial_year_Amount?.[language]}   
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <Input
                          type="number"
                          name="Sfinancial_year_amount"
                          className="form-control"
                          min="1"
                          onChange={props?.handleChange}
                          placeholder="Amount"
                          value={props?.values?.Sfinancial_year_amount}
                         
                        ></Input>

                        <ErrorMessage
                          name="Sfinancial_year_amount"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            {props?.type == 9 && (
              <>
                {" "}
                <Row>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Third_Financial_year?.[language]}  
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <br />

                        <DatePicker
                          value={props?.values?.Tfinancial_year}
                          onChange={(e: any) => {
                            props?.setFieldValue("Tfinancial_year", e);
                          }}
                          name="Tfinancial_year"
                          format={"dd-MM-y"}
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          className={`form-control ${
                            props?.touched.Tfinancial_year &&
                            props?.errors.Tfinancial_year
                              ? "is-invalid"
                              : ""
                          }`}
                          maxDate={
                            props?.list <= 0
                              ? props?.values?.Sfinancial_year
                                ? new Date(
                                    `${props?.values?.Sfinancial_year?.getFullYear()}`?.toString()
                                  )
                                : new Date()
                              : new Date()
                          }
                         
                        />

                        <ErrorMessage
                          name="Tfinancial_year"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md={4} sm={4}>
                    <FormGroup>
                      <Label>
                      {Vendor_Settings_ReuploadModal?.Third_Financial_year_Documents?.[language]}   
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        <Input
                          type="file"
                          id="Tfinancial_year"
                          name="TFfinancial_year"
                          className="form-control"
                          accept=".pdf"
                          onChange={(e: any) =>
                            handleFileChange(e, e.target.files[0])
                          }
                          disabled={
                            !props?.values?.Tfinancial_year
                              ? true
                              : props?.list > 0
                              ? true
                              : false
                          }
                        ></Input>
                        <Label
                          className="image-button"
                          for="Tfinancial_year"
                          id="Tfinancial_year"
                        >
                          {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}  
                        </Label>
                      </div>
                      <ErrorMessage
                        name="TFfinancial_year"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                         
                        {Vendor_Settings_ReuploadModal?.Third_Financial_year_Amount?.[language]}   
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <Input
                          type="number"
                          name="Tfinancial_year_amount"
                          onChange={props?.handleChange}
                          min="1"
                          className="form-control"
                          placeholder="Amount"
                        
                          value={props?.values?.Tfinancial_year_amount}
                        ></Input>

                        <ErrorMessage
                          name="Tfinancial_year_amount"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            <div>{Vendor_Settings_ReuploadModal?.Approved_by_Chartered_Accountant?.[language]}({Vendor_Settings_ReuploadModal?.CA?.[language]})</div>
            <Row>
              <Col md={4} sm={4}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.CA_Name?.[language]}{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="financial_turn_over_ca_name"
                      type="text"
                      value={props?.values?.financial_turn_over_ca_name}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="financial_turn_over_ca_name"
                    component={TextError}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={4}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.CA_Email?.[language]} {" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="financial_turn_over_ca_email"
                      type="email"
                      value={props?.values?.financial_turn_over_ca_email}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="financial_turn_over_ca_email"
                    component={TextError}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={4}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.CA_Phone_Number?.[language]} {" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="financial_turn_over_ca_phone"
                      type="tel"
                      onKeyPress={preventNegativeValues}
                      value={props?.values?.financial_turn_over_ca_phone}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="financial_turn_over_ca_phone"
                    component={TextError}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} className="text-end">
                <Button
                  className="btn btn-primary mb-5"
                  color="primary"
                  type="submit"
                >
                  {Vendor_Settings_ReuploadModal?.Submit?.[language]} 
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </>
  );
}
