import React, { useEffect, useRef, useState,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import {
  file_remove,
  preventNegativeValues,
} from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
export default function HrDocumentsSection(props: any) {
  const {language,setLanguage}=useContext(Context);
  const manRef = useRef<HTMLInputElement>(null);
  const landRef = useRef<HTMLInputElement>(null);
  const [sum, setSum] = useState();
  const [man_mark, setManMark] = useState("");
  useEffect(() => {
    let total: any =
      Number(props?.values?.nontechnical_manpower_number) +
      Number(props?.values?.technical_manpower_number);
    setSum(total);
    props?.setFieldValue("manpower_number", total);

    if (
      props?.values?.turn_cat == "B" &&
      props?.values?.manpower_number >= 25
    ) {
      setManMark("10");
      props?.setFieldValue("manpower_mark", "10");
    } else if (
      props?.values?.turn_cat == "C" &&
      props?.values?.manpower_number >= 15
    ) {
      setManMark("10");
      props?.setFieldValue("manpower_mark", "10");
    } else if (
      props?.values?.turn_cat == "A" &&
      props?.values?.manpower_number >= 50
    ) {
      setManMark("10");
      props?.setFieldValue("manpower_mark", "10");
    } else {
      setManMark("0");
      props?.setFieldValue("manpower_mark", "0");
    }
  }, [
    props?.values?.nontechnical_manpower_number,
    props?.values?.technical_manpower_number,
    props?.values?.turn_cat,
    props?.values?.manpower_number,
  ]);
  // console.log(
  //   props?.values?.turn_cat,
  //   props?.values?.manpower_mark,
  //   "manpower_marknnnn"
  // );
  // console.log(man_mark);
  return (
    <>
      {" "}
      <div className="company-information-update-wrap">
        <h4>{Vendor_profileUpdation?.HR_Documents?.[language]}</h4>
        <hr />

        <Row>
          <Col md={4} sm={6}>
            <FormGroup>
              <Label>
              {Vendor_profileUpdation?.Manpower_Documents?.[language]}{" "}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                <Input
                  type="file"
                  innerRef={manRef}
                  id="manpower_documents"
                  name="manpower_documents"
                  className="form-control"
                  accept=".pdf"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                ></Input>
                <Label
                  className="image-button"
                  for="manpower_documents"
                  id="manpower_documents"
                >
                  {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.manpower_documents && (
                  <>
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "5px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove(
                          "manpower_documents",
                          manRef,
                          "manpower_documents"
                        );
                        props?.setFieldValue("manpower_documents", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>
              <ErrorMessage name="manpower_documents" component={TextError} />
            </FormGroup>
          </Col>
          {/* <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.Date_in_document?.[language]}{" "}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                <DatePicker
                  value={props?.values?.manpower_date}
                  onChange={(e: any) => {
                    props?.setFieldValue("manpower_date", e);
                  }}
                  name="manpower_date"
                  format={"dd-MM-y"}
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  maxDate={new Date()}
                />
              </div>
              <ErrorMessage name="manpower_date" component={TextError} />
            </FormGroup>
          </Col> */}
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  No of Technical Employees(Manpower)
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                <Input
                  name="technical_manpower_number"
                  type="number"
                  value={props?.values?.technical_manpower_number}
                  onChange={props?.handleChange}
                  min={1}
                  onKeyPress={preventNegativeValues}
                />
              </div>
              <ErrorMessage
                name="technical_manpower_number"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  No of Non-Technical Employees(Manpower)
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                <Input
                  name="nontechnical_manpower_number"
                  type="number"
                  value={props?.values?.nontechnical_manpower_number}
                  onChange={props?.handleChange}
                  min={1}
                  onKeyPress={preventNegativeValues}
                />
              </div>
              <ErrorMessage
                name="nontechnical_manpower_number"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.No_of_Employees_Manpower?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                <Input
                  name="manpower_number"
                  type="number"
                  value={props?.values?.manpower_number}
                  onChange={props?.handleChange}
                  min={1}
                  disabled
                  onKeyPress={preventNegativeValues}
                />
              </div>
              <ErrorMessage name="manpower_number" component={TextError} />
            </FormGroup>
          </Col>
        </Row>

        {props?.start_up !== 1 && ( 
          <>
            {" "}
            {props?.values?.turn_cat && (
              <>
                <span className="text-success mark-end">{Vendor_profileUpdation?.Mark?.[language]} {man_mark}</span>
              </>
            )}
          </>
        )}

        <Row>
          <Col md={4} sm={6}>
            <FormGroup>
              <Label>Office Details</Label>
              <div className="image-input">
                <Input
                  type="file"
                  innerRef={landRef}
                  id="land_document"
                  name="land_document"
                  className="form-control"
                  accept=".pdf"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                ></Input>
                <Label
                  className="image-button"
                  for="land_document"
                  id="land_document"
                >
                  {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.land_document && (
                  <>
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "5px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove("land_document", landRef, "land_document");
                        props?.setFieldValue("land_document", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>
              <ErrorMessage name="land_document" component={TextError} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Total_Area?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="land_village_col"
                    type="text"
                    value={props?.values?.land_village_col}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="land_village_col"
                    type="text"
                    value={props?.values?.land_village_col}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="land_village_col" component={TextError} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Address?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="land_extent"
                    type="textarea"
                    value={props?.values?.land_extent}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="land_extent"
                    type="textarea"
                    value={props?.values?.land_extent}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="land_extent" component={TextError} />
            </FormGroup>
          </Col>
          {/* <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Value?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="land_value"
                    type="text"
                    value={props?.values?.land_value}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="land_value"
                    type="text"
                    value={props?.values?.land_value}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="land_value" component={TextError} />
            </FormGroup>
          </Col> */}
          {/* <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Survey_No?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="land_survey_no"
                    type="text"
                    value={props?.values?.land_survey_no}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="land_survey_no"
                    type="text"
                    value={props?.values?.land_survey_no}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="land_survey_no" component={TextError} />
            </FormGroup>
          </Col> */}
          <Col sm={12} >
            {
            // props?.values?.land_survey_no &&
            // props?.values?.land_value &&
            props?.values?.land_extent &&
            props?.values?.land_village_col &&
            props?.values?.land_document ? (
              <span className="text-success mark-end">{Vendor_profileUpdation?.Mark?.[language]}:5</span>
            ) : null}
          </Col>
        </Row>
      </div>
    </>
  );
}
