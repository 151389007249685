import * as Yup from "yup";


export const UploadChallanValidationSchema = Yup.object().shape({

    payment_type: Yup.string().required("Payment type is required"),
    ifsc: Yup.string().when('payment_type', {
        is: '1',
        then: Yup.string().required('IFSC code is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '2',
        then: Yup.string().required('IFSC code is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '5',
        then: Yup.string().required('IFSC code is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '6',
        then: Yup.string().required('IFSC code is required'),
        otherwise: Yup.string().notRequired(),
    }),
    // -----------------------------------------------------
    bank_name: Yup.string().when('payment_type', {
        is: '1',
        then: Yup.string().required('Bank name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '2',
        then: Yup.string().required('Bank name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '5',
        then: Yup.string().required('Bank name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '6',
        then: Yup.string().required('Bank name is required'),
        otherwise: Yup.string().notRequired(),
    }),
    // -----------------------------------------------------
    acc_no: Yup.number().typeError('Account number must be number').when('payment_type', {
        is: '1',
        then: Yup.number().typeError('Account number must be number').required('Account number is required'),
        otherwise: Yup.number().typeError('Account number must be number').notRequired(),
    }).when('payment_type', {
        is: '2',
        then: Yup.number().typeError('Account number must be number').required('Account number is required'),
        otherwise: Yup.number().typeError('Account number must be number').notRequired(),
    }).when('payment_type', {
        is: '5',
        then: Yup.number().typeError('Account number must be number').required('Account number is required'),
        otherwise: Yup.number().typeError('Account number must be number').notRequired(),
    }).when('payment_type', {
        is: '6',
        then: Yup.number().typeError('Account number must be number').required('Account number is required'),
        otherwise: Yup.number().typeError('Account number must be number').notRequired(),
    }),
    // --------------------------------------------------------
    branch_name: Yup.string().when('payment_type', {
        is: '1',
        then: Yup.string().required('Branch name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '2',
        then: Yup.string().required('Branch name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '5',
        then: Yup.string().required('Branch name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '6',
        then: Yup.string().required('Branch name is required'),
        otherwise: Yup.string().notRequired(),
    }),
    // --------------------------------------------------------------
    acc_holder_name: Yup.string().when('payment_type', {
        is: '1',
        then: Yup.string().required('Account holder name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '2',
        then: Yup.string().required('Account holder name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '5',
        then: Yup.string().required('Account holder name is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '6',
        then: Yup.string().required('Account holder name is required'),
        otherwise: Yup.string().notRequired(),
    }),
    // ---------------------------------------------------------------------
    amount: Yup.number().typeError('Amount must be a number').min(1, 'Invalid amount').required('Amount is required'),

    transaction_no: Yup.string().when('payment_type', {
        is: '1',
        then: Yup.string().required('Transaction number is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '5',
        then: Yup.string().required('Transaction number is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '6',
        then: Yup.string().required('Transaction number is required'),
        otherwise: Yup.string().notRequired(),
    }),
    // --------------------------------------------------------------------- 
    // cheque only 
    cheque_no: Yup.string().when('payment_type', {
        is: '2',
        then: Yup.string().required('Cheque number is required'),
        otherwise: Yup.string().notRequired(),
    }),
    cheque_date: Yup.date().when('payment_type', {
        is: '2',
        then: Yup.date().required('Cheque date is required'),
        otherwise: Yup.date().notRequired(),
    }),
    // demand draft only 
    receipt_no: Yup.string().when('payment_type', {
        is: '3',
        then: Yup.string().required('Receipt number is required'),
        otherwise: Yup.string().notRequired(),
    }),

    file: Yup.string().when('payment_type', {
        is: '3',
        then: Yup.string().required('File is required'),
        otherwise: Yup.string().notRequired(),
    }).when('payment_type', {
        is: '2',
        then: Yup.string().required('File is required'),
        otherwise: Yup.string().notRequired(),
    })
});