import * as Yup from "yup";
// const phoneRegExp = /^(?:\d{10}|\w+@\w+\.\w{2,3})$/;
const ForgotValidationSchema = Yup.object().shape({
  email_phone: Yup.string()
    .required("Email is required"),
  // .matches(phoneRegExp, "Email/Phone Number is not valid"),

});
const NewPasswordValidationSchema = Yup.object().shape({
  retype_password: Yup.string()
    .required("Field is required")
    .min(8, "Password must be at least 8 characters")
    .max(40, "Password must not exceed 40 characters"),
  password: Yup.string()
    .required("Field is required")
    .min(8, "Password must be at least 8 characters")
    .max(40, "Password must not exceed 40 characters"),
});

export { NewPasswordValidationSchema, ForgotValidationSchema };
