import { ErrorMessage, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Button,
  Spinner,
} from "reactstrap";
import { SignupValidationSchema } from "../../Department/AccountCreation/SignupValidationSchema";
import TextError from "../../../Components/Errors/TextError";
import { useEffect, useState, useContext } from "react";
import { api } from "../../../Library/CustomApi";
import { toast } from "react-toastify";
import { Context } from "../../../Components/Context/Context";
import { VendorSignup } from "../../../Library/LanguageConversion/Vendor_SignUp";
import {
  company_type_list,
  gst_class,
  vendor_type,
  vendor_types,
} from "../../../Library/Constants/MapConstants";
import { ValidationSchema } from "./ValidationSechema";
import DatePicker from "react-date-picker";
import moment from "moment";
import React from "react";
const initialValues = {
  contact_name: "",
  phone: "",
  email: "",
  gst_class: "",
  gst_number: "",
  pan_number: "",
  vendor_type: "",
  company_name: "",
  company_type: "",
  registration_id: "",
  comp_inc_date: "",
  checked: "",
};

export default function VendorRegister() {
  const navigate = useNavigate();
  const { language, setLanguage } = useContext(Context);
  useEffect(() => {
    if (localStorage.getItem("AUTH_TOKEN") !== null) {
      if (localStorage.getItem("ROLE_ID") == "2") {
        navigate("/dashboard");
      }
      if (localStorage.getItem("ROLE_ID") == "3") {
        navigate("/department/dashboard");
      }
      if (localStorage.getItem("ROLE_ID") == "4") {
        navigate("/basic-details-view");
      }
      if (localStorage.getItem("ROLE_ID") == "4") {
        //navigate("/basic-details-view");
        navigate("/overview-vendor");
      }
    }
  }, []);
  const preventNegativeValues = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/; // regex pattern to match only numeric input
    if (!regex.test(keyValue)) {
      e.preventDefault();
      ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  };
  const handleRegistration = (values: any, actions: any) => {
    api
      .post(
        `/register`,
        {
          email: values?.email,
          mse_status: "0",
          name: values?.company_name,
          registration_id: values?.registration_id,
          cmpny_type: values?.company_type,
          vendor_type: values?.vendor_type,
          phone: values?.phone,
          gst_number: values?.gst_number,
          pan_number: values?.pan_number,
          full_name: values?.contact_name,
          type: "vendor",
          empanelment_date: "2023-09-27",
          expiry_date: "2029-09-27",
          status: "1",
          start_date: moment(values?.comp_inc_date).format("YYYY-MM-DD"),
          end_date: "2029-09-29",
          gst_class: values?.gst_class,
        },
        false
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(
            `Registration Successfull! Check your ${values?.email} email`
          );
          navigate("/login");
          actions.handleReset();
          actions.setSubmitting(false);
        }
        actions?.setSubmitting(false);
        return response;
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.status == 422) {
          actions.setSubmitting(false);
          if (err?.response?.data?.errors?.email)
            actions?.setFieldError("email", err?.response?.data?.errors?.email);
          if (err?.response?.data?.errors?.gst_number)
            actions?.setFieldError(
              "gst_number",
              err?.response?.data?.errors?.gst_number
            );

          if (err?.response?.data?.errors?.phone)
            actions?.setFieldError("phone", err?.response?.data?.errors?.phone);
          if (err?.response?.data?.errors?.pan_number)
            actions?.setFieldError(
              "pan_number",
              err?.response?.data?.errors?.pan_number[0]
            );
          if (err?.response?.data?.errors?.registration_id)
            actions?.setFieldError(
              "registration_id",
              err?.response?.data?.errors?.registration_id
            );
          if (err?.response?.status == 500) {
            toast.error(err?.response?.data?.message, {
              toastId: "509",
            });
            actions.setSubmitting(false);
          }
        }
      });
  };


  return (
    <>
      <div className="page-wrapper">
        <div className="login-outer">
          <div className="row">
            {/* left side */}
            <div className="col-lg-5 col-md-6 col-sm-12 ps-0 login-desktop-column">
              {/* login image wrap */}
              <div className="login-img-wrap flex-column">
                <img
                  src="../images/logo-white1.png"
                  alt="login-background"
                  className="px-5"
                />
                <p className="text-white text-center px-5 w-75">
                  KEONICS focuses on setting up world-class infrastructure for
                  the electronics and IT related industries in the state
                </p>
              </div>
            </div>
            {/* Right side */}
            <div className="col-lg-7 col-md-6 col-sm-12 m-auto">
              {/* login wrap */}

              <div className="col-sm-12  ps-0  d-md-none d-lg-none d-xl-none d-xxl-none">
                <div className="logo-mobile mb-4">
                  <img
                    src="../images/logo-white1.png"
                    alt="login-background"
                    className="px-2"
                  />
                </div>
              </div>
              <div className="login-wrap">
                <h1> {VendorSignup?.Create_Account[language]} </h1>
                <div className="login-content">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validateOnMount={true}
                    validationSchema={ValidationSchema}
                    onSubmit={(values, actions) => {
                      handleRegistration(values, actions);
                    }}
                  >
                    {({
                      handleSubmit,
                      values,
                      errors,
                      handleChange,
                      setFieldTouched,
                      setFieldError,
                      isSubmitting,
                      setFieldValue,
                      touched,
                    }) => {
                      return (
                        <Form onSubmit={handleSubmit}>
                          <Row className="wrap-scroller-600">
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>  {VendorSignup?.Company_name[language]}</Label>
                                  <Input
                                    type="text"
                                    name="company_name"
                                    onChange={handleChange}
                                    value={values?.company_name}
                                  ></Input>
                                  <ErrorMessage
                                    name="company_name"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label> {VendorSignup?.phone_number[language]} </Label>
                                  <Input
                                    type="tel"
                                    name="phone"
                                    onKeyPress={preventNegativeValues}
                                    onChange={(e) => {
                                      setFieldTouched("phone");
                                      handleChange(e);
                                    }}
                                    value={values.phone}
                                  ></Input>
                                  <ErrorMessage
                                    name="phone"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>    {VendorSignup?.Select_Vendor_Types[language]}</Label>
                                  <Input
                                    type="select"
                                    onChange={handleChange}
                                    name="vendor_type"
                                  >
                                    <option value="">
                                    {VendorSignup?.Select_Vendor_Types[language]}
                                    </option>
                                    {/* mapped department list from map Constants */}
                                    {vendor_type?.map(
                                      (itm: any, key: number) => (
                                        <option value={itm?.value} key={key}>
                                          {itm?.name}
                                        </option>
                                      )
                                    )}
                                  </Input>
                                  <ErrorMessage
                                    name="vendor_type"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label> {VendorSignup?.Select_GST_Class[language]} </Label>
                                  <Input
                                    type="select"
                                    onChange={handleChange}
                                    name="gst_class"
                                  >
                                    <option value="">{VendorSignup?.Select_GST_Class[language]}</option>
                                    {/* mapped department list from map Constants */}
                                    {gst_class?.map((itm: any, key: number) => (
                                      <option value={itm?.value} key={key}>
                                        {itm?.name}
                                      </option>
                                    ))}
                                  </Input>
                                  <ErrorMessage
                                    name="gst_class"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{VendorSignup?.GST_Number[language]} </Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      name="gst_number"
                                      onChange={handleChange}
                                      value={values?.gst_number}
                                    />
                                  </InputGroup>
                                  <ErrorMessage
                                    name="gst_number"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{VendorSignup?.PAN_Number[language]}</Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      name="pan_number"
                                      onChange={handleChange}
                                      value={values?.pan_number}
                                    />
                                  </InputGroup>
                                  <ErrorMessage
                                    name="pan_number"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{VendorSignup?.Company_Email[language]} </Label>
                                  <InputGroup>
                                    <Input
                                      type="email"
                                      name="email"
                                      onChange={handleChange}
                                      value={values?.email}
                                    />
                                  </InputGroup>
                                  <ErrorMessage
                                    name="email"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label> {VendorSignup?.Contact_Name[language]}</Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      name="contact_name"
                                      onChange={handleChange}
                                      value={values?.contact_name}
                                    />
                                  </InputGroup>
                                  <ErrorMessage
                                    name="contact_name"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{VendorSignup?.Company_Registration_Number[language]}   </Label>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      name="registration_id"
                                      onChange={handleChange}
                                      value={values?.registration_id}
                                    />
                                  </InputGroup>
                                  <ErrorMessage
                                    name="registration_id"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup className="form-group date">
                                <Label>{VendorSignup?.Company_incorporation_date[language]}  </Label>
                                <DatePicker
                                  onChange={(e: any) => {
                                    setFieldValue("comp_inc_date", e);
                                  }}
                                  format={"dd-MM-y"}
                                  dayPlaceholder={"dd"}
                                  monthPlaceholder={"mm"}
                                  yearPlaceholder={"yyyy"}
                                  value={values?.comp_inc_date}
                                  name="comp_inc_date"
                                  calendarClassName={`form-control ${
                                    touched.comp_inc_date &&
                                    errors.comp_inc_date
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="comp_inc_date"
                                  component={TextError}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>  {VendorSignup?.Company_Type[language]}</Label>
                                  <Input
                                    type="select"
                                    onChange={handleChange}
                                    name="company_type"
                                  >
                                    <option value="">
                                      Select Company Type
                                    </option>
                                    {/* mapped department list from map Constants */}
                                    {company_type_list?.map(
                                      (itm: any, key: number) => (
                                        <option value={itm?.value} key={key}>
                                          {itm?.name}
                                        </option>
                                      )
                                    )}
                                  </Input>
                                  <ErrorMessage
                                    name="company_type"
                                    component={TextError}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <div className="agree-checkbox-wrap my-3">
                                <input
                                  type="checkbox"
                                  name="checked"
                                  id="termsCheckbox"
                                  className="form-check-input"
                                  onChange={(e: any) => {
                                    setFieldTouched("checked", true, true);
                                    e?.target?.checked
                                      ? setFieldValue("checked", "checked")
                                      : setFieldValue("checked", "");
                                  }}
                                />
                                <ErrorMessage
                                  name="checked"
                                  component={TextError}
                                />
                                <label
                                  htmlFor="termsCheckbox"
                                  className="form-check-label h6"
                                >
                                  Please confirm that the information provided
                                  is accurate before submitting the form
                                </label>
                              </div>
                            </Col>
                            {/* Add the label */}

                            <Col sm={12} className="mt-3">
                              <h6>
                                Already have an account?{" "}
                                <Link
                                  to="/login"
                                  className="blue-text"
                                >
                                  {VendorSignup?.login[language]}
                                </Link>
                              </h6>
                            </Col>

                            <Col sm={12}>
                              <Button
                                className="btn btn-primary mobile-button"
                                disabled={isSubmitting}
                                color="primary"
                                type="submit"
                              >
                                {isSubmitting ? (
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                ) : (
                                  "Register"
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className="language-btn-group left-align mt-3">
                    <Link to="" onClick={() => setLanguage("en")}>
                      English
                    </Link>
                    <div className="divider"></div>
                    <Link to="" onClick={() => setLanguage("ka")}>
                      ಕನ್ನಡ
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
