import React, { useContext } from 'react'
import AboutUsEng from './English/AboutUs';
import AboutKanada from './Kanada/About';
import { Context } from '../../Components/Context/Context';

export default function Abouts() {
    const { language, setLanguage } = useContext(Context);

    return (
        language == "en" ? <AboutUsEng/> : <AboutKanada/>
    )
}
