import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Col
} from "reactstrap";
import CurrentTab from "../../CurrentTab";
import { clearData } from "../../../../Library/KeyStorage";
import { Context } from "../../../../Components/Context/Context";

interface proptype {
    setOpenSidebar: Function,
    openSidebar: boolean
}

function AccountantHeader({ setOpenSidebar, openSidebar }: proptype) {

    let newDate = new Date();
    let date = moment(newDate).format("DD MMMM YYYY");
    let time = moment(newDate).format("hh:mm A");
    let user_name = localStorage.getItem("USER_NAME");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const Log_out = () => {
        Swal.fire({
            title: "Are you sure you want to logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
            }
        });
    };

    const logout = async () => {
        await clearData();
        navigate("/login");
    };

    return (
        <>
            {/*top-header  header */}
            <header className="top-bar">
                <Row className="m-0">
                    <div className="top-bar-full-wrap">
                        <Col md={12}>
                            <div className="header-outer-wrap">
                                <div className="page-name-date-wrap">
                                    {/* mobile sidebar toggle-btn */}
                                    <Button
                                        color="link"
                                        className="menu-mobile text-decoration-none d-block d-md-none d-lg-none d-xl-none d-xxl-none p-0"
                                        onClick={() => setOpenSidebar(!openSidebar)}
                                    >
                                        <i className="fa fa-bars"></i>
                                    </Button>

                                    {/* mobile sidebar toggle-btn ends */}
                                    <div className="page-heading">
                                        {/* mobile-logo */}
                                        <div className="mob-logo-wrap">
                                            <img src="../../images/mob-logo.png" alt="logo" />
                                        </div>
                                        {/* shows current tab name  */}
                                        <CurrentTab pathname={pathname} />
                                    </div>
                                    {/* desktop header date  */}
                                    <div className="date-and-time">
                                        <small className="mb-0">
                                            <span className="date">{date}</span>
                                            &nbsp;&nbsp;|&nbsp;&nbsp;
                                            <span className="time">{time} GMT</span>
                                        </small>
                                    </div>
                                    {/* desktop header date ends*/}
                                </div>
                                <div className="search-top-bar-outer">
                                    
                                    {/* <div className="notification-bell"> */}
                                    {/* notification collapse section */}
                                    {/* <Link
                      to="/notifications"
                      className="notification-bell text-decoration-none"
                    >
                      <i className="bi bi-bell"></i>
                    </Link> */}
                                    {/* </div>    */}
                                    <Dropdown
                                        isOpen={dropdownOpen}
                                        toggle={toggle}
                                        // direction={direction}
                                        className="account-drop"
                                    >
                                        <DropdownToggle
                                            className=""
                                            color="link"
                                        >
                                            <div className="account-info">
                                                <div className="avatar-img-wrap">
                                                    <img
                                                        src="/images/avatar.svg"
                                                        alt="img"
                                                    />
                                                </div>
                                                <small>{user_name}</small>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className="top-50">
                                            <DropdownItem
                                                title="Log Out"
                                                to="#"
                                                onClick={() => {
                                                    Log_out();
                                                }}
                                            >
                                                <i className="bi bi-box-arrow-in-left me-3"></i>
                                                Log Out
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>

                    </div>
                </Row>
            </header>
        </>
    );
}

export default AccountantHeader;