import React from "react";
import { useContext } from "react";
import { Context } from "../../../Components/Context/Context";
import { Vendor_Services } from "../../../Library/LanguageConversion/Vendor_Services";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";

export default function SeviceLayoutVendor(props: any) {
  const { pathname } = useLocation();
  const { language, setLanguage } = useContext(Context);
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/vendor-open-tickets"> {Vendor_Services?.Open_Tickets[language]}</NavLink>
            <NavLink to="/vendor-pending-tickets">  {Vendor_Services?.Pending_Tickets[language]}</NavLink>
            <NavLink to="/vendor-closed-tickets"> {Vendor_Services?.Closed_Tickets[language]}</NavLink>

          </div>
          {/* breadcrumb */}
          <List className=" breadcrumb">
            <li>
              <Link className="active" to="/overview-vendor">
              {Vendor_Services?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {pathname == "/vendor-open-tickets"
                  ? "Open Tickets"
                  : pathname == "/vendor-closed-tickets"
                    ? "Closed Tickets"
                    : pathname == "/vendor-pending-tickets"
                      ? "Pending Tickets"
                      : null}
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
