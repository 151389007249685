import React, { useState, useEffect,useContext } from "react";
import {
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import ProductSpecModal from "../ProductSpecModal/Index";
import Loader from "../../../../Components/Loader/Loader";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import { Link } from "react-router-dom";
import Feedback from "../Feedback/Feedback";
import ServiceRequestModal from "../ServiceRequestModal/ServiceRequestModal";
import OrderViewModal from "../OrderViewModal/OrderViewModal";
import { convertISODateToDateTime } from "../../../../Library/Constants/helpers";
import { Department_Orders } from "../../../../Library/LanguageConversion/Department_Orders";
import { Context } from "../../../../Components/Context/Context";
export default function DeptDeliveredOrders(args: any) {
  const [modalData, setModalData] = useState<any>([])
  const [alldata, setAllData] = useState<any>([])
  const [loading, setLoading] = useState(true);
  const [orderViewLoading, setOrderViewLoading] = useState(true);
  const [deliveredOrders, setDeliveredOrders] = useState<any>([]);
  const [pageData, setPageData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number | string>(1)
  const [searchVal, setSearchVal] = useState<string>("")
  const [selectedVendorId, setSelectedVendorId] = useState<number>(0)
  const [singleOrderData, setSingleOrderData] = useState<any>([])
  const [open1, setOpen1] = useState<boolean>(false) // for prod spec modal
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false) // for feedback modal
  const [serviceModalOpen, setServiceModalOpen] = useState<boolean>(false) // for feedback modal
  const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false) // for feedback modal
  const {language,setLanguage}=useContext(Context);

  useEffect(() => {
    getDeliveredOrder()
  }, [currentPage, searchVal]);

  const getDeliveredOrder = () => {
    setLoading(true);
    api
      .get(`/order/get-confirmed-orders?status=6&page=${currentPage}&search_value=${searchVal}`, true)
      .then(async function ([success, response]) {
        setDeliveredOrders(response.data || []);
        setPageData(response?.meta)
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // handles feedback modal 
  const feedbackHandler = (obj: any) => {
    setAllData(obj)
    setSelectedVendorId(obj?.vendor_id)
    setFeedbackModalOpen(!feedbackModalOpen)
  }

  // service request modal handler 
  const serviceRequestHandler = (obj: any) => {
    setAllData(obj)
    setSelectedVendorId(obj?.vendor_id)
    setServiceModalOpen(true)
  }

  // handle order view 
  const handleOrderView = (obj: any) => {
    getSingleOrder(obj?.id)
    setOrderModalOpen(true)
  }

  // get details for order details view modal 
  const getSingleOrder = (order_id: number) => {
    setOrderViewLoading(true)
    api
      .get(`/order/get-order-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setSingleOrderData(response?.message)
        setOrderViewLoading(false)
        return response;
      })
      .catch((err) => {
        setOrderViewLoading(false)
        console.log(err);
      });
  };

  return (
    <>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText title="Order ID/Product Name" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder="Order ID/Product Name"
              aria-label="Username"
              aria-describedby="search"
              onChange={(e: any) => {
                setCurrentPage(1)
                setSearchVal(e.target.value)
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th> {Department_Orders?.SlNo[language]}</th>
              <th> {Department_Orders?.OrderID[language]}</th>
              <th> {Department_Orders?.Product[language]}</th>
              <th> {Department_Orders?.ShippedAddress[language]}</th>
              <th>{Department_Orders?.OrderDate[language]}</th>
              <th>{Department_Orders?.Quantity[language]}</th>
              <th>{Department_Orders?.Delivered_Date[language]}</th>
              <th>{Department_Orders?.Actions[language]}</th>
            </tr>
          </thead>
          <tbody>
            {!loading ?
              <>
                {deliveredOrders.length !== 0 ?
                  deliveredOrders?.map((obj: any, i: number) => {
                    return (
                      <React.Fragment key={i} >
                        <tr>
                          <td><span className="mobile-heading">{Department_Orders?.SlNo[language]}:</span><span className="table-data">{pageData?.from + i}</span></td>
                          <td title="View Order"><span className="mobile-heading">{Department_Orders?.OrderID[language]}:</span><span className="table-data"><Link to="" className="blue-text" onClick={() => handleOrderView(obj)}>{obj?.vendor_po_no}</Link></span></td>
                          <td className="product-details" title="View Product Details"><span className="mobile-heading"> {Department_Orders?.Product[language]}:</span>
                            <span className="table-data">
                              <p onClick={() => {
                                setModalData({ ...modalData, prod_id: obj?.order_details?.product_id })
                                setAllData(obj)
                                setOpen1(true)
                              }}>
                              <strong>{obj?.order_details?.name}</strong>  
                              </p>
                            </span>
                          </td>
                          <td>
                            <span className="mobile-heading">{Department_Orders?.ShippedAddress[language]}:</span>
                            <span className="table-data">
                            {Department_Orders?.Building_No[language]}:{obj?.pf_invoice?.pf_address[0]?.building_no} <br />
                              {obj?.pf_invoice?.pf_address[0]?.city}<br />
                              {obj?.pf_invoice?.pf_address[0]?.district}, &nbsp;
                              {obj?.pf_invoice?.pf_address[0]?.state}<br />
                              {Department_Orders?.Pincode[language]}:{obj?.pf_invoice?.pf_address[0]?.pincode}<br />
                            </span>
                          </td>
                          <td><span className="mobile-heading"> {Department_Orders?.Order_Date[language]}:</span><span className="table-data">{convertISODateToDateTime(obj?.created_at)}</span></td>
                          <td><span className="mobile-heading">{Department_Orders?.Quantity[language]}:</span><span className="table-data">
                            <span className="table-data">
                              {obj?.order_details?.quantity}
                            </span>
                          </span></td>
                          <td><span className="mobile-heading">{Department_Orders?.Delivered_Date[language]}:</span><span className="table-data">{obj?.delivery_date}</span></td>
                          <td><span className="mobile-heading">{Department_Orders?.Actions[language]}:</span>
                            <div className="action-flex-btn">

                              {/* add feedback  */}
                              <Link to='' className="text-success" onClick={() => feedbackHandler(obj)} title="Add Feedback" ><i className="bi bi-chat"></i></Link>

                              {/* add service request  */}
                              <Link to='' className="text-primary" onClick={() => serviceRequestHandler(obj)} title="Add Service/Maintenance Request" ><i className="bi bi-gear"></i></Link>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                  :
                  <tr>
                    <td colSpan={8} className="text-center p-5 empty-text">
                    {Department_Orders?.No_Orders_Found[language]}
                    </td>
                  </tr>
                }
              </>
              :
              <tr>
                <td colSpan={8} className="text-center p-5 empty-text">
                <div className="d-flex justify-content-center">
                  <Loader />
                  </div>
                </td>
              </tr>}
          </tbody>
        </Table>
        {pageData && deliveredOrders.length > 0 && < div className="table-footer-wrap">
          <small>{Department_Orders?.Showing[language]}{pageData.from}{Department_Orders?.to[language]}{pageData.to} {Department_Orders?.of[language]} {pageData?.total}{Department_Orders?.entries[language]}</small>
          <div className="pagination-outer">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData?.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>}
      </div>

      {/* view product modal */}
      <div>
        <ModalContainer open={open1} setOpen={setOpen1} header={alldata?.order_details?.name || ''}>
          <ProductSpecModal allData={alldata} viewFeedback={true}/>
        </ModalContainer>
      </div>
      {/* order view modal  */}
      <div>
        <ModalContainer open={orderModalOpen} setOpen={setOrderModalOpen} header='Order Details'>
          <OrderViewModal singleOrderData={singleOrderData} orderViewLoading={orderViewLoading } />
        </ModalContainer>
      </div>
      {/* feedback modal  */}
      <div>
        <ModalContainer open={feedbackModalOpen} setOpen={setFeedbackModalOpen} header='Feedback'>
          <Feedback allData={alldata} vendorId={selectedVendorId} setFeedbackModalOpen={setFeedbackModalOpen} />
        </ModalContainer>
      </div>
      {/* Service/maintenance request modal  */}
      <div>
        <ModalContainer open={serviceModalOpen} setOpen={setServiceModalOpen} header='Service/Maintenance Request'>
          <ServiceRequestModal allData={alldata} setOpen={setServiceModalOpen} vendorId={selectedVendorId} />
        </ModalContainer>
      </div>

    </>
  );
}