import moment from "moment";
import React, { useEffect, useState, useRef,useContext } from "react";
import { Context } from "../../../Components/Context/Context";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Collapse,
  Table,
} from "reactstrap";
import { api } from "../../../Library/CustomApi";
import Toast from "reactstrap";
import { toast } from "react-toastify";
import { order_status } from "../../../Library/Constants/MapConstants";
import TextError from "../../../Components/Errors/TextError";
import { ErrorMessage } from "formik";
import { API_URL } from "../../../Library/ApiSettings";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { Formik } from "formik";
import EXIF from "exif-js";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { EmdCalculation } from "./EmdCalculation";
import { Value } from "sass";
import axios from "axios";

const OrderViews = (props: any) => {
  const { language, setLanguage } = useContext(Context);
  const [invoice, setInvoice] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState<any>(false);
  const order_data = props?.order_view?.[0];
  const location = useLocation();
  const ref = useRef<any>();
  const [mou_image, setImage] = useState();
  const [show_data, setShowData] = useState(false);
  const [pay_buuton, setPayButton] = useState(
    order_data?.vendor_to_pmu_payment?.status == 0 ? true : false
  );
  const [file_name, setFileName] = useState("");
  const [images_, setImages] = useState([]);
  const toggle = () => setIsOpen(!isOpen);

  // var amo = (((order_data?.order_details?.cgst * order_data?.order_details?.quantity) + (order_data?.order_details?.sgst * order_data?.order_details?.quantity)) + (order_data?.order_details?.amount * order_data?.order_details?.quantity) - (((order_data?.order_details?.cgst * order_data?.order_details?.quantity) + (order_data?.order_details?.sgst * order_data?.order_details?.quantity)) + (order_data?.order_details?.amount * order_data?.order_details?.quantity) * 5 / 100));
  var amo =
    order_data?.order_details?.cgst * order_data?.order_details?.quantity +
    order_data?.order_details?.sgst * order_data?.order_details?.quantity +
    order_data?.order_details?.amount * order_data?.order_details?.quantity;
  const navigate = useNavigate();
  const MouChange = (e: any) => {
    setImage(e);
  };
  const downloadmou = () => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(
      `${API_URL}/order/invoice-download?orderId=${props?.order_view[0]?.vendor_po_no}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Memorandum Of Understanding.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setShowData(true);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  const uploadmou = () => {
    if (!mou_image) {
      toast.error("MOU Certificate feild");
    } else {
      const formData = new FormData();
      formData.append("mou", mou_image);
      const body = formData;
      axios({
        method: "post",
        url: `${API_URL}/documents`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        },
      })
        .then(function (response) {
          if (response?.status == 200) {
            toast.success("MOU Certificate are uploaded successfuly");
            setPayButton(true);
            setShowData(false);
          }
        })
        .catch(function (response) {
          toast.error("Something Went Wrong, Please Check Datas");
          console.log(response);
        });
    }
  };

  const images = order_data?.order_details?.product_details?.product_image?.map(
    (item: any) => {
      return {
        original: item?.path,
        thumbnail: item?.path,
      };
    }
  );

  useEffect(() => {}, []);

  const generateinvoice = () => {
    api
      .post(
        `/order/invoice`,
        { vendorOrderId: props?.order_view[0]?.vendor_po_no },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Work Order Generated Successfully");
        // props?.toggle()
        setInvoice(true);

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const downloadinvoice = () => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      // body: JSON.stringify({
      //     orderId: props?.order_view[0]?.vendor_po_no
      //})
    };
    fetch(
      `${API_URL}/order/invoice-download?orderId=${props?.order_view[0]?.vendor_po_no}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Work Order.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        props?.setIsShowing(false);
        setDisabled(true);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  function truncateText(text: any) {
    return text?.replace(/<br>/g, ", ");
  }

  function removeTags(str: any) {
    if (str === null || str === "") return false;
    else str = str?.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str?.replace(/(<([^>]+)>)/gi, "   ");
  }

  const handleFilechange = (e: any, values: any, field: string) => {
    const myMemoObj = URL.createObjectURL(e.target.files[0]);

    if (field == "challan") {
      props?.setFieldValue("challan", e.target.files[0]);
      setFileName(e.target.files[0]?.name);
      props?.setFieldTouched("challan", false, false);
    } else if (field == "wou") {
      props?.setFieldValue("wou", e.target.files[0]);
      setFileName(e.target.files[0]?.name);
      props?.setFieldTouched("wou", false, false);
    } else if (field == "invoice") {
      props?.setFieldValue("invoice", e.target.files[0]);
      setFileName(e.target.files[0]?.name);
      props?.setFieldTouched("invoice", false, false);
    }

    // else if (field == "images") {
    //   setImages({
    //     ...images_,
    //     images: e.target.files
    //   })

    //   let all = [images_, e.target.files[0]]
    // }
    else if (field == "images") {
      if (e.target.files.length <= 1) {
        var datas = EXIF.getData(e.target.files[0], function () {
          EXIF.getAllTags(e.target.files[0]);
          var latDegree = e?.target?.files[0]?.exifdata?.GPSLatitude;
          var lonDegree = e?.target?.files[0]?.exifdata?.GPSLongitude;

          if (latDegree == "" || latDegree == null || latDegree == undefined) {
            toast.error("Please Upload Image With Location Details");
            // props?.setLocation([{images:"",latitude:"",longitude:""}])
          }

          var latitudes = latDegree[0]?.numerator
            ? [
                latDegree[0]?.numerator,
                latDegree[1]?.numerator,
                latDegree[2]?.numerator / latDegree[2]?.denominator,
              ]
            : [latDegree[0], latDegree[1], latDegree[2]];
          var longitudes = lonDegree[0]?.numerator
            ? [
                lonDegree[0]?.numerator,
                lonDegree[1]?.numerator,
                lonDegree[2]?.numerator / lonDegree[2]?.denominator,
              ]
            : [lonDegree[0], lonDegree[1], lonDegree[2]];
          const latitude =
            latitudes[0] + latitudes[1] / 60 + latitudes[2] / 3600;
          const longitude =
            longitudes[0] + longitudes[1] / 60 + longitudes[2] / 3600;

          // var latitude = latDegree ? latDegree[0]?.numerator ? `${latDegree[0]?.numerator}.${latDegree[2]?.numerator}` : `${Number(latDegree[0].toString().replace(".", ""))}.${Number(latDegree[2].toString().replace(".", ""))}` : ""
          // var longitude = lonDegree ? lonDegree[0]?.numerator ? `${lonDegree[0]?.numerator}.${lonDegree[2]?.numerator}` : `${Number(lonDegree[0].toString().replace(".", ""))}.${Number(lonDegree[2].toString().replace(".", ""))}` : ""

          if (latDegree == "" || latDegree == null || latDegree == undefined) {
            toast.error("Please Upload Image With Location Details");
            props?.setLatitudess("");
            props?.setLongitudess("");
            // props?.setLocation([{images:"",latitude:"",longitude:""}])
          } else {
            // props?.setLatitudess(...props?.latitudes, latitude)
            // props?.setLongitudess(...props?.longitudes, longitude)
            props?.setLocation([
              ...props?.location,
              {
                images: e?.target?.files[0],
                latitude: latitude,
                longitude: longitude,
              },
            ]);
            props?.setFieldTouched("location", false, false);
            var imgval = document.getElementById("img") as HTMLInputElement;
            imgval.value = "";
          }
        });
      } else {
        const selectedFiles = e.target.files;
        var filesArray: any = Array.from(selectedFiles);
        setImages(filesArray);
        filesArray?.forEach((item: any, index: any) => {
          EXIF.getData(item, async function () {
            EXIF.getAllTags(item);
            var latDegree = item?.exifdata?.GPSLatitude;
            var lonDegree = item?.exifdata?.GPSLongitude;

            if (!latDegree) {
              toast.error("Please Upload Image With Location Details");
            } else {
              var latitudes = latDegree[0]?.numerator
                ? [
                    latDegree[0]?.numerator,
                    latDegree[1]?.numerator,
                    latDegree[2]?.numerator / latDegree[2]?.denominator,
                  ]
                : [latDegree[0], latDegree[1], latDegree[2]];
              var longitudes = lonDegree[0]?.numerator
                ? [
                    lonDegree[0]?.numerator,
                    lonDegree[1]?.numerator,
                    lonDegree[2]?.numerator / lonDegree[2]?.denominator,
                  ]
                : [lonDegree[0], lonDegree[1], lonDegree[2]];
              item.latitude =
                latitudes[0] + latitudes[1] / 60 + latitudes[2] / 3600;
              item.longitude =
                longitudes[0] + longitudes[1] / 60 + longitudes[2] / 3600;

              // item.latitude = latDegree ? latDegree[0]?.numerator ? `${latDegree[0]?.numerator}.${latDegree[2]?.numerator}` : `${Number(latDegree[0].toString().replace(".", ""))}.${Number(latDegree[2].toString().replace(".", ""))}` : "";
              // item.longitude = lonDegree ? lonDegree[0]?.numerator ? `${lonDegree[0]?.numerator}.${lonDegree[2]?.numerator}` : `${Number(lonDegree[0].toString().replace(".", ""))}.${Number(lonDegree[2].toString().replace(".", ""))}` : "";

              // if (!latDegree) {
              //   toast.error("Please Upload Image With Location Details");
              //   props?.setLatitudess("");
              //   props?.setLongitudess("");
              //   return;
              // }
              //  filesArray = filesArray.filter((item: any) => item?.latitude);

              props?.setLocation([
                ...props?.location,
                ...filesArray.filter((item: any) => item?.latitude),
              ]);
              props?.setFieldTouched("location", false, false);
              var imgval = document.getElementById("img") as HTMLInputElement;
              imgval.value = "";
              return;
            }
          });
        });
      }
    }

    props?.setFieldValue("order_id", props?.orderIds);
  };
  const handleDeletes = (img: any, indexs: number) => {
    props?.setFieldValue("file", props?.values.file);
  };

  const removeImg = async (index: any) => {
    props?.location?.splice(index, 1);
    await props?.setLocation([...props?.location]);
    // await props?.setLocation([...props?.location.filter((val:any, inde:Number) => index !== inde)])
    // ref.current.value=""
  };

  return (
    <React.Fragment>
      {/* <ModalContainer open={} setOpen={} header="">

      </ModalContainer> */}
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Order View"
      >
        <div className="feeback-outer-wrap">
          <div className="feedback-main-heading">
            {/* Progress Tracker */}
            <div className="progress-tracker">
              <div className="progress-tracker-wrap">
                <Row className="align-items-center">
                  <h5 className="sub-heading"> {VendorRegLang?.OrderTrackingStatus?.[language]}</h5>
                  <Col md={12}>
                    <div className="tracker-status-details-wrap">
                      <ul className="main-list">
                        {location?.pathname == "/received-order-vendor" ? (
                          <>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle Received"></div>
                                  <p className="track-status-text">{VendorRegLang?.received?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">
                                    {props?.order_view?.[0]?.order_details?.created_at
                                      .toString()
                                      .slice(0, 10)}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="emptycircle Processed"></div>
                                  <p className="track-status-text">{VendorRegLang?.Processed?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="emptycircle OutForDelivery"></div>
                                  <p className="track-status-text">
                                  {VendorRegLang?.OutforDelivery?.[language]}
                                  </p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="emptycircle Delivered"></div>
                                  <p className="track-status-text">{VendorRegLang?.Delivered?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                                </div>
                              </div>
                            </li>
                          </>
                        ) : location?.pathname == "/processed-order-vendor" ? (
                          <>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle Recieved"></div>
                                  <p className="track-status-text">{VendorRegLang?.Received?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle Processed"></div>
                                  <p className="track-status-text">{VendorRegLang?.Processed?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">
                                    {props?.order_view?.[0]?.updated_at
                                      .toString()
                                      .slice(0, 10)}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="emptycircle OutForDelivery"></div>
                                  <p className="track-status-text">
                                  {VendorRegLang?.OutforDelivery?.[language]}
                                  </p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="emptycircle Delivered"></div>
                                  <p className="track-status-text">{VendorRegLang?.Delivered?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                                </div>
                              </div>
                            </li>
                          </>
                        ) : location?.pathname == "/outofdelivery" ? (
                          <>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle Received"></div>
                                  <p className="track-status-text">{VendorRegLang?.Received?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle Processed"></div>
                                  <p className="track-status-text">{VendorRegLang?.Processed?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle OutForDelivery"></div>
                                  <p className="track-status-text">
                                  {VendorRegLang?.OutforDelivery?.[language]}
                                  </p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">
                                    {props?.order_view?.[0]?.updated_at
                                      .toString()
                                      .slice(0, 10)}
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="emptycircle Delivered"></div>
                                  <p className="track-status-text">{VendorRegLang?.Delivered?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                                </div>
                              </div>
                            </li>
                          </>
                        ) : location?.pathname == "/delivered-vendor" ? (
                          <>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle Received"></div>
                                  <p className="track-status-text">{VendorRegLang?.Received?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle Processed"></div>
                                  <p className="track-status-text">{VendorRegLang?.Processed?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle OutForDelivery"></div>
                                  <p className="track-status-text">
                                  {VendorRegLang?.OutforDelivery?.[language]}
                                  </p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="flex-outer">
                                <div className="flex-wrap">
                                  <div className="circle Delivered"></div>
                                  <p className="track-status-text">{VendorRegLang?.Delivered?.[language]}</p>
                                </div>
                                <div className="date">
                                  <p className="track-status-text">
                                    {props?.order_view?.[0]?.updated_at
                                      .toString()
                                      .slice(0, 10)}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </>
                        ) : (
                          " "
                        )}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <h2> {VendorRegLang?.OrderDetails?.[language]}</h2>
          </div>
          <div className="feedback-wrap">
            <div className="feedback-details">
              <Form className="form-wrap">
                {props?.order_view?.[0] !== 0 ? (
                  <>
                    <React.Fragment>
                      <Table className="table-hover table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <b> {VendorRegLang?.OrderID?.[language]}</b>{" "}
                            </td>
                            <td> {order_data?.vendor_po_no} </td>
                          </tr>

                          <tr>
                            <td>
                              {" "}
                              <b> {VendorRegLang?.ProductName?.[language]}</b>{" "}
                            </td>
                            <td>
                              {order_data?.order_details?.product_details?.name}
                            </td>
                          </tr>
                          <tr onClick={() => toggle()} className="collapse-btn">
                            <td>
                              <b> {VendorRegLang?.product_code?.[language]}</b>{" "}
                            </td>
                            <td>
                              <span className="code">
                                {
                                  order_data?.order_details?.product_details
                                    ?.product_code
                                }{" "}
                              </span>{" "}
                            </td>
                          </tr>
                          <Collapse isOpen={isOpen} tag={"tr"}>
                            <td colSpan={2}>
                              <Table className=" table-bordered ">
                                <tbody>
                                  <tr>
                                    <td>
                                      {" "}
                                      <b> {VendorRegLang?.HSNCode?.[language]}</b>{" "}
                                    </td>
                                    <td>
                                      {
                                        order_data?.order_details
                                          ?.product_details?.hsn_code
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <b> {VendorRegLang?.quantity?.[language]}</b>
                                    </td>
                                    <td>
                                      {" "}
                                      {order_data?.order_details?.quantity}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b> {VendorRegLang?.UnitPrice?.[language]}</b>
                                    </td>
                                    <td>
                                      ₹ {order_data?.order_details?.unit_price}
                                    </td>
                                  </tr>
                                  {/* this row shows in desktop (Specs)*/}
                                  <tr className="large-screen-view">
                                    <td>
                                      <b>{VendorRegLang?.ProductSpecifications?.[language]}</b>
                                    </td>
                                    <td>
                                      <p className="specs">
                                        <small>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: truncateText(
                                                order_data?.order_details
                                                  ?.product_details?.features
                                              ),
                                            }}
                                          />
                                        </small>
                                        {/* <small><span><b>Processor:</b></span>i5</small> */}
                                      </p>
                                    </td>
                                  </tr>
                                  {/* this row shows in mobile (Specs)*/}
                                  <tr className="mobile-view">
                                    <td colSpan={2} className="text-center">
                                      <b>{VendorRegLang?.ProductSpecifications?.[language]}</b>
                                    </td>
                                  </tr>
                                  <tr className="mobile-view">
                                    <td colSpan={2}>
                                      <p className="specs centered">
                                        <small>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: truncateText(
                                                order_data?.order_details
                                                  ?.product_details?.features
                                              ),
                                            }}
                                          />
                                        </small>
                                        {/* <small><span><b>Processor:</b></span>i5</small> */}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                          </Collapse>
                          {order_data?.address?.[0]?.state == "Karnataka" ? (
                            <>
                              <tr>
                                <td>
                                  <b>{VendorRegLang?.SGST?.[language]}</b>{" "}
                                </td>
                                <td>₹ {order_data?.order_details?.sgst}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{VendorRegLang?.CGST?.[language]}</b>{" "}
                                </td>
                                <td>₹ {order_data?.order_details?.cgst}</td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                <td>
                                  <b>{VendorRegLang?.IGST?.[language]}</b>{" "}
                                </td>
                                <td>₹ {order_data?.order_details?.igst}</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2}>
                              <div className="order-view-bottom">
                                <h4>
                                  <small className="date">
                                    <span className="date-span-botton">
                                      {moment(
                                        props?.order_view?.updated_at
                                      ).format("LLL")}
                                    </span>
                                  </small>
                                </h4>
                                <h4 className="text-end">
                                {VendorRegLang?.GrandTotal?.[language]}:{" "}
                                  <strong>
                                    ₹
                                    {order_data?.order_details?.cgst *
                                      order_data?.order_details?.quantity +
                                      order_data?.order_details?.sgst *
                                        order_data?.order_details?.quantity +
                                      order_data?.order_details?.amount *
                                        order_data?.order_details?.quantity}
                                  </strong>
                                </h4>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                      {/* <h4>
                            <strong>Total Amount :</strong> {item?.total_amount}
                          </h4> */}
                    </React.Fragment>

                    {props?.stat == 9 ? (
                      <>
                        <div className="Order-status">
                          <FormGroup>
                            <Label>
                            {VendorRegLang?.UploadtheDeliveryChallan?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <div className="image-input">
                              <Input
                                type="file"
                                name="challan"
                                id="challan"
                                accept=".pdf"
                                //disabled={props?.show_add == true ? false : true}
                                title=""
                                onChange={(e: any) =>
                                  handleFilechange(
                                    e,
                                    e.target.files[0],
                                    "challan"
                                  )
                                }
                                className={`form-control ${
                                  props?.touched.challan &&
                                  props?.errors.challan
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />{" "}
                              <Label
                                className="image-button"
                                for="challan"
                                id="challan"
                              >
                               {VendorRegLang?.ChooseDocuments?.[language]}
                              </Label>
                              {/* <span className="upload-text">Upload the Challan(s){file_name}</span> */}
                            </div>
                            <ErrorMessage
                              name="challan"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                        <div className="Order-status">
                          <FormGroup>
                            <Label>
                            {VendorRegLang?.WorkCompletionCertificate?.[language]}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <div className="image-input">
                              <Input
                                type="file"
                                name="wou"
                                accept=".pdf"
                                id="wou"
                                //disabled={props?.show_add == true ? false : true}
                                title=""
                                onChange={(e: any) =>
                                  handleFilechange(e, e.target.files[0], "wou")
                                }
                                className={`form-control ${
                                  props?.touched.wou && props?.errors.wou
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />{" "}
                              <Label
                                className="image-button"
                                for="wou"
                                id="wou"
                              >
                               {VendorRegLang?.ChooseDocuments?.[language]}
                              </Label>
                              {/* <span className="upload-text">Upload the Challan(s){file_name}</span> */}
                            </div>
                            <ErrorMessage name="wou" component={TextError} />
                          </FormGroup>
                        </div>

                        <div className="Order-status">
                          <FormGroup>
                            <Label>
                            {VendorRegLang?.UploadtheTaxInvoice?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <div className="image-input">
                              <Input
                                type="file"
                                name="invoice"
                                accept=".pdf"
                                id="invoice"
                                //disabled={props?.show_add == true ? false : true}
                                title=""
                                onChange={(e: any) =>
                                  handleFilechange(
                                    e,
                                    e.target.files[0],
                                    "invoice"
                                  )
                                }
                                className={`form-control ${
                                  props?.touched.invoice &&
                                  props?.errors.invoice
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <Label
                                className="image-button"
                                for="invoice"
                                id="invoice"
                              >
                                {VendorRegLang?.ChooseDocuments?.[language]}
                              </Label>
                              {/* <span className="upload-text">Upload the Challan(s){file_name}</span> */}
                            </div>
                            <ErrorMessage
                              name="invoice"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>

                        <div className="Order-status">
                          <FormGroup>
                            <Label>
                            {VendorRegLang?.UploadtheProductsimage?.[language]}{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>
                            <Col md-8 lg-8>
                              <div className="image-input">
                                <Input
                                  ref={ref}
                                  type="file"
                                  name="location"
                                  accept="file_extension|.gif, .jpg, .png"
                                  //disabled={props?.show_add == true ? false : true}
                                  title=""
                                  multiple
                                  onChange={(e: any) =>
                                    handleFilechange(
                                      e,
                                      e.target.files[0],
                                      "images"
                                    )
                                  }
                                  id="img"
                                  className={`form-control ${
                                    props?.touched.location &&
                                    props?.errors.location
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <Label
                                  className="image-button"
                                  for="location"
                                  id="img"
                                >
                                  {VendorRegLang?.ChooseDocuments?.[language]}
                                </Label>
                                {/* <span className="upload-text">Upload the Challan(s){file_name}</span> */}
                              </div>
                            </Col>
                            <ErrorMessage
                              name="location"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                        <div>
                          {props?.location.length > 1
                            ? props?.location?.map((val: any, ind: any) => {
                                //console.log(val,"vuffhfufjfu")
                                return val?.latitude != "" ||
                                  null ||
                                  undefined ? (
                                  <>
                                    <div>
                                      <span
                                        onClick={() => removeImg(ind)}
                                        style={{
                                          color: "red",
                                          position: "absolute",
                                          left: "0px",
                                          fontWeight: "bold",
                                          padding: "7px",
                                          border: "2px solid red",
                                          borderRadius: "100%",
                                          lineHeight: "1rem",
                                          margin: "5px",
                                        }}
                                      >
                                        X
                                      </span>
                                      <img
                                        src={
                                          val?.images
                                            ? URL.createObjectURL(val?.images)
                                            : URL.createObjectURL(val)
                                        }
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                        }}
                                      />
                                      <p> {VendorRegLang?.Latitude?.[language]} : {val?.latitude}</p>
                                      <p> {VendorRegLang?.Longitude?.[language]} : {val?.longitude}</p>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                );
                              })
                            : ""}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {props?.stat == 7 ? (
                      <>
                        {/* <div className="flex-between price-flex">
                          <Col sm={6}>
                            <Label>
                              Order Status{" "}
                              <i
                                className="fa fa-asterisk required-field"
                                color="text-danger"
                                aria-hidden="true"
                              ></i>
                            </Label>

                            <Input
                              type="select"
                              name="order_status"
                              value={props?.order_status_reject}
                              data={data}
                              onChange={props?.handleChange}
                              className={`form-control  mt-2 ${props?.touched.order_status && props?.errors.order_status
                                ? "is-invalid"
                                : ""
                                }`}

                            >
                              <option value="" >Select order status</option>;
                              {props?.order_status_reject?.map((item: any, i: number) => {
                                return (
                                  <React.Fragment key={item?.value}>
                                    <option key={i} value={item?.value} >
                                      {" "}
                                      {item?.name}
                                    </option>
                                  </React.Fragment>
                                );
                              })}
                            </Input>

                            <ErrorMessage name="order_status" component={TextError} />
                          </Col>

                          <Col sm={5} className="text-end">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={props?.handleSubmit}
                              className={"px-5"}
                              disabled={props?.stat == 9 ? props?.location?.length == 1 : false}
                            >
                              Save
                            </Button>
                          </Col>
                        </div>
                        <br />
                        <br /> */}

                        {order_data?.vendor_to_pmu_payment?.status !== 0 ? (
                          <>
                            {" "}
                            <Row className="text-end">
                              <div></div>
                              <div className="vendor-order-buttons">
                                <div className="button-flex mt-4">
                                  <Button
                                    className="btn"
                                    color="warning"
                                    onClick={downloadmou}
                                  >
                                    {VendorRegLang?.DownloadMOU?.[language]}
                                  </Button>
                                </div>
                              </div>
                            </Row>{" "}
                            {show_data == true && (
                              <Form>
                                <FormGroup className="file">
                                  <Label>
                                  {VendorRegLang?.MOUCertificate?.[language]}
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: "18px" }}
                                    >
                                      *
                                    </span>
                                  </Label>
                                  <div className="image-input">
                                    <Input
                                      type="file"
                                      id="mou"
                                      name="mou"
                                      onChange={(e: any) =>
                                        MouChange(e.target.files[0])
                                      }
                                      accept=".pdf"
                                      // value={mou_image}
                                    ></Input>
                                    <Label
                                      className="image-button"
                                      for="mou"
                                      id="mou"
                                    >
                                     {VendorRegLang?.ChooseDocuments?.[language]}
                                    </Label>
                                  </div>
                                </FormGroup>
                                <div className="text-danger">{}</div>

                                <div className="button-flex-outer">
                                  <div className="button-flex mt-4">
                                    <Button
                                      //type="submit"
                                      color="primary"
                                      onClick={() => uploadmou()}
                                      className={"px-5"}
                                    >
                                   {VendorRegLang?.UploadMOU?.[language]}
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </>
                        ) : (
                          <div className="flex-between price-flex margin">
                            <p>
                              {" "}
                              {VendorRegLang?.EMDAmount?.[language]} :{" "}
                              <span className="green-text">
                                {" "}
                                ₹
                                {EmdCalculation({
                                  amount: amo,
                                  udyam: order_data?.vendor_details?.udyam,
                                })?.toFixed(2)}
                              </span>
                              {order_data?.vendor_to_pmu_payment?.status ==
                              1 ? (
                                <span className="text-success">({VendorRegLang?.Approved?.[language]})</span>
                              ) : order_data?.vendor_details?.udyam == "yes" ? (
                                <span className="text-success">({VendorRegLang?.Approved?.[language]})</span>
                              ) : order_data?.vendor_to_pmu_payment?.status ==
                                2 ? (
                                <span className="text-danger">({VendorRegLang?.rejected?.[language]})</span>
                              ) : (
                                ""
                              )}
                            </p>
                            <div className="button-flex">
                              <Button
                                class="btn btn-primary"
                                color="primary"
                                type="submit"
                                onClick={() => {
                                  navigate("/secure-payment", {
                                    state: {
                                      amount: EmdCalculation({
                                        amount: amo,
                                        udyam:
                                          order_data?.vendor_details?.udyam,
                                      }),
                                      payfor: "EMD",
                                      order_id: props?.order_view[0]?.id,
                                      proforma_id:
                                        props?.order_view[0]?.proforma_id,
                                    },
                                  });
                                }}
                                style={{
                                  display: `${
                                    order_data?.vendor_details?.udyam == "yes"
                                      ? "none"
                                      : "block"
                                  }`,
                                }}
                                disabled={
                                  order_data?.vendor_to_pmu_payment?.status == 1
                                    ? true
                                    : order_data?.vendor_to_pmu_payment
                                        ?.status == 0
                                    ? true
                                    : false
                                }
                              >
                                {order_data?.vendor_details?.udyam == "yes"
                                  ? "Approved"
                                  : order_data?.vendor_to_pmu_payment?.status ==
                                    0
                                  ? "Paid EMD"
                                  : order_data?.vendor_to_pmu_payment?.status ==
                                    1
                                  ? "Approved"
                                  : order_data?.vendor_to_pmu_payment?.status ==
                                    2
                                  ? "Pay Again"
                                  : "Pay EMD"}
                              </Button>
                              <Button
                                className="btn"
                                color="success"
                                type="submit"
                                onClick={() => {
                                  navigate("/secure-payment", {
                                    state: {
                                      amount: EmdCalculation({
                                        amount: amo,
                                        udyam:
                                          order_data?.vendor_details?.udyam,
                                      }),
                                      payfor: "PG",
                                      order_id: props?.order_view[0]?.id,
                                      proforma_id:
                                        props?.order_view[0]?.proforma_id,
                                      type: "Pay Performance Guarentee",
                                    },
                                  });
                                }}
                              >
                                {VendorRegLang?.PayPG?.[language]}
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {order_data?.vendor_to_pmu_payment?.status == 0 && (
                      <div className="Order-status">
                        <Label>
                        {VendorRegLang?.OrderStatus?.[language]}{" "}
                          <i
                            className="fa fa-asterisk required-field"
                            color="text-danger"
                            aria-hidden="true"
                          ></i>
                        </Label>
                        {order_data?.vendor_to_pmu_payment?.status !== 0 ? (
                          <Input
                            type="select"
                            name="order_status"
                            value={props?.values?.order_satus}
                            data={data}
                            onChange={props?.handleChange}
                            className={`form-control  mt-2 ${
                              props?.touched.order_status &&
                              props?.errors.order_status
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">{VendorRegLang?.SelectOrderStatus?.[language]}</option>

                            {order_data?.vendor_details?.udyam == "yes" ? (
                              <>
                                <option
                                  value={props?.order_status?.[0]?.value}
                                  style={{ color: "green" }}
                                >
                                  {" "}
                                  {props?.order_status?.[0]?.name}
                                </option>
                                <option
                                  value={props?.order_status?.[1]?.value}
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {props?.order_status?.[1]?.name}
                                </option>
                              </>
                            ) : order_data?.vendor_to_pmu_payment?.status ==
                              1 ? (
                              <>
                                <option
                                  value={props?.order_status?.[0]?.value}
                                  style={{ color: "green" }}
                                >
                                  {" "}
                                  {props?.order_status?.[0]?.name}
                                </option>
                              </>
                            ) : (
                              <>
                                <option
                                  value={props?.order_status?.[1]?.value}
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {props?.order_status?.[1]?.name}
                                </option>
                              </>
                            )}
                          </Input>
                        ) : (
                          <Input
                            type="select"
                            name="order_status"
                            value={props?.values?.order_status}
                            data={data}
                            onChange={props?.handleChange}
                            className={`form-control  mt-2 ${
                              props?.touched.order_status &&
                              props?.errors.order_status
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value=""> {VendorRegLang?.SelectOrderStatus?.[language]}</option>
                            {props?.order_status?.map(
                              (item: any, i: number) => {
                                return (
                                  <React.Fragment key={item?.value}>
                                    <option key={i} value={item?.value}>
                                      {" "}
                                      {item?.name}
                                    </option>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </Input>
                        )}
                        <ErrorMessage
                          name="order_status"
                          component={TextError}
                        />
                        {props?.values?.order_status == 10 ? (
                          <>
                            <Input
                              name="rejection_reasons"
                              type="text"
                              value={props?.values?.rejection_reasons}
                              className={`form-control mt-4 ${
                                props?.touched.rejection_reasons &&
                                props?.errors.rejection_reasons
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={props?.handleChange}
                              placeholder="please enter reject reasons"
                            />
                            <ErrorMessage
                              name="rejection_reasons"
                              component={TextError}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <h4>
                    <i>
                      <b> {VendorRegLang?.AfterAllocationonlyyoucanvieworder?.[language]}</b>
                    </i>
                  </h4>
                )}
                {order_data?.vendor_to_pmu_payment?.status == 0 && (
                  <Row className="text-end">
                    <div></div>
                    <div className="vendor-order-buttons">
                      {props?.process_order == 1 && (
                        <>
                          {" "}
                          <div className="button-flex mt-4">
                            <Button
                              className="btn btn-primary"
                              onClick={downloadinvoice}
                            >
                              {VendorRegLang?.GenarateICCR?.[language]}
                            </Button>

                            {/* <Button onClick={generateinvoice} color="outline">View Work Order</Button> */}
                          </div>
                        </>
                      )}

                      <div className="button-flex mt-4">
                        <Button
                          color="primary"
                          className="btn"
                          onClick={downloadinvoice}
                        >
                          {VendorRegLang?.download?.[language]}
                        </Button>

                        {/* <Button onClick={generateinvoice} color="outline">View Work Order</Button> */}
                      </div>
                      <div className="button-flex-outer">
                        {props?.stat == 7 ? (
                          <div className="button-flex">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={props?.handleSubmit}
                              className={"px-5"}
                              // disabled={order_data?.vendor_details?.udyam ? false : order_data?.vendor_to_pmu_payment?.status == 1 ? false : true}
                            >
                              {VendorRegLang?.Save?.[language]}
                            </Button>
                          </div>
                        ) : (
                          <div className="button-flex mt-4">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={props?.handleSubmit}
                              className={"px-5"}
                              disabled={
                                props?.stat == 9
                                  ? props?.location?.length == 1
                                  : false
                              }
                            >
                               {VendorRegLang?.Save?.[language]}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                )}
              </Form>
            </div>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default OrderViews;
