import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Form, Input, InputGroup, InputGroupText, Table, List } from 'reactstrap'
import { api } from '../../../Library/CustomApi';
import constants from '../../../Library/Constants/Constants';
import { convertISODateToDateTime } from '../../../Library/Constants/helpers';
import ModalContainer from '../../../Components/Modal/ModalContainer';
import ApproveOrderModal from './ApproveOrderModal/ApproveOrderModal';
import { toast } from 'react-toastify';
import OrderViewModal from '../../Department/Orders/OrderViewModal/OrderViewModal';
import axios from 'axios';
import { API_URL } from '../../../Library/ApiSettings';

const AssignedOrders = () => {

    const [receivedOrders, setReceivedOrders] = useState<any>([])
    const [singleOrderData, setSingleOrderData] = useState<any>([])
    const [approveModalOpen, setApproveModalOpen] = useState<boolean>(false)
    const [orderData, setOrderData] = useState<any>([])
    const [selectedAddress, setSelectedAddress] = useState<any>([])
    const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false) // for feedback modal
    const { pathname } = useLocation();


    useEffect(() => {
        getReceivedOrder()
    }, [])

    // gets orders assigned 
    const getReceivedOrder = (page = 1) => {

        api
            .get(
                `/order/pf-invoice?status=1`,
                true
            )
            .then(async function ([success, response]) {
                setReceivedOrders(response || [])
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // handle approve or reject order 
    const orderStatusHandler = (status: 0 | 1, id: string, files?: any) => {

        const formData = new FormData();

        files?.length !== 0 && files?.forEach((file: any) => formData.append("file[]", file));
        formData.append('qa_approve', status?.toString())
        formData.append('invoice_id', id)

        axios({
            method: "post",
            url: `${API_URL}/vendor/qa_approve_reject`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            },
        })
            .then(function (response) {
                if (response?.status == 200) {
                    toast.success(response?.data?.message);
                    getReceivedOrder()
                    setApproveModalOpen(false)
                }

            })
            .catch(function (response) {
                toast?.error(response?.message)
            });
    }



    // handles order view modal 
    const handleOrderView = (obj: any) => {
        setSingleOrderData(obj.order_details)
        setOrderData(obj)
        setSelectedAddress(obj?.shipping_address[0])
        setOrderModalOpen(true)
    }

    return (
        <>
        <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
            {/* breadcrumb */}
            <List className=" breadcrumb">
                <li>
                    <Link className="active" to="/overview-vendor">
                        Dashboard
                    </Link>
                </li>
                &nbsp; / &nbsp;
                <li>
                    <b>
                        {pathname == "/qa-vendor/orders"
                            ? "Orders" : null
                        }
                    </b>
                </li>
            </List>
        </div>
         <div className="custom-tab-content">
            <div className="table-outer-wrap">
            <div className="search-widget-outer">
                <Form className="d-flex" role="search">
                    <InputGroup className="search-group">
                        <InputGroupText className="search-icon" id="search">
                            <i className="fa-sharp fa-solid fa-magnifying-glass" />
                        </InputGroupText>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder="Order ID/Product Name"
                            aria-label="Username"
                            aria-describedby="search"
                            onChange={(e: any) => {
                                // setCurrentPage(1)
                                // setSearchVal(e.target.value)
                            }}
                        />
                    </InputGroup>
                </Form>
            </div>

            <div className="table-wrap responsive-table">
                <Table className="w-100 table-striped">
                    <thead>
                        <tr>
                            <th>Sl No</th>
                            <th>Order ID</th>
                            <th>Department Name</th>
                            <th>Address</th>
                            <th>Ordered Date</th>
                            <th>Product</th>
                            {/* <th>Quantity</th> */}
                            <th>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            receivedOrders?.data?.map((itm: any, i: number) => {
                                let count = 0
                                return (
                                    <tr key={i}>
                                        <td>
                                            <span className="mobile-heading">Sl No:</span>
                                            <span className="table-data">{receivedOrders?.meta?.from + i}</span>
                                        </td>
                                        <td title="View Order">
                                            <span className="mobile-heading">Order ID:</span>
                                            <span className="table-data">
                                                <Link to="" className="blue-text" onClick={() => handleOrderView(itm)}>{itm?.order_id}</Link>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="mobile-heading">Department Name:</span>
                                            <span className="table-data">{itm?.user?.full_name}</span>
                                        </td>
                                        <td>
                                            <span className="mobile-heading">Address:</span>
                                            <span className="table-data">
                                                <p>
                                                    Building No:
                                                    {itm?.shipping_address?.[0]?.building_no}
                                                </p>
                                                <p>{itm?.shipping_address?.[0]?.city}</p>
                                                <p>
                                                    {itm?.shipping_address?.[0]?.district},{" "}
                                                    {itm?.shipping_address?.[0]?.state}
                                                </p>
                                                <p>Pin code:{itm?.shipping_address?.[0]?.pincode}</p>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="mobile-heading">Ordered Date:</span>
                                            <span className="table-data">{convertISODateToDateTime(itm?.created_at)}</span>
                                        </td>
                                        <td>
                                            <span className="mobile-heading">Product:</span>
                                            <span className="table-data">
                                                {
                                                    itm?.order_details?.map((obj: any, x: number) => {
                                                        count = count + obj.quantity
                                                        return (
                                                            <React.Fragment key={x}>
                                                                <span>
                                                                    {obj?.name}<br /> Unit price:
                                                                    <i className="bi bi-currency-rupee">
                                                                    </i>{obj?.price} <br />
                                                                    Quantity: {obj?.quantity} <br />
                                                                    Total: <i className="bi bi-currency-rupee"></i>{obj?.total_amount}
                                                                </span>
                                                                {itm?.order_details?.length !== x + 1 && <hr />}
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </span>
                                        </td>
                                        {/* <td>
                                            <span className="mobile-heading">Quantity:</span>
                                            <span className="table-data">{itm?.order_details?.[0]?.quantity}</span>
                                        </td> */}
                                        <td>
                                            <span className="mobile-heading">Amount:</span>
                                            <span className="table-data">
                                                <span className="green-text">₹&nbsp;{itm?.grand_amount}</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="mobile-heading">Action:</span>
                                            <span className="table-data">
                                                {
                                                    itm?.order_status == '16' ?
                                                        <>
                                                            <div
                                                                onClick={() => {
                                                                    setSingleOrderData(itm)
                                                                    setApproveModalOpen(true)
                                                                }}
                                                                title='Approve Order'
                                                                className='text-success approve-order-btn'
                                                            >
                                                                <i className="bi bi-check-circle"></i>&nbsp;
                                                                Approve
                                                            </div>

                                                            <div
                                                                onClick={() => {
                                                                    orderStatusHandler(0, itm?.id)
                                                                }}
                                                                title='Reject Order'
                                                                className='text-danger reject-order-btn'
                                                            >
                                                                <i className="bi bi-x-circle"></i>&nbsp;
                                                                Reject
                                                            </div>
                                                        </>
                                                        :
                                                        itm?.order_status == 17 ?
                                                            <span className='rejected-status'>Rejected</span> : <span className='approved-status'>Approved</span>
                                                }
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>

            </div>
            </div>
         </div>
        </div>
            {/* order view modal  */}
            <div>
                <ModalContainer
                    open={orderModalOpen}
                    setOpen={setOrderModalOpen}
                    header='Order Details'
                >
                    <OrderViewModal
                        singleOrderData={singleOrderData}
                        orderData={orderData}
                        orderViewLoading={false}
                        address={selectedAddress}
                        hideTracker
                    />
                </ModalContainer>
            </div>

            {/* Approve order modal  */}
            <ModalContainer
                open={approveModalOpen}
                setOpen={setApproveModalOpen}
                header='Approve Order'
            >
                <ApproveOrderModal
                    orderStatusHandler={orderStatusHandler}
                    id={singleOrderData?.id}
                />
            </ModalContainer>
        </>
    )
}

export default AssignedOrders