import React, { useEffect } from "react";
import { useState,useContext } from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import OrderView from "../../Orders/Modal/OrderView";
import { Admin_Delivered } from "../../../../Library/LanguageConversion/Admin_Delivered";
import { Context } from "../../../../Components/Context/Context";
export default function CancelOrders() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [cancel_order, setCancelOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState<any>();
  const [order_view, setOrderView] = useState([]);
  const {language,setLanguage} = useContext(Context)
  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getCancelOrders();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);

  const getCancelOrders = () => {
    setLoading(true);
    api
      .get(
        `/order/pf-invoice?status=${constants.ORDERS.CANCELLED}&page=${currentPage}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setCancelOrders(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  function limitText(text: any, limit: any) {
    if (text?.length > limit) {
      return text.substring(0, limit) + "...";
    } else {
      return text;
    }
  }

  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-proforma-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message?.[0]);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText className="search-icon" id="search">
              <i title="Product Name/Order ID" className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder="Product Name/Order ID"
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100">
          <thead>
            <tr>
              <th>{Admin_Delivered?.OrderID[language]}</th>
              {/* <th>Tender ID</th> */}
              <th>{Admin_Delivered?.OrderDetails[language]}</th>
              <th>{Admin_Delivered?.ShippingAddress[language]}</th>
              <th>{Admin_Delivered?.Quantity[language]}</th>
              <th>{Admin_Delivered?.Price[language]}</th>
              <th>{Admin_Delivered?.Department[language]}</th>
              <th>{Admin_Delivered?.Status[language]}</th>
            </tr>
          </thead>
          <tbody>
            {cancel_order?.length !== 0 ? (
              <>
                {cancel_order?.map((item: any, i: any) => {
                  let count = 0;
                  return (
                    <>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.OrderID[language]}:</span>
                          <span className="table-data">
                          <Link
                            to="#"
                            className="primary-text"
                            onClick={() => {
                              toggle();
                              getSingleOrder(item?.id);
                            }}
                          >
                            {item?.order_id}
                          </Link>
                            
                            </span>
                        </td>
                        {/* <td>
                          <span className="mobile-heading">Tender Id:</span>
                          <span className="table-data">
                            {item?.order_details[0]?.tender_id}
                          </span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.OrderDetails[language]}:</span>
                          <span className="table-data">
                            {item?.order_details?.length !== 0 ? (
                              <>
                                {item?.order_details?.map(
                                  (obj: any, x: number) => {
                                    count = count + obj.quantity;
                                    return (
                                      <React.Fragment key={x}>
                                        <span
                                          className="table-data"
                                          title={obj?.name}
                                        >
                                          {limitText(obj?.name, 15)}
                                          <br />{Admin_Delivered?.UnitPrice[language]}:
                                          <i className="bi bi-currency-rupee"></i>
                                          {obj?.price} <br />
                                          {Admin_Delivered?.Quantity[language]}: {obj?.quantity} <br />
                                          {Admin_Delivered?.Total[language]}:{" "}
                                          <i className="bi bi-currency-rupee"></i>
                                          {obj?.total_amount}
                                        </span>
                                        <br></br>

                                        {item?.order_details.length > 1 ? (
                                          <hr />
                                        ) : null}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                          {Admin_Delivered?.ShippingAddress[language]}:
                          </span>
                          <span className="table-data">
                            <p>
                            {Admin_Delivered?.BuildingNo[language]}:
                              {item?.shipping_address[0]?.building_no}
                            </p>
                            <p>{item?.shipping_address[0]?.city}</p>
                            <p>
                              {" "}
                              {item?.shipping_address[0]?.district},{" "}
                              {item?.shipping_address[0]?.state}
                            </p>

                            <p>{Admin_Delivered?.Pincode[language]}:{item?.shipping_address[0]?.pincode}</p>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.Quantity[language]}:</span>
                          <span className="table-data">{count}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">Price:</span>
                          <span className="table-data">
                            <i className="bi bi-currency-rupee"></i>
                            {item?.grand_amount}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.Department[language]}:</span>
                          <span className="table-data">
                            {item?.user?.full_name}
                          </span>
                        </td>

                        <td>
                          <span className="mobile-heading">{Admin_Delivered?.Status[language]}:</span>
                          <span className="table-data"> <span className="text-danger">{Admin_Delivered?.Cancelled[language]}</span></span>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={7}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Cancelled Order Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && cancel_order?.length !== 0 && (
        <div className="table-footer-wrap">
          <small>
            Showing {pageData.from} to {pageData.to} of {pageData.total} entries
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      <OrderView order_view={order_view} modal={modal} toggle={toggle} loading={loading} cancel={7}/>
    </React.Fragment>
  );
}
