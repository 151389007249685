import React, { useEffect, useState, useRef ,useContext} from "react";


import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Collapse,
  Table,
} from "reactstrap";
import { truncateText } from "../../../Library/Constants/helpers";
import moment from "moment";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

export default function CommonOrderView(props: any) {
  const [isOpen, setIsOpen] = useState<any>(false);
  const { language, setLanguage } = useContext(Context);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      {props?.order_view?.[0] !== 0 ? (
        <>
          <React.Fragment>
            <Table className="table-hover table-bordered">
              <tbody>
                <tr>
                  <td>
                    <b>{VendorRegLang?.order_id?.[language]}</b>{" "}
                  </td>
                  <td> {props?.order_data?.vendor_po_no} </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    <b> {VendorRegLang?.product_name?.[language]}</b>{" "}
                  </td>
                  <td>
                    {props?.order_data?.order_details?.product_details?.name}
                  </td>
                </tr>
                <tr onClick={() => toggle()} className="collapse-btn">
                  <td>
                    <b>{VendorRegLang?.product_code?.[language]}</b>{" "}
                  </td>
                  <td>
                    <span className="code">
                      {
                        props?.order_data?.order_details?.product_details
                          ?.product_code
                      }{" "}
                    </span>{" "}
                  </td>
                </tr>
                <Collapse isOpen={isOpen} tag={"tr"}>
                  <td colSpan={2}>
                    <Table className=" table-bordered ">
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <b>{VendorRegLang?.hsn_code?.[language]}</b>{" "}
                          </td>
                          <td>
                            {
                              props?.order_data?.order_details?.product_details
                                ?.hsn_code
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <b> {VendorRegLang?.quantity?.[language]}</b>
                          </td>
                          <td> {props?.order_data?.order_details?.quantity}</td>
                        </tr>
                        <tr>
                          <td>
                            <b> {VendorRegLang?.UnitPrice?.[language]}</b>
                          </td>
                          <td>
                            ₹ {props?.order_data?.order_details?.unit_price}
                          </td>
                        </tr>

                        <tr className="large-screen-view">
                          <td>
                            <b>{VendorRegLang?.ProductSpecifications?.[language]}</b>
                          </td>
                          <td>
                            <p className="specs">
                              <small>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: truncateText(
                                      props?.order_data?.order_details
                                        ?.product_details?.features
                                    ),
                                  }}
                                />
                              </small>
                            </p>
                          </td>
                        </tr>
                        {/* this row shows in mobile (Specs)*/}
                        <tr className="mobile-view">
                          <td colSpan={2} className="text-center">
                            <b>{VendorRegLang?.ProductSpecifications?.[language]}</b>
                          </td>
                        </tr>
                        <tr className="mobile-view">
                          <td colSpan={2}>
                            <p className="specs centered">
                              <small>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: truncateText(
                                      props?.order_data?.order_details
                                        ?.product_details?.features
                                    ),
                                  }}
                                />
                              </small>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </Collapse>
                {props?.order_data?.address?.[0]?.state == "Karnataka" ? (
                  <>
                    <tr>
                      <td>
                        <b>{VendorRegLang?.SGST?.[language]}</b>{" "}
                      </td>
                      <td>₹ {props?.order_data?.order_details?.sgst}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{VendorRegLang?.CGST?.[language]}</b>{" "}
                      </td>
                      <td>₹ {props?.order_data?.order_details?.cgst}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>
                        <b> {VendorRegLang?.IGST?.[language]}</b>{" "}
                      </td>
                      <td>₹ {props?.order_data?.order_details?.igst}</td>
                    </tr>
                  </>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <div className="order-view-bottom">
                      <h4>
                        <small className="date">
                          <span className="date-span-botton">
                            {moment(props?.order_view?.updated_at).format(
                              "LLL"
                            )}
                          </span>
                        </small>
                      </h4>
                      <h4 className="text-end">
                      {VendorRegLang?.GrandTotal?.[language]} :{" "}
                        <strong>
                          ₹
                          {props?.order_data?.order_details?.cgst *
                            props?.order_data?.order_details?.quantity +
                            props?.order_data?.order_details?.sgst *
                              props?.order_data?.order_details?.quantity +
                            props?.order_data?.order_details?.amount *
                              props?.order_data?.order_details?.quantity}
                        </strong>
                      </h4>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </React.Fragment>
        </>
      ) : (
        <h4>
          <i>
            <b>{VendorRegLang?.AfterAllocationonlyyoucanvieworder?.[language]}</b>
          </i>
        </h4>
      )}
    </>
  );
}
