import moment from "moment";
import React, { useEffect, useState, useRef ,useContext} from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Collapse,
  Table,
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import Toast from "reactstrap";
import { toast } from "react-toastify";

import TextError from "../../../../Components/Errors/TextError";
import { ErrorMessage } from "formik";
import { API_URL } from "../../../../Library/ApiSettings";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { EmdCalculation, PGCalculation } from "../EmdCalculation";

import TrackOrder from "../TrackOders";
import DatePicker from "react-date-picker";
import CommonOrderView from "../CommonOrderView";
import { VendorRegLang } from "../../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../../Components/Context/Context";

export default function ProcessOrderModal(props: any) {
  const [isOpen, setIsOpen] = useState<any>(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const order_data = props?.order_view?.[0];

  const [show_status, setStatus] = useState(1);
  const {language,setLanguage} = useContext(Context)
  const [show_data_iccr, setShowDataICCR] = useState(false);

  var amo =
    order_data?.order_details?.cgst * order_data?.order_details?.quantity +
    order_data?.order_details?.sgst * order_data?.order_details?.quantity +
    order_data?.order_details?.amount * order_data?.order_details?.quantity;
  const toggle = () => setIsOpen(!isOpen);

  function truncateText(text: any) {
    return text?.replace(/<br>/g, ", ");
  }
  const preventNegativeValues = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/; // regex pattern to match only numeric input
    if (!regex.test(keyValue)) {
      e.preventDefault();
      ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  };
  const downloadinvoice = (value: string) => {
    const api =
      value == "DC"
        ? `order/vendor-delivery-chellan-generate?orderId=${props?.order_view[0]?.id}`
        : value == "WO"
        ? `order/invoice-download?orderId=${props?.order_view[0]?.vendor_po_no}`
        : value == "TAX"
        ? `order/vendor-tax-invoice-generate?orderId=${props?.order_view[0]?.id}`
        : value == "ICCR"
        ? `order/vendor-tax-invoice-generate?orderId=${props?.order_view[0]?.id}`
        : null;

    if (value == "WO") { 
      const requestOptions: any = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        },
      };
      fetch(`${API_URL}/${api}`, requestOptions)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Work Order.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        })
        .catch((e) => {
          console.log(e, ">>>");
        });
    } else {
      const requestOptions: any = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        },
      };
      fetch(`${API_URL}/${api}`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (value == "TAX") {
            props?.setFieldValue(
              "tax_invoice_place_of_supply",
              response?.data?.supply_address_to
            );
            props?.setFieldValue(
              "tax_invoice_billing_address",
              response?.data?.billing_address_from
            );
            props?.setFieldValue("tax_invoice_amount", response?.data?.amount);
            props?.setFieldValue(
              "tax_invoice_date",
              moment(response?.data?.invoice_date).format("YYYY-MM-DD")
            );
            props?.setFieldValue(
              "tax_invoice_number",
              response?.data?.invoice_number
            );
            props?.getSingleOrder(props?.order_id);
          }
          if (value == "DC") {
            props?.setFieldValue(
              "delivery_chellan_grand_total",
              response?.data?.grant_total
            );
            props?.setFieldValue(
              "delivery_chellan_round_off",
              response?.data?.tax
            );
            props?.setFieldValue("delivery_chellan_tax", response?.data?.tax);
            props?.setFieldValue(
              "delivery_chellan_total",
              response?.data?.sub_total
            );
            props?.setFieldValue(
              "delivery_chellan_date",
              moment(response?.data?.chellan_date).format("YYYY-MM-DD")
            );
            props?.setFieldValue(
              "delivery_chellan_number",
              response?.data?.chellan_number
            );
            props?.getSingleOrder(props?.order_id);
          }
        })
        .catch((e) => {
          console.log(e, ">>>");
        });
    }
  };
  const handleFilechange = (e: any, values: any, field: string) => {};

  return (
    <>
      <React.Fragment>
        <ModalContainer
          open={props?.isShowing}
          setOpen={() => {
            props?.setIsShowing(false);
            props?.handleReset();
          }}
          header="Order View"
        >
          <div className="feeback-outer-wrap">
            <div className="feedback-main-heading">
              <TrackOrder order_view={order_data} />
              <h2>{VendorRegLang?.order_details?.[language]}</h2>
            </div>
            <div className="feedback-wrap">
              <div className="feedback-details">
                <Form className="form-wrap">
                  <CommonOrderView order_data={order_data} />
                </Form>
                <div className="Order-status">
                  <h3>Tax Invoice Details</h3>
                  {order_data?.vendor_tax_invoice?.verified_status == "2" && (
                    <>
                      <h4>
                        <i>
                          <b className="text-danger">
                            Tax Invoice Rejected,please generate again
                          </b>
                        </i>
                      </h4>
                    </>
                  )}
                  {order_data?.vendor_tax_invoice?.verified_status == "0" ? (
                    <h4>
                      <i>
                        <b className="text-success">
                          Tax Invoice Under the Verification
                        </b>
                      </i>
                    </h4>
                  ) : order_data?.vendor_tax_invoice?.verified_status == "2" ||
                    order_data?.vendor_tax_invoice == null ? (
                    <Row>
                      <div className="gererate-btn-wrap">
                        <Button
                          color="primary"
                          className="btn btn-primary mb-4"
                          onClick={() => downloadinvoice("TAX")}
                        >
                          Generate Tax Invoice
                        </Button>
                      </div>
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Invoice Number</Label>
                            <Input
                              name="tax_invoice_number"
                              type="text"
                              value={props?.values?.tax_invoice_number}
                              onChange={props?.handleChange}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Invoice Date</Label>
                            <DatePicker
                              value={props?.values?.tax_invoice_date}
                              onChange={(e: any) => {
                                props?.setFieldValue("tax_invoice_date", e);
                              }}
                              name="tax_invoice_date"
                              format={"dd-MM-y"}
                              dayPlaceholder={"dd"}
                              monthPlaceholder={"mm"}
                              yearPlaceholder={"yyyy"}
                              maxDate={new Date()}
                              className={`form-control ${
                                props?.touched.work_completion_date &&
                                props?.errors.work_completion_date
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Amount</Label>
                            <Input
                              name="tax_invoice_amount"
                              type="number"
                              value={props?.values?.tax_invoice_amount}
                              onChange={props?.handleChange}
                              min={3}
                              onKeyPress={preventNegativeValues}
                              className={`form-control ${
                                props?.touched.tax_invoice_amount &&
                                props?.errors.tax_invoice_amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Billing Address</Label>
                            <Input
                              name="tax_invoice_billing_address"
                              type="textarea"
                              value={props?.values?.tax_invoice_billing_address}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.tax_invoice_amount &&
                                props?.errors.tax_invoice_amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Place of Supply</Label>
                            <Input
                              name="tax_invoice_place_of_supply"
                              type="textarea"
                              value={props?.values?.tax_invoice_place_of_supply}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.tax_invoice_place_of_supply &&
                                props?.errors.tax_invoice_place_of_supply
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  {order_data?.vendor_tax_invoice?.verified_status == "1" && (
                    <>
                      <h4>
                        <i>
                          <b className="text-success">Tax Invoice Verifed</b>
                        </i>
                      </h4>
                    </>
                  )}
                </div>
                <hr />
                <div className="Order-status">
                  <h3>Delivery Chellan Details</h3>
                  {order_data?.vendor_delivery_challan?.verified_status ==
                    "2" && (
                    <>
                      <h4>
                        <i>
                          <b className="text-danger">
                            Delivery chellan Rejected ,please generate again
                          </b>
                        </i>
                      </h4>
                    </>
                  )}
                  {order_data?.vendor_delivery_challan?.verified_status ==
                  "0" ? (
                    <h4>
                      <i>
                        <b className="text-success">
                          Delivery chellan Under the Verification
                        </b>
                      </i>
                    </h4>
                  ) : order_data?.vendor_delivery_challan?.verified_status ==
                      "2" || order_data?.vendor_delivery_challan == null ? (
                    <Row>
                      <div className="gererate-btn-wrap">
                        <Button
                          color="primary"
                          className="btn-primary mb-4"
                          onClick={() => downloadinvoice("DC")}
                        >
                          Generate Delivery Chellan
                        </Button>
                      </div>
                      

                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Chellan Number</Label>
                            <Input
                              name="delivery_chellan_number"
                              type="text"
                              value={props?.values?.delivery_chellan_number}
                              onChange={props?.handleChange}
                              className={`form-control ${
                                props?.touched.delivery_chellan_number &&
                                props?.errors.delivery_chellan_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Chellan Date</Label>
                            <DatePicker
                              value={props?.values?.delivery_chellan_date}
                              onChange={(e: any) => {
                                props?.setFieldValue(
                                  "delivery_chellan_date",
                                  e
                                );
                              }}
                              name="delivery_chellan_date"
                              format={"dd-MM-y"}
                              dayPlaceholder={"dd"}
                              monthPlaceholder={"mm"}
                              yearPlaceholder={"yyyy"}
                              maxDate={new Date()}
                              className={`form-control ${
                                props?.touched.delivery_chellan_date &&
                                props?.errors.delivery_chellan_date
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Sub Total</Label>
                            <Input
                              name="delivery_chellan_total"
                              type="number"
                              value={props?.values?.delivery_chellan_total}
                              onChange={props?.handleChange}
                              min={3}
                              onKeyPress={preventNegativeValues}
                              className={`form-control ${
                                props?.touched.delivery_chellan_total &&
                                props?.errors.delivery_chellan_total
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label> Tax</Label>
                            <Input
                              name="delivery_chellan_tax"
                              type="number"
                              value={props?.values?.delivery_chellan_tax}
                              onChange={props?.handleChange}
                              min={3}
                              onKeyPress={preventNegativeValues}
                              className={`form-control ${
                                props?.touched.delivery_chellan_tax &&
                                props?.errors.delivery_chellan_tax
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm={4}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label> Billing to</Label>
                          <Input
                            name="delivery_chellan_billing_address"
                            type="number"
                            value={
                              props?.values?.delivery_chellan_billing_address
                            }
                            onChange={props?.handleChange}
                            className={`form-control ${
                              props?.touched.delivery_chellan_billing_address &&
                              props?.errors.delivery_chellan_billing_address
                                ? "is-invalid"
                                : ""
                            }`}
                            disabled
                          />
                        </div>
                      </FormGroup>
                    </Col> */}
                      {/* <Col sm={4}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label> Round off</Label>
                          <Input
                            name="delivery_chellan_round_off"
                            type="number"
                            value={props?.values?.delivery_chellan_round_off}
                            onChange={props?.handleChange}
                            min={0}
                            className={`form-control ${
                              props?.touched.delivery_chellan_round_off &&
                              props?.errors.delivery_chellan_round_off
                                ? "is-invalid"
                                : ""
                            }`}
                            disabled
                          />
                         
                        </div>
                      </FormGroup>
                    </Col> */}
                      <Col sm={4}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                              {" "}
                              Grand Total
                              {/* <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span> */}
                            </Label>
                            <Input
                              name="delivery_chellan_grand_total"
                              type="number"
                              value={
                                props?.values?.delivery_chellan_grand_total
                              }
                              onChange={props?.handleChange}
                              min={0}
                              className={`form-control ${
                                props?.touched.delivery_chellan_grand_total &&
                                props?.errors.delivery_chellan_grand_total
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                            {/* <ErrorMessage
                            name="tax_invoice_amount"
                            component={TextError}
                          /> */}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  {order_data?.vendor_delivery_challan?.verified_status ==
                    "1" && (
                    <>
                      <h4>
                        <i>
                          <b className="text-success">Tax Invoice Verifed</b>
                        </i>
                      </h4>
                    </>
                  )}
                </div>
              </div>
              {/* {order_data?.vendor_tax_invoice?.verified_status == "1" &&
              order_data?.vendor_delivery_challan?.verified_status == "1" ? (
                <Row className="text-end">
                  <Col sm={12}>
                    {" "}
                    <div className="button-flex mt-4">
                      <Button
                        className="btn btn-primary"
                        onClick={() => downloadinvoice("ICCR")}
                        title="Proceed after Verification of Delivery Chellan && Tax Document"
                      >
                        Genarate ICRR
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="text-end">
                  <Col sm={12}>
                    {" "}
                    <div className="button-flex mt-4">
                      <Button
                        className="btn btn-primary"
                        onClick={() => downloadinvoice("ICCR")}
                        disabled
                        title="Proceed after Verification of Delivery Chellan && Tax Document"
                      >
                        Genarate ICRR
                      </Button>
                    </div>
                  </Col>
                </Row>
              )} */}

              {/* {show_data_iccr == true && (
                <> */}
              <div className="Order-status mt-5">
                <Label>
                  Order Status{" "}
                  <i
                    className="fa fa-asterisk required-field"
                    color="text-danger"
                    aria-hidden="true"
                  ></i>
                </Label>

                <Input
                  type="select"
                  name="order_status"
                  value={props?.values?.order_status}
                  data={data}
                  onChange={props?.handleChange}
                  className={`form-control  mt-2 ${
                    props?.touched.order_status && props?.errors.order_status
                      ? "is-invalid"
                      : ""
                  }`}
                  disabled={(props?.order_view?.[0]?.vendor_delivery_challan?.verified_status == 1 && props?.order_view?.[0]?.vendor_tax_invoice?.verified_status == 1) ? false : true }

                >
                  <option value="">Select Order Status</option>
                  {props?.order_status?.map((item: any, i: number) => {
                    return (
                      <React.Fragment key={item?.value}>
                        <option key={i} value={item?.value}>
                          {" "}
                          {item?.name}
                        </option>
                      </React.Fragment>
                    );
                  })}
                </Input>
                <ErrorMessage name="order_status" component={TextError} />
              </div>

              <Row className="text-end">
                <div></div>
                <div className="vendor-order-buttons">
                  <div className="button-flex mt-4">
                    <Button
                      color="success"
                      className="btn"
                      onClick={() => downloadinvoice("WO")}
                    >
                      Download Work Order
                    </Button>

                    {/* <Button onClick={generateinvoice} color="outline">View Work Order</Button> */}
                  </div>
                  <div className="button-flex-outer">
                    {/* {props?.stat == 7 ? (
                      <div className="button-flex">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={props?.handleSubmit}
                          className={"px-5"}
                          // disabled={order_data?.vendor_details?.udyam ? false : order_data?.vendor_to_pmu_payment?.status == 1 ? false : true}
                        >
                          Save
                        </Button>
                      </div>
                    ) : ( */}
                      <div className="button-flex mt-4">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={props?.handleSubmit}
                          className={"px-5"}
                          disabled={(props?.order_view?.[0]?.vendor_delivery_challan?.verified_status == 1 && props?.order_view?.[0]?.vendor_tax_invoice?.verified_status == 1) ? false : true }
                          >
                          Save
                        </Button>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </Row>
              {/* </>
              )} */}
            </div>
          </div>
        </ModalContainer>
      </React.Fragment>
    </>
  );
}
