import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";
import NonVerifiedUser from "../../Pages/Department/NonVerifiedUser/NonVerifiedUser";
import { api } from "../../Library/CustomApi";

interface PrivateRouteProps {
  loginStatus: boolean;
}

const DeptRoutes = (loginStatus: PrivateRouteProps) => {
  const [userData, setUserData] = useState<any>([])
  const [dept, setDpt] = useState(true);

  useEffect(() => {
    getProfile()
  }, [])

  // gets user details for validating role
  const getProfile = () => {
    api
      .get(`/profile`, true)
      .then(async function ([success, response]) {
        setUserData(response && response[0]);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("ROLE_ID") == "3") {
      setDpt(true);
    } else {
      setDpt(false);
    }
  }, [loginStatus]);

  const location = useLocation();

  return dept ? (
    <>
      {userData?.status == 1 && <Outlet />}
      {userData?.status == 0 && <NonVerifiedUser />}

    </>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default DeptRoutes;