import React, { useState } from 'react'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import { Col } from 'reactstrap';

const AdminLayout = ({ children }: any) => {
    const [openAdminSidebar, setAdminSidebar] = useState<any>(false); //mobile view
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true); // web view



    return (
        <>
            <Sidebar
                openAdminSidebar={openAdminSidebar}
                setAdminSidebar={setAdminSidebar}
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
            />
            
            {/* right side section which includes body and header  */}
            <Col md={sidebarIsOpen ? '10' : '12'} className={sidebarIsOpen ? 'offset-md-2 minimise-content' : 'expand-content'}>
                <Header openAdminSidebar={openAdminSidebar} setAdminSidebar={setAdminSidebar} />
                <div className="dashboard-outer" id="content"> <main>{children}</main>
                </div>
            </Col>
        </>
    )
}

export default AdminLayout