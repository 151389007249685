export const Admin_Settings:any={
    // Setting/Tabs/ProfileSettings
    Phone_Number: {
        en: 'Phone Number',
        ka: 'ಫೋನ್ ಸಂಖ್ಯೆ'
    },
    Password: {
        en: 'Password',
        kaimpor: 'ಗುಪ್ತಪದ'
    },
    Email: {
        en: 'Email',
        ka: 'ಇಮೇಲ್'
    },
    Enter_Password: {
        en: 'Enter Password',
        ka: 'ಗುಪ್ತಪದವನ್ನು ನಮೂದಿಸಿ'
    },
    Confirm_Password: {
        en: 'Confirm Password',
        ka: 'ಪಾಸ್ವರ್ಡ್ ದೃ ಪಾಸ್ವರ್ಡ್ ದೃ'
    },
    Enter_OTP: {
        en: 'Enter OTP',
        ka: 'ಒಟಪ ನಮೂದಿಸಿ'
    },
    Update: {
        en: 'Update',
        ka: 'ನವೀಕರಿಸಿ'
    },
    A1: {
        en: ' A Verification link has been sent to your email account',
        ka: 'ನಿಮ್ಮ ಇಮೇಲ್ ಖಾತೆಗೆ ಪರಿಶೀಲನಾ ಲಿಂಕ್ ಕಳುಹಿಸಲಾಗಿದೆ"'
    },
    A2: {
        en: 'Please click on the link that has been sent to your email account to verify your email',
        ka: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಖಾತೆಗೆ ಕಳುಹಿಸಲಾದ ಲಿಂಕ್‌ಗೆ ಕ್ಲಿಕ್ ಮಾಡಿ ನಿಮ್ಮ ಇಮೇಲ್ ಖಾತೆಯನ್ನು ಪರಿಶೀಲಿಸಿ'
    },
    
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    Profile_Settings: {
        en: 'Profile Settings',
        ka: 'ಪ್ರೊಫೈಲ್ ಸೆಟ್ಟಿಂಗ್ಗಳು'
    },
   
}