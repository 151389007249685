import React, { useContext , useEffect} from 'react'
import { Link } from 'react-router-dom'

export default function TCEnglish() {

   

    return (
        <div>
            <section>
                <div className="terms-container-fluid">
                    <div className="terms-banner-outer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="terms-banner-text-wrap">
                                        <h1>
                                            Terms And Conditions
                                        </h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to={"/home"}>Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">Terms and Conditions</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section> 
                <div className="container py-5">
                    <div className="terms-conditions">
                    <div className="WordSection1">

                        <p className="MsoNormal" ><span lang="EN">These Terms and
                        Conditions ("Agreement") govern your use of <Link to="https://ebuy.keonics.in/" target="_blank" style={{color:'#1155CC'}}>https://ebuy.keonics.in/</Link>
                        to meet the diverse needs of our clients. Our services include IT training,
                        networking solutions, software development, web solutions, data entry, data
                        warehousing, and e-procurement solutions. We provide comprehensive IT training
                        through our centers, Yuva.Com Centers, and Franchisee Centers, focusing on
                        strengthening the IT community and catering to market requirements. Our
                        well-trained and experienced manpower delivers computer networking and network
                        maintenance solutions across Karnataka, ensuring efficient operations and
                        security. With our in-house expertise, we develop application software and have
                        successfully executed numerous projects in this domain. Our dedicated Web
                        solutions division creates, hosts, and maintains websites, including those for
                        government departments. Additionally, we handle large-scale data entry and data
                        warehousing projects for various organizations. Through our e-procurement
                        solution, including e-tendering and works management software, we bring
                        automation and enhanced transparency to goods procurement processes for
                        government departments. We are committed to protecting the privacy of our
                        users, and this privacy policy outlines how we collect, use, store, and protect
                        personal information in accordance with applicable privacy laws and
                        regulations. provided by ("KEONICS"). By accessing or using the
                        Service, you agree to be bound by this Agreement. If you do not agree to these
                        terms, you may not use the Service.</span></p>

                        <h4><span lang="EN">1. User Obligations</span></h4>
                        <p className="MsoNormal" ><span lang="EN">1.1. You agree to
                        use the Service in compliance with all applicable laws and regulations.</span></p>

                        <p className="MsoNormal" ><span lang="EN">1.2. You are
                        responsible for maintaining the confidentiality of your account credentials and
                        for any activities that occur under your account.</span></p>

                        <h4><span lang="EN">2. Intellectual
                        Property</span></h4>
                        <p className="MsoNormal" ><span lang="EN">2.1. The Service
                        and all related materials, including but not limited to software, graphics,
                        logos, and content, are the intellectual property of the Company and protected
                        by copyright and other intellectual property laws.</span></p>

                        <p className="MsoNormal" ><span lang="EN">2.2. You may not
                        modify, reproduce, distribute, or create derivative works based on any part of
                        the Service without the Company's prior written consent.</span></p>

                        <h4><span lang="EN">3. Privacy</span></h4>
                        <p className="MsoNormal" ><span lang="EN">3.1. The Company's
                        Privacy Policy governs the collection, use, and disclosure of personal
                        information provided by users of the Service. By using the Service, you consent
                        to the collection and use of your personal information as outlined in the
                        Privacy Policy.</span></p>

                        <h4><span lang="EN">4. Limitation of
                        Liability</span></h4>

                        <p className="MsoNormal" ><span lang="EN">4.1. The Service is
                        provided on an "as is" and "as available" basis. The
                        Company makes no warranties or representations, expressed or implied, regarding
                        the Service's availability, reliability, or suitability for your particular
                        purpose.</span></p>

                        <p className="MsoNormal" ><span lang="EN">4.2. The Company
                        shall not be liable for any indirect, incidental, consequential, or punitive
                        damages arising out of or in connection with the use of the Service.</span></p>

                        <h4><span lang="EN">5.
                        Indemnification</span></h4>

                        <p className="MsoNormal" ><span lang="EN">5.1. You agree to
                        indemnify and hold the Company and its officers, directors, employees, and
                        affiliates harmless from any claims, damages, losses, or expenses (including
                        attorney's fees) arising out of your use of the Service or violation of this
                        Agreement.</span></p>

                        <h4><span lang="EN">6. Termination</span></h4>

                        <p className="MsoNormal" ><span lang="EN">6.1. The Company
                        may, at its discretion, suspend or terminate your access to the Service without
                        prior notice if you violate any provision of this Agreement.</span></p>

                        <h4><span lang="EN">7. Governing Law</span></h4>

                        <p className="MsoNormal" ><span lang="EN">7.1. This Agreement
                        shall be governed by and construed in accordance with the laws of [your
                        jurisdiction]. Any disputes arising under or in connection with this Agreement
                        shall be subject to the exclusive jurisdiction of the courts in.</span></p>

                        <h4><span lang="EN">8. Severability</span></h4>

                        <p className="MsoNormal" ><span lang="EN">8.1. If any
                        provision of this Agreement is found to be unenforceable or invalid, the
                        remaining provisions shall remain in full force and effect.</span></p>

                        <p className="MsoNormal"><span lang="EN">&nbsp;</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}