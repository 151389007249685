import React, { useEffect, useState ,useContext} from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { List } from "reactstrap";
import { api } from "../../../Library/CustomApi";
import { Context } from "../../../Components/Context/Context";
import { VendorSignup } from "../../../Library/LanguageConversion/Vendor_SignUp";

export default function LayoutSelfRegisterVendor(props: any) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const [doc_complted, setDocomplted] = useState();
  const [emd, setEmd] = useState();
  const [advanced, setAdvanced] = useState();
  const { language, setLanguage } = useContext(Context);
  useEffect(() => {
    getDocumnets();
    //navigate(pathname);
  }, [doc_complted, advanced, pathname]);
  const getDocumnets = () => {
    api
      .post(`/get_documents`, {}, true)
      .then(async function ([success, response]) {
        let c = response?.data?.documents?.length;
        setDocomplted(c);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .get(`/vendor/emd-history`, true)
      .then(async function ([success, response]) {
        let c = response?.data?.data?.length;
        setEmd(c);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .post(`/get_vendor_profile`, {}, true)
      .then(async function ([success, response]) {
        setAdvanced(response?.vendor_details?.address?.length);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/self-register-vendor-basic-det">
            {VendorSignup?.basic_details[language]}
            </NavLink>
            <NavLink to="/self-register-vendor-advanced-det">
            {VendorSignup?.Advanced_registration[language]}
            </NavLink>
            {advanced == 0 ? (
              <span
                className="main"
                title="Please Complete Advanced Registration"
              >
                {VendorSignup?.Document_upload[language]}
              </span>
            ) : (
              <NavLink to="/self-register-vendor-document-upload">
               {VendorSignup?.Document_upload[language]}
              </NavLink>
            )}
            {doc_complted == 0 ? (
              <span className="main" title="Please Upload Documents">
                {VendorSignup?.Non_Refundable_Loyalty_Amount[language]}
              </span>
            ) : (
              <NavLink to="/self-register-vendor-emd">
              {VendorSignup?.Non_Refundable_Loyalty_Amount[language]}
              </NavLink>
            )}
          </div>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
