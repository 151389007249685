import React ,{useContext} from 'react'
import { Link } from 'react-router-dom';
import { Context } from '../../../Components/Context/Context';


export default function IndexKanada() {
    // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
    const { language, setLanguage } = useContext(Context);
    return (
        <div>
            <div>
                <div className="bg" />
                <div className="drag">
                    <div className="w-100">
                        <div className="logo-launch-outer-wrap">
                            <img className="logolaunch logolaunch-ani" src={require("../../images/logo-white1.png")} width={500} />
                        </div>
                        <div className="text-launch-light fw-bold text-center fs-2 mt-4">
                            ನಾವು <span>ಕೆ.ಟಿ.ಐ.ಪಿ.ಪಿ</span> (ಕಿಯೋನಿಕ್ಸ್ ಪಾರದರ್ಶಕ ಐಟಿ ಪ್ರೊಕ್ಯೂರ್‌ಮೆಂಟ್ ಪೋರ್ಟಲ್) ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಪ್ರಾರಂಭಿಸುತ್ತಿದ್ದೇವೆ! </div>
                        <div className="text-white text-center mt-3 pt-2">
                        <Link to={'/home'} id="launch" className="d-inline-block fs-4 rounded px-5 py-3 mt-1 launch-btn" style={{ border: '2px solid white', backgroundColor: '#FF6517', color: 'white' }} >  ಪ್ರಾರಂಭಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ! </Link>

                            {/* <a id="launch" className="d-inline-block fs-4 rounded px-5 py-3 mt-1 launch-btn" href="welcome" style={{ border: '2px solid white', backgroundColor: '#FF6517', color: 'white' }}>
                                ಪ್ರಾರಂಭಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ!</a> */}
                        </div>
                        <div className="company-details-wrap">
                            <h6>ಮೂಲಕ ಒಂದು ಉಪಕ್ರಮ <img src={require("../../images/keonics-logo-white2.png")} alt="logo" /></h6>
                        </div>
                    </div>
                    <div className="language-btn-wrap">
                        <Link to='#' onClick={() => setLanguage('en')} className="" > English </Link>
                        <Link to='#' onClick={() => setLanguage('ka')} className="" > ಕನ್ನಡ </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
