import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Label, Input, FormGroup, Button } from "reactstrap";
import { api } from "../../../Library/CustomApi";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";
import { ProfileValidationSchema } from "./profileValidation";
import TextError from "../../../Components/Errors/TextError";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";
import Loader from "../../../Components/Loader/Loader";

export default function VendorProfileSettings() {
  const { language, setLanguage } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [getprofile, setgetprofile] = useState({
    fname: "",
    email: "",
    mobile_number: "",
    ids: "",
    company_name: "",
    company_email: "",
    company_mobile_number: "",
    company_id: "",
    user_id: "",
  });

  const getdatas = () => {
    setLoading(true);
    api
      .get(`/profile`, true)
      .then(async function ([success, response]) {
        setgetprofile({
          fname: response[0]?.user_meta?.full_name,
          email: response[0]?.user?.email,
          ids: response[0]?.user_meta?.id_number,
          mobile_number: response[0]?.user_meta?.phone,
          company_name: response[0]?.company?.name,
          company_email: response[0]?.company?.email,
          company_mobile_number: response[0]?.company?.phone,
          company_id: response[0]?.company?.registration_id,
          user_id: response[0]?.user_id,
        });
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getdatas();
  }, []);

  const onchange = (e: any) => {
    setgetprofile({
      ...getprofile,
      fname: e?.target?.fname,
      email: e?.target?.email,
      ids: e?.target?.ids,
      mobile_number: e?.target?.mobile_number,
      company_name: e?.target?.company_name,
      company_email: e?.target?.company_email,
      company_mobile_number: e?.target?.company_mobile_number,
      company_id: e?.target?.company_id,
    });
  };
  const handleSubmit = (values: any, action: any) => {
    api
      .patch(
        `/profile/${getprofile?.user_id}`,
        {
          company_email: values?.company_email,
          registration_id: values?.company_id,
          company_phone: values?.company_mobile_number,
          company_name: values?.company_name,
          email: values?.email,
          name: values?.fname,
          id: values?.ids,
          phone: values?.mobile_number,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success(response?.message);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="profile-tab-content">
        <div className="table-outer-wrap ">
          <div className="main-wrap">
            <Formik
              initialValues={getprofile}
              enableReinitialize
              validationSchema={ProfileValidationSchema}
              onSubmit={(values, actions) => {
                // submit handler
                handleSubmit(values, actions);
              }}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                errors,
                touched,
                isSubmitting,
                handleReset,
                setFieldValue,
                setFieldError,
              }) => {
                return (
                  <>
                    {loading ? (
                      <div className="loading-overlay">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <section className="details">
                          <Form>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.full_name[language]}
                                  </Label>
                                  <Input
                                    id="exampleName"
                                    name="fname"
                                    placeholder="name"
                                    type="text"
                                    value={values?.fname}
                                    onChange={handleChange}
                                    disabled={values.fname ? true : false}
                                  />
                                  <ErrorMessage
                                    name="fname"
                                    component={TextError}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="examplePassword">
                                    {VendorRegLang?.id_number[language]}
                                  </Label>
                                  <Input
                                    id="examplePassword"
                                    name="ids"
                                    placeholder="Id Number"
                                    value={values?.ids}
                                    type="text"
                                    onChange={handleChange}
                                    disabled={values.ids ? true : false}
                                  />
                                  <ErrorMessage
                                    name="ids"
                                    component={TextError}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.email_id[language]}
                                  </Label>
                                  <Input
                                    id="exampleEmail"
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    value={values?.email}
                                    onChange={handleChange}
                                    disabled={values.email ? true : false}
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component={TextError}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="examplePassword">
                                    {VendorRegLang?.mobile_number[language]}
                                  </Label>
                                  <Input
                                    id="examplePassword"
                                    name="mobile_number"
                                    placeholder="Mobile Number"
                                    type="tel"
                                    value={values?.mobile_number}
                                    onChange={handleChange}
                                    disabled={
                                      values.mobile_number ? true : false
                                    }
                                  />
                                  <ErrorMessage
                                    name="mobile_number"
                                    component={TextError}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </section>
                        <section className="company">
                          <h3>{VendorRegLang?.company_details[language]}</h3>
                          <Form className="demo">
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.full_name[language]}
                                  </Label>
                                  <Input
                                    id="exampleEmail"
                                    name="company_name"
                                    placeholder=""
                                    type="text"
                                    value={values?.company_name}
                                    onChange={handleChange}
                                    disabled={
                                      values.company_name ? true : false
                                    }
                                  />
                                  <ErrorMessage
                                    name="company_name"
                                    component={TextError}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="examplePassword">
                                    {VendorRegLang?.id_number[language]}
                                  </Label>
                                  <Input
                                    id="examplePassword"
                                    name="company_id"
                                    placeholder=""
                                    type="text"
                                    value={values?.company_id}
                                    onChange={handleChange}
                                    disabled={values.company_id ? true : false}
                                  />
                                  <ErrorMessage
                                    name="company_id"
                                    component={TextError}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="exampleEmail">
                                    {VendorRegLang?.email_id[language]}
                                  </Label>
                                  <Input
                                    id="exampleEmail"
                                    name="company_email"
                                    placeholder=""
                                    type="email"
                                    value={values?.company_email}
                                    onChange={handleChange}
                                    disabled={
                                      values.company_email ? true : false
                                    }
                                  />
                                  <ErrorMessage
                                    name="company_email"
                                    component={TextError}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="examplePassword">
                                    {VendorRegLang?.mobile_number[language]}
                                  </Label>
                                  <Input
                                    id="examplePassword"
                                    name="company_mobile_number"
                                    placeholder=""
                                    type="tel"
                                    value={values?.company_mobile_number}
                                    onChange={handleChange}
                                    disabled={
                                      values.company_mobile_number
                                        ? true
                                        : false
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            {/* <div className="text-end">
                              <Button
                                color="primary"
                                className="btn btn-primary"
                                onClick={() => handleSubmit()}
                              >
                                {VendorRegLang?.submit[language]}
                              </Button>
                            </div>{" "} */}
                          </Form>
                        </section>
                      </>
                    )}
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
