import React, { useContext } from 'react'
import IndexEng from './English';
import IndexKanada from './Kanada/Index';
import { Context } from '../../Components/Context/Context';

export default function Indexs() {
    // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
  const { language, setLanguage } = useContext(Context);

    return (
        language == "en" ? <IndexEng/> : <IndexKanada/>
    )
}
