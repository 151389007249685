import { useContext, useState } from "react";
import {
  Button,
  Collapse,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  List,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Context } from "../../../../Components/Context/Context";
import { AdminRegLang } from "../../../../Library/LanguageConversion/AdminLang";
 
interface propType {
  setAdminSidebar: Function,
  openAdminSidebar: boolean,
  sidebarIsOpen: boolean,
  setSidebarIsOpen: Function
}
function Sidebar({ setAdminSidebar, openAdminSidebar, sidebarIsOpen, setSidebarIsOpen }: propType, args: any) {
  const { paneltoggle, setpaneltoggle } = args;
  let newDate = new Date();
  let date = moment(newDate).format("DD MMMM YYYY");
  let time = moment(newDate).format("hh:mm A");
  let user_name = localStorage.getItem("USER_NAME");
  const navigate = useNavigate();
  const location_details = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [active, setActive] = useState(false);
  const [data, setData] = useState();
  const [isOpenOrders, setIsOpenOrders] = useState(false);
  const { language, setLanguage } = useContext(Context);

  return (
    <>
      {/* desktop-sidebar */}
      <div className={`col-md-2 desktop-sidebar ${!paneltoggle ? "panel-none" : null}  ${sidebarIsOpen ? 'col-md-2' : 'minimized-sidebar-wrap'}  panel-block `}>
        <Col md={12} className="logo-outer">
          <div className="logo-inner-wrap">
            <a className="header-logo" href="#">
              {sidebarIsOpen ? <img
                src="/images/logo-white1.png"
                className="d-block"
                alt="icon"
              /> : <img src="../images/mob-logo.png"></img>}
            </a>
            <div className="minimise-sidebar">
              <span onClick={() => setSidebarIsOpen(!sidebarIsOpen)}><i className={`bi bi-arrow-${sidebarIsOpen ? 'left' : 'right'}-circle`}></i></span>
            </div>
          </div>
        </Col>

        <div className="sidebar-wrap">
          <List className="nav">
            <li
              className={
                location_details?.pathname == "/dashboard"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/dashboard"
                className="nav-link"
              >
                <i className="icons icons-overview" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}> {AdminRegLang?.dashboard[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname == "/empanelments" ||
                  location_details?.pathname == "/self-registered-vendors" ||
                  location_details?.pathname == "/active-vendors" ||
                  location_details?.pathname == "/verify-vendor" ||
                  location_details?.pathname == "/basic-details-vendor" ||
                  location_details?.pathname == "/advanced-details-vendor" ||
                  location_details?.pathname == "/doc-verification" ||
                  location_details?.pathname == "/expired-vendor" ||
                  location_details?.pathname == "/tobe-expired-vendor" ||
                  location_details?.pathname == "/inactive-vendor"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/empanelments"
                className="nav-link"
              >
                <i className="icons icons-empanelments"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.vendor_registration[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname == "/uploadproduct" ||
                  location_details?.pathname == "/products"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/products"
                className="nav-link"
              >
                <i className="icons icons-products" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.products[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname == "/add-tendor"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/add-tendor"
                className="nav-link"
              >
                <i className="icons icons-tender"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.tender[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname == "/rate" ||
                  location_details?.pathname == "/rate-contract" ||
                  location_details?.pathname == "/specifications" ||
                  location_details?.pathname == "/rate-contract-assigned" ||
                  location_details?.pathname == "/product-comparison"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/rate"
                className="nav-link"
              >
                <i className="icons icons-upload-rate" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.rate_contract[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            {/* <li className={
              location_details?.pathname == "/received-orders" ||
                location_details?.pathname == "/processed-orders" ||
                location_details?.pathname == "/rejected-orders" ||
                location_details?.pathname == "/confirmed-orders" ||
                location_details?.pathname == "/cancelled-orders" ||
                location_details?.pathname == "/approved-orders"
                ? "nav-item active"
                : "nav-item"
            }>
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/received-orders"
                className="nav-link"
              >
                <i className="icons icons-order"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>Orders</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li> */}
            <li className="nav-item dropdown">
              <Link
                to="#"
                onClick={() => setIsOpenOrders(!isOpenOrders)}
                className={`nav-link ${sidebarIsOpen ? 'dropdown-toggle' : 'dropdown-toggle'} ${isOpenOrders ? 'link-toggle-open' : 'link-toggle-close'}`}
              >
                <i className="icons icons-order"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.orders[language]}</span>
              </Link>
              <Collapse isOpen={isOpenOrders} {...args}>
                <List id="sidebar-collapse01">
                  <li className={
                    location_details?.pathname == "/received-orders" ||
                      location_details?.pathname == "/processed-orders" ||
                      location_details?.pathname == "/rejected-orders" ||
                      location_details?.pathname == "/confirmed-orders" ||
                      location_details?.pathname == "/cancelled-orders" ||
                      location_details?.pathname == "/drafted-orders" ||
                      location_details?.pathname == "/approved-orders"
                      ? "sub-menu active"
                      : "sub-menu"
                  }
                  >
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/received-orders"
                      className="nav-link"
                    >
                      <i className="icons icons-orders-regular"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.regular_orders[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li>
                  <li className={
                    location_details?.pathname == "/custom-orders"
                      ? "sub-menu active"
                      : "sub-menu "
                  }>
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/custom-orders"
                      className="nav-link"
                    >
                      <i className="icons icons-orders-custom"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.custom_orders[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li>
                </List>
              </Collapse>
            </li>
            <li
              className={
                location_details?.pathname == "/delivered-orders" ||
                  location_details?.pathname == "/orders-cancelled"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/delivered-orders"
                className="nav-link"
              >
                <i className="icons icons-delivered" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.delivered[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname == "/tds-list" ||
                  location_details?.pathname == "/tds-to-vendor-list"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/tds-list" 
                className="nav-link"
              >
                <i className="icons icons-tds" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.tds[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li className={
              location_details?.pathname == "/payment-received" ||
              location_details?.pathname == "/payment-to-vendors" ||
              location_details?.pathname == "/cancelled-payment"
                ? "nav-item active"
                : "nav-item"
            }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/payment-received"
                className="nav-link"
              >
                <i className="icons icons-payments"></i>

                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.payments[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname == "/departments" ||
                  location_details?.pathname == "/department/list"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/departments"
                className="nav-link"
              >
                <i className="icons icons-department-list" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.departments[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li className="nav-item dropdown">
              <Link
                to="#"
                onClick={() => setIsOpen(!isOpen)}
                className={`nav-link ${sidebarIsOpen ? 'dropdown-toggle' : 'dropdown-toggle'} ${isOpen ? 'link-toggle-open' : 'link-toggle-close'}`}
              >
                <i className="icons icons-services"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.services[language]}</span>
              </Link>
              {/* <a className="nav-link text-white" data-bs-toggle="collapse" href="#sidebar-collapse01" role="button" aria-expanded="false" aria-controls="sidebar-collapse01"></Button> */}
              <Collapse isOpen={isOpen} {...args}>
                <List id="sidebar-collapse01">
                  {/* <li className={
                    location_details?.pathname == "/open-tickets" ||
                      location_details?.pathname == "/closed-tickets" ||
                      location_details?.pathname == "/pending-tickets"
                      ? "sub-menu active"
                      : "sub-menu"
                  }
                  >
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/open-tickets"
                      className="nav-link"
                    >
                      <i className="icons icons-tickets"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.tickets[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li> */}
                  {/* <li className={
                    location_details?.pathname == "/amc"
                      ? "sub-menu active"
                      : "sub-menu "
                  }>
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/amc"
                      className="nav-link"
                    >
                      <i className="icons icons-amc"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.amc[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li> */}

                  {/* qa-vendor  */}
                  <li className={
                    location_details?.pathname == "/qa-vendor"
                      ? "sub-menu active"
                      : "sub-menu "
                  }>
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/qa-vendor"
                      className="nav-link"
                    >
                      <i className="icons icons-list"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.third_party_inspection[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li>

                </List>
              </Collapse>
            </li>
            <li className={
              location_details?.pathname == "/addr-verifications" ||
                location_details?.pathname == "/department-verifications" ||
                location_details?.pathname == "/account-verifications" ||
                location_details?.pathname == "/draft-verifications"
                ? "nav-item active"
                : "nav-item"
            }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/department-verifications"
                className="nav-link"
              >
                <i className="icons icons-verifications"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.verification[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname == "/accounts"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/accounts"
                className="nav-link"
              >
                <i className="icons icons-accounts" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.accounts[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
        
            <li
              className={
                location_details?.pathname == "/reports"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/reports"
                className="nav-link"
              >
                <i className="icons icons-report" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.reports[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li className={
              location_details?.pathname == "/profile-settings"
                ? "nav-item active"
                : "nav-item"
            }

            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/profile-settings"
                className="nav-link"
              >
                <i className="icons icons-settings"></i>

                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.Settings[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
          </List>
        </div>
      </div>

      {/*mobile sidebar */}

      <Offcanvas
        isOpen={openAdminSidebar}
        scrollable
        className="mobile-sidebar"
      >
        <OffcanvasHeader toggle={() => setAdminSidebar(false)}>
          <a className="navbar-brand" href="#">
            <img src="/images/logo-white-ktipp.png" alt="icon" />
          </a>
        </OffcanvasHeader>

        <OffcanvasBody>
          <div className="offcanvas-body">
            <div className="sidebar-wrap">
              <List className="nav">
                <li
                  className={
                    location_details?.pathname == "/dashboard"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/dashboard"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-overview" /> {AdminRegLang?.dashboard[language]} 
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/uploadproduct" ||
                      location_details?.pathname == "/products"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/products"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-products" />
                    {AdminRegLang?.products[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/add-tendor"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/add-tendor"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-tender" />
                    {AdminRegLang?.tender[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>

                <li
                  className={
                    location_details?.pathname == "/empanelments" ||
                      location_details?.pathname == "/self-registered-vendors" ||
                      location_details?.pathname == "/active-vendors" ||
                      location_details?.pathname == "/verify-vendor" ||
                      location_details?.pathname == "/basic-details-vendor" ||
                      location_details?.pathname == "/advanced-details-vendor" ||
                      location_details?.pathname == "/doc-verification" ||
                      location_details?.pathname == "/expired-vendor" ||
                      location_details?.pathname == "/tobe-expired-vendor" ||
                      location_details?.pathname == "/inactive-vendor"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/empanelments"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-empanelments" />

                    {AdminRegLang?.vendor_registration[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/rate" ||
                      location_details?.pathname == "/rate-contract" ||
                      location_details?.pathname == "/specifications" ||
                      location_details?.pathname == "/rate-contract-assigned" ||
                      location_details?.pathname == "/product-comparison"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/rate"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-upload-rate" />
                    {AdminRegLang?.rate_contract[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                {/* <li className={
                  location_details?.pathname == "/received-orders" ||
                    location_details?.pathname == "/processed-orders" ||
                    location_details?.pathname == "/rejected-orders" ||
                    location_details?.pathname == "/confirmed-orders" ||
                    location_details?.pathname == "/cancelled-orders" ||
                    location_details?.pathname == "/approved-orders"
                    ? "nav-item active"
                    : "nav-item"
                }>
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/received-orders"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-order"></i>Orders
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li> */}


                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    onClick={() => setIsOpenOrders(!isOpenOrders)}
                    className={`nav-link ${sidebarIsOpen ? 'dropdown-toggle' : 'dropdown-toggle'} ${isOpenOrders ? 'link-toggle-open' : 'link-toggle-close'}`}
                  >
                    <i className="icons icons-order"></i>
                    <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.orders[language]}</span>
                  </Link>
                  <Collapse isOpen={isOpenOrders} {...args}>
                    <List id="sidebar-collapse01">
                      <li className={
                        location_details?.pathname == "/received-orders" ||
                          location_details?.pathname == "/processed-orders" ||
                          location_details?.pathname == "/rejected-orders" ||
                          location_details?.pathname == "/confirmed-orders" ||
                          location_details?.pathname == "/cancelled-orders" ||
                          location_details?.pathname == "/approved-orders"
                          ? "sub-menu active"
                          : "sub-menu"
                      }
                      >
                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link
                          to="/received-orders"
                          className="nav-link"
                          onClick={() => setAdminSidebar(false)}
                        >
                          <i className="icons icons-orders-regular"></i>
                          <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}
                          >{AdminRegLang?.regular_orders[language]}
                          </span>
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li>
                      <li className={
                        location_details?.pathname == "/custom-orders"
                          ? "sub-menu active"
                          : "sub-menu "
                      }>
                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link
                          to="/custom-orders"
                          className="nav-link"
                          onClick={() => setAdminSidebar(false)}
                        >
                          <i className="icons icons-orders-custom"></i>
                          <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}
                          >{AdminRegLang?.custom_orders[language]}
                          </span>
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li>
                    </List>
                  </Collapse>
                </li>
                <li
                  className={
                    location_details?.pathname == "/delivered-orders" ||
                      location_details?.pathname == "/orders-cancelled"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/delivered-orders"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-delivered" />
                    {AdminRegLang?.delivered[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>

                <li className={
                  location_details?.pathname == "/tds-list" || 
                  location_details?.pathname == "/tds-to-vendor-list"
                    ? "nav-item active"
                    : "nav-item"
                }

                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/tds-list"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-settings"></i>

                    <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.tds[language]}</span>
                  </Link> 
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                
                <li className={
                  location_details?.pathname == "/payment-received" ||
                  location_details?.pathname == "/payment-to-vendors" ||
                  location_details?.pathname == "/cancelled-payment"
                    ? "nav-item active"
                    : "nav-item"
                }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/payment-received"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-settings"></i>

                    <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.payments[language]}</span>
                  </Link> 
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                
                <li className={
                  location_details?.pathname == "/departments"
                    ? "nav-item active"
                    : "nav-item"
                }

                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/departments"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-department-list"></i>{AdminRegLang?.departments[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>

                <li className="nav-item">
                  <Link
                    to="#"
                    onClick={() => setIsOpen(!isOpen)}
                    className="nav-link dropdown-toggle"
                  >
                    <i className="icons icons-services"></i>
                    {AdminRegLang?.services[language]}
                  </Link>
                  {/* <a className="nav-link text-white" data-bs-toggle="collapse" href="#sidebar-collapse01" role="button" aria-expanded="false" aria-controls="sidebar-collapse01"></a> */}
                  <Collapse isOpen={isOpen} {...args}>
                    {/* <List id="sidebar-collapse01">
                      <li className={
                        location_details?.pathname == "/open-tickets" ||
                          location_details?.pathname == "/closed-tickets" ||
                          location_details?.pathname == "/pending-tickets"
                          ? "sub-menu active"
                          : "sub-menu"
                      }
                      >
                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link
                          to="/open-tickets"
                          className="nav-link"
                          onClick={() => setAdminSidebar(false)}
                        >
                          <i className="icons icons-tickets"></i>
                          Tickets
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li> */}

                      <li className={
                        location_details?.pathname == "/qa-vendor"
                          ? "sub-menu active"
                          : "sub-menu "
                      }>
                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link
                          to="/qa-vendor"
                          className="nav-link"
                          onClick={() => setAdminSidebar(false)}
                        >
                          <i className="icons icons-list"></i>
                          {AdminRegLang?.third_party_inspection[language]}
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li>

                      {/* <li className={
                        location_details?.pathname == "/amc"
                          ? "sub-menu active"
                          : "sub-menu "
                      }>
                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link
                          to="/amc"
                          className="nav-link"
                          onClick={() => setAdminSidebar(false)}
                        >
                          <i className="icons icons-amc"></i>
                          {AdminRegLang?.amc[language]}
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li> */}
                    {/* </List>*/}
                  </Collapse> 
                </li>
                
                <li className={
                  location_details?.pathname == "/addr-verifications" ||
                    location_details?.pathname == "/department-verifications" ||
                    location_details?.pathname == "/account-verifications" ||
                    location_details?.pathname == "/draft-verifications"
                    ? "nav-item active"
                    : "nav-item"
                }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/department-verifications"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-verifications"></i>
                    {AdminRegLang?.verification[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
              className={
                location_details?.pathname == "/accounts"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/accounts"
                className="nav-link"
                onClick={() => setAdminSidebar(false)}
              >
                <i className="icons icons-accounts" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{AdminRegLang?.accounts[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
              
                <li className={
                  location_details?.pathname == "/reports"
                    ? "nav-item active"
                    : "nav-item"
                }

                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/reports"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-report"></i>{AdminRegLang?.reports[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li className={
                  location_details?.pathname == "/profile-settings"
                    ? "nav-item active"
                    : "nav-item"
                }

                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/profile-settings"
                    className="nav-link"
                    onClick={() => setAdminSidebar(false)}
                  >
                    <i className="icons icons-settings"></i>{AdminRegLang?.Settings[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
              </List>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}
export default Sidebar;