import React, { useEffect, useContext } from "react";
import { useState } from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Formik } from "formik";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import Loader from "../../../../Components/Loader/Loader";
import { api } from "../../../../Library/CustomApi";
import AssignModal from "../Modal/AssignModal";
import AssignValidationSchema from "../Validation/AssignValidation";
import constants from "../../../../Library/Constants/Constants";
import axios from "axios";
import { API_URL } from "../../../../Library/ApiSettings";
import Swal from "sweetalert2";
import { Context } from "../../../../Components/Context/Context";
import { Admin_RateContract } from "../../../../Library/LanguageConversion/Admin_RateContract";

export default function RateAssigned() {
  const {language, setLanguage}= useContext(Context)
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { rate_id, setRateId } = useContext(Context);
  const [isShowing, setIsShowing] = useState(false);
  const [rate_contract, setRateContract] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [pro_id, setProId] = useState([]);
  const [value, setValue] = useState({
    cat_value: "",
  });
  const [date, setDate] = useState({
    validfrom: "",
    validto: "",
  });
  const [make, setMake] = useState([]);
  const [pageData, setPageData] = useState<any>({
    total: "",
    per_page: "",
    currentPage: "",
    from: "",
    to: "",
  });
  const location = useLocation();
  const tender_ids =
    location?.state?.id ||
    location?.state?.data ||
    location?.state?.t_data ||
    location?.state?.new_data || rate_id;
  const tend_data = localStorage.getItem("Tend_id");

  // ========================================================== Assign vendors ==========================================================

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getRateContract(tender_ids);
        getCategory(tend_data);
      }
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [search, tender_ids, currentPage, value, tend_data]);

  const handleSubmit = (values: any, actions: any) => {
    assignVendors(values, actions);
  };

  const assignVendors = (values: any, actions: any) => {
    let low: any = [];
    low.push({
      low1: {
        vendor_id: values?.low_1?.value,
        manufacturer_id: values?.low_1_make?.value || null,
        model_id: values?.low_1_model?.value || null,
      },

      low2: {
        vendor_id: values?.low_2?.value || null,
        manufacturer_id: values?.low_2_make?.value || null,
        model_id: values?.low_2_model?.value || null,
      },
      low3: {
        vendor_id: values?.low_3?.value || null,
        manufacturer_id: values?.low_3_make?.value || null,
        model_id: values?.low_3_model?.value || null,
      },
    });
    api
      .post(
        `/ratecontract`,
        {
          tender_id: tender_ids,
          from_date: date?.validfrom,
          to_date: date?.validto,
          price: values?.price,
          product_id: pro_id,
          low: low,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Tender created Successfully");
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
        }
        getRateContract(tender_ids);

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //============================================================ get manufacturer =====================================================

  const getManufacturer = (id: any) => {
    api
      .post(
        `/ratecontractmanufacturer`,
        {
          product_id: id,
        },
        true
      )
      .then(async function ([success, response]) {
        let datas: any = [];
        response?.data?.map((elem: any) => {
          datas.push({ value: elem?.id, label: elem?.name });
        });
        setMake(datas || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //========================================================= Search ==================================================================
  const handleSearch = (event: any) => {
    setCurrentPage(1)
    setSearch(event.target.value);
  };

  //========================================================== Get Category ============================================================
  const getCategory = (tender_id: any) => {
    api
      .get(`/ratecontractcat?tender_id=${tender_id}`, true)
      .then(async function ([success, response]) {
        setCategory(response?.data || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //=========================================================== Get Vendors==============================================================

  const getVendors = () => {
    api
      .get(`/ratecontractvendors`, true)
      .then(async function ([success, response]) {
        let datas: any = [];
        response?.data?.map((elem: any) => {
          datas.push({ value: elem?.id, label: elem?.full_name });
        });
        setVendors(datas || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //============================================================= Category ===========================================================

  const handlecategory = (e: any) => {
    setCurrentPage(1)
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  // ======================================================== Get Rate contract ========================================================

  const getRateContract = (tender_id: any) => {
    setIsLoading(true);
    api
      .get(
        `/ratecontract?tender_id=${tender_id}&page=${currentPage}&search_keyword=${search}&category_filter=${value?.cat_value}&assigned=${constants.RATE_DATA.ASSIGNED}`,
        true
      )
      .then(async function ([success, response]) {
        setRateContract(response?.message?.data || []);
        // setCategory(response?.message[0]?.productcategory || []);
        setPageData({
          ...pageData,
          total: response?.message?.total,
          per_page: response?.message?.per_page,
          currentPage: response?.message?.current_page,
          from: response?.message?.from,
          to: response?.message?.to,
        });
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  // ============================================== Navigate to Specification  ======================================================

  const go_url = (product_id: string) => {
    navigate("/specifications", {
      state: {
        id: product_id,
        tender_id: tender_ids,
      },
    });
  };
  //=============================================== Rate contract verification ====================================================
  const rateVerify = (id: any) => {
    api
      .post(
        `/approverate`,
        {
          rate_id: id,
        },
        true
      )
      .then(async function ([success, response]) {
        getRateContract(tender_ids);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function deleteRate(id: number) {
    Swal.fire({
      title: "Are you sure you want to delete this rate contract?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  }

  const handleDelete = async (id: number) => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      };
      const res = await axios.delete(`${API_URL}/ratecontract/${id}`, {
        headers,
      });
      toast.success("Rate Contract deleted successfully");
      getRateContract(tender_ids);
    } catch (error) {
      console.log(error);
    }
  };
  function truncateText(text: any) {
    return text.replace(/<br>/g, ", ");
  }
  function limitText(text: any, limit: any) {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    } else {
      return text;
    }
  }
  // ========================================================== View ================================================================

  return (
    <>
      <React.Fragment>
        {/* inner contents starts here */}
        <div className="right-content-wrap">
          {/* tabs and bradcrumbs flex */}
          <div className="breadcrumb-tabs-outer-wrap">
            {/* tabs */}

            <div className="custom-tabs ">
              <NavLink to="/rate-contract" state={{ t_data: tender_ids }}>
                {Admin_RateContract?.Unassigned?.[language]}
              </NavLink>
              <NavLink
                to="/rate-contract-assigned"
                state={{ t_data: tender_ids }}
              >
                {Admin_RateContract?.Assigned?.[language]}
              </NavLink>
            </div>
            <div className="custom-tabs "></div>
            {/* breadcrumb */}
            <List className="breadcrumb">
              <li>
                <Link to="/dashboard" className="active">
                  {Admin_RateContract?.Dashboard?.[language]}
                </Link>
              </li>
              &nbsp; / &nbsp;
              <li>
                <Link to="/rate" className="active">
                  {Admin_RateContract?.Upload_Rate?.[language]}
                </Link>
              </li>
              &nbsp; / &nbsp;
              <li>
                <b>{Admin_RateContract?.Rate_Contract?.[language]}</b>
              </li>
            </List>
          </div>
          <div className="custom-tab-content">
            <div className="table-outer-wrap">
              <div className="search-widget-outer">
                <Form role="search">
                  <InputGroup className="search-group">
                    <InputGroupText title="Product Name" className="search-icon" id="search">
                      <i className="fa-sharp fa-solid fa-magnifying-glass" />
                    </InputGroupText>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={Admin_RateContract?.Product_Name?.[language]}
                      aria-label="Username"
                      aria-describedby="search"
                      value={search}
                      onChange={handleSearch}
                      onKeyPress={(event) => {
                        var key = event.keyCode || event.which;
                        if (key === 13) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                </Form>

                <div className="select-filter" title="Click to Select Category">
                  <Input
                    type="select"
                    name="cat_value"
                    value={value?.cat_value}
                    data={category}
                    onChange={handlecategory}
                  >
                    <option value="">{Admin_RateContract?.Select_Category?.[language]}</option>;
                    {category?.map((item: any, i: number) => {
                      return (
                        <React.Fragment key={item?.id}>
                          <option key={i} value={item?.id}>
                            {" "}
                            {item?.name}
                          </option>
                        </React.Fragment>
                      );
                    })}
                  </Input>
                </div>
              </div>
              <div className="table-wrap responsive-table">
                <Table className="w-100 table-striped">
                  <thead>
                    <tr>
                      <th className="">{Admin_RateContract?.Sl_No?.[language]}</th>
                      <th className="">{Admin_RateContract?.Product_Name?.[language]}</th>
                      <th className="">{Admin_RateContract?.Product_Specifications?.[language]}</th>
                      <th className="">
                        {Admin_RateContract?.Cost_Price?.[language]}
                        <br />
                        <small>({Admin_RateContract?.Excluding_GST?.[language]})</small>
                      </th>
                      <th className="">
                        {Admin_RateContract?.Sales_Price?.[language]}
                        <br />
                        <small>(({Admin_RateContract?.Excluding_GST?.[language]}))</small>
                      </th>
                      <th className=""> {Admin_RateContract?.Valid_From?.[language]}</th>
                      <th className="me-4">&#160;&#160;&#160;{Admin_RateContract?.Valid_To?.[language]}&#160;&#160;&#160;</th>
                      <th className="">L1</th>
                      <th className="">L2</th>
                      <th className="">L3</th>
                      <th className="">{Admin_RateContract?.Action?.[language]}</th>
                      <th className="">{Admin_RateContract?.Status?.[language]}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rate_contract?.length !== 0 ? (
                      <>
                        {rate_contract?.map((item: any, i: number) => {
                          return (
                            <React.Fragment key={i}>
                              <tr>
                                <td className="">
                                  <span className="mobile-heading">{Admin_RateContract?.Sl_No?.[language]}:</span>
                                  <span className="table-data">{i + 1}</span>
                                </td>

                                <td
                                  onClick={() => {
                                    go_url(item?.id);
                                  }}
                                >
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Product_Name?.[language]}:
                                  </span>
                                  <span className="table-data">
                                    <span className="text-success">
                                      <Link to="#" className="primary-text" title="View Product Details">
                                        {item?.name}
                                      </Link>
                                    </span>
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Product_Specifications?.[language]}:
                                  </span>
                                  <span className="table-data">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: truncateText(
                                          limitText(item?.features, 100)
                                        ),
                                      }}
                                    ></p>
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">{Admin_RateContract?.Cost_Price?.[language]}:</span>
                                  <span className="table-data">
                                    {item?.rate_contract_data?.price ? (
                                      <>
                                        <i className="fa fa-inr"></i>
                                        {item?.rate_contract_data?.price}
                                      </>
                                    ) : (
                                      <i className="fa-solid fa-hand-holding-dollar text-danger lg"></i>
                                    )}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">{Admin_RateContract?.Sales_Price?.[language]}:</span>
                                  <span className="table-data">
                                    {item?.rate_contract_data?.price ? (
                                      <>
                                        <i className="fa fa-inr"></i>
                                        {item?.rate_contract_data?.price + (item?.rate_contract_data?.price * 0.05)}
                                      </>
                                    ) : (
                                      <i className="fa-solid fa-hand-holding-dollar text-danger lg"></i>
                                    )}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Valid_From?.[language]}:
                                  </span>
                                  <span className="table-data">
                                    {moment(
                                      item?.tendor_datas?.valid_from
                                    ).format("DD-MM-YYYY")}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Valid_To?.[language]}:
                                  </span>
                                  <span className="table-data">
                                    {moment(
                                      item?.tendor_datas?.valid_to
                                    ).format("DD-MM-YYYY")}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">L1:</span>
                                  <span className="table-data">
                                    {item?.tendervendors[0]?.name ? (
                                      item?.tendervendors[0]?.name
                                    ) : (
                                      <i className="fa-regular fa-circle-xmark text-danger lg"></i>
                                    )}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">L2:</span>
                                  <span className="table-data">
                                    {item?.tendervendors[1]?.name ? (
                                      item?.tendervendors[1]?.name
                                    ) : (
                                      <i className="fa-regular fa-circle-xmark text-danger lg"></i>
                                    )}
                                  </span>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">L3:</span>
                                  <span className="table-data">
                                    {item?.tendervendors[2]?.name ? (
                                      item?.tendervendors[2]?.name
                                    ) : (
                                      <i className="fa-regular fa-circle-xmark text-danger lg"></i>
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    Action {Admin_RateContract?.Action?.[language]}:
                                  </span>
                                  <span className="table-data text-success">
                                    {item?.rate_contract_data?.price && !(item?.rate_contract_data?.status ==
                                      constants.RATE_CONTRACT.PENDING) ? (
                                      <> {Admin_RateContract?.Assigned?.[language]}</>
                                    ) : (
                                      null
                                      // <Button
                                      //   type="button"
                                      //   color="warning"
                                      //   onClick={() => {
                                      //     setIsShowing(true);
                                      //     setDate({
                                      //       validfrom:
                                      //         item?.tendor_datas?.valid_from,
                                      //       validto:
                                      //         item?.tendor_datas?.valid_to,
                                      //     });
                                      //     getVendors();
                                      //     getManufacturer(item?.id);
                                      //     setProId(item?.id);
                                      //   }}
                                      // >
                                      //   Assign
                                      // </Button>
                                    )}
                                  </span>
                                  {item?.rate_contract_data?.status ==
                                    constants.RATE_CONTRACT.PENDING && <div className="assign-icon-wrap">
                                      {item?.rate_contract_data?.status ==
                                        constants.RATE_CONTRACT.PENDING ? (
                                        <span className="assign-flex-btn">
                                          <Button
                                            type="button"
                                            color="success"
                                            title="Approve"
                                            onClick={() =>
                                              rateVerify(
                                                item?.rate_contract_data?.id
                                              )
                                            }
                                          >
                                            <i className="fa-solid fa-check"></i>
                                          </Button>
                                        </span>
                                      ) : null}
                                      {item?.rate_contract_data?.status ==
                                        constants.RATE_CONTRACT.PENDING ? (
                                        <span className="assign-flex-btn">
                                          <Button
                                            type="button"
                                            color="danger"
                                            title="Reject"
                                            onClick={() =>
                                              deleteRate(
                                                item?.rate_contract_data?.id
                                              )
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </Button>
                                        </span>
                                      ) : null}
                                    </div>}

                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    {Admin_RateContract?.Status?.[language]}:
                                  </span>
                                  <span className="table-data">
                                    {item?.rate_contract_data?.status ==
                                      constants.RATE_CONTRACT.PENDING ? (
                                      <span className="pending-status">
                                        {Admin_RateContract?.Pending?.[language]}
                                      </span>
                                    ) : item?.rate_contract_data?.status ==
                                      constants.RATE_CONTRACT.APPROVED ? (
                                      <span className="approved-status">
                                        {Admin_RateContract?.Approved?.[language]}
                                      </span>
                                    ) : (
                                      <span className="inactive-status">{Admin_RateContract?.Not_Assigned?.[language]}</span>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td
                          className="text-center px-2 py-5 empty-text"
                          colSpan={11}
                        >
                          {isLoading ? (
                            <div className="d-flex justify-content-center">
                              <Loader />
                            </div>
                          ) : (
                            "No Product Found"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {pageData && rate_contract?.length !== 0 && (
                <div className="table-footer-wrap">
                  <small>
                  {Admin_RateContract?.Showing?.[language]} {pageData.from} {Admin_RateContract?.to?.[language]} {pageData.to} {Admin_RateContract?.of?.[language]} {pageData.total}{" "}
                  {Admin_RateContract?.entries?.[language]}
                  </small>
                  <div className="pagination">
                    <CustomPagination
                      totalItems={parseInt(pageData?.total)}
                      itemsPerPage={parseInt(pageData?.per_page)}
                      activePage={parseInt(pageData?.currentPage)}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}

              <Formik
                initialValues={{
                  low_1: "",
                  low_1_make: "",
                  low_1_model: "",
                  low_2: "",
                  low_2_make: "",
                  low_2_model: "",
                  low_3: "",
                  low_3_make: "",
                  low_3_model: "",
                  valid_from: "",
                  valid_to: "",
                  price: "",
                  Onsite_warranty: "",
                  service_warranty: "",
                  hardware_warranty: "",
                }}
                validationSchema={AssignValidationSchema}
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions);
                  // console.log(values);
                }}
              >
                {({
                  handleSubmit,
                  values,
                  handleChange,
                  errors,
                  touched,
                  isSubmitting,
                  handleReset,
                  handleBlur,
                  setFieldValue,
                }) => {
                  return (
                    <AssignModal
                      isShowing={isShowing}
                      setIsShowing={setIsShowing}
                      handleSubmit={handleSubmit}
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      isSubmitting={isSubmitting}
                      handleReset={handleReset}
                      handleBlur={handleBlur}
                      date={date}
                      vendors={vendors}
                      setFieldValue={setFieldValue}
                      make={make}
                    />
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}
