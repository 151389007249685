import React, {useContext} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

export default function PaymentLayout(props: any) {
    const { pathname } = useLocation();
    const { language, setLanguage } = useContext(Context);
    return (
        <React.Fragment>
            <div className="right-content-wrap">
                <div className="breadcrumb-tabs-outer-wrap">
                    {/* tabs */}
                    <div className="custom-tabs">
                        <NavLink to="/payment-vendor">{VendorRegLang?.payment_pending[language]}</NavLink>
                        <NavLink to="/payment-received-vendor">{VendorRegLang?.payment_received[language]}</NavLink>
                    </div> 
                    {/* breadcrumb */}
                    <List className=" breadcrumb">
                        <li>
                        <Link className="active" to="/overview-vendor">
                            {VendorRegLang?.dashboard[language]}
                            </Link>
                        </li>
                        &nbsp; / &nbsp;
                        <li>
                            <b>
                                {pathname == "/payment-received-vendor"
                                    ? "Payment Received"
                                    : pathname == "/payment-vendor"
                                        ? "Payment Pending"
                                        : null}
                            </b>
                        </li>
                    </List>
                </div>
                <div className="custom-tab-content">
                    <div className="table-outer-wrap">{props.children}</div>
                </div>
            </div>
        </React.Fragment>
    );
}