import { useState, useContext } from "react";
import {
  Collapse,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Col, List
} from "reactstrap";

import { Link, matchPath } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Context } from "../../../../Components/Context/Context";
import { DeptRegLang } from "../../../../Library/LanguageConversion/DepartmentLang";

interface proptype {
  setOpenSidebar: Function,
  openSidebar: boolean,
  setSidebarIsOpen: Function,
  sidebarIsOpen: boolean
}
function DeptSidebar({ openSidebar, setOpenSidebar, sidebarIsOpen, setSidebarIsOpen }: proptype) {
  const location_details = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOrdersOpen, setIsOrdersOpen] = useState(false);
  const { theme, toggleTheme, resetFilterHandler } = useContext(Context);
  const { language, setLanguage } = useContext(Context);

  return (
    <>
      {/* desktop-sidebar */}
      <div
        className={`${sidebarIsOpen ? 'col-md-2' : 'minimized-sidebar-wrap'} desktop-sidebar panel-block`}
      >
        <Col md={12} className="logo-outer">
          <div className="logo-inner-wrap">
            <a className="header-logo" href="#">
              {sidebarIsOpen ? <img
                src="/images/logo-white.png"
                className="d-block"
                alt="icon"
              /> : <img src="../images/mob-logo.png"></img>}
            </a>
            <div className="minimise-sidebar">
              <span onClick={() => setSidebarIsOpen(!sidebarIsOpen)}><i className={`bi bi-arrow-${sidebarIsOpen ? 'left' : 'right'}-circle`}></i></span>
              {/* <p onClick={()=>toggleTheme()}>{theme=='theme-light'?'Dark':'Light'}</p> */}
            </div>
          </div>
        </Col>
        <div className="sidebar-wrap dept-sidebar">
          <List className="nav">
            <li className={location_details?.pathname === "/department/dashboard"
              ? "nav-item active"
              : "nav-item"
            }>
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/department/dashboard"
                title="Dashboard"
                className="nav-link"
              >
                <i className="icons icons-dashboard" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.dashboard[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname.includes("/department/products") ||
                  location_details?.pathname.includes("/department/products-view")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/department/products/1"
                title="Products"
                className="nav-link"
                onClick={() => resetFilterHandler()}
              >
                <i className="icons icons-products" />
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.products[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>

            {/* orders  */}
            <li className="nav-item dropdown">
              <Link
                to="#"
                title="Orders"
                color="link"
                onClick={() => {
                  setIsOrdersOpen(!isOrdersOpen)
                }}
                className={`nav-link text-white text-start ${sidebarIsOpen ? 'dropdown-toggle' : 'dropdown-toggle'} ${isOrdersOpen ? 'link-toggle-open' : 'link-toggle-close'}`}
              >
                <i className="icons icons-order"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.orders[language]}</span>
              </Link>
              <Collapse isOpen={isOrdersOpen} >
                <List id="sidebar-collapse01">
                  <li
                    className={
                      location_details?.pathname === "/department/placed-orders" ||
                        location_details?.pathname === "/department/processing-orders" ||
                        location_details?.pathname === "/department/orders" ||
                        location_details?.pathname === "/department/payment-pending-orders" ||
                        location_details?.pathname === "/department/cancelled-orders" ?
                        "sub-menu active"
                        : "sub-menu"
                    }
                  >
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/department/placed-orders"
                      title="Regular Orders"
                      className="nav-link"
                    >
                      <i className="icons icons-orders-regular"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.regular_orders[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li>
                  <li
                    className={
                      location_details?.pathname === "/department/custom-orders"
                        ? "sub-menu active"
                        : "sub-menu"
                    }
                  >
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/department/custom-orders"
                      title="Custom Orders"
                      className="nav-link"
                    >
                      <i className="icons icons-orders-custom"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.custom_orders[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li>
                </List>
              </Collapse>
            </li>
            {/* TDS */}
            <li
              className={
                location_details?.pathname == "/department/tds" ?
                  "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/department/tds"
                title="TDS"
                className="nav-link"
              >
                <i className="icons icons-tds"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.tds[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>

            {/* services  */}
            {/* <li className="nav-item dropdown">
              <Link
                to="#"
                title="Services"
                color="link"
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
                className={`nav-link text-white text-start ${sidebarIsOpen ? 'dropdown-toggle' : 'dropdown-toggle'} ${isOpen ? 'link-toggle-open' : 'link-toggle-close'}`}
              >
                <i className="icons icons-services"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.services[language]}</span>
              </Link>
              <Collapse isOpen={isOpen} >
                <List id="sidebar-collapse01">
                  <li
                    className={
                      location_details?.pathname === "/department/tickets" ||
                        location_details?.pathname === "/department/pending-tickets" ||
                        location_details?.pathname === "/department/resolved-tickets" ?
                        "sub-menu active"
                        : "sub-menu"
                    }
                  >
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/department/tickets"
                      title="Tickets"
                      className="nav-link"
                    >
                      <i className="icons icons-tickets"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.tickets[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li>
                  <li
                    className={
                      location_details?.pathname === "/department/amc"
                        ? "sub-menu active"
                        : "sub-menu"
                    }
                  >
                    <span className="curve-before">
                      <span className="curve-inner"></span>
                    </span>
                    <Link
                      to="/department/amc"
                      title="AMC"
                      className="nav-link"
                    >
                      <i className="icons icons-amc"></i>
                      <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.amc[language]}</span>
                    </Link>
                    <span className="curve-after">
                      <span className="curve-inner"></span>
                    </span>
                  </li>
                </List>
              </Collapse>
            </li> */}
            <li
              className={
                location_details?.pathname == "/department/draft" || location_details?.pathname === "/department/workorder" ?
                  "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/department/draft"
                title="Drafts/Work Order"
                className="nav-link"
              >
                <i className="icons icons-drafts"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.drafts_workorder[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname === "/department/delivery-address" ?
                  "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/department/delivery-address"
                title="Delivery Address"
                className="nav-link"
              >
                <i className="icons icons-delivery-address"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.delivery_addr[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
            <li
              className={
                location_details?.pathname == "/department/settings"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/department/settings"
                title="Settings"
                className="nav-link"
              >
                <i className="icons icons-settings"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.settings[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>
          </List>

        </div>
      </div>
      {/*mobile sidebar */}

      <Offcanvas
        isOpen={openSidebar}
        scrollable
        className="mobile-sidebar mobile-font"
      >
        <OffcanvasHeader toggle={() => setOpenSidebar(false)}>
          <div className="offcanvas-header">
            <a className="navbar-brand" href="">
              <img src="/images/logo-white-ktipp.png" alt="icon" />
            </a>
          </div>
        </OffcanvasHeader>

        <OffcanvasBody>
          <div className="offcanvas-body">
            <div className="sidebar-wrap">
              <List className="nav">
                <li className={location_details?.pathname === "/department/dashboard"
                  ? "nav-item active"
                  : "nav-item"
                }>
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/department/dashboard"
                    title="Dashboard"
                    className="nav-link"
                    onClick={() => setOpenSidebar(false)}
                  >
                    <i className="icons icons-dashboard" />
                    {DeptRegLang?.dashboard[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname === "/department/products" ||
                      location_details?.pathname === "/department/products-view" ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/department/products/1"
                    title="Products"
                    className="nav-link"
                    onClick={() => {
                      setOpenSidebar(false)
                      resetFilterHandler()
                    }}
                  >
                    <i className="icons icons-products" />
                    {DeptRegLang?.products[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li className={
                  location_details?.pathname === "/department/orders" || location_details?.pathname === "/department/processing-orders" || location_details?.pathname === "/department/cancelled-orders" ?
                    "nav-item active  "
                    : "nav-item"
                }>
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/department/orders"
                    className="nav-link"
                    onClick={() => setOpenSidebar(false)}
                  >
                    <i className="icons icons-order"></i>
                    {DeptRegLang?.orders[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>

                {/* TDS */}
            <li
              className={
                location_details?.pathname == "/department/tds" ?
                  "nav-item active"
                  : "nav-item"
              }
            >
              <span className="curve-before">
                <span className="curve-inner"></span>
              </span>
              <Link
                to="/department/tds"
                title="TDS"
                className="nav-link"
                onClick={() => setOpenSidebar(false)}
              >
                <i className="icons icons-tds"></i>
                <span className={`${sidebarIsOpen ? 'toggle-links' : 'd-none toggle-links'}`}>{DeptRegLang?.tds[language]}</span>
              </Link>
              <span className="curve-after">
                <span className="curve-inner"></span>
              </span>
            </li>

                {/* <li className="nav-item">
                  <Link
                    to="#"
                    title="Services"
                    color="link"
                    onClick={() => setIsOpen(!isOpen)}
                    className="nav-link text-white text-start dropdown-toggle"
                  >
                    <i className="icons icons-services"></i>
                    Services
                  </Link>
                  <Collapse isOpen={isOpen} >
                    <List id="sidebar-collapse01">
                      <li
                        className={
                          location_details?.pathname === "/department/tickets" ?
                            "sub-menu active"
                            : "sub-menu"
                        }
                      >

                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link
                          to="/department/tickets"
                          title="Tickets"
                          className="nav-link"
                          onClick={() => setOpenSidebar(false)}
                        >
                          <i className="icons icons-tickets"></i>
                          Tickets
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li>
                      <li
                        className={
                          location_details?.pathname === "/department/amc"
                            ? "sub-menu active"
                            : "sub-menu"
                        }
                      >
                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link
                          to="/department/amc"
                          title="AMC"
                          className="nav-link"
                          onClick={() => setOpenSidebar(false)}
                        >
                          <i className="icons icons-amc"></i>
                          AMC
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li>
                    </List>
                  </Collapse>
                </li> */}
                <li
                  className={
                    location_details?.pathname == "/department/draft" ?
                      "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/department/draft"
                    title="Delivered"
                    className="nav-link"
                    onClick={() => setOpenSidebar(false)}
                  >
                    <i className="icons icons-drafts" />
                    {DeptRegLang?.drafts_workorder[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname === "/department/delivery-address" ?
                      "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/department/delivery-address"
                    title="Payments"
                    className="nav-link"
                    onClick={() => setOpenSidebar(false)}
                  >
                    <i className="icons icons-delivery-address"></i>
                    {DeptRegLang?.delivery_addr[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/department/settings"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/department/settings"
                    title="Settings"
                    className="nav-link"
                    onClick={() => setOpenSidebar(false)}
                  >
                    <i className="icons icons-settings"></i>  {DeptRegLang?.delivery_addr[language]}
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
              </List>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>

    </>
  );
}
export default DeptSidebar;
