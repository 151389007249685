export const AdminRegLang:any = {
    dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    vendor_registration:{
        en:"Vendors Registration", 
        ka:"ವೇಂದರ್ಸ್ ನೋಂದಣಿ"
    },
    products:{
        en:"Products",
        ka:"ಉತ್ಪನ್ನಗಳು"
    },
    tender:{
        en:"Tender",
        ka:"ಟೆಂಡರ್"
        },
    rate_contract:{
        en:"Rate Contract",
        ka:"ದರ ಒಪ್ಪಂದ"
    },
    orders:{
        en:"Orders",
        ka:"ಆರ್ಡರ್ಗಳು"
    },
    regular_orders:{
        en:"Regular Orders",
        ka:"ನಿಯಮಿತ ಆರ್ಡರ್ಗಳು"
    },
    custom_orders:{
        en:"Custom Orders",
        ka:"ಅನುಕೂಲಿತ ಆದೇಶಗಳು"
    },
    delivered:{
        en:"Delivered",
        ka:"ಬಂದಿದೆ"
    },
    tds:{
        en:"TDS",
        ka:"ಟಿಡಿಎಸ್"
    },
    payments:{
        en:"Payments",
        ka:"ಪಾವತಿಗಳು"
    },
    departments:{
        en:"Departments",
        ka:"ಇಲಾಖೆಗಳು"
    },
    services:{
        en:"Services",
        ka:"ಸೇವೆಗಳು"
    },
    tickets:{
        en:"Tickets",
        ka:"ಟಿಕೆಟ್ಗಳು"
    },
    amc:{
        en:"AMC",
        ka:"ಟಿಕೆಟ್ಗಳು"
    },
    third_party_inspection:{
        en:"Third Party Inspection",
        ka:"ಮೂರನೇ ವ್ಯಕ್ತಿಯ ತಪಾಸಣೆ"
    },
    verification:{
        en:"Verification",
        ka:"ಪರಿಶೀಲನೆ"
    },
    Settings:{
        en:"Settings",
        ka:"ಸಂಯೋಜನೆಗಳು"
    },
    reports:{
        en:"Reports",
        ka:"ವರದಿಗಳು"
    },
    accounts:{
        en:"Accounts",
        ka:"ಖಾತೆಗಳು"
    },
}
