import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../../../Library/LanguageConversion/Department_Products";

const CustomOrderContainer = ({ children }: any) => {
  const { language, setLanguage } = useContext(Context);
  return (
    <>
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumbs */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/department/dashboard">
                {DepartmentProducts?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>{DepartmentProducts?.Custom_Orders[language]}</b>
            </li>
          </List>
        </div>
        {children}
      </div>
    </>
  );
};

export default CustomOrderContainer;
