import React from "react";
import { useState, useEffect,useContext} from "react";

import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import moment from "moment";
import constants from "../../../../Library/Constants/Constants";
import { Context } from "../../../../Components/Context/Context";
import { Admin_services } from "../../../../Library/LanguageConversion/Admin_Services";


export default function Amc() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [amc_data, setAmcData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any>();
  const [from, setFrom] = useState([]);

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getAmc();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);
  //=========================================================== Get Amc ====================================================

  const getAmc = () => {
    setLoading(true);
    api
      .get(`/amc?search_keyword=${search}`, true)
      .then(async function ([success, response]) {
        setAmcData(response?.data || []);
        setPageData(response?.meta || []);
        setFrom(response?.meta?.from);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const { language, setLanguage } = useContext(Context);


  //======================================================== View ==========================================================

  return (
    <>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
              {Admin_services?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <Link className="active" to="/amc">
              {Admin_services?.AMC[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>{Admin_services?.Services[language]}</b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            <div className="search-widget-outer">
              <Form className="d-flex" role="search">
                <InputGroup className="search-group">
                  <InputGroupText  title="Search here" className="search-icon" id="search">
                    <i className="fa-sharp fa-solid fa-magnifying-glass" />
                  </InputGroupText>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    aria-label="Username"
                    aria-describedby="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyPress={(event) => {
                      var key = event.keyCode || event.which;
                      if (key === 13) {
                        event.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
              </Form>
            </div>
            <div className="table-wrap responsive-table">
              <Table className="w-100 table-striped">
                <thead>
                  <tr>
                    <th>{Admin_services?.SlNo[language]}</th>
                    <th>{Admin_services?.TenderID[language]} </th>
                    {/* <th>Contracts</th> */}
                    <th>{Admin_services?.Invoice[language]}</th>
                    <th>{Admin_services?.Department[language]}</th>
                    <th>{Admin_services?.Product[language]}</th>
                    <th>{Admin_services?.Type[language]}</th>
                    <th>{Admin_services?.ValidFrom[language]}</th>
                    <th>{Admin_services?.ValidTo[language]}</th>
                  </tr>
                </thead>
                <tbody>
                  {amc_data?.length !== 0 ? (
                    <>
                      {amc_data?.map((item: any, i: any) => {
                        return (
                          <React.Fragment key={i}>
                            <tr>
                              <td>
                                <span className="mobile-heading">{Admin_services?.SlNo[language]}:</span>
                                <span className="table-data">{from + i}</span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                {Admin_services?.TenderID[language]}:
                                </span>
                                <span className="table-data">
                                  {/* {item?.invoiceproduct?.usercompany?.name} */}
                                </span>
                              </td>
                              {/* <td>
                                <span className="mobile-heading">
                                  Contracts:
                                </span>
                                <span className="table-data">
                                  {item?.invoiceproduct?.usercompany?.name}
                                </span>
                              </td> */}
                              <td>
                                <span className="mobile-heading">
                                {Admin_services?.Invoice[language]}:
                                </span>
                                <span className="table-data">
                                  {item?.amcinvoice?.invoice_no}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {Admin_services?.Department[language]}:
                                </span>
                                <span className="table-data">
                                  {item?.invoiceproduct?.usercompany?.email}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {/* Invoice Id: */}
                                  {Admin_services?.Product[language]}:
                                </span>
                                <span className="table-data">
                                  {item?.invoiceproduct?.vendorproducts?.name}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">{Admin_services?.Type[language]}:</span>
                                <span className="table-data">
                                  {item?.type ==
                                  constants.SERVICETYPE.SERVICE ? (
                                    <span>{Admin_services?.Service[language]}</span>
                                  ) : item?.type ==
                                    constants.SERVICETYPE.HARDWARE ? (
                                    <span>{Admin_services?.Hardware[language]}</span>
                                  ) : item?.type ==
                                    constants.SERVICETYPE.ONSITE ? (
                                    <span>{Admin_services?.Onsite[language]}</span>
                                  ) : null}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                {Admin_services?.ValidFrom[language]}:
                                </span>
                                <span className="table-data">
                                  {moment(item?.start_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                {Admin_services?.ValidTo[language]}:
                                </span>
                                <span className="table-data">
                                  {moment(item?.end_date).format("DD-MM-YYYY")}
                                </span>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td
                        className="text-center px-2 py-5 empty-text"
                        colSpan={8}
                      >
                        {loading ? (
                          <div className="d-flex justify-content-center">
                            <Loader />
                          </div>
                        ) : (
                          "No AMC Found"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {pageData && amc_data?.length !== 0 && (
              <div className="table-footer-wrap">
                <small>
                Showing {pageData.from} to {pageData.to} of {pageData.total}{" "}
                entries
                </small>
                <div className="pagination">
                  <CustomPagination
                    totalItems={pageData?.total}
                    itemsPerPage={pageData?.per_page}
                    activePage={pageData.current_page}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
