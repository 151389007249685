import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PAYMENT_MODES } from '../../../../Library/Constants/MapConstants'
import { api } from '../../../../Library/CustomApi'
import { toast } from 'react-toastify'
import constants from '../../../../Library/Constants/Constants'
import { Button } from 'reactstrap'
import { Context } from "../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../Library/LanguageConversion/Department_Products";


interface propType {
    selectedMode: number,
    setSelectedMode: Function,
    partialAmount: number,
    setPartialAmount: Function,
    amount: number,
    order_id: any,
    totalAmount: any
}
const PaymentMode = ({ selectedMode, setSelectedMode, partialAmount, setPartialAmount, amount, order_id, totalAmount }: propType) => {
    const { language, setLanguage } = useContext(Context);
    const navigate = useNavigate()
    const location = useLocation();
    const [paylaterIsLoading, setPaylaterIsLoading] = useState<boolean>(false)
    const [entereAmount, setEnteredAmount] = useState(0)

    // paylater handler : we need only order id and payment mode 
    const handlePayLater = () => {
        setPaylaterIsLoading(true)
        let DATA =
        {
            payment_mode: selectedMode,
            transaction_id: null,
            order_id: order_id,
            amount: totalAmount,
            currency: null,
            status: null,
            method: null,
            bank: null,
        }
        if (totalAmount == 0) {
            toast.error('Error')
            return
        }
        else {
            api
                .post(
                    `/payment/payments`,
                    {
                        ...DATA
                    },
                    true
                )
                .then(async function ([success, response, status]) {
                    if (status == 200) {
                        toast.success("Payment Successful");
                        navigate(location.pathname, {});
                        navigate('/department/placed-orders')
                    }
                    setPaylaterIsLoading(false)
                    return response;
                })
                .catch((err) => {
                    setPaylaterIsLoading(false)
                    toast.error('Unexpected Error Occured');
                    console.log(err);
                });
        }
    }

    return (
        <div className='payment-mode-select'>
            <select onChange={(e: any) => {
                setPartialAmount(0)
                setSelectedMode(e.target.value)
            }} className='form-select'>
                <option value="">{DepartmentProducts?.SelectPaymentMode[language]}</option>
                {PAYMENT_MODES?.map((obj: any, i: number) => <option key={i} value={obj?.value}>{obj?.name}</option>)}
            </select>

            {/* partial payment  */}
            {(selectedMode == constants?.DEPT_PAYMENT_MODES?.PARTIAL_PAYMENT) &&
                <div className='custom-amount-wrap-outer'>
                    <label className='custom-amount-payment'>{DepartmentProducts?.Enter_Amount[language]}</label>
                    <input type="text" onChange={(e: any) => setEnteredAmount(e.target.value || 0)} />
                    <button className='btn-primary ms-2' style={{ borderRadius: '0.375rem' }} onClick={() => setPartialAmount(entereAmount)}>{DepartmentProducts?.Confirm_Amount[language]}</button>
                    {/* error message */}
                    {partialAmount > amount &&
                        <div>{DepartmentProducts?.Amount_exceeded_limit [language]}</div>}
                </div>
            }

            {/* Pay later  */}
            {(selectedMode == constants?.DEPT_PAYMENT_MODES?.PAY_LATER) &&
                <>
                    <Button
                        color='primary'
                        disabled={paylaterIsLoading}
                        className='paylater-confirm-button'
                        onClick={handlePayLater} >{DepartmentProducts?.Confirm_Pay_Later [language]}
                    </Button>
                </>}
        </div>
    )
}

export default PaymentMode