const  constants = {
  PRODUCT: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  TENDER: {
    APPROVED: 1,
    INACTIVE: 2,
  },

  TICKETS: {
    OPEN_TICKETS: 1,
    CLOSED_TICKETS: 2,
    PENDING_TICKETS: 3,
  },
  ORDERS: {
    DRAFT: 0,
    RECEIVED: 1,
    PROCESSED: 2,
    CONFIRMED: 3,
    APPROVED: 4,
    PENDING: 5,
    DELIVERED: 6,
    CANCELLED: 7,
    REJECTED: 10,
    OUTOFDELIVEREY: 9,
    ALLOCATED: 8,
    WORKORDERDRAFT: 12,
    WORKORDERACCEPT: "14",
    COMPLETED: 11,
    DELIVEREDANDINSTALLED: 15,
  },

  VENDOR_TYPE: {
    MANUFATURE: 1,
    DISTRIBUTER: 2,
    IMPORTER: 3,
    MARKETER: 4,
    PROPRITERSHIP: 5,
    PARTNERSHIP: 6,
    PVTLTD: 7,
    LLP: 8,
    OEMS: 9,
    STARTUP: 10,
    QAVENDOR: 11,
  },

  INVITE_STATUS: {
    INVITE_NOT_SEND: 0,
    INVITE_SEND: 1,
    ACCEPTED: 2,
    DOCUMENTS_UPLOADED: 3,
    DOCUMENT_UNDER_VERIFICATION: 4,
    DOCUMENT_VERIFIED: 5,
  },
  VENDOR_STAUS: {
    REGISTERED: 0,
    PMU_REGISTER: 1,
    SELF_REGISTER: 2,
    PENDING: 3,
    VERIFIED: 4,
    REJECTED: 5,
  },
  ADDRESS: {
    APPROVE: 0,
    APPROVED: 1,
  },
  DEPARTMENT: {
    VERIFY: 0,
    VERIFIED: 1,
  },
  ROLE_ID: {
    PMU: 1,
    ADMIN: 2,
    DEPARTMENT: 3,
    VENDOR: 4,
  },
  RATE_CONTRACT: {
    PENDING: 2,
    APPROVED: 1,
  },
  RATE_DATA: {
    ASSIGNED: 1,
    UNASSIGNED: 2,
  },
  DOCUMENTS: {
    PAN: 0,
    OEM: 1,
    AADAR: 2,
    AADRESS_PROOF: 3,
    INCORP: 4,
    MSE: 5,
    GST: 6,
    TURN_OVER1: 7,
    TURN_OVER2: 8,
    TURN_OVER3: 9,
    ISO: 10,
    DD: 11,
    BASICDETAILS: 12,
    VENDORTYPE: 13,
    GSTCLASSNAME: 14,
    COMPANYNAME: 15,
    COMPANYEMAIL: 16,
    COMPANYCONTACTNUMBER: 17,
    COMPANYCOMMUNICATIONADDRESS: 18,
    CONTACTPERSONNAME: 19,
    CONTACTNUMBER: 20,
    EMAILID: 21,
    CHEQUE: 24,
    CHELLAN: 25,
    EMPANEL: 26,
    UDYAM: 28,
    WORKORDER: 29,
    HR_MANPOWER: 30,
    HR_LAND: 31,
    HR_ANY_OFFICE: 32,
    IT_TURN_OVER_ONE: 33,
    IT_TURN_OVER_TWO: 34,
    MOU: 35,
    START_UP_CERIFICATE: 36,
    BLACK_LIST_DOCUMENT: 37,
    POWER_ATO: 38,
    UDYAM_DFO: 39,
    NO_CASE_DOCUMENT: 40,
  },
  DOCUMETSWAY: {
    APPROVED: 2,
    PENDING: 1,
    REJECTED: 3,
    REUPLOAD: 4,
  },
  VENDORTYPE: {
    MANUFACTURER: 1,
    DISTRIBUTOR: 2,
    IMPORTER: 3,
    MARKETER: 4,
  },
  GSTCLASS: {
    REGISTERED: 1,
    NOTREGISTERED: 2,
    COMPOUNDINGSCHEME: 3,
    MARKETER: 4,
    PSUGOVT: 5,
  },
  SERVICETYPE: {
    SERVICE: 0,
    ONSITE: 1,
    HARDWARE: 2,
  },
  BASIC_VERIFY: {
    APPROVED: 2,
    REJECTED: 3,
    REUPLOAD: 4,
  },
  ADVANCED_VERIFY: {
    PAN: 0,
    OEM: 1,
    AADAR: 2,
    AADRESS_PROOF: 3,
    INCORP: 4,
    MSE: 5,
    GST: 6,
    TURN_OVER1: 7,
    TURN_OVER2: 8,
    TURN_OVER3: 9,
    ISO: 10,
    DD: 11,
    BASICDETAILS: 12,
    VENDORTYPE: 13,
    GSTCLASSNAME: 14,
    COMPANYNAME: 15,
    COMPANYEMAIL: 16,
    COMPANYCONTACTNUMBER: 17,
    COMPANYCOMMUNICATIONADDRESS: 18,
    CONTACTPERSONNAME: 19,
    CONTACTNUMBER: 20,
    EMAILID: 21,
    EMD: 23,
    EMPANEL: 26,
    UDYAM: 28,
    WORKORDER: 29,
    HR_MANPOWER: 30,
    HR_LAND: 31,
    HR_ANY_OFFICE: 32,
    IT_TURN_OVER_ONE: 33,
    IT_TURN_OVER_TWO: 34,
    MOU: 35,
    START_UP_CERIFICATE: 36,
    BLACK_LIST_DOCUMENT: 37,
    POWER_ATO: 38,
    UDYAM_DFO: 39,
    NO_CASE_DOCUMENT: 40,
  },
  ACCOUNTVERIFY: {
    NOT_VERIFIED: 2,
    VERIFIED: 1,
  },
  PAYMENTRECEIVED: {
    RECEIVED: 1,
    TOVENDORS: 2,
  },

  PAYMENTSTATUS: {
    SUCCESS: 1,
    CANCLED: 2,
    FAIL: 3,
  },
  DRAFTVERIFICATION: {
    PENDING: "1",
    VERIFIED: "2",
  },

  TICKETSTATUS: {
    OPEN: 1,
    PENDING: 2,
    CLOSED: 3,
  },
  TICKET_TYPE: {
    SERVICES: 1,
    MAINTAINANCE: 2,
    ENQUIRE: 3,
    TICKETS: 4,
  },
  PRIORITY: {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
  },
  WORK_ORDER_VERIFY: {
    APPROVE: 2,
    REJECT: 3,
    REUPLOAD: 4,
  },
  DELIVERED_DOC: {
    PENDING: 6,
    APPROVED: 2,
    REJECTED: 3,
  },
  DELIVERY_DOC: {
    CHALLAN: 1,
    WORKORDER: 2,
    DELIVERYINVOICE: 3,
    IMAGES: 4,
  },
  PAYMENT_MODE: {
    COMLETED: 1,
    PARTIAL_MODE: 2,
    ON_DELIVERY: 3,
    PENDING: 4,
  },
  DEPT_PAYMENT_MODES: {
    FULL_PAYMENT: 1,
    PARTIAL_PAYMENT: 2,
    PAY_LATER: 3,
  },
  PAYMENT_TYPE: {
    BANK: 1,
    CHEQUE: 2,
    CHELLAN: 3,
    NETBANK: 4,
    NEFT: 5,
    RTGS: 6,
  },
  COUSTME_ORDERS: {
    NEW_ORDER: 3,
    REQUEST_SEND: 1,
    REQUEST_ACCEPT: 2,
    REQUEST_REJECT: 4,
  },
};

export default constants;
