import moment from "moment";
import React, { useState, useContext, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Context } from "../../../../Components/Context/Context";
import { Admin_orders } from "../../../../Library/LanguageConversion/Admin_orders";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Table,
} from "reactstrap";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import {
  convertISODateToDateTime,
  getOrderStatus,
  getUnique,
} from "../../../../Library/Constants/helpers";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { api } from "../../../../Library/CustomApi";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../../../Library/ApiSettings";
import Swal from "sweetalert2";

const OrderView2 = (props: any) => {
  const { language } = useContext(Context);

  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [docData, setDocData] = useState<any>([]);

  useEffect(() => {
    getSingleOrderDocs();
  }, [props?.order_view?.id]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  function truncateText(text: any) {
    return text?.replace(/<br>/g, ",");
  }

  // MOU Certificate Aprrove / Reject
  const handleMOUStatus = (id: any, status: number) => {
    // Approve 1
    // Reject 2
    api
      .post(
        `/order/mou-document-reject-accept`,
        {
          orderId: id,
          status: status,
        },
        true
      )
      .then(async function ([success, response]) {
        props?.getSingleOrder && props?.getSingleOrder(props?.order_view?.id);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // PG Payment
  const handlePGStatus = (id: any, status: number) => {
    // Approve 1
    // Reject 2
    api
      .post(
        `/VendorToPmu_status_changes`,
        {
          id: id,
          status: status,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Done");
        getSingleOrderDocs();
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSingleOrderDocs = () => {
    api
      .get(
        `/order/get_order_details_order_id?order_id=${props?.order_view?.id}`,
        true
      )
      .then(async function ([success, response]) {
        setDocData(response?.message);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //  Tax Invoice
  const handleTaxInvoice = (id: any, status: number) => {
    // Approve 1
    // Reject 2
    api
      .post(
        `/order/vendor_tax_invoice_status_update`,
        {
          orderId: id,
          status: status,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Done");
        props?.getSingleOrder && props?.getSingleOrder(props?.order_view?.id);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //  Delivery challan
  const handleDelChallan = (id: any, status: number) => {
    // Approve 1
    // Reject 2
    api
      .post(
        `/order/vendor_delivery_chellan_status_update`,
        {
          orderId: id,
          status: status,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Done");
        props?.getSingleOrder && props?.getSingleOrder(props?.order_view?.id);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ICRR
  const generateICRR = () => {
    api
      .post(
        `/order/generate_icrr`,
        {
          id: props?.order_view?.pf_invoice?.id,
          order_id: props?.order_view?.id,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("ICRR Generated");
        props?.getSingleOrder && props?.getSingleOrder(props?.order_view?.id);
        // window.open(response?.data?.icrr_doc, "_blank")
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadICCRDownload = () => {
    window?.open(props?.order_view?.icrr?.icrr_doc, "_blank");
  };

  const vendorApprovalDocument = (
    id: any,
    vendor: Number,
    order_id: number
  ) => {
    if (id == 1) {
      Swal.fire({
        title: "Are you sure?" + "\n" + "Do you want to Approve document?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          sendApproveData(id, vendor, order_id);
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure?" + "\n" + "Do you want to Reject document?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          sendApproveData(id, vendor, order_id);
        }
      });
    }
  };

  const sendApproveData = (id: any, vendor: Number, order_id?: Number) => {
    api
      .post(
        `/order/approve_reject_emd_stmt`,
        {
          status: id,
          proforma_id: props?.order_view?.pf_invoice?.id,
          order_id: order_id,
          vendor_id: vendor,
        },
        true
      )
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          toast.success(response?.message);
        }
        getSingleOrderDocs();
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const download_documents = async (id: any) => {
    // documents/1602/download

    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(`${API_URL}/documents/${id}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.innerHTML = "This is a new div element.";
        a.href = url;
        a.download = "Document.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.modal}
        setOpen={props?.toggle}
        header="Order Details"
      >
        {props?.loading ? (
          <div style={{ marginLeft: "350px" }}>
            <Loader />
          </div>
        ) : (
          <div className="feeback-outer-wrap">
            <div className="feedback-main-heading"></div>
            <div className="feedback-wrap">
              <div className="feedback-details ">
                {/* ======================================= order tracking status ===================================== */}
                <div className="progress-tracker mt-4">
                  <div className="progress-tracker-wrap">
                    <Row className="align-items-center">
                      <h5 className="sub-heading">
                        {Admin_orders?.OrderTrackingStatus?.[language]}
                      </h5>
                      <Col md={12}>
                        <div className="tracker-status-details-wrap">
                          <ul className="main-list">
                            {props?.graph_data?.map((item: any) => {
                              return (
                                <>
                                  <li>
                                    <div className="flex-outer">
                                      <div className="flex-wrap">
                                        <div className="circle check "></div>
                                        <p className="track-status-text">
                                          {getOrderStatus(item?.status)}
                                        </p>
                                      </div>
                                      <div className="date">
                                        <p className="track-status-text">
                                          {convertISODateToDateTime(
                                            item?.created_at
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <Table className="table-hover table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <b>{Admin_orders?.OrderID?.[language]}</b>{" "}
                      </td>
                      <td> {props?.order_view?.vendor_po_no} </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <b>{Admin_orders?.ProductName?.[language]}</b>{" "}
                      </td>
                      <td>
                        {" "}
                        {
                          props?.order_view?.order_details?.product_details
                            ?.name
                        }{" "}
                      </td>
                    </tr>
                    <tr onClick={toggle} className="collapse-btn">
                      <td>
                        {" "}
                        <b>{Admin_orders?.ProductCode?.[language]}</b>{" "}
                      </td>
                      <td>
                        {" "}
                        {
                          props?.order_view?.order_details?.product_details
                            ?.product_code
                        }{" "}
                      </td>
                    </tr>
                    <Collapse isOpen={isOpen} tag={"tr"}>
                      <td colSpan={2}>
                        <Table className=" table-bordered ">
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <b>{Admin_orders?.HSNCode?.[language]}</b>{" "}
                              </td>
                              <td>
                                {" "}
                                {
                                  props?.order_view?.order_details
                                    ?.product_details?.hsn_code
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <b>{Admin_orders?.Quantity?.[language]}</b>
                              </td>
                              <td>
                                {" "}
                                {props?.order_view?.order_details?.quantity}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>{Admin_orders?.UnitPrice?.[language]}</b>
                              </td>
                              <td>
                                ₹{" "}
                                {
                                  props?.order_view?.order_details
                                    ?.product_details?.product_rate?.[0]?.price
                                }
                              </td>
                              {/* this row shows in desktop (Specs)*/}
                            </tr>
                            <tr className="large-screen-view">
                              <td>
                                <b>
                                  {
                                    Admin_orders?.ProductSpecifications?.[
                                      language
                                    ]
                                  }
                                </b>
                              </td>
                              <td>
                                <p className="specs">
                                  {props?.order_view?.order_details
                                    ?.product_details?.features ? (
                                    <small>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: truncateText(
                                            props?.order_view?.order_details
                                              ?.product_details?.features
                                          ),
                                        }}
                                      />
                                    </small>
                                  ) : null}
                                  {/* <small><span><b>Processor:</b></span>i5</small> */}
                                </p>
                              </td>
                            </tr>
                            {/* this row shows in mobile(Specs)*/}
                            <tr className="mobile-view">
                              <td colSpan={2} className="text-center">
                                <b>
                                  {
                                    Admin_orders?.ProductSpecifications?.[
                                      language
                                    ]
                                  }
                                </b>
                              </td>
                            </tr>
                            <tr className="mobile-view">
                              <td colSpan={2}>
                                <p className="specs centered">
                                  {props?.order_view?.order_details
                                    ?.product_details?.features ? (
                                    <small>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: truncateText(
                                            props?.order_view?.order_details
                                              ?.product_details?.features
                                          ),
                                        }}
                                      />
                                    </small>
                                  ) : null}
                                  {/* <small><span><b>Processor:</b></span>i5</small> */}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>{Admin_orders?.Department?.[language]}</b>
                              </td>
                              <td>
                                {props?.order_view?.pf_invoice?.user?.full_name}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>
                                  {Admin_orders?.ShipmentAddress?.[language]}
                                </b>
                              </td>
                              <td>
                                {Admin_orders?.BuildingNo?.[language]}:{" "}
                                {
                                  props?.order_view?.pf_invoice?.pf_address?.[0]
                                    ?.building_no
                                }
                                ,
                                {
                                  props?.order_view?.pf_invoice?.pf_address?.[0]
                                    ?.city
                                }
                                ,
                                {
                                  props?.order_view?.pf_invoice?.pf_address?.[0]
                                    ?.district
                                }
                                ,{" "}
                                {
                                  props?.order_view?.pf_invoice?.pf_address?.[0]
                                    ?.state
                                }
                                ,
                                {
                                  props?.order_view?.pf_invoice?.pf_address?.[0]
                                    ?.pincode
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </Collapse>
                    {props?.order_view?.address?.[0]?.state == "Karnataka" ? (
                      <>
                        <tr>
                          <td>
                            <b>{Admin_orders?.SGST?.[language]}</b>{" "}
                          </td>
                          <td>₹ {props?.order_view?.sgst}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>{Admin_orders?.CGST?.[language]}</b>{" "}
                          </td>
                          <td>₹ {props?.order_view?.cgst}</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>
                            <b>{Admin_orders?.IGST?.[language]}</b>{" "}
                          </td>
                          <td>₹ {props?.order_view?.igst}</td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td>
                        <b>{Admin_orders?.TotalGST?.[language]}</b>{" "}
                      </td>
                      <td>₹ {props?.order_view?.total_gst}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <td colSpan={2}>
                      <div className="order-view-bottom">
                        <h4>
                          <small className="date">
                            <span className="date-span-botton">
                              {moment(props?.order_view?.updated_at).format(
                                "LLL"
                              )}
                            </span>
                          </small>
                        </h4>
                        <h4 className="text-end">
                          {Admin_orders?.GrandTotal?.[language]}:{" "}
                          <strong> ₹{props?.order_view?.total_amount}</strong>
                        </h4>
                      </div>
                    </td>
                  </tfoot>
                </Table>

                {/* MOU  */}
                {props?.order_view?.mou_document && (
                  
                  <div className="py-3 flex-between mou-certificate-wrap">
                    <h3 className="green-text mb-0">
                      {Admin_orders?.MOUCertificate?.[language]}
                    </h3>
 
                    <div>
                      <span className="mx-2">
                        <Button
                          color="success"
                          outline
                          href={props?.order_view?.mou_document}
                          target="_blank"
                        >
                          {/* <i title="View MOU Certificate" className="icons bi bi-eye lg"></i> */}
                          <i className="icons bi bi-download lg"></i> {Admin_orders?.View?.[language]}
                        </Button>
                      </span>
                     
                    </div>


                    <div>

                    {props?.order_view?.mou_status == 0 ? (
                        <>
                          <Button
                            color="warning"
                            onClick={() =>
                              handleMOUStatus(props?.order_view?.id, 1)
                            }
                          >
                            {Admin_orders?.Approve?.[language]}
                          </Button>
                          <Button
                            className="bg-danger ms-2"
                            onClick={() =>
                              handleMOUStatus(props?.order_view?.id, 2)
                            }
                          >
                            {Admin_orders?.Reject?.[language]}
                          </Button>
                        </>
                      ) : props?.order_view?.mou_status == 1 ? (
                        <span className="approved-status">
                          {Admin_orders?.Approved?.[language]}
                        </span>
                      ) : props?.order_view?.mou_status == 2 ? (
                        <span className="rejected-status">
                          {Admin_orders?.Rejected?.[language]}
                        </span>
                      ) : (
                        <></>
                      )}

                    </div>
                    
                  </div>
                )}

                {/* EMD & PG  */}
                {docData?.map((it: any, i: number) => {
                  return (
                    <>
                      <hr />

                      <h3 className="green-text">
                          {it?.payfor == "EMD"
                            ? "EMD"
                            : "Performance Guarantee Payment"}
                          {/* {Admin_orders?.PerformanceGuaranteePayment?.[language]} */}
                        </h3>

                      <Table className="table-hover table-bordered">
                        <tbody>
                        {it?.account_holder_name && (

                          <tr>
                            <td>
                            {Admin_orders?.AccountHolderName?.[language]}
                            </td>
                            <td>                                 
                              {it?.account_holder_name}
                            </td>
                          </tr>
                          )}
                          {it?.bank_name && (
                          <tr>
                            <td>
                            {Admin_orders?.BankName?.[language]}
                            </td>
                            <td>
                            {it?.bank_name}
                            </td>
                          </tr>
                      )}
                      {it?.branch_name && (
                            <tr>
                              <td>{Admin_orders?.BranchName?.[language]}</td>
                              <td>{it?.branch_name}</td>
                            </tr>
                          )}
                          {it?.amount && (
                            <tr>
                              <td>{Admin_orders?.Amount?.[language]}</td>
                              <td className="green-text"><strong>₹ {it?.amount}</strong></td>
                            </tr>
                          )}
                          {it?.docs && (
                            <tr>
                              <td>
                                {Admin_orders?.DocumentUploaded?.[language]}:
                              </td>
                              <td>
                                <Link
                                  to=""
                                  onClick={() => {
                                    var win: any = window.open(
                                      it?.docs,
                                      "_blank"
                                    );
                                    win.focus();
                                  }}
                                >
                                  <i
                                    title="View"
                                    className="icons bi bi-eye lg"
                                  ></i>
                                </Link>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      {/* <div className="performance-payment-wrap">
                        <h3 className="green-text">
                          {it?.payfor == "EMD"
                            ? "EMD"
                            : "Performance Guarantee Payment"}
                        </h3> */}

                        {/* PG  */}
                        {it?.payfor !== "EMD" && (
                          <div className="button-container">
                            {/* <p className="mb-0">
                              {Admin_orders?.Status?.[language]}:
                            </p> */}
                            {it?.pgstatus == 0 || it?.pgstatus == null ? (
                              <>
                                  <Button
                                    color="warning"
                                    onClick={() => handlePGStatus(it?.id, 1)}
                                  >
                                    {Admin_orders?.Approve?.[language]}
                                  </Button>
                                  <Button
                                    className="bg-danger"
                                    onClick={() => handlePGStatus(it?.id, 2)}
                                  >
                                    {Admin_orders?.Reject?.[language]}
                                  </Button>
                              </>
                            ) : it?.pgstatus == 1 ? (
                              <span className="approved-status">
                                {Admin_orders?.Approved?.[language]}
                              </span>
                            ) : it?.pgstatus == 2 ? (
                              <span className="rejected-status">
                                {Admin_orders?.Rejected?.[language]}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}

                        {/* emd  */}
                        
                        {/* <td>
                              <span className="mobile-heading">
                                {Admin_orders?.Status?.[language]}:
                              </span>
                              {it?.status == 2 && (
                                <span className="text-danger">
                                  {Admin_orders?.Rejected?.[language]}
                                </span>
                              )}
                              {it?.status == 1 && (
                                <span className="text-success">
                                  {Admin_orders?.Approved?.[language]}
                                </span>
                              )}
                              {it?.status == 0 && (
                                <span className="text-info">
                                  {Admin_orders?.Waiting?.[language]}
                                </span>
                              )}
                            </td> */} 
                        {it?.payfor == "EMD" && (
                          // <tr>
                          //   <td>
                              <div className="button-container">
                                {it?.status == 1 ? (
                                  <a className="rounded-tag bg-success">
                                    <i className="icons icons-accept-white"></i>
                                  </a>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      vendorApprovalDocument(
                                        1,
                                        it?.payment_from,
                                        props?.order_view?.id
                                      )
                                    }
                                    className="rounded-tag"
                                    color="warning"
                                  >
                                    {/* <i className="icons icons-accept-white"></i> */}
                                    {Admin_orders?.Approve?.[language]}
                                  </Button>
                                )}
                                {it?.status !== 1 && (
                                  <>
                                    {" "}
                                    {it?.status == 2 ? (
                                      <Button className="rounded-tag bg-danger">
                                        <i className="bi bi-x-circle text-white"></i>
                                      </Button>
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          vendorApprovalDocument(
                                            2,
                                            it?.payment_from,
                                            props?.order_view?.id
                                          )
                                        }
                                        target="_blank"
                                        className="rounded-tag bg-danger"
                                      >
                                        {Admin_orders?.Reject?.[language]}
                                      </Button>
                                    )}
                                  </>
                                )}
                              </div>
                          //   </td>

                          // </tr>
                        )}
                      {/* </div> */}
                    </>
                  );
                })}

                {/* Tax invoice  */}
                {props?.order_view?.vendor_tax_invoice && (
                  <>
                    <hr />
                    {/* <div className="performance-payment-wrap"> */}
                      <h3 className="green-text">
                        {Admin_orders?.TaxInvoice?.[language]}
                      </h3>
                      <Table className="table-hover table-bordered">
                        <tbody>
                          <tr>
                            <td>{Admin_orders?.InvoiceNo?.[language]}</td>
                            <td>
                              {
                                props?.order_view?.vendor_tax_invoice
                                  ?.invoice_number
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.Amount?.[language]}</td>
                            <td className="green-text">
                            <strong>₹{props?.order_view?.vendor_tax_invoice?.amount}</strong> 
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.FromAddress?.[language]}</td>
                            <td>
                              {" "}
                              {
                                props?.order_view?.vendor_tax_invoice
                                  ?.billing_address_from
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.DeliveryAddress?.[language]}</td>
                            <td>
                              {
                                props?.order_view?.vendor_tax_invoice
                                  ?.supply_address_to
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.InvoiceDate?.[language]}</td>
                            <td>
                              {" "}
                              {convertISODateToDateTime(
                                props?.order_view?.vendor_tax_invoice
                                  ?.invoice_date
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.Documents?.[language]}</td>
                            <td>
                              {" "}
                              <a
                                href={decodeURIComponent(
                                  props?.order_view?.vendor_tax_invoice
                                    ?.invoice_doc
                                )}
                                target="_blank"
                              >
                                <i
                                  title="View"
                                  className="icons bi bi-eye lg"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>  
                      </Table>

                      {/* <div className="flex-between status-text-wrap"> */}
                      <div className="button-container">
                        {/* <p className="mb-0">
                          {Admin_orders?.Status?.[language]}:
                        </p> */}
                        {props?.order_view?.vendor_tax_invoice
                          ?.verified_status == 0 ? (
                          <>
                            <Button
                              color="warning"
                              onClick={() =>
                                handleTaxInvoice(
                                  props?.order_view?.vendor_tax_invoice?.id,
                                  1
                                )
                              }
                            >
                              {Admin_orders?.Approve?.[language]}
                            </Button>

                            <Button
                              className="bg-danger"
                              onClick={() =>
                                handleTaxInvoice(
                                  props?.order_view?.vendor_tax_invoice?.id,
                                  2
                                )
                              }
                            >
                              {Admin_orders?.Reject?.[language]}
                            </Button>
                          </>
                        ) : props?.order_view?.vendor_tax_invoice
                            ?.verified_status == 1 ? (
                          <span className="approved-status">
                            {Admin_orders?.Approved?.[language]}
                          </span>
                        ) : props?.order_view?.vendor_tax_invoice
                            ?.verified_status == 2 ? (
                          <span className="rejected-status">
                            {Admin_orders?.Rejected?.[language]}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    {/* </div>{" "} */}
                  </>
                )}

                {/* Delivery challan */}
                {props?.order_view?.vendor_delivery_challan && (
                  <>
                    <hr />

                    {/* <div className="performance-payment-wrap"> */}
                      <h3 className="green-text">
                        {Admin_orders?.DeliveryChallan?.[language]}
                      </h3>
                      <Table className="table-hover table-bordered">
                        <tbody>
                          <tr>
                            <td>{Admin_orders?.ChallanNo?.[language]}</td>
                            <td>
                              {
                                props?.order_view?.vendor_delivery_challan
                                  ?.chellan_number
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.SubTotal?.[language]}</td>
                            <td>
                              {
                                props?.order_view?.vendor_delivery_challan
                                  ?.sub_total
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.Tax?.[language]}</td>
                            <td>
                              {" "}
                              {props?.order_view?.vendor_delivery_challan?.tax}
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.GrandTotal?.[language]}</td>
                            <td>
                              {
                                props?.order_view?.vendor_delivery_challan
                                  ?.grant_total
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>{Admin_orders?.Documents?.[language]}</td>
                            <td>
                              <a
                                className="m-2"
                                href={decodeURIComponent(
                                  props?.order_view?.vendor_delivery_challan?.doc
                                )}
                                target="_blank"
                              >
                                <i
                                  title="View"
                                  className="icons icons-eye-orange"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>                  
                      </Table>

                      {/* <div className="flex-between status-text-wrap"> */}
                      <div className="button-container">
                        {/* <p className="mb-0">
                          {Admin_orders?.Status?.[language]}:
                        </p> */}
                        {props?.order_view?.vendor_delivery_challan
                          ?.verified_status == 0 ? (
                          <>
                            <Button
                              color="warning"
                              onClick={() =>
                                handleDelChallan(
                                  props?.order_view?.vendor_delivery_challan
                                    ?.id,
                                  1
                                )
                              }
                            >
                              {Admin_orders?.Approve?.[language]}
                            </Button>

                            <Button
                              className="bg-danger ms-2"
                              onClick={() =>
                                handleDelChallan(
                                  props?.order_view?.vendor_delivery_challan
                                    ?.id,
                                  2
                                )
                              }
                            >
                              {Admin_orders?.Reject?.[language]}
                            </Button>
                          </>
                        ) : props?.order_view?.vendor_delivery_challan
                            ?.verified_status == 1 ? (
                          <span className="approved-status">
                            {Admin_orders?.Approved?.[language]}
                          </span>
                        ) : props?.order_view?.vendor_delivery_challan
                            ?.verified_status == 2 ? (
                          <span className="rejected-status">
                            {Admin_orders?.Rejected?.[language]}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    {/* </div> */}
                    <hr />
                  </>
                )}

                {/* ICRR  */}
                <div className="flex-between">
                  <h3 className="green-text">ICRR</h3>
                  <div>
                    {props?.order_view?.icrr == null ||
                    props?.order_view?.icrr?.length == 0 ? (
                      <Button
                        color="success"
                        outline
                        // target="_blank"
                        onClick={() => generateICRR()}
                      >
                        {Admin_orders?.GenerateICRR?.[language]}
                      </Button>
                    ) : (
                      <Button 
                        color="success"
                        outline
                        // target="_blank"
                        onClick={downloadICCRDownload}
                      >
                        {Admin_orders?.DownloadICRR?.[language]}
                      </Button>
                    )}
                  </div>
                </div>

                <hr />


                {props?.order_view?.icrr && (

                // <div className="performance-payment-wrap mt-4">
<div>
                <h3 className="green-text">
                      {Admin_orders?.ApprovalStatus?.[language]}
                    </h3>
                <Table className="table-hover table-bordered">
                        <tbody>

                          <tr>
                            <td>
                            {Admin_orders?.Store?.[language]}
                            </td>
                            <td>  
                            {props?.order_view?.icrr?.store_status == 1 ? (
                            <span className="approved-status">{Admin_orders?.Approved?.[language]}
                            </span>
                          ) : (
                            <span className="warning-status">{Admin_orders?.Pending?.[language]}</span>
                          )}                               
                            </td>
                          </tr>

                          <tr>
                        <td>{Admin_orders?.Accountant?.[language]}</td>
                        <td>
                          {props?.order_view?.icrr?.acc_status == 1 ? (
                            <span className="approved-status">{Admin_orders?.Approved?.[language]}</span>
                          ) : (
                            <span className="warning-status">{Admin_orders?.Pending?.[language]}</span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>{Admin_orders?.Manager?.[language]}</td>
                        <td>
                          {props?.order_view?.icrr?.manager_status == 1 ? (
                            <span className="approved-status">{Admin_orders?.Approved?.[language]}</span>
                          ) : (
                            <span className="warning-status">{Admin_orders?.Pending?.[language]}</span>
                          )}
                        </td>
                      </tr>
                          </tbody>
                          </Table>
                          </div>
                          )}






                <hr />
                <h3>
                  <strong>{Admin_orders?.VendorDetails?.[language]}</strong>
                </h3>
                <div className="container">
                  <div className="row">
                    <Col md="6" className="vendor-outer-wrap">
                      <div className="vendor-detail-wrap">
                        <h4>
                          <b>{Admin_orders?.VendorName?.[language]}:</b>{" "}
                          {props?.order_view?.cmpny_detail?.name}
                        </h4>
                        <h4>
                          <b>{Admin_orders?.VendorEmail?.[language]}:</b>{" "}
                          {props?.order_view?.cmpny_detail?.email}
                        </h4>
                        <h4>
                          <b>{Admin_orders?.RegistrationID?.[language]}:</b>{" "}
                          {props?.order_view?.cmpny_detail?.registration_id}
                        </h4>
                        <h4>
                          <b>{Admin_orders?.AllocatedQuantity?.[language]}:</b>{" "}
                          {props?.order_view?.order_details?.quantity}
                        </h4>
                      </div>
                    </Col>
                  </div>
                </div>
                {/* <hr /> */}
              </div>
              {/* Checkbox and download btn */}
              {/* <div className="flex-between">
                <div className="agree-checkbox-wrap">
                  <Input id="checkbox2" type="checkbox" />{" "}
                  <Label check>Click to confirm the Work Order</Label>
                </div>
                <div className="download-btn">
                  <Button
                    color="outline"
                    className="download-button"
                    onClick={() => window.print()}
                  >
                    <i className="bi bi-download me-2"></i>Download
                  </Button>
                </div>
              </div> */}
            </div>

            {/* 
              start documents
              */}
            <div className="table-outer-wrap doc-approval-outer-wrap">
              {/* {
                props?.deliverd == 1 && (<>   <div className="table-wrap responsive-table ">
                  <h5 className="sub-heading">Document Approval</h5>
                  <Table className="w-100 doc-approval-wrap table-striped">
                    <thead>
                      <tr>
                        <th>Documents</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props?.order_view?.get_delivered_docs?.length !== 0 ?
                        <>
                          {
                            props?.order_view?.get_delivered_docs?.map((item: any) => {

                              let invoice = item?.type == 1 ? "Chellan" : null
                              let workorder = item?.type == 2 ? "WorkOrder" : null
                              let delivery = item?.type == 3 ? "Invoice" : null
                              let image = item?.type == 4 ? "Image" : null
                              let Completion = item?.type == 5 ? "Work Completion" : null
                              return (
                                <>

                                  {
                                    item?.type !== 4 && (<>
                                      <tr>
                                        <td>
                                          <div className="input-wrap doc-wrap">
                                            <div className="input-group">
                                              <Input
                                                disabled
                                                type="text"
                                                class="form-control"
                                                value={`${workorder || delivery || invoice || Completion}`}
                                              />
                                              <div className="checkbox-image-circle">
                                                <i className="bi bi-check-circle-fill"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <span className="mobile-heading">Action:</span>
                                          <div className="action-flex-btn">
                                            {

                                              item?.type !== 4 && (<> <a
                                                href={item?.docs || item?.wo_doc}
                                                target="_blank"
                                                className="rounded-tag" rel="noreferrer"
                                              >
                                                <i title="View" className="icons bi bi-eye lg"></i>

                                              </a></>)
                                            }
                                            
                                          </div>
                                        </td>
                                      </tr>
                                    </>)}

                           

                                  {
                                    item?.type == 0 && (
                                      <>
                                        {
                                          item?.getimages?.map((item: any) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>
                                                    <div className="input-wrap doc-wrap">
                                                      <div className="input-group">
                                                        <Input
                                                          disabled
                                                          type="text"
                                                          class="form-control"
                                                          value={`${image || workorder || delivery || invoice || Completion}`}
                                                        />
                                                        <div className="checkbox-image-circle">
                                                          <i className="bi bi-check-circle-fill"></i>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <span className="mobile-heading">Action:</span>
                                                    <div className="action-flex-btn">
                                                      <a
                                                        href={item?.image}
                                                        target="_blank"
                                                        className="rounded-tag" rel="noreferrer"
                                                      >
                                                        <i title="View" className="icons bi bi-eye lg"></i>
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr >
                                              </>
                                            )
                                          })}
                                      </>
                                    )}



                                </>)
                            })
                          }
                        </>
                        :
                        <tr>
                          <td colSpan={2}>
                            <div className="input-wrap doc-wrap text-center">
                              <span>No Document Found</span>
                            </div>
                          </td>
                        </tr>
                      }

                    </tbody>
                  </Table>
                </div></>)} */}

              <hr />
              {props?.confirmed == 1 && (
                <>
                  {props?.order_view?.vendor_to_pmu_payment !== null && (
                    <>
                      <div className="table-outer-wrap doc-approval-outer-wrap">
                        <div className="table-wrap responsive-table ">
                          <h5 className="sub-heading">
                            {Admin_orders?.DocumentApproval?.[language]}
                          </h5>
                          <Table className="w-100 doc-approval-wrap table-striped">
                            <thead>
                              <tr>
                                <th>{Admin_orders?.Documents?.[language]}</th>
                                <th>{Admin_orders?.Action?.[language]}</th>
                                <th>{Admin_orders?.Status?.[language]}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="input-wrap doc-wrap">
                                    <div className="input-group">
                                      <Input
                                        disabled
                                        type="text"
                                        class="form-control"
                                        value={"EMD"}
                                      />
                                      <div className="checkbox-image-circle">
                                        <i className="bi bi-check-circle-fill"></i>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    {Admin_orders?.Action?.[language]}:
                                  </span>
                                  <div className="action-flex-btn">
                                    <a
                                      href={
                                        props?.order_view?.vendor_to_pmu_payment
                                          ?.docs
                                      }
                                      target="_blank"
                                      className="rounded-tag"
                                    >
                                      <i
                                        title="View"
                                        className="icons bi bi-eye lg"
                                      ></i>
                                    </a>
                                    {props?.order_view?.vendor_to_pmu_payment
                                      ?.status == 1 ? (
                                      <a
                                        // onClick={() => props?.vendorApprovalDocument(1)}
                                        //target="_blank"
                                        className="rounded-tag bg-success"
                                      >
                                        <i className="icons icons-accept-white"></i>
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() =>
                                          props?.vendorApprovalDocument(1)
                                        }
                                        target="_blank"
                                        className="rounded-tag bg-success"
                                      >
                                        <i className="icons icons-accept-white"></i>
                                      </a>
                                    )}

                                    {props?.order_view?.vendor_to_pmu_payment
                                      ?.status == 0 ? (
                                      <a
                                        target="_blank"
                                        className="rounded-tag bg-danger"
                                      >
                                        <i className="bi bi-x-circle text-white"></i>
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() =>
                                          props?.vendorApprovalDocument(0)
                                        }
                                        target="_blank"
                                        className="rounded-tag bg-danger"
                                      >
                                        <i className="bi bi-x-circle text-white"></i>
                                      </a>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <span className="mobile-heading">
                                    {Admin_orders?.Status?.[language]}:
                                  </span>
                                  {props?.order_view?.vendor_to_pmu_payment
                                    ?.status == 0 ? (
                                    <span className="text-danger">
                                      {Admin_orders?.Rejected?.[language]}
                                    </span>
                                  ) : (
                                    <span className="text-success">
                                      {Admin_orders?.Approved?.[language]}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <span>
                            <strong>
                              {Admin_orders?.DateofPayment?.[language]} :
                            </strong>{" "}
                            {convertISODateToDateTime(
                              props?.order_view?.vendor_to_pmu_payment
                                ?.created_at
                            )}
                          </span>
                          <p>
                            {Admin_orders?.Paymenttype?.[language]} :
                            {props?.order_view?.vendor_to_pmu_payment
                              ?.payment_type == constants?.PAYMENT_TYPE?.CHELLAN
                              ? "Chellan"
                              : props?.order_view?.vendor_to_pmu_payment
                                  ?.payment_type ==
                                constants?.PAYMENT_TYPE?.CHEQUE
                              ? "Cheque"
                              : props?.order_view?.vendor_to_pmu_payment
                                  ?.payment_type ==
                                constants?.PAYMENT_TYPE?.BANK
                              ? "Bank Transfer"
                              : props?.order_view?.vendor_to_pmu_payment
                                  ?.payment_type ==
                                constants?.PAYMENT_TYPE?.NETBANK
                              ? "Net Banking"
                              : null}
                          </p>
                        </div>
                        <hr />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </ModalContainer>
    </React.Fragment>
  );
};

export default OrderView2;