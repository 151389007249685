import { ErrorMessage } from "formik";
import { useState,useContext } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Form
} from "reactstrap";
import TextError from "../../../../../Components/Errors/TextError";
import { company_type_list } from "../../../../../Library/Constants/MapConstants";
import DatePicker from 'react-date-picker'
import ModalContainer from "../../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../../Components/Context/Context";
import { admin_empanelment } from "../../../../../Library/LanguageConversion/admin_empanelment";

interface propTypes {
  showAddVendor: boolean;
  setShowAddVendor: Function;
  handleSubmit: any;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  resetForm: Function;
  productList: any;
  gst_class: any;
  vendor_types_data: any;
  isSubmitting: any;
  setFieldValue: any;
  setFieldTouched: any;
  files: any;
  setFile: any;
}

const AddVendorModal = (props: propTypes) => {
  const { language, setLanguage } = useContext(Context);

  const preventNegativeValues = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/; // regex pattern to match only numeric input
    if (!regex.test(keyValue)) {
      e.preventDefault();
      ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  }

  // file selection 
  const handleFileChange = (event: any) => {
    props?.setFile(event.target.files[0]);
  };

  function minExpirtDate(){
      const date = new Date(props?.values?.empanelment_date);
      // console.log(typeof date, "dgfuhf")
      return props?.values?.empanelment_date ?date.setDate(date.getDate() + 1)
      : new Date() || new Date()
  }

  return (
    <>
      <ModalContainer
        open={props.showAddVendor}
        setOpen={() => {
          props.resetForm();
          props.setShowAddVendor(false)
        }}
        header={admin_empanelment?. Add_vendor[language]}
      >
        <div className="add-vendor-outer">
          <div className="vendor-details text-center">
            {/* <div className="circle-icon mb-2">
                <img
                  src="images/Addvendorimg.svg"
                  alt="img"
                  className="d-block mx-auto"
                />
              </div> */}
            {/* <h5 className="text-center">Vendor 1</h5> */}
            {/* <p className="mb-0">
                Lorem Ipsum is simply dummy text of the printing{" "}
              </p>
              <p className="mb-0">12/12/2022-22/01/2023</p> */}
          </div>
          <Form className="form-wrap" onSubmit={props.handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormGroup className="form-group">
                  <Label className="large-screen-view">
                  {admin_empanelment?.Vendor_type?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="select"
                    label="Vendor"
                    name="vendor_type"
                    value={props.values.vendor_type}
                    className={`form-control ${props?.touched.vendor_type && props?.errors.vendor_type
                      ? "is-invalid"
                      : ""
                      }`}
                    onChange={props.handleChange}
                  >
                    <option value="">{admin_empanelment?.Select_Vendor_Type?.[language]}</option>
                    {props?.vendor_types_data?.map((item: any, i: number) => <option key={i} value={item?.value}>{item?.name}</option>
                    )}
                  </Input>
                  <ErrorMessage name="vendor_type" component={TextError} />
                </FormGroup>
              </Col>

              {props?.values?.vendor_type == '10' && <Col sm={6}>
                <FormGroup className="form-group">
                  <Label className="large-screen-view">
                  {admin_empanelment?.Startup_Certificate?.[language]} 
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <div className="image-input">
                    <Input
                      type="file"
                      name="startup_certificate"
                      id="upload-cheque"
                      onChange={(e: any) => {
                        handleFileChange(e)
                        props.setFieldValue('startup_certificate', 'dummy_value')
                      }}
                      className={`${props?.touched.file && props?.errors.file ? "is-invalid" : ""}`}
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    /><Label className="image-button" for="upload-cheque" id="upload-cheque">Choose Documents</Label>
                  </div>
                  <ErrorMessage name="startup_certificate" component={TextError} />
                </FormGroup>
              </Col>}

              <Col sm={6}>
                <FormGroup className="form-group">
                  <Label className="large-screen-view">
                  {admin_empanelment?.GST_Class?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="select"
                    label="GST Class"
                    name="gst_class"
                    className={`form-control ${props?.touched.gst_class && props?.errors.gst_class
                      ? "is-invalid"
                      : ""
                      }`}
                    value={props.values.gst_class}
                    onChange={props.handleChange}
                  >
                    <option value="">{admin_empanelment?.Select_GST_Class?.[language]}</option>
                    {props?.gst_class?.map((item: any, i: number) => <option key={i} value={item?.value}>{item?.name}</option>
                    )}
                  </Input>
                  <ErrorMessage name="gst_class" component={TextError} />
                </FormGroup>
              </Col>

              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label>
                  {admin_empanelment?.Pan_Number?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="text"
                    name="pan_number"
                    maxLength={10}
                    placeholder=""
                    className={`form-control ${props?.touched.pan_number && props?.errors.pan_number
                      ? "is-invalid"
                      : ""
                      }`}
                    value={props.values.pan_number}
                    onChange={props.handleChange}
                  />
                  <ErrorMessage name="pan_number" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label for="">
                  {admin_empanelment?.GST_Number?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="text"
                    maxLength={15}
                    className={`form-control ${props?.touched.gst_number && props?.errors.gst_number
                      ? "is-invalid"
                      : ""
                      }`}
                    name="gst_number"
                    value={props.values.gst_number}
                    onChange={props.handleChange}
                  />
                  <ErrorMessage name="gst_number" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label for="">
                  {admin_empanelment?.Company_Name?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="text"
                    name="company_name"
                    placeholder=""
                    className={`form-control ${props?.touched.company_name &&
                      props?.errors.company_name
                      ? "is-invalid"
                      : ""
                      }`}
                    value={props.values.company_name}
                    onChange={props.handleChange}
                  />
                  <ErrorMessage name="company_name" component={TextError} />
                </FormGroup>
              </Col>

              <Col md={6} sm={6}>
                <FormGroup className="form-group">
                  <Label for="">
                  {admin_empanelment?.Company_Email?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    maxLength={35}
                    placeholder=""
                    className={`form-control ${props?.touched.email && props?.errors.email
                      ? "is-invalid"
                      : ""
                      }`}
                    value={props.values.email}
                    onChange={props.handleChange}
                  />
                  <ErrorMessage name="email" component={TextError} />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup className="form-group">
                  <Label className="large-screen-view">
                  {admin_empanelment?.Company_Type?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="select"
                    label="Company type"
                    name="company_type"
                    value={props.values.company_type}
                    className={`form-control ${props?.touched.company_type && props?.errors.company_type
                      ? "is-invalid"
                      : ""
                      }`}
                    onChange={props.handleChange}
                  >
                    <option value="">{admin_empanelment?.select_Company_Type?.[language]}</option>
                    {company_type_list?.map((item: any, i: number) => <option key={i} value={item?.value}>{item?.name}</option>
                    )}
                  </Input>
                  <ErrorMessage name="company_type" component={TextError} />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup className="custom-input date">
                  <Label for="">
                  {admin_empanelment?.Company_Incorporation_Date?.[language]}
                    <i
                      className="fa fa-asterisk required-field form-label"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <DatePicker onChange={(e: any) => {
                    props?.setFieldValue("comp_inc_date", e);
                  }}
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={props?.values?.comp_inc_date}
                    name="comp_inc_date"
                    calendarClassName={`form-control ${props?.touched.comp_inc_date &&
                      props?.errors.comp_inc_date
                      ? "is-invalid"
                      : ""
                      }`}
                    maxDate={new Date()}
                  />
                  <ErrorMessage name="comp_inc_date" component={TextError} />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup className="form-group date">
                  <Label for="">
                  {admin_empanelment?.Empanelment_Date?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <DatePicker
                    onChange={(e: any) => {
                      props?.setFieldValue("expiry_date", '');
                      props?.setFieldValue("empanelment_date", e);
                    }}
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={props?.values?.empanelment_date}
                    name="empanelment_date"
                    calendarClassName={`form-control ${props?.touched.empanelment_date &&
                      props?.errors.empanelment_date
                      ? "is-invalid"
                      : ""
                      }`}
                    maxDate={new Date()} />
                  <ErrorMessage name="empanelment_date" component={TextError} />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup className="form-group date">
                  <Label for="">
                  {admin_empanelment?.Empanelment_Expiry_Date?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <DatePicker onChange={(e: any) => {
                    props?.setFieldValue("expiry_date", e);
                  }}
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    value={props?.values?.expiry_date}
                    name="empanelment_date"
                    calendarClassName={`form-control ${props?.touched.expiry_date &&
                      props?.errors.expiry_date
                      ? "is-invalid"
                      : ""
                      }`}
                    minDate={new Date(minExpirtDate())}
                  />
                  <ErrorMessage name="expiry_date" component={TextError} />
                </FormGroup>
              </Col>

              <Col md={4} sm={6} lg={6}>
                <FormGroup className="form-group">
                  <Label for="">
                  {admin_empanelment?.Company_Registration_Number?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="registration_id"
                      placeholder=""
                      className={`form-control ${props?.touched.registration_id &&
                        props?.errors.registration_id
                        ? "is-invalid"
                        : ""
                        }`}
                      value={props.values.registration_id}
                      onChange={props.handleChange}
                    />
                  </InputGroup>
                  <div>
                    <ErrorMessage
                      name="registration_id"
                      component={TextError}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col md={4} sm={6} lg={6}>
                <FormGroup className="form-group">
                  <Label for="">
                  {admin_empanelment?. Contact_Person_Name[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    type="text"
                    name="full_name"
                    placeholder=""
                    className={`form-control ${props?.touched.full_name && props?.errors.full_name
                      ? "is-invalid"
                      : ""
                      }`}
                    value={props.values.full_name}
                    onChange={props.handleChange}
                  />
                  <ErrorMessage name="full_name" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6} lg={6}>
                <FormGroup className="form-group">
                  <Label for="">
                  {admin_empanelment?.Contact_Person_Number?.[language]}
                    <i
                      className="fa fa-asterisk required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="phone"
                      onKeyPress={preventNegativeValues}
                      className={`form-control ${props?.touched.phone && props?.errors.phone
                        ? "is-invalid"
                        : ""
                        }`}
                      maxLength={11}
                      value={props.values.phone}
                      onChange={props.handleChange}
                    />
                  </InputGroup>
                  <div>
                    <ErrorMessage name="phone" component={TextError} />
                  </div>
                </FormGroup>
              </Col>

              {/* <Col md={12}>
                    <FormGroup
                      className={"d-flex align-items-center gap-2 mb-5"}
                    >
                      <Input type="checkbox" id="agree" />{" "}
                      <Label check htmlFor="agree">
                        Lorem Ipsum is simply dummy text of the printing
                      </Label>
                    </FormGroup> 
                  </Col> */}
              <div className="footer-sticky">
                <FormGroup className={"text-end"}>
                  <Button
                    type="submit"
                    color="primary"
                    className={"px-5"}
                    disabled={props?.isSubmitting}
                  >
                    {props?.isSubmitting ? (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    ) : 
                    admin_empanelment?.Add_a_Vendor[language]
                    }
                    
                  </Button>
                </FormGroup>
              </div>
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </>
  );
};

export default AddVendorModal;
