export const Admin_Verifications: any = {
  //--------------Admin----Verifications------Tabs------Modals----DraftDetailsModal------//

  AccountHolderName: {
    en: "Account Holder Name",
    ka: "ಖಾತೆದಾರನ ಹೆಸರು",
  },
  Amount: {
    en: "Amount",
    ka: "ಮೊತ್ತ",
  },
  AccountNumber: {
    en: "Account Number",
    ka: "ಖಾತೆ ಸಂಖ್ಯೆ",
  },
  BankName: {
    en: "Bank Name",
    ka: "ಬ್ಯಾಂಕ್ ಹೆಸರು",
  },
  Branch: {
    en: "Branch",
    ka: "ಶಾಖೆ",
  },
  IFSC: {
    en: "IFSC",
    ka: "ಐ.ಎಫ್.ಎಸ್.ಸಿ",
  },
  ChequeDate: {
    en: "Cheque Date",
    ka: "ಚೆಕ್ ದಿನಾಂಕ",
  },
  Date: {
    en: 'Date',
    ka: 'ದಿನಾಂಕ'
},
  ChequeNumber: {
    en: "Cheque Number",
    ka: "ಚೆಕ್ ಸಂಖ್ಯೆ",
  },
  PaymentType: {
    en: "Payment Type",
    ka: "ಪಾವತಿ ಪ್ರಕಾರ",
  },
  ReceiptNumber: {
    en: "Receipt Number",
    ka: "ರಸೀತು ಸಂಖ್ಯೆ",
  },
  TransactionID: {
    en: "Transaction ID",
    ka: "ವಹಿವಾಟು",
  },
  Uploadeddoc: {
    en: "Uploaded doc",
    ka: "ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
  },
  View: {
    en: "View",
    ka: "ವೀಕ್ಷಿಸು",
  },
  NoDetailsFound: {
    en: "No Details Found",
    ka: "ಯಾವುದೇ ವಿವರಗಳು ಸಿಗಲಿಲ್ಲ",
  },
  Approve: {
    en: "Approve",
    ka: "ಅನುಮೋದಿಸಿ",
  },
  Approved: {
    en: "Approved",
    ka: "ಅನುಮೋದಿತ",
  },

  //-------Admin----Verifications------Tabs------Modals----AccountVerification------//

  All: {
    en: "All",
    ka: "ಎಲ್ಲಾ",
  },
  Pending: {
    en: "Pending",
    ka: "ಬಾಕಿ",
  },
  SelectVendortype: {
    en: "Select Vendor type",
    ka: "ವೆಂಡರ್ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  },
  SlNo: {
    en: "Sl No",
    ka: "ಕ್ರ.ಸಂ",
  },
  AccountNo: {
    en: "Account No",
    ka: "ಖಾತೆ ಸಂಖ್ಯೆ",
  },
  IFSCCode: {
    en: "IFSC Code",
    ka: "ಐ.ಎಫ್.ಎಸ್.ಸಿ ಕೋಡ್",
  },
  Action: {
    en: "Action",
    ka: "ಕ್ರಿಯೆ",
  },
  Status: {
    en: "Status",
    ka: "ಸ್ಥಿತಿ",
  },
  PendingVerification: {
    en: "Pending Verification",
    ka: "ಸ್ಬಾಕಿ ಉಳಿದಿರುವ ಪರಿಶೀಲನೆ",
  },
  Verified: {
    en: "Verified",
    ka: "ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
  },
  Showing: {
    en: "Showing ",
    ka: "ತೋರಿಸುತ್ತಿದೆ",
  },
  to: {
    en: "to",
    ka: "ಗೆ",
  },
  of: {
    en: "of",
    ka: "ನ",
  },
  entries: {
    en: "entries",
    ka: "ಪ್ರವೇಶಗಳು",
  },

  //-------Admin----Verifications------Tabs------Modals----AddrVerification------//

  Selectdepartment: {
    en: "Select department",
    ka: "ಇಲಾಖೆ ಆಯ್ಕೆಮಾಡಿ",
  },

  Name: {
    en: "Name",
    ka: "ಹೆಸರು",
  },
  Email: {
    en: "Email",
    ka: "ಇಮೇಲ್",
  },
  Address: {
    en: "Address",
    ka: "ವಿಳಾಸ",
  },
  Phone: {
    en: "Phone",
    ka: "ಫೋನ್",
  },
  //-------Admin----Verifications------Tabs------Modals----DeptVerification------//

  UserName: {
    en: "User Name",
    ka: "ಬಳಕೆದಾರ ಹೆಸರು",
  },
  PhoneNumber: {
    en: "Phone Number",
    ka: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
  },

  //-------Admin----Verifications------Tabs------Modals----DraftVerification------//

  DepartmentName: {
    en: "Department Name",
    ka: "ಇಲಾಖೆಯ",
  },
  OrderID: {
    en: "Order ID",
    ka: "ಆದೇಶ ಐಡಿ",
  },
  VendorName: {
    en: "Vendor Name",
    ka: "ವೆಂದರ್ ಹೆಸರು",
  },

  //-------Admin----Verifications------Tabs------Modals----VerificationsLayout------//


  Department: {
    en: "Department",
    ka: "ಇಲಾಖೆ",
  },
  Draft: {
    en: "Draft",
    ka: "ಡ್ರಾಫ್ಟ್",
  },
  Dashboard: {
    en: "Dashboard",
    ka: "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
  },
  Account: {
    en: "Account",
    ka: "ಖಾತೆ",
  },
  Vendor_draft: {
    en: "Vendor Draft",
    ka: "ವೆಂದರ ಡ್ರಾಫ್ಟ್",
  },
};

