import React from 'react'
import axios, { AxiosRequestConfig } from 'axios';

const DeletableFile = () => {
    
    const handleSubmit = () => {
        const url = 'https://apiuat.bankofbaroda.co.in:4443/olive/publisher/paymentsTxnInq';
        const data = {
            "paymentTxnReq": {
                "custTxnRef": "REF151120161544",
                "beneAccNo": "109566016496",
                "beneID": "",
                "beneName": "Ashutosh Upadhyay",
                "beneAddr1": "MUMBAI",
                "beneAddr2": "MAHARASHTRA",
                "beneAddr3": "India",
                "IFSC": "DNSB0000021",
                "valueDate": "2021-04-17",
                "txnDate": "2021-04-21",
                "tranAmount": "15.09",
                "remitInfo1": "Towards API Based Testing",
                "paymentType": "NEFT",
                "beneAccType": "SA",
                "remarks": "Sample NEFT API Based Txn at 1509",
                "beneMail": "vikas.bhatt@idfcbank.com",
                "beneMobile": "919619561619",
                "enrichment1": "",
                "enrichment2": "",
                "enrichment3": "",
                "enrichment4": "",
                "enrichment5": "",
                "reservedfield1": "",
                "reservedfield2": "",
                "reservedfield3": "",
                "reservedfield4": "",
                "reservedfield5": "",
                "debitAcNo": "",
                "isSubmbr": "N",
                "submbr_Rmtr_Name": "",
                "submbr_Rmtr_Acctype": "",
                "submbr_Rmtr_Purpcd": "",
                "submbr_Rmtr_Accno": "",
                "submbr_Rmtr_Addfld": "",
                "invDtlReq": "N"
            }
        };
        const headers: AxiosRequestConfig['headers'] = {
            "ClientCode": "RACHANA",
            "Content-Type": "application/json"
        };

        axios.post(url, data, { headers })
            .then(response => {
                console.log('Response:', response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
        <div>
            <button onClick={handleSubmit}>Call API</button>
        </div>
    )
}

export default DeletableFile