import { useContext } from "react";
import { CurrentTabLang } from "../../../Library/LanguageConversion/CurrentTabLang";
import { Context } from "../../../Components/Context/Context";
interface propsType {
  pathname: string;
}

const CurrentTab = (props: propsType) => {
  const { language, setLanguage } = useContext(Context);

  // to display current tab name in Navbar
  return (
    <h2 className="mb-0">
      {props.pathname === "/dashboard"
        ? `${CurrentTabLang?.dashboard[language]}`
        : props.pathname === "/Overview"
        ? `${CurrentTabLang?.overview[language]}`
        : props.pathname === "/products"
        ? `${CurrentTabLang?.products[language]}`
        : props.pathname.includes("/products")
        ? `${CurrentTabLang?.products[language]}`
        : props.pathname === "/rate"
        ? `${CurrentTabLang?.uploaded_rate[language]}`
        : props.pathname === "/rate-contract"
        ? `${CurrentTabLang?.rate_contract[language]}`
        : props.pathname === "/rate-contract-assigned"
        ? `${CurrentTabLang?.rate_contract_assigned[language]}`
        : props.pathname === "/add-tendor"
        ? `${CurrentTabLang?.tender[language]}`
        : props.pathname === "/empanelments"
        ? `${CurrentTabLang?.vendor_registration[language]}`
        : props.pathname === "/self-registered-vendors"
        ? `${CurrentTabLang?.vendor_registration[language]}`
        : props.pathname === "/active-vendors"
        ? `${CurrentTabLang?.active_vendors[language]}`
        : props.pathname === "/inactive-vendor"
        ? `${CurrentTabLang?.inactive_vendors[language]}`
        : props.pathname === "/verify-vendor"
        ? `${CurrentTabLang?.verify_vendors[language]}`
        : props.pathname === "/expired-vendor"
        ? `${CurrentTabLang?.expired_vendors[language]}`
        : props.pathname === "/tobe-expired-vendor"
        ? `${CurrentTabLang?.vendors_going_to_expire[language]}`
        : props.pathname === "/basic-details-vendor"
        ? `${CurrentTabLang?.basic_details[language]}`
        : props.pathname === "/advanced-details-vendor"
        ? `${CurrentTabLang?.advanced_details[language]}`
        : props.pathname === "/doc-verification"
        ? `${CurrentTabLang?.document_verification[language]}`
                                          
        : props.pathname === "/received-orders" 
        ? `${CurrentTabLang?.regular_orders[language]}`
        :props.pathname === "/confirmed-orders" 
        ? `${CurrentTabLang?.Confirmed_Orders[language]}`
          :props.pathname === "/approved-orders" 
        ? `${CurrentTabLang?.Approved_Orders[language]}`
          :props.pathname === "/processed-orders" 
        ? `${CurrentTabLang?.Processed_Orders[language]}`
          :props.pathname === "/drafted-orders" 
        ? `${CurrentTabLang?.Drafted_Orders[language]}`
         :props.pathname === "/rejected-orders" 
        ? `${CurrentTabLang?.Rejected_Orders[language]}`
          :props.pathname === "/cancelled-orders"
        ? `${CurrentTabLang?.Cancelled_Orders[language]}`
                                            
        : props.pathname === "/open-tickets"
        ? `${CurrentTabLang?.open_tickets[language]}`
        : props.pathname === "/closed-tickets"
        ? `${CurrentTabLang?.closed_tickets[language]}`
        : props.pathname === "/pending-tickets"
        ? `${CurrentTabLang?.pending_tickets[language]}`
        : props.pathname === "/amc"
        ? `${CurrentTabLang?.amc[language]}`
        : props.pathname === "/qa-vendor"
        ? `${CurrentTabLang?.third_party_inspection[language]}`
        : props.pathname === "/delivered-orders"
        ? `${CurrentTabLang?.delivered_orders[language]}`
        : props.pathname === "/tds-list"
        ? `${CurrentTabLang?.tds[language]}`
        : props.pathname === "/tds-to-vendor-list"
        ? `${CurrentTabLang?.tds[language]}`
        : props.pathname === "/orders-cancelled"
        ? `${CurrentTabLang?.cancelled_orders[language]}`
        : props.pathname === "/payment-received"
        ? `${CurrentTabLang?.payment_received[language]}`
        : props.pathname === "/payment-to-vendors"
        ? `${CurrentTabLang?.payment_to_vendors[language]}`
        : props.pathname === "/cancelled-payment"
        ? `${CurrentTabLang?.cancelled_payments[language]}`
        : props.pathname === "/addr-verifications"
        ? `${CurrentTabLang?.addr_verification[language]}`
        : props.pathname === "/department-verifications"
        ? `${CurrentTabLang?.dept_verification[language]}`
        : props.pathname === "/draft-verifications"
        ? `${CurrentTabLang?.draft_verification[language]}`
        : props.pathname === "/account-verifications"
        ? `${CurrentTabLang?.account_verification[language]}`
        : props.pathname === "/notifications"
        ? `${CurrentTabLang?.notifications[language]}`
        : props.pathname === "/specifications"
        ? `${CurrentTabLang?.specifications[language]}`
        : props.pathname === "/department/dashboard"
        ? `${CurrentTabLang?.dashboard[language]}`
        : props.pathname === "/department/products"
        ? `${CurrentTabLang?.products[language]}`
        : props.pathname === "/department/products-view"
        ? `${CurrentTabLang?.products[language]}`
        : props.pathname === "/department/cart"
        ? `${CurrentTabLang?.cart[language]}`
        : props.pathname === "/department/cart-custom-orders"
        ? `${CurrentTabLang?.custom_orders[language]}`
        : props.pathname === "/department/custom-orders"
        ? `${CurrentTabLang?.custom_orders[language]}`
        : props.pathname === "/department/orders" ||
          props.pathname === "/department/processing-orders" ||
          props.pathname === "/department/placed-orders" ||
          props.pathname === "/department/payment-pending-orders" ||
          props.pathname === "/department/cancelled-orders"
        ? `${CurrentTabLang?.orders[language]}`
        : props.pathname === "/department/tickets"
        ? `${CurrentTabLang?.tickets[language]}`
        : props.pathname === "/department/processing-tickets"
        ? `${CurrentTabLang?.tickets[language]}`
        : props.pathname === "/department/pending-tickets"
        ? `${CurrentTabLang?.tickets[language]}`
        : props.pathname === "/department/resolved-tickets"
        ? `${CurrentTabLang?.tickets[language]}`
        : props.pathname === "/department/amc"
        ? `${CurrentTabLang?.amc[language]}`
        : props.pathname === "/department/draft" ||
          props.pathname === "/department/workorder"
        ? `${CurrentTabLang?.draft_workorder[language]}`
        : props.pathname === "/department/delivery-address"
        ? `${CurrentTabLang?.delivery_address[language]}`
        : props.pathname === "/department/settings"
        ? `${CurrentTabLang?.profile[language]}`
        : props.pathname === "/profile-settings"
        ? `${CurrentTabLang?.profile_settings[language]}`
        : props.pathname === "/custom-orders"
        ? `${CurrentTabLang?.custom_orders[language]}`
        : props.pathname === "/reports"
        ? `${CurrentTabLang?.reports[language]}`
        : props.pathname === "/departments"
        ? `${CurrentTabLang?.departments[language]}`
        : props.pathname === "/department/list"
        ? `${CurrentTabLang?.departments[language]}`
        : // Vendor tab
        props.pathname === "/overview-vendor"
        ? `${CurrentTabLang?.dashboard[language]}`
        : props.pathname === "/purchase-order-vendor"
        ? `${CurrentTabLang?.purchase_order[language]}`
        : props.pathname === "/services-vendor"
        ? `${CurrentTabLang?.services[language]}`
        : props.pathname === "/tickets-vendor"
        ? `${CurrentTabLang?.tickets[language]}`
        : props.pathname === "/amc-vendor"
        ? `${CurrentTabLang?.amc[language]}`
        : props.pathname === "/delivered-vendor"
        ? `${CurrentTabLang?.delivered[language]}`
        : props.pathname === "/schedule-maintenance-vendor-completed"
        ? `${CurrentTabLang?.schedule_maintenance[language]}`
        : props.pathname === "/feedback-summary-vendor"
        ? `${CurrentTabLang?.feedback_summary[language]}`
        : props.pathname === "/settings-vendor"
        ? `${CurrentTabLang?.settings[language]}`
        : props.pathname === "/received-order-vendor"
        ? `${CurrentTabLang?.received_orders[language]}`
        : props.pathname === "/processed-order-vendor"
        ? `${CurrentTabLang?.processed_orders[language]}`
        : props.pathname === "/outofdelivery"
        ? `${CurrentTabLang?.outfordelivery_orders[language]}`
        : props.pathname === "/cancelled-order-vendor"
        ? `${CurrentTabLang?.cancelled_orders[language]}`
        : props.pathname === "/payment-vendor"
        ? `${CurrentTabLang?.cancelled_orders[language]}`
        : props.pathname === "/payment-received-vendor"
        ? `${CurrentTabLang?.payment_received[language]}`
        : props.pathname === "/vendor-open-tickets"
        ? `${CurrentTabLang?.open_tickets[language]}`
        : props.pathname === "/vendor-pending-tickets"
        ? `${CurrentTabLang?.pending_tickets[language]}`
        : props.pathname === "/vendor-closed-tickets"
        ? `${CurrentTabLang?.closed_tickets[language]}`
        : props.pathname === "/vendor-documents-view"
        ? `${CurrentTabLang?.documents[language]}`
        : props.pathname === "/vendor-profile-settings"
        ? `${CurrentTabLang?.profile_settings[language]}`
        : props.pathname === "/vendor-documents"
        ? `${CurrentTabLang?.profile_settings[language]}`
        : props.pathname === "/vendor-advanced-reupload"
        ? "Advanced Reupload"
        : props.pathname === "/vendor-account-details"
        ? `${CurrentTabLang?.account_details[language]}`
        : props.pathname === "/secure-payment"
        ? `${CurrentTabLang?.payments[language]}`
        : props.pathname === "/document-upload"
        ? `${CurrentTabLang?.document_upload[language]}`
        : props.pathname === "/advanced-registration"
        ? `${CurrentTabLang?.advanced_registration[language]}`
        : props.pathname === "/basic-details-view"
        ? `${CurrentTabLang?.basic_details[language]}`
        : props.pathname === "/rejected-order-vendor"
        ? `${CurrentTabLang?.rejected_orders[language]}`
        : props.pathname === "/start-up-document-upload"
        ? `${CurrentTabLang?.start_up_documents[language]}`
        : props.pathname === "/emd-details"
        ? `${CurrentTabLang?.no_refund[language]}`
        : // QA Vendor
        props.pathname === "/qa-vendor/orders"
        ? `${CurrentTabLang?.orders[language]}`
        : null}
    </h2>
  );
};

export default CurrentTab;
