import React, {useContext} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../Components/Context/Context";
import { Admin_Delivered } from "../../../Library/LanguageConversion/Admin_Delivered";

export default function DeliveredLayout(props:any) {
  const { pathname } = useLocation()
  const {language,setLanguage} = useContext(Context)
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
        {/* tabs */}
        <div className="custom-tabs">
          {/* <NavLink to="/delivered-orders">
            Delivered Orders
          </NavLink> */}
          {/* <NavLink to="/orders-cancelled">
            Cancelled Orders
          </NavLink> */}
        </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
              {Admin_Delivered?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
              {
                  pathname=="/delivered-orders"?Admin_Delivered?.Delivered_Orders[language]:
                  pathname=="/orders-cancelled"?"Orders Cancelled": "Delivered"
                }
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}


