import React, {useContext} from "react";
import { Context } from "../../../../Components/Context/Context";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Admin_services } from "../../../../Library/LanguageConversion/Admin_Services";

function TicketLayout(props: any) {
  const {language,setLanguage}=useContext(Context);
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/open-tickets">{Admin_services?.OpenTickets[language]}</NavLink>
            <NavLink to="/pending-tickets">{Admin_services?.PendingTickets[language]}</NavLink>
            <NavLink to="/closed-tickets">{Admin_services?.ClosedTickets[language]}</NavLink>
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
              {Admin_services?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <Link className="active" to="/open-tickets">
              {Admin_services?.Services[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {pathname == "/open-tickets"
                  ? "Open Tickets"
                  : pathname == "/closed-tickets"
                  ? "Closed Tickets"
                  : pathname == "/pending-tickets"
                  ? "Pending Tickets"
                  : "Tickets"}
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default TicketLayout;
