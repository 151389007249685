import { ErrorMessage } from "formik";
import React, { useState,useContext } from "react";
import { Context } from "../../../Components/Context/Context";
import { Admin_orders } from "../../../Library/LanguageConversion/Admin_orders";
import {
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    Collapse,
} from "reactstrap";
import TextError from "../../../Components/Errors/TextError";
import { convertISODateToDateTime } from "../../../Library/Constants/helpers";
import Select from 'react-select';
import constants from "../../../Library/Constants/Constants";
import ModalContainer from "../../../Components/Modal/ModalContainer";



const CoustmeOrdersModal = (props: any) => {
    const { language, setLanguage } = useContext(Context);
    const cat_Change = (e: any) => {
        if (e.target.value) {
            console.log(e.target.value, "asfjshdfjgsdhgf")
            props?.setFieldValue("category", e.target.value)
            props?.getProduct(e.target.value)
            props?.setFieldValue("product", "")
        }
        else {
            props?.setFieldValue("product", "")

        }
    }


    return (
        <>
            <ModalContainer
                open={props?.isShowing}
                setOpen={() => {
                    props.setIsShowing(false);
                    props.resetForm();
                    props.setSuggestion(false)
                }}
                header='Custom Orders Request'
            >

                <div className="add-vendor-outer">

                    <form className="form-wrap" onSubmit={props.handleSubmit}>
                        <Row>

                            <div className="feedback-wrap">
                                <div className="feedback-details ">
                                    <table className="table table-hover table-bordered ">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <b>{Admin_orders?.Department[language]}</b>{" "}
                                                </td>
                                                <td>{props?.order_view_data?.department?.name} </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <b>{Admin_orders?.DateofRequest[language]}</b>{" "}
                                                </td>
                                                <td>{convertISODateToDateTime(props?.order_view_data?.created_at)}</td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <b>{Admin_orders?.Category[language]}</b>{" "}
                                                </td>
                                                <td>{props?.order_view_data?.category}</td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <b>{Admin_orders?.ProductName[language]}</b>{" "}
                                                </td>

                                                <td>{props?.order_view_data?.product_name}</td>

                                            </tr>
                                            <tr className="spec-box">
                                                <td>
                                                    {" "}
                                                    <b>{Admin_orders?.Specifications[language]}</b>{" "}
                                                </td>
                                                <td>
                                                    {props?.order_view_data?.custom_order_product_spec?.map((item: any) => {
                                                        return (
                                                            <>
                                                                <span>{item?.spec_key} : {item?.spec_value}</span>
                                                            </>)
                                                    })}

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <b>{Admin_orders?.Quantity[language]}</b>{" "}
                                                </td>
                                                <td>{props?.order_view_data?.quantity}</td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <b>{Admin_orders?.Comments[language]}</b>{" "}
                                                </td>
                                                <td className=" px-2"><span className="text-success">{props?.order_view_data?.comments}</span></td>
                                            </tr>
                                            {
                                                props?.order_view_data?.ref_links !== null && (<><tr>
                                                    <td>
                                                        {" "}
                                                        <b>{Admin_orders?.Link[language]}</b>{" "}
                                                    </td>
                                                    <td title="Refer Product" className="custom-order-link"> <a href={props?.order_view_data?.ref_links}
                                                        target="_blank">{props?.order_view_data?.ref_links}</a></td>

                                                </tr></>)
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* {props?.order_views !== true && (<> */}

                            {
                                props?.order_view_data?.suggestion_status == constants.COUSTME_ORDERS?.NEW_ORDER && (<>
                                    <Row>
                                        <div className="comment-box">
                                            <Col md={12} sm={12}>
                                                <FormGroup>
                                                    <Label for="">
                                                    {Admin_orders?.Comments[language]}
                                                        <i
                                                            className="fa fa-asterisk required-field"
                                                            color="text-danger"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        name="comments"
                                                        placeholder=""
                                                        className={`form-control ${props?.touched.comments && props?.errors.comments
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        value={props.values.comments}
                                                        onChange={props.handleChange}
                                                    />
                                                    <ErrorMessage name="comments" component={TextError} />
                                                </FormGroup>
                                            </Col>
                                        </div>



                                        <Col md={4} sm={6} lg={6} className="">
                                            <FormGroup>
                                                {props.suggestion == false ? <Input
                                                    type="checkbox"
                                                    name="registration_id"
                                                    placeholder=""
                                                    onClick={() => props.setSuggestion(true)}


                                                /> : <Input
                                                    type="checkbox"
                                                    name="registration_id"
                                                    placeholder=""
                                                    onClick={() => props.setSuggestion(false)}

                                                />}

                                                {" "}
                                                <Label for="">
                                                {Admin_orders?.AlternateSuggestions[language]}

                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {
                                        props.suggestion == true && (<>

                                            <Col md={4} sm={6} lg={6}>
                                                <Label>
                                                    Category{" "}
                                                    <i
                                                        className="fa fa-asterisk fa-1 required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="select"
                                                    name="category"
                                                    value={props?.values?.category}
                                                    data={props?.category}
                                                    onChange={(e: any) => { cat_Change(e) }}
                                                    className={`form-control ${props?.touched.category && props?.errors.category
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                >
                                                    <option value="">Select Category</option>;
                                                    {props?.category?.map((item: any, i: number) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <option key={i} value={item?.value}>
                                                                    {" "}
                                                                    {item?.label}
                                                                </option>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Input>
                                                <ErrorMessage name="category" component={TextError} />
                                            </Col>
                                            <Col md={4} sm={6} lg={6}>
                                                <Label>
                                                    Product{" "}
                                                    <i
                                                        className="fa fa-asterisk fa-1 required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Select
                                                    name="product"
                                                    options={props?.product}
                                                    value={props?.values?.product}
                                                    onChange={(category) => {
                                                        props?.setFieldValue("product", category)
                                                    }
                                                    }
                                                    className={` ${props?.touched.product && props?.errors.product
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    isLoading={props?.loadingPro}
                                                    loadingMessage={() => "Fetching Product"}
                                                    noOptionsMessage={() => "Product appears here"}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable
                                                />
                                                <ErrorMessage name="product" component={TextError} />
                                            </Col>
                                        </>)
                                    }
                                    <div className="footer-sticky mt-5">
                                        {
                                            props.suggestion == true && (
                                                <>
                                                    <FormGroup className={"text-end"}>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className={"px-5"}
                                                            disabled={props?.isSubmitting}
                                                        >
                                                            {props?.isSubmitting ? (
                                                                <Spinner
                                                                    as="span"
                                                                    variant="light"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    animation="border"
                                                                />
                                                            ) : (
                                                                "Send Suggestion"
                                                            )}
                                                        </Button>
                                                    </FormGroup>
                                                </>)}
                                        {

                                            props?.suggestion == false && (
                                                <>
                                                    <FormGroup className={"text-end"}>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className={"px-5"}
                                                            disabled={props?.isSubmitting}
                                                        >
                                                            {props?.isSubmitting ? (
                                                                <Spinner
                                                                    as="span"
                                                                    variant="light"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    animation="border"
                                                                />
                                                            ) : (
                                                                "Accept order"
                                                            )}
                                                        </Button>
                                                    </FormGroup>
                                                </>)}
                                    </div></>)

                            }




                        </Row>
                    </form>
                </div>
            </ModalContainer>
        </>
    );
};

export default CoustmeOrdersModal;
