// import React from "react";
import React, { useState, useContext } from "react";
import {
  List,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../Library/CustomApi";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { Context } from "../../../Components/Context/Context";
import { Adminnotification } from "../../../Library/LanguageConversion/Admin_Notification";

export default function Notifications(args: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = useState([]);
  const {language, setLanguage} = useContext(Context)
  const toggle = () => setModal(!modal);

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const getNotification = () => {
    setLoading(true);
    api
      .get(`/notification`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          <div className="custom-tabs">
            <Link to="" className="active" style={{ pointerEvents: "none" }}>
              {Adminnotification?.Notifications[language]}
            </Link>
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
                {Adminnotification?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>{Adminnotification?.Notifications[language]}</b>
            </li>
          </List>
        </div>

        {/* Search */}
        <div className="search-widget-outer">
          <Form role="search">
            <InputGroup className="search-group">
              <InputGroupText className="search-icon" id="search">
                <i title="Search here" className="fa-sharp fa-solid fa-magnifying-glass" />
              </InputGroupText>
              <Input
                type="text"
                className="form-control"
                placeholder="Search here"
                aria-label="Username"
                aria-describedby="search"
                value={search}
                onChange={handleSearch}
                onKeyPress={(event) => {
                  var key = event.keyCode || event.which;
                  if (key === 13) {
                    event.preventDefault();
                  }
                }}
              />
            </InputGroup>
          </Form>
        </div>

        <div className="notifications-outer-wrap">
          <Row className="gy-3">
            {notification.length !== 0 ? (
              <>
                {notification?.map((item: any) => {
                  return (
                    <>
                      <Col md={12}>
                        <Link
                          to=""
                          onClick={toggle}
                          className="notification-item"
                        >
                          <div className="notification-item-inner-wrap">
                            <div className="notification-details">
                              <h6>{Adminnotification?.PaymentWasDonebyWaterAuthority[language]}</h6>
                              <small>
                                {Adminnotification?.Moredescriptiongoeshereanditcanbevery[language]}
                              </small>
                            </div>
                            <div className="notification-status">
                              <h6 className="text-end">{Adminnotification?.Unread[language]} </h6>
                              <small className="text-end d-block">
                                4.4.2019;&nbsp;<span>13:44</span>
                              </small>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    </>
                  );
                })}
              </>
            ) : (
              <Col md={12}>
                <span>{Adminnotification?.NoNotificationsFound[language]}</span>
              </Col>
            )}
          </Row>
        </div>
      </div>

      {/* Notification Popup modal */}
      <div>
        <ModalContainer
          open={modal}
          setOpen={toggle}
          header='  '
        >

          <div className="custom-tabs modal-heading">
            <Link to="" className="active" style={{ pointerEvents: "none" }}>
              {Adminnotification?.Notifications[language]}
            </Link>
          </div>
          <div className="notification-modal-content mt-4">
            <h5 className="notification-content-title">
              {Adminnotification?.Thecompanyispivotingtoanewmarket[language]}{" "}
            </h5>
            <div className="notification-content-details mt-4">
              <p>
                {Adminnotification?.Firstparagraph[language]}
              </p>
              <p>
                {Adminnotification?.Secondparagraph[language]}
              </p>
              <p>
                {Adminnotification?.Thirdparagraph[language]}
              </p>
            </div>
          </div>
        </ModalContainer>
      </div>
    </>
  );
}
