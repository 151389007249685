export const Department_Orders:any = {
    // Department/Orders/CompletePayment/CompletePayment.tsx
    Product: {
        en: 'Product',
        ka: 'ಉತ್ಪನ್ನ'
    },
    OrderID: {
        en: 'Order ID',
        ka: 'ಆದೇಶ ಐಡಿ'
    },
    ProductName: {
        en: 'Product Name',
        ka: 'ಉತ್ಪನ್ನದ ಹೆಸರು'
    },
    ProductName_orderID: {
        en: 'Product Name / Order ID',
        ka: 'ಉತ್ಪನ್ನದ ಹೆಸರು / ಆದೇಶ ಐಡಿ'
    },
    
    ProductCode: {
        en: 'Product Code',
        ka: 'ಉತ್ಪನ್ನ ಕೋಡ್'
    },
    Quantity: {
        en: 'Quantity',
        ka: 'ಪ್ರಮಾಣ'
    },
    HSNCode: {
        en: 'HSN Code',
        ka: 'ಎಚ್‌ಎಸ್‌ಎನ್ ಕೋಡ್'
    }, 
    ProductSpecifications: {
        en: 'Product Specifications',
        ka: 'ಉತ್ಪನ್ನ ವಿಶೇಷಣಗಳು'
    },
    Vendor: {
        en: 'Vendor',
        ka: 'ವೆಂಡರ್'
    },
    UnitPrice: {
        en: 'Unit Price',
        ka: 'ಘಟಕ ಬೆಲೆ'
    },
    TotalGST: {
        en: 'Total GST',
        ka: 'ಒಟ್ಟು ಜಿಎಸ್ಟಿ'
    },
    TDS_Amount: {
        en: 'TDS Amount',
        ka: 'ಟಿಡಿಎಸ್ ಮೊತ್ತ'
    },
    TotalAmount: {
        en: 'Total Amount',
        ka: 'ಒಟ್ಟು ಮೊತ್ತ'
    },
    Payable_Amount: {
        en: 'Payable Amount',
        ka: 'ಪಾವತಿಸಬೇಕಾದ ಮೊತ್ತ'
    },
    TotalPaidAmount: {
        en: 'Total Paid Amount',
        ka: 'ಪಾವತಿಸಿದ ಒಟ್ಟು ಮೊತ್ತ'
    },
    BalanceAmounttoPay: {
        en: 'Balance Amount to Pay',
        ka: 'ಪಾವತಿಸಬೇಕಾದ ಬಾಕಿ ಮೊತ್ತ'
    },
    Payment: {
        en: 'Payment',
        ka: 'ಪಾವತಿಗಳು'
    },
    Via: {
        en: 'Via',
        ka: 'ಮೂಲಕ'
    },
    Paying: {
        en: 'Paying',
        ka: 'ಪಾವತಿಸುತ್ತಿದೆ'
    },

    // Department/Orders/Feedback/feedback.tsx
    Message: {
        en: 'Message',
        ka: 'ಸಂದೇಶ'
    },
    ViewWorkCompletionCertificate: {
        en: 'View Work Completion Certificate',
        ka: 'ಕೆಲಸ ಪೂರ್ಣಗೊಂಡ ಪ್ರಮಾಣಪತ್ರವನ್ನು ವೀಕ್ಷಿಸಿ'
    },
    ChooseDocuments: {
        en: 'Choose Documents',
        ka: 'ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    Submit: {
        en: 'Submit',
        ka: 'ಸಲ್ಲಿಸು'
    },
    NoMessageAdded: {
        en: 'No Message Added',
        ka: 'ಯಾವುದೇ ಸಂದೇಶವನ್ನು ಸೇರಿಸಲಾಗಿಲ್ಲ'
    },
    Updatedat: {
        en: 'Updated at',
        ka: 'ನಲ್ಲಿ ನವೀಕರಿಸಲಾಗಿದೆ'
    },
    WorkCompletionCertificate: {
        en: 'Work Completion Certificate',
        ka: 'ಕೆಲಸ ಪೂರ್ಣಗೊಳಿಸಿದ ಪ್ರಮಾಣಪತ್ರ'
    },
    RateYourOrder: {
        en: 'How Do You Rate Your Order',
        ka: 'ನೀವು ನಿಮ್ಮ ಆರ್ಡರ್ ಅನುಭವವನ್ನು ಹೇಗೆ ಮೌಲ್ಯಮಾಪನ ಮಾಡುತ್ತೀರಿ'
    },
    RatingReview: {
        en: 'Please give your Rating & Review',
        ka: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮೌಲ್ಯಮಾಪನ ಮತ್ತು ವಿಮರ್ಶೆಯನ್ನು ನೀಡಿ'
    },
    YourFeedback: {
        en: 'Your Feedback',
        ka: 'ನಿಮ್ಮ ಅನಿಸಿಕೆ'
    },
    
    // Department/Orders/OrderViewModal/OrderViewModal.tsx 

    // Correct
    OrderTrackingStatus: {
        en: 'Order Tracking Status',
        ka: 'ಆದೇಶ ಟ್ರ್ಯಾಕಿಂಗ್ ಸ್ಥಿತಿ'
    },
    OrderPlaced: {
        en: 'Order Placed',
        ka: 'ಆರ್ಡರ್ ಮಾಡಲಾಗಿದೆ'
    },
    Completed: {
        en: 'Completed',
        ka: 'ಪೂರ್ಣಗೊಂಡಿದೆ'
    },
    Processed: {
        en: 'Processed',
        ka: 'ಪ್ರಕ್ರಿಯೆ ಮಾಡಲಾಗಿದೆ'
    },
    Delivered: {
        en: 'Delivered',
        ka: 'ವಿತರಿಸಲಾಗಿದೆ'
    },
    Processing: {
        en: 'Processing',
        ka: 'ಸಂಸ್ಕರಣೆ'
    },
    Pending: {
        en: 'Pending',
        ka: 'ಬಾಕಿಯಿದೆ'
    },
    SGST: {
        en: 'SGST',
        ka: 'ಎಸ್ ಜಿ ಎಸ್ ಟಿ'
    },
    CGST: {
        en: 'CGST',
        ka: 'ಸಿಜಿಎಸ್ಟಿ'
    },
    IGST: {
        en: 'IGST',
        ka: 'ಐಜಿಎಸ್ಟಿ'
    },
    OrderCancelled: {
        en: 'Order Cancelled',
        ka: 'ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ'
    },
    PendingVerification: {
        en: 'Pending verification',
        ka: 'ಪರಿಶೀಲನೆ ಬಾಕಿಯಿದೆ'
    },
    InProgress: {
        en: 'In Progress',
        ka: 'ಪ್ರಗತಿಯಲ್ಲಿದೆ'
    },
    Shipment_Address: {
        en: '  Shipment Address',
        ka: 'ಶಿಪ್ಮೆಂಟ್ ವಿಳಾಸ'
    },
    // Department/Orders/ProductSpecModal/index.tsx
    Feedback: {
        en: 'Feedback',
        ka: 'ಪ್ರತಿಕ್ರಿಯೆ'
    },
    ServiceRequest: {
        en: 'Service Request',
        ka: 'ಸೇವೆ ಕೋರಿಕೆ'
    },
    SlNo: {
        en: 'Sl No',
        ka: 'ಕ್ರಮಸಂಖ್ಯೆ'
    },
    IssueType: {
        en: 'Issue Type',
        ka: 'ಸಮಸ್ಯೆ ಪ್ರಕಾರ'
    },
    Priority: {
        en: 'Priority',
        ka: 'ಪ್ರಾಥಮಿಕತೆ'
    },
    Status: {
        en: 'Status',
        ka: 'ಸ್ಥಿತಿ'
    },
    NoTicketsFound: {
        en: 'No Tickets Found',
        ka: 'ಯಾವುದೇ ಟಿಕೆಟ್‌ಗಳು ಕಂಡುಬಂದಿಲ್ಲ'
    },
    Billedto: {
        en: 'Billed to',
        ka: 'ಗೆ ಬಿಲ್ ಮಾಡಲಾಗಿದೆ'
    },
    RaiseaComplaint: {
        en: 'Raise a Complaint',
        ka: 'ಫಿರ್ಯಾದು ಎತ್ತಿಕೊಳ್ಳಿ'
    },
    AddComplaint: {
        en: 'Add Complaint',
        ka: 'ಫಿರ್ಯಾದು ಸೇರಿಸಿ'
    },
    RequestedDate: {
        en: 'Requested Date',
        ka: 'ವಿನಂತಿಸಿದ ದಿನಾಂಕ'
    },
    // Department/Orders/ServiceRequestModal/ServiceRequestModal.tsx
    UploadProductImage: {
        en: 'Upload Product Image',
        ka: 'ಉತ್ಪನ್ನ ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ'
    },
    SelectType: {
        en: 'Select  Type',
        ka: 'ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    SelectRequestType: {
        en: 'Select Request Type',
        ka: 'ವಿನಂತಿಯ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ'
    },
    MaintenanceRequest: {
        en: 'Maintenance Request',
        ka: 'ನಿರ್ವಹಣೆ ವಿನಂತಿ'
    },
    SelectIssueType: {
        en: 'Select Issue Type',
        ka: 'ಸಮಸ್ಯೆ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    ContactNumber: {
        en: 'Contact Number',
        ka: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ'
    },
    Describe_Issue: {
        en: 'Describe Your Issue',
        ka: 'ನಿಮ್ಮ ಸಮಸ್ಯೆಯನ್ನು ವಿವರಿಸಿ'
    },
   
    // Department/Orders/Tabs/DeptCancelledOrders.tsx

    // Correct
    TenderID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    OrderDetails: {
        en: 'Order Details',
        ka: 'ಆರ್ಡರ್ ವಿವರಗಳು'
    },
    ShippingAddress: {
        en: 'Shipping Address',
        ka: 'ಶಿಪಿಂಗ್ ವಿಳಾಸ'
    },
    Payments: {
        en: 'Payments',
        ka: 'ಪಾವತಿಗಳು'
    },
    On_: {
        en: 'Click to see the Order list',
        ka: 'ಆದೇಶ ಪಟ್ಟಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ'
    },
    MoreThanOneOrder: {
        en: 'More than one order',
        ka: 'ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ಆದೇಶ'
    },
    On: {
        en: 'Click to see the Order list',
        ka: 'ಆರ್ಡರ್ ಪಟ್ಟಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ'
    },
    Orderlist: {
        en: 'Click to see the Order list',
        ka: 'ಆದೇಶ ಪಟ್ಟಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ'
    },
    Unitprice: {
        en: 'Unit price',
        ka: 'ಯೂನಿಟ್ ಬೆಲೆ'
    },
    Total: {
        en: 'Total',
        ka: 'ಒಟ್ಟು'
    },
    Delivered_Date: {
        en: 'Delivered Date',
        ka: 'ವಿತರಿಸಿದ ದಿನಾಂಕ'
    },
    Order_ID: {
        en: 'Order ID',
        ka: 'ಆದೇಶ ಐಡಿ'
    },
    Pincode: {
        en: 'Pincode',
        ka: 'ಪಿನ್‌ಕೋಡ್'
    },
    Showing: {
        en: 'Showing',
        ka: 'ತೋರಿಸಲಾಗುತ್ತಿದೆ'
    },
    IncludingGST: {
        en: 'Including GST',
        ka: 'GST ಸೇರಿದಂತೆ'
    },
    BuildingNo: {
        en: 'Building no',
        ka: 'ಕಟ್ಟಡ ನಂ'
    },
    YouPaid: {
        en: 'You Paid',
        ka: 'ನೀವು ಪಾವತಿಸಿದ್ದೀರಿ'
    },
    RefundinProgress: {
        en: 'Refund in Progress',
        ka: 'ಮರುಪಾವತಿ ಪ್ರಗತಿಯಲ್ಲಿದೆ'
    },
    NoRefundNeeded: {
        en: 'No refund needed',
        ka: 'ಮರುಪಾವತಿ ಅಗತ್ಯವಿಲ್ಲ'
    }, 
    Refunded: {
        en: 'Refunded',
        ka: 'ಮರುಪಾವತಿ ಮಾಡಲಾಗಿದೆ" '
    },
    NoOrdersFound: {
        en: 'No Orders Found',
        ka: 'ಯಾವುದೇ ಆದೇಶಗಳು ಕಂಡುಬಂದಿಲ್ಲ'
    },
    ShippedAddress: {
        en: 'Shipped Address',
        ka: 'ರವಾನೆಯಾದ ವಿಳಾಸ'
    },
    OrderDate: {
        en: 'Order Date',
        ka: 'ಆದೇಶದ ದಿನಾಂಕ'
    },
    Actions: {
        en: 'Actions',
        ka: 'ಕ್ರಿಯೆಗಳು'
    },
    Shipped_Address: {
        en: 'Shipped Address',
        ka: 'ರವಾನೆಯಾದ ವಿಳಾಸ'
    },
    Building_No: {
        en: 'Building No',
        ka: 'ಕಟ್ಟಡ ನಂ'
    },
    Order_Date: {
        en: 'Order Date',
        ka: 'ಆದೇಶದ ದಿನಾಂಕ'
    },
    Action: {
        en: 'Action',
        ka: 'ಕ್ರಿಯೆ'
    },
    No_Work_Orders_Found: {
        en: 'No Work Orders Found',
        ka: 'ಯಾವುದೇ ಕೆಲಸದ ಆದೇಶಗಳು ಕಂಡುಬಂದಿಲ್ಲ'
    },
    of: {
        en: 'of',
        ka: 'ನನ್ನ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    entries: {
        en: 'entries',
        ka: 'ಪ್ರವೇಶಗಳು'
    },
    // Order/Tabs/DeptProcessingOrders
    Sl_No: {
        en: 'Sl No',
        ka: 'ಕ್ರಮ ಸಂಖ್ಯೆ'
    },
    Tender_ID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    Order_Details: {
        en: 'Order Details',
        ka: 'ಆರ್ಡರ್ ವಿವರಗಳು'
    },
    Shipping_Address: {
        en: 'Shipping Address',
        ka: 'ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ'
    },
    Unit_price: {
        en: 'Unit price',
        ka: 'ಘಟಕ ಬೆಲೆ'
    },
    Cancel_Order: {
        en: 'Cancel Order',
        ka: 'ಆದೇಶವನ್ನು ರದ್ದುಮಾಡಿ'
    },
    Order_Accepted: {
        en: 'Order Accepted',
        ka: 'ಆದೇಶವನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ'
    },
    // Orders/Tabs/PaymentPendingOrders
    Paid: {
        en: 'Paid',
        ka: 'ಪಾವತಿಸಲಾಗಿದೆ'
    },
    Balance: {
        en: 'Balance',
        ka: 'ಸಮತೋಲನ'
    },
    Pay_Now: {
        en: 'Pay Now',
        ka: 'ಈಗ ಪಾವತಿಸಿ'
    },
    
    
    // Orders/Tabs/PlacedOrders
    Date: {
        en: 'Date',
        ka: 'ದಿನಾಂಕ'
    },
    Click_to_see_the_Order_list: {
        en: 'Click to see the Order list',
        ka: 'ಆದೇಶ ಪಟ್ಟಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ'
    },
    TDS: {
        en: 'TDS',
        ka: 'ಟಿಡಿಎಸ್'
    },
    Cancel: {
        en: 'Cancel',
        ka: 'ರದ್ದುಮಾಡು'
    },
    More_than_one_order: {
        en: 'More than one order',
        ka: 'ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ಆದೇಶ'
    },
    Building_no: {
        en: 'Building no',
        ka: 'ಕಟ್ಟಡ ನಂ'
    },

    // Order/Tabs/ProcessingOrders
 
    // Order/Tabs/DEptOrderLayout
    Placed: {
        en: 'Placed',
        ka: 'ಇರಿಸಲಾಗಿದೆ'
    },
    Cancelled: {
        en: 'Cancelled',
        ka: 'ರದ್ದುಗೊಂಡಿದೆ'
    },
    Payment_Pending : {
        en: 'Payment Pending',
        ka: 'ಪಾವತಿ ಬಾಕಿಯಿದೆ'
    },
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
    },
    Delivery_Chellan: {
        en:'Delivery Chellan',
        ka:'ಡೆಲಿವರಿ ಚೆಲ್ಲನ್'
    },
    Features:{
        en:'Features',
        ka:'ವೈಶಿಷ್ಟ್ಯಗಳು'
    },
    PayableAmount:{
        en:'Payable Amount',
        ka:'ಪಾವತಿಸಬೇಕಾದ ಮೊತ್ತ'
    }
  }