import * as Yup from "yup";
const today = new Date();
const orderValiadtionFirstSchema = Yup.object().shape({

    product: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.string().required("key is required (from label)"),
                value: Yup.string().required("Value is required"),
            })
        )
        .test(
            "required",
            "Product is required",
            (value) => Array.isArray(value) && value.length > 0
        ),
    category: Yup.number()
        .required("Category is required"),
    comments: Yup.string()
        .required("Comment is required"),
});
const orderValiadtionSecondSchema = Yup.object().shape({
    comments: Yup.string()
        .required("Comment is required"),
});

const paymentCheckValiadtionSecondSchema = Yup.object().shape({
    comments: Yup.string()
        .required("Comment is required"),
});
export { orderValiadtionFirstSchema, orderValiadtionSecondSchema, paymentCheckValiadtionSecondSchema };
