import React, { useState, useEffect,useContext } from "react";
import {
    Table,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    Button,
    UncontrolledAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem
} from "reactstrap";
import { Link } from "react-router-dom";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import { convertISODateToDateTime } from "../../../Library/Constants/helpers";
import { api } from "../../../Library/CustomApi";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { toast } from "react-toastify";
import { Admin_tds } from "../../../Library/LanguageConversion/Admin_Tds";
import { Context } from "../../../Components/Context/Context";
export default function TDSTable() {
    const [loading, setLoading] = useState(false);
    const [TDSData, setTDSDAta] = useState([]);
    const [pageData, setPageData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number | string>(1)
    const [searchVal, setSearchVal] = useState<string>("")
    const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false) // for feedback modal
    const [payIsOpen, setPayIsOpen] = useState<boolean>(false) // for feedback modal
    const [singleOrderData, setSingleOrderData] = useState<any>([])
    const [TDSDataIndex, setTDSDataIndex] = useState<number | null>(null)
    const [orderData, setOrderData] = useState<any>([])
    const [selectedAddress, setSelectedAddress] = useState<any>([])
    const {language,setLanguage} = useContext(Context)
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if (searchVal !== null ||searchVal === "") {
            getTDSList();
          }
        }, 500);
    
        return () => clearTimeout(delayDebounceFn);
      }, [searchVal]);

    // fetch processing orders 
    const getTDSList = () => {
        setLoading(true);
        api
            .get(`/order/pf-invoice?page=${currentPage}&search_value=${searchVal}`, true)
            .then(async function ([, response]) {
                setTDSDAta(response.data || []);
                setPageData(response?.meta)
                setLoading(false);
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };


    const getTDSDoc = (id: any) => {
        api
            .post(`/order/tds_doc_view`, {
                pf_id: id
            }, true)
            .then(async function ([success, response]) {
                var win: any = window.open(response?.data?.[0]?.url, '_blank');
                win.focus();
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const TdsStatusHandler = (id: any, status: 3 | 4) => {
        // status->3 approve 
        // status->4 reject 
        api
            .post(`/order/tds_update`, {
                pfInvoiceNo: id,
                status: status
            }, true)
            .then(async function ([success, response]) {
                toast.success('Success')
                getTDSList()
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // handles order view modal 
    const handleOrderView = (obj: any) => {
        setSingleOrderData(obj.order_details)
        setOrderData(obj)
        setSelectedAddress(obj?.shipping_address[0])
        setOrderModalOpen(true)
    }

    return (
        <>
            <div className="search-widget-outer">
                <Form className="d-flex" role="search">
                    <InputGroup className="search-group">
                        <InputGroupText title="Order ID/Product Name" className="search-icon" id="search">
                            <i className="fa-sharp fa-solid fa-magnifying-glass" />
                        </InputGroupText>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder={Admin_tds?.ProductNameOrder?.[language]}
                            aria-label="Username"
                            aria-describedby="search"
                            onChange={(e: any) => {
                                setCurrentPage(1)
                                setSearchVal(e.target.value)
                            }}
                        />
                    </InputGroup>
                </Form>
            </div>
        <div className="table-outer-wrap">
            <div className="table-wrap responsive-table">
                <Table className="w-100 table-striped">
                    <thead>
                        <tr>
                            <th>{Admin_tds?.SlNo?.[language]}</th>
                            <th>{Admin_tds?.OrderID?.[language]}</th>
                            <th>{Admin_tds?.TenderID?.[language]}</th>
                            <th>{Admin_tds?.OrderDetails?.[language]}</th>
                            <th>{Admin_tds?.ShippingAddress?.[language]}</th>
                            <th>{Admin_tds?.Date?.[language]}</th>
                            <th>{Admin_tds?.Quantity?.[language]}</th>
                            <th>{Admin_tds?.Action?.[language]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading ?
                            <>
                                {TDSData?.length !== 0 ? (
                                    <>
                                        {TDSData?.map((item: any, i: number) => {
                                            let count = 0

                                            return (
                                                <React.Fragment key={i}>
                                                    <tr className="order-relative">
                                                        <td><span className="mobile-heading">{Admin_tds?.SlNo?.[language]}:</span><span className="table-data">{pageData?.from + i}</span></td>
                                                        <td title="View Order">
                                                            <span className="mobile-heading">{Admin_tds?.OrderID?.[language]}:</span>
                                                            <span className="table-data">
                                                                <Link to="" className="blue-text" onClick={() => handleOrderView(item)}>{item?.order_id}</Link>
                                                            </span>
                                                        </td>
                                                        <td>
                                                       
                                                            <span className="mobile-heading">{Admin_tds?.TenderID?.[language]}:</span>
                                                            <span className="table-data">{item?.order_details[0].tender_id}</span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-heading">{Admin_tds?.Product?.[language]}:</span>
                                                            <span className="table-data">
                                                                {/* Multiple order list */}
                                                                {item?.order_details?.length > 1 ? (
                                                                    <>
                                                                        <UncontrolledAccordion defaultOpen="0" className="order-list-accordion">
                                                                            <AccordionItem>
                                                                                <AccordionHeader targetId="1" className="">
                                                                                {Admin_tds?.Morethanoneorder?.[language]}. <span>{Admin_tds?.ClicktoseetheOrderlist?.[language]}.</span>
                                                                                </AccordionHeader>
                                                                                <AccordionBody accordionId="1" className="">
                                                                                    <AccordionHeader targetId="1" className="close-btn">
                                                                                        <span className="close-btn-inner" title="Close">
                                                                                            <i className="bi bi-x-lg"></i>
                                                                                        </span>
                                                                                    </AccordionHeader>
                                                                                    <div className="order-list-outer-wrap">
                                                                                        {
                                                                                            item?.order_details?.map((obj: any, x: number) => {
                                                                                                count = count + obj.quantity
                                                                                                return (
                                                                                                    <React.Fragment key={x}>
                                                                                                        <div className="product-details">
                                                                                                            <p className="product-title"><span>{obj?.name}</span></p>
                                                                                                            <p className="sub-details"> {Admin_tds?.Unitprice?.[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.price * 0.05) + obj?.price} </span></p>
                                                                                                            <p className="sub-details">{Admin_tds?.Quantity?.[language]}: <span>{obj?.quantity}</span> </p>
                                                                                                            <p className="sub-details">{Admin_tds?.Total?.[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.total_amount - obj?.tds_on_gst).toFixed(2)} (Including GST)</span></p>
                                                                                                        </div>
                                                                                                        {/* {item?.order_details?.length !== x + 1 && <hr />} */}
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </AccordionBody>
                                                                            </AccordionItem>
                                                                        </UncontrolledAccordion>
                                                                    </>

                                                                ) : null}

                                                                {/* Single order */}
                                                                {item?.order_details?.length == 1 ? (
                                                                    <>
                                                                        {
                                                                            item?.order_details?.map((obj: any, x: number) => {
                                                                                count = count + obj.quantity
                                                                                return (
                                                                                    <React.Fragment key={x}>
                                                                                        <div className="product-details">
                                                                                            <p className="product-title"><span>{obj?.name}</span></p>
                                                                                            <p className="sub-details"> {Admin_tds?.Unitprice?.[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.price * 0.05) + obj?.price} </span></p>
                                                                                            <p className="sub-details">{Admin_tds?.Quantity?.[language]}: <span>{obj?.quantity}</span> </p>
                                                                                            <p className="sub-details">{Admin_tds?.TDS?.[language]}: <span><i className="bi bi-currency-rupee"></i>{obj?.tds_on_gst}</span> </p>
                                                                                            <p className="sub-details">{Admin_tds?.Total?.[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.total_amount - obj?.tds_on_gst).toFixed(2)} (Including GST)</span></p>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                ) : null}
                                                            </span>
                                                        </td>
                                                        <td><span className="mobile-heading">{Admin_tds?.ShippingAddress?.[language]}:</span>
                                                            <span className="table-data">
                                                            {Admin_tds?.BuildingNo?.[language]}:{item?.shipping_address[0]?.building_no} <br />
                                                                {item?.shipping_address[0]?.city} <br />
                                                                {item?.shipping_address[0]?.district} <br />
                                                                {item?.shipping_address[0]?.pincode} <br />
                                                                {item?.shipping_address[0]?.email} <br />
                                                                {item?.shipping_address[0]?.phone} <br />
                                                            </span>
                                                        </td>
                                                        <td><span className="mobile-heading">{Admin_tds?.Date?.[language]}:</span><span className="table-data">
                                                            {convertISODateToDateTime(item?.created_at)}
                                                        </span></td>
                                                        <td><span className="mobile-heading">{Admin_tds?.Quantity?.[language]}:</span>
                                                            <span className="table-data">{count}</span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-heading">{Admin_tds?.Action?.[language]}:</span>
                                                            <span className="table-data">
                                                            <div className="action-flex-btn smaller">
                                                            {(item?.tds_status == 2 ||
                                                                item?.tds_status == 3 ||
                                                                item?.tds_status == 4) &&
                                                                <a className=""
                                                                    onClick={() => {
                                                                        getTDSDoc(item?.id)
                                                                    }}
                                                                ><i className="icons bi bi-eye lg" title="View TDS Document"></i></a>
                                                            }
                                                            
                                                            {
                                                                item?.tds_status == 2 ?
                                                                    <>
                                                                        <div className="action-flex-btn smaller">
                                                                            <Link to=""
                                                                                title="Approve"
                                                                                onClick={() => TdsStatusHandler(item?.id, 3)}
                                                                                className=""
                                                                            ><i className="icons bi bi-check-circle text-success"></i></Link>

                                                                            <Link to=""
                                                                                title="Reject"
                                                                                onClick={() => TdsStatusHandler(item?.id, 4)}
                                                                                className=""
                                                                            ><i className="icons bi bi-x-circle text-danger"></i></Link>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    item?.tds_status == 1 ?
                                                                        <>
                                                                            <span className="warning-status">{Admin_tds?.NotUploaded?.[language]}</span>
                                                                        </>
                                                                        :
                                                                        item?.tds_status == 3 ?
                                                                            <>
                                                                                <span className="approved-status">{Admin_tds?.Approved?.[language]}</span>
                                                                            </>
                                                                            :
                                                                            item?.tds_status == 4 ?
                                                                                <>
                                                                                    <span className="rejected-status">{Admin_tds?.Rejected?.[language]}</span>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    -
                                                                                </>
                                                                                
                                                            }
                                                            </div>
                                                            </span>
                                                            
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                )
                                    : (
                                        <tr>
                                            <td colSpan={9} className="text-center p-5 empty-text">
                                            {Admin_tds?.NoOrdersFound?.[language]}
                                            </td>
                                        </tr>
                                    )}
                            </>
                            :
                            <tr>
                                <td colSpan={9} className="text-center p-5 empty-text">
                                    <Loader />
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
                {pageData && TDSData.length > 0 && <div className="table-footer-wrap">
                    <small> {Admin_tds?.Showing?.[language]} {pageData.from} {Admin_tds?.to?.[language]}{pageData.to} {Admin_tds?.of?.[language]} {pageData?.total} {Admin_tds?.entries?.[language]}</small>
                    <div className="pagination-outer">
                        <CustomPagination
                            totalItems={pageData?.total}
                            itemsPerPage={pageData?.per_page}
                            activePage={pageData?.current_page}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>}
            </div>
        </div>

            {/* order view modal  */}
            <div>
                {/* <ModalContainer
                    open={orderModalOpen}
                    setOpen={setOrderModalOpen}
                    header='Order Details'
                >
                    <OrderViewModal
                        singleOrderData={singleOrderData}
                        orderData={orderData}
                        orderViewLoading={false}
                        address={selectedAddress}
                    />
                </ModalContainer> */}
            </div>

            {/* payment modal  */}
            <div>
                <ModalContainer
                    open={payIsOpen}
                    setOpen={setPayIsOpen}
                    header='Pay Now'
                >

                </ModalContainer>
            </div>
        </>
    );
}