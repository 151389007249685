import React,{ useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../../Components/Context/Context";
import { admin_empanelment } from "../../../../Library/LanguageConversion/admin_empanelment";

export default function VendorVerificationLayout(props: any) {
  const { pathname } = useLocation();
  const location = useLocation();
  const { language, setLanguage } = useContext(Context);
  const vendor_id = location?.state?.id || location?.state?.data;
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/basic-details-vendor" state={{ data: vendor_id }}>
            {admin_empanelment?.Basic_details?.[language]}
            </NavLink>
            <NavLink to="/advanced-details-vendor" state={{ data: vendor_id }}>
            {admin_empanelment?.Advanced_details?.[language]}
            </NavLink>
            <NavLink to="/doc-verification" state={{ data: vendor_id }}>
            {admin_empanelment?.Documents?.[language]}
            </NavLink>
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
              {admin_empanelment?.Dashboard?.[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <Link className="active" to="/verify-vendor">
              {admin_empanelment?.Verify_Vendor?.[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {pathname == "/basic-details-vendor"
                  ? "Basic Details"
                  : pathname == "/advanced-details-vendor"
                  ? "Advanced Details"
                  : pathname == "/doc-verification"
                  ? "Documents Upload"
                  : null}
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
