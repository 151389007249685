import moment from "moment";
import React, { useState, useEffect ,useContext} from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Table,
} from "reactstrap";

import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import { API_URL } from "../../../../Library/ApiSettings";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import ImageGallery from "react-image-gallery";
import { capitalizeFirst } from "../../../../Library/Constants/helpers";
import { VendorRegLang } from "../../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../../Components/Context/Context";

const url = "https://www.google.com/maps/place/";
const url_ =
  "/,14z/data=!3m1!4b1!4m5!3m4!1s0x3b05df341025532f:0x6e9a61c01275f84c!8m2!3d";

const DeliveryModal = (props: any) => {
  const {language,setLanguage} = useContext(Context)
  const [reuploadField, setReuploadField] = useState<any>();
  const [doc_type, setDocType] = useState<any>();

  const order_data = props?.order_view?.[0];

  const images = order_data?.order_details?.product_details?.product_image?.map(
    (item: any) => {
      return {
        original: item?.path,
        thumbnail: item?.path,
      };
    }
  );

  const downloadinvoice = () => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      // body: JSON.stringify({
      //     orderId: props?.order_view[0]?.vendor_po_no
      //})
    };
    fetch(
      `${API_URL}/order/invoice-download?orderId=${props?.order_view[0]?.vendor_po_no}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Invoice.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        props?.setIsShowing(false);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  const reUploadOpen = (type: number, id: number, order_id: number) => {
    props?.setReuploadOpen(true);
    setReuploadField(type);
    props?.setReuploadParams({
      type: type,
      id: id,
      order_id: order_id,
    });
    let challan = type == 1 ? "Delivery Challan" : null;
    let workorder = type == 2 ? "Work Order Certificate" : null;
    let delivery_invoice = type == 3 ? "Delivery Invoice" : null;
    setDocType(challan || workorder || delivery_invoice);
  };

  const reUploadClosed = () => {
    props?.setReuploadOpen(false);
    setReuploadField("");
    setDocType("");
  };

  const handleFilechange = (e: any) => {
    // const myMemoObj = URL.createObjectURL(e.target.files[0]);
    props?.setFieldValue("doc", e.target.files[0]);
    props?.setFieldTouched("doc", false, false);
  };

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Order View"
      >
        <div className="feeback-outer-wrap">
          {/* <div className="feedback-main-heading">
                            <h2>Order Details</h2>
                        </div> */}
          <div className="feedback-wrap">
            <div className="feedback-details ">
              <Form className="form-wrap">
                <section>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <div className="">
                        <div className="product-specs-wrap">
                          <Row className="gy-4 ">
                            <Col md={7}>
                              <div className="product-specs-gallery">
                                {/* image gallery */}
                                {images && (
                                  <ImageGallery
                                    items={images}
                                    showNav={false}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    lazyLoad
                                    additionalClass={"card-img-top "}

                                    // className="card-img-top" alt="Apple Computer"
                                  />
                                )}
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="product-details-outer-wrap">
                                <div className="product-details">
                                  <h2 className="card-title">
                                    {
                                      order_data?.order_details?.product_details
                                        ?.name
                                    }
                                  </h2>
                                  <p className="text-muted mb-4">
                                    {
                                      order_data?.order_details?.product_details
                                        ?.product_code
                                    }
                                  </p>
                                </div>
                                <div
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      order_data?.order_details?.product_details
                                        ?.features,
                                  }}
                                >
                                  
                                </div>
                                
                                {/* <span><p>{removeTags(order_data?.order_details?.product_details?.features)}</p></span> */}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="price-wrap">
                          <div className="item-flex">
                            <span>{VendorRegLang?.UnitPrice?.[language]}</span>
                            <span>₹{order_data?.order_details?.amount}</span>
                          </div>
                          <div className="item-flex">
                            <span>{VendorRegLang?.quantity?.[language]}</span>
                            <span>{props?.data?.order_details?.quantity}</span>
                          </div>
                          {order_data?.address?.[0]?.state == "Karnataka" ? (
                            <>
                              <div className="item-flex">
                                <span>{VendorRegLang?.CGST?.[language]}</span>
                                <span>₹{order_data?.order_details?.cgst}</span>
                              </div>
                              <div className="item-flex">
                                <span>{VendorRegLang?.SGST?.[language]}</span>
                                <span>₹{order_data?.order_details?.sgst}</span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="item-flex">
                                <span>{VendorRegLang?.IGST?.[language]}</span>
                                <span>₹{order_data?.order_details?.igst}</span>
                              </div>
                            </>
                          )}
                          <hr />
                          <div className="item-flex">
                            <span>{VendorRegLang?.TotalAmount?.[language]}</span>
                            {/* <span className="green-text">₹{(((props?.data?.order_details?.cgst * props?.data?.order_details?.quantity) + (props?.data?.order_details?.sgst * props?.data?.order_details?.quantity)) + (props?.data?.order_details?.amount * props?.data?.order_details?.quantity)) - ((((props?.data?.order_details?.cgst * props?.data?.order_details?.quantity) + (props?.data?.order_details?.sgst * props?.data?.order_details?.quantity)) + (props?.data?.order_details?.amount * props?.data?.order_details?.quantity)) * 5 / 100)} </span> */}
                            <span className="green-text">
                              ₹
                              {props?.data?.order_details?.cgst *
                                props?.data?.order_details?.quantity +
                                props?.data?.order_details?.sgst *
                                  props?.data?.order_details?.quantity +
                                props?.data?.order_details?.amount *
                                  props?.data?.order_details?.quantity}{" "}
                            </span>
                          </div>
                        </div>

                        <div className="shipping-addr-wrap">
                          <div className="shipment-addr-inner-wrap">
                            <h3>{VendorRegLang?.ShippingAddress?.[language]}</h3>
                            <hr />
                            <div className="content-wrap">
                              <p>
                                <span>{VendorRegLang?.Name?.[language]}:</span>
                                {"  "}
                                {capitalizeFirst(
                                  props?.data?.pf_invoice?.user?.full_name
                                )}
                              </p>

                              <p>
                                <span>{VendorRegLang?.PhoneNumber?.[language]}:</span>
                                {"  "}
                                {props?.data?.pf_invoice?.user?.phone}
                              </p>
                              <p>
                                <span>{VendorRegLang?.Address?.[language]}:</span>
                                {"  "}
                                {
                                  props?.data?.pf_invoice?.pf_address?.[0]
                                    ?.email
                                }
                                ,
                                {
                                  props?.data?.pf_invoice?.pf_address?.[0]
                                    ?.pincode
                                }
                                ,
                                {
                                  props?.data?.pf_invoice?.pf_address?.[0]
                                    ?.landmark
                                }
                                ,
                                {props?.data?.pf_invoice?.pf_address?.[0]?.city}
                                ,
                                {
                                  props?.data?.pf_invoice?.pf_address?.[0]
                                    ?.state
                                }
                                ,
                                {
                                  props?.data?.pf_invoice?.pf_address?.[0]
                                    ?.district
                                }
                                ,
                                {
                                  props?.data?.pf_invoice?.pf_address?.[0]
                                    ?.country
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <Row className="">
                  <div className="table-outer-wrap doc-approval-outer-wrap">
                    <div className="mt-4">
                      <h5 className="sub-heading ">{VendorRegLang?.DeliveryDocuments?.[language]}</h5>
                      <Col md={12}>
                        <div className="add-button-end">
                          <Button
                            type="button"
                            className="btn btn-primary px-5 w-100"
                            onClick={props?.handleSubmitss}
                          >
                            <span>
                              {" "}
                              {VendorRegLang?.WorkCompletionCertificateDownload?.[language]}{" "}
                              <i className="fa-solid fa-download ms-1"></i>
                            </span>
                          </Button>
                        </div>
                      </Col>
                      {props?.doc_data?.length !== 0 ? (
                        <>
                          <div className="delivery-details-wrap">
                            <h5 className="sub-heading  mt-3">
                              {" "}
                               {VendorRegLang?.DeliveryDetails?.[language]}
                            </h5>

                            <span> {VendorRegLang?.WorkCompletionCertificate?.[language]}</span>
                            <a href={props?.doc_data?.wc_doc_dept} target="_blank">View</a>

                            {props?.doc_data?.map((item: any) => {
                              return (
                                <>
                                  {item?.getimages?.map((item: any) => {
                                    return (
                                      <>
                                        <Row className="delivery-inner-wrap gy-4">
                                          <Col md={6}>
                                            <div className="delivery-image">
                                              <img
                                                src={item?.image}
                                                alt="image"
                                              />
                                            </div>
                                          </Col>
                                          <Col md={6}>
                                            <div className="location-wrap form-wrap">
                                              <Row className="gy-4">
                                                {/* <Col md={12}>
                                                                                                        <div className="latitude-wrap form-group">
                                                                                                            <Label>Latitude</Label>
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                value={item?.latitude}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col md={12}>
                                                                                                        <div className="longitude-wrap form-group">
                                                                                                            <Label>Longitude</Label>
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                value={item?.longitude}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                    </Col> */}
                                                <Col md={12}>
                                                  <div className="latitude-wrap form-group">
                                                    <a
                                                      href={
                                                        url +
                                                        item?.latitude +
                                                        "+" +
                                                        item?.longitude +
                                                        "" +
                                                        url_
                                                      }
                                                      target="_blank"
                                                    >
                                                      {" "}
                                                      <MapContainer
                                                        style={{
                                                          height: "30vh",
                                                          width: "100%",
                                                        }}
                                                        center={[
                                                          item?.latitude,
                                                          item?.longitude,
                                                        ]}
                                                        dragging={false}
                                                        zoom={13}
                                                        doubleClickZoom={true}
                                                        scrollWheelZoom={true}
                                                        zoomControl={true}
                                                      >
                                                        <TileLayer
                                                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        />
                                                        <Marker
                                                          position={[
                                                            item?.latitude,
                                                            item?.longitude,
                                                          ]}
                                                          icon={
                                                            new Icon({
                                                              iconUrl:
                                                                "images/placeholder.png",
                                                              iconSize: [
                                                                41, 41,
                                                              ],
                                                              iconAnchor: [
                                                                12, 41,
                                                              ],
                                                            })
                                                          }
                                                        >
                                                          <Popup>
                                                          {VendorRegLang?.Location?.[language]}
                                                          </Popup>
                                                        </Marker>
                                                      </MapContainer>
                                                    </a>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Col>
                                        </Row>
                                        <hr className="dashed" />
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default DeliveryModal;
