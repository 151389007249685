import React, { useEffect, useContext } from "react";
import { useState, useMemo } from "react";
import { Context } from "../../../../Components/Context/Context";
import { Admin_orders } from "../../../../Library/LanguageConversion/Admin_orders";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import VendorAllocationModal from "../Modal/VendorAllocationModal";
import { Formik } from "formik";
import {
  VendorAllocationValidationSchema,
  RequestamountValidationSchema,
} from "./../Validation/VendorAllocationValidation";
import constants from "../../../../Library/Constants/Constants";
import Loader from "../../../../Components/Loader/Loader";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import { toast } from "react-toastify";
import OrderView from "../Modal/OrderView";
import moment from "moment";
import { PaymentStatus } from "../../../../Library/Constants/helpers";
import PaymentCheckModal from "../../Payment/PaymentCheckModal";

export default function ReceivedOrders(props: any) {
  const { language, setLanguage } = useContext(Context);
  const [url, setUrl] = useState("");
  const [id, setDocId] = useState("");
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  const [received_order, setReceivedOrder] = useState([]);
  const [isShowing, setIsShowing] = useState(false);
  const [isShowings, setIsShowings] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any>();
  const [search, setSearch] = useState("");
  const [pro_data, setProData] = useState({});
  const [order_data, setOrderData] = useState([]);
  const [ship_data, setShipData] = useState([]);
  const [pro_id, setProId] = useState<any>({});
  const [order_id, setOrderId] = useState();
  const [tend_id, setTend_id] = useState("");
  const [length, setLength] = useState("");
  const [vendor_data, setVendorData] = useState([]);
  const [quantiy_asigned, setQuantityAssigned] = useState(false);
  const [history, setHistory] = useState([]);
  const [vendor_details, setVendorDetails] = useState([]);
  const [order_view, setOrderView] = useState([]);
  const [verify_status, setVerifyStatus] = useState("");
  const [update, setUpdate] = useState(false);
  const [payment_data, setPayamentData] = useState([]);
  const [err, setErr] = useState("");
  const [check, setCheck] = useState(false);
  const [wor_id, setWorkID] = useState();
  const initialValuesPayment = {
    comments: "",
    reamin_amount: "",
  };
  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getReceivedOrder();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage]);
  // ========================================================== Get Recieved Order ==========================================================

  const getReceivedOrder = () => {
    setLoading(true);
    api
      .get(
        `/order/pf-invoice?status=${constants.ORDERS.RECEIVED}&page=${currentPage}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setReceivedOrder(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ============================================================ get Single Order View =====================================================
  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-proforma-details?id=${order_id}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message?.[0]);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  //================================================================ Download ====================================================

  const getDownload = (id: any, pf_id: any) => {
    setUrl("");
    setLoading(true);
    api
      .get(`/order/workorderview?user_id=${id}&pf_id=${pf_id}`, true)
      .then(async function ([success, response]) {
        setUrl(response?.data);
        setDocId(response?.data?.id);

        let x: any = 3;
        response?.data?.forEach((it: any, i: number) => {
          // for getting status of docs
          // 4->re-uploaded
          // 2->approved
          // 3->rejected
          if (it?.status == 4 || it?.status == 2 || it?.status == 1) {
            x = it?.status;
          }
        });
        setVerifyStatus(x);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // ========================================================== Vendor Allocation ==========================================================

  const handleSubmit = (values: any, actions: any) => {
    vendorAllocation(values, actions);
  };

  const vendorAllocation = (values: any, actions: any) => {
    let data: any = [];

    if (values?.low_1_quantity !== "") {
      data.push(values?.low_1_quantity);
    }
    if (values?.low_2_quantity !== "") {
      data.push(values?.low_2_quantity);
    }
    if (values?.low_3_quantity !== "") {
      data.push(values?.low_3_quantity);
    }

    let vdata: any = [];
    vendor_data.map((item: any) => {
      vdata.push(item?.vendor_id);
    });
    let bdata: any = [];
    vendor_data.map((item: any) => {
      bdata.push(item?.billing_id);
    });

    api
      .post(
        `/vendor/allocation`,
        {
          product_id: pro_id.prod_id,
          quantity: data,
          vendor: vdata,
          invoice_id: pro_id.invo_id,
          billing_address_id: bdata,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Quantity Allocated Successfully");
          setQuantityAssigned(true);
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
          getReceivedOrder();
          data = [];
        }
        return response;
      })
      .catch((err) => {
        if (err?.response?.status == 500) {
          actions.setSubmitting(false);
        }
        console.log(err);
      });
  };

  // ================================================================ Vendors ===============================================================
  const productVendor = (t_id: any, p_id: any) => {
    api
      .post(
        `/vendor/listavendors`,
        {
          product_id: p_id,
          tender_id: t_id,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          let data: any = [];
          response?.data[0]?.vendor_data?.map((elem: any) => {
            data.push({
              type: elem?.type,
              vendor_id: elem?.user_id,
              billing_id: elem?.billing_address_id,
            });
          });
          setVendorDetails(response?.data[0]?.vendor_data || []);
          setVendorData(data || []);
          setLength(response?.data[0]?.vendor_data?.length || []);
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ======================================================= get Allocation details =====================================================

  const allocationHistory = (t_id: any, p_id: any) => {
    api
      .post(
        `/vendor/allocation-history`,
        {
          product_id: p_id,
          tender_id: t_id,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          setHistory(response || []);
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ======================================================= Limit Text Display ==========================================================

  function limitText(text: any, limit: any) {
    if (text?.length > limit) {
      return text?.substring(0, limit) + "...";
    } else {
      return text;
    }
  }

  // ================================================================ View ==================================================================
  const handleSubmits = (values: any, actions: any) => {
    api
      .post(
        `/order/request_remaining_amount`,
        {
          amount: values?.reamin_amount,
          comment: values?.comments,
          order_id: order_id,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Successfully requested to remaining amount");
          setIsShowings(false);
          actions.resetForm();
          actions.setSubmitting(false);
          getReceivedOrder();
          setErr("");
          setCheck(false);
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText
              title="Search"
              className="search-icon"
              id="search"
            >
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={Admin_orders?.ProductName_order?.[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100  table-striped">
          <thead>
            <tr>
              <th>{Admin_orders?.SlNo[language]}</th>
              <th>{Admin_orders?.OrderID[language]}</th>
              {/* <th>Tender ID</th> */}
              <th>{Admin_orders?.OrderedDate[language]}</th>
              <th>{Admin_orders?.OrderDetails[language]}</th>
              <th>{Admin_orders?.ShippingAddress[language]}</th>
              <th>{Admin_orders?.Quantity[language]}</th>
              <th>{Admin_orders?.Department[language]}</th>
              <th>{Admin_orders?.PaymentStatus[language]}</th>
            </tr>
          </thead>
          <tbody>
            {received_order?.length != 0 ? (
              <>
                {received_order.map((item: any, i: any) => {
                  let count = 0;
                  return (
                    <React.Fragment key={item.id}>
                      <tr className="order-relative">
                        <td>
                          <span className="mobile-heading">
                            {Admin_orders?.SlNo[language]}:
                          </span>
                          <span className="table-data">
                            {pageData?.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {Admin_orders?.OrderID[language]}:
                          </span>
                          <span className="table-data">
                            <Link
                              to="#"
                              className="primary-text"
                              title="View Order Details"
                              onClick={() => {
                                toggle();
                                getSingleOrder(item?.id);
                                getDownload(item?.user?.user_id, item?.id);
                              }}
                            >
                              {item?.order_id}
                            </Link>
                          </span>
                        </td>
                        {/* <td>
                          <span className="mobile-heading">Tender ID:</span>
                          <span className="table-data">
                            {item?.order_details[0]?.tender_id}
                          </span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">
                            {Admin_orders?.OrderedDate[language]}:
                          </span>
                          <span className="table-data">
                            {moment(item?.created_at).format("DD-MM-YYYY")}
                          </span>
                        </td>

                        <td>
                          <span className="mobile-heading">
                            {Admin_orders?.OrderDetails[language]}:
                          </span>
                          <span className="table-data">
                            {item?.order_details?.length > 1 ? (
                              <>
                                <UncontrolledAccordion
                                  defaultOpen="0"
                                  className="order-list-accordion"
                                >
                                  <AccordionItem>
                                    <AccordionHeader targetId="1" className="">
                                      More than one order.{" "}
                                      <span>Click to see the Order list.</span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                      <AccordionHeader
                                        targetId="1"
                                        className="close-btn"
                                      >
                                        <span
                                          className="close-btn-inner"
                                          title="Close"
                                        >
                                          <i className="bi bi-x-lg"></i>
                                        </span>
                                      </AccordionHeader>
                                      <div className="order-list-outer-wrap">
                                        {item?.order_details?.map(
                                          (obj: any, x: number) => {
                                            count = count + obj.quantity;
                                            return (
                                              <React.Fragment key={obj.id}>
                                                <div className="product-details">
                                                  {item?.order_status ==
                                                    constants.ORDERS
                                                      .WORKORDERACCEPT ? (
                                                    <Link
                                                      to=""
                                                      title="Allocate Product to Vendor"
                                                      onClick={() => {
                                                        setProData({
                                                          order_id:
                                                            item?.order_id,
                                                          tender_id:
                                                            obj?.tender_id,
                                                        });
                                                        productVendor(
                                                          obj?.tenderid,
                                                          obj?.product_id
                                                        );
                                                        allocationHistory(
                                                          obj?.tenderid,
                                                          obj?.product_id
                                                        );
                                                        setProId({
                                                          tender_id:
                                                            obj?.tenderid,
                                                          prod_id:
                                                            obj?.product_id,
                                                          invo_id:
                                                            obj?.pro_forma_invoice_id,
                                                        });
                                                        setShipData(
                                                          item
                                                            ?.shipping_address[0]
                                                        );
                                                        setOrderData(obj);
                                                        setIsShowing(true);
                                                      }}
                                                      className="text-decoration-none primary-text product-name"
                                                    >
                                                      {limitText(obj?.name, 15)}
                                                    </Link>
                                                  ) : (
                                                    <p
                                                      title="Allocate Product to Vendor"
                                                      className="product-title"
                                                    >
                                                      {limitText(obj?.name, 15)}
                                                    </p>
                                                  )}
                                                  <p className="sub-details">
                                                    {
                                                      Admin_orders?.Unitprice[
                                                      language
                                                      ]
                                                    }
                                                    :{" "}
                                                    <span>
                                                      <i className="bi bi-currency-rupee"></i>
                                                      {obj?.price}
                                                    </span>
                                                  </p>
                                                  <p className="sub-details">
                                                    {
                                                      Admin_orders?.Quantity[
                                                      language
                                                      ]
                                                    }
                                                    :{" "}
                                                    <span>{obj?.quantity}</span>
                                                  </p>
                                                  <p className="sub-details">
                                                    {" "}
                                                    {
                                                      Admin_orders?.Total[
                                                      language
                                                      ]
                                                    }
                                                    :{" "}
                                                    <span>
                                                      {" "}
                                                      <i className="bi bi-currency-rupee"></i>
                                                      {obj?.total_amount}
                                                    </span>
                                                  </p>
                                                </div>

                                                {/* {item?.order_details.length > 1 ? (
                                    <hr />
                                  ) : null} */}
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    </AccordionBody>
                                  </AccordionItem>
                                </UncontrolledAccordion>
                              </>
                            ) : null}

                            {item?.order_details?.length == 1 ? (
                              <>
                                {item?.order_details?.map(
                                  (obj: any, x: number) => {
                                    count = count + obj.quantity;
                                    return (
                                      <React.Fragment key={obj.id}>
                                        <div className="product-details">
                                          {item?.order_status ==
                                            constants.ORDERS.WORKORDERACCEPT ? (
                                            <Link
                                              to=""
                                              title="Allocate Product to Vendor"
                                              onClick={() => {
                                                setProData({
                                                  order_id: item?.order_id,
                                                  tender_id: obj?.tender_id,
                                                });
                                                productVendor(
                                                  obj?.tenderid,
                                                  obj?.product_id
                                                );
                                                allocationHistory(
                                                  obj?.tenderid,
                                                  obj?.product_id
                                                );
                                                setProId({
                                                  tender_id: obj?.tenderid,
                                                  prod_id: obj?.product_id,
                                                  invo_id:
                                                    obj?.pro_forma_invoice_id,
                                                });
                                                setShipData(
                                                  item?.shipping_address[0]
                                                );
                                                setOrderData(obj);
                                                setIsShowing(true);
                                              }}
                                              className="text-decoration-none primary-text product-name"
                                            >
                                              {limitText(obj?.name, 15)}
                                            </Link>
                                          ) : (
                                            <p
                                              title="Allocate Product to Vendor"
                                              className="product-title"
                                            >
                                              {limitText(obj?.name, 15)}
                                            </p>
                                          )}
                                          <p className="sub-details">
                                            {Admin_orders?.Unitprice[language]}:{" "}
                                            <span>
                                              <i className="bi bi-currency-rupee"></i>
                                              {obj?.price * 0.05 + obj?.price}(
                                              <i className="bi bi-currency-rupee"></i>
                                              {obj?.price} + commission
                                              <i className="bi bi-currency-rupee"></i>
                                              {obj?.price * 0.05} )
                                            </span>
                                          </p>
                                          <p className="sub-details">
                                            {Admin_orders?.Quantity[language]}:{" "}
                                            <span>{obj?.quantity}</span>
                                          </p>
                                          <p className="sub-details">
                                            {" "}
                                            {
                                              Admin_orders?.Total[language]
                                            }:{" "}
                                            <span>
                                              {" "}
                                              <i className="bi bi-currency-rupee"></i>
                                              {obj?.total_amount} (Including
                                              GST)
                                            </span>
                                          </p>
                                          {item?.dep_deduction?.map((it: any, i: number) => {
                                            return (
                                              <React.Fragment key={it.id}>
                                              <p className="sub-details">
                                                {it?.item}:
                                                <span>
                                                  <i className="bi bi-currency-rupee"></i>
                                                  {it?.amount}
                                                </span>
                                              </p>
                                              </React.Fragment>
                                            )
                                          })}
                                          <p className="sub-details">
                                            Payable:
                                            <span>
                                              <i className="bi bi-currency-rupee"></i>
                                              {item?.grand_amount - item?.sum_dep_deduction}
                                            </span>
                                          </p>
                                        </div>

                                        {item?.order_details.length > 1 ? (
                                          <hr />
                                        ) : null}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {Admin_orders?.ShippingAddress[language]}:
                          </span>
                          <span className="table-data">
                            <p>
                              {Admin_orders?.BuildingNo[language]}:
                              {item?.shipping_address[0]?.building_no}
                            </p>
                            <p>{item?.shipping_address[0]?.city}</p>
                            <p>
                              {" "}
                              {item?.shipping_address[0]?.district},{" "}
                              {item?.shipping_address[0]?.state}
                            </p>

                            <p>
                              {Admin_orders?.Pincode[language]}:
                              {item?.shipping_address[0]?.pincode}
                            </p>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {Admin_orders?.Quantity[language]}:
                          </span>
                          <span className="table-data">{count}</span>
                        </td>

                        <td>
                          <span className="mobile-heading">
                            {Admin_orders?.Department[language]}:
                          </span>
                          <span className="table-data d-block">
                            {item?.user?.full_name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {Admin_orders?.PaymentStatus[language]}:
                          </span>
                          {item?.payment_mode ==
                            constants.DEPT_PAYMENT_MODES.FULL_PAYMENT ||
                            item?.payment_details?.payment_mode ==
                            constants.DEPT_PAYMENT_MODES.FULL_PAYMENT ? (
                            <span className="table-data d-block">
                              <strong>
                                {" "}
                                {PaymentStatus(
                                  item?.payment_details?.payment_mode
                                    ? item?.payment_details?.payment_mode
                                    : item?.payment_mode
                                )}
                              </strong>
                            </span>
                          ) : (
                            // <Link
                            //   to=""
                            //   onClick={() => {
                            //     setIsShowings(true);
                            //     setPayamentData(item);
                            //     setOrderId(item?.order_details?.[0]?.id);
                            //   }}
                            // >
                            //   <Link to="" className="table-data d-block">
                            //     <Button color="warning">
                            //       <strong>
                            //         {PaymentStatus(
                            //           item?.payment_details?.payment_mode
                            //             ? item?.payment_details?.payment_mode
                            //             : item?.payment_mode
                            //         )}
                            //       </strong>
                            //     </Button>
                            //   </Link>
                            // </Link>

                            <span className="payment-status"
                              onClick={() => {
                                setIsShowings(true);
                                setPayamentData(item);
                                setOrderId(item?.order_details?.[0]?.id);
                              }}
                            >
                              <strong>
                                {PaymentStatus(
                                  item?.payment_details?.payment_mode
                                    ? item?.payment_details?.payment_mode
                                    : item?.payment_mode
                                )}
                              </strong>
                            </span>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={8}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Order Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && received_order?.length != 0 && (
        <div className="table-footer-wrap">
          <small>
            {Admin_orders?.Showing[language]} {pageData.from} to {pageData.to}{" "}
            of {pageData.total} {Admin_orders?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}

      <OrderView
        order_view={order_view}
        modal={modal}
        work_id={id}
        url={url}
        toggle={toggle}
        work_order={1}
        verify_status={verify_status}
        setVerifyStatus={setVerifyStatus}
        loading={loading}
        getDownload={getDownload}
        getReceivedOrder={getReceivedOrder}
        getSingleOrder={getSingleOrder}
        wor_id={wor_id}
      />
      <Formik
        initialValues={{
          low_1_quantity: "",
          low_2_quantity: "",
          low_3_quantity: "",
        }}
        validationSchema={VendorAllocationValidationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          handleBlur,
          setFieldValue,
        }) => {
          return (
            <VendorAllocationModal
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleReset={handleReset}
              handleBlur={handleBlur}
              pro_data={pro_data}
              order_data={order_data}
              ship_data={ship_data}
              length={length}
              vendor_details={vendor_details}
              history={history}
            />
          );
        }}
      </Formik>

      <Formik
        initialValues={initialValuesPayment}
        validationSchema={RequestamountValidationSchema}
        onSubmit={(values, actions) => {
          handleSubmits(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          handleBlur,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <PaymentCheckModal
              isShowing={isShowings}
              setIsShowing={setIsShowings}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleReset={handleReset}
              handleBlur={handleBlur}
              resetForm={resetForm}
              payment_data={payment_data}
              setFieldValue={setFieldValue}
              err={err}
              setErr={setErr}
              check={check}
              setCheck={setCheck}
            />
          );
        }}
      </Formik>
    </>
  );
}
