import React, { useContext} from "react";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
export default function AllValiadation(props: any) {
  const { language, setLanguage } = useContext(Context);
  const count = Object.values(props?.error)?.length;
  return (
    <>
      {count !== 0 ? (
        <>
          {props?.val == 1 && (
            <>
              <div
                className="p-3 mb-2  text-emphasis-danger"
                style={{
                  backgroundColor: "#f8d7da",
                }}
              >
                <span className="text-dark text-center">
                  Some Validations are failed ,Please Check it...
                </span>
              </div>
            </>
          )}
        </>
      ) : null}
    </>
  );
}
