import React, { useContext } from 'react'
import { LandingPageContext } from '../Components/Contexts';
import HomeEng from './English/Home';
import HomeKanada from './Kanada/Home';
import { Context } from '../../Components/Context/Context';

export default function Homes() {
    // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
    const { language, setLanguage } = useContext(Context);

    return (
        language == "en" ? <HomeEng/> : <HomeKanada/>
    )
}
