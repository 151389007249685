import React, { useEffect, useContext} from "react";
import { useState, useMemo } from "react";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  List,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import constants from "../../../Library/Constants/Constants";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import {
  capitalizeFirst,
  getOrderStatus,
} from "../../../Library/Constants/helpers";
import { Formik } from "formik";
import { API_URL } from "../../../Library/ApiSettings";
import { deliveryDocReuploadValidation } from "../PurchaseOrder/OrderValidation";
import axios from "axios";
import { toast } from "react-toastify";
import ProductDetailsModal from "../PurchaseOrder/productDetailsModal";
import DeliveryModal from "../PurchaseOrder/OrderModal/DeliveryModal";

export default function DeliveredOrders(props: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [received_order, setReceivedOrder] = useState([]);
  const [isShowing, setIsShowing] = useState(false);
  const [isShowings, setIsShowings] = useState(false);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [values, setValues] = useState<any>();
  const [order_view, setOrderView] = useState([]);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [datas, setDatas] = useState();
  const [search, setSearch] = useState("");
  const [doc_data, setDocData] = useState<any>([]);
  const [reuploadOpen, setReuploadOpen] = useState<boolean>(false);
  const { language, setLanguage } = useContext(Context);
  const [reuploadParams, setReuploadParams] = useState<any>({
    type: "",
    id: "",
    order_id: "",
  });

  const initialReupload = {
    doc: "",
  };

  const [value, setValue] = useState({
    id: null,
  });

  useEffect(() => {
    getReceivedOrder(currentPage);
  }, [currentPage]);
  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getReceivedOrder(1);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  // ========================================================== Get Recieved Order ==========================================================

  const getReceivedOrder = (page: any) => {
    setLoading(true);
    api
      .get(
        `/order/get-confirmed-orders?status=6&page=${page}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setReceivedOrder(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ================================================================ View ==================================================================
  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-order-details?id=${order_id}&search=${search}`, true)
      .then(async function ([success, response]) {
        setDatas(response?.message?.[0]);
        setLoading(false);
        setOrderView(response?.message);
        setIsShowing(true);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getDocument = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get_delivered_documents?order_id=${order_id}`, true)
      .then(async function ([success, response]) {
        setDocData(response?.message || []);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleSubmitss = (values: any, actions: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(
      `${API_URL}/order/purchase-order-download-vendor/${value?.id}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "workorder.doc";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        // setIsShowing(false);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  const handleSubmitChallan = (values: any, actions: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        // "content-type":"multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(
      `${API_URL}/vendor-delivery-images-download/${value?.id}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "deliverychallan.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setIsShowing(false);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  const handleSubmits = (values: any, actions: any) => {
    const formData = new FormData();
    formData.append("file", values?.doc);
    formData.append("type", reuploadParams?.type);
    formData.append("id", reuploadParams?.id);
    const body = formData;

    axios({
      method: "post",
      url: `${API_URL}/order/deliver_docs_reupload`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          setReuploadOpen(false);
          toast.success("Document Reuploaded Successfuly");
          getDocument(reuploadParams?.order_id);
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeTags = (str: any) => {
    const regex = /(<([^>]+)>)/gi;
    // const result = props?.data?.description.replace(regex, '');
    if (str === null || str === "") return false;
    else str = str?.toString();
    //    let all= str?.replace(/(<<([^>]+)>>)/g, ' ');
    let all = str?.replace(/(<br>)/g, " ,");
    let alls = all?.replace(/(<([^>]+)>)/g, "");
    let val = alls.split(",");
    var vals: any[] = [];
    val.map((items: any, index: any) => {
      var vall = val[index].split(":");
      vals.push([{ key: vall[0], val: vall[1] }]);
    });
    setValues(vals);
    return vals;
  };

  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumb */}
          <List className=" breadcrumb">
            <li>
              <Link className="active" to="/overview-vendor">
                {VendorRegLang?.dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>{pathname == "/delivered-vendor" ? "Delivered" : null}</b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            <div className="search-widget-outer">
              <Form role="search">
                <InputGroup className="search-group">
                  <InputGroupText
                    title="Search"
                    className="search-icon"
                    id="search"
                  >
                    <i className="fa-sharp fa-solid fa-magnifying-glass" />
                  </InputGroupText>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Order No/Product Name"
                    aria-label="Username"
                    aria-describedby="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyPress={(event) => {
                      var key = event.keyCode || event.which;
                      if (key === 13) {
                        event.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
              </Form>
            </div>
            <div className="table-wrap responsive-table">
              <Table className="w-100 table-striped">
                <thead>
                  <tr>
                    <th>{VendorRegLang?.sl_no[language]}</th>
                    <th>{VendorRegLang?.order_id[language]}</th>
                    <th>{VendorRegLang?.products[language]}</th>
                    <th>{VendorRegLang?.order_by[language]}</th>
                    <th>{VendorRegLang?.quantity[language]}</th>
                    <th>{VendorRegLang?.price[language]}</th>
                    <th>{VendorRegLang?.delivered_date[language]}</th>
                    <th>{VendorRegLang?.status[language]}</th>
                    <th>{VendorRegLang?.action[language]}</th>
                  </tr>
                </thead>
                <tbody>
                  {received_order?.length !== 0 ? (
                    <>
                      {received_order?.map((item: any, index: any) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <span className="mobile-heading">{VendorRegLang?.sl_no[language]}:</span>
                                <span className="table-data">{index + 1}</span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {VendorRegLang?.order_id[language]}:
                                </span>
                                <span className="table-data">
                                  <small className="main">
                                    {item?.vendor_po_no}
                                  </small>
                                </span>
                              </td>

                              <td>
                                <span className="mobile-heading">
                                  {VendorRegLang?.products[language]}:
                                </span>
                                <span className="table-data">
                                  <Link
                                    to="#"
                                    className="blue-text"
                                    title="View Product"
                                    onClick={() => {
                                      setIsShowings(true);
                                      removeTags(item?.order_details?.features);
                                    }}
                                  >
                                    <strong>{item?.order_details?.name}</strong>
                                  </Link>
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {VendorRegLang?.order_by[language]}:
                                </span>
                                <span className="table-data">
                                  <p>
                                    {capitalizeFirst(
                                      item?.pf_invoice?.user?.full_name
                                    )}
                                  </p>

                                  {item?.pf_invoice?.user_data?.email}
                                  <p>{item?.pf_invoice?.user_data?.phone}</p>
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">
                                  {VendorRegLang?.quantity[language]}:
                                </span>
                                <span className="table-data">
                                  {item?.order_details?.quantity}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">{VendorRegLang?.price[language]} :</span>
                                <span className="table-data">
                                  <p>
                                    {/* ₹{(((item?.order_details?.cgst * item?.order_details?.quantity) + (item?.order_details?.sgst * item?.order_details?.quantity ) + (item?.order_details?.amount * item?.order_details?.quantity)) - ((((item?.order_details?.cgst * item?.order_details?.quantity) + (item?.order_details?.sgst * item?.order_details?.quantity))) + (item?.order_details?.amount * item?.order_details?.quantity) )* 5/100 )} */}
                                    ₹
                                    {item?.order_details?.cgst *
                                      item?.order_details?.quantity +
                                      item?.order_details?.sgst *
                                        item?.order_details?.quantity +
                                      item?.order_details?.amount *
                                        item?.order_details?.quantity}
                                  </p>
                                </span>
                              </td>

                              <td>
                                <span className="mobile-heading">
                                  {VendorRegLang?.delivered_date[language]}:
                                </span>
                                <span className="table-data">
                                  {item?.delivery_date}
                                </span>
                              </td>

                              <td>
                                <span className="mobile-heading">{VendorRegLang?.status[language]} :</span>
                                <span className="table-data">
                                  <p>{getOrderStatus(item?.status)}</p>
                                </span>
                              </td>
                              <td>
                                <span className="mobile-heading">{VendorRegLang?.action[language]}:</span>
                                <span className="table-data primary-text">
                                  <Link
                                    to="#"
                                    className="primary-text"
                                    title="View"
                                    onClick={() => {
                                      getSingleOrder(item?.id);
                                      getDocument(item?.id);
                                      setValue({
                                        ...value,
                                        id: item?.id,
                                      });
                                      setData(item);
                                    }}
                                  >
                                    <i
                                      title="View"
                                      className="bi bi-eye lg"
                                    ></i>
                                  </Link>
                                </span>
                              </td>
                            </tr>
                            </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td
                          className="text-center px-2 py-5 empty-text"
                          colSpan={8}
                        >
                          {loading ? (
                            <div className="d-flex justify-content-center">
                              <Loader />
                            </div>
                          ) : (
                            `${VendorRegLang?.no_data_found[language]}`                      
                            )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            {pageData && (
              <div className="table-footer-wrap">
                <small>
                  {VendorRegLang?.showing[language]} {pageData?.from != null ? pageData?.from : 0} {VendorRegLang?.to[language]}{" "}
                  {pageData?.to != null ? pageData?.to : 0} {VendorRegLang?.of[language]}{" "}
                  {pageData?.total != null ? pageData?.total : 0} {VendorRegLang?.entries[language]}
                </small>
                <div className="pagination">
                  <CustomPagination
                    totalItems={pageData?.total}
                    itemsPerPage={pageData?.per_page}
                    activePage={pageData.current_page}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isShowing == true ? (
        <Formik
          initialValues={initialReupload}
          validationSchema={deliveryDocReuploadValidation}
          onSubmit={(values, actions) => {
            handleSubmits(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            {
              console.log(values, "ashddsgiuiooj");
            }
            return (
              <DeliveryModal
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                order_view={order_view}
                handleSubmit={handleSubmit}
                handleSubmitChallan={handleSubmitChallan}
                handleSubmitss={handleSubmitss}
                data={datas}
                doc_data={doc_data}
                setReuploadOpen={setReuploadOpen}
                reuploadOpen={reuploadOpen}
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                touched={touched}
                setReuploadParams={setReuploadParams}
                handleReset={handleReset}
              />
            );
          }}
        </Formik>
      ) : null}

      <ProductDetailsModal
        isShowing={isShowings}
        setIsShowings={setIsShowings}
        values={values}
      />
    </React.Fragment>
  );
}