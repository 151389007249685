import React from 'react'
import { Link } from 'react-router-dom';


export default function ContactKanada() {
  return (
    <div><section>
      <div className="contact-container-fluid">
        <div className="contact-banner-outer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-banner-text-wrap">
                  <h1>
                    ಸಂಪರ್ಕಿಸಿ
                  </h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to={"/home"}>ಮುಖಪುಟ</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">ಸಂಪರ್ಕಿಸಿ</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      {/*Contact Section*/}
      <section>
        <div className="contact-us-wrap">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4">
                <div className="contact-info-outer-wrap" style={{ backgroundImage: 'url("../website-images/contact-us-banner2.png")' }}>
                  <div className="heading">
                    <h3>ಸಂಪರ್ಕ ಮಾಹಿತಿ</h3>
                    <p>ವಿವರಗಳನ್ನು ಕೇಳಲು  ಹಿಂಜರಿಯಬೇಡಿ, ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಉಳಿಸಬೇಡಿ!!</p>
                  </div>
                  <div className="contact-info-content">
                    <h5>ಕರ್ನಾಟಕ ರಾಜ್ಯ ಇಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಅಭಿವೃದ್ಧಿ ನಿಗಮ ಸಿದ್ಧಿಗೆ ಮಿತಿಯಿದ್ದ ಕಂಪನಿ ನಿಯಮಿತ</h5>
                    <p><img src={require("../../images/location.png")}alt="icon"className='icon' />
                      2ನೇ ಮಹಡಿ, ಟಿಟಿಎಂಸಿ 'ಏ' ಬ್ಲಾಕ್, ಬಿಎಂಟಿಸಿ, ಶಾಂತಿನಗರ, ಕೆ.ಹೊಂಗೆ, ಬೆಂಗಳೂರು - 560 027.</p>
                    <p><img src={require("../../images/phone.png")} alt="icon" className='icon' />91-80-22225645</p>
                    <p><img src={require("../../images/email.png")} alt="icon" className='icon' />info@keonics.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="map-wrap">
                  {/*Map*/}
                  <div style={{ textDecoration: 'none', overflow: 'hidden', maxWidth: '100%', height: '563px' }}><div id="embed-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7776.459693339103!2d77.592465!3d12.957139!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e222868c71%3A0x8ed74f529c8923fb!2sKEONICS%20Govt%20Of%20Karnataka!5e0!3m2!1sen!2sin!4v1686717464306!5m2!1sen!2sin" /></div><a className="googl-ehtml" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="make-map-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#embed-map-canvas img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
