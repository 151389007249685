
export const Adminnotification:any = {
    //Admin\Notifications\Notificationlist
    Notifications: {
        en: 'Notifications',
        ka: 'ಅಧಿಸೂಚನೆಗಳು'
    },

    Dashboard: {
        en: ' Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },

    PaymentWasDonebyWaterAuthority: {
        en: ' Payment Was Done by Water Authority',
        ka: 'ಪಾವತಿ ನಿಗದಿ ನೀಡಲಾಗಿದೆ'
    },

    Moredescriptiongoeshereanditcanbevery: {
        en: '  More description goes here and it can be very',
        ka: 'ಇಲ್ಲಿ ಹೆಚ್ಚು ವಿವರಣೆ ಹೋಗುತ್ತದೆ ಮತ್ತು ಅದು ಅತ್ಯಂತ ಆಕರ್ಷಕವಾಗಿರಬಹುದು'
    },

    Unread: {
        en: ' Unread',
        ka: 'ಡ್ಓದಲು ಅನಧಿಕೃತ'
    },

    NoNotificationsFound: {
        en: ' No Notifications Found',
        ka: 'ಯಾವುದೇ ಅಧಿಸೂಚನೆಗಳು ಸಿಗಲಿಲ್ಲ'
   },

   Thecompanyispivotingtoanewmarket: {
    en: ' The company is pivoting to a new market',
    ka: 'ಕಂಪನಿಯು ಹೊಸ ಮಾರುಕಟ್ಟೆಗೆ ಬದಲಾಗುತ್ತಿದೆ'
},

Firstparagraph: {
    en: 'For many of us, our very first experience of learning aboutthe celestial bodies begins when we saw our first full moon inthe sky. It is truly a magnificent view even to the naked eye. If the night is clear you can see amazing detail of the lunar surface just star gazing on in your back yard',
    ka: 'ನಮ್ಮಲ್ಲಿ ಹಲವರಿಗೆ, ಸ್ವರ್ಗದ ಬೆಳಕನ್ನು ಕಂಡಾಗ ನಾವು ಪ್ರಥಮ ಸಲ ವ್ಯೋಮದ ಬಗ್ಗೆ ಕಲಿಯುವ ಅನುಭವವು ಬೇರೆ ಬೇರೆಯಾಗಿರುತ್ತದೆ. ಅದು ನಗುನೋಡಕ್ಕೆ ಅತ್ಯಂತ ಅದ್ಭುತವಾದ ದೃಶ್ಯವೇ ಸರಿ. ಇರುವ ರಾತ್ರಿ ಸ್ಪಷ್ಟವಾಗಿದ್ದರೆ, ನೀವು ನಿಮ್ಮ ಮನೆಯ ಹಿಂದಿನ ಕೊಠಡಿಯಲ್ಲಿ ನೋಡುವುದರಿಂದ ಚಂದ್ರನ ಮೇಲಿನ ವಿಚಿತ್ರ ವಿವರಗಳನ್ನು ನೋಡಬಹುದು'
},

Secondparagraph: {
    en: 'Naturally, as you grow in your love of astronomy, you willfind many celestial bodies fascinating. But the moon mayalways be our first love because is the one far away spaceobject that has the unique distinction of flying close to theearth and upon which man has walked',
    ka: 'ಸ್ವಾಭಾವಿಕವಾಗಿ, ನೀವು ಜ್ಯೋತಿರ್ಗಣದಲ್ಲಿ ನಿಮ್ಮ ಪ್ರೀತಿಯನ್ನು ಹೆಚ್ಚಿಸುತ್ತಾ ಹೋಗುವಂತಾಗುತ್ತೀರಿ. ಆದರೆ ಚಂದ್ರನೇ ನಮ್ಮ ಮೊದಲ ಪ್ರೇಮವಾಗಿರುವುದು ಹೌದು, ಏಕೆಂದರೆ ಅದು ಭೂಮಿಗೆ ಹತ್ತಿರದಲ್ಲಿ ಹಾರಾಡುವ ಮಾತ್ರದಿಂದ ಮತ್ತು ಅದರ ಮೇಲೆ ಮಾನವ ನಡೆದಿದ್ದುದರಿಂದ ಅದಕ್ಕೆ ಅನುಕೂಲ ನೀಡುವ ವಿಶೇಷತೆ ಇದೆ'
},

Thirdparagraph: {
    en: 'Your study of the moon, like anything else, can go from the simple to the very complex. To gaze at the moon with the nakedeye, making yourself familiar with the lunar map will help youpick out the seas, craters and other geographic phenomenonthat others have already mapped to make your study moreenjoyable. Moon maps can be had from any astronomy shop oronline and they are well worth the investment.',
    ka: 'ನೀವು ಚಂದ್ರನ ಅಧ್ಯಯನವನ್ನು ಮಾಡುವುದು ಸರಳದಿಂದ ತುಂಬಾ ಜಟಿಲವಾಗಿರಬಹುದು. ನಂತರದ ಕೆಲಸಗಾರಿಕೆಗಳನ್ನು ನೋಡುವುದರಿಂದ ಮೊದಲು ನಗುವಿನ ಕಣ್ಣಿನಿಂದ ಚಂದ್ರನನ್ನು ನೋಡಲು, ಚಂದ್ರಪ್ರದೇಶವನ್ನು ನಿಮಗೆ ಪರಿಚಯ ಮಾಡಿಕೊಳ್ಳುವುದು ನಿಮ್ಮ ಅಧ್ಯಯನವನ್ನು ಹೆಚ್ಚಿನ ಆನಂದಕರವಾಗಿ ಮಾಡುವುದಕ್ಕೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಚಂದ್ರ ನಕ್ಷೆಗಳನ್ನು ಯಾವುದೇ ಜ್ಯೋತಿಷ್ಯ ಕಟ್ಟಡದಿಂದ ಅಥವಾ ಆನ್ಲೈನ್ ಪಡೆಯಬಹುದು ಮತ್ತು ಅವುಗಳು ನಿರ್ಮಾಣ ಮಾಡಲ್ಪಟ್ಟ ವೆಚ್ಚವನ್ನು ಮರೆತುಬಿಡಲು ಅದು ಅರ್ಹವಾಗಿದೆ'
},

}