import moment from "moment";
import React, { useEffect, useState, useRef ,useContext} from "react";
import { useLocation } from "react-router-dom";
import { Context } from "../../../Components/Context/Context";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang"; 
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Label,
    Collapse,
    Table,
  } from "reactstrap";
export default function TrackOrder(props:any) {
  const { language, setLanguage } = useContext(Context);
    const location = useLocation();
  return (
    <>
      <div className="progress-tracker">
        <div className="progress-tracker-wrap">
          <Row className="align-items-center">
            <h5 className="sub-heading">{VendorRegLang?.OrderTrackingStatus?.[language]}</h5>
            <Col md={12}>
              <div className="tracker-status-details-wrap">
                <ul className="main-list">
                  {location?.pathname == "/received-order-vendor" ? (
                    <>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle Received"></div>
                            <p className="track-status-text">{VendorRegLang?.Received?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">
                              {props?.order_view?.[0]?.order_details?.created_at
                                .toString()
                                .slice(0, 10)}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="emptycircle Processed"></div>
                            <p className="track-status-text">{VendorRegLang?.Processed?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="emptycircle OutForDelivery"></div>
                            <p className="track-status-text">
                            {VendorRegLang?.OutForDelivery?.[language]}
                            </p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="emptycircle Delivered"></div>
                            <p className="track-status-text">{VendorRegLang?.Delivered?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : location?.pathname == "/processed-order-vendor" ? (
                    <>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle Received"></div>
                            <p className="track-status-text">{VendorRegLang?.Received?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle Processed"></div>
                            <p className="track-status-text">{VendorRegLang?.Processed?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">
                              {props?.order_view?.[0]?.updated_at
                                .toString()
                                .slice(0, 10)}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="emptycircle OutForDelivery"></div>
                            <p className="track-status-text">
                            {VendorRegLang?.out_for_delivery?.[language]}
                            </p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="emptycircle Delivered"></div>
                            <p className="track-status-text">{VendorRegLang?.Delivered?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : location?.pathname == "/outofdelivery" ? (
                    <>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle Received"></div>
                            <p className="track-status-text">{VendorRegLang?.Received?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle Processed"></div>
                            <p className="track-status-text">{VendorRegLang?.Processed?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle OutForDelivery"></div>
                            <p className="track-status-text">
                            {VendorRegLang?.OutforDelivery?.[language]}
                            </p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">
                              {props?.order_view?.[0]?.updated_at
                                .toString()
                                .slice(0, 10)}
                            </p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="emptycircle Delivered"></div>
                            <p className="track-status-text">{VendorRegLang?.Delivered?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Pending?.[language]}</p>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : location?.pathname == "/delivered-vendor" ? (
                    <>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle Received"></div>
                            <p className="track-status-text">{VendorRegLang?.Received?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle Processed"></div>
                            <p className="track-status-text">{VendorRegLang?.Processed?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle OutForDelivery"></div>
                            <p className="track-status-text">
                            {VendorRegLang?.OutforDelivery?.[language]}
                            </p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">{VendorRegLang?.Completed?.[language]}</p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="flex-outer">
                          <div className="flex-wrap">
                            <div className="circle Delivered"></div>
                            <p className="track-status-text">{VendorRegLang?.Delivered?.[language]}</p>
                          </div>
                          <div className="date">
                            <p className="track-status-text">
                              {props?.order_view?.[0]?.updated_at
                                .toString()
                                .slice(0, 10)}
                            </p>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : (
                    " "
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
