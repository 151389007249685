import { ErrorMessage } from 'formik'
import React, { useEffect, useState,useContext } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { api } from '../../../../Library/CustomApi'
import { Context } from "../../../../Components/Context/Context";
import {Admin_orders} from "../../../../Library/LanguageConversion/Admin_orders";

interface propType {
    QAVendorSubmitHandler: Function,
}

const AssignQAVendorModal = ({ QAVendorSubmitHandler }: propType) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [QAVendors, setQAVendors] = useState<any>([])
    const [selectedQAVendors, setSelectedQAVendors] = useState<string>('')
    const { language, setLanguage } = useContext(Context);
    useEffect(() => {
        getQAVendors()
    }, [])


    // gets list of QA Vendors 
    const getQAVendors = () => {
        setIsLoading(true)
        api
            .get(`/qa_ratecontractvendors`, true)
            .then(async function ([success, response]) {
                setQAVendors(response?.data)
                setIsLoading(false)
                return response;
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            });
    };

    const submitHandler = () => {
        QAVendorSubmitHandler(selectedQAVendors)
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <div className="custom-input">
                        <FormGroup className="form-group">
                            <Label> {Admin_orders?.SelectQAVendor[language]}
                                <i
                                    className="fa fa-asterisk required-field"
                                    color="text-danger"
                                    aria-hidden="true"
                                ></i>
                            </Label>
                            <Input
                                type="select"
                                name="city"
                                onChange={(e: any) => {
                                    setSelectedQAVendors(e.target.value)
                                }}
                            >
                                <option value="">  {Admin_orders?.SelectQAVendor[language]}</option>
                                {QAVendors?.map((itm: any, i: number) => {
                                    return <option value={itm?.id} key={i}>{itm?.full_name}</option>
                                })}
                            </Input>
                            <Button
                                onClick={submitHandler}
                                disabled={!selectedQAVendors}
                                className='my-3'
                            > {Admin_orders?.Assign[language]}</Button>
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default AssignQAVendorModal