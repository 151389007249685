import {
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";

interface propType {
    open: boolean // modal open / close
    setOpen:Function
    header?: string,
    children: any
}

const ModalContainer = (props: propType) => {
    
    return (
        <>
            <Modal
                className="modal-outer"
                isOpen={props.open}
                style={{  maxWidth: "880px" }}
            >
                <ModalHeader toggle={()=>props.setOpen(false)}>
                    {props.header}
                </ModalHeader>
                <ModalBody >
                    {
                        props.children
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default ModalContainer