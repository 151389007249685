import { Row, Col, Button, Table, Spinner } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../../Library/CustomApi";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/Loader";
import { API_URL } from "../../../Library/ApiSettings";
import axios from "axios";
import { Context } from "../../../Components/Context/Context";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import UploadWorkOrderModal from "./UploadWorkOrderModal";
import moment from "moment";
import Select from "react-select";
import { convertISODateToDateTime } from "../../../Library/Constants/helpers";
import { DepartmentProducts } from "../../../Library/LanguageConversion/Department_Products";

const ProformaInvoice = () => {
  const { updateCartCount } = useContext(Context);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [proformaData, setProformaData] = useState<any>([]);
  const [deductions, setDeductions] = useState<any>([]);
  const [selectedDeductions, setSelectedDeductions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [uploadWOModal, setUploadWOModal] = useState<boolean>(false);
  const [woDownloading, setWoDownloading] = useState(false);
  const [status, setStatus] = useState<boolean>(false); // false:work order not uploaded, true: uploaded
  const [proformaDownloading, setProformaDownloading] = useState(false);
  const { language, setLanguage } = useContext(Context);

  const [total, setTotal] = useState<number>(0);
  // Get today's date
  const today: Date = new Date();

  // Add 90 days to today's date
  const futureDate: Date = new Date();
  futureDate.setDate(today.getDate() + 90);

  // Format the dates
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedToday: string = today.toLocaleDateString("en-GB", options);
  const formattedFutureDate: string = futureDate.toLocaleDateString(
    "en-GB",
    options
  );

  useEffect(() => {
    getProforma();
    if (!state?.id) {
      navigate('/department/products/1')
    }
  }, [state?.id]);

  const getProforma = () => {
    if (state?.id) {
      setIsLoading(true);
      api
        .get(`/order/pf-invoice/${state?.id}`, true)
        .then(async function ([success, response]) {
          setProformaData(response?.data);
          setIsLoading(false);
          updateCartCount();
          getDeductions();
          return response;
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          toast.error("Unexpected error occured");
          navigate("/department/cart");
        });
    } else {
      navigate("/department/cart");
    }
  };

  const clearCart = () => {
    api
      .get(`/order/clear-cart`, true)
      .then(async function ([success, response]) {
        updateCartCount();
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const workOrderDownloadHandle = () => {
    setWoDownloading(true);
    if (state?.id) {
      axios({
        url: `${API_URL}/order/purchase-order-download/${state?.id}`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
        },
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Work-order-${proformaData?.order_id}.doc`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          setWoDownloading(false);
        })
        .catch(function (error) {
          console.log(error);
          setWoDownloading(false);
        });
    } else {
      console.log("id error");
      return;
    }
  };

  // proforma download handler
  const proformaDownloadHandler = (status: 1 | 2) => {
    setProformaDownloading(true);
    axios({
      url: `${API_URL}/order/proforma-download/${state?.id}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
      },
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Commercial Proposal-${proformaData?.order_id}.pdf`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        setProformaDownloading(false);
      })
      .catch(function (error) {
        console.log(error);
        setProformaDownloading(false);
      });
  };

  // gets deductions information
  const getDeductions = () => {
    api
      .get(`/deduction/1`, true)
      .then(async function ([success, response, status]) {
        let datas: any = [];
        response?.map((elem: any) => {
          datas.push({
            value: elem?.id,
            label: elem?.name,
            rate: elem?.value,
          });
        });
        setDeductions(datas || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // removes deductions
  const removeDeductionHandler = (index: number) => {
    setSelectedDeductions(
      selectedDeductions?.filter((itm: any, i: number) => i !== index)
    );
  };

  // handles save deductions
  const handleSaveDeduction = () => {
    setIsLoading(true);
    const formData = new FormData();
    var data: any = new Array();

    selectedDeductions.forEach((el: any) => {
      data.push({
        invoice_number: proformaData?.id,
        item: el?.label,
        rate: el?.rate,
        amount:
          (proformaData?.grand_amount - proformaData?.grand_gst) *
          (el?.rate / 100),
      });
    });

    formData.append("deduction", JSON.stringify(data));
    const body = formData;

    axios({
      method: "post",
      url: `${API_URL}/save_deduction`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        getProforma();
        console.log(response);
        toast?.success("Saved");
      })
      .catch(function (response) {
        setIsLoading(false);
        console.log(response);
        toast.error("Error");
      });
  };

  return (
    <>
      {/* inner contents starts here */}
      {proformaData?.length !== 0 && !isLoading && (
        <div className="right-content-wrap">
          <div className="custom-tab-content">
            <div className="table-outer-wrap">
              <h2 className="page-heading">{DepartmentProducts?.Commercial_Proposal[language]}</h2>
              <div className="invoice-content">
                <Row>
                  <Col md={12}>
                    <Row className="gy-4">
                      <Col md={5}>
                        <Row>
                          <Col md={12}>
                            <small className="InvoiceNum">
                              {DepartmentProducts?.Commercial_No[language]} :{" "}
                              <span>{proformaData?.order_id}</span>
                            </small>
                          </Col>
                          <Col md={6}>
                            <small className="InvoiceNum">
                              <strong>{DepartmentProducts?.ValidFrom[language]}: </strong>
                              <span>{formattedToday || null}</span>
                            </small>
                          </Col>
                          <Col md={6}>
                            <small className="InvoiceNum">
                              <strong>{DepartmentProducts?.ValidTo[language]}: </strong>
                              <span>{formattedFutureDate || null}</span>
                            </small>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={7}>
                        <div className="proforma-top-btn-wrap">
                          <Button
                            color="outline"
                            className="btn performa-download-btn"
                            onClick={() => proformaDownloadHandler(1)}
                          >
                            {proformaDownloading ? (
                              <Spinner
                                as="span"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                              />
                            ) : (
                              <>
                                <i className="bi bi-download me-2"></i>{DepartmentProducts?.Download_Commercial_Proposal[language]}
                              </>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <div className="billed-to my-3">
                <h6 className='green-text'>Billed To</h6>
                {proformaData.length !== 0 && <small>{proformaData?.shipping_address[0]?.department}<br />
                  Building No:{proformaData?.shipping_address[0]?.building_no}<br />
                  {proformaData?.shipping_address[0]?.landmark}<br />
                  {proformaData?.shipping_address[0]?.city}<br />
                  {proformaData?.shipping_address[0]?.district}, {proformaData?.shipping_address[0]?.state} <br />
                  Pincode: {proformaData?.shipping_address[0]?.pincode} </small>}
              </div> */}
                <div className="table-outer-wrap">
                  <div className=" table-wrap responsive-table">
                    <Table className="table-striped">
                      <thead>
                        <tr>
                          <th>{DepartmentProducts?.SlNo[language]}</th>
                          <th>{DepartmentProducts?.Product_Name[language]}</th>
                          <th>{DepartmentProducts?.Features[language]}</th>
                          <th>{DepartmentProducts?.Quantity[language]}</th>
                          <th>{DepartmentProducts?.UnitPrice[language]}</th>
                          <th>{DepartmentProducts?.TotalPrice[language]}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {proformaData?.order_details?.map(
                          (obj: any, i: number) => {
                            return (
                              <React.Fragment key={i}>
                                <tr>
                                  <td>
                                    <span className="mobile-heading">
                                      {DepartmentProducts?.SlNo[language]}:
                                    </span>
                                    <span className="table-data">{i + 1}</span>
                                  </td>
                                  <td>
                                    <span className="mobile-heading">
                                      {DepartmentProducts?.Product_Name[language]}:
                                    </span>
                                    <span className="table-data">
                                      <strong>{obj?.products?.name}</strong>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="mobile-heading">
                                      {DepartmentProducts?.Features[language]}:
                                    </span>
                                    <span className="table-data">
                                      <p
                                        className="prod-details"
                                        dangerouslySetInnerHTML={{
                                          __html: obj?.products?.features,
                                        }}
                                      ></p>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="mobile-heading">
                                      {DepartmentProducts?.Quantity[language]}:
                                    </span>
                                    <span className="table-data">
                                      {obj.quantity}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="mobile-heading">
                                      {DepartmentProducts?.UnitPrice[language]}:
                                    </span>
                                    <span className="table-data">
                                      <span className="green-text no-wrap">
                                        ₹ {obj?.unit_price} ( + GST ₹{obj?.igst} )
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="mobile-heading">
                                      {DepartmentProducts?.TotalPrice[language]}:
                                    </span>
                                    <span className="table-data">
                                      <span className="green-text no-wrap">
                                        ₹ {obj?.total_amount}
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {/* <hr /> */}

                <div className="table-outer-wrap">
                  <div className=" table-wrap responsive-table">
                    <Table className="table-striped">
                      <thead>
                        <tr>
                          <th>{DepartmentProducts?.SubTotal[language]}</th>
                          <th>{DepartmentProducts?.GST[language]}</th>
                          <th>{DepartmentProducts?.TotalAmount[language]}</th>
                          {/* <th>TDS on GST at 2% Amount</th> */}
                          <th>{DepartmentProducts?.Payable_Amount[language]}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="mobile-heading">{DepartmentProducts?.SubTotal[language]}:</span>
                            <span className="table-data">
                              <p>
                                ₹&nbsp;
                                {proformaData?.grand_amount &&
                                  (
                                    proformaData?.grand_amount -
                                    proformaData?.grand_gst
                                  )?.toFixed(2)}
                              </p>
                            </span>
                          </td>
                          <td>
                            <span className="mobile-heading">GST:</span>
                            <span className="table-data">
                              <p>
                                ₹&nbsp;
                                {proformaData?.grand_gst &&
                                  proformaData?.grand_gst}
                              </p>
                            </span>
                          </td>
                          <td>
                            <span className="mobile-heading">
                              {DepartmentProducts?.TotalAmount[language]}:
                            </span>
                            <span className="table-data">
                              <span className="green-text no-wrap">
                                <p>
                                  ₹&nbsp;
                                  {proformaData?.grand_amount &&
                                    proformaData?.grand_amount}
                                </p>
                              </span>
                            </span>
                          </td>
                          {/* <td>
                          <span className='mobile-heading'>TDS on GST at 2% Amount:</span>
                          <span className="table-data">
                          <span className='green-text no-wrap'>
                            ₹
                            {proformaData?.tds_on_gst}
                          </span>
                          </span>
                        </td> */}
                          <td>
                            <span className="mobile-heading">
                              {DepartmentProducts?.Payable_Amount[language]}:
                            </span>
                            <span className="table-data">
                              <span className="green-text no-wrap">
                                ₹
                                {proformaData?.grand_amount &&
                                  (
                                    proformaData?.grand_amount -
                                    proformaData?.sum_dep_deduction
                                  ).toFixed(2)}
                              </span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>

                {/* deductions  */}
                <div className="table-outer-wrap">
                  <h3 className="green-text">{DepartmentProducts?.Deductions[language]}</h3>
                  {proformaData?.dep_deduction?.length == 0 ? (
                    <>
                      <div className="deductions-select">
                        <div className="add-deduction-wrap">
                          <p>{DepartmentProducts?.Add_Deduction[language]}</p>
                          
                        </div>
                        <div className="select-wrap">
                          <Select
                            name="category"
                            options={deductions}
                            value={selectedDeductions}
                            onChange={(category: any) =>
                              setSelectedDeductions(category)
                            }
                            isLoading={false}
                            isSearchable={true}
                            isMulti={true}
                            isClearable
                          />
                        </div>
                      </div>
                      <div className=" table-wrap responsive-table">
                        <Table className="table-striped">
                          <thead>
                            <tr>
                              <th>{DepartmentProducts?.SlNo[language]}</th>
                              <th>{DepartmentProducts?.Item[language]}</th>
                              <th>{DepartmentProducts?.Rate[language]}</th>
                              <th>{DepartmentProducts?.Amount[language]}</th>
                              <th>{DepartmentProducts?.Action[language]}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedDeductions?.length !== 0 ? (
                              selectedDeductions?.map((it: any, i: number) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <span className="mobile-heading">
                                        {DepartmentProducts?.SlNo[language]}:
                                      </span>
                                      <span className="table-data">
                                        <p>{i + 1}</p>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mobile-heading">
                                        {DepartmentProducts?.Item[language]}:
                                      </span>
                                      <span className="table-data">
                                        <p>{it?.label}</p>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mobile-heading">
                                        {DepartmentProducts?.Rate[language]}:
                                      </span>
                                      <span className="table-data">
                                        <p>{it?.rate} %</p>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mobile-heading">
                                        {DepartmentProducts?.Amount[language]}:
                                      </span>
                                      <span className="table-data">
                                        <p>
                                          ₹
                                          {(
                                            (proformaData?.grand_amount -
                                              proformaData?.grand_gst) *
                                            (it?.rate / 100)
                                          ).toFixed(2)}{" "}
                                        </p>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="mobile-heading"></span>
                                      <span className="table-data">
                                        <span
                                          onClick={() =>
                                            removeDeductionHandler(i)
                                          }
                                        >
                                          <i
                                            title="Remove"
                                            className="icons icons-close pointer"
                                          ></i>
                                        </span>
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={5} className="text-center empty-text">
                                  {DepartmentProducts?.No_Deductions_Selected[language]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>

                      {selectedDeductions?.length !== 0 && (
                        <Button
                          color="primary"
                          className="text-end tds-save-btn"
                          onClick={handleSaveDeduction}
                          disabled={isLoading}
                        >
                          Save
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <div className=" table-wrap responsive-table">
                        <Table className="table-striped">
                          <thead>
                            <tr>
                              <th>{DepartmentProducts?.SlNo[language]}</th>
                              <th> {DepartmentProducts?.Item[language]}</th>
                              <th> {DepartmentProducts?.Rate[language]}</th>
                              <th> {DepartmentProducts?.Amount[language]}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {proformaData?.dep_deduction?.length !== 0 ? (
                              proformaData?.dep_deduction?.map(
                                (it: any, i: number) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <span className="mobile-heading">
                                          {DepartmentProducts?.SlNo[language]}:
                                        </span>
                                        <span className="table-data">
                                          <p>{i + 1}</p>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mobile-heading">
                                          {DepartmentProducts?.Item[language]}:
                                        </span>
                                        <span className="table-data">
                                          <p>{it?.item}</p>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mobile-heading">
                                          {DepartmentProducts?.Rate[language]}:
                                        </span>
                                        <span className="table-data">
                                          <p>{it?.rate} %</p>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="mobile-heading">
                                          {DepartmentProducts?.Amount[language]}:
                                        </span>
                                        <span className="table-data">
                                          <p>₹{it?.amount} </p>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  {DepartmentProducts?.NoDeduction[language]}
                                </td>
                              </tr>
                            )}

                            {proformaData?.sum_dep_deduction && (
                              <tr>
                                <td colSpan={2} className="text-center"></td>
                                <td>{DepartmentProducts?.Total[language]}</td>
                                <td>₹{proformaData?.sum_dep_deduction}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  )}
                </div>

                <div className="proforma-invoice-btn mt-3">
                  <Button
                    color="danger"
                    outline
                    onClick={() => navigate("/department/cart")}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    onClick={workOrderDownloadHandle}
                  >
                    {woDownloading ? (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    ) : (
                      "Download Work Order"
                    )}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setUploadWOModal(true)}
                  >
                    {DepartmentProducts?.Upload_Work_Order[language]}
                  </Button>
                  {status
                    ? proformaData?.grand_amount &&
                    proformaData?.dep_deduction?.length !== 0 && (
                      <Button
                        color="primary"
                        onClick={() => {
                          clearCart();
                          navigate("/department/proforma-checkout", {
                            state: {
                              proformaData: proformaData,
                              id: state?.id,
                            },
                            replace: true
                          });
                        }}
                      >
                        {DepartmentProducts?.Confirm_Order[language]}
                      </Button>
                    )
                    : null}
                </div>
                {/* </Col>
              </Row> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* upload work order modal  */}
      <div>
        <ModalContainer
          open={uploadWOModal}
          setOpen={setUploadWOModal}
          header={"Upload Work Order / Meeting Proceedings / Internal Letters"}
        >
          <UploadWorkOrderModal
            id={state?.id}
            setUploadWOModal={setUploadWOModal}
            setStatus={setStatus}
          />
        </ModalContainer>
      </div>

      {isLoading && (
        <div className="w-100 d-flex justify-content-center pt-5">
          <Loader />
        </div>
      )}
    </>
  );
}

export default ProformaInvoice;
