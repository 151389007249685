import React, { useEffect,useState,useContext } from "react";
import { Row, Col, Label, Input, Form, FormGroup, Button, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import { ErrorMessage, Formik } from "formik";
import TextError from "../../../../Components/Errors/TextError";
import { ProfilePasswordValidation, ProfileValidation } from "../ValidationSchemas/ProfileValidationSchema";
import Loader from "../../../../Components/Loader/Loader";
import { toast } from "react-toastify";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { DepartmentSettings } from "../../../../Library/LanguageConversion/Department_Settings";
import { Context } from "../../../../Components/Context/Context";

export default function DeptProfileSettings(args: any) {
  // modal
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const toggle = () => {
    setOtpVerified(false)
    setOtpSent(false)
    setModal(!modal)
  };
  const toggle2 = () => setModal2(!modal2);
  const [isLoading, setIsLoading] = useState(false);
  const [otpSentLoading, setOtpSentLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [profile, setProfile] = useState<any>([]);
  const [passwordShow, setPasswordShow] = useState<boolean>(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const {language,setLanguage}=useContext(Context);


  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    setIsLoading(true);
    api
      .get(`/profile`, true)
      .then(async function ([success, response]) {
        setProfile(response[0]);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const initialValues = {
    fullname: profile?.name || '',
    phone: profile?.user?.phone || '',
    email: profile?.user?.email || ''
  };
  const PasswordinitialValues = {
    password: '',
    password_confirmation: '',
    otp: ''
  }

  // for changing name, phone, and email
  const handleDetailsChange = (values: any, actions: any) => {
    api
      .patch(
        `/profile/1`,
        {
          full_name: values?.fullname,
          phone: values?.phone,
          email: values?.email
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Details changed");
        actions.resetForm()
        setIsChanged(false)
        getProfileData()
        return response;
      })
      .catch((err) => {
        if (err?.response?.data?.phone) {
          actions?.setFieldError('phone', err?.response?.data?.phone)
        }
        if (err?.response?.data?.email) {
          actions?.setFieldError('email', err?.response?.data?.email)
        }
        setIsChanged(true)
      });
  }

  // password verify otp 
  const VerifyOtp = (otp: any) => {
    setVerifyLoading(true)
    api
      .post(
        `/check-password-otp`,
        {
          otp: otp,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          setOtpVerified(true)
          toast.success(response?.message);
        }
        setVerifyLoading(false)
        return response;
      })
      .catch((err) => {
        console.log(err);
        toast.error('Invalid OTP')
        setVerifyLoading(false)
      });
  };

  // password change OTP 
  const SendOtp = () => {
    setOtpSentLoading(true)
    api
      .post(`/password_otp`, {}, true)
      .then(async function ([success, response, status]) {
        if (status == 200) {
          setOtpSent(true)
          toast.success(response?.message);
        }
        setOtpSentLoading(false)
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setOtpSentLoading(false)
      });
  };

  // password change 
  const handleUpdateSubmit = (values: any, actions: any) => {
    api
      .post(
        `/change-password`,
        {
          password: values?.password,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success(response?.message);
          toggle();
        }
        return response;
      })
      .catch((err) => {
        actions.setSubmitting(false);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {isLoading &&
        <div className="d-flex justify-content-center pt-5">
          <Loader />
        </div>}
      {profile?.length !== 0 && !isLoading && <>
        <div className="profile-tab-content">
          <div className="table-outer-wrap ">
            <div className="main-wrap">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={ProfileValidation}
                onSubmit={(values, actions) => {
                  // submit handler 
                  handleDetailsChange(values, actions)
                }}
              >
                {({
                  handleSubmit,
                  values,
                  handleChange,
                  errors,
                  touched,
                  isSubmitting,
                  handleReset,
                  setFieldValue,
                  setFieldError,
                  resetForm,
                }) => {

                  return (
                    <>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>{DepartmentSettings?.Full_Name[language]}</Label>
                                <Input type="text" name="fullname" title="Edit name" value={values.fullname} onChange={(e: any) => {
                                  handleChange(e)
                                  setIsChanged(true)
                                }}
                                disabled={values?.fullname?true:false} />
                                <ErrorMessage name="fullname" component={TextError} />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <div className="custom-input"
                              // title="Edit phone number"
                              >
                                <Label>{DepartmentSettings?.Phone_Number[language]}</Label>
                                <Input type="tel" name="phone" value={values.phone} onChange={(e: any) => {
                                  handleChange(e)
                                  setIsChanged(true)
                                }} 
                                disabled={values?.phone?true:false}
                                />
                                <ErrorMessage name="phone" component={TextError} />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>{DepartmentSettings?.Password[language]}</Label>
                                <InputGroup onClick={toggle} title='Change password'>
                                  <Input type="password" value='1111111111' disabled />
                                  <Button color="primary" onClick={toggle}><i className="bi bi-pencil"></i></Button>
                                </InputGroup>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>{DepartmentSettings?.Email[language]}</Label>
                                <InputGroup>
                                  <Input type="email" name="email" value={values.email}
                                    onChange={(e) => {
                                      handleChange(e)
                                      setIsChanged(true)
                                    }}
                                  />
                                  {/* <Button color="primary"
                                    // onClick={toggle2}
                                  >
                                    <i className="bi bi-exclamation-circle"></i>
                                  </Button> */}
                                </InputGroup>
                                <ErrorMessage name="email" component={TextError} />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            {isChanged && <div className="action-flex-btn">
                              <Button type="submit" className='btn btn-primary' color='primary' >{DepartmentSettings?.Update[language]}</Button>
                              <Button className='btn btn-primary' color='outline' onClick={(e) => {
                                handleReset(e)
                                setIsChanged(false)
                              }}>Cancel</Button>
                            </div>}
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>

        {/*Change Password Modal */}
        <div>
          <ModalContainer
            open={modal}
            setOpen={() => toggle()}
            header={DepartmentSettings?.Change_password[language]}
          >
            <div className="change-pass-wrap">
              <div className="change-password-form">
                <Formik
                  initialValues={PasswordinitialValues}
                  enableReinitialize
                  validationSchema={ProfilePasswordValidation}
                  onSubmit={(values, actions) => {
                    // submit handler 
                    handleUpdateSubmit(values, actions);
                  }}
                >
                  {({
                    handleSubmit,
                    values,
                    handleChange,
                    errors,
                    touched,
                    isSubmitting,
                    handleReset,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched
                  }) => {

                    return (
                      <>
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DepartmentSettings?.Enter_Password[language]}</Label>
                                  <InputGroup>
                                    <Input type='password' name="password" value={values.password} onChange={(e) => {
                                      setFieldTouched('password')
                                      handleChange(e)
                                    }} />
                                  </InputGroup>
                                  <ErrorMessage name="password" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DepartmentSettings?.Confirm_Password[language]}</Label>
                                  <InputGroup>
                                    <Input type={passwordShow ? 'text' : 'password'} name="password_confirmation" value={values.password_confirmation} onChange={(e) => {
                                      setFieldTouched('password_confirmation')
                                      handleChange(e)
                                    }} />
                                    <InputGroupText onClick={() => setPasswordShow(!passwordShow)}>
                                      <i className="bi bi-eye" ></i>
                                    </InputGroupText>
                                  </InputGroup>
                                  <ErrorMessage name="password_confirmation" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={12}>
                              {otpSentLoading ? <Spinner
                                as="span"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                              /> :
                                !otpVerified && !errors.password && !errors.password_confirmation && values.password && values.password_confirmation && <Link to='' className="text-decoration-none otp-link" onClick={() => {
                                  SendOtp()
                                }}><small>{!otpSent ? 'Send OTP' : 'Resend OTP'}</small></Link>
                              }
                            </Col>
                            <Col sm={6}>
                              {otpSent && <FormGroup>
                                <div className="custom-input">
                                  <Label>{DepartmentSettings?.Enter_OTP[language]}</Label>
                                  <Input type="text" name="otp" disabled={otpVerified} value={values.otp} onChange={handleChange}>
                                  </Input>
                                  <ErrorMessage name="otp" component={TextError} />
                                </div>
                              </FormGroup>}
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <div className="verify-otp-text">
                                    {otpVerified ? <div><i className="fa fa-check-circle text-success"></i>{DepartmentSettings?.Verified[language]}</div> :

                                      verifyLoading ?
                                        <Spinner
                                          as="span"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          animation="border"
                                        /> :
                                        values.otp && <Link to='' className="primary-text me-2" onClick={() => { VerifyOtp(values?.otp) }}><small>{DepartmentSettings?.Verify_OTP[language]}</small></Link>
                                    }
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col className="text-end">
                              <Button color="primary" className="mt-4" disabled={!otpVerified} type="submit">{DepartmentSettings?.Update[language]}</Button>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </ModalContainer>
        </div>

        {/*Email Verification Modal */}
        <div>
          <ModalContainer
            open={modal2}
            setOpen={() => toggle2()}
            header='Email Verification'
          >
            <div className="email-verify-wrap">
              <div className="heading">
                <h5 className="primary-text">{DepartmentSettings?.Verify_link[language]}</h5>
              </div>
              <div className="forgot-password-form">
                <small>{DepartmentSettings?.Please_click[language]}</small>
              </div>
            </div>
          </ModalContainer>
        </div>
      </>}
    </React.Fragment>
  );
}