import React from "react";
import constants from "../../../Library/Constants/Constants"; 
// import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
// import { Context } from "../../../Components/Context/Context";

// const { language, setLanguage } = useContext(Context);
const PaymentItems = ({
  selectedMethod,
  setSelectedMethod,
  get_Type_payment_method,}) => {
    
  return (
    <>
      <div className="choose-list-wrap">
        <div
          className={`choose-type-inner  ${
            selectedMethod == constants?.PAYMENT_TYPE?.BANK ? "active" : ""
          }`}
          onClick={() => {
            setSelectedMethod(constants?.PAYMENT_TYPE?.BANK);
            get_Type_payment_method(constants?.PAYMENT_TYPE?.BANK);
          }}
        >
          {/* <i className="bi bi-bank2 d-block"></i> */}
          <img className="icon" src="/images/banking.png" alt="icon" />
          <small>Direct Bank Transfer</small>

          {/* <small>{VendorRegLang?.direct_bank_transfer[language]}</small> */}
        </div>

        <div
          className={`choose-type-inner ${
            selectedMethod == constants?.PAYMENT_TYPE?.NETBANK ? "active" : ""
          }`}
          onClick={() => {
            setSelectedMethod(constants?.PAYMENT_TYPE?.NETBANK);
            get_Type_payment_method(constants?.PAYMENT_TYPE?.NETBANK);
          }}
        >
          {/* <i className="bi bi-credit-card-2-back d-block"></i> */}
          <img className="icon" src="/images/credit-card.png" alt="icon" />
          <small>NetBanking</small>
          {/* <small>{VendorRegLang?.net_banking[language]}</small> */}
        </div>

        <div
          className={`choose-type-inner ${
            selectedMethod == constants?.PAYMENT_TYPE?.CHEQUE ? "active" : ""
          }`}
          onClick={() => {
            setSelectedMethod(constants?.PAYMENT_TYPE?.CHEQUE);
            get_Type_payment_method(constants?.PAYMENT_TYPE?.CHEQUE);
          }}
        >
          {/* <i className="bi bi-cash d-block"></i> */}
          <img className="icon" src="/images/cheque(1).png" alt="icon" />
          <small>Cheque</small>
          {/* <small>{VendorRegLang?.cheque[language]}</small> */}
        </div>

        <div
          className={`choose-type-inner ${
            selectedMethod == constants?.PAYMENT_TYPE?.CHELLAN ? "active" : ""
          }`}
          onClick={() => {
            setSelectedMethod(constants?.PAYMENT_TYPE?.CHELLAN);
            get_Type_payment_method(constants?.PAYMENT_TYPE?.CHELLAN);
          }}
        >
          {/* <i className="bi bi-paperclip d-block"></i> */}
          <img className="icon" src="/images/sketching.png" alt="icon" />
          <small>Demand Draft</small>
          {/* <small>{VendorRegLang?.demand_draft[language]}</small> */}
        </div>

        {/* ========== */}
        <div
          className={`choose-type-inner ${
            selectedMethod == constants?.PAYMENT_TYPE?.RTGS ? "active" : ""
          }`}
          onClick={() => {
            setSelectedMethod(constants?.PAYMENT_TYPE?.RTGS);
            get_Type_payment_method(constants?.PAYMENT_TYPE?.RTGS);
          }}
        >
          {/* <i className="bi bi-cash d-block"></i> */}
          <img className="icon" src="/images/cheque(1).png" alt="icon" />
          <small>RTGS</small>
          {/* <small>{VendorRegLang?.rtgs[language]}</small> */}
        </div>
        <div
          className={`choose-type-inner ${
            selectedMethod == constants?.PAYMENT_TYPE?.NEFT ? "active" : ""
          }`}
          onClick={() => {
            setSelectedMethod(constants?.PAYMENT_TYPE?.NEFT);
            get_Type_payment_method(constants?.PAYMENT_TYPE?.NEFT);
          }}
        >
          {/* <i className="bi bi-cash d-block"></i> */}
          <img className="icon" src="/images/cheque(1).png" alt="icon" />
          <small>NEFT</small>
          {/* <small>{VendorRegLang?.neft[language]}</small> */}
        </div>
      </div>
    </>
  );
};

export default PaymentItems;