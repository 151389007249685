import React, { useEffect, useContext  } from "react";
import { useState } from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import constants from "../../../Library/Constants/Constants";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

export default function VendorSchedulePending() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [openTicket, setOpenTickets] = useState([]);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const { language, setLanguage } = useContext(Context);
  const [search, setSearch] = useState("")

  useEffect(() => {
    getOpenTickets(currentPage)
  }, [search, currentPage]);

  const getOpenTickets = (page: any) => {
    setIsLoading(true);
    api
      .get(`/service-maintenance?status=2&page=${page}&search_keyword=${search}`, true)
      .then(async function ([success, response]) {
        setOpenTickets(response?.data || []);
        setIsLoading(false);
        setPageData(response?.meta);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onChange = (e: any) => {
    setSearch(e.target.value)
  }

  function stateType(val: any) {
    var stval = Object.entries(constants.TICKET_TYPE)
    var filterval = stval.filter(([key, value]) => value == val)
    var finalval = filterval[0][0].toLowerCase();
    var capitilice = finalval.charAt(0).toUpperCase();
    var splitremainletters = finalval.slice(1);
    finalval = capitilice + splitremainletters;

    return finalval
  }

  function statePriority(val: any) {
    var stval = Object.entries(constants.PRIORITY)
    var filterval = stval.filter(([key, value]) => value == val)
    var finalval = filterval[0][0].toLowerCase();
    var capitilice = finalval.charAt(0).toUpperCase();
    var splitremainletters = finalval.slice(1);
    finalval = capitilice + splitremainletters;

    return finalval
  }

  return (
    <React.Fragment>
      <div className="section">
        <div className="search-widget-outer">
          <Form role="search">
            <InputGroup className="search-group">
              <InputGroupText title="Search here" className="search-icon" id="search">
                <i title="Search here" className="fa-sharp fa-solid fa-magnifying-glass" />
              </InputGroupText>
              <Input
                type="text"
                className="form-control"
                placeholder="Search here"
                aria-label="Username"
                aria-describedby="search"
                onChange={onChange}
              />
            </InputGroup>
          </Form>
        </div>
        <div className="table-wrap responsive-table">
          <Table className="w-100">
            <thead>
              <tr>
                <th>{VendorRegLang?.sl_no[language]}</th>
                <th>{VendorRegLang?.products[language]}</th>
                <th>{VendorRegLang?.priority[language]}</th>
                <th>{VendorRegLang?.issue_type[language]}</th>
                <th>{VendorRegLang?.resolved_date[language]}</th>
                <th>{VendorRegLang?.time[language]}</th>
                <th>{VendorRegLang?.status[language]}</th>
              </tr>
            </thead>
            <tbody>
              {openTicket?.length !== 0 ? (
                <>
                  {
                    openTicket?.map((item: any, index: any) => {
                      return (
                        <>
                          <tr>
                            <td><span className="mobile-heading">{VendorRegLang?.sl_no[language]}:</span><span className="table-data">{index + 1}</span></td>
                            <td><span className="mobile-heading">{VendorRegLang?.products[language]}:</span>
                            <span className="table-data">
                              <div className="product-flex">
                                <div className="prod-icon">
                                  <img src="images/thumb.svg" />
                                </div>
                                <div className="prod-details">
                                  <small className="main"><span className="green-text">{item?.serviceproduct?.name}</span><br />
                                   <span className="new">
                                      <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item?.serviceproduct?.features,
                                      }}>
                                        </p>
                                    </span>
                                  </small>
                                </div>
                              </div>
                              </span>
                            </td>
                            <td><span className="mobile-heading">{VendorRegLang?.priority[language]}:</span><span className="table-data">{stateType(item?.priority)}</span></td>
                            <td><span className="mobile-heading">{VendorRegLang?.issue_type[language]}:</span><span className="table-data">{statePriority(item?.issue_type)}</span></td>
                            <td><span className="mobile-heading">{VendorRegLang?.resolved_date[language]}:</span><span className="table-data">{item?.resolve_date}</span></td>
                            <td><span className="mobile-heading">{VendorRegLang?.time[language]}:</span><span className="table-data">{item?.time_taken ? item?.time_taken : 0}  Hours</span></td>
                            <td className="txt"><span className="mobile-heading">{VendorRegLang?.status[language]}:</span><span className="table-data approved-status">Resolved</span></td>
                          </tr>
                        </>
                      )
                    })
                  }
                </>) :
                (<tr>
                  <td
                    className="text-center px-2 py-5 empty-text"
                    colSpan={7}
                  >
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    ) : (
                      `${VendorRegLang?.no_data_found[language]}`
                    )}
                  </td>
                </tr>)}

            </tbody>
          </Table>
        </div>
        {pageData && (
          <div className="table-footer-wrap">
            <small>
              {VendorRegLang?.showing[language]} {pageData?.from ? pageData?.from : 0} {VendorRegLang?.to[language]} {pageData?.to != null ? pageData?.to : 0} {VendorRegLang?.of[language]} {pageData?.total != null ? pageData?.total : 0}{" "}
              {VendorRegLang?.entries[language]}
            </small>
            <div className="pagination">
              <CustomPagination
                totalItems={pageData?.total}
                itemsPerPage={pageData?.per_page}
                activePage={pageData.current_page}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}