export const VendorSignup:any ={
    //vendor--vendorSignup--advancedRegSelf--

    PAN_Number:{
        en:"PAN Number",
        ka:"ಪಿ ಏ ಎನ್ ಸಂಖ್ಯೆ"
    },

    GST_Number:{
        en:"GST Number",
        ka:"ಜಿ ಎಸ್ ಟಿ ಸಂಖ್ಯೆ"
    },
    Select:{
        en:"Select",
        ka:"ಆಯ್ಕೆ ಮಾಡಿ"
    },

    Vendor_Type:{
        en:"Vendor type",
        ka:"ವೆಂಡರ್ ಪ್ರಕಾರ"
    },

    GST_class:{
        en:"GST class",
        ka:"ಜಿ ಎಸ್ ಟಿ  ವರ್ಗ"
    },
  
    Is_your_company_MSME_yes_no:{
        en:"Is your company MSME (yes/no)",
        ka:"ನಿಮ್ಮ ಕಂಪನಿ ಎಂ ಎಸ್ ಎಂ ಇ ಆಗಿದೆಯೇ (ಹೌದು/ಇಲ್ಲ)"
    },
    MSME_valid_upto:{
        en:"MSME Valid upto",
        ka:"ಎಂ ಎಸ್ ಎಂ ಇ ಮಾನ್ಯತಾ ಮುಗಿಯುವ ವರೆಗೆ"
    },
    UDYAM_Yes_No:{
        en:"UDYAM (Yes/No)",
        ka:"ಉದ್ಯಮ (ಹೌದು/ಇಲ್ಲ)"
    },
   
    Are_you_SC_ST_entrepreneur_Yes_No:{
        en:"Are you SC/ST entrepreneur (Yes/No)",
        ka:"ನೀವುಡಿ ಐತಿಹಾಸಿಕ ಜಾತಿ/ಕ್ರಮಿತ ಜನಜಾತಿ ಉದ್ಯಮಿಯೇ (ಹೌದು/ಇಲ್ಲ)"
    },
    Are_you_women_entrepreneur_Yes_No:{
        en:"Are you women entrepreneur (Yes/No)",
        ka:"ನೀವು ಮಹಿಳಾ ಉದ್ಯಮಿಯೇ (ಹೌದು/ಇಲ್ಲ)"
    },
    Company_information:{
        en:"Company information",
        ka:"ಕಂಪನಿ ಮಾಹಿತಿ"
    },
    Company_name:{
        en:"Company name",
        ka:"ಕಂಪನಿ ಹೆಸರು"
    },
    Company_Email:{
        en:"Company Email",
        ka:"ಕಂಪನಿ ಇಮೇಲ್"
    },
    Contact_Number:{
        en:"Contact Number",
        ka:"ಸಂಪರ್ಕ ಸಂಖ್ಯೆ"
    },
    Company_incorporation_date:{
        en:"Company incorporation date",
        ka:"ಕಂಪನಿ ಸೇರುವ ದಿನಾಂಕ"
    },
    Company_Registered_Address:{
        en:"Company Registered Address",
        ka:"ಕಂಪನಿ ನೋಂದಣಿಗೆ ಹೆಸರಾಂತ ವಿಳಾಸ"
    },
    Company_Communication_Address:{
        en:"Company Communication Address",
        ka:"ಕಂಪನಿ ಸಂವಾದ ವಿಳಾಸ"
    },
    Point_of_Contact_Information:{
        en:"Point of Contact Information",
        ka:"ಸಂಪರ್ಕ ಮೂಲಕ ಮಾಹಿತಿ"
    },
    contact_email_id:{
        en:"contact email id",
        ka:"ಸಂಪರ್ಕ ಇಮೇಲ್ ಐಡಿ"
    },
    designation:{
        en:"designation",
        ka:"ಹುದ್ದೆ"
    },
    Make_sure_entered_details_are_correct:{
        en:"Make sure entered details are correct",
        ka:"ನಮೂದಿಸಿದ ವಿವರಗಳು ಸರಿಯಾಗಿವೆಯೆಂದು ಖಚಿತಪಡಿಸಿ"
    },
    submit:{
        en:"submit",
        ka:"ಸಲ್ಲಿಸಿ"
    },
    Add:{
        en:"Add",
        ka:"ಸೇರಿಸಿ"
    },
    Contact_Name:{
        en:"Contact Name",
        ka:"ಸಂಪರ್ಕಿಸುವ ಹೆಸರು"
    },
   
    Not_Mandatory:{
        en:"Not Mandatory",
        ka:" ಕಡ್ಡಾಯವಲ್ಲ"
        },
        ISO_Certificate:{
        en:"ISO Certificate",
        ka:" ISO ಪ್ರಮಾಣಪತ್ರ"
        },

//vendor--vendorSignup--basicdetailsSelgreg--

full_name:{
    en:"full name",
    ka:"ಪೂರ್ಣ ಹೆಸರು "
},
phone_number:{
    en:"phone number",
    ka:" ಫೋನ್ ಸಂಖ್ಯೆ "
},
email:{
    en:"email",
    ka:"ಇಮೇಲ್ "
},
Send_notification_on_email:{
    en:"Send notification on email:",
    ka:"ಇಮೇಲ್ ಮೂಲಕ ಅಧಿಸೂಚನೆ ಕಳುಹಿಸಿ "
},
Resend_OTP:{
    en:"Resend OTP",
    ka:" ಒಂದು ಸಲ ಪಾಸ್ವರ್ಡ್ ಮರುಕಳಿಸಿ "
},
enter_OTP:{
    en:"enter OTP",
    ka:" ಒಂದು ಸಲ ಪಾಸ್ವರ್ಡ್ ನಮೂದಿಸಿ "
},
confirm_password:{
    en:"confirm password",
    ka:"ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ "
},
Already_have_an_account:{
    en:"confirm password",
    ka:"ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರ "
},
login:{
    en:"login",
    ka:"ಲಾಗಿನ್ "
},

register:{
    en:"register",
    ka:"ನೋಂದಣಿ  "
},

//vendor--vendorSignup--documentuploadselfregister

pancard:{
    en:"pancard",
    ka:" ಪಾನ್ ಕಾರ್ಡ್"
},
MSME_Certificate:{
    en:"MSME Certificate",
    ka:" ಎಂ ಎಸ್ ಎಂ ಇ ಪ್ರಮಾಣಪತ್ರ"
},
Choose_Documents:{
    en:"Choose Documents",
    ka:" ಡಾಕ್ಯುಮೆಂಟ್ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ"
},
GST_Registration:{
    en:"GST Registration",
    ka:" ಜಿಎಸ್‌ಟಿ ನೋಂದಣಿ"
},
address_proof:{
    en:"Address proof",
    ka:" ವಿಳಾಸ ಪುರಾವೆ"
},
company_incorporation_certificate:{
    en:"Company incorporation certificate",
    ka:" ಕಂಪನಿಯ ಸಂಯೋಜನೆಯ ಪ್ರಮಾಣಪತ್ರ"
},
Empanel_Certificate :{
    en:"Empanel Certificate ",
    ka:" ಇಂಪ್ಯಾನಲ್ ಪ್ರಮಾಣಪತ್ರ"
},
Udyam_Certificate:{
    en:"Udyam Certificate",
    ka:" ಉದ್ಯಮ ಪ್ರಮಾಣಪತ್ರ"
},
Company_Turnover :{
    en:"Company Turnover ",
    ka:" ಕಂಪನಿಯ ಹಣಕಾಸು"
},
First_Financial_year:{
    en:"First Financial year",
    ka:" ಮೊದಲ ಆರ್ಥಿಕ ವರ್ಷ"
},
First_Financial_year_amount:{
    en:"First Financial year_amount",
    ka:" ಮೊದಲ ಹಣಕಾಸು ವರ್ಷದ ಮೊತ್ತ"
},
First_Financial_year_document:{
    en:"First Financial year document",
    ka:" ಮೊದಲ ಹಣಕಾಸು ವರ್ಷದ ದಾಖಲೆಗಳು"
},
Second_Financial_year:{
    en:"Second Financial year",
    ka:"ಎರಡನೇ ಆರ್ಥಿಕ ವರ್ಷ"
},
Second_Financial_year_Documents:{
    en:"Second Financial year Documents",
    ka:" ಎರಡನೆಯ ಆರ್ಥಿಕ ವರ್ಷ ಡಾಕ್ಯುಮೆಂಟ್ಗಳು"
},
Third_Financial_year:{
    en:"Third Financial year",
    ka:" ಮೂರನೆಯ ಆರ್ಥಿಕ ವರ್ಷ"
},
Third_Financial_year_amount:{
    en:"Third Financial year Amount",
    ka:"ಮೂರನೇ ಹಣಕಾಸು ವರ್ಷದ ಮೊತ್ತ"
},
Third_Financial_year_document:{
    en:"Third Financial year Document",
    ka:"ಮೂರನೇ ಹಣಕಾಸು ವರ್ಷದ ದಾಖಲೆ"
},
OEM_Certificate:{
    en:"OEM Certificate",
    ka:" ಓಈಎಂ ಪ್ರಮಾಣಪತ್ರ"
},
MAF_Certificate:{
    en:"MAF Certificate",
    ka:" ಎಂಏಫ್ ಪ್ರಮಾಣಪತ್ರ"
},


//vendor--vendorSignup--emdpayments

for_A_Category:{
    en:" for A Category",
    ka:" ಎ ವರ್ಗಕ್ಕೆ"
},

Above_20_Crores_NRLA:{
    en:"Above 20 Crores — NRLA",
    ka:" 20 ಕೋಟಿಯಷ್ಟು ಮೇಲೆ - ಎನ್‌ಆರ್‌ಎಲ್‌ಏ "
},
Above_20_Crores:{
    en:"Above 20 Crores",
    ka:"20 ಕೋಟಿಗೂ ಹೆಚ್ಚು"
},
For_B_Category:{
    en:" For B Category",
    ka:" ಬಿ ವರ್ಗಕ್ಕೆ"
},
ten_20Crores_NRLA:{
    en:"10-20 Crores — NRLA",
    ka:"10 ಕೋಟಿಯಿಂದ 20 ಕೋಟಿಗೆ - ಎನ್‌ಆರ್‌ಎಲ್‌ಏ"
},
For_C_Category:{
    en:" For C Category",
    ka:"C ವರ್ಗಕ್ಕೆ"
},
Upto_10_Crores_NRLA:{
    en:"Upto 10 Crores — NRLA",
    ka:"10 ಕೋಟಿಗಳವರೆಗೆ - NRLA"
},
Upto_10_Crores:{
    en:"Upto 10 Crores",
    ka:"10 ಕೋಟಿ ವರೆಗೆ"
},
History_details:{
    en:"history details",
    ka:" ಇತಿಹಾಸ ವಿವರಗಳು"
},
Sl_No:{
    en:"Sl No",
    ka:" ಸಂಖ್ಯೆ ಲೆಕ್ಕಾಚಾರ"
},

Transaction_ID:{
    en:"Transaction ID",
    ka:" ಲಂಚನ ಐಡಿ"
},

Start_date:{
    en:"start date",
    ka:" ಪ್ರಾರಂಭ ದಿನಾಂಕ"
},
Category:{
    en:"category",
    ka:" ವರ್ಗ"
},
Amount:{
    en:"Amount",
    ka:" ಮೊತ್ತ"
},
Pay:{
    en:"Pay",
    ka:"ಪಾವತಿ ಮಾಡಿ"
},

//vendor--vendorSignup--Tabsection

basic_details:{
    en:"basic details",
    ka:" ಮೌಲ್ಯಮಾಪನ ವಿವರಗಳು"
},

Advanced_registration:{
    en:"advanced registration",
    ka:" ಆಧುನಿಕ ನೋಂದಣಿ"
},
Document_upload:{
    en:"document upload",
    ka:"   ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್‌ಲೋಡ್"
},

Non_Refundable_Loyalty_Amount:{
    en:"Non-Refundable Loyalty Amount",
    ka:" ಮರಳಿ ಪಡೆಯಲಾಗದ ನಿಷ್ಪ್ರತಿಷ್ಠಾತ್ಮಕ ಮೊತ್ತ "
},


//vendor--vendorSignup--validationschema

Vendor_type_is_required :{
    en:"Vendor type is required ",
    ka:" ವೆಂಡರ್ ಪ್ರಕಾರ ಅಗತ್ಯವಿದೆ"
},

GST_class_is_required :{
    en:"GST class is required ",
    ka:" ಜಿಎಸ್‌ಟಿ ವರ್ಗ ಅಗತ್ಯವಿದೆ"
},
GST_number_is_required :{
    en:"GST number is required ",
    ka:" ಜಿಎಸ್‌ಟಿ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ"
},
GST_Number_should_be_at_least_15_characters :{
    en:"GST Number should be at least 15 characters ",
    ka:" ಜಿಎಸ್‌ಟಿ ಸಂಖ್ಯೆ ಕಡಿಮೆಯಾದರೂ 15 ಅಕ್ಷರಗಳಿರಬೇಕು"
},

GST_Number_should_not_be_more_than_15_characters:{
    en:"GST Number should not be more than 15 characters ",
    ka:" ಜಿಎಸ್‌ಟಿ ಸಂಖ್ಯೆ 15 ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚಾಗಕೂಡದು"
},
PAN_number_is_required :{
    en:"PAN number is required ",
    ka:" ಪ್ಯಾನ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ"
},
PAN_Number_should_be_at_least_10_characters :{
    en:"PAN Number should be at least 10 characters  ",
    ka:" ಪ್ಯಾನ್ ಸಂಖ್ಯೆ ಕಡಿಮೆಯಾದರೂ 10 ಅಕ್ಷರಗಳಿರಬೇಕು"
},
PAN_Number_should_not_be_more_than_10_characters :{
    en:"PAN Number should not be more than 10 characters ",
    ka:" ಪ್ಯಾನ್ ಸಂಖ್ಯೆ 10 ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚಾಗಕೂಡದು"
},
Invalid_email:{
    en:"Invalid email ",
    ka:" ಅಮಾನ್ಯ ಇಮೇಲ್"
},
Company_email_is_required:{
    en:"Company e-mail is required ",
    ka:" ಕಂಪನಿ ಇಮೇಲ್ ಅಗತ್ಯವಿದೆ"
},
Phone_number_is_required:{
    en:"Phone number is required ",
    ka:" ಫೋನ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ"
},
Phone_number_must_be_10_or_11_digits:{
    en:"Phone number must be 10 or 11 digits ",
    ka:" ಫೋನ್ ಸಂಖ್ಯೆ 10 ಅಥವಾ 11 ಅಂಕಗಳಾಗಿರಬೇಕು"
},
Phone_number_must_be_at_least_10_digits:{
    en:"Phone number must be at least 10 digits ",
    ka:" ಅಮಾನ್ಯ ಇಮೇಲ್ಫೋನ್ ಸಂಖ್ಯೆ ಕಡಿಮೆಯಾದರೂ 10 ಅಂಕಗಳಿರಬೇಕು"
},
Registration_number_is_required:{
    en:" Registration number is required ",
    ka:" ನೋಂದಣಿ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ"
},
Company_Type_is_required:{
    en:"Company Type is required ",
    ka:" ಕಂಪನಿ ಪ್ರಕಾರ ಅಗತ್ಯವಿದೆ"
},
Contact_name_is_required:{
    en:"Contact name is required ",
    ka:" ಸಂಪರ್ಕ ಹೆಸರು ಅಗತ್ಯವಿದೆ"
},
Company_name_is_required:{
    en:"Company name is required ",
    ka:" ಕಂಪನಿ ಹೆಸರು ಅಗತ್ಯವಿದೆ"
},
Please_enter_a_valid_date:{
    en:"Please enter a valid date ",
    ka:" ಅಮಾನ್ಯ ಇಮೇಲ್"
},
Date_required:{
    en:"Date required ",
    ka:" ದಿನಾಂಕ ಅಗತ್ಯವಿದೆ"
},

//vendor--vendorSignup--vendorbasicdetails
Create_Account:{
    en:"Create Account",
    ka:"ಖಾತೆ ರಚಿಸಿ"
},
Select_Vendor_Types:{
    en:"Select Vendor Types",
    ka:"ಮಾರಾಟಗಾರರ ಪ್ರಕಾರಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ"
},
Select_GST_Class:{
    en:"Select GST Class",
    ka:" ಜಿಎಸ್‌ಟಿ ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
},
Company_Registration_Number:{
    en:"Company Registration Number",
    ka:"ಕಂಪನಿ ನೋಂದಣಿ ಸಂಖ್ಯೆ"
},
Company_Type:{
    en:"Company Type",
    ka:"ನನಗೆ ಈಗಾಗಲೇ ಖಾತೆ ಇದೆ "
},
}