import React, { useState, useContext } from 'react'

import { Link } from 'react-router-dom';
import { LandingPageContext } from '../../Components/Contexts';
import { Context } from '../../../Components/Context/Context';

const IndexEng = () => {
  // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
  const { language, setLanguage } = useContext(Context);

  return (
    <>
      <div>
        <div className="bg" />
        <div className="drag">
          <div className="w-100">
            <div className="logo-launch-outer-wrap">
              <img className="logolaunch logolaunch-ani" src={require("../../images/logo-white1.png")} width={500} />
            </div>
            <div className="text-launch-light fw-bold text-center fs-2 mt-4">
              We are launching <span>KTIPP</span> (KEONICS Transparent IT Procurement Portal) Platform ! </div>
            <div className="text-white text-center mt-3 pt-2">
              <Link to={'/home'} id="launch" className="d-inline-block fs-4 rounded px-5 py-3 mt-1 launch-btn" style={{ border: '2px solid white', backgroundColor: '#FF6517', color: 'white' }} > Click to Launch! </Link>
              {/* <a id="launch" className="d-inline-block fs-4 rounded px-5 py-3 mt-1 launch-btn" href="welcome.html" style={{ border: '2px solid white', backgroundColor: '#FF6517', color: 'white' }}>
                Click to Launch!</a> */}
            </div>
            <div className="company-details-wrap">
              <h6>An initiative by <img src={require("../../images/keonics-logo-white2.png")} alt="logo" /></h6>
            </div>
          </div>
          <div className="language-btn-wrap">
             <Link to='#' onClick={() => setLanguage('en')} className="" > English </Link> 
             <Link to='#' onClick={() => setLanguage('ka')} className="" > ಕನ್ನಡ </Link> 
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexEng;