import React from 'react';
import Slider from 'react-slick'
import { Slide } from 'react-toastify';


const HomeEng = () => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    arrows: true
  };
  return (
    <><div>
    <main>
      {/* hero-banner */}
      <section className="banner-home">
        <div className="container">
          <img src={require("../../images/logo1.png")} className="img1" />
          {/* <img src="../../images/logo2.png" class="img2"> */}
          <div className="marquee-list">
            <ul>
              <li>KEONICS, invites applications from eligible candidates for an “Education Wing” at the office of Directorate of Minorities, Bangalore for the Consultant positions on contract basis.</li>
              <li>Keonics will setup IT Park in Mangalore</li>
              <li>Cancellation of Backlog Appointment</li>
              <li>The India Skills Karnataka 2020</li>
            </ul>
          </div> 
          <h1 className="typewriter1">Efficient, transparent &amp; </h1>
          <h1 className="typewriter"> socially responsible procurement.</h1>
          <h1 className="mobile-heading-home">Efficient, transparent &amp; socially responsible procurement.</h1>
          <div className="home-para">
            <p className="para animate__animated animate__slideInUp">Karnataka State Electronics Development Corporation Limited (KEONICS), an ISO 9001:2015 certified organization, is in existence since 1976 with the sole objective of promoting electronics industries in Karnataka. To ensure that Karnataka is always at the forefront of electronics and IT,  we have been providing high-quality infrastructure for IT industries, spreading IT education to the masses and providing state-of-the products and services for e-Governance.</p>
          </div>
          <div className="pictures">
            <div className="pictures-inner animate__animated animate__slideInUp animate__delay-2s">
              <div className="picture-wrap">
                <img src={require("../../images/product-icon.png")} className="pic1  px-5" />
              </div>
              <p className="count">1000</p>
              <p className="user">Products</p>
            </div>
            <div className="pictures-inner animate__animated animate__slideInUp animate__delay-3s">
              <div className="picture-wrap">
                <img src={require("../../images/vendor-icon.png")} className="pic2 px-5 " />
              </div>
              <p className="count">1000</p>
              <p className="user">Vendors</p>
            </div>
            <div className="pictures-inner animate__animated animate__slideInUp animate__delay-4s">
              <div className="picture-wrap">
                <img src={require("../../images/Dept-icon.png")} className="pic3 px-5 " />
              </div>
              <p className="count">1000</p>
              <p className="user">Departments</p>
            </div>
          </div>
        </div>
      </section>
      {/* why-keonics */}
      <section className="team-section">
        <div className="container">
          <div className="row justify-content-center banner-image-team">
            <div className="col-sm-6 col-md-6 col-xl-4 mb-3">
              <div className="management-team-outer">
                <div className="team-img-wrap"><img src={require("../../images/Siddaramaiah.png")} alt="name" /></div>
                <div className="team-details-wrap">
                  <h6>Sri. Siddaramaiah</h6>
                  <p>Hon'ble Chief Minister, Government of Karnataka</p>
                </div>
              </div>
            </div> 
            <div className="col-sm-6 col-md-6 col-xl-4 mb-3">
              <div className="management-team-outer">
                <div className="team-img-wrap"><img src={require("../../images/shivakumar.png")} alt="name" /></div>
                <div className="team-details-wrap">
                  <h6>Sri. D. K. Shivakumar</h6>
                  <p>Deputy Chief Minister of Karnataka</p>
                </div>
              </div>
            </div> 
            {/* <div className="col-sm-6 col-md-6 col-xl-4 mb-3">
              <div className="management-team-outer">
                <div className="team-img-wrap"><img src={require("../../images/priyank.png")} alt="name" /></div>
                <div className="team-details-wrap">
                  <h6>Sri. Priyank Kharge</h6>
                  <p>Minister for Rural Development and Panchayat Raj, IT &amp; BT, Government of Karnataka </p>
                </div>
              </div>
            </div>  */}
            {/* <div className="col-sm-6 col-md-6 col-xl-4 mb-3">
              <div className="management-team-outer">
                <div className="team-img-wrap"><img src={require("../../images/ev.png")} alt="name" /></div>
                <div className="team-details-wrap">
                  <h6>Dr. E.V. Ramana Reddy, IAS </h6>
                  <p>Additional Chief Secretary to Government - Department of Electronics, Information Technology, Biotechnology and Science &amp; Technology.</p>
                </div>
              </div>
            </div> 
            <div className="col-sm-6 col-md-6 col-xl-4 mb-3">
              <div className="management-team-outer">
                <div className="team-img-wrap"><img src={require("../../images/ravi.png")} alt="name" /></div>
                <div className="team-details-wrap">
                  <h6>Sri. Ravi D Channannavar, IPS</h6>
                  <p>Managing Director, Keonics</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="video reveal">
        <h2>Discover Our Work Flow</h2>
        <div className="container">
          <div className="work-process-inner">
            <div className="work-process-item  animate__animated animate__bounceInLeft animate__delay-3s">
              <div className="icon">
                {/* <img src={require("../../images/w1.png")} alt="" /> */}
              </div>
              <p className="text-center">On Boarding  To The <br /> Platform</p>
            </div>
            <div className="work-process-item  animate__animated animate__bounceInLeft animate__delay-5s">
              <div className="icon">
                {/* <img src={require("../../images/w2.png")} alt="" /> */}
              </div>
              <p className="text-center">Login To Select <br /> Product</p>
            </div>
            <div className="work-process-item  animate__animated animate__bounceInUp">
              <div className="icon">
                {/* <img src={require("../../images/w3.png")} alt="" /> */}
              </div>
              <p className="text-center">Payment To <br /> Keonics</p>
            </div>
            <div className="work-process-item  animate__animated animate__bounceInUp">
              <div className="icon">
                {/* <img src={require("../../images/w4.png")} alt="" /> */}
              </div>
              <p className="text-center">Issuing Purchase <br /> Order</p>
            </div>
            <div className="work-process-item  animate__animated animate__bounceInRight animate__delay-3s">
              <div className="icon">
                {/* <img src={require("../../images/w5.png")} alt="" /> */}
              </div>
              <p className="text-center">Deliveries From <br /> Reputable Vendors</p>
            </div>
            <div className="work-process-item  animate__animated animate__bounceInRight animate__delay-5s">
              <div className="icon">
                {/* <img src={require("../../images/w6.png")} alt="" /> */}
              </div>
              <p className="text-center">Vendor Guaranteed <br />Services </p>
            </div>
          </div>
        </div>
      </section>
      {/* sec1 */}
      <section style={{display: 'none'}}>
        <div className="services-outer-wrap">
          <div className="container">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-services-tab" data-bs-toggle="pill" data-bs-target="#pills-services" type="button" role="tab" aria-controls="pills-services" aria-selected="true">Services</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link " id="pills-products-tab" data-bs-toggle="pill" data-bs-target="#pills-products" type="button" role="tab" aria-controls="pills-products" aria-selected="false" tabIndex={-1}>Products</button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-services" role="tabpanel" aria-labelledby="pills-services-tab">
                <div className="row gy-4">
                  <div className="col-md-6 col-xl-4">
                    <div className="services-item">
                      <div className=" services-item-content">
                        <div className="icon">
                          {/* <img src={require("../../images/icon4.svg")} alt="" /> */}
                        </div>
                        <h5>Software Development</h5>
                        <p>Keonics IT Education &amp; Services (ITES) Division is having a network of 289 centers located in Hubli, Taluka and District Head Quarters across the Karnataka State.</p>
                      </div>              
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="services-item">
                      <div className=" services-item-content">
                        <div className="icon">
                          {/* <img src={require("../../images/icon5.svg")} alt="" /> */}
                        </div>
                        <h5>HR Services</h5>
                        <p>Supply and management of human resource is one of the important activities Keonics has undertaken.</p>
                      </div>              
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="services-item">
                      <div className=" services-item-content">
                        <div className="icon">
                          {/* <img src={require("../../images/icon6.svg")} alt="" /> */}
                        </div>
                        <h5>IT Services</h5>
                        <p>We provide a comprehensive range of IT and automation consultancy services. Our expertise has been built over 25 years where in.</p>
                      </div>              
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade show" id="pills-products" role="tabpanel" aria-labelledby="pills-products-tab">
                <div className="row gy-4">
                  <div className="col-md-6 col-xl-4">
                    <div className="services-item">
                      <div className=" services-item-content">
                        <div className="icon">
                          {/* <img src={require("../../images/icon7.png" )} alt="" /> */}
                        </div>
                        <h5>Computers &amp; Laptops</h5>
                        <p>Keonics IT Education &amp; Services (ITES) Division is having a network of 289 centers located in Hubli, Taluka and District Head Quarters across the Karnataka State.</p>
                      </div>              
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="services-item">
                      <div className=" services-item-content">
                        <div className="icon">
                          {/* <img src={require("../../images/icon8.png")} alt="" /> */}
                        </div>
                        <h5>Softwares</h5>
                        <p>Supply and management of human resource is one of the important activities Keonics has undertaken.</p>
                      </div>              
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="services-item">
                      <div className=" services-item-content">
                        <div className="icon">
                          {/* <img src={require("../../images/icon9.png")} alt="" /> */}
                        </div>
                        <h5>Accessories</h5>
                        <p>We provide a comprehensive range of IT and automation consultancy services. Our expertise has been built over 25 years where in.</p>
                      </div>              
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section> 
        <div className="why-us-outer">
          <div className="container">
            <div className="row align-items-center gy-4">
              <div className="col-lg-5">
                <h4 className="heading vision-upper">Keonics leads the <br /><span>Digital Transformation</span><br /> in the state.</h4>
                <h5 className="sub-heading">Vision</h5>
                <p className="sentence">The vision of KEONICS is to be a leading organization in the field of electronics and IT in India, contributing to the development of a knowledge-based society. KEONICS aims to provide world-class infrastructure and services to enable the growth and success of the electronics and IT industries in Karnataka and beyond. </p>
                <h5 className="sub-heading">Mission</h5>
                <p className="sentence">The mission of KEONICS is to promote the growth and development of the electronics and IT industries in Karnataka and India as a whole. KEONICS aims to achieve this mission through various activities, including the development of world-class infrastructure, providing high-quality education and training, promoting research and innovation, and fostering entrepreneurship in the electronics and IT sectors.</p>    
              </div>
              <div className="col-lg-7">
                <div className="sub-content-wrap">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="sub-content-inner">
                        <div className="icon animate__animated animate__backInUp animate__delay-2s"><img src={require("../../images/ico11.png")} alt="" /></div>
                        <h6 className="">Purchase order management</h6>
                        <p className="text-black">Automated purchase order management, allowing Keonics to efficiently manage procurement processes and reduce manual work.</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="sub-content-inner">
                        <div className="icon animate__animated animate__backInUp animate__delay-3s"><img src={require("../../images/ico22.png")} alt="" /></div>
                        <h6 className="">Reporting and analytics</h6>
                        <p className="text-black">Real-time reporting and analytics capabilities to provide insights into procurement activities and improve decision making.</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="sub-content-inner">
                        <div className="icon animate__animated animate__backInUp animate__delay-4s"><img src={require("../../images/ico33.png")} alt="" /></div>
                        <h6 className="">Delivery management</h6>
                        <p className="text-black">Real-time tracking and reporting of product deliveries, ensuring departments receive their products in a timely and efficient manner.</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="sub-content-inner">
                        <div className="icon animate__animated animate__backInUp animate__delay-5s"><img src={require("../../images/ico44.png")} alt="" /></div>
                        <h6 className="">Compliance with regulations</h6>
                        <p className="text-black">Robust security features to ensure compliance with government regulations and protect sensitive information.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* service */}
      <section className="service">
        <div className="container">
          <div className="one clearfix">
            <div className="col-sm-12 col-md-12 col-lg-6 mt-5 pe-lg-5">
              <p className="para1" style={{borderBottom: '1px solid #1a1167', display: 'inline-block'}}>Services</p>
              <h2>What We Do</h2>
              <p className="sentence">KEONICS focuses on setting up world-class infrastructure for the electronics and IT-related industries in the state. Aimed at providing a platform for these industries to operate from, we work with Software Technology Parks of India to develop the estates, provide connectivity and administer export-oriented schemes. We diversified into IT-related activities since 1990.<br /> <br /> KEONICS IT solutions span the areas of ERP solutions, web portals, software development, IT consultancy, IT training and networking. Though our major customer base is in Karnataka, we have been awarded prestigious e-Governance projects including the State Wide Area Networking (SWAN) project from the Government of Arunachal Pradesh. And so also, our popular web-based e-Procurement solution is being used by many departments of Kerala, Maharashtra etc. and Central Public Sectors like HLL, HSCC Ltd, Hindustan Shipyard etc.</p>
            </div>
            <div className="col-sm-12 col-md-12  col-lg-6 my-sm-5">
              <div className="right-child">
                <div className=" mb-5">
                  <img src={require("../../images/Group (4).png")} className="fix-height-icon" />
                  <p className="heading"><strong>IT Education</strong></p>
                  <p className="sentence">Keonics provides computer training as per the requirements of the market, business establishment, educational field etc. It also provides training to the employees of the Government Departments / Boards / Corporations as per their requirement, Keonics Training Centers are fully equipped with the latest infrastructure along with internet facilities.</p>
                </div>
                <div className=" mb-5">
                  <img src={require("../../images/Group (5).png")} className="fix-height-icon" />
                  <p className="heading"><strong>HR Services</strong></p>
                  <p className="sentence">Supply and management of human resources is one of the important activities Keonics has undertaken. Manpower services in IT can include resources in the areas of data entry operations, programming, web development, database administration, network management, technical support and a host of related services.</p>
                </div>
              </div>   
            </div> 
          </div>
        </div>
      </section>
      {/* products */}
      <section className="products">
        <div className="container">
          <section className="one clearfix">
            <div className="col-sm-12 col-md-12 col-lg-6 mt-5 pe-lg-5">
              <p className="para1" style={{borderBottom: '1px solid #1a1167', display: 'inline-block'}}>Products</p>
              <h2>What we Offer</h2>
              <p className="sentence">We provide a comprehensive range of IT and automation consultancy services. Our expertise has been built over 25 years wherein we have executed landmark projects in the history of the state of Karnataka. Well-trained and experienced manpower to provide computer networking and network maintenance solutions. These services are provided all across Karnataka either directly by KEONICS or through business associates in each location. Currently, we handle the bulk of the Annual Maintenance Contracts (AMC) of computers and computer peripherals for State Government and public sector organizations. KEONICS has also developed the capability to handle large-scale data entry and data warehousing projects. Projects have been executed for customers like Census Department, Small Savings &amp; State Lottery, Bangalore Metropolitan Transport Corporation, Central Jail, Bangalore Water Supply  Karnataka Power Transmission Corporation Ltd. &amp; Sewage Board and the Education Department.</p>
            </div>
            <div className="col-sm-12 col-md-12  col-lg-6 my-sm-5">
              <div className="right-child">
                <div className=" mb-5">
                  <img src={require("../../images/Group (4).png")} className="fix-height-icon" />
                  <p className="heading"><strong>Hardware Services</strong></p>
                  <p className="sentence">KEONICS provides a range of hardware services, including data center setup, network design and maintenance, hardware maintenance and repair, and installation of computer systems and peripherals, among others. Their team of experienced professionals aims to offer end-to-end hardware solutions to their customers.</p>
                </div>
                <div className=" mb-5">
                  <img src={require("../../images/Group (5).png")} className="fix-height-icon" />
                  <p className="heading"><strong>Software Services</strong></p>
                  <p className="sentence">KEONICS offers customized ERP solutions for businesses to streamline processes, reduce costs and improve efficiency. They also provide web and software development, IT consulting, networking solutions, and training programs to help individuals and organizations enhance their IT infrastructure and skills.</p>
                </div>
              </div>   
            </div> 
            {/* <div class="col-sm-12 col-md-12  col-lg-6 pe-lg-5 my-sm-5"> */}
            {/* Mobile View */}
            {/* <div class="right-child align-right mobile-view">
            <p class="para2 text-end-right" style="border-bottom: 1px solid #1a1167;">Products</p>
            <h2 class="text-end text-right">What we Offer</h2>
            <p class="sentence">We provide a comprehensive range of IT and automation consultancy services. Our expertise has been built over 25 years wherein we have executed landmark projects in the history of the state of Karnataka. Well-trained and experienced manpower provide computer networking and network maintenance solutions. These services are provided all across Karnataka either directly by KEONICS or through business associates in each location. Currently, we handle the bulk of the Annual Maintenance Contracts (AMC) of computers and computer peripherals for State Government and public sector organizations. KEONICS has also developed the capability to handle large-scale data entry and data warehousing projects. Projects have been executed for customers like Census Department, Small Savings & State Lottery, Bangalore Metropolitan Transport Corporation, Central Jail, Bangalore Water Supply  Karnataka Power Transmission Corporation Ltd. & Sewage Board and the Education Department.</p>
          </div>
          <div class=" align-right">
            <img src="../images/Group (4).png" class="fix-height-icon align-img-right">
            <p class="heading text-end"><strong>Hardware Services</strong></p>
            <p class="sentence">KEONICS provides a range of hardware services, including data center setup, network design and maintenance, hardware maintenance and repair, and installation of computer systems and peripherals, among others. Their team of experienced professionals aims to offer end-to-end hardware solutions to their customers.</p>
          </div>
          <div class=" my-sm-5 align-right">
            <img src="../images/Group (5).png" class="fix-height-icon">
            <p class="heading text-end"><strong>Software Services</strong></p>
            <p class="sentence">KEONICS offers customized ERP solutions for businesses to streamline processes, reduce costs and improve efficiency. They also provide web and software development, IT consulting, networking solutions, and training programs to help individuals and organizations enhance their IT infrastructure and skills.</p>
          
          </div> */}
            {/* </div> */}
            {/* <div class="col-sm-12 col-md-12  col-lg-6 mt-5 my-sm-5"> */}
            {/* Desktop View */}
            {/* <div class="right-child align-right desktop-view">
            <p class="para2 text-end-right" style="border-bottom: 1px solid #1a1167;">Products</p>
            <h2 class="text-end text-right">What we Offer</h2>
            <p class="sentence">We provide a comprehensive range of IT and automation consultancy services. Our expertise has been built over 25 years wherein we have executed landmark projects in the history of the state of Karnataka. Well-trained and experienced manpower provide computer networking and network maintenance solutions. These services are provided all across Karnataka either directly by KEONICS or through business associates in each location. <br> <br> Currently, we handle the bulk of the Annual Maintenance Contracts (AMC) of computers and computer peripherals for State Government and public sector organizations. KEONICS has also developed the capability to handle large-scale data entry and data warehousing projects. Projects have been executed for customers like Census Department, Small Savings & State Lottery, Bangalore Metropolitan Transport Corporation, Central Jail, Bangalore Water Supply  Karnataka Power Transmission Corporation Ltd. & Sewage Board and the Education Department.</p>
          </div>    */}
            {/* </div>  */}
          </section></div>
      </section>
      <section className="other-team-section">
        <div className="container">
          <h2>Words from Our Leadership Team</h2>
          <div className=" extra-banner-image-team">
          <Slider {...settings}>
            <div className="">
              <div className="other-team-outer">
                <div className="other-teams-inner">
                <div className="team-img-wrap"><img src={require("../../images/ev.png")} alt="name" /></div>
                <div className="team-content">
                  <h6>"The Keonics Karnataka public procurement system is a game-changer for the state. It promotes social accountability and responsibility in procurement processes, while reducing costs and time." </h6>
                </div>
                </div>
                <div className="team-details-wrap">
                  <h6>Dr. E.V. Ramana Reddy, IAS </h6>
                  <p>Additional Chief Secretary to Government - Department of Electronics, Information Technology, Biotechnology and Science &amp; Technology.</p>
                </div>
              </div>
            </div> 
            <div className="">
              <div className="other-team-outer">
              <div className="other-teams-inner">
                <div className="team-img-wrap"><img src={require("../../images/ravi.png")} alt="name" /></div>
                <div className="team-content">
                  <h6>"KEONICS is a pioneer in the field of electronics and information 
                        technology, dedicated to empowering businesses and driving digital 
                        transformation. Our mission is to provide innovative solutions and 
                        services that enable organizations to thrive in the digital age."</h6>
                </div>
                </div>
                <div className="team-details-wrap">
                  <h6>Sri. Ravi D. Channannavar, IPS</h6>
                  <p>Managing Director, Keonics</p>
                </div>
              </div>
            </div> 
            <div className="">
              <div className="other-team-outer">
              <div className="other-teams-inner">
                <div className="team-img-wrap"><img src={require("../../images/dir.jpeg")} alt="name" /></div>
                <div className="team-content">
                  <h6>"KEONICS is more than just a technology solutions provider – we your
                        strategic partner. We are invested in your success and are dedicated to
                        helping you achieve your business objectives. Together, we can unlock 
                        new opportunities and drive sustainable growth."
                  </h6>
                </div>
                </div>
                <div className="team-details-wrap">
                  <h6>Prof Dr Sarit Kumar</h6>
                  <p>Consultant of Keonics</p>
                </div>
              </div>
            </div>
            </Slider>
          </div>
        </div>
      </section>
    </main>
   
  </div></>
  )
}

export default HomeEng