import React, { useState, useContext } from 'react'
import { Link , useLocation } from 'react-router-dom';
import ModalContainer from '../../Components/Modal/ModalContainer';
import { LandingPageContext } from './Contexts';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
import { Context } from '../../Components/Context/Context';

const HeaderEng = () => {

    // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
    const [isShowing, setIsShowing] = useState<any>(false);
    const { language, setLanguage } = useContext(Context);


    const [collapsed, setCollapsed] = useState(true);

    const location_details = useLocation();

  const toggleNavbar = () => setCollapsed(!collapsed);

    return (
        language == 'en' ?
            <section className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                    <Link className="navbar-brand" to="/home"><img src={require("../images/Group 1000002263.png")} /></Link>
                        {/*Mobile screen language buttons */}
                        <div className="language-btn-wrap mobile-screen-view">
                            <Link to='#' onClick={() => setLanguage('en')} className="nav-link text-white" > English </Link>
                            {/* <a href="english/welcome.html" className="active">English</a> */}

                            <Link to='#' onClick={() => setLanguage('ka')} className="nav-link text-white" > ಕನ್ನಡ </Link>
                            {/* <a href="/web/welcome.html">ಕನ್ನಡ</a> */}
                        </div>
                        <NavbarToggler onClick={toggleNavbar} />
                        <Collapse isOpen={!collapsed} navbar id='id="navbarSupportedContent"'>
                            <ul className="navbar-nav mx-auto ">
                                <li className="nav-item active">
                                    <Link to={'/home'} className={ 
                                        location_details?.pathname == "/home" || location_details?.pathname == "/welcome" || location_details?.pathname == "/"
                                        ? "nav-link text-white active"
                                        : "nav-link text-white"  
                                        } >Home </Link>
                                    {/* <a className="nav-link text-white active" href="home.html">Home</a> */}
                                </li>
                                <li className="nav-item">
                                    <Link to={'/about'} className={ 
                                        location_details?.pathname == "/about" 
                                        ? "nav-link text-white active"
                                        : "nav-link text-white"  
                                        } > About Us </Link>
                                    {/* <a className="nav-link text-white" href="about-us.html">About Us</a> */}
                                </li>
                                <li className="nav-item">
                                    <Link to={'/faq'} className={ 
                                        location_details?.pathname == "/faq"
                                        ? "nav-link text-white active"
                                        : "nav-link text-white"  
                                        } > FAQ </Link>
                                    {/* <a className="nav-link text-white" href="faq.html">FAQ</a> */}
                                </li>
                                <li className="nav-item">
                                    <Link to={'/contact'} className={ 
                                        location_details?.pathname == "/contact"
                                        ? "nav-link text-white active"
                                        : "nav-link text-white"  
                                        }> Contact Us </Link>
                                    {/* <a className="nav-link text-white" href="contact-us.html">Contact Us</a> */}
                                </li>
                            </ul>
                            <div className="main">
                                <Link
                                    to="#"
                                    // className="blue-text"
                                    className="nav-link text-white"
                                    onClick={() => {
                                        setIsShowing(true)

                                    }}
                                >
                                    <i className="fa fa-sign-in" aria-hidden="true" /> Login
                                </Link>
                                {/* <a className="nav-link text-white" onClick={setIsShowing(true)} href="" data-bs-toggle="modal" data-bs-target="#LoginModal"><i className="fa fa-sign-in" aria-hidden="true" /> Login</a> */}
                                {/*<a class="nav-link text-white" href="#">Register</a>*/}
                            </div>
                            {/*Large screen language buttons */}
                            <div className="language-btn-wrap large-screen-view">
                                <Link to='#' onClick={() => setLanguage('en')} className="" > English </Link>
                                {/* <a href="english/welcome.html" className="active">English</a> */}
                                <Link to='#' onClick={() => setLanguage('ka')} className="" > ಕನ್ನಡ </Link>
                                {/* <a href="/web/welcome.html">ಕನ್ನಡ</a> */}
                            </div>
                        </Collapse>
                    </nav>
                </div >
                <div className="logos-container">
                    <Link to="http://www.keonics.in/english/" target="_blank"><img src={require("../images/keonics_logo.png")} alt="logo" /></Link>
                </div>

                {/* Login Modal */}
                <ModalContainer
                    open={isShowing}
                    setOpen={() => {
                        setIsShowing(false);
                        
                    }}
                    header="Login"
                    
                >
                    <div className="LoginModal">
                            <h6 className="description-head">Click on which user you want to login.</h6>
                            <div className="login-user-links-wrap">
                                <Link to="/login" className="btn btn-secondary" >KEONICS</Link>
                                <Link to="/login" className="btn btn-secondary" >Department</Link>
                                <Link to="/login" className="btn btn-secondary" >Vendor</Link>
                            </div>
                    </div>

                </ModalContainer>

            </section >

            :

            <section className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link className="navbar-brand" to="/home"><img src={require("../images/Group 1000002263.png")} /></Link>
                        {/*Mobile screen language buttons */}
                        <div className="language-btn-wrap mobile-screen-view">
                            <Link to='#' onClick={() => setLanguage('en')} className="nav-link text-white" > English </Link>
                            {/* <a href="english/welcome.html" className="active">English</a> */}

                            <Link to='#' onClick={() => setLanguage('ka')} className="nav-link text-white " > ಕನ್ನಡ </Link>
                            {/* <a href="/web/welcome.html">ಕನ್ನಡ</a> */}
                        </div>
                        <NavbarToggler onClick={toggleNavbar} />
                        <Collapse isOpen={!collapsed} navbar id='id="navbarSupportedContent"'>
                            <ul className="navbar-nav mx-auto ">
                                <li className="nav-item active">
                                    <Link to={'/home'} className={ 
                                        location_details?.pathname == "/home" || location_details?.pathname == "/welcome" || location_details?.pathname == "/"
                                        ? "nav-link text-white active"
                                        : "nav-link text-white"  
                                        } >ಮುಖಪುಟ </Link>
                                    {/* <a className="nav-link text-white active" href="home.html">Home</a> */}
                                </li>
                                <li className="nav-item">
                                    <Link to={'/about'} className={ 
                                        location_details?.pathname == "/about"
                                        ? "nav-link text-white active"
                                        : "nav-link text-white"  
                                        } > ನಮ್ಮ ಬಗ್ಗೆ </Link>
                                    {/* <a className="nav-link text-white" href="about-us.html">About Us</a> */}
                                </li>
                                <li className="nav-item">
                                    <Link to={'/faq'} className={ 
                                        location_details?.pathname == "/faq" 
                                        ? "nav-link text-white active"
                                        : "nav-link text-white"  
                                        }> ಪ್ರಶ್ನೋತ್ತರ </Link>
                                    {/* <a className="nav-link text-white" href="faq.html">FAQ</a> */}
                                </li>
                                <li className="nav-item">
                                    <Link to={'/contact'} className={ 
                                        location_details?.pathname == "/contact"
                                        ? "nav-link text-white active"
                                        : "nav-link text-white"  
                                        }> ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಲು </Link>
                                    {/* <a className="nav-link text-white" href="contact-us.html">Contact Us</a> */}
                                </li>
                            </ul>
                            <div className="d-flex main justify-content-center justify-content-lg-start">
                                <Link
                                    to="#"
                                    // className="blue-text"
                                    className="nav-link text-white"
                                    onClick={() => {
                                        setIsShowing(true)

                                    }}
                                >
                                    <i className="fa fa-sign-in" aria-hidden="true" /> ಲಾಗಿನ್ ಮಾಡಿ
                                </Link>
                                {/* <a className="nav-link text-white" onClick={setIsShowing(true)} href="" data-bs-toggle="modal" data-bs-target="#LoginModal"><i className="fa fa-sign-in" aria-hidden="true" /> Login</a> */}
                                {/*<a class="nav-link text-white" href="#">Register</a>*/}
                            </div>
                            {/*Large screen language buttons */}
                            <div className="language-btn-wrap large-screen-view">
                                <Link to='#' onClick={() => setLanguage('en')} className="" > English </Link>
                                {/* <a href="english/welcome.html" className="active">English</a> */}
                                <Link to='#' onClick={() => setLanguage('ka')} className="" > ಕನ್ನಡ </Link>
                                {/* <a href="/web/welcome.html">ಕನ್ನಡ</a> */}
                            </div>
                        </Collapse>
                    </nav>
                </div >
                <div className="logos-container">
                    <Link to="http://www.keonics.in/english/" target="_blank"><img src={require("../images/keonics_logo.png")} alt="logo" /></Link>
                </div>


                {/* Login Modal */}
                <ModalContainer
                    open={isShowing}
                    setOpen={() => {
                        setIsShowing(false);
                    }}
                    header="ಲಾಗಿನ್ ಮಾಡಿ"
                >
                      <div className="LoginModal">
                            <h6 className="description-head">ನೀವು ಲಾಗಿನ್ ಮಾಡಲು ಬಯಸುವ ಬಳಕೆದಾರರ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ.</h6>
                            <div className="login-user-links-wrap">
                                <Link to="/login" className="btn btn-secondary" >ಕಿಯೋನಿಕ್ಸ್</Link>
                                <Link to="/login" className="btn btn-secondary" >ಇಲಾಖೆ</Link>
                                <Link to="/login" className="btn btn-secondary" >ವೆಂಡರ್</Link>
                            </div>
                        </div>

                </ModalContainer>

            </section >


    )
}

export default HeaderEng;
