import React from "react";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { useState, useMemo, useEffect, useContext } from "react";
import { api } from "../../../Library/CustomApi";
import { Context } from "../../../Components/Context/Context";
import { VendorSignup } from "../../../Library/LanguageConversion/Vendor_SignUp";

import {
  AdvaDetailsValidationSchema,
  AdvaDetailsValidationSchema1,
  pincodeValidation,
  pincodeValidation1,
} from "../profileUpdation/UploadValidation";
import TextError from "../../../Components/Errors/TextError";
import {
  company_mses,
  gst_class,
  sc_st_entrepreneur,
  vendor_types,
  woman_entrepreneur,
} from "../../../Library/Constants/MapConstants";
import DatePicker from "react-date-picker";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddressModal from "../profileUpdation/AddressAddModal";
import SameAddressModal from "../profileUpdation/SameAddressModal";

export default function AdvancedRegisterSelfVendor() {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [isShowing, setIsShowing] = useState(false);
  const [isShowings, setIsShowings] = useState(false);
  const [check, setCheck] = useState(false);
  const [states, setstates] = useState(false);
  const [mse, setMse] = useState("");
  const { language, setLanguage } = useContext(Context);
  const [value, setValue] = useState({
    company_mse: "",
    sc_st_entrepreneur: "",
    woman_entrepreneur: "",
    valid_upto: "",
    udyam: "",
    designation: "",
    same: "",
    Lorem: "false",
  });

  const [all, setAll] = useState({
    city: "",
    district: "",
    state: "",
    country: "",
    building_number: "",
    building_address: "",
    pincode: "",
    landmark: "",
    address: "",
  });

  const [alls, setAlls] = useState({
    city: "",
    district: "",
    state: "",
    country: "",
    building_number: "",
    building_address: "",
    pincode: "",
    landmark: "",
    address: "",
    check: "",
  });

  const [data, setData] = useState({
    pan_number: "",
    gst_number: "",
    vendor_type: "",
    gst_class: "",
    company_mse: "",
    valid_upto: "",
    udyam: "",
    sc_st_entrepreneur: "",
    woman_entrepreneur: "",
    company_name: "",
    company_email: "",
    contact_number: "",
    incorporation_date: "",
    company_registraion_address: "",
    company_communication_address: "",
    point_company_name: "",
    point_contact_number: "",
    point_email_id: "",
    designation: "",
    Lorem: "",
    address_line_1: "",
    address_line_2: "",
    user_id: "",
    Category: "",
    profile_status: "",
    city: "",
    district: "",
    state: "",
    country: "",
    building_number: "",
    building_address: "",
    pincode: "",
    landmark: "",
    address: "",
    check: "",
  });

  const [pincodeValues, setpincodeValues] = useState({
    city: "",
    district: "",
    state: "",
    country: "",
    building_number: "",
    building_address: "",
    pincode: "",
    landmark: "",
    address: "",
    check: "",
  });

  useEffect(() => {
    getProfile();
  }, [states]);
  const [doc_complted, setDocomplted] = useState();

  const getProfile = (): any => {
    api
      .post(`/get_documents`, {}, true)
      .then(async function ([success, response]) {
        setDocomplted(response?.data?.documents?.length);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .post(`/get_vendor_profile`, {}, true)
      .then(async function ([success, response]) {
        let a = response?.vendor_details?.advanced_registration_details[0];
        let b = response?.vendor_details?.basic_details[0];
        let c = response?.vendor_details?.company_details[0];
        let d = response?.vendor_details?.address[0];
        let e = response?.vendor_details?.address[1];

        setData({
          ...data,

          gst_number: a?.gst_number,
          pan_number: a?.pan_number,
          vendor_type: a?.vendor_type,
          // sc_st_entrepreneur: a?.sc_st == "no" ? "2" : "1",
          // woman_entrepreneur: a?.women == "no" ? "2" : "1",
          // company_mse: a?.company_mse == "no" ? "2" : "1",
          sc_st_entrepreneur:
            a?.sc_st == "yes" ? "1" : a?.sc_st == "no" ? "2" : "",
          woman_entrepreneur:
            a?.women == "yes" ? "1" : a?.women == "no" ? "2" : "",
          // company_mse: a?.company_mse == "yes" ? "1" : a?.company_mse == "no" ? "2" : "",
          gst_class: a?.gst_class,
          company_email: c?.email,
          company_name: c?.name,
          contact_number: c?.phone,
          incorporation_date: a?.incorporation_date,
          address_line_1: a?.address_line_1,
          address_line_2: a?.address_line_2,
          point_company_name: a?.point_contact_name,
          point_contact_number: a?.point_contact_number,
          point_email_id: a?.point_email_id,
          designation: a?.designation,
          user_id: c?.user_id,
          Category: a?.category,
          valid_upto: a?.validity,
          udyam: a?.udyam == "yes" ? "1" : a?.udyam == "no" ? "2" : "",
          profile_status: a?.profile_status,
          pincode: d?.pincode || "",
          district: d?.district || "",
          landmark: d?.landmark || "",
          city: d?.city || "",
          state: d?.state || "",
          country: d?.country || "",
          building_number: d?.building_number || "",
          building_address: d?.building_address || "",
        });

        setAlls({
          ...alls,
          pincode: e?.pincode || "",
          district: e?.district || "",
          landmark: e?.landmark || "",
          city: e?.city || "",
          state: e?.state || "",
          country: e?.country || "",
          building_number: e?.building_number || "",
          building_address: e?.building_address || "",
        });
        setValue({
          ...value,
          company_mse:
            a?.company_mse == "yes" ? "1" : a?.company_mse == "no" ? "2" : "",
        });

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmits = (values: any, actions: any) => {
    setData({
      ...data,
      city: values?.city,
      district: values?.district,
      state: values?.state,
      country: values?.country,
      building_number: values?.building_number,
      building_address: values?.building_address,
      pincode: values?.pincode,
      landmark: values?.landmark,
      address: values?.address,
    });
    setIsShowing(false);
  };

  const handleSubmitData = (values: any, actions: any) => {
    setAlls({
      ...alls,
      city: values?.city,
      district: values?.district,
      state: values?.state,
      country: values?.country,
      building_number: values?.building_number,
      building_address: values?.building_address,
      pincode: values?.pincode,
      landmark: values?.landmark,
      address: values?.address,
      check: values?.check,
    });
    setIsShowings(false);
  };

  let start_date: any = `${new Date().toLocaleString("default", {
    year: "numeric",
  })}-${new Date().toLocaleString("default", {
    month: "2-digit",
  })}-${new Date().toLocaleString("default", { day: "2-digit" })}`;
  const today = new Date().toISOString().slice(0, 16);

  const initialValues: any = {
    pan_number: "" || data?.pan_number,
    gst_number: "" || data?.gst_number,
    vendor_type: "" || data?.vendor_type,
    gst_class: "" || data?.gst_class,
    company_mse: "" || data?.company_mse || value?.company_mse,
    valid_upto: "" || data?.valid_upto || value?.valid_upto,
    udyam: "" || data?.udyam || value?.udyam,
    sc_st_entrepreneur:
      "" || data?.sc_st_entrepreneur || value?.sc_st_entrepreneur,
    woman_entrepreneur:
      "" || data?.woman_entrepreneur || value?.woman_entrepreneur,
    company_name: "" || data?.company_name,
    company_email: "" || data?.company_email,
    contact_number: "" || data?.contact_number,
    incorporation_date: "" || data?.incorporation_date,
    company_registraion_address:
      "" || data?.city == ""
        ? ""
        : data?.city +
          "," +
          data?.district +
          "," +
          data?.state +
          "," +
          data?.country +
          "," +
          data?.building_number +
          "," +
          data?.building_address +
          "," +
          data?.landmark +
          "," +
          data?.address +
          ",",
    company_communication_address:
      "" || alls?.city == ""
        ? ""
        : alls?.city +
            "," +
            alls?.district +
            "," +
            alls?.state +
            "," +
            alls?.country +
            "," +
            alls.building_number +
            "," +
            alls?.building_address +
            "," +
            alls.landmark +
            "," +
            alls?.address +
            "," || "",
    point_company_name: "" || data?.point_company_name,
    point_contact_number: "" || data?.point_contact_number,
    point_email_id: "" || data?.point_email_id,
    designation: "" || data?.designation || value?.designation,
    Lorem: "false",
    user_id: "" || data?.user_id,
    Category: "" || data?.Category,
    profile_status: "" || data?.profile_status,
    check: alls?.check || "",
  };

  const get_check = (value: any) => {
    setCheck(!check);
    // initialValues.Lorem = value.Lorem ;
  };

  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions);
  };
  const sendData = (values: any, actions: any) => {
    var dates: any = new Date(values?.valid_upto).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;
    api
      .patch(
        `/vendors/${data?.user_id}`,
        {
          ...values,

          company_mse: values?.company_mse == "1" ? "yes" : "no",
          valid_upto: values?.company_mse == "1" ? dat : "",
          udyam:
            values?.company_mse == "2"
              ? "no"
              : values?.udyam == "1"
              ? "yes"
              : "no",
          woman_entrepreneur:
            values?.woman_entrepreneur == "1"
              ? "yes"
              : values?.woman_entrepreneur,
          sc_st_entrepreneur:
            values?.sc_st_entrepreneur == "1"
              ? "yes"
              : values?.sc_st_entrepreneur,
          company_registraion_address_: values?.company_registraion_address,
          email: values?.company_email,
          profile_status: 2,
          type: "advanced",
          company_register_address: {
            type: "5",
            building_no: data?.building_number,
            pincode: data?.pincode,
            district: data?.district,
            longtitude: null,
            latitude: null,
            landmark: data?.landmark,
            city: data?.city,
            state: data?.state,
            country: data?.country,
            building_number: data?.building_number,
            building_address: data?.building_address,
          },

          company_communication_address: {
            type: "6",
            building_no: alls?.building_number,
            pincode: alls?.pincode,
            district: alls?.district,
            longtitude: null,
            latitude: null,
            landmark: alls?.landmark,
            city: alls?.city,
            state: alls?.state,
            country: alls?.country,
            building_number: alls?.building_number,
            building_address: alls?.building_address,
          },
        },
        true
      )
      .then(async function ([success, response, status]: any) {
        toast.success("Updated successfully");
        //etdisableds(true);
        navigate("/document-upload");
        return response;
      })
      .catch((err) => {});
  };
  return (
    <>
      <div className="login-content">
        <Formik
          validationSchema={
            value?.company_mse !== "1"
              ? AdvaDetailsValidationSchema1
              : AdvaDetailsValidationSchema
          }
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <Form className="Reg-wrap">
                <Row>
                  <Col md={3} sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {VendorSignup?.PAN_Number[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          name="pan_number"
                          type="text"
                          value={values?.pan_number}
                          className={`form-control ${
                            touched.pan_number && errors.pan_number
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={handleChange}
                          disabled
                        />
                        <ErrorMessage name="pan_number" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {VendorSignup?.GST_Number[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          name="gst_number"
                          type="text"
                          value={values?.gst_number}
                          className={`form-control ${
                            touched.gst_number && errors.gst_number
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={handleChange}
                          disabled
                        />
                        <ErrorMessage name="gst_number" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md={3} sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {VendorSignup?.Vendor_Type[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          type="select"
                          name="vendor_type"
                          value={values.vendor_type}
                          onChange={handleChange}
                          disabled
                        >
                          <option value="">{VendorSignup?.Select[language]} </option>
                          {vendor_types?.map((item: any) => {
                            return (
                              <>
                                <option value={item?.value}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </Input>
                        <ErrorMessage
                          name="vendor_type"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {VendorSignup?.GST_class[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          type="select"
                          name="gst_class"
                          value={values?.gst_class}
                          onChange={handleChange}
                          disabled
                        >
                          <option value="">{VendorSignup?.Select[language]} </option>
                          {gst_class?.map((item: any) => {
                            return (
                              <>
                                <option value={item?.value}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </Input>
                        <ErrorMessage name="gst_class" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {VendorSignup?.Is_your_company_MSME_yes_no[language]}{" "}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          disabled={doc_complted == 0 ? false : true}
                          type="select"
                          name="company_mse"
                          value={values?.company_mse}
                          className={`form-control ${
                            touched.company_mse && errors.company_mse
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={(e) => {
                            handleChange(e);
                            setValue({
                              ...value,
                              company_mse: e.target.value,
                              valid_upto: "",
                            });
                          }}
                        >
                          <option value="">{VendorSignup?.Select[language]}</option>
                          {company_mses?.map((item: any) => {
                            return (
                              <>
                                <option value={item?.value}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </Input>
                        <ErrorMessage
                          name="company_mse"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  {value?.company_mse == "1" ? (
                    <Col md={3} sm={6}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                          {VendorSignup?.MSME_valid_upto[language]}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>{" "}
                          </Label>
                          {/* <Input
                            name="valid_upto"
                            type="date"
                            value={values?.valid_upto}
                            className={`form-control ${touched.valid_upto && errors.valid_upto
                              ? "is-invalid"
                              : ""
                              }`}
                            onChange={(e) => {
                              handleChange(e);
                              setValue({
                                ...value,
                                valid_upto: e.target.value,
                              });
                            }}
                            min={start_date}
                          /> */}

                          <DatePicker
                            disabled={doc_complted == 0 ? false : true}
                            name="valid_upto"
                            format={"dd-MM-y"}
                            dayPlaceholder={"dd"}
                            monthPlaceholder={"mm"}
                            yearPlaceholder={"yyyy"}
                            value={values?.valid_upto}
                            className={`form-control ${
                              touched.valid_upto && errors.valid_upto
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={(e: any) => {
                              setFieldValue("valid_upto", e);
                              setValue({
                                ...value,
                                valid_upto: e,
                              });
                            }}
                            minDate={new Date()}
                            // disabled
                          />

                          <ErrorMessage
                            name="valid_upto"
                            component={TextError}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  ) : null}

                  {value?.company_mse == "1" ? (
                    <Col md={3} sm={6}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                          {VendorSignup?.UDYAM_Yes_No[language]}{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <Input
                            disabled={doc_complted == 0 ? false : true}
                            type="select"
                            name="udyam"
                            value={values?.udyam}
                            onChange={(e) => {
                              handleChange(e);
                              setValue({
                                ...value,
                                udyam: e.target.value,
                              });
                            }}
                          >
                            <option value="">{VendorSignup?.Select[language]}</option>
                            {company_mses?.map((item: any) => {
                              return (
                                <>
                                  <option value={item?.value}>
                                    {item?.name}
                                  </option>
                                </>
                              );
                            })}
                          </Input>
                          <ErrorMessage name="udyam" component={TextError} />
                        </div>
                      </FormGroup>
                    </Col>
                  ) : null}
                  <Col md={3} sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {VendorSignup?.Are_you_SC_ST_entrepreneur_Yes_No[language]}{" "}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          disabled={doc_complted == 0 ? false : true}
                          type="select"
                          name="sc_st_entrepreneur"
                          value={values?.sc_st_entrepreneur}
                          onChange={(e) => {
                            handleChange(e);
                            setValue({
                              ...value,
                              sc_st_entrepreneur: e.target.value,
                            });
                          }}
                        >
                          <option value="">{VendorSignup?.Select[language]}</option>
                          {sc_st_entrepreneur?.map((item: any) => {
                            return (
                              <>
                                <option value={item?.value}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </Input>
                        <ErrorMessage
                          name="sc_st_entrepreneur"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {VendorSignup?.Are_you_women_entrepreneur_Yes_No[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          disabled={doc_complted == 0 ? false : true}
                          type="select"
                          label="Are you woman entrepreneur (Yes/No)"
                          name="woman_entrepreneur"
                          value={values?.woman_entrepreneur}
                          onChange={(e) => {
                            handleChange(e);
                            setValue({
                              ...value,
                              woman_entrepreneur: e.target.value,
                            });
                          }}
                        >
                          <option value="">{VendorSignup?.Select[language]}</option>
                          {woman_entrepreneur?.map((item: any) => {
                            return (
                              <>
                                <option value={item?.value}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </Input>
                        <ErrorMessage
                          name="woman_entrepreneur"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="company-information-update-wrap">
                    <h4>{VendorSignup?.Company_information[language]}</h4>
                    <Row>
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                            {VendorSignup?.Company_name[language]}
                              <span
                                className="text-danger"
                                style={{ fontSize: "18px" }}
                              >
                                *
                              </span>
                            </Label>
                            <Input
                              name="company_name"
                              type="text"
                              value={values?.company_name}
                              className={`form-control ${
                                touched.company_name && errors.company_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                              disabled
                            />
                            <ErrorMessage
                              name="company_name"
                              component={TextError}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                            {VendorSignup?.Company_Email[language]}
                              <span
                                className="text-danger"
                                style={{ fontSize: "18px" }}
                              >
                                *
                              </span>
                            </Label>
                            <Input
                              name="company_email"
                              type="email"
                              value={values?.company_email}
                              className={`form-control ${
                                touched.company_email && errors.company_email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                              disabled
                            />
                            <ErrorMessage
                              name="company_email"
                              component={TextError}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                            {VendorSignup?.Contact_Number[language]}
                              <span
                                className="text-danger"
                                style={{ fontSize: "18px" }}
                              >
                                *
                              </span>
                            </Label>
                            <Input
                              name="contact_number"
                              type="number"
                              value={values?.contact_number}
                              className={`form-control ${
                                touched.contact_number && errors.contact_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                              disabled
                            />
                            <ErrorMessage
                              name="contact_number"
                              component={TextError}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup className="main">
                          <div className="custom-input">
                            <Label>
                              {VendorSignup?.Company_incorporation_date[language]}
                              <span
                                className="text-danger"
                                style={{ fontSize: "18px" }}
                              >
                                *
                              </span>
                            </Label>
                            <input
                              name="incorporation_date"
                              type="date"
                              id="dates"
                              value={values?.incorporation_date}
                              className={`form-control ${
                                touched.incorporation_date &&
                                errors.incorporation_date
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                              disabled
                            />
                            <ErrorMessage
                              name="incorporation_date"
                              component={TextError}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                              {VendorSignup?.Company_Registered_Address[language]}
                              <span
                                className="text-danger"
                                style={{ fontSize: "18px" }}
                              >
                                *
                              </span>
                            </Label>
                            {doc_complted == 0 ? (
                              <a
                                href="#"
                                onClick={() => setIsShowing(true)}
                                className="form-control"
                              >
                                {VendorSignup?.Add[language]}
                              </a>
                            ) : null}

                            {values?.company_registraion_address ? (
                              <>
                                <Input
                                  type="textarea"
                                  name="company_registraion_address"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    touched.company_registraion_address &&
                                    errors?.company_registraion_address
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={values?.company_registraion_address}
                                  disabled
                                />
                              </>
                            ) : null}
                            <ErrorMessage
                              name="company_registraion_address"
                              component={TextError}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>
                              {VendorSignup?.Company_Communication_Address[language]}
                              <span
                                className="text-danger"
                                style={{ fontSize: "18px" }}
                              >
                                *
                              </span>
                            </Label>
                            {doc_complted == 0 ? (
                              <a
                                href="#"
                                onClick={() => setIsShowings(true)}
                                className="form-control"
                              >
                                {VendorSignup?.Add[language]}
                              </a>
                            ) : null}

                            {/* <Button
                              class="btn btn-primary mb-5 mt-5"
                              color="primary"
                              type="submit"
                              onClick={() => setIsShowings(true)}
                            >
                              Add Address
                            </Button> */}
                            {values?.company_communication_address ? (
                              <>
                                <Input
                                  type="textarea"
                                  name="company_communication_address"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    touched.company_communication_address &&
                                    errors?.company_communication_address
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={values?.company_communication_address}
                                  disabled
                                />
                              </>
                            ) : null}
                            <ErrorMessage
                              name="company_communication_address"
                              component={TextError}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="pointofcon-information-update-wrap">
                        <h4>{VendorSignup?.Point_of_Contact_Information[language]}</h4>
                        <Row>
                          <Col md={4} sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>
                                {VendorSignup?.Contact_Name[language]}
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </Label>
                                <Input
                                  name="point_company_name"
                                  type="text"
                                  value={values?.point_company_name}
                                  className={`form-control ${
                                    touched.point_company_name &&
                                    errors.point_company_name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={handleChange}
                                  disabled={doc_complted == 0 ? false : true}
                                />
                                <ErrorMessage
                                  name="point_company_name"
                                  component={TextError}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>
                                {VendorSignup?.Contact_Number[language]}
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </Label>
                                <Input
                                  name="point_contact_number"
                                  type="number"
                                  value={values?.point_contact_number}
                                  className={`form-control ${
                                    touched.point_contact_number &&
                                    errors.point_contact_number
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={handleChange}
                                  disabled={doc_complted == 0 ? false : true}
                                />
                                <ErrorMessage
                                  name="point_contact_number"
                                  component={TextError}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>
                                  {" "}
                                  {VendorSignup?.contact_email_id[language]}
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </Label>
                                <Input
                                  name="point_email_id"
                                  type="email"
                                  value={values?.point_email_id}
                                  className={`form-control ${
                                    touched.point_email_id &&
                                    errors.point_email_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={handleChange}
                                  disabled={doc_complted == 0 ? false : true}
                                />
                                <ErrorMessage
                                  name="point_email_id"
                                  component={TextError}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6}>
                            <FormGroup>
                              <div className="custom-input">
                                <Label>
                                {VendorSignup?.designation[language]}
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "18px" }}
                                  >
                                    *
                                  </span>
                                </Label>
                                <Input
                                  name="designation"
                                  type="text"
                                  value={values?.designation}
                                  className={`form-control ${
                                    touched.designation && errors.designation
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setValue({
                                      ...value,
                                      designation: e.target.value,
                                    });
                                  }}
                                  disabled={doc_complted == 0 ? false : true}
                                />
                                <ErrorMessage
                                  name="designation"
                                  component={TextError}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="agree-checkbox">
                      <FormGroup>
                        <div className="agree-checkbox-wrap">
                          <Input
                            name="Lorem"
                            type="checkbox"
                            className="form-check-input"
                            value={values?.Lorem}
                            // className={`form-control ${
                            //   check == false ? "is-invalid" : ""
                            // }`}
                            onChange={(e: any) => {
                              handleChange(e);
                              // setValue({
                              //   ...value,
                              //   Lorem: e.target.value,
                              // });
                            }}
                            // onClick={(e: any) => get_check(e.target.value)}
                          />

                          <Label>{VendorSignup?.Make_sure_entered_details_are_correct[language]}</Label>
                        </div>
                        {/* <div className="error-outer mt-2">
                          <div className="errors">
                        
                            {
                              check == false ? "Field is required" : null
                            }
                          </div>
                        </div> */}
                        <ErrorMessage name="Lorem" component={TextError} />
                      </FormGroup>
                    </div>
                  </Col>
                  <Col sm={12} className="text-end">
                    <Button
                      class="btn btn-primary mb-5"
                      color="primary"
                      type="submit"
                      disabled={doc_complted == 0 ? false : true}
                      //disabled={check == false ? true : false}
                    >
                      {VendorSignup?.submit[language]}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Formik>
      </div>

      <Formik
        initialValues={pincodeValues}
        enableReinitialize
        validationSchema={pincodeValidation}
        onSubmit={(values, actions) => {
          handleSubmits(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => {
          return (
            <AddressModal
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              touched={touched}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              handleReset={handleReset}
              data={value}
            />
          );
        }}
      </Formik>

      <Formik
        initialValues={pincodeValues}
        validationSchema={pincodeValidation}
        onSubmit={(values, actions) => {
          handleSubmitData(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => {
          return (
            <SameAddressModal
              isShowing={isShowings}
              setIsShowing={setIsShowings}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              handleReset={handleReset}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              all={data}
              value={value}

              //setValue={setValue}
              //value={value}
              // order_view={order_view}
            />
          );
        }}
      </Formik>
    </>
  );
}
