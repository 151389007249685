import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState,useContext } from "react";
import { Context } from "../../../Components/Context/Context";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import TextError from "../../../Components/Errors/TextError";
import "react-datepicker/dist/react-datepicker.css";
import ModalContainer from "../../../Components/Modal/ModalContainer";

interface proptype {
    isShowing: boolean;
    setIsShowing: Function;
    handleSubmit: any;
    values: any;
    handleChange: any;
    errors: any;
    touched: any;
    isSubmitting: any;
    setFieldValue: any;
    setValue: any;
    value: any;
    handleReset: any;
    orderIds: any
}

const ChallanModal = (props: proptype) => {
    const [images, setImages] = useState<string[]>([]);
    const { language, setLanguage } = useContext(Context);
    const [file_name, setFileName] = useState("")
    const handleFilechange = (e: any, values: any) => {
        const myMemoObj = URL.createObjectURL(e.target.files[0]);
        setImages([...images, myMemoObj]);
        props?.setFieldValue("challan", e.target.files[0]);
        setFileName(e.target.files[0]?.name)
        props?.setFieldValue('order_id', props?.orderIds)
    }
    const handleDeletes = (img: any, indexs: number) => {
        props?.setFieldValue(
            "file",
            props?.values.file
        );
        const s1 = images?.filter((key, index) => index !== indexs);
        setImages(s1);
    };
    // console.log(date?.payment_date,"date?.payment_date")
    return (<>
        <React.Fragment>
            <ModalContainer
                open={props?.isShowing}
                setOpen={() => {
                    props?.setIsShowing(false);
                    props?.handleReset();
                    setImages([]);
                }}
                header=' Challan Upload'
            >
                <div className="upload-prod-outer">
                    <Form className="form-wrap">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>
                                    {VendorRegLang?.UploadtheDocuments[language]}{" "}
                                        <i
                                            className="fa fa-asterisk required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <div className="image-upload-input">
                                        <Input
                                            type="file"
                                            name="challan"
                                            // accept=".pdf"
                                            //disabled={props?.show_add == true ? false : true}
                                            title=""
                                            multiple
                                            onChange={(e: any) =>
                                                handleFilechange(e, e.target.files[0])

                                            }
                                            className={`form-control ${props?.touched.file && props?.errors.file
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <span className="upload-text">{VendorRegLang?.UploadtheDocuments[language]}{file_name}</span>
                                    </div>
                                    <ErrorMessage name="challan" component={TextError} />
                                </FormGroup>
                            </Col>

                            <div className="button-flex text-end">
                                <FormGroup>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        onClick={props?.handleSubmit}
                                        className={"px-5 mt-5"}
                                        disabled={props?.isSubmitting}
                                    >
                                       {VendorRegLang?.UploadChellan[language]}
                                    </Button>
                                </FormGroup>
                            </div>

                        </Row>
                    </Form>
                </div>
            </ModalContainer>
        </React.Fragment>

    </>)

}

export default ChallanModal;