import React, { useState, useEffect ,useContext} from "react";
import {
    Table,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    Button,
    UncontrolledAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem
} from "reactstrap";
import { Link } from "react-router-dom";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { convertISODateToDateTime } from "../../../Library/Constants/helpers";
import { api } from "../../../Library/CustomApi";
import OrderViewModal from "../Orders/OrderViewModal/OrderViewModal";
import TDSPaymentModal from "./Modal/TDSPaymentModal";
import { Department_TDS } from "../../../Library/LanguageConversion/Department_TDS";
import { Context } from "../../../Components/Context/Context";

export default function TDSList() {
    const [loading, setLoading] = useState(false);
    const [TDSData, setTDSDAta] = useState([]);
    const [pageData, setPageData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number | string>(1)
    const [searchVal, setSearchVal] = useState<string>("")
    const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false) // for feedback modal
    const [payIsOpen, setPayIsOpen] = useState<boolean>(false) // for feedback modal
    const [singleOrderData, setSingleOrderData] = useState<any>([])
    const [TDSDataIndex, setTDSDataIndex] = useState<number | null>(null)
    const [orderData, setOrderData] = useState<any>([])
    const [selectedAddress, setSelectedAddress] = useState<any>([])
    const { language, setLanguage } = useContext(Context);

//    Searching
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if (searchVal !== null ||searchVal === "") {
            getTDSList();
          }
        }, 500);
    
        return () => clearTimeout(delayDebounceFn);
      }, [searchVal]);
    // fetch processing orders 
    const getTDSList = () => {
        setLoading(true);
        api
            .get(`/order/pf-invoice?page=${currentPage}&search_value=${searchVal}`, true)
            .then(async function ([, response]) {
                setTDSDAta(response.data || []);
                setPageData(response?.meta)
                setLoading(false);
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    


    // handles order view modal 
    const handleOrderView = (obj: any) => {
        setSingleOrderData(obj.order_details)
        setOrderData(obj)
        setSelectedAddress(obj?.shipping_address[0])
        setOrderModalOpen(true)
    }

    return (
        <>
            <div className="search-widget-outer">
                <Form className="d-flex" role="search">
                    <InputGroup className="search-group">
                        <InputGroupText title="Order ID/Product Name" className="search-icon" id="search">
                            <i className="fa-sharp fa-solid fa-magnifying-glass" />
                        </InputGroupText>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder={Department_TDS?.OrderID_ProductName[language]}
                            aria-label="Username"
                            aria-describedby="search"
                            onChange={(e: any) => {
                                setCurrentPage(1)
                                 setSearchVal(e.target.value)
                            }}
                        />
                    </InputGroup>
                </Form>
            </div>
            <div className="table-outer-wrap">
                <div className="table-wrap responsive-table">
                    <Table className="w-100 table-striped">
                        <thead>
                            <tr>
                            <th>{Department_TDS?.Sl_No[language]}</th>
                                <th>{Department_TDS?.Order_ID[language]}</th>
                                <th>{Department_TDS?.Tender_ID[language]}</th>
                                <th>{Department_TDS?.Order_Details[language]}</th>
                                <th>{Department_TDS?.Shipping_Address[language]}</th>
                                <th>{Department_TDS?.Date[language]}</th>
                                <th>{Department_TDS?.Quantity[language]}</th>
                                <th>{Department_TDS?.Action[language]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading ?
                                <>
                                    {TDSData?.length !== 0 ? (
                                        <>
                                            {TDSData?.map((item: any, i: number) => {
                                                let count = 0

                                                return (
                                                    <React.Fragment key={i}>
                                                        <tr className="order-relative">
                                                            <td><span className="mobile-heading">{Department_TDS?.Sl_No[language]}:</span><span className="table-data">{pageData?.from + i}</span></td>
                                                            <td title="View Order">
                                                                <span className="mobile-heading">{Department_TDS?.Order_ID[language]}:</span>
                                                                <span className="table-data">
                                                                    <Link to="" className="blue-text" onClick={() => handleOrderView(item)}>{item?.order_id}</Link>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="mobile-heading">{Department_TDS?.Tender_ID[language]}:</span>
                                                                <span className="table-data">{item?.order_details[0].tender_id}</span>
                                                            </td>
                                                            <td>
                                                                <span className="mobile-heading">{Department_TDS?.Product[language]}:</span>
                                                                <span className="table-data">
                                                                    {/* Multiple order list */}
                                                                    {item?.order_details?.length > 1 ? (
                                                                        <>
                                                                            <UncontrolledAccordion defaultOpen="0" className="order-list-accordion">
                                                                                <AccordionItem>
                                                                                    <AccordionHeader targetId="1" className="">
                                                                                    {Department_TDS?.More_than_one_order[language]} <span>{Department_TDS?.Click_to_see_the_Order_list[language]}</span>
                                                                                    </AccordionHeader>
                                                                                    <AccordionBody accordionId="1" className="">
                                                                                        <AccordionHeader targetId="1" className="close-btn">
                                                                                            <span className="close-btn-inner" title="Close">
                                                                                                <i className="bi bi-x-lg"></i>
                                                                                            </span>
                                                                                        </AccordionHeader>
                                                                                        <div className="order-list-outer-wrap">
                                                                                            {
                                                                                                item?.order_details?.map((obj: any, x: number) => {
                                                                                                    count = count + obj.quantity
                                                                                                    return (
                                                                                                        <React.Fragment key={x}>
                                                                                                            <div className="product-details">
                                                                                                                <p className="product-title"><span>{obj?.name}</span></p>
                                                                                                                <p className="sub-details">{Department_TDS?.Unit_price[language]} <span><i className="bi bi-currency-rupee"></i>{(obj?.price * 0.05) + obj?.price} </span></p>
                                                                                                                <p className="sub-details">{Department_TDS?.Quantity[language]}: <span>{obj?.quantity}</span> </p>
                                                                                                                <p className="sub-details">{Department_TDS?.Total[language]}:  <span><i className="bi bi-currency-rupee"></i>{(obj?.total_amount - obj?.tds_on_gst).toFixed(2)} (Including GST)</span></p>
                                                                                                            </div>
                                                                                                            {/* {item?.order_details?.length !== x + 1 && <hr />} */}
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </AccordionBody>
                                                                                </AccordionItem>
                                                                            </UncontrolledAccordion>
                                                                        </>

                                                                    ) : null}

                                                                    {/* Single order */}
                                                                    {item?.order_details?.length == 1 ? (
                                                                        <>
                                                                            {
                                                                                item?.order_details?.map((obj: any, x: number) => {
                                                                                    count = count + obj.quantity
                                                                                    return (
                                                                                        <React.Fragment key={x}>
                                                                                            <div className="product-details">
                                                                                            <p className="product-title"><span>{obj?.name}</span></p>
                                                                                                <p className="sub-details">{Department_TDS?.Unit_price[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.price * 0.05) + obj?.price} </span></p>
                                                                                                <p className="sub-details">{Department_TDS?.Quantity[language]}: <span>{obj?.quantity}</span> </p>
                                                                                                <p className="sub-details">{Department_TDS?.TDS[language]}: <span><i className="bi bi-currency-rupee"></i>{obj?.tds_on_gst}</span> </p>
                                                                                                <p className="sub-details">{Department_TDS?.Total[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.total_amount - obj?.tds_on_gst).toFixed(2)} (Including GST)</span></p>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    ) : null}
                                                                </span>
                                                            </td>
                                                            <td><span className="mobile-heading">{Department_TDS?.Shipping_Address[language]}:</span>
                                                                <span className="table-data">
                                                                {Department_TDS?.Building_no[language]}:{item?.shipping_address[0]?.building_no} <br />
                                                                    {item?.shipping_address[0]?.city} <br />
                                                                    {item?.shipping_address[0]?.district} <br />
                                                                    {item?.shipping_address[0]?.pincode} <br />
                                                                    {item?.shipping_address[0]?.email} <br />
                                                                    {item?.shipping_address[0]?.phone} <br />
                                                                </span>
                                                            </td>
                                                            <td><span className="mobile-heading">{Department_TDS?.Date[language]}:</span><span className="table-data">
                                                                {convertISODateToDateTime(item?.created_at)}
                                                            </span></td>
                                                            <td><span className="mobile-heading">{Department_TDS?.Quantity[language]}:</span>
                                                                <span className="table-data">{count}</span>
                                                            </td>
                                                            <td className="tds-payment">
                                                                <span className="mobile-heading">{Department_TDS?.Action[language]}:</span>
                                                                <span className="table-data">
                                                                {
                                                                    item?.tds_status == 1 ?
                                                                        <Button
                                                                            className="tds-btn"
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                setTDSDataIndex(i)
                                                                                setPayIsOpen(true)
                                                                            }}
                                                                        >{Department_TDS?.Upload_form_No_16A?.[language]}</Button> 
                                                                        :
                                                                        item?.tds_status == 2 ?
                                                                            <>
                                                                                <span className="info-status">{Department_TDS?.Uploaded[language]}</span>
                                                                            </>
                                                                            :
                                                                            item?.tds_status == 3 ?
                                                                                <>
                                                                                    <span className="approved-status">{Department_TDS?.Approved[language]}</span>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    -
                                                                                </>
                                                                }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </>
                                    )
                                        : (
                                            <tr>
                                                <td colSpan={9} className="text-center p-5 empty-text">
                                                {Department_TDS?.No_Orders_Found[language]}

                                                </td>
                                            </tr>
                                        )}
                                </>
                                :
                                <tr>
                                    <td colSpan={9} className="text-center p-5 empty-text">
                                        <Loader />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    {pageData && TDSData.length > 0 && <div className="table-footer-wrap">
                        <small>{Department_TDS?.Showing[language]}{pageData.from}{Department_TDS?.to[language]}{pageData.to} {Department_TDS?.of[language]} {pageData?.total}{Department_TDS?.entries[language]}</small>
                        <div className="pagination-outer">
                            <CustomPagination
                                totalItems={pageData?.total}
                                itemsPerPage={pageData?.per_page}
                                activePage={pageData?.current_page}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>}
                </div>
            </div>

            {/* order view modal  */}
            <div>
                <ModalContainer
                    open={orderModalOpen}
                    setOpen={setOrderModalOpen}
                    header='Order Details'
                >
                    <OrderViewModal
                        singleOrderData={singleOrderData}
                        orderData={orderData}
                        orderViewLoading={false}
                        address={selectedAddress}
                    />
                </ModalContainer>
            </div>

            {/* payment modal  */}
            <div>
                <ModalContainer
                    open={payIsOpen}
                    setOpen={setPayIsOpen}
                    header='Pay Now'
                >
                    <TDSPaymentModal
                        TDSDataIndex={TDSDataIndex}
                        TDSData={TDSData}
                        setPayIsOpen={setPayIsOpen}
                        getTDSList={getTDSList}
                    />
                </ModalContainer>
            </div>
        </>
    );
}