import React from "react";
import { useState, useEffect, useContext } from "react";
import {
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import { Link } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import { Context } from "../../../../Components/Context/Context";
import { Department_Services } from "../../../../Library/LanguageConversion/Department_Services";

export default function DeptAmc() {
  const [loading, setLoading] = useState(false);
  const [amc_data, setAmcData] = useState([]);
  const [pageData, setPageData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number | string>(1)
  const [searchVal, setSearchVal] = useState<string>("")
  const { language, setLanguage } = useContext(Context);

  useEffect(() => {
    getAmc();
  }, [currentPage, searchVal]);

  const getAmc = () => {
    setLoading(true);
    api
      .get(`/amc?page=${currentPage}&search_value=${searchVal}`, true)
      .then(async function ([success, response]) {
        setAmcData(response?.data || []);
        setPageData(response?.meta)
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/department/dashboard">
                {Department_Services?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b> {Department_Services?.AMC[language]}</b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            <div className="search-widget-outer">
              <Form role="search">
                <InputGroup className="search-group">
                  <InputGroupText title="Search here" className="search-icon" id="search">
                    <i className="fa-sharp fa-solid fa-magnifying-glass" />
                  </InputGroupText>
                  <Input
                    type="text"
                    placeholder="Search here"
                    aria-label="Username"
                    aria-describedby="search"
                    onChange={(e) => {
                      setCurrentPage(1)
                      setSearchVal(e.target.value)
                    }}
                  />
                </InputGroup>
              </Form>
            </div>
            <div className="table-wrap responsive-table">
              <Table className="w-100 table-striped">
                <thead>
                  <tr>
                    <th>{Department_Services?.Sl_No[language]}</th>
                    <th>{Department_Services?.Contracts[language]}</th>
                    <th>{Department_Services?.Invoice_ID[language]}</th>
                    <th>{Department_Services?.Valid_From[language]} </th>
                    <th>{Department_Services?.Valid_To[language]}</th>
                    <th>{Department_Services?.Email_ID[language]} </th>
                  </tr>
                </thead>
                <tbody>
                  {amc_data?.length !== 0 && !loading ?
                    amc_data?.map((itm: any, i: number) => {
                      return (
                        <React.Fragment key={i}>
                          <tr>
                            <td><span className="mobile-heading">{Department_Services?.Sl_No[language]}:</span><span className="table-data">{pageData?.from + i}</span></td>
                            <td><span className="mobile-heading">{Department_Services?.Contracts[language]}:</span><span className="table-data">{itm?.invoiceproduct?.usercompany?.name}</span></td>
                            <td><span className="mobile-heading"> {Department_Services?.Invoice_ID[language]}:</span><span className="table-data">{itm?.amcinvoice?.invoice_no}</span></td>
                            <td><span className="mobile-heading"> {Department_Services?.Valid_From[language]}:</span><span className="table-data">{itm?.start_date || 'Invalid date'}</span></td>
                            <td><span className="mobile-heading"> {Department_Services?.Valid_To[language]}:</span><span className="table-data">{itm?.end_date || 'Invalid date'}</span></td>
                            <td><span className="mobile-heading">{Department_Services?.Email_ID[language]} :</span><span className="table-data">{itm?.invoiceproduct?.usercompany?.email}</span></td>
                          </tr>
                        </React.Fragment>)
                    })
                    :
                    loading ? <tr>
                      <td colSpan={6} className="text-center py-5">
                        <Loader />
                      </td>
                    </tr> : <tr><td colSpan={6} className="text-center py-5">
                      <p>{Department_Services?.No_AMC_Found[language]}  </p>
                    </td></tr>}
                </tbody>
              </Table>
              {pageData && amc_data?.length > 0 && < div className="table-footer-wrap">
                <small>{Department_Services?.Showing[language]}  {pageData.from} {Department_Services?.to[language]} {pageData.to} {Department_Services?.of[language]} {pageData?.total} {Department_Services?.entries[language]}</small>
                <div className="pagination-outer">
                  <CustomPagination
                    totalItems={pageData?.total}
                    itemsPerPage={pageData?.per_page}
                    activePage={pageData?.current_page}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
