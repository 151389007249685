export const DepartmentNonVUser:any = {
    //Admin\TDS\Modal\UploadTdsToVendor
    AccountActivated: {
        en: 'Your account will be activated once it is verified by the Admin',
        ka: 'ನಿಮ್ಮ ಖಾತೆಯನ್ನು ವ್ಯವಸ್ಥಾಪಕರು ಪರಿಶೀಲಿಸಿ ದೃಢೀಕರಿಸಲು ನಂತರ ನಿಮ್ಮ ಖಾತೆ ಸಕ್ರಿಯಗೊಳ್ಳುತ್ತದೆ'
    },
     welcome: {
        en: 'Welcome',
        ka: 'ನಸ್ವಾಗತ'
    },

}