import React, { useEffect, useState } from "react";
import ModalContainer from "../../../Components/Modal/ModalContainer";

const ProductDetailsModal = (props: any) => {
  
  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => props?.setIsShowings(false)}
        header="Product Details">
        <div className="feeback-outer-wrap pt-5">
          <div className="feedback-main-heading">
            <div className="feedback-wrap">
              <table className="table table-hover table-bordered">
                <tbody>
                {props?.values?.map((val: any, index: any) => {
  return (
    <React.Fragment key={index}>
      <tr>
        <td>
          <h4>{val[0].key}</h4>
        </td>
        <td>{val[0].val ? val[0].val : "-"}</td>
      </tr>
    </React.Fragment>
  );
})}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default ProductDetailsModal;
