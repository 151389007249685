import { ErrorMessage, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col, InputGroup, InputGroupText, Button, Spinner } from "reactstrap";
import { SignupValidationSchema } from "./SignupValidationSchema";
import TextError from "../../../Components/Errors/TextError";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../../Library/CustomApi";
import { toast } from "react-toastify";
import Select from "react-select";
import { Context } from "../../../Components/Context/Context";
import { DeptRegLang } from "../../../Library/LanguageConversion/DepartmentLang";

const initialValues = {
  name: "",
  phone: "",
  department: "",
  email: "",
  otp: "",
  password1: "",
  password2: "",
}

export default function CreateAccount() {
  
  const [departmentList, setDepartmentList] = useState<any>([])
  const [dept, setDept] = useState<any>([])
  const [passwordShow, setPasswordShow] = useState<boolean>(false)
  const [otpVerified, setOtpVerified] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [otpLoading, setOtpLoading] = useState<boolean>(false)
  const [otpChecking, setOtpChecking] = useState<boolean>(false)
  const [isSent, setIsSent] = useState<boolean>(false)
  const navigate = useNavigate()

  const { language, setLanguage } = useContext(Context);
  
  useEffect(() => {
    getDepartmentsList()
    if (localStorage.getItem("AUTH_TOKEN") !== null) {
      if (localStorage.getItem("ROLE_ID") == "2") {
        navigate("/dashboard");
      }
      if (localStorage.getItem("ROLE_ID") == "3") {
        navigate("/department/dashboard");
      }
      if (localStorage.getItem("ROLE_ID") == "4") {
        navigate("/basic-details-view");
      }
      if (localStorage.getItem("ROLE_ID") == "4") {
        //navigate("/basic-details-view");
        navigate("/overview-vendor");
      }
    }
  }, []);

  const handleRegistration = (values: any, actions: any) => {
    if (!otpVerified) {
      actions?.setFieldError('otp', 'OTP is not verified')
      return
    }
    else {
      setLoading(true)
      api
        .post(
          `/registerDepartment`,
          {
            name: values?.name,
            password: values?.password1,
            password_confirmation: values?.password2,
            phone: values?.phone,
            email: values?.email,
            department: values?.department?.value,
          },
          false
        )
        .then(async function ([success, response, status]) {
          if (status == 200) {
            toast.success("User registered Successfully!");
            navigate('/login')
            actions.handleReset()
            setLoading(false)
          }
          return response;
        })
        .catch((err) => {
          if (err?.response?.status == 422) {
            if (err?.response?.data?.errors?.phone) {
              actions.setFieldError('phone', err?.response?.data?.errors?.phone)
            }

            actions.setSubmitting(false);
          }
          console.log(err);
          setLoading(false)
        });
    }
  }

  // handles otp triggering 
  const handleEmail = (email: string, phone: string, setFieldError: Function) => {
    setOtpLoading(true)
    setIsSent(false)
    api
      .post(
        `/generate_otp`,
        {
          email: email,
          phone: phone
        },
        false
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("OTP sent Successfully!");
        }
        setOtpLoading(false)
        setIsSent(true)
        return response;
      })
      .catch((err) => {

        if (err?.response?.status == 422) {
          if (err?.response?.data?.errors?.phone) {
            setFieldError('phone', err?.response?.data?.errors?.phone)
          }
          if (err?.response?.data?.errors?.email) {
            setFieldError('email', err?.response?.data?.errors?.email)
          }
        }
        console.log(err);
        setOtpLoading(false)
      });
  }

  // handles otp verification 
  const handleOtpCheck = (e: any, email: string, setFieldError: Function) => {
    if (e.target.value.length == 6) {
      setOtpChecking(true)
      setLoading(true)
      api
        .post(
          `/check-otp`,
          {
            email: email,
            otp: e.target.value
          },
          false
        )
        .then(async function ([success, response, status]) {
          if (status == 200) {
            if (response == 1) {
              toast.success("OTP Verified");
              setOtpVerified(true)
            }
            if (response == 0) {
              toast.error("Invalid OTP");
              setFieldError('otp', 'Invalid OTP')
            }
          }
          setLoading(false)
          setOtpChecking(false)
          return response;
        })
        .catch((err) => {
          if (err?.response?.status == 422) {
            toast.error(err?.response?.data?.message);
          }
          console.log(err);
          setLoading(false)
          setOtpChecking(false)
        });
    }
  }

  const getDepartmentsList = () => {
    setLoading(true);
    api
      .get(`/get_all_dept_type`, false)
      .then(async function ([success, response]) {
        // setDepartmentList(response?.data)
        let datas: any = [];
        response?.data?.map((elem: any) => {
          datas.push({ value: elem?.id, label: elem?.name });
        });

        setDepartmentList(datas)
        return response;
      })
      .catch((err) => {
        toast.error('Error while loading Departments list')
        console.log(err);
      });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="login-outer">
          <div className="row">
            {/* left side */}
            <div className="col-lg-5 col-md-6 col-sm-12 ps-0 login-desktop-column">
              {/* login image wrap */}
              <div className="login-img-wrap flex-column">
                <img
                  src="../images/logo-white1.png"
                  alt="login-background"
                  className="px-5"
                />
                <p className="text-white text-center px-5 w-75">
                {DeptRegLang?.Keonics[language]}
                </p>
              </div>
            </div>
            {/* Right side */}
            <div className="col-lg-7 col-md-6 col-sm-12 m-auto">
              {/* login wrap */}

              <div className="col-sm-12  ps-0  d-md-none d-lg-none d-xl-none d-xxl-none">
                <div className="logo-mobile mb-4">
                  <img
                    src="../images/logo-white1.png"
                    alt="login-background"
                    className="px-2"
                  />
                </div>
              </div>
              <div className="login-wrap">
                {/* <h1>Create Account</h1> */}
                <h1>{DeptRegLang?.create_account[language]}</h1>
                <div className="login-content">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validateOnMount={true}
                    validationSchema={SignupValidationSchema}
                    onSubmit={(values, actions) => {
                      handleRegistration(values, actions)
                    }}
                  >
                    {({
                      handleSubmit,
                      values,
                      errors,
                      handleChange,
                      setFieldTouched,
                      setFieldError,
                      setFieldValue
                    }) => {

                      return (
                        <Form onSubmit={handleSubmit}>
                          <Row className="wrap-scroller-600">
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DeptRegLang?.full_name[language]}</Label>
                                  <Input type="text" name="name" onChange={handleChange} value={values.name}></Input>
                                  <ErrorMessage name="name" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DeptRegLang?.PhoneNumber[language]}</Label>
                                  <Input type="tel" name="phone" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }} onChange={(e) => {
                                    setFieldTouched('phone')
                                    handleChange(e)
                                  }
                                  } value={values.phone}></Input>
                                  <ErrorMessage name="phone" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DeptRegLang?.SelectDepartment[language]}</Label>
                                  <Select
                                    name="department"
                                    options={departmentList}
                                    value={dept}
                                    onChange={(department: any) => {
                                      setDept(department)
                                      setFieldValue("department", department?.value || '')
                                    }
                                    }
                                    isLoading={false}
                                    loadingMessage={() => "Fetching Department"}
                                    noOptionsMessage={() => "Departments appears here"}
                                    isSearchable={true}
                                    isMulti={false}
                                    isClearable
                                  />

                                  {/* <Input type="select" onChange={handleChange} name="department">
                                    <option value="">Select Department</option>
                                    {departmentList?.map((itm: any, key: number) => <option value={itm?.id} key={key} >{itm?.name}</option>)}
                                  </Input> */}
                                  <ErrorMessage name="department" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DeptRegLang?.Email[language]}</Label>
                                  <InputGroup>
                                    <Input type="email" name="email" disabled={otpVerified} onChange={handleChange} />
                                    <InputGroupText onClick={() => {
                                      setFieldTouched('phone')
                                      setFieldTouched('email')
                                      !otpVerified && !errors?.phone && handleEmail(values.email, values.phone, setFieldError)
                                    }} style={{ cursor: 'pointer' }} >
                                      {otpLoading ? <>
                                        <Spinner
                                          className="scanner"
                                          as="span"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          animation="border" />
                                      </> :
                                        <>
                                          <label style={{ cursor: 'pointer' }}>{isSent ? 'Resend OTP' : 'Send OTP'}</label>
                                          <i className="bi bi-arrow-right-square-fill ms-1 green-text"></i>
                                        </>}
                                    </InputGroupText>
                                  </InputGroup>
                                  <ErrorMessage name="email" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DeptRegLang?.OTP[language]}</Label>
                                  <Input type="text" onChange={(e) => {
                                    setFieldTouched('otp')
                                    handleChange(e)
                                    handleOtpCheck(e, values.email, setFieldError)
                                  }}
                                    disabled={otpVerified}
                                    name="otp">
                                  </Input>
                                  {otpChecking && <Spinner className="otp-scanner"
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border" />}
                                  <ErrorMessage name="otp" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DeptRegLang?.Password[language]}</Label>
                                  <InputGroup>
                                    <Input type="password" name="password1" onChange={handleChange} />
                                  </InputGroup>
                                  <ErrorMessage name="password1" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <div className="custom-input">
                                  <Label>{DeptRegLang?.ConfirmPassword[language]}</Label>
                                  <InputGroup>
                                    <Input type={passwordShow ? "text" : "password"} name="password2" onChange={handleChange} />
                                    <InputGroupText onClick={() => setPasswordShow(!passwordShow)}>
                                      {
                                        !passwordShow ?
                                          <i className="bi bi-eye"></i>
                                          :
                                          <i className="bi bi-eye-slash"></i>
                                      }
                                    </InputGroupText>
                                  </InputGroup>
                                  <ErrorMessage name="password2" component={TextError} />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row >
                          <Col sm={12}>
  <div className="agree-checkbox-wrap my-3">
    <input
      type="checkbox"
      id="termsCheckbox"
      disabled={
        !values.name ||
        !values.phone ||
        !values.department ||
        !values.email ||
        !values.otp ||
        !values.password1 ||
        !values.password2
      }
      className="form-check-input"
    />
    <label htmlFor="termsCheckbox" className="form-check-label h6">
    {DeptRegLang?.please_confirm?.[language]} 
    </label>
  </div>
</Col>
                            <Col sm={12}>
                              <h6 className="mb-4">{DeptRegLang?.Alreadyhaveanaccount[language]} ? <Link to='/login' className="blue-text">{DeptRegLang?.Login[language]} </Link></h6>
                            </Col>
                            <Col sm={12}>
                              <Button className='btn btn-primary mobile-button'
                                // disabled={!otpVerified || loading}
                                color='primary' type="submit">{DeptRegLang?.Register[language]}</Button>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className="language-btn-group left-align mt-3">
                    <Link to=""  onClick={()=>setLanguage('en')}>English</Link>
                    <div className="divider"></div>
                    <Link to="" onClick={() => setLanguage('ka')}>ಕನ್ನಡ</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
