export const Admin_tds:any = {
    //Admin\TDS\Modal\UploadTdsToVendor
    UploadTDSDetail: {
        en: 'Upload TDS Detail',
        ka: 'ಟಿಡಿಎಸ್ ವಿವರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ'
    },

    ChooseDocuments: {
        en: 'Choose Documents',
        ka: 'ಆಯ್ಕೆ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಆರಿಸಿ'
    },

    TDSFromDepartments: {
        en: 'TDS From Departments',
        ka: 'ವಿಭಾಗಗಳಿಂದ ಟಿಡಿಎಸ್'
    },

    Dashboard: {
        en: ' Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },

    TDS: {
        en: 'TDS',
        ka: 'ಟಿ ಡಿ ಎಸ್'
    },

    Product: {
        en: 'Product',
        ka: 'ಉತ್ಪನ್ನ'
    },

    Morethanoneorder: {
        en: 'More than one order',
        ka: 'ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ಆದೇಶ'
    },

    ClicktoseetheOrderlist: {
        en: 'Click to see the Order list',
        ka: 'ಆದೇಶ ಪಟ್ಟಿಯನ್ನು ನೋಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ'
    },

    Unitprice: {
        en: 'Unit price',
        ka: 'ಘಟಕ ಬೆಲೆ'
    },

    OrderedDate: {
        en: 'Ordered Date',
        ka: 'ಆದೇಶಿಸಿದ ದಿನಾಂಕ'
    },

    //Admin\TDS\TDSTable

    SlNo: {
        en: 'Sl No',
        ka: 'ಕ್ರ.ಸಂ'
    },

    OrderID: {
        en: 'Order ID',
        ka: 'ಆರ್ಡರ್ ಐಡಿ'
    },
    ProductNameOrder: {
        en: 'Order ID/Product Name',
        ka: 'ಆರ್ಡರ್ ಐಡಿ/ಉತ್ಪಾದನ ಹೆಸರು'
    },


    TenderID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },

    OrderDetails: {
        en: 'Order Details',
        ka: 'ಆರ್ಡರ್ ವಿವರಗಳು'
    },

    ShippingAddress:{
        en: 'Shipping Address',
        ka: 'ಶಿಪಿಂಗ್ ವಿಳಾಸ'
    },

    Date:{
        en: 'Date',
        ka: 'ದಿನಾಂಕ'
    },

    Quantity:{
        en: 'Quantity',
        ka: 'ಪರಿಮಾಣ'
    },

    Total: {
        en: 'Total',
        ka: 'ಒಟ್ಟು'
    },
    Action:{
        en:'Action',
        ka:'ಕ್ರಿಯೆ'
    },
    Approved: {
        en: 'Approved',
        ka: 'ಅನುಮೋದಿಸಲಾಗಿದೆ'
    },
    Rejected: {
        en: 'Rejected',
        ka: 'ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
    },
    NotUploaded: {
        en: 'Not Uploaded',
        ka: 'ಅಪ್‌ಲೋಡ್ ಆಗಿಲ್ಲ'
    },

    NoOrdersFound:{
        en:'No Orders Found',
        ka:'ಕಯಾವುದೇ ಆದೇಶಗಳು ಕಂಡುಬಂದಿಲ್ಲ'
    },

    //Admin\TDS\TDSToVendor

    VendorName:{
        en:'Vendor Name',
        ka:'ವೆಂಡರ್ ಹೆಸರು'
    },

    BuildingNo:{
        en:'Building No',
        ka:'ಕಟ್ಟಡ ಸಂಖ್ಯೆ'
    },

    Pincode:{
        en:'Pincode',
        ka:'ಪಿನ್ಕೋಡ್'
    },

    Department:{
        en: 'Department',
        ka: 'ಇಲಾಖೆ'
    },
 
    Actions:{
        en:'Actions',
        ka:'ಕ್ರಿಯೆಗಳು'
    },

    UploadTDS:{
        en:'Upload TDS',
        ka:'ಅಪ್‌ಲೋಡ್ ಮಾಡಿ ಟಿ ಡಿಎ ಸ್'
    },

    Showing:{
        en: 'Showing',
        ka: 'ತೋರಿಸಲಾಗುತ್ತಿದೆ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ನ'
    },
    entries:{
        en: 'entries',
        ka:'ನಮೂದುಗಳು'
    },

}
