import React, { useEffect, useContext  } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";

import DatePicker from "react-date-picker";

import { Context } from "../../../../Components/Context/Context";
import { Vendor_Settings_ReuploadModal } from "../../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";

import axios from "axios";
import { toast } from "react-toastify";
import { DOCS_URL } from "../../../../Library/ApiSettings";
import TextError from "../../../../Components/Errors/TextError";
import ModalContainer from "../../../../Components/Modal/ModalContainer";

const DOCUMENT_URL = DOCS_URL;


export default function AnyOthercomapnyDocumentsReuploadModal(props: any) {
  const {language,setLanguage}=useContext(Context)
  const fetchLocation = (pinCode: any) => {
    if (pinCode.length > 5) {
      axios
        .get(`https://api.postalpincode.in/pincode/${pinCode}`)
        .then((response: any) => {
          let v = response?.data[0]?.PostOffice?.[0];
          props?.setFieldValue("any_other_company_district", v?.District);
          props?.setFieldValue("any_other_company_state", v?.State);
          props?.setFieldValue("any_other_company_city", v?.Name);
        })
        .catch((error) => {
          toast.error("Please Enter a Valid Pincode");
        });
    }
  };

  const handleFileChange = (e: any, values?: any) => {
    props?.setFieldValue("any_other_company_documents", e.target.files[0]);
  };
  return (
    <>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Document Reupload"
      >
        <Form className="form-wrap" onSubmit={props?.handleSubmit}>
          <Row>
            <Col md={6} sm={6}>
              <FormGroup>
                <Label>{Vendor_Settings_ReuploadModal?.Any_Other_Office_Proof?.[language]}</Label>
                <div className="image-input">
                  <input
                    type="file"
                    ref={props?.oemRef}
                    id="any_other_company_documents"
                    name="any_other_company_documents"
                    className="form-control"
                    accept=".pdf"
                    onChange={(e: any) =>
                      handleFileChange(e, e.target.files[0])
                    }
                    disabled={props?.list > 0 ? true : false}
                  ></input>
                  <Label
                    className="image-button"
                    for="any_other_company_documents"
                    id="any_other_company_documents"
                  >
                    {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}
                  </Label>
                </div>
                <ErrorMessage
                  name="any_other_company_documents"
                  component={TextError}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <div className="custom-input">
                  <Label>
                    {" "}
                    {Vendor_Settings_ReuploadModal?.Company_Name?.[language]} {" "}
                    <span className="text-danger" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </Label>
                  <Input
                    name="any_other_company_name"
                    type="text"
                    value={props?.values?.any_other_company_name}
                    onChange={props?.handleChange}
                  />
                </div>
                <ErrorMessage
                  name="any_other_company_name"
                  component={TextError}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <div className="custom-input">
                  <Label>
                    {" "}
                    {Vendor_Settings_ReuploadModal?.Company_Email?.[language]} 
                    <span className="text-danger" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </Label>
                  <Input
                    name="any_other_company_email"
                    type="text"
                    value={props?.values?.any_other_company_email}
                    onChange={props?.handleChange}
                  />
                </div>
                <ErrorMessage
                  name="any_other_company_email"
                  component={TextError}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <div className="custom-input">
                  <Label>
                  {Vendor_Settings_ReuploadModal?.Pincode?.[language]}  
                    <span className="text-danger" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </Label>
                  <Input
                    name="any_other_company_pincode"
                    type="text"
                    value={props?.values?.any_other_company_pincode}
                    onChange={(e) => {
                      props?.handleChange(e);
                      fetchLocation(e.target.value);
                    }}
                  />
                </div>
                <ErrorMessage
                  name="any_other_company_pincode"
                  component={TextError}
                />
              </FormGroup>
            </Col>

            <Col sm={6}>
              <FormGroup>
                <div className="custom-input">
                  <Label> {Vendor_Settings_ReuploadModal?.City?.[language]}</Label>
                  <Input
                    name="any_other_company_city"
                    type="text"
                    value={props?.values?.any_other_company_city}
                    onChange={props?.handleChange}
                    disabled
                  />
                </div>
                {/* <ErrorMessage name="village_land" component={TextError} /> */}
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <div className="custom-input">
                  <Label> {Vendor_Settings_ReuploadModal?.District?.[language]}</Label>
                  <Input
                    name="any_other_company_district"
                    type="text"
                    value={props?.values?.any_other_company_district}
                    onChange={props?.handleChange}
                    disabled
                  />
                </div>
                {/* <ErrorMessage name="village_land" component={TextError} /> */}
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <div className="custom-input">
                  <Label>{Vendor_Settings_ReuploadModal?.State?.[language]}</Label>
                  <Input
                    name="any_other_company_state"
                    type="text"
                    value={props?.values?.any_other_company_state}
                    onChange={props?.handleChange}
                    disabled
                  />
                </div>
                {/* <ErrorMessage name="village_land" component={TextError} /> */}
              </FormGroup>
            </Col>
            {/* <Col sm={12} className="mt-2">
              {" "}
              {props?.values?.any_other_company_state == "Karnataka" &&
              props?.values?.any_other_company_documents ? (
                <>
                  <span className="text-success mark-end">Mark:10/-</span>
                </>
              ) : null}
            </Col> */}
          </Row>
          <Row className="mt-3">
            <Col sm={12} className="text-end">
              <Button
                className="btn btn-primary mb-5"
                color="primary"
                type="submit"
              >
                 {Vendor_Settings_ReuploadModal?.Submit?.[language]}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalContainer>
    </>
  );
}
