// import { useState, useEffect } from "react";
// import { useLocation } from "react-router";
// import { Navigate, Outlet } from "react-router-dom";
// import Layout from "./../../Containers/Common/Layout";

// interface PrivateRouteProps {
//   loginStatus: boolean;
// }

// const ProtectedRoutes = (loginStatus: PrivateRouteProps) => {
//   const [authUser, setAuthUser] = useState(true);
//   useEffect(() => {
//     if (localStorage.getItem("AUTH_TOKEN") !== null) {
//       setAuthUser(true);
//     } else {
//       setAuthUser(false);
//     }
//   }, [loginStatus]);
//   const location = useLocation();
//   const role_id = localStorage.getItem("ROLE_ID")

//   return authUser ? (
//     <>
//       {/* {role_id == "4" ?
//         <Outlet />
//         :
//         <Layout>
//           <Outlet />
//         </Layout>
//       } */}
//     </>
//   ) : (
//     <Navigate to="/login" replace state={{ from: location }} />
//   );
// };

// export default ProtectedRoutes;

import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "./../../Containers/Common/Layout";

interface PrivateRouteProps {
  loginStatus: boolean;
}

const ProtectedRoutes = (loginStatus: PrivateRouteProps) => {
  const [authUser, setAuthUser] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("AUTH_TOKEN") !== null) {
      setAuthUser(true);
    } else {
      setAuthUser(false);
    }
  }, [loginStatus]);
  const location = useLocation();
  const role_id = localStorage.getItem("ROLE_ID");
  const [value, setValue] = useState({
    admin: "",
    vendor_not_login: "",
    vendor_logined: "",
  });
  const profile_status = localStorage.getItem("PROFILE_STATUS");
  const profile_status_not = 4;
  useEffect(() => {
    route_cond();
  }, [role_id, profile_status, profile_status_not]);
  const route_cond = () => {
    if (role_id == "1" || role_id == "2" || role_id == "3" || role_id == "5" || role_id == "6" || role_id == "7" || role_id == "8") {
      setValue({
        ...value,
        admin: "1",
      });
    }
    if (role_id == "4") {
      setValue({
        ...value,
        vendor_logined: "1",
      });
    }
  };

  return authUser ? (
    <>
      {value?.admin == "1" && (
        <Layout>
          <Outlet />
        </Layout>
      )}
      {value?.vendor_logined == "1" && (
        <Layout>
          <Outlet />
        </Layout>
      )}
    </>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
