import React, { useState, useContext } from 'react'
import FaqEng from './English/Faq';
import FaqKanada from './Kanada/Faq';
import { Context } from '../../Components/Context/Context';
export default function Faqs() {
    // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
    const { language, setLanguage } = useContext(Context);
    return (
        language == "en" ? <FaqEng/> : <FaqKanada/>
    )
}
