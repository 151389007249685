import React, { useEffect, useState,useContext } from "react"
import { toast } from "react-toastify"
import { Button, FormGroup, Input, Label } from "reactstrap"
import { api } from "../../../../Library/CustomApi"
import Loader from "../../../../Components/Loader/Loader"
import { capitalizeFirst } from "../../../../Library/Constants/helpers"
import moment from "moment"
import axios from "axios"
import { API_URL } from "../../../../Library/ApiSettings"
import { Link } from "react-router-dom"
import { Department_Orders } from "../../../../Library/LanguageConversion/Department_Orders";
import { Context } from "../../../../Components/Context/Context";
interface propType {
    allData: any,
    vendorId: any,
    setFeedbackModalOpen?: any
}

const Feedback = ({ allData, vendorId, setFeedbackModalOpen }: propType) => {
    const maxStars: number = 5
    const [rating, setRating] = useState<any>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [feedback, setFeedback] = useState<string>('')
    const [pro_data, setProData] = useState<any>([]);
    const [file, setFile] = useState<any>(null);
    const {language,setLanguage}=useContext(Context);


    useEffect(() => {
        getFeedback()
    }, [])

    // submits feedback 
    const submitHandler = (e: any) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = new FormData();
        formData.append('product_id', allData?.order_details?.product_id);
        formData.append('vendor_id', vendorId);
        formData.append('star', rating);
        formData.append('message', feedback);
        formData.append('order_id', allData?.id);
        formData.append('wou', file)

        axios({
            method: "post",
            url: `${API_URL}/product/productfeedback`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            },
        })
            .then(function (response) {
                if (response?.status == 200) {
                    toast.success("Feedback added Successfully!");
                    setFeedback('')
                    setRating(0)
                    getFeedback()
                    setFeedbackModalOpen(false)
                    setIsLoading(false)
                }
                return response;
            })
            .catch(function (response) {
                if (response?.err?.response?.status == 422) {
                    setIsLoading(false)
                    toast.error(response?.err?.response?.data?.message);
                }
                console.log(response?.err);
            });
    }

    // get feedback 
    const getFeedback = () => {
        setIsLoading(true)
        api
            .get(`/product/orderFeedback?order_id=${allData?.id}`, true)
            .then(async function ([success, response]) {
                setProData(response?.data?.feedback || []);
                setIsLoading(false)
                return response;
            })
            .catch((err) => {
                setIsLoading(false)
                console.log(err);
            });
    };

    // work order completion upload 
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files ? event.target.files[0] : null;
        setFile(selectedFile);
    };

    return (
        <>
            {/* list feedback  */}
            {pro_data?.length !== 0 && !isLoading && <div className="feeback-outer-wrap">
                <div className="feedback-main-heading"></div>
                <div className="feedback-wrap">
                    <div className="feedback-details ">
                        <>
                            {pro_data?.map((item: any, i: any) => {
                                
                                return (
                                    <React.Fragment key={i}>
                                       {Department_Orders?.ProductName[language]} : {item?.get_product_details[0]?.name}
                                        {/* </h6> */}
                                        <p>
                                        {Department_Orders?.Message[language]} :{" "}
                                            {item?.message ? (
                                                item?.message
                                            ) : (
                                                <span className="text-danger">
                                                    {Department_Orders?.NoMessageAdded[language]}
                                                </span>
                                            )}
                                        </p>
                                        <small className="date">
                                        {Department_Orders?.Updatedat[language]}{" "}
                                            <span className="date-span">
                                                {moment(item?.updated_at).format("LLLL")}
                                            </span>
                                        </small>
                                        <div className="rating-wrap">
                                            <div className="rating-stars">
                                                {Array.from({ length: maxStars }, (_, i) => (
                                                    <span key={i}>
                                                        {item.star > i ? (
                                                            <i className="bi bi-star-fill"></i>
                                                        ) : (
                                                            <i className="bi bi-star"></i>
                                                        )}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>

                                        {/* work completion certificate view  */}
                                        <div>
                                            {item?.get_delivered_docs?.map((obj: any, i: number) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        {obj.type === 5 && 
                                                        <div className="certificate-view">   
                                                            <Link to={obj?.wo_doc} target="blank_attribute" > {Department_Orders?.ViewWorkCompletionCertificate[language]}</Link>
                                                        </div>}
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                );
                            })}
                        </>
                    </div>
                </div>
            </div>}

            {/* add feedback  */}
            {allData?.order_details?.product_id && pro_data?.length == 0 && !isLoading &&
                <div className="feed-back-tab-content">
                    <form onSubmit={submitHandler}>
                    <label>{Department_Orders?.WorkCompletionCertificate[language]}:</label>
                        <div className="image-input px-2">
                            <Input
                                type="file"
                                name="work-completion"
                                id="work-completion"
                                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                className="form-control"
                                onChange={handleFileChange}
                                title='Upload Work Completion Certificate'
                            />
                            <Label className="image-button" for="work-completion" id="work-completion">{Department_Orders?.ChooseDocuments[language]}</Label>
                        </div>
                        <h6 className="feeback-heading">{Department_Orders?.RateYourOrder[language]}</h6>
                        <small className="mb-4">{Department_Orders?.RatingReview[language]}</small>
                        <div className="rating-wrap">
                            {/* star rating  */}
                            <div className="rating-wrap-inner">
                                {Array.from({ length: maxStars }, (_, i) => (
                                    <span key={i} onClick={() => setRating(i + 1)} style={{ cursor: 'pointer' }}>
                                        {rating > i ? <i className="bi bi-star-fill"></i> : <i className="bi bi-star"></i>}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <FormGroup>
                            <Label className="text-start">{Department_Orders?.YourFeedback[language]}</Label>
                            <Input value={feedback} type="textarea" onChange={(e: any) => setFeedback(e.target.value)}></Input>
                        </FormGroup>
                        <div className="text-end">
                            <Button type="submit" color="primary" disabled={!rating || !feedback} className="btn btn-primary">{Department_Orders?.Submit[language]}</Button>
                        </div>
                    </form>
                </div>}
            {isLoading &&
                <div className="text-center pt-5">
                    <Loader />
                </div>
            }
        </>
    )
}

export default Feedback