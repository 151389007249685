import * as Yup from "yup";
const today = new Date();
const AssignValidationSchema = Yup.object().shape({
  // low_1: Yup.object().required("Low 1 is required"),
  low_1: Yup.object()
    .nullable()
    .required("Low 1 is required")
    .typeError("Low 1 must be an object"),
  low_1_make: Yup.object()
    .nullable()
    .required("Low 1 Make is required")
    .typeError("Low 1 Make be an object"),

  low_1_model: Yup.object()
    .nullable()
    .required("Low 1 Model is required")
    .typeError("Low 1 Model be an object"),

  low_2_make: Yup.object().when("low_2", {
    is: (val: any) => val !== undefined && val !== null,
    then: Yup.object()
      .nullable()
      .required("Low 2 Make is required")
      .typeError("Low 2 Make be an object"),
    otherwise: Yup.object().nullable().typeError("Low 2 Make be an object"),
  }),

  low_2_model: Yup.object().when("low_2", {
    is: (val: any) => val !== undefined && val !== null,
    then: Yup.object()
      .nullable()
      .required("Low 2 Model is required")
      .typeError("Low 2 Model be an object"),
    otherwise: Yup.object().nullable().typeError("Low 2 Model be an object"),
  }),
  low_3_make: Yup.object().when("low_3", {
    is: (val: any) => val !== undefined && val !== null,
    then: Yup.object()
      .nullable()
      .required("Low 3 Make is required")
      .typeError("Low 3 Make be an object"),
    otherwise: Yup.object().nullable().typeError("Low 3 Make be an object"),
  }),
  low_3_model: Yup.object().when("low_3", {
    is: (val: any) => val !== undefined && val !== null,
    then: Yup.object()
      .nullable()
      .required("Low 3 Model is required")
      .typeError("Low 3 Model be an object"),
    otherwise: Yup.object().nullable().typeError("Low 3 Model be an object"),
  }),
  price: Yup.string().required("Price is required"),
  Onsite_warranty: Yup.string().required("On site warranty is required"),
  service_warranty: Yup.string().required("Service warranty is required"),
  hardware_warranty: Yup.string().required("Hardware Warranty is required"),
});
export default AssignValidationSchema;
