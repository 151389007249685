import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState, useContext } from "react";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";
import { api } from "../../../Library/CustomApi";

interface proptype {
    isShowing: boolean;
    setIsShowing: Function;
    handleSubmit: any;
    values: any;
    handleChange: any;
    errors: any;
    touched: any;
    isSubmitting: any;
    setFieldValue: any;
    setValue: any;
    value: any;
    handleReset: any;
    amount: any;
    setMethod: any;
    setFieldTouched: any
}
const ChellanModal = (props: proptype) => {
    const [images, setImages] = useState<string[]>([]);
    const [date, setDate] = useState({
        payment_date: ""
    })
    const [file_name, setFileName] = useState("")
    const { language, setLanguage } = useContext(Context);

    useEffect(() => {
        getBank();

    }, [props?.values?.ifsc_code]);

    const getBank = () => {
        if (props?.values?.ifsc_code?.length == 11) {
            api
                .post(`/getifscdetails`, { ifsc_code: props?.values?.ifsc_code }, true)
                .then(async function ([success, response]) {
                    props?.setFieldValue("bank_name", response?.data?.BANK);
                    props?.setFieldValue("branch_name", response?.data?.BRANCH);
                    props?.setFieldTouched("bank_name", false, false);
                    props?.setFieldTouched("branch_name", false, false)


                    return response;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleFilechange = (e: any, values: any) => {
        const myMemoObj = URL.createObjectURL(e.target.files[0]);
        setImages([...images, myMemoObj]);
        props?.setFieldValue("file", e.target.files[0]);
        setFileName(e.target.files[0]?.name)
    }
    const handleDeletes = (img: any, indexs: number) => {
        props?.setFieldValue(
            "file",
            props?.values.file
        );
        const s1 = images?.filter((key, index) => index !== indexs);
        setImages(s1);
    };



    // console.log(date?.payment_date,"date?.payment_date")
    return (<>
        <React.Fragment>
            <ModalContainer
                open={props?.isShowing}
                setOpen={() => {
                    props?.setIsShowing(false);
                    props?.handleReset();
                    setImages([]);
                    props?.setMethod("")
                }}
                header='Challan Upload'
            >
                {/* <div className="upload-prod-outer">
                    <Form className="form-wrap">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.upload_the_documents[language]}{" "}
                                        <i
                                            className="fa fa-asterisk required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <div className="image-upload-input">
                                        <Input
                                            type="file"
                                            name="file"
                                            accept=".pdf"
                                            //disabled={props?.show_add == true ? false : true}
                                            title=""
                                            multiple
                                            onChange={(e: any) =>
                                                handleFilechange(e, e.target.files[0])

                                            }
                                            className={`form-control ${props?.touched.file && props?.errors.file
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <span className="upload-text">{VendorRegLang?.upload_the_documents[language]}{file_name}</span>
                                    </div>
                                    <ErrorMessage name="file" component={TextError} />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <div className="display-upload-image-wrap">
                                    {images?.length > 0 &&
                                        images.map((item, index, key) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="display-image" key={index}>
                                                        <i
                                                            className="fa-solid fa-xmark "
                                                            onClick={() => handleDeletes(item, index)}
                                                            title="Remove photo"
                                                        ></i>
                                                        <embed src={item} type='application/pdf' className="img-fluid " />
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            </Col>
                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.receipt_number[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="receipt_number"
                                        type="text"
                                        value={props?.values?.receipt_number}
                                        onChange={props?.handleChange}

                                        className={`form-control ${props?.touched.receipt_number &&
                                            props?.errors.receipt_number
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="receipt_number" component={TextError} />
                                </FormGroup>
                            </Col>

                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.payment_site[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="payment_site"
                                        type="text"
                                        value={props?.values?.payment_site}
                                        onChange={props?.handleChange}
                                        className={`form-control ${props?.touched.payment_site &&
                                            props?.errors.payment_site
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="payment_site" component={TextError} />
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.payment_date[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>

                                    <DatePicker
                                        value={props?.values?.payment_date}
                                        onChange={(e: any) => {
                                            props?.setFieldValue("payment_date", e);
                                        }}
                                        name="payment_date"
                                        format={"dd-MM-y"}
                                        dayPlaceholder={"dd"}
                                        monthPlaceholder={"mm"}
                                        yearPlaceholder={"yyyy"}
                                        calendarClassName={`form-control ${props?.touched.payment_date &&
                                            props?.errors.payment_date
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        maxDate={new Date()}
                                    />
                                    <ErrorMessage name="payment_date" component={TextError} />
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.bsr_code[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="bsr_code"
                                        type="text"
                                        value={props?.values?.bsr_code}
                                        onChange={props?.handleChange}

                                        className={`form-control ${props?.touched.bsr_code &&
                                            props?.errors.bsr_code
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="bsr_code" component={TextError} />
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.challan_number[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="chellan_number"
                                        type="text"
                                        value={props?.values?.chellan_number}
                                        onChange={props?.handleChange}

                                        className={`form-control ${props?.touched.chellan_number &&
                                            props?.errors.chellan_number
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="chellan_number" component={TextError} />
                                </FormGroup>
                            </Col>

                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.amount[language]}(₹){" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="amount_data"
                                        type="number"
                                        value={props?.amount}
                                        onChange={props?.handleChange}
                                        disabled
                                        className={`form-control ${props?.touched.amount_data &&
                                            props?.errors.amount_data
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="amount_data" component={TextError} />
                                </FormGroup>
                            </Col>

                            <div className="button-flex">
                                <FormGroup>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        onClick={props?.handleSubmit}
                                        className={"px-5 mt-5"}
                                        disabled={props?.isSubmitting}
                                    >
                                        {VendorRegLang?.submit[language]}
                                    </Button>
                                </FormGroup>
                            </div>
                        </Row>
                    </Form>
                </div> */}

                <div className="upload-prod-outer">
                    <Form className="form-wrap">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>
                                        {/* Upload the Documnte(s) */}
                                        {VendorRegLang?.upload_the_documents[language]}{" "}
                                        <i
                                            className="fa fa-asterisk required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <div className="image-upload-input">
                                        <Input
                                            type="file"
                                            name="file"
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            //disabled={props?.show_add == true ? false : true}
                                            title=""
                                            multiple
                                            onChange={(e: any) =>
                                                handleFilechange(e, e.target.files[0])
                                            }
                                            className={`form-control ${props?.touched.file && props?.errors.file
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <span className="upload-text">
                                            {/* Upload the Documnte(s) */}
                                            {VendorRegLang?.upload_the_documents[language]}(s){" "}
                                        </span>
                                    </div>
                                    <ErrorMessage name="file" component={TextError} />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <div className="display-upload-image-wrap">
                                    {images?.length > 0 &&
                                        images.map((item, index, key) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="display-image" key={index}>
                                                        <i
                                                            className="fa-solid fa-xmark "
                                                            onClick={() => handleDeletes(item, index)}
                                                            title="Remove photo"
                                                        ></i>
                                                        <embed
                                                            src={item}
                                                            type="application/pdf"
                                                            className="img-fluid "
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            </Col>
                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.ifsc_code[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="ifsc_code"
                                        type="text"
                                        value={props?.values?.ifsc_code}
                                        onChange={props?.handleChange}
                                        className={`form-control ${props?.touched.ifsc_code && props?.errors.ifsc_code
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="ifsc_code" component={TextError} />
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.bank_name[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="bank_name"
                                        type="text"
                                        value={props?.values?.bank_name}
                                        onChange={props?.handleChange}
                                        className={`form-control ${props?.touched.bank_name && props?.errors.bank_name
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="bank_name" component={TextError} />
                                </FormGroup>
                            </Col>

                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.branch_name[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="branch_name"
                                        type="text"
                                        value={props?.values?.branch_name}
                                        onChange={props?.handleChange}
                                        className={`form-control ${props?.touched.branch_name && props?.errors.branch_name
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="branch_name" component={TextError} />
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.account_number[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="account_number"
                                        type="text"
                                        value={props?.values?.account_number}
                                        onChange={props?.handleChange}
                                        className={`form-control ${props?.touched.account_number &&
                                            props?.errors.account_number
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="account_number" component={TextError} />
                                </FormGroup>
                            </Col>



                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.account_holder_name[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="account_holder_name"
                                        type="text"
                                        value={props?.values?.account_holder_name}
                                        onChange={props?.handleChange}
                                        className={`form-control ${props?.touched.account_holder_name &&
                                            props?.errors.account_holder_name
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name="account_holder_name"
                                        component={TextError}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.demand_draft_number[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="chellan_number"
                                        type="text"
                                        value={props?.values?.chellan_number}
                                        onChange={props?.handleChange}

                                        className={`form-control ${props?.touched.chellan_number &&
                                            props?.errors.chellan_number
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="chellan_number" component={TextError} />
                                </FormGroup>
                            </Col>


                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.amount[language]}(₹){" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    <Input
                                        name="amount_data"
                                        type="number"
                                        value={props?.amount}
                                        onChange={props?.handleChange}
                                        disabled
                                        className={`form-control ${props?.touched.amount_data && props?.errors.amount_data
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage name="amount_data" component={TextError} />
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={6}>
                                <FormGroup>
                                    <Label>
                                        {VendorRegLang?.payment_date[language]}{" "}
                                        <i
                                            className="fa fa-asterisk fa-1 required-field"
                                            color="text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </Label>
                                    {/* <Input
                                            name="payment_date"
                                            type="date"
                                            placeholderText={"dd-mm-yyyy"}
                                            dateFormat="dd-MM-yyyy"
                                            value={props?.values?.payment_date}
                                            onChange={props?.handleChange}

                                            className={`form-control ${props?.touched.payment_date &&
                                                props?.errors.payment_date
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        /> */}
                                    <DatePicker
                                        value={props?.values?.payment_date}
                                        onChange={(e: any) => {
                                            props?.setFieldValue("payment_date", e);
                                        }}
                                        name="payment_date"
                                        format={"dd-MM-y"}
                                        dayPlaceholder={"dd"}
                                        monthPlaceholder={"mm"}
                                        yearPlaceholder={"yyyy"}
                                        calendarClassName={`form-control ${props?.touched.payment_date &&
                                            props?.errors.payment_date
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        maxDate={new Date()}
                                    // disabled={list > 0 ? true : false}
                                    />
                                    <ErrorMessage name="payment_date" component={TextError} />
                                </FormGroup>
                            </Col>
                            <div className="button-flex">
                                <FormGroup>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        onClick={props?.handleSubmit}
                                        className={"px-5 mt-5"}
                                        disabled={props?.isSubmitting}
                                    >
                                        {VendorRegLang?.submit[language]}
                                    </Button>
                                </FormGroup>
                            </div>
                        </Row>
                    </Form>
                </div>
            </ModalContainer>
        </React.Fragment>
    </>)
}

export default ChellanModal;