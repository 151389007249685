import React, { useState, useEffect,useContext } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";
import CustomPagination from "../../../Components/CustomPagination/Index";
import {
    Button,
    List,
    Table,
} from 'reactstrap'
import ModalContainer from '../../../Components/Modal/ModalContainer';
import AddAccountModal from './AddAccountModal';
import { api } from '../../../Library/CustomApi';
import { toast } from 'react-toastify';
import Loader from '../../../Components/Loader/Loader';
import { ACCOUNT_TYPE } from '../../../Library/Constants/MapConstants';
import {Admin_accountlist} from '../../../Library/LanguageConversion/Admin_accountlist';
import { Context } from '../../../Components/Context/Context';

const AccountsList = () => {
    const { pathname } = useLocation()
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [accountsData, setAccountsData] = useState<any>([])
    const [pageData, setPageData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number | string>(1)
    const [searchVal, setSearchVal] = useState<string>('')
    const { language, setLanguage } = useContext(Context);

    useEffect(() => {
        getAccounts()
    }, [currentPage])
   

    const getAccounts = () => {
        setLoading(true);
        api
            .post(
                `/user_det?page=${currentPage}`,
                {
                    role_id: '[6,7,8]'
                },
                true
            )
            .then(async function ([success, response, status]) {
                setAccountsData(response?.data)
                setPageData(response?.meta)
                setLoading(false)
                return response;
            })
            .catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error(err?.response?.data?.message);
                }
                setLoading(false)
                console.log(err);
            });
    }
    return (
        <>
            <div className="right-content-wrap">
                <div className="breadcrumb-tabs-outer-wrap">
                    {/* breacrumb */}
                    <List className="breadcrumb">
                        <li><Link className="active" to="/dashboard">{Admin_accountlist?.Dashboard?.[language]}</Link></li>
                        &nbsp; / &nbsp;
                        <li>
                            <b>{
                                pathname == "/accounts" ? Admin_accountlist?.Account?.[language] : "Accounts"
                            }</b>
                        </li>
                    </List>
                </div>
                <div className="custom-tab-content">
                    <div className="table-outer-wrap">
                        <div className="search-widget-outer">
                            <div className="add-button text-end">
                                <Button
                                    title='Add Account'
                                    className="primary-btn" color="primary"
                                    onClick={() => setOpenModal(true)}
                                >
                                    <span className="large-screen-view"> <i className="bi bi-plus" />
                                    {Admin_accountlist?.AddAccount?.[language]}</span>
                                    <span className="mobile-view"><i className="bi bi-plus-lg"></i></span>
                                </Button>
                            </div>
                        </div>
                        <div className="table-wrap responsive-table">
                            <Table className="table-striped">
                                <thead>
                                    <tr>
                                    <th>{Admin_accountlist?.SlNo?.[language]}</th>
                                        <th>{Admin_accountlist?.Name?.[language]}</th>
                                        <th>{Admin_accountlist?.Phone?.[language]}</th>
                                        <th>{Admin_accountlist?.Mail_Id?.[language]}</th>
                                        <th>{Admin_accountlist?.AccountType?.[language]}</th>
                                        <th>{Admin_accountlist?.Status?.[language]}</th>
                                    </tr>
                                </thead>
                                <tbody>{
                                    (accountsData?.length !== 0 && !loading) ?
                                        accountsData?.map((it: any, i: number) => {
                                           
                                            return (
                                                <tr key={i}>
                                                    <td><span className="mobile-heading">{Admin_accountlist?.SlNo?.[language]}:</span>
                                                        <span className="table-data">{i + 1}</span>
                                                    </td>
                                                    <td><span className="mobile-heading">{Admin_accountlist?.Name?.[language]}:</span>
                                                        <span className="table-data">{it?.user_details?.full_name}</span>
                                                    </td>
                                                    <td><span className="mobile-heading">{Admin_accountlist?.Phone?.[language]}:</span>
                                                        <span className="table-data">{it?.phone}</span>
                                                    </td>
                                                    <td><span className="mobile-heading">{Admin_accountlist?.Mail_Id?.[language]}:</span>
                                                        <span className="table-data">{it?.email}</span>
                                                    </td>
                                                    <td><span className="mobile-heading">{Admin_accountlist?.Email?.[language]}:</span>
                                                        <span className="table-data">{(ACCOUNT_TYPE?.filter((itm: any) => itm?.value == it?.userroles[0].role_id))?.[0]?.name}</span>
                                                    </td>

                                                    <td>
                                                        <span className="mobile-heading">{Admin_accountlist?.Status?.[language]}:</span>
                                                        <span className="table-data">

                                                            {it?.status == 1 ?
                                                                <span className="approved-status">{Admin_accountlist?.Active?.[language]}</span>
                                                                :
                                                                <span className="rejected-status">{Admin_accountlist?.Inactive?.[language]}</span>}

                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                        :
                                        <tr>
                                            <td>{loading ? <Loader /> : 'No Accounts Found'}</td>
                                        </tr>
                                }

                                </tbody>
                            </Table>
                        </div>
                        {/* Table footer */}
                        {pageData && accountsData.length > 0 && <div className="table-footer-wrap">
                            <small>{Admin_accountlist?.Showing?.[language]} {pageData.from} {Admin_accountlist?.to?.[language]} {pageData.to}  {Admin_accountlist?.of?.[language]} {pageData?.total}{" "} {Admin_accountlist?.entries?.[language]}</small>
                            <div className="pagination-outer">
                                <CustomPagination
                                    totalItems={pageData?.total}
                                    itemsPerPage={pageData?.per_page}
                                    activePage={pageData?.current_page}
                                    setCurrentPage={setCurrentPage}
                                />
                                
                            </div>
                         </div>}
                    </div>
                </div>
            </div>

            {/* Modals  */}
            <ModalContainer open={openModal} setOpen={setOpenModal} header='Add Account' >
                <AddAccountModal
                    setOpenModal={setOpenModal}
                    getAccounts={getAccounts}
                />
            </ModalContainer>
        </>
    );
}

export default AccountsList
