import React, { useEffect, useState,useContext } from "react"
import { api } from "../../../../Library/CustomApi"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import CustomPagination from "../../../../Components/CustomPagination/Index"
import ModalContainer from "../../../../Components/Modal/ModalContainer"
import UploadChellanModal from "../UploadChellanModal"
import Loader from "../../../../Components/Loader/Loader"
import constants from "../../../../Library/Constants/Constants"
import WorkOrderReUploadModal from "./WorkOrderReUploadModal/WorkOrderReUploadModal"
import axios from "axios"
import { API_URL } from "../../../../Library/ApiSettings"
import { Context } from "../../../../Components/Context/Context"
import { Department_drafts } from '../../../../Library/LanguageConversion/Department_drafts';


const WorkOrderTab = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedId, setSelectedId] = useState<string | null>()
    const [proformaData, setProformaData] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [currentPage, setCurrentPage] = useState<number | string>(1)
    const [pageData, setPageData] = useState<any>([])
    const {language,setLanguage}=useContext(Context);


    useEffect(() => {
        getProforma()
    }, [])
    useEffect(() => {
        getProforma()
    }, [currentPage])

    // get draft details 
    const getProforma = () => {
        setIsLoading(true)
        api
            .get(`/order/pf-invoice?status=${constants?.ORDERS?.WORKORDERDRAFT}&page=${currentPage}`, true)
            .then(async function ([success, response]) {
                setPageData(response?.meta)
                setProformaData(response?.data?.filter((obj: any) => {
                    return obj.order_status == constants?.ORDERS?.WORKORDERDRAFT;
                }) || []);
                setIsLoading(false)
                return response;
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            });
    };

    // to format time 
    function formatTime(timeString: any) {
        const [hourString, minute] = timeString.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }

    // Upload work order modal handler 
    const WOUploadModalhandler = (obj:string) => {
        setSelectedId(obj)
        setIsOpen(true)
    }

    // work order download handler 
    const WODownloadHandler = (obj:string) => {
        if (obj) {
            axios({
              url: `${API_URL}/order/purchase-order-download/${obj}`,
              method: 'GET',
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN")
              },
              responseType: 'blob', // important
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `Work-order-${proformaData?.order_id}.pdf`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }).catch(
              function (error) {
                console.log(error);
              }
            )
          }
          else {
            console.log('id error');
            return
          }
    }

    return (
        <>
            <div className="notifications-outer-wrap">
                <Row className="gy-3">
                    {
                        proformaData?.length !== 0 && !isLoading ?
                            <>
                                {
                                    proformaData?.map((obj: any, i: number) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Col md={12}>
                                                    <div className="notification-item">
                                                        <div className="notification-item-inner-wrap">
                                                            <div className="notification-details">
                                                                <h6>{Department_drafts?.Order_ID[language]}: <span>{obj.order_id}</span></h6>
                                                                {
                                                                    obj.order_details.map((itm: any, x: number) => {

                                                                        return (
                                                                            <React.Fragment key={x}>
                                                                                <div className="notify-inner">
                                                                                    <small ><i className="bi bi-check2-circle me-2"></i>{itm.name}</small><br /> &nbsp;  &nbsp;  &nbsp;
                                                                                    <small>{Department_drafts?.Quantity[language]}: {itm.quantity}</small><br /> &nbsp;  &nbsp;  &nbsp;
                                                                                    <small>{Department_drafts?.Price[language]}: ₹ {itm.total_amount}</small>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="notification-status">
                                                                <Link title="Download Work Order" onClick={()=>WODownloadHandler(obj?.id)} className="blue-text" to=''>{Department_drafts?.Download_Work_Order[language]}</Link>
                                                                <Link title="Reupload Work Order" onClick={()=>WOUploadModalhandler(obj?.id)} className="blue-text" to=''>{Department_drafts?.Reupload_Work_Order[language]}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </> :
                            isLoading ? <div className="text-center py-5"> <Loader /> </div>
                                :
                                <>
                                    <div className="text-center py-5">
                                        <p>{Department_drafts?.No_Work_Orders_Found[language]}</p>
                                    </div>
                                </>
                    }

                    {/* pagination  */}
                    {pageData && proformaData.length > 0 && < div className="table-footer-wrap">
                        <small>{Department_drafts?.Showing[language]}{pageData.from}{Department_drafts?.to[language]}{pageData.to} {Department_drafts?.of[language]} {pageData?.total}{Department_drafts?.entries[language]}</small>
                        <div className="pagination-outer">
                            <CustomPagination
                                totalItems={pageData?.total}
                                itemsPerPage={pageData?.per_page}
                                activePage={pageData?.current_page}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>}
                </Row>
            </div>
            {/* Upload Work Order Modal  */}
            <ModalContainer
                open={isOpen}
                setOpen={setIsOpen}
                header={Department_drafts?.Reupload_Meeting_Internal[language]}
            >
                <WorkOrderReUploadModal id={selectedId} setUploadWOModal={setIsOpen} />
            </ModalContainer>
        </>
    )
}

export default WorkOrderTab