import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Input, Label } from 'reactstrap'
import { api } from '../../../Library/CustomApi'
import { useNavigate } from 'react-router-dom'

interface propType {
    paymentMethod: string,
    id: number,
    datas: any
}
const PaymentGateWay = ({ paymentMethod, id, datas }: propType) => {
    const [checked, setChecked] = useState<boolean>(false)
    const [value, setValue] = useState({
        account_id: ""
    })
    const add_account = (e: any) => {
    }
    const navigate = useNavigate()
    useEffect(() => {
        api
            .get(
                `/order/pf-invoice/${id}`,

                true
            )
            .then(async function ([success, response]) {

                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])
    const HandlePaymentConfirm = () => {
        if (id) {
            api
                .patch(
                    `/order/pf-invoice/${id}`,
                    {
                        order_status: 0,
                    },
                    true
                )
                .then(async function ([success, response]) {
                    toast.success("Payment successful");
                    //navigate('/department/draft')
                    return response;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            console.log('id not found error');
        }
    }
    return (
        <div>
            <div className="account-details">
                <h6>{paymentMethod}</h6>
                {/* {
                    datas?.map((item: any) => {
                        let reg = /.{1,5}/;
                        let str = "888 525 636 456 422"
                        console.log(str.replace(reg, (m: any) => "X".repeat(m.length)));
                        return (<>

                            <Input type="radio" onClick={(e: any) => { add_account(e) }} id='confirm-payment' name="account" value={item?.id}>

                            </Input>

                            <small>Account Holder name: {item?.account_holder_name}</small>
                            <small>Bank name: {item?.bank_name}</small>
                            <small>Account Number:{str.replace(reg, (m: any) => "X".repeat(m.length))}</small>
                            <small>Branch: Branch Name</small>
                        </>)
                    })
                } */}

            </div>

            {/* <div className="submit-wrap">
                <div className="agree-checkbox-wrap">
                    <Input type="checkbox" onClick={(e: any) => { setChecked(e.target.checked) }} id='confirm-payment'></Input>
                    <Label htmlFor='confirm-payment'>
                        Confirm entered details are correct
                    </Label>
                </div>

                <Button color='primary' disabled={!checked} onClick={HandlePaymentConfirm} >Confirm Payment</Button>
                <small>Note: Please upload the challan from drafts</small>
            </div> */}
        </div>
    )
}

export default PaymentGateWay