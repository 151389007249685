import { PieChart, Pie, ResponsiveContainer } from "recharts";
interface Props {
  halfdata: any[];
}
const HalfCircleProgressBar: React.FC<Props> = ({ halfdata }) => {
  let v = halfdata[0]?.value || 0;
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart width={90} height={300}>
          <Pie
            data={halfdata}
            dataKey="value"
            startAngle={180}
            endAngle={0}
            innerRadius={80}
            outerRadius={100}
            fill="#169fdb "
            paddingAngle={0}
            // label={() => `${v} Vendor(s)`} // set the progress text here
            label={({ cx, cy, midAngle, innerRadius, outerRadius, value }) => (
              <text
                x={cx}
                y={cy - outerRadius - 35} // add 16 units of padding (1 rem = 16px)
                fill="#169FDB "
                textAnchor="middle"
                dominantBaseline="central"
              >
                {`${value} Vendor(s)`}
              </text>
            )}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};
export default HalfCircleProgressBar;
