import React,{useContext} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Admin_Payment } from "../../../Library/LanguageConversion/Admin_Payment";
import { Context } from "../../../Components/Context/Context";
export default function PaymentLayout(props: any) {
  const { pathname } = useLocation()
  const {language,setLanguage} = useContext(Context)
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/payment-received">
            {Admin_Payment?.PaymentReceived?.[language]}
            </NavLink>
            <NavLink to="/payment-to-vendors">
            {Admin_Payment?.PaymenttoVendors?.[language]}
            </NavLink>
            <NavLink to="/cancelled-payment">
            {Admin_Payment?.CancelledPayments?.[language]}
            </NavLink>
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
              {Admin_Payment?.Dashboard?.[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {
                  pathname == "/payment-received" ? Admin_Payment?.PaymentReceived?.[language] :
                    pathname == "/payment-to-vendors" ? Admin_Payment?.PaymenttoVendors?.[language] :
                      pathname == "/cancelled-payment" ? Admin_Payment?.CancelledPayments?.[language] : "Payments"
                }
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}


