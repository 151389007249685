import React, { useEffect,useContext } from "react";
import { Context } from "../../../../../Components/Context/Context";
import { useState } from "react";
import { Admin_services } from "../../../../../Library/LanguageConversion/Admin_Services";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../../Library/CustomApi";
import CustomPagination from "../../../../../Components/CustomPagination/Index";
import Loader from "./../../../../../Components/Loader/Loader";
import moment from "moment";
import constants from "../../../../../Library/Constants/Constants";
import {
  ticket_priority,
  ticket_type,
} from "../../../../../Library/Constants/MapConstants";
import DatePicker from 'react-date-picker'

export default function PendingTickets() {
  const navigate = useNavigate();
  const {language,setLanguage}=useContext(Context);

  const [loading, setLoading] = useState(true);
  const [pendingTicket, setPendingTickets] = useState([]);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [type, setType] = useState([]);
  const [priority, setPriority] = useState([]);
  const [from, setFrom] = useState("");
  const [date, setDate] = useState<any>({
    validfrom: "",
    validto: "",
  });

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getPendingTickets();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, type, currentPage, priority, date?.validfrom, date?.validto]);

  const getPendingTickets = () => {
    let v_from: any = moment(date?.validfrom).format("YYYY-MM-DD");
    let v_to: any = moment(date?.validto).format("YYYY-MM-DD");
    if (v_from !== "Invalid date" && v_to !== "Invalid date") {
      setLoading(true);
      api
        .get(
          `/service-maintenance?page=${currentPage}&search_keyword=${search}&status=${constants.TICKETSTATUS.PENDING}&type=${type}&priority=${priority}&valid_from=${v_from}&valid_to=${v_to}`,
          true
        )
        .then(async function ([success, response]) {
          setPendingTickets(response?.data || []);
          setPageData(response?.meta);
          setFrom(response?.meta?.from);
          setLoading(false);
          return response;
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(true);
      api
        .get(
          `/service-maintenance?page=${currentPage}&search_keyword=${search}&status=${constants.TICKETSTATUS.PENDING}&type=${type}&priority=${priority}`,
          true
        )
        .then(async function ([success, response]) {
          setPendingTickets(response?.data || []);
          setPageData(response?.meta);
          setFrom(response?.meta?.from);
          setLoading(false);
          return response;
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const clearAll = () => {
    setDate({
      ...date,
      validfrom: "",
      validto: "",
    });
    setSearch("");
    setPriority([]);
    setType([]);
    getPendingTickets();
  };

  return (
    <React.Fragment>
      <Row className="gy-4 gx-5 add-tendor-content-wrap-outer">
        <div className="search-widget-outer">
          <Form role="search" className="widget-inner">
            <div className="valid-from">
              <Label>{Admin_services?.ValidFrom[language]}</Label>
              <DatePicker
                name="validfrom"
                className="form-control"
                onChange={(e: any) => {
                  setCurrentPage(1)
                  setDate({
                    ...date,
                    validfrom: e,
                  });
                }}
                format={"dd-MM-y"}
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                value={date?.validfrom} />
            </div>
            <div className="valid-to">
              <Label>{Admin_services?.ValidTo[language]}</Label>
              <DatePicker
                name="validto"
                className="form-control"
                onChange={(e: any) => {
                  setCurrentPage(1)
                  setDate({
                    ...date,
                    validto: e,
                  });
                }}
                format={"dd-MM-y"}
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                minDate={date?.validfrom}
                disabled={date?.validfrom ? false : true}
                value={date?.validto} />
            </div>
            <div className="search-btn-wrap">
              <div className="select-priority">
                <Input
                  type="select"
                  name="priority"
                  value={priority}
                  data={ticket_priority}
                  onChange={(e: any) => {
                    setPriority(e.target.value);
                  }}
                >
                  <option value="">{Admin_services?.SelectPriority[language]}</option>;
                  {ticket_priority?.map((item: any, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        <option key={i} value={item?.value}>
                          {" "}
                          {item?.name}
                        </option>
                      </React.Fragment>
                    );
                  })}
                </Input>
              </div>
              <div className="search-btn-wrap">
                <div className="select-priority">
                  <Input
                    type="select"
                    name="type"
                    value={type}
                    data={ticket_type}
                    onChange={(e: any) => {
                      setType(e.target.value);
                    }}
                  >
                    <option value="">{Admin_services?.SelectType[language]}</option>;
                    {ticket_type?.map((item: any, i: number) => {
                      return (
                        <React.Fragment key={i}>
                          <option key={i} value={item?.value}>
                            {" "}
                            {item?.name}
                          </option>
                        </React.Fragment>
                      );
                    })}
                  </Input>
                </div>
              </div>
            </div>

              <div className="tickets-search-outer">
                  <div className="search-outer">
                    <InputGroup className="search-group">
                      <InputGroupText title="Product Name" className="search-icon" id="search">
                        <i className="fa-sharp fa-solid fa-magnifying-glass" />
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Product Name"
                        aria-label="Username"
                        aria-describedby="search"
                        value={search}
                        onChange={handleSearch}
                        onKeyPress={(event) => {
                          var key = event.keyCode || event.which;
                          if (key === 13) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                    {/* <div className="reset-wrap"> */}
                      <div className="reset-all">
                        <i title="Reset All"><a href="#" onClick={clearAll}>{Admin_services?.Reset[language]}</a></i>
                      </div>
                    {/* </div> */}
              </div>
          </Form>
        </div>

        <Col lg={12}>
          <div className="table-wrap responsive-table">
            <Table className="w-100 table-striped">
              <thead>
                <tr>
                  <th>{Admin_services?.SlNo[language]}</th>
                  <th>{Admin_services?.Requestby[language]}</th>
                  <th>{Admin_services?.Products[language]}</th>
                  <th>{Admin_services?.Type[language]}</th>
                  <th>{Admin_services?.Priority[language]}</th>
                  <th>{Admin_services?.Date[language]}</th>
                </tr>
              </thead>
              <tbody>
                {pendingTicket?.length !== 0 ? (
                  pendingTicket?.map((item: any, i: any) => {
                    return (
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_services?.SlNo[language]}:</span>
                          <span className="table-data">{from + i}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_services?.Requestby[language]}:</span>
                          <span className="table-data">
                            {item?.department?.name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_services?.Products[language]}:</span>
                          <span className="table-data">
                            {item?.serviceproduct?.name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_services?.Type[language]}:</span>
                          <span className="table-data">
                            {item?.type == constants.TICKET_TYPE.SERVICES
                              ? "Sevice"
                              : item?.type == constants.TICKET_TYPE.MAINTAINANCE
                                ? "Maintainance"
                                : item?.type == constants.TICKET_TYPE.ENQUIRE
                                  ? "Enquiry"
                                  : item?.type == constants.TICKET_TYPE.SERVICES
                                    ? "Tickets"
                                    : null}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_services?.Priority[language]}:</span>
                          <span className="table-data">
                            {item?.priority == constants.PRIORITY.LOW ? (
                              <span className="text-info">{Admin_services?.low[language]}</span>
                            ) : item?.priority == constants.PRIORITY.MEDIUM ? (
                              <span className="text-warning">{Admin_services?.Medium[language]}</span>
                            ) : item?.priority == constants.PRIORITY.HIGH ? (
                              <span className="text-danger">{Admin_services?.High[language]}</span>
                            ) : null}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_services?.Date[language]}:</span>
                          <span className="table-data">
                            {moment(item?.request_date).format("DD-MM-YYYY")}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center p-5">
                      {loading ? <Loader /> : "No Tickets Found "}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {pendingTicket?.length !== 0 && pageData && (
            <div className="table-footer-wrap">
              <small>
                Showing {pageData.from} to {pageData.to} of {pageData.total}{" "}
                entries
              </small>
              <div className="pagination">
                <CustomPagination
                  totalItems={pageData?.total}
                  itemsPerPage={pageData?.per_page}
                  activePage={pageData.current_page}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
}
