import React, { useEffect ,useContext} from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";
import { DOCS_URL } from "../../../../Library/ApiSettings";
import TextError from "../../../../Components/Errors/TextError";
import { preventNegativeValues } from "../../../../Library/Constants/helpers";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../Components/Context/Context";
import { Vendor_Settings_ReuploadModal } from "../../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";

const DOCUMENT_URL = DOCS_URL;
export default function WorkOrderReuploadModal(props: any) {
  const {language,setLanguage}=useContext(Context)
  const handleFileChange = (e: any, values?: any) => {
    props?.setFieldValue("work_order_certificate", e.target.files[0]);
  };
  return (
    <ModalContainer
      open={props?.isShowing}
      setOpen={() => {
        props?.setIsShowing(false);
        props?.handleReset();
      }}
      header="Document Reupload"
    >
      <div className="company-information-update-wrap">
        <h4>{Vendor_Settings_ReuploadModal?.Work_Order_Details?.[language]}</h4>
        <hr />
        <Form className="form-wrap" onSubmit={props?.handleSubmit}>
          <Row className="align-items-end">
            <Col md={12} sm={12}>
              <FormGroup>
                <Label>
                {Vendor_Settings_ReuploadModal?.Work_Order_Certificate?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                <div className="image-input">
                  <input
                    type="file"
                    ref={props?.oemRef}
                    id="work_order_certificate"
                    name="work_order_certificate"
                    className="form-control"
                    accept=".pdf"
                    onChange={(e: any) =>
                      handleFileChange(e, e.target.files[0])
                    }
                    disabled={props?.list > 0 ? true : false}
                  ></input>
                  <Label
                    className="image-button"
                    for="work_order_certificate"
                    id="work_order_certificate"
                  >
                    {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]} 
                  </Label>
                </div>
                <ErrorMessage
                  name="work_order_certificate"
                  component={TextError}
                />
              </FormGroup>
            </Col>{" "}
            <Col sm={12}>
              <FormGroup>
                <div className="custom-input">
                  <Label>
                    {" "}
                    {Vendor_Settings_ReuploadModal?.No_of_Projects_Done?.[language]} 
                    <span className="text-danger" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </Label>
                  <Input
                    name="work_order_no_of_projects"
                    type="number"
                    value={props?.values?.work_order_no_of_projects}
                    onChange={props?.handleChange}
                    min={1}
                    onKeyPress={preventNegativeValues}
                  />
                </div>
                <ErrorMessage
                  name="work_order_no_of_projects"
                  component={TextError}
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              {/* {props?.values?.work_order_no_of_projects &&
                props?.values?.work_order_certificate && (
                  <>
                    <FormGroup>
                      <div className="custom-input">
                        <span className="mark-end text-success underlined">
                          Mark:5/-
                        </span>
                      </div>
                    </FormGroup>
                  </>
                )} */}
            </Col>
            <Row className="mt-3">
              <Col sm={12} className="text-end">
                <Button
                  className="btn btn-primary mb-5"
                  color="primary"
                  type="submit"
                >
                  {Vendor_Settings_ReuploadModal?.Submit?.[language]}  
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>
    </ModalContainer>
  );
}
