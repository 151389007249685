import { Row, Col } from "reactstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { SetStateAction, useEffect, useState, useContext } from "react";
import Paypal from "../../../Components/PaymentGateway";
import ChequeModal from "./SecureChequeModal";
import { Formik } from "formik";
import BankAccountModal from "./SecureBankAccountModal";
import {
  SecurechellanValidation,
  SecureAccountValidation,
  SecureChequeValidation,
} from "./ChellanValidation";
import RightSection from "../../Department/Products/PaymentMethodComponents/RightSection";
import PaymentGateWay from "./PaymentGateWay";
import { api } from "../../../Library/CustomApi";
import axios from "axios";
import { API_URL } from "../../../Library/ApiSettings";
import { toast } from "react-toastify";
import ChellanModal from "./SecureChallanModal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RTGSModal from "./RTGSModal";
import NEFTModal from "./NEFTModal";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

// interface proptype {
//   state?:{amount:Number}
// }

export default function SecurePayment() {
  const { state } = useLocation();

  const { language, setLanguage } = useContext(Context);
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [isShowing, setIsShowing] = useState(false);
  const [isShowingNEFT, setIsShowingNEFT] = useState(false);
  const [isShowingRTGS, setIsShowingRTGS] = useState(false);
  const [isShowings, setIsShowings] = useState(false);
  const [isShowingChellan, setIsShowingChellan] = useState(false);
  const [stateFor, setStateFor] = useState(state?.payfor);
  const today: any = new Date().toJSON().slice(0, 10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState({});
  const [ifsc, setIfsc] = useState({
    ifsc: "",
  });

  const [succes_data, setSuccesData] = useState(false);
  const [data, setData] = useState([]);

  const initialValues_chellan = {
    bank_name: "",
    account_holder_name: "",
    branch_name: "",
    ifsc_code: "",
    account_number: "",
    cheque_number: "",
    amount_data: "",
    file: "",
    // account_name: ""
  };
  const initialValues_account = {
    bank_name: "",
    account_holder_name: "",
    branch_name: "",
    // account_name: "",
    ifsc_code: "",
    account_number: "",
    amount_data: "",
    file: "",
  };
  const initialValues_Cheque = {
    receipt_number: "",
    file: "",
    bsr_code: "",
    payment_site: "",
    payment_date: "",
    chellan_number: "",
    amount_data: "",
  };
  function paychange(statues: string) {
    return setIsOpen(true), setSelectedMethod(statues);
  }
  //send bank Save
  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions);
  };

  const sendData = (values: any, actions: any) => {
    var dates: any = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;

    let type: any = 1;
    let cat: any = localStorage.getItem("CAT");
    const formData = new FormData();
    formData.append("type", type);
    formData.append("order_id", state?.order_id);
    formData.append("proforma_id", state?.proforma_id);
    formData.append("payfor", state?.payfor);
    formData.append("ifsc_code", values?.ifsc_code);
    formData.append("file", values?.file);
    // formData.append("account_name", values?.account_name);
    formData.append("bank_name", values?.bank_name);
    formData.append("payment_date", dat);
    formData.append("branch_name", values?.branch_name);
    formData.append("account_holder_name", values?.account_holder_name);
    formData.append("amount", state?.amount);
    formData.append("transaction_id", values?.account_number);
    formData.append("category", cat);
    if (state?.payfor == "PG") {
      formData.append("pg_status", "0");
    } else {
      formData.append("status", "0");
    }
    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/secure-payment`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success(`${VendorRegLang?.payment_details_uploaded[language]}`);
          setSuccesData(true);
          getData();
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/received-order-vendor");
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  //neft
  const handleSubmitNEFT = (values: any, actions: any) => {
    sendDataNEFT(values, actions);
  };

  const sendDataNEFT = (values: any, actions: any) => {
    var dates: any = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;

    let type: any = 5;
    let cat: any = localStorage.getItem("CAT");
    const formData = new FormData();
    formData.append("type", type);
    formData.append("order_id", state?.order_id);
    formData.append("proforma_id", state?.proforma_id);
    formData.append("payfor", state?.payfor);
    formData.append("ifsc_code", values?.ifsc_code);
    formData.append("file", values?.file);
    // formData.append("account_name", values?.account_name);
    formData.append("bank_name", values?.bank_name);
    formData.append("payment_date", dat);
    formData.append("branch_name", values?.branch_name);
    formData.append("account_holder_name", values?.account_holder_name);
    formData.append("amount", state?.amount);
    formData.append("transaction_id", values?.account_number);
    formData.append("category", cat);
    if (state?.payfor == "PG") {
      formData.append("pg_status", "0");
    } else {
      formData.append("status", "0");
    }

    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/secure-payment`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success(`${VendorRegLang?.payment_details_uploaded[language]}`);
          setSuccesData(true);
          getData();
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/received-order-vendor");
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  //rtgs
  const handleSubmitRTGS = (values: any, actions: any) => {
    sendDataRTGS(values, actions);
  };

  const sendDataRTGS = (values: any, actions: any) => {
    var dates: any = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;

    let type: any = 6;
    let cat: any = localStorage.getItem("CAT");
    const formData = new FormData();
    formData.append("type", type);
    formData.append("order_id", state?.order_id);
    formData.append("proforma_id", state?.proforma_id);
    formData.append("payfor", state?.payfor);
    formData.append("ifsc_code", values?.ifsc_code);
    formData.append("file", values?.file);
    // formData.append("account_name", values?.account_name);
    formData.append("bank_name", values?.bank_name);
    formData.append("payment_date", dat);
    formData.append("branch_name", values?.branch_name);
    formData.append("account_holder_name", values?.account_holder_name);
    formData.append("amount", state?.amount);
    formData.append("transaction_id", values?.account_number);
    formData.append("category", cat);
    if (state?.payfor == "PG") {
      formData.append("pg_status", "0");
    } else {
      formData.append("status", "0");
    }
    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/secure-payment`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success(`${VendorRegLang?.payment_details_uploaded[language]}`);
          setSuccesData(true);
          getData();
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/received-order-vendor");
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  //send cheque
  const handleSubmits = (values: any, actions: any) => {
    sendDatas(values, actions);
  };
  const sendDatas = (values: any, actions: any) => {
    var dates: any = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;
    let type: any = 2;
    const formData = new FormData();
    let cat: any = localStorage.getItem("CAT");
    formData.append("type", type);
    formData.append("order_id", state?.order_id);
    formData.append("proforma_id", state?.proforma_id);
    formData.append("payfor", state?.payfor);
    formData.append("file", values?.file);
    formData.append("ifsc_code", values?.ifsc_code);
    // formData.append("account_name", values?.account_name);
    formData.append("bank_name", values?.bank_name);
    formData.append("branch_name", values?.branch_name);
    formData.append("amount", state?.amount);
    formData.append("account_holder_name", values?.account_holder_name);
    formData.append("cheque_number", values?.cheque_number);
    formData.append("payment_date", dat);
    formData.append("category", cat);
    if (state?.payfor == "PG") {
      formData.append("pg_status", "0");
    } else {
      formData.append("status", "0");
    }

    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/secure-payment`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success(
            `${VendorRegLang?.cheque_uploaded_successfully[language]}`
          );
          setIsShowings(false);
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/received-order-vendor");
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  //send chellan

  const handleSubmitCheque = (values: any, actions: any) => {
    sendDataChellan(values, actions);
  };
  const sendDataChellan = (values: any, actions: any) => {
    let type: any = 3;
    var dates: any = new Date(values?.payment_date).toLocaleDateString(
      "en-GB",
      {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }
    );
    const [month, day, year] = dates.split("/");
    var dat = `${year}-${day}-${month}`;
    let cat: any = localStorage.getItem("CAT");
    const formData = new FormData();
    formData.append("type", type);
    formData.append("order_id", state?.order_id);
    formData.append("proforma_id", state?.proforma_id);
    formData.append("payfor", state?.payfor);
    formData.append("payment_site", values?.payment_site);
    formData.append("payment_date", dat);
    formData.append("file", values?.file);
    formData.append("bsr_code", values?.bsr_code);
    formData.append("amount", state?.amount);
    formData.append("challan_no", values?.chellan_number);
    formData.append("category", cat);
    // formData.append("cheque_number", values?.cheque_number);
    if (state?.payfor == "PG") {
      formData.append("pg_status", "0");
    } else {
      formData.append("status", "0");
    }
    const body = formData;
    axios({
      method: "post",
      url: `${API_URL}/secure-payment`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success(
            `${VendorRegLang?.challan_uploaded_successfully[language]}`
          );
          setIsShowingChellan(false);
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/received-order-vendor");
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  useEffect(() => {
    if (selectedMethod == "DBT") {
      getData();
    }
  }, [selectedMethod]);

  const getData = () => {
    api
      .get(`/account_list?type=1`, true)
      .then(async function ([success, response]) {
        setData(response?.data || []);
        setSuccesData(true);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <React.Fragment>
        {/* inner contents starts here */}
        <div className="right-content-wrap">
          <div className="proforma-checkout-wrap">
            <div className="choose-payment-outer">
              <h5>
                <span className="money-icon-wrap">
                  <img src="../../images/rupee-icon.svg" alt="" />
                </span>
                {VendorRegLang?.choose_payment[language]}:
              </h5>
            </div>
            {/* <div className="custom-tabs">
              <NavLink to="/emd-details"><i className="fa-solid fa-arrow-left"></i>{" "}EMD details</NavLink>
            </div> */}
            <Row className="gy-4">
              <Col lg={7}>
                <div className="choose-payment-outer-wrap">
                  <Col md={12}>
                    <div className="choose-payment-wrap">
                      <div className="choose-list-wrap">
                        <div
                          className={`choose-type-inner ${
                            selectedMethod == "DBT" ? "active" : ""
                          }`}
                          onClick={() => {
                            // if (data?.length == 0) {
                            //   setIsShowing(true)
                            //  }
                            setIsShowing(true);
                            setSelectedMethod("DBT");
                          }}
                        >
                          {/* <i className="bi bi-bank2 d-block"></i> */}
                          <img
                            className="icon"
                            src="/images/banking.png"
                            alt="icon"
                          />
                          <small>
                            {VendorRegLang?.direct_bank_transfer[language]}
                          </small>
                        </div>
                        <div
                          className={`choose-type-inner ${
                            selectedMethod == "RTGS" ? "active" : ""
                          }`}
                          onClick={() => {
                            // if (data?.length == 0) {
                            //   setIsShowing(true)
                            //  }
                            setIsShowingRTGS(true);
                            setSelectedMethod("RTGS");
                          }}
                        >
                          {/* <i className="bi bi-bank2 d-block"></i> */}
                          <img
                            className="icon"
                            src="/images/rtgs.png"
                            alt="icon"
                          />
                          <small>{VendorRegLang?.rtgs[language]}</small>
                        </div>
                        <div
                          className={`choose-type-inner ${
                            selectedMethod == "NEFT" ? "active" : ""
                          }`}
                          onClick={() => {
                            // if (data?.length == 0) {
                            //   setIsShowing(true)
                            //  }
                            setIsShowingNEFT(true);
                            setSelectedMethod("NEFT");
                          }}
                        >
                          {/* <i className="bi bi-bank2 d-block"></i> */}
                          <img
                            className="icon"
                            src="/images/neft.png"
                            alt="icon"
                          />
                          <small>{VendorRegLang?.neft[language]}</small>
                        </div>
                        {/* <div className={`choose-type-inner ${(selectedMethod == 'NB') ? 'active' : ''}`} onClick={() => { */}
                        {/* setSelectedMethod('NB') */}
                        {/* } */}

                        {/* } */}

                        {/* > */}
                        {/* <i className="bi bi-phone d-block"></i> */}
                        {/* <img className='icon' src="/images/credit-card.png" alt="icon" /> */}
                        {/* <small>NetBanking</small> */}
                        {/* </div> */}
                        <div
                          className={`choose-type-inner ${
                            selectedMethod == "CH" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedMethod("CH");
                            setIsShowings(true);
                          }}
                        >
                          {/* <i className="bi bi-cash d-block"></i> */}
                          <img
                            className="icon"
                            src="/images/cheque(1).png"
                            alt="icon"
                          />
                          <small>{VendorRegLang?.cheque[language]}</small>
                        </div>
                        <div
                          className={`choose-type-inner ${
                            selectedMethod == "DD" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedMethod("DD");
                            setIsShowingChellan(true);
                            //paychange('DD')
                          }}
                        >
                          {/* <i className="bi bi-paperclip d-block"></i> */}
                          <img
                            className="icon"
                            src="/images/sketching.png"
                            alt="icon"
                          />
                          <small>{VendorRegLang?.challan[language]}</small>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>
              <Col lg={5}>
                <div className="checkout-slip-wrap">
                  <div className="cart-total-left-wrap">
                    <div className="cart-total-left-inner">
                      <strong>
                        {VendorRegLang?.date_of_payment[language]}
                      </strong>
                      <p>{today}</p>
                    </div>
                    <div className="cart-total-left-inner">
                      <p className="mb-0">
                        {state?.type ? state?.type : "EMD"}{" "}
                        {VendorRegLang?.amount[language]}:
                      </p>
                      <h2 className="primary-text">
                        ₹{state?.amount?.toFixed(2)}
                      </h2>
                    </div>
                    <hr />
                  </div>

                  {/* {
                    selectedMethod == 'DBT' ?
                      <PaymentGateWay paymentMethod="Direct Bank Transfer" id={state?.id} /> :
                      selectedMethod == 'CH' ?
                        <PaymentGateWay paymentMethod="Cheque" id={state?.id} /> :
                        selectedMethod == 'DD' ?
                          <PaymentGateWay paymentMethod="Demand Draft" id={state?.id} /> :
                          selectedMethod == 'NB' ?
                            <Paypal amount={state?.amount} purchaseType='challon' order_id={state?.category} /> :
                            null
                  } */}

                  {/* {selectedMethod == 'DBT' ?
                    <PaymentGateWay paymentMethod="Direct Bank Transfer" id={state?.id} datas={data} /> : null
                  }
                  {selectedMethod == 'CH' ?
                    <PaymentGateWay paymentMethod="Cheque" id={state?.id} datas={data} /> : null
                  }
                  {selectedMethod == 'DD' ?
                    <PaymentGateWay paymentMethod="Demand Draft" id={state?.id} datas={data} /> : null
                  }
                  {
                    selectedMethod == 'NB' ?
                      <Paypal amount={state?.amount || 1000} purchaseType='challon' order_id={state?.category} /> :
                      null
                  } */}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Formik
          initialValues={initialValues_account}
          validationSchema={SecureAccountValidation}
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            return (
              <>
                <BankAccountModal
                  isShowing={isShowing}
                  setIsShowing={setIsShowing}
                  handleSubmit={handleSubmit}
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  setValue={setValue}
                  value={value}
                  amount={state?.amount?.toFixed(2)}
                  handleReset={handleReset}
                />
              </>
            );
          }}
        </Formik>

        {/* NEFT */}
        <Formik
          initialValues={initialValues_account}
          validationSchema={SecureAccountValidation}
          onSubmit={(values, actions) => {
            handleSubmitNEFT(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            return (
              <>
                <NEFTModal
                  isShowing={isShowingNEFT}
                  setIsShowing={setIsShowingNEFT}
                  handleSubmit={handleSubmit}
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  setValue={setValue}
                  value={value}
                  amount={state?.amount?.toFixed(2)}
                  handleReset={handleReset}
                />
              </>
            );
          }}
        </Formik>

        {/* RTGS */}
        <Formik
          initialValues={initialValues_account}
          validationSchema={SecureAccountValidation}
          onSubmit={(values, actions) => {
            handleSubmitRTGS(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            return (
              <>
                <RTGSModal
                  isShowing={isShowingRTGS}
                  setIsShowing={setIsShowingRTGS}
                  handleSubmit={handleSubmit}
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  setValue={setValue}
                  value={value}
                  amount={state?.amount?.toFixed(2)}
                  handleReset={handleReset}
                />
              </>
            );
          }}
        </Formik>

        <Formik
          initialValues={initialValues_chellan}
          validationSchema={SecureChequeValidation}
          onSubmit={(values, actions) => {
            handleSubmits(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            return (
              <ChequeModal
                isShowing={isShowings}
                setIsShowing={setIsShowings}
                handleSubmit={handleSubmit}
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                setValue={setValue}
                value={value}
                handleReset={handleReset}
                amount={state?.amount?.toFixed(2)}
              />
            );
          }}
        </Formik>
        <Formik
          initialValues={initialValues_Cheque}
          validationSchema={SecurechellanValidation}
          onSubmit={(values, actions) => {
            handleSubmitCheque(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            return (
              <ChellanModal
                isShowing={isShowingChellan}
                setIsShowing={setIsShowingChellan}
                handleSubmit={handleSubmit}
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                amount={state?.amount?.toFixed(2)}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                setValue={setValue}
                value={value}
                handleReset={handleReset}
              />
            );
          }}
        </Formik>
      </React.Fragment>
    </>
  );
}
