import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
} from "reactstrap";
import { api } from "../../../Library/CustomApi";

import moment from "moment";
import Empanelment from "./../Empanelment/Index";
import AreaChartData from "../Dashboard/Chart/AreaChartData";
import DonutChartView from "../Dashboard/Chart/DonutChartView";
import HalfCircleProgressBar from "../Dashboard/Chart/HalfCircularView";
import LineChartView from "../Dashboard/Chart/LineChartView";
import Progress from "../Dashboard/Chart/ProgressView";
import PieChartView from "../Dashboard/Chart/PieChartView";
import Loader from "../../../Components/Loader/Loader";
import RadialChart from "../Dashboard/Chart/TicketChart";
import { Context } from "../../../Components/Context/Context";
import { Admin_Overview } from "../../../Library/LanguageConversion/Admin_Overview";
export default function Dashboard() {
  const navigate = useNavigate()
  const [empanelment, setEmpanelement] = useState<any>([]);
  const [order, setOrder] = useState([]);
  const [total_sales, setTotalSales] = useState([]);
  const [total_sales_count, setTotalSalesOrder] = useState<any>([]);
  const [cat_sales, setCatSales] = useState([]);
  const [Line_data, setLineData] = useState([]);
  const [ticket_data, setTicketData] = useState([]);
  const [order_count, setOrderCount] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const {language, setLanguage}= useContext(Context)
  const [value, setValue] = useState({
    data: "",
    sales: "",
    sales_cat: "",
    order_status: "",
    ticket_status: "",
  });
  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    getOrderCount();
    getTickets();
    getOrderStatus();
    getSalesCategory();
    getEmpanelemnt();
    getSales();
  }, [value?.sales]);

  // ====================================================== Onchange Dropdown ===========================================================

  const handleChange = (e: any) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  // ====================================================== Onchange Dropdown ===========================================================

  const getOrderCount = () => {
    api
      .post(
        `/dashboard/dashboard_order_count`,
        {
          data: value?.sales || 1,
        },
        true
      )
      .then(async function ([success, response]) {
        setOrderCount(response.data || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //============================================================== Ticket card ===========================================================
  const getTickets = () => {
    setIsLoading(true);
    api
      .post(
        `/dashboard/ticket_graph_list`,
        {
          data: value?.sales || 1,
        },
        true
      )
      .then(async function ([success, response]) {
        setTicketData(response || []);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  //============================================================== Order card =============================================================

  const getOrders = () => {
    setIsLoading1(true);
    api
      .post(`/dashboard/order_list`, {}, true)
      .then(async function ([success, response]) {
        setOrder(response?.data || []);
        setIsLoading1(false);
        return response;
      })
      .catch((err) => {
        setIsLoading1(false);
        console.log(err);
      });
  };

  //============================================================== Empanelment ==========================================================

  const getEmpanelemnt = () => {
    setIsLoading(true);
    api
      .post(
        `/dashboard/empanelment`,
        {
          data: value?.sales || 1,
        },
        true
      )
      .then(async function ([success, response]) {
        setEmpanelement(response?.data || []);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  //================================================================ sales ==============================================================

  const getSales = () => {
    api
      .post(
        `/dashboard/total_sales_graph`,
        {
          data: value?.sales || 1,
        },
        true
      )
      .then(async function ([success, response]) {
        let data: any = [];
        response?.data?.map((elem: any) => {
          data?.push({
            name: elem?.name,
            Orders: elem?.Orders,
          });
        });

        setTotalSales(data || []);
        setTotalSalesOrder(response || 0);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //=========================================================== Sales by category =====================================================
  const getSalesCategory = () => {
    setIsLoading(true);
    api
      .post(
        `/dashboard/sales_by_category_pichart`,
        {
          data: value?.sales || 1,
        },
        true
      )
      .then(async function ([success, response]) {
        let data: any = [];
        response?.map((elem: any) => {
          data?.push({
            name: elem?.name,
            value: elem?.value,
          });
        });
        setCatSales(data || []);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  let halfdata: any = [];
  halfdata?.push({ value: empanelment?.total_count });

  //============================================================== Line Chart ==========================================================

  const getOrderStatus = () => {
    api
      .post(
        `/dashboard/line_graph`,
        {
          data: value?.sales || 1,
        },
        true
      )
      .then(async function ([success, response]) {
        let data: any = [];
        response?.map((elem: any) => {
          data?.push({
            name: elem?.name,
            value: elem?.value,
          });
        });

        setLineData(response || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   function nFormatter(num:any) {
  //     if (num >= 1000000000) {
  //        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  //     }
  //     if (num >= 1000000) {
  //        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  //     }
  //     if (num >= 1000) {
  //        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  //     }
  //     return num;
  // }

  function nFormatter(value: any) {
    var val: any = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(1) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(1) + " L";
    } else if (val >= 1000) {
      val = (val / 1000).toFixed(1) + " K";
    } else if (val >= 1000000) {
      val = (val / 1000000).toFixed(1) + " M";
    } else if (val >= 1000000000) {
      val = (val / 1000000000).toFixed(1) + " G";
    }
    return val;
  }

  //====================================================================== View ==========================================================

  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <Row className={"gx-3 gy-3"}>
          <Col xl={8}>
            <Row className={"gx-3 gy-3"}>
              {/* Total sales col */}
              <Col md={12}>
                <Card className="dashboard-card-wrap important-card">
                  <CardHeader>
                    <h5 className="heading">{Admin_Overview?.Total_Orders[language]}</h5>
                    <div className="action-flex-btn">
                      <Input
                        type="select"
                        name="sales"
                        value={value?.sales}
                        onChange={handleChange}
                        className="form-select w-auto"
                      >
                        <option value="1">{Admin_Overview?.This_Week[language]}</option>
                        <option value="2">{Admin_Overview?.This_Month[language]}</option>
                        <option value="3">{Admin_Overview?.This_Year[language]}</option>
                      </Input>

                      <div className="download-button" title="Download">
                        <i className="bi bi-download"></i>
                      </div>

                      {/* <Button
                        color="warning"
                        className="download-button"
                        title="Download"
                        onClick={() => window.print()}
                      >
                        <i className="bi bi-download me-2"></i>
                        <span>{Admin_Overview?.Download[language]}</span>
                      </Button> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="total-sales-content-wrap">
                      <div className="left-side">
                        <h2 className="price">
                          ₹ {nFormatter(total_sales_count?.total_sales) || 0}
                        </h2>
                        <div className="total-wrap">
                          <small className="badge success">
                            <span>
                              <i className="bi bi-arrow-up" />
                            </span>
                            {parseInt(
                              total_sales_count?.total_sales_percentage
                            ) || 0}
                            %
                          </small>
                          {/* <small className="count">
                            {" "}
                            {parseInt(total_sales_count?.total_sales_current) ||
                              0}
                          </small> */}
                        </div>
                      </div>
                      <AreaChartData data={total_sales} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* Empanelments column */}
              <Col md={6}>
                <Card className="dashboard-card-wrap empanelments-card shadow-sm h-100">
                  <CardHeader>
                    <h6 className="heading">{Admin_Overview?.Empanelments[language]}</h6>
                    {/* <select
                      name="data"
                      value={value?.data}
                      onChange={handleChange}
                      className="form-select w-auto"
                    >
                      <option value="1">This Week</option>
                      <option value="2">This Month</option>
                      <option value="3">This Year</option>
                    </select> */}
                  </CardHeader>
                  {!isLoading ? (
                    <CardBody>
                      <div className="empanelments-wrap">
                        <div className="empanelment-chart-container mb-4">
                          <HalfCircleProgressBar halfdata={halfdata} />
                        </div>
                        <div className="empanelment-chart-flex-container">
                          <Row>
                            <Col
                              md={4}
                              sm={4}
                              xs={4}
                              className="progress-chart-column"
                            >
                              <Progress
                                percentage={empanelment?.pending_count || 0}
                              />
                              <h6>{Admin_Overview?.Pending_For_Approval[language]}</h6>
                            </Col>
                            <Col
                              md={4}
                              sm={4}
                              xs={4}
                              className="progress-chart-column"
                            >
                              <Progress
                                percentage={empanelment?.approved_count || 0}
                              />
                              <h6>{Admin_Overview?.Approved_Vendors[language]}</h6>
                            </Col>
                            <Col
                              md={4}
                              sm={4}
                              xs={4}
                              className="progress-chart-column"
                            >
                              <Progress
                                percentage={empanelment?.barred_count || 0}
                              />
                              <h6>{Admin_Overview?.Invited_Vendors[language]}</h6>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardBody>
                  ) : (
                    <div className="loader">
                      <Loader />
                    </div>
                  )}
                </Card>
              </Col>
              {/* Sales by category column */}
              <Col md={6}>
                <Card className="dashboard-card-wrap sales-by-order-card shadow-sm h-100">
                  <CardHeader>
                    <h6 className="heading">{Admin_Overview?.Sales_by_Category[language]}</h6>
                    {/* <Input
                      type="select"
                      name="sales_cat"
                      value={value?.sales_cat}
                      onChange={handleChange}
                      className="form-select w-auto"
                    >
                      <option value="1">This Week</option>
                      <option value="2">This Month</option>
                      <option value="3">This Year</option>
                    </Input> */}
                  </CardHeader>
                  {!isLoading ? (
                    <CardBody>
                      <div className="sales-categ-wrap">
                        <div className="sales-categ-chart-flex-container">
                          <DonutChartView Salesdata={cat_sales} />
                        </div>
                      </div>
                    </CardBody>
                  ) : (
                    <div className="loader">
                      <Loader />
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
          {/* orders-col */}
          <Col xl={4}>
            <Card className="dashboard-card-wrap orders-card  shadow-sm h-100">
              <CardHeader>
                <h5 className="heading">{Admin_Overview?.latest_orders[language]}</h5>
              </CardHeader>
              <CardBody>
                <div className="orders-content-wrap overview-orders-wrap">
                  <div className="orders-content-inner">
                    {
                      order?.length !== 0 ? (
                        <>
                          {order?.map((item: any, i: any) => {
                            return (
                              <React.Fragment key={i}>
                                <div className="orders-item">
                                  <i className="fa-solid fa-basket-shopping"></i>
                                  <div className="order-item-inner">
                                    <p>
                                      {" "}
                                      {Admin_Overview?.Product_Name[language]}:{" "}
                                      <span>
                                        {item?.order_details?.[0]?.products
                                          ?.name || " "}
                                      </span>
                                    </p>
                                    { }
                                    <p>
                                      {Admin_Overview?.Quantity[language]}:{" "}
                                      <span>
                                        {item?.order_details?.[0]?.quantity || " "}
                                      </span>
                                    </p>
                                    <p>
                                      {Admin_Overview?.Order_ID[language]}:{" "}
                                      <span>{item?.order_id || " "}</span>
                                    </p>
                                    <p>
                                      {Admin_Overview?.Amount[language]} :{" "}
                                      <span>₹{item?.grand_amount || "0"}</span>
                                    </p>

                                    <small className="time">
                                      {moment(item?.created_at).format("LLLL")}
                                    </small>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : (
                        <div>
                          {isLoading ? (
                            <div className="loader">
                              <Loader />
                            </div>
                          ) : (
                            "No Order Found"
                          )}
                        </div>
                      )

                      // <span>No Order Found</span>
                    }
                  </div>
                </div>
                {order?.length !== 0 ? (
                  <>
                    <div className="see-all-btn text-end">
                      <Link to="/received-orders">
                        <Button
                          className="see-all btn px-4 py-2 text-decoration-none"
                          color="outline"
                        >
                          {Admin_Overview?.See_all[language]}
                        </Button>
                      </Link>
                    </div>
                  </>
                ) : null}
              </CardBody>
            </Card>
          </Col>
          {/* growth chart col */}
          <Col lg={6}>
            <Card className="dashboard-card-wrap growth-graph-card  shadow-sm">
              <CardHeader>

                <h6 className="heading">{Admin_Overview?.Order_Status[language]}</h6>
              </CardHeader>
              <CardBody>
                <div className="growth-graph-wrap">
                  <LineChartView linedata={Line_data} />
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* tickets col */}
          <Col lg={6}>
            <Card className="dashboard-card-wrap growth-graph-card overview-growth-card shadow-sm">
              <CardHeader>
                <h6 className="heading">{Admin_Overview?.Tickets[language]}</h6>

              </CardHeader>
              {!isLoading ? (
                <CardBody>
                  <div className="growth-graph-wrap">
                    {/* <PieChartView ticketdata={ticket_data} /> */}
                    {/* <TicketsChart data={ticket_data} /> */}
                    <RadialChart data={ticket_data} />
                  </div>
                </CardBody>
              ) : (
                <div className="loader">
                  <Loader />
                </div>
              )}
            </Card>
          </Col>
          <Col md={3}>
            <Card className="count-card-wrap" style={{ cursor: 'pointer' }} onClick={() => navigate("/received-orders")}>
              <CardBody title="Click to see Total Orders">
                <h6 className="heading">{Admin_Overview?.Total_Orders[language]}</h6>
                <div className="count-flex">
                  {order_count[0]?.total_orders >= 0 ? (
                    <h5 className="count primary-text">
                      {nFormatter(order_count[0]?.total_orders) || 0}
                    </h5>
                  ) : (
                    <h5 className="count  text-danger">
                      {nFormatter(order_count[0]?.total_orders) || 0}
                    </h5>
                  )}

                  {order_count[0]?.todays_sales_percentage >= 0 ? (
                    <>
                      <small className="count-growth primary-text">
                        {order_count[0]?.todays_sales_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-up.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>
                      {/* <small className="count-growth text-danger">
                        {order_count[0]?.todays_sales_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-down.svg" alt="img" />
                        </span>
                      </small> */}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="count-card-wrap" style={{ cursor: 'pointer' }} onClick={() => navigate("/approved-orders")}>
              <CardBody title="Click to see Approved Orders">
                <h6 className="heading">{Admin_Overview?.Approved_Orders[language]}</h6>
                <div className="count-flex">
                  {order_count[0]?.approved_orders >= 0 ? (
                    <h5 className="count primary-text">
                      {nFormatter(order_count[0]?.approved_orders) || 0}
                    </h5>
                  ) : (
                    <h5 className="count  text-danger">
                      {nFormatter(order_count[0]?.approved_orders) || 0}
                    </h5>
                  )}
                  {order_count[0]?.approved_orders_percentage >= 0 ? (
                    <>
                      <small className="count-growth primary-text">
                        {order_count[0]?.approved_orders_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-up.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>
                      {/* <small className="count-growth text-danger">
                        {order_count[0]?.approved_orders_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-down.svg" alt="img" />
                        </span>
                      </small> */}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="count-card-wrap" style={{ cursor: 'pointer' }} onClick={() => navigate("/delivered-orders")}>
              <CardBody title="Click to see Delivered Orders">
                <h6 className="heading">{Admin_Overview?.Delivered_Orders[language]}</h6>
                <div className="count-flex">
                  {order_count[0]?.delivered_orders >= 0 ? (
                    <h5 className="count primary-text">
                      {nFormatter(order_count[0]?.delivered_orders) || 0}
                    </h5>
                  ) : (
                    <h5 className="count  text-danger">
                      {nFormatter(order_count[0]?.delivered_orders) || 0}
                    </h5>
                  )}
                  {order_count[0]?.delivered_orders_percentage >= 0 ? (
                    <>
                      <small className="count-growth primary-text">
                        {order_count[0]?.delivered_orders_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-up.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>
                      {/* <small className="count-growth text-danger">
                        {order_count[0]?.delivered_orders_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-down.svg" alt="img" />
                        </span>
                      </small> */}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="count-card-wrap" style={{ cursor: 'pointer' }} onClick={() => navigate("/cancelled-orders")}>
              <CardBody title="Click to see Cancelled Orders">
                <h6 className="heading">{Admin_Overview?.Cancelled_Orders[language]}</h6>
                <div className="count-flex">
                  {order_count[0]?.cancelled_orders >= 0 ? (
                    <h5 className="count primary-text">
                      {nFormatter(order_count[0]?.cancelled_orders) || 0}
                    </h5>
                  ) : (
                    <h5 className="count  text-danger">
                      {nFormatter(order_count[0]?.cancelled_orders) || 0}
                    </h5>
                  )}
                  {order_count[0]?.cancelled_orders_percentage >= 0 ? (
                    <>
                      <small className="count-growth primary-text">
                        {order_count[0]?.cancelled_orders_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-up.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>
                      {/* <small className="count-growth text-danger">
                        {order_count[0]?.cancelled_orders_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-down.svg" alt="img" />
                        </span>
                      </small> */}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="count-card-wrap" style={{ cursor: 'pointer' }} onClick={() => navigate("/payment-received")}>
              <CardBody title="Click to see Total Payments">
                <h6 className="heading">{Admin_Overview?.Total_Payments[language]}</h6>
                <div className="count-flex">
                  {order_count[0]?.total_payment >= 0 ? (
                    <h5 className="count primary-text">
                      {nFormatter(order_count[0]?.total_payment) || 0}
                    </h5>
                  ) : (
                    <h5 className="count  text-danger">
                      {nFormatter(order_count[0]?.total_payment) || 0}
                    </h5>
                  )}

                  {order_count[0]?.total_payment_percentage >= 0 ? (
                    <>
                      <small className="count-growth primary-text">
                        {order_count[0]?.total_payment_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-up.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>
                      {/* <small className="count-growth text-danger">
                        {order_count[0]?.total_payment_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-down.svg" alt="img" />
                        </span>
                      </small> */}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="count-card-wrap" style={{ cursor: 'pointer' }} onClick={() => navigate("/payment-to-vendors")}>
              <CardBody title="Click to see Pending Payments">
                <h6 className="heading">{Admin_Overview?.Pending_Payments[language]}</h6>
                <div className="count-flex">
                  {order_count[0]?.pending_payment >= 0 ? (
                    <h5 className="count primary-text">
                      {nFormatter(order_count[0]?.pending_payment) || 0}
                    </h5>
                  ) : (
                    <h5 className="count text-danger">
                      {nFormatter(order_count[0]?.pending_payment) || 0}
                    </h5>
                  )}
                  {order_count[0]?.pending_payment_percentage >= 0 ? (
                    <>
                      <small className="count-growth primary-text">
                        {order_count[0]?.pending_payment_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-up.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>
                      {/* <small className="count-growth text-danger">
                        {order_count[0]?.pending_payment_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-down.svg" alt="img" />
                        </span>
                      </small> */}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* <Col md={3}>
            <Card className="count-card-wrap" style={{ cursor: 'pointer' }} onClick={() => navigate("/approved-orders")}>
              <CardBody title="Click to see Penalties">
                <h6 className="heading">Penalties</h6>
                <div className="count-flex">
                  {order_count[0]?.penalties >= 0 ? (
                    <h5 className="count primary-text">
                      {nFormatter(order_count[0]?.penalties) || 0}
                    </h5>
                  ) : (
                    <h5 className="count  text-danger">
                      {nFormatter(order_count[0]?.penalties) || 0}
                    </h5>
                  )}
                  {order_count[0]?.penalties_percentage >= 0 ? (
                    <>
                      <small className="count-growth primary-text">
                        {order_count[0]?.penalties_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-up.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>
                      <small className="count-growth text-danger">
                        {order_count[0]?.penalties_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-down.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col md={3}>
            <Card className="count-card-wrap" style={{ cursor: 'pointer' }} onClick={() => navigate("/pending-tickets")}>
              <CardBody title="Click to see Pending Tickets">
                <h6 className="heading">{Admin_Overview?.Pending_Tickets[language]}</h6>
                <div className="count-flex">
                  {order_count[0]?.pending_ticket >= 0 ? (
                    <h5 className="count primary-text">
                      {nFormatter(order_count[0]?.pending_ticket) || 0}
                    </h5>
                  ) : (
                    <h5 className="count  text-danger">
                      {nFormatter(order_count[0]?.pending_ticket) || 0}
                    </h5>
                  )}
                  {order_count[0]?.pending_ticket_percentage >= 0 ? (
                    <>
                      <small className="count-growth primary-text">
                        {order_count[0]?.pending_ticket_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-up.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  ) : (
                    <>
                      <small className="count-growth text-danger">
                        {order_count[0]?.pending_ticket_percentage?.toFixed(2) || 0}%
                        <span className="ms-1">
                          <img src="images/grow-down.svg" alt="img" />
                        </span>
                      </small>
                    </>
                  )}
                </div>
                
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </div>
    </React.Fragment>
  );
}
