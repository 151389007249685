import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Table,
} from "reactstrap";

import { Formik } from "formik";
import { Link } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import { api } from "../../../../Library/CustomApi";
import { verifyVendorSchema } from "../Modals/Formik Schemas";
import VerifyVendorModal from "../Modals/VerifyVendor/VerifyVendorModal";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import { toast } from "react-toastify";
import moment from "moment";
import { Context } from "../../../../Components/Context/Context";
import { admin_empanelment } from "../../../../Library/LanguageConversion/admin_empanelment";
import { VendorType } from "../../../../Library/Constants/helpers";

interface empMap {
  user: any;
  vendor_name: string;
  vendor_type: string;
  created_at: string;
  status: number;
}

const SelfVendorsTab = () => {
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showVerifyVendor, setShowVerifyVendor] = useState(false);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [get_vendor, setGetVendor] = useState({});
  const [status, setStatus] = useState({});
  const { language, setLanguage } = useContext(Context);
 
  // =========================================================== Search ===============================================================

  const handleSearch = (event: any) => {
    setCurrentPage(1)
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getTableData();
      }
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [search,currentPage]);

  //===================================================================================================================================

  const getTableData = () => {
    setIsLoading(true);
    api
      .get(
        `/vendors?status=2&page=${currentPage}&search_keyword=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setTableData(response?.data || []);
        setPageData(response?.meta || []);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err)
      });
  };

  const handleSubmit = (get_data: any) => {
    sendData(get_vendor, status);
  };
  const sendData = (get_vendor: any, status: any) => {
    api
      .post(
        `/verifyvendor`,
        {
          user_id: get_vendor?.user?.id,
          status:
            status == 1
              ? constants?.VENDOR_STAUS?.VERIFIED
              : constants?.VENDOR_STAUS?.REJECTED,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("user updated");
        setShowVerifyVendor(false);
        getTableData();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status == 422) {
        }
      });
  };
  return (
    <>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText className="search-icon" title="Search" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control border-start-0"
              placeholder="Vendor Name"
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100  table-striped">
          <thead>
            <tr>
            <th> {admin_empanelment?.Registration_Id?.[language]}</th>
              <th>{admin_empanelment?.Vendor_Name?.[language]}</th>
              <th>{admin_empanelment?.Registration_Date?.[language]}</th>
              <th>{admin_empanelment?.Vendor_type?.[language]}</th>
              <th>{admin_empanelment?.Email_ID?.[language]}</th>
              <th>{admin_empanelment?.Contact_Number?.[language]}</th>
              <th>{admin_empanelment?.Status?.[language]}</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.length !== 0 ? (
              tableData?.map((item: any, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <tr key={i}>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Vendor_Id?.[language]}:</span>
                        <span className="table-data">
                          {item?.registration_id}
                        </span>
                      </td>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Vendor_Name?.[language]}:</span>
                        <span className="table-data">{item?.company_name}</span>
                      </td>
                      <td>
                        {" "}
                        <span className="mobile-heading">
                        {admin_empanelment?.Registration_Date?.[language]}:
                        </span>
                        <span className="table-data">
                          {item.created_at
                            ? moment(item.created_at).format("DD-MM-YYYY")
                            : null}
                        </span>{" "}
                      </td>
                      <td>
                        <span className="mobile-heading">
                        {admin_empanelment?.Category_of_vendors?.[language]}:
                        </span>
                        <span className="table-data">
                        {VendorType(item?.vendor_type)}
                        </span>
                      </td>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Email_ID?.[language]}:</span>
                        <span className="table-data">{item.user.email}</span>
                      </td>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Contact_Number?.[language]}:</span>
                        <span className="table-data">{item.user.phone}</span>
                      </td>
                      <td>
                        <span className="mobile-heading">{admin_empanelment?.Status?.[language]}:</span>
                        <span className="table-data">
                          {item?.user?.status ==
                          constants.VENDOR_STAUS.VERIFIED ? (
                            "Verified"
                          ) : (
                            <Link
                              to="#"
                              className="verify-vendor-modal-btn text-primary"
                              onClick={() => {
                                setShowVerifyVendor(true);
                                setGetVendor(item);
                              }}
                            >
                              {admin_empanelment?.View_and_Verify?.[language]}
                            </Link>
                          )}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center px-2 py-5 empty-text">
                  {isLoading ?  <div className="d-flex justify-content-center">
                            <Loader />
                      </div>
                        : "No Vendors Found"}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {tableData?.length !== 0 && pageData && (
        <div className="table-footer-wrap">
          <small>
            Showing {pageData.from} to {pageData.to} of {pageData.total} entries
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      <Formik
        initialValues={{}}
        onSubmit={(status) => {
          handleSubmit(get_vendor);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <VerifyVendorModal
              showVerifyVendor={showVerifyVendor}
              setShowVerifyVendor={setShowVerifyVendor}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              resetForm={resetForm}
              get_vendor={get_vendor}
              setStatus={setStatus}
            />
          );
        }}
      </Formik>
    </>
  );
};
export default SelfVendorsTab;
