import React from "react";
import {useContext} from "react";
import { Context } from "../../../Components/Context/Context";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";

export default function OrderLayoutVendor(props: any) {
  const { pathname } = useLocation();
  const { language, setLanguage } = useContext(Context);
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/received-order-vendor">{VendorRegLang?.Received[language]}</NavLink>
            <NavLink to="/processed-order-vendor">{VendorRegLang?.Processed[language]}</NavLink>
            <NavLink to="/rejected-order-vendor">{VendorRegLang?.Rejected[language]}</NavLink>
            <NavLink to="/outofdelivery">{VendorRegLang?.OutForDelivery[language]}</NavLink>
            <NavLink to="/cancelled-order-vendor">{VendorRegLang?.cancelled_orders[language]}</NavLink>

          </div>
          {/* breadcrumb */}
          <List className=" breadcrumb">
            <li>
              <Link className="active" to="/overview-vendor">
              {VendorRegLang?.PurchaseOrders[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {pathname == "/received-order-vendor"
                  ? "Received Orders"
                  : pathname == "/cancelled-order-vendor"
                    ? "Cancelled Orders"
                    : pathname == "/processed-order-vendor"
                      ? "Processed Orders"
                      : pathname == "/outofdelivery" 
                        ? "Out For Delivery Orders"
                        :pathname == "/rejected-order-vendor"
                        ? "Rejected Orders"
                        : 
                         null}
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}