import React, { useContext } from "react";
import { List } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Context } from "../../../Components/Context/Context";
import { admin_empanelment } from "../../../Library/LanguageConversion/admin_empanelment";

interface propType { children: React.ReactElement<any, string > }

export default function Empanelment(props: propType) {
  const { pathname } = useLocation()
  const { language, setLanguage } = useContext(Context);
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs flex-full">
          <NavLink to="/empanelments" >{admin_empanelment?.Latest_Registered_Vendors?.[language]}</NavLink>
            <NavLink to="/verify-vendor" >{admin_empanelment?.Vendor_Verification?.[language]}</NavLink>
            {/* <NavLink to="/self-registered-vendors" >Self Registered Vendors</NavLink> */}
            <NavLink to="/active-vendors" >{admin_empanelment?.Empanelled_Vendors?.[language]}</NavLink>
            <NavLink to="/inactive-vendor" >{admin_empanelment?.Inactive_Vendors?.[language]}</NavLink>
            <NavLink to="/expired-vendor" >{admin_empanelment?.Expired_Vendors?.[language]}</NavLink>
            <NavLink to="/tobe-expired-vendor" >{admin_empanelment?.Vendors_Going_To_Expire?.[language]}</NavLink>
          
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link to="/dashboard" className="active">
              {admin_empanelment?.Dashboard?.[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b> 
                {
                  pathname === "/empanelments" ? "Latest Registered Vendors" :
                  pathname === "/verify-vendor" ? "Vendor Verification":
                  pathname === "/active-vendors" ? "Empanelled Vendors" :
                  pathname === "/inactive-vendor" ? "Inactive Vendors":
                  pathname === "/expired-vendor" ? "Expired Vendors":
                  pathname === "/tobe-expired-vendor" ? "Vendors Going To Expire":
                  pathname === "/self-registered-vendors" ? "Self Registered Vendors" :
                  null
                }
              </b>
            </li>
          </List>
        </div>

        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            {
              props.children
            }
          </div>
        </div>
        
      </div>
    </React.Fragment>
  );
}