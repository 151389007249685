import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  List,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Button
} from "reactstrap";
import ModalContainer from "../../../../../../Components/Modal/ModalContainer";
import { api } from "../../../../../../Library/CustomApi";
import Loader from "../../../../../../Components/Loader/Loader";
import CustomPagination from "../../../../../../Components/CustomPagination/Index";
import { convertISODateToDateTime } from "../../../../../../Library/Constants/helpers";
import AddCustomOrder from "./CustomOrderModals/AddCustomOrder";
import CustomOrderModalView from "./CustomOrderModals/CustomOrderModalView";
import ProductSuggestionModal from "./CustomOrderModals/ProductSuggestionModal";
import { spawn } from "child_process";
import { Context } from "../../../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../../../Library/LanguageConversion/Department_Products";

export default function CustomOrder(args: any) {
  const { language, setLanguage } = useContext(Context);
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOrderOpen, setIsOrderOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [searchVal, setSearchVal] = useState<string>('')
  const [customOrders, setCustomOrders] = useState<any>([])
  const [selectedOrder, setSelectedOrder] = useState<any>([])
  const [pageData, setPageData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number | string>(1)
  const [status, setStatus] = useState<boolean>(false)

  const [isSuggestionOpen, setIsSuggestionOpen] = useState<boolean>(false)

  useEffect(() => {
    getCustomOrders()
  }, [searchVal, currentPage, searchVal])

  // get custom order list
  const getCustomOrders = () => {
    setIsLoading(true)
    api
      .get(`/order/custom-order-list?search_value=${searchVal}&page=${currentPage}`, true)
      .then(async function ([success, response]) {
        setCustomOrders(response?.data)
        setPageData(response?.meta)
        setIsLoading(false)
        return response;
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      });
  };

  // order view handler 
  const orderViewHandler = (obj: any) => {
    setSelectedOrder(obj)
    setIsOrderOpen(true)
  }

  return (
    <React.Fragment>
      {/* inner contents starts here */}
      <div className="custom-tab-content">
        <div className="table-outer-wrap">
          {/* Search section */}
          <div className="search-widget-outer">
            <Form role="search" className="search end between">
              {/* <InputGroup className="search-group">
                  <InputGroupText className="search-icon" id="search">
                    <i className="fa-sharp fa-solid fa-magnifying-glass" />
                  </InputGroupText>
                  <Input
                    type="text"
                    className=""
                    placeholder="Search here"
                    aria-label="Username"
                    aria-describedby="search"
                    onChange={(e) => setSearchVal(e.target.value)}
                  />
                </InputGroup> */}

              {/* add custom order on mobile */}
              <div className="add-button mobile-view ">
                <Link to=""><Button color="primary" className="btn btn-primary bi bi-plus-lg" style={{ minWidth: 'auto' }} onClick={() => setIsOpen(true)}></Button></Link>
              </div>
            </Form>
            {/* add custom order on desktop */}
            <div className="add-button large-screen-view ">
              <Link to=""><Button color="primary" className="btn btn-primary" style={{ minWidth: 'auto' }} onClick={() => setIsOpen(true)}><i className="bi bi-plus"></i>{DepartmentProducts?.Add_Custom_Order[language]}</Button></Link>
            </div>
          </div>
          {/* Table section */}
          <div className="table-wrap responsive-table">
            <Table className="w-100 table-striped">
              <thead>
                <tr>
                  <th>{DepartmentProducts?.SlNo[language]}</th>
                  <th>{DepartmentProducts?.OrderID[language]}</th>
                  <th>{DepartmentProducts?.Product[language]}</th>
                  <th>{DepartmentProducts?.Category[language]}</th>
                  <th>{DepartmentProducts?.Date_of_Request[language]}</th>
                  <th>{DepartmentProducts?.Alternative_Suggestion[language]}</th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ? <>
                    <tr>
                      <td colSpan={6} className="empty-text text-center">
                        <Loader />
                      </td>
                    </tr>
                  </> :
                    (customOrders?.length !== 0 && !isLoading) ? customOrders?.map((obj: any, i: number) => {
                      return (
                        <React.Fragment key={i}>
                          <tr>
                            <td>
                              <span className="mobile-heading">{DepartmentProducts?.SlNo[language]}</span>
                              <span className="table-data">{i + 1}</span>
                            </td>
                            <td onClick={() => orderViewHandler(obj)}>
                              <span className="mobile-heading">{DepartmentProducts?.OrderID[language]}</span>
                              <Link to='#' className="primary-text" title="View Order Details">
                                <span className="table-data ">{obj?.custom_order_id}</span>
                              </Link>
                            </td>
                            <td>
                              <span className="mobile-heading">{DepartmentProducts?.Product[language]}</span>
                              <span className="table-data">{obj?.product_name}</span>
                            </td>
                            <td>
                              <span className="mobile-heading">{DepartmentProducts?.Category[language]}</span>
                              <span className="table-data">{obj?.category}</span>
                            </td>
                            <td>
                              <span className="mobile-heading">{DepartmentProducts?.Date_of_Request[language]}</span>
                              <span className="table-data">{convertISODateToDateTime(obj?.created_at)}</span>
                            </td>
                            <td>
                              <span className="mobile-heading">{DepartmentProducts?.Alternative_Suggestion[language]}</span>
                              {obj?.suggestion_status == 3 ?
                                <a className="table-data">{DepartmentProducts?.No_Suggestions[language]}</a> :
                                obj?.suggestion_status == 1 ?
                                  <a title="View Suggestion" onClick={() => {
                                    setIsSuggestionOpen(true)
                                    setSelectedOrder(obj)
                                  }}>
                                    <i title="View Suggestion" className="icons bi bi-eye lg"></i>
                                  </a> :
                                  obj?.suggestion_status == 2 ?
                                    <a className="table-data" onClick={() => {
                                      setIsSuggestionOpen(true)
                                      setSelectedOrder(obj)
                                    }}>{DepartmentProducts?.Accepted[language]}</a> :
                                    obj?.suggestion_status == 4 ?
                                      <a className="table-data">{DepartmentProducts?.Rejected[language]}</a> : <a className="table-data"></a>
                              }
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })
                      :
                      <>
                        <tr>
                          <td colSpan={6} className="empty-text text-center">{DepartmentProducts?.No_Orders_Found[language]}</td>
                        </tr>
                      </>
                }
              </tbody>
            </Table>
            {/* pagination  */}
            {pageData && customOrders?.length > 0 && <div className="table-footer-wrap">
              <small>{DepartmentProducts?.Showing[language]} {pageData.from} {DepartmentProducts?.to[language]} {pageData.to} {DepartmentProducts?.of[language]} {pageData?.total} {DepartmentProducts?.entries[language]}</small>
              <div className="pagination-outer">
                <CustomPagination
                  totalItems={pageData?.total}
                  itemsPerPage={pageData?.per_page}
                  activePage={pageData?.current_page}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>}
          </div>
        </div>
      </div>

      {/* modal  */}
      <ModalContainer open={isOpen} setOpen={setIsOpen} header={DepartmentProducts?.Add_Custom_Order[language]}>
        <AddCustomOrder setOpen={setIsOpen} setIsOpen={setIsOpen} getCustomOrders={getCustomOrders} />
      </ModalContainer>

      {/* Order view  */}
      <ModalContainer open={isOrderOpen} setOpen={setIsOrderOpen} header={selectedOrder?.product_name}>
        <CustomOrderModalView selectedOrder={selectedOrder} />
      </ModalContainer>

      {/* suggestion view modal  */}
      <ModalContainer open={isSuggestionOpen} setOpen={setIsSuggestionOpen} header={DepartmentProducts?.Suggestions[language]}>
        <ProductSuggestionModal id={selectedOrder?.id} setIsSuggestionOpen={setIsSuggestionOpen} status={selectedOrder?.suggestion_status} getCustomOrders={getCustomOrders} />
      </ModalContainer>
    </React.Fragment>
  );
}