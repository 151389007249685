import DeptUserDashboard from '../../../Pages/Department/Dashboard/Dashboard';
import AddressChange from '../../../Pages/Department/DeliveryAddress/AddressLayout';
import DraftTab from '../../../Pages/Department/Drafts/DraftsTab/DraftTab';
import PaymentDrafts from '../../../Pages/Department/Drafts/PaymentDrafts';
import WorkOrderTab from '../../../Pages/Department/Drafts/WorkOrderTab/WorkOrderTab';
import DeptOrderLayout from '../../../Pages/Department/Orders/DeptOrderLayout';
import DeptCancelledOrders from '../../../Pages/Department/Orders/Tabs/DeptCancelledOrders';
import DeptDeliveredOrders from '../../../Pages/Department/Orders/Tabs/DeptDeliveredOrders';
import PaymentPendingOrders from '../../../Pages/Department/Orders/Tabs/PaymentPendingOrders';
import PlacedOrders from '../../../Pages/Department/Orders/Tabs/PlacedOrders';
import ProcessingOrders from '../../../Pages/Department/Orders/Tabs/ProcessingOrders';
import CartLayout from '../../../Pages/Department/Products/Cart/CartLayout';
import DeptCart from '../../../Pages/Department/Products/Cart/Tabs/Cart';
import CustomOrder from '../../../Pages/Department/Products/Cart/Tabs/CustomOrder/CustomOrder';
import CustomOrderContainer from '../../../Pages/Department/Products/Cart/Tabs/CustomOrder/CustomOrderContainer';
import DeptProductList from '../../../Pages/Department/Products/ProductList';
import DeptProductsView from '../../../Pages/Department/Products/ProductsView';
import ProformaCheckOut from '../../../Pages/Department/Products/ProformaCheckOut';
import ProformaInvoice from '../../../Pages/Department/Products/ProformaInvoice';
import DeptAmc from '../../../Pages/Department/Services/AMC/DeptAmc';
import DeptTicketLayout from '../../../Pages/Department/Services/Tickets/DeptTicketLayout';
import DeptOpenTickets from '../../../Pages/Department/Services/Tickets/Tabs/OpenTickets';
import DeptPendingTickets from '../../../Pages/Department/Services/Tickets/Tabs/PendingTickets';
import DeptResolvedTickets from '../../../Pages/Department/Services/Tickets/Tabs/ResolvedTickets';
import DeptSettingsLayout from '../../../Pages/Department/Settings/SettingsLayout';
import { default as DeptProfileSettings } from '../../../Pages/Department/Settings/Tabs/ProfileSettings';
import TDSList from '../../../Pages/Department/TDS/TDSList';
import TDSListingLayout from '../../../Pages/Department/TDS/TDSListingLayout';
export const DeptRouteList = [
  // Dashboard
  {
    path: "/department/dashboard",
    Element: <DeptUserDashboard />,
  },
  // products
  {
    path: "/department/products/:page_no?",
    Element: <DeptProductList />,
  },
  {
    path: "/department/products-view/:id",
    Element: <DeptProductsView />,
  },
  {
    path: "/department/cart",
    Element:
      <CartLayout>
        <DeptCart />
      </CartLayout>,
  },
  {
    path: "/department/cart-custom-orders",
    Element:
      <CartLayout>
        <CustomOrder />
      </CartLayout>,
  },
  {
    path: "/department/proforma-invoice",
    Element: <ProformaInvoice />,
  },
  {
    path: "/department/proforma-checkout",
    Element: <ProformaCheckOut />,
  },
  // orders
  {
    path: "/department/placed-orders",
    Element:
      <DeptOrderLayout>
        <PlacedOrders />
      </DeptOrderLayout>,
  },
  {
    path: "/department/processing-orders",
    Element:
      <DeptOrderLayout>
        <ProcessingOrders />
      </DeptOrderLayout>,
  },
  {
    path: "/department/orders",
    Element:
      <DeptOrderLayout>
        <DeptDeliveredOrders />
      </DeptOrderLayout>,
  },
  {
    path: "/department/cancelled-orders",
    Element:
      <DeptOrderLayout>
        <DeptCancelledOrders />
      </DeptOrderLayout>,
  },
  {
    path: "/department/payment-pending-orders",
    Element:
      <DeptOrderLayout>
        <PaymentPendingOrders />
      </DeptOrderLayout>,
  },
  // tds
  {
    path: "/department/tds",
    Element:
      <TDSListingLayout>
        <TDSList />
      </TDSListingLayout>
  },
  // custom orders
  {
    path: "/department/custom-orders",
    Element:
      <CustomOrderContainer>
        <CustomOrder />
      </CustomOrderContainer>,
  },
  // tickets
  {
    path: "/department/tickets",
    Element:
      <DeptTicketLayout>
        <DeptOpenTickets />
      </DeptTicketLayout>,
  },
  // {
  //   path: "/department/processing-tickets",
  //   Element: <DeptTicketLayout> <DeptProcessingTickets /> </DeptTicketLayout>,
  // },
  {
    path: "/department/pending-tickets",
    Element:
      <DeptTicketLayout>
        <DeptPendingTickets />
      </DeptTicketLayout>,
  },
  {
    path: "/department/resolved-tickets",
    Element:
      <DeptTicketLayout>
        <DeptResolvedTickets />
      </DeptTicketLayout>,
  },
  // amc
  {
    path: "/department/amc",
    Element: <DeptAmc />,
  },
  // draft
  {
    path: "/department/draft",
    Element:
      <PaymentDrafts >
        <DraftTab />
      </PaymentDrafts>,
  },
  {
    path: "/department/workorder",
    Element:
      <PaymentDrafts >
        <WorkOrderTab />
      </PaymentDrafts>,
  },
  // address
  {
    path: "/department/delivery-address",
    Element: <AddressChange />,
  },
  // profile
  {
    path: "department/settings",
    Element:
      <DeptSettingsLayout>
        <DeptProfileSettings />
      </DeptSettingsLayout>,
  },
]