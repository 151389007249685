import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import TextError from "../../../../Components/Errors/TextError";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import Select from "react-select";
import { api } from "../../../../Library/CustomApi";
import axios from "axios";
import { API_URL } from "../../../../Library/ApiSettings";
import { toast } from "react-toastify";
import PaymentTypesSelector from "../../../Department/Products/PaymentMethodComponents/PaymentTypesSelector";
import { Admin_Delivered } from "../../../../Library/LanguageConversion/Admin_Delivered";
import { Context } from "../../../../Components/Context/Context";
const PaymentForVendors: any = (props: any) => {
  const [value, setValue] = useState<any>([]);
  const [selectedDeductions, setSelectedDeductions] = useState<any>([])
  const [deductions, setDeductions] = useState<any>([])
  const [addedDeductions, setAddedDeductions] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedMethod, setSelectedMethod] = useState<number>(0)
  const {language,setLanguage} = useContext(Context)

  useEffect(() => {
    getDeductions()
    if (props?.payment_data?.id) {
      getAddedDeductions()
    }
  }, [props])

  // gets deductions information
  const getDeductions = () => {

    api
      .get(
        `/deduction/2`,
        true
      )
      .then(async function ([success, response, status]) {
        let datas: any = [];
        response?.map((elem: any) => {
          datas.push(
            {
              value: elem?.id,
              label: elem?.name,
              rate: elem?.value,
            });

        });
        setDeductions(datas || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      }
      )
  }

  // removes deductions 
  const removeDeductionHandler = (index: number) => {
    setSelectedDeductions(selectedDeductions?.filter((itm: any, i: number) => i !== index));
  }


  // handles save deductions 
  const handleSaveDeduction = () => {

    // setIsLoading(true)
    const formData = new FormData();
    var data: any = new Array();

    selectedDeductions.forEach((el: any) => {
      data.push({
        invoice_number: props?.payment_data?.id,
        item: el?.label,
        rate: el?.rate,
        amount: (props?.payment_data?.total_amount * (el?.rate / 100))
      });
    });

    formData.append("deduction", JSON.stringify(data));
    const body = formData;

    axios({
      method: "post",
      url: `${API_URL}/save_ver_deduction`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        console.log(response);
        toast?.success('Saved')
        if (props?.getPaymentData) {
          props?.getPaymentData(props?.payment_data?.id)
        }
      })
      .catch(function (response) {
        setIsLoading(false)
        console.log(response);
        toast.error('Error')
      });
  }

  // gets already added  tds deductions 
  const getAddedDeductions = () => {
    api
      .get(`/get_ven_deduction/${props?.payment_data?.id}`, true)
      .then(async function ([success, response]) {
        setAddedDeductions(response)
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.modal1}
        setOpen={props?.toggle1}
        header='Money Transfer to Vendor'
      >
        <div className="feeback-outer-wrap">
          <div className="money-transfer-wrap">
            <div className="feedback-main-heading">
            </div>
            {/* <h5 className="title">Money Transfer to Vendor</h5> */}
            <p className="transfer-details">
              {props?.payment_data?.order_details?.product_details?.name}

              <br />
              <span>
                {" "}
                {Admin_Delivered?.Quantity?.[language]}:{props?.payment_data?.order_details?.quantity} {Admin_Delivered?.Pieces?.[language]}
              </span>
              <br />
              <span>{Admin_Delivered?.Unit_Price?.[language]}:₹{props?.payment_data?.amount} </span>
            </p>
            <p className="transfer-price">
              {Admin_Delivered?.Total_Price?.[language]}: ₹ {(props?.payment_data?.total_amount)?.toFixed(2)}
            </p>
            <div className="transfer-account-details">
              {/* <div > */}
              <Formik
                initialValues={props?.initialValues}
                validationSchema={props?.PaymentValidationSchema}
                onSubmit={(values, actions) => {
                  props?.onSubmit(values, actions);
                }}
              >
                {({
                  isSubmitting,
                  values,
                  handleSubmit,
                  handleChange,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <p>
                      {Admin_Delivered?.OrderId?.[language]}:&nbsp;
                        <span>{props?.payment_data?.vendor_po_no}</span>
                      </p>
                      <p>
                      {Admin_Delivered?.VendorName?.[language]}:&nbsp;
                        <span>
                          {props?.payment_data?.cmpny_detail?.name}
                        </span>
                      </p>

                      {/* deductions  */}
                      <div className='p-3'>
                        <h4>{Admin_Delivered?.Deductions?.[language]}</h4>
                        {addedDeductions?.data?.length == 0 ?
                          <>
                            <Col sm={12}>
                              <Select
                                name="category"
                                options={deductions}
                                value={selectedDeductions}
                                onChange={(category: any) => setSelectedDeductions(category)}
                                isLoading={false}
                                isSearchable={true}
                                isMulti={true}
                                isClearable
                              />
                            </Col>

                            <Table className='table-striped'>
                              <thead>
                                <tr>
                                  <th>{Admin_Delivered?.SlNo?.[language]}</th>
                                  <th>{Admin_Delivered?.Item?.[language]}</th>
                                  <th>{Admin_Delivered?.Rate?.[language]}</th>
                                  <th>{Admin_Delivered?.Amount?.[language]}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedDeductions?.length !== 0 ?
                                  selectedDeductions?.map((it: any, i: number) => {

                                    return (
                                      <tr key={i}>
                                        <td>
                                          <span className="mobile-heading">{Admin_Delivered?.SlNo?.[language]}:</span>
                                          <span className='table-data'>
                                            <p>{i + 1}</p>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="mobile-heading">{Admin_Delivered?.Item?.[language]}:</span>
                                          <span className='table-data'>
                                            <p>{it?.label}</p>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="mobile-heading">{Admin_Delivered?.Rate?.[language]}:</span>
                                          <span className='table-data'>
                                            <p>{it?.rate} %</p>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="mobile-heading">{Admin_Delivered?.Amount?.[language]}:</span>
                                          <span className='table-data'>
                                            <p>₹ {(props?.payment_data?.total_amount * (it?.rate / 100)).toFixed(2)}</p>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="mobile-heading"></span>
                                          <span className='table-data'>
                                            <span onClick={() => removeDeductionHandler(i)}>{Admin_Delivered?.Remove?.[language]}</span>
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  })
                                  :
                                  <tr>
                                    <td colSpan={5} className='text-center'>{Admin_Delivered?.No_Deductions_Selected?.[language]}</td>
                                  </tr>
                                }
                                {selectedDeductions?.length !== 0 && <Button onClick={handleSaveDeduction} disabled={isLoading}>
                                  {
                                    isLoading ?
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      /> :
                                      'Save'
                                  }
                                </Button>}
                              </tbody>
                            </Table>
                          </>

                          :
                          <>
                            <Table className='table-striped'>
                              <thead>
                                <tr>
                                  <th>{Admin_Delivered?.SlNo?.[language]}</th>
                                  <th>{Admin_Delivered?.Item?.[language]}</th>
                                  <th>{Admin_Delivered?.Rate?.[language]}</th>
                                  <th>{Admin_Delivered?.Amount?.[language]}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  (addedDeductions?.data?.length !== 0) ?
                                    addedDeductions?.data?.map((it: any, i: number) => {

                                      return (
                                        <tr key={i}>
                                          <td>
                                            <span className="mobile-heading">{Admin_Delivered?.SlNo?.[language]}:</span>
                                            <span className='table-data'>
                                              <p>{i + 1}</p>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="mobile-heading">{Admin_Delivered?.Item?.[language]}:</span>
                                            <span className='table-data'>
                                              <p>{it?.item}</p>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="mobile-heading">{Admin_Delivered?.Rate?.[language]}:</span>
                                            <span className='table-data'>
                                              <p>{it?.rate} %</p>
                                            </span>
                                          </td>
                                          <td>
                                            <span className="mobile-heading">{Admin_Delivered?.Amount?.[language]}:</span>
                                            <span className='table-data'>
                                              <p>₹{it?.amount} </p>
                                            </span>
                                          </td>
                                        </tr>
                                      )
                                    })
                                    :
                                    <tr>
                                      <td colSpan={5} className='text-center'>{Admin_Delivered?.No_Deductions_Saved_for_this_invoice?.[language]}</td>
                                    </tr>}

                                {props?.payment_data?.ven_deduction && <tr>
                                  <td colSpan={2} className='text-center'></td>
                                  <td >{Admin_Delivered?.Total?.[language]}</td>
                                  <td >₹{addedDeductions?.total}</td>
                                </tr>}
                              </tbody>
                            </Table>
                            <h3>{Admin_Delivered?.Payable_Amount?.[language]}: ₹{(props?.payment_data?.total_amount - addedDeductions?.total)?.toFixed(2)}</h3>
                            <hr />
                          </>
                        }
                      </div>
                      {false && <div className="proforma-checkout-wrap">
                        <Col lg={7}>
                          <div className="choose-payment-outer-wrap">
                            <Col md={12}>
                              <div className="choose-payment-wrap">

                                <PaymentTypesSelector
                                  selectedMethod={selectedMethod}
                                  setSelectedMethod={setSelectedMethod}
                                  HideNB
                                />

                              </div>
                            </Col>
                          </div>
                        </Col>
                      </div>}


                      <FormGroup>
                        <Label>{Admin_Delivered?.Transaction_Number?.[language]}</Label>
                        <Input
                          type="text"
                          value={values?.transaction}
                          name="transaction"
                          onChange={handleChange}
                          className={`form-control ${touched.transaction && errors.transaction
                            ? "is-invalid"
                            : ""
                            }`}
                        />
                        <ErrorMessage
                          name="transaction"
                          component={TextError}
                        />
                      </FormGroup>

                      <div className="transfer-btn">
                        <Button
                          title="Transfer Money to Vendor"
                          color="primary"
                          className={"px-5"}
                          type="submit"
                          onClick={() => {
                            setFieldValue('tds_deduction', props?.payment_data?.total_amount - addedDeductions?.total)
                          }}
                        >
                          {Admin_Delivered?.TransferMoneytoVendor?.[language]}
                        </Button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
              {/* <p>
                    Vendor Name:&nbsp;<span>xxx xxx xxx</span>
                  </p>
                  <p>
                    Vendor Name:&nbsp;<span>xxx xxx xxx</span>
                  </p> */}
              {/* </div> */}
              {/* <p>Transaction Id: xxx xxx xxx </p> */}
            </div>
          </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default PaymentForVendors;
