export const DepartmentSettings:any={

    // Settings/Tabs/ProfileSettings
    Full_Name: {
        en: 'Full Name',
        ka: 'ಪೂರ್ಣ ಹೆಸರು'
    },
    Department_Name: {
        en: 'Department Name',
        ka: 'ಇಲಾಖೆಯ ಹೆಸರು'
    },
     Phone_Number: {
        en: 'Phone Number',
        ka: 'ದೂರವಾಣಿ ಸಂಖ್ಯೆ'
    },
     Password: {
        en: 'Password',
        ka: 'ಪಾಸ್ವರ್ಡ್'
    },
     Email: {
        en: 'Email',
        ka: 'ಇಮೇಲ್'
    }, 
    Update: {
        en: 'Update',
        ka: 'ನವೀಕರಿಸು'
    }, 
    Cancel: {
        en: '  ',
        ka: 'ರದ್ದು ಮಾಡು'
    }, 
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    Enter_Password: {
        en: 'Enter Password',
        ka: 'ಪಾಸ್ವರ್ಡ್ ನಮೂದಿಸಿ'
    }, 
    Confirm_Password: {
        en: 'Confirm Password',
        ka: 'ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ'
    }, 
    Enter_OTP: {
        en: 'Enter OTP',
        ka: 'ಒ.ಟಿ.ಪಿ ನಮೂದಿಸಿ'
    }, 

    Verified: {
        en: 'Verified',
        ka: 'ಪರಿಶೀಲಿಸಲಾಗಿದೆ'
    },
    // checked
    // need to translate
    Verify_OTP: {
        en: 'Verify OTP',
        ka: 'OTP ಪರಿಶೀಲಿಸಿ'
    },

    Change_password: {
        en: 'Change Password',
        ka: 'ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ'
    },
    Verify_link: {
        en: 'A Verification link has been sent to your email account',
        ka: 'ನಿಮ್ಮ ಇಮೇಲ್ ಖಾತೆಗೆ ಪರಿಶೀಲನಾ ಲಿಂಕ್ ಕಳುಹಿಸಲಾಗಿದೆ'
    },
    Please_click: {
        en: 'Please click on the link that has been sent to your email account to verify your email.',
        ka: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಖಾತೆಗೆ ಕಳುಹಿಸಲಾದ ಲಿಂಕ್‌ಗೆ ಕ್ಲಿಕ್ ಮಾಡಿ ನಿಮ್ಮ ಇಮೇಲ್ ಖಾತೆಯನ್ನು ಪರಿಶೀಲಿಸಿ'
    },

   
}