import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./Themes/Keonics/SCSS/main.min.css";
import 'react-toastify/dist/ReactToastify.css';
import RoutesData from "./Components/Route/RoutesData";
import ThemeWrap from "./ThemeWrap";
import { LandingPageContextProvider } from "./LandingPage/Components/Contexts";

// import '../node_modules/font-awesome/css/font-awesome.min.css'; 
// import '@fortawesome/fontawesome-free/css/all.min.css';

const App = () => {
  
  return (
    <ThemeWrap>
      <LandingPageContextProvider>
        <BrowserRouter>
          {/* parent route component */}

          <RoutesData />
        </BrowserRouter>
      </LandingPageContextProvider>

    </ThemeWrap>
  );
};

export default App;