export const Admin_Overview: any = {
    Total_Orders: {
        en: 'Total Orders',
        ka: 'ಡ್ಒಟ್ಟು ಆದೇಶಗಳು'
    },
    This_Week: {
        en: 'This Week',
        ka: 'ಈ ವಾರ'
    },
    This_Month: {
        en: 'This Month',
        ka: 'ಈ ತಿಂಗಳು'
    },
    This_Year: {
        en: 'This Year',
        ka: 'ಈ ವರ್ಷ'
    },
    Download: {
        en: 'Download',
        ka: 'ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ'
    },
    Empanelments: {
        en: 'Empanelments',
        ka: 'ಆಯೋಜನಾಧೀನಗೊಳಿಸುವುದು'
    },

    Pending_For_Approval: {
        en: 'Pending For Approval',
        ka: 'ಅನುಮೋದನೆಗಾಗಿ ಬಾಕಿ ಇದೆ'
    },

    Approved_Vendors: {
        en: 'Approved Vendors',
        ka: 'ಅನುಮೋದಿತ ವೆಂಡರ್ಗಳು'
    },

    Invited_Vendors: {
        en: 'Invited Vendors',
        ka: 'ಆಹ್ವಾನಿತ ವೆಂಡರ್ಗಳು'
    },
    Sales_by_Category: {
        en: 'Sales by Category',
        ka: 'ವರ್ಗಾನುಸಾರ ಮಾರಾಟ'
    },
    Orders: {
        en: 'Orders',
        ka: 'ಆದೇಶಗಳು'
    },
    Product_Name: {
        en: 'Product Name',
        ka: 'ಉತ್ಪಾದನ ಹೆಸರು'
    },
    Quantity: {
        en: 'Quantity',
        ka: 'ಪ್ರಮಾಣ'
    },
    Order_ID: {
        en: 'Order ID',
        ka: 'ಆದೇಶ ಐಡಿ'
    },
    Amount: {
        en: 'Amount',
        ka: 'ಮೊತ್ತ'
    },
    See_all: {
        en: ' See all',
        ka: 'ಎಲ್ಲನ್ನೂ ನೋಡಿ'
    },
    Order_Status: {
        en: 'Order Status',
        ka: 'ಆದೇಶ ಸ್ಥಿತಿ'
    },
    Tickets: {
        en: 'Tickets',
        ka: 'ಟಿಕೆಟ್ಗಳು'
    },
    Approved_Orders: {
        en: 'Approved Orders',
        ka: 'ಅನುಮೋದಿತ ಆದೇಶಗಳು'
    },
    Delivered_Orders: {
        en: 'Delivered Orders',
        ka: 'ಬಹಿರಂಗಗೊಳ್ಳಲಾದ ಆದೇಶಗಳು'
    },
    Cancelled_Orders: {
        en: 'Cancelled Orders',
        ka: 'ರದ್ದುಗೊಳಿಸಲಾದ ಆದೇಶಗಳು'
    },
    Total_Payments: {
        en: 'Total Payments',
        ka: 'ಒಟ್ಟು ಪಾವತಿಗಳು'
    },
    Pending_Payments: {
        en: 'Pending Payments',
        ka: 'ಪೂರ್ಣಗೊಳ್ಳಲಾಗದ ಪಾವತಿಗಳು'
    },
    Pending_Tickets: {
        en: 'Pending Tickets',
        ka: 'ಬಾಕಿಯಿರುವ ಟಿಕೆಟ್ಗಳು'
    },
    Penalties: {
        en: 'Penalties',
        ka: 'ದಂಡಗಳು'
    },
    latest_orders: {
        en: 'Latest Orders',
        ka:'ಇತ್ತೀಚಿನ ಆದೇಶಗಳು'
    },
}