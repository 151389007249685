import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../../Components/Context/Context";
import { Vendor_Services } from "../../../Library/LanguageConversion/Vendor_Services";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Label,

} from "reactstrap";
import { api } from "../../../Library/CustomApi";
import Toast from "reactstrap";
import { toast } from "react-toastify";
import { order_status, ticket_status } from "../../../Library/Constants/MapConstants";
import TextError from "../../../Components/Errors/TextError";
import { ErrorMessage } from "formik";
import ModalContainer from "../../../Components/Modal/ModalContainer";


const TicketModal = (props: any) => {
    const { language, setLanguage } = useContext(Context);
    const [days, setDays] = useState(0)
    const generateinvoice = () => {
        api
            .post(`/order/invoice`, { vendorOrderId: props?.order_view[0]?.vendor_po_no }, true)
            .then(async function ([success, response]) {
                toast.success("Invoice Generated Successfully")
                props?.toggle()
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const date_c: any = new Date()
    let start_date: any = `${new Date().toLocaleString("default", {
        year: "numeric",
    })}-${new Date().toLocaleString("default", {
        month: "2-digit",
    })}-${new Date().toLocaleString("default", { day: "2-digit" })}`;
    const today = new Date().toISOString().slice(0, 16);

    useEffect(() => {
        const date1: any = new Date(props?.values?.resolved_date_start);
        const date2: any = new Date(props?.values?.resolved_date_end);

        // Calculate the difference in milliseconds
        const diffInMs = Math.abs(date2 - date1);

        // Convert milliseconds to days
        const diffInDays: any = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        // Output the result
        setDays(diffInDays == 0 ? 0.5 : diffInDays || 0)

        props?.setFieldValue("time_taken", diffInDays)

    }, [props?.values?.resolved_date_start, props?.values?.resolved_date_end])

    return (
        <React.Fragment>
            <ModalContainer
                open={props?.isShowing}
                setOpen={() => {
                    props?.setIsShowing(false);
                    props?.handleReset();
                }}
                header='Tickets'
            >
                <div className="feeback-outer-wrap">

                    <div className="feedback-wrap">
                        <div className="feedback-details ">
                            <Form className="form-wrap">
                            

                                {/* {props?.order_view?.length !== 0 ? (
                                        <>
                                            {props?.order_view?.map((item: any, i: any) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <h4>
                                                            <b>Order ID :</b>
                                                            #{item?.vendor_po_no}
                                                        </h4>
                                                        <h4>
                                                            <b>Product Name :</b> {item?.order_details?.name}|{" "}
                                                            {item?.order_details?.product_code} |{" "}
                                                            {item?.order_details?.hsn_code}
                                                        </h4>
                                                        <h4><u><b>Discription</b></u></h4>
                                                        <b>

                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        item?.order_details?.features,


                                                                }}
                                                            ></span>
                                                        </b>
                                                        <h4>
                                                            <b>Quantity :</b> {item?.order_details?.quantity}
                                                        </h4>
                                                        <h4>
                                                            <b>Unit Price :</b>{" "}
                                                            {item?.order_details?.unit_price}
                                                        </h4>
                                                        <h4>
                                                            <strong>Total Amount :</strong>{" "}
                                                            {item?.order_details?.total_amount}
                                                        </h4>
                                                        <small className="date">
                                                            Delivery date{" "}
                                                            <span className="date-span">
                                                                {moment(item?.delivery_date).format("DD-MM-YYYY")}
                                                            </span>
                                                        </small>
                                                        <hr />


                                                    </React.Fragment>
                                                );
                                            })}
                                            */}
                                <Row>
                                <FormGroup className="form-group">
                                <Label>
                                {Vendor_Services?.Ticket_Status[language]}{" "}
                                    <i
                                        className="fa fa-asterisk required-field"
                                        color="text-danger"
                                        aria-hidden="true"
                                    ></i>
                                </Label>

                                <Input
                                    type="select"
                                    name="ticket_status"
                                    value={props?.values?.ticket_status}

                                    data={ticket_status}
                                    onChange={props?.handleChange}
                                    className={`form-control ${props?.touched.ticket_status && props?.errors.ticket_status
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                >
                                    <option value=""> {Vendor_Services?.Select_Order_Status[language]}</option>;
                                    {ticket_status?.map((item: any, i: number) => {
                                        return (
                                            <React.Fragment key={item?.value}>
                                                <option key={i} value={item?.value}>
                                                    {" "}
                                                    {item?.name}
                                                </option>
                                            </React.Fragment>
                                        );
                                    })}
                                </Input>
                                <ErrorMessage name="ticket_status" component={TextError} />
                                </FormGroup>





                                {props?.value?.open == 1 ? null :

                                    <FormGroup className="demo form-group">
                                        <div className="custom-input">
                                            <Label> {Vendor_Services?.Resolved_Date[language]} </Label>
                                            <Input
                                                name="resolved_date"
                                                type="date"
                                                min={start_date}
                                                value={props?.values?.resolved_date}
                                                className={`form-control ${props?.touched?.resolved_date && props?.errors?.resolved_date
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                onChange={props?.handleChange}

                                            />
                                            <ErrorMessage
                                                name="resolved_date"
                                                component={TextError}
                                            />
                                        </div>
                                    </FormGroup>
                                }

                                <FormGroup className="demo form-group">
                                    <div className="custom-input">
                                        <Label> {Vendor_Services?.comments[language]} </Label>
                                        <Input
                                            name="comment"
                                            type="textarea"
                                            value={props?.values?.comment}
                                            className={`form-control ${props?.touched?.comment && props?.errors?.comment
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            onChange={props?.handleChange}

                                        />
                                        <ErrorMessage
                                            name="comment"
                                            component={TextError}
                                        />
                                    </div>
                                </FormGroup>
                                {props?.value?.open == 1 ? null :
                                    <>
                                        
                                            <Col md={6} lg={6}>
                                                <FormGroup className="demo form-group">
                                                    <div className="custom-input">
                                                        <Label> {Vendor_Services?.Resolve_start_Date[language]}</Label>
                                                        <Input
                                                            name="resolved_date_start"
                                                            type="datetime-local"
                                                            min={today}
                                                            value={props?.values?.resolved_date_start}
                                                            className={`form-control ${props?.touched?.resolved_date_start && props?.errors?.resolved_date_start
                                                                ? "is-invalid"
                                                                : ""
                                                                }`}
                                                            onChange={props?.handleChange}

                                                        />
                                                        <ErrorMessage
                                                            name="resolved_date_start"
                                                            component={TextError}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <FormGroup className="demo form-group">
                                                    <div className="custom-input">
                                                        <Label> {Vendor_Services?.Resolve_end_Date[language]} </Label>
                                                        <Input
                                                            name="resolved_date_end"
                                                            type="datetime-local"
                                                            value={props?.values?.resolved_date_end}
                                                            className={`form-control ${props?.touched?.resolved_date_end && props?.errors?.resolved_date_end
                                                                ? "is-invalid"
                                                                : ""
                                                                }`}
                                                            min={today}
                                                            onChange={props?.handleChange}

                                                        />
                                                        <ErrorMessage
                                                            name="resolved_date_end"
                                                            component={TextError}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                       

                                        
                                        <FormGroup className="demo form-group">
                                            <div className="custom-input">
                                                <Label> {Vendor_Services?.How_many[language]}</Label>
                                                <Input
                                                    name="time_taken"
                                                    type="text"
                                                    value={days}
                                                    className={`form-control ${props?.touched?.time_taken && props?.errors?.time_taken
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    onChange={props?.handleChange}
                                                    disabled

                                                />
                                                <ErrorMessage
                                                    name="time_taken"
                                                    component={TextError}
                                                />
                                            </div>
                                        </FormGroup>
                                    </>
                                }
                                    <FormGroup className="footer-sticky">
                                        <div className="text-end">
                                            <FormGroup >
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    onClick={props?.handleSubmit}
                                                    className={"px-5 mt-2"}
                                                    disabled={props?.isSubmitting}
                                                >
                                                    Save
                                                </Button>
                                            </FormGroup>

                                            {/* <div className="btn btn-primary" onClick={generateinvoice}>Generate Invoices</div> */}
                                        </div>

                                    </FormGroup>
                                    </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </React.Fragment >
    );
};

export default TicketModal;
