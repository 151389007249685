import * as Yup from "yup";

export const addVendorSchema = Yup.object().shape({
  vendor_type: Yup.string().required("Vendor type is required"),
  gst_class: Yup.string().required("GST class is required"),
  pan_number: Yup.string()
    .required("PAN number is required")
    .min(10, "PAN Number should be atleast 10 characters")
    .max(10, "PAN Number should not be more than 10 characters"),
  gst_number: Yup.string()
    .required("GST number is required")
    .min(15, "GST Number should be atleast 15 characters")
    .max(15, "GST Number should not be more than 15 characters"),
  company_name: Yup.string().required("Company name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required"),
  full_name: Yup.string().required("Person name is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
    .min(10, "Phone number must be at least 10 digits"),
  registration_id: Yup.string().when('company_type', {
    is: '4',
    then: Yup.string().required('Company Registration Number is required'),
    otherwise: Yup.string().notRequired(),
  }),
  company_type: Yup.string().required("Company type is required"),
  comp_inc_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      // const result = parent(originalValue, "dd.MM.yyyy", new Date());
      // return result;
    })
    .typeError("Please enter a valid date")
    .when('company_type', {
      is: '4',
      then: Yup.date().required('Company Incorporation Date is required'),
      otherwise: Yup.date().notRequired(),
    }),
  expiry_date: Yup.date().required('Expiry date is required'),
  empanelment_date: Yup.date().required('Empanelment date is required'),
  // startup_certificate:Yup.string().required('Startup Certificate is required')

});

export const inviteVendorSchema = Yup.object().shape({
  vendor_name: Yup.string().required("Vendor name is required"),
  company_name: Yup.string().required("Company name is required"),
  tender_id: Yup.string().required("Tender id is required"),
  mobile_number: Yup.number().required("Mobile number is required"),
  email_id: Yup.string().email("Invalid email").required("Email is required"),
  product: Yup.string().required("Product is required"),
});
export const verifyVendorSchema = Yup.object().shape({
  vendor: Yup.string().required("Vendor required"),
  gst_class: Yup.string().required("Vendor required"),
  pan_number: Yup.string().required("Pan number is required"),
  gst_number: Yup.string().required("Gst number is required"),
  company_name: Yup.string().required("Company name is required"),
  company_email: Yup.string()
    .email("Invalid email")
    .required("Company email is required"),
  company_incorporate_date: Yup.date().required("Date is required"),
  person_name: Yup.string().required("Person name is required"),
  contact_number: Yup.number().required("Contact number is required"),
});
