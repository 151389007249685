import React, { useEffect ,useContext} from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import { useState, useMemo } from "react";
import { api } from "../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../Components/Context/Context";
import { VendorSignup } from "../../../Library/LanguageConversion/Vendor_SignUp";


export default function EmdDetailsSelfRegisterVendor() {
  const navigate = useNavigate();
  const { language, setLanguage } = useContext(Context);
  const [category, setCategory] = useState({
    emd: "",
    category: "",
  });
  const [emdhistory, setEmdhistory] = useState<any>([]);
  useEffect(() => {
    emds();
    emdlist();
  }, []);
  const emds = () => {
    api
      .get(`/vendor/emd`, true)
      .then(async function ([success, response]) {
        let v: any = response;
        setCategory({ emd: v.emd, category: v.category });
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const emdlist = () => {
    api
      .get(`/vendor/emd-history`, true)
      .then(async function ([success, response]) {
        setEmdhistory(response?.data?.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="login-content">
        <Form className="Reg-wrap">
          <Row>
            <Col md={4} sm={6}>
              <FormGroup>
                <div className="emd-check-wrap">
                  {category.category == "1" ? (
                    <>
                      <Input type="radio" checked></Input>
                      <Label>
                      {VendorSignup?.for_A_Category[language]}
                        <br />
                        <span> {VendorSignup?.Above_20_Crores_NRLA[language]} ₹{category?.emd}/-</span>
                      </Label>
                    </>
                  ) : (
                    <>
                      <Input type="radio" disabled></Input>
                      <Label>
                      {VendorSignup?.for_A_Category[language]}
                        <br />
                        <span> {VendorSignup?.Above_20_Crores[language]}</span>
                      </Label>
                    </>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <FormGroup>
                <div className="emd-check-wrap">
                  {category.category == "2" ? (
                    <>
                      <Input type="radio" checked></Input>
                      <Label>
                      {VendorSignup?.For_B_Category[language]}
                        <br />
                        <span> {VendorSignup?.ten_20Crores_NRLA[language]} ₹{category.emd}/-</span>
                      </Label>
                    </>
                  ) : (
                    <>
                      <Input type="radio" disabled></Input>
                      <Label>
                      {VendorSignup?.For_B_Category[language]}
                        <br />
                        <span>10-20 Crores</span>
                      </Label>
                    </>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <FormGroup>
                <div className="emd-check-wrap">
                  {category.category == "3" ? (
                    <>
                      <Input type="radio" checked></Input>
                      <Label>
                      {VendorSignup?.For_C_Category[language]}
                        <br />
                        <span>{VendorSignup?.Upto_10_Crores_NRLA[language]} ₹{category.emd}/-</span>
                      </Label>
                    </>
                  ) : (
                    <>
                      <Input type="radio" disabled></Input>
                      <Label>
                      {VendorSignup?.For_C_Category[language]}
                        <br />
                        <span>{VendorSignup?.Upto_10_Crores[language]}</span>
                      </Label>
                    </>
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {emdhistory.length != 0 ? (
                <div className="table-outer-wrap">
                  <h6 className="heading">{VendorSignup?.History_details[language]}</h6>
                  <div className="history-table">
                    <Table className="w-100">
                      <thead>
                        <tr>
                          <th>{VendorSignup?.Sl_No[language]}</th>
                          <th>{VendorSignup?.Transaction_ID[language]}</th>
                          <th>{VendorSignup?.Start_date[language]}</th>
                          <th>{VendorSignup?.Category[language]}</th>
                          <th>{VendorSignup?.Amount[language]}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {emdhistory?.map((item: any, index: number) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.transact_id}</td>
                              <td>
                                {item?.created_at != null
                                  ? item?.created_at.slice(0, 10)
                                  : null}
                              </td>
                              <td>
                                {item?.category == 1
                                  ? "A"
                                  : item?.category == 2
                                  ? "B"
                                  : "C"}{" "}
                              {VendorSignup?.Category[language]}
                              </td>
                              <td className="primary-text">
                                NRLA ₹{item?.amount}/-
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            {emdhistory?.length == 0 ? (
              <>
                {category?.emd == "0" ? (
                  <Col sm={12} className="text-end">
                    <Button
                      className="btn btn-primary mb-5"
                      color="primary"
                      type="submit"
                      disabled
                      onClick={() => {
                        navigate("/chellon", {
                          state: {
                            amount: category?.emd,
                            category: category?.category,
                          },
                        });
                      }}
                    >
                      {VendorSignup?.Pay[language]}
                    </Button>
                  </Col>
                ) : (
                  <Col sm={12} className="text-end">
                    <Button
                      className="btn btn-primary mb-5"
                      color="primary"
                      type="submit"
                      onClick={() => {
                        navigate("/chellon", {
                          state: {
                            amount: category?.emd,
                            category: category?.category,
                          },
                        });
                      }}
                    >
                      {VendorSignup?.Pay[language]}
                    </Button>
                  </Col>
                )}
              </>
            ) : null}
          </Row>
        </Form>
      </div>
    </>
  );
}
