import * as Yup from "yup";
const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/svg",
  "image/jpg",
  "image/gif",
  "image/webp",
];
const ProductValidationSchema = Yup.object().shape({
  product_name: Yup.string().required("Product name is required"),
  product_code: Yup.string().required("Product code is required"),
  hsn_code: Yup.string()
    .required("HSN code is required")
    .matches(/^[0-9]+$/, "Hsn code should be number")
    .length(8, "Hsn code should be atleast 8 digits"),

  product_description: Yup.string().required("Product description is required"),
  category: Yup.string().required("Category is required"),
  file: Yup.array()
    .of(Yup.string())
    .min(1, "Atleast 1 image is required")
    .max(4, "Only 4 image is allowed")
    .required("File is required"),
  // spec_details: Yup.array().of(
  //   Yup.object().shape({
  //     spec_details: Yup.string().required("Field is required"),
  //   })
  // ),
});

const catinitialValidation =
  Yup.object().shape({
    category_name: Yup.string().required("Category  is required"),

  });
export { ProductValidationSchema, catinitialValidation };
