import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Context } from "../../../../Components/Context/Context";
import { Admin_Verifications } from "../../../../Library/LanguageConversion/Admin_Verifications";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  ButtonGroup,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import { vendor_types } from "../../../../Library/Constants/MapConstants";
import constants from "../../../../Library/Constants/Constants";
import { toast } from "react-toastify";
import CustomPagination from "../../../../Components/CustomPagination/Index";
export default function AccountVerification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState<any>();
  const [filter, setFilter] = useState("");
  const { language, setLanguage } = useContext(Context);
  const [vendor_data, setVendorData] = useState({
    vendor_type: "",
  });
  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  const handleVendor = (e: any) => {
    setCurrentPage(1);
    setVendorData({ ...vendor_data, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   getAccount();
  // }, [vendor_data?.vendor_type, currentPage, filter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getAccount();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, vendor_data?.vendor_type, currentPage, filter]);
  //=========================================================== Account list =================================================================

  const getAccount = () => {
    setLoading(true);
    setAccount([]);
    api
      .get(
        `/bank/accounts?type=${vendor_data?.vendor_type}&search_keyword=${search}&status=${filter}&page=${currentPage}`,
        true
      )
      .then(async function ([success, response]) {
        setAccount(response?.data || []);
        setPageData(response?.meta || []);
        setLoading(false);

        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //=========================================================== Verify Account =================================================================

  const verifyAccount = (id: any, status: any) => {
    setLoading(true);
    api
      .post(
        `/approvebankaccount`,
        {
          bank_id: id,
          status: status,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Account Verified Successfully");
        }
        setLoading(false);
        getAccount();
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //==================================================================== View =================================================================

  return (
    <React.Fragment>
      <div className="search-widget-outer verif-outer">
        <Form role="search">
          <ButtonGroup>
          <Button
              className={`${
                filter === "" ? "status-tabs active" : "status-tabs"
              }`}
              onClick={() => {
                setCurrentPage(1);
                setFilter("");
              }}
            >
              {Admin_Verifications?.All[language]}
            </Button>
            <Button
              className={`${
                filter === "2" ? "status-tabs active" : "status-tabs"
              }`}
              onClick={() => {
                setCurrentPage(1);
                setFilter("2");
              }}
            >
              {Admin_Verifications?.Pending[language]}
            </Button>
            <Button
              className={`${
                filter === "1" ? "status-tabs active" : "status-tabs"
              }`}
              onClick={() => {
                setCurrentPage(1);
                setFilter("1");
              }}
            >
              {Admin_Verifications?.Approved[language]}
            </Button>
          </ButtonGroup>
          <InputGroup className="search-group">
            <InputGroupText title="Account Holder Name" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control border-start-0"
              placeholder={Admin_Verifications?.AccountHolderName[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
        <div className="select-filter" title="Select Vendor type">
          <Input
            type="select"
            label="Vendor"
            name="vendor_type"
            value={vendor_data?.vendor_type}
            onChange={handleVendor}
          >
            <option value="">{Admin_Verifications?.SelectVendortype[language]}</option>
            {vendor_types?.map((item: any, i: any) => {
              return (
                <React.Fragment key={i}>
                  <option value={item?.value}>{item?.name}</option>
                </React.Fragment>
              );
            })}
          </Input>
        </div>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_Verifications?.SlNo[language]}</th>
              <th>{Admin_Verifications?.AccountHolderName[language]}</th>
              <th>{Admin_Verifications?.AccountNo[language]}</th>
              {/* <th>Bank</th> */}
              <th>{Admin_Verifications?.IFSCCode[language]}</th>
              <th>{Admin_Verifications?.Branch[language]}</th>
              <th>{Admin_Verifications?.Action[language]}</th>
              <th>{Admin_Verifications?.Status[language]}</th>
            </tr>
          </thead>
          <tbody>
            {account?.length !== 0 ? (
              <>
                {account?.map((item: any, i: any) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.SlNo[language]}:</span>
                          <span className="table-data"> {pageData.from + i}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                          {Admin_Verifications?.AccountHolderName[language]}:
                          </span>
                          <span className="table-data">
                            {item?.account_holder_name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.AccountNo[language]}:</span>
                          <span className="table-data">
                            {item?.account_number}
                          </span>
                        </td>
                        {/* <td>
                          <span className="mobile-heading">Bank:</span>
                          <span className="table-data">ICICI Bank</span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.IFSCCode[language]}:</span>
                          <span className="table-data">{item?.ifsc_code}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Branch[language]}:</span>
                          <span className="table-data">{item?.branch}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Action[language]}:</span>
                          <span className="action-flex-btn ">
                            {item?.status ==
                            constants.ACCOUNTVERIFY.NOT_VERIFIED ? (
                              <Input
                                type="checkbox"
                                checked={false}
                                onClick={() => verifyAccount(item?.id, 1)}
                              ></Input>
                            ) : (
                              <Input
                                type="checkbox"
                                disabled={true}
                                checked={true}
                              ></Input>
                            )}
                          </span>
                        </td>
                        <td>
                        <span className="mobile-heading">{Admin_Verifications?.Status[language]}:</span>
                          {item?.status ==
                          constants.ACCOUNTVERIFY.NOT_VERIFIED ? (
                            <span className="pending-status">
                              {Admin_Verifications?.PendingVerification[language]}
                            </span>
                          ) : (
                            <span className="approved-status">{Admin_Verifications?.Verified[language]}</span>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={7}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    " No Account Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {account?.length !== 0 && pageData && (
        <div className="table-footer-wrap">
          <small>
            Showing {pageData.from} to {pageData.to} of {pageData.total} entries
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
