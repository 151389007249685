import React, { useEffect, useState , useContext} from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";

import DatePicker from "react-date-picker";
import { DOCS_URL } from "../../../../Library/ApiSettings";
import { it_turn_mark_caluction } from "../../../../Library/Constants/helpers";
import TextError from "../../../../Components/Errors/TextError";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import constants from "../../../../Library/Constants/Constants";
import moment from "moment";
import { Context } from "../../../../Components/Context/Context";
import{Vendor_Settings_ReuploadModal } from "../../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";

const DOCUMENT_URL = DOCS_URL;

export default function OEMReuplaodModal(props: any) {
  const {language,setLanguage}=useContext(Context)
  const handleFileChange = (e: any, values?: any) => {
    props?.setFieldValue("oem_certificate", e.target.files[0]);
  };
  return (
    <>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Document Reupload"
      >
        <div className="company-information-update-wrap">
          <h4>{Vendor_Settings_ReuploadModal?.OEM?.[language]} </h4>
          <hr />
          <Form className="form-wrap" onSubmit={props?.handleSubmit}>
          <Col md={12} sm={12}>
            <FormGroup>
              <Label>{Vendor_Settings_ReuploadModal?.OEM_Certificate?.[language]}</Label>
              <div className="image-input">
                <input
                  type="file"
                  ref={props?.oemRef}
                  id="oem_certificate"
                  name="oem_certificate"
                  className="form-control"
                  accept=".pdf"
                  onChange={(e: any) => handleFileChange(e, e.target.files[0])}
                  disabled={props?.list > 0 ? true : false}
                ></input>
                <Label
                  className="image-button"
                  for="oem_certificate"
                  id="oem_certificate"
                >
                 {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]} 
                </Label>
              </div>
              <ErrorMessage name="oem_certificate" component={TextError} />
            </FormGroup>
          </Col>
          <Row>
            {" "}
            <Col sm={4}>
              <FormGroup>
                <div className="custom-input">
                  <Label>
                    {" "}
                    {Vendor_Settings_ReuploadModal?.Comapny_Type_in_OEM_Certificate?.[language]}  
                    <span className="text-danger" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </Label>

                  <Input
                    name="oem_in_company_type"
                    type="text"
                    value={props?.values?.oem_in_company_type}
                    onChange={props?.handleChange}
                  />
                </div>
                <ErrorMessage
                  name="oem_in_company_type"
                  component={TextError}
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <div className="custom-input">
                  <Label>
                    {" "}
                    {Vendor_Settings_ReuploadModal?.OEM_Valid_From?.[language]}  
        
                    <span className="text-danger" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </Label>

                  <DatePicker
                    value={props?.values?.oem_in_company_valid_from}
                    onChange={(e: any) => {
                      props?.setFieldValue("oem_in_company_valid_from", e);
                    }}
                    name="oem_in_company_valid_from"
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    maxDate={new Date()}
                  />
                </div>
                <ErrorMessage
                  name="oem_in_company_valid_from"
                  component={TextError}
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <div className="custom-input">
                  <Label>
                    {" "}
                    {Vendor_Settings_ReuploadModal?.OEM_Valid_To?.[language]}  
                    <span className="text-danger" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </Label>

                  <DatePicker
                    value={props?.values?.oem_in_company_valid_to}
                    onChange={(e: any) => {
                      props?.setFieldValue("oem_in_company_valid_to", e);
                    }}
                    name="oem_in_company_valid_to"
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    minDate={new Date()}
                  />

                  <ErrorMessage
                    name="oem_in_company_valid_to"
                    component={TextError}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm={12} className="text-end">
              <Button
                className="btn btn-primary mb-5"
                color="primary"
                type="submit"
              >
                {Vendor_Settings_ReuploadModal?.Submit?.[language]}   
              </Button>
            </Col>
          </Row>
          </Form>
        </div>
      </ModalContainer>
    </>
  );
}
