import { useState, useContext } from 'react'
import constants from '../../../../Library/Constants/Constants'
import { Context } from "../../../../Components/Context/Context";
import { DepartmentProducts } from '../../../../Library/LanguageConversion/Department_Products';


interface propType {
    selectedMethod: number,
    setSelectedMethod: Function,
    HideNB?: boolean,
}
const PaymentTypesSelector = ({ selectedMethod, setSelectedMethod, HideNB }: propType) => {
    const { language, setLanguage } = useContext(Context);
    return (
        <>
            <div className="choose-list-wrap">
                <div
                    title='Select Direct Bank Transfer'
                    className={`choose-type-inner  ${(selectedMethod == constants?.PAYMENT_TYPE?.BANK) ? 'active' : ''}`}
                    onClick={() => setSelectedMethod(constants?.PAYMENT_TYPE?.BANK)}>
                    {/* <i className="bi bi-bank2 d-block"></i> */}
                    <img className='icon' src="/images/banking.png" alt="icon" />
                    <small>{DepartmentProducts?.Direct_Bank_Transfer[language]}</small>
                </div>

                {!HideNB && <div
                    title='Select NetBanking'
                    className={`choose-type-inner ${(selectedMethod == constants?.PAYMENT_TYPE?.NETBANK) ? 'active' : ''}`}
                    onClick={() => setSelectedMethod(constants?.PAYMENT_TYPE?.NETBANK)}>
                    {/* <i className="bi bi-credit-card-2-back d-block"></i> */}
                    <img className='icon' src="/images/credit-card.png" alt="icon" />
                    <small>{DepartmentProducts?.NetBanking[language]}</small>
                </div>}

                <div
                    title='Select Cheque'
                    className={`choose-type-inner ${(selectedMethod == constants?.PAYMENT_TYPE?.CHEQUE) ? 'active' : ''}`}
                    onClick={() => setSelectedMethod(constants?.PAYMENT_TYPE?.CHEQUE)}>
                    {/* <i className="bi bi-cash d-block"></i> */}
                    <img className='icon' src="/images/cheque(1).png" alt="icon" />
                    <small>{DepartmentProducts?.Cheque[language]}</small>
                </div>

                <div
                    title='Select Demand Draft'
                    className={`choose-type-inner ${(selectedMethod == constants?.PAYMENT_TYPE?.CHELLAN) ? 'active' : ''}`}
                    onClick={() => setSelectedMethod(constants?.PAYMENT_TYPE?.CHELLAN)}>
                    {/* <i className="bi bi-paperclip d-block"></i> */}
                    <img className='icon' src="/images/sketching.png" alt="icon" />
                    <small>{DepartmentProducts?.Demand_Draft[language]}</small>
                </div>

                {/* ========== */}
                <div
                    title='Select RTGS'
                    className={`choose-type-inner ${(selectedMethod == constants?.PAYMENT_TYPE?.RTGS) ? 'active' : ''}`}
                    onClick={() => setSelectedMethod(constants?.PAYMENT_TYPE?.RTGS)}>
                    {/* <i className="bi bi-cash d-block"></i> */}
                    <img className='icon' src="/images/rtgs.png" alt="icon" />
                    <small>{DepartmentProducts?.RTGS[language]}</small>
                </div>
                <div
                    title='Select NEFT'
                    className={`choose-type-inner ${(selectedMethod == constants?.PAYMENT_TYPE?.NEFT) ? 'active' : ''}`}
                    onClick={() => setSelectedMethod(constants?.PAYMENT_TYPE?.NEFT)}>
                    {/* <i className="bi bi-cash d-block"></i> */}
                    <img className='icon' src="/images/neft.png" alt="icon" />
                    <small>{DepartmentProducts?.NEFT[language]}</small>
                </div>

            </div>
        </>
    )
}

export default PaymentTypesSelector