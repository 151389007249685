import React, { useEffect, useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import { preventNegativeValues } from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";

const DOCUMENT_URL = DOCS_URL;
export default function PoweAtoDocuments(props: any) {
  const { language, setLanguage } = useContext(Context);
  return (
    <>
      <div className="company-information-update-wrap">
        <h4> {Vendor_profileUpdation?.Power_Of_Attorney?.[language]}</h4>
        <hr />
        <Row>
          <Col md={4} sm={6}>
            <FormGroup>
              <Label>
              {Vendor_profileUpdation?.Power_Of_Attorney_Board?.[language]}{" "}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.list <= 0 ? (
                  <>
                    {" "}
                    <input
                      type="file"
                      ref={props?.oemRef}
                      id="start_up_company_power_attorney_certificate"
                      name="start_up_company_power_attorney_certificate"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={props?.list > 0 ? true : false}
                    ></input>
                    <Label
                      className="image-button"
                      for="start_up_company_power_attorney_certificate"
                      id="start_up_company_power_attorney_certificate"
                    >
                     {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    <ErrorMessage
                      name="start_up_company_power_attorney_certificate"
                      component={TextError}
                    />
                  </>
                ) : (
                  <>
                    <InputGroup>
                      <input
                        id="start_up_company_power_attorney_certificate"
                        name="start_up_company_power_attorney_certificate"
                        className="form-control"
                        accept=".pdf"
                        value={
                          props?.datas?.start_up_company_power_attorney_certificate
                        }
                        disabled={
                          props?.datas?.start_up_company_power_attorney_certificate
                            ? true
                            : false
                        }
                      ></input>
                      <InputGroupText
                        className="input-group-text bg-white border-start-0"
                        id="newpass"
                      >
                        <a
                          href={
                            `${DOCUMENT_URL}` +
                            props?.datas
                              ?.start_up_company_power_attorney_certificate
                          }
                          download
                          target="_blank"
                        >
                          {" "}
                          <i className="bi bi-box-arrow-down"></i>
                        </a>
                      </InputGroupText>
                    </InputGroup>
                  </>
                )}
              </div>
            </FormGroup>
          </Col>

          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Company_Name?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_power_attorney_company_name"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_power_attorney_company_name
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_power_attorney_company_name"
                    type="text"
                    value={props?.values?.start_up_company_po}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_power_attorney_company_name"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Company_Address?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_power_attorney_director_name"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_power_attorney_director_name
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_power_attorney_director_name"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_power_attorney_director_name
                    }
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_power_attorney_director_name"
                component={TextError}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
