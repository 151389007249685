import React from "react";
import { FormGroup, Input, Label, Row, Col, Button, Spinner } from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { useState, useMemo, useEffect,useContext } from "react";
import moment from "moment";
import { api } from "../../../Library/CustomApi";
import {
  StartUpDocumetsValiadation,
  StartUpDocumetsValiadationOne,
  StartUpDocumetsValiadationmsmeno,
} from "./StartUpdocumentValiadtion";
import RequiredDocuments from "./RequiredDocuments";
import TextError from "../../../Components/Errors/TextError";
import StartUpDocuments from "./StartUpCertificates";
import BlackLsitDocuments from "./BlackListDocuments";
import NoCaseDocuments from "./PowerAtDocument";
import PoweAtoDocuments from "./PowerAtDocument";
import MSMEDocuments from "./MSMEDouments";
import AnyOthercomapnyDocuments from "./AnyOtherCompanyDocument";
import LandDocument from "./LandDocuments";
import axios from "axios";
import { API_URL } from "../../../Library/ApiSettings";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OEMDocumentsSection from "./OEMDocumntes";
import NormalVendorView from "./DocumentsView/NormalVendorView";
import StartupdocumentsView from "./DocumentsView/StartUpDOcumentsView";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
export default function StartUpdocuments(props: any) {
  const { language, setLanguage } = useContext(Context);
  const navigate = useNavigate();
  const [vendorUdyam, setVendorUdyam] = useState("");
  const [vendormse, setVendorMSE] = useState("");
  const [list, setList] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [data, setData] = useState([]);
  const [value, setValue] = useState({
    start_up_certificate: "",
    incorporation_certificate: "",
    start_up_company_name: "",
    start_up_company_address: "",
    start_up_company_number: "",
    start_up_company_incoparation_date: "",
    start_up_company_under_black_list_document: "",
    start_up_company_under_black_list: "",
    start_up_company_no_case_certificate: "",
    start_up_company_power_attorney_certificate: "",
    start_up_company_power_attorney_director_name: "",
    start_up_company_udyam_registration_number: "",
    start_up_company_udyam_name_of_enterprises: "",
    start_up_company_udyam_type_of_enterprises: "",
    start_up_company_udyam_major_activity: "",
    start_up_company_udyam_social_category_of_enterprises: "",
    start_up_company_udyam_type_office_address: "",
    start_up_company_udyam_date: "",
    start_up_company_udyam_district_industries_center: "",
    start_up_company_udyam_msme_dfo: "",
    pan: "",
    land_document: "",
    land_village_col: "",
    land_extent: "",
    land_value: "",
    land_survey_no: "",
    any_other_company_document: "",
    any_other_company_pincode: "",
    any_other_company_name: "",
    any_other_company_email: "",
    mse_certificate: "",
    oem_certificate: "",
    gst_certificate: "",
    udyam: "",
    address_proof: "",
    start_up_company_power_attorney_company_name: "",
    oem_in_company_type: "",
    oem_in_company_valid_from: "",
    oem_in_company_valid_to: "",
    start_up_company_udyam_msme_document: "",
    // start_up_company_under_black_list_yes:"",
    // start_up_company_under_black_list_no:""
  });

  const intialValues = {
    incorporation_certificate: "",
    start_up_certificate: "",
    start_up_company_name: "",
    start_up_company_address: "",
    start_up_company_number: "",
    start_up_company_incoparation_date: "",
    start_up_company_under_black_list_document: "",
    start_up_company_under_black_list: "",
    start_up_company_no_case_certificate: "",
    start_up_company_power_attorney_certificate: "",
    start_up_company_power_attorney_director_name: "",
    start_up_company_power_attorney_company_name: "",
    start_up_company_udyam_registration_number: "",
    start_up_company_udyam_name_of_enterprises: "",
    start_up_company_udyam_major_activity: "",
    start_up_company_udyam_social_category_of_enterprises: "",
    start_up_company_udyam_type_office_address: "",
    start_up_company_udyam_date: "",
    start_up_company_udyam_district_industries_center: "",
    start_up_company_udyam_msme_dfo: "",
    start_up_company_udyam_type_of_enterprises: "",
    pan: "",
    land_document: "",
    land_village_col: "",
    land_extent: "",
    land_value: "",
    land_survey_no: "",
    any_other_company_documents: "",
    any_other_company_pincode: "",
    any_other_company_name: "",
    any_other_company_email: "",
    mse_certificate: "",
    oem_certificate: "",
    gst_certificate: "",
    address: "",
    udyam: "",
    address_proof: "",
    oem_in_company_type: "",
    oem_in_company_valid_from: "",
    oem_in_company_valid_to: "",
    start_up_company_udyam_msme_document: "",

    // start_up_company_under_black_list_yes:"",
    // start_up_company_under_black_list_no:""
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // Save the scroll position when navigating away
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    // Restore the scroll position when navigating back
    window.scrollTo(0, scrollPosition);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    getDocumnets();
    getVendor();
  }, []);

  const getVendor = () => {
    api
      .post(`/get_vendor_profile`, {}, true)
      .then(async function ([success, response]) {
        setVendorUdyam(
          response?.vendor_details?.advanced_registration_details?.[0]?.udyam
        );
        setVendorMSE(
          response?.vendor_details?.advanced_registration_details?.[0]
            ?.company_mse
        );

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumnets = () => {
    api
      .get(`/documents`, true)
      .then(async function ([success, response]) {
        setList(response?.data?.length);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = (values: any, actions: any) => {
    uploadProduct(values, actions);
  };
  const uploadProduct = (values: any, actions: any) => {
    const formData = new FormData();

    // documets
    if (values?.start_up_certificate?.type !== "application/pdf") {
      actions?.setFieldError("start_up_certificate", "Only Accept pdf file");
    }

    formData.append("start_up_certificate", values?.start_up_certificate);
    formData.append("start_up_company_name", values?.start_up_company_name);
    formData.append(
      "start_up_company_address",
      values?.start_up_company_address
    );
    formData.append("start_up_company_number", values?.start_up_company_number);

    formData.append(
      "start_up_company_under_black_list_document",
      values?.start_up_company_under_black_list_document
    );
    formData.append(
      "start_up_company_under_black_list",
      values?.start_up_company_under_black_list
    );
    formData.append(
      "start_up_company_no_case_certificate",
      values?.start_up_company_no_case_certificate
    );
    formData.append(
      "start_up_company_power_attorney_certificate",
      values?.start_up_company_power_attorney_certificate
    );
    formData.append(
      "start_up_company_power_attorney_director_name",
      values?.start_up_company_power_attorney_director_name
    );
    formData.append(
      "start_up_company_udyam_registration_number",
      values?.start_up_company_udyam_registration_number
    );
    formData.append(
      "start_up_company_udyam_name_of_enterprises",
      values?.start_up_company_udyam_name_of_enterprises
    );
    formData.append(
      "start_up_company_udyam_major_activity",
      values?.start_up_company_udyam_major_activity
    );
    formData.append(
      "start_up_company_udyam_social_category_of_enterprises",
      values?.start_up_company_udyam_social_category_of_enterprises
    );
    formData.append(
      "start_up_company_udyam_type_office_address",
      values?.start_up_company_udyam_type_office_address
    );
    formData.append(
      "start_up_company_udyam_date",
      moment(values?.start_up_company_udyam_date).format("YYYY-MM-DD")
    );
    formData.append(
      "start_up_company_udyam_district_industries_center",
      values?.start_up_company_udyam_district_industries_center
    );
    formData.append(
      "start_up_company_udyam_msme_df",
      values?.start_up_company_udyam_msme_dfo
    );
    formData.append(
      "start_up_company_udyam_msme_document",
      values?.start_up_company_udyam_msme_document
    );
    formData.append(
      "start_up_company_power_attorney_company_name",
      values?.start_up_company_power_attorney_company_name
    );
    formData.append(
      "start_up_company_incoparation_date",
      moment(values?.start_up_company_incoparation_date).format("YYYY-MM-DD")
    );

    formData.append("pan", values?.pan);
    formData.append("oem_certificate", values?.oem_certificate);

    formData.append("address_proof", values?.address_proof);
    formData.append(
      "incorporation_certificate",
      values?.incorporation_certificate
    );
    formData.append("mse_certificate", values?.mse_certificate);
    formData.append("gst_certificate", values?.gst_certificate);

    formData.append("udyam", values?.udyam);

    formData.append(
      "any_other_company_document",
      values?.any_other_company_documents
    );
    formData.append("land_document", values?.land_document);

    formData.append("land_extent", values?.land_extent);
    formData.append("land_village_col", values?.land_village_col);
    formData.append("land_value", values?.land_value);
    formData.append("land_survey_no", values?.land_survey_no);

    formData.append("oem_in_company_type", values?.oem_in_company_type);
    formData.append(
      "oem_valid_from",
      moment(values?.oem_in_company_valid_from).format("YYYY-MM-DD")
    );
    formData.append(
      "oem_valid_to",
      moment(values?.oem_in_company_valid_to).format("YYYY-MM-DD")
    );
    formData.append(
      "start_up_company_udyam_msme_document",
      values?.mse_certificate
    );

    formData.append(
      "start_up_company_udyam_type_of_enterprises",
      values?.start_up_company_udyam_type_of_enterprises
    );

    formData.append(
      "start_up_company_udyam_type_of_enterprises",
      values?.start_up_company_udyam_type_of_enterprises
    );
    formData.append(
      "any_other_company_pincode",
      values?.any_other_company_pincode
    );
    formData.append("any_other_company_state", values?.any_other_company_state);
    formData.append(
      "any_other_company_district",
      values?.any_other_company_district
    );
    formData.append("any_other_company_city", values?.any_other_company_city);
    formData.append("any_other_company_name", values?.any_other_company_name);
    formData.append("any_other_company_email", values?.any_other_company_email);
    formData.append("oem_in_company_type", values?.oem_in_company_type);
    formData.append(
      "oem_valid_from",
      moment(values?.oem_in_company_valid_from).format("YYYY-MM-DD")
    );
    formData.append(
      "oem_valid_to",
      moment(values?.oem_in_company_valid_to).format("YYYY-MM-DD")
    );

    if (values?.iso_certificate) {
      formData.append("is_iso", "1");
    }
    if (values?.oem_certificate) {
      formData.append("is_oem", "1");
    }
    if (values?.maf_certificate) {
      formData.append("is_adhar", "1");
    }
    if (values?.mse_certificate) {
      formData.append("is_msme", "1");
    }
    const body = formData;

    axios({
      method: "post",
      url: `${API_URL}/documents`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        if (response?.status == 200) {
          toast.success("Documents are uploaded successfully");
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/emd-details", { state: true });
          localStorage.setItem("emds", "true");
        }
      })
      .catch(function (response) {
        toast.error("Something Went Wrong, Please Check Datas");
        console.log(response);
      });
  };
  const documentdownload = (id: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(`${API_URL}/documents/${id}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Memorandum Of Understanding.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  return (
    <>
      <div className="login-content">
        <Formik
          initialValues={intialValues}
          enableReinitialize
          validationSchema={
            vendormse == "no"
              ? StartUpDocumetsValiadationmsmeno
              : value?.start_up_company_under_black_list == "2"
              ? StartUpDocumetsValiadation
              : StartUpDocumetsValiadationOne
          }
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            // {setMark({
            //   ...mark,
            //   turn_over_mark:values?.mark_turn
            // })}
            const handleFileChange = (e: any, values: any) => {
              const myMemoObj = URL.createObjectURL(e.target.files[0]);

              if (e.target.name == "pan") {
                setFieldValue("pan", e.target.files[0]);
              }
              if (e.target.name == "incorporation_certificate") {
                setFieldValue("incorporation_certificate", e.target.files[0]);
              }
              if (e.target.name == "address_proof") {
                setFieldValue("address_proof", e.target.files[0]);
              }
              if (e.target.name == "mse_certificate") {
                setFieldValue("mse_certificate", e.target.files[0]);
              }
              if (e.target.name == "gst_certificate") {
                setFieldValue("gst_certificate", e.target.files[0]);
              }

              if (e.target.name == "maf_certificate") {
                setFieldValue("maf_certificate", e.target.files[0]);
              }
              if (e.target.name == "oem_certificate") {
                setFieldValue("oem_certificate", e.target.files[0]);
              }
              if (e.target.name == "empanel_certificate") {
                setFieldValue("empanel_certificate", e.target.files[0]);
              }
              if (e.target.name == "udyam") {
                setFieldValue("udyam", e.target.files[0]);
              }

              if (e.target.name == "any_other_company_documents") {
                setFieldValue("any_other_company_documents", e.target.files[0]);
              }
              if (
                e.target.name == "start_up_company_under_black_list_document"
              ) {
                setFieldValue(
                  "start_up_company_under_black_list_document",
                  e.target.files[0]
                );
              }
              if (e.target.name == "land_document") {
                setFieldValue("land_document", e.target.files[0]);
              }
              if (
                e.target.name == "start_up_company_power_attorney_certificate"
              ) {
                setFieldValue(
                  "start_up_company_power_attorney_certificate",
                  e.target.files[0]
                );
              }
              if (e.target.name == "start_up_certificate") {
                setFieldValue("start_up_certificate", e.target.files[0]);
              }
              if (e.target.name == "start_up_company_no_case_certificate") {
                setFieldValue(
                  "start_up_company_no_case_certificate",
                  e.target.files[0]
                );
              }
            };

            return (
              <Form onSubmit={handleSubmit} className="Reg-wrap">
                <span className="text-danger">
                  *{Vendor_profileUpdation?.Please_be_advised?.[language]}
                </span>

                <hr />
                {list !== 0 ? (
                  <StartupdocumentsView />
                ) : (
                  <>
                    <RequiredDocuments
                      datas={value}
                      list={list}
                      // oemRef={oemRef}
                      handleFileChange={handleFileChange}
                      values={values}
                      handleChange={handleChange}
                      vendormse={vendormse}
                      vendorUdyam={vendorUdyam}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      start_up={1}
                      documentdownload={documentdownload}
                    />
                    <OEMDocumentsSection
                      datas={value}
                      list={list}
                      // oemRef={oemRef}
                      handleFileChange={handleFileChange}
                      values={values}
                      handleChange={handleChange}
                      vendormse={vendormse}
                      vendorUdyam={vendorUdyam}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      start_up={1}
                      documentdownload={documentdownload}
                    />

                    <StartUpDocuments
                      datas={value}
                      list={list}
                      // oemRef={oemRef}
                      handleFileChange={handleFileChange}
                      values={values}
                      handleChange={handleChange}
                      vendormse={vendormse}
                      vendorUdyam={vendorUdyam}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      start_up={1}
                      documentdownload={documentdownload}
                    />
                    <BlackLsitDocuments
                      datas={value}
                      list={list}
                      // oemRef={oemRef}
                      handleFileChange={handleFileChange}
                      values={values}
                      handleChange={handleChange}
                      vendormse={vendormse}
                      vendorUdyam={vendorUdyam}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      setValue={setValue}
                      start_up={1}
                      documentdownload={documentdownload}
                    />
                    <PoweAtoDocuments
                      datas={value}
                      list={list}
                      // oemRef={oemRef}
                      handleFileChange={handleFileChange}
                      values={values}
                      handleChange={handleChange}
                      vendormse={vendormse}
                      vendorUdyam={vendorUdyam}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      setValue={setValue}
                      start_up={1}
                      documentdownload={documentdownload}
                    />
                    {vendormse == "yes" && (
                      <>
                        {" "}
                        <MSMEDocuments
                          datas={value}
                          list={list}
                          // oemRef={oemRef}
                          handleFileChange={handleFileChange}
                          values={values}
                          handleChange={handleChange}
                          vendormse={vendormse}
                          vendorUdyam={vendorUdyam}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          setValue={setValue}
                          start_up={1}
                          documentdownload={documentdownload}
                        />
                      </>
                    )}

                    <AnyOthercomapnyDocuments
                      datas={values}
                      list={list}
                      handleFileChange={handleFileChange}
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      start_up={1}
                      documentdownload={documentdownload}
                    />
                    <LandDocument
                      datas={values}
                      list={list}
                      handleFileChange={handleFileChange}
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      start_up={1}
                      documentdownload={documentdownload}
                    />

                    <Row>
                      <Col sm={12}>
                        <div className="agree-checkbox">
                          <FormGroup>
                            <div className="agree-checkbox-wrap">
                              <Input
                                name="address"
                                type="checkbox"
                                className="form-check-input"
                                value={values?.address}
                                onChange={handleChange}
                                //  disabled={list > 0 ? true : false}
                                // style={{
                                //   backgroundColor: `${
                                //     list > 0 ? "#b6b4b4" : "white"
                                //   }`,
                                // }}
                              ></Input>
                              <Label>
                              {Vendor_profileUpdation?.Make_sure_entered?.[language]}
                              </Label>
                            </div>
                            <ErrorMessage
                              name="address"
                              component={TextError}
                            />
                          </FormGroup>
                        </div>
                      </Col>
                      <Col sm={12} className="text-end">
                        {isSubmitting ? (
                          <Button
                            className="btn btn-primary mb-5"
                            color="primary"
                            type="submit"
                            disabled
                          >
                            <Spinner
                              as="span"
                              variant="light"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                            />
                          </Button>
                        ) : (
                          <Button
                            className="btn btn-primary mb-5"
                            color="primary"
                            type="submit"
                            //disabled={list > 0 ? true : false}
                          >
                            {Vendor_profileUpdation?.Make_sure_entered?.[language]}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
      {isVisible && (
        <>
          <button
            onClick={() => scrollToTop()}
            className="btn btn-dark button_scroll_ "
          >
            <i className="fa-solid fa-arrow-up"></i>
          </button>
        </>
      )}
    </>
  );
}
