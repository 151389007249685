import React, { useEffect,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";

import DatePicker from "react-date-picker";
import { DOCS_URL } from "../../../../Library/ApiSettings";
import TextError from "../../../../Components/Errors/TextError";
import { preventNegativeValues } from "../../../../Library/Constants/helpers";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../Components/Context/Context";
import { Vendor_Settings_ReuploadModal } from "../../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";


const DOCUMENT_URL = DOCS_URL;
export default function LandDocumentReuploadModal(props: any) {
  const {language,setLanguage}=useContext(Context)
  const handleFileChange = (e: any, values?: any) => {
    props?.setFieldValue("land_document", e.target.files[0]);
  };
  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Document Reupload"
      >
        
            <Form className="form-wrap" onSubmit={props?.handleSubmit}>
            <div className="upload-prod-outer">
         
         <div className="company-information-update-wrap">
           <h4>  {Vendor_Settings_ReuploadModal?.Land_Document?.[language]}</h4>
           <hr />
            <Row>
              <Col md={6} sm={6}>
                <FormGroup>
                  <Label>{Vendor_Settings_ReuploadModal?.Land_Document?.[language]}</Label>
                  <div className="image-input">
                    <input
                      type="file"
                      ref={props?.oemRef}
                      id="land_document"
                      name="land_document"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        handleFileChange(e, e.target.files[0])
                      }
                      disabled={props?.list > 0 ? true : false}
                    ></input>
                    <Label
                      className="image-button"
                      for="land_document"
                      id="land_document"
                    >
                        {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}
                    </Label>
                  </div>
                  <ErrorMessage name="land_document" component={TextError} />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.Village?.[language]}/ {Vendor_Settings_ReuploadModal?.Col?.[language]}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="land_village_col"
                      type="text"
                      value={props?.values?.land_village_col}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage name="land_village_col" component={TextError} />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.Extent?.[language]}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="land_extent"
                      type="text"
                      value={props?.values?.land_extent}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage name="land_extent" component={TextError} />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.Value?.[language]}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="land_value"
                      type="text"
                      value={props?.values?.land_value}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage name="land_value" component={TextError} />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.Survey_No?.[language]}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="land_survey_no"
                      type="text"
                      value={props?.values?.land_survey_no}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage name="land_survey_no" component={TextError} />
                </FormGroup>
              </Col>
              <Col sm={4} className="mt-5">
                {" "}
                {/* {props?.values?.land_survey_no &&
                props?.values?.land_value &&
                props?.values?.land_extent &&
                props?.values?.land_village_col &&
                props?.values?.land_document ? (
                  <>
                    <span className="text-success mark-end">Mark:10/-</span>
                  </>
                ) : null} */}
              </Col>
            </Row>
          </div>
          <Row className="mt-3">
          <Col sm={12} className="text-end">
            <Button
              className="btn btn-primary mb-5"
              color="primary"
              type="submit"
            >
              {Vendor_Settings_ReuploadModal?.Submit?.[language]}
            </Button>
          </Col>
        </Row>
        </div>
        
        </Form>
      </ModalContainer>
    </React.Fragment>
  );
}
