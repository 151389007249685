import * as Yup from "yup";

const StartUpDocumetsValiadation = Yup.object().shape({
  incorporation_certificate: Yup.string().required("Field is required"),
  start_up_certificate: Yup.string().required("Field is required"),
  start_up_company_name: Yup.string().required("Field is required"),
  start_up_company_address: Yup.string().required("Field is required"),
  start_up_company_number: Yup.string().required("Field is required").nullable(),
  // start_up_company_udyam_msme_document:
  //   Yup.string().required("Field is required"),
  start_up_company_incoparation_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  start_up_company_under_black_list_document:
    Yup.string().required("Field is required"),
  start_up_company_under_black_list: Yup.number().required("Field is required").nullable(),
  start_up_company_no_case_certificate:
    Yup.string().required("Field is required"),
  start_up_company_power_attorney_certificate:
    Yup.string().required("Field is required"),
  start_up_company_power_attorney_director_name:
    Yup.string().required("Field is required"),
  start_up_company_udyam_registration_number:
    Yup.number().required("Field is required").nullable(),
  start_up_company_udyam_name_of_enterprises:
    Yup.string().required("Field is required"),
  start_up_company_udyam_major_activity:
    Yup.string().required("Field is required"),
  start_up_company_udyam_social_category_of_enterprises:
    Yup.string().required("Field is required"),
  start_up_company_udyam_type_office_address:
    Yup.string().required("Field is required"),
  start_up_company_udyam_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  start_up_company_udyam_district_industries_center:
    Yup.string().required("Field is required"),
  start_up_company_udyam_msme_dfo: Yup.string().required("Field is required"),
  pan: Yup.string().required("Field is required"),
  gst_certificate: Yup.string().required("Field is required"),
  mse_certificate: Yup.string().required("Field is required"),
  oem_certificate: Yup.string().required("Field is required"),
  oem_in_company_type: Yup.string().required("Field is required"),
  oem_in_company_valid_from: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  oem_in_company_valid_to: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  any_other_company_pincode: Yup.number().required("Field is required").nullable(),
  any_other_company_documents: Yup.string().required("Field is required"),
  any_other_company_name: Yup.string().required("Field is required"),
  any_other_company_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  address: Yup.string().required("Field is required"),
  // udyam: Yup.string().required("Field is required"),
  address_proof: Yup.string().required("Field is required"),
  start_up_company_power_attorney_company_name:
    Yup.string().required("Field is required"),
  start_up_company_udyam_type_of_enterprises:
    Yup.string().required("Field is required"),
  land_document: Yup.string().required("Field is required"),
  land_village_col: Yup.string().required("Field is required"),
  land_extent: Yup.string().required("Field is required"),
  land_value: Yup.number().required("Field is required").nullable(),
  land_survey_no: Yup.string().required("Field is required"),
});
const StartUpDocumetsValiadationOne = Yup.object().shape({
  incorporation_certificate: Yup.string().required("Field is required"),
  start_up_certificate: Yup.string().required("Field is required"),
  start_up_company_name: Yup.string().required("Field is required"),
  start_up_company_address: Yup.string().required("Field is required"),
  start_up_company_number: Yup.string().required("Field is required").nullable(),
  start_up_company_incoparation_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  // start_up_company_under_black_list_document:
  //   Yup.string().required("Field is required"),
  start_up_company_under_black_list: Yup.number().required("Field is required").nullable(),
  start_up_company_no_case_certificate:
    Yup.string().required("Field is required"),
  start_up_company_power_attorney_certificate:
    Yup.string().required("Field is required"),
  start_up_company_power_attorney_director_name:
    Yup.string().required("Field is required"),
  start_up_company_udyam_registration_number:
    Yup.string().required("Field is required"),
  start_up_company_udyam_name_of_enterprises:
    Yup.string().required("Field is required"),
  start_up_company_udyam_major_activity:
    Yup.string().required("Field is required"),
  start_up_company_udyam_social_category_of_enterprises:
    Yup.string().required("Field is required"),
  start_up_company_udyam_type_office_address:
    Yup.string().required("Field is required"),
  start_up_company_udyam_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  start_up_company_udyam_district_industries_center:
    Yup.string().required("Field is required"),
  start_up_company_udyam_msme_dfo: Yup.string().required("Field is required"),
  pan: Yup.string().required("Field is required"),
  gst_certificate: Yup.string().required("Field is required"),
  mse_certificate: Yup.string().required("Field is required"),
  oem_certificate: Yup.string().required("Field is required"),
  oem_in_company_type: Yup.string().required("Field is required"),
  oem_in_company_valid_from: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  oem_in_company_valid_to: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  any_other_company_pincode: Yup.number().required("Field is required").nullable(),
  any_other_company_documents: Yup.string().required("Field is required"),
  any_other_company_name: Yup.string().required("Field is required"),
  any_other_company_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  address: Yup.string().required("Field is required"),
  // udyam: Yup.string().required("Field is required"),
  address_proof: Yup.string().required("Field is required"),
  start_up_company_power_attorney_company_name:
    Yup.string().required("Field is required"),
  start_up_company_udyam_type_of_enterprises:
    Yup.string().required("Field is required"),
  land_document: Yup.string().required("Field is required"),
  land_village_col: Yup.string().required("Field is required"),
  land_extent: Yup.string().required("Field is required"),
  land_value: Yup.number().required("Field is required").nullable(),
  land_survey_no: Yup.string().required("Field is required"),
  // start_up_company_udyam_msme_document:
  //   Yup.string().required("Field is required"),
});

const StartUpDocumetsValiadationmsmeno = Yup.object().shape({
  incorporation_certificate: Yup.string().required("Field is required"),
  start_up_certificate: Yup.string().required("Field is required"),
  start_up_company_name: Yup.string().required("Field is required"),
  start_up_company_address: Yup.string().required("Field is required"),
  start_up_company_number: Yup.string().required("Field is required"),
  start_up_company_incoparation_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  start_up_company_under_black_list: Yup.number().required("Field is required"),
  start_up_company_no_case_certificate:
    Yup.string().required("Field is required"),
  start_up_company_power_attorney_certificate:
    Yup.string().required("Field is required"),
  start_up_company_power_attorney_director_name:
    Yup.string().required("Field is required"),

  pan: Yup.string().required("Field is required"),
  gst_certificate: Yup.string().required("Field is required"),

  oem_certificate: Yup.string().required("Field is required"),
  oem_in_company_type: Yup.string().required("Field is required"),
  oem_in_company_valid_from: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  oem_in_company_valid_to: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  any_other_company_pincode: Yup.number().required("Field is required"),
  any_other_company_documents: Yup.string().required("Field is required"),
  any_other_company_name: Yup.string().required("Field is required"),
  any_other_company_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  address: Yup.string().required("Field is required"),

  address_proof: Yup.string().required("Field is required"),
  start_up_company_power_attorney_company_name:
    Yup.string().required("Field is required"),

  land_document: Yup.string().required("Field is required"),
  land_village_col: Yup.string().required("Field is required"),
  land_extent: Yup.string().required("Field is required"),
  land_value: Yup.number().required("Field is required").nullable(),
  land_survey_no: Yup.string().required("Field is required"),
});
export {
  StartUpDocumetsValiadation,
  StartUpDocumetsValiadationOne,
  StartUpDocumetsValiadationmsmeno,
};
