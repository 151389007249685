import React, { useEffect, useContext } from "react";
import { useState } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Badge,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../../Library/CustomApi";
import constants from "../../../../../Library/Constants/Constants";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../../../../Components/Loader/Loader";
import { API_URL } from "../../../../../Library/ApiSettings";
import { Context } from "../../../../../Components/Context/Context";
import { admin_empanelment } from "../../../../../Library/LanguageConversion/admin_empanelment";
import { VendorType } from "../../../../../Library/Constants/helpers";

export default function AdvancedDetails() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [advanced_details, setAdvancedDetails] = useState<any>([]);
  const [vendor_data, setVendorData] = useState<any>([]);
  const [documents, setDocument] = useState<any>([]);
  const [com_add, setComAdd] = useState<any>([]);
  const [com_reg, setRegAdd] = useState<any>([]);
  const [turnOver, setTurnOver] = useState<any>();
  const [verify_status, setVerifystatus] = useState<any>([]);
  const [doc_data, setDocData] = useState<any>("");
  const [turn_data, setTurnData] = useState<any>([]);
  const [payDoc, setPayDoc] = useState<any>();
  const { language, setLanguage } = useContext(Context);
  const [start_up, setStartUp] = useState<any>([]);
  const [comm_data, setCommData] = useState([]);
  const [emd, setEmd] = useState<any>([]);
  const [comment, setComment] = useState({
    comm_1: "",
    comm_2: "",
    comm_3: "",
    comm_4: "",
    comm_5: "",
    comm_6: "",
    comm_7: "",
    comm_8: "",
    comm_9: "",
    comm_10: "",
    comm_11: "",
    comm_12: "",
    comm_13: "",
    comm_14: "",
    turn_comm_1: "",
    turn_comm_2: "",
    turn_comm_3: "",
    emd_comm: "",
  });
  const location = useLocation();
  const id = localStorage.getItem("vendor_data");
  const vendor_id = location?.state?.id || location?.state?.data || id;

  useEffect(() => {
    getAdvancedDetails();
    getTurnOvers(vendor_id);
  }, [vendor_id]);

  const handleComment = (e: any) => {
    setComment({
      ...comment,
      [e.target.name]: e.target.value,
    });
  };

  const getAdvancedDetails = () => {
    setLoading(true);
    api
      .post(
        `/get_vendor_data`,
        {
          user_id: vendor_id,
        },
        true
      )
      .then(async function ([success, response]) {
        setAdvancedDetails(
          response?.vendor_details?.advanced_registration_details || []
        );
        setRegAdd(response?.vendor_details?.COMPANYREGISTERED || []);
        setComAdd(response?.vendor_details?.COMPANYCOMMUNICATION || []);
        setVendorData(response?.vendor_details?.company_details || []);
        setDocument(response?.vendor_details?.document || []);
        setPayDoc(response?.vendor_details?.vendor_doc_pay);
        setTurnData(response?.vendor_details?.turnover || []);
        setEmd(response?.vendor_details?.emd || []);
        setStartUp(
          response?.vendor_details?.advanced_registration_details[0]
            ?.vendor_type
        );
        setComment({
          ...comment,
          comm_1:
            response?.vendor_details?.advanced_registration_details[0]
              ?.gst_number_verified_comment,
          comm_2:
            response?.vendor_details?.advanced_registration_details[0]
              ?.pan_number_verified_comment,
          comm_3:
            response?.vendor_details?.advanced_registration_details[0]
              ?.vendor_type_verified_comment,
          comm_4:
            response?.vendor_details?.advanced_registration_details[0]
              ?.gst_class_verified_comment,
          comm_5:
            response?.vendor_details?.advanced_registration_details[0]
              .company_mse_verified_comment,
          comm_6:
            response?.vendor_details?.company_details[0]
              ?.company_name_verified_comment,
          comm_7:
            response?.vendor_details?.company_details[0]
              ?.company_email_verified_comment,
          comm_8:
            response?.vendor_details?.company_details[0]
              ?.company_contact_verified_comment,
          comm_9:
            response?.vendor_details?.company_details[0]
              ?.company_incorp_verified_comment,
          comm_10:
            response?.vendor_details?.COMPANYREGISTERED
              ?.company_address_proof_verified_comment,
          comm_11:
            response?.vendor_details?.COMPANYCOMMUNICATION
              ?.company_communication_address_verified_comment,
          comm_12:
            response?.vendor_details?.advanced_registration_details[0]
              ?.contact_person_verified_comment,
          comm_13:
            response?.vendor_details?.advanced_registration_details[0]
              ?.contact_number_verified_comment,
          comm_14:
            response?.vendor_details?.advanced_registration_details[0]
              ?.email_verified_comment,
          turn_comm_1: response?.vendor_details?.turnover[0]?.note,
          turn_comm_2: response?.vendor_details?.turnover[1]?.note,
          turn_comm_3: response?.vendor_details?.turnover[2]?.note,
          emd_comm: response?.vendor_details?.emd?.emd_verified_comment,
        });
        setLoading(false);

        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getTurnOvers = (vendor_id: any) => {
    api
      .post(
        `/vendor-finacial-datas`,
        {
          user_id: vendor_id,
        },
        true
      )
      .then(async function ([success, response]) {
        setTurnOver(response?.data || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verifyData = (id: any, status: any, type: any, comment_value: any) => {
    api
      .post(
        `/doc_verification`,
        {
          user_id: vendor_id,
          type: type,
          doc_status: status,
          comment: comment_value,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Verified Successfully");
        }
        getAdvancedDetails();
        setLoading(false);

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const docFilter = (type: any) => {
    console.log("sachagcs");
    documents?.filter((item: any) => {
      if (item.type?.id == type) {
        console.log(item.type, "item.type");

        download(item?.id);
        setDocData(item?.id);
      }
    });
  };

  const download = (id: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(`${API_URL}/documents/${id}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        //   const url = URL.createObjectURL(blob);
        //   const a = document.createElement("a");
        //   a.href = url;
        //   console.log(a,"a")
        //  // a.download = "view.pdf";
        //   document.body.appendChild(a);
        //   a.click();
        //   document.body.removeChild(a);
        //   URL.revokeObjectURL(url);

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.innerHTML = "This is a new div element.";
        a.href = url;
        a.download = "Document.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  return (
    <React.Fragment>
      {loading == true ? (
        <div className="text-center py-5">
          {" "}
          <Loader />{" "}
        </div>
      ) : (

        
        <div className="table-wrap responsive-table advanced-details-wrap">
          <div className="skewed-div">
            <h3 className="table-heading">Advanced Details</h3>
          </div>

          <Table className="w-100 table-striped margintop-zero">
            <tbody>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.GST_Number?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.GST_Number?.[language]}:
                  </span>
                  {advanced_details[0]?.gst_number}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn download-icon">
                    <a
                      //href={doc_data}
                      // target="_blank"
                      rel="noreferrer"
                      className="rounded-tag"
                      onClick={() => docFilter(constants.ADVANCED_VERIFY.GST)}
                    >
                      {/* <i title="View" className="icons bi bi-eye lg"></i> */}
                      <i title="Download and View Gst Details" className="icons bi bi-download lg"></i>
                    </a>

                    {advanced_details[0]?.gst_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                          title="Approved"
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.gst_number_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.GST,
                              comment?.comm_1
                            );
                          }}
                          // disabled={true}
                          checked={false}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.gst_number_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.GST,
                              comment?.comm_1
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.GST,
                              comment?.comm_1
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {advanced_details[0]?.gst_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.GST,
                              comment?.comm_1
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.gst_number_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.gst_number_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.GST,
                            comment?.comm_1
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.GST,
                              comment?.comm_1
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {advanced_details[0]?.gst_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  advanced_details[0]?.gst_number_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      name="comm_1"
                      type="textarea"
                      rows="1"
                      placeholder="Enter Comment"
                      className="comment-text"
                      disabled={true}
                      onChange={handleComment}
                      value={comment?.comm_1}
                    ></Input>
                  ) : (
                    <Input
                      name="comm_1"
                      type="textarea"
                      rows="1"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_1}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {advanced_details[0]?.gst_number_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.gst_number_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.gst_number_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Pan_Number?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Pan_Number?.[language]}:
                  </span>
                  {advanced_details[0]?.pan_number}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn download-icon">
                    <a
                      // href={doc_data}
                      rel="noreferrer"
                      className="rounded-tag"
                      onClick={() => docFilter(constants.ADVANCED_VERIFY.PAN)}
                    >
                      {/* <i title="View" className="icons bi bi-eye lg"></i> */}
                      <i title="Download and View PAN Details" className="icons bi bi-download lg"></i>

                    </a>
                    {advanced_details[0]?.pan_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.pan_number_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.PAN,
                              comment?.comm_2
                            );
                          }}
                          // disabled={true}
                          checked={false}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.pan_number_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.PAN,
                              comment?.comm_2
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.PAN,
                              comment?.comm_2
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {advanced_details[0]?.pan_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.PAN,
                              comment?.comm_2
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.pan_number_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.pan_number_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.PAN,
                            comment?.comm_2
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.PAN,
                              comment?.comm_2
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {advanced_details[0]?.pan_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  advanced_details[0]?.pan_number_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_2"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_2}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_2"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_2}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {advanced_details[0]?.pan_number_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.pan_number_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.pan_number_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Vendor_type?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Vendor_type?.[language]}:
                  </span>

                  {VendorType(advanced_details[0]?.vendor_type)}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn">
                    {advanced_details[0]?.vendor_type_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.vendor_type_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          // disabled={true}
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.VENDORTYPE,
                              comment?.comm_3
                            );
                          }}
                          checked={false}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.vendor_type_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.VENDORTYPE,
                              comment?.comm_3
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.VENDORTYPE,
                              comment?.comm_3
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {advanced_details[0]?.vendor_type_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.VENDORTYPE,
                              comment?.comm_3
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.vendor_type_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.vendor_type_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.VENDORTYPE,
                            comment?.comm_3
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.VENDORTYPE,
                              comment?.comm_3
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {advanced_details[0]?.vendor_type_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  advanced_details[0]?.vendor_type_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_3"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_3}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_3"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_3}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {advanced_details[0]?.vendor_type_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.vendor_type_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.vendor_type_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.GST_Class_Name?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.GST_Class_Name?.[language]}:
                  </span>
                  {advanced_details[0]?.gst_class ==
                  constants?.GSTCLASS?.REGISTERED ? (
                    <span>{admin_empanelment?.Registered?.[language]}</span>
                  ) : advanced_details[0]?.gst_class ==
                    constants?.GSTCLASS?.NOTREGISTERED ? (
                    <span>{admin_empanelment?.Not_Registered?.[language]}</span>
                  ) : advanced_details[0]?.gst_class ==
                    constants?.GSTCLASS?.COMPOUNDINGSCHEME ? (
                    <span>
                      {admin_empanelment?.Componding_Scheme?.[language]}
                    </span>
                  ) : advanced_details[0]?.gst_class ==
                    constants?.GSTCLASS?.MARKETER ? (
                    <span>{admin_empanelment?.Marketer?.[language]}</span>
                  ) : advanced_details[0]?.gst_class ==
                    constants?.GSTCLASS?.PSUGOVT ? (
                    <span>
                      {admin_empanelment?.PSU_Organization?.[language]}
                    </span>
                  ) : null}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn">
                    {advanced_details[0]?.gst_class_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.gst_class_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          // disabled={true}
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.GSTCLASSNAME,
                              comment?.comm_4
                            );
                          }}
                          checked={false}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.gst_class_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.GSTCLASSNAME,
                              comment?.comm_4
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.GSTCLASSNAME,
                              comment?.comm_4
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {advanced_details[0]?.gst_class_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.GSTCLASSNAME,
                              comment?.comm_4
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.gst_class_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.gst_class_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.GSTCLASSNAME,
                            comment?.comm_4
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.GSTCLASSNAME,
                              comment?.comm_4
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {advanced_details[0]?.gst_class_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  advanced_details[0]?.gst_class_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_4"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_4}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_4"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_4}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {advanced_details[0]?.gst_class_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.gst_class_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.gst_class_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Is_Your?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Is_Your?.[language]}:
                  </span>
                  {advanced_details[0]?.company_mse
                    ? advanced_details[0]?.company_mse
                    : "No"}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn download-icon">
                    {advanced_details[0]?.company_mse ? (
                      <>
                        {advanced_details[0]?.company_mse == "no" ? null : (
                          <a
                            rel="noreferrer"
                            className="rounded-tag"
                            onClick={() => {
                              docFilter(constants.ADVANCED_VERIFY.MSE);
                            }}
                          >
                            {/* <i title="View" className="icons bi bi-eye lg"></i> */}
                            <i title="Download and View MSME Certificate" className="icons bi bi-download lg"></i>

                          </a>
                        )}

                        {advanced_details[0]?.company_mse_verified ==
                        constants.BASIC_VERIFY.APPROVED ? (
                          <span title="Approved">
                            <Input
                              type="checkbox"
                              disabled={true}
                              checked={true}
                            ></Input>
                          </span>
                        ) : advanced_details[0]?.company_mse_verified ==
                          constants.BASIC_VERIFY.REJECTED ? (
                          <span title="Approve">
                            <Input
                              type="checkbox"
                              onClick={() => {
                                verifyData(
                                  vendor_id,
                                  2,
                                  constants.ADVANCED_VERIFY.MSE,
                                  comment?.comm_5
                                );
                              }}
                              // disabled={true}
                              checked={false}
                            ></Input>
                          </span>
                        ) : advanced_details[0]?.company_mse_verified ==
                          constants.BASIC_VERIFY.REUPLOAD ? (
                          <span title="Approve">
                            <Input
                              type="checkbox"
                              onClick={() => {
                                verifyData(
                                  vendor_id,
                                  2,
                                  constants.ADVANCED_VERIFY.MSE,
                                  comment?.comm_5
                                );
                              }}
                            ></Input>
                          </span>
                        ) : (
                          <span title="Approve">
                            <Input
                              type="checkbox"
                              onClick={() => {
                                verifyData(
                                  vendor_id,
                                  2,
                                  constants.ADVANCED_VERIFY.MSE,
                                  comment?.comm_5
                                );
                              }}
                            ></Input>
                          </span>
                        )}
                        {advanced_details[0]?.company_mse_verified ==
                        constants.BASIC_VERIFY.APPROVED ? (
                          <>
                            <Link
                              to="#"
                              className="refuse-btn"
                              onClick={() => {
                                verifyData(
                                  vendor_id,
                                  3,
                                  constants.ADVANCED_VERIFY.MSE,
                                  comment?.comm_5
                                );
                              }}
                            >
                              <i
                                title="Reject"
                                className="icons icons-reject"
                              ></i>
                            </Link>
                          </>
                        ) : advanced_details[0]?.company_mse_verified ==
                          constants.BASIC_VERIFY.REJECTED ? (
                          <>
                            <Link to="#" className="refuse-btn active">
                              <i
                                title="Rejected"
                                className="icons icons-reject-white"
                              ></i>
                            </Link>
                          </>
                        ) : advanced_details[0]?.company_mse_verified ==
                          constants.BASIC_VERIFY.REUPLOAD ? (
                          <Link
                            to="#"
                            className="refuse-btn"
                            onClick={() => {
                              verifyData(
                                vendor_id,
                                3,
                                constants.ADVANCED_VERIFY.MSE,
                                comment?.comm_5
                              );
                            }}
                          >
                            <i
                              title="Reject"
                              className="icons icons-reject"
                            ></i>
                          </Link>
                        ) : (
                          <>
                            <Link
                              to="#"
                              className="refuse-btn"
                              onClick={() => {
                                verifyData(
                                  vendor_id,
                                  3,
                                  constants.ADVANCED_VERIFY.MSE,
                                  comment?.comm_5
                                );
                              }}
                            >
                              <i
                                title="Reject"
                                className="icons icons-reject"
                              ></i>
                            </Link>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {advanced_details[0]?.company_mse ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_5"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_5}
                    ></Input>
                  ) : advanced_details[0]?.company_mse_verified ==
                      constants.BASIC_VERIFY.APPROVED ||
                    advanced_details[0]?.company_mse_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_5"
                      placeholder="Enter Comment"
                      className="comment-text"
                      disabled={true}
                      onChange={handleComment}
                      value={comment?.comm_5}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_5"
                      placeholder="Enter Comment"
                      className="comment-text"
                      disabled={true}
                      onChange={handleComment}
                      value={comment?.comm_5}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {advanced_details[0]?.company_mse_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.company_mse_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.company_mse_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.company_mse == null ? (
                    <Badge color="secondary">
                      {admin_empanelment?.No_MSME_Added?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="skewed-div">
            <h3 className="table-heading">Company Details</h3>
          </div>
          <Table className="w-100 table-striped margintop-zero">
            <tbody>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Company_Name?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Company_Name?.[language]}:
                  </span>
                  {vendor_data[0]?.name}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn">
                    {vendor_data[0]?.company_name_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : vendor_data[0]?.company_name_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYNAME,
                              comment?.comm_6
                            );
                          }}
                          // disabled={true}
                          checked={false}
                        ></Input>
                      </span>
                    ) : vendor_data[0]?.company_name_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYNAME,
                              comment?.comm_6
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYNAME,
                              comment?.comm_6
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {vendor_data[0]?.company_name_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.COMPANYNAME,
                              comment?.comm_6
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : vendor_data[0]?.company_name_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : vendor_data[0]?.company_name_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.COMPANYNAME,
                            comment?.comm_6
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.COMPANYNAME,
                              comment?.comm_6
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {vendor_data[0]?.company_name_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  vendor_data[0]?.company_name_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_6"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_6}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_6"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_6}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {vendor_data[0]?.company_name_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : vendor_data[0]?.company_name_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : vendor_data[0]?.company_name_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Company_Email?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Company_Email?.[language]}:
                  </span>
                  {vendor_data[0]?.email}
                </td>
                <td>
                  <span className="mobile-heading">Action:</span>
                  <div className="action-flex-btn">
                    {vendor_data[0]?.company_email_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : vendor_data[0]?.company_email_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYEMAIL,
                              comment?.comm_7
                            );
                          }}
                          // disabled={true}
                          checked={false}
                        ></Input>
                      </span>
                    ) : vendor_data[0]?.company_email_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYEMAIL,
                              comment?.comm_7
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYEMAIL,
                              comment?.comm_7
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {vendor_data[0]?.company_email_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.COMPANYEMAIL,
                              comment?.comm_7
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : vendor_data[0]?.company_email_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : vendor_data[0]?.company_email_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.COMPANYEMAIL,
                            comment?.comm_7
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.COMPANYEMAIL,
                              comment?.comm_7
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {vendor_data[0]?.company_email_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  vendor_data[0]?.company_email_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_7"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_7}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_7"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_7}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {vendor_data[0]?.company_email_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : vendor_data[0]?.company_email_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : vendor_data[0]?.company_email_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Contact_Number?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Contact_Number?.[language]}:
                  </span>
                  {vendor_data[0]?.phone}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn">
                    {vendor_data[0]?.company_contact_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : vendor_data[0]?.company_contact_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          // disabled={true}
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYCONTACTNUMBER,
                              comment?.comm_8
                            );
                          }}
                          checked={false}
                        ></Input>
                      </span>
                    ) : vendor_data[0]?.company_contact_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYCONTACTNUMBER,
                              comment?.comm_8
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.COMPANYCONTACTNUMBER,
                              comment?.comm_8
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {vendor_data[0]?.company_contact_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.COMPANYCONTACTNUMBER,
                              comment?.comm_8
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : vendor_data[0]?.company_contact_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : vendor_data[0]?.company_contact_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.COMPANYCONTACTNUMBER,
                            comment?.comm_8
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.COMPANYCONTACTNUMBER,
                              comment?.comm_8
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {vendor_data[0]?.company_contact_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  vendor_data[0]?.company_contact_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_8"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_8}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_8"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_8}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {vendor_data[0]?.company_contact_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : vendor_data[0]?.company_contact_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : vendor_data[0]?.company_contact_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {
                    admin_empanelment?.Company_Incorporation_Certificate?.[
                      language
                    ]
                  }
                </td>
                <td>
                  <span className="mobile-heading">
                    {
                      admin_empanelment?.Company_Incorporation_Certificate?.[
                        language
                      ]
                    }
                    :
                  </span>
                  {advanced_details[0]?.incorporation_date
                    ? moment(advanced_details[0]?.incorporation_date).format(
                        "DD-MM-YYYY"
                      )
                    : null}
                </td>
                <td>
                  <span className="mobile-heading">Action:</span>
                  <div className="action-flex-btn download-icon">
                    <a
                      rel="noreferrer"
                      className="rounded-tag"
                      onClick={() =>
                        docFilter(constants.ADVANCED_VERIFY.INCORP)
                      }
                    >
                      {/* <i title="View" className="icons bi bi-eye lg"></i> */}
                      <i title="Download and View Company Incorporation Certificate" className="icons bi bi-download lg"></i>

                    </a>
                    {vendor_data[0]?.company_incorp_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : vendor_data[0]?.company_incorp_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          // disabled={true}
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.INCORP,
                              comment?.comm_9
                            );
                          }}
                          checked={false}
                        ></Input>
                      </span>
                    ) : vendor_data[0]?.company_incorp_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.INCORP,
                              comment?.comm_9
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.INCORP,
                              comment?.comm_9
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {vendor_data[0]?.company_incorp_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.INCORP,
                              comment?.comm_9
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : vendor_data[0]?.company_incorp_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : vendor_data[0]?.company_incorp_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.INCORP,
                            comment?.comm_9
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.INCORP,
                              comment?.comm_9
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {vendor_data[0]?.company_incorp_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  vendor_data[0]?.company_incorp_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_9"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_9}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_9"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_9}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {vendor_data[0]?.company_incorp_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : vendor_data[0]?.company_incorp_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : vendor_data[0]?.company_incorp_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Company_Registered_Address?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Company_Registered_Address?.[language]}:
                  </span>{" "}
                  {com_reg?.building_number},
                  {com_reg?.building_address},
                  {com_reg?.landmark ? com_reg?.landmark+"," : ""}
                  {com_reg?.pincode},
                  {com_reg?.city},{""}
                  {com_reg?.district}, {com_reg?.state},
                  {""}
                  {com_reg?.country}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn">
                    {/* <a
                      rel="noreferrer"
                      className="rounded-tag"
                      onClick={() =>
                        docFilter(constants.ADVANCED_VERIFY.AADRESS_PROOF)
                      }
                    >
                      <i title="View" className="icons bi bi-eye lg"></i>
                    </a> */}
                    {com_reg?.company_address_proof_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : com_reg?.company_address_proof_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          // disabled={true}
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.AADRESS_PROOF,
                              comment?.comm_10
                            );
                          }}
                          checked={false}
                        ></Input>
                      </span>
                    ) : com_reg?.company_address_proof_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.AADRESS_PROOF,
                              comment?.comm_10
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.AADRESS_PROOF,
                              comment?.comm_10
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {com_reg?.company_address_proof_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.AADRESS_PROOF,
                              comment?.comm_10
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : com_reg?.company_address_proof_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : com_reg?.company_address_proof_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.AADRESS_PROOF,
                            comment?.comm_10
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.AADRESS_PROOF,
                              comment?.comm_10
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {com_reg?.company_address_proof_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  com_reg?.company_address_proof_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_10"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_10}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_10"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_10}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {com_reg?.company_address_proof_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : com_reg?.company_address_proof_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : com_reg?.company_address_proof_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Company_Communication_Address?.[language]}{" "}
                </td>
                <td>
                  <span className="mobile-heading">
                    {
                      admin_empanelment?.Company_Communication_Address?.[
                        language
                      ]
                    }
                    :
                  </span>
                  {com_add?.building_number},
                  {com_add?.building_address},
                  {com_add?.landmark ? com_add?.landmark+"," : ""}
                  {com_add?.pincode},
                  {com_add?.city},{""}
                  {com_add?.district}, {com_add?.state}, 
                  {""}
                  {com_add?.country}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn">
                    {com_add?.company_communication_address_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : com_add?.company_communication_address_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          // disabled={true}
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY
                                .COMPANYCOMMUNICATIONADDRESS,
                              comment?.comm_11
                            );
                          }}
                          checked={false}
                        ></Input>
                      </span>
                    ) : com_add?.company_communication_address_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY
                                .COMPANYCOMMUNICATIONADDRESS,
                              comment?.comm_11
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY
                                .COMPANYCOMMUNICATIONADDRESS,
                              comment?.comm_11
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {com_add?.company_communication_address_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY
                                .COMPANYCOMMUNICATIONADDRESS,
                              comment?.comm_11
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : com_add?.company_communication_address_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : com_add?.company_communication_address_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY
                              .COMPANYCOMMUNICATIONADDRESS,
                            comment?.comm_11
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY
                                .COMPANYCOMMUNICATIONADDRESS,
                              comment?.comm_11
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {com_add?.company_communication_address_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  com_add?.company_communication_address_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_11"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_11}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_11"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_11}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {com_add?.company_communication_address_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : com_add?.company_communication_address_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : com_add?.company_communication_address_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>

          {start_up !== 10 && (
            <>
              {" "}
              <div className="table-inner2">
              <div className="skewed-div">
                <h3 className="table-heading">Turn Over Details</h3>
              </div>
                <Table className="w-100 table-striped margintop-zero">
                  <tbody>
                    <tr>
                      <td className="large-screen-view">
                        {admin_empanelment?.Turn_Over_1?.[language]} 1
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Turn_Over_1?.[language]} 1:
                        </span>
                        ₹{turnOver?.[0]?.[1]?.value}
                        {/* {turn_data[0]?.finance?.amount} */}
                      </td>
                      <td>
                        {/* mobile label*/}
                        <span className="mobile-heading">
                          {admin_empanelment?.year?.[language]}:
                        </span>
                        {/* desktop label */}
                        <span className="large-screen-view">
                          {admin_empanelment?.year?.[language]}:
                        </span>
                        {turnOver?.[0]?.[0]?.value.slice(10, 15)}
                        {/* Apr-01-{turn_data[0]?.finance?.year_from} to Mar-31-
          {Number(turn_data[0]?.finance?.year_from) + 1} */}
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Action?.[language]}:
                        </span>
                        <div className="action-flex-btn download-icon">
                          <a
                            rel="noreferrer"
                            className="rounded-tag"
                            onClick={() =>
                              docFilter(constants.ADVANCED_VERIFY.TURN_OVER1)
                            }
                          >
                            {/* <i title="View" className="icons bi bi-eye lg"></i> */}
                            <i title="Download and View First Turn Over Details" className="icons bi bi-download lg"></i>
                          </a>
                          {turn_data[0]?.status ==
                          constants.BASIC_VERIFY.APPROVED ? (
                            <span title="Approved">
                              <Input
                                type="checkbox"
                                disabled={true}
                                checked={true}
                              ></Input>
                            </span>
                          ) : turn_data[0]?.status ==
                            constants.BASIC_VERIFY.REJECTED ? (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                // disabled={true}
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER1,
                                    comment?.turn_comm_1
                                  );
                                }}
                                checked={false}
                              ></Input>
                            </span>
                          ) : turn_data[0]?.status ==
                            constants.BASIC_VERIFY.REUPLOAD ? (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER1,
                                    comment?.turn_comm_1
                                  );
                                }}
                              ></Input>
                            </span>
                          ) : (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER1,
                                    comment?.turn_comm_1
                                  );
                                }}
                              ></Input>
                            </span>
                          )}
                          {turn_data[0]?.status ==
                          constants.BASIC_VERIFY.APPROVED ? (
                            <>
                              <Link
                                to="#"
                                className="refuse-btn"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    3,
                                    constants.ADVANCED_VERIFY.TURN_OVER1,
                                    comment?.turn_comm_1
                                  );
                                }}
                              >
                                <i
                                  title="Reject"
                                  className="icons icons-reject"
                                ></i>
                              </Link>
                            </>
                          ) : turn_data[0]?.status ==
                            constants.BASIC_VERIFY.REJECTED ? (
                            <>
                              <Link to="#" className="refuse-btn active">
                                <i
                                  title="Rejected"
                                  className="icons icons-reject-white"
                                ></i>
                              </Link>
                            </>
                          ) : turn_data[0]?.status ==
                            constants.BASIC_VERIFY.REUPLOAD ? (
                            <Link
                              to="#"
                              className="refuse-btn"
                              onClick={() => {
                                verifyData(
                                  vendor_id,
                                  3,
                                  constants.ADVANCED_VERIFY.TURN_OVER1,
                                  comment?.turn_comm_1
                                );
                              }}
                            >
                              <i
                                title="Reject"
                                className="icons icons-reject"
                              ></i>
                            </Link>
                          ) : (
                            <>
                              <Link
                                to="#"
                                className="refuse-btn"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    3,
                                    constants.ADVANCED_VERIFY.TURN_OVER1,
                                    comment?.turn_comm_1
                                  );
                                }}
                              >
                                <i
                                  title="Reject"
                                  className="icons icons-reject"
                                ></i>
                              </Link>
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.comment?.[language]}:
                        </span>
                        {turn_data[0]?.status ==
                          constants.BASIC_VERIFY.APPROVED ||
                        turn_data[0]?.status ==
                          constants.BASIC_VERIFY.REJECTED ? (
                          <Input
                            type="textarea"
                            rows="1"
                            name="turn_comm_1"
                            disabled={true}
                            placeholder="Enter Comment"
                            className="comment-text"
                            onChange={handleComment}
                            value={comment?.turn_comm_1}
                          ></Input>
                        ) : (
                          <Input
                            type="textarea"
                            rows="1"
                            name="turn_comm_1"
                            placeholder="Enter Comment"
                            className="comment-text"
                            onChange={handleComment}
                            value={comment?.turn_comm_1}
                          ></Input>
                        )}
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Action?.[language]}:
                        </span>
                        {turn_data[0]?.status ==
                        constants.BASIC_VERIFY.APPROVED ? (
                          <Badge color="success">
                            {admin_empanelment?.Approved?.[language]}
                          </Badge>
                        ) : turn_data[0]?.status ==
                          constants.BASIC_VERIFY.REJECTED ? (
                          <Badge color="danger">
                            {admin_empanelment?.Rejected?.[language]}
                          </Badge>
                        ) : turn_data[0]?.status ==
                          constants.BASIC_VERIFY.REUPLOAD ? (
                          <Badge color="warning">
                            {admin_empanelment?.Reuploaded?.[language]}
                          </Badge>
                        ) : (
                          <Badge color="warning">
                            {admin_empanelment?.pending?.[language]}
                          </Badge>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="large-screen-view">
                        {admin_empanelment?.Turn_Over_1?.[language]} 2
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Turn_Over_1?.[language]}2:
                        </span>
                        ₹{turnOver?.[1]?.[1]?.value}
                        {/* {turn_data[1]?.finance?.amount} */}
                      </td>
                      <td>
                        {/* Mobile label */}
                        <span className="mobile-heading">
                          {admin_empanelment?.year?.[language]}:
                        </span>
                        {/* Desktop Label */}
                        <span className="large-screen-view">
                          {admin_empanelment?.year?.[language]}:
                        </span>
                        {turnOver?.[1]?.[0]?.value.slice(10, 15)}
                        {/* Apr-01-{turn_data[1]?.finance?.year_from} to Mar-31-
          {Number(turn_data[1]?.finance?.year_from) + 1} */}
                      </td>

                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Action?.[language]}:
                        </span>
                        <div className="action-flex-btn download-icon">
                          <a
                            rel="noreferrer"
                            className="rounded-tag"
                            onClick={() =>
                              docFilter(constants.ADVANCED_VERIFY.TURN_OVER2)
                            }
                          >
                            {/* <i title="View" className="icons bi bi-eye lg"></i> */}
                            <i title="Download and Second Turn Over Details" className="icons bi bi-download lg"></i>
                          </a>
                          {turn_data[1]?.status ==
                          constants.BASIC_VERIFY.APPROVED ? (
                            <span title="Approved">
                              <Input
                                type="checkbox"
                                disabled={true}
                                checked={true}
                              ></Input>
                            </span>
                          ) : turn_data[1]?.status ==
                            constants.BASIC_VERIFY.REJECTED ? (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                // disabled={true}
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER2,
                                    comment?.turn_comm_2
                                  );
                                }}
                                checked={false}
                              ></Input>
                            </span>
                          ) : turn_data[0]?.status ==
                            constants.BASIC_VERIFY.REUPLOAD ? (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER2,
                                    comment?.turn_comm_2
                                  );
                                }}
                              ></Input>
                            </span>
                          ) : (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER2,
                                    comment?.turn_comm_2
                                  );
                                }}
                              ></Input>
                            </span>
                          )}
                          {turn_data[1]?.status ==
                          constants.BASIC_VERIFY.APPROVED ? (
                            <>
                              <Link
                                to="#"
                                className="refuse-btn"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    3,
                                    constants.ADVANCED_VERIFY.TURN_OVER2,
                                    comment?.turn_comm_2
                                  );
                                }}
                              >
                                <i
                                  title="Reject"
                                  className="icons icons-reject"
                                ></i>
                              </Link>
                            </>
                          ) : turn_data[1]?.status ==
                            constants.BASIC_VERIFY.REJECTED ? (
                            <>
                              <Link to="#" className="refuse-btn active">
                                <i
                                  title="Rejected"
                                  className="icons icons-reject-white"
                                ></i>
                              </Link>
                            </>
                          ) : turn_data[0]?.status ==
                            constants.BASIC_VERIFY.REUPLOAD ? (
                            <Link
                              to="#"
                              className="refuse-btn"
                              onClick={() => {
                                verifyData(
                                  vendor_id,
                                  3,
                                  constants.ADVANCED_VERIFY.TURN_OVER2,
                                  comment?.turn_comm_2
                                );
                              }}
                            >
                              <i
                                title="Reject"
                                className="icons icons-reject"
                              ></i>
                            </Link>
                          ) : (
                            <>
                              <Link
                                to="#"
                                className="refuse-btn"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    3,
                                    constants.ADVANCED_VERIFY.TURN_OVER2,
                                    comment?.turn_comm_2
                                  );
                                }}
                              >
                                <i
                                  title="Reject"
                                  className="icons icons-reject"
                                ></i>
                              </Link>
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.comment?.[language]}:
                        </span>
                        {turn_data[1]?.status ==
                          constants.BASIC_VERIFY.APPROVED ||
                        turn_data[1]?.status ==
                          constants.BASIC_VERIFY.REJECTED ? (
                          <Input
                            type="textarea"
                            rows="1"
                            name="turn_comm_2"
                            disabled={true}
                            placeholder="Enter Comment"
                            className="comment-text"
                            onChange={handleComment}
                            value={comment?.turn_comm_2}
                          ></Input>
                        ) : (
                          <Input
                            type="textarea"
                            rows="1"
                            name="turn_comm_2"
                            placeholder="Enter Comment"
                            className="comment-text"
                            onChange={handleComment}
                            value={comment?.turn_comm_2}
                          ></Input>
                        )}
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Status?.[language]}:
                        </span>
                        {turn_data[1]?.status ==
                        constants.BASIC_VERIFY.APPROVED ? (
                          <Badge color="success">
                            {admin_empanelment?.Approved?.[language]}
                          </Badge>
                        ) : turn_data[1]?.status ==
                          constants.BASIC_VERIFY.REJECTED ? (
                          <Badge color="danger">
                            {admin_empanelment?.Rejected?.[language]}
                          </Badge>
                        ) : turn_data[1]?.status ==
                          constants.BASIC_VERIFY.REUPLOAD ? (
                          <Badge color="warning">
                            {admin_empanelment?.Reuploaded?.[language]}
                          </Badge>
                        ) : (
                          <Badge color="warning">
                            {admin_empanelment?.pending?.[language]}
                          </Badge>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="large-screen-view">
                        {admin_empanelment?.Turn_Over_1?.[language]} 3
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Turn_Over_1?.[language]} 3:
                        </span>
                        ₹{turnOver?.[2]?.[1]?.value}
                        {/* {turn_data[2]?.finance?.amount} */}
                      </td>
                      <td>
                        {" "}
                        {/* Mobile label */}
                        <span className="mobile-heading">
                          {admin_empanelment?.year?.[language]}:
                        </span>
                        {/* Desktop Label */}
                        <span className="large-screen-view">
                          {admin_empanelment?.year?.[language]}:
                        </span>
                        {/* Apr-01-{turn_data[2]?.finance?.year_from} to Mar-31-
          {Number(turn_data[2]?.finance?.year_from) + 1} */}
                        {turnOver?.[2]?.[0]?.value.slice(10, 15)}
                      </td>

                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Action?.[language]}:
                        </span>
                        <div className="action-flex-btn download-icon">
                          <a
                            rel="noreferrer"
                            className="rounded-tag"
                            onClick={() =>
                              docFilter(constants.ADVANCED_VERIFY.TURN_OVER3)
                            }
                          >
                            {/* <i title="View" className="icons bi bi-eye lg"></i> */}
                            <i title="Download and Third Turn Over Details" className="icons bi bi-download lg"></i>
                          </a>
                          {turn_data[2]?.status ==
                          constants.BASIC_VERIFY.APPROVED ? (
                            <span title="Approved">
                              <Input
                                type="checkbox"
                                disabled={true}
                                checked={true}
                              ></Input>
                            </span>
                          ) : turn_data[2]?.status ==
                            constants.BASIC_VERIFY.REJECTED ? (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                // disabled={true}
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER3,
                                    comment?.turn_comm_3
                                  );
                                }}
                                checked={false}
                              ></Input>
                            </span>
                          ) : turn_data[2]?.status ==
                            constants.BASIC_VERIFY.REUPLOAD ? (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER3,
                                    comment?.turn_comm_3
                                  );
                                }}
                              ></Input>
                            </span>
                          ) : (
                            <span title="Approve">
                              <Input
                                type="checkbox"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    2,
                                    constants.ADVANCED_VERIFY.TURN_OVER3,
                                    comment?.turn_comm_3
                                  );
                                }}
                              ></Input>
                            </span>
                          )}
                          {turn_data[2]?.status ==
                          constants.BASIC_VERIFY.APPROVED ? (
                            <>
                              <Link
                                to="#"
                                className="refuse-btn"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    3,
                                    constants.ADVANCED_VERIFY.TURN_OVER3,
                                    comment?.turn_comm_3
                                  );
                                }}
                              >
                                <i
                                  title="Reject"
                                  className="icons icons-reject"
                                ></i>
                              </Link>
                            </>
                          ) : turn_data[2]?.status ==
                            constants.BASIC_VERIFY.REJECTED ? (
                            <>
                              <Link to="#" className="refuse-btn active">
                                <i
                                  title="Rejected"
                                  className="icons icons-reject-white"
                                ></i>
                              </Link>
                            </>
                          ) : turn_data[2]?.status ==
                            constants.BASIC_VERIFY.REUPLOAD ? (
                            <Link
                              to="#"
                              className="refuse-btn"
                              onClick={() => {
                                verifyData(
                                  vendor_id,
                                  3,
                                  constants.ADVANCED_VERIFY.TURN_OVER3,
                                  comment?.turn_comm_3
                                );
                              }}
                            >
                              <i
                                title="Reject"
                                className="icons icons-reject"
                              ></i>
                            </Link>
                          ) : (
                            <>
                              <Link
                                to="#"
                                className="refuse-btn"
                                onClick={() => {
                                  verifyData(
                                    vendor_id,
                                    3,
                                    constants.ADVANCED_VERIFY.TURN_OVER3,
                                    comment?.turn_comm_3
                                  );
                                }}
                              >
                                <i
                                  title="Reject"
                                  className="icons icons-reject"
                                ></i>
                              </Link>
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.comment?.[language]}:
                        </span>
                        {turn_data[2]?.status ==
                          constants.BASIC_VERIFY.APPROVED ||
                        turn_data[2]?.status ==
                          constants.BASIC_VERIFY.REJECTED ? (
                          <Input
                            type="textarea"
                            rows="1"
                            name="turn_comm_3"
                            disabled={true}
                            placeholder="Enter Comment"
                            className="comment-text"
                            onChange={handleComment}
                            value={comment?.turn_comm_3}
                          ></Input>
                        ) : (
                          <Input
                            type="textarea"
                            rows="1"
                            name="turn_comm_3"
                            placeholder="Enter Comment"
                            className="comment-text"
                            onChange={handleComment}
                            value={comment?.turn_comm_3}
                          ></Input>
                        )}
                      </td>
                      <td>
                        <span className="mobile-heading">
                          {admin_empanelment?.Status?.[language]}:
                        </span>
                        {turn_data[2]?.status ==
                        constants.BASIC_VERIFY.APPROVED ? (
                          <Badge color="success">
                            {admin_empanelment?.Approved?.[language]}
                          </Badge>
                        ) : turn_data[2]?.status ==
                          constants.BASIC_VERIFY.REJECTED ? (
                          <Badge color="danger">
                            {admin_empanelment?.Rejected?.[language]}
                          </Badge>
                        ) : turn_data[2]?.status ==
                          constants.BASIC_VERIFY.REUPLOAD ? (
                          <Badge color="warning">
                            {admin_empanelment?.Reuploaded?.[language]}
                          </Badge>
                        ) : (
                          <Badge color="warning">
                            {admin_empanelment?.pending?.[language]}
                          </Badge>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </>
          )}

          <div className="skewed-div">
            <h3 className="table-heading">NRLA Details</h3>
          </div>       
          {emd?.length !== 0 ? (
            <Table className="w-100 table-striped margintop-zero">
              <tbody>
                <tr>
                  <td className="large-screen-view">
                    {
                      admin_empanelment?.Non_refundable_Loyalty_Amount?.[
                        language
                      ]
                    }
                  </td>
                  <td>
                    <span className="mobile-heading">
                      {
                        admin_empanelment?.Non_refundable_Loyalty_Amount?.[
                          language
                        ]
                      }
                      :
                    </span>
                    ₹{emd?.amount}
                  </td>
                  <td>
                    <span className="mobile-heading">Action:</span>
                    <div className="action-flex-btn download-icon">
                      <a
                        rel="noreferrer"
                        href={payDoc?.file}
                        className="rounded-tag"
                        target="_blank"
                        // onClick={() =>
                        //   docFilter(payDoc?.file)
                        // }
                      >
                        {/* <i title="View" className="icons bi bi-eye lg"></i> */}
                        <i title="Download and Second Turn Over Details" className="icons bi bi-download lg"></i>
                      </a>

                      {/* {doc_data && (
                        <a
                        rel="noreferrer"
                        className="rounded-tag"
                          onClick={() =>
                            docFilter(constants.ADVANCED_VERIFY.EMD)
                          }
                        >
                          <i title="View" className="icons bi bi-eye lg"></i>
                        </a>
                      )} */}
                      {emd?.emd_verified == constants.BASIC_VERIFY.APPROVED ? (
                        <span title="Approved">
                          <Input
                            type="checkbox"
                            disabled={true}
                            checked={true}
                          ></Input>
                        </span>
                      ) : emd?.emd_verified ==
                        constants.BASIC_VERIFY.REJECTED ? (
                        <span title="Approve">
                          <Input
                            type="checkbox"
                            // disabled={true}
                            onClick={() => {
                              verifyData(
                                vendor_id,
                                2,
                                constants.ADVANCED_VERIFY.EMD,
                                comment?.emd_comm
                              );
                            }}
                            checked={false}
                          ></Input>
                        </span>
                      ) : emd?.emd_verified ==
                        constants.BASIC_VERIFY.REUPLOAD ? (
                        <span title="Approve">
                          <Input
                            type="checkbox"
                            onClick={() => {
                              verifyData(
                                vendor_id,
                                2,
                                constants.ADVANCED_VERIFY.EMD,
                                comment?.emd_comm
                              );
                            }}
                          ></Input>
                        </span>
                      ) : (
                        <span title="Approve">
                          <Input
                            type="checkbox"
                            onClick={() => {
                              verifyData(
                                vendor_id,
                                2,
                                constants.ADVANCED_VERIFY.EMD,
                                comment?.emd_comm
                              );
                            }}
                          ></Input>
                        </span>
                      )}
                      {emd?.emd_verified == constants.BASIC_VERIFY.APPROVED ? (
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.EMD,
                              comment?.emd_comm
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      ) : emd?.emd_verified ==
                        constants.BASIC_VERIFY.REJECTED ? (
                        <>
                          <Link to="#" className="refuse-btn active">
                            <i
                              title="Rejected"
                              className="icons icons-reject-white"
                            ></i>
                          </Link>
                        </>
                      ) : emd?.emd_verified ==
                        constants.BASIC_VERIFY.REUPLOAD ? (
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.EMD,
                              comment?.emd_comm
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      ) : (
                        <>
                          <Link
                            to="#"
                            className="refuse-btn"
                            onClick={() => {
                              verifyData(
                                vendor_id,
                                3,
                                constants.ADVANCED_VERIFY.EMD,
                                comment?.emd_comm
                              );
                            }}
                          >
                            <i
                              title="Reject"
                              className="icons icons-reject"
                            ></i>
                          </Link>
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <span className="mobile-heading">
                      {admin_empanelment?.comment?.[language]}:
                    </span>
                    {emd?.emd_verified == constants.BASIC_VERIFY.APPROVED ||
                    emd?.emd_verified == constants.BASIC_VERIFY.REJECTED ? (
                      <Input
                        type="textarea"
                        rows="1"
                        name="emd_comm"
                        disabled={true}
                        placeholder="Enter Comment"
                        className="comment-text"
                        onChange={handleComment}
                        value={comment?.emd_comm}
                      ></Input>
                    ) : (
                      <Input
                        type="textarea"
                        rows="1"
                        name="emd_comm"
                        placeholder="Enter Comment"
                        className="comment-text"
                        onChange={handleComment}
                        value={comment?.emd_comm}
                      ></Input>
                    )}
                  </td>
                  <td>
                    <span className="mobile-heading">
                      {admin_empanelment?.Status?.[language]}:
                    </span>
                    {emd?.emd_verified == constants.BASIC_VERIFY.APPROVED ? (
                      <Badge color="success">
                        {admin_empanelment?.Approved?.[language]}
                      </Badge>
                    ) : emd?.emd_verified == constants.BASIC_VERIFY.REJECTED ? (
                      <Badge color="danger">
                        {admin_empanelment?.Rejected?.[language]}
                      </Badge>
                    ) : emd?.emd_verified == constants.BASIC_VERIFY.REUPLOAD ? (
                      <Badge color="warning">
                        {admin_empanelment?.Reuploaded?.[language]}
                      </Badge>
                    ) : (
                      <Badge color="warning">
                        {admin_empanelment?.pending?.[language]}
                      </Badge>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <Table className="w-100 table-striped">
              <tbody>
                <tr>
                  <td>No NRLA Added</td>
                </tr>
              </tbody>
            </Table>
          )}

          <div className="skewed-div">
            <h3 className="table-heading">Contact Details</h3>
          </div>   
          <Table className="w-100 table-striped margintop-zero">
            <tbody>
              <tr>
                <td className="large-screen-view">Contact Person Name</td>
                <td>
                  <span className="mobile-heading">Contact Person Name:</span>
                  {advanced_details[0]?.point_contact_name}
                </td>
                <td>
                  <span className="mobile-heading">Action:</span>
                  <div className="action-flex-btn">
                    {advanced_details[0]?.contact_person_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.contact_person_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          // disabled={true}
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.CONTACTPERSONNAME,
                              comment?.comm_12
                            );
                          }}
                          checked={false}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.contact_person_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.CONTACTPERSONNAME,
                              comment?.comm_12
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.CONTACTPERSONNAME,
                              comment?.comm_12
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {advanced_details[0]?.contact_person_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.CONTACTPERSONNAME,
                              comment?.comm_12
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.contact_person_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.contact_person_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.CONTACTPERSONNAME,
                            comment?.comm_12
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.CONTACTPERSONNAME,
                              comment?.comm_12
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">Comment:</span>
                  {advanced_details[0]?.contact_person_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  advanced_details[0]?.contact_person_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_12"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_12}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_12"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_12}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {advanced_details[0]?.contact_person_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.contact_person_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.contact_person_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Contact_Number?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Contact_Number?.[language]}:
                  </span>
                  {advanced_details[0]?.point_contact_number}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn">
                    {advanced_details[0]?.contact_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.contact_number_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.CONTACTNUMBER,
                              comment?.comm_13
                            );
                          }}
                          // disabled={true}
                          checked={false}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.contact_number_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.CONTACTNUMBER,
                              comment?.comm_13
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.CONTACTNUMBER,
                              comment?.comm_13
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {advanced_details[0]?.contact_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.CONTACTNUMBER,
                              comment?.comm_13
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.contact_number_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.contact_number_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.CONTACTNUMBER,
                            comment?.comm_13
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.CONTACTNUMBER,
                              comment?.comm_13
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {advanced_details[0]?.contact_number_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  advanced_details[0]?.contact_number_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_13"
                      disabled={true}
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_13}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_13"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_13}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {advanced_details[0]?.contact_number_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.contact_number_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.contact_number_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td className="large-screen-view">
                  {admin_empanelment?.Email_ID?.[language]}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Email_ID?.[language]}:
                  </span>
                  {advanced_details[0]?.point_email_id}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Action?.[language]}:
                  </span>
                  <div className="action-flex-btn">
                    {advanced_details[0]?.email_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.email_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.EMAILID,
                              comment?.comm_14
                            );
                          }}
                          // disabled={true}
                          checked={false}
                        ></Input>
                      </span>
                    ) : advanced_details[0]?.email_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.EMAILID,
                              comment?.comm_14
                            );
                          }}
                        ></Input>
                      </span>
                    ) : (
                      <span title="Approve">
                        <Input
                          type="checkbox"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              2,
                              constants.ADVANCED_VERIFY.EMAILID,
                              comment?.comm_14
                            );
                          }}
                        ></Input>
                      </span>
                    )}
                    {advanced_details[0]?.email_verified ==
                    constants.BASIC_VERIFY.APPROVED ? (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.EMAILID,
                              comment?.comm_14
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.email_verified ==
                      constants.BASIC_VERIFY.REJECTED ? (
                      <>
                        <Link to="#" className="refuse-btn active">
                          <i
                            title="Rejected"
                            className="icons icons-reject-white"
                          ></i>
                        </Link>
                      </>
                    ) : advanced_details[0]?.email_verified ==
                      constants.BASIC_VERIFY.REUPLOAD ? (
                      <Link
                        to="#"
                        className="refuse-btn"
                        onClick={() => {
                          verifyData(
                            vendor_id,
                            3,
                            constants.ADVANCED_VERIFY.EMAILID,
                            comment?.comm_14
                          );
                        }}
                      >
                        <i title="Reject" className="icons icons-reject"></i>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="refuse-btn"
                          onClick={() => {
                            verifyData(
                              vendor_id,
                              3,
                              constants.ADVANCED_VERIFY.EMAILID,
                              comment?.comm_14
                            );
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.comment?.[language]}:
                  </span>
                  {advanced_details[0]?.email_verified ==
                    constants.BASIC_VERIFY.APPROVED ||
                  advanced_details[0]?.email_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_14"
                      placeholder="Enter Comment"
                      disabled={true}
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_14}
                    ></Input>
                  ) : (
                    <Input
                      type="textarea"
                      rows="1"
                      name="comm_14"
                      placeholder="Enter Comment"
                      className="comment-text"
                      onChange={handleComment}
                      value={comment?.comm_14}
                    ></Input>
                  )}
                </td>
                <td>
                  <span className="mobile-heading">
                    {admin_empanelment?.Status?.[language]}:
                  </span>
                  {advanced_details[0]?.email_verified ==
                  constants.BASIC_VERIFY.APPROVED ? (
                    <Badge color="success">
                      {admin_empanelment?.Approved?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.email_verified ==
                    constants.BASIC_VERIFY.REJECTED ? (
                    <Badge color="danger">
                      {admin_empanelment?.Rejected?.[language]}
                    </Badge>
                  ) : advanced_details[0]?.email_verified ==
                    constants.BASIC_VERIFY.REUPLOAD ? (
                    <Badge color="warning">
                      {admin_empanelment?.Reuploaded?.[language]}
                    </Badge>
                  ) : (
                    <Badge color="warning">
                      {admin_empanelment?.pending?.[language]}
                    </Badge>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
}
