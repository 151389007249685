import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import TextError from '../../../../Components/Errors/TextError';
import { AddDepartmentValidationSchema } from './AddDepartmentValidationSchema';
import { api } from '../../../../Library/CustomApi';
import { toast } from 'react-toastify';
import { Context } from "../../../../Components/Context/Context";
import { Admin_Departlist} from "../../../../Library/LanguageConversion/Admin_Departlist";
import { useContext } from "react";

interface propType{
    setOpen: Function,
    getDepartmentsList:Function
}

const AddDepartmentType = ({ setOpen, getDepartmentsList }:propType) => {


    // formik initial values 
    const initialValues = {
        name: '',
    };

    const addDepertmentType = (values: any, actions: any) => {

        api
            .post(
                `/store_get_dept_type`,
                {
                    name: values.name,
                },
                true
            )
            .then(async function ([success, response, status]) {
                if (status == 200) {
                    toast?.success('Department Added')
                }
                setOpen(false)
                getDepartmentsList()
                return response;
            })
            .catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error(err?.response?.data?.message);
                    actions.setSubmitting(false);
                }
                actions.setSubmitting(false);
            });
    }
    const { language, setLanguage } = useContext(Context);

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={AddDepartmentValidationSchema}
                onSubmit={(values, actions) => {
                    addDepertmentType(values, actions)
                }}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    errors,
                    touched,
                    isSubmitting,
                    handleReset,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched
                }) => {

                    return (
                        <>
                            <Form onSubmit={handleSubmit} className="form-wrap">
                                <Row>
                                    <Col md={12}>
                                        <div className="custom-input">
                                            <FormGroup className="form-group">
                                                <Label> {Admin_Departlist?.DepartmentName[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input type="text" name="name" onChange={handleChange} className={`${touched.name && errors.name ? "is-invalid" : ""}`} />
                                                <ErrorMessage name="name" component={TextError} />
                                            </FormGroup>
                                        </div>
                                    </Col>


                                    <Col className="footer-sticky">
                                        <FormGroup className="text-end">
                                            <Button type="submit" title="Submit" disabled={isSubmitting} color="primary">{Admin_Departlist?.Submit[language]}</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}

export default AddDepartmentType