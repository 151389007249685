import React, { useEffect,useContext } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import { useState, useMemo } from "react";
// import TextField from "../../../components/Fields/TextField";
import { api } from "../../../Library/CustomApi";

import { toast } from "react-toastify";

import Logout from "./Logout";
import Chellon from "../payments/Chellan";
import { convertISODateToDateTime } from "../../../Library/Constants/helpers";
import Loader from "../../../Components/Loader/Loader";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
export default function EmdDetails() {
  const { language, setLanguage } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({
    emd: "",
    category: "",
  });
  const [start_up, setStartup] = useState();
  const [emdhistory, setEmdhistory] = useState<any>([]);
  const [start_up_payment, setStartUpPayment] = useState({
    bank_name: "",
    account_holder_name: "",
    branch_name: "",
    ifsc_code: "",
    date: "",
    amount: "",
  });
  const [value, setValue] = useState({
    document_status: "",
    emd_status: "",
    start_up: "",
    advanced: "",
  });
  useEffect(() => {
    emds();
    emdlist();
  }, []);
  const emds = () => {
    setLoading(true);
    api
      .get(`/vendor/emd`, true)
      .then(async function ([success, response]) {
        let v: any = response;
        setCategory({ emd: v.emd, category: v.category });
        console.log(v,"jfbr")
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    api
      .post(`/get_vendor_profile`, {}, true)
      .then(async function ([success, response]) {
        setStartup(
          response?.vendor_details?.advanced_registration_details?.[0]
            ?.vendor_type
        );

        setLoading(false);

        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    api
      .get(`/vendorPay`, true)
      .then(async function ([success, response]) {
        if (response?.status == "error") {
        } else {
          setStartUpPayment(response?.data || []);
          setStartUpPayment({
            ...start_up_payment,
            bank_name: response?.data?.bank_name,
            account_holder_name: response?.data?.account_holder_name,
            branch_name: response?.data?.branch_name,
            ifsc_code: response?.data?.ifsc_code,
            date: response?.data?.created_at,
            amount: response?.data?.amount,
          });
          setLoading(false);
        }
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });

    // vendorPay
  };

  const emdlist = () => {
    api
      .get(`/vendor/emd-history`, true)
      .then(async function ([success, response]) {
        setEmdhistory(response?.data?.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="login-content">
        <Form className="Reg-wrap">
          {loading ? (
            <div className="loading-overlay">
              <Loader />
            </div>
          ) : (
            <>
              <Row>
                {start_up == 10 ? (
                  <>
                    {start_up_payment?.bank_name ? (
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <div className="emd-check-wrap">
                            <Input type="radio" checked disabled></Input>
                            <Label>
                              <span> NRLA ₹88500/-</span>
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col md={4} sm={6}>
                        <FormGroup>
                          <div className="emd-check-wrap">
                            <Input type="radio" checked></Input>
                            <Label>
                              <span> NRLA ₹88500/-</span>
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                  </>
                ) : (
                  <>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <div className="emd-check-wrap">
                          {category?.category == "A" ? (
                            <>
                              <Input type="radio" checked></Input>
                              <Label>
                              {Vendor_profileUpdation?.for?.[language]} {category?.category } {Vendor_profileUpdation?.Category?.[language]}
                                <br />
                                <span>{Vendor_profileUpdation?.Above_20_Crores?.[language]} — NRLA ₹{category?.emd}/-</span>
                              </Label>
                            </>
                          ) : (
                            <>
                              <Input type="radio" disabled></Input>
                              <Label>
                              {Vendor_profileUpdation?.For_A_Category?.[language]}
                                <br />
                                <span>{Vendor_profileUpdation?.Above_20_Crores?.[language]}</span>
                              </Label>
                            </>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <div className="emd-check-wrap">
                          {category?.category  == "B" ? (
                            <>
                              <Input type="radio" checked></Input>
                              <Label>
                              {Vendor_profileUpdation?.for?.[language]} {category?.category } {Vendor_profileUpdation?.Category?.[language]}
                                <br />
                                <span>{Vendor_profileUpdation?.ten_20_Crores_NRLA?.[language]} ₹{category?.emd}/-</span>
                              </Label>
                            </>
                          ) : (
                            <>
                              <Input type="radio" disabled></Input>
                              <Label>
                              {Vendor_profileUpdation?.For_B_Category?.[language]}
                                <br />
                                <span>{Vendor_profileUpdation?.ten_20_Crores?.[language]}</span>
                              </Label>
                            </>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6}>
                      <FormGroup>
                        <div className="emd-check-wrap">
                          {category?.category  == "C" ? (
                            <>
                              <Input type="radio" checked></Input>
                              <Label>
                              {Vendor_profileUpdation?.for?.[language]} {category?.category } {Vendor_profileUpdation?.Category?.[language]}
                                <br />
                                <span>{Vendor_profileUpdation?.upto_10_Crores?.[language]} — NRLA ₹{category?.emd}/-</span>
                              </Label>
                            </>
                          ) : (
                            <>
                              <Input type="radio" disabled></Input>
                              <Label>
                              {Vendor_profileUpdation?.For_C_Category?.[language]}
                                <br />
                                <span>{Vendor_profileUpdation?.upto_10_Crores?.[language]}</span>
                              </Label>
                            </>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col md={12}>
                  {emdhistory.length !== 0 ? (
                    <>
                      {" "}
                      {emdhistory.length != 0 && (
                        <div className="table-outer-wrap">
                          <h6 className="heading">{Vendor_profileUpdation?.History_details?.[language]}</h6>
                          <div className="history-table">
                            <Table className="w-100">
                              <thead>
                                <tr>
                                  <th>{Vendor_profileUpdation?.Sl_No?.[language]}</th>
                                  <th>{Vendor_profileUpdation?.Transaction_ID?.[language]}</th>
                                  <th>{Vendor_profileUpdation?.Start_Date?.[language]}</th>
                                  <th>{Vendor_profileUpdation?.Category?.[language]}</th>
                                  <th>{Vendor_profileUpdation?.Amount?.[language]}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {emdhistory?.map((item: any, index: number) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item?.transact_id}</td>
                                      <td>
                                        {item?.created_at != null
                                          ? item?.created_at.slice(0, 10)
                                          : null}
                                      </td>
                                      <td>
                                        {category?.category == "A"
                                          ? "A"
                                          : category?.category  == "B"
                                          ? "B"
                                          : "C"}{" "}
                                        {Vendor_profileUpdation?.Category?.[language]}
                                      </td>
                                      <td className="primary-text">
                                        NRLA ₹{item?.amount}/-
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}{" "}
                    </>
                  ) : (
                    <>
                      {start_up_payment?.account_holder_name && (
                        <div className="table-outer-wrap">
                          <h6 className="heading">{Vendor_profileUpdation?.History_details?.[language]}</h6>
                          <div className="history-table">
                            <Table className="w-100">
                              <thead>
                                <tr>
                                  <th>{Vendor_profileUpdation?.Account_Holder_Name?.[language]}</th>
                                  <th>{Vendor_profileUpdation?.Payment_Date?.[language]}</th>
                                  <th>{Vendor_profileUpdation?.Bank_Name?.[language]}</th>
                                  <th>{Vendor_profileUpdation?.Amount?.[language]}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>
                                    {start_up_payment?.account_holder_name}
                                  </th>
                                  <th>
                                    {convertISODateToDateTime(
                                      start_up_payment?.date
                                    )}
                                  </th>
                                  <th>{start_up_payment?.bank_name}</th>
                                  <th>₹{start_up_payment?.amount}</th>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                {emdhistory?.length == 0 ? (
                  <>
                    {start_up == 10 ? (
                      <>
                        {start_up_payment?.bank_name ? null : (
                          <>
                            {category?.emd == "0" ? (
                              <Col sm={12} className="text-end">
                                <Button
                                  className="btn btn-primary mb-5"
                                  color="primary"
                                  // type="submit"
                                  disabled
                                  onClick={() => {
                                    navigate("/chellon", {
                                      state: {
                                        amount: "88500",
                                        category: category?.category,
                                      },
                                    });
                                  }}
                                >
                                  {Vendor_profileUpdation?.Pay?.[language]}
                                </Button>
                              </Col>
                            ) : (
                              <Col sm={12} className="text-end">
                                <Button
                                  className="btn btn-primary mb-5"
                                  color="primary"
                                  // type="submit"
                                  onClick={() => {
                                    navigate("/chellon", {
                                      state: {
                                        amount: "88500",
                                        category: category?.category,
                                      },
                                    });
                                  }}
                                >
                                   {Vendor_profileUpdation?.Pay?.[language]}
                                </Button>
                              </Col>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {start_up_payment?.bank_name ? null : (
                          <>
                            {" "}
                            {category?.emd == "0" ? (
                              <Col sm={12} className="text-end">
                                <Button
                                  className="btn btn-primary mb-5"
                                  color="primary"
                                  // type="submit"
                                  disabled
                                  onClick={() => {
                                    navigate("/chellon", {
                                      state: {
                                        amount:
                                        category?.category  == "A"
                                            ? "177000"
                                            : category?.category  == "B"
                                            ? "88500"
                                            : category?.category  == "C"
                                            ? "59000"
                                            : category?.emd,
                                        category:
                                          localStorage.getItem("CAT") ||
                                          category?.category,
                                      },
                                    });
                                  }}
                                >
                                   {Vendor_profileUpdation?.Pay?.[language]}
                                </Button>
                              </Col>
                            ) : (
                              <Col sm={12} className="text-end">
                                <Button
                                  className="btn btn-primary mb-5"
                                  color="primary"
                                  // type="submit"
                                  onClick={() => {
                                    navigate("/chellon", {
                                      state: {
                                        amount:
                                        category?.category  == "A"
                                            ? "177000"
                                            : category?.category  == "B"
                                            ? "88500"
                                            : category?.category  == "C"
                                            ? "59000"
                                            : category?.emd,
                                        category:
                                          location?.state?.cat ||
                                          category?.category,
                                      },
                                    });
                                  }}
                                >
                                   {Vendor_profileUpdation?.Pay?.[language]}
                                </Button>
                              </Col>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </Row>
            </>
          )}
        </Form>
      </div>
    </>
  );
}
