import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import {
  file_remove,
  it_turn_mark_caluction,
} from "../../../Library/Constants/helpers";
import moment from "moment";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;
export default function ITTurnover(props: any) {
  const {language,setLanguage}=useContext(Context);
  const itfirstRef = useRef<HTMLInputElement>(null);
  const itsecondREf = useRef<HTMLInputElement>(null);

  const preventNegativeValues = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/; // regex pattern to match only numeric input
    if (!regex.test(keyValue)) {
      e.preventDefault();
      ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  };
  const [turn_mark, setTurnMark] = useState({
    cat: "",
    mark: "",
  });
  useEffect(() => {
    let first_yr_amount = props?.values?.it_turn_over_first_amount
      ? props?.values?.it_turn_over_first_amount
      : 0;
    let second_yr_amount = props?.values?.it_turn_over_second_amount
      ? props?.values?.it_turn_over_second_amount
      : 0;

    let total = first_yr_amount + second_yr_amount;

    let mark_turn_over: any = it_turn_mark_caluction(total);

    setTurnMark({
      ...turn_mark,
      cat: mark_turn_over?.[0],
      mark: mark_turn_over?.[1],
    });
    props?.setFieldValue("mark_it_turn", total == 0 ? 0 : mark_turn_over?.[1]);
  }, [
    props?.values?.it_turn_over_second_amount,
    props?.values?.it_turn_over_first_amount,
  ]);

  return (
    <>
      {" "}
      <div className="company-information-update-wrap">
        <h4>{Vendor_profileUpdation?.IT_Turnover?.[language]}</h4>
        <hr />
        {/* first */}
        <Row>
          {" "}
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.First_IT_Year?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                {props?.list <= 0 ? (
                  <DatePicker
                    value={props?.values?.it_turn_over_first_year}
                    onChange={(e: any) => {
                      props?.setFieldValue("it_turn_over_first_year", e);
                      props?.setFieldValue("it_turn_over_second_year", "");
                    }}
                    name="it_turn_over_first_year"
                    format="dd-MM-y"
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    className={` ${
                      props?.touched.it_turn_over_first_year &&
                      props?.errors.it_turn_over_first_year
                        ? "is-invalid"
                        : ""
                    }`}
                    maxDate={new Date()}
                    disabled={props?.list > 0 ? true : false}
                  />
                ) : (
                  <Input
                    type="text"
                    name="Ffinancial_year"
                    value={`(Apr-01-${
                      props?.datas?.it_turn_over_first_year
                    }) - (Mar-31-${
                      Number(props?.datas?.it_turn_over_first_year) + 1
                    }) `}
                    disabled={props?.list > 0 ? true : false}
                  ></Input>
                )}

                <ErrorMessage
                  name="it_turn_over_first_year"
                  component={TextError}
                />
              </div>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label>
              {Vendor_profileUpdation?.First_IT_Year_document?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.datas?.it_turn_over_first_document ? (
                  <InputGroup>
                    <Input
                      id="it_turn_over_first_document"
                      name="it_turn_over_first_document"
                      className="form-control"
                      accept=".pdf"
                      value={" First IT Year Documents.pdf"}
                      disabled={
                        props?.datas?.it_turn_over_first_document ? true : false
                      }
                    ></Input>
                    <InputGroupText
                      className="input-group-text bg-white border-start-0"
                      id="newpass"
                    >
                      <a
                        href={"#"}
                        onClick={() => {
                          props?.documentdownload(
                            props?.datas?.it_turn_over_first_document
                          );
                        }}
                      >
                        {" "}
                        <i className="bi bi-box-arrow-down"></i>
                      </a>
                    </InputGroupText>
                  </InputGroup>
                ) : (
                  <>
                    <Input
                      innerRef={itfirstRef}
                      type="file"
                      id="it_turn_over_first_document"
                      name="it_turn_over_first_document"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={
                        !props?.values?.it_turn_over_first_year
                          ? true
                          : props?.list > 0
                          ? true
                          : false
                      }
                    ></Input>
                    <Label
                      className="image-button"
                      for="it_turn_over_first_document"
                      id="it_turn_over_first_document"
                    >
                      {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    {props?.values?.it_turn_over_first_document && (
                      <>
                        <span
                          className="upload-text"
                          style={{
                            marginRight: "5px",
                            zIndex: "45",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            file_remove(
                              "it_turn_over_first_document",
                              itfirstRef,
                              "it_turn_over_first_document"
                            );
                            props?.setFieldValue(
                              "it_turn_over_first_document",
                              ""
                            );
                          }}
                        >
                          &#x2715;
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              <ErrorMessage
                name="it_turn_over_first_document"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <div className="custom-input">
              <Label>
              {Vendor_profileUpdation?.First_IT_Year_Amount?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              {props?.list <= 0 ? (
                <Input
                  type="number"
                  name="it_turn_over_first_amount"
                  onChange={props?.handleChange}
                  value={props?.values?.it_turn_over_first_amount}
                  min="1"
                  placeholder="Amount"
                  disabled={
                    !props?.values?.it_turn_over_first_year
                      ? true
                      : props?.list > 0
                      ? true
                      : false
                  }
                ></Input>
              ) : (
                <Input
                  type="number"
                  name="it_turn_over_first_amount"
                  onChange={props?.handleChange}
                  value={props?.values?.it_turn_over_first_amount}
                  min="1"
                  placeholder="Amount"
                  disabled
                ></Input>
              )}

              <ErrorMessage
                name="it_turn_over_first_amount"
                component={TextError}
              />
            </div>
          </Col>
        </Row>
        {/* second */}
        <Row>
          <Col md={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.Second_IT_Year?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                <br />
                {props?.list <= 0 ? (
                  <DatePicker
                    value={props?.values?.it_turn_over_second_year}
                    onChange={(e: any) => {
                      props?.setFieldValue("it_turn_over_second_year", e);
                      // props?.setFieldValue("Tfinancial_year", "");
                    }}
                    name="it_turn_over_second_year"
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    className={`form-control ${
                      props?.touched.it_turn_over_second_year &&
                      props?.errors.it_turn_over_second_year
                        ? "is-invalid"
                        : ""
                    }`}
                    maxDate={
                      props?.list <= 0
                        ? props?.values?.it_turn_over_first_year
                          ? new Date(
                              `${props?.values?.it_turn_over_first_year?.getFullYear()}`?.toString()
                            )
                          : new Date()
                        : new Date()
                    }
                  />
                ) : (
                  <Input
                    type="text"
                    name="Ffinancial_year"
                    value={`(Apr-01-${
                      props?.datas?.it_turn_over_first_year
                    }) - (Mar-31-${
                      Number(props?.datas?.it_turn_over_first_year) + 1
                    }) `}
                    disabled={props?.list > 0 ? true : false}
                  ></Input>
                )}
                <ErrorMessage
                  name="it_turn_over_second_year"
                  component={TextError}
                />
              </div>
            </FormGroup>
          </Col>

          <Col sm={4}>
            <FormGroup>
              <Label>
              {Vendor_profileUpdation?.Second_IT_Year_Documents?.[language]}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.datas?.it_turn_over_second_document ? (
                  <InputGroup>
                    <Input
                      id="it_turn_over_second_document"
                      name="it_turn_over_second_document"
                      className="form-control"
                      accept=".pdf"
                      value={" Second IT Year Documents.pdf"}
                      disabled={
                        props?.datas?.it_turn_over_second_document
                          ? true
                          : false
                      }
                    ></Input>
                    <InputGroupText
                      className="input-group-text bg-white border-start-0"
                      id="newpass"
                    >
                      <a
                        href={"#"}
                        onClick={() => {
                          props?.documentdownload(
                            props?.datas?.it_turn_over_second_document
                          );
                        }}
                      >
                        {" "}
                        <i className="bi bi-box-arrow-down"></i>
                      </a>
                    </InputGroupText>
                  </InputGroup>
                ) : (
                  <>
                    {" "}
                    <Input
                      innerRef={itsecondREf}
                      type="file"
                      id="it_turn_over_second_document"
                      name="it_turn_over_second_document"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={
                        !props?.values?.it_turn_over_second_year
                          ? true
                          : props?.list > 0
                          ? true
                          : false
                      }
                    ></Input>
                    <Label
                      className="image-button"
                      for="it_turn_over_second_document"
                      id="it_turn_over_second_document"
                    >
                      {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                    {props?.values?.it_turn_over_second_document && (
                      <>
                        <span
                          className="upload-text"
                          style={{
                            marginRight: "5px",
                            zIndex: "45",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            file_remove(
                              "it_turn_over_second_document",
                              itsecondREf,
                              "it_turn_over_second_document"
                            );
                            props?.setFieldValue(
                              "it_turn_over_second_document",
                              ""
                            );
                          }}
                        >
                          &#x2715;
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              <ErrorMessage
                name="it_turn_over_second_document"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            {" "}
            <FormGroup>
              <div className="custom-input">
                <Label>
                {Vendor_profileUpdation?.Second_IT_Year_Amount?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    type="number"
                    name="it_turn_over_second_amount"
                    className="form-control"
                    min="1"
                    onChange={props?.handleChange}
                    placeholder="Amount"
                    value={props?.values?.it_turn_over_second_amount}
                    disabled={
                      !props?.values?.it_turn_over_second_year
                        ? true
                        : props?.list > 0
                        ? true
                        : false
                    }
                  ></Input>
                ) : (
                  <Input
                    type="number"
                    name="it_turn_over_second_amount"
                    className="form-control"
                    min="1"
                    onChange={props?.handleChange}
                    placeholder="Amount"
                    value={props?.values?.it_turn_over_second_amount}
                    disabled
                  ></Input>
                )}

                <ErrorMessage
                  name="it_turn_over_second_amount"
                  component={TextError}
                />
              </div>{" "}
            </FormGroup>
          </Col>
        </Row>

        <div>{Vendor_profileUpdation?.Approved_by_Chartered_Accountant_CA?.[language]}</div>
        <Row>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.CA_Name?.[language]}{" "}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="it_turn_over_ca_name"
                    type="text"
                    value={props?.values?.it_turn_over_ca_name}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="it_turn_over_ca_name"
                    type="text"
                    value={props?.values?.it_turn_over_ca_name}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="it_turn_over_ca_name" component={TextError} />
            </FormGroup>
          </Col>

          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.CA_Email?.[language]}{" "}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="it_turn_over_ca_email"
                    type="email"
                    value={props?.values?.it_turn_over_ca_email}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="it_turn_over_ca_email"
                    type="email"
                    value={props?.values?.it_turn_over_ca_email}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="it_turn_over_ca_email"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.CA_Phone_Number?.[language]}{" "}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="it_turn_over_ca_phone"
                    type="tel"
                    onKeyPress={preventNegativeValues}
                    value={props?.values?.it_turn_over_ca_phone}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="it_turn_over_ca_phone"
                    type="tel"
                    onKeyPress={preventNegativeValues}
                    value={props?.values?.it_turn_over_ca_phone}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="it_turn_over_ca_phone"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={12}>
            {props?.values?.it_turn_over_first_amount &&
            props?.values?.it_turn_over_second_amount &&
            props?.values?.it_turn_over_first_document &&
            props?.values?.it_turn_over_second_document ? (
              <>
                <span className="text-success mark-end">
                {Vendor_profileUpdation?.Category?.[language]}:{turn_mark?.cat}
                </span>
                <span className="text-success mark-end">
                {Vendor_profileUpdation?.Mark?.[language]}:{turn_mark?.mark}
                </span>
              </>
            ) : null}
          </Col>
        </Row>
      </div>
    </>
  );
}
