import React,{useContext} from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { List } from 'reactstrap'
import { Admin_Settings } from '../../../Library/LanguageConversion/Admin_Setting'
import { Context } from '../../../Components/Context/Context'
function Settings(props:any) {
  const { pathname } = useLocation()
  const {language,setLanguage} = useContext(Context)
  return (
    <React.Fragment>
    <div className="right-content-wrap">
      <div className="breadcrumb-tabs-outer-wrap">
        {/* breadcrumb */}
        <List className="breadcrumb">
          <li>
            <NavLink className='active'  to="/dashboard">
            {Admin_Settings?.Dashboard[language]}
            </NavLink>
          </li>
          &nbsp; / &nbsp;
          <li>
            <b>
              {
                pathname == "/profile-settings" ? Admin_Settings?.Profile_Settings[language] :null
              }
            </b>
          </li>
        </List>
      </div>
   
          {props.children}
       
    </div>
  </React.Fragment>
  )
}

export default Settings