const version = [
  { value: 1, name: "v1" },
  { value: 2, name: "v2" },
  { value: 3, name: "v3" },
  { value: 4, name: "v4" },
  { value: 5, name: "v5" },
];

const vendor_types = [
  { value: 1, name: "Manufacturer" },
  { value: 2, name: "Distributor" },
  { value: 3, name: "Importer" },
  { value: 4, name: "Marketer" },
  { value: 5, name: "Propritership" },
  { value: 6, name: "Partnership" },
  { value: 7, name: "Pvt Ltd" },
  { value: 8, name: "LLP" },
  { value: 9, name: "OEMS" },
  { value: 10, name: "Startup" },
  { value: 11, name: "QA Vendor" }, // do not delete
];
const vendor_type = [
  { value: 1, name: "Manufacturer" },
  { value: 2, name: "Distributor" },
  { value: 3, name: "Importer" },
  { value: 4, name: "Marketer" },
  { value: 5, name: "Propritership" },
  { value: 6, name: "Partnership" },
  { value: 7, name: "Pvt Ltd" },
  { value: 8, name: "LLP" },
  { value: 9, name: "OEMS" },
  { value: 10, name: "Startup" },
];
const vendor_type_start = [{ value: 10, name: "Startup" }];

const vendor_type_start_ = [{ value: 22, name: "Startup" }];

const gst_class = [
  { value: 1, name: "Registered" },
  // { value: 2, name: " Not Registered" },
  { value: 3, name: "Compounding Scheme" },
  { value: 4, name: "Marketer" },
  { value: 5, name: "PSU/Government Organization" },
];
const company_mses = [
  { value: 1, name: "Yes" },
  { value: 2, name: "No" },
];
const sc_st_entrepreneur = [
  { value: 1, name: "Yes" },
  { value: 2, name: "No" },
];
const woman_entrepreneur = [
  { value: 1, name: "Yes" },
  { value: 2, name: "No" },
];
const departments = [
  { value: 1, name: "KSEB" },
  { value: 2, name: "College" },
  { value: 3, name: "Hospitals" },
];
const ticket_type = [
  { value: 1, name: "Services" },
  { value: 2, name: "Maintanance" },
  { value: 3, name: "Enquiry" },
  { value: 4, name: "Tickets" },
];

const order_status = [
  { value: 3, name: "Accept" },
  { value: 10, name: "Reject" },

  //{ value: 2, name: "Processing" },
  // { value: 9, name: "Out for Delivery" },
  // { value: 6, name: "Delivered" },
  //{ value: 10, name: "Rejected" },
];

const order_status_reject = [
  // { value: 3, name: "Accept" },
  { value: 10, name: "Reject" },
];

const order_status_accept = [
  { value: 3, name: "Accept" },
  { value: 10, name: "Reject" },
];

const order_status_one = [
  // { value: 2, name: "Processing" },
  { value: 9, name: "Out for Delivery" },
  //{ value: 6, name: "Delivered" },
  //{ value: 10, name: "Rejected" },
];
const order_status_two = [
  { value: 9, name: "Out for Delivery" },
  { value: 6, name: "Delivered" },
  // { value: 10, name: "Rejected" },
];
const order_status_three = [
  { value: 6, name: "Delivery" },
  { value: 15, name: "Delivered and Installed" },
  //{ value: 10, name: "Rejected" },
];
const ticket_status = [
  { value: 2, name: "Pending" },
  { value: 3, name: "Closed" },
];
const ticket_priority = [
  { value: 1, name: "Low" },
  { value: 2, name: "Medium" },
  { value: 3, name: "High" },
];

const company_type_list = [
  {
    value: 1,
    name: "Private company limited by guarantee",
  },
  {
    value: 2,
    name: "Private company limited by shares",
  },
  {
    value: 3,
    name: "Limited liability company",
  },
  {
    value: 4,
    name: "Private limited company",
  },
  {
    value: 5,
    name: "Limited company",
  },
  {
    value: 6,
    name: "Public company",
  },
  {
    value: 7,
    name: "Public limited company",
  },
  {
    value: 8,
    name: "Limited Liability Partnership",
  },
  {
    value: 9,
    name: "Business",
  },
  {
    value: 10,
    name: "Holding company",
  },
  {
    value: 11,
    name: "Unlimited company",
  },
  {
    value: 12,
    name: "Foreign corporation",
  },
  {
    value: 13,
    name: "Statutory corporation",
  },
  {
    value: 14,
    name: "Cooperative",
  },
  {
    value: 15,
    name: "Joint venture",
  },
  {
    value: 16,
    name: "Hindu joint family",
  },
  {
    value: 17,
    name: "Family business",
  },
  {
    value: 18,
    name: "Sole proprietorship",
  },
  {
    value: 19,
    name: "Partnership",
  },
  {
    value: 20,
    name: "Traders",
  },
  {
    value: 21,
    name: "Suppliers",
  },
  {
    value: 22,
    name: "Startup",
  },
];

const PAYMENT_MODES = [
  {
    value: 1,
    key: "FP",
    name: "Full Payment",
  },
  {
    value: 2,
    key: "PP",
    name: "Partial Payment",
  },
  {
    value: 3,
    key: "PL",
    name: "Pay Later",
  },
];
const PAYMENT_TYPES = [
  {
    value: 1,
    name: "Bank Transfer",
  },
  {
    value: 2,
    name: "Cheque",
  },
  {
    value: 3,
    name: "Demand Draft",
  },
  {
    value: 4,
    name: "Net Banking",
  },
  {
    value: 5,
    name: "NEFT",
  },
  {
    value: 6,
    name: "RTGS",
  },
];

const ADDRESS_TYPE = [
  {
    VALUE: 1,
    TYPE: "Billing Address",
  },
  {
    VALUE: 2,
    TYPE: "Registered Address",
  },
];

const ACCOUNT_TYPE = [
  {
    name: "Store",
    value: 6,
  },
  {
    name: "Manager",
    value: 7,
  },
  {
    name: "Accountant",
    value: 8,
  },
];

const PMU_BANK_DETAILS = [
  {
    NAME_OF_ACCOUNT: "KEONICS",
    NAME_OF_BANK: "Bank Of Baroda",
    NAME_OF_BRANCH: "Shanti Nagar",
    ACC_NO: 73960200000967,
    IFSC: "BARB0VJSHAN",
    MICR_CODE: 560012150,
    RECIPIENT_CODE: 2903267152,
    GST_NO: "29AABCK6661P1ZT",
    KHAJANE_2_RECIPIENT_ID: 2903174263,
  },
  {
    NAME_OF_ACCOUNT: "KEONICS",
    NAME_OF_BANK: "State Bank Of India",
    NAME_OF_BRANCH: "Race Course Branch",
    ACC_NO: 31605441440,
    IFSC: "SBIN0006198",
    MICR_CODE: 560002038,
    RECIPIENT_CODE: 2903267152,
    GST_NO: "29AABCK6661P1ZT",
    KHAJANE_2_RECIPIENT_ID: 2903174263,
  },
];

export {
  version,
  ACCOUNT_TYPE,
  vendor_types,
  gst_class,
  company_mses,
  sc_st_entrepreneur,
  woman_entrepreneur,
  departments,
  ticket_type,
  order_status,
  ticket_status,
  ticket_priority,
  order_status_one,
  order_status_two,
  order_status_three,
  company_type_list,
  PAYMENT_MODES,
  PAYMENT_TYPES,
  ADDRESS_TYPE,
  PMU_BANK_DETAILS,
  order_status_accept,
  order_status_reject,
  vendor_type,
};
