import { useState} from 'react';
import Header from '../Components/DeptHeader';
import DeptSidebar from '../Components/DeptSidebar';
import { Col } from 'reactstrap';


const DepartmentLayout = ({ children }: any) => {
    const [openSidebar, setOpenSidebar] = useState(false); // mobile view
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true); // web view

    

    return (
        <>
            {/* department sidebar  */}
            <DeptSidebar
                setOpenSidebar={setOpenSidebar}
                openSidebar={openSidebar}
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
            />
            
            {/* right side section which includes body and header  */}
            <Col md={sidebarIsOpen ? '10' : '12'} className={sidebarIsOpen ? 'offset-md-2 minimise-content' : 'expand-content'}>
                <Header setOpenSidebar={setOpenSidebar} openSidebar={openSidebar} />
                <div className="dashboard-outer" id="content">
                    <main>{children}</main>
                </div>
            </Col>
        </>
    )
}
export default DepartmentLayout