import React, { useState, useContext } from 'react'
import StoreSideBar from './Components/StoreSideBar';
import StoreHeader from './Components/StoreHeader';
import { Col } from 'reactstrap';
import { Context } from "../../../Components/Context/Context";


const StoreLayout = ({ children }: any) => {

    const [openSidebar, setOpenSidebar] = useState(false); // mobile view
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true); // web view

    const { language, setLanguage } = useContext(Context)

    return (
        <>
            {/*  sidebar  */}
            <StoreSideBar
                setOpenSidebar={setOpenSidebar}
                openSidebar={openSidebar}
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
            />

            {/* right side section which includes body and header  */}
            <Col
                md={sidebarIsOpen ? '10' : '12'}
                className={sidebarIsOpen ?
                    'offset-md-2 minimise-content'
                    : 'expand-content'}
            >
                {/* <Header setOpenSidebar={setOpenSidebar} openSidebar={openSidebar} /> */}
                <StoreHeader
                    setOpenSidebar={setOpenSidebar}
                    openSidebar={openSidebar}
                />

                <div
                    className="dashboard-outer"
                    id="content"
                >
                    <main>{children}</main>

                <div className="sidebar-language-btn-group">
                  <div className="lang-tab-inner">
                  <span className="en-tab" onClick={()=>setLanguage('en')}>English</span>
                    <div className="divider"></div>
                    <span className="ka-tab" onClick={() => setLanguage('ka')}>ಕನ್ನಡ</span>
                  </div>
                </div>
                </div>
            </Col>
        </>
    )
}

export default StoreLayout