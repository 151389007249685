import { useState, useMemo, useEffect, useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import DatePicker from 'react-date-picker'

import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  FormGroup,
  Row,
  Col,
  List
} from "reactstrap";
import { ReportsList } from "../../../Library/Constants/Reports";
import { api } from "../../../Library/CustomApi";
import { API_URL } from "../../../Library/ApiSettings";
import axios from "axios";
import getExportData from "../../../Components/CsvExport/CsvExport";
import ReportSection from "./ReportSection";
import { Context } from "../../../Components/Context/Context";
import { Admin_Reports } from "../../../Library/LanguageConversion/Admin_Reports"


const Reports = () => {
  const { language, setLanguage } = useContext(Context);

  return (
    <>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">

          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
                {Admin_Reports?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li><b> {Admin_Reports?.Reports[language]}</b></li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="reports-full-wrap">
            <div className="inner-report-wrap">

              {
                ReportsList?.map((it: any, i: number) => {
                  const { en, ka, URL, HIDE_DATE } = it
                  return (
                    <ReportSection
                      NAME={language == 'en' ? en : ka}
                      URL={URL}
                      HideButton={HIDE_DATE}
                      key={i}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reports
