import React, { useState, useMemo, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import DatePicker from 'react-date-picker'
import CustomPagination from "../../../Components/CustomPagination/Index";
import {
  List,
  Input,
  InputGroup,
  InputGroupText,
  Form,
  Table
} from "reactstrap";
import { api } from "../../../Library/CustomApi";
import Loader from "../../../Components/Loader/Loader";
import { Context } from "../../../Components/Context/Context";
import { Admin_Departlist } from "../../../Library/LanguageConversion/Admin_Departlist";
import { useContext } from "react";


const DepartmentsList = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [searchVal, setSearchVal] = useState<string>('')
  const [pageData, setPageData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number | string>(1)
  const [departmentData, setDepartmentData] = useState<any>([])

  useEffect(() => {
    getDepartmentsList()
  }, [searchVal, currentPage])

  const getDepartmentsList = () => {
    setLoading(true);
    api
      .get(`/departments?status=1&page=${currentPage}&search_value=${searchVal}`, true)
      .then(async function ([success, response]) {
        setDepartmentData(response?.data)
        setPageData(response?.meta)
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const{ language,setLanguage } = useContext(Context);
  return (
    <div className="right-content-wrap">
      <div className="custom-tab-content">
        <div className="table-outer-wrap">
          {/* Search */}
          <div className="search-widget-outer">
            <Form role="search">
              <InputGroup className="search-group">
                <InputGroupText title="Department/Name" className="search-icon" id="search">
                  <i className="fa-sharp fa-solid fa-magnifying-glass" />
                </InputGroupText>
                <Input
                  type="text"
                  className="form-control"
                  placeholder={Admin_Departlist?.Department_Name?.[language]}
                  aria-label="Username"
                  aria-describedby="search"
                />
              </InputGroup>
            </Form>
          </div>
          {/* Table list */}
          <div className="table-wrap responsive-table">
            <Table className="w-100 table-striped">
              <thead>
                <tr>
                  <th>{Admin_Departlist?.SlNo[language]}</th>
                  <th>{Admin_Departlist?.Name[language]}</th>
                  <th>{Admin_Departlist?.Department[language]}</th>
                  <th>{Admin_Departlist?.EmailID[language]}</th>
                  <th>{Admin_Departlist?.PhoneNumber[language]}</th>
                </tr>
              </thead>
              <tbody>
                {(departmentData?.length !== 0 && !loading) ?
                  departmentData?.map((it: any, i: number) => {                    
                    return (
                      <tr key={i}>
                        <td>
                          <span className="mobile-heading">{Admin_Departlist?.SlNo[language]}:</span>
                          <span className="table-data">
                            {pageData?.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Departlist?.Name[language]}:</span>
                          <span className="table-data">
                            {it?.name}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Departlist?.Department[language]}:</span>
                          <span className="table-data">
                            {it?.user_details?.department_type?.name || '-'}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Departlist?.EmailID[language]}:</span>
                          <span className="table-data">
                            {it?.user_details?.email}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Departlist?.PhoneNumber[language]}:</span>
                          <span className="table-data">
                            {it?.user_details?.phone}
                          </span>
                        </td>
                      </tr>
                    )
                  })
                  :
                  loading ?
                    <tr>
                      <td colSpan={5} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                    :
                    <tr>
                      <td colSpan={5} className="text-center">
                      {Admin_Departlist?.NoDepartmentAccountsfound[language]}
                      </td>
                    </tr>
                }

              </tbody>
            </Table>
          </div>

          {pageData && departmentData.length > 0 && <div className="table-footer-wrap">
            <small> {Admin_Departlist?.Showing[language]} {pageData.from}  {Admin_Departlist?.to[language]}{pageData.to} {Admin_Departlist?.of[language]} {pageData?.total}  {Admin_Departlist?.entries[language]}</small>
            <div className="pagination-outer">
              <CustomPagination
                totalItems={pageData?.total}
                itemsPerPage={pageData?.per_page}
                activePage={pageData?.current_page}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default DepartmentsList
