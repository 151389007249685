import React, { useContext } from 'react'
import { Button, Label, Table } from 'reactstrap'
import { PAYMENT_TYPES } from '../../../../../Library/Constants/MapConstants';
import constants from '../../../../../Library/Constants/Constants';
import { Context } from '../../../../../Components/Context/Context';
import { Admin_Verifications } from '../../../../../Library/LanguageConversion/Admin_Verifications';

interface propType {
    viewData: any,
    VerifyDept: Function,
}
const DraftDetailsModal = ({ viewData, VerifyDept }: propType) => {
    const { language } = useContext(Context);
    return (
        <>
            <div className="feeback-outer-wrap">
                <div className="feedback-wrap">
                    <div className="feedback-details">
                        <div className="table-wrap responsive-table">
                            <Table className="w-100 table-hover table-bordered">
                                <tbody>
                                    {
                                        viewData?.depart_account?.length !== 0 ?
                                            (viewData?.depart_account?.map((itm: any, i: number) => {

                                                return (
                                                    <React.Fragment key={i}>
                                                        {itm?.account_holder_name && <tr>
                                                            <td>{Admin_Verifications?.AccountHolderName[language]}</td>
                                                            <td>{itm?.account_holder_name}</td>
                                                        </tr>}
                                                        {itm?.amount && <tr>
                                                            <td>{Admin_Verifications?.Amount[language]}</td>
                                                            <td><i className="bi bi-currency-rupee"></i>
                                                                {viewData?.pf_invoice[0].last_paid_amount}
                                                            </td>
                                                        </tr>}
                                                        {itm?.account_number && <tr>
                                                            <td>{Admin_Verifications?.AccountNumber[language]}</td>
                                                            <td>{itm?.account_number}</td>
                                                        </tr>}
                                                        {itm?.bank_name && <tr>
                                                            <td>{Admin_Verifications?.BankName[language]}</td>
                                                            <td>{itm?.bank_name}</td>
                                                        </tr>}
                                                        {itm?.branch_name && <tr>
                                                            <td>{Admin_Verifications?.Branch[language]}</td>
                                                            <td>{itm?.branch_name}</td>
                                                        </tr>}
                                                        {itm?.ifsc_code && <tr>
                                                            <td>{Admin_Verifications?.IFSC[language]}</td>
                                                            <td>{itm?.ifsc_code}</td>
                                                        </tr>}
                                                        {itm?.cheque_date && <tr>
                                                            <td>{Admin_Verifications?.ChequeDate[language]}</td>
                                                            <td>{itm?.cheque_date}</td>
                                                        </tr>}
                                                        {itm?.cheque_number && <tr>
                                                            <td>{Admin_Verifications?.ChequeNumber[language]}</td>
                                                            <td>{itm?.cheque_number}</td>
                                                        </tr>}
                                                        {itm?.payment_type && <tr>
                                                            <td>{Admin_Verifications?.PaymentType[language]}</td>
                                                            <td>
                                                                {PAYMENT_TYPES?.find((it: any) => it?.value == itm?.payment_type)?.name}
                                                            </td>
                                                        </tr>}
                                                        {itm?.receipt_number && <tr>
                                                            <td>{Admin_Verifications?.ReceiptNumber[language]}</td>
                                                            <td>{itm?.receipt_number}</td>
                                                        </tr>}
                                                        {itm?.transaction_id && <tr>
                                                            <td>{Admin_Verifications?.TransactionID[language]}</td>
                                                            <td>{itm?.transaction_id}</td>
                                                        </tr>}
                                                        {viewData?.document?.[0]?.url && <tr>
                                                            <td>{Admin_Verifications?.Uploadeddoc[language]}</td>
                                                            <td>{ }
                                                                <a
                                                                    href={viewData?.document?.[0]?.url}
                                                                    target="_blank"
                                                                    className='blue-text'
                                                                >
                                                                    <i className="bi bi-file-text"></i>{"   "}
                                                                    {Admin_Verifications?.View[language]}
                                                                </a>
                                                            </td>
                                                        </tr>}
                                                    </React.Fragment>
                                                )
                                            }))
                                            :
                                            <>
                                                <tr>
                                                    <td colSpan={2} className='text-center'>{Admin_Verifications?.NoDetailsFound[language]}</td>
                                                </tr>
                                            </>
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className='btn-end text-end'>
                            {viewData?.status ==
                                constants.DRAFTVERIFICATION.PENDING ?
                                <Button
                                    color='warning'
                                    onClick={() => VerifyDept(viewData?.id)}>{Admin_Verifications?.Approve[language]}</Button>
                                :
                                <Button disabled color='success'>{Admin_Verifications?.Approved[language]}</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DraftDetailsModal