import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const AddAccountValidationSchema = Yup.object().shape({

    name: Yup.string().required('Name is Required'),
    
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
        .min(10, 'Phone number must be atleast 10 numbers')
        .max(10, 'Phone number must be 10 numbers')
        .required('Phone number is required'),
    
    email: Yup.string().required('Email is Required')
        .email('Invalid Email'),

    role_id: Yup.string().required('Account Type is Required'),

});