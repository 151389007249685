import React, { useEffect, useState, useContext } from "react";
import { Table, Input, InputGroup, InputGroupText, List } from "reactstrap";
import ReDocUpload from "./ReuploadModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import {
  RejectUploadValidationSchema,
  TurnOverValidationSchema,
} from "../profileUpdation/UploadValidation";
import { api } from "../../../Library/CustomApi";
import constants from "../../../Library/Constants/Constants";
import {
  DocumentTYpe,
  DocumentTYpeUpload,
  getOrderStyle,
  getUnique,
  mergeCommonValues,
} from "../../../Library/Constants/helpers";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../../Library/ApiSettings";
import WorkOrderReuploadModal from "./ReuploadModal/WorkOrderReupload";
import AnyOthercomapnyDocumentsReuploadModal from "./ReuploadModal/AnyOtherOfficeReupload";
import LandDocumentReuploadModal from "./ReuploadModal/LandReuplaod";
import ManpowerDocumentModal from "./ReuploadModal/ManpowerReuplaod";
import ITTurnoverReuplaodModal from "./ReuploadModal/ITTurnOverReuplaod";
import OEMReuplaodModal from "./ReuploadModal/OEMReuplaod";
import EmpanelReuplaodModal from "./ReuploadModal/EmpanelReuplaod";
import {
  AnyOtherValidation,
  CommonValidation,
  EmpanelValiadtion,
  FinancialValidationOne,
  FinancialValidationThree,
  FinancialValidationTwo,
  ITValidationOne,
  ITValidationTwo,
  LandValidation,
  ManpowerValidation,
  OEMValiadtion,
  WorkOrderValidation,
} from "./ReuploadModal/ReuplaodValidation";
import FinancialTurnoverModal from "./ReuploadModal/TurnOverReuploadModal";
import moment from "moment";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";
import Loader from "../../../Components/Loader/Loader";

export default function VendorDocuments() {
  const navigate = useNavigate();
  const [isShowing, setIsShowing] = useState(false);
  const [commenst, setComments] = useState([]);
  const [isShowing_man_power, setIsShowingManPower] = useState(false);
  const [isShowing_land, setIsShowingLand] = useState(false);
  const [isShowing_iturn, setIsShowingITurn] = useState(false);
  const [isShowing_work, setIsShowingWork] = useState(false);
  const [isShowing_any_other, setIsShowingAnyOther] = useState(false);
  const [isShowing_oem, setIsShowingOEM] = useState(false);
  const [isShowing_emapanel, setIsShowingEmapanel] = useState(false);
  const [isShowing_Fina, setIsShowingFina] = useState(false);
  const [document_cooment, setDocumentComment] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const { language, setLanguage } = useContext(Context);
  const [value, setValue] = useState<any>({
    pan: "",
    amount: "",
    year: "",
    Ffinancial_year: "",
  });
  const [type, setType] = useState<any>("");
  const [amount, setamount] = useState<any>("");
  const [year, setyear] = useState<any>("");
  const [years, setYears] = useState<any>("");
  const [documents, setDocumnets] = useState([]);
  const [finance, setFinance] = useState([]);
  const [finances, setFinances] = useState([]);
  const [optionalFields, setOptionalFields] = useState<string>("");

  const initialValues = {
    pan: "",
  };

  const initialValuesanyOther = {
    any_other_company_document: "",
    any_other_company_name: "",
    any_other_company_email: "",
    any_other_company_pincode: "",
    any_other_company_state: "",
    any_other_company_district: "",
    any_other_company_city: "",
  };
  const initialValuesanyEmapanel = {
    empanel_certificate: "",
    empanelment_date: "",
    expiry_date: "",
  };

  const initialValuesITTurnover = {
    it_turn_over_first_year: "",
    it_turn_over_first_document: "",
    it_turn_over_first_amount: "",
    it_turn_over_second_year: "",
    it_turn_over_second_amount: "",
    it_turn_over_ca_name: "",
    it_turn_over_ca_email: "",
    it_turn_over_ca_phone: "",
  };

  const initialValuesLand = {
    land_document: "",
    land_village_col: "",
    land_extent: "",
    land_value: "",
    land_survey_no: "",
  };

  const initialValuesManPower = {
    manpower_documents: "",
    manpower_date: "",
    manpower_number: "",
  };
  const initialValuesWorkOrder = {
    work_order_certificate: "",
    work_order_no_of_projects: "",
  };

  const initialValuesFina = {
    financial_turn_over_ca_email: "",
    financial_turn_over_ca_name: "",
    financial_turn_over_ca_phone: "",
    FFfinancial_year: "",
    SFfinancial_year: "",
    TFfinancial_year: "",
    Ffinancial_year_amount: "",
    Sfinancial_year_amount: "",
    Tfinancial_year_amount: "",
    Ffinancial_year: "",
    Tfinancial_year: "",
  };
  const initialValuesOEM = {
    oem_certificate: "",
    oem_in_company_type: "",
    oem_in_company_valid_from: "",
    oem_in_company_valid_to: "",
  };

  const handleSubmit = (values: any, actions: any) => {
    const formData = new FormData();
    if (type == 0) {
      formData.append("pan", values.pan);
    }
    if (type == 5) {
      formData.append("mse_certificate", values.pan);
    }
    if (type == 2) {
      formData.append("maf_certificate", values.pan);
    }
    if (type == 10) {
      formData.append("iso_certificate", values.pan);
    }
    if (type == 1) {
      formData.append("oem_certificate", values.oem_certificate);
      formData.append("oem_in_company_type", values?.oem_in_company_type);
      formData.append(
        "oem_valid_from",
        moment(values?.oem_in_company_valid_from).format("YYYY-MM-DD")
      );
      formData.append(
        "oem_valid_to",
        moment(values?.oem_in_company_valid_to).format("YYYY-MM-DD")
      );
    }
    if (type == 3) {
      formData.append("address_proof", values.pan);
    }
    if (type == 4) {
      formData.append("incorporation_certificate", values.pan);
    }
    if (type == 6) {
      formData.append("gst_certificate", values.pan);
    }
    if (type == 7) {
      formData.append("f_financial_year_doc", values?.FFfinancial_year);

      formData.append(
        "financial_turn_over_ca_email",
        values?.financial_turn_over_ca_email
      );
      formData.append(
        "financial_turn_over_ca_name",
        values?.financial_turn_over_ca_name
      );
      formData.append(
        "financial_turn_over_ca_phone",
        values?.financial_turn_over_ca_phone
      );

      formData.append(
        "f_financial_year",
        moment(values?.Ffinancial_year).format("YYYY-MM-DD")
      );

      formData.append(
        "f_financial_year_amount",
        values?.Ffinancial_year_amount
      );
    }
    if (type == 8) {
      formData.append("s_financial_year_doc", values?.SFfinancial_year);

      formData.append(
        "financial_turn_over_ca_email",
        values?.financial_turn_over_ca_email
      );
      formData.append(
        "financial_turn_over_ca_name",
        values?.financial_turn_over_ca_name
      );
      formData.append(
        "financial_turn_over_ca_phone",
        values?.financial_turn_over_ca_phone
      );

      formData.append(
        "s_financial_year",
        moment(values?.Sfinancial_year).format("YYYY-MM-DD")
      );
      formData.append(
        "s_financial_year_amount",
        values?.Sfinancial_year_amount
      );
    }
    if (type == 9) {
      formData.append("t_financial_year_doc", values?.TFfinancial_year);
      formData.append(
        "financial_turn_over_ca_email",
        values?.financial_turn_over_ca_email
      );
      formData.append(
        "financial_turn_over_ca_name",
        values?.financial_turn_over_ca_name
      );
      formData.append(
        "financial_turn_over_ca_phone",
        values?.financial_turn_over_ca_phone
      );

      formData.append(
        "t_financial_year",
        moment(values?.Tfinancial_year).format("YYYY-MM-DD")
      );

      formData.append(
        "t_financial_year_amount",
        values?.Tfinancial_year_amount
      );
    }
    if (type == 26) {
      formData.append("empanel_certificate", values?.empanel_certificate);
      formData.append(
        "empanelment_date",
        moment(values?.empanelment_date).format("YYYY-MM-DD")
      );
      formData.append(
        "empanelment_expiry_date",
        moment(values?.expiry_date).format("YYYY-MM-DD")
      );
    }
    if (type == 28) {
      formData.append("udyam", values?.pan);
    }

    if (type == 29) {
      formData.append("work_order_certificate", values?.work_order_certificate);
      formData.append(
        "work_order_no_of_projects",
        values?.work_order_no_of_projects
      );
    }
    if (type == 30) {
      formData.append("manpower_number", values?.manpower_number);
      formData.append("manpower_document", values?.manpower_documents);
      formData.append(
        "manpower_date",
        moment(values?.manpower_date).format("YYYY-MM-DD")
      );
    }
    if (type == 31) {
      formData.append("land_extent", values?.land_extent);
      formData.append("land_village_col", values?.land_village_col);
      formData.append("land_value", values?.land_value);
      formData.append("land_survey_no", values?.land_survey_no);
      formData.append("land_document", values?.land_document);
    }
    if (type == 32) {
      formData.append(
        "any_other_company_document",
        values?.any_other_company_documents
      );
      formData.append(
        "any_other_company_pincode",
        values?.any_other_company_pincode
      );
      formData.append(
        "any_other_company_state",
        values?.any_other_company_state
      );
      formData.append(
        "any_other_company_district",
        values?.any_other_company_district
      );
      formData.append("any_other_company_city", values?.any_other_company_city);
      formData.append("any_other_company_name", values?.any_other_company_name);
      formData.append(
        "any_other_company_email",
        values?.any_other_company_email
      );
    }
    if (type == 33) {
      formData.append("it_turn_over_ca_email", values?.it_turn_over_ca_email);
      formData.append("it_turn_over_ca_name", values?.it_turn_over_ca_name);
      formData.append("it_turn_over_ca_phone", values?.it_turn_over_ca_phone);
      formData.append(
        "f_it_turn_over_document",
        values?.it_turn_over_first_document
      );

      formData.append(
        "f_it_turn_over_amount",
        values?.it_turn_over_first_amount
      );

      formData.append("f_t_turn_over_year", values?.it_turn_over_first_year);
    }
    if (type == 34) {
      formData.append("it_turn_over_ca_email", values?.it_turn_over_ca_email);
      formData.append("it_turn_over_ca_name", values?.it_turn_over_ca_name);
      formData.append("it_turn_over_ca_phone", values?.it_turn_over_ca_phone);
      formData.append(
        "s_it_turn_over_document",
        values?.it_turn_over_second_document
      );

      formData.append(
        "s_it_turn_over_amount",
        values?.it_turn_over_second_amount
      );

      formData.append("s_t_turn_over_year", values?.it_turn_over_second_year);
    }
    formData.append("type", type);
    formData.append("reupload", "1");

    formData.append("is_iso", `${optionalFields == "10" ? 1 : 0}`);
    formData.append("is_oem", `${optionalFields == "1" ? 1 : 0}`);
    formData.append("is_adhar", `${optionalFields == "2" ? 1 : 0}`);

    const body = formData;

    axios({
      method: "post",
      url: `${API_URL}/documents`,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response) {
        let st: any = response?.status;
        if (response?.status == 200) {
          toast.success("Document Uploaded Successfuly");
          getDocumnets();
          setIsShowing(false);
          setIsShowingManPower(false);
          setIsShowingEmapanel(false);
          setIsShowingWork(false);
          setIsShowingLand(false);
          setIsShowingAnyOther(false);
          setIsShowingITurn(false);
          setIsShowingFina(false);
          setIsShowingOEM(false);
          setOptionalFields("");
          actions.resetForm();
          actions.setSubmitting(false);
          return response;
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDocumnets();
  }, []);
  const getDocumnets = () => {
    setLoading(true);
    api
      .get(`/documents`, true)
      .then(async function ([success, response]) {
        let data = response?.data?.filter((item: any) => {
          return item?.type?.id !== 28;
        });
        setDocumnets(data || []);
        setFinance(response?.data?.finance || []);
        setFinances(response?.data?.finance || []);
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .get(`/comments`, true)
      .then(async function ([success, response]) {
        let data = response?.data?.filter((item: any) => {
          return item?.type !== 28;
        });
        setComments(data);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    documents.map((item: any) => {
      commenst.map((com: any) => {
        if (com.type === item.type.id) {
          item.comment = com.comment;
        }
      });
    });
  });

  //  console.log(commenst,"comments");
  // console.log(documents, "documents");
  // useEffect(() => {
  //   let comment_array: any = [];
  //   let document_array: any = [];
  //   [];
  //   commenst?.map((item: any) => {
  //     comment_array?.push({
  //       comment: item?.comment,
  //       type: item?.type,
  //     });
  //   });
  //   documents?.map((item: any) => {
  //     document_array?.push({
  //       document_id: item?.id,
  //       type: item?.type?.id,
  //       status: item?.status?.id,
  //     });
  //   });

  //   let mergedArray: any = mergeCommonValues(comment_array, document_array);

  //   mergedArray?.sort((a: any, b: any) => (a?.type > b?.type ? 1 : -1));
  //   setDocumentComment(mergedArray || []);
  // }, [commenst, documents]);
  const download_documents = (id: any, type: any) => {
    // documents/1602/download

    const requestOptions: any = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(`${API_URL}/documents/${id}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${DocumentTYpeUpload(type)}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };

  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumb */}
          <List className=" breadcrumb">
            <li></li>

            <li>
              <b>{pathname == "/vendor-documents-view" ? "Documents" : null}</b>
            </li>
          </List>
        </div>
        <div className="document-table-wrap">
          <div className="table-outer-wrap ">
            <div className="table-wrap responsive-table">
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th>{VendorRegLang?.sl_no[language]}</th>
                    <th>{VendorRegLang?.documents[language]}</th>
                    <th>{VendorRegLang?.view_document[language]}</th>
                    <th>{VendorRegLang?.status[language]}</th>
                    <th>{VendorRegLang?.comments[language]}</th>
                    <th>{VendorRegLang?.reupload[language]}</th>
                  </tr>
                </thead>
                {loading ? (
                  <div className="loading-overlay">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <tbody>
                      {documents?.length !== 0 ? (
                        documents?.map((item: any, i: any) => {
                          return (
                            <React.Fragment key={i}>
                              {/* {" "} */}
                              <tr>
                                <td>
                                  <div className="mobile-heading">
                                    {VendorRegLang?.sl_no[language]}
                                  </div>
                                  <div className="table-data">{i + 1}</div>
                                </td>
                                <td>
                                  <div className="input-wrap doc-wrap">
                                    <InputGroup
                                      title={`${DocumentTYpeUpload(
                                        item?.type?.id
                                      )}`}
                                    >
                                      <Input
                                        value={`${DocumentTYpeUpload(
                                          item?.type?.id
                                        )}`}
                                        disabled
                                      />
                                      <div className="checkbox-image-circle">
                                        <i className="bi bi-check-circle-fill"></i>
                                      </div>
                                    </InputGroup>
                                  </div>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {VendorRegLang?.view_document[language]}:
                                  </span>
                                  <div className="action-flex-btn">
                                    <Link
                                      to=""
                                      className="green-text"
                                      onClick={() => {
                                        download_documents(
                                          item?.id,
                                          item?.type?.id
                                        );
                                      }}
                                      title="Download & View"
                                    >
                                      {VendorRegLang?.download[language]}

                                      <i className="bi bi-download"></i>
                                    </Link>
                                  </div>
                                </td>
                                <td className="">
                                  <span className="mobile-heading">
                                    {VendorRegLang?.status[language]}:
                                  </span>
                                  {getOrderStyle(item?.status?.id)}
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    {VendorRegLang?.comments[language]}:
                                  </span>
                                  <span className="table-data comments">
                                    <i className="fa-solid fa-comment text-success"></i>{" "}
                                    {item?.comment == "" ||
                                    item?.comment == null
                                      ? "No comment"
                                      : item?.comment}
                                  </span>
                                </td>
                                <td>
                                  <span className="mobile-heading">
                                    {VendorRegLang?.reupload[language]}:
                                  </span>
                                  {item?.status?.id ==
                                  constants.DOCUMETSWAY?.REJECTED ? (
                                    <div
                                      className="one"
                                      onClick={() => {
                                        item?.type?.id ===
                                        constants.DOCUMENTS.HR_MANPOWER
                                          ? setIsShowingManPower(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.HR_LAND
                                          ? setIsShowingLand(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.HR_ANY_OFFICE
                                          ? setIsShowingAnyOther(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.WORKORDER
                                          ? setIsShowingWork(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.IT_TURN_OVER_ONE
                                          ? setIsShowingITurn(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.IT_TURN_OVER_TWO
                                          ? setIsShowingITurn(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.OEM
                                          ? setIsShowingOEM(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.EMPANEL
                                          ? setIsShowingEmapanel(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.TURN_OVER1
                                          ? setIsShowingFina(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.TURN_OVER2
                                          ? setIsShowingFina(true)
                                          : item?.type?.id ===
                                            constants.DOCUMENTS.TURN_OVER3
                                          ? setIsShowingFina(true)
                                          : setIsShowing(true);

                                        setType(item?.type?.id);
                                        setOptionalFields(item?.type);
                                        setYears(
                                          item?.type?.id == 7
                                            ? finances?.[0]
                                            : item?.type?.id == 8
                                            ? finances[1]
                                            : item?.type?.id == 9
                                            ? finances[2]
                                            : null
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {VendorRegLang?.reupload[language]}
                                    </div>
                                  ) : (
                                    <span>--</span>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={6}
                            className="empty-text text-center py-5"
                          >
                            {VendorRegLang?.no_documents_found[language]}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </>
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* common Reupalod */}
      <Formik
        initialValues={
          type == 7
            ? initialValuesFina
            : type == 8
            ? initialValuesFina
            : type == 9
            ? initialValuesFina
            : initialValues
        }
        validationSchema={
          type == 7
            ? FinancialValidationOne
            : type == 8
            ? FinancialValidationTwo
            : type == 9
            ? FinancialValidationThree
            : CommonValidation
        }
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          {
            // console.log(errors, "123");
          }
          return (
            <ReDocUpload
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>

      <Formik
        initialValues={initialValuesFina}
        validationSchema={
          type == 7
            ? FinancialValidationOne
            : type == 8
            ? FinancialValidationTwo
            : FinancialValidationThree
        }
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <FinancialTurnoverModal
              isShowing={isShowing_Fina}
              setIsShowing={setIsShowingFina}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>
      {/* work orfer reuplaod */}
      <Formik
        initialValues={initialValuesWorkOrder}
        validationSchema={WorkOrderValidation}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <WorkOrderReuploadModal
              isShowing={isShowing_work}
              setIsShowing={setIsShowingWork}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>

      {/* Any other reupalod  */}
      <Formik
        initialValues={initialValuesanyOther}
        validationSchema={AnyOtherValidation}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <AnyOthercomapnyDocumentsReuploadModal
              isShowing={isShowing_any_other}
              setIsShowing={setIsShowingAnyOther}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>

      {/* land document */}

      <Formik
        initialValues={initialValuesLand}
        validationSchema={LandValidation}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <LandDocumentReuploadModal
              isShowing={isShowing_land}
              setIsShowing={setIsShowingLand}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>

      {/* man power */}
      <Formik
        initialValues={initialValuesManPower}
        validationSchema={ManpowerValidation}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <ManpowerDocumentModal
              isShowing={isShowing_man_power}
              setIsShowing={setIsShowingManPower}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>

      {/* IT work  */}
      <Formik
        initialValues={initialValuesITTurnover}
        validationSchema={type == 33 ? ITValidationOne : ITValidationTwo}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <ITTurnoverReuplaodModal
              isShowing={isShowing_iturn}
              setIsShowing={setIsShowingITurn}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>

      {/* OEM */}

      <Formik
        initialValues={initialValuesOEM}
        validationSchema={OEMValiadtion}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <OEMReuplaodModal
              isShowing={isShowing_oem}
              setIsShowing={setIsShowingOEM}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>

      {/* Empanel */}

      <Formik
        initialValues={initialValuesanyEmapanel}
        validationSchema={EmpanelValiadtion}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <EmpanelReuplaodModal
              isShowing={isShowing_emapanel}
              setIsShowing={setIsShowingEmapanel}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              yearss={years}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setValue={setValue}
              value={value}
              handleReset={handleReset}
              type={type}
              setamount={setamount}
              setyear={setyear}
            />
          );
        }}
      </Formik>
    </React.Fragment>
  );
}
