import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

interface linetype{
  linedata:any[]
}
const LineChartView:React.FC<linetype> = ({linedata}) => {
 

  return (
    <LineChart width={500} height={280} data={linedata}>
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip/>
      <Legend />
      <Line
        type="monotone"
        dataKey="Approved"
        stroke="#006400"
        activeDot={{ r: 8 }}
        label="Approved"
      />
      
      <Line type="monotone" dataKey="Pending" stroke="#FF0000" label="Pending"/>
    </LineChart>
  );
};
export default LineChartView
