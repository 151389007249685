import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
import { List } from "reactstrap";
import { api } from "../../../Library/CustomApi";

export default function ProfileLayoutVendor(props: any) {
  const { language, setLanguage } = useContext(Context);
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const [doc_complted, setDocomplted] = useState();

  const [advanced, setAdvanced] = useState();
  const [value, setValue] = useState({
    document_status: "",
    emd_status: "",
    start_up: "",
    advanced: "",
  });
  useEffect(() => {
    getDocumnets();
  }, [pathname]);
  const getDocumnets = () => {
    api
      .get(`/vendor-document-status`, true)
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          setValue({
            ...value,
            document_status: response?.data?.document_status,
            emd_status: response?.data?.emd_status,
            start_up: response?.data?.is_startup,
            advanced: response?.data?.is_advanced,
          });
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/basic-details-view">
              {Vendor_profileUpdation?.Basic_Details[language]}
            </NavLink>
            <NavLink to="/advanced-registration">
              {Vendor_profileUpdation?.Advanced_Registration[language]}
            </NavLink>
            {value?.advanced == "0" ? (
              <span
                className="main"
                title="Please Complete Advanced Registration"
              >
                {Vendor_profileUpdation?.Document_Upload[language]}
              </span>
            ) : value?.start_up == "1" ? (
              <NavLink to="/start-up-document-upload">{Vendor_profileUpdation?.Document_Upload[language]}</NavLink>
            ) : value?.start_up == "1" ? (
              <NavLink to="/start-up-document-upload">
                {Vendor_profileUpdation?.Document_Upload[language]}
              </NavLink>
            ) : (
              <NavLink to="/document-upload">
                {Vendor_profileUpdation?.Document_Upload[language]}
              </NavLink>
            )}

            {value?.document_status == "0" ? (
              <span className="main" title="Please Upload Documents">
                {Vendor_profileUpdation?.Non_Refundable_Loyalty[language]}
              </span>
            ) : (
              <NavLink to="/emd-details">
                {Vendor_profileUpdation?.Non_Refundable_Loyalty[language]}
              </NavLink>
            )}
          </div>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
