import moment from "moment";
import React, { useState, useContext } from "react";
import { Context } from "../../../../Components/Context/Context";
import { useLocation } from "react-router-dom";
import { Admin_orders } from "../../../../Library/LanguageConversion/Admin_orders";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Table,
} from "reactstrap";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import { convertISODateToDateTime, getOrderStatus, getUnique } from "../../../../Library/Constants/helpers";
import ModalContainer from "../../../../Components/Modal/ModalContainer";

const OrderView3 = (props: any) => {
  const { language, setLanguage } = useContext(Context);
  var data: any = []
  for (let x of props?.orderId) {
    props?.order_view?.vendor_to_pmu_payment?.forEach((item: any, index: number) => {
      return data.push({ item, order_id: x[index] });
    })
    console.log(x[0], "dfhduhfd")
  }

  function truncateText(text: any) {
    return text?.replace(/<br>/g, ",");
  }
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<any>(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.modal}
        setOpen={props?.toggle}
        header='Order Details'
      >
        {props?.loading ? (
          <div style={{ marginLeft: "350px" }}>
            <Loader />
          </div>
        ) : (
          <div className="feeback-outer-wrap">
            <div className="feedback-main-heading"></div>
            <div className="feedback-wrap">
              <div className="feedback-details ">
                {/* ======================================= order tracking status =====================================
              */}
                <Row className="align-items-center">
                  <Col md={12}>
                    <div className="">
                      {
                        props?.confirmed == 1 && (<>
                          {
                            props?.order_view?.vendor_to_pmu_payment?.length !== 0 ? (<>
                              <div className="table-outer-wrap doc-approval-outer-wrap">
                                <div className="table-wrap responsive-table ">
                                  <h5 className="sub-heading">{Admin_orders?.DocumentApproval?.[language]}</h5>
                                  <Table className="w-100 doc-approval-wrap table-striped">
                                    <thead>
                                      <tr>
                                        <th>{Admin_orders?.Documents?.[language]}</th>
                                        <th>{Admin_orders?.Action?.[language]}</th>
                                        <th>{Admin_orders?.Status?.[language]}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {

                                        props?.order_view?.vendor_to_pmu_payment?.map((item: any, index: number) => {
                                          return (<>

                                            <tr>
                                              <td>
                                                <div className="input-wrap doc-wrap">
                                                  <div className="input-group">
                                                    <Input disabled type="text" class="form-control" value={"EMD"} />
                                                    <div className="checkbox-image-circle">
                                                      <i className="bi bi-check-circle-fill"></i>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <span className="mobile-heading">{Admin_orders?.Action?.[language]}:</span>
                                                <div className="action-flex-btn">
                                                  <a href={item?.docs} target="_blank"
                                                    className="rounded-tag"
                                                    title="View">
                                                    {/* <i className="icons icons-eye-orange"></i> */}
                                                    <i className="icons bi bi-eye lg"></i>

                                                  </a>
                                                  {item?.status == 1 ?
                                                    <a className="rounded-tag bg-success"
                                                    >
                                                      <i className="icons icons-accept-white"></i>
                                                    </a> :
                                                    <a onClick={() => props?.vendorApprovalDocument(1, item?.payment_from, data[index].item.order_id)}
                                                      target="_blank"
                                                      className="rounded-tag bg-success"

                                                    >
                                                      <i className="icons icons-accept-white"></i>
                                                    </a>}
                                                  {
                                                    item?.status !== 1 && (<> {item?.status == 2 ?
                                                      <a target="_blank" className="rounded-tag bg-danger">
                                                        <i className="bi bi-x-circle text-white"></i>
                                                      </a> :
                                                      <a onClick={() => props?.vendorApprovalDocument(2, item?.payment_from, data[index].item.order_id)}
                                                        target="_blank"
                                                        className="rounded-tag bg-danger"
                                                      >
                                                        <i className="bi bi-x-circle text-white"></i>
                                                      </a>}</>)
                                                  }
                                                </div>
                                              </td>

                                              <td>
                                                <span className="mobile-heading">{Admin_orders?.Status?.[language]}:</span>
                                                {item?.status == 2 && (<span className="text-danger">{Admin_orders?.Rejected?.[language]}</span>)}
                                                {item?.status == 1 && (<span className="text-success">{Admin_orders?.Approved?.[language]}</span>)}
                                                {item?.status == 0 && (<span className="text-info">{Admin_orders?.Waiting?.[language]}</span>)}
                                              </td>
                                            </tr>
                                            {/* {
                                                                                    props?.order_view?.order?.map((item: any) => {
                                                                                        return (
                                                                                            <>
                                                                                                <span>Vendor Name  :  {item?.cmpny_detail?.name}</span>

                                                                                            </>)
                                                                                    })

                                                                                } */}
                                            <tr>
                                              <td colSpan={3}>
                                                <div className="date-type-wrap">
                                                  <span><strong>{Admin_orders?.DateofPayment?.[language]}:</strong>
                                                    {convertISODateToDateTime(item?.created_at
                                                    )}</span>
                                                  <p>{Admin_orders?.Paymenttype?.[language]} : <span className="green-text">{item?.payment_type ==
                                                    constants?.PAYMENT_TYPE?.CHELLAN ? "Chellan" :
                                                    item?.payment_type ==
                                                      constants?.PAYMENT_TYPE?.CHEQUE ?
                                                      "Cheque" :
                                                      item?.payment_type ==
                                                        constants?.PAYMENT_TYPE?.BANK ? "Bank Transfer" :
                                                        item?.payment_type ==
                                                          constants?.PAYMENT_TYPE?.NETBANK ? "Net Banking" : null
                                                  }</span></p>
                                                </div>
                                              </td>
                                            </tr>
                                          </>)
                                        })
                                      }
                                    </tbody>

                                  </Table>
                                </div>

                              </div>
                            </>)
                              :
                              <div className="table-outer-wrap doc-approval-outer-wrap">
                                <span>{Admin_orders?.NoDocumentsarefound?.[language]}</span>
                              </div>

                          }
                        </>)
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      </ModalContainer>
    </React.Fragment>
  );
};

export default OrderView3;