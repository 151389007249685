import { useState ,useContext} from 'react'
import { toast } from 'react-toastify'
import { AccordionBody, AccordionHeader, AccordionItem, Button, Input, Label, Spinner, UncontrolledAccordion } from 'reactstrap'
import { api } from '../../../../Library/CustomApi'
import { useNavigate } from 'react-router-dom'
import { PAYMENT_TYPES, PMU_BANK_DETAILS } from '../../../../Library/Constants/MapConstants'
import { DepartmentProducts } from '../../../../Library/LanguageConversion/Department_Products'
import { Context } from '../../../../Components/Context/Context'

interface propType {
    paymentMethod?: string,
    id: number,
    selectedMode: number,
    amount: number,
    payment_type?: number,
    order_status?: number,
    orderId?: any
}
const RightSection = ({
    paymentMethod,
    id,
    selectedMode,
    amount,
    payment_type,
    order_status = 0,
    orderId = null
}: propType) => {

    const navigate = useNavigate()
    const [paying, setPaying] = useState<boolean>(false)
    const { language, setLanguage } = useContext(Context);


    const HandlePaymentConfirm = () => {
        if (id) {
            setPaying(true)
            api
                .patch(
                    `/order/pf-invoice/${id}`,
                    {
                        order_status: order_status || 0,
                        payment_mode: selectedMode,
                        last_paid_amount: Number(amount)?.toFixed(2),
                        payment_type: payment_type,
                        order_id: orderId
                    },
                    true
                )
                .then(async function ([success, response]) {
                    toast.success("Payment successful");
                    navigate('/department/draft')
                    setPaying(false)
                    return response;
                })
                .catch((err) => {
                    setPaying(false)
                    toast?.error('Error')
                    console.log(err);
                });
        }
        else {
            console.log('Error:id not found!');
            toast.error('Unexpected Error Occured')
        }
    }

    return (
        <div className='account-details-outer-wrap'>
            {payment_type !== 0 && <div className="submit-wrap">
                {/* <div className="agree-checkbox-wrap">
                    <Input
                        type="checkbox"
                        onClick={(e: any) => { setChecked(e.target.checked) }}
                        id='confirm-payment'>
                    </Input>
                    <Label htmlFor='confirm-payment'> 
                        Confirm entered details are correct
                    </Label>
                </div> */}

                <Button
                    color='primary'
                    disabled={paying}
                    onClick={HandlePaymentConfirm}
                >
                    {paying ?
                        <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                        />
                        :
                        'Confirm Payment'
                    }
                </Button>
                <small className='note'>{DepartmentProducts?.Note[language]}: <span>{DepartmentProducts?.Please_upload_drafts[language]}</span></small>
            </div>}
        </div>
    )
}

export default RightSection