import React, { useContext,useEffect } from 'react'
import PPEnglish from './EnglishComponents/PPEnglish';
import PPKanada from './KanadaComponents/PPKanada';
import { Context } from '../../Components/Context/Context';
import { useLocation } from 'react-router-dom';

export default function PP() {
    const location = useLocation();

    useEffect(() => {
        console.log(location?.pathname); 
        if (location?.pathname === "/privacy-policy") {
          window.scrollTo({ top: 0 });
        }
      }, []);

    // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
    const { language, setLanguage } = useContext(Context);

    return (
        language == "en" ? <PPEnglish/> : <PPKanada/>
    )
}
