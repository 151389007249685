import React, { useEffect, useState, useContext } from "react";
import { api } from "../../../../../../../Library/CustomApi";
import { Button, Card, CardBody, Col, ModalFooter } from "reactstrap";
import Loader from "../../../../../../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { API_URL } from "../../../../../../../Library/ApiSettings";
import { Context } from "../../../../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../../../../Library/LanguageConversion/Department_Products";

const ProductSuggestionModal = ({
  id,
  status,
  getCustomOrders,
  setIsSuggestionOpen,
}: any) => {
    const { language, setLanguage } = useContext(Context);
  const [suggestedProducts, setSuggestedProducts] = useState<any>([]);
  const [showButton, setShowButton] = useState<boolean>(true);

  useEffect(() => {
    getSuggestion();
  }, []);

  // gets suggestion details
  const getSuggestion = () => {
    api
      .get(`/product/suggest-productlist/${id}`, true)
      .then(async function ([success, response]) {
        setSuggestedProducts(response?.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // accept suggestion
  const acceptSuggestionHandler = (key: number) => {
    api
      .post(
        `/product/suggest-request`,
        {
          custom_order_id: id,
          suggestion_status: key,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          if (key == 2) {
            toast.success("Suggestion Accepted");
            setShowButton(false);
          } else if (4) {
            toast.success("Suggestion Rejected");
            setIsSuggestionOpen(false);
          }
        }
        getCustomOrders();
        return response;
      })
      .catch((err) => {
        toast.error("Unexpected Error Occured");
      });
  };

  // navigates to products view
  const handleNavigation = (id: number) => {
    window.open(`${"/department/products-view/"}${id}`);
  };

  return (
    <div className="row">
      {suggestedProducts?.length !== 0 ? (
        <>
          <div className="row">
            <>
              {suggestedProducts?.length !== 0 &&
                suggestedProducts?.map((obj: any, i: number) => {
                  return (
                    <Col className="mt-2" lg={6} md={6} sm={6} key={i}>
                      <Card
                        className="product-list-inner-wrap custom-order"
                        onClick={() =>
                          handleNavigation(obj?.custom_order_product?.id)
                        }
                      >
                        <CardBody>
                          <div className="product-thumb">
                            <img
                              className="img-fluid"
                              alt="img"
                              src={obj?.custom_product_image?.[0]?.path}
                            />
                          </div>
                          <div className="product-details">
                            {/* name of product  */}
                            <h6>{obj?.custom_order_product?.name}</h6>
                            {/* spec  */}
                            <small
                              dangerouslySetInnerHTML={{
                                __html: obj?.custom_order_product?.features,
                              }}
                            ></small>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
            </>
          </div>
        </>
      ) : (
        <div className="text-center">
          <Loader />
        </div>
      )}

      <ModalFooter className="product-suggestion-footer">
        <div>
          {status == 1 && showButton && (
            <div>
              <Button
                onClick={() => acceptSuggestionHandler(2)}
                className="btn btn-primary"
              >
                {DepartmentProducts?.Accept_Suggestion[language]}
              </Button>
              <Button
                onClick={() => acceptSuggestionHandler(4)}
                className="btn btn-danger"
              >
                {DepartmentProducts?.Reject_Suggestion[language]}
              </Button>
            </div>
          )}
        </div>
      </ModalFooter>
    </div>
  );
};

export default ProductSuggestionModal;
