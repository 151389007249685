import { Legend, Tooltip } from "recharts";
import { ResponsiveContainer, Pie, PieChart, Cell } from "recharts";
interface datasales {
  Salesdata: any[];
}
const DonutChartView: React.FC<datasales> = ({ Salesdata }) => {
  const colors = ["#9f3eff", "#7AC173", "#DCB12D", "#169fdb"];
  
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={Salesdata}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={100}
          fill="#8884d8"
          // paddingAngle={5}
          label   
               
        >
          {Salesdata?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend
        
        layout="horizontal"
        align="center"
        iconType="circle"
        iconSize={10}
        wrapperStyle={{ paddingLeft: 10 }}
      />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChartView;
