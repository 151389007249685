import React from "react";
import { useContext, useState } from "react";

import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../Components/Context/Context";
import { Department_Orders } from "../../../Library/LanguageConversion/Department_Orders";
export default function DeptOrderLayout(props: any) {
  const { pathname } = useLocation()
  const {language,setLanguage}=useContext(Context);

  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink
              to="/department/placed-orders"
            >
             {Department_Orders?.Placed[language]}
            </NavLink>
            <NavLink
              to="/department/processing-orders"
            >
              {Department_Orders?.Processing[language]}
            </NavLink>
            <NavLink
              to="/department/orders"
            >
              {Department_Orders?.Delivered[language]}
            </NavLink>
            <NavLink
              to="/department/cancelled-orders"
            >
             {Department_Orders?.Cancelled[language]}
            </NavLink>
            <NavLink
              to="/department/payment-pending-orders"
            >
             {Department_Orders?.Payment_Pending[language]} 
            </NavLink>
          </div>
          {/* breadcrumbs */}
          <List className=" breadcrumb">
            <li>
              <Link
                className="active"
                to="/department/dashboard"
              >
                {Department_Orders?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {
                  pathname == "/dept-delivered-orders" ? "Delivered Orders" :
                    pathname == "/dept-processing-orders" ? "Processing Orders" :
                      pathname == "/dept-cancelled-orders" ? "Cancelled Orders" : 'Orders'
                }
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
