import React, { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../Components/Context/Context";
import { Admin_Verifications } from "../../../Library/LanguageConversion/Admin_Verifications";

export default function VerificationLayout(props: any) {
  const { pathname } = useLocation();
  const { language, setLanguage } = useContext(Context);
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/department-verifications">
              {Admin_Verifications?.Department?.[language]}
            </NavLink>
            <NavLink to="/addr-verifications">
              {Admin_Verifications?.Address?.[language]}
            </NavLink>
            <NavLink to="/account-verifications">
              {Admin_Verifications?.Account?.[language]}
            </NavLink>
            <NavLink to="/draft-verifications">
              {Admin_Verifications?.Draft?.[language]}
            </NavLink>
            <NavLink to="/vendor-verifications">
              {Admin_Verifications?.Vendor_draft?.[language]}
            </NavLink>
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/dashboard">
              {Admin_Verifications?.Dashboard?.[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {pathname == "/department-verifications"
                  ? `${Admin_Verifications?.Department?.[language]}`
                  : pathname == "/addr-verifications"
                  ? `${Admin_Verifications?.Address?.[language]}`
                  : pathname == "/draft-verifications"
                  ? `${Admin_Verifications?.Draft?.[language]}`
                  : pathname == "/account-verifications"
                  ? `${Admin_Verifications?.Account?.[language]}`
                  : pathname == "/vendor-verifications"
                  ? `${Admin_Verifications?.Vendor_draft?.[language]}`
                  : "Verification"}
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
