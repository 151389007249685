export const Admin_RateContract: any = {
    // RateContract/Modal/AddTendorModal
    Download: {
        en: 'Download',
        ka: 'ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ'
    },
    Tender_1: {
        en: 'Tender 1',
        ka: 'ಟೆಂಡರ್ ೧'
    },
    Para1: {
        en: 'Lorem Ipsum is simply dummy text of the printing',
        ka: 'ಲೋರೆಮ್ ಇಪ್ಸಮ್ ಪ್ರಿಂಟಿಂಗ್ ನ ಸರಳವಾದ ಮೂಡನೆಯ ಪಠ್ಯವನ್ನು'
    },
    Tender_ID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    TenderIDCategory: {
        en: 'Tender ID/Category',
        ka: 'ಟೆಂಡರ್ ಐಡಿ/ವರ್ಗ'
    },
    Tender_Date: {
        en: 'Tender Date',
        ka: 'ಟೆಂಡರ್ ದಿನಾಂಕ'
    },
    Category: {
        en: 'Category',
        ka: 'ವರ್ಗ'
    },
    Version: {
        en: 'Version',
        ka: 'ಆವೃತ್ತಿ'
    },

    Select_version: {
        en: 'Select version',
        ka: 'ಆವೃತ್ತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },

    Valid_From: {
        en: 'Valid From',
        ka: 'ಯಥಾರ್ಥವಾಗಿ ಪ್ರಾರಂಭವಾದ'
    },

    Valid_To: {
        en: 'Valid To',
        ka: 'ಯಥಾರ್ಥವಾಗಿ ಮುಗಿದ'
    },

    Approved_by: {
        en: 'Approved by',
        ka: 'ಅನುಮೋದಿತ ಮಾಡಲಾಗಿದೆ'
    },

    Add_Tender: {
        en: 'Add Tender',
        ka: 'ಟೆಂಡರ್ ಸೇರಿಸಿ'
    },
    // RateContract/Modal/AssignModal

    Para3: {
        en: '+ Add new make here',
        ka: '+ ಹೊಸ ಮೇಕ್ ಇಲ್ಲಿ ಸೇರಿಸಿ'
    },
    Low_1: {
        en: 'Low 1',
        ka: 'ಕಡಿಮೆ ೧'
    },
    Low_1_Make: {
        en: 'Low 1 Make',
        ka: 'ಕಡಿಮೆ ೧ ಮೇಕ್'
    },
    Low_1_Model: {
        en: 'Low 1 Model',
        ka: 'ಕಡಿಮೆ ೧ ಮಾದರಿ'
    },
    Low_2: {
        en: 'Low 2',
        ka: 'ಕಡಿಮೆ ೨'
    },
    Low_2_Make: {
        en: 'Low 2 Make',
        ka: 'ಕಡಿಮೆ ೨ ಮೇಕ್'
    },
    Low_2_Model: {
        en: 'Low 2 Model',
        ka: 'ಕಡಿಮೆ ೨ ಮಾದರಿ'
    },
    Low_3: {
        en: 'Low 3',
        ka: 'ಕಡಿಮೆ ೩'
    },
    Low_3_Make: {
        en: 'Low 3 Make',
        ka: 'ಕಡಿಮೆ ೩ ಮೇಕ್'
    },
    Low_3_Model: {
        en: 'Low 3 Model',
        ka: 'ಕಡಿಮೆ ೩ ಮಾದರಿ'
    },
    Service_Warranty: {
        en: 'Service Warranty',
        ka: 'ಸೇವೆ ವಾರಂಟಿ'
    },
    Hardware_Warranty: {
        en: ' Hardware Warranty',
        ka: 'ಹಾರ್ಡ್‌ವೇರ್ ವಾರಂಟಿ'
    },
    On_Site_Warranty: {
        en: 'On Site Warranty',
        ka: 'ಸ್ಥಳದಲ್ಲಿ ವಾರಂಟಿ'
    },

    Assign: {
        en: 'Assign',
        ka: 'ನಿಯೋಜಿಸು'
    },



    // RateContract/Modal/Manufacture

    Make: {
        en: 'Make',
        ka: 'ಮಾಡು'
    },
    Model: {
        en: 'Model',
        ka: 'ಮಾದರಿ'
    },
    Add: {
        en: 'Add',
        ka: 'ಸೇರಿಸಿ'
    },

    // RateContract/ProductDetails/ProductComparison

    Product_Details: {
        en: 'Product Details',
        ka: 'ಉತ್ಪಾದನ ವಿವರಗಳು'
    },

    Upload_Rate_Contract: {
        en: 'CatUpload Rate Contractegory',
        ka: 'ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ಅಪ್ಲೋಡ್ ಮಾಡಿ'
    },
    Dell_Laptop: {
        en: 'Dell Laptop',
        ka: 'ಡೆಲ್ ಲ್ಯಾಪ್‌ಟಾಪ್'
    },

    Dell1: {
        en: 'Dell 23.8 inch Full HD VA Panel 3-Side Near Edgeless with TUV Eye Care Monitor',
        ka: 'ಡೆಲ್ 23.8 ಇಂಚು ಪೂರ್ಣ HD VA ಪ್ಯಾನಲ್, 3 ಭಾಗದ ಹತ್ತುಗಳನ್ನು ಸೇರಿಸಿದೆ, TUV ಕಣ್ಣು ಸೌರಭ ಮಾರ್ಗವನ್ನು ಹೊಂದಿದ ಮಾನಿಟರ್'
    },

    Panel_Type: {
        en: 'Panel Type',
        ka: 'ಪ್ಯಾನಲ್ ಪ್ರಕಾರ'
    },

    VA_Panel: {
        en: 'VA Panel',
        ka: 'VA ಪ್ಯಾನಲ್'
    },

    Screen_Resolution_Type: {
        en: 'Screen Resolution Type',
        ka: 'ಸ್ಕ್ರೀನ್ ರೆಸಲ್ಯೂಶನ್ ಪ್ರಕಾರ'
    },

    Full_HD: {
        en: 'Full HD',
        ka: 'ಪೂರ್ಣ HD'
    },

    Response_Time: {
        en: 'Response Time: 4 ms',
        ka: 'ಪ್ರತಿಕ್ರಿಯಾ ಸಮಯ: 4 ಮಿಲಿಸೆಕೆಂಡ್'
    },
    Refresh_Rate: {
        en: 'Refresh Rate: 60 Hz',
        ka: 'ರಿಫ್ರೆಶ್ ದರ: 60 ಹೆರ್ಟ್ಜ್'
    },
    General_Specifications: {
        en: 'General Specifications',
        ka: 'ಸಾಮಾನ್ಯ ವಿನಿಮಯ'
    },
    Model_Name: {
        en: 'Model Name',
        ka: 'ಮಾದರಿ ಹೆಸರು'
    },
    Color: {
        en: 'Color',
        ka: 'ಬಣ್ಣ'
    },
    Raven_Black: {
        en: 'Raven Black',
        ka: 'ರೇವನ್ ಕಪ್ಪು'
    },
    Display: {
        en: 'Display',
        ka: 'ಪ್ರದರ್ಶನ'
    },
    Resolution_Type: {
        en: 'No Screen Resolution Type',
        ka: 'ಸ್ಕ್ರೀನ್ ರೆಸಲ್ಯೂಶನ್ ಪ್ರಕಾರವಿಲ್ಲ'
    },
    Sales_Package: {
        en: 'Sales Package',
        ka: 'ಮಾರಾಟ ಪ್ಯಾಕೇಜ್'
    },
    packages: {
        en: '1 Monitor, Power Cable, HDMI Cable,Quick Setup Guide',
        ka: '1 ಮಾನಿಟರ್, ವಿದ್ಯುತ್ ಕೇಬಲ್, HDMI ಕೇಬಲ್, ತುಂಬಾ ಬೇಗನೆ ಸೆಟ್ಅಪ್ ಗೈಡ್'
    },
    View_Vendors: {
        en: 'View Vendors',
        ka: 'ವೆಂಡರ್ಗಳನ್ನು ವೀಕ್ಷಿಸಿ'
    },
    Product_Comparison: {
        en: 'Product Comparison',
        ka: 'ಉತ್ಪನ್ನ ಹೋಲಿಕೆ'
    },
    Keonics: {
        en: 'Keonics',
        ka: 'ಕೇಓನಿಕ್ಸ್'
    },
    Amazon: {
        en: 'Amazon',
        ka: 'ಅಮೇಜಾನ್'
    },
    Price: {
        en: 'Price',
        ka: 'ಬೆಲೆ'
    },
    Flipkart: {
        en: 'Flipkart',
        ka: 'ಫ್ಲಿಪ್‌ಕಾರ್ಟ್'
    },


    // RateContract/ProductDetails/Specifications


    Upload_Rate: {
        en: 'Upload Rate',
        ka: 'ಅಪ್ಲೋಡ್ ದರ'
    },
    Rate_Contract: {
        en: 'Rate Contract',
        ka: 'ದರ ಒಪ್ಪಿಗೆ'
    },
    Specifications: {
        en: 'Specifications',
        ka: 'ವಿವರಣೆಗಳು'
    },
    HSN_Code: {
        en: 'HSN Code',
        ka: 'HSN ಕೋಡ್'
    },
    Product_code: {
        en: ' Product code',
        ka: 'ಉತ್ಪನ್ನ ಕೋಡ್'
    },
    Sl_No: {
        en: 'Sl No',
        ka: 'ಕ್ರಮ ಸಂಖ್ಯೆ'
    },
    Vendors: {
        en: 'Vendors',
        ka: 'ವೆಂಡರ್ಗಳು'
    },
    Vendor_Name: {
        en: 'Vendor Name',
        ka: 'ವೆಂಡರ್ ಹೆಸರು'
    },
    Contract_Expiry: {
        en: 'Contract Expiry',
        ka: 'ಒಪ್ಪಿಗೆ ಮುಗಿಯುವುದು'
    },
    Registration_ID: {
        en: 'Registration ID',
        ka: 'ನೋಂದಾಯಿಸುವ ಐಡಿ'
    },
    Contact_Number: {
        en: 'Contact Number',
        ka: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ'
    },
    Email_ID: {
        en: 'Email ID',
        ka: 'ಇಮೇಲ್ ಐಡಿ'
    },
    Lows: {
        en: 'Lows',
        ka: 'ಕಡಿಮೆಯಾಗಿರುವುದು'
    },
    Not_Assigned: {
        en: 'Vendor(s) Not Assigned',
        ka: 'ವೆಂಡರ್(ಗಳು) ನೇಮಕಾತಿಗೆ ಒಪ್ಪಿಗೆ ಹೊಂದಿಲ್ಲ'
    },
    Not__Assigned: {
        en: ' Not Assigned',
        ka: 'ನಿಯೋಜಿಸಲಾಗಿಲ್ಲ'
    },

    // RateContract/Tabs/RateAssigned

    Unassigned: {
        en: 'Unassigned',
        ka: 'ನೇಮಕಾತಿಗೆ ಹೊಂದಿಲ್ಲ'
    },
    Assigned: {
        en: 'Assigned',
        ka: 'ನೇಮಕಾತಿಗೆ ಹೊಂದಿದೆ'
    },
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್'
    },
    Select_Category: {
        en: 'Select Category',
        ka: 'ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    Product_Name: {
        en: 'Product Name',
        ka: 'ಉತ್ಪನ್ನ ಹೆಸರು'
    },
    Product_Specifications: {
        en: 'Product Specifications',
        ka: 'ಉತ್ಪನ್ನ ವಿನಿಮಯ ವಿವರಗಳು'
    },
    Cost_Price: {
        en: 'Cost Price',
        ka: 'ವಿಲೆ ಬೆಲೆ'
    },
    Excluding_GST: {
        en: 'Excluding GST',
        ka: 'GST ಹೊಂದಿಲ್ಲದೆ'
    },
    Sales_Price: {
        en: 'Sales Price',
        ka: 'ಮಾರಾಟ ಬೆಲೆ'
    },
    Action: {
        en: 'Action',
        ka: 'ಕ್ರಿಯೆ'
    },
    Status: {
        en: 'Status',
        ka: 'ಸ್ಥಿತಿ'
    },
    Pending: {
        en: 'Pending',
        ka: 'ಬಾಕಿಯಿದೆ'
    },
    Approved: {
        en: 'Approved',
        ka: 'ಅನುಮೋದಿಸಲಾಗಿದೆ'
    },
    Showing: {
        en: 'Showing',
        ka: 'ತೋರಿಸುತ್ತಿದೆ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ಯಲ್ಲಿ'
    },
    entries: {
        en: 'entries',
        ka: 'ಪ್ರವೇಶಗಳು'
    },

    // RateContract/Tabs/RateContract

    // RateContract/Tabs/UploadRate

    Categories: {
        en: 'Categories',
        ka: 'ವರ್ಗಗಳು'
    },
    Active: {
        en: 'Active',
        ka: 'ಸಕ್ರಿಯ'
    },
    Inactive: {
        en: 'Inactive',
        ka: 'ನಿಷ್ಕ್ರಿಯ'
    },
    Reset: {
        en: 'Reset',
        ka: 'ಮರುಹೊಂದಿಸಿ'
    },
    No_Tender_Found: {
        en: 'No Tender Found',
        ka: 'ಯಾವುದೇ ಟೆಂಡರ್ ಕಂಡುಬಂದಿಲ್ಲ'
    },
    LCD_Display: {
        en: 'LCD Display',
        ka: 'LCD ಡಿಸ್ಪ್ಲೇ'
    },
}