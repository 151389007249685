export const Admin_addtendor:any = {

    //Admin---AddTendor---RateContractLayout

    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },

    Tender: {
        en: 'Tender',
        ka: 'ಡ್ಟೆಂಡರ್'
    },
    
    //Admin---AddTendor---Modal---AddTendorModal

    TenderID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    TenderIDCategory: {
        en: 'Tender ID/Category',
        ka: 'ಟೆಂಡರ್ ಐಡಿ/ವರ್ಗ'
    },
    TenderDate: {
        en: 'Tender Date',
        ka: 'ಟೆಂಡರ್ ದಿನಾಂಕ'
    },
    Category: {
        en: 'Category',
        ka: 'ವರ್ಗ'
    },
    Version: {
        en: 'Version',
        ka: 'ಆವೃತ್ತಿ'
    },
    SelectVersion: {
        en: 'Select Version',
        ka: 'ಆಯ್ಕೆಮಾಡಿರುವ ಆವೃತ್ತಿ'
    },
    ValidFrom: {
        en: 'Valid From',
        ka: 'ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ'
    },
    ValidTo: {
        en: 'Valid To',
        ka: 'ಗೆ ಮಾನ್ಯವಾಗಿದೆ'
    },
    Approvedby: {
        en: 'Approved by',
        ka: 'ಇವರಿಂದ ಅನುಮೋದಿಸಲ್ಪಟ್ಟಿದೆ'
    },
    AddTender: {
        en: 'Add Tender',
        ka: 'ಟೆಂಡರ್ ಸೇರಿಸಿ'
    },

    //Admin---AddTendor---Tabs---UploadRate

    Reset: {
        en: 'Reset',
        ka: 'ಮರುಹೊಂದಿಸಿ'
    },
    SlNo: {
        en: 'Sl No',
        ka: 'ಕ್ರ.ಸಂ'
    },
    Categories: {
        en: 'Categories',
        ka: 'ವರ್ಗಗಳು'
    },
    Action: {
        en: 'Action',
        ka: 'ಕ್ರಿಯೆ'
    },
    Showing: {
        en: 'Showing',
        ka: 'ತೋರಿಸುವ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ನ'
    },
    entries: {
        en: 'entries',
        ka: 'ಪ್ರವೇಶಗಳು'
    },

}