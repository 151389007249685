import React, { useEffect,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import { preventNegativeValues } from "../../../Library/Constants/helpers";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
import { Context } from "../../../Components/Context/Context";
const DOCUMENT_URL = DOCS_URL;
export default function MSMEDocuments(props: any) {
  const {language,setLanguage} = useContext(Context)
  return (
    <>
      <div className="company-information-update-wrap">
        <h4> {Vendor_profileUpdation?.MSME_Certificate?.[language]}</h4>
        <hr />
        <Row>
          {/* <Col md={4} sm={6}>
            <FormGroup>
              <Label>
                MSME Certificate{" "}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.list <= 0 ? (
                  <>
                    {" "}
                    <input
                      type="file"
                      ref={props?.oemRef}
                      id="mse_certificate"
                      name="mse_certificate"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                      disabled={props?.list > 0 ? true : false}
                    ></input>
                    <Label
                      className="image-button"
                      for="mse_certificate"
                      id="mse_certificate"
                    >
                      Choose Documents
                    </Label>
                    <ErrorMessage
                      name="mse_certificate"
                      component={TextError}
                    />
                  </>
                ) : (
                  <>
                    <InputGroup>
                      <input
                        id="mse_certificate"
                        name="mse_certificate"
                        className="form-control"
                        accept=".pdf"
                        value={props?.datas?.mse_certificate}
                        disabled={props?.datas?.mse_certificate ? true : false}
                      ></input>
                      <InputGroupText
                        className="input-group-text bg-white border-start-0"
                        id="newpass"
                      >
                        <a
                          href={
                            `${DOCUMENT_URL}` + props?.datas?.mse_certificate
                          }
                          download
                          target="_blank"
                        >
                          {" "}
                          <i className="bi bi-box-arrow-down"></i>
                        </a>
                      </InputGroupText>
                    </InputGroup>
                  </>
                )}
              </div>
            </FormGroup>
          </Col> */}

          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Udyam_Registration?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_udyam_registration_number"
                    type="text"
                    value={
                      props?.values?.start_up_company_udyam_registration_number
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_udyam_registration_number"
                    type="text"
                    value={
                      props?.values?.start_up_company_udyam_registration_number
                    }
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_registration_number"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Name_Of_Enterprises?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_udyam_name_of_enterprises"
                    type="text"
                    value={
                      props?.values?.start_up_company_udyam_name_of_enterprises
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_udyam_name_of_enterprises"
                    type="text"
                    value={
                      props?.values?.start_up_company_udyam_name_of_enterprises
                    }
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_name_of_enterprises"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Type_Of_Enterprises?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_udyam_type_of_enterprises"
                    type="text"
                    value={
                      props?.values?.start_up_company_udyam_type_of_enterprises
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_udyam_type_of_enterprises"
                    type="text"
                    value={
                      props?.values?.start_up_company_udyam_type_of_enterprises
                    }
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_type_of_enterprises"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Major_Activity?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_udyam_major_activity"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_major_activity
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_udyam_major_activity"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_major_activity
                    }
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_major_activity"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Social_Category_Of_Enterprises?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_udyam_social_category_of_enterprises"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_social_category_of_enterprises
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_udyam_social_category_of_enterprises"
                    type="text"
                    value={props?.values?.start_up_company_po}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_social_category_of_enterprises"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Office_Address?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_udyam_type_office_address"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_type_office_address
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_udyam_type_office_address"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_type_office_address
                    }
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_type_office_address"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Date_Of_UDYAM?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <DatePicker
                    value={props?.values?.start_up_company_udyam_date}
                    onChange={(e: any) => {
                      props?.setFieldValue(
                        "start_up_company_udyam_date",
                        e
                      );
                    }}
                    name="start_up_company_udyam_date"
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    maxDate={new Date()}
                  />
                ) : (
                  <Input
                    type="text"
                    name="start_up_company_udyam_date"
                    onChange={props?.handleChange}
                    value={props?.values?.start_up_company_udyam_date}
                    disabled
                  ></Input>
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_date"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.District_Industries?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_udyam_district_industries_center"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_district_industries_center
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_udyam_district_industries_center"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_district_industries_center
                    }
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_district_industries_center"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={6}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.MSME_DFO?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="start_up_company_udyam_msme_dfo"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_msme_dfo
                    }
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="start_up_company_udyam_msme_dfo"
                    type="text"
                    value={
                      props?.values
                        ?.start_up_company_udyam_msme_dfo
                    }
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage
                name="start_up_company_udyam_msme_dfo"
                component={TextError}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
