import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import{useContext} from "react"
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import TextError from "../../../Components/Errors/TextError";
import { api } from "../../../Library/CustomApi";
import constants from "../../../Library/Constants/Constants";
// import DatePicker from "react-datepicker";
import DatePicker from "react-date-picker";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { preventNegativeValues } from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_Settings_ReuploadModal } from "../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";

interface ProductProps {
  isShowing: boolean;
  setIsShowing: Function;
  handleSubmit: any;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  isSubmitting: any;
  setFieldValue: any;
  setValue: any;
  value: any;
  handleReset: any;
  type: any;
  setamount: any;
  setyear: any;
  yearss: any;
}

const ReDocUpload = (props: ProductProps) => {
  const {language,setLanguage}=useContext(Context)
  const currentYear = new Date().getFullYear(); // 2020
  const previousYear = currentYear - 1;
  const nest_yr = previousYear - 1;
  const handleFilechange = (e: any, values?: any) => {
    const myMemoObj = URL.createObjectURL(e.target.files[0]);
    // props?.setValue([...props?.value, myMemoObj]);
    props?.setFieldValue("pan", e.target.files[0]);
    // if (e.target.name == "pan") {
    //     props?.setFieldValue("pan", e.target.files[0])
    // }

    // if (e.target.name == "amount") {
    //     props?.setamount(e.target.values)
    // }
  };
  let pan = props?.type == constants.DOCUMENTS.PAN ?VendorRegLang?.pan_card?.[language]: null;

  let oem = props?.type == constants.DOCUMENTS.OEM ?VendorRegLang?.oem?.[language] : null;
  let adar = props?.type == constants.DOCUMENTS.AADAR ?VendorRegLang?.maf?.[language] : null;
  let addr =
    props?.type == constants.DOCUMENTS.AADRESS_PROOF ?VendorRegLang?.address_proof?.[language]: null;
  let incorporation =
    props?.type == constants.DOCUMENTS.INCORP ?VendorRegLang?.incoporation?.[language]: null;
  let mse = props?.type == constants.DOCUMENTS.MSE ? "MSE" : null;
  let gst = props?.type == constants.DOCUMENTS.GST ?VendorRegLang?.gst?.[language] : null;
  let t1 = props?.type == constants.DOCUMENTS.TURN_OVER1 ? "TURN_OVER1" : null;
  let t2 = props?.type == constants.DOCUMENTS.TURN_OVER2 ? "TURN_OVER2" : null;
  let t3 = props?.type == constants.DOCUMENTS.TURN_OVER3 ? "TURN_OVER3" : null;
  let iso = props?.type == constants.DOCUMENTS.ISO ?VendorRegLang?.iso?.[language]: null;
  let chellan = props?.type == constants.DOCUMENTS.CHELLAN?VendorRegLang?.challan?.[language]: null;
  let cheque = props?.type == constants.DOCUMENTS.CHEQUE ?VendorRegLang?.cheque?.[language]: null;
  let dd = props?.type == constants.DOCUMENTS.DD ? "DD" : null;
  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Document Reupload"
      >
        <div className="upload-prod-outer">
          <Form className="form-wrap" onSubmit={props?.handleSubmit}>
            <span>
              {pan ||
                oem ||
                mse ||
                gst ||
                t1 ||
                t2 ||
                t3 ||
                iso ||
                incorporation ||
                adar ||
                addr ||
                chellan ||
                cheque ||
                dd}{" "}
            { VendorRegLang?.Certificate?.[language] } 
            </span>
            <Row className="mt-3">
              <Col md={12} sm={12}>
                <FormGroup>
                  <Label>
                  {Vendor_Settings_ReuploadModal?.Upload_document?.[language]}
                    <span className="text-danger" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </Label>
                  <div className="image-input">
                    <Input
                      type="file"
                      accept=".pdf"
                      id="pan"
                      name="pan"
                      onChange={(e: any) =>
                        handleFilechange(e, e.target.files[0])
                      }
                    ></Input>
                    <Label className="image-button" for="pan" id="pan">
                     {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}
                    </Label>
                  </div>
                  <ErrorMessage name="pan" component={TextError} />
                </FormGroup>
              </Col>
            </Row>
            {props?.type == 7 && (
              <>
                <Row className="mt-3">
                  <Col md={12} sm={12}>
                    <FormGroup>
                      <Label>
                      {Vendor_Settings_ReuploadModal?.Upload_document?.[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        <Input
                          type="file"
                          accept=".pdf"
                          id="FFfinancial_year"
                          name="FFfinancial_year"
                          onChange={(e: any) =>
                            handleFilechange(e, e.target.files[0])
                          }
                        ></Input>
                        <Label
                          className="image-button"
                          for="FFfinancial_year"
                          id="FFfinancial_year"
                        >
                           {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}
                        </Label>
                      </div>
                     
                    </FormGroup>
                  </Col>
                  <ErrorMessage
                        name="FFfinancial_year"
                        component={TextError}
                      />
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Amount?.[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          type="number"
                          name="Ffinancial_year"
                          value={props?.values?.Ffinancial_year}
                          onChange={props?.handleChange}
                          className={`form-control ${
                            props?.touched.Ffinancial_year &&
                            props?.errors.Ffinancial_year
                              ? "is-invalid"
                              : ""
                          }`}
                        ></Input>
                      </div>
                      <ErrorMessage
                        name="Ffinancial_year"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    <FormGroup className="demo">
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Year?.[language]} 
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <DatePicker
                          value={props?.values?.Ffinancial_year}
                          onChange={(e: any) => {
                            props?.setFieldValue("Ffinancial_year", e);
                          }}
                          name="Ffinancial_year"
                          format={"dd-MM-y"}
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          className={`form-control ${
                            props?.touched.Ffinancial_year && props?.errors.Ffinancial_year
                              ? "is-invalid"
                              : ""
                          }`}
                          maxDate={new Date()}
                          // disabled={list > 0 ? true : false}
                        />
                        <ErrorMessage name="Ffinancial_year" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>
                  <div>{Vendor_Settings_ReuploadModal?.Approved_by_Chartered_Accountant?.[language]}( {Vendor_Settings_ReuploadModal?.CA?.[language]} )</div>
                  <Row>
                    <Col md={4} sm={4}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                            {" "}
                            {Vendor_Settings_ReuploadModal?.CA_Name?.[language]} {" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <Input
                            name="financial_turn_over_ca_name"
                            type="text"
                            value={props?.values?.financial_turn_over_ca_name}
                            onChange={props?.handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="financial_turn_over_ca_name"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={4}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                            {" "}
                            {Vendor_Settings_ReuploadModal?.CA_Email?.[language]}{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <Input
                            name="financial_turn_over_ca_email"
                            type="email"
                            value={props?.values?.financial_turn_over_ca_email}
                            onChange={props?.handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="financial_turn_over_ca_email"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={4}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                            {" "}
                            {Vendor_Settings_ReuploadModal?.CA_Phone_Number?.[language]}{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <Input
                            name="financial_turn_over_ca_phone"
                            type="tel"
                            onKeyPress={preventNegativeValues}
                            value={props?.values?.financial_turn_over_ca_phone}
                            onChange={props?.handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="financial_turn_over_ca_phone"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {props?.type == 8 && (
              <>
                <Row className="mt-3">
                  <Col md={12} sm={12}>
                    <FormGroup>
                      <Label>
                      {Vendor_Settings_ReuploadModal?.Upload_document?.[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        <Input
                          type="file"
                          accept=".pdf"
                          id="pan"
                          name="pan"
                          onChange={(e: any) =>
                            handleFilechange(e, e.target.files[0])
                          }
                        ></Input>
                        <Label className="image-button" for="pan" id="pan">
                          Choose Document
                        </Label>
                      </div>
                      <ErrorMessage name="pan" component={TextError} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Amount?.[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          type="number"
                          name="Ffinancial_year"
                          value={props?.values?.Ffinancial_year}
                          onChange={props?.handleChange}
                          className={`form-control ${
                            props?.touched.Ffinancial_year &&
                            props?.errors.Ffinancial_year
                              ? "is-invalid"
                              : ""
                          }`}
                        ></Input>
                      </div>
                      <ErrorMessage
                        name="Ffinancial_year"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    <FormGroup className="demo">
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Year?.[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <DatePicker
                          value={props?.values?.year}
                          onChange={(e: any) => {
                            props?.setFieldValue("year", e);
                          }}
                          name="year_from"
                          format={"dd-MM-y"}
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          className={`form-control ${
                            props?.touched.year && props?.errors.year
                              ? "is-invalid"
                              : ""
                          }`}
                          maxDate={new Date()}
                          // disabled={list > 0 ? true : false}
                        />
                        <ErrorMessage name="year" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>
                  <div>{Vendor_Settings_ReuploadModal?.Approved_by_Chartered_Accountant?.[language]}({Vendor_Settings_ReuploadModal?.CA?.[language]})</div>
                  <Row>
                    <Col md={4} sm={4}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                            {" "}
                            {Vendor_Settings_ReuploadModal?.CA_Name?.[language]}{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <Input
                            name="financial_turn_over_ca_name"
                            type="text"
                            value={props?.values?.financial_turn_over_ca_name}
                            onChange={props?.handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="financial_turn_over_ca_name"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={4}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                            {" "}
                            {Vendor_Settings_ReuploadModal?.CA_Email?.[language]}{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <Input
                            name="financial_turn_over_ca_email"
                            type="email"
                            value={props?.values?.financial_turn_over_ca_email}
                            onChange={props?.handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="financial_turn_over_ca_email"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={4}>
                      <FormGroup>
                        <div className="custom-input">
                          <Label>
                            {" "}
                            {Vendor_Settings_ReuploadModal?.CA_Phone_Number?.[language]}{" "}
                            <span
                              className="text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              *
                            </span>
                          </Label>
                          <Input
                            name="financial_turn_over_ca_phone"
                            type="tel"
                            onKeyPress={preventNegativeValues}
                            value={props?.values?.financial_turn_over_ca_phone}
                            onChange={props?.handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="financial_turn_over_ca_phone"
                          component={TextError}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {props?.type == 9 && (
              <>
                <Row>
                  <Col md={12} sm={12}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Amount?.[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          type="number"
                          name="Ffinancial_year"
                          value={props?.values?.Ffinancial_year}
                          onChange={props?.handleChange}
                          className={`form-control ${
                            props?.touched.Ffinancial_year &&
                            props?.errors.Ffinancial_year
                              ? "is-invalid"
                              : ""
                          }`}
                        ></Input>
                      </div>
                      <ErrorMessage
                        name="Ffinancial_year"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    <FormGroup className="demo">
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Year?.[language]}  
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <DatePicker
                          value={props?.values?.year}
                          onChange={(e: any) => {
                            props?.setFieldValue("year", e);
                          }}
                          name="year_from"
                          format={"dd-MM-y"}
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          className={`form-control ${
                            props?.touched.year && props?.errors.year
                              ? "is-invalid"
                              : ""
                          }`}
                          maxDate={new Date()}
                          // disabled={list > 0 ? true : false}
                        />
                        <ErrorMessage name="year" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <div>{Vendor_Settings_ReuploadModal?.Approved_by_Chartered_Accountant?.[language]}({Vendor_Settings_ReuploadModal?.CA?.[language]})</div>
                <Row>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                          {" "}
                          {Vendor_Settings_ReuploadModal?.CA_Name?.[language]}{" "}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          name="financial_turn_over_ca_name"
                          type="text"
                          value={props?.values?.financial_turn_over_ca_email}
                          onChange={props?.handleChange}
                        />
                      </div>
                      <ErrorMessage
                        name="financial_turn_over_ca_name"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                          {" "}
                          {Vendor_Settings_ReuploadModal?.CA_Email?.[language]}{" "}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          name="financial_turn_over_ca_email"
                          type="email"
                          value={props?.values?.financial_turn_over_ca_email}
                          onChange={props?.handleChange}
                        />
                      </div>
                      <ErrorMessage
                        name="financial_turn_over_ca_email"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                          {" "}
                          {Vendor_Settings_ReuploadModal?.CA_Phone_Number?.[language]}{" "}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <Input
                          name="financial_turn_over_ca_phone"
                          type="tel"
                          onKeyPress={preventNegativeValues}
                          value={props?.values?.financial_turn_over_ca_phone}
                          onChange={props?.handleChange}
                        />
                      </div>
                      <ErrorMessage
                        name="financial_turn_over_ca_phone"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            <Row className="mt-3">
              <Col sm={12} className="text-end">
                <Button
                  className="btn btn-primary mb-5"
                  color="primary"
                  type="submit"
                >
                 {Vendor_Settings_ReuploadModal?.Submit?.[language]} 
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export default ReDocUpload;
