import React ,{useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import { useState, useMemo } from "react";
// import TextField from "../../../components/Fields/TextField";
import { api } from "../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Logout from "./Logout";

export default function EmplanmentDetails() {
  const [data,setdata] = useState([])

  const empanellist = () => {
    api
      .get(`/vendor/empanelment_list`, true)
      .then(async function ([success, response]) {
        setdata(response)
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    }

  useEffect(()=>{
    empanellist()
  },[])
  return (
    <>
      <div className="login-content">
        <Form className="Reg-wrap">
          {/* <Row>
            <Col md={4} sm={6}>
              <FormGroup>
                <div className="emd-check-wrap">
                  <Input type="radio"></Input>
                  <Label>
                    For A category
                    <br />
                    <span>10 Crores — EMD 59,000/-</span>
                  </Label>
                </div>
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <FormGroup>
                <div className="emd-check-wrap">
                  <Input type="radio"></Input>
                  <Label>
                    For B category
                    <br />
                    <span>10 Crores — EMD 59,000/-</span>
                  </Label>
                </div>
              </FormGroup>
            </Col>
            <Col md={4} sm={6}>
              <FormGroup>
                <div className="emd-check-wrap">
                  <Input type="radio"></Input>
                  <Label>
                    For C category
                    <br />
                    <span>10 Crores — EMD 59,000/-</span>
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row> */}
          <Row>
            <Col md={12}>
              <div className="table-outer-wrap">
                {/* <h6 className="heading">History details</h6> */}
                <div className="history-table">
                  <Table className="w-100">
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Emp category</th>
                        <th>Valid from</th>
                        <th>Valid upto</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      data.length > 0 ?
                      
                      data.map((item:any,index:number)=>{
                        return(
                          <tr>
                            <td>{index+1}</td>
                            <td>{item?.products?.name}</td>
                            <td>{item?.from_date}</td>
                            <td>{item?.to_date}</td>
                            <td className={item?.status == 1 ? "primary-text" : "text-danger"}>{item?.status == 1 ? "active" : "inactive"}</td>
                          </tr>

                        )
                      })
                    :
                    <tr>
                    <td
                      className="text-center px-2 py-5 empty-text"
                      colSpan={7}
                    >
                     
                      No Data Found
              
                    </td>
                  </tr>

                    }
                      
                  
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col sm={12}>
              <div className="agree-checkbox">
                <FormGroup>
                  <div className="agree-checkbox-wrap">
                    <Input type="checkbox" className="form-check-input"></Input>
                    <Label>Make sure entered details are correct</Label>
                  </div>
                </FormGroup>
              </div>
            </Col>
            <Col sm={12}>
              <Button class="btn btn-primary" color="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row> */}
        </Form>
      </div>
    </>
  );
}
