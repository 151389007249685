import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  UncontrolledAccordion,
  AccordionHeader,
  AccordionItem, Card,
  CardBody,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  List,
  Row
} from "reactstrap";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import { Context } from "../../../Components/Context/Context";
import { DepartmentProducts } from "../../../Library/LanguageConversion/Department_Products";

export default function DeptProductList() {

  const {
    searchVal,
    setSearchVal,
    catFilterVal,
    setCatFilterVal,
    specSelectedVal,
    setSpecSelectedVal,
    resetFilterHandler,
    language
  } = useContext(Context);

  const [isLoading, setIsLoading] = useState<boolean>()
  const [productData, setProductData] = useState<any>([])
  const [pageData, setPageData] = useState<any>()
  const [spec, setSpec] = useState<any>("")
  const [category, setCategory] = useState<any>()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [open, setOpen] = useState('1');
  const [isError, setIsError] = useState<boolean>(false)
  const page_no = useParams().page_no
  const [sortVal, setSortVal] = useState<1 | 2>(1) //0-default, Price - 1-Hight to low, 2- low to high
  // const [accordionOpen, setaccordionOpen]=useState('')

  const navigate = useNavigate()

  useEffect(() => {
    getProductList()
    getFilterData()
  }, [page_no, sortVal])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchVal !== null || searchVal === "") {
        navigate('/department/products/' + page_no)
        getProductList()
      }
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [searchVal, catFilterVal, specSelectedVal]);

  const toggle2 = (id: string) => {
    if (open === id) {
      setOpen(id);
    } else {
      setOpen(id);
    }
  };
// const accordiontoggle = (id: string)=>{
//   if(accordionOpen === id){
//     setaccordionOpen(''); 
//   }
//   else{
//     setaccordionOpen(id)
//   }
// }
  // pagination handler
  const handlePageChange = (pageno: number) => {
    navigate('/department/products/' + pageno)
  }

  // products data fetching
  const getProductList = () => {
    setIsLoading(true);
    api
      .get(`/product/products?search_key=name&ratefilter=${sortVal}&search_value=${searchVal}&page=${page_no}&${catFilterVal?.length !== 0 ? `category=[${catFilterVal}` : ''}]&${specSelectedVal.length !== 0 ? `spec[]=${[specSelectedVal]}` : ''}
  `, true)
      .then(async function ([success, response]) {
        setProductData(response?.data?.filter((itm: any) => {
          return itm.rate.length !== 0
        }) || []);
        setPageData(response.meta)
        setIsLoading(false);
        setIsError(false)
        return response;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setIsError(true)
      });
  };

  // Get Specifications for filter
  const getFilterData = () => {
    api.get(`/product/categories`, true)
      .then(async function ([success, response]) {
        setCategory(response.data)
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    api.get(`/product/collectmetaspecifications`, true)
      .then(async function ([success, response]) {
        setSpec(response)
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCheck = (obj: any) => {
    setCatFilterVal([...catFilterVal, obj.id])
    navigate('/department/products/1')
  }
  const onUnCheck = (obj: any) => {
    setCatFilterVal(catFilterVal.filter((it: any) => it !== obj.id))
    navigate('/department/products/1')
  }

  return (
    <>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumbs */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/department/dashboard">
              {DepartmentProducts?.Dashboard?.[language]}             
               </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
              {DepartmentProducts?.Products?.[language]}
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <Row className="gy-4">
            <Col lg={3} className="desktop-filter">
              <div className="fiter-outer-wrap">
                <div className="filter-inner">
                  <h5 className="heading">{DepartmentProducts?.Filter[language]}</h5>

                  {/* Reset filters  */}
                  {(catFilterVal?.length !== 0 || specSelectedVal?.length !== 0 || searchVal?.length !== 0) &&
                    <Link to='' className="green-text flex-anchor end" title="Reset All" onClick={() => {
                      setSortVal(1)
                      resetFilterHandler()
                    }}>

                      <i className="icons icons-reset"></i>
                    </Link>}

                </div>
                <hr />
                <UncontrolledAccordion >

                  {/* {(catFilterVal?.length !== 0 || specSelectedVal?.length !== 0) && <Link to='' className="green-text flex-anchor end" onClick={resetFilterHandler}>
                    <button type="reset" className="btn">
                      <i className="icons icons-reset"></i>
                    </button>          
                  </Link>} */}

                  {/* category filter  */}
                  {category?.length > 0 && <List className="first-list">
                    <li>
                      <AccordionItem>
                        <AccordionHeader targetId="1" >
                          <h6>{DepartmentProducts?.Category[language]}</h6>
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <div className="filter-inner-items">
                            {
                              category?.map((obj: any, i: number) => {
                                return (
                                  <div className="item" key={i}>
                                    <Input type='checkbox' checked={catFilterVal.includes(obj.id)} id={`cat${i} `}
                                      onChange={(e: any) => e.target.checked ? onCheck(obj) : onUnCheck(obj)} ></Input>
                                    <Label htmlFor={`cat${i} `}>{obj.name}</Label>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    </li>
                  </List>}

                  {/* Filter by price  */}
                  <List className="first-list">
                    <li>
                      <AccordionItem >
                        <AccordionHeader targetId="3">
                          <h6>{DepartmentProducts?.Price[language]}</h6>
                        </AccordionHeader>
                        <AccordionBody accordionId="3">
                          <div className="filter-inner-items">

                            <div className="item" >
                              <Input
                                type='radio'
                                id="lth"
                                name="radioOption"
                                checked={sortVal == 1}
                                onClick={(e: any) => {
                                  if (e?.target?.checked) {
                                    setSortVal(1)
                                  }

                                }}

                              ></Input>
                              <Label htmlFor="lth" onClick={() => setSortVal(1)}>{DepartmentProducts?.High_to_low[language]}</Label>
                            </div>

                            <div className="item" >
                              <Input
                                type='radio'
                                id="htl"
                                name="radioOption"
                                onClick={(e: any) => {
                                  if (e?.target?.checked) {
                                    setSortVal(2)
                                  }
                                }}
                              // checked={sortVal == 2}
                              ></Input>
                              <Label htmlFor="htl">{DepartmentProducts?.Low_to_High[language]}</Label>
                            </div>

                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    </li>
                  </List>

                  {/* specifications filter  */}
                  {spec?.length > 0 && <List className="loop-list">
                    {
                      spec?.map((obj: any, i: number) => {
                        return (
                          <React.Fragment key={i} >
                            {obj.children.length > 0 &&
                              <li className="items" >
                                <AccordionItem>
                                  <AccordionHeader targetId={obj.name} key={i}>
                                    <h6>{obj.name}</h6>
                                  </AccordionHeader>
                                  <AccordionBody accordionId={obj.name}>
                                    <div className="filter-inner-items">
                                      {obj?.children?.map((child: any, index: number) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <div className="item">
                                              <Input type='checkbox' checked={specSelectedVal?.includes(child.id)} id={`${child.name}${index} -${index} `}
                                                onChange={(e: any) => e.target.checked ? setSpecSelectedVal([...specSelectedVal, child.id]) :
                                                  setSpecSelectedVal(specSelectedVal.filter((item: any) => {
                                                    return item !== child.id
                                                  }))}></Input>
                                              <Label htmlFor={`${child.name}${index} -${index} `}>{child.name}</Label>
                                            </div>
                                          </React.Fragment>
                                        )
                                      })}
                                    </div>
                                  </AccordionBody>
                                </AccordionItem>
                              </li>}
                          </React.Fragment>
                        )
                      })
                    }
                  </List>}
                </UncontrolledAccordion>
              </div>
            </Col>
            <Col lg={9}>
              <div className="product-list-outer-wrap">
                <div className="search-widget-outer">
                  <h5 className="heading">
                  {DepartmentProducts?.Product_List?.[language]}
                  </h5>
                  <Form role="search" className="search">
                    <InputGroup className="search-group">
                      <InputGroupText title="Product Name" className="search-icon" id="search" >
                        <i className="fa-sharp fa-solid fa-magnifying-glass" />
                      </InputGroupText>
                      <Input
                        type="text"
                        value={searchVal}
                        onChange={(e: any) => {
                          navigate('/department/products/1')
                          setSearchVal(e.target.value)
                        }}
                        placeholder= {DepartmentProducts?.Product_Name?.[language]}
                        aria-label="Username"
                        aria-describedby="search"
                      />
                    </InputGroup>

                    {/* mobile-filter */}
                    <div className="filter-btn mobile-filter">
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                        <DropdownToggle color="primary" className="bi bi-funnel" title="Filter">
                          {/* <i className="bi bi-funnel me-3"></i> */}
                          {/* Filter */}
                        </DropdownToggle>
                        <DropdownMenu >
                          <Accordion open={open} >
                            {category?.length > 0 && <AccordionItem >
                              <AccordionHeader targetId="1"><i className="bi bi-list-task me-2" />{DepartmentProducts?.Category?.[language]}</AccordionHeader>
                              <AccordionBody accordionId="1">
                                <div className="filter-inner-items">
                                  {
                                    category?.map((obj: any, i: number) => {
                                      return (
                                        <div className="item" key={i}>
                                          <Input type='checkbox' checked={catFilterVal.includes(obj.id)} id={`cat${i} `} onChange={(e: any) => e.target.checked ? setCatFilterVal([...catFilterVal, obj.id]) : setCatFilterVal(catFilterVal.filter((it: number) => it !== obj.id))} ></Input>
                                          <Label htmlFor={`cat${i} `}>{obj.name}</Label>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </AccordionBody>
                            </AccordionItem>}
                            {spec?.length > 0 &&
                              <>
                                {spec?.map((obj: any, i: number) => {
                                  return (
                                    <React.Fragment key={i} >
                                      <AccordionItem>
                                        {obj.children.length > 0 &&
                                          <div className="item" key={i}>
                                            <AccordionHeader targetId='2'><i className="bi bi-list-task me-2" />{obj?.name}</AccordionHeader>
                                            <AccordionBody accordionId='2'>
                                              <div className="filter-inner-items">
                                                {
                                                  obj?.children?.map((child: any, index: number) => {
                                                    return (
                                                      <React.Fragment key={index}>
                                                        <div className="item">
                                                          <Input type='checkbox' checked={specSelectedVal.includes(child?.id)} id={`${child?.name}${index} -${index} `}
                                                            onChange={
                                                              (e: any) => e.target.checked ? setSpecSelectedVal([...specSelectedVal, child?.id]) :
                                                                setSpecSelectedVal(specSelectedVal?.filter((item: any) => {
                                                                  return item !== child.id
                                                                }))
                                                            }></Input>
                                                          <Label htmlFor={`${child.name}${index} -${index} `}>{child.name}</Label>
                                                        </div>
                                                      </React.Fragment>
                                                    )
                                                  })
                                                }
                                              </div>
                                            </AccordionBody>
                                          </div>}
                                      </AccordionItem>
                                    </React.Fragment>
                                  )
                                })
                                }
                              </>}
                          </Accordion>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Form>
                </div>
                <hr />

                {/* Product card-wrap */}
                <div className="product-list-outer">
                  <Row className="gy-2 gx-2">
                    {!isLoading ? isError ? <p>{DepartmentProducts?.Unexpected_error_occured?.[language]}</p> : <>
                      {
                        productData.length > 0 ? productData?.map((prod: any, i: number) => {
                          console.log();

                          return (
                            <React.Fragment key={i}>
                              <Col lg={4} md={6} sm={6}>
                                <Card className="product-list-inner-wrap">
                                  <Link
                                    to={`/department/products-view/${prod.id}`}
                                  >
                                    <CardBody title="View Product">
                                      <div className="product-thumb">
                                        <img
                                          src={prod.images[0] ? prod.images[0]?.path : "https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg"}
                                          alt="img" />
                                      </div>
                                      <div className="product-details">
                                        <h6 title={prod?.name}>{prod?.name}</h6>
                                        <h6 title={prod?.price}>
                                          <i className="bi bi-currency-rupee"></i>
                                          {/* {prod?.rate[0].price} */}
                                          {(prod?.rate[0]?.price + (prod?.rate[0]?.price * 0.05))?.toFixed(2)}

                                          <small>&nbsp; + {DepartmentProducts?.GST?.[language]} </small>
                                        </h6>
                                        <small
                                          dangerouslySetInnerHTML={{
                                            __html: prod?.features,
                                          }}></small>
                                      </div>
                                    </CardBody>
                                  </Link>
                                </Card>
                              </Col>
                            </React.Fragment>
                          )
                        })
                          :
                          <>
                            <div className="d-flex justify-content-center py-3">
                              <p>{DepartmentProducts?.No_Products_Found?.[language]}</p>
                            </div>
                          </>
                      }
                    </>
                      :
                      <>
                        <div className="d-flex justify-content-center">
                          <Loader />
                        </div>
                      </>
                    }
                  </Row>
                </div>

                {/* pagination  */}
                {pageData && productData.length > 0 && !isLoading && <div className="table-footer-wrap">
                  <small>{DepartmentProducts?.Showing[language]}{pageData.from}{DepartmentProducts?.to[language]}{pageData.to} {DepartmentProducts?.of[language]} {pageData?.total}{DepartmentProducts?.entries[language]}</small>
                  <div className="pagination-outer">
                    <CustomPagination
                      totalItems={pageData?.total}
                      itemsPerPage={pageData?.per_page}
                      activePage={pageData.current_page}
                      setCurrentPage={handlePageChange}
                    />
                  </div>
                </div>}
              </div>
            </Col>
          </Row >
        </div >
      </div >
    </>
  );
}

