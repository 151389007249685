import React, { createContext, useState } from 'react'


interface MyContextType {
  language: string;
  setLanguage: (language: string) => void;
}

const LandingPageContext: any = createContext<MyContextType | undefined>(undefined);

const LandingPageContextProvider = (props: any) => {

  const [language, setLanguage] = useState('english')

  const setLanguageFunction = (lang: string) => {
    setLanguage(lang)
  }

  return (
    <LandingPageContext.Provider value={{ language, setLanguageFunction }}>
      {props?.children}
    </LandingPageContext.Provider>
  )
}

export { LandingPageContextProvider, LandingPageContext }