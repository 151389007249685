import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import TextError from "../../../../Components/Errors/TextError";
import { api } from "../../../../Library/CustomApi";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Context } from "../../../../Components/Context/Context";
import { Admin_RateContract } from "../../../../Library/LanguageConversion/Admin_RateContract";
import ManufactureValidationSchema from "../Validation/ManufactureValidation";
import Manufacture from "../Modal/Manufacture";
import ModalContainer from "../../../../Components/Modal/ModalContainer";

const AssignModal = (props: any) => {
  const {language, setLanguage} = useContext(Context)
  const [model, setModel] = useState([]);
  const [selectedv1, setSelectV1] = useState("");
  const [selectedv2, setSelectV2] = useState("");
  const [selectedv3, setSelectV3] = useState("");
  const [open, setOpen] = useState(false);
  const [low_1data, setLow1Data] = useState([]);
  // const [low_2data, setLow2Data] = useState([]);
  // const [low_3data, setLow3Data] = useState([]);

  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions);
  };

  const sendData = (values: any, actions: any) => {
    props?.setCall(false);
    api
      .post(
        `/product/manufacturermodel`,
        {
          product_id: props?.pro_id,
          manufacture_name: values?.make_new,
          model_name: values?.model_new,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Make & Model Created Successfully");
          setOpen(false);
          actions.resetForm();
          actions.setSubmitting(false);
          props?.setCall(true);
        }
        return response;
      })
      .catch((err) => {
        actions.setSubmitting(false);
        if (err?.response?.status == 500) {
          toast.error(err?.response?.data?.message);
        }
        console.log(err);
      });
  };

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  useEffect(() => {
    if (props?.values?.low_1_make?.length !== 0) {
      getModel(props?.values?.low_1_make?.value);
    }
    if (props?.values?.low_1_make == null) {
      props?.setFieldValue("low_1_model", "");
    }
  }, [props?.values?.low_1_make]);

  useEffect(() => {
    if (props?.values?.low_2_make?.length !== 0) {
      getModel(props?.values?.low_2_make?.value);
    }
    if (props?.values?.low_2_make == null) {
      props?.setFieldValue("low_2_model", "");
    }
  }, [props?.values?.low_2_make]);

  useEffect(() => {
    if (props?.values?.low_3_make?.length !== 0) {
      getModel(props?.values?.low_3_make?.value);
    }
    if (props?.values?.low_3_make == null) {
      props?.setFieldValue("low_3_model", "");
    }
  }, [props?.values?.low_3_make]);

  const getModel = (id: any) => {
    api
      .post(
        `/ratecontractmodels`,
        {
          manufacturer_id: id,
        },
        true
      )
      .then(async function ([success, response]) {
        let datas: any = [];
        response?.data?.map((elem: any) => {
          datas.push({ value: elem?.id, label: elem?.name });
        });
        setModel(datas || []);
        return response;
      })
      .catch((err) => {

        console.log(err);
      });
  };
  const toggle = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header='Assign Vendors'
      >
        <div className="add-vendor-outer">
          <div className="vendor-details">
            <h3 className="valid-date">
              <span><strong> {Admin_RateContract?.Valid_From[language]}: </strong>
                {moment(props?.date?.validfrom).format("DD-MM-YYYY")}{""} {" "} &nbsp;-&nbsp;
                {""} </span>
              <span><strong> {Admin_RateContract?.Valid_To[language]}: </strong>{moment(props?.date?.validto).format("DD-MM-YYYY")} </span>
            </h3>
          </div>
          <div className="add-make-text">
            <Col md={12}>
              <FormGroup>
                <Link to="" title="Add new make here" onClick={(e) => toggle(e)} className="primary-text">
                  {Admin_RateContract?.Para3[language]}
                </Link>
              </FormGroup>
            </Col>
          </div>

          <Form className="form-wrap">
            <Row>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>
                    {Admin_RateContract?.Low_1[language]}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>

                  <Select
                    name="low_1"
                    options={props?.vendors?.filter(
                      (item: any) =>
                        item !== selectedv2 && item !== selectedv3
                    )}
                    value={props?.values?.low_1}
                    onChange={(e: any) => {
                      setSelectV1(e);
                      props?.setFieldValue("low_1", e);
                    }}
                    className={` ${props?.touched.low_1 && props?.errors.low_1
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching Vendor"}
                    noOptionsMessage={() => "Vendor appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="low_1" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>
                    {Admin_RateContract?.Low_1_Make[language]}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Select
                    name="low_1_make"
                    options={props?.make}
                    value={props?.values?.low_1_make}
                    onChange={(e) => {
                      setLow1Data(e);
                      props?.setFieldValue("low_1_model", "");
                      props?.setFieldValue("low_1_make", e);
                    }}
                    className={` ${props?.touched.low_1_make && props?.errors.low_1_make
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching low 1 make"}
                    noOptionsMessage={() => "Low 1 make appears here"}
                    isSearchable={true}
                    isClearable
                  />

                  <ErrorMessage name="low_1_make" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>
                    {Admin_RateContract?.Low_1_Model[language]}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Select
                    name="low_1_model"
                    options={model}
                    value={props?.values?.low_1_model}
                    onChange={(e) => props?.setFieldValue("low_1_model", e)}
                    className={` ${props?.touched.low_1_model && props?.errors.low_1_model
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching low 1 model"}
                    noOptionsMessage={() => "Low 1 model appears here"}
                    isSearchable={true}
                    isClearable
                  />

                  <ErrorMessage name="low_1_model" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>{Admin_RateContract?.Low_2[language]}</Label>
                  <Select
                    name="low_2"
                    options={props?.vendors?.filter(
                      (item: any) =>
                        item !== selectedv1 && item !== selectedv3
                    )}
                    value={props?.values?.low_2}
                    onChange={(e) => {
                      setSelectV2(e);
                      props?.setFieldValue("low_2_model", "");
                      props?.setFieldValue("low_2_make", "");
                      props?.setFieldValue("low_2", e);
                    }}
                    className={` ${props?.touched.low_2 && props?.errors.low_2
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching vendors"}
                    noOptionsMessage={() => "vendors appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="low_2" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>{Admin_RateContract?.Low_2_Make[language]}</Label>
                  <Select
                    name="low_2_make"
                    options={props?.make}
                    value={props?.values?.low_2_make}
                    isDisabled={props?.values?.low_2 ? false : true}
                    onChange={(e) => {
                      setLow1Data(e);
                      props?.setFieldValue("low_2_model", "");
                      props?.setFieldValue("low_2_make", e);
                    }}
                    className={` ${props?.touched.low_2_make && props?.errors.low_2_make
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching Low 2 make"}
                    noOptionsMessage={() => "Low 2 make appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="low_2_make" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>{Admin_RateContract?.Low_2_Model[language]}</Label>
                  <Select
                    name="low_2_model"
                    options={model}
                    value={props?.values?.low_2_model}
                    isDisabled={props?.values?.low_2 ? false : true}
                    onChange={(e) => props?.setFieldValue("low_2_model", e)}
                    className={` ${props?.touched.low_2_make && props?.errors.low_2_model
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching Low 2 model"}
                    noOptionsMessage={() => "Low 2 model appears here"}
                    isSearchable={true}
                    isClearable
                  />

                  <ErrorMessage name="low_2_model" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>{Admin_RateContract?.Low_3[language]}</Label>
                  <Select
                    name="low_3"
                    options={props?.vendors?.filter(
                      (item: any) =>
                        item !== selectedv2 && item !== selectedv1
                    )}
                    value={props?.values?.low_3}
                    onChange={(e) => {
                      setSelectV3(e);
                      props?.setFieldValue("low_3_model", "");
                      props?.setFieldValue("low_3_make", "");
                      props?.setFieldValue("low_3", e);
                    }}
                    className={` ${props?.touched.low_3 && props?.errors.low_3
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching vendors"}
                    noOptionsMessage={() => "vendors appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="low_3" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>{Admin_RateContract?.Low_3_Make[language]}</Label>
                  <Select
                    name="low_3_make"
                    options={props?.make}
                    value={props?.values?.low_3_make}
                    isDisabled={props?.values?.low_3 ? false : true}
                    onChange={(e) => {
                      setLow1Data(e);
                      props?.setFieldValue("low_3_model", "");
                      props?.setFieldValue("low_3_make", e);
                    }}
                    className={` ${props?.touched.low_3_make && props?.errors.low_3_make
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching low 3 make"}
                    noOptionsMessage={() => "Low 3 make appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="low_3_make" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={4} sm={6}>
                <FormGroup>
                  <Label>{Admin_RateContract?.Low_3_Model[language]}</Label>
                  <Select
                    name="low_3_model"
                    options={model}
                    value={props?.values?.low_3_model}
                    isDisabled={props?.values?.low_3 ? false : true}
                    onChange={(e) => {
                      props?.setFieldValue("low_3_model", e);
                    }}
                    className={` ${props?.touched.low_3_model && props?.errors.low_3_model
                      ? "is-invalid"
                      : ""
                      }`}
                    isLoading={false}
                    loadingMessage={() => "Fetching low 3 model"}
                    noOptionsMessage={() => "Low 3 model appears here"}
                    isSearchable={true}
                    isClearable
                  />
                  <ErrorMessage name="low_3_model" component={TextError} />
                </FormGroup>
              </Col>
              <Row>
                <Col md={4} sm={6}>
                  <FormGroup>
                    <Label>
                      {Admin_RateContract?.Service_Warranty[language]}{" "}
                      <i
                        className="fa fa-asterisk fa-1 required-field"
                        color="text-danger"
                        aria-hidden="true"
                      ></i>
                    </Label>
                    <Input
                      name="service_warranty"
                      type="number"
                      placeholder="Months"
                      min="0"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) &&
                        e.preventDefault()
                      }
                      value={props?.values?.service_warranty}
                      onChange={props?.handleChange}
                      className={`form-control ${props?.touched.service_warranty &&
                        props?.errors.service_warranty
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <ErrorMessage
                      name="service_warranty"
                      component={TextError}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} sm={6}>
                  <FormGroup>
                    <Label>
                      {Admin_RateContract?.Hardware_Warranty[language]}{" "}
                      <i
                        className="fa fa-asterisk fa-1 required-field"
                        color="text-danger"
                        aria-hidden="true"
                      ></i>
                    </Label>
                    <Input
                      name="hardware_warranty"
                      type="number"
                      placeholder="Months"
                      min="0"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) &&
                        e.preventDefault()
                      }
                      value={props?.values?.hardware_warranty}
                      onChange={props?.handleChange}
                      className={`form-control ${props?.touched.hardware_warranty &&
                        props?.errors.hardware_warranty
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <ErrorMessage
                      name="hardware_warranty"
                      component={TextError}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} sm={6}>
                  <FormGroup>
                    <Label>
                      {Admin_RateContract?.On_Site_Warranty[language]}{" "}
                      <i
                        className="fa fa-asterisk fa-1 required-field"
                        color="text-danger"
                        aria-hidden="true"
                      ></i>
                    </Label>
                    <Input
                      name="Onsite_warranty"
                      type="number"
                      placeholder="Months"
                      min="0"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) &&
                        e.preventDefault()
                      }
                      value={props?.values?.Onsite_warranty}
                      onChange={props?.handleChange}
                      className={`form-control ${props?.touched.Onsite_warranty &&
                        props?.errors.Onsite_warranty
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <ErrorMessage
                      name="Onsite_warranty"
                      component={TextError}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Col md={6} sm={6}>
                <FormGroup>
                  <Label>{Admin_RateContract?.Valid_From[language]}</Label>
                  <Input
                    label="Valid From"
                    name="valid_from"
                    type="date"
                    value={props?.date?.validfrom}
                    onChange={props?.handleChange}
                    className={`form-control ${props?.touched.valid_from && props?.errors.valid_from
                      ? "is-invalid"
                      : ""
                      }`}
                    disabled={true}
                  />
                  <ErrorMessage name="valid_from" component={TextError} />
                </FormGroup>
              </Col>

              <Col md={6} sm={6}>
                <FormGroup>
                  <Label>{Admin_RateContract?.Valid_To[language]}</Label>
                  <Input
                    label="valid To"
                    name="valid_to"
                    type="date"
                    value={props?.date?.validto}
                    onChange={props?.handleChange}
                    className={`form-control ${props?.touched.valid_to && props?.errors.valid_to
                      ? "is-invalid"
                      : ""
                      }`}
                    disabled={true}
                  />
                  <ErrorMessage name="valid_to" component={TextError} />
                </FormGroup>
              </Col>

              <Col md={6} sm={6}>
                <FormGroup>
                  <Label>
                    {Admin_RateContract?.Price[language]}
                    <i
                      className="fa fa-asterisk fa-1 required-field"
                      color="text-danger"
                      aria-hidden="true"
                    ></i>
                  </Label>
                  <Input
                    name="price"
                    label="Price"
                    placeholder="Rupees"
                    type="number"
                    value={props?.values?.price}
                    onChange={props?.handleChange}
                    className={`form-control ${props?.touched.price && props?.errors.price
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  <ErrorMessage name="price" component={TextError} />
                </FormGroup>
              </Col>
              <Col md={12} className="footer-sticky">
                <FormGroup className={"text-end"}>
                  <Button
                    color="primary"
                    className={"px-5"}
                    onClick={props?.handleSubmit}
                    disabled={props?.isSubmitting}
                  >
                    {Admin_RateContract?.Assign[language]}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalContainer>
      <Formik
        initialValues={{
          make_new: "",
          model_new: "",
        }}
        validationSchema={ManufactureValidationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          handleBlur,
          setFieldValue,
        }) => {
          return (
            <Manufacture
              open={open}
              setOpen={setOpen}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleReset={handleReset}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default AssignModal;
