import React, { useEffect, useContext} from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { DOCS_URL } from "../../../Library/ApiSettings";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import DatePicker from "react-date-picker";
import { preventNegativeValues } from "../../../Library/Constants/helpers";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
const DOCUMENT_URL = DOCS_URL;

export default function LandDocument(props: any) {
  const {language,setLanguage}=useContext(Context);
  return (
    <>
      {" "}
      <div className="company-information-update-wrap">
        <h4>{Vendor_profileUpdation?.HR_Documents?.[language]}</h4>
        <hr />

        <Row>
          <Col md={4} sm={6}>
            <FormGroup>
              <Label>
              {Vendor_profileUpdation?.Land_Document?.[language]}{" "}
                <span className="text-danger" style={{ fontSize: "18px" }}>
                  *
                </span>
              </Label>
              <div className="image-input">
                {props?.list <= 0 ? (
                  <>
                    {" "}
                    <input
                      type="file"
                      ref={props?.oemRef}
                      id="land_document"
                      name="land_document"
                      className="form-control"
                      accept=".pdf"
                      onChange={(e: any) =>
                        props?.handleFileChange(e, e.target.files[0])
                      }
                    ></input>
                    <Label
                      className="image-button"
                      for="land_document"
                      id="land_document"
                    >
                      {Vendor_profileUpdation?.Choose_Documents?.[language]}
                    </Label>
                  </>
                ) : (
                  <>
                    <InputGroup>
                      <input
                        id="land_document"
                        name="land_document"
                        className="form-control"
                        accept=".pdf"
                        value={props?.datas?.land_document}
                        disabled={props?.datas?.land_document ? true : false}
                      ></input>
                      <InputGroupText
                        className="input-group-text bg-white border-start-0"
                        id="newpass"
                      >
                        <a
                          href={`${DOCUMENT_URL}` + props?.datas?.land_document}
                          download
                          target="_blank"
                        >
                          {" "}
                          <i className="bi bi-box-arrow-down"></i>
                        </a>
                      </InputGroupText>
                    </InputGroup>
                  </>
                )}
              </div>
              <ErrorMessage name="land_document" component={TextError} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Total_Area?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="land_village_col"
                    type="text"
                    value={props?.values?.land_village_col}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="land_village_col"
                    type="text"
                    value={props?.values?.land_village_col}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="land_village_col" component={TextError} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Extent?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="land_extent"
                    type="text"
                    value={props?.values?.land_extent}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="land_extent"
                    type="text"
                    value={props?.values?.land_extent}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="land_extent" component={TextError} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Value?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="land_value"
                    type="number"
                    value={props?.values?.land_value}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="land_value"
                    type="text"
                    value={props?.values?.land_value}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="land_value" component={TextError} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Survey_No?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                {props?.list <= 0 ? (
                  <Input
                    name="land_survey_no"
                    type="text"
                    value={props?.values?.land_survey_no}
                    onChange={props?.handleChange}
                  />
                ) : (
                  <Input
                    name="land_survey_no"
                    type="text"
                    value={props?.values?.land_survey_no}
                    onChange={props?.handleChange}
                    disabled
                  />
                )}
              </div>
              <ErrorMessage name="land_survey_no" component={TextError} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
