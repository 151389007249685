import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  vendor_type: Yup.string().required("Vendor type is required"),
  gst_class: Yup.string().required("GST class is required"),
  gst_number: Yup.string()
    .required("GST number is required")
    .min(15, "GST Number should be atleast 15 characters")
    .max(15, "GST Number should not be more than 15 characters"),

  pan_number: Yup.string()
    .required("PAN number is required")
    .min(10, "PAN Number should be atleast 10 characters")
    .max(10, "PAN Number should not be more than 10 characters"),
  email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),
  registration_id: Yup.string().required("Registration number is required"),
  company_type: Yup.string().required("Company Type is required"),
  contact_name: Yup.string().required("Contact name is required"),
  company_name: Yup.string().required("Company name is required"),
  checked: Yup.string().required('Required'),
  comp_inc_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Date is required"),
});

const ValidationSchemaone = Yup.object().shape({
  // vendor_type: Yup.string().required("Vendor type is required"),
  gst_class: Yup.string().required("GST class is required"),
  gst_number: Yup.string()
    .required("GST number is required")
    .min(15, "GST Number should be atleast 15 characters")
    .max(15, "GST Number should not be more than 15 characters"),

  pan_number: Yup.string()
    .required("PAN number is required")
    .min(10, "PAN Number should be atleast 10 characters")
    .max(10, "PAN Number should not be more than 10 characters"),
  email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
    .min(10, "Phone number must be at least 10 digits"),
  registration_id: Yup.string().required("Registration number is required"),
  company_type: Yup.string().required("Company Type is required"),
  contact_name: Yup.string().required("Contact name is required"),
  company_name: Yup.string().required("Company name is required"),
  comp_inc_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      // const result = parent(originalValue, "dd.MM.yyyy", new Date());
      // return result;
    })
    .typeError("please enter a valid date")
    .required("Date required"),
});

const ValidationSchematwo = Yup.object().shape({
  vendor_type: Yup.string().required("Vendor type is required"),
  gst_class: Yup.string().required("GST class is required"),
  gst_number: Yup.string()
    .required("GST number is required")
    .min(15, "GST Number should be atleast 15 characters")
    .max(15, "GST Number should not be more than 15 characters"),

  pan_number: Yup.string()
    .required("PAN number is required")
    .min(10, "PAN Number should be atleast 10 characters")
    .max(10, "PAN Number should not be more than 10 characters"),
  email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
    .min(10, "Phone number must be at least 10 digits"),
  registration_id: Yup.string().required("Registration number is required"),
  // company_type: Yup.string().required("Company Type is required"),
  contact_name: Yup.string().required("Contact name is required"),
  company_name: Yup.string().required("Company name is required"),
  comp_inc_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      // const result = parent(originalValue, "dd.MM.yyyy", new Date());
      // return result;
    })
    .typeError("please enter a valid date")
    .required("Date required"),
});

export { ValidationSchema, ValidationSchemaone, ValidationSchematwo };
