import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { api } from "../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { LoginValidationSchema } from "./LoginValidation";
import { toast } from "react-toastify";
import TextError from "../../Components/Errors/TextError";
import { LoginViewRegLang } from "../../Library/LanguageConversion/LoginViewLang";
import { Context } from "../../Components/Context/Context";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";

function LoginView() {
  const navigate = useNavigate();
  const [password, setpassword] = useState<any>("password");
  const [eye, seteye] = useState(true);
  const [emd, setEmd] = useState();
  const { resetFilterHandler } = useContext(Context);
  const { language, setLanguage } = useContext(Context);
  const handleSubmit = (values: any, actions: any) => {
    sendLoginData(values, actions);
  };
  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };
  const [status, setStaus] = useState();

  useEffect(() => {    
    // product list filter clearing in department
    resetFilterHandler() 
    if (localStorage.getItem("AUTH_TOKEN") !== null) {
      if (localStorage.getItem("ROLE_ID") == "2") {
        navigate("/dashboard");
      }
      if (localStorage.getItem("ROLE_ID") == "3") {
        navigate("/department/dashboard");
      }
      if (localStorage.getItem("ROLE_ID") == "4") {
        navigate("/basic-details-view");
      }
      if (localStorage.getItem("ROLE_ID") == "5") {
        navigate("/qa-vendor/orders");
      }
      if (localStorage.getItem("ROLE_ID") == "6") {
        navigate("/store/landing");
      }
      if (localStorage.getItem("ROLE_ID") == "7") {
        navigate("/manager/landing");
      }
      if (localStorage.getItem("ROLE_ID") == "8") {
        navigate("/accountant/landing");
      }
      // if (localStorage.getItem("ROLE_ID") == "4") {
      //   //navigate("/basic-details-view");
      //   navigate("/overview-vendor");
      // }
    }
  }, []);
  
  const sendLoginData = (values: any, actions: any) => {
    api
      .post(
        "/login",
        {
          email_phone: values?.email_phone,
          password: values?.password,
          mode: "no-cors",
        },
        false
      )
      .then(async function ([success, response, status]: any) {
        if (status == 200) {
          let token = response?.message?.token;
          let user_name = response?.message?.name;
          let user_id = response?.message?.advanced_registration_details?.[0]?.user_id;
          let role_id = response?.message?.role;
          // let profile_status=response?.message?.profile_status
          let profile_status = 5;
          localStorage?.setItem("AUTH_TOKEN", token);
          localStorage?.setItem("USER_NAME", user_name);
          localStorage?.setItem("USER_ID", user_id);
          localStorage?.setItem("ROLE_ID", role_id);
          if (role_id == "2") {
            toast.success(`${LoginViewRegLang?.user_signed_in[language]}`);
            navigate("/dashboard", { replace: true });
          }
          if (role_id == "3") {
            toast.success(`${LoginViewRegLang?.user_signed_in[language]}`);
            navigate("/department/dashboard", { replace: true });
          }
          if (role_id == "4") {
            get_status();
          }
          if (role_id == "5") {
            toast.success(`${LoginViewRegLang?.user_signed_in[language]}`);
            navigate("/qa-vendor/orders", { replace: true });
          }
          if (role_id == "6") {
            toast.success("User Signed In");
            navigate("/store/landing", { replace: true });
          }
          if (role_id == "7") {
            toast.success("User Signed In");
            navigate("/manager/landing", { replace: true });
          }
          if (role_id == "8") {
            toast.success("User Signed In");
            navigate("/accountant/landing", { replace: true });
          }
          // if (localStorage?.getItem("ROLE_ID") == "4") {
          //   navigate("/document-upload");
          // }
        }
        actions.setSubmitting(false);
        return response;
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
            actions.setSubmitting(false);
          } else {
            toast.error(`${LoginViewRegLang?.credentials_not_match[language]}`);
            actions.setSubmitting(false);
          }
        }
        if (err?.response?.status == 500) {
          toast.error(`${LoginViewRegLang?.something_went_wrong[language]}`);
          actions.setSubmitting(false);
        }
        actions.setSubmitting(false);
      });
  };
  const get_status = () => {
    api
      .get(`/get_vendor_pstatus`, true)
      .then(async function ([success, response]) {
        let v: any = response?.vendor_data?.doccount;
        setStaus(v);

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .get(`/vendor/emd-history`, true)
      .then(async function ([success, response]) {
        let c = response?.data?.data?.length;
        setEmd(c);
        if (c == 0) {
          toast.success(`${LoginViewRegLang?.user_signed_in[language]}`);
          navigate("/basic-details-view", { replace: true });
        } else {
          toast.success(`${LoginViewRegLang?.user_signed_in[language]}`);
          navigate("/overview-vendor", { replace: true });
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="login-outer">
          <Row>
            {/* left side */}
            <Col lg={5} md={6} sm={12} className="ps-0 login-desktop-column">
              {/* login image wrap */}
              <div className="login-img-wrap flex-column">
                <img
                  src="../images/logo-white1.png"
                  alt="login-background"
                  className="px-5"
                />
                <p className="text-white text-center px-5 w-75">
                  {/* KEONICS focuses on setting up world-class infrastructure for
                  the electronics and IT related industries in the state */}
                  {LoginViewRegLang?.keonics_info[language]}
                </p> 
              </div>
            </Col>
            {/* Right side */}
            <Col lg={5} md={5} sm={12} className="m-auto">
              {/* login wrap */}
              <Col sm={12} className="ps-0 login-mobile-column">
                <div className="logo-mobile mb-4">
                  <img
                    src="images/mob-logo.png"
                    alt="login-background"
                    className="px-2"
                  />
                </div>
              </Col>
              <div className="login-wrap">
                <h1>{LoginViewRegLang?.login[language]}</h1>
                {/* <p>Enter your credentials to access the system</p> */}
                <p>{LoginViewRegLang?.credentials_info[language]}</p>
                <div className="login-content">
                  <Formik
                    validationSchema={LoginValidationSchema}
                    initialValues={{ email_phone: "", password: "" }}
                    onSubmit={(values, actions) => {
                      handleSubmit(values, actions);
                    }}
                  >
                    {({
                      errors,
                      values,
                      isSubmitting,
                      handleChange,
                      touched,
                    }) => (
                      <>
                        <Form>
                          <FormGroup>
                            <div className="custom-input">
                              <Label>{LoginViewRegLang?.email_or_phone_number[language]}</Label>
                              <Input
                                name="email_phone"
                                type="text"
                                placeholder={`${LoginViewRegLang?.email_or_phone_number[language]}`}
                                value={values?.email_phone}
                                className={`form-control ${touched.email_phone && errors.email_phone
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="email_phone"
                                component={TextError}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="custom-input">
                              <Label>{LoginViewRegLang?.password[language]}</Label>
                              <InputGroup>
                                <Input
                                  name="password"
                                  type={password}
                                  placeholder={`${LoginViewRegLang?.password[language]}`}
                                  value={values?.password}
                                  aria-describedby="newpass"
                                  className={`form-control ${touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  onChange={handleChange}
                                />
                                <InputGroupText
                                  className="input-group-text bg-white border-start-0"
                                  id="newpass"
                                >
                                  <i
                                    onClick={Eye}
                                    className={`bi ${eye ? "bi-eye" : "bi-eye-slash"
                                      }`}
                                  />
                                </InputGroupText>
                              </InputGroup>
                              <ErrorMessage
                                name="password"
                                component={TextError}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="forgot-password">
                              <Link
                                title={`${LoginViewRegLang?.forgot_your_password[language]}`}
                                to="/forgot-password"
                                className="text-decoration-none"
                              >
                                {" "}
                                {LoginViewRegLang?.forgot_your_password[language]}
                              </Link>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="login-button pt-2 my-4">
                              <Button
                                color="primary"
                                className={"px-5"}
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                ) : (
                                  // "Sign in" 
                                  `${LoginViewRegLang?.sign_in[language]}`
                                )}
                              </Button>
                            </div>
                          </FormGroup>
                        </Form>
                      </>
                    )}
                  </Formik>
                    {/* <p className="signup-text">
                      Don't you have an account?
                      <Link title="Department Sign Up" className="ms-2" to="/department/signup">
                        Department Sign Up
                      </Link>{" "}
                      {"  "}
                      <Link title="Vendor Sign Up" className="ms-2" to="/vendor/signup">
                        Vendor Sign Up
                      </Link>
                    </p>           */}
                    <Col lg={12}>
                      <div className="signup-text-outer">
                        <p className="signup-text">
                          {LoginViewRegLang?.dont_you_have_an_account[language]}
                        </p>
                        <p className="signup-text">
                          <Link className="ms-2" to="/department/signup">
                            {LoginViewRegLang?.dept_sign_up[language]}
                          </Link>{" "}{"  "}
                        </p>
                        <p className="signup-text">
                          <Link className="ms-2" to="/vendor/signup">
                            {LoginViewRegLang?.vendor_sign_up[language]}
                          </Link>
                        </p>
                      </div>
                      <div className="language-btn-group">
                        <Link to="" className="" onClick={()=>setLanguage('en')}>English</Link>
                        <div className="divider"></div>
                        <Link to="" onClick={() => setLanguage('ka')}>ಕನ್ನಡ</Link>
                      </div>
                    </Col>     
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default LoginView;