import * as Yup from "yup";
const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/svg",
  "image/jpg",
  "image/gif",
  "image/webp",
];
const UploadValidationSchema = Yup.object().shape({
  pan: Yup.string().required("Field is required"),
  incorporation_certificate: Yup.string().required("Field is required"),
  address_proof: Yup.string().required("Field is required"),
  mse_certificate: Yup.string().required("Field is required"),
  gst_certificate: Yup.string().required("Field is required"),
  Ffinancial_year_amount: Yup.number().required("Field is required").nullable(),
  Sfinancial_year_amount: Yup.number().required("Field is required").nullable(),
  Tfinancial_year_amount: Yup.number().required("Field is required").nullable(),
  Sfinancial_year: Yup.mixed().required("Field is required"),
  Ffinancial_year: Yup.mixed().required("Field is required"),
  FFfinancial_year: Yup.string().required("Field is required"),
  SFfinancial_year: Yup.string().required("Field is required"),
  TFfinancial_year: Yup.string().required("Field is required"),
  Tfinancial_year: Yup.mixed().required("Field is required"),
  address: Yup.boolean().oneOf([true], "You must accept").nullable(),
  empanel_certificate: Yup.string().required("Field is required"),
  // udyam: Yup.string().required("Field is required"),
  //iso_certificate: Yup.string().required("Field is required"),
  // oem_certificate: Yup.string().required("Field is required"),
  // oem_in_company_type: Yup.string().required("Field is required"),
  // oem_in_company_valid_from: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  // oem_in_company_valid_to: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  empanelment_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  expiry_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  it_turn_over_first_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  it_turn_over_second_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  // it_turn_over_third_year: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),

  it_turn_over_first_document: Yup.string().required("Field is required"),
  it_turn_over_second_document: Yup.string().required("Field is required"),
  //it_turn_over_third_document: Yup.string().required("Field is required"),
  it_turn_over_first_amount: Yup.number().required("Field is required").nullable(),
  it_turn_over_second_amount: Yup.number().required("Field is required").nullable(),
  // it_turn_over_third_amount: Yup.number().required("Field is required"),
  it_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  it_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,14}$/, "Phone number must be upto 10 or maximum 14 digits")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),
  it_turn_over_ca_name: Yup.string().required("Field is required"),
  financial_turn_over_ca_name: Yup.string().required("Field is required"),
  financial_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  financial_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,14}$/, "Phone number must be upto 10 or maximum 14 digits")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),  work_order_certificate: Yup.string().required("Field is required"),
  work_order_no_of_projects: Yup.number().required("Field is required").nullable(),

  any_other_company_pincode: Yup.number().required("Field is required").nullable(),
  any_other_company_documents: Yup.string().required("Field is required"),
  any_other_company_name: Yup.string().required("Field is required"),
  any_other_company_address: Yup.string().required("Field is required"),
  any_other_company_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  //  manpower_mark: Yup.string().required("Field is required"),
  // manpower_date: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
    technical_manpower_number: Yup.number().required("Field is required").nullable(),
    nontechnical_manpower_number: Yup.number().required("Field is required").nullable(),
  manpower_number: Yup.number().required("Field is required").nullable(),
  manpower_documents: Yup.string().required("Field is required"),
  land_document: Yup.string().required("Field is required"),
  land_village_col: Yup.string().required("Field is required"),
  land_extent: Yup.string().required("Field is required"),
  // land_value: Yup.string().required("Field is required").nullable(),
  // land_survey_no: Yup.string().required("Field is required"),
});

const UploadValidationWithoutUdyamSchema = Yup.object().shape({
  pan: Yup.string().required("Field is required"),
  incorporation_certificate: Yup.string().required("Field is required"),
  address_proof: Yup.string().required("Field is required"),
  mse_certificate: Yup.string().required("Field is required"),
  gst_certificate: Yup.string().required("Field is required"),
  Ffinancial_year: Yup.mixed().required("Field is required"),
  Ffinancial_year_amount: Yup.number().required("Field is required"),
  Sfinancial_year_amount: Yup.number().required("Field is required"),
  Tfinancial_year_amount: Yup.number().required("Field is required"),
  Sfinancial_year: Yup.mixed().required("Field is required"),
  FFfinancial_year: Yup.string().required("Field is required"),
  SFfinancial_year: Yup.string().required("Field is required"),
  TFfinancial_year: Yup.string().required("Field is required"),
  Tfinancial_year: Yup.mixed().required("Field is required"),
  address: Yup.boolean().oneOf([true], "You must accept").nullable(),
  empanel_certificate: Yup.string().required("Field is required"),
  //iso_certificate: Yup.string().required("Field is required"),
  // oem_certificate: Yup.string().required("Field is required"),
  oem_in_company_type: Yup.string().required("Field is required"),
  oem_in_company_valid_from: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  oem_in_company_valid_to: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  empanelment_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  expiry_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  it_turn_over_first_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  it_turn_over_second_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  // it_turn_over_third_year: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),

  it_turn_over_first_document: Yup.string().required("Field is required"),
  it_turn_over_second_document: Yup.string().required("Field is required"),
  //it_turn_over_third_document: Yup.string().required("Field is required"),
  it_turn_over_first_amount: Yup.number().required("Field is required"),
  it_turn_over_second_amount: Yup.number().required("Field is required"),
  // it_turn_over_third_amount: Yup.number().required("Field is required"),
  it_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required")
    .nullable(),
  it_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")

    .matches(/^\d{10,14}$/, "Phone number must be upto 10 or maximum 14 digits")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),
  it_turn_over_ca_name: Yup.string().required("Field is required"),
  work_order_certificate: Yup.string().required("Field is required"),
  work_order_no_of_projects: Yup.number().required("Field is required"),

  any_other_company_pincode: Yup.number().required("Field is required"),
  any_other_company_documents: Yup.string().required("Field is required"),
  any_other_company_name: Yup.string().required("Field is required"),
  any_other_company_address: Yup.string().required("Field is required"),
  any_other_company_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  // manpower_mark: Yup.string().required("Field is required"),
  // manpower_date: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  manpower_number: Yup.number().required("Field is required"),
  manpower_documents: Yup.string().required("Field is required"),
  technical_manpower_number: Yup.number().required("Field is required").nullable(),
  nontechnical_manpower_number: Yup.number().required("Field is required").nullable(),
  land_document: Yup.string().required("Field is required"),
  land_village_col: Yup.string().required("Field is required"),
  land_extent: Yup.string().required("Field is required"),
  // land_value: Yup.string().required("Field is required").nullable(),
  // land_survey_no: Yup.string().required("Field is required"),
  financial_turn_over_ca_name: Yup.string().required("Field is required"),
  financial_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  financial_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")

    .matches(/^\d{10,14}$/, "Phone number must be upto 10 or maximum 14 digits")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),
});

const UploadValidationWithoutMSESchema = Yup.object().shape({
  pan: Yup.string().required("Field is required"),
  incorporation_certificate: Yup.string().required("Field is required"),
  address_proof: Yup.string().required("Field is required"),
  gst_certificate: Yup.string().required("Field is required"),
  Ffinancial_year: Yup.mixed().required("Field is required"),
  Ffinancial_year_amount: Yup.number().required("Field is required"),
  Sfinancial_year_amount: Yup.number().required("Field is required"),
  Tfinancial_year_amount: Yup.number().required("Field is required"),
  Sfinancial_year: Yup.mixed().required("Field is required"),
  FFfinancial_year: Yup.string().required("Field is required"),
  SFfinancial_year: Yup.string().required("Field is required"),
  TFfinancial_year: Yup.string().required("Field is required"),
  Tfinancial_year: Yup.mixed().required("Field is required"),
  address: Yup.boolean().oneOf([true], "You must accept").nullable(),
  empanel_certificate: Yup.string().required("Field is required"),
  // udyam: Yup.string().required("Field is required"),
  //iso_certificate: Yup.string().required("Field is required"),
  // oem_certificate: Yup.string().required("Field is required"),
  // oem_in_company_type: Yup.string().required("Field is required"),
  // oem_in_company_valid_from: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  // oem_in_company_valid_to: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  empanelment_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  expiry_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  it_turn_over_first_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  it_turn_over_second_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  // it_turn_over_third_year: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),

  it_turn_over_first_document: Yup.string().required("Field is required"),
  it_turn_over_second_document: Yup.string().required("Field is required"),
  // it_turn_over_third_document: Yup.string().required("Field is required"),
  it_turn_over_first_amount: Yup.number().required("Field is required"),
  it_turn_over_second_amount: Yup.number().required("Field is required"),
  // it_turn_over_third_amount: Yup.number().required("Field is required"),
  it_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required")
    .nullable(),
  it_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")

    .matches(/^\d{10,14}$/, "Phone number must be upto 10 or maximum 14 digits")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),
  it_turn_over_ca_name: Yup.string().required("Field is required"),
  work_order_certificate: Yup.string().required("Field is required"),
  work_order_no_of_projects: Yup.number().required("Field is required"),

  any_other_company_pincode: Yup.number().required("Field is required"),
  any_other_company_documents: Yup.string().required("Field is required"),
  any_other_company_name: Yup.string().required("Field is required"),
  any_other_company_address: Yup.string().required("Field is required"),
  
  any_other_company_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  // manpower_mark: Yup.string().required("Field is required"),
  financial_turn_over_ca_name: Yup.string().required("Field is required"),
  financial_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  financial_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")

    .matches(/^\d{10,14}$/, "Phone number must be upto 10 or maximum 14 digits")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),
  manpower_number: Yup.number().required("Field is required"),
  manpower_documents: Yup.string().required("Field is required"),
  technical_manpower_number: Yup.number().required("Field is required").nullable(),
  nontechnical_manpower_number: Yup.number().required("Field is required").nullable(),
  // manpower_date: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  land_document: Yup.string().required("Field is required"),
  land_village_col: Yup.string().required("Field is required"),
  land_extent: Yup.string().required("Field is required"),
  // land_value: Yup.string().required("Field is required").nullable(),
  // land_survey_no: Yup.string().required("Field is required"),
});
const UploadValidationWithoutUdeyamMSESchema = Yup.object().shape({
  pan: Yup.string().required("Field is required"),
  incorporation_certificate: Yup.string().required("Field is required"),
  address_proof: Yup.string().required("Field is required"),
  gst_certificate: Yup.string().required("Field is required"),
  Ffinancial_year: Yup.mixed().required("Field is required"),
  Ffinancial_year_amount: Yup.number().required("Field is required"),
  Sfinancial_year_amount: Yup.number().required("Field is required"),
  Tfinancial_year_amount: Yup.number().required("Field is required"),
  Sfinancial_year: Yup.mixed().required("Field is required"),
  FFfinancial_year: Yup.string().required("Field is required"),
  SFfinancial_year: Yup.string().required("Field is required"),
  TFfinancial_year: Yup.string().required("Field is required"),
  Tfinancial_year: Yup.mixed().required("Field is required"),
  address: Yup.boolean().oneOf([true], "You must accept").nullable(),
  empanel_certificate: Yup.string().required("Field is required"),
  //iso_certificate: Yup.string().required("Field is required"),
  // oem_certificate: Yup.string().required("Field is required"),
  // oem_in_company_type: Yup.string().required("Field is required"),
  // oem_in_company_valid_from: Yup.date().transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  // oem_in_company_valid_to: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  empanelment_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  expiry_date: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),
  it_turn_over_first_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  it_turn_over_second_year: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
    })
    .typeError("please enter a valid date")
    .required("Field is required"),

  // it_turn_over_third_year: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),

  it_turn_over_first_document: Yup.string().required("Field is required"),
  it_turn_over_second_document: Yup.string().required("Field is required"),
  // it_turn_over_third_document: Yup.string().required("Field is required"),
  it_turn_over_first_amount: Yup.number().required("Field is required"),
  it_turn_over_second_amount: Yup.number().required("Field is required"),
  //it_turn_over_third_amount: Yup.number().required("Field is required"),
  it_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("Company e-mail is required")
    .nullable(),
  it_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")

    .matches(/^\d{10,14}$/, "Phone number must be upto 10 or maximum 14 digits")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),
  it_turn_over_ca_name: Yup.string().required("Field is required"),
  work_order_certificate: Yup.string().required("Field is required"),
  work_order_no_of_projects: Yup.number().required("Field is required"),

  any_other_company_pincode: Yup.number()
    .required("Field is required")
    .nullable(),
  any_other_company_documents: Yup.string().required("Field is required"),
  any_other_company_name: Yup.string().required("Field is required"),
  any_other_company_address: Yup.string().required("Field is required"),
  any_other_company_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  manpower_documents: Yup.string().required("Field is required"),
  technical_manpower_number: Yup.number().required("Field is required").nullable(),
  nontechnical_manpower_number: Yup.number().required("Field is required").nullable(),
  //manpower_mark: Yup.string().required("Field is required"),
  // manpower_date: Yup.date()
  //   .transform(function (value, originalValue) {
  //     if (this.isType(value)) {
  //       return value;
  //     }
  //   })
  //   .typeError("please enter a valid date")
  //   .required("Field is required"),
  manpower_number: Yup.number().required("Field is required"),
  land_document: Yup.string().required("Field is required"),
  land_village_col: Yup.string().required("Field is required"),
  land_extent: Yup.string().required("Field is required"),
  // land_value: Yup.string().required("Field is required").nullable(),
  // land_survey_no: Yup.string().required("Field is required"),
  financial_turn_over_ca_name: Yup.string().required("Field is required"),
  financial_turn_over_ca_email: Yup.string()
    .email("Invalid email")
    .required("E-mail is required")
    .nullable(),
  financial_turn_over_ca_phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10,14}$/, "Phone number must be upto 10 or maximum 14 digits")
    .max(14)
    .min(10, "Phone number must be at least 10 digits"),
});
const RejectUploadValidationSchema = Yup.object().shape({
  pan: Yup.string().required("Field is required"),
  // lorem: Yup.string().required("Field is required"),
});
const TurnOverValidationSchema = Yup.object().shape({
  pan: Yup.string().required("Field is required").nullable(),
  Ffinancial_year: Yup.number().required("Field is required").nullable(),
  year: Yup.string().required("Field is required").nullable(),
});
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const AdvaDetailsValidationSchema = Yup.object().shape({
  // phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(14).required('Phone number must be atleast 10 digits'),
  pan_number: Yup.string().required("Field is required").nullable(),
  gst_number: Yup.string().required("Field is required").nullable(),
  vendor_type: Yup.string().required("Field is required").nullable(),
  gst_class: Yup.string().required("Field is required").nullable(),
  company_mse: Yup.string().required("Field is required").nullable(),
  valid_upto: Yup.string().required("Field is required").nullable(),
  // udyam: Yup.string().required("Field is required").nullable(),
  sc_st_entrepreneur: Yup.string().required("Field is required").nullable(),
  woman_entrepreneur: Yup.string().required("Field is required").nullable(),
  company_name: Yup.string().required("Field is required").nullable(),
  company_email: Yup.string().required("Field is required").nullable(),
  contact_number: Yup.string().required("Field is required").nullable(),
  incorporation_date: Yup.string().required("Field is required").nullable(),
  point_company_name: Yup.string().required("Field is required").nullable(),
  point_contact_number: Yup.string().required("Field is required").nullable(),
  point_email_id: Yup.string().required("Field is required").nullable(),
  designation: Yup.string().required("Field is required").nullable(),
  Lorem: Yup.boolean().oneOf([true], "You must accept").nullable(),
  // building_number: Yup.string().required("Field is required").nullable(),
  // building_address: Yup.string().required("Field is required").nullable(),
  company_registraion_address: Yup.string()
    .required("Field is required")
    .nullable(),
  company_communication_address: Yup.string()
    .required("Field is required")
    .nullable(),
});
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const AdvaDetailsValidationSchema1 = Yup.object().shape({
  // phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(14).required('Phone number must be atleast 10 digits'),
  pan_number: Yup.string().required("Field is required").nullable(),
  gst_number: Yup.string().required("Field is required").nullable(),
  vendor_type: Yup.string().required("Field is required").nullable(),
  gst_class: Yup.string().required("Field is required").nullable(),
  company_mse: Yup.string().required("Field is required").nullable(),
  sc_st_entrepreneur: Yup.string().required("Field is required").nullable(),
  woman_entrepreneur: Yup.string().required("Field is required").nullable(),
  company_name: Yup.string().required("Field is required").nullable(),
  company_email: Yup.string().required("Field is required").nullable(),
  contact_number: Yup.string().required("Field is required").nullable(),
  incorporation_date: Yup.string().required("Field is required").nullable(),
  point_company_name: Yup.string().required("Field is required").nullable(),
  point_contact_number: Yup.string().required("Field is required").nullable(),
  point_email_id: Yup.string().required("Field is required").nullable(),
  designation: Yup.string().required("Field is required").nullable(),
  Lorem: Yup.boolean().oneOf([true], "You must accept").nullable(),
  company_registraion_address: Yup.string()
    .required("Field is required")
    .nullable(),
  company_communication_address: Yup.string()
    .required("Field is required")
    .nullable(),
  //building_number: Yup.string().required("Field is required").nullable(),
  // building_address: Yup.string().required("Field is required").nullable(),
});

const BasicDetailsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(14).required('Phone number must be atleast 10 digits'),
  email: Yup.string().required("Field is required"),
  // departments: Yup.number().required("Field is required").nullable(),
});
const pincodeValidation = Yup.object().shape({
  pincode: Yup.string()
    .matches(
      /^[1-9][0-9]{5}$/,
      "Please enter a valid 6 digit Indian postal PIN code"
    )
    .required("Field is required"),
  district: Yup.string().required("Field is required").nullable(),
  city: Yup.string().required("Field is required").nullable(),
  state: Yup.string().required("Field is required").nullable(),
  country: Yup.string().required("Field is required").nullable(),
  address: Yup.string().required("Field is required").nullable(),
  building_number: Yup.string().required("Field is required").nullable(),
  building_address: Yup.string().required("Field is required").nullable(),
  // landmark: Yup.string().required("Field is required").nullable(),
});

const pincodeValidation1 = Yup.object().shape({
  building_number: Yup.string().required("Field is required").nullable(),
  building_address: Yup.string().required("Field is required").nullable(),
  // landmark: Yup.string().required("Field is required").nullable(),
});
export {
  UploadValidationSchema,
  AdvaDetailsValidationSchema,
  BasicDetailsValidationSchema,
  RejectUploadValidationSchema,
  pincodeValidation,
  pincodeValidation1,
  AdvaDetailsValidationSchema1,
  TurnOverValidationSchema,
  UploadValidationWithoutUdyamSchema,
  UploadValidationWithoutUdeyamMSESchema,
  UploadValidationWithoutMSESchema,
};
