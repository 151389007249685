export const ReportsList = [
    // paste url and en to display in the UI 
    {
        en: 'Products Report',         // Label of report 
        ka: 'ಉತ್ಪನ್ನಗಳ ವರದಿ ',         // Label of report 
        URL: '/reports/products/csv',   // url to fetch report data
        HIDE_DATE:true                   // Paste this line only if you dont need date filter
    },
    {
        en: 'Purchase Report',   
        ka: 'ಖರೀದಿ ವರದಿ ',         // Label of report      
        URL: '/reports/purchase/csv',   // url to fetch report data
    },
    {
        en: 'Product Delivered Report', 
        ka: 'ಉತ್ಪನ್ನ ವಿತರಿಸಿದ ವರದಿ',         // Label of report 
        URL: '/reports/product-delivered/csv',   // url to fetch report data
    },
    {
        en: 'Orders Report',   
        ka: 'ಆದೇಶಗಳ ವರದಿ ',      // Label of report 
        URL: '/reports/orders/csv',   // url to fetch report data
    },
    
]