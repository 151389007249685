import React, { useEffect, useState ,useContext} from "react"
import { api } from "../../../../Library/CustomApi"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import CustomPagination from "../../../../Components/CustomPagination/Index"
import ModalContainer from "../../../../Components/Modal/ModalContainer"
import UploadChellanModal from "../UploadChellanModal"
import Loader from "../../../../Components/Loader/Loader"
import constants from "../../../../Library/Constants/Constants"
import { convertISODateToDateTime } from "../../../../Library/Constants/helpers"
import { PAYMENT_TYPES } from "../../../../Library/Constants/MapConstants"
import { Context } from "../../../../Components/Context/Context"
import { Department_drafts } from "../../../../Library/LanguageConversion/Department_drafts"


const DraftTab = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedId, setSelectedId] = useState<any>()
    const [selectedAmount, setSelectedAmount] = useState<string | null>()
    const [selectedPaymentMode, setSelectedPaymentMode] = useState<any>()
    const [selectedPaymentType, setSelectedPaymentType] = useState<any>()
    const [proformaData, setProformaData] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [currentPage, setCurrentPage] = useState<number | string>(1)
    const [pageData, setPageData] = useState<any>([])
    const {language,setLanguage}=useContext(Context);

    useEffect(() => {
        getProforma()
    }, [currentPage])

    // get draft details 
    const getProforma = () => {
        setIsLoading(true)
        api
            .get(`/order/pf-invoice?status=${constants?.ORDERS?.DRAFT}&page=${currentPage}`, true)
            .then(async function ([success, response]) {
                setPageData(response?.meta)
                setProformaData(response?.data || []);
                setIsLoading(false)
                return response;
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            });
    };

    // to format time 
    function formatTime(timeString: any) {
        const [hourString, minute] = timeString.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }

    return (
        <>
            <div className="notifications-outer-wrap"> 
                <Row className="gy-3">
                    {proformaData?.length !== 0 && !isLoading ?
                        <>{proformaData?.map((obj: any, i: number) => {
                            return (
                                <React.Fragment key={i}>
                                    <Col md={12}>
                                        <div className="notification-item">
                                            <div className="notification-item-inner-wrap">
                                                <div className="notification-details">
                                                    <h6>{Department_drafts?.Order_ID[language]}: <span>{obj.order_id}</span> </h6>
                                                    {
                                                        obj.order_details.map((itm: any, x: number) => {

                                                            return (
                                                                <React.Fragment key={x}>
                                                                    <div className="notify-inner">
                                                                        <small>
                                                                            <i className="bi bi-check2-circle me-2"></i>
                                                                            {itm.name}
                                                                        </small><br /> &nbsp;  &nbsp;  &nbsp;
                                                                        <small>{Department_drafts?.Quantity[language]}: {itm.quantity}</small><br /> &nbsp;  &nbsp;  &nbsp;
                                                                        <small>{Department_drafts?.Price[language]}: ₹ {(itm.total_amount - obj?.sum_dep_deduction).toFixed(2)}</small>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="notification-status">
                                                    {
                                                        (obj?.draft == null || obj?.draft?.status == 3 || obj?.draft?.status == 0) ?
                                                            <Link to='' title="Click to Upload Transaction Details" className="blue-text" onClick={() => {
                                                                setSelectedId(obj.id);
                                                                setSelectedAmount(obj?.last_paid_amount)
                                                                setSelectedPaymentMode(obj?.payment_mode)
                                                                setSelectedPaymentType(obj?.payment_type)
                                                                setIsOpen(true);
                                                            }} >
                                                                {obj?.draft?.status == 3 ? 'Reupload Transaction Details' : 'Upload Transaction Details'}
                                                            </Link>
                                                            :
                                                            <Link to='' className="text-decoration-none">
                                                                {obj?.draft?.status == 1 &&
                                                                    <>
                                                                        <span className="text-success">{Department_drafts?.Uploaded[language]}</span>
                                                                        <i className="fa fa-check-circle text-success ms-1"></i>
                                                                    </>}
                                                                {obj?.draft?.status == 2 &&
                                                                    <>
                                                                        <span className="text-Success">{Department_drafts?.Approved[language]}</span>
                                                                    </>}
                                                                {/* {obj?.draft?.status == 3 && <><span className="text-danger">Rejected</span></>} */}
                                                                {obj?.draft?.status == 2 &&
                                                                    <>
                                                                        <span className="green-text"></span>
                                                                        <i className="fa fa-check-circle text-success ms-1"></i>
                                                                    </>}
                                                            </Link>
                                                    }
                                                    {obj?.payment_mode &&
                                                        <small className="text-end d-block">
                                                            {(obj?.payment_mode == constants.DEPT_PAYMENT_MODES.FULL_PAYMENT) ?
                                                                'Full Payment' :
                                                                (obj?.payment_mode == constants.DEPT_PAYMENT_MODES.PARTIAL_PAYMENT) ?
                                                                    'Partial Payment' :
                                                                    (obj?.payment_mode == constants.DEPT_PAYMENT_MODES.PAY_LATER) ?
                                                                        'Pay Later Payment' : null}
                                                            {'  '} of  ₹{obj?.last_paid_amount} {' '} via {' '}
                                                            {PAYMENT_TYPES?.find((itm: any) => itm?.value == obj?.payment_type)?.name}
                                                        </small>}
                                                    {obj?.created_at && <small className="text-end d-block">{convertISODateToDateTime(obj?.created_at)}</small>}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </React.Fragment>
                            )
                        })
                        }
                        </> :
                        isLoading ? <div className="text-center py-5"> <Loader /> </div>
                            :
                            <>
                                <div className="text-center py-5">
                                    <p>{Department_drafts?.No_Drafts_Found[language]}</p>
                                </div>
                            </>
                    }

                    {/* pagination  */}
                    {pageData && proformaData.length > 0 && < div className="table-footer-wrap">
                    <small> {Department_drafts?.Showing[language]}{pageData.from}{Department_drafts?.to[language]}{pageData.to} {Department_drafts?.of[language]} {pageData?.total}{Department_drafts?.entries[language]}</small>
                        <div className="pagination-outer">
                            <CustomPagination
                                totalItems={pageData?.total}
                                itemsPerPage={pageData?.per_page}
                                activePage={pageData?.current_page}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>}
                </Row>
            </div>
            {/* upload challan modal  */}
            <ModalContainer
                open={isOpen}
                setOpen={setIsOpen}
                header={Department_drafts?.Upload_transaction_details[language]}
            >
                <UploadChellanModal
                    setIsOpen={setIsOpen}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    getProforma={getProforma}
                    amount={selectedAmount}
                    selectedPaymentMode={selectedPaymentMode}
                    selectedPaymentType={selectedPaymentType}
                />
            </ModalContainer>
        </>
    )
}

export default DraftTab