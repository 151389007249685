import React, { useEffect, useContext } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  Label,
  Badge,
  Table,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../../Library/CustomApi";
import { toast } from "react-toastify";
import constants from "../../../../../Library/Constants/Constants";
import Loader from "../../../../../Components/Loader/Loader";
import { Context } from "../../../../../Components/Context/Context";
import { admin_empanelment } from "../../../../../Library/LanguageConversion/admin_empanelment";

export default function BasicDetails() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [basic_details, setBasicDetails] = useState<any>([]);
  const [vendor_data, setVendorData] = useState<any>([]);
  const [verify_status, setVerifystatus] = useState<any>([]);
  const [status, setStatus] = useState(false);
  const [rej_status, setRejStatus] = useState(false);
  const [err, setErr] = useState("");
  const [comment, setComment] = useState({
    comm: "",
  });
  const location = useLocation();
  const id = localStorage.getItem("vendor_data");
  const vendor_id = location?.state?.id || location?.state?.data || id;
  const { language, setLanguage } = useContext(Context);

  useEffect(() => {
    getBasicDetails();
  }, [vendor_id]);

  const getBasicDetails = () => {
    setLoading(true);
    api
      .post(
        `/get_vendor_data`,
        {
          user_id: vendor_id,
        },
        true
      )
      .then(async function ([success, response]) {
        setBasicDetails(response?.vendor_details?.basic_details || []);
        setVendorData(response?.vendor_details?.company_details || []);

        let data: any = response?.vendor_details?.verified_data;
        const filteredItems = data.find((item: any) => {
          if (item?.type == "12") {
            setVerifystatus(item?.doc_status || "");
            setComment({
              comm: item?.comment,
            });
          }
        });

        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChange = (e: any) => {
    setComment({
      ...comment,
      [e.target.name]: e.target.value,
    });
    setErr("");
  };
  const verifyData = (id: any, status: any) => {
    api
      .post(
        `/doc_verification`,
        {
          user_id: vendor_id,
          type: 12,
          doc_status: status,
          comment: comment?.comm,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Verified Successfully");
          navigate("/advanced-details-vendor");
        }
        getBasicDetails();

        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {loading == true &&
      basic_details.length == 0 &&
      vendor_data.length == 0 ? (
        <>
          <div className="text-center py-5 empty-text"> <Loader /> </div>
        </>
      ) : (
        <div className="table-wrap responsive-table">
          <Table className="w-100">
            <thead>
              <tr>
                {/* <th>Sl No</th> */}
                <th>{admin_empanelment?.Vendor_Name?.[language]}</th>
                <th>{admin_empanelment?.Mobile_Number?.[language]}</th>
                <th>{admin_empanelment?.Email_ID?.[language]}</th>
                <th>{admin_empanelment?.comment?.[language]}</th>
                <th>{admin_empanelment?.Action?.[language]}</th>
                <th>{admin_empanelment?.Status?.[language]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="mobile-heading">{admin_empanelment?.Vendor_Name?.[language]}:</span>
                  <span className="table-data">{vendor_data[0]?.name}</span>
                </td>
                <td>
                  <span className="mobile-heading">{admin_empanelment?.Mobile?.[language]}:</span>
                  <span className="table-data">{basic_details[0]?.phone}</span>
                </td>
                <td>
                  <span className="mobile-heading">{admin_empanelment?.Email_ID?.[language]}:</span>
                  <span className="table-data">{basic_details[0]?.email}</span>
                </td>
                <td>
                  <span className="mobile-heading">{admin_empanelment?.comment?.[language]}:</span>
                  <span className="table-data">
                    {verify_status == constants.BASIC_VERIFY.APPROVED ? (
                      <Input
                        type="textarea"
                        rows="1"
                        className="comment-text form-control"
                        disabled={true}
                        placeholder="Approved"
                      ></Input>
                    ) : verify_status == constants.BASIC_VERIFY.REUPLOAD ? (
                      <Input
                        type="textarea"
                        rows="1"
                        name="comm"
                        className=" form-control comment-text"
                        value={comment?.comm}
                        onChange={handleChange}
                        placeholder="Add Comment"
                      ></Input>
                    ) : (
                      <>
                        <Input
                          type="textarea"
                          rows="1"
                          name="comm"
                          className="form-control comment-text"
                          value={comment?.comm}
                          onChange={handleChange}
                          placeholder="Add Comment"
                        ></Input>
                        <div className="error-outer">
                          <div className="errors">
                            {err ? (
                              <>
                                <i className="fa fa-info-circle"></i>
                                &nbsp;&nbsp;
                                {err}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                  </span>
                </td>
                <td>
                  <span className="mobile-heading">{admin_empanelment?.Action?.[language]}:</span>
                  <span className="table-data">
                    <div className="action-flex-btn">
                      {verify_status == constants.BASIC_VERIFY.APPROVED ? (
                        <span title="Approved">
                        <Input
                          type="checkbox"
                          disabled={true}
                          checked={true}
                        ></Input>
                        </span> 
                      ) : verify_status == constants.BASIC_VERIFY.REJECTED ? (
                        <Input title="Approve"
                          type="checkbox"
                          // disabled={true}
                          onClick={() => {
                            setStatus(true);
                            verifyData(vendor_id, 2);
                          }}
                          checked={false}
                        ></Input>
                      ) : verify_status == constants.BASIC_VERIFY.REUPLOAD ? (
                        <Input title="Approve"
                          type="checkbox"
                          onClick={() => {
                            setStatus(true);
                            verifyData(vendor_id, 2);
                          }}
                        ></Input>
                      ) : (
                        <Input title="Approve"
                          type="checkbox"
                          onClick={() => {
                            setStatus(true);
                            verifyData(vendor_id, 2);
                          }}
                        ></Input>
                      )}

                      {verify_status == constants.BASIC_VERIFY.REJECTED ? (
                        <>
                          <Link to="#" className="refuse-btn active" title="Rejected">
                            <i className="icons icons-reject-white"></i>
                          </Link>
                        </>
                      ) : verify_status == constants.BASIC_VERIFY.REUPLOAD ? (
                        <Link
                          to="#"
                          className="refuse-btn "
                          onClick={() => {
                            setRejStatus(true);
                            verifyData(vendor_id, 3);
                          }}
                        >
                          <i title="Reject" className="icons icons-reject"></i>
                        </Link>
                      ) : (
                        <>
                          <Link
                            to="#"
                            className="refuse-btn "
                            onClick={() => {
                              setRejStatus(true);
                              verifyData(vendor_id, 3);
                            }}
                          >
                            <i title="Reject" className="icons icons-reject"></i>
                          </Link>
                        </>
                      )}
                    </div>
                  </span>
                </td>
                <td>
                  <span className="mobile-heading">{admin_empanelment?.Status?.[language]}:</span>
                  <span className="table-data">
                    {verify_status == constants.BASIC_VERIFY.APPROVED ? (
                      <Badge color="success">{admin_empanelment?.Approved?.[language]}</Badge>
                    ) : verify_status == constants.BASIC_VERIFY.REJECTED ? (
                      <Badge color="danger">{admin_empanelment?.Rejected?.[language]}</Badge>
                    ) : verify_status == constants.BASIC_VERIFY.REUPLOAD ? (
                      <Badge color="info">{admin_empanelment?.Reuploaded?.[language]}</Badge>
                    ) : (
                      <Badge color="warning">{admin_empanelment?.pending?.[language]}</Badge>
                    )}
                  </span> 
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
}