import React, { useEffect, useContext } from "react";
import { useState, useMemo } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import constants from "../../../Library/Constants/Constants";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

export default function AmcVendor(props: any) {
  const [loading, setLoading] = useState(false);
  const [received_order, setReceivedOrder] = useState([]);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const { language, setLanguage } = useContext(Context);
  const [search, setSearch] = useState("")
  useEffect(() => {
    getReceivedOrder();
  }, [currentPage]);
  // ========================================================== Get Recieved Order ==========================================================

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getReceivedOrder(1);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const onChange = (e: any) => {
    setSearch(e.target.value)
  }

  const getReceivedOrder = (page = 1) => {
    setLoading(true);
    api
      .get(`/amc?page=${page}&search_keyword=${search}`, true)
      .then(async function ([success, response]) {
        setReceivedOrder(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ================================================================ View ==================================================================

  return (
    <>
      <div className="custom-tab-content">
        <div className="table-outer-wrap">
          <div className="search-widget-outer">
            <Form role="search">
              <InputGroup className="search-group">
                <InputGroupText title="Search here" className="search-icon" id="search">
                  <i className="fa-sharp fa-solid fa-magnifying-glass" />
                </InputGroupText>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  aria-label="Username"
                  aria-describedby="search"
                  onChange={onChange} 
                />
              </InputGroup>
            </Form>
          </div>
          <div className="table-wrap responsive-table">
            <Table className="w-100">
              <thead>
                <tr>
                  <th>{VendorRegLang?.sl_no[language]}</th>
                  <th>{VendorRegLang?.contract[language]}</th>
                  <th>{VendorRegLang?.products[language]}</th>
                  <th>{VendorRegLang?.invoice_id[language]}</th>
                  <th>{VendorRegLang?.valid_from[language]}</th>
                  <th>{VendorRegLang?.valid_to[language]}</th>
                  <th>{VendorRegLang?.email_id[language]}</th>
                </tr>
              </thead>
              <tbody>
                {received_order?.length !== 0 ? (
                  <>
                    {
                      received_order?.map((item: any, i) => {
                        return (<>
                          <tr>
                            <td>
                            <span className="mobile-heading">{VendorRegLang?.sl_no[language]}:</span>
                            <span className="table-data">
                              {i + 1}
                              </span>
                            </td>
                            <td>
                            <span className="mobile-heading">{VendorRegLang?.contract[language]}:</span>
                            <span className="table-data">
                              {item?.invoiceproduct?.usercompany?.name ? item?.invoiceproduct?.usercompany?.name : '-'
                              }
                              </span>
                            </td>
                            <td>
                            <span className="mobile-heading">{VendorRegLang?.products[language]}:</span>
                            <span className="table-data">
                              {item?.invoiceproduct?.vendorproducts?.name ? item?.invoiceproduct?.vendorproducts?.name : '-'
                              }
                              </span>
                            </td>
                            <td>
                            <span className="mobile-heading">{VendorRegLang?.invoice_id[language]}:</span>
                            <span className="table-data">
                              {item?.amcinvoice?.invoice_no ? item?.amcinvoice?.invoice_no : '-'
                              }
                              </span>
                            </td>
                            <td>
                            <span className="mobile-heading">{VendorRegLang?.valid_from[language]}:</span>
                            <span className="table-data">
                              {item?.start_date ? item?.start_date : '-'
                              }
                              </span>
                            </td>
                            <td>
                            <span className="mobile-heading">{VendorRegLang?.valid_to[language]}:</span>
                            <span className="table-data">
                              {item?.end_date ? item?.end_date : '-'
                              }
                              </span>
                            </td>
                            <td>
                            <span className="mobile-heading">{VendorRegLang?.email_id[language]}:</span>
                            <span className="table-data">
                              {item?.invoiceproduct?.usercompany?.email ? item?.invoiceproduct?.usercompany?.email : '-'
                              }
                              </span>
                            </td>
                          </tr>
                        </>)
                      })}
                  </>) :
                  (<tr>
                    <td
                      className="text-center px-2 py-5 empty-text"
                      colSpan={7}
                    >
                      {loading ? (
                        <div className="d-flex justify-content-center">
                          <Loader />
                        </div>
                      ) : (
                        `${VendorRegLang?.no_data_found[language]}`
                      )}
                    </td>
                  </tr>)}
              </tbody>
            </Table>
          </div>
          {pageData && (
            <div className="table-footer-wrap">
              <small>
                {VendorRegLang?.showing[language]} {pageData?.from} {VendorRegLang?.to[language]} {pageData?.to} {VendorRegLang?.of[language]} {pageData?.total}{" "}
                {VendorRegLang?.entries[language]}
              </small>
              <div className="pagination">
                <CustomPagination
                  totalItems={pageData?.total}
                  itemsPerPage={pageData?.per_page}
                  activePage={pageData.current_page}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}