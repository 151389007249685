import React from "react";
import { useState, useEffect,useContext } from "react";
import { Context } from "../../../../Components/Context/Context";
import {Admin_orders} from "../../../../Library/LanguageConversion/Admin_orders";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import CustomPagination from "../../../../Components/CustomPagination/Index";

export default function PendingOrders() {
  const { language, setLanguage } = useContext(Context);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  const [pending_order, setPendingOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    getPendingOrder();
  }, []);

  const getPendingOrder = () => {
    setLoading(true);
    api
      .get(`/order/pf-invoice?status=${constants.ORDERS.PENDING}`, true)
      .then(async function ([success, response]) {
        // setPendingOrder(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <div className="search-widget-outer">
        <Form className="d-flex" role="search">
          <InputGroup className="search-group">
            <InputGroupText className="search-icon" id="search">
              <i title="Search here" className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={Admin_orders?.search_here?.[language]}
              aria-label="Username"
              aria-describedby="search"
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_orders?.SlNo[language]}</th>
              <th>{Admin_orders?.OrderID[language]}</th>
              <th>{Admin_orders?.OrderedDate[language]}</th>
              {/* <th>Tender ID</th> */}
              <th>{Admin_orders?.OrderDetails[language]}</th>
              <th>{Admin_orders?.VendorName[language]}</th>
              <th>{Admin_orders?.ShippingAddress[language]}</th>
              <th>{Admin_orders?.Quantity[language]}</th>
              <th>{Admin_orders?.Department[language]}</th>
            </tr>
          </thead>
          <tbody>
            {pending_order?.length !== 0 ? (
              <>
                {pending_order?.map((item: any, i: number) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.SlNo[language]}:</span>
                          <span className="table-data">
                            {pageData?.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.OrderID[language]}:</span>
                          <span className="table-data">124512</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.OrderedDate[language]}:</span>
                          <span className="table-data">124512</span>
                        </td>
                        {/* <td>
                        <span className="mobile-heading">Tender Id:</span>
                        <span className="table-data">253659</span>
                        </td> */}
                        <td>
                          <span className="mobile-heading">{Admin_orders?.OrderDetails[language]}:</span>
                          <span className="table-data">
                            Dell 23.8 inch Full HD VA Panel 3-Side Near Edgeless
                            with TUV Eye Care Monitor
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.VendorName[language]}:</span>
                          <span className="table-data">124512</span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                          {Admin_orders?.ShippingAddress[language]}:
                          </span>
                          <span className="table-data">
                            Bannerghatta Main Rd, Bengaluru, Karnataka 560029
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.Quantity[language]}:</span>
                          <span className="table-data">200</span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_orders?.Department[language]}:</span>
                          <span className="table-data">Water Authority</span>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={8}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    "No Order Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && pending_order?.length !== 0 && (
        <div className="table-footer-wrap">
          <small>
          {Admin_orders?.Showing[language]} {pageData.from} to {pageData.to} of {pageData.total} {Admin_orders?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </>
  );
}
