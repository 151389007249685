import React, { useEffect,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";

import DatePicker from "react-date-picker";
import { DOCS_URL } from "../../../../Library/ApiSettings";
import TextError from "../../../../Components/Errors/TextError";
import { preventNegativeValues } from "../../../../Library/Constants/helpers";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import { Context } from "../../../../Components/Context/Context";
import{Vendor_Settings_ReuploadModal } from "../../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";

const DOCUMENT_URL = DOCS_URL;


export default function ManpowerDocumentModal(props: any) {
  const {language,setLanguage}=useContext(Context)

  const handleFileChange = (e: any, values?: any) => {
    props?.setFieldValue("manpower_documents", e.target.files[0]);
  };
  return (
    <React.Fragment>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Document Reupload"
      >
        <div className="upload-prod-outer">
          <Form className="form-wrap" onSubmit={props?.handleSubmit}>
            {" "}
            <div className="company-information-update-wrap">
              <h4>{Vendor_Settings_ReuploadModal?.HR_Documents?.[language]}</h4>
              <hr />

              <Row>
                <Col md={12} sm={12}>
                  <FormGroup>
                    <Label>
                    {Vendor_Settings_ReuploadModal?.Manpower_Documents?.[language]}{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <div className="image-input">
                      <input
                        type="file"
                        ref={props?.oemRef}
                        id="manpower_documents"
                        name="manpower_documents"
                        className="form-control"
                        accept=".pdf"
                        onChange={(e: any) =>
                        handleFileChange(e, e.target.files[0])
                        }
                        disabled={props?.list > 0 ? true : false}
                      ></input>
                      <Label
                        className="image-button"
                        for="manpower_documents"
                        id="manpower_documents"
                      >
                         {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}
                      </Label>
                    </div>
                    <ErrorMessage
                      name="manpower_documents"
                      component={TextError}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <div className="custom-input">
                      <Label>
                      {Vendor_Settings_ReuploadModal?.Date_of_in_Document?.[language]}{" "}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <DatePicker
                        value={props?.values?.manpower_date}
                        onChange={(e: any) => {
                          props?.setFieldValue("manpower_date", e);
                        }}
                        name="manpower_date"
                        format={"dd-MM-y"}
                        dayPlaceholder={"dd"}
                        monthPlaceholder={"mm"}
                        yearPlaceholder={"yyyy"}
                        maxDate={new Date()}
                      />
                    </div>
                    <ErrorMessage name="manpower_date" component={TextError} />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <div className="custom-input">
                      <Label>
                        {" "}
                        {Vendor_Settings_ReuploadModal?.No_of_Employees?.[language]}( {Vendor_Settings_ReuploadModal?.Manpower?.[language]})

                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <Input
                        name="manpower_number"
                        type="number"
                        value={props?.values?.manpower_number}
                        onChange={props?.handleChange}
                        min={props?.man_}
                        onKeyPress={preventNegativeValues}
                      />
                    </div>
                    <ErrorMessage
                      name="manpower_number"
                      component={TextError}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* {props?.values?.turn_cat && (
                <>
                  <span className="text-danger mark-end">
                    You are in Category {props?.values?.turn_cat}. Must need a
                    Minumum of {props?.man_} Employees
                  </span>
                  <span className="text-success mark-end">
                    {props?.values?.manpower_number && (
                      <>
                        {" "}
                        {props?.man_ == props?.values?.manpower_number
                          ? "Mark:10/-"
                          : ""}{" "}
                      </>
                    )}
                  </span>
                </>
              )} */}
            </div>
            <Row className="mt-3">
              <Col sm={12} className="text-end">
                <Button
                  className="btn btn-primary mb-5"
                  color="primary"
                  type="submit"
                >
                    {Vendor_Settings_ReuploadModal?.Submit?.[language]}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
}
