import axios from "axios";
import { useState,useContext } from "react";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { API_URL } from "../../../Library/ApiSettings";
import { ErrorMessage, Formik } from "formik";
import TextError from "../../../Components/Errors/TextError";
import { UploadChallanValidationSchema } from "./UploadChallanValidationSchema";
import { api } from "../../../Library/CustomApi";
import DatePicker from "react-date-picker";
import moment from "moment";
import constants from "../../../Library/Constants/Constants";
import { Context } from '../../../Components/Context/Context';
import { Department_drafts } from '../../../Library/LanguageConversion/Department_drafts';


interface proptype {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | any,
    selectedId?: any,
    setSelectedId?: Function,
    getProforma?: Function,
    selectedPaymentMode: string,
    amount: any,
    selectedPaymentType: any
}

const UploadChellanModal = ({ setIsOpen, selectedId, setSelectedId, getProforma, selectedPaymentMode, amount, selectedPaymentType }: proptype) => {
    const [files, setFile] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const initialValues = {
        payment_type: selectedPaymentType,
        ifsc: '',
        bank_name: '',
        acc_no: '',
        branch_name: '',
        acc_holder_name: '',
        amount: amount,
        cheque_no: '',
        cheque_date: '',
        transaction_no: '',
        receipt_no: '',
        file: ''
    };

    // fetch details based on ifsc code 
    const getBanKDetails = (ifsc: string, setFieldValue: Function, setFieldError: Function, setFieldTouched: Function) => {
        if (ifsc?.length == 11) {
            api
                .post(`/getifscdetails`, { ifsc_code: ifsc }, true)
                .then(async function ([success, response]) {
                    setFieldValue('bank_name', response?.data?.BANK)
                    setFieldValue('branch_name', response?.data?.BRANCH)
                    setFieldTouched('bank_name', false, false)
                    setFieldTouched('branch_name', false, false)
                    return response;
                })
                .catch((err) => {
                    setFieldError('ifsc', 'Invalid IFSC Code')
                    console.log(err);
                });
        }
    }

    // file selection 
    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };

    // submit 
    const submitHandler = (values: any, actions: any) => {
        const formData = new FormData();
        setIsLoading(true)
        formData.append("order_id", selectedId)
        formData.append("file", files);
        formData.append('payment_mode', selectedPaymentMode)
        formData.append('ifsc_code', values.ifsc)
        formData.append('bank_name', values.bank_name)
        formData.append('account_number', values.acc_no)
        formData.append('account_holder_name', values.acc_holder_name)
        formData.append('amount', amount)
        formData.append('transaction_id', values.transaction_no)
        formData.append('cheque_number', values.cheque_no)
        formData.append('cheque_date', moment(values?.cheque_date).format("YYYY-MM-DD"))
        formData.append('branch_name', values.branch_name)
        formData.append('receipt_number', values.receipt_no)
        formData.append('payment_type', values.payment_type)

        axios({
            method: "post",
            url: `${API_URL}/drafts`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            },
        })
            .then(function (response) {
                if (response?.status == 200) {
                    toast.success("Chellan Uploaded");
                    getProforma && getProforma()
                    setIsOpen(false)
                    setIsLoading(false)
                    actions?.setSubmitting(false)
                }
            })
            .catch(function (response) {
                if (response.response.status == 422) {
                    toast.error(response.response.data.message);
                }
                actions?.setSubmitting(false)
                setIsLoading(false)
            });
    }
    const{language,setLanguage}=useContext(Context)

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={UploadChallanValidationSchema}
                onSubmit={(values, actions) => {
                    submitHandler(values, actions)
                }}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    handleReset,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched,
                    touched,
                    errors,
                    isSubmitting,
                    setSubmitting
                }) => {

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <div className="custom-input">
                                            <FormGroup>
                                                <Label>{Department_drafts?.Payment_type[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="select"
                                                    name="payment_type"
                                                    disabled
                                                    onChange={(e: any) => {
                                                        handleReset()
                                                        handleChange(e)
                                                    }
                                                    } className={`${touched.payment_type && errors.payment_type ? "is-invalid" : ""}`}>
                                                    <option value="" >{Department_drafts?.Payment_type[language]}</option>
                                                    <option
                                                        value="1"
                                                        selected={selectedPaymentType == constants?.PAYMENT_TYPE?.BANK}
                                                    >{Department_drafts?.Direct_Bank_Transfer[language]}
                                                    </option>
                                                    <option
                                                        value="2"
                                                        selected={selectedPaymentType == constants?.PAYMENT_TYPE?.CHEQUE}
                                                    >{Department_drafts?.Cheque[language]}
                                                    </option>
                                                    <option
                                                        value="3"
                                                        selected={selectedPaymentType == constants?.PAYMENT_TYPE?.CHELLAN}
                                                    >{Department_drafts?.Demand_draft[language]}
                                                    </option>
                                                    <option
                                                        value="3"
                                                        selected={selectedPaymentType == constants?.PAYMENT_TYPE?.NEFT}
                                                    >{Department_drafts?.NEFT[language]}
                                                    </option>
                                                    <option
                                                        value="3"
                                                        selected={selectedPaymentType == constants?.PAYMENT_TYPE?.RTGS}
                                                    >{Department_drafts?.RTGS[language]}
                                                    </option>

                                                </Input>
                                                <ErrorMessage name="payment_type" component={TextError} />
                                            </FormGroup>
                                        </div>
                                    </Col>

                                    {/* cheque and direct transfer  */}
                                    {(values?.payment_type == '1' || values?.payment_type == '2' || values?.payment_type == '5' || values?.payment_type == '6') && <>
                                        <Col md={6}>
                                            <div className="custom-input">
                                                <FormGroup>
                                                
                                                    <Label>{Department_drafts?.IFSC[language]}
                                                        <i
                                                            className="fa fa-asterisk required-field"
                                                            color="text-danger"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Label>
                                                    <Input type="text" name="ifsc" onChange={(e: any) => {
                                                        setFieldTouched('ifsc')
                                                        setFieldValue('bank_name', '')
                                                        setFieldValue('branch_name', '')
                                                        getBanKDetails(e.target.value, setFieldValue, setFieldError, setFieldTouched)
                                                        handleChange(e)
                                                    }
                                                    } className={`${touched.ifsc && errors.ifsc ? "is-invalid" : ""}`} />
                                                    <ErrorMessage name="ifsc" component={TextError} />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Department_drafts?.Bank_name[language]}
                                                        <i
                                                            className="fa fa-asterisk required-field"
                                                            color="text-danger"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Label>
                                                    <Input type="text" name="bank_name" onChange={handleChange} value={values.bank_name} className={`${touched.bank_name && errors.bank_name ? "is-invalid" : ""}`} />
                                                    <ErrorMessage name="bank_name" component={TextError} />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Department_drafts?.Account_Number[language]}
                                                        <i
                                                            className="fa fa-asterisk required-field"
                                                            color="text-danger"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Label>
                                                    <Input type="number" name="acc_no" onChange={handleChange} value={values.acc_no} className={`${touched.acc_no && errors.acc_no ? "is-invalid" : ""}`} />
                                                    <ErrorMessage name="acc_no" component={TextError} />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Department_drafts?.Branch_name[language]}
                                                        <i
                                                            className="fa fa-asterisk required-field"
                                                            color="text-danger"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Label>
                                                    <Input type="text" name="branch_name" onChange={handleChange} value={values.branch_name} className={`${touched.branch_name && errors.branch_name ? "is-invalid" : ""}`} />
                                                    <ErrorMessage name="branch_name" component={TextError} />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Department_drafts?.Account_Holder_Name[language]}
                                                        <i
                                                            className="fa fa-asterisk required-field"
                                                            color="text-danger"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Label>
                                                    <Input type="text" name="acc_holder_name" onChange={handleChange} value={values.acc_holder_name} className={`${touched.acc_holder_name && errors.acc_holder_name ? "is-invalid" : ""}`} />
                                                    <ErrorMessage name="acc_holder_name" component={TextError} />
                                                </FormGroup>
                                            </div>
                                        </Col>

                                        {/* cheque  */}
                                        {values?.payment_type == '2' &&
                                            <>
                                                <Col md={6}>
                                                    <div className="custom-input">
                                                        <FormGroup>
                                                            <Label>{Department_drafts?.Cheque_number[language]}
                                                                <i
                                                                    className="fa fa-asterisk required-field"
                                                                    color="text-danger"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </Label>
                                                            <Input type="text"
                                                                name="cheque_no"
                                                                onChange={handleChange}
                                                                value={values.cheque_no}
                                                                className={`${touched.cheque_no && errors.cheque_no ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="cheque_no" component={TextError} />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="custom-input">
                                                        <FormGroup>
                                                            <Label>{Department_drafts?.Cheque_date[language]}
                                                                <i
                                                                    className="fa fa-asterisk required-field"
                                                                    color="text-danger"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </Label>
                                                            <DatePicker
                                                                onChange={(e: any) => {
                                                                    setFieldValue("cheque_date", e);
                                                                }}
                                                                format={"dd-MM-y"}
                                                                dayPlaceholder={"dd"}
                                                                monthPlaceholder={"mm"}
                                                                yearPlaceholder={"yyyy"}
                                                                value={values?.cheque_date}
                                                                name="cheque_date"
                                                                calendarClassName={`form-control ${touched.cheque_date &&
                                                                    errors.cheque_date
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                            />
                                                            <ErrorMessage name="cheque_date" component={TextError} />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </>
                                        }
                                        {/* Bank transfer  */}
                                        {(values?.payment_type == '1' || values?.payment_type == '5' || values?.payment_type == '6') && <Col md={6}>
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Department_drafts?.Transaction_Number[language]}
                                                        <i
                                                            className="fa fa-asterisk required-field"
                                                            color="text-danger"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Label>
                                                    <Input type="text" name="transaction_no" onChange={handleChange} value={values.transaction_no} className={`${touched.transaction_no && errors.transaction_no ? "is-invalid" : ""}`} />
                                                    <ErrorMessage name="transaction_no" component={TextError} />
                                                </FormGroup>
                                            </div>
                                        </Col>}
                                    </>}

                                    <Col md={6}>
                                        <div className="custom-input">
                                            <FormGroup>
                                                <Label>{Department_drafts?. Amount[language]}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="amount"
                                                    onChange={handleChange}
                                                    disabled
                                                    value={values.amount}
                                                    className={`${touched.amount && errors.amount ? "is-invalid" : ""}`}
                                                />
                                                <ErrorMessage name="amount" component={TextError} />
                                            </FormGroup>
                                        </div>
                                    </Col>

                                    {/* demand draft  */}
                                    {(values?.payment_type == '3' || values?.payment_type == '2') && <>
                                        {values?.payment_type == '3' && <Col md={12}>
                                            <div className="custom-input">
                                                <FormGroup>
                                                    <Label>{Department_drafts?. Receipt_Number[language]}
                                                        <i
                                                            className="fa fa-asterisk required-field"
                                                            color="text-danger"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Label>
                                                    <Input type="text" name="receipt_no" onChange={handleChange} value={values.receipt_no} className={`${touched.receipt_no && errors.receipt_no ? "is-invalid" : ""}`} />
                                                    <ErrorMessage name="receipt_no" component={TextError} />
                                                </FormGroup>
                                            </div>
                                        </Col>}
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>
                                                    {values?.payment_type == '3' && 'Upload Chellan'}
                                                    {values?.payment_type == '2' && 'Upload Cheque'}
                                                    <i
                                                        className="fa fa-asterisk required-field"
                                                        color="text-danger"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Label>
                                                <div className="image-input">
                                                    <Input
                                                        type="file"
                                                        name="file"
                                                        id="upload-cheque"
                                                        onChange={(e: any) => {
                                                            handleFileChange(e)
                                                            // setFieldTouched('file', false, false)
                                                            handleChange(e)
                                                        }}
                                                        className={`${touched.file && errors.file ? "is-invalid" : ""}`}
                                                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                                    /><Label className="image-button" for="upload-cheque" id="upload-cheque">{Department_drafts?. Choose_Documents[language]}</Label>
                                                </div>
                                                <ErrorMessage name="file" component={TextError} />
                                            </FormGroup>
                                        </Col>
                                    </>}
                                    <div>
                                        <FormGroup>
                                            <div className="text-end">
                                                <Button type="submit" color="primary">
                                                    {isSubmitting ?
                                                        <Spinner
                                                            as="span"
                                                            variant="light"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            animation="border"
                                                        />
                                                        :
                                                        'Submit'}
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Row>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}

export default UploadChellanModal