import React, { useEffect, useState ,useContext} from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";

import DatePicker from "react-date-picker";
import { DOCS_URL } from "../../../../Library/ApiSettings";
import { it_turn_mark_caluction } from "../../../../Library/Constants/helpers";
import TextError from "../../../../Components/Errors/TextError";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import constants from "../../../../Library/Constants/Constants";
import moment from "moment";

import { Context } from "../../../../Components/Context/Context";
import { Vendor_Settings_ReuploadModal } from "../../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";

const DOCUMENT_URL = DOCS_URL;
export default function EmpanelReuplaodModal(props: any) {
  
const {language,setLanguage}=useContext(Context)
  function minExpirtDate() {
    const date = new Date(props?.values?.empanelment_date);
    return props?.values?.empanelment_date
      ? date.setDate(date.getDate() + 1)
      : new Date() || new Date();
  }
  const handleFileChange = (e: any, values?: any) => {
    props?.setFieldValue("empanel_certificate", e.target.files[0]);
  };
  return (
    <>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Document Reupload"
      >
        <div className="company-information-update-wrap">
          <h4>{Vendor_Settings_ReuploadModal?.Empanel_Documents?.[language]}</h4>
          <hr />
          <Form className="form-wrap" onSubmit={props?.handleSubmit}>
          <Row>
            <Col md={12} sm={6}>
              <FormGroup className="">
                <Label>
                {Vendor_Settings_ReuploadModal?.Empanel_Certificate?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>
                <div className="image-input">
                  <Input
                    type="file"
                    id="empanel_certificate"
                    name="empanel_certificate"
                    accept=".pdf"
                    className="form-control"
                    onChange={(e: any) =>
                      handleFileChange(e, e.target.files[0])
                    }
                    disabled={props?.list > 0 ? true : false}
                  ></Input>
                  <Label
                    className="image-button"
                    for="empanel_certificate"
                    id="empanel_certificate"
                  >
                     {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}
                  </Label>
                </div>
                <ErrorMessage
                  name="empanel_certificate"
                  component={TextError}
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={6}>
              <FormGroup className="form-group date">
                <Label for="">
                {Vendor_Settings_ReuploadModal?.Empanelment_Date?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                <DatePicker
                  onChange={(e: any) => {
                    props?.setFieldValue("expiry_date", "");
                    props?.setFieldValue("empanelment_date", e);
                  }}
                  format={"dd-MM-y"}
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  value={props?.values?.empanelment_date}
                  name="empanelment_date"
                  maxDate={new Date()}
                />

                <ErrorMessage name="empanelment_date" component={TextError} />
              </FormGroup>
            </Col>

            <Col md={4} sm={6}>
              <FormGroup className="form-group date">
                <Label for="">
                {Vendor_Settings_ReuploadModal?.Empanelment_Expiry_Date?.[language]}
                  <span className="text-danger" style={{ fontSize: "18px" }}>
                    *
                  </span>
                </Label>

                <DatePicker
                  onChange={(e: any) => {
                    props?.setFieldValue("expiry_date", e);
                  }}
                  format={"dd-MM-y"}
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  value={props?.values?.expiry_date}
                  name="empanelment_date"
                  minDate={new Date(minExpirtDate())}
                />

                <ErrorMessage name="expiry_date" component={TextError} />
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm={12} className="text-end">
              <Button
                className="btn btn-primary mb-5"
                color="primary"
                type="submit"
              >
                 {Vendor_Settings_ReuploadModal?.Submit?.[language]}
              </Button>
            </Col>
          </Row>
          </Form>
        </div>
      </ModalContainer>
    </>
  );
}
