import React, { useEffect, useContext } from "react";
import { useState, useMemo } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import constants from "../../../Library/Constants/Constants";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import TicketModal from "./TicketModal";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";

const UploadValidationSchema = Yup.object().shape({
  resolved_date: Yup.string().required("Field is required").nullable(),
  ticket_status: Yup.string().required("Field is required").nullable(),
  comment: Yup.string().required("Field is required").nullable(),
  time_taken: Yup.string().required("Field is required").nullable(),
  resolved_date_start: Yup.string().required("Field is required").nullable(),
  resolved_date_end: Yup.string().required("Field is required").nullable(),

});
const UploadValidationSchemaone = Yup.object().shape({
  ticket_status: Yup.string().required("Field is required").nullable(),
  comment: Yup.string().required("Field is required").nullable(),
 });
export default function OpenTicketsVendor(props: any) {
  const [loading, setLoading] = useState(false);
  const [received_order, setReceivedOrder] = useState([]);
  const [isShowing, setIsShowing] = useState(false);
  const [pageData, setPageData] = useState<any>();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { language, setLanguage } = useContext(Context);
  const [value, setValue] = useState({
    id: "",
    ids: "",
    open: 0
  })
  const initialValues = {
    resolved_date: "",
    ticket_status: "",
    comment: "",
    time_taken: "",
    resolved_date_start: "",
    resolved_date_end: ""
  }
  useEffect(() => {
    getReceivedOrder(currentPage);
  }, [search, currentPage]);
  // ========================================================== Get Recieved Order ==========================================================


  const getReceivedOrder = (page: any) => {
    setLoading(true);
    api
      .get(`/service-maintenance?status=1&page=${page}&search_keyword=${search}`, true)
      .then(async function ([success, response]) {
        setReceivedOrder(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ================================================================ View ==================================================================

  const onChange = (e: any) => {
    setSearch(e.target.value)
  }

  function stateType(val: any) {
    var stval = Object.entries(constants.TICKET_TYPE)
    var filterval = stval.filter(([key, value]) => value == val)
    var finalval = filterval[0][0].toLowerCase();
    var capitilice = finalval.charAt(0).toUpperCase();
    var splitremainletters = finalval.slice(1);
    finalval = capitilice + splitremainletters;

    return finalval
  }

  function statePriority(val: any) {
    var stval = Object.entries(constants.PRIORITY)
    var filterval = stval.filter(([key, value]) => value == val)
    var finalval = filterval[0][0].toLowerCase();
    var capitilice = finalval.charAt(0).toUpperCase();
    var splitremainletters = finalval.slice(1);
    finalval = capitilice + splitremainletters;

    return finalval
  }
  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions)
  }

  const sendData = (values: any, actions: any) => {
    let resolve_date = new Date(values?.resolved_date)
    let end_date = new Date(values?.resolved_date_end)
    let resolve_date_format = moment(resolve_date).format('DD-MM-YYYY')
    let end_date_format = moment(end_date).format('DD-MM-YYYY')
    if (resolve_date_format !== end_date_format) {
      toast.error("Resolved date &  Resolved last date is not equal")
      actions.setSubmitting(false);
    }
    else {
      api
        .post(`/order/ticket-update`, {
          time_taken: values?.time_taken,
          resolve_date: values?.resolved_date,
          vendor_comments: values?.comment,
          vendor_id: value?.id,
          ticket_status: values?.ticket_status,
          id: value?.ids
        }, true)
        .then(async function ([success, response]) {
          if (response?.status == "success") {
            toast.success("Ticket Status Updated!")
            setIsShowing(false);
            actions.resetForm();
            actions.setSubmitting(false);
            getReceivedOrder(1)
          }
          else {
            actions.setSubmitting(true);
          }
          return response;
        })
        .catch((err) => {
          actions.setSubmitting(true);
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="custom-tab-content">
        <div className="table-outer-wrap">
          <div className="search-widget-outer">
            <Form role="search">
              <InputGroup className="search-group">
                <InputGroupText title="Search here" className="search-icon" id="search">
                  <i className="fa-sharp fa-solid fa-magnifying-glass" />
                </InputGroupText>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  aria-label="Username"
                  aria-describedby="search"
                  onChange={onChange}
                />
              </InputGroup>
            </Form>
          </div>
          <div className="table-wrap responsive-table">
            <Table className="w-100">
              <thead>
                <tr>
                  <th>{VendorRegLang?.sl_no[language]}</th>
                  <th>{VendorRegLang?.request_by[language]}</th>
                  <th>{VendorRegLang?.products[language]}</th>
                  <th>{VendorRegLang?.type[language]}</th>
                  <th>{VendorRegLang?.priority[language]}</th>
                  <th>{VendorRegLang?.date[language]}</th>
                  <th>{VendorRegLang?.action[language]}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      className="text-center px-2 py-5 empty-text"
                      colSpan={7}
                    >
                      <div className="d-flex justify-content-center">
                        {" "}
                        <Loader />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {
                      received_order?.length > 0 ?

                        received_order?.map((item: any, index: any) => {
                          return (
                            <>
                              {" "}
                              <tr>
                                <td><span className="mobile-heading">{VendorRegLang?.sl_no[language]}:</span><span className="table-data">#{index + 1}</span></td>
                                <td><span className="mobile-heading">{VendorRegLang?.request_by[language]}:</span><span className="table-data">{item?.department?.name ? item?.department?.name : '-'}</span></td>
                                <td><span className="mobile-heading">{VendorRegLang?.products[language]}:</span>
                                <span className="table-data">
                                  <span className="green-text">{item?.serviceproduct?.name}</span><br/>
                                    <span>{VendorRegLang?.product_code[language]}: <span className="green-text normal">{item?.serviceproduct?.product_code}</span></span><br />
                                    <span>{VendorRegLang?.hsn_code[language]}: <span className="green-text normal">{item?.serviceproduct?.hsn_code}</span></span>                                 
                                    <span className="text-clamp"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item?.serviceproduct?.features,
                                    }}>
                                  </span>
                                  </span>
                                </td>
                                <td>
                                <span className="mobile-heading">{VendorRegLang?.type[language]}:</span>
                                  <span className="table-data">{stateType(item?.issue_type)}</span>
                                </td>
                                <td>
                                <span className="mobile-heading">{VendorRegLang?.priority[language]}:</span>
                                  <span className="table-data">{statePriority(item?.priority)}</span>
                                </td>
                                <td>
                                  <span className="mobile-heading">{VendorRegLang?.date[language]}:</span>
                                  <span className="table-data">{item?.request_date ? item?.request_date : "-"}</span>
                                </td>
                                <td><span className="mobile-heading">{VendorRegLang?.action[language]}:</span><span className="table-data"><Link to="#" onClick={() => {
                                  setIsShowing(true);
                                  setValue({
                                    ...value,
                                    id: item?.vendor?.id||localStorage.getItem("USER_ID"),
                                    ids: item?.id,
                                    open: 1,
                                  })
                                }}><i title="View" className="icons bi bi-eye lg"></i></Link></span></td>
                              </tr>
                            </>
                          );
                        })
                        :
                        <tr>
                          <td
                            className="text-center px-2 py-5 empty-text"
                            colSpan={7}
                          >
                            {VendorRegLang?.no_data_found[language]}
                          </td>
                        </tr>
                    }
                  </>)}
              </tbody>
            </Table>
          </div>
          {pageData && (
            <div className="table-footer-wrap">
              <small>
                {VendorRegLang?.showing[language]} {pageData?.from != null ? pageData?.from : 0} {VendorRegLang?.to[language]} {pageData?.to != null ? pageData?.to : 0} {VendorRegLang?.of[language]} {pageData?.total != null ? pageData?.total : 0}{" "}
                {VendorRegLang?.entries[language]}
              </small>
              <div className="pagination">
                <CustomPagination
                  totalItems={pageData?.total}
                  itemsPerPage={pageData?.per_page}
                  activePage={pageData.current_page}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={value?.open == 1 ? UploadValidationSchemaone : UploadValidationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          return (

            <TicketModal
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              handleReset={handleReset}
              setValue={setValue}
              value={value}
            // order_view={order_view}
            />
          );
        }}
      </Formik>
    </>
  );
}