import React, { useState} from 'react'
import QAVsidebar from '../Components/QAVsidebar';
import { Col } from 'reactstrap';
import QAVheader from '../Components/QAVheader';


const QAVendorLayout = ({ children }: any) => {

    const [openSidebar, setOpenSidebar] = useState(false); // mobile view
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true); // web view

    

    return (
        <>
            {/*  sidebar  */}
            <QAVsidebar
                setOpenSidebar={setOpenSidebar}
                openSidebar={openSidebar}
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
            />

            {/* right side section which includes body and header  */}
            <Col
                md={sidebarIsOpen ? '10' : '12'}
                className={sidebarIsOpen ?
                    'offset-md-2 minimise-content'
                    : 'expand-content'}
            >
                {/* <Header setOpenSidebar={setOpenSidebar} openSidebar={openSidebar} /> */}
                <QAVheader
                    setOpenSidebar={setOpenSidebar}
                    openSidebar={openSidebar}
                />

                <div
                    className="dashboard-outer"
                    id="content"
                >
                    <main>{children}</main>
                </div>
            </Col>
        </>
    )
}

export default QAVendorLayout