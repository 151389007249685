import { Link, NavLink } from "react-router-dom";
import {
    List
} from "reactstrap";
import { Children, useContext } from "react";
import { Admin_tds } from "../../../Library/LanguageConversion/Admin_Tds";
import { Context } from "../../../Components/Context/Context";
const TDSList = ({ children }: any) => {
    const {language,setLanguage} = useContext(Context)
    return (
        <>
            <div className="right-content-wrap">
                <div className="breadcrumb-tabs-outer-wrap">
                    {/* tabs */}
                    <div className="custom-tabs">
                        <NavLink to="/tds-list">
                        {Admin_tds?.TDSFromDepartments[language]}
                        </NavLink>
                        {/* <NavLink to="/tds-to-vendor-list">
                            TDS To Vendor
                        </NavLink> */}

                    </div>
                    {/* breadcrumb */}
                    <List className="breadcrumb">
                        <li>
                            <Link className="active" to="/dashboard">
                            {Admin_tds?.Dashboard[language]}
                            </Link>
                        </li>
                        &nbsp; / &nbsp;
                        <li><b>{Admin_tds?.TDS[language]}</b></li>
                    </List>
                </div>
                <div className="custom-tab-content">
                    {children}
                </div>
            </div>
        </>
    )
}

export default TDSList
