export const Admin_Delivered:any = {   
    // Admin/Delivered/DeliveredLayout
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    // Admin/Delivered/Modal/PaymentToVendors
    Quantity:{
        en: 'Quantity',
        ka: 'ಪ್ರಮಾಣ'
    },
    Pieces:{
        en: 'Pieces',
        ka: 'ತುಕಡಿಗಳು'
    },
    UnitPrice:{
        en: 'Unit Price',
        ka: 'ಘಟಕ ಬೆಲೆ'
    },
    OrderID:{
        en: 'Order ID',
        ka: 'ಆದೇಶ ಐಡಿ'
    },
    VendorName:{
        en: 'Vendor Name',
        ka: 'ವೇಂದರ್ ಹೆಸರು'
    },
    VendorNameOrder: {
        en: 'Vendor Name/Order ID',
        ka: 'ವೆಂಡರ್ ಹೆಸರು/ಆದೇಶ ಐಡಿ'
    },
    TransactionNumber:{
        en: 'Transaction Number',
        ka: 'ಲಂಬಿತ ಸಂಖ್ಯೆ'
    },
    TransferMoneytoVendor:{
        en: 'Transfer Money to Vendor',
        ka: 'ವೆಂಡರಿಗೆ ಹಣ ಹೂಡಿಕೆ ಮಾಡಿ'
    },
    // Admin/Delivered/Tabs/CancelledOrders
    OrderDetails:{
        en: 'Order Details',
        ka: 'ಆರ್ಡರ್ ವಿವರಗಳು'
    },
    ShippingAddress:{
        en: 'Shipping Address',
        ka: 'ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ'
    },
    Price:{
        en: 'Price',
        ka: 'ಬೆಲೆ'
    },
    Department:{
        en: 'Department',
        ka: 'ಇಲಾಖೆ'
    },
    Status:{
        en: 'Status',
        ka: 'ಸ್ಥಿತಿ'
    },
    Unitprice:{
        en: 'Unit price',
        ka: 'ಘಟಕ ಬೆಲೆ'
    },
    Total:{
        en: 'Total',
        ka: 'ಒಟ್ಟು'
    },
    BuildingNo:{
        en: 'Building No',
        ka: 'ಕಟ್ಟಡ ಸಂಖ್ಯೆ'
    },
    Pincode:{
        en: 'Pincode',
        ka: 'ಪಿನ್‌ಕೋಡ್'
    },
    Cancelled:{
        en: 'Cancelled',
        ka: 'ರದ್ದುಗೊಂಡಿದೆ'
    },
    // Admin/Delivered/Tabs/DeliveredOrders
    SlNo:{
        en: 'Sl No',
        ka: 'ಕ್ರಮ ಸಂಖ್ಯೆ'
    },
    OrderStatus:{
        en: 'Order Status',
        ka: 'ಆದೇಶ ಸ್ಥಿತಿ'
    },
    Feedback:{
        en: 'Feedback',
        ka: 'ಪ್ರತಿಕ್ರಿಯೆ'
    },
    DepartmentName:{
        en: 'Department Name',
        ka: 'ಇಲಾಖೆಯ ಹೆಸರು'
    },
    Action:{
        en: 'Action',
        ka: 'ಕ್ರಿಯೆ'
    },
    ProductName:{
        en: 'Product Name',
        ka: 'ಉತ್ಪಾದನ ಹೆಸರು'
    },
    Message:{
        en: 'Message',
        ka: 'ಸಂದೇಶ'
    },
    NoMessageAdded:{
        en: 'No Message Added',
        ka: 'ಸಂದೇಶಯಾವುದೇ ಸಂದೇಶ ಸೇರಿಸಲಾಗಿಲ್ಲ'
    },
    Updatedat:{
        en: 'Updated at',
        ka: 'ಅದ್ಯಾವತ್ತಿತವಾಗಿದೆ'
    },
    NoFeedbackFound:{
        en: 'No Feedback Found',
        ka: 'ಯಾವುದೇ ಪ್ರತಿಪಾದನೆ ಕಂಡುಬಂದಿಲ್ಲ'
    },

    DeliveryDetails:{
        en: 'Delivery Details',
        ka: 'ವಿತರಣೆ ವಿವರಗಳು'
    }, 

    Location:{
        en: 'Location',
        ka: 'ಸ್ಥಳ'
    },
   
    Delivered_Orders:{
        en: 'Delivered Orders',
        ka: 'ಆದೇಶಗಳನ್ನು ವಿತರಿಸಲಾಗಿದೆ'
    },
    
    Showing:{
        en: 'Showing',
        ka: 'ತೋರಿಸಲಾಗುತ್ತಿದೆ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ನ'
    },
    entries:{
        en: 'entries',
        ka:'ನಮೂದುಗಳು'
    },
    
    document_verification:{
        en:"Document Verification",
        ka:"ಡಾಕ್ಯುಮೆಂಟ್ ಪರಿಶೀಲನೆ"
    },
}