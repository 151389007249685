import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { useContext } from "react";
import React, {
    BaseSyntheticEvent,
    ChangeEvent,
    useEffect,
    useState,
} from "react";
import { Link } from "react-router-dom";

import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import Select from "react-select";
import TextError from "../../../Components/Errors/TextError";
import { api } from "../../../Library/CustomApi";
import constants from "../../../Library/Constants/Constants";
import { company_mses, gst_class, vendor_types } from "../../../Library/Constants/MapConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import { Context } from "../../../Components/Context/Context";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";


const AdvancedReDocUpload = (props: any) => {
   
    const {language,setLanguage}=useContext(Context)
    const currentYear = new Date().getFullYear(); // 2020
    const previousYear = currentYear - 1;
    const nest_yr = previousYear - 1
    const handleDate = (e: any) => {
        props?.setFieldValue("reupload_date", e);
    }
    const handleFilechange = (e: any, values?: any) => {
        const myMemoObj = URL.createObjectURL(e.target.files[0]);
        props?.setFieldValue("pan", e.target.files[0])



    }
    let gst_class_ = constants?.ADVANCED_VERIFY?.GSTCLASSNAME ? "GST Class Name" : null;

    let pan =
        props?.type == constants.DOCUMENTS.PAN ? "Pancard Number" : null;
    let vendor_type =
        props?.type == constants.ADVANCED_VERIFY.VENDORTYPE ? "Vendor Type" : null;
    let oem =
        props?.type == constants.DOCUMENTS.OEM ? "OEM" : null;
    let adar =
        props?.type == constants.DOCUMENTS.AADAR ? "MAF" : null;
    let addr =
        props?.type == constants.DOCUMENTS.AADRESS_PROOF
            ? "Address Proof"
            : null;
    let incorporation =
        props?.type == constants.DOCUMENTS.INCORP
            ? "Incoporation"
            : null;
    let mse =
        props?.type == constants.DOCUMENTS.MSE ? "MSE" : null;
    let gst =
        props?.type == constants.DOCUMENTS.GST ? "GST" : null;
    let t1 =
        props?.type == constants.DOCUMENTS.TURN_OVER1
            ? "TURN_OVER1"
            : null;
    let t2 =
        props?.type == constants.DOCUMENTS.TURN_OVER2
            ? "TURN_OVER2"
            : null;
    let t3 =
        props?.type == constants.DOCUMENTS.TURN_OVER3
            ? "TURN_OVER3"
            : null;
    let iso =
        props?.type == constants.DOCUMENTS.ISO ? "ISO" : null;
    let chellan = props?.type == constants.DOCUMENTS.CHELLAN ? "Chellan" : null;
    let cheque = props?.type == constants.DOCUMENTS.CHEQUE ? "Cheque" : null;
    let dd = props?.type == constants.DOCUMENTS.DD ? "DD" : null;
    let company_email = props?.type == constants.ADVANCED_VERIFY?.COMPANYEMAIL ? "Company Email" : null;
    let company_name = props?.type == constants.ADVANCED_VERIFY?.COMPANYNAME ? "Company Name" : null;
    let incop_data = props?.type == constants.ADVANCED_VERIFY?.INCORP ? "Company Incorporation Date" : null;
    let personal_name = constants.ADVANCED_VERIFY.CONTACTPERSONNAME ? "Contact Personal Name" : null
    return (
        <React.Fragment>
            <ModalContainer
                open={props?.isShowing}
                setOpen={() => {
                    props?.setIsShowing(false);
                    props?.handleReset();
                }}
                header='Document Reupload'
            >
                <div className="upload-prod-outer">
                    <Form className="form-wrap" onSubmit={props?.handleSubmit}>
                        <Row>
                            <Col md={12} sm={12}>
                                {props?.type == constants.ADVANCED_VERIFY.VENDORTYPE && (
                                    <FormGroup>
                                        <div className="custom-input">
                                            <Label>
                                            {Vendor_profileUpdation?.Vendor_Type?.[language]} 
                                                <span
                                                    className="text-danger"
                                                    style={{ fontSize: "18px" }}
                                                >
                                                    *
                                                </span>
                                            </Label>
                                            <Input
                                                type="select"
                                                name="reupload_name"
                                                value={props?.values?.reupload_name}
                                                onChange={props?.handleChange}
                                                className={`form-control ${props?.touched.reupload_name && props?.errors.reupload_name
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}

                                            >
                                                <option value="">{Vendor_profileUpdation?.Select?.[language]} </option>
                                                {vendor_types?.map((item: any) => {
                                                    return (
                                                        <>
                                                            <option value={item?.value}>
                                                                {item?.name}
                                                            </option>
                                                        </>
                                                    );
                                                })}
                                            </Input>
                                            <ErrorMessage
                                                name="reupload_name"
                                                component={TextError}
                                            />
                                        </div>
                                    </FormGroup>

                                )}
                            </Col>

                            {(props?.type == constants.ADVANCED_VERIFY.PAN ||
                                props?.type == constants.ADVANCED_VERIFY.COMPANYNAME ||
                                props?.type == constants.ADVANCED_VERIFY.CONTACTPERSONNAME ||
                                props?.type == constants.ADVANCED_VERIFY.GST
                            )
                                && (<FormGroup>
                                    <div className="custom-input">
                                        <Label>
                                            {
                                                pan ||
                                                oem ||
                                                mse ||
                                                gst ||
                                                t1 ||
                                                t2 ||
                                                t3 ||
                                                iso ||
                                                incorporation ||
                                                adar ||
                                                addr || chellan || cheque || dd || company_email ||
                                                company_name || personal_name
                                            }{" "} <span className="text-danger" style={{ fontSize: "18px" }}>*</span>
                                        </Label>


                                        <Input
                                            type="text"
                                            name="reupload_name"
                                            value={props?.values?.reupload_name}
                                            onChange={props?.handleChange}
                                            className={`form-control ${props?.touched.reupload_name && props?.errors.reupload_name
                                                ? "is-invalid"
                                                : ""
                                                }`}

                                        ></Input>
                                    </div>
                                    <ErrorMessage
                                        name="reupload_name"
                                        component={TextError}
                                    />
                                </FormGroup>)
                            }


                            {props?.type == constants.ADVANCED_VERIFY.GSTCLASSNAME && (
                                <FormGroup>
                                    <div className="custom-input">
                                        <Label>
                                        {Vendor_profileUpdation?.GST_CLass?.[language]}  
                                            <span
                                                className="text-danger"
                                                style={{ fontSize: "18px" }}
                                            >
                                                *
                                            </span>
                                        </Label>
                                        <Input
                                            type="select"
                                            name="reupload_name"
                                            value={props?.values?.reupload_name}
                                            onChange={props?.handleChange}
                                            className={`form-control ${props?.touched.reupload_name && props?.errors.reupload_name
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        >
                                            <option value="">{Vendor_profileUpdation?.Select?.[language]} </option>
                                            {gst_class?.map((item: any) => {
                                                return (
                                                    <>
                                                        <option value={item?.value}>
                                                            {item?.name}
                                                        </option>
                                                    </>
                                                );
                                            })}
                                        </Input>
                                        <ErrorMessage name="reupload_name" component={TextError} />
                                    </div>
                                </FormGroup>
                            )}


                            {(props?.type == constants.ADVANCED_VERIFY.COMPANYEMAIL ||
                                props?.type == constants.ADVANCED_VERIFY.EMAILID) && (
                                    <FormGroup>
                                        <div className="custom-input">
                                            <Label>
                                            {Vendor_profileUpdation?.Company_Email?.[language]}
                                                <span
                                                    className="text-danger"
                                                    style={{ fontSize: "18px" }}
                                                >
                                                    *
                                                </span>
                                            </Label>
                                            <Input
                                                name="reupload_name"
                                                type="email"
                                                value={props?.values?.reupload_name}
                                                className={`form-control ${props?.touched.reupload_name && props?.errors.reupload_name
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                onChange={props?.handleChange}

                                            />
                                            <ErrorMessage
                                                name="reupload_name"
                                                component={TextError}
                                            />
                                        </div>
                                    </FormGroup>
                                )}

                            {(props?.type == constants.ADVANCED_VERIFY.COMPANYCONTACTNUMBER ||
                                props?.type == constants.ADVANCED_VERIFY.CONTACTNUMBER) && (
                                    <FormGroup>
                                        <div className="custom-input">
                                            <Label>
                                            {Vendor_profileUpdation?.Company_Number?.[language]}  
                                                <span
                                                    className="text-danger"
                                                    style={{ fontSize: "18px" }}
                                                >
                                                    *
                                                </span>
                                            </Label>
                                            <Input
                                                name="reupload_name"
                                                type="number"
                                                value={props?.values?.reupload_name}
                                                className={`form-control ${props?.touched.reupload_name && props?.errors.reupload_name
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                                onChange={props?.handleChange}

                                            />
                                            <ErrorMessage
                                                name="reupload_name"
                                                component={TextError}
                                            />
                                        </div>
                                    </FormGroup>)}

                            {props?.type == constants.ADVANCED_VERIFY.INCORP && (<FormGroup>
                                <Label for="">
                                {Vendor_profileUpdation?.Company_Incorporation?.[language]}  
                                    <i
                                        className="fa fa-asterisk required-field"
                                        color="text-danger"
                                        aria-hidden="true"
                                    ></i>
                                </Label>


                                <DatePicker
                                    name="reupload_date"
                                    placeholderText={"dd-mm-yyyy"}
                                    dateFormat="dd-MM-yyyy"
                                    selected={props.values.reupload_date}
                                    value={props.values.reupload_date}
                                    onChange={(e: any) => {
                                        handleDate(e)
                                    }}
                                    maxDate={new Date()}
                                    className={`form-control ${props?.touched.reupload_date &&
                                        props?.errors.reupload_date
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                    onKeyDown={(e: any) => {
                                        e.preventDefault();
                                    }}
                                />
                                <ErrorMessage name="reupload_date" component={TextError} />
                            </FormGroup>)}

                        </Row>
                        <Row className="mt-3">

                            <Col sm={12} className="text-end">
                                <Button className='btn btn-primary mb-5' color='primary' type="submit" >{Vendor_profileUpdation?.Submit?.[language]}</Button>
                            </Col>
                        </Row>


                    </Form>
                </div>
            </ModalContainer>
        </React.Fragment >
    );
};

export default AdvancedReDocUpload;
