import React, { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button, List } from "reactstrap";

import { Context } from "../../../Components/Context/Context";
import { Admin_RateContract } from "../../../Library/LanguageConversion/Admin_RateContract";

export default function RateContractLayout(props: any) {
  const {language, setLanguage} = useContext(Context)

  const { pathname } = useLocation();
  return (
    <React.Fragment>
      {/* inner contents starts here */}
      <div className="right-content-wrap">
        {/* tabs and bradcrumbs flex */}
        <div className="breadcrumb-tabs-outer-wrap">
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link to="/dashboard" className="active">
                {Admin_RateContract?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>{Admin_RateContract?.Upload_Rate[language]}</b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
