import React, { useEffect,useContext } from "react";
import {Link } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import { API_URL } from "../../../../Library/ApiSettings";
import { useState } from "react";
import { Table, Badge } from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import StartupDocumentModal from "./StartUpModal";
import constants from "../../../../Library/Constants/Constants";
import { DocumentTYpeUpload } from "../../../../Library/Constants/helpers";
import { Context } from "../../../../Components/Context/Context";
import { Vendor_DocumentView } from "../../../../Library/LanguageConversion/Vendor_DocumentView";
export default function StartupdocumentsView(props: any) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { language, setLanguage } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [id, setId] = useState<any>("");
  useEffect(() => {
    setId(localStorage?.getItem("USER_ID"));
  }, [id]);
  const vendor_id: any = id;
  const [documents, setDocument] = useState<any>([]);
  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = () => {
    setLoading(true);
    api
      .get(
        `/documents`,

        true
      )
      .then(async function ([success, response]) {
        setDocument(response?.data || []);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const download_documents = async (id: any) => {
    const requestOptions: any = {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
    fetch(`${API_URL}/documents/${id}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Document.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  };
  return (
    <>
      <div className="company-information-update-wrap">
        <div className="table-wrap responsive-table">
          <Table className="w-100 table-striped">
            <thead>
              <tr>
                <th>{Vendor_DocumentView?.Sl_No?.[language]}</th>
                <th>{Vendor_DocumentView?.Document_Type?.[language]}</th>
                {/* <th>Valid Upto</th> */}
                <th>{Vendor_DocumentView?.Document?.[language]}</th>
                {/* <th>Action</th> */}
                {/* <th>System Notification</th> */}
                <th>{Vendor_DocumentView?.View?.[language]}</th>
                <th>{Vendor_DocumentView?.Status?.[language]}</th>
              </tr>
            </thead>
            <tbody>
              {documents?.length !== 0 ? (
                <>
                  {documents?.filter((it:any)=> {return it?.type?.id != 5}).map((item: any, i: any) => {
                    return (
                      <React.Fragment key={i}>
                        <tr>
                          <td>
                            <span className="mobile-heading">{Vendor_DocumentView?.Sl_No?.[language]}</span>
                            <span className="table-data">{i + 1}</span>
                          </td>
                          <td>
                            <span className="mobile-heading">{Vendor_DocumentView?.Documents?.[language]}</span>
                            <span className="table-data">
                            {DocumentTYpeUpload(item?.type?.id)}
                            </span>
                            </td>
                          <td>
                            {" "}
                            <td>
                            <span className="mobile-heading">{Vendor_DocumentView?.Action?.[language]}</span>
                              <div className="action-flex-btn">
                                <Link to=""
                                  onClick={() => {
                                    download_documents(item?.id);
                                  }}
                                  rel="noreferrer"
                                  className="rounded-tag"
                                >
                                  <i
                                    title="View"
                                    className="icons bi bi-download lg"
                                  ></i>
                                </Link>
                              </div>
                            </td>
                          </td>

                          <td>
                            <span className="mobile-heading">{Vendor_DocumentView?.View?.[language]}</span>
                            {(
                              // item?.type.id ==
                              // constants?.DOCUMENTS?.START_UP_CERIFICATE ||
                              // item?.type.id ==
                              //   constants?.DOCUMENTS?.BLACK_LIST_DOCUMENT ||
                              item?.type.id ==
                                constants?.DOCUMENTS?.POWER_ATO ||
                              item?.type?.id ==
                                constants?.DOCUMENTS?.UDYAM_DFO ||
                              item?.type?.id ==
                                constants?.DOCUMENTS?.NO_CASE_DOCUMENT ||
                              item?.type?.id ==
                                constants?.DOCUMENTS?.HR_ANY_OFFICE ||
                              item?.type?.id ==
                                constants?.DOCUMENTS?.HR_MANPOWER ||
                              item?.type?.id == constants?.DOCUMENTS?.HR_LAND ||
                              item?.type?.id == 35 ||
                              item?.type?.id == 36 ||
                              item?.type?.id ==
                                constants?.DOCUMENTS?.IT_TURN_OVER_ONE ||
                              item?.type?.id ==
                                constants?.DOCUMENTS?.IT_TURN_OVER_ONE
                                 ||
                              item?.type?.id == constants?.DOCUMENTS?.EMPANEL ||
                              item?.type?.id == constants?.DOCUMENTS?.OEM 
                              // ||
                              // item?.type?.id == constants?.DOCUMENTS?.MSE
                              ) && (
                              <>
                                <i
                                  title="View"
                                  className="icons pointer bi bi-eye lg"
                                  onClick={() => {
                                    if (
                                      // item?.type.id ==
                                      //   constants?.DOCUMENTS
                                      //     ?.START_UP_CERIFICATE ||
                                      // item?.type.id ==
                                      //   constants?.DOCUMENTS
                                      //     ?.BLACK_LIST_DOCUMENT ||
                                      item?.type.id ==
                                        constants?.DOCUMENTS?.POWER_ATO ||
                                      item?.type?.id ==
                                        constants?.DOCUMENTS?.UDYAM_DFO ||
                                      // item?.type?.id ==
                                      //   constants?.DOCUMENTS
                                      //     ?.NO_CASE_DOCUMENT ||
                                      item?.type?.id ==
                                        constants?.DOCUMENTS?.HR_ANY_OFFICE ||
                                      item?.type?.id ==
                                        constants?.DOCUMENTS?.HR_MANPOWER ||
                                        item?.type?.id == 35 ||
                                        item?.type?.id == 36 ||

                                      item?.type?.id ==
                                        constants?.DOCUMENTS?.HR_LAND ||
                                      item?.type?.id ==
                                        constants?.DOCUMENTS?.EMPANEL ||
                                      item?.type?.id ==
                                        constants?.DOCUMENTS?.OEM 
                                      //   ||
                                      // item?.type?.id ==
                                      //   constants?.DOCUMENTS?.MSE
                                    ) {
                                      setShow(true);
                                    }
                                    setData(item);
                                  }}
                                ></i>
                              </>
                            )}
                          </td>
                          <td>
                          <span className="mobile-heading">{Vendor_DocumentView?.Status?.[language]}</span>
                            {item?.status?.name == "APPROVED" ? (
                              <Badge color="success">{Vendor_DocumentView?.Approved?.[language]}</Badge>
                            ) : item.status?.name == "REJECTED" ? (
                              <Badge color="danger">{Vendor_DocumentView?.Rejected?.[language]}</Badge>
                            ) : item.status?.name == "REUPLOAD" ? (
                              <Badge color="info">{Vendor_DocumentView?.Reuploaded?.[language]}</Badge>
                            ) : (
                              <Badge color="success">{Vendor_DocumentView?.Uploaded?.[language]}</Badge>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={7} className="text-center p-5">
                    {loading ? (
                      <div className="text-center py-5">
                        {" "}
                        <Loader />{" "}
                      </div>
                    ) : (
                      "No data added"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {show && (
        <>
          <StartupDocumentModal setShow={setShow} show={show} data={data} />
        </>
      )}
    </>
  );
}
