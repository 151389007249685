import { ErrorMessage, FieldArray, Formik } from "formik";
import React, { useState, useEffect,useContext } from "react";
import {
  Button, Input, Row,
  Col,
  Form,
  FormGroup,
  Label
} from "reactstrap";
import TextError from "../../../../../../../Components/Errors/TextError";
import { customOrderValidation } from "../../CustomOrderValidation";
import { api } from "../../../../../../../Library/CustomApi";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../../../../../Library/ApiSettings";
import { toast } from "react-toastify";
import { Context } from "../../../../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../../../../Library/LanguageConversion/Department_Products";

interface Specification {
  name: string;
  value: string;
  parent: string;
}

interface SpecId {
  product_id: string;
  spec_meta_id: string;
  key: string;
  value: string;
}

export default function AddCustomOrder(props: any) {


  const { language, setLanguage } = useContext(Context);
  const [specifications, setSpecifications] = useState<Specification[]>([]);
  const [category, setCategory] = useState<any>();
  const [spec_data, setSpecData] = useState([]);
  const [Specvalue, setSpecValue] = useState<SpecId[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getCategory()
    setSpecifications([])
  }, [])

  const initialValues = {
    product_name: '',
    quantity: '',
    ref_links: '',
    comments: '',
    category: '',
    specifications: '',
    cat_id: '',
    spec_details: [],
    spec_all: [],
  };

  // get categories 
  const getCategory = () => {
    api
      .get(`/product/categories`, true)
      .then(async function ([success, response]) {
        const arr: { value: number; label: string }[] = [];
        response?.data?.forEach((elem: any) => {
          arr.push({ value: elem.id, label: elem.name });
        });
        setCategory(arr);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get spec 
  const getSpecification = (id: any) => {
    api
      .get(`/product/collectmetaspecifications/${id}`, true)
      .then(async function ([success, response]) {
        response?.map((item: any) => {
          item.spec_details = "";
        });
        setSpecData(response || []);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // add spec 
  const handleValueChange = (
    index: number,
    field: keyof SpecId,
    values: string,
    e: any,
    setFieldValue: Function
  ) => {
    const specData: SpecId[] = [...Specvalue];
    const { name, value } = e.target;
    const list: any = [...spec_data];
    list[index][`spec_details`] = value;
    const data: any = [];
    list.map((elem: any) => {
      if (elem.spec_details !== "") {
        data.push({ name: elem.name, value: elem.spec_details });
      }
    });
    const d: any = JSON.stringify(data);
    const jsonStr = d;
    const jsonArray = JSON.parse(jsonStr);
    const formattedArray = jsonArray
      .map((item: any) => `<b>${item.name}</b> : ${item.value}<br>`)
      .join(" ");
    // setFieldValue("product_description", formattedArray);
    setFieldValue("spec_details", list);
    // console.log(formattedArray, "formattedArray")
  };

  // specification change handle 
  const handleSpecificationChange = (
    index: number,
    field: keyof Specification,
    value: string,
    setFieldValue: Function
  ) => {

    const newSpecifications: Specification[] = [...specifications];
    newSpecifications[index][field] = value;
    setSpecifications(newSpecifications);
    setFieldValue('specifications', newSpecifications)

  };

  // remove custom spec 
  const handleRemove = (index: number, setFieldValue: Function) => {
    const filteredSpec = specifications.filter((_, i) => i !== index);
    setSpecifications(filteredSpec);
    setFieldValue("specifications", filteredSpec);
  };

  // submit handler 
  const handleSubmit = (values: any, actions: any) => {
    setIsLoading(true)

    // spec values 
    let spec_val: any = []
    if (values?.spec_details?.length !== 0) {
      values?.spec_details?.map((obj: any) => {
        spec_val.push({
          spec_meta_id: obj?.id,
          spec_key: obj?.name,
          spec_value: obj?.spec_details

        })
      })
    }

    // custom spec 
    let spec_all: any = []
    if (specifications?.length !== 0) {
      values?.specifications?.map((obj: any) => {
        spec_all.push({ "spec_key": obj?.name, "spec_value": obj?.value })
      })
    }

    api
      .post(
        `/order/request`,
        {
          product_name: values?.product_name,
          quantity: values?.quantity,
          ref_links: values?.ref_links,
          comments: values?.comments,
          cat_id: values?.cat_id,
          spec_data: spec_val,
          spec_all: spec_all
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          toast.success("Custom Order Added Successfully!");
          props.getCustomOrders()
          props?.setIsOpen(false)
          setIsLoading(false)
        }
        return response;
      })
      .catch((err) => {
        toast.error('Unexpected Error Occured')
        actions.setSubmitting(false);
        setIsLoading(false)
      });
  }

  return (
    <React.Fragment>
      <div className="upload-prod-outer">
        <Formik
          initialValues={initialValues}
          validationSchema={customOrderValidation}
          onSubmit={(values, actions) => {
            // submit handler 
            handleSubmit(values, actions)

          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {

            return (
              <>
                <Form className="form-wrap" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6} sm={6}>
                      <FormGroup>
                        <Label>{DepartmentProducts?.ProductName[language]}</Label>
                        <Input type="text" onChange={handleChange} name="product_name" value={values?.product_name} />
                        <ErrorMessage name="product_name" component={TextError} />
                      </FormGroup>
                    </Col>
                    <Col md={6} sm={6}>
                      <FormGroup>
                        <Label>{DepartmentProducts?.Quantity[language]}</Label>
                        <Input type="text" onChange={handleChange} name="quantity" value={values?.quantity} />
                        <ErrorMessage name="quantity" component={TextError} />
                      </FormGroup>
                    </Col>
                    <Col md={6} sm={6}>
                      <FormGroup>
                        <Label>{DepartmentProducts?.ReferenceLink[language]}</Label>
                        <Input type="text" onChange={handleChange} name="ref_links" value={values?.ref_links} />
                        <ErrorMessage name="ref_links" component={TextError} />
                      </FormGroup>
                    </Col>
                    <Col md={6} sm={6}>
                      <FormGroup>
                        <Label>{DepartmentProducts?.Comments[language]}</Label>
                        <Input type="textarea" onChange={handleChange} name="comments" value={values?.comments} />
                        <ErrorMessage name="comments" component={TextError} />
                      </FormGroup>
                    </Col>

                    {/* product Category  */}
                    <Col md={6} sm={6}>
                      <FormGroup>
                        <Label>{DepartmentProducts?.Category[language]}</Label>
                        <Input
                          type="select"
                          name="cat_id"
                          value={values.cat_id}
                          data={category}
                          onChange={(e) => {
                            getSpecification(e.target.value);
                            setSpecData([]);
                            setFieldValue("cat_id", e.target.value);
                          }}
                        >
                          <option value={''}>{DepartmentProducts?.Select_Category[language]}</option>;
                          {category?.map((item: any, i: number) => {
                            return (
                              <React.Fragment key={i}>
                                <option key={i} value={item?.value}>
                                  {" "}
                                  {item?.label}
                                </option>
                              </React.Fragment>
                            );
                          })}
                        </Input>
                        <ErrorMessage name="cat_id" component={TextError} />
                      </FormGroup>
                    </Col>

                    {/* specs  */}
                    <FieldArray
                      name="spec_details"
                      render={(arrayHelpers:any) => {
                        return spec_data && spec_data.length > 0
                          ? spec_data.map((item: any, index: any) => (
                            <Col md={6} sm={6}>
                              <div key={index}>
                                <FormGroup className="form-group" >
                                  <Label>{item?.name} </Label>
                                  <Input
                                    name={`spec_details.${index}.spec_details`}
                                    type="text"
                                    value={item?.value}
                                    min="0"
                                    onChange={(e) => {
                                      handleValueChange(
                                        index,
                                        item?.name,
                                        e.target.value,
                                        e,
                                        setFieldValue
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`spec_details.${index}.spec_details`}
                                    component={TextError}
                                  />
                                </FormGroup>
                              </div>
                            </Col>
                          ))
                          : null;
                      }}
                    />

                    {/* custom spec  */}
                    <div className=" add-spec-outer-wrap">
                      {
                        values?.cat_id && (
                          <>
                            <FieldArray name="specifications">
                              {(arrayHelpers:any) => (
                                <div className="items-outer p-0">
                                  {specifications.map((spec, index) => (
                                    <div key={index} className="item">
                                      <Row className="gy-4">
                                        <div className="mobile-view">
                                          <i className="bi bi-x-circle remove-btn text-danger"
                                            onClick={() => handleRemove(index, setFieldValue)} title="Remove"></i>
                                        </div>
                                        <Col md={3} sm={6}>
                                          <Input
                                            name={`specifications.${index}.name`}
                                            type="text"
                                            placeholder="Specification name"
                                            onChange={(e) => {
                                              handleSpecificationChange(
                                                index,
                                                "name",
                                                e.target.value,
                                                setFieldValue
                                              );
                                            }}
                                          />
                                        </Col>
                                        <Col md={3} sm={6}>
                                          <Input
                                            name={`specifications.${index}.value`}
                                            type="text"
                                            placeholder="Specification value"
                                            onChange={(e) => {
                                              handleSpecificationChange(
                                                index,
                                                "value",
                                                e.target.value,
                                                setFieldValue
                                              );
                                            }}
                                          />
                                        </Col>
                                        <Col md={3} sm={6}>
                                          <Input
                                            type="select"
                                            name={`values.spec.${index}.type`}
                                            data={spec_data}
                                            onChange={(e) => {
                                              handleSpecificationChange(
                                                index,
                                                "parent",
                                                e.target.value,
                                                setFieldValue
                                              );
                                            }}
                                          >
                                            <option value="">{DepartmentProducts?.Select_Parent[language]}</option>;
                                            {spec_data?.map((item: any, i: number) => {
                                              return (
                                                <React.Fragment key={item?.value}>
                                                  <option key={i} value={item?.id}>
                                                    {" "}
                                                    {item?.name}
                                                  </option>
                                                </React.Fragment>
                                              );
                                            })}
                                          </Input>
                                          <ErrorMessage
                                            name={`values.spec.${index}.type`}
                                            component={TextError}
                                          />
                                        </Col>

                                        <Col md={3} sm={6} className="large-screen-view">
                                          <Button
                                            type="button"
                                            color="danger"
                                            className={"col-12 px-3"}
                                            onClick={() => handleRemove(index, setFieldValue)}
                                          >
                                            <i className="fa fa-times"></i>&nbsp; {DepartmentProducts?.Remove[language]}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}

                                  <FormGroup className="text-end my-3">
                                    <Link to="" className="blue-text" onClick={() => {
                                      const newField = {
                                        name: "",
                                        value: "",
                                        parent: "",
                                      };
                                      const newSpecifications = [
                                        ...specifications,
                                        newField,
                                      ];
                                      setSpecifications(newSpecifications);
                                      arrayHelpers.push(newField);
                                    }}>
                                      <i className="bi bi-plus-lg"></i>&nbsp;{DepartmentProducts?.Add_Specification[language]}</Link>
                                  </FormGroup>
                                </div>
                              )}
                            </FieldArray>
                          </>)}
                    </div>
                    <Col md={12}>
                      <div className="text-end">
                        <FormGroup>
                          <Button
                            type="submit"
                            disabled={isLoading}
                            color="primary"
                            className={"px-5"}
                          >
                            {DepartmentProducts?.Add_Custom_Order[language]}
                          </Button>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </>
            )
          }}
        </Formik>

      </div>

    </React.Fragment>
  );
}
