import React from 'react';
import { Link } from 'react-router-dom';


export default function AboutKanada() {
    return (
        <div>
            <section>
                <div className="about-container-fluid">
                    <div className="about-banner-outer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="about-banner-text-wrap">
                                        <h1>
                                            ನಮ್ಮ ಬಗ್ಗೆ
                                        </h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to={"/home"}>ಮುಖಪುಟ</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">ನಮ್ಮ ಬಗ್ಗೆ</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></section>
            {/* content */}
            <section className="about-us-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 mt-4 py-5">
                            {/* <canvas id="myChart" /> */}
                            <img className='chart' src={require("../../images/kannadapiechart.jpg")} />
                        </div>
                        <div className="content-inner col-lg-8 col-md-12">
                            <p className="sentence">ಕಿಯೋನಿಕ್ಸ್ ನವೀನ ತಂತ್ರಜ್ಞಾನ ಪರಿಹಾರಗಳ ಪ್ರಮುಖ ಪೂರೈಕೆದಾರರಾಗಿದ್ದು, ಭಾರತೀಯ ಸರ್ಕಾರ ಮತ್ತು ಸಾರ್ವಜನಿಕ ವಲಯದ ಸಂಸ್ಥೆಗಳಿಗೆ ಸೇವೆ ಸಲ್ಲಿಸುತ್ತಿದೆ. ಉದ್ಯಮದಲ್ಲಿ ಮೂರು ದಶಕಗಳ ಅನುಭವದೊಂದಿಗೆ, ಕಿಯೋನಿಕ್ಸ್ ತನ್ನ ಗ್ರಾಹಕರ ಅನನ್ಯ ಅಗತ್ಯಗಳನ್ನು ಪೂರೈಸುವ ಉತ್ತಮ-ಗುಣಮಟ್ಟದ, ವೆಚ್ಚ-ಪರಿಣಾಮಕಾರಿ ಪರಿಹಾರಗಳನ್ನು ನೀಡುವ ಸಾಬೀತಾದ ದಾಖಲೆಯನ್ನು ಹೊಂದಿದೆ.</p>
                            <p className="sentence">ನಿರ್ವಹಣಾ ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ಸುಗಮಗೊಳಿಸುವುದು ಮತ್ತು ಪಾರದರ್ಶಿತೆಯನ್ನು ಹೆಚ್ಚಿಸುವ ಉದ್ದೇಶದಿಂದ, ಕಿಯೋನಿಕ್ಸ್ ಪ್ರಕಟಿಸುತ್ತಿದೆ - ಸರ್ಕಾರದ ಕೊಠಡಿ ಕೂಡಿದ ಸಾರ್ವಜನಿಕ ಖರೀದಿ ವ್ಯವಸ್ಥೆಯನ್ನು ಅಭಿವೃದ್ಧಿಗೊಳಿಸಲು ಹೊಸ ಯೋಜನೆಯನ್ನು ರೂಪಿಸುತ್ತಿದೆ. ಈ ಮಂಚವು ಸರ್ಕಾರದ ಇಲಾಖೆಗಳು, ಕಿಯೋನಿಕ್ಸ್ ಯೋಜನೆ ನಿರ್ವಹಣೆ ತಂಡ, ಮತ್ತು ಕಿಯೋನಿಕ್ಸ್ ಮೂಲಕ ಖರೀದಿ ಮಾಡಲು ಹಿಂಬಾಲಿಸಲು ಬಯಸುವ ಮೇಲೆಯೇ ಸಂಘಟಿಸಲಾಗುವುದು</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* content-para */}
            <section className="about-us-content-para">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 animate__animated animate__fadeInUp">
                            <p className="sentence">ವ್ಯವಸ್ಥೆಯು ಪ್ರಾರಂಭದಿಂದ ಕೊನೆಗೆ ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಸರ್ಕಾರಿ ಸಂಸ್ಥೆಗಳಿಗೆ ಕೊಡುಗೆ ಮಾಡುವ ಎಲ್ಲ ಚಟುವಟಿಕೆಗಳಿಗೆ ಒಂದೇ ಸ್ಥಳದಲ್ಲಿನ, ಆನ್‌ಲೈನ್ ಪೋರ್ಟಲ್ ಒದಗಿಸುತ್ತದೆ. ಮಂಚದಲ್ಲಿನ ಸಹಕರಣೆಗಳ ನಡುವೆ ಸುಲಭವಾಗಿ ಸಹಯೋಗ ನಡೆಸಲು ಮತ್ತು ಖರೀದಿ ಚಟುವಟಿಕೆಗಳ ನಿರವಧಿ ಹಾಗೂ ವರ್ತಿಸುತ್ತಿರುವ ಚಟುವಟಿಕೆಗಳ ಪರಿಸ್ಥಿತಿಯ ಸಂತಕವನ್ನು ಪರಿಶೀಲಿಸಲು ಈ ಮಂಚವು ಸಹಕಾರಿಸುತ್ತದೆ. ಬಳಕೆದಾರರ ಸುಲಭತೆ ಮತ್ತು ಸೇರುವಿಕೆಯ ಮೂಲಕ, ಈ ವ್ಯವಸ್ಥೆಯು ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳುವ ಎಲ್ಲ ಪಕ್ಷದವರಿಗೂ ಅವರು ತೀರ್ಮಾನ ಮಾಡುವುದಕ್ಕೆ ಅಗತ್ಯವಾದ ಮಾಹಿತಿಯನ್ನು ಸಿಗುವಂತೆ ಖಾತೆಗೆ ಹೊಂದಿಸುತ್ತದೆ.</p>
                            <p className="sentence">ಕಿಯೋನಿಕ್ಸ್ ಗೌರವದ ಮೇಲೆ ಭದ್ರತೆ ಒಂದು ಮುಖ್ಯ ಪ್ರಾಧಾನ್ಯತೆ ಮತ್ತು ಹೊಸ ಖರೀದಿ ವ್ಯವಸ್ಥೆಯಲ್ಲಿ ಸಂವಿಧಾನ ನಿಯಮಗಳ ಅನುಸರಣೆಗೆ ಸರಿಯಾದ ಭದ್ರತಾ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಹೊಂದಿಸಲಿದೆ. ಇದರಿಂದ ಸಂವೇದಿಯ ಮಾಹಿತಿಯನ್ನು ರಕ್ಷಿಸಲು ಮತ್ತು ಸರ್ಕಾರಿ ನಿಯಮಗಳನ್ನು ಪಾಲಿಸಲು ಸಕ್ರಿಯ ಭದ್ರತಾ ಅಂಶಗಳನ್ನು ಅಂದರೆ ಕೃಶಿಯನ್ನು ಸೇರಿಸಲಾಗುವುದು.</p>
                            <p className="sentence">ಸಾರ್ವಜನಿಕ ಖರೀದಿ ವ್ಯವಸ್ಥೆಗೆ ತಂತ್ರಜ್ಞಾನ ಪರಿಹಾರಗಳ ಮುಖ್ಯ ಪೂರ್ವಾವಸ್ಥೆಯಾಗಿರುವ ಕಿಯೋನಿಕ್ಸ್, ಗ್ರಾಹಕರಿಗೆ ಮೌಲ್ಯ ನೀಡುವುದರ ಮೂಲಕ ಮತ್ತು ಭಾರತೀಯ ಸರ್ಕಾರದ ಯಶಸ್ಸಿಗೆ ಸೇರುವ ಮೂಲಕ ಯೋಗದಾನ ಮಾಡಲು ಪ್ರತಿಜ್ಞೆಯನ್ನು ಹೊಂದಿದೆ. ಹೊಸ ಸರ್ಕಾರಿ ಖರೀದಿ ವ್ಯವಸ್ಥೆಯ ಮೂಲಕ, ಕಿಯೋನಿಕ್ಸ್ ಸರ್ಕಾರಿ ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಕ್ರಾಂತಿಕಾರಿ ಮಾಡಲು ಸಿದ್ಧವಾಗಿದೆ ಮತ್ತು ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಯ ಒಟ್ಟು ಕೌಶಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಲು ಉದ್ಯುಕ್ತವಾಗಿದೆ.</p>
                            <p className="sentence">ಕಿಯೋನಿಕ್ಸ್ ಭಾರತೀಯ ಸರ್ಕಾರ ಮತ್ತು ಸಾರ್ವಜನಿಕ ಕ್ಷೇತ್ರದ ಸಂಸ್ಥೆಗಳಿಗೆ ಹೊಸದೊಂದು ಪ್ರಯೋಜನಕಾರಿ ತಂತ್ರಜ್ಞಾನ ಪರಿಹಾರಗಳ ಮುಖ್ಯ ಸರಬರಾಜುಗಳನ್ನು ಒದಗಿಸುವ ಮುಖ್ಯ ಸಂಬಂಧವಾಗಿದೆ. ಇಂದು ವಿಜ್ಞಾನ ಕ್ಷೇತ್ರದಲ್ಲಿ ಮೂರು ದಶಕಗಳ ಹೆಚ್ಚಿನ ಅನುಭವವನ್ನು ಹೊಂದಿದ್ದು, ಕಿಒನಿಕ್ಸ್ ತನ್ನ ಗ್ರಾಹಕರ ವಿಶಿಷ್ಟ ಆವಶ್ಯಕತೆಗಳನ್ನು ಪೂರೈಸುವ ಉನ್ನತ ಮಟ್ಟದ, ಖರ್ಚು ಹಾಕಿ ಕಡಿಮೆ ವೆಚ್ಚದ ಪರಿಹಾರಗಳನ್ನು ನೀಡುವ ಪ್ರಮಾಣಪತ್ರ ಹೊಂದಿದೆ.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* objectives */}
            <section className="about-us-objectives">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h3>ಉದ್ದೇಶಗಳು:</h3>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <ul className="animate__animated animate__fadeInUp">
                                <li><p>ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಉನ್ನತ ಇ-ಸರ್ಕಾರಿ ಮುಖ್ಯತ್ವವನ್ನು ಮತ್ತು ಹೆಚ್ಚಿನ ಪಾರದರ್ಶಕತೆಯನ್ನು ಒದಗಿಸಲು</p></li>
                                <li><p>ಕುಶಲ ಮತ್ತು ಪರಿಣಾಮಕಾರಿ ಸಾಮಾಜಿಕ ಆಡಿಟ್ ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ಸಾಧ್ಯವಾಗಿ ನಿರ್ವಹಿಸಲು</p></li>
                                <li><p>ಸಂಗ್ರಹಣೆಯ ವೇಗವನ್ನು ಹೆಚ್ಚಿಸಲು ಮತ್ತು ಹಸ್ತಚಾಲಿತ ಕೆಲಸವನ್ನು ಕಡಿಮೆ ಮಾಡಲು.</p></li>
                                <li><p>ಸಂಗ್ರಹಣೆಯ ಗುಣಮಟ್ಟವನ್ನು ಸುಧಾರಿಸಲು ಮತ್ತು ಸರ್ಕಾರದ ನಿಯಮಗಳ ಅನುಸರಣೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು.</p></li>
                                <li><p>ಇಲಾಖೆಗಳು ಮತ್ತು ಮಾರಾಟಗಾರರಿಗೆ ಆನ್-ಕಾಲ್ ಬೆಂಬಲವನ್ನು ಒದಗಿಸಲು.</p></li>
                                <li><p>ವಿಭಾಗಗಳು ಮತ್ತು ವೆಂಡರ್ಸ್ರೊಂದಿಗೆ ಒಳ್ಳೆಯ ಸಂಬಂಧವನ್ನು ಬೆಳೆಸಲು, ಸಹಯೋಗ ಮತ್ತು ನಂಬಿಕೆಯನ್ನು ಸಾರ್ವಜನಿಕಗೊಳಿಸಲು.</p></li>
                                <li><p>ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ಮುನ್ನಡೆಸಲು ಮತ್ತು ಒಟ್ಟು ಕೌಶಲ್ಯವನ್ನು ಮೇಲ್ಮೆಗೊಳಿಸಲು ನವೀನತಾರಕ ತಂತ್ರಜ್ಞಾನವನ್ನು ಉಪಯೋಗಿಸುವುದು.</p></li>
                                <li><p>ಖರೀದಿ ಖರ್ಚುಗಳನ್ನು ಮತ್ತು ಸಮಯವನ್ನು ಕಡಿಮೆ ಮಾಡುವುದು, ವಿಭಾಗಗಳಿಗೆ ಹೆಚ್ಚು ವೇದಿಕೆಯ ವಿಕ್ರೇತಾಗಳು ಮತ್ತು ಉತ್ಪನ್ನಗಳ ಹೊಂದಾಣಿಕೆಗೆ ಅವಕಾಶ ನೀಡುವುದು.</p></li>
                                <li><p>ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಗಳಲ್ಲಿ ಸಾಮಾಜಿಕ ಹೊಂದಿಕೆಯನ್ನು ಬೆಳೆಸಲು.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* why-keonics */}
            <section className="about-us-why-keonics">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2>ನೀವು ಕಿಯೋನಿಕ್ಸ್ ಅನ್ನು ಏಕೆ ಆರಿಸಬೇಕು ?</h2>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <p className="sentence animate__animated animate__fadeInUp">ನವೀನ ತಂತ್ರಜ್ಞಾನದ ಬಳಕೆಯಿಂದ ಕರ್ನಾಟಕದಲ್ಲಿ ಕೌಶಲ್ಯಪೂರ್ವಕ, ಪಾರದರ್ಶಕ ಮತ್ತು ಸಾಮಾಜಿಕ ಹೊಂದಿಕೆಯ ನಿಗಮನ ಸೌಲಭ್ಯ ನೀಡುವ ವಿಶ್ವಕ್ಕೆ ಮಾರ್ಗದರ್ಶನವನ್ನು ನೀಡುವ ಪ್ರಮುಖ ಸಾರ್ವಜನಿಕ ಖರೀದಿ ವ್ಯವಸ್ಥೆಯನ್ನು ರಚಿಸುವುದು.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* info */}
            <section className="about-us-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <img src={require("../../images/Rectangle 4604.png")} alt="image" className="img-fluid" />
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="info-content animate__animated animate__fadeInUp">
                                <h3>ಖರೀದಿ ಆದೇಶ ನಿರ್ವಹಣೆ</h3>
                                <p className="sentence">ಸ್ವಯಂಚಾಲಿತ ಖರೀದಿ ಆದೇಶ ನಿರ್ವಹಣೆ, ಕಿಯೋನಿಕ್ಸ್ ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ಕೌಶಲ್ಯಪೂರ್ವಕವಾಗಿ ನಿರ್ವಹಿಸಲು ಮತ್ತು ಕೈಗಾರಿಕೆಯ ಕಾರ್ಯವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಅವಕಾಶ ನೀಡುತ್ತದೆ.</p>
                            </div>
                            <div className="info-content animate__animated animate__fadeInUp">
                                <h3>ಪ್ರತಿವೇದನೆ ಮತ್ತು ವಿಶ್ಲೇಷಣೆ</h3>
                                <p className="sentence">ಸಂಗ್ರಹಣೆ ಚಟುವಟಿಕೆಗಳ ಒಳನೋಟಗಳನ್ನು ಒದಗಿಸಲು ಮತ್ತು ನಿರ್ಧಾರ ತೆಗೆದುಕೊಳ್ಳುವಿಕೆಯನ್ನು ಸುಧಾರಿಸಲು ನೈಜ-ಸಮಯದ ವರದಿ ಮತ್ತು ವಿಶ್ಲೇಷಣೆಯ ಸಾಮರ್ಥ್ಯಗಳು.</p>
                            </div>
                            <div className="info-content animate__animated animate__fadeInUp">
                                <h3>ವಿತರಣೆ ನಿರ್ವಹಣೆ</h3>
                                <p className="sentence">ಉತ್ಪನ್ನಗಳ ವಿತರಣೆಯ ನಿರಂತರ ಟ್ರ್ಯಾಕಿಂಗ್ ಮತ್ತು ಪ್ರತಿವೇದನೆ, ವಿಭಾಗಗಳು ತಮ್ಮ ಉತ್ಪನ್ನಗಳನ್ನು ಸಮಯಕ್ಕೆ ಸೇರಿಸುವ ಹಾಗೂ ಕೌಶಲ್ಯಪೂರ್ವಕ ರೀತಿಯಲ್ಲಿ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ.</p>
                            </div>
                            <div className="info-content animate__animated animate__fadeInUp">
                                <h3>ನಿಯಮಗಳ ಅನುಸಾರ ಅನುಮೋದನೆ</h3>
                                <p className="sentence">ಸರ್ಕಾರದ ನಿಯಮಗಳ ಅನುಸಾರ ಪಾಲಿಸು ಮತ್ತು ಸಂವಹನ ಮಾಹಿತಿಯನ್ನು ಸಂರಕ್ಷಿಸಲು ಶಕ್ತಿಶಾಲಿ ಭದ್ರತಾ ಅಂಶಗಳು.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
