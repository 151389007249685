import React, { useEffect, useContext } from "react";
import { useState, useMemo } from "react";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { Link } from "react-router-dom";
import constants from "../../../Library/Constants/Constants";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import Loader from "../../../Components/Loader/Loader";
import OrderViews from "./ViewModal";
import { Formik } from "formik";
import {
  orderValidations,
  orderWithReasonsValidations,
} from "./OrderValidation";
import { toast } from "react-toastify";
import {
  convertISODateToDateTime,
  getOrderStatus,
} from "../../../Library/Constants/helpers";
import {
  order_status,
  order_status_accept,
  order_status_reject,
} from "../../../Library/Constants/MapConstants";
import ProductDetailsModal from "./productDetailsModal";
import ReceivedOrderModal from "./OrderModal/ReceivedOrderModal";
import { Context } from "../../../Components/Context/Context";

export default function ReceivedOrders(props: any) {
  const [loading, setLoading] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [isShowings, setIsShowings] = useState(false);
  const [received_order, setReceivedOrder] = useState([]);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [order_view, setOrderView] = useState([]);
  const [orderid, setorderid] = useState("");
  const [search, setSearch] = useState("");
  const [values, setValues] = useState<any>();
  const [getProductdet, setgetProductdet] = useState<any>();
  const [statusId, setStatusId] = useState<any>("");
  const { language, setLanguage } = useContext(Context);
  const [emd, setEMD] = useState([]);
  const [pg, setPG] = useState([]);
  const [value, setValue] = useState({
    orderid: "",
    proforma_id: "",
    product_id: "",
    quantity: "",
  });
  const toggle = () => setModal(!modal);
  const initialValues = {
    order_status: "",
    rejection_reasons: "",
  };

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getReceivedOrder(1);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  useEffect(() => {
    getReceivedOrder(1);
  }, [currentPage]);
  // ========================================================== Get Recieved Order ==========================================================

  const getReceivedOrder = (page = 1) => {
    setLoading(true);
    api
      .get(
        `/order/orders?status=8&page=${currentPage}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setReceivedOrder(response?.data || []);
        setPageData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ================================================================ View ==================================================================

  const getSingleOrder = (order_id: any) => {
    setLoading(true);
    api
      .get(`/order/get-order-details?id=${order_id}&search=${search}`, true)
      .then(async function ([success, response]) {
        setLoading(false);
        setOrderView(response?.message || []);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    api
      .get(
        `/order/get_order_details_order_id?order_id=${order_id}&search=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setLoading(false);
        if (response?.message?.length == 0) {
        } else {
          response?.message?.map((item: any) => {
            if (item?.payfor == "EMD") {
              setEMD(item);
            }
            if (item?.payfor == "PG") {
              setPG(item);
            }
          });
        }
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
 
  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions);
  };

  const sendData = (values: any, actions: any) => {
    api
      .post(
        `/order/order-accept`,
        {
          order_id: value?.orderid,
          status: values?.order_status,
          proforma_id: value?.proforma_id,
          product_id: value?.product_id,
          quantity: value?.quantity,
          order_reject_reason: values?.rejection_reasons,
        },
        true
      )
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          toast.success("Order Status Updated!");
          setIsShowing(false);
          actions.resetForm();
          actions.setSubmitting(false);
          getReceivedOrder();
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeTags = (str: any) => {
    const regex = /(<([^>]+)>)/gi;
    // const result = props?.data?.description.replace(regex, '');
    if (str === null || str === "") return false;
    else str = str?.toString();
    //    let all= str?.replace(/(<<([^>]+)>>)/g, ' ');
    let all = str?.replace(/(<br>)/g, " ,");
    let alls = all?.replace(/(<([^>]+)>)/g, "");
    let val = alls.split(",");
    var vals: any[] = [];
    val.map((items: any, index: any) => {
      var vall = val[index].split(":");
      vals.push([{ key: vall[0], val: vall[1] }]);
    });
    setValues(vals);
    return vals;
  };

  return (
    <React.Fragment>
      <div className="search-widget-outer">
        <Form role="search">
          <InputGroup className="search-group">
            <InputGroupText
              title="Search here"
              className="search-icon"
              id="search"
            >
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control"
              placeholder={VendorRegLang?.OrderID_ProductName[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100">
          <thead>
            <tr>
              <th>{VendorRegLang?.sl_no[language]}</th>
              <th>{VendorRegLang?.order_id[language]}</th>
              <th>{VendorRegLang?.order_allocated_date[language]}</th>
              <th>{VendorRegLang?.products[language]}</th>
              <th>{VendorRegLang?.quantity[language]}</th>
              {/* <th>{VendorRegLang?.status[language]}</th> */}
              <th>{VendorRegLang?.price[language]}</th>
              <th>{VendorRegLang?.action[language]}</th>
            </tr>
          </thead>
          <tbody>
            {received_order?.length !== 0 ? (
              <>
                {received_order?.map((item: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.sl_no[language]}:
                          </span>
                          <span className="table-data">
                            {pageData?.from + index}
                          </span>
                        </td>

                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.order_id[language]}:
                          </span>
                          <span className="table-data">
                            {item?.vendor_po_no}
                          </span>
                        </td>

                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.order_allocated_date[language]}:
                          </span>
                          <span className="table-data">
                            {convertISODateToDateTime(item?.created_at)}
                          </span>
                        </td>

                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.products[language]} :
                          </span>
                          <span className="table-data">
                            <Link
                              to="#"
                              className="blue-text"
                              title="View Product"
                              onClick={() => {
                                setIsShowings(true);
                                setgetProductdet(item?.order_details?.features);
                                removeTags(item?.order_details?.features);
                              }}
                            >
                              <strong>{item?.order_details?.name}</strong>
                            </Link>
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.quantity[language]} :
                          </span>
                          <span className="table-data d-block ">
                            {item?.order_details?.quantity}
                          </span>
                        </td>
                        {/* <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.status[language]} :
                          </span>
                          <span className="table-data new-status">
                            {getOrderStatus(item?.order_details?.status)}
                          </span>
                        </td> */}

                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.price[language]} :
                          </span>
                          <span className="table-data">
                            {/* ₹{(((item?.order_details?.cgst * item?.order_details?.quantity) + (item?.order_details?.sgst * item?.order_details?.quantity ) + (item?.order_details?.amount * item?.order_details?.quantity)) - ((((item?.order_details?.cgst * item?.order_details?.quantity) + (item?.order_details?.sgst * item?.order_details?.quantity))) + (item?.order_details?.amount * item?.order_details?.quantity) )* 5/100 )} */}
                            ₹
                            {(
                              item?.order_details?.cgst *
                                item?.order_details?.quantity +
                              item?.order_details?.sgst *
                                item?.order_details?.quantity +
                              item?.order_details?.amount *
                                item?.order_details?.quantity
                            ).toFixed(2)}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {VendorRegLang?.action[language]} :
                          </span>
                          <span className="table-data">
                            <Link
                              title="Verify"
                              to="#"
                              className=""
                              onClick={() => {
                                setIsShowing(true);
                                getSingleOrder(item?.id);
                                setValue({
                                  ...value,
                                  orderid: item?.id,
                                  proforma_id: item?.proforma_id,
                                  product_id: item?.order_details?.id,
                                  quantity: item?.order_details?.quantity,
                                });
                              }}
                            >
                              <i className="icons icons-view-more"></i>
                            </Link>
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>
                <tr>
                  <td className="text-center px-2 py-5 empty-text" colSpan={8}>
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    ) : (
                      `${VendorRegLang?.no_data_found[language]}`
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </div>
      {pageData && (
        <div className="table-footer-wrap">
          <small>
            {VendorRegLang?.showing[language]}{" "}
            {pageData?.from != null ? pageData?.from : 0}{" "}
            {VendorRegLang?.to[language]}{" "}
            {pageData?.to != null ? pageData?.to : 0}{" "}
            {VendorRegLang?.of[language]}{" "}
            {pageData?.total != null ? pageData?.total : 0}{" "}
            {VendorRegLang?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData?.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={
          statusId == 10 ? orderWithReasonsValidations : orderValidations
        }
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          setStatusId(values.order_status);
          return (
            <ReceivedOrderModal
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              handleSubmit={handleSubmit}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              handleReset={handleReset}
              order_view={order_view}
              recive_order={1}
              order_status={order_status_accept}
              order_status_reject={order_status_reject}
              stat={7}
              setStatusId={setStatusId}
              getSingleOrder={getSingleOrder}
              order_id={value?.orderid}
              pg={pg}
              emd={emd}
            />
          );
        }}
      </Formik>

      <ProductDetailsModal
        isShowing={isShowings}
        setIsShowings={setIsShowings}
        values={values}
      />
    </React.Fragment>
  );
}