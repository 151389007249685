import React, { useState, useContext } from 'react'
import { LandingPageContext } from '../Components/Contexts';
import ContactUsEng from './English/ContactUs';
import ContactKanada from './Kanada/Contact';
import { Context } from '../../Components/Context/Context';

export default function Contacts() {
    // const { language, setLanguageFunction }: any = useContext(LandingPageContext);
    const { language, setLanguage } = useContext(Context);

    return (
        language == "en" ? <ContactUsEng/> : <ContactKanada/>
    )
}
