import React, { useEffect, useContext, useRef } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { ErrorMessage } from "formik";
import TextError from "../../../Components/Errors/TextError";
import { Vendor_profileUpdation } from "../../../Library/LanguageConversion/Vendor_profileUpdation";
import DatePicker from "react-date-picker";
import moment from "moment";
import { Context } from "../../../Components/Context/Context";
import { file_remove } from "../../../Library/Constants/helpers";

export default function OEMDocumentsSection(props: any) {
  const { language, setLanguage } = useContext(Context);

  const oemRef = useRef<HTMLInputElement>(null);


  return (
    <>
      <div className="company-information-update-wrap">
        <h4> {Vendor_profileUpdation?.OEM_Details[language]}</h4>
        <hr />
        <Row>
          <Col md={4} sm={6}>
            <FormGroup className="file">
              <Label>
                {Vendor_profileUpdation?.OEM_Certificate[language]}
              </Label>
              <div className="image-input">
                <Input
                  innerRef={oemRef}
                  type="file"
                  id="oem_certificate"
                  name="oem_certificate"
                  className="form-control"
                  onChange={(e: any) =>
                    props?.handleFileChange(e, e.target.files[0])
                  }
                  accept=".pdf"
                ></Input>
                <Label
                  className="image-button"
                  for="oem_certificate"
                  id="oem_certificate"
                >
                  {Vendor_profileUpdation?.Choose_Documents?.[language]}
                </Label>
                {props?.values?.oem_certificate && (
                  <>
                    <span
                      className="upload-text"
                      style={{
                        marginRight: "50px",
                        zIndex: "45",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        file_remove(
                          "oem_certificate",
                          oemRef,
                          "oem_certificate"
                        );
                        props?.setFieldValue("oem_certificate", "");
                      }}
                    >
                      &#x2715;
                    </span>
                  </>
                )}
              </div>

              <ErrorMessage name="oem_certificate" component={TextError} />
            </FormGroup>
          </Col>{" "}
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.Comapny_Type_in_OEM[language]}
                </Label>
                {props?.list > 0 ? (
                  <Input
                    name="oem_in_company_type"
                    type="text"
                    value={props?.values?.oem_in_company_type}
                    onChange={props?.handleChange}
                    disabled
                  />
                ) : (
                  <Input
                    name="oem_in_company_type"
                    type="text"
                    value={props?.values?.oem_in_company_type}
                    onChange={props?.handleChange}
                  />
                )}
              </div>
              <ErrorMessage name="oem_in_company_type" component={TextError} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.OEM_Valid_From[language]}{" "}
                </Label>
                {props?.list <= 0 ? (
                  <DatePicker
                    value={props?.values?.oem_in_company_valid_from}
                    onChange={(e: any) => {
                      props?.setFieldValue("oem_in_company_valid_from", e);
                    }}
                    name="oem_in_company_valid_from"
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    maxDate={new Date()}
                  />
                ) : (
                  <Input
                    type="text"
                    name="oem_in_company_valid_from"
                    value={moment(
                      props?.values?.oem_in_company_valid_from
                    ).format("DD-MM-YYYY")}
                    disabled={
                      props?.values?.oem_in_company_valid_from ? true : false
                    }
                  ></Input>
                )}
              </div>
              <ErrorMessage
                name="oem_in_company_valid_from"
                component={TextError}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <div className="custom-input">
                <Label>
                  {" "}
                  {Vendor_profileUpdation?.OEM_Valid_To[language]}
                </Label>
                {props?.list <= 0 ? (
                  <DatePicker
                    value={props?.values?.oem_in_company_valid_to}
                    onChange={(e: any) => {
                      props?.setFieldValue("oem_in_company_valid_to", e);
                    }}
                    name="oem_in_company_valid_to"
                    format={"dd-MM-y"}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    minDate={new Date()}
                  />
                ) : (
                  <Input
                    type="text"
                    name="oem_in_company_valid_to"
                    value={moment(
                      props?.values?.oem_in_company_valid_to
                    ).format("DD-MM-YYYY")}
                    disabled={
                      props?.values?.oem_in_company_valid_to ? true : false
                    }
                  ></Input>
                )}

                <ErrorMessage
                  name="oem_in_company_valid_to"
                  component={TextError}
                />
              </div>
            </FormGroup>
          </Col>
          <Col sm={12}>
          {props?.start_up !== 1 && props?.values?.oem_certificate && (
            <>
              {" "}
              <span className="text-success mark-end">
                {Vendor_profileUpdation?.Mark[language]}:5
              </span>
            </>
          )}
        </Col>
        </Row>
      </div>
    </>
  );
}
