import { yupToFormErrors } from "formik";
import moment from "moment";
import * as Yup from "yup";

const today = new Date();
let start_date: any = `${new Date().toLocaleString("default", {
  year: "numeric",
})}-${new Date().toLocaleString("default", {
  month: "2-digit",
})}-${new Date().toLocaleString("default", { day: "2-digit" })}`;

function formatDate(date: any) {
  return moment(date).format("DD-MM-YYYY");
}
const TenderValidationSchema = Yup.object().shape({
  tender_Id: Yup.string().required("Tender id is required"),
  tender_date: Yup.date().required("Tender date is required"),
  //  .max(new Date(), 'Tender date cannot be future date' ),

  approved_by: Yup.string().required("Approved by is required"),
  category: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("key is required (from label)"),
        value: Yup.string().required("Value is required"),
      })
    )
    .test(
      "required",
      "Category is required",
      (value) => Array.isArray(value) && value.length > 0
    ),
  version: Yup.string(),
  // .required("Version is required"),
  valid_from: Yup.date().required("Valid from is required"),
  // .min(start_date, 'Valid from cannot be past date' ),

  valid_to: Yup.date().required("Valid to is required"),
  // .min(
  //   Yup.ref('valid_from'),
  //   ({ min }) => `Valid to needs to be after ${formatDate(min)}!`),
});
export default TenderValidationSchema;
