import React, { useContext, useState } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap'
import { PMU_BANK_DETAILS } from '../../../../Library/Constants/MapConstants' 
import { Context } from "../../../../Components/Context/Context";
import { DepartmentProducts } from "../../../../Library/LanguageConversion/Department_Products";

const AccountDetails = () => {
    const { language, setLanguage } = useContext(Context);
    return (
        <UncontrolledAccordion>
            {PMU_BANK_DETAILS?.map((itm: any, i: number) => { 
                return (
                    <AccordionItem key={i}>
                        <AccordionHeader targetId={`${(2 + i).toString()}`} title='View Bank Details'>
                            <h6>{DepartmentProducts?.Bank_Details[language]} {i + 1}</h6>
                        </AccordionHeader>
                        <AccordionBody accordionId={`${(2 + i).toString()}`} >
                            <div className="account-details">
                                <small>{DepartmentProducts?.Name_of_Account[language]}: <span>{itm?.NAME_OF_ACCOUNT}</span></small><br></br>
                                <small>{DepartmentProducts?.Name_of_Bank[language]}:  <span>{itm?.NAME_OF_BANK} </span></small><br></br>
                                <small>{DepartmentProducts?.BranchName [language]}:  <span>{itm?.NAME_OF_BRANCH}</span></small><br></br>
                                <small>{DepartmentProducts?.AccountNo [language]}:  <span>{itm?.ACC_NO}</span></small><br></br>
                                <small>{DepartmentProducts?.IFSC [language]}:  <span>{itm?.IFSC}</span></small><br></br>
                                <small>{DepartmentProducts?.MICRCode [language]}:  <span>{itm?.MICR_CODE}</span></small><br></br>
                                <small>{DepartmentProducts?.Recipient_Code [language]}:  <span>{itm?.RECIPIENT_CODE}</span></small><br></br>
                                <small>{DepartmentProducts?.GST_No  [language]}:  <span>{itm?.GST_NO}</span></small><br></br>
                                <small>{DepartmentProducts?.Khajane_Recipient_ID  [language]}:  <span>{itm?.KHAJANE_2_RECIPIENT_ID}</span></small>
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                )
            })
            }
        </UncontrolledAccordion>
    )
}
export default AccountDetails