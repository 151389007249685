import React, { useEffect, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap";
import { useState, useMemo } from "react";
import { api } from "../../../Library/CustomApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Context } from "../../../Components/Context/Context";
import { VendorSignup } from "../../../Library/LanguageConversion/Vendor_SignUp";

import {
  vendor_types,
  gst_class,
  departments,
} from "../../../Library/Constants/MapConstants";
import { Formik, Form, ErrorMessage } from "formik";
import { BasicDetailsValidationSchema } from "../profileUpdation/UploadValidation";
import TextError from "../../../Components/Errors/TextError";

export default function SelfVendorRegisterBasic() {
  const token = localStorage?.getItem("AUTH_TOKEN");
  const navigate = useNavigate();
  const { language, setLanguage } = useContext(Context);
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    user_id: "",
    departments: "",
  });
  const initialValues = {
    name: "" || data?.name,
    email: "" || data?.email,
    phone: "" || data?.phone,
    departments: "" || data?.departments,
    user_id: "",
    profile_status: 1,
    type: "basic",
  };
  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = () => {
    api
      .post(`/get_vendor_profile`, {}, true)
      .then(async function ([success, response]) {
        let c = response?.vendor_details?.company_details[0];
        let a = response?.vendor_details?.advanced_registration_details[0];
        setData({
          ...data,
          name: c?.name,
          phone: c?.phone,
          email: c?.email,
          user_id: c?.user_id,
          departments: a?.departments,
        });
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values: any, actions: any) => {
    sendData(values, actions);
  };
  const sendData = (values: any, actions: any) => {
    api
      .patch(
        `/vendors/${data?.user_id}`,
        {
          ...values,
        },
        true
      )
      .then(async function ([success, response, status]: any) {
        toast.success("Updated successfully");
        //actions.isSubmitting(false);
        navigate("/advanced-registration");
        return response;
      })
      .catch((err) => {
        //toast.success("updated successfully");
        //   actions.isSubmitting(false);
        //   navigate("/basic-details-view");
        // if (err?.response?.data?.message) {
        //   actions.isSubmitting(false);
        // } else {
        //   toast.success("updated successfully");
        //   actions.isSubmitting(false);
        //   navigate("/basic-details-view");
        // }
        // if (err.response.status == 500) {
        //   actions.isSubmitting(false);
        // }
      });
  };
  return (
    <>
      <div className="login-content">
        <Formik
          validationSchema={BasicDetailsValidationSchema}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
        >
          {({ errors, values, isSubmitting, handleChange, touched }) => (
            <>

              <Form className="Reg-wrap">
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>{VendorSignup?.full_name[language]}</Label>
                        <Input
                          name="name"
                          type="text"
                          value={values?.name}
                          className={`form-control ${
                            touched.name && errors.name ? "is-invalid" : ""
                          }`}
                          onChange={handleChange}
                          disabled
                        />
                        <ErrorMessage name="name" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>{VendorSignup?.phone_number[language]}</Label>
                        <Input
                          name="phone"
                          type="text"
                          value={values?.phone}
                          className={`form-control ${
                            touched.phone && errors.phone ? "is-invalid" : ""
                          }`}
                          onChange={handleChange}
                          disabled
                        />
                        <ErrorMessage name="phone" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>
              
                  <Col sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>{VendorSignup?.email[language]}</Label>
                        <InputGroup>
                          <Input
                            name="email"
                            type="text"
                            value={values?.email}
                            className={`form-control ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            disabled
                          />
                          {token == null && (
                            <>
                              <InputGroupText>
                                <i className="bi bi-arrow-right-square-fill primary-text"></i>
                              </InputGroupText>
                            </>
                          )}{" "}
                        </InputGroup>
                        <ErrorMessage name="email" component={TextError} />
                      </div>
                    </FormGroup>
                  </Col>
                  {token == null && (
                    <>
                      <Col sm={12}>
                        <div className="breadcrumb-tabs-outer-wrap">
                          <div className="agree-checkbox-wrap">
                            <Input type="checkbox"></Input>
                            <Label>{VendorSignup?.Send_notification_on_email[language]}</Label>
                          </div>
                          <Link to="#" className="primary-text">
                          {VendorSignup?.Resend_OTP[language]}
                          </Link>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>{VendorSignup?.enter_OTP[language]}</Label>
                            <Input type="password"></Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>{VendorSignup?.confirm_password[language]}</Label>
                            <Input type="password"></Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <div className="custom-input">
                            <Label>{VendorSignup?.confirm_password[language]}</Label>
                            <InputGroup>
                              <Input type="password" />
                              <InputGroupText>
                                <i className="bi bi-eye"></i>
                              </InputGroupText>
                            </InputGroup>
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  {token == null && (
                    <>
                      <Col sm={12}>
                        <h6 className="mb-4">
                        {VendorSignup?.Already_have_an_account[language]} ?
                          <Link
                            to="/login"
                            className="primary-text text-decoration-none"
                          >
                            {VendorSignup?.login[language]}
                          </Link>
                        </h6>
                      </Col>
                      <Col sm={12}>
                        <Button
                          class="btn btn-primary"
                          color="primary"
                          type="submit"
                        >
                          {VendorSignup?.register[language]}
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}
