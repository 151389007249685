import React from "react";
import { Link } from "react-router-dom";

import { Accordion, AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from "reactstrap";

export default function FaqKanada() {
    return (
        <div>
            <section>
                <div className="container-fluid">
                    <div className="faq-banner-outer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="faq-banner-text-wrap ">
                                        <h1>ಪ್ರಶ್ನೋತ್ತರ</h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={"/home"}>ಮುಖಪುಟ</Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    ಪ್ರಶ್ನೋತ್ತರ
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Faq */}
            <section className="my-5">
                <div className="faq-wrap">
                    <div className="container">
                        <div className="accordion-content-wrap">
                            <UncontrolledAccordion id="faq">
                                {/*Accordion Item 1*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="1" className="">
                                        ದರ ಒಪ್ಪಿಗೆ ವ್ಯವಸ್ಥೆ (ಆರ್‌ಸಿ) ?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        ಕಿಯೋನಿಕ್ಸ್ ನಲ್ಲಿ, ದರ ಒಪ್ಪಿಗೆ ವ್ಯವಸ್ಥೆ (RC) ವ್ಯವಸ್ಥೆಯು ಪೂರ್ವ-ನೇಗೋಷಿತ ಬೆಲೆಗಳಿಂದ ಇಮ್ಪಾನ್ಲ್ ಮಾಡಲ್ಪಟ್ಟ ವೆಂಡರ್‌ಗಳಿಂದ ಸಾಮಾನುಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಖರೀದಿಸುವ ಒಂದು ಆಪತ್ತಿ ಪದ್ಧತಿ. RC ವ್ಯವಸ್ಥೆಯು ಖರೀದಿ
                                        ಪ್ರಕ್ರಿಯೆಯನ್ನು ಸರಳೀಕರಿಸಲು ಮತ್ತು ವೆಂಡರ್ಗಳ ನಡುವೆ ಬೆಲೆ ಮತ್ತು ಗುಣಮಟ್ಟದ ಸಂಗತಿಗಳಲ್ಲಿ ಒಂದೇ ಮಾನದಂಡವನ್ನು ಹೊಂದುವಂತೆ ರೂಪಿಸಲಾಗಿದೆ. RC ವ್ಯವಸ್ಥೆಗೆ, ಕಿಯೋನಿಕ್ಸ್ ನಿರ್ಧರಿಸಿದ ಯಾವುದೇ ಒಳ್ಳೆಯತನದ ಮಾನದಂಡಗಳನ್ನು ಪೂರೈಸುವ ಮತ್ತು
                                        ನಿಗದಿತ ಅವಧಿಗೆ ವಸ್ತುಗಳನ್ನು ಅಥವಾ ಸೇವೆಗಳನ್ನು ಒಪ್ಪಿಗೆ ಬೇರೆ ಡೆ ತಂದುಕೊಡುವ ವೆಂಡರ್‌ಗಳನ್ನು ಕಿಯೋನಿಕ್ಸ್ ಇಮ್ಪಾನ್ಲ್ ಮಾಡುತ್ತದೆ. RC ವ್ಯವಸ್ಥೆಯ ಮೂಲಕ ವೆಂಡರ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡುವಾಗ ತಾಂತ್ರಿಕ ಸಾಮರ್ಥ್ಯ, ಆರ್ಥಿಕ ಸ್ಥಿರತೆ, ಮತ್ತು
                                        ಗುಣಮಟ್ಟ ಮುಖ್ಯ ಅಂಶಗಳನ್ನು ಆಯ್ಕೆಗೆ ಗುರಿಪಡಿಸುವ ಪ್ರತಿಯೊಂದು ಮೂಲೆಯನ್ನೂ ಮೌಲ್ಯಮಾಪನ ಪ್ರಕ್ರಿಯೆಯ ಮೂಲಕ ಪರಿಶೀಲಿಸುತ್ತಾರೆ. ವೆಂಡರ್‌ಗಳು RC ವ್ಯವಸ್ಥೆಯ ಅಡಿಯಲ್ಲಿ ಪ್ರವೇಶಪಡುವ ನಂತರ, ವೆಂಡರ್‌ಗಳು ಬೇರೆ ಟೆಂಡರಿಂಗ್ ಪ್ರಕ್ರಿಯೆಯ
                                        ಆವಶ್ಯಕತೆ ಇಲ್ಲದೆ ನಿಗದಿತ ಬೆಲೆಯಲ್ಲಿ ಸಾಮಾನುಗಳನ್ನು ಅಥವಾ ಸೇವೆಗಳನ್ನು ಸರಿಯಾಗಿ ಒದಗಿಸಬಹುದು. ಇದು ಖರೀದಿ ಮತ್ತು ವಿಪಣ ಿಗೆಗೆ ಅಗತ್ಯವಾದ ಸಮಯ ಮತ್ತು ಪ್ರಯತ್ನಗಳನ್ನು ಕಡಿಮೆ ಮಾಡುತ್ತದೆ ಮತ್ತು ಕಿಯೋನಿಕ್ಸ್ ಅಗ್ರಗಣ್ಯ ವೆಂಡರ್‌ಗಳಿಂದ
                                        ಶೀಘ್ರದಲ್ಲಿ ಸಾಮಾನುಗಳನ್ನು ಅಥವಾ ಸೇವೆಗಳನ್ನು ಪಡೆಯಲು ಅವಕಾಶ ನೀಡುತ್ತದೆ. RC ವ್ಯವಸ್ಥೆಯು ಸಾಮಾನ್ಯವಾಗಿ ಹಾರ್ಡ್‌ವೇರ್, ಸಾಫ್ಟ್‌ವೇರ್, ಉಪಭೋಗದ ವಸ್ತುಗಳು ಮತ್ತು ರಕ್ಷಣೆ ಮತ್ತು ಬೆಂಬಲ ಸೇವೆಗಳು ಹೀಗೆಂದು ಕೊಂಡೊಯ್ಯುವುದಕ್ಕೆ
                                        ಬಳಸಲ್ಪಡುತ್ತದೆ. ಕಿಯೋನಿಕ್ಸ್ ನೇರವಾಗಿಯೇ ಮಾರ್ಕೆಟ್‌ನ ಪರಿಸ್ಥಿತಿಯನ್ನು ಮೀರಿದ ಹಾರ್ವರ್ಡ್‌ನಂತಹ ವೆಂಡರ್‌ಗಳ ಸಮೀಕ್ಷೆಯನ್ನು ಆರ್ದರ್ಕಪಡಿಸಲು ನಿಯಮಿಸುತ್ತದೆ. ಒಟ್ಟುಗೂಡಿನಲ್ಲಿ, RC ವ್ಯವಸ್ಥೆಯು ಖರೀದಿಯನ್ನು ಮೀರಿದ ಪ್ರಕ್ರಿಯೆಯನ್ನು
                                        ಕಿಯೋನಿಕ್ಸ್‌ಗೆ ಸರಳೀಕರಿಸುವುದರಿಂದ ಸಹಕರಿಸುತ್ತದೆ ಮತ್ತು ವೆಂಡರ್‌ಗಳಿಂದ ಸುರಕ್ಷಿತವಾದ ವ್ಯಾಪಾರಕ್ಕೆ ಆಧಾರವನ್ನು ನೀಡುತ್ತದೆ.
                                    </AccordionBody>
                                </AccordionItem>
                                {/*Accordion Item 2*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="2" className="">
                                        ಕಿಯೋನಿಕ್ಸ್ ಗೆ ವೆಂಡರ್‌ಗಳ ಎಂಪನೆಲ್ಮೆಂಟ್ ವಿಧಾನ ಮಾಡುವ ಹಂತಗಳು ಏನು? ?
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2">
                                        ಕಿಯೋನಿಕ್ಸ್ (ಕರ್ನಾಟಕ ರಾಜ್ಯ ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಅಭಿವೃದ್ಧಿ ನಿಗಮ ನಿಯಮಿತ) ಗೆ ವೆಂಡರ್‌ಗಳ ಇಮ್ಪಾನ್ಲ್ ಮಾಡುವ ಹಂತಗಳು ಈ ಮುಂದಿನ ಹೆಜ್ಜೆಗಳನ್ನು ಒಳಗೊಂಡಿರಬಹುದು:
                                        <br /> <br />
                                        <ul>
                                            <li>
                                                1. <b>ನೋಂದಣಿ:</b> ವೆಂಡರ್ ಅವಶ್ಯಕವಾಗಿ ಕಿಯೋನಿಕ್ಸ್ ವೆಂಡರ್ ಪೋರ್ಟಲ್‌ನಲ್ಲಿ ನೋಂದಣಿ ಮಾಡಬೇಕು. ಕಂಪನಿಯ ಹೆಸರು, ಸಂಪರ್ಕ ವ್ಯಕ್ತಿಯ ಹೆಸರು, ಇಮೇಲ್ ಐಡಿ, ಫೋನ್ ಸಂಖ್ಯೆ ಮತ್ತು ವಿಳಾಸ ಪ್ರಮುಖ ವಿವರಗಳನ್ನು ನೀಡಬೇಕು.
                                            </li>
                                            <li>
                                                2. <b>ಇಮ್ಪಾನ್ಲ್‌ಮೆಂಟ್ ಅರ್ಜಿ:</b> ವೆಂಡರ್‌ಗೆ ಇಮ್ಪಾನ್ಲ್‌ಮೆಂಟ್ ಅರ್ಜಿ ಪಟ್ಟಿಯನ್ನು ತುಂಬಿ ಸಲ್ಲಿಸಬೇಕು. ಪ್ಯಾನ್ ಕಾರ್ಡ್, ಜಿಎಸ್‌ಟಿ ನೋಂದಣಿ, ಬ್ಯಾಂಕ್ ಖಾತೆ ವಿವರಗಳು ಮತ್ತು ಇತರ ಸಂಬಂಧಿತ ಪ್ರಮಾಣಪತ್ರಗಳೊಂದಿಗೆ
                                                ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಬೇಕು.
                                            </li>
                                            <li>
                                                3. <b>ಡಾಕ್ಯುಮೆಂಟ್ ಪರಿಶೀಲನೆ:</b> ಕಿಯೋನಿಕ್ಸ್ ವೆಂಡರ್ ನೀಡಿದ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಅಂಗೀಕರಿಸಲು ಪರಿಶೀಲಿಸುತ್ತದೆ. ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳ ನೈಜತ್ವ ಮತ್ತು ಅರ್ಹತೆಯನ್ನು ಖಚಿತಪಡಿಸುವುದಕ್ಕೆ.
                                            </li>
                                            <li>
                                                4. <b>ತಾಂತ್ರಿಕ ಮೌಲ್ಯಮಾಪನ:</b> ಕಿಯೋನಿಕ್ಸ್ ವೆಂಡರ್‌ಗಳ ತಾಂತ್ರಿಕ ಸಾಮರ್ಥ್ಯವನ್ನು, ಅನುಭವವನ್ನು ಮತ್ತು ಗುಣಮಟ್ಟವನ್ನು ಮೌಲ್ಯಮಾಪನ ಮಾಡಿ, ಅವರ ಇಮ್ಪಾನ್ಲ್‌ಮೆಂಟ್ ಯೋಗ್ಯತೆಯನ್ನು ನಿರ್ಧರಿಸುವುದು.
                                            </li>
                                            <li>
                                                5. <b>ಆರ್ಥಿಕ ಮೌಲ್ಯಮಾಪನ:</b> ಕಿಯೋನಿಕ್ಸ್ ವೆಂಡರ್‌ಗೆ ಆರ್ಥಿಕ ಸ್ಥಿರತೆಯನ್ನೂ ಪ್ರಾಜೆಕ್ಟುಗಳನ್ನು ನಿರ್ವಹಿಸುವ ಯೋಗ್ಯತೆಯನ್ನೂ ಮೌಲ್ಯಮಾಪನ ಮಾಡಬಹುದು.
                                            </li>
                                            <li>
                                                6. <b>ಅನುಮೋದನೆ:</b> ವೆಂಡರ್ ಎಲ್ಲ ಇಮ್ಪಾನ್ಲ್‌ಮೆಂಟ್ ಮೌಲ್ಯಮಾಪನ ಮಾದರಿಗಳನ್ನು ಮೀರಿದ್ದು ಮತ್ತು ಆವಶ್ಯಕತೆಗಳನ್ನು ಪೂರೈಸಿದ್ದುದರಿಂದ, ಕಿಯೋನಿಕ್ಸ್ ಅವರ ಅರ್ಜಿಯನ್ನು ಅನುಮೋದಿಸಿ ಇಮ್ಪಾನ್ಲ್‌ಮೆಂಟ್ ಪತ್ರವನ್ನು
                                                ಜಾರಿಗೊಳಿಸುತ್ತದೆ.
                                            </li>
                                            <li>
                                                7. <b>ಎಂಪನೆಲ್ಮೆಂಟ್ ಒಪ್ಪಂದ:</b> ವೆಂಡರ್ ಕಿಯೋನಿಕ್ಸ್‌ನೊಂದಿಗೆ ಎಂಪನೆಲ್ಮೆಂಟ್ ಯಾವ ನಿಬಂಧನೆಗಳನ್ನು ವಿವರಿಸುವುದು ಮತ್ತು ನಿಬಂಧನೆಗಳನ್ನು ಸ್ವೀಕರಿಸಬೇಕು.
                                            </li>
                                            <li>
                                                8. <b>ಟೆಂಡರ್‌ಗಳಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳುವುದು:</b> ಎಂಪನೆಲ್ಮೆಂಟ್ ಮಾಡಲಾದ ನಂತರ, ವೆಂಡರ್ ನೋಂದಣಿಯ ವೆಂಡರ್ ಆಗಿ ಕಿಯೋನಿಕ್ಸ್ ಟೆಂಡರ್‌ಗಳಲ್ಲಿ ಮತ್ತು ಪ್ರಾಜೆಕ್ಟುಗಳಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳಬಹುದು.
                                            </li>
                                        </ul>
                                        ಕಿಯೋನಿಕ್ಸ್ ತರಬೇತಿ ನಿಗದಿಯ ಮೇರೆಗೆ ಮತ್ತು ನಿಯಮಗಳ ಆಧಾರದ ಮೇರೆಗೆ ವೆಂಡರ್ ಆಯೋಜನೆ ವಿಧಾನ ಬೇರೆಬೇರೆಯಾಗಿರಬಹುದು. ಆದ್ದರಿಂದ, ಹೆಚ್ಚಿನ ವಿವರಗಳಿಗಾಗಿ ಕಿಯೋನಿಕ್ಸ್ ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿನ ವೆಂಡರ್ ಆಯೋಜನೆ ಮಾರ್ಗನೇಟಿಂಗ್ ಮತ್ತು ಅವರ ಖರೀದಿ ಇಲಾಖೆಗೆ ಸಂಪರ್ಕ ಸೂಚಿಸಲು ಶಿಫಾರಿಗೆ ಅಪ್ಪಣೆನೀಡಲು ಶ್ರೇಷ್ಠವಾದ ಪ್ರಯತ್ನ ಮಾಡಲಾಗುತ್ತದೆ.
                                    </AccordionBody>
                                </AccordionItem>
                                {/*Accordion Item 3*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="3">ಕಿಯೋನಿಕ್ಸ್‌ನಲ್ಲಿ ಎಂಪನೆಲ್ಮೆಂಟ್ ಪಡೆಯುವ ಹೊರತು ಪತ್ರಗಳು ಯಾವುವು ಎಂದು ಕಾಣಿಸುತ್ತದೆ ?</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        <ol>
                                            <li>a. ಕಂಪನಿ ಸೇರ್ಪಡೆ ಪ್ರಮಾಣಪತ್ರ</li>
                                            <li>b. ಪ್ಯಾನ್ (ಸ್ಥಾಯಿ ಖಾತೆ ಸಂಖ್ಯೆ) </li>
                                            <li>c. ಜಿಎಸ್ಟಿ ನೋಂದಣಿ </li>
                                            <li>d. 3 ವರ್ಷಗಳ ಗಳಿಕೆಯ ಗಣಕ ತಂತ್ರಜ್ಞಾನ ಸಂದರ್ಭ</li>
                                            <li>e. ವರ್ಷಗಳ ಕಂಪನಿ ಬ್ಯಾಲೆನ್ಸ್ ಶೀಟ್</li>
                                            <li>f. ಟರ್ನ್ ಓವರ್ ಪ್ರಮಾಣಪತ್ರ </li>
                                            <li>g. (ಐಎಸ್ಒ) ಪ್ರಮಾಣಪತ್ರ </li>
                                            <li>h. OEM/MAF </li>
                                            <li>i. ವಿಳಾಸ ಪ್ರಮಾಣ</li>
                                            <li>j. MSME </li>
                                        </ol>
                                    </AccordionBody>
                                </AccordionItem>
                                {/*Accordion Item 4*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="4">ವೆಂಡರ್ ಕೆಯೋನಿಕ್ಸ್‌ನೊಂದಿಗೆ ಎಂಪ್ಯಾನೆಲ್ ಮಾಡುವುದು ಹೇಗೆ ಸಾಧ್ಯ ?</AccordionHeader>
                                    <AccordionBody accordionId="4">
                                        ಕಿಯೋನಿಕ್ಸ್‌, ಅಥವಾ ಕರ್ನಾಟಕ ರಾಜ್ಯ ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಡೆವಲಪ್ಮೆಂಟ್ ಕಾರ್ಪೊರೇಷನ್ ಲಿಮಿಟೆಡ್, ಭಾರತದಲ್ಲಿ ಸರ್ಕಾರಿ ಮತ್ತು ಆಯ್ಕೆ ಮೇಲೆಯಾದ ಒಂದು ಕಂಪನಿಯಾಗಿದೆ. ನೀವು ಕಿಯೋನಿಕ್ಸ್‌ನೊಂದಿಗೆ ಎಂಪ್ಯಾನೆಲ್ ಮಾಡಲು ನೀವು ಈ ಹೆಜ್ಜೆಗಳನ್ನು
                                        ಅನುಸರಿಸಬಹುದು:
                                        <ol>
                                            <li>1. ಕಿಯೋನಿಕ್ಸ್ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಭೇಟಿ ನೀಡಿ ಮತ್ತು "ವೆಂಡರ್ ಎಂಪಾನೆಲ್‌ಮೆಂಟ್" ವಿಭಾಗವನ್ನು ಹುಡುಕಿ.</li>
                                            <li>2. ಅರ್ಹತಾ ಮಾನದಂಡಗಳು, ಮೌಲ್ಯಮಾಪನ ಪ್ರಕ್ರಿಯೆ ಮತ್ತು ಇತರ ಅವಶ್ಯಕತೆಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮಾರಾಟಗಾರರ ಎಂಪನೆಲ್ಮೆಂಟ್ ನೀತಿಯನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ಓದಿ.</li>
                                            <li>3. ಕಿಯೋನಿಕ್ಸ್ ವೆಂಡರ್ ಪೋರ್ಟಲ್‌ಗೆ ನೋಂದಾಯಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಕಂಪನಿಯ ವಿವರಗಳು, ಸಂಪರ್ಕ ಮಾಹಿತಿ, ಮತ್ತು ಇತರ ಆವಶ್ಯಕ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಒದಗಿಸಿ ನಿಮ್ಮ ಪ್ರೊಫೈಲ್‌ನ್ನು ರಚಿಸಿ.</li>
                                            <li>4. ಪ್ಯಾನ್ ಕಾರ್ಡ್, ಜಿಎಸ್ಟಿ ನೋಂದಣಿ, ಬ್ಯಾಂಕ್ ಖಾತೆ ವಿವರಗಳು, ಮತ್ತು ಇತರ ಸಂಬಂಧಿತ ಪ್ರಮಾಣಪತ್ರಗಳೊಂದಿಗೆ ಎಂಪಾನೆಲ್‌ಮೆಂಟ್ ಅರ್ಜಿ ಪತ್ರವನ್ನು ಸಲ್ಲಿಸಿ.</li>
                                            <li>
                                                5. ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಿದ ನಂತರ, ಅರ್ಹತಾ ಮಾನದಂಡಗಳು ಮತ್ತು ಅನುಭವ, ತಾಂತ್ರಿಕ ಸಾಮರ್ಥ್ಯಗಳು, ಆರ್ಥಿಕ ಸ್ಥಿರತೆ ಮತ್ತು ಗುಣಮಟ್ಟದ ಮಾನದಂಡಗಳಂತಹ ಇತರ ಅಂಶಗಳ ಆಧಾರದ ಮೇಲೆ ಕಿಯೋನಿಕ್ಸ್ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮೌಲ್ಯಮಾಪನ ಮಾಡುತ್ತದೆ.
                                            </li>
                                            <li>6. ನಿಮ್ಮ ಅರ್ಜಿ ಅನುಮೋದಿಸಲ್ಪಟ್ಟರೆ, ನೀವು ಕಿಯೋನಿಕ್ಸ್‌ನಿಂದ ಎಂಪಾನೆಲ್‌ಮೆಂಟ್ ಪತ್ರವನ್ನು ಪಡೆಯುತ್ತೀರಿ. ಈ ಪತ್ರದಲ್ಲಿ ಎಂಪಾನೆಲ್‌ಮೆಂಟ್ ಒಪ್ಪಿಗೆಯ ನಿರ್ದೇಶಗಳು ಅಡ್ಡಬರುತ್ತವೆ.</li>
                                            <li>7. ಎಂಪಾನೆಲ್‌ಮೆಂಟ್ ಒಪ್ಪಿಗೆಯನ್ನು ಸೈನ್ ಮಾಡಿದ ನಂತರ, ನೀವು ನೋಂದಾಯಿತ ವೆಂಡರ್ ಆಗಿದ್ದು, ಕಿಯೋನಿಕ್ಸ್ ಟೆಂಡರ್‌ಗಳಲ್ಲಿ ಮತ್ತು ಪ್ರಾಜೆಕ್ಟ್‌ಗಳಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳಲು ಅರ್ಹರಾಗುತ್ತೀರಿ.</li>
                                        </ol>
                                        ಕಿಯೋನಿಕ್ಸ್‌ನ ನಿರ್ದಿಷ್ಟ ಅವಶ್ಯಕತೆಗಳು ಮತ್ತು ನೀತಿಗಳನ್ನು ಅವಲಂಬಿಸಿ ಎಂಪನೆಲ್‌ಮೆಂಟ್ ಪ್ರಕ್ರಿಯೆಯು ಬದಲಾಗಬಹುದು ಎಂಬುದನ್ನು ಗಮನಿಸುವುದು ಮುಖ್ಯವಾಗಿದೆ. ಆದ್ದರಿಂದ, ಕಿಯೋನಿಕ್ಸ್ ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ಮಾರಾಟಗಾರರ ಎಂಪನೆಲ್‌ಮೆಂಟ್
                                        ಮಾರ್ಗಸೂಚಿಗಳನ್ನು ಪರಿಶೀಲಿಸಲು ಅಥವಾ ಹೆಚ್ಚಿನ ವಿವರಗಳಿಗಾಗಿ ಅವರ ಸಂಗ್ರಹಣೆ ವಿಭಾಗವನ್ನು ಸಂಪರ್ಕಿಸಲು ಶಿಫಾರಸು ಮಾಡಲಾಗಿದೆ.
                                    </AccordionBody>
                                </AccordionItem>
                                {/*Accordion Item 5*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="5">ಮಾರಾಟಗಾರರ ನೋಂದಣಿಗಾಗಿ ಮರುಪಾವತಿಸಲಾಗದ ಮೊತ್ತ ಎಷ್ಟು ?</AccordionHeader>
                                    <AccordionBody accordionId="5">
                                        ಶ್ರೇಣಿ A, ರೂಪಾಯಿ 1,77,000/- (GST ಸೇರಿದ್ದರಿಂದ), ಟರ್ನ್ ಓವರ್ &gt; 20 ಕೋಟಿ,
                                        <br />
                                        ಶ್ರೇಣಿ B, ರೂಪಾಯಿ 88,500/- (GST ಸೇರಿದ್ದರಿಂದ), ಟರ್ನ್ ಓವರ್ &gt; 10 ಕೋಟಿ &amp;&amp; ಟರ್ನ್ ಓವರ್ &lt; 20 ಕೋಟಿ, ಶ್ರೇಣಿ C, ರೂಪಾಯಿ 59,000/- (GST ಸೇರಿದ್ದರಿಂದ), ಟರ್ನ್ ಓವರ್ &lt; 10 ಕೋಟಿ.
                                    </AccordionBody>
                                </AccordionItem>
                                {/*Accordion Item 6*/}
                                {/* <AccordionItem>
                                    <AccordionHeader targetId="6">ಜಿಎಸ್‌ಟಿ ಎಂದರೇನು ?</AccordionHeader>
                                    <AccordionBody accordionId="6">
                                        ಜಿಎಸ್‌ಟಿ ಎಂದರೆ ಸರಕು ಮತ್ತು ಸೇವಾ ತೆರಿಗೆ, ಇದು ಭಾರತದಲ್ಲಿ ಸರಕು ಮತ್ತು ಸೇವೆಗಳ ಪೂರೈಕೆಯ ಮೇಲೆ ವಿಧಿಸಲಾಗುವ ಮೌಲ್ಯವರ್ಧಿತ ತೆರಿಗೆಯಾಗಿದೆ. GST ಅನ್ನು ಭಾರತದಲ್ಲಿ ಜುಲೈ 1, 2017 ರಂದು ಪರಿಚಯಿಸಲಾಯಿತು, ಇದು ಅಬಕಾರಿ ಸುಂಕ, ಸೇವಾ
                                        ತೆರಿಗೆ ಮತ್ತು ಮೌಲ್ಯವರ್ಧಿತ ತೆರಿಗೆ (ವ್ಯಾಟ್) ನಂತಹ ಹಲವಾರು ಕೇಂದ್ರ ಮತ್ತು ರಾಜ್ಯ ತೆರಿಗೆಗಳನ್ನು ಬದಲಿಸುವ ಸಮಗ್ರ ಪರೋಕ್ಷ ತೆರಿಗೆಯಾಗಿ ಪರಿಚಯಿಸಲಾಯಿತು. GST ಆಡಳಿತದ ಅಡಿಯಲ್ಲಿ, ಎಲ್ಲಾ ಸರಕುಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಅವುಗಳ ಸ್ವಭಾವದ
                                        ಆಧಾರದ ಮೇಲೆ ವಿವಿಧ ತೆರಿಗೆ ಸ್ಲ್ಯಾಬ್‌ಗಳಾಗಿ ವರ್ಗೀಕರಿಸಲಾಗಿದೆ ಮತ್ತು ಪೂರೈಕೆ ಸರಪಳಿಯ ಪ್ರತಿಯೊಂದು ಹಂತದಲ್ಲೂ ಸರಕು ಅಥವಾ ಸೇವೆಗಳ ಮೌಲ್ಯದ ಮೇಲೆ ನಿಗದಿತ ಶೇಕಡಾವಾರು ತೆರಿಗೆಯನ್ನು ವಿಧಿಸಲಾಗುತ್ತದೆ. GST ಎನ್ನುವುದು ಗಮ್ಯಸ್ಥಾನ ಆಧಾರಿತ
                                        ತೆರಿಗೆಯಾಗಿದೆ, ಅಂದರೆ ಸರಕು ಅಥವಾ ಸೇವೆಗಳ ಮೂಲವನ್ನು ಲೆಕ್ಕಿಸದೆಯೇ ಅವುಗಳ ಅಂತಿಮ ಬಳಕೆಯ ಮೇಲೆ ತೆರಿಗೆಯನ್ನು ವಿಧಿಸಲಾಗುತ್ತದೆ. ಪರೋಕ್ಷ ತೆರಿಗೆ ವ್ಯವಸ್ಥೆಯ ಸರಳೀಕರಣ, ಬಹು ತೆರಿಗೆಗಳ ನಿರ್ಮೂಲನೆ ಮತ್ತು ತೆರಿಗೆ ವಂಚನೆ ಮತ್ತು
                                        ಭ್ರಷ್ಟಾಚಾರವನ್ನು ಕಡಿಮೆ ಮಾಡುವುದು ಸೇರಿದಂತೆ ಜಿಎಸ್‌ಟಿ ಹಲವಾರು ಪ್ರಯೋಜನಗಳನ್ನು ಹೊಂದಿದೆ. ಇದು ಭಾರತದಾದ್ಯಂತ ಏಕರೂಪದ ತೆರಿಗೆ ರಚನೆಯನ್ನು ಒದಗಿಸುವ ಮೂಲಕ ವ್ಯವಹಾರವನ್ನು ಸುಲಭಗೊಳಿಸುವುದನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ, ಇದು ವಿವಿಧ ರಾಜ್ಯಗಳಲ್ಲಿ
                                        ವ್ಯವಹಾರಗಳನ್ನು ಸುಲಭವಾಗಿ ನಿರ್ವಹಿಸುವಂತೆ ಮಾಡುತ್ತದೆ. ಭಾರತದಲ್ಲಿ, GST ಅನ್ನು ಸರಕು ಮತ್ತು ಸೇವಾ ತೆರಿಗೆ ಮಂಡಳಿಯು ನಿರ್ವಹಿಸುತ್ತದೆ, ಇದು ಪ್ರತಿನಿಧಿಗಳನ್ನು ಒಳಗೊಂಡಿರುವ ಒಂದು ದೇಹವಾಗಿದೆ. ಕೇಂದ್ರ ಮತ್ತು ರಾಜ್ಯ ಸರ್ಕಾರಗಳು.
                                        ಕೌನ್ಸಿಲ್ ತೆರಿಗೆ ದರಗಳನ್ನು ಹೊಂದಿಸುವುದು, GST ಕಾನೂನಿಗೆ ಬದಲಾವಣೆಗಳನ್ನು ಮಾಡುವುದು ಮತ್ತು GST ಗೆ ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ವಿವಾದಗಳನ್ನು ಪರಿಹರಿಸುವ ಜವಾಬ್ದಾರಿಯನ್ನು ಹೊಂದಿದೆ.
                                    </AccordionBody>
                                </AccordionItem> */}
                                {/*Accordion Item 7*/}
                                {/* <AccordionItem>
                                    <AccordionHeader targetId="7">ಟಿಡಿಎಸ್ ಎಂದರೇನು ? </AccordionHeader>
                                    <AccordionBody accordionId="7">
                                        TDS ಎಂದರೆ ಮೂಲದಲ್ಲಿ ತೆರಿಗೆ ಕಡಿತಗೊಳಿಸಲಾಗಿದೆ, ಇದು ಪಾವತಿಯ ಸಮಯದಲ್ಲಿ ಭಾರತದಲ್ಲಿ ಆದಾಯ ತೆರಿಗೆಯನ್ನು ಸಂಗ್ರಹಿಸುವ ಕಾರ್ಯವಿಧಾನವಾಗಿದೆ. ವೇತನಗಳು, ವೃತ್ತಿಪರ ಶುಲ್ಕಗಳು, ಬಾಡಿಗೆಗಳು ಮತ್ತು ಗುತ್ತಿಗೆದಾರರು ಮತ್ತು
                                        ಸ್ವತಂತ್ರೋದ್ಯೋಗಿಗಳಿಗೆ ಪಾವತಿಗಳಂತಹ ವಿವಿಧ ರೀತಿಯ ಪಾವತಿಗಳಿಗೆ TDS ಅನ್ವಯಿಸುತ್ತದೆ. ಟಿಡಿಎಸ್‌ನ ಸಂದರ್ಭದಲ್ಲಿ, ಪಾವತಿದಾರನು ಪಾವತಿಯ ನಿರ್ದಿಷ್ಟ ಶೇಕಡಾವಾರು ಮೊತ್ತವನ್ನು ತೆರಿಗೆಯಾಗಿ ಕಡಿತಗೊಳಿಸಬೇಕಾಗುತ್ತದೆ ಮತ್ತು ಅದನ್ನು
                                        ಪಾವತಿಸುವವರ ಪರವಾಗಿ ಸರ್ಕಾರಕ್ಕೆ ಪಾವತಿಸಬೇಕಾಗುತ್ತದೆ. ಪಾವತಿಸುವವರು ತಮ್ಮ ಆದಾಯ ತೆರಿಗೆ ರಿಟರ್ನ್‌ಗಳನ್ನು ಸಲ್ಲಿಸುವಾಗ ಕಡಿತಗೊಳಿಸಲಾದ TDS ಗೆ ಕ್ರೆಡಿಟ್ ಅನ್ನು ಕ್ಲೈಮ್ ಮಾಡಬಹುದು. ಸೇವೆಗಳು ಮತ್ತು ಸರಕುಗಳ ಮೇಲಿನ TDS ದರಗಳು ವಹಿವಾಟಿನ
                                        ಸ್ವರೂಪ, ಪಾವತಿಯ ಪ್ರಕಾರ ಮತ್ತು ಪಾವತಿಸುವವರ ವರ್ಗದಂತಹ ವಿವಿಧ ಅಂಶಗಳನ್ನು ಅವಲಂಬಿಸಿರುತ್ತದೆ. ಭಾರತದಲ್ಲಿ TDS ಗೆ ಸಾಮಾನ್ಯವಾಗಿ ಅನ್ವಯವಾಗುವ ಕೆಲವು ದರಗಳು ಇಲ್ಲಿವೆ: <br /> <br />
                                        <b>ಸೇವೆಗಳ ಮೇಲೆ ಟಿಡಿಎಸ್:</b>
                                        <ul>
                                            <li>● ವೃತ್ತಿಪರ ಅಥವಾ ತಾಂತ್ರಿಕ ಸೇವೆಗಳಿಗಾಗಿ: 10% (ಪಾವತಿದಾರರು ಒಬ್ಬ ವ್ಯಕ್ತಿ ಅಥವಾ ಹಿಂದೂ ಅವಿಭಜಿತ ಕುಟುಂಬವಾಗಿದ್ದರೆ) ಅಥವಾ 2% (ಪಾವತಿದಾರರು ಕಂಪನಿ ಅಥವಾ ಪಾಲುದಾರಿಕೆ ಸಂಸ್ಥೆ ಆಗಿದ್ದರೆ).</li>
                                            <li>● ರಾಯಲ್ಟಿ ಅಥವಾ ತಾಂತ್ರಿಕ ಸೇವೆಗಳಿಗೆ ಶುಲ್ಕಕ್ಕಾಗಿ: 10% (ಪಾವತಿದಾರರು ಒಬ್ಬ ವ್ಯಕ್ತಿ ಅಥವಾ ಹಿಂದೂ ಅವಿಭಜಿತ ಕುಟುಂಬವಾಗಿದ್ದರೆ) ಅಥವಾ 2% (ಪಾವತಿದಾರರು ಕಂಪನಿ ಅಥವಾ ಪಾಲುದಾರಿಕೆ ಸಂಸ್ಥೆ ಆಗಿದ್ದರೆ).</li>
                                            <li>● ಕಮಿಷನ್ ಅಥವಾ ಬ್ರೋಕರೇಜ್ ಗಾಗಿ: 5% (ಪಾವತಿ ಪಡೆಯುವವರು ವ್ಯಕ್ತಿ ಅಥವಾ ಹಿಂದೂ ಭಾಗಿಸದ ಕುಟುಂಬವಾದರೆ) ಅಥವಾ 2% (ಪಾವತಿ ಪಡೆಯುವವರು ಕಂಪನಿ ಅಥವಾ ಕೂಡಲೇಖಕ್ಕೆ ಸೇರಿದ ಕಂಪನಿಯಾಗಿದ್ದರೆ).</li>
                                        </ul>
                                        <b>ವಸ್ತುಗಳ ಮೇಲೆ ಟಿಡಿಎಸ್:</b>
                                        <ul>
                                            <li>● ವ್ಯಾವಸಾಯಿಕ ಅಥವಾ ತಾಂತ್ರಿಕ ಸೇವೆಗಳ ಮೇಲೆ: 10% (ಪರಿಶೀಲಿಸುವವರು ವ್ಯಕ್ತಿ ಅಥವಾ ಹಿಂದೂ ಒಂದು ಕುಟುಂಬ) ಅಥವಾ 2% (ಪರಿಶೀಲಿಸುವವರು ಕಂಪನಿ ಅಥವಾ ಕಾರ್ಯಕ್ರಮ ನಿರ್ಮಿಸುವ ಸಂಘದಲ್ಲಿರುವವರು.</li>
                                            <li>● ಭೂಮಿ, ಕಟ್ಟಡ ಅಥವಾ ಮಂಚಗಳ ಬಾಡಿಗೆಗೆ: 10% (ವಾರ್ಷಿಕವಾಗಿ ಬಾಡಿಗೆ Rs. 2,40,000 ಅತಿವರೆಯಾದರೆ).</li>
                                            <li>● ಗುತ್ತಿಗೆದಾರರು ಮತ್ತು ಉಪ-ಗುತ್ತಿಗೆದಾರರಿಗೆ ಪಾವತಿಗಳಿಗಾಗಿ: 1% (ವೈಯಕ್ತಿಕ ಅಥವಾ ಹಿಂದೂ ಅವಿಭಜಿತ ಕುಟುಂಬಕ್ಕೆ) ಅಥವಾ 2% (ಕಂಪನಿ ಅಥವಾ ಪಾಲುದಾರಿಕೆ ಸಂಸ್ಥೆಗೆ).</li>
                                            <li>● ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಐಟಂಗಾಗಿ ಮಾರಾಟದ ಬಿಲ್ ಅನ್ನು ಹೆಚ್ಚಿಸಿದ ಮಾರಾಟಗಾರರಿಗೆ ಪಾವತಿಗಳಿಗೆ TD ಗಳು ಶೂನ್ಯ ಶೇಕಡಾವಾರು. </li>
                                        </ul>
                                        ಈ ದರಗಳು ಕಾಲಕಾಲಕ್ಕೆ ಬದಲಾಗಬಹುದು ಎಂಬುದನ್ನು ಗಮನಿಸುವುದು ಮುಖ್ಯ, ಮತ್ತು ಪಾವತಿದಾರರು ಯಾವುದೇ ಪಾವತಿಗಳನ್ನು ಮಾಡುವ ಮೊದಲು ಆದಾಯ ತೆರಿಗೆ ಇಲಾಖೆಯೊಂದಿಗೆ ಇತ್ತೀಚಿನ ದರಗಳನ್ನು ಪರಿಶೀಲಿಸಬೇಕು.
                                    </AccordionBody>
                                </AccordionItem> */}
                                {/*Accordion Item 8*/}
                                {/* <AccordionItem>
                                    <AccordionHeader targetId="8">ಕಿಯೋನಿಕ್ಸ್ನಲ್ಲಿ ಜಿಎಸ್‌ಟಿ ಪಾವತಿಯ ಟಿಡಿಎಸ್ ಏನು ?</AccordionHeader>
                                    <AccordionBody accordionId="8">
                                        ಕಿಯೋನಿಕ್ಸ್ನಲ್ಲಿ ಜಿಎಸ್‌ಟಿ ಪಾವತಿಗಳಿಗೆ ನಿರ್ದಿಷ್ಟಗೊಳ್ಳುವ ಟಿಡಿಎಸ್ ಮಾರ್ಗದರ್ಶಿಕೆಗಳ ಪ್ರಕಾರ, ಒಟ್ಟು ಪಾವತಿ ಮೊತ್ತದ 2% ವಾಣಿಜ್ಯಿಕ ಪಾವತಿಯಾಗುತ್ತದೆ. ಆದರೆ, ವ್ಯಾಪಾರ ರಾಜ್ಯಾಂತರದ ವ್ಯಾಪ್ತಿಯಲ್ಲಿ ಪಾವತಿಗೆ 1% ಸಿಜಿಎಸ್‌ಟಿ ಮತ್ತು
                                        1% ಎಸ್ಜಿಎಸ್‌ಟಿ ವಿಭಾಗಗಳಲ್ಲಿ ಹಂಚಿಕೊಳ್ಳಲಾಗುತ್ತದೆ. ಇತರ ವಿನಂತಿಗಾಗಿ, ವ್ಯಾಪಾರ ರಾಜ್ಯಾಂತರದ ವ್ಯಾಪ್ತಿಯಲ್ಲಿ ಪಾವತಿಗೆ 2% ಐಜಿಎಸ್‌ಟಿ ಹಂಚಿಕೊಳ್ಳಲಾಗುತ್ತದೆ.
                                        <br />
                                        <br />
                                        ಆದುದರಿಂದ, ನೀವು ಕಿಯೋನಿಕ್ಸ್ ನಲ್ಲಿ ಸೇವೆಗಳನ್ನು ಅಥವಾ ವಸ್ತುಗಳನ್ನು ಕೊಡುಗೆಯಾಗಿ ಕೊಡುವ ಕಾಲದಲ್ಲಿ, ಅದು ಜಿಎಸ್ಟಿಗೆ ಒಳಗಾಗಿದ್ದರೆ, ಹಣ ಪಾವತಿಯ ದರ 2% ಆಗುವುದು. ವ್ಯಾಪಾರ ರಾಜ್ಯಾಂತರದಲ್ಲಿದ್ದರೆ, ಪಾವತಿಯ ಮೊತ್ತವನ್ನು 1% ಸಿಜಿಎಸ್ಟಿ
                                        ಮತ್ತು 1% ಎಸ್ಜಿಎಸ್ಟಿಗೆ ಹಂಚಿಕೊಳ್ಳಲಾಗುವುದು. ವ್ಯಾಪಾರ ರಾಜ್ಯಾಂತರವಾಗಿದ್ದರೆ, ಪಾವತಿಯ ದರ 2% ಐಜಿಎಸ್ಟಿಗೆ ಹಂಚಿಕೊಳ್ಳಲಾಗುವುದು.
                                        <br />
                                        <br />
                                        ಗಮನಿಸಬೇಕಾದ ಮುಖ್ಯವಾದ ಅಂಶವೆಂದರೆ, ಎಲ್ಲ ಪಾವತಿ ಮೊತ್ತವನ್ನು ಗ್ರಾಹಕರಿಂದ ಪಡೆಯುವ ಹಣದ ಮೊತ್ತದ ಮೇಲೆ ಹರಿತ ಉಳಿಯಬೇಕು. ಹರಿತ ಮೊತ್ತವನ್ನು ಆಯಕರ ಇಲಾಖೆಯಿಂದ ನಿರ್ಧಾರಿಸಲಾದ ನಿರ್ದಿಷ್ಟ ಹದಿತಿನಿಂದ ಸರ್ಕಾರಕ್ಕೆ ತೆಗೆದುಕೊಳ್ಳಬೇಕು. ಹೀನಾಯ
                                        ಪಡುವಣಿಗೆ ಅಥವಾ ಶಿಕ್ಷೆ ಪಡೆಯಬಹುದು.
                                    </AccordionBody>
                                </AccordionItem> */}
                                {/*Accordion Item 9*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="9">ಕಿಯೋನಿಕ್ಸ್ ಬೆಲೆ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆಯ ಪ್ರಯೋಜನಗಳೇನು ?</AccordionHeader>

                                    <AccordionBody accordionId="9">
                                        ಕಿಯೋನಿಕ್ಸ್ ಬೆಲೆ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆಯು ಖರೀದಿದಾರರಿಗೂ ಮಾರಾಟಗಾರರಿಗೂ ಅನೇಕ ಪ್ರಯೋಜನಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ. ಇಲ್ಲಿ ಕೆಲವು ಮುಖ್ಯ ಲಾಭಗಳು: <br />
                                        <br />
                                        <ol>
                                            <li>
                                                1. ಪಾರದರ್ಶಕ ಮತ್ತು ಸ್ಪರ್ಧಾತ್ಮಕ ಬೆಲೆ: ದರ ಒಪ್ಪಂದ ವ್ಯವಸ್ಥೆಯು ಸರಕು ಮತ್ತು ಸೇವೆಗಳ ಬೆಲೆಗಳನ್ನು ಪೂರ್ವ-ಸಂಧಾನ ಮತ್ತು ನಿರ್ದಿಷ್ಟ ಅವಧಿಗೆ ನಿಗದಿಪಡಿಸಲಾಗಿದೆ ಎಂದು ಖಚಿತಪಡಿಸುತ್ತದೆ. ಬೆಲೆಗಳು ಸ್ಪರ್ಧಾತ್ಮಕ ಮತ್ತು
                                                ಪಾರದರ್ಶಕವಾಗಿವೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಇದು ಸಹಾಯ ಮಾಡುತ್ತದೆ ಮತ್ತು ಖರೀದಿದಾರರು ತಮ್ಮ ಹಣಕ್ಕೆ ಉತ್ತಮ ಮೌಲ್ಯವನ್ನು ಪಡೆಯುವ ಭರವಸೆ ನೀಡಬಹುದು.
                                            </li>
                                            <li>
                                                2. ಸಮಯ ಮತ್ತು ಖರ್ಚು ಉಳಿಸುವುದು: ಬೆಲೆ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಖರೀದಿದಾರರಿಗೂ ಮಾರಾಟಗಾರರಿಗೂ ಸಮಯ ಮತ್ತು ಖರ್ಚುಗಳನ್ನು ಉಳಿಸುತ್ತದೆ. ಖರೀದಿದಾರರು ಪ್ರತ್ಯೇಕವಾಗಿ ಪ್ರತಿ ಮಾರಾಟಗಾರರೊಂದಿಗೆ ಖರೀದಿ, ಮಾರಾಟ, ಮತ್ತು ಬೆಲೆ
                                                ಅಂತಿಮೀಕರಣ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಹೋಗಲಾಡಿಸಬೇಕಾಗುವುದಿಲ್ಲ. ಮಾರಾಟಗಾರರು ಮತ್ತು ಖರೀದಿದಾರರು ಪ್ರತಿ ಖರೀದಿ ಅವಕಾಶಕ್ಕೆ ಮಾರ್ಕೆಟಿಂಗ್, ವಿಜ್ಞಾಪನ ಮತ್ತು ಬೆಲೆ ಹೋರಾಟಗಳ ಖರ್ಚುಗಳನ್ನು ಉಳಿಸಬಹುದು.
                                            </li>
                                            <li>
                                                3. ಗುಣಮಟ್ಟದ ಖಚಿತತೆ: ಬೆಲೆ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಆವಶ್ಯಕ ಗುಣಮಟ್ಟದ ಮಾಲ್ಪನೆಯನ್ನು ಖಚಿತಗೊಳಿಸುವಲ್ಲಿ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ವ್ಯಾಪಾರಿಗಳು ಕಾಂಟ್ರಾಕ್ಟ್ ನ ಶರತ್ತುಗಳನ್ನು ಪಾಲನೆ ಮಾಡಬೇಕಾಗಿದೆ ಮತ್ತು ಯಾವುದೇ ಕಾಂಟ್ರಾಕ್ಟ್
                                                ಭಂಗವುಳ್ಳವರಿಗೆ ಶಿಕ್ಷಾರ್ಹತೆ ಮತ್ತು ಕರ್ಮಚಾರಿಗೆ ಮುದ್ರಣ ಮಾಡಲಾಗುತ್ತದೆ.
                                            </li>
                                            <li>
                                                4. ಮಾರಾಟಗಾರರ ಸುಲಭ ಪ್ರವೇಶ: ಬೆಲೆ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆಯ ಮೂಲಕ ಕಿಯೋನಿಕ್ಸ್ ದ್ವಾರಾ ಪಟ್ಟಿಗೆ ತೆಗೆದುಕೊಂಡ ಮಾರಾಟಗಾರರ ಕೇಂದ್ರೀಕೃತ ಡೇಟಾಬೇಸ್ ಒದಗಿಸುತ್ತದೆ. ಇದು ಖರೀದಿ ಆವಶ್ಯಕತೆಗಳಿಗೆ ವಿವಿಧ ಮಾರಾಟಗಾರರನ್ನು ಸುಲಭವಾಗಿ
                                                ಪ್ರವೇಶಿಸಲು ಖರ್ಚು ಮಾಡುತ್ತದೆ.
                                            </li>
                                            <li>
                                                5. ಮೋಸಗಾರಿಕೆ ಮತ್ತು ಭ್ರಷ್ಟಾಚಾರದ ಹಂತಗಳ ಕಡಿಮೆ ಅಪಾಯ: ಬೆಲೆ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಮೂಲಕ ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಯು ಪ್ರಾದೇಶಿಕವಾಗಿಯೂ ನ್ಯಾಯಮೂಲಕವಾಗಿಯೂ ನಡೆಸಲ್ಪಡುತ್ತದೆ ಎಂಬುದರಿಂದ ಮೋಸಗಾರಿಕೆ ಮತ್ತು ಭ್ರಷ್ಟಾಚಾರದ ಅಪಾಯ
                                                ಕಡಿಮೆಯಾಗುತ್ತದೆ. ವ್ಯಾಪಾರಿಗಳು ಅವರ ಯೋಗ್ಯತೆ ಮತ್ತು ಸಾಮರ್ಥ್ಯದ ಆಧಾರದ ಮೇಲೆ ಆಯ್ಕೆಗೆ ಒಳಪಟ್ಟಿರುತ್ತಾರೆ ಮತ್ತು ಖರೀದಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಕಠಿಣ ನಿಯಮಗಳ ಮತ್ತು ನಿಯಮಗಳಿಂದ ನಿರ್ವಹಿಸಲಾಗುತ್ತದೆ.
                                            </li>
                                        </ol>
                                        ಸಂಗ್ರಹದಲ್ಲಿ, ಕೇಆನಿಕ್ಸ್ ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆಯು ಖರೀದಾರರಿಗೂ ಮಾರುತ್ತೂ ಮಾರುತ್ತೂ ಅನುಕೂಲಗಳ ಸಮೂಹವನ್ನು ಒದಗಿಸುತ್ತದೆ. ಈ ಅನುಕೂಲಗಳಲ್ಲಿ ಪರಿಷ್ಕರಗೊಳಿಸಲು, ಖರ್ಚು ಕಡಿಮೆಗೆ, ಗುಣಮಟ್ಟದ ಆಶ್ವಾಸನೆಗೆ, ವ್ಯಾಪಾರಿಗಳ ಸುಲಭ ಪ್ರವೇಶಕ್ಕೆ ಮತ್ತು ಕಡಿಮೆ ಅಪಾಯಕ್ಕೆ ಸಂಕುಚಿಸುವುದು ಸೇರಿದೆ.
                                    </AccordionBody>
                                </AccordionItem>
                                {/*Accordion Item 10*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="10">ಬೆಲೆ ಕಾಂಟ್ರಾಕ್ಟ್ ಯಾವ ಕಾಲಾವಧಿಯಲ್ಲಿದೆ ?</AccordionHeader>

                                    <AccordionBody accordionId="10">೧೮೦ ದಿನಗಳು (6 ತಿಂಗಳು)</AccordionBody>
                                </AccordionItem>
                                {/*Accordion Item 11*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="11">ಬೆಲೆ ಬದಲಾವಣೆಗಳು 180 ದಿನಗಳವರೆಗೆ ಇರುತ್ತವೆಂದು ಹೇಳಲಾಗುತ್ತದೆಯೇ ?</AccordionHeader>
                                    <AccordionBody accordionId="11">
                                    ಸಾಮಾನ್ಯವಾಗಿ ಅಲ್ಲ, ಬೆಲೆಗಳು ಮತ್ತು ಸೂಜಿಯ ಗರಿಕೆ ಒಂದು ನಿರ್ಧಾರಿತ ಪರಿಧಿಯನ್ನು ಮೀರಿ ಹೋಗುತ್ತದೆ ಎಂಬುದು ಕಿಯೋನಿಕ್ಸ್ ನ ನಿರ್ವಹಣೆಯಲ್ಲಿ ವಿಶ್ಲೇಷಿಸಲ್ಪಡುವುದು ಮತ್ತು ನಿರ್ಧಾರಿಸಲ್ಪಡುವುದು.
                                    </AccordionBody>
                                </AccordionItem>
                                {/*Accordion Item 12*/}
                                <AccordionItem>
                                    <AccordionHeader targetId="12">ಕಿಯೋನಿಕ್ಸ್ ಅಮಲುಗೊಳ್ಳುವ ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆಯಿಂದ ಇಲಾಖೆಗೆ ಹೇಗೆ ಪ್ರಯೋಜನ ಸಿಗಬಹುದು ?</AccordionHeader>
                                    <AccordionBody accordionId="12">
                                        ಕಿಯೋನಿಕ್ಸ್ ದ್ವಾರಾ ಅಮಲುಗೊಳ್ಳುವ ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಒಂದು ಇಲಾಖೆಗೆ ಹಲವು ಪ್ರಯೋಜನಗಳನ್ನು ತರಬಹುದು. ಇಲ್ಲಿ ಕೆಲವು ಮುಖ್ಯ ಪ್ರಯೋಜನಗಳು ಇವೆ:
                                        <br />
                                        <br />
                                        <ol>
                                            <li>
                                                1. <b>ಖರ್ಚು ಕಡಿಮೆಗೊಳಿಸುವುದು:</b> ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆಯನ್ನು ಬಳಸುವುದರಿಂದ, ಒಂದು ಇಲಾಖೆಗೆ ಮೊದಲೇ ಮಾಧ್ಯಮದ ಬೆಲೆಯಲ್ಲಿ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಪಡೆಯಬಹುದು, ಇದು ಖರ್ಚುಗಳನ್ನು ತಗ್ಗಿಸುವಲ್ಲಿ ಸಹಾಯ
                                                ಮಾಡಬಹುದು. ಏಕೆಂದರೆ, ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆಯ ಮೂಲಕ ಒದಗಿಸಲ್ಪಡುವ ಬೆಲೆಗಳು ಸಾಮಾನ್ಯವಾಗಿ ಮಾರ್ಕೆಟ್ ಬೆಲೆಗಳಿಗಿಂತ ಕಡಿಮೆಯಾಗಿರುತ್ತವೆ.
                                            </li>
                                            <li>
                                                2. <b>ಸಮಯ ಉಳಿಸುವುದು:</b> ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಇರುವುದರಿಂದ, ಪ್ರತಿ ಖರೀದಿಗೆ ಬೆಲೆ ನಿರ್ಧರಿಸುವ ಮೂಲಕ ಇಲಾಖೆಗಳು ಸಮಯವನ್ನು ಹೋಗಲಾಡಿಸಬೇಕಾಗುವುದಿಲ್ಲ. ಇದು ಅಪಾರ ಸಮಯ ಮತ್ತು ಶ್ರಮವನ್ನು ಉಳಿಸಬಹುದು ಮತ್ತು ಇಲಾಖೆಗೆ
                                                ಇತರ ಮುಖ್ಯ ಕಾರ್ಯಗಳಿಗೆ ಮುಂದುವರೆಯುವ ಅವಕಾಶ ನೀಡಬಹುದು.
                                            </li>
                                            <li>
                                                3. <b>ಗುಣಮಟ್ಟದ ಖಚರುತ್ವ:</b> ಕಿಯೋನಿಕ್ಸ್ ಒಂದು ಮುಕ್ತಾಯಮಾನ ಸಂಸ್ಥೆಯೂ, ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಮೂಲಕ ಒದಗಿಸುವ ಉತ್ಪನ್ನಗಳ ಮತ್ತು ಸೇವೆಗಳ ಗುಣಮಟ್ಟವನ್ನು ಖಚರುಗೊಳಿಸುತ್ತದೆ. ಇದು ಇಲಾಖೆಗಳಿಗೆ ಸಾರ್ವಜನಿಕ ಒಪ್ಪಂದದ
                                                ಮೂಲಕ ಅರ್ಹ ಬೆಲೆಯಲ್ಲಿ ಗುಣಮಟ್ಟದ ಉತ್ಪನ್ನಗಳನ್ನು ಪಡೆಯುವುದನ್ನು ಖಚರುಗೊಳಿಸಲು ಸಹಾಯ ಮಾಡಬಹುದು.
                                            </li>
                                            <li>
                                                4. <b>ಪಾರದರ್ಶಕತೆ:</b> ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಒಂದು ಪಾರದರ್ಶಕ ಕೊರತೆ ವಿನಿಯೋಗ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಒದಗಿಸುತ್ತದೆ, ಹೇಗೆ ಎಲ್ಲ ನಿಬಂಧನೆಗಳು ಮತ್ತು ಶರತ್ತುಗಳು ಸ್ಪಷ್ಟವಾಗಿ ವ್ಯಾಖ್ಯಾನಿಸಲ್ಪಟ್ಟಿವೆ. ಇದು ಇಲಾಖೆಗಳಿಗೆ
                                                ಪರಿಚಯ ಅಥವಾ ಪರಿರೋಧಗಳ ಜೊತೆಗೆ ನಿರ್ಮಾಣ ಸರಬರಾಜು ಮಾಡಲು ಸಹಾಯ ಮಾಡಬಹುದು.
                                            </li>
                                            <li>
                                                5. <b>ಮಾನಕೀಕರಣ:</b> ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಮೂಲಕ ಎಲ್ಲ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳು ನಿರ್ದಿಷ್ಟ ಮಾನಕಗಳನ್ನು ಪಾಲಿಸುತ್ತವೆ, ಇದು ಇಲಾಖೆಯ ಮೂಲಕ ಗುಣಮಟ್ಟದ ಸ್ಥಿರತೆಯನ್ನು ಖಚರುಗೊಳಿಸುವಲ್ಲಿ ಸಹಾಯ ಮಾಡಬಹುದು.
                                            </li>
                                        </ol>
                                        ಒಟ್ಟಾರೆ, ಕಿಯೋನಿಕ್ಸ್ ನಿಂದ ಅಮಲುಗೊಳ್ಳುವ ರೇಟ್ ಕಾಂಟ್ರಾಕ್ಟ್ ವ್ಯವಸ್ಥೆ ಒಂದು ಇಲಾಖೆಗೆ ಹಲವಾರು ಪ್ರಯೋಜನಗಳನ್ನು ತರಬಹುದು. ಈ ಪ್ರಯೋಜನಗಳು ಖರ್ಚು ಉಳಿಸುವುದು, ಸಮಯ ಉಳಿಸುವುದು, ಗುಣಮಟ್ಟದ ಖಚರುತ್ವವನ್ನು ಖಚರುಗೊಳಿಸುವುದು, ಪಾರದರ್ಶಕತೆ
                                        ಮತ್ತು ಮಾನಕೀಕರಣದ ಮೂಲಕ ಇದ್ದುಕೊಳ್ಳಲ್ಪಡಬಹುದು.
                                    </AccordionBody>
                                </AccordionItem>
                            </UncontrolledAccordion>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
