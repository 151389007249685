import React, { useEffect, useState,useContext } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { ErrorMessage } from "formik";

import DatePicker from "react-date-picker";
import { DOCS_URL } from "../../../../Library/ApiSettings";
import { it_turn_mark_caluction } from "../../../../Library/Constants/helpers";
import TextError from "../../../../Components/Errors/TextError";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import constants from "../../../../Library/Constants/Constants";
import { Vendor_Settings_ReuploadModal } from "../../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";
import { Context } from "../../../../Components/Context/Context";


const DOCUMENT_URL = DOCS_URL;
export default function ITTurnoverReuplaodModal(props: any) {
  
const {language,setLanguage}=useContext(Context);
  const preventNegativeValues = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/; // regex pattern to match only numeric input
    if (!regex.test(keyValue)) {
      e.preventDefault();
      ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  };
  const [turn_mark, setTurnMark] = useState({
    cat: "",
    mark: "",
  });
  useEffect(() => {
    let first_yr_amount = props?.values?.it_turn_over_second_amount
      ? props?.values?.Ffinancial_year_amount
      : 0;
    let second_yr_amount = props?.values?.Sfinancial_year_amount
      ? props?.values?.Sfinancial_year_amount
      : 0;

    let total = first_yr_amount + second_yr_amount;

    let mark_turn_over: any = it_turn_mark_caluction(total);

    setTurnMark({
      ...turn_mark,
      cat: mark_turn_over?.[0],
      mark: mark_turn_over?.[1],
    });
    props?.setFieldValue("mark_it_turn", total == 0 ? 0 : mark_turn_over?.[1]);
  }, [
    props?.values?.Ffinancial_year_amount,
    props?.values?.Sfinancial_year_amount,
  ]);
  const handleFileChange = (e: any, values?: any) => {
    props?.type === constants?.DOCUMENTS?.IT_TURN_OVER_ONE
      ? props?.setFieldValue("it_turn_over_first_document", e.target.files[0])
      : props?.setFieldValue("it_turn_over_second_document", e.target.files[0]);
  };
  return (
    <>
      <ModalContainer
        open={props?.isShowing}
        setOpen={() => {
          props?.setIsShowing(false);
          props?.handleReset();
        }}
        header="Document Reupload"
      >
        <div className="company-information-update-wrap">
          <h4>{Vendor_Settings_ReuploadModal?.IT_Turnover?.[language]}</h4>
          <hr />

          <Form className="form-wrap" onSubmit={props?.handleSubmit}>
            {/* first */}{" "}
            {props?.type === constants?.DOCUMENTS?.IT_TURN_OVER_ONE && (
              <>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.First_IT_Year?.[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <DatePicker
                          value={props?.values?.it_turn_over_first_year}
                          onChange={(e: any) => {
                            props?.setFieldValue("it_turn_over_first_year", e);
                          }}
                          name="it_turn_over_first_year"
                          format="dd-MM-y"
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          // className={` ${
                          //   props?.touched.it_turn_over_first_year &&
                          //   props?.errors.it_turn_over_first_year
                          //     ? "is-invalid"
                          //     : ""
                          // }`}
                          maxDate={new Date()}
                        />

                        <ErrorMessage
                          name="it_turn_over_first_year"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>
                      {Vendor_Settings_ReuploadModal?.First_IT_Year_Documents?.[language]}  
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        <Input
                          type="file"
                          id="it_turn_over_first_document"
                          name="it_turn_over_first_document"
                          className="form-control"
                          accept=".pdf"
                          onChange={(e: any) =>
                            handleFileChange(e, e.target.files[0])
                          }
                          disabled={
                            props?.values?.it_turn_over_first_year
                              ? false
                              : true
                          }
                        ></Input>
                        <Label
                          className="image-button"
                          for="it_turn_over_first_document"
                          id="it_turn_over_first_document"
                        >
                          {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}
                        </Label>
                      </div>
                      <ErrorMessage
                        name="it_turn_over_first_document"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <div className="custom-input">
                      <Label>
                      {Vendor_Settings_ReuploadModal?.First_IT_Year_Documents?.[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <Input
                        type="number"
                        name="it_turn_over_first_amount"
                        onChange={props?.handleChange}
                        value={props?.values?.it_turn_over_first_amount}
                        min="1"
                        placeholder="Amount"
                        disabled={
                          props?.values?.it_turn_over_first_year ? false : true
                        }
                      ></Input>
                      <ErrorMessage
                        name="it_turn_over_first_amount"
                        component={TextError}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {/* second */}
            {props?.type === constants?.DOCUMENTS?.IT_TURN_OVER_TWO && (
              <>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Second_IT_Year?.[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>
                        <br />

                        <DatePicker
                          value={props?.values?.it_turn_over_second_year}
                          onChange={(e: any) => {
                            props?.setFieldValue("it_turn_over_second_year", e);
                            // props?.setFieldValue("Tfinancial_year", "");
                          }}
                          name="it_turn_over_second_year"
                          format={"dd-MM-y"}
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          className={`form-control ${
                            props?.touched.it_turn_over_second_year &&
                            props?.errors.it_turn_over_second_year
                              ? "is-invalid"
                              : ""
                          }`}
                          maxDate={
                            props?.list <= 0
                              ? props?.values?.it_turn_over_first_year
                                ? new Date(
                                    `${props?.values?.it_turn_over_first_year?.getFullYear()}`?.toString()
                                  )
                                : new Date()
                              : new Date()
                          }
                        />

                        <ErrorMessage
                          name="it_turn_over_second_year"
                          component={TextError}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col sm={4}>
                    <FormGroup>
                      <Label>
                      {Vendor_Settings_ReuploadModal?.Second_IT_Year_Documents?.[language]}
                        <span
                          className="text-danger"
                          style={{ fontSize: "18px" }}
                        >
                          *
                        </span>
                      </Label>
                      <div className="image-input">
                        {" "}
                        <Input
                          type="file"
                          id="it_turn_over_second_document"
                          name="it_turn_over_second_document"
                          className="form-control"
                          accept=".pdf"
                          onChange={(e: any) =>
                            handleFileChange(e, e.target.files[0])
                          }
                          disabled={
                            props?.values?.it_turn_over_second_year
                              ? false
                              : true
                          }
                        ></Input>
                        <Label
                          className="image-button"
                          for="it_turn_over_second_document"
                          id="it_turn_over_second_document"
                        >
                         {Vendor_Settings_ReuploadModal?.Choose_Documents?.[language]}
                        </Label>
                      </div>
                      <ErrorMessage
                        name="it_turn_over_second_document"
                        component={TextError}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    {" "}
                    <FormGroup>
                      <div className="custom-input">
                        <Label>
                        {Vendor_Settings_ReuploadModal?.Second_IT_Year_Amount?.[language]}
                          <span
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            *
                          </span>
                        </Label>

                        <Input
                          type="number"
                          name="it_turn_over_second_amount"
                          className="form-control"
                          min="1"
                          onChange={props?.handleChange}
                          placeholder="Amount"
                          value={props?.values?.it_turn_over_second_amount}
                          disabled={
                            props?.values?.it_turn_over_second_year
                              ? false
                              : true
                          }
                        ></Input>

                        <ErrorMessage
                          name="it_turn_over_second_amount"
                          component={TextError}
                        />
                      </div>{" "}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            <div className="mt-5">{Vendor_Settings_ReuploadModal?.Approved_by_Chartered_Accountant?.[language]}</div>
            <Row className="mt-5">
              <Col md={4} sm={4}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.CA_Name?.[language]}{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="it_turn_over_ca_name"
                      type="text"
                      value={props?.values?.it_turn_over_ca_name}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="it_turn_over_ca_name"
                    component={TextError}
                  />
                </FormGroup>
              </Col>

              <Col md={4} sm={4}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.CA_Email?.[language]}{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="it_turn_over_ca_email"
                      type="email"
                      value={props?.values?.it_turn_over_ca_email}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="it_turn_over_ca_email"
                    component={TextError}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={4}>
                <FormGroup>
                  <div className="custom-input">
                    <Label>
                      {" "}
                      {Vendor_Settings_ReuploadModal?.CA_Phone_Number?.[language]}{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize: "18px" }}
                      >
                        *
                      </span>
                    </Label>
                    <Input
                      name="it_turn_over_ca_phone"
                      type="tel"
                      onKeyPress={preventNegativeValues}
                      value={props?.values?.it_turn_over_ca_phone}
                      onChange={props?.handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="it_turn_over_ca_phone"
                    component={TextError}
                  />
                </FormGroup>
              </Col>
              {/* <Col sm={12}>
                {props?.values?.it_turn_over_ca_phone &&
                  props?.values?.it_turn_over_ca_email &&
                  props?.values?.it_turn_over_ca_name &&
                  props?.values?.it_turn_over_first_amount &&
                  props?.values?.it_turn_over_second_amount &&
                  props?.values?.it_turn_over_first_document &&
                  props?.values?.it_turn_over_second_document && (
                    <>
                      <span className="text-success mark-end">
                        Catagory:{turn_mark?.cat}
                      </span>
                      <span className="text-success mark-end">
                        Mark:{turn_mark?.mark}
                      </span>
                    </>
                  )}
              </Col> */}
            </Row>
            <Row className="mt-3">
              <Col sm={12} className="text-end">
                <Button
                  className="btn btn-primary mb-5"
                  color="primary"
                  type="submit"
                >
                   {Vendor_Settings_ReuploadModal?.Submit?.[language]}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </>
  );
}
