import { useState, useEffect, useContext } from "react";
import {
  Button,
  Collapse,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  List,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Dashboard from "./../../../../Pages/Admin/Overview/Overview";
import { Context } from "../../../../Components/Context/Context";
//language
import { VendorRegLang } from "../../../../Library/LanguageConversion/VendorLang";

interface proptype {
  setOpenVendorSidebar: Function;
  openSideVendorbar: boolean;
  setSidebarIsOpen: Function;
  sidebarIsOpen: boolean;
}

function VendorSidebar(
  {
    setOpenVendorSidebar,
    openSideVendorbar,
    sidebarIsOpen,
    setSidebarIsOpen,
  }: proptype,
  args: any
) {
  const { paneltoggle, setpaneltoggle } = args;
  const navigate = useNavigate();
  const location_details = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const pathname = window.location.pathname;
  //language
  const { language, setLanguage } = useContext(Context);
  const [value, setValue] = useState({
    document_status: "",
    emd_status: "",
    start_up: "",
    advanced: "",
  });
  useEffect(() => {
    api
      .get(`/vendor-document-status`, true)
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          setValue({
            ...value,
            document_status: response?.data?.document_status,
            emd_status: response?.data?.emd_status,
            start_up: response?.data?.is_startup,
            advanced: response?.data?.is_advanced,
          });
          if (response?.data?.emd_status == 0) {
            navigate(pathname);
          } else {
            // navigate(pathname);
          }
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {/* desktop-sidebar */}
      <div
        className={`${
          sidebarIsOpen ? "col-md-2" : "minimized-sidebar-wrap"
        } col-md-2 desktop-sidebar ${
          !paneltoggle ? "panel-none" : null
        } panel-block  `}
      >
        <Col md={12} className="logo-outer">
          <div className="logo-inner-wrap">
            <a className="header-logo" href="#">
              {sidebarIsOpen ? (
                <img
                  src="/images/logo-white1.png"
                  className="d-block"
                  alt="icon"
                />
              ) : (
                <img src="../images/mob-logo.png"></img>
              )}
            </a>
            <div className="minimise-sidebar">
              <span onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>
                <i
                  className={`bi bi-arrow-${
                    sidebarIsOpen ? "left" : "right"
                  }-circle`}
                ></i>
              </span>
            </div>
          </div>
        </Col>
        <div className="sidebar-wrap vendor-sidebar">
          <List className="nav">
            {value?.emd_status == "0" && (
              <>
                <li
                  className={
                    location_details?.pathname == "/basic-details-view" ||
                    location_details?.pathname == "/advanced-registration" ||
                    location_details?.pathname == "/emd-details" ||
                    location_details?.pathname == "/empanelment-details" ||
                    location_details?.pathname == "/document-upload" ||
                    location_details?.pathname == "/start-up-document-upload" ||
                    location_details?.pathname == "/chellon"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/basic-details-view"
                    title="Dashboard"
                    className="nav-link"
                  >
                    <i className="icons icons-settings" />
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.profile_settings[language]}
                    </span>
                  </Link>
                </li>
              </>
            )}
            {value?.emd_status == "1" && (
              <>
                <li
                  className={
                    location_details?.pathname == "/overview-vendor"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/overview-vendor"
                    title="Dashboard"
                    className="nav-link"
                  >
                    <i className="icons icons-dashboard" />
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.dashboard[language]}
                    </span>
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/received-order-vendor" ||
                    location_details?.pathname == "/cancelled-order-vendor" ||
                    location_details?.pathname == "/processed-order-vendor" ||
                    location_details?.pathname == "/outofdelivery" ||
                    location_details?.pathname == "/rejected-order-vendor"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/received-order-vendor"
                    title="Purchase Order"
                    className="nav-link"
                  >
                    <i className="icons icons-purchase-order"></i>
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.purchase_order[language]}
                    </span>
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/delivered-vendor"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/delivered-vendor"
                    title="Delivered"
                    className="nav-link"
                  >
                    <i className="icons icons-delivered" />
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.delivered[language]}
                    </span>
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/feedback-summary-vendor"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/feedback-summary-vendor"
                    title="Feedback & Summary"
                    className="nav-link"
                  >
                    <i className="icons icons-feedback-summary"></i>
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.feedback_and_summary[language]}
                    </span>
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/payment-vendor"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/payment-vendor"
                    title="Payments Lists"
                    className="nav-link"
                    onClick={() => setOpenVendorSidebar(false)}
                  >
                    <i className="icons icons-payments" />
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.payment_lists[language]}
                    </span>
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>

                {/* <li className="nav-item  dropdown">
                <Link
                    to="#"
                    title="Services"
                    color="link"
                    onClick={() => setIsOpen(!isOpen)}
                    className={`nav-link text-white text-start ${
                      sidebarIsOpen ? "dropdown-toggle" : "dropdown-toggle"
                    } ${isOpen ? "link-toggle-open" : "link-toggle-close"}`}
                  >
                    <i className="icons icons-services"></i>
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.services[language]}
                    </span>
                  </Link>
                <a className="nav-link text-white" data-bs-toggle="collapse" href="#sidebar-collapse01" role="button" aria-expanded="false" aria-controls="sidebar-collapse01"></a>
                <Collapse isOpen={isOpen} {...args}>
                    <List id="sidebar-collapse01">
                      <li
                        className={
                          location_details?.pathname ==
                            "/vendor-open-tickets" ||
                          location_details?.pathname ==
                            "/vendor-closed-tickets" ||
                          location_details?.pathname ==
                            "/vendor-pending-tickets"
                            ? "sub-menu active"
                            : "sub-menu"
                        }
                      >
                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link
                          to="/vendor-open-tickets"
                          title="Tickets"
                          className="nav-link"
                        >
                          <i className="icons icons-tickets "></i>
                          <span
                            className={`${
                              sidebarIsOpen
                                ? "toggle-links"
                                : "d-none toggle-links"
                            }`}
                          >
                            {VendorRegLang?.tickets[language]}
                          </span>
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li>
                      <li
                        className={
                          location_details?.pathname == "/amc-vendor"
                            ? "sub-menu active"
                            : "sub-menu"
                        }
                      >
                        <span className="curve-before">
                          <span className="curve-inner"></span>
                        </span>
                        <Link to="/amc-vendor" title="AMC" className="nav-link">
                          <i className="icons icons-amc"></i>
                          <span
                            className={`${
                              sidebarIsOpen
                                ? "toggle-links"
                                : "d-none toggle-links"
                            }`}
                          >
                            {VendorRegLang?.amc[language]}
                          </span>
                        </Link>
                        <span className="curve-after">
                          <span className="curve-inner"></span>
                        </span>
                      </li>
                    </List>
                  </Collapse>
                </li> */}
                <li
                  className={
                    location_details?.pathname ==
                      "/schedule-maintenance-vendor-completed" ||
                    location_details?.pathname ==
                      "/schedule-maintenance-vendor-pending"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/schedule-maintenance-vendor-completed"
                    title="Schedule Maintenance"
                    className="nav-link"
                  >
                    <i className="icons icons-schedule-maintenance"></i>
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.schedule_maintenance[language]}
                    </span>
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/vendor-documents-view"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/vendor-documents-view"
                    title="Documents"
                    className="nav-link"
                  >
                    <i className="icons icons-documents"></i>
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.documents[language]}
                    </span>
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
                <li
                  className={
                    location_details?.pathname == "/vendor-profile-settings" ||
                    location_details?.pathname == "/vendor-documents" ||
                    location_details?.pathname == "/vendor-account-details"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <span className="curve-before">
                    <span className="curve-inner"></span>
                  </span>
                  <Link
                    to="/vendor-profile-settings"
                    title="Settings"
                    className="nav-link"
                  >
                    <i className="icons icons-settings"></i>
                    <span
                      className={`${
                        sidebarIsOpen ? "toggle-links" : "d-none toggle-links"
                      }`}
                    >
                      {VendorRegLang?.settings[language]}
                    </span>
                  </Link>
                  <span className="curve-after">
                    <span className="curve-inner"></span>
                  </span>
                </li>
              </>
            )}

            {/* {value?.emd_status !== "0"&& (
              <>
               
              </>
            )} */}
          </List>
        </div>
      </div>
      {/* Desktop sidebar end */}

      {/*mobile sidebar */}

      <Offcanvas
        isOpen={openSideVendorbar}
        scrollable
        className="mobile-sidebar mobile-font"
      >
        <OffcanvasHeader toggle={() => setOpenVendorSidebar(false)}>
          <div className="offcanvas-header">
            <a className="navbar-brand" href="">
              <img src="../images/logo-white-ktipp.png" alt="icon" />
            </a>
          </div>
        </OffcanvasHeader>

        <OffcanvasBody>
          <div className="offcanvas-body">
            <div className="sidebar-wrap">
              <List className="nav">
                {value?.emd_status == "0" && (
                  <>
                    <li
                      className={
                        location_details?.pathname == "/basic-details-view" ||
                        location_details?.pathname ==
                          "/advanced-registration" ||
                        location_details?.pathname == "/emd-details" ||
                        location_details?.pathname == "/empanelment-details" ||
                        location_details?.pathname == "/document-upload" ||
                        location_details?.pathname ==
                          "/start-up-document-upload"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link
                        to="/basic-details-view"
                        title="Dashboard"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-settings" />
                        {VendorRegLang?.profile_settings[language]}
                      </Link>
                    </li>
                  </>
                )}
                {value?.emd_status !== "0" && (
                  <>
                    <li
                      className={
                        location_details?.pathname == "/overview-vendor"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="curve-before">
                        <span className="curve-inner"></span>
                      </span>
                      <Link
                        to="/overview-vendor"
                        title="Dashboard"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-dashboard" />
                        {VendorRegLang?.dashboard[language]}
                      </Link>
                      <span className="curve-after">
                        <span className="curve-inner"></span>
                      </span>
                    </li>
                    <li
                      className={
                        location_details?.pathname ==
                          "/received-order-vendor" ||
                        location_details?.pathname ==
                          "/cancelled-order-vendor" ||
                        location_details?.pathname ==
                          "/processed-order-vendor" ||
                        location_details?.pathname == "/outofdelivery" ||
                        location_details?.pathname == "/rejected-order-vendor"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="curve-before">
                        <span className="curve-inner"></span>
                      </span>
                      <Link
                        to="/received-order-vendor"
                        title="Purchase Order"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-purchase-order"></i>
                        {VendorRegLang?.purchase_order[language]}
                      </Link>
                      <span className="curve-after">
                        <span className="curve-inner"></span>
                      </span>
                    </li>
                    <li
                      className={
                        location_details?.pathname == "/delivered-vendor"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="curve-before">
                        <span className="curve-inner"></span>
                      </span>
                      <Link
                        to="/delivered-vendor"
                        title="Delivered"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-delivered" />
                        {VendorRegLang?.delivered[language]}
                      </Link>
                      <span className="curve-after">
                        <span className="curve-inner"></span>
                      </span>
                    </li>
                    <li
                      className={
                        location_details?.pathname == "/feedback-summary-vendor"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="curve-before">
                        <span className="curve-inner"></span>
                      </span>
                      <Link
                        to="/feedback-summary-vendor"
                        title="Feedback & Summary"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-feedback-summary"></i>
                        {VendorRegLang?.feedback_and_summary[language]}
                      </Link>
                      <span className="curve-after">
                        <span className="curve-inner"></span>
                      </span>
                    </li>
                    <li
                      className={
                        location_details?.pathname == "/payment-vendor" ||
                        location_details?.pathname == "/payment-received-vendor"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="curve-before">
                        <span className="curve-inner"></span>
                      </span>
                      <Link
                        to="/payment-vendor"
                        title="Payments"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-payments" />
                        {VendorRegLang?.payment_lists[language]}
                      </Link>
                      <span className="curve-after">
                        <span className="curve-inner"></span>
                      </span>
                    </li>

                    {/* <li className="nav-item">
                      <Link
                        to="#"
                        title="Services"
                        color="link"
                        onClick={() => setIsOpen(!isOpen)}
                        className="nav-link text-white text-start dropdown-toggle"
                      >
                        <i className="icons icons-services "></i>
                        {VendorRegLang?.services[language]}
                      </Link>
                    <a className="nav-link text-white" data-bs-toggle="collapse" href="#sidebar-collapse01" role="button" aria-expanded="false" aria-controls="sidebar-collapse01"></a>
                    <Collapse isOpen={isOpen} {...args}>
                        <List id="sidebar-collapse01">
                          <li
                            className={
                              location_details?.pathname ==
                                "/vendor-open-tickets" ||
                              location_details?.pathname ==
                                "/vendor-closed-tickets" ||
                              location_details?.pathname ==
                                "/vendor-pending-tickets"
                                ? "sub-menu active"
                                : "sub-menu"
                            }
                          >
                            <span className="curve-before">
                              <span className="curve-inner"></span>
                            </span>
                            <Link
                              to="/vendor-open-tickets"
                              title="Tickets"
                              className="nav-link"
                              onClick={() => setOpenVendorSidebar(false)}
                            >
                              <i className="icons icons-tickets"></i>
                              {VendorRegLang?.tickets[language]}
                            </Link>
                            <span className="curve-after">
                              <span className="curve-inner"></span>
                            </span>
                          </li>
                          <li
                            className={
                              location_details?.pathname == "/amc-vendor"
                                ? "sub-menu active"
                                : "sub-menu"
                            }
                          >
                            <span className="curve-before">
                              <span className="curve-inner"></span>
                            </span>
                            <Link
                              to="/amc-vendor"
                              title="AMC"
                              className="nav-link"
                              onClick={() => setOpenVendorSidebar(false)}
                            >
                              <i className="icons icons-amc"></i>
                              {VendorRegLang?.amc[language]}
                            </Link>
                            <span className="curve-after">
                              <span className="curve-inner"></span>
                            </span>
                          </li>
                        </List>
                      </Collapse>
                    </li> */}

                    <li
                      className={
                        location_details?.pathname ==
                          "/schedule-maintenance-vendor-completed" ||
                        location_details?.pathname ==
                          "/schedule-maintenance-vendor-pending"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="curve-before">
                        <span className="curve-inner"></span>
                      </span>
                      <Link
                        to="/schedule-maintenance-vendor-completed"
                        title="Schedule Maintenance"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-schedule-maintenance"></i>
                        {VendorRegLang?.schedule_maintenance[language]}
                      </Link>
                      <span className="curve-after">
                        <span className="curve-inner"></span>
                      </span>
                    </li>
                    <li
                      className={
                        location_details?.pathname == "/vendor-documents-view"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="curve-before">
                        <span className="curve-inner"></span>
                      </span>
                      <Link
                        to="/vendor-documents-view"
                        title="Dashboard"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-documents"></i>
                        {VendorRegLang?.documents[language]}
                      </Link>
                      <span className="curve-after">
                        <span className="curve-inner"></span>
                      </span>
                    </li>
                    <li
                      className={
                        location_details?.pathname ==
                          "/vendor-profile-settings" ||
                        location_details?.pathname == "/vendor-documents" ||
                        location_details?.pathname == "/vendor-account-details"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="curve-before">
                        <span className="curve-inner"></span>
                      </span>
                      <Link
                        to="/vendor-profile-settings"
                        title="Settings"
                        className="nav-link"
                        onClick={() => setOpenVendorSidebar(false)}
                      >
                        <i className="icons icons-settings "></i>
                        {VendorRegLang?.settings[language]}
                      </Link>
                      <span className="curve-after">
                        <span className="curve-inner"></span>
                      </span>
                    </li>
                  </>
                )}
              </List>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>

      {/*mobile sidebar */}
    </>
  );
}
export default VendorSidebar;
