import React from "react";
import { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import { Context } from "../../../../Components/Context/Context";
import { Department_Services } from "../../../../Library/LanguageConversion/Department_Services";

function DeptTicketLayout(props: any) {
  const { pathname } = useLocation()
  const { language, setLanguage } = useContext(Context);
  return (
    <React.Fragment>
      <div className="right-content-wrap">
        <div className="breadcrumb-tabs-outer-wrap">
          {/* tabs */}
          <div className="custom-tabs">
            <NavLink to="/department/tickets">
              {Department_Services?.Open_Tickets[language]}
            </NavLink>
            {/* <NavLink to="/department/processing-tickets">
            Processing Tickets
          </NavLink> */}
            <NavLink to="/department/pending-tickets">
              {Department_Services?.Pending_Tickets[language]}
            </NavLink>
            <NavLink to="/department/resolved-tickets">
              {Department_Services?.Resolved_Tickets[language]}
            </NavLink>
          </div>
          {/* breadcrumb */}
          <List className="breadcrumb">
            <li>
              <Link className="active" to="/department/dashboard">
                {Department_Services?.Dashboard[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <Link className="active" to="/department/tickets">
                {Department_Services?.Services[language]}
              </Link>
            </li>
            &nbsp; / &nbsp;
            <li>
              <b>
                {
                  pathname == "/dept-open-tickets" ? "Open Tickets" :
                    pathname == "/dept-processing-tickets" ? "Processing Tickets" :
                      pathname == "/dept-pending-tickets" ? "Pending Tickets" :
                        pathname == "/dept-resolved-tickets" ? "Resolved Tickets" : "Tickets"
                }
              </b>
            </li>
          </List>
        </div>
        <div className="custom-tab-content">
          <div className="table-outer-wrap">
            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default DeptTicketLayout;