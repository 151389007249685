import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../../Components/Context/Context";
import { Department_Orders } from "../../../../Library/LanguageConversion/Department_Orders";
import {
    Table,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    Button,
    UncontrolledAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem
} from "reactstrap";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import { toast } from "react-toastify";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import OrderViewModal from "../OrderViewModal/OrderViewModal";
import { Link } from "react-router-dom";
import { PAYMENT_MODES } from "../../../../Library/Constants/MapConstants";
import { convertISODateToDateTime } from "../../../../Library/Constants/helpers";
import CompletePayment from "../CompletePayment/CompletePayment";

export default function PlacedOrders(args: any) {
    const [loading, setLoading] = useState(false);
    const [pending_order, setPendingOrder] = useState([]);
    const [pageData, setPageData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number | string>(1)
    const [searchVal, setSearchVal] = useState<string>("")
    const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false) // for feedback modal
    const [payIsOpen, setPayIsOpen] = useState<boolean>(false) // for feedback modal
    const [singleOrderData, setSingleOrderData] = useState<any>([])
    const [orderId, setOrderId] = useState<any>()
    const [singlePayData, setSinglePayData] = useState<any>([])
    const [orderData, setOrderData] = useState<any>([])
    const [selectedAddress, setSelectedAddress] = useState<any>([])
    const { language, setLanguage } = useContext(Context);
    useEffect(() => {
        getProcessingOrders();
    }, [currentPage, searchVal]);

    // fetch processing orders 
    const getProcessingOrders = () => {
        setLoading(true);
        api
            .get(`/order/pf-invoice?status=1&sort_value=DESC&page=${currentPage}&search_value=${searchVal}`, true)
            .then(async function ([success, response]) {
                setPendingOrder(response.data || []);
                setPageData(response?.meta)
                setLoading(false);
                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    // cancel order handler function
    const cancelOrderHandler = (id: number, payment_mode: any) => {
        if (id) {
            api
                .patch(
                    `/order/pf-invoice/${id}`,
                    {
                        order_status: 7,
                        payment_mode: payment_mode
                    },
                    true
                )
                .then(async function ([success, response]) {
                    toast.success("Order cancelled");
                    getProcessingOrders()
                    return response;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            console.log('id not found error');
        }
    }

    // handles order view modal 
    const handleOrderView = (obj: any) => {
        setSingleOrderData(obj.order_details)
        setOrderData(obj)
        setSelectedAddress(obj?.shipping_address[0])
        setOrderModalOpen(true)
    }

    return (
        <>
            <div className="search-widget-outer">
                <Form className="d-flex" role="search">
                    <InputGroup className="search-group">
                        <InputGroupText title="Order ID/Product Name" className="search-icon" id="search">
                            <i className="fa-sharp fa-solid fa-magnifying-glass" />
                        </InputGroupText>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder={Department_Orders?.ProductName_orderID[language]}
                            aria-label="Username"
                            aria-describedby="search"
                            onChange={(e: any) => {
                                setCurrentPage(1)
                                setSearchVal(e.target.value)
                            }}
                        />
                    </InputGroup>
                </Form>
            </div>
            <div className="table-wrap responsive-table">
                <Table className="w-100 table-striped">
                    <thead>
                        <tr>
                            <th>{Department_Orders?.SlNo[language]}</th>
                            <th>{Department_Orders?.Order_ID[language]}</th>
                            <th>{Department_Orders?.TenderID[language]}</th>
                            <th>{Department_Orders?.OrderDetails[language]}</th>
                            <th>{Department_Orders?.ShippingAddress[language]}</th>
                            <th>{Department_Orders?.Date[language]}</th>
                            <th>{Department_Orders?.Quantity[language]}</th>
                            <th>{Department_Orders?.Payments[language]}</th>
                            <th>{Department_Orders?.Action[language]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading ?
                            <>
                                {pending_order?.length !== 0 ? (
                                    <>
                                        {pending_order?.map((item: any, i: number) => {
                                            let count = 0

                                            return (
                                                <React.Fragment key={i}>
                                                    <tr className="order-relative">
                                                        <td><span className="mobile-heading">{Department_Orders?.SlNo[language]}:</span><span className="table-data">{pageData?.from + i}</span></td>
                                                        <td title="View Order">
                                                            <span className="mobile-heading">{Department_Orders?.Order_ID[language]}:</span>
                                                            <span className="table-data">
                                                                <Link to="" className="blue-text" onClick={() => handleOrderView(item)}>{item?.order_id}</Link>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-heading">{Department_Orders?.TenderID[language]}:</span>
                                                            <span className="table-data">{item?.order_details[0].tender_id}</span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-heading">{Department_Orders?.Product[language]}:</span>
                                                            <span className="table-data">
                                                                {/* Multiple order list */}
                                                                {item?.order_details?.length > 1 ? (
                                                                    <>
                                                                        <UncontrolledAccordion defaultOpen="0" className="order-list-accordion">
                                                                            <AccordionItem>
                                                                                <AccordionHeader targetId="1" className="">
                                                                                    {Department_Orders?.More_than_one_order?.[language]}. <span>{Department_Orders?.Click_to_see_the_Order_list[language]}.</span>
                                                                                </AccordionHeader>
                                                                                <AccordionBody accordionId="1" className="">
                                                                                    <AccordionHeader targetId="1" className="close-btn">
                                                                                        <span className="close-btn-inner" title="Close">
                                                                                            <i className="bi bi-x-lg"></i>
                                                                                        </span>
                                                                                    </AccordionHeader>
                                                                                    <div className="order-list-outer-wrap">
                                                                                        {
                                                                                            item?.order_details?.map((obj: any, x: number) => {
                                                                                                count = count + obj.quantity
                                                                                                return (
                                                                                                    <React.Fragment key={x}>
                                                                                                        <div className="product-details">
                                                                                                            <p className="product-title"><span>{obj?.name}</span></p>
                                                                                                            <p className="sub-details">{Department_Orders?.Unitprice[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.price * 0.05) + obj?.price} </span></p>
                                                                                                            <p className="sub-details">{Department_Orders?.Quantity[language]}: <span>{obj?.quantity}</span> </p>
                                                                                                            <p className="sub-details">{Department_Orders?.Total[language]}: <span><i className="bi bi-currency-rupee"></i>{(obj?.total_amount - obj?.tds_on_gst).toFixed(2)} ({Department_Orders?.IncludingGST[language]})</span></p>
                                                                                                        </div>
                                                                                                        {/* {item?.order_details?.length !== x + 1 && <hr />} */}
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </AccordionBody>
                                                                            </AccordionItem>
                                                                        </UncontrolledAccordion>
                                                                    </>

                                                                ) : null}

                                                                {/* Single order */}
                                                                {item?.order_details?.length == 1 ? (
                                                                    <>
                                                                        {
                                                                            item?.order_details?.map((obj: any, x: number) => {
                                                                                count = count + obj.quantity
                                                                                return (
                                                                                    <React.Fragment key={x}>
                                                                                        <div className="product-details">
                                                                                            <p className="product-title"><span>{obj?.name}</span></p>
                                                                                            <p className="sub-details">
                                                                                                {Department_Orders?.Unitprice[language]}:
                                                                                                <span><i className="bi bi-currency-rupee">
                                                                                                </i>{(obj?.price * 0.05) + obj?.price}
                                                                                                </span>
                                                                                            </p>
                                                                                            <p className="sub-details">{Department_Orders?.Quantity[language]}: <span>{obj?.quantity}</span> </p>
                                                                                            <p className="sub-details">Total GST:
                                                                                                <span><i className="bi bi-currency-rupee" />{item?.grand_gst}</span> </p>

                                                                                            {/* Deductions  */}
                                                                                            {item?.dep_deduction?.map((it: any, i: number) => {
                                                                                                return (
                                                                                                    <React.Fragment key={it.id}>
                                                                                                        <p className="sub-details">
                                                                                                            {it?.item}:
                                                                                                            <span>
                                                                                                                <i className="bi bi-currency-rupee"></i>
                                                                                                                {it?.amount}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })}
                                                                                            <p className="sub-details">{Department_Orders?.Total[language]}:
                                                                                                <span><i className="bi bi-currency-rupee"></i>
                                                                                                    {(obj?.total_amount - item?.sum_dep_deduction).toFixed(2)}
                                                                                                    ({Department_Orders?.IncludingGST[language]})</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                ) : null}
                                                            </span>
                                                        </td>
                                                        <td><span className="mobile-heading">{Department_Orders?.ShippingAddress[language]}:</span>
                                                            <span className="table-data">
                                                                {Department_Orders?.BuildingNo[language]}:{item?.shipping_address[0]?.building_no} <br />
                                                                {item?.shipping_address[0]?.city} <br />
                                                                {item?.shipping_address[0]?.district} <br />
                                                                {item?.shipping_address[0]?.pincode} <br />
                                                                {item?.shipping_address[0]?.email} <br />
                                                                {item?.shipping_address[0]?.phone} <br />
                                                            </span>
                                                        </td>
                                                        <td><span className="mobile-heading">{Department_Orders?.Date[language]}:</span><span className="table-data">
                                                            {convertISODateToDateTime(item?.created_at)}
                                                        </span></td>
                                                        <td><span className="mobile-heading">{Department_Orders?.Quantity[language]}:</span>
                                                            <span className="table-data">{count}</span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-heading">{Department_Orders?.Payments[language]}:</span>
                                                            <div className="payment-detail-wrap blue-text">
                                                                <span className="status">{(PAYMENT_MODES?.find((obj: any) => {
                                                                    return obj?.value == item?.payment_mode || obj?.value == item?.payment_details?.payment_mode
                                                                }))?.name}</span>
                                                                <div className="tooltip-outer-wrap">
                                                                    <i className="bi bi-info-circle tooltip-eye"></i>
                                                                    <span className="tooltip-text">
                                                                        {(item?.last_paid_amount || item?.last_paid_amount == 0) ? <div>
                                                                            <small>{Department_Orders?.Paid[language]}: <span className="green-text">₹{item?.last_paid_amount}</span></small>
                                                                        </div> : null}
                                                                        {item?.payment_details?.balance_amt !== null ? <div>
                                                                            <small>{Department_Orders?.Balance[language]}: <span className="green-text">₹{item?.payment_details?.balance_amt}</span></small>
                                                                        </div> : null}
                                                                        {item?.grand_amount && <div>
                                                                            <small>{Department_Orders?.Total[language]}: <span className="green-text">₹{item?.grand_amount}</span></small>
                                                                        </div>}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-heading">{Department_Orders?.Action[language]}:</span>
                                                            <div className="table-data">
                                                                <div className="action-flex-btn mobile-flex">
                                                                    {item?.order_status == '1' &&
                                                                        <>
                                                                            <Button
                                                                                color="warning"
                                                                                className=" px-3"
                                                                                onClick={() => cancelOrderHandler(item?.id, item?.payment_mode ||
                                                                                    item?.payment_details?.payment_mode)}
                                                                                >
                                                                                {Department_Orders?.Cancel[language]}
                                                                            </Button>
                                                                        </>}
                                                                    {item?.order_status == '4' && <span className="text-success">{Department_Orders?.Order_Accepted[language]}</span>}
                                                                    {item?.payment_details?.is_pending == '1' &&
                                                                        <Button
                                                                            color="success"
                                                                            onClick={() => {
                                                                                setOrderId(item?.draft?.id)
                                                                                setSingleOrderData(item?.order_details)
                                                                                setSinglePayData(item?.payment_details)
                                                                                setPayIsOpen(true)
                                                                            }}
                                                                            className=" px-3 my-3"
                                                                             >
                                                                            {Department_Orders?.Pay_Now[language]}
                                                                        </Button>}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                )
                                    : (
                                        <tr>
                                            <td colSpan={9} className="text-center p-5 empty-text">
                                                {Department_Orders?.NoOrdersFound[language]}
                                            </td>
                                        </tr>
                                    )}
                            </>
                            :
                            <tr>
                                <td colSpan={9} className="text-center p-5 empty-text">
                                    <Loader />
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
                {pageData && pending_order.length > 0 && <div className="table-footer-wrap">
                    <small>{Department_Orders?.Showing[language]} {pageData.from} to {pageData.to} of {pageData?.total} {Department_Orders?.entries[language]}</small>
                    <div className="pagination-outer">
                        <CustomPagination
                            totalItems={pageData?.total}
                            itemsPerPage={pageData?.per_page}
                            activePage={pageData?.current_page}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>}
            </div>

            {/* order view modal  */}
            <div>
                <ModalContainer
                    open={orderModalOpen}
                    setOpen={setOrderModalOpen}
                    header='Order Details'
                >
                    <OrderViewModal
                        singleOrderData={singleOrderData}
                        orderData={orderData}
                        orderViewLoading={false}
                        address={selectedAddress}
                    />
                </ModalContainer>
            </div>

            {/* payment modal  */}
            <div>
                <ModalContainer
                    open={payIsOpen}
                    setOpen={setPayIsOpen}
                    header='Pay Now'
                >
                    <CompletePayment
                        singleOrderData={singleOrderData || []}
                        singlePayData={singlePayData}
                        orderId={orderId}
                    />
                </ModalContainer>
            </div>
        </>
    );
}