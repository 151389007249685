import React from 'react'
import { Link } from 'react-router-dom'


export default function FooterEnglish() {
    return (

        <>
            <div>
                <footer>
                    <div className="container foot-section">
                        <div className=" footer-logo d-flex justify-content-between">
                            <div className="logo">
                                <img src={require("../../images/Group 1000002263.png")} alt="logo" />
                            </div>
                            <div className="powered-by">
                                <p>An initiative by</p>
                                <img src={require("../../images/keonics-logo-white2.png")} alt="logo" />
                            </div>
                            <div className="links">
                                {/* <a href="privacy-policy.html" target="_blank">Privacy policy</a> */}
                                <Link to={"/privacy-policy"}>Privacy policy</Link>
                                <div className="vr white" />
                                {/* <a href="terms-and-conditions.html" target="_blank">Terms and Conditions</a> */}
                                <Link to={"/terms-condition"}>Terms and Conditions</Link>

                            </div>

                        </div>
                        <hr />
                        <div className="copyright">
                            <div className="links">
                                <span><a href="https://www.karnataka.gov.in/english" target="_blank">Government of Karnataka</a></span>
                                <div className="vr white" />
                                <span><a href="http://www.keonics.in/english/" target="_blank">Keonics</a></span>
                            </div>
                            <div className="copy">
                                <span>Copyright © 2023. All rights reserved.</span>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}
