import React, { useContext } from 'react';
import { Context } from '../../../Components/Context/Context';
import { DepartmentProducts } from '../../../Library/LanguageConversion/Department_Products';

const ViewOrderCard = ({ proformaData }: any) => {
    const { language,setLanguage } = useContext(Context);

    return (
        <>
            <table className="table table-hover table-bordered ">

                <thead>
                    <tr className='sticky'>
                        <th className='col-lg-4'>{DepartmentProducts?.OrderID[language]}:</th>
                        <th className='col-lg-8'>
                            {proformaData?.order_id}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{DepartmentProducts?.Total_Unit_Price[language]}:</td>
                        <td>
                            <i className="bi bi-currency-rupee"></i>
                            {proformaData?.grand_amount - proformaData?.grand_gst}
                        </td>
                    </tr>
                    <tr>
                        <td>{DepartmentProducts?.Total_GST[language]}:</td>
                        <td>
                            <i className="bi bi-currency-rupee"></i>
                            {proformaData?.grand_gst}
                        </td>
                    </tr>
                    <tr>
                        <td>{DepartmentProducts?.TotalAmount[language]}:</td>
                        <td>
                            <i className="bi bi-currency-rupee"></i>
                            {proformaData?.grand_amount}
                        </td>
                    </tr>
                    {/* TDS Deductions  */}
                    {proformaData?.dep_deduction?.map((it: any, i: number) => {
                        return (
                            <tr >
                                <td>{it?.item}:</td>
                                <td>
                                    <i className="bi bi-currency-rupee"></i>
                                    ₹{it?.amount}
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td>{DepartmentProducts?.Total_Payable_Amount[language]}:</td>
                        <td>
                            <i className="bi bi-currency-rupee"></i>
                            {(proformaData?.grand_amount - proformaData?.sum_dep_deduction)?.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default ViewOrderCard
