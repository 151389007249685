import * as Yup from "yup";

const orderValidation = Yup.object().shape({
  order_status: Yup.string().required("Field is required").nullable(),
  // challan: Yup.string().required("Field is required").nullable(),
  location: Yup.mixed().required("Field is required").nullable(),
  wou: Yup.string().required("Field is required").nullable(),
  //invoice:Yup.string().required("Field is required").nullable()
});

const orderValidations = Yup.object().shape({
  order_status: Yup.string().required("Field is required").nullable(),
  // challan: Yup.string().required("Field is required").nullable(),
  // images : Yup.string().required("Field is required").nullable(),
  // wou : Yup.string().required("Field is required").nullable(),
});

const orderWithReasonsValidations = Yup.object().shape({
  order_status: Yup.string().required("Field is required").nullable(),
  rejection_reasons: Yup.string().required("Field is required").nullable(),
  // challan: Yup.string().required("Field is required").nullable(),
  // images : Yup.string().required("Field is required").nullable(),
  // wou : Yup.string().required("Field is required").nullable(),
});

const challanValidation = Yup.object().shape({
  challan: Yup.string().required("Field is required").nullable(),
});

const deliveryDocReuploadValidation = Yup.object().shape({
  doc: Yup.string().required("Field is required").nullable(),
});

export {
  orderValidation,
  challanValidation,
  orderValidations,
  deliveryDocReuploadValidation,
  orderWithReasonsValidations,
};
