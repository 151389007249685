import React, { useState,useContext } from 'react'
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { API_URL } from '../../../../Library/ApiSettings';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Context } from '../../../../Components/Context/Context';
import { Department_TDS } from '../../../../Library/LanguageConversion/Department_TDS';
const TDSPaymentModal = ({ TDSDataIndex, TDSData, setPayIsOpen, getTDSList }: any) => {

    const [files, setFile] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleFileChange = (event: any) => {
        setFile([...files, ...event?.target?.files]);
    };

    // removes a file 
    const removeFileHandler = (index: number) => {
        setFile(files?.filter((img: any) => {
            return img !== files[index]
        }));

    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        if (files?.length == 0) return;

        const formData = new FormData();

        if (TDSData?.[TDSDataIndex]?.id) {

            files?.forEach((file: any) => formData.append("file[]", file));
            formData.append("pfInvoiceNo", TDSData?.[TDSDataIndex]?.id);
            setIsLoading(true)

            axios({
                method: "post",
                url: `${API_URL}/order/tdsUpload`,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                },
            })
                .then(function (response) {
                    if (response?.status == 200) {
                        toast.success("TDS Details uploaded");
                        getTDSList()
                        setIsLoading(false)
                        setPayIsOpen(false)
                    }
                })
                .catch(function (response) {
                    if (response.response.status == 422) {
                        toast.error(response.response.data.message);
                    }

                    setIsLoading(false)
                    console.log(response);
                });
        }
        else {
            toast.error('Unexpected error occured')
        }

    }
    const { language, setLanguage } = useContext(Context);


    return (
        <>
            <div>
                <FormGroup>
                    <Label className="form-Label">{Department_TDS?.Upload_TDS_Details[language]}<i
                        className="fa fa-asterisk required-field"
                        color="text-danger"
                        aria-hidden="true"
                    ></i></Label>
                    <div className="image-input">
                        <Input
                            type="file"
                            multiple
                            name="file"
                            id="file"
                            accept="image/jpeg,image/gif,application/pdf,image/x-eps"
                            className="form-control"
                            onChange={handleFileChange}
                            title='Upload TDS Details'
                        ></Input>
                        <Label className="image-button" for="file" id="file">{Department_TDS?.Choose_Documents[language]}</Label>
                    </div>

                    {/* to display selected file  */}
                    {files?.length !== 0 && (
                        <Row className='gy-4 mt-2 image-input-show-wrap'>
                            {files?.map((it: any, i: number) => {
                                return (
                                    <Col md={3} sm={6} xs={6} key={i}>
                                        <div >
                                            {it?.type.startsWith('image/') ? (
                                                <div className="img-wrap">
                                                    <img src={URL.createObjectURL(it)} alt="Preview" className="img-fluid" />
                                                </div>
                                            ) : (
                                                <div className="doc-wrap">
                                                    <embed src={URL.createObjectURL(it)} type={it.type} />
                                                    <Button onClick={() => removeFileHandler(i)} className="bi bi-x-circle close-btn"></Button>
                                                </div>
                                            )
                                            }
                                            <span>{it?.name}</span>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    )}
                </FormGroup >
            </div >
            <div className="text-end pt-4">
                <FormGroup>
                    <Button
                        type="submit"
                        color="primary"
                        title={!files ? 'Select file to enable button' : 'Upload work order'}
                        className={"px-5"}
                        disabled={files?.length == 0}
                        onClick={submitHandler}
                        value={undefined}
                    >
                        {isLoading ?
                            <Spinner
                                as="span"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                            /> : 'Upload TDS Details'
                        }
                    </Button>
                </FormGroup>
            </div>
        </>
    )
}

export default TDSPaymentModal