import * as Yup from "yup";


const ProfileValidationSchema = Yup.object().shape({
    fname: Yup.string().required("Field is required"),
    email: Yup.string().email().required("Field is required"),
    mobile_number: Yup.string().length(10).matches(/^[0-9]{10}/).required("Field is required"),
    ids: Yup.number().required("Field is required"),
    company_name: Yup.string().required("Field is required"),
    company_email: Yup.string().email().required("Field is required"),
    company_mobile_number: Yup.string().length(10).matches(/^[0-9]{10}/).required("Field is required"),
    company_id: Yup.number().required("Field is required"),

});
export {
    ProfileValidationSchema,
  
};