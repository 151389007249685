import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Context } from "../../../../Components/Context/Context";
import { Admin_Verifications } from "../../../../Library/LanguageConversion/Admin_Verifications";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  ButtonGroup,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import { toast } from "react-toastify";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import constants from "./../../../../Library/Constants/Constants";

export default function AddrVerification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState([]);
  const [dept, setDept] = useState([]);
  const [dept_value, setDeptValue] = useState("");
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState<any>([]);
  const { language, setLanguage } = useContext(Context);

  const handledept = (e: any) => {
    setCurrentPage(1);
    setDeptValue(e.target.value);
  };
  //=========================================================== Search =================================================
  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getAddress();
        getDepartment();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [dept_value, currentPage, search]);

  //=========================================================== Get Address ==============================================

  const getAddress = () => {
    setLoading(true);
    api
      .get(
        `/address?page=${currentPage}&department=${dept_value}&status=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setAddress(response?.data || []);
        setPageData(response?.meta || []);
        setFrom(response?.meta?.from || []);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //============================================================ Get Dept ==================================================

  const getDepartment = () => {
    api
      .get(`/departments`, true)
      .then(async function ([success, response]) {
        setDept(response?.data || []);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //============================================================ Addr Approve ================================================

  const ApproveAddress = (id: number, status: number) => {
    api
      .patch(
        `/addressVerify/${id}`,
        {
          status: status,
        },
        true
      )
      .then(async function ([success, response]) {
        toast.success("Address Verified Successfully");
        getAddress();
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //============================================================= View =========================================================

  return (
    <React.Fragment>
      <div className="search-widget-outer">
        <Form role="search">
          <ButtonGroup>
            <Button
              className={`${search === "" ? "status-tabs active" : "status-tabs"
                }`}
              onClick={() => {
                setCurrentPage(1);
                setSearch("");
              }}
            >
              {Admin_Verifications?.All[language]}
            </Button>
            <Button
              className={`${search === "1" ? "status-tabs active" : "status-tabs"
                }`}
              onClick={() => {
                setCurrentPage(1);
                setSearch("1");
              }}
            >
              {Admin_Verifications?.Pending[language]}
            </Button>
            <Button
              className={`${search === "2" ? "status-tabs active" : "status-tabs"
                }`}
              onClick={() => {
                setCurrentPage(1);
                setSearch("2");
              }}
            >
              {Admin_Verifications?.Approved[language]}
            </Button>
          </ButtonGroup>
          <InputGroup className="search-group"></InputGroup>
        </Form>
        <div className="select-filter" title="Select department">
          <Input
            type="select"
            name="department"
            value={dept_value}
            data={dept}
            onChange={handledept}
            ismulti="false"
          >
            <option value="">{Admin_Verifications?.Selectdepartment[language]}</option>;
            {dept?.map((item: any, i: number) => {
              return (
                <React.Fragment key={item?.id}>
                  <option key={i} value={item?.id}>
                    {" "}
                    {item?.name}
                  </option>
                </React.Fragment>
              );
            })}
          </Input>
        </div>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_Verifications?.SlNo[language]}</th>
              <th>{Admin_Verifications?.Name[language]}</th>
              <th>{Admin_Verifications?.Email[language]}</th>
              <th>{Admin_Verifications?.Address[language]}</th>
              <th>{Admin_Verifications?.Status[language]}</th>
              <th>{Admin_Verifications?.Action[language]}</th>
            </tr>
          </thead>
          <tbody>
            {address?.length !== 0 ? (
              <>
                {address.map((item: any, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.SlNo[language]}:</span>
                          <span className="table-data">
                            {pageData.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Name[language]}:</span>
                          <span className="table-data">
                            {item?.department ? item?.department.name : null}
                          </span>
                        </td>
                        <td><span className="mobile-heading">{Admin_Verifications?.Email[language]}:</span>
                          <span className="table-data">{item?.user?.email}</span></td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Address[language]}:</span>
                          <div className="action-flex-btn reversible">
                            <div className="icon">
                              <i className="icons icons-address-rounded xxl"></i>
                              {/* <img src="images/addressicon.svg" alt="icon" /> */}
                            </div>
                            <div className="address-wrap">
                              <h6>
                                <br />
                                <small>
                                  {item?.building_no},{item?.district},
                                  {item?.state} <br />
                                  {item?.pincode},{item?.country},
                                  <br></br>
                                  {Admin_Verifications?.Phone[language]}:{item?.phone}
                                </small>
                              </h6>
                            </div>
                          </div>
                        </td>
                        {item?.status == constants.ADDRESS.APPROVE ? (
                          <td>
                            <span className="mobile-heading">{Admin_Verifications?.Status[language]}:</span>
                            <span className="pending-status">{Admin_Verifications?.Pending[language]}</span>
                          </td>
                        ) : (
                          <td>
                            <span className="mobile-heading">{Admin_Verifications?.Action[language]}:</span>
                            <span className="approved-status">{Admin_Verifications?.Approved[language]}</span>
                          </td>
                        )}
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Status[language]}:</span>
                          <div className="action-flex-btn">
                            {item?.status == constants.ADDRESS.APPROVE ? (
                              <Input
                                className="pointer"
                                title="Approve"
                                type="checkbox"
                                checked={false}
                                onClick={() => {
                                  ApproveAddress(item?.id, 1);
                                }}
                              ></Input>
                            ) : (
                              <Input
                                type="checkbox"
                                disabled={true}
                                checked={true}
                              ></Input>
                            )}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={6}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    " No Address Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {address?.length !== 0 && pageData && (
        <div className="table-footer-wrap">
          <small>
          {Admin_Verifications?.Showing[language]} {pageData.from} {Admin_Verifications?.to[language]} {pageData.to} {Admin_Verifications?.of[language]} {pageData.total} {Admin_Verifications?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
