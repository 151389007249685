import React from 'react'
import ModalContainer from '../../../Components/Modal/ModalContainer'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { clearData } from '../../../Library/KeyStorage'
import { useNavigate } from 'react-router-dom'
import { DepartmentNonVUser } from '../../../Library/LanguageConversion/Department_nonverified'
import { Context } from '../../../Components/Context/Context'
import { useContext } from "react";
import { DepartDash } from '../../../Library/LanguageConversion/Department_Dash'

const NonVerifiedUser = () => {
    const navigate = useNavigate()
    const logout = async () => {
        await clearData();
        navigate("/login");
    };
    const { language,setLanguage } = useContext(Context);

    return (
        <>
            <ModalContainer
                open={true}
                setOpen={() => {
                    logout()
                }}
                header={DepartmentNonVUser?.welcome[language]}
            >
                <div className="empty-dashboard py-3">
                    <p className="empty-dash-text">
                        {DepartmentNonVUser?.AccountActivated[language]}
                    </p>
                </div>
            </ModalContainer>
        </>
    )
}

export default NonVerifiedUser