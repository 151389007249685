export const Admin_Payment: any = {

    //Admin---Payment---PaymentLayout
    SlNo: {
        en: 'Sl No',
        ka: 'ಕ್ರ.ಸಂ'
    },

   

    PaymentReceived: {
        en: 'Payment Received',
        ka: 'ಪಾವತಿಯನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ'
    },
    PaymenttoVendors: {
        en: 'Payment to Vendors',
        ka: 'ಮಾರಾಟಗಾರರಿಗೆ ಪಾವತಿ'
    },
    CancelledPayments: {
        en: 'Cancelled Payments',
        ka: 'ರದ್ದಾದ ಪಾವತಿಗಳು'
    },
    Dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },

    //Admin---Payment---Tabs---CancelledPayments

    Date: {
        en: 'Date',
        ka: 'ದಿನಾಂಕ'
    },
    TransactionID: {
        en: 'Transaction ID',
        ka: 'ವ್ಯಾಪಾರ ಐಡಿ'
    },
    OrderDetails: {
        en: 'Order Details',
        ka: 'ಆರ್ಡರ್ ವಿವರಗಳು'
    },
   
    Quantity: {
        en: 'Quantity',
        ka: 'ಪರಿಮಾಣ'
    },
    Price: {
        en: 'Price',
        ka: 'ಬೆಲೆ'
    },

    //Admin---Payment---PaymentReceived
    TenderID: {
        en: 'Tender ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    OrderedDate: {
        en: 'Ordered Date',
        ka: 'ಟೆಂಡರ್ ಐಡಿ'
    },
    More_than_one_order: {
        en: 'More than one order',
        ka: 'ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ಆದೇಶ'
    },
    Click_to_see_the_Orderlist: {
        en: 'Click to see the Order list',
        ka: 'ಕ್ಲಿಕ್ ಮಾಡಿ ಆದೇಶ ಪಟ್ಟಿಯನ್ನು ನೋಡಲು'
    },
    Dell: {
        en: 'Dell',
        ka: 'ಡೆಲ್'
    },
    Unit_Price: {
        en: 'Unit Price',
        ka: 'ಘಟಕ ಬೆಲೆ'
    },
    Total_Price: {
        en: 'Total Price',
        ka: 'ಒಟ್ಟು ಬೆಲೆ'
    },
   
    //Admin---Payment---Tabs---PaymentToVendors

    ValidFrom: {
        en: 'Valid From',
        ka: 'ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ'
    },
    ValidTo: {
        en: 'Valid To',
        ka: 'ಗೆ ಮಾನ್ಯವಾಗಿದೆ'
    },
    ThisWeek: {
        en: 'This Week',
        ka: 'ಈ ವಾರ'
    },
    ThisMonth: {
        en: 'This Month',
        ka: 'ಈ ತಿಂಗಳು'
    },
    ThisYear: {
        en: 'This Year',
        ka: 'ಈ ವರ್ಷ'
    },
    Reset: {
        en: 'Reset',
        ka: 'ಮರುಹೊಂದಿಸಿ'
    },
    All: {
        en: 'All',
        ka: 'ಎಲ್ಲಾ'
    },

    Pending: {
        en: 'Pending',
        ka: 'ಬಾಕಿಯಿದೆ'
    },
    Completed: {
        en: 'Completed',
        ka: 'ಪೂರ್ಣಗೊಂಡಿದೆ'
    },
    VendorName: {
        en: 'Vendor Name',
        ka: 'ಮಾರಾಟಗಾರರ ಹೆಸರು'
    },
    OrderId: {
        en: 'Order ID',
        ka: 'ಆರ್ಡರ್ ಐಡಿ'
    },
    Action: {
        en: 'Action',
        ka: 'ಕ್ರಿಯೆ'
    },
    PaymentCompleted: {
        en: 'Payment Completed',
        ka: 'ಪಾವತಿ ಪೂರ್ಣಗೊಂಡಿದೆ'
    },
    Payment_to_Vendors: {
        en: 'Payment to Vendors',
        ka: 'ಕಮಾರಾಟಗಾರರಿಗೆ ಪಾವತಿ'
    },
    Showing: {
        en: 'Showing',
        ka: 'ತೋರಿಸುವ'
    },
    to: {
        en: 'to',
        ka: 'ಗೆ'
    },
    of: {
        en: 'of',
        ka: 'ನ'
    },
    entries: {
        en: 'entries',
        ka: 'ಪ್ರವೇಶಗಳು'
    },
    //Admin---Payment---PaymentCheckModal

    Payment_Mode: {
        en: 'Payment Mode',
        ka: 'ಪಾವತಿ ಮಾದರಿ'
    },
    Department: {
        en: 'Department',
        ka: 'ವಿಭಾಗ'
    },
    TotalAmount: {
        en: 'Total Amount',
        ka: 'ಒಟ್ಟು ಮೊತ್ತ'
    },
    ChequeDBTNBChellan: {
        en: 'Cheque/DBT/NB/Chellan',
        ka: 'ಚೆಕ್/ಡಿಬಿಟಿ/ಎನ್‌ಬಿ/ಚೆಲ್ಲಾನ್'
    },
    TotalPaidAmount: {
        en: 'Total Paid Amount',
        ka: 'ಒಟ್ಟು ಪಾವತಿ ಮೊತ್ತ'
    },
    RemainingAmountToPay: {
        en: 'Remaining Amount To Pay',
        ka: 'ಪಾವತಿಸಬೇಕಾದ ಉಳಿದ ಮೊತ್ತ'
    },
    CheckAmountisCorrect: {
        en: 'Check Amount is Correct',
        ka: 'ಚೆಕ್ ಮೊತ್ತ ಸರಿಯಾಗಿದೆ'
    },
    Comments: {
        en: 'Comments',
        ka: 'ಕಾಮೆಂಟ್ಗಳು'
    },
    RemainingAmount: {
        en: 'Remaining Amount',
        ka: 'ಉಳಿದ ಮೊತ್ತ'
    },
    RequesttoRemainingpayment: {
        en: 'Request to Remaining payment',
        ka: 'ಉಳಿದ ಪಾವತಿಗೆ ವಿನಂತಿ'
    },

    View: {
        en: 'View',
        ka: 'ನೋಟ'
    },
    ProductName_order: {
        en: 'Product Name/Order ID',
        ka: 'ಟೆಂಡರ್ ಐಡಿ/ಆದೇಶ ಐಡಿ'
    },
    Search_here:{
        en:'Search Here',
        ka:'ಇಲ್ಲಿ ಹುಡುಕಿ'
    },


}