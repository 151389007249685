import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Context } from "../../../../Components/Context/Context";
import { Admin_Verifications } from "../../../../Library/LanguageConversion/Admin_Verifications";
import {
  Button,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  ButtonGroup,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import constants from "../../../../Library/Constants/Constants";
import { toast } from "react-toastify";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import DraftDetailsModal from "./Modals/DraftDetailsModal";
import { convertISODateToDateTime } from "../../../../Library/Constants/helpers";

export default function DraftVerification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dept, setDept] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const { language, setLanguage } = useContext(Context);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null || search === "") {
        getDraft();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, currentPage, filter]);

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearch(event.target.value);
  };
  //===================================================== get Department ==================================================

  const getDraft = () => {
    setLoading(true);
    api
      .get(
        `/drafts?page=${currentPage}&status=${filter}&search_value=${search}`,
        true
      )
      .then(async function ([success, response]) {
        setDept(response?.data || []);
        setPageData({
          ...pageData,
          total: response?.total,
          per_page: response?.per_page,
          current_page: response?.current_page,
          from: response?.from,
          to: response?.to,
        });
        setLoading(false);

        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //====================================================== update status =================================================

  const VerifyDept = (id: number) => {
    setLoading(true);
    api
      .post(
        `/verifyDraft`,
        {
          order_id: id,
        },
        true
      )
      .then(async function ([success, response, status]) {
        if (status == 200) {
          setShowDetails(false)
          toast.success("Draft Verified Successfully");
        }
        setLoading(false);
        getDraft();
        return response;
      })
      .catch((err) => {
        toast.error('Error')
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="search-widget-outer verif-outer">
        <Form role="search">
          <ButtonGroup>
            <Button
              className={`${filter === "" ? "status-tabs active" : "status-tabs"
                }`}
              onClick={() => {
                setCurrentPage(1);
                setFilter("");
              }}
            >
              {Admin_Verifications?.All[language]}
            </Button>
            <Button
              className={`${filter === "1" ? "status-tabs active" : "status-tabs"
                }`}
              onClick={() => {
                setCurrentPage(1);
                setFilter("1");
              }}
            >
              {Admin_Verifications?.Pending[language]}
            </Button>
            <Button
              className={`${filter === "2" ? "status-tabs active" : "status-tabs"
                }`}
              onClick={() => {
                setCurrentPage(1);
                setFilter("2");
              }}
            >
              {Admin_Verifications?.Approved[language]}
            </Button>
          </ButtonGroup>
          <InputGroup className="search-group">
            <InputGroupText title="Department Name" className="search-icon" id="search">
              <i className="fa-sharp fa-solid fa-magnifying-glass" />
            </InputGroupText>
            <Input
              type="text"
              className="form-control border-start-0"
              placeholder={Admin_Verifications?.DepartmentName[language]}
              aria-label="Username"
              aria-describedby="search"
              value={search}
              onChange={handleSearch}
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        </Form>
      </div>
      <div className="table-wrap responsive-table">
        <Table className="w-100 table-striped">
          <thead>
            <tr>
              <th>{Admin_Verifications?.SlNo[language]}</th>
              <th>{Admin_Verifications?.DepartmentName[language]}</th>
              <th>{Admin_Verifications?.OrderID[language]}</th>
              <th>{Admin_Verifications?.Date[language]}</th>
              <th>{Admin_Verifications?.Status[language]}</th>
              <th>{Admin_Verifications?.Action[language]}</th>
            </tr>
          </thead>
          <tbody>
            {dept?.length != 0 ? (
              <>
                {dept?.map((item: any, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.SlNo[language]}:</span>
                          <span className="table-data">
                            {pageData.from + i}
                          </span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {Admin_Verifications?.DepartmentName[language]}
                          </span>
                          <span className="table-data">{item?.get_user?.[0]?.department?.[0]?.name}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            {Admin_Verifications?.OrderID[language]}:
                          </span>
                          <span className="table-data">{item?.pf_invoice?.[0]?.pro_forma_no}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">
                            Date:
                          </span>
                          <span className="table-data">{convertISODateToDateTime(item?.created_at)}</span>
                        </td>
                        <td>
                          <span className="mobile-heading">Status:</span>
                          {item?.status ==
                            constants?.DRAFTVERIFICATION?.PENDING ? (
                            <span className="text-danger">
                              {Admin_Verifications?.Pending[language]}
                            </span>
                          ) : (
                            <span className="text-success">
                              {Admin_Verifications?.Verified[language]}
                              <i className="fa fa-check-circle text-success ms-1"></i>
                            </span>
                          )}
                        </td>
                        <td>
                          <span className="mobile-heading">{Admin_Verifications?.Action[language]}:</span>
                          <div className="action-flex-btn">
                            <Link to='' title="View Details" onClick={() => {
                              setViewData(item)
                              setShowDetails(true)
                            }}>
                              {
                                item?.status ==
                                  constants.DRAFTVERIFICATION.PENDING ?
                                  'View & Verify' : 'View'
                              }
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td className="text-center px-2 py-5 empty-text" colSpan={5}>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    " No Draft Found"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {dept?.length !== 0 && pageData && (
        <div className="table-footer-wrap">
          <small>
          {Admin_Verifications?.Showing[language]} {pageData.from} {Admin_Verifications?.to[language]} {pageData.to} {Admin_Verifications?.of[language]} {pageData.total} {Admin_Verifications?.entries[language]}
          </small>
          <div className="pagination">
            <CustomPagination
              totalItems={pageData?.total}
              itemsPerPage={pageData?.per_page}
              activePage={pageData.current_page}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      )}

      <ModalContainer
        open={showDetails}
        setOpen={() => {
          setViewData([])
          setShowDetails(false)
        }}
        header="View Details"
      >
        <DraftDetailsModal
          viewData={viewData}
          VerifyDept={VerifyDept}
        />
      </ModalContainer>
    </React.Fragment>
  );
}
