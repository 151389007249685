import React, { useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'

interface propType {
    orderStatusHandler: Function,
    id: string
}

const ApproveOrderModal = ({ orderStatusHandler, id }: propType) => {

    const [files, setFiles] = useState<any>([])

    const handleImagechange = (e: any, values: any) => {
        setFiles([...files, ...e?.target?.files])
    };

    // removes a file 
    const removeFileHandler = (index: number) => {
        setFiles(files?.filter((img: any) => img !== files[index]));
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        orderStatusHandler(1, id, files)
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>
                                Choose Documents
                                <i
                                    className="fa fa-asterisk required-field"
                                    color="text-danger"
                                    aria-hidden="true"
                                ></i>
                            </Label>
                            <div className="image-input">
                                <Input
                                    type="file"
                                    name="file"
                                    multiple
                                    id="upload-cheque"
                                    onChange={(e: any) =>
                                        handleImagechange(e, e.target.files[0])
                                    }

                                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                />
                                <Label className="image-button" for="upload-cheque" id="upload-cheque">Choose Documents</Label>
                            </div>
                        </FormGroup>
                    </Col>
                    {/* to display selected file  */}
                    {files?.length !== 0 && (
                        <Row className='gy-4 mt-2 image-input-show-wrap'>
                            {files?.map((it: any, i: number) => {
                                return (
                                    <Col md={3} sm={6} xs={6} key={i}>
                                        <React.Fragment key={i}>
                                            {it?.type.startsWith('image/') ? (
                                                <div className="img-wrap">
                                                    <img src={URL.createObjectURL(it)} alt="Preview" className="img-fluid" />
                                                    <Button onClick={() => removeFileHandler(i)} className="bi bi-x-circle close-btn"></Button>
                                                </div>
                                            ) : (
                                                <div className="doc-wrap">
                                                    <embed src={URL.createObjectURL(it)} type={it.type} />
                                                    <Button onClick={() => removeFileHandler(i)} className="bi bi-x-circle close-btn"></Button>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    </Col>
                                )
                            })}
                        </Row>
                    )}
                    <Col md={6}>
                        <Button
                            disabled={files?.length == 0}
                            type='submit'
                        >Approve Order</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ApproveOrderModal