export const DeptRegLang:any = {
    create_account: {
        en: 'Create Account',
        ka: 'ಖಾತೆ ತೆರೆ'
    },
    full_name:{ 
        en:"Full Name", 
        ka:"ಪೂರ್ಣ ಹೆಸರು"
    },
    PhoneNumber:{
        en:'Phone Number',
        ka:'ಫೋನ್ ಸಂಖ್ಯೆ'
    },
    Keonics : {
        en: 'KEONICS focuses on setting up world-class infrastructure for the electronics and IT related industries in the state',
        ka: 'ಕಿಯೋನಿಕ್ಸ್ ರಾಜ್ಯದಲ್ಲಿ ಇಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ಮತ್ತು ಗಣಕಯಂತ್ರ ಸಂಬಂಧಿತ ಕ್ಷೇತ್ರಗಳಿಗೆ ವಿಶ್ವಕ್ಕೆ ಸಮಾನವಾದ ಅಂತರಾಷ್ಟ್ರೀಯ ಅವಕಾಶಗಳನ್ನು ಹೊಂದುವುದಕ್ಕೆ ಕೇಂದ್ರಿಸಿದೆ'
    },

    SelectDepartment: {
        en: 'Select Department',
        ka: 'ಇಲಾಖೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ'
    },
    Email: {
        en: 'Email',
        ka: 'ಇಮೇಲ್'
    },
    OTP: {
        en: 'Enter OTP',
        ka: 'ಒ ಟಿ ಪಿ ಅನ್ನು ನಮೂದಿಸಿ'
    },
    SendOTP: {
        en: 'Send OTP',
        ka: 'ಒ ಟಿ ಪಿ ಕಳುಹಿಸಿ'
    },
    
    account:{
        en: 'Already have an account?',
        ka: 'ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ'
    },
    Login:{
        en: 'Login',
        ka: 'ಲಾಗಿನ್ ಮಾಡಿ'
    },

    Password: {
        en: 'Password',
        ka: 'ಗುಪ್ತಪದ'
    },

    ConfirmPassword: {
        en: 'Confirm Password',
        ka: 'ಪಾಸ್‌ವರ್ಡ್ ದೃಢೀಕರಿಸಿ'
    },
    Please_confirm:{
        en: 'Please confirm that the information provided is accurate before submitting the details.',
        ka: 'ವಿವರಗಳನ್ನು ಸಲ್ಲಿಸುವ ಮೊದಲು ಒದಗಿಸಿದ ಮಾಹಿತಿಯು ನಿಖರವಾಗಿದೆ ಎಂದು ದಯವಿಟ್ಟು ಖಚಿತಪಡಿಸಿ.'
            // need to translate 
    },
    Alreadyhaveanaccount: {
        en: 'Already have an account?',
        ka: 'ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರಾ '
    },
    Register: {
        en: 'Register',
        ka: 'ನೋಂದಣಿ'
    },
    Kannada: {
        en: 'Kannada',
        ka: 'ಕನ್ನಡ'
    },

    // Sidebar

    dashboard: {
        en: 'Dashboard',
        ka: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್'
    },
    products:{
        en:"Products",
        ka:"ಉತ್ಪನ್ನಗಳು"
    },
    orders:{
        en:"Orders",
        ka:"ಆರ್ಡರ್ಗಳು"
    },
    regular_orders:{
        en:"Regular Orders",
        ka:"ನಿಯಮಿತ ಆರ್ಡರ್ಗಳು"
    },
    custom_orders:{
        en:"Custom Orders",
        ka:"ಅನುಕೂಲಿತ ಆದೇಶಗಳು"
    },
    tds:{
        en:"TDS",
        ka:"ಟಿಡಿಎಸ್"
    },
    services:{
        en:"Services",
        ka:"ಸೇವೆಗಳು"
    },
    tickets:{
        en:"Tickets",
        ka:"ಟಿಕೆಟ್ಗಳು"
    },
    amc:{
        en:"AMC",
        ka:"ಟಿಕೆಟ್ಗಳು"
    },
    drafts_workorder:{
        en:"Drafts / Work Orders",
        ka:"ಕರಡು / ಕೆಲಸದ ಆದೇಶ"
    },
    delivery_addr:{
        en:"Delivery Address",
        ka:"ವಿತರಣಾ ವಿಳಾಸ"
    },
    settings:{
        en:"Settings",
        ka:"ಸಂಯೋಜನೆಗಳು"
    },

    // Dashboard

    top_banner_head:{
        en:"Discover a Huge Collection of ",
        ka:"ಅಲ್ಲಿ ಒಂದು ಮುಕ್ತ ಸಂಗ್ರಹ ಹೊಸಲ ಪ್ರಿಂಟರ್‌ನ ಒಂದು ಅಲ್ಲಿ ಹೊಂದಿರುವುದು "
    },
    latest_products:{
        en:"Latest Products",
        ka:"ಇತ್ತೀಚಿನ ಉತ್ಪನ್ನಗಳು"
    },

    // Products

    filter:{
        en:"Filter",
        ka:"ಪಾತ್ರೆ"
    },
    category:{
        en:"Category",
        ka:"ವರ್ಗ"
    },
    product_list:{
        en:"Product List",
        ka:"ಉತ್ಪನ್ನ ಪಟ್ಟಿ"
    },
    showing:{
        en:"Showing",
        ka:"ತೋರುವುದು"
    },
}