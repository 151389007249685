import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Context } from "../../../../Components/Context/Context";
import { Admin_Verifications } from "../../../../Library/LanguageConversion/Admin_Verifications";
import {
    Button,
    Table,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    ButtonGroup,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../../Library/CustomApi";
import Loader from "../../../../Components/Loader/Loader";
import CustomPagination from "../../../../Components/CustomPagination/Index";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import VendorDetailsModal from "./Modals/VendorDetailsModal";



const VendorDraftVerification = () => {

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [draft, setDraft] = useState<any>([]);
    const [pageData, setPageData] = useState<any>();
    const [isShowings, setIsShowings] = useState<boolean>(false)
    const [viewData, setViewData] = useState([]);
    const { language, setLanguage } = useContext(Context);

    useEffect(() => {

        getDraft();

    }, [currentPage]);

    const getDraft = () => {
        setLoading(true);
        api
            .get(
                `/vendorDrafts?page=${currentPage}`,
                true
            )
            .then(async function ([success, response]) {
                console.log(response?.data, "ejfen")
                setDraft(response?.data?.data || []);
                setPageData({
                    ...pageData,
                    total: response?.data?.total,
                    per_page: response?.data?.per_page,
                    current_page: response?.data?.current_page,
                    from: response?.data?.from,
                    to: response?.data?.to,
                });
                setLoading(false);

                return response;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const Verify = (user_id: Number, type: string) => {
        api
            .post(
                `/verifyvendorpay`,
                {
                    user_id: user_id,
                    status: 1,
                    type: type
                },
                true
            )
            .then(async function ([success, response]) {
                getDraft();
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (

        <React.Fragment>
            {/* <div className="search-widget-outer verif-outer">
                <Form role="search">
                    <ButtonGroup>
                        <Button
                            className={`${filter === "" ? "status-tabs active" : "status-tabs"
                                }`}
                            onClick={() => {
                                setCurrentPage(1);
                                setFilter("");
                            }}
                        >
                            All
                        </Button>
                        <Button
                            className={`${filter === "1" ? "status-tabs active" : "status-tabs"
                                }`}
                            onClick={() => {
                                setCurrentPage(1);
                                setFilter("1");
                            }}
                        >
                            Pending
                        </Button>
                        <Button
                            className={`${filter === "2" ? "status-tabs active" : "status-tabs"
                                }`}
                            onClick={() => {
                                setCurrentPage(1);
                                setFilter("2");
                            }}
                        >
                            Approved
                        </Button>
                    </ButtonGroup>
                    <InputGroup className="search-group">
                        <InputGroupText title="Department Name" className="search-icon" id="search">
                            <i className="fa-sharp fa-solid fa-magnifying-glass" />
                        </InputGroupText>
                        <Input
                            type="text"
                            className="form-control border-start-0"
                            placeholder="Department Name"
                            aria-label="Username"
                            aria-describedby="search"
                            value={search}
                            onChange={handleSearch}
                            onKeyPress={(event) => {
                                var key = event.keyCode || event.which;
                                if (key === 13) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </InputGroup>
                </Form>
            </div> */}
            <div className="table-wrap responsive-table">
                <Table className="w-100 table-striped">
                    <thead>
                        <tr>
                            <th>{Admin_Verifications?.SlNo[language]}</th>
                            <th>{Admin_Verifications?.VendorName[language]}</th>
                            <th>{Admin_Verifications?.View[language]}</th>
                            <th>{Admin_Verifications?.Action[language]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {draft?.length != 0 ? (
                            <>
                                {draft?.map((item: any, i: number) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <tr>
                                                <td>
                                                    <span className="mobile-heading">{Admin_Verifications?.SlNo[language]}:</span>
                                                    <span className="table-data">
                                                        {pageData.from + i}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="mobile-heading">
                                                    {Admin_Verifications?.VendorName[language]}:
                                                    </span>
                                                    <span className="table-data">{item?.user?.userme?.full_name}</span>
                                                </td>

                                                <td>
                                                    <span className="mobile-heading">{Admin_Verifications?.View[language]}:</span>
                                                    <a
                                                        href={item?.file}
                                                        target="_blank"
                                                        // onClick={() => {
                                                        //     download_documents(item?.id);
                                                        // }}
                                                        rel="noreferrer"
                                                        className="rounded-tag"
                                                    >
                                                        <i
                                                            title="Download & View"
                                                            className="icons bi bi-download lg"
                                                        ></i>
                                                    </a>

                                                    

                                                    <Link to='' title="View Details" onClick={() => {
                                                        setViewData(item)
                                                        setIsShowings(true)
                                                    }}>
                                                        <i className="bi bi-eye lg"></i>


                                                    </Link>
                                                </td>
                                                <td>
                                                    <span className="mobile-heading">{Admin_Verifications?.Action[language]}:</span>
                                                    <div className="action-flex-btn">
                                                        {/* <Link to='' title="View Details" onClick={() => {
                                                            setViewData(item)
                                                            setShowDetails(true)
                                                        }}>
                                                            {
                                                                item?.status ==
                                                                    constants.DRAFTVERIFICATION.PENDING ?
                                                                    'View & Verify' : 'View'
                                                            }
                                                            
                                                        </Link> */}
                                                        {
                                                            item?.status == 2 ?
                                                                <span className="approved-status"> {Admin_Verifications?.Approved[language]} </span>
                                                                :
                                                                <Button color='primary' className='text-end tds-save-btn' onClick={() => Verify(item?.user_id, item?.type)}> {Admin_Verifications?.Approve[language]}</Button>

                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })}
                            </>
                        ) : (
                            <tr>
                                <td className="text-center px-2 py-5 empty-text" colSpan={5}>
                                    {loading ? (
                                        <div className="d-flex justify-content-center">
                                            <Loader />
                                        </div>
                                    ) : (
                                        " No Draft Found"
                                    )}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {draft?.length !== 0 && pageData && (
                <div className="table-footer-wrap">
                    <small>
                    {Admin_Verifications?.Showing[language]} {pageData.from} {Admin_Verifications?.to[language]} {pageData.to} {Admin_Verifications?.of[language]} {pageData.total} {Admin_Verifications?.entries[language]}
                    </small>
                    <div className="pagination">
                        <CustomPagination
                            totalItems={pageData?.total}
                            itemsPerPage={pageData?.per_page}
                            activePage={pageData.current_page}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            )}


            <VendorDetailsModal

                isShowing={isShowings}
                setIsShowings={setIsShowings}
                values={viewData}
            />
        </React.Fragment>


    )
}

export default VendorDraftVerification;