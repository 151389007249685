type propsType = {
  amount: number;
  udyam: string;
};

const EmdCalculation = ({ amount, udyam }: propsType) => {
  let cat_1 = (amount * 1.25) / 100;
  let cat_2 = (amount * 1) / 100;
  let cat_3 = (amount * 2) / 100;

  let amounts =
    udyam == "yes"
      ? 0
      : amount <= 2000000
      ? cat_1
      : amount > 2000000 && amount <= 5000000
      ? cat_2
      : amount > 5000000
      ? cat_3
      : 0;
  return amounts;
};

const PGCalculation = ({ amount, udyam }: propsType) => {
  let pg_amount = (amount * 5) / 100;

  return pg_amount;
};

export { EmdCalculation, PGCalculation };
