import React, { PureComponent } from 'react';
import { RadialBarChart, RadialBar, Legend, Tooltip, ResponsiveContainer } from 'recharts';

import {
  Row
} from "reactstrap";

const RadialChart = (props: any) => {

  let v = [{
    id: 1,
    enquiry: 10,
    services: 20,
    tickets: 40,
    todays_date: "2023-02-20"
  }, props?.data]
  var datas: any = []
  var colors: any = ["#140d4f", "#ec7e00", "#DD3333", "#7AC173"]
  props?.data.map((val: number, index: any) => {
    return (
      datas[index] = { ...props?.data[index], fill: colors[index] }

    )
  }
  )

  const data = [
    {
      name: 'Services',
      Value: 4000,
      pv: 2400,
      amt: 2400,
      "fill": "#140d4f"
    },
    {
      name: 'Maintenance',
      Value: 3000,
      pv: 1398,
      amt: 2210,
      "fill": "#ec7e00"
    },
    {
      name: 'Complaints',
      Value: 2000,
      pv: 9800,
      amt: 2290,
      "fill": "#DD3333"
    },
    {
      name: 'Tickets',
      Value: 2780,
      pv: 3908,
      amt: 2000,
      "fill": "#7AC173"

    },
  ];

  return (
    <div style={{ width: '100%' }}>


      <ResponsiveContainer width="100%" height={350} className='tickets-graph'>
        <RadialBarChart
          height={250}
          innerRadius="40%"
          outerRadius="100%"
          data={datas}
          startAngle={180}
          endAngle={0}
        >
          <RadialBar label={{ fill: datas?.fill, position: 'insideStart' }} background dataKey='value' />
          <Tooltip />
        </RadialBarChart>
      </ResponsiveContainer>

      <Row>
        <div className="tickes-count-outer-wrap">
          <div className="tickets-inner-wrap">
            <h2 className="count">{datas?.[0]?.value}</h2>
            <p>{datas?.[0]?.name}</p>
          </div>
          <div className="tickets-inner-wrap">
            <h2 className="count">{datas?.[1]?.value}</h2>
            <p>{datas?.[1]?.name}</p>
          </div>
          <div className="tickets-inner-wrap">
            <h2 className="count">{datas?.[2]?.value}</h2>
            <p>{datas?.[2]?.name}</p>
          </div>
          <div className="tickets-inner-wrap">
            <h2 className="count">{datas?.[3]?.value}</h2>
            <p>{datas?.[3]?.name}</p>
          </div>
        </div>
      </Row>
    </div>
  )
}


export default RadialChart;
