import React, { useEffect, useContext } from "react";
import { useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import constants from "../../../Library/Constants/Constants";
import { api } from "../../../Library/CustomApi";
import CustomPagination from "../../../Components/CustomPagination/Index";
import { VendorRegLang } from "../../../Library/LanguageConversion/VendorLang";
import { Context } from "../../../Components/Context/Context";
import Loader from "../../../Components/Loader/Loader";

export default function FeedbackSummary(props: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [pageData, setPageData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const { language, setLanguage } = useContext(Context);

  useEffect(() => {
    getFeedback(currentPage);
  }, []);
  // ========================================================== Get Recieved Order ==========================================================

  const getFeedback = (page: any) => {
    setLoading(true);
    api
      .get(`/product/productfeedback?page=${page}`, true)
      .then(async function ([success, response]) {
        setFeedback(response?.data || []);
        setPageData(response?.meta);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // ================================================================ View ==================================================================

  return (
    <>
      <React.Fragment>
        <div className="right-content-wrap">
          <div className="feedback-summary-outer-wrap">
            <Row className="gy-4">
              {!loading ? (
                feedback.length > 0 ? (
                  feedback?.map((item: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <Col lg={4} md={6} sm={6}>
                          <Card>
                            <CardBody>
                              <div className="feedback-content">
                                <div className="user-icon">
                                  <img src="images/avatar.svg" alt="icon" />
                                </div>
                                <h5>
                                  {VendorRegLang?.water_authority[language]}
                                </h5>
                                <small>{item?.message}</small>
                                <small className="date-text">
                                  {VendorRegLang?.vendor[language]}
                                  <span>12/01/2023</span>
                                </small>
                                <div className="rating-wrap">
                                  {item?.star == 5 ? (
                                    <div className="rating-stars">
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                    </div>
                                  ) : item?.star == 4 ? (
                                    <div className="rating-stars">
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star"></i>
                                    </div>
                                  ) : item?.star == 3 ? (
                                    <div className="rating-stars">
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star"></i>
                                      <i className="bi bi-star"></i>
                                    </div>
                                  ) : item?.star == 2 ? (
                                    <div className="rating-stars">
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star"></i>
                                      <i className="bi bi-star"></i>
                                      <i className="bi bi-star"></i>
                                    </div>
                                  ) : item?.star == 1 ? (
                                    <div className="rating-stars">
                                      <i className="bi bi-star-fill"></i>
                                      <i className="bi bi-star"></i>
                                      <i className="bi bi-star"></i>
                                      <i className="bi bi-star"></i>
                                      <i className="bi bi-star"></i>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <p className="empty-dash-text">
                    {VendorRegLang?.no_data_found[language]}
                  </p>
                )
              ) : (
                <div className="loading-overlay">
                  <Loader />
                </div>
              )}
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}
