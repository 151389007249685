import { Route, Routes } from "react-router-dom";
import LoginView from '../../Pages/Login/LoginView';
import routes from "./AdminRoute/RouteList";
import { DeptRouteList } from "./DepartmentRoute/DeptRouteList";
import ProtectedRoutes from "./ProtectedRoute";
import AdminRoutes from "./AdminRoutes";
import DeptRoutes from "./DeptRoutes";
import ForgotView from "../../Pages/ForgotPassword/ForgotView";
import SetNewPassword from "../../Pages/ForgotPassword/SetNewPassword";
import Vendorroutes from "./VendorRoute/RouteList";
import VendorRoutes from "./VendorRoutes";
import CreateAccount from "../../Pages/Department/AccountCreation/CreateAccount";
import VendorRegister from '../../Pages/Vendor/VendorSignUp/VendorBasicDetails';
import { QAVendorsRoutes } from "./QAVendorRoutes/QAVendorRoutes";
import QAVendorRoutes from "./QAVendorRoutes";
import Indexs from '../../LandingPage/Pages/Index';
import Welcomes from '../../LandingPage/Pages/Welcomes';
import Homes from '../../LandingPage/Pages/Homes';
import Abouts from '../../LandingPage/Pages/Abouts';
import Faqs from '../../LandingPage/Pages/Faqs';
import Contacts from '../../LandingPage/Pages/Contacts';
import LayoutEng from '../../LandingPage/Components/Layout';
import TC from '../../LandingPage/Components/TC';
import PP from '../../LandingPage/Components/PP';
import { StoreRoutesData } from './StoreRoutes/StoreRoutesData';
import StoreRoutes from "./StoreRoutes";
import ManagerRoutes from "./ManagerRoutes";
import { ManagerRoutesData } from "./ManagerRoutes/ManagerRoutesData";
import { AccountantRoutesList } from "./AccountantRoutes/AccountantRoutes";
import AccountRoutes from "./AccountRoutes";
import DeletableFile from "../../Pages/Deletable/DeletableFile";

export default function RoutesData() {
  return (
    <>
      {routes && (
        <Routes>
          <Route path="/" element={<LayoutEng><Homes /></LayoutEng>} />
          <Route path="/home" element={<LayoutEng><Homes /></LayoutEng>} />
          <Route path="/launch" element={<Indexs />} />
          <Route path="/about" element={<LayoutEng><Abouts /></LayoutEng>} />
          <Route path="/contact" element={<LayoutEng><Contacts /></LayoutEng>} />
          <Route path="/faq" element={<LayoutEng><Faqs /></LayoutEng>} />
          <Route path="/terms-condition" element={<LayoutEng><TC /></LayoutEng>} />
          <Route path="/privacy-policy" element={<LayoutEng><PP /></LayoutEng>} />

          {/* <Route path="/" element={<Splash />} /> */}
          <Route path="/login" element={<LoginView />} />
          <Route path="/forgot-password" element={<ForgotView />} />
          <Route path="/set-new-password/:id" element={<SetNewPassword />} />
          {/* department signup  */}
          <Route path="/department/signup" element={<CreateAccount />} />
          <Route path="/vendor/signup" element={<VendorRegister />} />

          {/* for testing payment gateway- can be deleted  */}
          <Route path="/deletable-file-for-testing" element={<DeletableFile />} />

          <Route element={<ProtectedRoutes loginStatus={true} />}>
            {/* PMU routes  */}
            <Route element={<AdminRoutes loginStatus={true} />}>
              {routes?.map((route: any, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.Element}
                  />
                );
              })}
            </Route>

            {/* Department routes  */}
            <Route element={<DeptRoutes loginStatus={true} />}>
              {DeptRouteList?.map((route: any, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.Element}
                  />
                );
              })}
            </Route>

            {/* Vendor routes  */}
            <Route element={<VendorRoutes loginStatus={true} />}>
              {Vendorroutes?.map((route: any, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.Element}
                  />
                );
              })}
            </Route>

            {/* QA-Vendor  */}
            <Route element={<QAVendorRoutes loginStatus={true} />}>
              {QAVendorsRoutes?.map((route: any, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.Element}
                  />
                );
              })}
            </Route>

            {/* Store  */}
            <Route element={<StoreRoutes loginStatus={true} />}>
              {StoreRoutesData?.map((route: any, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.Element}
                  />
                );
              })}
            </Route>

            {/* Manager  */}
            <Route element={<ManagerRoutes loginStatus={true} />}>
              {ManagerRoutesData?.map((route: any, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.Element}
                  />
                );
              })}
            </Route>

            {/* Accountant  */}
            <Route element={<AccountRoutes loginStatus={true} />}>
              {AccountantRoutesList?.map((route: any, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.Element}
                  />
                );
              })}
            </Route>

          </Route>
        </Routes>
      )}
    </>
  );
}
