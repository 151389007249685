import React ,{useContext} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { List } from "reactstrap";
import {Vendor_Settings_ReuploadModal} from "../../../Library/LanguageConversion/Vendor_Settings_ReuploadModal";
import { Context } from "../../../Components/Context/Context";

function VendorSettingsLayout(props: any) {
    const { pathname } = useLocation()
    const { language, setLanguage } = useContext(Context);
    return (
        <React.Fragment>
            <div className="right-content-wrap">
                <div className="breadcrumb-tabs-outer-wrap">
                    {/* tabs */}
                    <div className="custom-tabs">
                        <NavLink to="/vendor-profile-settings">
                        {Vendor_Settings_ReuploadModal?.OEM_details[language]}
                        </NavLink>
                        <NavLink to="/vendor-documents">
                        {Vendor_Settings_ReuploadModal?.Documents[language]}

                        </NavLink>
                        <NavLink to="/vendor-advanced-reupload">
                        {Vendor_Settings_ReuploadModal?.Vendor_Advanced_Reupload[language]}
                        </NavLink>

                        <NavLink to="/vendor-account-details">
                        {Vendor_Settings_ReuploadModal?.Account_Details[language]}
                        </NavLink>

                    </div>
                    {/* breadcrumb */}
                    <List className="breadcrumb">
                        <li>
                            <NavLink className='active' to="/dashboard">
                            {Vendor_Settings_ReuploadModal?.Dashboard[language]}

                            </NavLink>
                        </li>
                        &nbsp; / &nbsp;
                        <li>
                            <b>
                                {
                                    pathname == "/vendor-account-details" ? "Account Details" :
                                        pathname == "/vendor-advanced-reupload" ? "Vendor Advanced Reupload" :
                                            pathname == "/vendor-documents" ? "Documents" :
                                                pathname == "/vendor-profile-settings" ? "Profile Settings" : "Settings"
                                }
                            </b>
                        </li>
                    </List>
                </div>

                {props.children}

            </div>
        </React.Fragment>
    );
}
export default VendorSettingsLayout;

